
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ChartBarsImportInfo struct
 */
class ChartBarsImportInfo {
  /**
   * Initialize struct
   * @param {!string=} symbol
   * @param {!number=} m1BarsReceived
   * @param {!number=} m1BarsImported
   * @param {!number=} m5BarsImported
   * @param {!number=} m15BarsImported
   * @param {!number=} m30BarsImported
   * @param {!number=} h1BarsImported
   * @param {!number=} h4BarsImported
   * @param {!number=} d1BarsImported
   * @param {!number=} w1BarsImported
   * @param {!number=} mNBarsImported
   * @constructor
   */
  constructor (symbol = '', m1BarsReceived = new UInt64(0, 0), m1BarsImported = new UInt64(0, 0), m5BarsImported = new UInt64(0, 0), m15BarsImported = new UInt64(0, 0), m30BarsImported = new UInt64(0, 0), h1BarsImported = new UInt64(0, 0), h4BarsImported = new UInt64(0, 0), d1BarsImported = new UInt64(0, 0), w1BarsImported = new UInt64(0, 0), mNBarsImported = new UInt64(0, 0)) {
    this.Symbol = symbol
    this.M1BarsReceived = m1BarsReceived
    this.M1BarsImported = m1BarsImported
    this.M5BarsImported = m5BarsImported
    this.M15BarsImported = m15BarsImported
    this.M30BarsImported = m30BarsImported
    this.H1BarsImported = h1BarsImported
    this.H4BarsImported = h4BarsImported
    this.D1BarsImported = d1BarsImported
    this.W1BarsImported = w1BarsImported
    this.MNBarsImported = mNBarsImported
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ChartBarsImportInfo}
   * @param {!ChartBarsImportInfo} other Other struct
   * @returns {!ChartBarsImportInfo} This struct
   */
  copy (other) {
    if (other.Symbol != null) {
      this.Symbol = other.Symbol
    } else {
      this.Symbol = null
    }
    if (other.M1BarsReceived != null) {
      this.M1BarsReceived = UInt64.fromNumber(other.M1BarsReceived)
    } else {
      this.M1BarsReceived = null
    }
    if (other.M1BarsImported != null) {
      this.M1BarsImported = UInt64.fromNumber(other.M1BarsImported)
    } else {
      this.M1BarsImported = null
    }
    if (other.M5BarsImported != null) {
      this.M5BarsImported = UInt64.fromNumber(other.M5BarsImported)
    } else {
      this.M5BarsImported = null
    }
    if (other.M15BarsImported != null) {
      this.M15BarsImported = UInt64.fromNumber(other.M15BarsImported)
    } else {
      this.M15BarsImported = null
    }
    if (other.M30BarsImported != null) {
      this.M30BarsImported = UInt64.fromNumber(other.M30BarsImported)
    } else {
      this.M30BarsImported = null
    }
    if (other.H1BarsImported != null) {
      this.H1BarsImported = UInt64.fromNumber(other.H1BarsImported)
    } else {
      this.H1BarsImported = null
    }
    if (other.H4BarsImported != null) {
      this.H4BarsImported = UInt64.fromNumber(other.H4BarsImported)
    } else {
      this.H4BarsImported = null
    }
    if (other.D1BarsImported != null) {
      this.D1BarsImported = UInt64.fromNumber(other.D1BarsImported)
    } else {
      this.D1BarsImported = null
    }
    if (other.W1BarsImported != null) {
      this.W1BarsImported = UInt64.fromNumber(other.W1BarsImported)
    } else {
      this.W1BarsImported = null
    }
    if (other.MNBarsImported != null) {
      this.MNBarsImported = UInt64.fromNumber(other.MNBarsImported)
    } else {
      this.MNBarsImported = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ChartBarsImportInfo}
   * @returns {!ChartBarsImportInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ChartBarsImportInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ChartBarsImportInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ChartBarsImportInfo}
   * @param {!ChartBarsImportInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ChartBarsImportInfo)) {
      throw new TypeError('Instance of ChartBarsImportInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ChartBarsImportInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Symbol: ((this.Symbol != null) ? this.Symbol : null),
      M1BarsReceived: ((this.M1BarsReceived != null) ? this.M1BarsReceived.toNumber() : null),
      M1BarsImported: ((this.M1BarsImported != null) ? this.M1BarsImported.toNumber() : null),
      M5BarsImported: ((this.M5BarsImported != null) ? this.M5BarsImported.toNumber() : null),
      M15BarsImported: ((this.M15BarsImported != null) ? this.M15BarsImported.toNumber() : null),
      M30BarsImported: ((this.M30BarsImported != null) ? this.M30BarsImported.toNumber() : null),
      H1BarsImported: ((this.H1BarsImported != null) ? this.H1BarsImported.toNumber() : null),
      H4BarsImported: ((this.H4BarsImported != null) ? this.H4BarsImported.toNumber() : null),
      D1BarsImported: ((this.D1BarsImported != null) ? this.D1BarsImported.toNumber() : null),
      W1BarsImported: ((this.W1BarsImported != null) ? this.W1BarsImported.toNumber() : null),
      MNBarsImported: ((this.MNBarsImported != null) ? this.MNBarsImported.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ChartBarsImportInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ChartBarsImportInfo} other Object value
   * @returns {!ChartBarsImportInfo} Created struct
   */
  static fromObject (other) {
    return new ChartBarsImportInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ChartBarsImportInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ChartBarsImportInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ChartBarsImportInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 3021
  }
}

export { ChartBarsImportInfo };

/**
 * Fast Binary Encoding ChartBarsImportInfo field model
 */
class FieldModelChartBarsImportInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Symbol = new fbe.FieldModelString(buffer, 4 + 4)
    this.M1BarsReceived = new fbe.FieldModelUInt64(buffer, this.Symbol.fbeOffset + this.Symbol.fbeSize)
    this.M1BarsImported = new fbe.FieldModelUInt64(buffer, this.M1BarsReceived.fbeOffset + this.M1BarsReceived.fbeSize)
    this.M5BarsImported = new fbe.FieldModelUInt64(buffer, this.M1BarsImported.fbeOffset + this.M1BarsImported.fbeSize)
    this.M15BarsImported = new fbe.FieldModelUInt64(buffer, this.M5BarsImported.fbeOffset + this.M5BarsImported.fbeSize)
    this.M30BarsImported = new fbe.FieldModelUInt64(buffer, this.M15BarsImported.fbeOffset + this.M15BarsImported.fbeSize)
    this.H1BarsImported = new fbe.FieldModelUInt64(buffer, this.M30BarsImported.fbeOffset + this.M30BarsImported.fbeSize)
    this.H4BarsImported = new fbe.FieldModelUInt64(buffer, this.H1BarsImported.fbeOffset + this.H1BarsImported.fbeSize)
    this.D1BarsImported = new fbe.FieldModelUInt64(buffer, this.H4BarsImported.fbeOffset + this.H4BarsImported.fbeSize)
    this.W1BarsImported = new fbe.FieldModelUInt64(buffer, this.D1BarsImported.fbeOffset + this.D1BarsImported.fbeSize)
    this.MNBarsImported = new fbe.FieldModelUInt64(buffer, this.W1BarsImported.fbeOffset + this.W1BarsImported.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelChartBarsImportInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelChartBarsImportInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Symbol.fbeSize + this.M1BarsReceived.fbeSize + this.M1BarsImported.fbeSize + this.M5BarsImported.fbeSize + this.M15BarsImported.fbeSize + this.M30BarsImported.fbeSize + this.H1BarsImported.fbeSize + this.H4BarsImported.fbeSize + this.D1BarsImported.fbeSize + this.W1BarsImported.fbeSize + this.MNBarsImported.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelChartBarsImportInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Symbol.fbeExtra + this.M1BarsReceived.fbeExtra + this.M1BarsImported.fbeExtra + this.M5BarsImported.fbeExtra + this.M15BarsImported.fbeExtra + this.M30BarsImported.fbeExtra + this.H1BarsImported.fbeExtra + this.H4BarsImported.fbeExtra + this.D1BarsImported.fbeExtra + this.W1BarsImported.fbeExtra + this.MNBarsImported.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelChartBarsImportInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelChartBarsImportInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelChartBarsImportInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 3021
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelChartBarsImportInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelChartBarsImportInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Symbol.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.M1BarsReceived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.M1BarsReceived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M1BarsReceived.fbeSize

    if ((fbeCurrentSize + this.M1BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.M1BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M1BarsImported.fbeSize

    if ((fbeCurrentSize + this.M5BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.M5BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M5BarsImported.fbeSize

    if ((fbeCurrentSize + this.M15BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.M15BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M15BarsImported.fbeSize

    if ((fbeCurrentSize + this.M30BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.M30BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M30BarsImported.fbeSize

    if ((fbeCurrentSize + this.H1BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.H1BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.H1BarsImported.fbeSize

    if ((fbeCurrentSize + this.H4BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.H4BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.H4BarsImported.fbeSize

    if ((fbeCurrentSize + this.D1BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.D1BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.D1BarsImported.fbeSize

    if ((fbeCurrentSize + this.W1BarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.W1BarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.W1BarsImported.fbeSize

    if ((fbeCurrentSize + this.MNBarsImported.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MNBarsImported.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MNBarsImported.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelChartBarsImportInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelChartBarsImportInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelChartBarsImportInfo}
   * @param {!ChartBarsImportInfo} fbeValue Default value, defaults is new ChartBarsImportInfo()
   * @returns {!ChartBarsImportInfo} ChartBarsImportInfo value
   */
  get (fbeValue = new ChartBarsImportInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelChartBarsImportInfo}
   * @param {!ChartBarsImportInfo} fbeValue ChartBarsImportInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) <= fbeStructSize) {
      fbeValue.Symbol = this.Symbol.get()
    } else {
      fbeValue.Symbol = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.M1BarsReceived.fbeSize) <= fbeStructSize) {
      fbeValue.M1BarsReceived = this.M1BarsReceived.get()
    } else {
      fbeValue.M1BarsReceived = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M1BarsReceived.fbeSize

    if ((fbeCurrentSize + this.M1BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.M1BarsImported = this.M1BarsImported.get()
    } else {
      fbeValue.M1BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M1BarsImported.fbeSize

    if ((fbeCurrentSize + this.M5BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.M5BarsImported = this.M5BarsImported.get()
    } else {
      fbeValue.M5BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M5BarsImported.fbeSize

    if ((fbeCurrentSize + this.M15BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.M15BarsImported = this.M15BarsImported.get()
    } else {
      fbeValue.M15BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M15BarsImported.fbeSize

    if ((fbeCurrentSize + this.M30BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.M30BarsImported = this.M30BarsImported.get()
    } else {
      fbeValue.M30BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.M30BarsImported.fbeSize

    if ((fbeCurrentSize + this.H1BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.H1BarsImported = this.H1BarsImported.get()
    } else {
      fbeValue.H1BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.H1BarsImported.fbeSize

    if ((fbeCurrentSize + this.H4BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.H4BarsImported = this.H4BarsImported.get()
    } else {
      fbeValue.H4BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.H4BarsImported.fbeSize

    if ((fbeCurrentSize + this.D1BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.D1BarsImported = this.D1BarsImported.get()
    } else {
      fbeValue.D1BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.D1BarsImported.fbeSize

    if ((fbeCurrentSize + this.W1BarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.W1BarsImported = this.W1BarsImported.get()
    } else {
      fbeValue.W1BarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.W1BarsImported.fbeSize

    if ((fbeCurrentSize + this.MNBarsImported.fbeSize) <= fbeStructSize) {
      fbeValue.MNBarsImported = this.MNBarsImported.get()
    } else {
      fbeValue.MNBarsImported = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MNBarsImported.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelChartBarsImportInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelChartBarsImportInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelChartBarsImportInfo}
   * @param {!ChartBarsImportInfo} fbeValue ChartBarsImportInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelChartBarsImportInfo}
   * @param {ChartBarsImportInfo} fbeValue ChartBarsImportInfo value
   */
  setFields (fbeValue) {
    this.Symbol.set(fbeValue.Symbol)
    this.M1BarsReceived.set(fbeValue.M1BarsReceived)
    this.M1BarsImported.set(fbeValue.M1BarsImported)
    this.M5BarsImported.set(fbeValue.M5BarsImported)
    this.M15BarsImported.set(fbeValue.M15BarsImported)
    this.M30BarsImported.set(fbeValue.M30BarsImported)
    this.H1BarsImported.set(fbeValue.H1BarsImported)
    this.H4BarsImported.set(fbeValue.H4BarsImported)
    this.D1BarsImported.set(fbeValue.D1BarsImported)
    this.W1BarsImported.set(fbeValue.W1BarsImported)
    this.MNBarsImported.set(fbeValue.MNBarsImported)
  }
}

export { FieldModelChartBarsImportInfo };

/**
 * Fast Binary Encoding ChartBarsImportInfo model
 */
class ChartBarsImportInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelChartBarsImportInfo(this.buffer, 4)
  }

  /**
   * Get the ChartBarsImportInfo model
   * @this {!ChartBarsImportInfoModel}
   * @returns {!FieldModelChartBarsImportInfo} model ChartBarsImportInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ChartBarsImportInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ChartBarsImportInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ChartBarsImportInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ChartBarsImportInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelChartBarsImportInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ChartBarsImportInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ChartBarsImportInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ChartBarsImportInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ChartBarsImportInfoModel}
   * @param {!ChartBarsImportInfo} value ChartBarsImportInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ChartBarsImportInfoModel}
   * @param {!ChartBarsImportInfo} value ChartBarsImportInfo value, defaults is new ChartBarsImportInfo()
   * @return {!object} Deserialized ChartBarsImportInfo value and its size
   */
  deserialize (value = new ChartBarsImportInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ChartBarsImportInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ChartBarsImportInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ChartBarsImportInfoModel}
   * @param {!number} prev Previous ChartBarsImportInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ChartBarsImportInfoModel };
