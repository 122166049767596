// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: manager.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'
import * as config from './config'
import * as history from './history'
import * as client from './client'
import * as feed from './feed'
import * as storage from './storage'
import * as exposed from './exposed'
import * as execution from './execution'
import * as backup from './backup'
import * as inner from './inner'
import * as trade from './trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {TradeEventType} from './manager/TradeEventType'
import {ReasonCode} from './manager/ReasonCode'
import {PingModuleRequest} from './manager/PingModuleRequest'
import {PingModuleRequestModel} from './manager/PingModuleRequest'
import {PongModuleResponse} from './manager/PongModuleResponse'
import {PongModuleResponseModel} from './manager/PongModuleResponse'
import {PingModuleReject} from './manager/PingModuleReject'
import {PingModuleRejectModel} from './manager/PingModuleReject'
import {Deprecated3} from './manager/Deprecated3'
import {Deprecated3Model} from './manager/Deprecated3'
import {Deprecated4} from './manager/Deprecated4'
import {Deprecated4Model} from './manager/Deprecated4'
import {Deprecated1} from './manager/Deprecated1'
import {Deprecated1Model} from './manager/Deprecated1'
import {Deprecated2} from './manager/Deprecated2'
import {Deprecated2Model} from './manager/Deprecated2'
import {ManagerSessionInfo} from './manager/ManagerSessionInfo'
import {ManagerSessionInfoModel} from './manager/ManagerSessionInfo'
import {ManagerSessionInfoRequest} from './manager/ManagerSessionInfoRequest'
import {ManagerSessionInfoRequestModel} from './manager/ManagerSessionInfoRequest'
import {ManagerSessionInfoResponse} from './manager/ManagerSessionInfoResponse'
import {ManagerSessionInfoResponseModel} from './manager/ManagerSessionInfoResponse'
import {OnlineSessionsRequest} from './manager/OnlineSessionsRequest'
import {OnlineSessionsRequestModel} from './manager/OnlineSessionsRequest'
import {OnlineSessionsResponse} from './manager/OnlineSessionsResponse'
import {OnlineSessionsResponseModel} from './manager/OnlineSessionsResponse'
import {DropSessionRequest} from './manager/DropSessionRequest'
import {DropSessionRequestModel} from './manager/DropSessionRequest'
import {DropSessionResponse} from './manager/DropSessionResponse'
import {DropSessionResponseModel} from './manager/DropSessionResponse'
import {ConfigRequest} from './manager/ConfigRequest'
import {ConfigRequestModel} from './manager/ConfigRequest'
import {ConfigResponse} from './manager/ConfigResponse'
import {ConfigResponseModel} from './manager/ConfigResponse'
import {ConfigReject} from './manager/ConfigReject'
import {ConfigRejectModel} from './manager/ConfigReject'
import {ConfigUpdateRequest} from './manager/ConfigUpdateRequest'
import {ConfigUpdateRequestModel} from './manager/ConfigUpdateRequest'
import {ConfigUpdateResponse} from './manager/ConfigUpdateResponse'
import {ConfigUpdateResponseModel} from './manager/ConfigUpdateResponse'
import {ConfigUpdateNotify} from './manager/ConfigUpdateNotify'
import {ConfigUpdateNotifyModel} from './manager/ConfigUpdateNotify'
import {ConfigVersionRequest} from './manager/ConfigVersionRequest'
import {ConfigVersionRequestModel} from './manager/ConfigVersionRequest'
import {ConfigVersionResponse} from './manager/ConfigVersionResponse'
import {ConfigVersionResponseModel} from './manager/ConfigVersionResponse'
import {ConfigExportRequest} from './manager/ConfigExportRequest'
import {ConfigExportRequestModel} from './manager/ConfigExportRequest'
import {ConfigExportResponse} from './manager/ConfigExportResponse'
import {ConfigExportResponseModel} from './manager/ConfigExportResponse'
import {ConfigImportRequest} from './manager/ConfigImportRequest'
import {ConfigImportRequestModel} from './manager/ConfigImportRequest'
import {ConfigImportResponse} from './manager/ConfigImportResponse'
import {ConfigImportResponseModel} from './manager/ConfigImportResponse'
import {ConfigValidateRequest} from './manager/ConfigValidateRequest'
import {ConfigValidateRequestModel} from './manager/ConfigValidateRequest'
import {ConfigValidateResponse} from './manager/ConfigValidateResponse'
import {ConfigValidateResponseModel} from './manager/ConfigValidateResponse'
import {ConfigRefreshRequest} from './manager/ConfigRefreshRequest'
import {ConfigRefreshRequestModel} from './manager/ConfigRefreshRequest'
import {ConfigRefreshResponse} from './manager/ConfigRefreshResponse'
import {ConfigRefreshResponseModel} from './manager/ConfigRefreshResponse'
import {ConfigHistoryRequest} from './manager/ConfigHistoryRequest'
import {ConfigHistoryRequestModel} from './manager/ConfigHistoryRequest'
import {ConfigHistoryResponse} from './manager/ConfigHistoryResponse'
import {ConfigHistoryResponseModel} from './manager/ConfigHistoryResponse'
import {ConfigRollbackRequest} from './manager/ConfigRollbackRequest'
import {ConfigRollbackRequestModel} from './manager/ConfigRollbackRequest'
import {ConfigRollbackResponse} from './manager/ConfigRollbackResponse'
import {ConfigRollbackResponseModel} from './manager/ConfigRollbackResponse'
import {HostGetRequest} from './manager/HostGetRequest'
import {HostGetRequestModel} from './manager/HostGetRequest'
import {HostGetResponse} from './manager/HostGetResponse'
import {HostGetResponseModel} from './manager/HostGetResponse'
import {HostsGetAllRequest} from './manager/HostsGetAllRequest'
import {HostsGetAllRequestModel} from './manager/HostsGetAllRequest'
import {HostsGetAllResponse} from './manager/HostsGetAllResponse'
import {HostsGetAllResponseModel} from './manager/HostsGetAllResponse'
import {HostCreateRequest} from './manager/HostCreateRequest'
import {HostCreateRequestModel} from './manager/HostCreateRequest'
import {HostCreateResponse} from './manager/HostCreateResponse'
import {HostCreateResponseModel} from './manager/HostCreateResponse'
import {HostModifyRequest} from './manager/HostModifyRequest'
import {HostModifyRequestModel} from './manager/HostModifyRequest'
import {HostModifyResponse} from './manager/HostModifyResponse'
import {HostModifyResponseModel} from './manager/HostModifyResponse'
import {HostDeleteRequest} from './manager/HostDeleteRequest'
import {HostDeleteRequestModel} from './manager/HostDeleteRequest'
import {HostDeleteResponse} from './manager/HostDeleteResponse'
import {HostDeleteResponseModel} from './manager/HostDeleteResponse'
import {HostUpdateNotify} from './manager/HostUpdateNotify'
import {HostUpdateNotifyModel} from './manager/HostUpdateNotify'
import {ModuleGetRequest} from './manager/ModuleGetRequest'
import {ModuleGetRequestModel} from './manager/ModuleGetRequest'
import {ModuleGetResponse} from './manager/ModuleGetResponse'
import {ModuleGetResponseModel} from './manager/ModuleGetResponse'
import {ModulesGetAllRequest} from './manager/ModulesGetAllRequest'
import {ModulesGetAllRequestModel} from './manager/ModulesGetAllRequest'
import {ModulesGetAllResponse} from './manager/ModulesGetAllResponse'
import {ModulesGetAllResponseModel} from './manager/ModulesGetAllResponse'
import {ModuleCreateRequest} from './manager/ModuleCreateRequest'
import {ModuleCreateRequestModel} from './manager/ModuleCreateRequest'
import {ModuleCreateResponse} from './manager/ModuleCreateResponse'
import {ModuleCreateResponseModel} from './manager/ModuleCreateResponse'
import {ModuleModifyRequest} from './manager/ModuleModifyRequest'
import {ModuleModifyRequestModel} from './manager/ModuleModifyRequest'
import {ModuleModifyResponse} from './manager/ModuleModifyResponse'
import {ModuleModifyResponseModel} from './manager/ModuleModifyResponse'
import {ModuleDeleteRequest} from './manager/ModuleDeleteRequest'
import {ModuleDeleteRequestModel} from './manager/ModuleDeleteRequest'
import {ModuleDeleteResponse} from './manager/ModuleDeleteResponse'
import {ModuleDeleteResponseModel} from './manager/ModuleDeleteResponse'
import {ModuleUpdateNotify} from './manager/ModuleUpdateNotify'
import {ModuleUpdateNotifyModel} from './manager/ModuleUpdateNotify'
import {ServerTimeGetRequest} from './manager/ServerTimeGetRequest'
import {ServerTimeGetRequestModel} from './manager/ServerTimeGetRequest'
import {ServerTimeGetResponse} from './manager/ServerTimeGetResponse'
import {ServerTimeGetResponseModel} from './manager/ServerTimeGetResponse'
import {ManagerPlatformTimezoneGetRequest} from './manager/ManagerPlatformTimezoneGetRequest'
import {ManagerPlatformTimezoneGetRequestModel} from './manager/ManagerPlatformTimezoneGetRequest'
import {ManagerPlatformTimezoneGetResponse} from './manager/ManagerPlatformTimezoneGetResponse'
import {ManagerPlatformTimezoneGetResponseModel} from './manager/ManagerPlatformTimezoneGetResponse'
import {TimezoneGetRequest} from './manager/TimezoneGetRequest'
import {TimezoneGetRequestModel} from './manager/TimezoneGetRequest'
import {TimezoneGetResponse} from './manager/TimezoneGetResponse'
import {TimezoneGetResponseModel} from './manager/TimezoneGetResponse'
import {TimezonesGetAllRequest} from './manager/TimezonesGetAllRequest'
import {TimezonesGetAllRequestModel} from './manager/TimezonesGetAllRequest'
import {TimezonesGetAllResponse} from './manager/TimezonesGetAllResponse'
import {TimezonesGetAllResponseModel} from './manager/TimezonesGetAllResponse'
import {TimezoneCreateRequest} from './manager/TimezoneCreateRequest'
import {TimezoneCreateRequestModel} from './manager/TimezoneCreateRequest'
import {TimezoneCreateResponse} from './manager/TimezoneCreateResponse'
import {TimezoneCreateResponseModel} from './manager/TimezoneCreateResponse'
import {TimezoneModifyRequest} from './manager/TimezoneModifyRequest'
import {TimezoneModifyRequestModel} from './manager/TimezoneModifyRequest'
import {TimezoneModifyResponse} from './manager/TimezoneModifyResponse'
import {TimezoneModifyResponseModel} from './manager/TimezoneModifyResponse'
import {TimezoneDeleteRequest} from './manager/TimezoneDeleteRequest'
import {TimezoneDeleteRequestModel} from './manager/TimezoneDeleteRequest'
import {TimezoneDeleteResponse} from './manager/TimezoneDeleteResponse'
import {TimezoneDeleteResponseModel} from './manager/TimezoneDeleteResponse'
import {TimezoneUpdateNotify} from './manager/TimezoneUpdateNotify'
import {TimezoneUpdateNotifyModel} from './manager/TimezoneUpdateNotify'
import {CurrencyGroupGetRequest} from './manager/CurrencyGroupGetRequest'
import {CurrencyGroupGetRequestModel} from './manager/CurrencyGroupGetRequest'
import {CurrencyGroupGetResponse} from './manager/CurrencyGroupGetResponse'
import {CurrencyGroupGetResponseModel} from './manager/CurrencyGroupGetResponse'
import {CurrencyGroupsGetAllRequest} from './manager/CurrencyGroupsGetAllRequest'
import {CurrencyGroupsGetAllRequestModel} from './manager/CurrencyGroupsGetAllRequest'
import {CurrencyGroupsGetAllResponse} from './manager/CurrencyGroupsGetAllResponse'
import {CurrencyGroupsGetAllResponseModel} from './manager/CurrencyGroupsGetAllResponse'
import {CurrencyGroupCreateRequest} from './manager/CurrencyGroupCreateRequest'
import {CurrencyGroupCreateRequestModel} from './manager/CurrencyGroupCreateRequest'
import {CurrencyGroupCreateResponse} from './manager/CurrencyGroupCreateResponse'
import {CurrencyGroupCreateResponseModel} from './manager/CurrencyGroupCreateResponse'
import {CurrencyGroupModifyRequest} from './manager/CurrencyGroupModifyRequest'
import {CurrencyGroupModifyRequestModel} from './manager/CurrencyGroupModifyRequest'
import {CurrencyGroupModifyResponse} from './manager/CurrencyGroupModifyResponse'
import {CurrencyGroupModifyResponseModel} from './manager/CurrencyGroupModifyResponse'
import {CurrencyGroupDeleteRequest} from './manager/CurrencyGroupDeleteRequest'
import {CurrencyGroupDeleteRequestModel} from './manager/CurrencyGroupDeleteRequest'
import {CurrencyGroupDeleteResponse} from './manager/CurrencyGroupDeleteResponse'
import {CurrencyGroupDeleteResponseModel} from './manager/CurrencyGroupDeleteResponse'
import {CurrencyGroupUpdateNotify} from './manager/CurrencyGroupUpdateNotify'
import {CurrencyGroupUpdateNotifyModel} from './manager/CurrencyGroupUpdateNotify'
import {CurrencyGetRequest} from './manager/CurrencyGetRequest'
import {CurrencyGetRequestModel} from './manager/CurrencyGetRequest'
import {CurrencyGetResponse} from './manager/CurrencyGetResponse'
import {CurrencyGetResponseModel} from './manager/CurrencyGetResponse'
import {CurrenciesGetAllRequest} from './manager/CurrenciesGetAllRequest'
import {CurrenciesGetAllRequestModel} from './manager/CurrenciesGetAllRequest'
import {CurrenciesGetAllResponse} from './manager/CurrenciesGetAllResponse'
import {CurrenciesGetAllResponseModel} from './manager/CurrenciesGetAllResponse'
import {CurrencyCreateRequest} from './manager/CurrencyCreateRequest'
import {CurrencyCreateRequestModel} from './manager/CurrencyCreateRequest'
import {CurrencyCreateResponse} from './manager/CurrencyCreateResponse'
import {CurrencyCreateResponseModel} from './manager/CurrencyCreateResponse'
import {CurrencyModifyRequest} from './manager/CurrencyModifyRequest'
import {CurrencyModifyRequestModel} from './manager/CurrencyModifyRequest'
import {CurrencyModifyResponse} from './manager/CurrencyModifyResponse'
import {CurrencyModifyResponseModel} from './manager/CurrencyModifyResponse'
import {CurrencyDeleteRequest} from './manager/CurrencyDeleteRequest'
import {CurrencyDeleteRequestModel} from './manager/CurrencyDeleteRequest'
import {CurrencyDeleteResponse} from './manager/CurrencyDeleteResponse'
import {CurrencyDeleteResponseModel} from './manager/CurrencyDeleteResponse'
import {CurrencyUpdateNotify} from './manager/CurrencyUpdateNotify'
import {CurrencyUpdateNotifyModel} from './manager/CurrencyUpdateNotify'
import {SymbolGroupGetRequest} from './manager/SymbolGroupGetRequest'
import {SymbolGroupGetRequestModel} from './manager/SymbolGroupGetRequest'
import {SymbolGroupGetResponse} from './manager/SymbolGroupGetResponse'
import {SymbolGroupGetResponseModel} from './manager/SymbolGroupGetResponse'
import {SymbolGroupsGetAllRequest} from './manager/SymbolGroupsGetAllRequest'
import {SymbolGroupsGetAllRequestModel} from './manager/SymbolGroupsGetAllRequest'
import {SymbolGroupsGetAllResponse} from './manager/SymbolGroupsGetAllResponse'
import {SymbolGroupsGetAllResponseModel} from './manager/SymbolGroupsGetAllResponse'
import {SymbolGroupCreateRequest} from './manager/SymbolGroupCreateRequest'
import {SymbolGroupCreateRequestModel} from './manager/SymbolGroupCreateRequest'
import {SymbolGroupCreateResponse} from './manager/SymbolGroupCreateResponse'
import {SymbolGroupCreateResponseModel} from './manager/SymbolGroupCreateResponse'
import {SymbolGroupModifyRequest} from './manager/SymbolGroupModifyRequest'
import {SymbolGroupModifyRequestModel} from './manager/SymbolGroupModifyRequest'
import {SymbolGroupModifyResponse} from './manager/SymbolGroupModifyResponse'
import {SymbolGroupModifyResponseModel} from './manager/SymbolGroupModifyResponse'
import {SymbolGroupDeleteRequest} from './manager/SymbolGroupDeleteRequest'
import {SymbolGroupDeleteRequestModel} from './manager/SymbolGroupDeleteRequest'
import {SymbolGroupDeleteResponse} from './manager/SymbolGroupDeleteResponse'
import {SymbolGroupDeleteResponseModel} from './manager/SymbolGroupDeleteResponse'
import {SymbolGroupUpdateNotify} from './manager/SymbolGroupUpdateNotify'
import {SymbolGroupUpdateNotifyModel} from './manager/SymbolGroupUpdateNotify'
import {SymbolGetRequest} from './manager/SymbolGetRequest'
import {SymbolGetRequestModel} from './manager/SymbolGetRequest'
import {SymbolGetResponse} from './manager/SymbolGetResponse'
import {SymbolGetResponseModel} from './manager/SymbolGetResponse'
import {SymbolsGetAllRequest} from './manager/SymbolsGetAllRequest'
import {SymbolsGetAllRequestModel} from './manager/SymbolsGetAllRequest'
import {SymbolsGetAllResponse} from './manager/SymbolsGetAllResponse'
import {SymbolsGetAllResponseModel} from './manager/SymbolsGetAllResponse'
import {SymbolCreateRequest} from './manager/SymbolCreateRequest'
import {SymbolCreateRequestModel} from './manager/SymbolCreateRequest'
import {SymbolCreateResponse} from './manager/SymbolCreateResponse'
import {SymbolCreateResponseModel} from './manager/SymbolCreateResponse'
import {SymbolModifyRequest} from './manager/SymbolModifyRequest'
import {SymbolModifyRequestModel} from './manager/SymbolModifyRequest'
import {SymbolModifyResponse} from './manager/SymbolModifyResponse'
import {SymbolModifyResponseModel} from './manager/SymbolModifyResponse'
import {SymbolDeleteRequest} from './manager/SymbolDeleteRequest'
import {SymbolDeleteRequestModel} from './manager/SymbolDeleteRequest'
import {SymbolDeleteResponse} from './manager/SymbolDeleteResponse'
import {SymbolDeleteResponseModel} from './manager/SymbolDeleteResponse'
import {SymbolUpdateNotify} from './manager/SymbolUpdateNotify'
import {SymbolUpdateNotifyModel} from './manager/SymbolUpdateNotify'
import {SwapsExportRequest} from './manager/SwapsExportRequest'
import {SwapsExportRequestModel} from './manager/SwapsExportRequest'
import {SwapsExportResponse} from './manager/SwapsExportResponse'
import {SwapsExportResponseModel} from './manager/SwapsExportResponse'
import {SwapsImportRequest} from './manager/SwapsImportRequest'
import {SwapsImportRequestModel} from './manager/SwapsImportRequest'
import {SwapsImportResponse} from './manager/SwapsImportResponse'
import {SwapsImportResponseModel} from './manager/SwapsImportResponse'
import {CommissionProfileGetRequest} from './manager/CommissionProfileGetRequest'
import {CommissionProfileGetRequestModel} from './manager/CommissionProfileGetRequest'
import {CommissionProfileGetResponse} from './manager/CommissionProfileGetResponse'
import {CommissionProfileGetResponseModel} from './manager/CommissionProfileGetResponse'
import {CommissionProfilesGetAllRequest} from './manager/CommissionProfilesGetAllRequest'
import {CommissionProfilesGetAllRequestModel} from './manager/CommissionProfilesGetAllRequest'
import {CommissionProfilesGetAllResponse} from './manager/CommissionProfilesGetAllResponse'
import {CommissionProfilesGetAllResponseModel} from './manager/CommissionProfilesGetAllResponse'
import {CommissionProfileCreateRequest} from './manager/CommissionProfileCreateRequest'
import {CommissionProfileCreateRequestModel} from './manager/CommissionProfileCreateRequest'
import {CommissionProfileCreateResponse} from './manager/CommissionProfileCreateResponse'
import {CommissionProfileCreateResponseModel} from './manager/CommissionProfileCreateResponse'
import {CommissionProfileModifyRequest} from './manager/CommissionProfileModifyRequest'
import {CommissionProfileModifyRequestModel} from './manager/CommissionProfileModifyRequest'
import {CommissionProfileModifyResponse} from './manager/CommissionProfileModifyResponse'
import {CommissionProfileModifyResponseModel} from './manager/CommissionProfileModifyResponse'
import {CommissionProfileDeleteRequest} from './manager/CommissionProfileDeleteRequest'
import {CommissionProfileDeleteRequestModel} from './manager/CommissionProfileDeleteRequest'
import {CommissionProfileDeleteResponse} from './manager/CommissionProfileDeleteResponse'
import {CommissionProfileDeleteResponseModel} from './manager/CommissionProfileDeleteResponse'
import {CommissionProfileUpdateNotify} from './manager/CommissionProfileUpdateNotify'
import {CommissionProfileUpdateNotifyModel} from './manager/CommissionProfileUpdateNotify'
import {MarkupProfileGetRequest} from './manager/MarkupProfileGetRequest'
import {MarkupProfileGetRequestModel} from './manager/MarkupProfileGetRequest'
import {MarkupProfileGetResponse} from './manager/MarkupProfileGetResponse'
import {MarkupProfileGetResponseModel} from './manager/MarkupProfileGetResponse'
import {MarkupProfilesGetAllRequest} from './manager/MarkupProfilesGetAllRequest'
import {MarkupProfilesGetAllRequestModel} from './manager/MarkupProfilesGetAllRequest'
import {MarkupProfilesGetAllResponse} from './manager/MarkupProfilesGetAllResponse'
import {MarkupProfilesGetAllResponseModel} from './manager/MarkupProfilesGetAllResponse'
import {MarkupProfileCreateRequest} from './manager/MarkupProfileCreateRequest'
import {MarkupProfileCreateRequestModel} from './manager/MarkupProfileCreateRequest'
import {MarkupProfileCreateResponse} from './manager/MarkupProfileCreateResponse'
import {MarkupProfileCreateResponseModel} from './manager/MarkupProfileCreateResponse'
import {MarkupProfileModifyRequest} from './manager/MarkupProfileModifyRequest'
import {MarkupProfileModifyRequestModel} from './manager/MarkupProfileModifyRequest'
import {MarkupProfileModifyResponse} from './manager/MarkupProfileModifyResponse'
import {MarkupProfileModifyResponseModel} from './manager/MarkupProfileModifyResponse'
import {MarkupProfileDeleteRequest} from './manager/MarkupProfileDeleteRequest'
import {MarkupProfileDeleteRequestModel} from './manager/MarkupProfileDeleteRequest'
import {MarkupProfileDeleteResponse} from './manager/MarkupProfileDeleteResponse'
import {MarkupProfileDeleteResponseModel} from './manager/MarkupProfileDeleteResponse'
import {MarkupProfileUpdateNotify} from './manager/MarkupProfileUpdateNotify'
import {MarkupProfileUpdateNotifyModel} from './manager/MarkupProfileUpdateNotify'
import {ManagerGroupGetRequest} from './manager/ManagerGroupGetRequest'
import {ManagerGroupGetRequestModel} from './manager/ManagerGroupGetRequest'
import {ManagerGroupGetResponse} from './manager/ManagerGroupGetResponse'
import {ManagerGroupGetResponseModel} from './manager/ManagerGroupGetResponse'
import {ManagerGroupsGetAllRequest} from './manager/ManagerGroupsGetAllRequest'
import {ManagerGroupsGetAllRequestModel} from './manager/ManagerGroupsGetAllRequest'
import {ManagerGroupsGetAllResponse} from './manager/ManagerGroupsGetAllResponse'
import {ManagerGroupsGetAllResponseModel} from './manager/ManagerGroupsGetAllResponse'
import {ManagerGroupCreateRequest} from './manager/ManagerGroupCreateRequest'
import {ManagerGroupCreateRequestModel} from './manager/ManagerGroupCreateRequest'
import {ManagerGroupCreateResponse} from './manager/ManagerGroupCreateResponse'
import {ManagerGroupCreateResponseModel} from './manager/ManagerGroupCreateResponse'
import {ManagerGroupModifyRequest} from './manager/ManagerGroupModifyRequest'
import {ManagerGroupModifyRequestModel} from './manager/ManagerGroupModifyRequest'
import {ManagerGroupModifyResponse} from './manager/ManagerGroupModifyResponse'
import {ManagerGroupModifyResponseModel} from './manager/ManagerGroupModifyResponse'
import {ManagerGroupDeleteRequest} from './manager/ManagerGroupDeleteRequest'
import {ManagerGroupDeleteRequestModel} from './manager/ManagerGroupDeleteRequest'
import {ManagerGroupDeleteResponse} from './manager/ManagerGroupDeleteResponse'
import {ManagerGroupDeleteResponseModel} from './manager/ManagerGroupDeleteResponse'
import {ManagerGroupUpdateNotify} from './manager/ManagerGroupUpdateNotify'
import {ManagerGroupUpdateNotifyModel} from './manager/ManagerGroupUpdateNotify'
import {ManagerGetRequest} from './manager/ManagerGetRequest'
import {ManagerGetRequestModel} from './manager/ManagerGetRequest'
import {ManagerGetResponse} from './manager/ManagerGetResponse'
import {ManagerGetResponseModel} from './manager/ManagerGetResponse'
import {ManagersGetAllRequest} from './manager/ManagersGetAllRequest'
import {ManagersGetAllRequestModel} from './manager/ManagersGetAllRequest'
import {ManagersGetAllResponse} from './manager/ManagersGetAllResponse'
import {ManagersGetAllResponseModel} from './manager/ManagersGetAllResponse'
import {ManagerCreateRequest} from './manager/ManagerCreateRequest'
import {ManagerCreateRequestModel} from './manager/ManagerCreateRequest'
import {ManagerCreateResponse} from './manager/ManagerCreateResponse'
import {ManagerCreateResponseModel} from './manager/ManagerCreateResponse'
import {ManagerModifyRequest} from './manager/ManagerModifyRequest'
import {ManagerModifyRequestModel} from './manager/ManagerModifyRequest'
import {ManagerModifyResponse} from './manager/ManagerModifyResponse'
import {ManagerModifyResponseModel} from './manager/ManagerModifyResponse'
import {ManagerDeleteRequest} from './manager/ManagerDeleteRequest'
import {ManagerDeleteRequestModel} from './manager/ManagerDeleteRequest'
import {ManagerDeleteResponse} from './manager/ManagerDeleteResponse'
import {ManagerDeleteResponseModel} from './manager/ManagerDeleteResponse'
import {ManagerUpdateNotify} from './manager/ManagerUpdateNotify'
import {ManagerUpdateNotifyModel} from './manager/ManagerUpdateNotify'
import {PlatformGetRequest} from './manager/PlatformGetRequest'
import {PlatformGetRequestModel} from './manager/PlatformGetRequest'
import {PlatformGetResponse} from './manager/PlatformGetResponse'
import {PlatformGetResponseModel} from './manager/PlatformGetResponse'
import {PlatformsGetAllRequest} from './manager/PlatformsGetAllRequest'
import {PlatformsGetAllRequestModel} from './manager/PlatformsGetAllRequest'
import {PlatformsGetAllResponse} from './manager/PlatformsGetAllResponse'
import {PlatformsGetAllResponseModel} from './manager/PlatformsGetAllResponse'
import {PlatformCreateRequest} from './manager/PlatformCreateRequest'
import {PlatformCreateRequestModel} from './manager/PlatformCreateRequest'
import {PlatformCreateResponse} from './manager/PlatformCreateResponse'
import {PlatformCreateResponseModel} from './manager/PlatformCreateResponse'
import {PlatformModifyRequest} from './manager/PlatformModifyRequest'
import {PlatformModifyRequestModel} from './manager/PlatformModifyRequest'
import {PlatformModifyResponse} from './manager/PlatformModifyResponse'
import {PlatformModifyResponseModel} from './manager/PlatformModifyResponse'
import {PlatformDeleteRequest} from './manager/PlatformDeleteRequest'
import {PlatformDeleteRequestModel} from './manager/PlatformDeleteRequest'
import {PlatformDeleteResponse} from './manager/PlatformDeleteResponse'
import {PlatformDeleteResponseModel} from './manager/PlatformDeleteResponse'
import {PlatformUpdateNotify} from './manager/PlatformUpdateNotify'
import {PlatformUpdateNotifyModel} from './manager/PlatformUpdateNotify'
import {AccountGroupGetRequest} from './manager/AccountGroupGetRequest'
import {AccountGroupGetRequestModel} from './manager/AccountGroupGetRequest'
import {AccountGroupGetResponse} from './manager/AccountGroupGetResponse'
import {AccountGroupGetResponseModel} from './manager/AccountGroupGetResponse'
import {AccountGroupsGetAllRequest} from './manager/AccountGroupsGetAllRequest'
import {AccountGroupsGetAllRequestModel} from './manager/AccountGroupsGetAllRequest'
import {AccountGroupsGetAllResponse} from './manager/AccountGroupsGetAllResponse'
import {AccountGroupsGetAllResponseModel} from './manager/AccountGroupsGetAllResponse'
import {AccountGroupCreateRequest} from './manager/AccountGroupCreateRequest'
import {AccountGroupCreateRequestModel} from './manager/AccountGroupCreateRequest'
import {AccountGroupCreateResponse} from './manager/AccountGroupCreateResponse'
import {AccountGroupCreateResponseModel} from './manager/AccountGroupCreateResponse'
import {AccountGroupModifyRequest} from './manager/AccountGroupModifyRequest'
import {AccountGroupModifyRequestModel} from './manager/AccountGroupModifyRequest'
import {AccountGroupModifyResponse} from './manager/AccountGroupModifyResponse'
import {AccountGroupModifyResponseModel} from './manager/AccountGroupModifyResponse'
import {AccountGroupDeleteRequest} from './manager/AccountGroupDeleteRequest'
import {AccountGroupDeleteRequestModel} from './manager/AccountGroupDeleteRequest'
import {AccountGroupDeleteResponse} from './manager/AccountGroupDeleteResponse'
import {AccountGroupDeleteResponseModel} from './manager/AccountGroupDeleteResponse'
import {AccountGroupUpdateNotify} from './manager/AccountGroupUpdateNotify'
import {AccountGroupUpdateNotifyModel} from './manager/AccountGroupUpdateNotify'
import {AccountGetRequest} from './manager/AccountGetRequest'
import {AccountGetRequestModel} from './manager/AccountGetRequest'
import {AccountGetResponse} from './manager/AccountGetResponse'
import {AccountGetResponseModel} from './manager/AccountGetResponse'
import {AccountsGetAllRequest} from './manager/AccountsGetAllRequest'
import {AccountsGetAllRequestModel} from './manager/AccountsGetAllRequest'
import {AccountsGetAllResponse} from './manager/AccountsGetAllResponse'
import {AccountsGetAllResponseModel} from './manager/AccountsGetAllResponse'
import {AccountCreateRequest} from './manager/AccountCreateRequest'
import {AccountCreateRequestModel} from './manager/AccountCreateRequest'
import {AccountCreateResponse} from './manager/AccountCreateResponse'
import {AccountCreateResponseModel} from './manager/AccountCreateResponse'
import {AccountCreateParams} from './manager/AccountCreateParams'
import {AccountCreateParamsModel} from './manager/AccountCreateParams'
import {AccountSocialTradingMasterCreateRequest} from './manager/AccountSocialTradingMasterCreateRequest'
import {AccountSocialTradingMasterCreateRequestModel} from './manager/AccountSocialTradingMasterCreateRequest'
import {AccountSocialTradingMasterCreateResponse} from './manager/AccountSocialTradingMasterCreateResponse'
import {AccountSocialTradingMasterCreateResponseModel} from './manager/AccountSocialTradingMasterCreateResponse'
import {AccountSocialTradingInvestorCreateRequest} from './manager/AccountSocialTradingInvestorCreateRequest'
import {AccountSocialTradingInvestorCreateRequestModel} from './manager/AccountSocialTradingInvestorCreateRequest'
import {AccountSocialTradingInvestorCreateResponse} from './manager/AccountSocialTradingInvestorCreateResponse'
import {AccountSocialTradingInvestorCreateResponseModel} from './manager/AccountSocialTradingInvestorCreateResponse'
import {SocialTradingMasterUpdate} from './manager/SocialTradingMasterUpdate'
import {SocialTradingMasterUpdateModel} from './manager/SocialTradingMasterUpdate'
import {SocialTradingInvestorUpdate} from './manager/SocialTradingInvestorUpdate'
import {SocialTradingInvestorUpdateModel} from './manager/SocialTradingInvestorUpdate'
import {AccountModifyRequest} from './manager/AccountModifyRequest'
import {AccountModifyRequestModel} from './manager/AccountModifyRequest'
import {AccountModifyResponse} from './manager/AccountModifyResponse'
import {AccountModifyResponseModel} from './manager/AccountModifyResponse'
import {AccountDeleteRequest} from './manager/AccountDeleteRequest'
import {AccountDeleteRequestModel} from './manager/AccountDeleteRequest'
import {AccountDeleteResponse} from './manager/AccountDeleteResponse'
import {AccountDeleteResponseModel} from './manager/AccountDeleteResponse'
import {DemoAccountsDeleteRequest} from './manager/DemoAccountsDeleteRequest'
import {DemoAccountsDeleteRequestModel} from './manager/DemoAccountsDeleteRequest'
import {DemoAccountsDeleteResponse} from './manager/DemoAccountsDeleteResponse'
import {DemoAccountsDeleteResponseModel} from './manager/DemoAccountsDeleteResponse'
import {AccountUpgradeToSocialTradingMasterRequest} from './manager/AccountUpgradeToSocialTradingMasterRequest'
import {AccountUpgradeToSocialTradingMasterRequestModel} from './manager/AccountUpgradeToSocialTradingMasterRequest'
import {AccountUpgradeToSocialTradingMasterResponse} from './manager/AccountUpgradeToSocialTradingMasterResponse'
import {AccountUpgradeToSocialTradingMasterResponseModel} from './manager/AccountUpgradeToSocialTradingMasterResponse'
import {AccountUpgradeToSocialTradingInvestorRequest} from './manager/AccountUpgradeToSocialTradingInvestorRequest'
import {AccountUpgradeToSocialTradingInvestorRequestModel} from './manager/AccountUpgradeToSocialTradingInvestorRequest'
import {AccountUpgradeToSocialTradingInvestorResponse} from './manager/AccountUpgradeToSocialTradingInvestorResponse'
import {AccountUpgradeToSocialTradingInvestorResponseModel} from './manager/AccountUpgradeToSocialTradingInvestorResponse'
import {SocialTradingInvestorAccountToRegularRequest} from './manager/SocialTradingInvestorAccountToRegularRequest'
import {SocialTradingInvestorAccountToRegularRequestModel} from './manager/SocialTradingInvestorAccountToRegularRequest'
import {SocialTradingInvestorAccountToRegularResponse} from './manager/SocialTradingInvestorAccountToRegularResponse'
import {SocialTradingInvestorAccountToRegularResponseModel} from './manager/SocialTradingInvestorAccountToRegularResponse'
import {SocialTradingMasterAccountToRegularRequest} from './manager/SocialTradingMasterAccountToRegularRequest'
import {SocialTradingMasterAccountToRegularRequestModel} from './manager/SocialTradingMasterAccountToRegularRequest'
import {SocialTradingMasterAccountToRegularResponse} from './manager/SocialTradingMasterAccountToRegularResponse'
import {SocialTradingMasterAccountToRegularResponseModel} from './manager/SocialTradingMasterAccountToRegularResponse'
import {AccountUpdateNotify} from './manager/AccountUpdateNotify'
import {AccountUpdateNotifyModel} from './manager/AccountUpdateNotify'
import {MarginGetRequest} from './manager/MarginGetRequest'
import {MarginGetRequestModel} from './manager/MarginGetRequest'
import {MarginGetResponse} from './manager/MarginGetResponse'
import {MarginGetResponseModel} from './manager/MarginGetResponse'
import {MarginsGetAllRequest} from './manager/MarginsGetAllRequest'
import {MarginsGetAllRequestModel} from './manager/MarginsGetAllRequest'
import {MarginsGetAllResponse} from './manager/MarginsGetAllResponse'
import {MarginsGetAllResponseModel} from './manager/MarginsGetAllResponse'
import {MarginUpdateNotify} from './manager/MarginUpdateNotify'
import {MarginUpdateNotifyModel} from './manager/MarginUpdateNotify'
import {MarginCallNotify} from './manager/MarginCallNotify'
import {MarginCallNotifyModel} from './manager/MarginCallNotify'
import {StopOutNotify} from './manager/StopOutNotify'
import {StopOutNotifyModel} from './manager/StopOutNotify'
import {AssetGetRequest} from './manager/AssetGetRequest'
import {AssetGetRequestModel} from './manager/AssetGetRequest'
import {AssetGetResponse} from './manager/AssetGetResponse'
import {AssetGetResponseModel} from './manager/AssetGetResponse'
import {AssetsGetRequest} from './manager/AssetsGetRequest'
import {AssetsGetRequestModel} from './manager/AssetsGetRequest'
import {AssetsGetResponse} from './manager/AssetsGetResponse'
import {AssetsGetResponseModel} from './manager/AssetsGetResponse'
import {AssetsGetAllRequest} from './manager/AssetsGetAllRequest'
import {AssetsGetAllRequestModel} from './manager/AssetsGetAllRequest'
import {AssetsGetAllResponse} from './manager/AssetsGetAllResponse'
import {AssetsGetAllResponseModel} from './manager/AssetsGetAllResponse'
import {AssetUpdateNotify} from './manager/AssetUpdateNotify'
import {AssetUpdateNotifyModel} from './manager/AssetUpdateNotify'
import {OrderGetRequest} from './manager/OrderGetRequest'
import {OrderGetRequestModel} from './manager/OrderGetRequest'
import {OrderGetResponse} from './manager/OrderGetResponse'
import {OrderGetResponseModel} from './manager/OrderGetResponse'
import {OrdersGetRequest} from './manager/OrdersGetRequest'
import {OrdersGetRequestModel} from './manager/OrdersGetRequest'
import {OrdersGetResponse} from './manager/OrdersGetResponse'
import {OrdersGetResponseModel} from './manager/OrdersGetResponse'
import {OrdersGetAllRequest} from './manager/OrdersGetAllRequest'
import {OrdersGetAllRequestModel} from './manager/OrdersGetAllRequest'
import {OrdersGetAllResponse} from './manager/OrdersGetAllResponse'
import {OrdersGetAllResponseModel} from './manager/OrdersGetAllResponse'
import {OrderCreateRequest} from './manager/OrderCreateRequest'
import {OrderCreateRequestModel} from './manager/OrderCreateRequest'
import {OrderCreateResponse} from './manager/OrderCreateResponse'
import {OrderCreateResponseModel} from './manager/OrderCreateResponse'
import {OrderModifyRequest} from './manager/OrderModifyRequest'
import {OrderModifyRequestModel} from './manager/OrderModifyRequest'
import {OrderModifyResponse} from './manager/OrderModifyResponse'
import {OrderModifyResponseModel} from './manager/OrderModifyResponse'
import {OrderActivateRequest} from './manager/OrderActivateRequest'
import {OrderActivateRequestModel} from './manager/OrderActivateRequest'
import {OrderActivateResponse} from './manager/OrderActivateResponse'
import {OrderActivateResponseModel} from './manager/OrderActivateResponse'
import {OrderCancelRequest} from './manager/OrderCancelRequest'
import {OrderCancelRequestModel} from './manager/OrderCancelRequest'
import {OrderCancelResponse} from './manager/OrderCancelResponse'
import {OrderCancelResponseModel} from './manager/OrderCancelResponse'
import {OrderCancelAllRequest} from './manager/OrderCancelAllRequest'
import {OrderCancelAllRequestModel} from './manager/OrderCancelAllRequest'
import {OrderCancelAllResponse} from './manager/OrderCancelAllResponse'
import {OrderCancelAllResponseModel} from './manager/OrderCancelAllResponse'
import {OrderCancelAllByIdRequest} from './manager/OrderCancelAllByIdRequest'
import {OrderCancelAllByIdRequestModel} from './manager/OrderCancelAllByIdRequest'
import {OrderCloseRequest} from './manager/OrderCloseRequest'
import {OrderCloseRequestModel} from './manager/OrderCloseRequest'
import {OrderCloseResponse} from './manager/OrderCloseResponse'
import {OrderCloseResponseModel} from './manager/OrderCloseResponse'
import {OrderCloseAllRequest} from './manager/OrderCloseAllRequest'
import {OrderCloseAllRequestModel} from './manager/OrderCloseAllRequest'
import {OrderCloseAllResponse} from './manager/OrderCloseAllResponse'
import {OrderCloseAllResponseModel} from './manager/OrderCloseAllResponse'
import {OrderCloseAllByIdRequest} from './manager/OrderCloseAllByIdRequest'
import {OrderCloseAllByIdRequestModel} from './manager/OrderCloseAllByIdRequest'
import {OrderCloseByRequest} from './manager/OrderCloseByRequest'
import {OrderCloseByRequestModel} from './manager/OrderCloseByRequest'
import {OrderCloseByResponse} from './manager/OrderCloseByResponse'
import {OrderCloseByResponseModel} from './manager/OrderCloseByResponse'
import {OrderCloseByAllRequest} from './manager/OrderCloseByAllRequest'
import {OrderCloseByAllRequestModel} from './manager/OrderCloseByAllRequest'
import {OrderCloseByAllResponse} from './manager/OrderCloseByAllResponse'
import {OrderCloseByAllResponseModel} from './manager/OrderCloseByAllResponse'
import {OrderCancelOrCloseRequest} from './manager/OrderCancelOrCloseRequest'
import {OrderCancelOrCloseRequestModel} from './manager/OrderCancelOrCloseRequest'
import {OrderCancelOrCloseResponse} from './manager/OrderCancelOrCloseResponse'
import {OrderCancelOrCloseResponseModel} from './manager/OrderCancelOrCloseResponse'
import {OrderCancelOrCloseAllRequest} from './manager/OrderCancelOrCloseAllRequest'
import {OrderCancelOrCloseAllRequestModel} from './manager/OrderCancelOrCloseAllRequest'
import {OrderCancelOrCloseAllResponse} from './manager/OrderCancelOrCloseAllResponse'
import {OrderCancelOrCloseAllResponseModel} from './manager/OrderCancelOrCloseAllResponse'
import {OrderUpdateNotify} from './manager/OrderUpdateNotify'
import {OrderUpdateNotifyModel} from './manager/OrderUpdateNotify'
import {PositionGetRequest} from './manager/PositionGetRequest'
import {PositionGetRequestModel} from './manager/PositionGetRequest'
import {PositionGetResponse} from './manager/PositionGetResponse'
import {PositionGetResponseModel} from './manager/PositionGetResponse'
import {PositionsGetRequest} from './manager/PositionsGetRequest'
import {PositionsGetRequestModel} from './manager/PositionsGetRequest'
import {PositionsGetResponse} from './manager/PositionsGetResponse'
import {PositionsGetResponseModel} from './manager/PositionsGetResponse'
import {PositionsGetAllRequest} from './manager/PositionsGetAllRequest'
import {PositionsGetAllRequestModel} from './manager/PositionsGetAllRequest'
import {PositionsGetAllResponse} from './manager/PositionsGetAllResponse'
import {PositionsGetAllResponseModel} from './manager/PositionsGetAllResponse'
import {PositionUpdateNotify} from './manager/PositionUpdateNotify'
import {PositionUpdateNotifyModel} from './manager/PositionUpdateNotify'
import {TradeHistoryRequest} from './manager/TradeHistoryRequest'
import {TradeHistoryRequestModel} from './manager/TradeHistoryRequest'
import {TradeHistoryResponse} from './manager/TradeHistoryResponse'
import {TradeHistoryResponseModel} from './manager/TradeHistoryResponse'
import {TradeHistoryFilterRequest} from './manager/TradeHistoryFilterRequest'
import {TradeHistoryFilterRequestModel} from './manager/TradeHistoryFilterRequest'
import {TradeHistoryByIdRequest} from './manager/TradeHistoryByIdRequest'
import {TradeHistoryByIdRequestModel} from './manager/TradeHistoryByIdRequest'
import {TradeHistoryByIdResponse} from './manager/TradeHistoryByIdResponse'
import {TradeHistoryByIdResponseModel} from './manager/TradeHistoryByIdResponse'
import {TradeHistoryUpdateNotify} from './manager/TradeHistoryUpdateNotify'
import {TradeHistoryUpdateNotifyModel} from './manager/TradeHistoryUpdateNotify'
import {TestRolloverDumpRequest} from './manager/TestRolloverDumpRequest'
import {TestRolloverDumpRequestModel} from './manager/TestRolloverDumpRequest'
import {TestRolloverDumpResponse} from './manager/TestRolloverDumpResponse'
import {TestRolloverDumpResponseModel} from './manager/TestRolloverDumpResponse'
import {Deprecated001} from './manager/Deprecated001'
import {Deprecated001Model} from './manager/Deprecated001'
import {Deprecated002} from './manager/Deprecated002'
import {Deprecated002Model} from './manager/Deprecated002'
import {Deprecated003} from './manager/Deprecated003'
import {Deprecated003Model} from './manager/Deprecated003'
import {Deprecated004} from './manager/Deprecated004'
import {Deprecated004Model} from './manager/Deprecated004'
import {Deprecated005} from './manager/Deprecated005'
import {Deprecated005Model} from './manager/Deprecated005'
import {Deprecated006} from './manager/Deprecated006'
import {Deprecated006Model} from './manager/Deprecated006'
import {Deprecated007} from './manager/Deprecated007'
import {Deprecated007Model} from './manager/Deprecated007'
import {Deprecated008} from './manager/Deprecated008'
import {Deprecated008Model} from './manager/Deprecated008'
import {Deprecated009} from './manager/Deprecated009'
import {Deprecated009Model} from './manager/Deprecated009'
import {Deprecated010} from './manager/Deprecated010'
import {Deprecated010Model} from './manager/Deprecated010'
import {Deprecated011} from './manager/Deprecated011'
import {Deprecated011Model} from './manager/Deprecated011'
import {Deprecated012} from './manager/Deprecated012'
import {Deprecated012Model} from './manager/Deprecated012'
import {Deprecated013} from './manager/Deprecated013'
import {Deprecated013Model} from './manager/Deprecated013'
import {DepositAccountRequest} from './manager/DepositAccountRequest'
import {DepositAccountRequestModel} from './manager/DepositAccountRequest'
import {DepositAccountResponse} from './manager/DepositAccountResponse'
import {DepositAccountResponseModel} from './manager/DepositAccountResponse'
import {CreditAccountRequest} from './manager/CreditAccountRequest'
import {CreditAccountRequestModel} from './manager/CreditAccountRequest'
import {CreditAccountResponse} from './manager/CreditAccountResponse'
import {CreditAccountResponseModel} from './manager/CreditAccountResponse'
import {TransferMoneyRequest} from './manager/TransferMoneyRequest'
import {TransferMoneyRequestModel} from './manager/TransferMoneyRequest'
import {TransferMoneyResponse} from './manager/TransferMoneyResponse'
import {TransferMoneyResponseModel} from './manager/TransferMoneyResponse'
import {TradingCalendarRegularRuleGetRequest} from './manager/TradingCalendarRegularRuleGetRequest'
import {TradingCalendarRegularRuleGetRequestModel} from './manager/TradingCalendarRegularRuleGetRequest'
import {TradingCalendarRegularRuleGetResponse} from './manager/TradingCalendarRegularRuleGetResponse'
import {TradingCalendarRegularRuleGetResponseModel} from './manager/TradingCalendarRegularRuleGetResponse'
import {TradingCalendarRegularRulesGetAllRequest} from './manager/TradingCalendarRegularRulesGetAllRequest'
import {TradingCalendarRegularRulesGetAllRequestModel} from './manager/TradingCalendarRegularRulesGetAllRequest'
import {TradingCalendarRegularRulesGetAllResponse} from './manager/TradingCalendarRegularRulesGetAllResponse'
import {TradingCalendarRegularRulesGetAllResponseModel} from './manager/TradingCalendarRegularRulesGetAllResponse'
import {TradingCalendarRegularRuleCreateRequest} from './manager/TradingCalendarRegularRuleCreateRequest'
import {TradingCalendarRegularRuleCreateRequestModel} from './manager/TradingCalendarRegularRuleCreateRequest'
import {TradingCalendarRegularRuleCreateResponse} from './manager/TradingCalendarRegularRuleCreateResponse'
import {TradingCalendarRegularRuleCreateResponseModel} from './manager/TradingCalendarRegularRuleCreateResponse'
import {TradingCalendarRegularRuleModifyRequest} from './manager/TradingCalendarRegularRuleModifyRequest'
import {TradingCalendarRegularRuleModifyRequestModel} from './manager/TradingCalendarRegularRuleModifyRequest'
import {TradingCalendarRegularRuleModifyResponse} from './manager/TradingCalendarRegularRuleModifyResponse'
import {TradingCalendarRegularRuleModifyResponseModel} from './manager/TradingCalendarRegularRuleModifyResponse'
import {TradingCalendarRegularRuleDeleteRequest} from './manager/TradingCalendarRegularRuleDeleteRequest'
import {TradingCalendarRegularRuleDeleteRequestModel} from './manager/TradingCalendarRegularRuleDeleteRequest'
import {TradingCalendarRegularRuleDeleteResponse} from './manager/TradingCalendarRegularRuleDeleteResponse'
import {TradingCalendarRegularRuleDeleteResponseModel} from './manager/TradingCalendarRegularRuleDeleteResponse'
import {TradingCalendarRegularRuleUpdateNotify} from './manager/TradingCalendarRegularRuleUpdateNotify'
import {TradingCalendarRegularRuleUpdateNotifyModel} from './manager/TradingCalendarRegularRuleUpdateNotify'
import {TradingCalendarOffTimeRuleGetRequest} from './manager/TradingCalendarOffTimeRuleGetRequest'
import {TradingCalendarOffTimeRuleGetRequestModel} from './manager/TradingCalendarOffTimeRuleGetRequest'
import {TradingCalendarOffTimeRuleGetResponse} from './manager/TradingCalendarOffTimeRuleGetResponse'
import {TradingCalendarOffTimeRuleGetResponseModel} from './manager/TradingCalendarOffTimeRuleGetResponse'
import {TradingCalendarOffTimeRulesGetAllRequest} from './manager/TradingCalendarOffTimeRulesGetAllRequest'
import {TradingCalendarOffTimeRulesGetAllRequestModel} from './manager/TradingCalendarOffTimeRulesGetAllRequest'
import {TradingCalendarOffTimeRulesGetAllResponse} from './manager/TradingCalendarOffTimeRulesGetAllResponse'
import {TradingCalendarOffTimeRulesGetAllResponseModel} from './manager/TradingCalendarOffTimeRulesGetAllResponse'
import {TradingCalendarOffTimeRuleCreateRequest} from './manager/TradingCalendarOffTimeRuleCreateRequest'
import {TradingCalendarOffTimeRuleCreateRequestModel} from './manager/TradingCalendarOffTimeRuleCreateRequest'
import {TradingCalendarOffTimeRuleCreateResponse} from './manager/TradingCalendarOffTimeRuleCreateResponse'
import {TradingCalendarOffTimeRuleCreateResponseModel} from './manager/TradingCalendarOffTimeRuleCreateResponse'
import {TradingCalendarOffTimeRuleModifyRequest} from './manager/TradingCalendarOffTimeRuleModifyRequest'
import {TradingCalendarOffTimeRuleModifyRequestModel} from './manager/TradingCalendarOffTimeRuleModifyRequest'
import {TradingCalendarOffTimeRuleModifyResponse} from './manager/TradingCalendarOffTimeRuleModifyResponse'
import {TradingCalendarOffTimeRuleModifyResponseModel} from './manager/TradingCalendarOffTimeRuleModifyResponse'
import {TradingCalendarOffTimeRuleDeleteRequest} from './manager/TradingCalendarOffTimeRuleDeleteRequest'
import {TradingCalendarOffTimeRuleDeleteRequestModel} from './manager/TradingCalendarOffTimeRuleDeleteRequest'
import {TradingCalendarOffTimeRuleDeleteResponse} from './manager/TradingCalendarOffTimeRuleDeleteResponse'
import {TradingCalendarOffTimeRuleDeleteResponseModel} from './manager/TradingCalendarOffTimeRuleDeleteResponse'
import {TradingCalendarOffTimeRuleUpdateNotify} from './manager/TradingCalendarOffTimeRuleUpdateNotify'
import {TradingCalendarOffTimeRuleUpdateNotifyModel} from './manager/TradingCalendarOffTimeRuleUpdateNotify'
import {TradingCalendarRulesGetRequest} from './manager/TradingCalendarRulesGetRequest'
import {TradingCalendarRulesGetRequestModel} from './manager/TradingCalendarRulesGetRequest'
import {TradingCalendarRulesGetResponse} from './manager/TradingCalendarRulesGetResponse'
import {TradingCalendarRulesGetResponseModel} from './manager/TradingCalendarRulesGetResponse'
import {TradingRouteRulesGetRequest} from './manager/TradingRouteRulesGetRequest'
import {TradingRouteRulesGetRequestModel} from './manager/TradingRouteRulesGetRequest'
import {TradingRouteRulesGetResponse} from './manager/TradingRouteRulesGetResponse'
import {TradingRouteRulesGetResponseModel} from './manager/TradingRouteRulesGetResponse'
import {TradingRouteRuleCreateRequest} from './manager/TradingRouteRuleCreateRequest'
import {TradingRouteRuleCreateRequestModel} from './manager/TradingRouteRuleCreateRequest'
import {TradingRouteRuleCreateResponse} from './manager/TradingRouteRuleCreateResponse'
import {TradingRouteRuleCreateResponseModel} from './manager/TradingRouteRuleCreateResponse'
import {TradingRouteRuleModifyRequest} from './manager/TradingRouteRuleModifyRequest'
import {TradingRouteRuleModifyRequestModel} from './manager/TradingRouteRuleModifyRequest'
import {TradingRouteRuleModifyResponse} from './manager/TradingRouteRuleModifyResponse'
import {TradingRouteRuleModifyResponseModel} from './manager/TradingRouteRuleModifyResponse'
import {TradingRouteRuleDeleteRequest} from './manager/TradingRouteRuleDeleteRequest'
import {TradingRouteRuleDeleteRequestModel} from './manager/TradingRouteRuleDeleteRequest'
import {TradingRouteRuleDeleteResponse} from './manager/TradingRouteRuleDeleteResponse'
import {TradingRouteRuleDeleteResponseModel} from './manager/TradingRouteRuleDeleteResponse'
import {TradingRouteRuleUpdateNotify} from './manager/TradingRouteRuleUpdateNotify'
import {TradingRouteRuleUpdateNotifyModel} from './manager/TradingRouteRuleUpdateNotify'
import {SymbolMapsGetRequest} from './manager/SymbolMapsGetRequest'
import {SymbolMapsGetRequestModel} from './manager/SymbolMapsGetRequest'
import {SymbolMapsGetResponse} from './manager/SymbolMapsGetResponse'
import {SymbolMapsGetResponseModel} from './manager/SymbolMapsGetResponse'
import {SymbolMapCreateRequest} from './manager/SymbolMapCreateRequest'
import {SymbolMapCreateRequestModel} from './manager/SymbolMapCreateRequest'
import {SymbolMapCreateResponse} from './manager/SymbolMapCreateResponse'
import {SymbolMapCreateResponseModel} from './manager/SymbolMapCreateResponse'
import {SymbolMapModifyRequest} from './manager/SymbolMapModifyRequest'
import {SymbolMapModifyRequestModel} from './manager/SymbolMapModifyRequest'
import {SymbolMapModifyResponse} from './manager/SymbolMapModifyResponse'
import {SymbolMapModifyResponseModel} from './manager/SymbolMapModifyResponse'
import {SymbolMapDeleteRequest} from './manager/SymbolMapDeleteRequest'
import {SymbolMapDeleteRequestModel} from './manager/SymbolMapDeleteRequest'
import {SymbolMapDeleteResponse} from './manager/SymbolMapDeleteResponse'
import {SymbolMapDeleteResponseModel} from './manager/SymbolMapDeleteResponse'
import {SymbolMapUpdateNotify} from './manager/SymbolMapUpdateNotify'
import {SymbolMapUpdateNotifyModel} from './manager/SymbolMapUpdateNotify'
import {GatewaysGetRequest} from './manager/GatewaysGetRequest'
import {GatewaysGetRequestModel} from './manager/GatewaysGetRequest'
import {GatewaysGetResponse} from './manager/GatewaysGetResponse'
import {GatewaysGetResponseModel} from './manager/GatewaysGetResponse'
import {GatewayCreateRequest} from './manager/GatewayCreateRequest'
import {GatewayCreateRequestModel} from './manager/GatewayCreateRequest'
import {GatewayCreateResponse} from './manager/GatewayCreateResponse'
import {GatewayCreateResponseModel} from './manager/GatewayCreateResponse'
import {GatewayModifyRequest} from './manager/GatewayModifyRequest'
import {GatewayModifyRequestModel} from './manager/GatewayModifyRequest'
import {GatewayModifyResponse} from './manager/GatewayModifyResponse'
import {GatewayModifyResponseModel} from './manager/GatewayModifyResponse'
import {GatewayDeleteRequest} from './manager/GatewayDeleteRequest'
import {GatewayDeleteRequestModel} from './manager/GatewayDeleteRequest'
import {GatewayDeleteResponse} from './manager/GatewayDeleteResponse'
import {GatewayDeleteResponseModel} from './manager/GatewayDeleteResponse'
import {GatewayUpdateNotify} from './manager/GatewayUpdateNotify'
import {GatewayUpdateNotifyModel} from './manager/GatewayUpdateNotify'
import {DataFeedsGetRequest} from './manager/DataFeedsGetRequest'
import {DataFeedsGetRequestModel} from './manager/DataFeedsGetRequest'
import {DataFeedsGetResponse} from './manager/DataFeedsGetResponse'
import {DataFeedsGetResponseModel} from './manager/DataFeedsGetResponse'
import {DataFeedCreateRequest} from './manager/DataFeedCreateRequest'
import {DataFeedCreateRequestModel} from './manager/DataFeedCreateRequest'
import {DataFeedCreateResponse} from './manager/DataFeedCreateResponse'
import {DataFeedCreateResponseModel} from './manager/DataFeedCreateResponse'
import {DataFeedModifyRequest} from './manager/DataFeedModifyRequest'
import {DataFeedModifyRequestModel} from './manager/DataFeedModifyRequest'
import {DataFeedModifyResponse} from './manager/DataFeedModifyResponse'
import {DataFeedModifyResponseModel} from './manager/DataFeedModifyResponse'
import {DataFeedDeleteRequest} from './manager/DataFeedDeleteRequest'
import {DataFeedDeleteRequestModel} from './manager/DataFeedDeleteRequest'
import {DataFeedDeleteResponse} from './manager/DataFeedDeleteResponse'
import {DataFeedDeleteResponseModel} from './manager/DataFeedDeleteResponse'
import {DataFeedUpdateNotify} from './manager/DataFeedUpdateNotify'
import {DataFeedUpdateNotifyModel} from './manager/DataFeedUpdateNotify'
import {ModuleSystemInfoNotify} from './manager/ModuleSystemInfoNotify'
import {ModuleSystemInfoNotifyModel} from './manager/ModuleSystemInfoNotify'
import {ModulesSystemInfoGetAllRequest} from './manager/ModulesSystemInfoGetAllRequest'
import {ModulesSystemInfoGetAllRequestModel} from './manager/ModulesSystemInfoGetAllRequest'
import {ModulesSystemInfoGetAllResponse} from './manager/ModulesSystemInfoGetAllResponse'
import {ModulesSystemInfoGetAllResponseModel} from './manager/ModulesSystemInfoGetAllResponse'
import {ModulesSystemInfoNotify} from './manager/ModulesSystemInfoNotify'
import {ModulesSystemInfoNotifyModel} from './manager/ModulesSystemInfoNotify'
import {ChartBarHistoryModifyRequest} from './manager/ChartBarHistoryModifyRequest'
import {ChartBarHistoryModifyRequestModel} from './manager/ChartBarHistoryModifyRequest'
import {ChartBarHistoryModifyResponse} from './manager/ChartBarHistoryModifyResponse'
import {ChartBarHistoryModifyResponseModel} from './manager/ChartBarHistoryModifyResponse'
import {FeedUpdateTickSnapshotRequest} from './manager/FeedUpdateTickSnapshotRequest'
import {FeedUpdateTickSnapshotRequestModel} from './manager/FeedUpdateTickSnapshotRequest'
import {FeedUpdateTickSnapshotResponse} from './manager/FeedUpdateTickSnapshotResponse'
import {FeedUpdateTickSnapshotResponseModel} from './manager/FeedUpdateTickSnapshotResponse'
import {FeedUpdateTickRequest} from './manager/FeedUpdateTickRequest'
import {FeedUpdateTickRequestModel} from './manager/FeedUpdateTickRequest'
import {FeedUpdateTickResponse} from './manager/FeedUpdateTickResponse'
import {FeedUpdateTickResponseModel} from './manager/FeedUpdateTickResponse'
import {FeedUpdateLevel2SnapshotRequest} from './manager/FeedUpdateLevel2SnapshotRequest'
import {FeedUpdateLevel2SnapshotRequestModel} from './manager/FeedUpdateLevel2SnapshotRequest'
import {FeedUpdateLevel2SnapshotResponse} from './manager/FeedUpdateLevel2SnapshotResponse'
import {FeedUpdateLevel2SnapshotResponseModel} from './manager/FeedUpdateLevel2SnapshotResponse'
import {FeedUpdateLevel2Request} from './manager/FeedUpdateLevel2Request'
import {FeedUpdateLevel2RequestModel} from './manager/FeedUpdateLevel2Request'
import {FeedUpdateLevel2Response} from './manager/FeedUpdateLevel2Response'
import {FeedUpdateLevel2ResponseModel} from './manager/FeedUpdateLevel2Response'
import {MailServerGetRequest} from './manager/MailServerGetRequest'
import {MailServerGetRequestModel} from './manager/MailServerGetRequest'
import {MailServerGetResponse} from './manager/MailServerGetResponse'
import {MailServerGetResponseModel} from './manager/MailServerGetResponse'
import {MailServersGetAllRequest} from './manager/MailServersGetAllRequest'
import {MailServersGetAllRequestModel} from './manager/MailServersGetAllRequest'
import {MailServersGetAllResponse} from './manager/MailServersGetAllResponse'
import {MailServersGetAllResponseModel} from './manager/MailServersGetAllResponse'
import {MailServerCreateRequest} from './manager/MailServerCreateRequest'
import {MailServerCreateRequestModel} from './manager/MailServerCreateRequest'
import {MailServerCreateResponse} from './manager/MailServerCreateResponse'
import {MailServerCreateResponseModel} from './manager/MailServerCreateResponse'
import {MailServerModifyRequest} from './manager/MailServerModifyRequest'
import {MailServerModifyRequestModel} from './manager/MailServerModifyRequest'
import {MailServerModifyResponse} from './manager/MailServerModifyResponse'
import {MailServerModifyResponseModel} from './manager/MailServerModifyResponse'
import {MailServerDeleteRequest} from './manager/MailServerDeleteRequest'
import {MailServerDeleteRequestModel} from './manager/MailServerDeleteRequest'
import {MailServerDeleteResponse} from './manager/MailServerDeleteResponse'
import {MailServerDeleteResponseModel} from './manager/MailServerDeleteResponse'
import {MailServerUpdateNotify} from './manager/MailServerUpdateNotify'
import {MailServerUpdateNotifyModel} from './manager/MailServerUpdateNotify'
import {AccountStatesUpdateNotify} from './manager/AccountStatesUpdateNotify'
import {AccountStatesUpdateNotifyModel} from './manager/AccountStatesUpdateNotify'
import {AccountStatesGetAllRequest} from './manager/AccountStatesGetAllRequest'
import {AccountStatesGetAllRequestModel} from './manager/AccountStatesGetAllRequest'
import {AccountStatesGetAllResponse} from './manager/AccountStatesGetAllResponse'
import {AccountStatesGetAllResponseModel} from './manager/AccountStatesGetAllResponse'
import {ModuleAuthRequest} from './manager/ModuleAuthRequest'
import {ModuleAuthRequestModel} from './manager/ModuleAuthRequest'
import {ModuleAuthResponse} from './manager/ModuleAuthResponse'
import {ModuleAuthResponseModel} from './manager/ModuleAuthResponse'
import {LeverageProfileGetRequest} from './manager/LeverageProfileGetRequest'
import {LeverageProfileGetRequestModel} from './manager/LeverageProfileGetRequest'
import {LeverageProfileGetResponse} from './manager/LeverageProfileGetResponse'
import {LeverageProfileGetResponseModel} from './manager/LeverageProfileGetResponse'
import {LeverageProfilesGetAllRequest} from './manager/LeverageProfilesGetAllRequest'
import {LeverageProfilesGetAllRequestModel} from './manager/LeverageProfilesGetAllRequest'
import {LeverageProfilesGetAllResponse} from './manager/LeverageProfilesGetAllResponse'
import {LeverageProfilesGetAllResponseModel} from './manager/LeverageProfilesGetAllResponse'
import {LeverageProfileCreateRequest} from './manager/LeverageProfileCreateRequest'
import {LeverageProfileCreateRequestModel} from './manager/LeverageProfileCreateRequest'
import {LeverageProfileCreateResponse} from './manager/LeverageProfileCreateResponse'
import {LeverageProfileCreateResponseModel} from './manager/LeverageProfileCreateResponse'
import {LeverageProfileModifyRequest} from './manager/LeverageProfileModifyRequest'
import {LeverageProfileModifyRequestModel} from './manager/LeverageProfileModifyRequest'
import {LeverageProfileModifyResponse} from './manager/LeverageProfileModifyResponse'
import {LeverageProfileModifyResponseModel} from './manager/LeverageProfileModifyResponse'
import {LeverageProfileDeleteRequest} from './manager/LeverageProfileDeleteRequest'
import {LeverageProfileDeleteRequestModel} from './manager/LeverageProfileDeleteRequest'
import {LeverageProfileDeleteResponse} from './manager/LeverageProfileDeleteResponse'
import {LeverageProfileDeleteResponseModel} from './manager/LeverageProfileDeleteResponse'
import {LeverageProfileUpdateNotify} from './manager/LeverageProfileUpdateNotify'
import {LeverageProfileUpdateNotifyModel} from './manager/LeverageProfileUpdateNotify'
import {MarginRateProfileGetRequest} from './manager/MarginRateProfileGetRequest'
import {MarginRateProfileGetRequestModel} from './manager/MarginRateProfileGetRequest'
import {MarginRateProfileGetResponse} from './manager/MarginRateProfileGetResponse'
import {MarginRateProfileGetResponseModel} from './manager/MarginRateProfileGetResponse'
import {MarginRateProfilesGetAllRequest} from './manager/MarginRateProfilesGetAllRequest'
import {MarginRateProfilesGetAllRequestModel} from './manager/MarginRateProfilesGetAllRequest'
import {MarginRateProfilesGetAllResponse} from './manager/MarginRateProfilesGetAllResponse'
import {MarginRateProfilesGetAllResponseModel} from './manager/MarginRateProfilesGetAllResponse'
import {MarginRateProfileCreateRequest} from './manager/MarginRateProfileCreateRequest'
import {MarginRateProfileCreateRequestModel} from './manager/MarginRateProfileCreateRequest'
import {MarginRateProfileCreateResponse} from './manager/MarginRateProfileCreateResponse'
import {MarginRateProfileCreateResponseModel} from './manager/MarginRateProfileCreateResponse'
import {MarginRateProfileModifyRequest} from './manager/MarginRateProfileModifyRequest'
import {MarginRateProfileModifyRequestModel} from './manager/MarginRateProfileModifyRequest'
import {MarginRateProfileModifyResponse} from './manager/MarginRateProfileModifyResponse'
import {MarginRateProfileModifyResponseModel} from './manager/MarginRateProfileModifyResponse'
import {MarginRateProfileDeleteRequest} from './manager/MarginRateProfileDeleteRequest'
import {MarginRateProfileDeleteRequestModel} from './manager/MarginRateProfileDeleteRequest'
import {MarginRateProfileDeleteResponse} from './manager/MarginRateProfileDeleteResponse'
import {MarginRateProfileDeleteResponseModel} from './manager/MarginRateProfileDeleteResponse'
import {MarginRateProfileUpdateNotify} from './manager/MarginRateProfileUpdateNotify'
import {MarginRateProfileUpdateNotifyModel} from './manager/MarginRateProfileUpdateNotify'
import {NewsProfileGetRequest} from './manager/NewsProfileGetRequest'
import {NewsProfileGetRequestModel} from './manager/NewsProfileGetRequest'
import {NewsProfileGetResponse} from './manager/NewsProfileGetResponse'
import {NewsProfileGetResponseModel} from './manager/NewsProfileGetResponse'
import {NewsProfilesGetAllRequest} from './manager/NewsProfilesGetAllRequest'
import {NewsProfilesGetAllRequestModel} from './manager/NewsProfilesGetAllRequest'
import {NewsProfilesGetAllResponse} from './manager/NewsProfilesGetAllResponse'
import {NewsProfilesGetAllResponseModel} from './manager/NewsProfilesGetAllResponse'
import {NewsProfileCreateRequest} from './manager/NewsProfileCreateRequest'
import {NewsProfileCreateRequestModel} from './manager/NewsProfileCreateRequest'
import {NewsProfileCreateResponse} from './manager/NewsProfileCreateResponse'
import {NewsProfileCreateResponseModel} from './manager/NewsProfileCreateResponse'
import {NewsProfileModifyRequest} from './manager/NewsProfileModifyRequest'
import {NewsProfileModifyRequestModel} from './manager/NewsProfileModifyRequest'
import {NewsProfileModifyResponse} from './manager/NewsProfileModifyResponse'
import {NewsProfileModifyResponseModel} from './manager/NewsProfileModifyResponse'
import {NewsProfileDeleteRequest} from './manager/NewsProfileDeleteRequest'
import {NewsProfileDeleteRequestModel} from './manager/NewsProfileDeleteRequest'
import {NewsProfileDeleteResponse} from './manager/NewsProfileDeleteResponse'
import {NewsProfileDeleteResponseModel} from './manager/NewsProfileDeleteResponse'
import {NewsProfileUpdateNotify} from './manager/NewsProfileUpdateNotify'
import {NewsProfileUpdateNotifyModel} from './manager/NewsProfileUpdateNotify'
import {NewsProfileImportRequest} from './manager/NewsProfileImportRequest'
import {NewsProfileImportRequestModel} from './manager/NewsProfileImportRequest'
import {NewsProfileImportResponse} from './manager/NewsProfileImportResponse'
import {NewsProfileImportResponseModel} from './manager/NewsProfileImportResponse'
import {TickHistoryRequest} from './manager/TickHistoryRequest'
import {TickHistoryRequestModel} from './manager/TickHistoryRequest'
import {TickHistoryResponse} from './manager/TickHistoryResponse'
import {TickHistoryResponseModel} from './manager/TickHistoryResponse'
import {EmailTemplateGetRequest} from './manager/EmailTemplateGetRequest'
import {EmailTemplateGetRequestModel} from './manager/EmailTemplateGetRequest'
import {EmailTemplateGetResponse} from './manager/EmailTemplateGetResponse'
import {EmailTemplateGetResponseModel} from './manager/EmailTemplateGetResponse'
import {EmailTemplatesGetAllRequest} from './manager/EmailTemplatesGetAllRequest'
import {EmailTemplatesGetAllRequestModel} from './manager/EmailTemplatesGetAllRequest'
import {EmailTemplatesGetAllResponse} from './manager/EmailTemplatesGetAllResponse'
import {EmailTemplatesGetAllResponseModel} from './manager/EmailTemplatesGetAllResponse'
import {EmailTemplateCreateRequest} from './manager/EmailTemplateCreateRequest'
import {EmailTemplateCreateRequestModel} from './manager/EmailTemplateCreateRequest'
import {EmailTemplateCreateResponse} from './manager/EmailTemplateCreateResponse'
import {EmailTemplateCreateResponseModel} from './manager/EmailTemplateCreateResponse'
import {EmailTemplateModifyRequest} from './manager/EmailTemplateModifyRequest'
import {EmailTemplateModifyRequestModel} from './manager/EmailTemplateModifyRequest'
import {EmailTemplateModifyResponse} from './manager/EmailTemplateModifyResponse'
import {EmailTemplateModifyResponseModel} from './manager/EmailTemplateModifyResponse'
import {EmailTemplateDeleteRequest} from './manager/EmailTemplateDeleteRequest'
import {EmailTemplateDeleteRequestModel} from './manager/EmailTemplateDeleteRequest'
import {EmailTemplateDeleteResponse} from './manager/EmailTemplateDeleteResponse'
import {EmailTemplateDeleteResponseModel} from './manager/EmailTemplateDeleteResponse'
import {EmailTemplateUpdateNotify} from './manager/EmailTemplateUpdateNotify'
import {EmailTemplateUpdateNotifyModel} from './manager/EmailTemplateUpdateNotify'
import {OrderStateSubscribeRequest} from './manager/OrderStateSubscribeRequest'
import {OrderStateSubscribeRequestModel} from './manager/OrderStateSubscribeRequest'
import {OrderStateSubscribeResponse} from './manager/OrderStateSubscribeResponse'
import {OrderStateSubscribeResponseModel} from './manager/OrderStateSubscribeResponse'
import {OrderStateUpdateSubscriptionRequest} from './manager/OrderStateUpdateSubscriptionRequest'
import {OrderStateUpdateSubscriptionRequestModel} from './manager/OrderStateUpdateSubscriptionRequest'
import {OrderStateUpdateSubscriptionResponse} from './manager/OrderStateUpdateSubscriptionResponse'
import {OrderStateUpdateSubscriptionResponseModel} from './manager/OrderStateUpdateSubscriptionResponse'
import {OrderStateUnsubscribeRequest} from './manager/OrderStateUnsubscribeRequest'
import {OrderStateUnsubscribeRequestModel} from './manager/OrderStateUnsubscribeRequest'
import {OrderStateUnsubscribeResponse} from './manager/OrderStateUnsubscribeResponse'
import {OrderStateUnsubscribeResponseModel} from './manager/OrderStateUnsubscribeResponse'
import {OrderStateNotify} from './manager/OrderStateNotify'
import {OrderStateNotifyModel} from './manager/OrderStateNotify'
import {RequestMonitoringRequest} from './manager/RequestMonitoringRequest'
import {RequestMonitoringRequestModel} from './manager/RequestMonitoringRequest'
import {RequestMonitoringSubscribeRequest} from './manager/RequestMonitoringSubscribeRequest'
import {RequestMonitoringSubscribeRequestModel} from './manager/RequestMonitoringSubscribeRequest'
import {RequestMonitoringSubscribeResponse} from './manager/RequestMonitoringSubscribeResponse'
import {RequestMonitoringSubscribeResponseModel} from './manager/RequestMonitoringSubscribeResponse'
import {RequestMonitoringUnsubscribeRequest} from './manager/RequestMonitoringUnsubscribeRequest'
import {RequestMonitoringUnsubscribeRequestModel} from './manager/RequestMonitoringUnsubscribeRequest'
import {RequestMonitoringUnsubscribeResponse} from './manager/RequestMonitoringUnsubscribeResponse'
import {RequestMonitoringUnsubscribeResponseModel} from './manager/RequestMonitoringUnsubscribeResponse'
import {RequestMonitoringRequestUpdateNotify} from './manager/RequestMonitoringRequestUpdateNotify'
import {RequestMonitoringRequestUpdateNotifyModel} from './manager/RequestMonitoringRequestUpdateNotify'
import {FilledOrdersSummaryInfoBySymbol} from './manager/FilledOrdersSummaryInfoBySymbol'
import {FilledOrdersSummaryInfoBySymbolModel} from './manager/FilledOrdersSummaryInfoBySymbol'
import {FilledOrdersSummaryInfo} from './manager/FilledOrdersSummaryInfo'
import {FilledOrdersSummaryInfoModel} from './manager/FilledOrdersSummaryInfo'
import {FilledOrdersSummaryInfoSubscribeRequest} from './manager/FilledOrdersSummaryInfoSubscribeRequest'
import {FilledOrdersSummaryInfoSubscribeRequestModel} from './manager/FilledOrdersSummaryInfoSubscribeRequest'
import {FilledOrdersSummaryInfoSubscribeResponse} from './manager/FilledOrdersSummaryInfoSubscribeResponse'
import {FilledOrdersSummaryInfoSubscribeResponseModel} from './manager/FilledOrdersSummaryInfoSubscribeResponse'
import {FilledOrdersSummaryInfoNotify} from './manager/FilledOrdersSummaryInfoNotify'
import {FilledOrdersSummaryInfoNotifyModel} from './manager/FilledOrdersSummaryInfoNotify'
import {FilledOrdersSummaryInfoUpdateSubscriptionRequest} from './manager/FilledOrdersSummaryInfoUpdateSubscriptionRequest'
import {FilledOrdersSummaryInfoUpdateSubscriptionRequestModel} from './manager/FilledOrdersSummaryInfoUpdateSubscriptionRequest'
import {FilledOrdersSummaryInfoUnsubscribeRequest} from './manager/FilledOrdersSummaryInfoUnsubscribeRequest'
import {FilledOrdersSummaryInfoUnsubscribeRequestModel} from './manager/FilledOrdersSummaryInfoUnsubscribeRequest'
import {FilledOrdersSummaryInfoUnsubscribeResponse} from './manager/FilledOrdersSummaryInfoUnsubscribeResponse'
import {FilledOrdersSummaryInfoUnsubscribeResponseModel} from './manager/FilledOrdersSummaryInfoUnsubscribeResponse'
import {OrderStable} from './manager/OrderStable'
import {OrderStableModel} from './manager/OrderStable'
import {AccountMarginStable} from './manager/AccountMarginStable'
import {AccountMarginStableModel} from './manager/AccountMarginStable'
import {OrderSnapshot} from './manager/OrderSnapshot'
import {OrderSnapshotModel} from './manager/OrderSnapshot'
import {AccountMarginSnapshot} from './manager/AccountMarginSnapshot'
import {AccountMarginSnapshotModel} from './manager/AccountMarginSnapshot'
import {TickSnapshot} from './manager/TickSnapshot'
import {TickSnapshotModel} from './manager/TickSnapshot'
import {TransactionDuration} from './manager/TransactionDuration'
import {TransactionDurationModel} from './manager/TransactionDuration'
import {OrderEvent} from './manager/OrderEvent'
import {OrderEventModel} from './manager/OrderEvent'
import {TradeEvent} from './manager/TradeEvent'
import {TradeEventModel} from './manager/TradeEvent'
import {TradeEventsNotify} from './manager/TradeEventsNotify'
import {TradeEventsNotifyModel} from './manager/TradeEventsNotify'
import {TradeEventsGetRequest} from './manager/TradeEventsGetRequest'
import {TradeEventsGetRequestModel} from './manager/TradeEventsGetRequest'
import {TradeEventsGetResponse} from './manager/TradeEventsGetResponse'
import {TradeEventsGetResponseModel} from './manager/TradeEventsGetResponse'
import {JournalRequest} from './manager/JournalRequest'
import {JournalRequestModel} from './manager/JournalRequest'
import {JournalResponse} from './manager/JournalResponse'
import {JournalResponseModel} from './manager/JournalResponse'
import {ClientReportsListGetRequest} from './manager/ClientReportsListGetRequest'
import {ClientReportsListGetRequestModel} from './manager/ClientReportsListGetRequest'
import {ClientReportsListGetResponse} from './manager/ClientReportsListGetResponse'
import {ClientReportsListGetResponseModel} from './manager/ClientReportsListGetResponse'
import {ClientReportGetRequest} from './manager/ClientReportGetRequest'
import {ClientReportGetRequestModel} from './manager/ClientReportGetRequest'
import {ClientReportGetResponse} from './manager/ClientReportGetResponse'
import {ClientReportGetResponseModel} from './manager/ClientReportGetResponse'
import {ClientReportsSendRequest} from './manager/ClientReportsSendRequest'
import {ClientReportsSendRequestModel} from './manager/ClientReportsSendRequest'
import {ClientReportsSendResponse} from './manager/ClientReportsSendResponse'
import {ClientReportsSendResponseModel} from './manager/ClientReportsSendResponse'
import {ProfitClosedGetRequest} from './manager/ProfitClosedGetRequest'
import {ProfitClosedGetRequestModel} from './manager/ProfitClosedGetRequest'
import {ProfitClosedGetResponse} from './manager/ProfitClosedGetResponse'
import {ProfitClosedGetResponseModel} from './manager/ProfitClosedGetResponse'
import {ChartBarsImportRequest} from './manager/ChartBarsImportRequest'
import {ChartBarsImportRequestModel} from './manager/ChartBarsImportRequest'
import {ChartBarsImportResponse} from './manager/ChartBarsImportResponse'
import {ChartBarsImportResponseModel} from './manager/ChartBarsImportResponse'
import {ChartBarsImportNotify} from './manager/ChartBarsImportNotify'
import {ChartBarsImportNotifyModel} from './manager/ChartBarsImportNotify'
import {JournalBusinessRequest} from './manager/JournalBusinessRequest'
import {JournalBusinessRequestModel} from './manager/JournalBusinessRequest'
import {JournalBusinessResponse} from './manager/JournalBusinessResponse'
import {JournalBusinessResponseModel} from './manager/JournalBusinessResponse'
import {AccountsGetPageRequest} from './manager/AccountsGetPageRequest'
import {AccountsGetPageRequestModel} from './manager/AccountsGetPageRequest'
import {AccountsGetPageResponse} from './manager/AccountsGetPageResponse'
import {AccountsGetPageResponseModel} from './manager/AccountsGetPageResponse'
import {HeartbeatNotify} from './manager/HeartbeatNotify'
import {HeartbeatNotifyModel} from './manager/HeartbeatNotify'
import {OrdersClosedSummariesGetRequest} from './manager/OrdersClosedSummariesGetRequest'
import {OrdersClosedSummariesGetRequestModel} from './manager/OrdersClosedSummariesGetRequest'
import {OrdersClosedSummariesGetResponse} from './manager/OrdersClosedSummariesGetResponse'
import {OrdersClosedSummariesGetResponseModel} from './manager/OrdersClosedSummariesGetResponse'
import {OrdersGetPageRequest} from './manager/OrdersGetPageRequest'
import {OrdersGetPageRequestModel} from './manager/OrdersGetPageRequest'
import {OrdersGetPageResponse} from './manager/OrdersGetPageResponse'
import {OrdersGetPageResponseModel} from './manager/OrdersGetPageResponse'
import {StorageUniversalNotify} from './manager/StorageUniversalNotify'
import {StorageUniversalNotifyModel} from './manager/StorageUniversalNotify'
import {ClientLoginInfoGetRequest} from './manager/ClientLoginInfoGetRequest'
import {ClientLoginInfoGetRequestModel} from './manager/ClientLoginInfoGetRequest'
import {ClientLoginInfoGetResponse} from './manager/ClientLoginInfoGetResponse'
import {ClientLoginInfoGetResponseModel} from './manager/ClientLoginInfoGetResponse'
import {AccountGroupExistsGetRequest} from './manager/AccountGroupExistsGetRequest'
import {AccountGroupExistsGetRequestModel} from './manager/AccountGroupExistsGetRequest'
import {AccountGroupExistsGetResponse} from './manager/AccountGroupExistsGetResponse'
import {AccountGroupExistsGetResponseModel} from './manager/AccountGroupExistsGetResponse'
import {LoginWithPasswordRequest} from './manager/LoginWithPasswordRequest'
import {LoginWithPasswordRequestModel} from './manager/LoginWithPasswordRequest'
import {LoginWithPasswordResponse} from './manager/LoginWithPasswordResponse'
import {LoginWithPasswordResponseModel} from './manager/LoginWithPasswordResponse'
import {LoginWithTokenRequest} from './manager/LoginWithTokenRequest'
import {LoginWithTokenRequestModel} from './manager/LoginWithTokenRequest'
import {LoginWithTokenResponse} from './manager/LoginWithTokenResponse'
import {LoginWithTokenResponseModel} from './manager/LoginWithTokenResponse'
import {RefreshTokenRequest} from './manager/RefreshTokenRequest'
import {RefreshTokenRequestModel} from './manager/RefreshTokenRequest'
import {RefreshTokenResponse} from './manager/RefreshTokenResponse'
import {RefreshTokenResponseModel} from './manager/RefreshTokenResponse'
import {Person} from './manager/Person'
import {PersonModel} from './manager/Person'
import {PersonGetRequest} from './manager/PersonGetRequest'
import {PersonGetRequestModel} from './manager/PersonGetRequest'
import {PersonGetResponse} from './manager/PersonGetResponse'
import {PersonGetResponseModel} from './manager/PersonGetResponse'
import {PersonsGetAllRequest} from './manager/PersonsGetAllRequest'
import {PersonsGetAllRequestModel} from './manager/PersonsGetAllRequest'
import {PersonsGetAllResponse} from './manager/PersonsGetAllResponse'
import {PersonsGetAllResponseModel} from './manager/PersonsGetAllResponse'
import {PersonCreateRequest} from './manager/PersonCreateRequest'
import {PersonCreateRequestModel} from './manager/PersonCreateRequest'
import {PersonCreateResponse} from './manager/PersonCreateResponse'
import {PersonCreateResponseModel} from './manager/PersonCreateResponse'
import {PersonModifyRequest} from './manager/PersonModifyRequest'
import {PersonModifyRequestModel} from './manager/PersonModifyRequest'
import {PersonModifyResponse} from './manager/PersonModifyResponse'
import {PersonModifyResponseModel} from './manager/PersonModifyResponse'
import {PersonUpdateNotify} from './manager/PersonUpdateNotify'
import {PersonUpdateNotifyModel} from './manager/PersonUpdateNotify'
import {SecuritySettingsGetRequest} from './manager/SecuritySettingsGetRequest'
import {SecuritySettingsGetRequestModel} from './manager/SecuritySettingsGetRequest'
import {SecuritySettingsGetResponse} from './manager/SecuritySettingsGetResponse'
import {SecuritySettingsGetResponseModel} from './manager/SecuritySettingsGetResponse'
import {SecuritySettingsGetAllRequest} from './manager/SecuritySettingsGetAllRequest'
import {SecuritySettingsGetAllRequestModel} from './manager/SecuritySettingsGetAllRequest'
import {SecuritySettingsGetAllResponse} from './manager/SecuritySettingsGetAllResponse'
import {SecuritySettingsGetAllResponseModel} from './manager/SecuritySettingsGetAllResponse'
import {SecuritySettingsCreateRequest} from './manager/SecuritySettingsCreateRequest'
import {SecuritySettingsCreateRequestModel} from './manager/SecuritySettingsCreateRequest'
import {SecuritySettingsCreateResponse} from './manager/SecuritySettingsCreateResponse'
import {SecuritySettingsCreateResponseModel} from './manager/SecuritySettingsCreateResponse'
import {SecuritySettingsModifyRequest} from './manager/SecuritySettingsModifyRequest'
import {SecuritySettingsModifyRequestModel} from './manager/SecuritySettingsModifyRequest'
import {SecuritySettingsModifyResponse} from './manager/SecuritySettingsModifyResponse'
import {SecuritySettingsModifyResponseModel} from './manager/SecuritySettingsModifyResponse'
import {SecuritySettingsDeleteRequest} from './manager/SecuritySettingsDeleteRequest'
import {SecuritySettingsDeleteRequestModel} from './manager/SecuritySettingsDeleteRequest'
import {SecuritySettingsDeleteResponse} from './manager/SecuritySettingsDeleteResponse'
import {SecuritySettingsDeleteResponseModel} from './manager/SecuritySettingsDeleteResponse'
import {SecuritySettingsUpdateNotify} from './manager/SecuritySettingsUpdateNotify'
import {SecuritySettingsUpdateNotifyModel} from './manager/SecuritySettingsUpdateNotify'
import {FirewallRuleGetRequest} from './manager/FirewallRuleGetRequest'
import {FirewallRuleGetRequestModel} from './manager/FirewallRuleGetRequest'
import {FirewallRuleGetResponse} from './manager/FirewallRuleGetResponse'
import {FirewallRuleGetResponseModel} from './manager/FirewallRuleGetResponse'
import {FirewallRulesGetAllRequest} from './manager/FirewallRulesGetAllRequest'
import {FirewallRulesGetAllRequestModel} from './manager/FirewallRulesGetAllRequest'
import {FirewallRulesGetAllResponse} from './manager/FirewallRulesGetAllResponse'
import {FirewallRulesGetAllResponseModel} from './manager/FirewallRulesGetAllResponse'
import {FirewallRuleCreateRequest} from './manager/FirewallRuleCreateRequest'
import {FirewallRuleCreateRequestModel} from './manager/FirewallRuleCreateRequest'
import {FirewallRuleCreateResponse} from './manager/FirewallRuleCreateResponse'
import {FirewallRuleCreateResponseModel} from './manager/FirewallRuleCreateResponse'
import {FirewallRuleModifyRequest} from './manager/FirewallRuleModifyRequest'
import {FirewallRuleModifyRequestModel} from './manager/FirewallRuleModifyRequest'
import {FirewallRuleModifyResponse} from './manager/FirewallRuleModifyResponse'
import {FirewallRuleModifyResponseModel} from './manager/FirewallRuleModifyResponse'
import {FirewallRuleDeleteRequest} from './manager/FirewallRuleDeleteRequest'
import {FirewallRuleDeleteRequestModel} from './manager/FirewallRuleDeleteRequest'
import {FirewallRuleDeleteResponse} from './manager/FirewallRuleDeleteResponse'
import {FirewallRuleDeleteResponseModel} from './manager/FirewallRuleDeleteResponse'
import {FirewallRuleUpdateNotify} from './manager/FirewallRuleUpdateNotify'
import {FirewallRuleUpdateNotifyModel} from './manager/FirewallRuleUpdateNotify'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding manager sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize manager sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._configSender = new config.Sender(this.buffer)
    this._historySender = new history.Sender(this.buffer)
    this._clientSender = new client.Sender(this.buffer)
    this._feedSender = new feed.Sender(this.buffer)
    this._storageSender = new storage.Sender(this.buffer)
    this._exposedSender = new exposed.Sender(this.buffer)
    this._executionSender = new execution.Sender(this.buffer)
    this._backupSender = new backup.Sender(this.buffer)
    this._innerSender = new inner.Sender(this.buffer)
    this._tradeSender = new trade.Sender(this.buffer)
    this._PingModuleRequestModel = new PingModuleRequestModel(this.buffer)
    this._PongModuleResponseModel = new PongModuleResponseModel(this.buffer)
    this._PingModuleRejectModel = new PingModuleRejectModel(this.buffer)
    this._Deprecated3Model = new Deprecated3Model(this.buffer)
    this._Deprecated4Model = new Deprecated4Model(this.buffer)
    this._Deprecated1Model = new Deprecated1Model(this.buffer)
    this._Deprecated2Model = new Deprecated2Model(this.buffer)
    this._ManagerSessionInfoRequestModel = new ManagerSessionInfoRequestModel(this.buffer)
    this._ManagerSessionInfoResponseModel = new ManagerSessionInfoResponseModel(this.buffer)
    this._OnlineSessionsRequestModel = new OnlineSessionsRequestModel(this.buffer)
    this._OnlineSessionsResponseModel = new OnlineSessionsResponseModel(this.buffer)
    this._DropSessionRequestModel = new DropSessionRequestModel(this.buffer)
    this._DropSessionResponseModel = new DropSessionResponseModel(this.buffer)
    this._ConfigRequestModel = new ConfigRequestModel(this.buffer)
    this._ConfigResponseModel = new ConfigResponseModel(this.buffer)
    this._ConfigRejectModel = new ConfigRejectModel(this.buffer)
    this._ConfigUpdateRequestModel = new ConfigUpdateRequestModel(this.buffer)
    this._ConfigUpdateResponseModel = new ConfigUpdateResponseModel(this.buffer)
    this._ConfigUpdateNotifyModel = new ConfigUpdateNotifyModel(this.buffer)
    this._ConfigVersionRequestModel = new ConfigVersionRequestModel(this.buffer)
    this._ConfigVersionResponseModel = new ConfigVersionResponseModel(this.buffer)
    this._ConfigExportRequestModel = new ConfigExportRequestModel(this.buffer)
    this._ConfigExportResponseModel = new ConfigExportResponseModel(this.buffer)
    this._ConfigImportRequestModel = new ConfigImportRequestModel(this.buffer)
    this._ConfigImportResponseModel = new ConfigImportResponseModel(this.buffer)
    this._ConfigValidateRequestModel = new ConfigValidateRequestModel(this.buffer)
    this._ConfigValidateResponseModel = new ConfigValidateResponseModel(this.buffer)
    this._ConfigRefreshRequestModel = new ConfigRefreshRequestModel(this.buffer)
    this._ConfigRefreshResponseModel = new ConfigRefreshResponseModel(this.buffer)
    this._ConfigHistoryRequestModel = new ConfigHistoryRequestModel(this.buffer)
    this._ConfigHistoryResponseModel = new ConfigHistoryResponseModel(this.buffer)
    this._ConfigRollbackRequestModel = new ConfigRollbackRequestModel(this.buffer)
    this._ConfigRollbackResponseModel = new ConfigRollbackResponseModel(this.buffer)
    this._HostGetRequestModel = new HostGetRequestModel(this.buffer)
    this._HostGetResponseModel = new HostGetResponseModel(this.buffer)
    this._HostsGetAllRequestModel = new HostsGetAllRequestModel(this.buffer)
    this._HostsGetAllResponseModel = new HostsGetAllResponseModel(this.buffer)
    this._HostCreateRequestModel = new HostCreateRequestModel(this.buffer)
    this._HostCreateResponseModel = new HostCreateResponseModel(this.buffer)
    this._HostModifyRequestModel = new HostModifyRequestModel(this.buffer)
    this._HostModifyResponseModel = new HostModifyResponseModel(this.buffer)
    this._HostDeleteRequestModel = new HostDeleteRequestModel(this.buffer)
    this._HostDeleteResponseModel = new HostDeleteResponseModel(this.buffer)
    this._HostUpdateNotifyModel = new HostUpdateNotifyModel(this.buffer)
    this._ModuleGetRequestModel = new ModuleGetRequestModel(this.buffer)
    this._ModuleGetResponseModel = new ModuleGetResponseModel(this.buffer)
    this._ModulesGetAllRequestModel = new ModulesGetAllRequestModel(this.buffer)
    this._ModulesGetAllResponseModel = new ModulesGetAllResponseModel(this.buffer)
    this._ModuleCreateRequestModel = new ModuleCreateRequestModel(this.buffer)
    this._ModuleCreateResponseModel = new ModuleCreateResponseModel(this.buffer)
    this._ModuleModifyRequestModel = new ModuleModifyRequestModel(this.buffer)
    this._ModuleModifyResponseModel = new ModuleModifyResponseModel(this.buffer)
    this._ModuleDeleteRequestModel = new ModuleDeleteRequestModel(this.buffer)
    this._ModuleDeleteResponseModel = new ModuleDeleteResponseModel(this.buffer)
    this._ModuleUpdateNotifyModel = new ModuleUpdateNotifyModel(this.buffer)
    this._ServerTimeGetRequestModel = new ServerTimeGetRequestModel(this.buffer)
    this._ServerTimeGetResponseModel = new ServerTimeGetResponseModel(this.buffer)
    this._ManagerPlatformTimezoneGetRequestModel = new ManagerPlatformTimezoneGetRequestModel(this.buffer)
    this._ManagerPlatformTimezoneGetResponseModel = new ManagerPlatformTimezoneGetResponseModel(this.buffer)
    this._TimezoneGetRequestModel = new TimezoneGetRequestModel(this.buffer)
    this._TimezoneGetResponseModel = new TimezoneGetResponseModel(this.buffer)
    this._TimezonesGetAllRequestModel = new TimezonesGetAllRequestModel(this.buffer)
    this._TimezonesGetAllResponseModel = new TimezonesGetAllResponseModel(this.buffer)
    this._TimezoneCreateRequestModel = new TimezoneCreateRequestModel(this.buffer)
    this._TimezoneCreateResponseModel = new TimezoneCreateResponseModel(this.buffer)
    this._TimezoneModifyRequestModel = new TimezoneModifyRequestModel(this.buffer)
    this._TimezoneModifyResponseModel = new TimezoneModifyResponseModel(this.buffer)
    this._TimezoneDeleteRequestModel = new TimezoneDeleteRequestModel(this.buffer)
    this._TimezoneDeleteResponseModel = new TimezoneDeleteResponseModel(this.buffer)
    this._TimezoneUpdateNotifyModel = new TimezoneUpdateNotifyModel(this.buffer)
    this._CurrencyGroupGetRequestModel = new CurrencyGroupGetRequestModel(this.buffer)
    this._CurrencyGroupGetResponseModel = new CurrencyGroupGetResponseModel(this.buffer)
    this._CurrencyGroupsGetAllRequestModel = new CurrencyGroupsGetAllRequestModel(this.buffer)
    this._CurrencyGroupsGetAllResponseModel = new CurrencyGroupsGetAllResponseModel(this.buffer)
    this._CurrencyGroupCreateRequestModel = new CurrencyGroupCreateRequestModel(this.buffer)
    this._CurrencyGroupCreateResponseModel = new CurrencyGroupCreateResponseModel(this.buffer)
    this._CurrencyGroupModifyRequestModel = new CurrencyGroupModifyRequestModel(this.buffer)
    this._CurrencyGroupModifyResponseModel = new CurrencyGroupModifyResponseModel(this.buffer)
    this._CurrencyGroupDeleteRequestModel = new CurrencyGroupDeleteRequestModel(this.buffer)
    this._CurrencyGroupDeleteResponseModel = new CurrencyGroupDeleteResponseModel(this.buffer)
    this._CurrencyGroupUpdateNotifyModel = new CurrencyGroupUpdateNotifyModel(this.buffer)
    this._CurrencyGetRequestModel = new CurrencyGetRequestModel(this.buffer)
    this._CurrencyGetResponseModel = new CurrencyGetResponseModel(this.buffer)
    this._CurrenciesGetAllRequestModel = new CurrenciesGetAllRequestModel(this.buffer)
    this._CurrenciesGetAllResponseModel = new CurrenciesGetAllResponseModel(this.buffer)
    this._CurrencyCreateRequestModel = new CurrencyCreateRequestModel(this.buffer)
    this._CurrencyCreateResponseModel = new CurrencyCreateResponseModel(this.buffer)
    this._CurrencyModifyRequestModel = new CurrencyModifyRequestModel(this.buffer)
    this._CurrencyModifyResponseModel = new CurrencyModifyResponseModel(this.buffer)
    this._CurrencyDeleteRequestModel = new CurrencyDeleteRequestModel(this.buffer)
    this._CurrencyDeleteResponseModel = new CurrencyDeleteResponseModel(this.buffer)
    this._CurrencyUpdateNotifyModel = new CurrencyUpdateNotifyModel(this.buffer)
    this._SymbolGroupGetRequestModel = new SymbolGroupGetRequestModel(this.buffer)
    this._SymbolGroupGetResponseModel = new SymbolGroupGetResponseModel(this.buffer)
    this._SymbolGroupsGetAllRequestModel = new SymbolGroupsGetAllRequestModel(this.buffer)
    this._SymbolGroupsGetAllResponseModel = new SymbolGroupsGetAllResponseModel(this.buffer)
    this._SymbolGroupCreateRequestModel = new SymbolGroupCreateRequestModel(this.buffer)
    this._SymbolGroupCreateResponseModel = new SymbolGroupCreateResponseModel(this.buffer)
    this._SymbolGroupModifyRequestModel = new SymbolGroupModifyRequestModel(this.buffer)
    this._SymbolGroupModifyResponseModel = new SymbolGroupModifyResponseModel(this.buffer)
    this._SymbolGroupDeleteRequestModel = new SymbolGroupDeleteRequestModel(this.buffer)
    this._SymbolGroupDeleteResponseModel = new SymbolGroupDeleteResponseModel(this.buffer)
    this._SymbolGroupUpdateNotifyModel = new SymbolGroupUpdateNotifyModel(this.buffer)
    this._SymbolGetRequestModel = new SymbolGetRequestModel(this.buffer)
    this._SymbolGetResponseModel = new SymbolGetResponseModel(this.buffer)
    this._SymbolsGetAllRequestModel = new SymbolsGetAllRequestModel(this.buffer)
    this._SymbolsGetAllResponseModel = new SymbolsGetAllResponseModel(this.buffer)
    this._SymbolCreateRequestModel = new SymbolCreateRequestModel(this.buffer)
    this._SymbolCreateResponseModel = new SymbolCreateResponseModel(this.buffer)
    this._SymbolModifyRequestModel = new SymbolModifyRequestModel(this.buffer)
    this._SymbolModifyResponseModel = new SymbolModifyResponseModel(this.buffer)
    this._SymbolDeleteRequestModel = new SymbolDeleteRequestModel(this.buffer)
    this._SymbolDeleteResponseModel = new SymbolDeleteResponseModel(this.buffer)
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel(this.buffer)
    this._SwapsExportRequestModel = new SwapsExportRequestModel(this.buffer)
    this._SwapsExportResponseModel = new SwapsExportResponseModel(this.buffer)
    this._SwapsImportRequestModel = new SwapsImportRequestModel(this.buffer)
    this._SwapsImportResponseModel = new SwapsImportResponseModel(this.buffer)
    this._CommissionProfileGetRequestModel = new CommissionProfileGetRequestModel(this.buffer)
    this._CommissionProfileGetResponseModel = new CommissionProfileGetResponseModel(this.buffer)
    this._CommissionProfilesGetAllRequestModel = new CommissionProfilesGetAllRequestModel(this.buffer)
    this._CommissionProfilesGetAllResponseModel = new CommissionProfilesGetAllResponseModel(this.buffer)
    this._CommissionProfileCreateRequestModel = new CommissionProfileCreateRequestModel(this.buffer)
    this._CommissionProfileCreateResponseModel = new CommissionProfileCreateResponseModel(this.buffer)
    this._CommissionProfileModifyRequestModel = new CommissionProfileModifyRequestModel(this.buffer)
    this._CommissionProfileModifyResponseModel = new CommissionProfileModifyResponseModel(this.buffer)
    this._CommissionProfileDeleteRequestModel = new CommissionProfileDeleteRequestModel(this.buffer)
    this._CommissionProfileDeleteResponseModel = new CommissionProfileDeleteResponseModel(this.buffer)
    this._CommissionProfileUpdateNotifyModel = new CommissionProfileUpdateNotifyModel(this.buffer)
    this._MarkupProfileGetRequestModel = new MarkupProfileGetRequestModel(this.buffer)
    this._MarkupProfileGetResponseModel = new MarkupProfileGetResponseModel(this.buffer)
    this._MarkupProfilesGetAllRequestModel = new MarkupProfilesGetAllRequestModel(this.buffer)
    this._MarkupProfilesGetAllResponseModel = new MarkupProfilesGetAllResponseModel(this.buffer)
    this._MarkupProfileCreateRequestModel = new MarkupProfileCreateRequestModel(this.buffer)
    this._MarkupProfileCreateResponseModel = new MarkupProfileCreateResponseModel(this.buffer)
    this._MarkupProfileModifyRequestModel = new MarkupProfileModifyRequestModel(this.buffer)
    this._MarkupProfileModifyResponseModel = new MarkupProfileModifyResponseModel(this.buffer)
    this._MarkupProfileDeleteRequestModel = new MarkupProfileDeleteRequestModel(this.buffer)
    this._MarkupProfileDeleteResponseModel = new MarkupProfileDeleteResponseModel(this.buffer)
    this._MarkupProfileUpdateNotifyModel = new MarkupProfileUpdateNotifyModel(this.buffer)
    this._ManagerGroupGetRequestModel = new ManagerGroupGetRequestModel(this.buffer)
    this._ManagerGroupGetResponseModel = new ManagerGroupGetResponseModel(this.buffer)
    this._ManagerGroupsGetAllRequestModel = new ManagerGroupsGetAllRequestModel(this.buffer)
    this._ManagerGroupsGetAllResponseModel = new ManagerGroupsGetAllResponseModel(this.buffer)
    this._ManagerGroupCreateRequestModel = new ManagerGroupCreateRequestModel(this.buffer)
    this._ManagerGroupCreateResponseModel = new ManagerGroupCreateResponseModel(this.buffer)
    this._ManagerGroupModifyRequestModel = new ManagerGroupModifyRequestModel(this.buffer)
    this._ManagerGroupModifyResponseModel = new ManagerGroupModifyResponseModel(this.buffer)
    this._ManagerGroupDeleteRequestModel = new ManagerGroupDeleteRequestModel(this.buffer)
    this._ManagerGroupDeleteResponseModel = new ManagerGroupDeleteResponseModel(this.buffer)
    this._ManagerGroupUpdateNotifyModel = new ManagerGroupUpdateNotifyModel(this.buffer)
    this._ManagerGetRequestModel = new ManagerGetRequestModel(this.buffer)
    this._ManagerGetResponseModel = new ManagerGetResponseModel(this.buffer)
    this._ManagersGetAllRequestModel = new ManagersGetAllRequestModel(this.buffer)
    this._ManagersGetAllResponseModel = new ManagersGetAllResponseModel(this.buffer)
    this._ManagerCreateRequestModel = new ManagerCreateRequestModel(this.buffer)
    this._ManagerCreateResponseModel = new ManagerCreateResponseModel(this.buffer)
    this._ManagerModifyRequestModel = new ManagerModifyRequestModel(this.buffer)
    this._ManagerModifyResponseModel = new ManagerModifyResponseModel(this.buffer)
    this._ManagerDeleteRequestModel = new ManagerDeleteRequestModel(this.buffer)
    this._ManagerDeleteResponseModel = new ManagerDeleteResponseModel(this.buffer)
    this._ManagerUpdateNotifyModel = new ManagerUpdateNotifyModel(this.buffer)
    this._PlatformGetRequestModel = new PlatformGetRequestModel(this.buffer)
    this._PlatformGetResponseModel = new PlatformGetResponseModel(this.buffer)
    this._PlatformsGetAllRequestModel = new PlatformsGetAllRequestModel(this.buffer)
    this._PlatformsGetAllResponseModel = new PlatformsGetAllResponseModel(this.buffer)
    this._PlatformCreateRequestModel = new PlatformCreateRequestModel(this.buffer)
    this._PlatformCreateResponseModel = new PlatformCreateResponseModel(this.buffer)
    this._PlatformModifyRequestModel = new PlatformModifyRequestModel(this.buffer)
    this._PlatformModifyResponseModel = new PlatformModifyResponseModel(this.buffer)
    this._PlatformDeleteRequestModel = new PlatformDeleteRequestModel(this.buffer)
    this._PlatformDeleteResponseModel = new PlatformDeleteResponseModel(this.buffer)
    this._PlatformUpdateNotifyModel = new PlatformUpdateNotifyModel(this.buffer)
    this._AccountGroupGetRequestModel = new AccountGroupGetRequestModel(this.buffer)
    this._AccountGroupGetResponseModel = new AccountGroupGetResponseModel(this.buffer)
    this._AccountGroupsGetAllRequestModel = new AccountGroupsGetAllRequestModel(this.buffer)
    this._AccountGroupsGetAllResponseModel = new AccountGroupsGetAllResponseModel(this.buffer)
    this._AccountGroupCreateRequestModel = new AccountGroupCreateRequestModel(this.buffer)
    this._AccountGroupCreateResponseModel = new AccountGroupCreateResponseModel(this.buffer)
    this._AccountGroupModifyRequestModel = new AccountGroupModifyRequestModel(this.buffer)
    this._AccountGroupModifyResponseModel = new AccountGroupModifyResponseModel(this.buffer)
    this._AccountGroupDeleteRequestModel = new AccountGroupDeleteRequestModel(this.buffer)
    this._AccountGroupDeleteResponseModel = new AccountGroupDeleteResponseModel(this.buffer)
    this._AccountGroupUpdateNotifyModel = new AccountGroupUpdateNotifyModel(this.buffer)
    this._AccountGetRequestModel = new AccountGetRequestModel(this.buffer)
    this._AccountGetResponseModel = new AccountGetResponseModel(this.buffer)
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel(this.buffer)
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel(this.buffer)
    this._AccountCreateRequestModel = new AccountCreateRequestModel(this.buffer)
    this._AccountCreateResponseModel = new AccountCreateResponseModel(this.buffer)
    this._AccountSocialTradingMasterCreateRequestModel = new AccountSocialTradingMasterCreateRequestModel(this.buffer)
    this._AccountSocialTradingMasterCreateResponseModel = new AccountSocialTradingMasterCreateResponseModel(this.buffer)
    this._AccountSocialTradingInvestorCreateRequestModel = new AccountSocialTradingInvestorCreateRequestModel(this.buffer)
    this._AccountSocialTradingInvestorCreateResponseModel = new AccountSocialTradingInvestorCreateResponseModel(this.buffer)
    this._AccountModifyRequestModel = new AccountModifyRequestModel(this.buffer)
    this._AccountModifyResponseModel = new AccountModifyResponseModel(this.buffer)
    this._AccountDeleteRequestModel = new AccountDeleteRequestModel(this.buffer)
    this._AccountDeleteResponseModel = new AccountDeleteResponseModel(this.buffer)
    this._DemoAccountsDeleteRequestModel = new DemoAccountsDeleteRequestModel(this.buffer)
    this._DemoAccountsDeleteResponseModel = new DemoAccountsDeleteResponseModel(this.buffer)
    this._AccountUpgradeToSocialTradingMasterRequestModel = new AccountUpgradeToSocialTradingMasterRequestModel(this.buffer)
    this._AccountUpgradeToSocialTradingMasterResponseModel = new AccountUpgradeToSocialTradingMasterResponseModel(this.buffer)
    this._AccountUpgradeToSocialTradingInvestorRequestModel = new AccountUpgradeToSocialTradingInvestorRequestModel(this.buffer)
    this._AccountUpgradeToSocialTradingInvestorResponseModel = new AccountUpgradeToSocialTradingInvestorResponseModel(this.buffer)
    this._SocialTradingInvestorAccountToRegularRequestModel = new SocialTradingInvestorAccountToRegularRequestModel(this.buffer)
    this._SocialTradingInvestorAccountToRegularResponseModel = new SocialTradingInvestorAccountToRegularResponseModel(this.buffer)
    this._SocialTradingMasterAccountToRegularRequestModel = new SocialTradingMasterAccountToRegularRequestModel(this.buffer)
    this._SocialTradingMasterAccountToRegularResponseModel = new SocialTradingMasterAccountToRegularResponseModel(this.buffer)
    this._AccountUpdateNotifyModel = new AccountUpdateNotifyModel(this.buffer)
    this._MarginGetRequestModel = new MarginGetRequestModel(this.buffer)
    this._MarginGetResponseModel = new MarginGetResponseModel(this.buffer)
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel(this.buffer)
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel(this.buffer)
    this._MarginUpdateNotifyModel = new MarginUpdateNotifyModel(this.buffer)
    this._MarginCallNotifyModel = new MarginCallNotifyModel(this.buffer)
    this._StopOutNotifyModel = new StopOutNotifyModel(this.buffer)
    this._AssetGetRequestModel = new AssetGetRequestModel(this.buffer)
    this._AssetGetResponseModel = new AssetGetResponseModel(this.buffer)
    this._AssetsGetRequestModel = new AssetsGetRequestModel(this.buffer)
    this._AssetsGetResponseModel = new AssetsGetResponseModel(this.buffer)
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel(this.buffer)
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel(this.buffer)
    this._AssetUpdateNotifyModel = new AssetUpdateNotifyModel(this.buffer)
    this._OrderGetRequestModel = new OrderGetRequestModel(this.buffer)
    this._OrderGetResponseModel = new OrderGetResponseModel(this.buffer)
    this._OrdersGetRequestModel = new OrdersGetRequestModel(this.buffer)
    this._OrdersGetResponseModel = new OrdersGetResponseModel(this.buffer)
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel(this.buffer)
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel(this.buffer)
    this._OrderCreateRequestModel = new OrderCreateRequestModel(this.buffer)
    this._OrderCreateResponseModel = new OrderCreateResponseModel(this.buffer)
    this._OrderModifyRequestModel = new OrderModifyRequestModel(this.buffer)
    this._OrderModifyResponseModel = new OrderModifyResponseModel(this.buffer)
    this._OrderActivateRequestModel = new OrderActivateRequestModel(this.buffer)
    this._OrderActivateResponseModel = new OrderActivateResponseModel(this.buffer)
    this._OrderCancelRequestModel = new OrderCancelRequestModel(this.buffer)
    this._OrderCancelResponseModel = new OrderCancelResponseModel(this.buffer)
    this._OrderCancelAllRequestModel = new OrderCancelAllRequestModel(this.buffer)
    this._OrderCancelAllResponseModel = new OrderCancelAllResponseModel(this.buffer)
    this._OrderCancelAllByIdRequestModel = new OrderCancelAllByIdRequestModel(this.buffer)
    this._OrderCloseRequestModel = new OrderCloseRequestModel(this.buffer)
    this._OrderCloseResponseModel = new OrderCloseResponseModel(this.buffer)
    this._OrderCloseAllRequestModel = new OrderCloseAllRequestModel(this.buffer)
    this._OrderCloseAllResponseModel = new OrderCloseAllResponseModel(this.buffer)
    this._OrderCloseAllByIdRequestModel = new OrderCloseAllByIdRequestModel(this.buffer)
    this._OrderCloseByRequestModel = new OrderCloseByRequestModel(this.buffer)
    this._OrderCloseByResponseModel = new OrderCloseByResponseModel(this.buffer)
    this._OrderCloseByAllRequestModel = new OrderCloseByAllRequestModel(this.buffer)
    this._OrderCloseByAllResponseModel = new OrderCloseByAllResponseModel(this.buffer)
    this._OrderCancelOrCloseRequestModel = new OrderCancelOrCloseRequestModel(this.buffer)
    this._OrderCancelOrCloseResponseModel = new OrderCancelOrCloseResponseModel(this.buffer)
    this._OrderCancelOrCloseAllRequestModel = new OrderCancelOrCloseAllRequestModel(this.buffer)
    this._OrderCancelOrCloseAllResponseModel = new OrderCancelOrCloseAllResponseModel(this.buffer)
    this._OrderUpdateNotifyModel = new OrderUpdateNotifyModel(this.buffer)
    this._PositionGetRequestModel = new PositionGetRequestModel(this.buffer)
    this._PositionGetResponseModel = new PositionGetResponseModel(this.buffer)
    this._PositionsGetRequestModel = new PositionsGetRequestModel(this.buffer)
    this._PositionsGetResponseModel = new PositionsGetResponseModel(this.buffer)
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel(this.buffer)
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel(this.buffer)
    this._PositionUpdateNotifyModel = new PositionUpdateNotifyModel(this.buffer)
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel(this.buffer)
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel(this.buffer)
    this._TradeHistoryFilterRequestModel = new TradeHistoryFilterRequestModel(this.buffer)
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel(this.buffer)
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel(this.buffer)
    this._TradeHistoryUpdateNotifyModel = new TradeHistoryUpdateNotifyModel(this.buffer)
    this._TestRolloverDumpRequestModel = new TestRolloverDumpRequestModel(this.buffer)
    this._TestRolloverDumpResponseModel = new TestRolloverDumpResponseModel(this.buffer)
    this._Deprecated001Model = new Deprecated001Model(this.buffer)
    this._Deprecated002Model = new Deprecated002Model(this.buffer)
    this._Deprecated003Model = new Deprecated003Model(this.buffer)
    this._Deprecated004Model = new Deprecated004Model(this.buffer)
    this._Deprecated005Model = new Deprecated005Model(this.buffer)
    this._Deprecated006Model = new Deprecated006Model(this.buffer)
    this._Deprecated007Model = new Deprecated007Model(this.buffer)
    this._Deprecated008Model = new Deprecated008Model(this.buffer)
    this._Deprecated009Model = new Deprecated009Model(this.buffer)
    this._Deprecated010Model = new Deprecated010Model(this.buffer)
    this._Deprecated011Model = new Deprecated011Model(this.buffer)
    this._Deprecated012Model = new Deprecated012Model(this.buffer)
    this._Deprecated013Model = new Deprecated013Model(this.buffer)
    this._DepositAccountRequestModel = new DepositAccountRequestModel(this.buffer)
    this._DepositAccountResponseModel = new DepositAccountResponseModel(this.buffer)
    this._CreditAccountRequestModel = new CreditAccountRequestModel(this.buffer)
    this._CreditAccountResponseModel = new CreditAccountResponseModel(this.buffer)
    this._TransferMoneyRequestModel = new TransferMoneyRequestModel(this.buffer)
    this._TransferMoneyResponseModel = new TransferMoneyResponseModel(this.buffer)
    this._TradingCalendarRegularRuleGetRequestModel = new TradingCalendarRegularRuleGetRequestModel(this.buffer)
    this._TradingCalendarRegularRuleGetResponseModel = new TradingCalendarRegularRuleGetResponseModel(this.buffer)
    this._TradingCalendarRegularRulesGetAllRequestModel = new TradingCalendarRegularRulesGetAllRequestModel(this.buffer)
    this._TradingCalendarRegularRulesGetAllResponseModel = new TradingCalendarRegularRulesGetAllResponseModel(this.buffer)
    this._TradingCalendarRegularRuleCreateRequestModel = new TradingCalendarRegularRuleCreateRequestModel(this.buffer)
    this._TradingCalendarRegularRuleCreateResponseModel = new TradingCalendarRegularRuleCreateResponseModel(this.buffer)
    this._TradingCalendarRegularRuleModifyRequestModel = new TradingCalendarRegularRuleModifyRequestModel(this.buffer)
    this._TradingCalendarRegularRuleModifyResponseModel = new TradingCalendarRegularRuleModifyResponseModel(this.buffer)
    this._TradingCalendarRegularRuleDeleteRequestModel = new TradingCalendarRegularRuleDeleteRequestModel(this.buffer)
    this._TradingCalendarRegularRuleDeleteResponseModel = new TradingCalendarRegularRuleDeleteResponseModel(this.buffer)
    this._TradingCalendarRegularRuleUpdateNotifyModel = new TradingCalendarRegularRuleUpdateNotifyModel(this.buffer)
    this._TradingCalendarOffTimeRuleGetRequestModel = new TradingCalendarOffTimeRuleGetRequestModel(this.buffer)
    this._TradingCalendarOffTimeRuleGetResponseModel = new TradingCalendarOffTimeRuleGetResponseModel(this.buffer)
    this._TradingCalendarOffTimeRulesGetAllRequestModel = new TradingCalendarOffTimeRulesGetAllRequestModel(this.buffer)
    this._TradingCalendarOffTimeRulesGetAllResponseModel = new TradingCalendarOffTimeRulesGetAllResponseModel(this.buffer)
    this._TradingCalendarOffTimeRuleCreateRequestModel = new TradingCalendarOffTimeRuleCreateRequestModel(this.buffer)
    this._TradingCalendarOffTimeRuleCreateResponseModel = new TradingCalendarOffTimeRuleCreateResponseModel(this.buffer)
    this._TradingCalendarOffTimeRuleModifyRequestModel = new TradingCalendarOffTimeRuleModifyRequestModel(this.buffer)
    this._TradingCalendarOffTimeRuleModifyResponseModel = new TradingCalendarOffTimeRuleModifyResponseModel(this.buffer)
    this._TradingCalendarOffTimeRuleDeleteRequestModel = new TradingCalendarOffTimeRuleDeleteRequestModel(this.buffer)
    this._TradingCalendarOffTimeRuleDeleteResponseModel = new TradingCalendarOffTimeRuleDeleteResponseModel(this.buffer)
    this._TradingCalendarOffTimeRuleUpdateNotifyModel = new TradingCalendarOffTimeRuleUpdateNotifyModel(this.buffer)
    this._TradingCalendarRulesGetRequestModel = new TradingCalendarRulesGetRequestModel(this.buffer)
    this._TradingCalendarRulesGetResponseModel = new TradingCalendarRulesGetResponseModel(this.buffer)
    this._TradingRouteRulesGetRequestModel = new TradingRouteRulesGetRequestModel(this.buffer)
    this._TradingRouteRulesGetResponseModel = new TradingRouteRulesGetResponseModel(this.buffer)
    this._TradingRouteRuleCreateRequestModel = new TradingRouteRuleCreateRequestModel(this.buffer)
    this._TradingRouteRuleCreateResponseModel = new TradingRouteRuleCreateResponseModel(this.buffer)
    this._TradingRouteRuleModifyRequestModel = new TradingRouteRuleModifyRequestModel(this.buffer)
    this._TradingRouteRuleModifyResponseModel = new TradingRouteRuleModifyResponseModel(this.buffer)
    this._TradingRouteRuleDeleteRequestModel = new TradingRouteRuleDeleteRequestModel(this.buffer)
    this._TradingRouteRuleDeleteResponseModel = new TradingRouteRuleDeleteResponseModel(this.buffer)
    this._TradingRouteRuleUpdateNotifyModel = new TradingRouteRuleUpdateNotifyModel(this.buffer)
    this._SymbolMapsGetRequestModel = new SymbolMapsGetRequestModel(this.buffer)
    this._SymbolMapsGetResponseModel = new SymbolMapsGetResponseModel(this.buffer)
    this._SymbolMapCreateRequestModel = new SymbolMapCreateRequestModel(this.buffer)
    this._SymbolMapCreateResponseModel = new SymbolMapCreateResponseModel(this.buffer)
    this._SymbolMapModifyRequestModel = new SymbolMapModifyRequestModel(this.buffer)
    this._SymbolMapModifyResponseModel = new SymbolMapModifyResponseModel(this.buffer)
    this._SymbolMapDeleteRequestModel = new SymbolMapDeleteRequestModel(this.buffer)
    this._SymbolMapDeleteResponseModel = new SymbolMapDeleteResponseModel(this.buffer)
    this._SymbolMapUpdateNotifyModel = new SymbolMapUpdateNotifyModel(this.buffer)
    this._GatewaysGetRequestModel = new GatewaysGetRequestModel(this.buffer)
    this._GatewaysGetResponseModel = new GatewaysGetResponseModel(this.buffer)
    this._GatewayCreateRequestModel = new GatewayCreateRequestModel(this.buffer)
    this._GatewayCreateResponseModel = new GatewayCreateResponseModel(this.buffer)
    this._GatewayModifyRequestModel = new GatewayModifyRequestModel(this.buffer)
    this._GatewayModifyResponseModel = new GatewayModifyResponseModel(this.buffer)
    this._GatewayDeleteRequestModel = new GatewayDeleteRequestModel(this.buffer)
    this._GatewayDeleteResponseModel = new GatewayDeleteResponseModel(this.buffer)
    this._GatewayUpdateNotifyModel = new GatewayUpdateNotifyModel(this.buffer)
    this._DataFeedsGetRequestModel = new DataFeedsGetRequestModel(this.buffer)
    this._DataFeedsGetResponseModel = new DataFeedsGetResponseModel(this.buffer)
    this._DataFeedCreateRequestModel = new DataFeedCreateRequestModel(this.buffer)
    this._DataFeedCreateResponseModel = new DataFeedCreateResponseModel(this.buffer)
    this._DataFeedModifyRequestModel = new DataFeedModifyRequestModel(this.buffer)
    this._DataFeedModifyResponseModel = new DataFeedModifyResponseModel(this.buffer)
    this._DataFeedDeleteRequestModel = new DataFeedDeleteRequestModel(this.buffer)
    this._DataFeedDeleteResponseModel = new DataFeedDeleteResponseModel(this.buffer)
    this._DataFeedUpdateNotifyModel = new DataFeedUpdateNotifyModel(this.buffer)
    this._ModuleSystemInfoNotifyModel = new ModuleSystemInfoNotifyModel(this.buffer)
    this._ModulesSystemInfoGetAllRequestModel = new ModulesSystemInfoGetAllRequestModel(this.buffer)
    this._ModulesSystemInfoGetAllResponseModel = new ModulesSystemInfoGetAllResponseModel(this.buffer)
    this._ModulesSystemInfoNotifyModel = new ModulesSystemInfoNotifyModel(this.buffer)
    this._ChartBarHistoryModifyRequestModel = new ChartBarHistoryModifyRequestModel(this.buffer)
    this._ChartBarHistoryModifyResponseModel = new ChartBarHistoryModifyResponseModel(this.buffer)
    this._FeedUpdateTickSnapshotRequestModel = new FeedUpdateTickSnapshotRequestModel(this.buffer)
    this._FeedUpdateTickSnapshotResponseModel = new FeedUpdateTickSnapshotResponseModel(this.buffer)
    this._FeedUpdateTickRequestModel = new FeedUpdateTickRequestModel(this.buffer)
    this._FeedUpdateTickResponseModel = new FeedUpdateTickResponseModel(this.buffer)
    this._FeedUpdateLevel2SnapshotRequestModel = new FeedUpdateLevel2SnapshotRequestModel(this.buffer)
    this._FeedUpdateLevel2SnapshotResponseModel = new FeedUpdateLevel2SnapshotResponseModel(this.buffer)
    this._FeedUpdateLevel2RequestModel = new FeedUpdateLevel2RequestModel(this.buffer)
    this._FeedUpdateLevel2ResponseModel = new FeedUpdateLevel2ResponseModel(this.buffer)
    this._MailServerGetRequestModel = new MailServerGetRequestModel(this.buffer)
    this._MailServerGetResponseModel = new MailServerGetResponseModel(this.buffer)
    this._MailServersGetAllRequestModel = new MailServersGetAllRequestModel(this.buffer)
    this._MailServersGetAllResponseModel = new MailServersGetAllResponseModel(this.buffer)
    this._MailServerCreateRequestModel = new MailServerCreateRequestModel(this.buffer)
    this._MailServerCreateResponseModel = new MailServerCreateResponseModel(this.buffer)
    this._MailServerModifyRequestModel = new MailServerModifyRequestModel(this.buffer)
    this._MailServerModifyResponseModel = new MailServerModifyResponseModel(this.buffer)
    this._MailServerDeleteRequestModel = new MailServerDeleteRequestModel(this.buffer)
    this._MailServerDeleteResponseModel = new MailServerDeleteResponseModel(this.buffer)
    this._MailServerUpdateNotifyModel = new MailServerUpdateNotifyModel(this.buffer)
    this._AccountStatesUpdateNotifyModel = new AccountStatesUpdateNotifyModel(this.buffer)
    this._AccountStatesGetAllRequestModel = new AccountStatesGetAllRequestModel(this.buffer)
    this._AccountStatesGetAllResponseModel = new AccountStatesGetAllResponseModel(this.buffer)
    this._ModuleAuthRequestModel = new ModuleAuthRequestModel(this.buffer)
    this._ModuleAuthResponseModel = new ModuleAuthResponseModel(this.buffer)
    this._LeverageProfileGetRequestModel = new LeverageProfileGetRequestModel(this.buffer)
    this._LeverageProfileGetResponseModel = new LeverageProfileGetResponseModel(this.buffer)
    this._LeverageProfilesGetAllRequestModel = new LeverageProfilesGetAllRequestModel(this.buffer)
    this._LeverageProfilesGetAllResponseModel = new LeverageProfilesGetAllResponseModel(this.buffer)
    this._LeverageProfileCreateRequestModel = new LeverageProfileCreateRequestModel(this.buffer)
    this._LeverageProfileCreateResponseModel = new LeverageProfileCreateResponseModel(this.buffer)
    this._LeverageProfileModifyRequestModel = new LeverageProfileModifyRequestModel(this.buffer)
    this._LeverageProfileModifyResponseModel = new LeverageProfileModifyResponseModel(this.buffer)
    this._LeverageProfileDeleteRequestModel = new LeverageProfileDeleteRequestModel(this.buffer)
    this._LeverageProfileDeleteResponseModel = new LeverageProfileDeleteResponseModel(this.buffer)
    this._LeverageProfileUpdateNotifyModel = new LeverageProfileUpdateNotifyModel(this.buffer)
    this._MarginRateProfileGetRequestModel = new MarginRateProfileGetRequestModel(this.buffer)
    this._MarginRateProfileGetResponseModel = new MarginRateProfileGetResponseModel(this.buffer)
    this._MarginRateProfilesGetAllRequestModel = new MarginRateProfilesGetAllRequestModel(this.buffer)
    this._MarginRateProfilesGetAllResponseModel = new MarginRateProfilesGetAllResponseModel(this.buffer)
    this._MarginRateProfileCreateRequestModel = new MarginRateProfileCreateRequestModel(this.buffer)
    this._MarginRateProfileCreateResponseModel = new MarginRateProfileCreateResponseModel(this.buffer)
    this._MarginRateProfileModifyRequestModel = new MarginRateProfileModifyRequestModel(this.buffer)
    this._MarginRateProfileModifyResponseModel = new MarginRateProfileModifyResponseModel(this.buffer)
    this._MarginRateProfileDeleteRequestModel = new MarginRateProfileDeleteRequestModel(this.buffer)
    this._MarginRateProfileDeleteResponseModel = new MarginRateProfileDeleteResponseModel(this.buffer)
    this._MarginRateProfileUpdateNotifyModel = new MarginRateProfileUpdateNotifyModel(this.buffer)
    this._NewsProfileGetRequestModel = new NewsProfileGetRequestModel(this.buffer)
    this._NewsProfileGetResponseModel = new NewsProfileGetResponseModel(this.buffer)
    this._NewsProfilesGetAllRequestModel = new NewsProfilesGetAllRequestModel(this.buffer)
    this._NewsProfilesGetAllResponseModel = new NewsProfilesGetAllResponseModel(this.buffer)
    this._NewsProfileCreateRequestModel = new NewsProfileCreateRequestModel(this.buffer)
    this._NewsProfileCreateResponseModel = new NewsProfileCreateResponseModel(this.buffer)
    this._NewsProfileModifyRequestModel = new NewsProfileModifyRequestModel(this.buffer)
    this._NewsProfileModifyResponseModel = new NewsProfileModifyResponseModel(this.buffer)
    this._NewsProfileDeleteRequestModel = new NewsProfileDeleteRequestModel(this.buffer)
    this._NewsProfileDeleteResponseModel = new NewsProfileDeleteResponseModel(this.buffer)
    this._NewsProfileUpdateNotifyModel = new NewsProfileUpdateNotifyModel(this.buffer)
    this._NewsProfileImportRequestModel = new NewsProfileImportRequestModel(this.buffer)
    this._NewsProfileImportResponseModel = new NewsProfileImportResponseModel(this.buffer)
    this._TickHistoryRequestModel = new TickHistoryRequestModel(this.buffer)
    this._TickHistoryResponseModel = new TickHistoryResponseModel(this.buffer)
    this._EmailTemplateGetRequestModel = new EmailTemplateGetRequestModel(this.buffer)
    this._EmailTemplateGetResponseModel = new EmailTemplateGetResponseModel(this.buffer)
    this._EmailTemplatesGetAllRequestModel = new EmailTemplatesGetAllRequestModel(this.buffer)
    this._EmailTemplatesGetAllResponseModel = new EmailTemplatesGetAllResponseModel(this.buffer)
    this._EmailTemplateCreateRequestModel = new EmailTemplateCreateRequestModel(this.buffer)
    this._EmailTemplateCreateResponseModel = new EmailTemplateCreateResponseModel(this.buffer)
    this._EmailTemplateModifyRequestModel = new EmailTemplateModifyRequestModel(this.buffer)
    this._EmailTemplateModifyResponseModel = new EmailTemplateModifyResponseModel(this.buffer)
    this._EmailTemplateDeleteRequestModel = new EmailTemplateDeleteRequestModel(this.buffer)
    this._EmailTemplateDeleteResponseModel = new EmailTemplateDeleteResponseModel(this.buffer)
    this._EmailTemplateUpdateNotifyModel = new EmailTemplateUpdateNotifyModel(this.buffer)
    this._OrderStateSubscribeRequestModel = new OrderStateSubscribeRequestModel(this.buffer)
    this._OrderStateSubscribeResponseModel = new OrderStateSubscribeResponseModel(this.buffer)
    this._OrderStateUpdateSubscriptionRequestModel = new OrderStateUpdateSubscriptionRequestModel(this.buffer)
    this._OrderStateUpdateSubscriptionResponseModel = new OrderStateUpdateSubscriptionResponseModel(this.buffer)
    this._OrderStateUnsubscribeRequestModel = new OrderStateUnsubscribeRequestModel(this.buffer)
    this._OrderStateUnsubscribeResponseModel = new OrderStateUnsubscribeResponseModel(this.buffer)
    this._OrderStateNotifyModel = new OrderStateNotifyModel(this.buffer)
    this._RequestMonitoringSubscribeRequestModel = new RequestMonitoringSubscribeRequestModel(this.buffer)
    this._RequestMonitoringSubscribeResponseModel = new RequestMonitoringSubscribeResponseModel(this.buffer)
    this._RequestMonitoringUnsubscribeRequestModel = new RequestMonitoringUnsubscribeRequestModel(this.buffer)
    this._RequestMonitoringUnsubscribeResponseModel = new RequestMonitoringUnsubscribeResponseModel(this.buffer)
    this._RequestMonitoringRequestUpdateNotifyModel = new RequestMonitoringRequestUpdateNotifyModel(this.buffer)
    this._FilledOrdersSummaryInfoSubscribeRequestModel = new FilledOrdersSummaryInfoSubscribeRequestModel(this.buffer)
    this._FilledOrdersSummaryInfoSubscribeResponseModel = new FilledOrdersSummaryInfoSubscribeResponseModel(this.buffer)
    this._FilledOrdersSummaryInfoNotifyModel = new FilledOrdersSummaryInfoNotifyModel(this.buffer)
    this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel = new FilledOrdersSummaryInfoUpdateSubscriptionRequestModel(this.buffer)
    this._FilledOrdersSummaryInfoUnsubscribeRequestModel = new FilledOrdersSummaryInfoUnsubscribeRequestModel(this.buffer)
    this._FilledOrdersSummaryInfoUnsubscribeResponseModel = new FilledOrdersSummaryInfoUnsubscribeResponseModel(this.buffer)
    this._TradeEventsNotifyModel = new TradeEventsNotifyModel(this.buffer)
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel(this.buffer)
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel(this.buffer)
    this._JournalRequestModel = new JournalRequestModel(this.buffer)
    this._JournalResponseModel = new JournalResponseModel(this.buffer)
    this._ClientReportsListGetRequestModel = new ClientReportsListGetRequestModel(this.buffer)
    this._ClientReportsListGetResponseModel = new ClientReportsListGetResponseModel(this.buffer)
    this._ClientReportGetRequestModel = new ClientReportGetRequestModel(this.buffer)
    this._ClientReportGetResponseModel = new ClientReportGetResponseModel(this.buffer)
    this._ClientReportsSendRequestModel = new ClientReportsSendRequestModel(this.buffer)
    this._ClientReportsSendResponseModel = new ClientReportsSendResponseModel(this.buffer)
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel(this.buffer)
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel(this.buffer)
    this._ChartBarsImportRequestModel = new ChartBarsImportRequestModel(this.buffer)
    this._ChartBarsImportResponseModel = new ChartBarsImportResponseModel(this.buffer)
    this._ChartBarsImportNotifyModel = new ChartBarsImportNotifyModel(this.buffer)
    this._JournalBusinessRequestModel = new JournalBusinessRequestModel(this.buffer)
    this._JournalBusinessResponseModel = new JournalBusinessResponseModel(this.buffer)
    this._AccountsGetPageRequestModel = new AccountsGetPageRequestModel(this.buffer)
    this._AccountsGetPageResponseModel = new AccountsGetPageResponseModel(this.buffer)
    this._HeartbeatNotifyModel = new HeartbeatNotifyModel(this.buffer)
    this._OrdersClosedSummariesGetRequestModel = new OrdersClosedSummariesGetRequestModel(this.buffer)
    this._OrdersClosedSummariesGetResponseModel = new OrdersClosedSummariesGetResponseModel(this.buffer)
    this._OrdersGetPageRequestModel = new OrdersGetPageRequestModel(this.buffer)
    this._OrdersGetPageResponseModel = new OrdersGetPageResponseModel(this.buffer)
    this._StorageUniversalNotifyModel = new StorageUniversalNotifyModel(this.buffer)
    this._ClientLoginInfoGetRequestModel = new ClientLoginInfoGetRequestModel(this.buffer)
    this._ClientLoginInfoGetResponseModel = new ClientLoginInfoGetResponseModel(this.buffer)
    this._AccountGroupExistsGetRequestModel = new AccountGroupExistsGetRequestModel(this.buffer)
    this._AccountGroupExistsGetResponseModel = new AccountGroupExistsGetResponseModel(this.buffer)
    this._LoginWithPasswordRequestModel = new LoginWithPasswordRequestModel(this.buffer)
    this._LoginWithPasswordResponseModel = new LoginWithPasswordResponseModel(this.buffer)
    this._LoginWithTokenRequestModel = new LoginWithTokenRequestModel(this.buffer)
    this._LoginWithTokenResponseModel = new LoginWithTokenResponseModel(this.buffer)
    this._RefreshTokenRequestModel = new RefreshTokenRequestModel(this.buffer)
    this._RefreshTokenResponseModel = new RefreshTokenResponseModel(this.buffer)
    this._PersonGetRequestModel = new PersonGetRequestModel(this.buffer)
    this._PersonGetResponseModel = new PersonGetResponseModel(this.buffer)
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel(this.buffer)
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel(this.buffer)
    this._PersonCreateRequestModel = new PersonCreateRequestModel(this.buffer)
    this._PersonCreateResponseModel = new PersonCreateResponseModel(this.buffer)
    this._PersonModifyRequestModel = new PersonModifyRequestModel(this.buffer)
    this._PersonModifyResponseModel = new PersonModifyResponseModel(this.buffer)
    this._PersonUpdateNotifyModel = new PersonUpdateNotifyModel(this.buffer)
    this._SecuritySettingsGetRequestModel = new SecuritySettingsGetRequestModel(this.buffer)
    this._SecuritySettingsGetResponseModel = new SecuritySettingsGetResponseModel(this.buffer)
    this._SecuritySettingsGetAllRequestModel = new SecuritySettingsGetAllRequestModel(this.buffer)
    this._SecuritySettingsGetAllResponseModel = new SecuritySettingsGetAllResponseModel(this.buffer)
    this._SecuritySettingsCreateRequestModel = new SecuritySettingsCreateRequestModel(this.buffer)
    this._SecuritySettingsCreateResponseModel = new SecuritySettingsCreateResponseModel(this.buffer)
    this._SecuritySettingsModifyRequestModel = new SecuritySettingsModifyRequestModel(this.buffer)
    this._SecuritySettingsModifyResponseModel = new SecuritySettingsModifyResponseModel(this.buffer)
    this._SecuritySettingsDeleteRequestModel = new SecuritySettingsDeleteRequestModel(this.buffer)
    this._SecuritySettingsDeleteResponseModel = new SecuritySettingsDeleteResponseModel(this.buffer)
    this._SecuritySettingsUpdateNotifyModel = new SecuritySettingsUpdateNotifyModel(this.buffer)
    this._FirewallRuleGetRequestModel = new FirewallRuleGetRequestModel(this.buffer)
    this._FirewallRuleGetResponseModel = new FirewallRuleGetResponseModel(this.buffer)
    this._FirewallRulesGetAllRequestModel = new FirewallRulesGetAllRequestModel(this.buffer)
    this._FirewallRulesGetAllResponseModel = new FirewallRulesGetAllResponseModel(this.buffer)
    this._FirewallRuleCreateRequestModel = new FirewallRuleCreateRequestModel(this.buffer)
    this._FirewallRuleCreateResponseModel = new FirewallRuleCreateResponseModel(this.buffer)
    this._FirewallRuleModifyRequestModel = new FirewallRuleModifyRequestModel(this.buffer)
    this._FirewallRuleModifyResponseModel = new FirewallRuleModifyResponseModel(this.buffer)
    this._FirewallRuleDeleteRequestModel = new FirewallRuleDeleteRequestModel(this.buffer)
    this._FirewallRuleDeleteResponseModel = new FirewallRuleDeleteResponseModel(this.buffer)
    this._FirewallRuleUpdateNotifyModel = new FirewallRuleUpdateNotifyModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  /**
   * Get imported config sender
   * @this {!Sender}
   * @returns {!config.Sender} config sender
   */
  get configSender () {
    return this._configSender
  }

  /**
   * Get imported history sender
   * @this {!Sender}
   * @returns {!history.Sender} history sender
   */
  get historySender () {
    return this._historySender
  }

  /**
   * Get imported client sender
   * @this {!Sender}
   * @returns {!client.Sender} client sender
   */
  get clientSender () {
    return this._clientSender
  }

  /**
   * Get imported feed sender
   * @this {!Sender}
   * @returns {!feed.Sender} feed sender
   */
  get feedSender () {
    return this._feedSender
  }

  /**
   * Get imported storage sender
   * @this {!Sender}
   * @returns {!storage.Sender} storage sender
   */
  get storageSender () {
    return this._storageSender
  }

  /**
   * Get imported exposed sender
   * @this {!Sender}
   * @returns {!exposed.Sender} exposed sender
   */
  get exposedSender () {
    return this._exposedSender
  }

  /**
   * Get imported execution sender
   * @this {!Sender}
   * @returns {!execution.Sender} execution sender
   */
  get executionSender () {
    return this._executionSender
  }

  /**
   * Get imported backup sender
   * @this {!Sender}
   * @returns {!backup.Sender} backup sender
   */
  get backupSender () {
    return this._backupSender
  }

  /**
   * Get imported inner sender
   * @this {!Sender}
   * @returns {!inner.Sender} inner sender
   */
  get innerSender () {
    return this._innerSender
  }

  /**
   * Get imported trade sender
   * @this {!Sender}
   * @returns {!trade.Sender} trade sender
   */
  get tradeSender () {
    return this._tradeSender
  }

  // Sender models accessors

  /**
   * Get PingModuleRequest model
   * @this {!Sender}
   * @returns {!PingModuleRequestModel} PingModuleRequest model
   */
  get PingModuleRequestModel () {
    return this._PingModuleRequestModel
  }

  /**
   * Get PongModuleResponse model
   * @this {!Sender}
   * @returns {!PongModuleResponseModel} PongModuleResponse model
   */
  get PongModuleResponseModel () {
    return this._PongModuleResponseModel
  }

  /**
   * Get PingModuleReject model
   * @this {!Sender}
   * @returns {!PingModuleRejectModel} PingModuleReject model
   */
  get PingModuleRejectModel () {
    return this._PingModuleRejectModel
  }

  /**
   * Get Deprecated3 model
   * @this {!Sender}
   * @returns {!Deprecated3Model} Deprecated3 model
   */
  get Deprecated3Model () {
    return this._Deprecated3Model
  }

  /**
   * Get Deprecated4 model
   * @this {!Sender}
   * @returns {!Deprecated4Model} Deprecated4 model
   */
  get Deprecated4Model () {
    return this._Deprecated4Model
  }

  /**
   * Get Deprecated1 model
   * @this {!Sender}
   * @returns {!Deprecated1Model} Deprecated1 model
   */
  get Deprecated1Model () {
    return this._Deprecated1Model
  }

  /**
   * Get Deprecated2 model
   * @this {!Sender}
   * @returns {!Deprecated2Model} Deprecated2 model
   */
  get Deprecated2Model () {
    return this._Deprecated2Model
  }

  /**
   * Get ManagerSessionInfoRequest model
   * @this {!Sender}
   * @returns {!ManagerSessionInfoRequestModel} ManagerSessionInfoRequest model
   */
  get ManagerSessionInfoRequestModel () {
    return this._ManagerSessionInfoRequestModel
  }

  /**
   * Get ManagerSessionInfoResponse model
   * @this {!Sender}
   * @returns {!ManagerSessionInfoResponseModel} ManagerSessionInfoResponse model
   */
  get ManagerSessionInfoResponseModel () {
    return this._ManagerSessionInfoResponseModel
  }

  /**
   * Get OnlineSessionsRequest model
   * @this {!Sender}
   * @returns {!OnlineSessionsRequestModel} OnlineSessionsRequest model
   */
  get OnlineSessionsRequestModel () {
    return this._OnlineSessionsRequestModel
  }

  /**
   * Get OnlineSessionsResponse model
   * @this {!Sender}
   * @returns {!OnlineSessionsResponseModel} OnlineSessionsResponse model
   */
  get OnlineSessionsResponseModel () {
    return this._OnlineSessionsResponseModel
  }

  /**
   * Get DropSessionRequest model
   * @this {!Sender}
   * @returns {!DropSessionRequestModel} DropSessionRequest model
   */
  get DropSessionRequestModel () {
    return this._DropSessionRequestModel
  }

  /**
   * Get DropSessionResponse model
   * @this {!Sender}
   * @returns {!DropSessionResponseModel} DropSessionResponse model
   */
  get DropSessionResponseModel () {
    return this._DropSessionResponseModel
  }

  /**
   * Get ConfigRequest model
   * @this {!Sender}
   * @returns {!ConfigRequestModel} ConfigRequest model
   */
  get ConfigRequestModel () {
    return this._ConfigRequestModel
  }

  /**
   * Get ConfigResponse model
   * @this {!Sender}
   * @returns {!ConfigResponseModel} ConfigResponse model
   */
  get ConfigResponseModel () {
    return this._ConfigResponseModel
  }

  /**
   * Get ConfigReject model
   * @this {!Sender}
   * @returns {!ConfigRejectModel} ConfigReject model
   */
  get ConfigRejectModel () {
    return this._ConfigRejectModel
  }

  /**
   * Get ConfigUpdateRequest model
   * @this {!Sender}
   * @returns {!ConfigUpdateRequestModel} ConfigUpdateRequest model
   */
  get ConfigUpdateRequestModel () {
    return this._ConfigUpdateRequestModel
  }

  /**
   * Get ConfigUpdateResponse model
   * @this {!Sender}
   * @returns {!ConfigUpdateResponseModel} ConfigUpdateResponse model
   */
  get ConfigUpdateResponseModel () {
    return this._ConfigUpdateResponseModel
  }

  /**
   * Get ConfigUpdateNotify model
   * @this {!Sender}
   * @returns {!ConfigUpdateNotifyModel} ConfigUpdateNotify model
   */
  get ConfigUpdateNotifyModel () {
    return this._ConfigUpdateNotifyModel
  }

  /**
   * Get ConfigVersionRequest model
   * @this {!Sender}
   * @returns {!ConfigVersionRequestModel} ConfigVersionRequest model
   */
  get ConfigVersionRequestModel () {
    return this._ConfigVersionRequestModel
  }

  /**
   * Get ConfigVersionResponse model
   * @this {!Sender}
   * @returns {!ConfigVersionResponseModel} ConfigVersionResponse model
   */
  get ConfigVersionResponseModel () {
    return this._ConfigVersionResponseModel
  }

  /**
   * Get ConfigExportRequest model
   * @this {!Sender}
   * @returns {!ConfigExportRequestModel} ConfigExportRequest model
   */
  get ConfigExportRequestModel () {
    return this._ConfigExportRequestModel
  }

  /**
   * Get ConfigExportResponse model
   * @this {!Sender}
   * @returns {!ConfigExportResponseModel} ConfigExportResponse model
   */
  get ConfigExportResponseModel () {
    return this._ConfigExportResponseModel
  }

  /**
   * Get ConfigImportRequest model
   * @this {!Sender}
   * @returns {!ConfigImportRequestModel} ConfigImportRequest model
   */
  get ConfigImportRequestModel () {
    return this._ConfigImportRequestModel
  }

  /**
   * Get ConfigImportResponse model
   * @this {!Sender}
   * @returns {!ConfigImportResponseModel} ConfigImportResponse model
   */
  get ConfigImportResponseModel () {
    return this._ConfigImportResponseModel
  }

  /**
   * Get ConfigValidateRequest model
   * @this {!Sender}
   * @returns {!ConfigValidateRequestModel} ConfigValidateRequest model
   */
  get ConfigValidateRequestModel () {
    return this._ConfigValidateRequestModel
  }

  /**
   * Get ConfigValidateResponse model
   * @this {!Sender}
   * @returns {!ConfigValidateResponseModel} ConfigValidateResponse model
   */
  get ConfigValidateResponseModel () {
    return this._ConfigValidateResponseModel
  }

  /**
   * Get ConfigRefreshRequest model
   * @this {!Sender}
   * @returns {!ConfigRefreshRequestModel} ConfigRefreshRequest model
   */
  get ConfigRefreshRequestModel () {
    return this._ConfigRefreshRequestModel
  }

  /**
   * Get ConfigRefreshResponse model
   * @this {!Sender}
   * @returns {!ConfigRefreshResponseModel} ConfigRefreshResponse model
   */
  get ConfigRefreshResponseModel () {
    return this._ConfigRefreshResponseModel
  }

  /**
   * Get ConfigHistoryRequest model
   * @this {!Sender}
   * @returns {!ConfigHistoryRequestModel} ConfigHistoryRequest model
   */
  get ConfigHistoryRequestModel () {
    return this._ConfigHistoryRequestModel
  }

  /**
   * Get ConfigHistoryResponse model
   * @this {!Sender}
   * @returns {!ConfigHistoryResponseModel} ConfigHistoryResponse model
   */
  get ConfigHistoryResponseModel () {
    return this._ConfigHistoryResponseModel
  }

  /**
   * Get ConfigRollbackRequest model
   * @this {!Sender}
   * @returns {!ConfigRollbackRequestModel} ConfigRollbackRequest model
   */
  get ConfigRollbackRequestModel () {
    return this._ConfigRollbackRequestModel
  }

  /**
   * Get ConfigRollbackResponse model
   * @this {!Sender}
   * @returns {!ConfigRollbackResponseModel} ConfigRollbackResponse model
   */
  get ConfigRollbackResponseModel () {
    return this._ConfigRollbackResponseModel
  }

  /**
   * Get HostGetRequest model
   * @this {!Sender}
   * @returns {!HostGetRequestModel} HostGetRequest model
   */
  get HostGetRequestModel () {
    return this._HostGetRequestModel
  }

  /**
   * Get HostGetResponse model
   * @this {!Sender}
   * @returns {!HostGetResponseModel} HostGetResponse model
   */
  get HostGetResponseModel () {
    return this._HostGetResponseModel
  }

  /**
   * Get HostsGetAllRequest model
   * @this {!Sender}
   * @returns {!HostsGetAllRequestModel} HostsGetAllRequest model
   */
  get HostsGetAllRequestModel () {
    return this._HostsGetAllRequestModel
  }

  /**
   * Get HostsGetAllResponse model
   * @this {!Sender}
   * @returns {!HostsGetAllResponseModel} HostsGetAllResponse model
   */
  get HostsGetAllResponseModel () {
    return this._HostsGetAllResponseModel
  }

  /**
   * Get HostCreateRequest model
   * @this {!Sender}
   * @returns {!HostCreateRequestModel} HostCreateRequest model
   */
  get HostCreateRequestModel () {
    return this._HostCreateRequestModel
  }

  /**
   * Get HostCreateResponse model
   * @this {!Sender}
   * @returns {!HostCreateResponseModel} HostCreateResponse model
   */
  get HostCreateResponseModel () {
    return this._HostCreateResponseModel
  }

  /**
   * Get HostModifyRequest model
   * @this {!Sender}
   * @returns {!HostModifyRequestModel} HostModifyRequest model
   */
  get HostModifyRequestModel () {
    return this._HostModifyRequestModel
  }

  /**
   * Get HostModifyResponse model
   * @this {!Sender}
   * @returns {!HostModifyResponseModel} HostModifyResponse model
   */
  get HostModifyResponseModel () {
    return this._HostModifyResponseModel
  }

  /**
   * Get HostDeleteRequest model
   * @this {!Sender}
   * @returns {!HostDeleteRequestModel} HostDeleteRequest model
   */
  get HostDeleteRequestModel () {
    return this._HostDeleteRequestModel
  }

  /**
   * Get HostDeleteResponse model
   * @this {!Sender}
   * @returns {!HostDeleteResponseModel} HostDeleteResponse model
   */
  get HostDeleteResponseModel () {
    return this._HostDeleteResponseModel
  }

  /**
   * Get HostUpdateNotify model
   * @this {!Sender}
   * @returns {!HostUpdateNotifyModel} HostUpdateNotify model
   */
  get HostUpdateNotifyModel () {
    return this._HostUpdateNotifyModel
  }

  /**
   * Get ModuleGetRequest model
   * @this {!Sender}
   * @returns {!ModuleGetRequestModel} ModuleGetRequest model
   */
  get ModuleGetRequestModel () {
    return this._ModuleGetRequestModel
  }

  /**
   * Get ModuleGetResponse model
   * @this {!Sender}
   * @returns {!ModuleGetResponseModel} ModuleGetResponse model
   */
  get ModuleGetResponseModel () {
    return this._ModuleGetResponseModel
  }

  /**
   * Get ModulesGetAllRequest model
   * @this {!Sender}
   * @returns {!ModulesGetAllRequestModel} ModulesGetAllRequest model
   */
  get ModulesGetAllRequestModel () {
    return this._ModulesGetAllRequestModel
  }

  /**
   * Get ModulesGetAllResponse model
   * @this {!Sender}
   * @returns {!ModulesGetAllResponseModel} ModulesGetAllResponse model
   */
  get ModulesGetAllResponseModel () {
    return this._ModulesGetAllResponseModel
  }

  /**
   * Get ModuleCreateRequest model
   * @this {!Sender}
   * @returns {!ModuleCreateRequestModel} ModuleCreateRequest model
   */
  get ModuleCreateRequestModel () {
    return this._ModuleCreateRequestModel
  }

  /**
   * Get ModuleCreateResponse model
   * @this {!Sender}
   * @returns {!ModuleCreateResponseModel} ModuleCreateResponse model
   */
  get ModuleCreateResponseModel () {
    return this._ModuleCreateResponseModel
  }

  /**
   * Get ModuleModifyRequest model
   * @this {!Sender}
   * @returns {!ModuleModifyRequestModel} ModuleModifyRequest model
   */
  get ModuleModifyRequestModel () {
    return this._ModuleModifyRequestModel
  }

  /**
   * Get ModuleModifyResponse model
   * @this {!Sender}
   * @returns {!ModuleModifyResponseModel} ModuleModifyResponse model
   */
  get ModuleModifyResponseModel () {
    return this._ModuleModifyResponseModel
  }

  /**
   * Get ModuleDeleteRequest model
   * @this {!Sender}
   * @returns {!ModuleDeleteRequestModel} ModuleDeleteRequest model
   */
  get ModuleDeleteRequestModel () {
    return this._ModuleDeleteRequestModel
  }

  /**
   * Get ModuleDeleteResponse model
   * @this {!Sender}
   * @returns {!ModuleDeleteResponseModel} ModuleDeleteResponse model
   */
  get ModuleDeleteResponseModel () {
    return this._ModuleDeleteResponseModel
  }

  /**
   * Get ModuleUpdateNotify model
   * @this {!Sender}
   * @returns {!ModuleUpdateNotifyModel} ModuleUpdateNotify model
   */
  get ModuleUpdateNotifyModel () {
    return this._ModuleUpdateNotifyModel
  }

  /**
   * Get ServerTimeGetRequest model
   * @this {!Sender}
   * @returns {!ServerTimeGetRequestModel} ServerTimeGetRequest model
   */
  get ServerTimeGetRequestModel () {
    return this._ServerTimeGetRequestModel
  }

  /**
   * Get ServerTimeGetResponse model
   * @this {!Sender}
   * @returns {!ServerTimeGetResponseModel} ServerTimeGetResponse model
   */
  get ServerTimeGetResponseModel () {
    return this._ServerTimeGetResponseModel
  }

  /**
   * Get ManagerPlatformTimezoneGetRequest model
   * @this {!Sender}
   * @returns {!ManagerPlatformTimezoneGetRequestModel} ManagerPlatformTimezoneGetRequest model
   */
  get ManagerPlatformTimezoneGetRequestModel () {
    return this._ManagerPlatformTimezoneGetRequestModel
  }

  /**
   * Get ManagerPlatformTimezoneGetResponse model
   * @this {!Sender}
   * @returns {!ManagerPlatformTimezoneGetResponseModel} ManagerPlatformTimezoneGetResponse model
   */
  get ManagerPlatformTimezoneGetResponseModel () {
    return this._ManagerPlatformTimezoneGetResponseModel
  }

  /**
   * Get TimezoneGetRequest model
   * @this {!Sender}
   * @returns {!TimezoneGetRequestModel} TimezoneGetRequest model
   */
  get TimezoneGetRequestModel () {
    return this._TimezoneGetRequestModel
  }

  /**
   * Get TimezoneGetResponse model
   * @this {!Sender}
   * @returns {!TimezoneGetResponseModel} TimezoneGetResponse model
   */
  get TimezoneGetResponseModel () {
    return this._TimezoneGetResponseModel
  }

  /**
   * Get TimezonesGetAllRequest model
   * @this {!Sender}
   * @returns {!TimezonesGetAllRequestModel} TimezonesGetAllRequest model
   */
  get TimezonesGetAllRequestModel () {
    return this._TimezonesGetAllRequestModel
  }

  /**
   * Get TimezonesGetAllResponse model
   * @this {!Sender}
   * @returns {!TimezonesGetAllResponseModel} TimezonesGetAllResponse model
   */
  get TimezonesGetAllResponseModel () {
    return this._TimezonesGetAllResponseModel
  }

  /**
   * Get TimezoneCreateRequest model
   * @this {!Sender}
   * @returns {!TimezoneCreateRequestModel} TimezoneCreateRequest model
   */
  get TimezoneCreateRequestModel () {
    return this._TimezoneCreateRequestModel
  }

  /**
   * Get TimezoneCreateResponse model
   * @this {!Sender}
   * @returns {!TimezoneCreateResponseModel} TimezoneCreateResponse model
   */
  get TimezoneCreateResponseModel () {
    return this._TimezoneCreateResponseModel
  }

  /**
   * Get TimezoneModifyRequest model
   * @this {!Sender}
   * @returns {!TimezoneModifyRequestModel} TimezoneModifyRequest model
   */
  get TimezoneModifyRequestModel () {
    return this._TimezoneModifyRequestModel
  }

  /**
   * Get TimezoneModifyResponse model
   * @this {!Sender}
   * @returns {!TimezoneModifyResponseModel} TimezoneModifyResponse model
   */
  get TimezoneModifyResponseModel () {
    return this._TimezoneModifyResponseModel
  }

  /**
   * Get TimezoneDeleteRequest model
   * @this {!Sender}
   * @returns {!TimezoneDeleteRequestModel} TimezoneDeleteRequest model
   */
  get TimezoneDeleteRequestModel () {
    return this._TimezoneDeleteRequestModel
  }

  /**
   * Get TimezoneDeleteResponse model
   * @this {!Sender}
   * @returns {!TimezoneDeleteResponseModel} TimezoneDeleteResponse model
   */
  get TimezoneDeleteResponseModel () {
    return this._TimezoneDeleteResponseModel
  }

  /**
   * Get TimezoneUpdateNotify model
   * @this {!Sender}
   * @returns {!TimezoneUpdateNotifyModel} TimezoneUpdateNotify model
   */
  get TimezoneUpdateNotifyModel () {
    return this._TimezoneUpdateNotifyModel
  }

  /**
   * Get CurrencyGroupGetRequest model
   * @this {!Sender}
   * @returns {!CurrencyGroupGetRequestModel} CurrencyGroupGetRequest model
   */
  get CurrencyGroupGetRequestModel () {
    return this._CurrencyGroupGetRequestModel
  }

  /**
   * Get CurrencyGroupGetResponse model
   * @this {!Sender}
   * @returns {!CurrencyGroupGetResponseModel} CurrencyGroupGetResponse model
   */
  get CurrencyGroupGetResponseModel () {
    return this._CurrencyGroupGetResponseModel
  }

  /**
   * Get CurrencyGroupsGetAllRequest model
   * @this {!Sender}
   * @returns {!CurrencyGroupsGetAllRequestModel} CurrencyGroupsGetAllRequest model
   */
  get CurrencyGroupsGetAllRequestModel () {
    return this._CurrencyGroupsGetAllRequestModel
  }

  /**
   * Get CurrencyGroupsGetAllResponse model
   * @this {!Sender}
   * @returns {!CurrencyGroupsGetAllResponseModel} CurrencyGroupsGetAllResponse model
   */
  get CurrencyGroupsGetAllResponseModel () {
    return this._CurrencyGroupsGetAllResponseModel
  }

  /**
   * Get CurrencyGroupCreateRequest model
   * @this {!Sender}
   * @returns {!CurrencyGroupCreateRequestModel} CurrencyGroupCreateRequest model
   */
  get CurrencyGroupCreateRequestModel () {
    return this._CurrencyGroupCreateRequestModel
  }

  /**
   * Get CurrencyGroupCreateResponse model
   * @this {!Sender}
   * @returns {!CurrencyGroupCreateResponseModel} CurrencyGroupCreateResponse model
   */
  get CurrencyGroupCreateResponseModel () {
    return this._CurrencyGroupCreateResponseModel
  }

  /**
   * Get CurrencyGroupModifyRequest model
   * @this {!Sender}
   * @returns {!CurrencyGroupModifyRequestModel} CurrencyGroupModifyRequest model
   */
  get CurrencyGroupModifyRequestModel () {
    return this._CurrencyGroupModifyRequestModel
  }

  /**
   * Get CurrencyGroupModifyResponse model
   * @this {!Sender}
   * @returns {!CurrencyGroupModifyResponseModel} CurrencyGroupModifyResponse model
   */
  get CurrencyGroupModifyResponseModel () {
    return this._CurrencyGroupModifyResponseModel
  }

  /**
   * Get CurrencyGroupDeleteRequest model
   * @this {!Sender}
   * @returns {!CurrencyGroupDeleteRequestModel} CurrencyGroupDeleteRequest model
   */
  get CurrencyGroupDeleteRequestModel () {
    return this._CurrencyGroupDeleteRequestModel
  }

  /**
   * Get CurrencyGroupDeleteResponse model
   * @this {!Sender}
   * @returns {!CurrencyGroupDeleteResponseModel} CurrencyGroupDeleteResponse model
   */
  get CurrencyGroupDeleteResponseModel () {
    return this._CurrencyGroupDeleteResponseModel
  }

  /**
   * Get CurrencyGroupUpdateNotify model
   * @this {!Sender}
   * @returns {!CurrencyGroupUpdateNotifyModel} CurrencyGroupUpdateNotify model
   */
  get CurrencyGroupUpdateNotifyModel () {
    return this._CurrencyGroupUpdateNotifyModel
  }

  /**
   * Get CurrencyGetRequest model
   * @this {!Sender}
   * @returns {!CurrencyGetRequestModel} CurrencyGetRequest model
   */
  get CurrencyGetRequestModel () {
    return this._CurrencyGetRequestModel
  }

  /**
   * Get CurrencyGetResponse model
   * @this {!Sender}
   * @returns {!CurrencyGetResponseModel} CurrencyGetResponse model
   */
  get CurrencyGetResponseModel () {
    return this._CurrencyGetResponseModel
  }

  /**
   * Get CurrenciesGetAllRequest model
   * @this {!Sender}
   * @returns {!CurrenciesGetAllRequestModel} CurrenciesGetAllRequest model
   */
  get CurrenciesGetAllRequestModel () {
    return this._CurrenciesGetAllRequestModel
  }

  /**
   * Get CurrenciesGetAllResponse model
   * @this {!Sender}
   * @returns {!CurrenciesGetAllResponseModel} CurrenciesGetAllResponse model
   */
  get CurrenciesGetAllResponseModel () {
    return this._CurrenciesGetAllResponseModel
  }

  /**
   * Get CurrencyCreateRequest model
   * @this {!Sender}
   * @returns {!CurrencyCreateRequestModel} CurrencyCreateRequest model
   */
  get CurrencyCreateRequestModel () {
    return this._CurrencyCreateRequestModel
  }

  /**
   * Get CurrencyCreateResponse model
   * @this {!Sender}
   * @returns {!CurrencyCreateResponseModel} CurrencyCreateResponse model
   */
  get CurrencyCreateResponseModel () {
    return this._CurrencyCreateResponseModel
  }

  /**
   * Get CurrencyModifyRequest model
   * @this {!Sender}
   * @returns {!CurrencyModifyRequestModel} CurrencyModifyRequest model
   */
  get CurrencyModifyRequestModel () {
    return this._CurrencyModifyRequestModel
  }

  /**
   * Get CurrencyModifyResponse model
   * @this {!Sender}
   * @returns {!CurrencyModifyResponseModel} CurrencyModifyResponse model
   */
  get CurrencyModifyResponseModel () {
    return this._CurrencyModifyResponseModel
  }

  /**
   * Get CurrencyDeleteRequest model
   * @this {!Sender}
   * @returns {!CurrencyDeleteRequestModel} CurrencyDeleteRequest model
   */
  get CurrencyDeleteRequestModel () {
    return this._CurrencyDeleteRequestModel
  }

  /**
   * Get CurrencyDeleteResponse model
   * @this {!Sender}
   * @returns {!CurrencyDeleteResponseModel} CurrencyDeleteResponse model
   */
  get CurrencyDeleteResponseModel () {
    return this._CurrencyDeleteResponseModel
  }

  /**
   * Get CurrencyUpdateNotify model
   * @this {!Sender}
   * @returns {!CurrencyUpdateNotifyModel} CurrencyUpdateNotify model
   */
  get CurrencyUpdateNotifyModel () {
    return this._CurrencyUpdateNotifyModel
  }

  /**
   * Get SymbolGroupGetRequest model
   * @this {!Sender}
   * @returns {!SymbolGroupGetRequestModel} SymbolGroupGetRequest model
   */
  get SymbolGroupGetRequestModel () {
    return this._SymbolGroupGetRequestModel
  }

  /**
   * Get SymbolGroupGetResponse model
   * @this {!Sender}
   * @returns {!SymbolGroupGetResponseModel} SymbolGroupGetResponse model
   */
  get SymbolGroupGetResponseModel () {
    return this._SymbolGroupGetResponseModel
  }

  /**
   * Get SymbolGroupsGetAllRequest model
   * @this {!Sender}
   * @returns {!SymbolGroupsGetAllRequestModel} SymbolGroupsGetAllRequest model
   */
  get SymbolGroupsGetAllRequestModel () {
    return this._SymbolGroupsGetAllRequestModel
  }

  /**
   * Get SymbolGroupsGetAllResponse model
   * @this {!Sender}
   * @returns {!SymbolGroupsGetAllResponseModel} SymbolGroupsGetAllResponse model
   */
  get SymbolGroupsGetAllResponseModel () {
    return this._SymbolGroupsGetAllResponseModel
  }

  /**
   * Get SymbolGroupCreateRequest model
   * @this {!Sender}
   * @returns {!SymbolGroupCreateRequestModel} SymbolGroupCreateRequest model
   */
  get SymbolGroupCreateRequestModel () {
    return this._SymbolGroupCreateRequestModel
  }

  /**
   * Get SymbolGroupCreateResponse model
   * @this {!Sender}
   * @returns {!SymbolGroupCreateResponseModel} SymbolGroupCreateResponse model
   */
  get SymbolGroupCreateResponseModel () {
    return this._SymbolGroupCreateResponseModel
  }

  /**
   * Get SymbolGroupModifyRequest model
   * @this {!Sender}
   * @returns {!SymbolGroupModifyRequestModel} SymbolGroupModifyRequest model
   */
  get SymbolGroupModifyRequestModel () {
    return this._SymbolGroupModifyRequestModel
  }

  /**
   * Get SymbolGroupModifyResponse model
   * @this {!Sender}
   * @returns {!SymbolGroupModifyResponseModel} SymbolGroupModifyResponse model
   */
  get SymbolGroupModifyResponseModel () {
    return this._SymbolGroupModifyResponseModel
  }

  /**
   * Get SymbolGroupDeleteRequest model
   * @this {!Sender}
   * @returns {!SymbolGroupDeleteRequestModel} SymbolGroupDeleteRequest model
   */
  get SymbolGroupDeleteRequestModel () {
    return this._SymbolGroupDeleteRequestModel
  }

  /**
   * Get SymbolGroupDeleteResponse model
   * @this {!Sender}
   * @returns {!SymbolGroupDeleteResponseModel} SymbolGroupDeleteResponse model
   */
  get SymbolGroupDeleteResponseModel () {
    return this._SymbolGroupDeleteResponseModel
  }

  /**
   * Get SymbolGroupUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolGroupUpdateNotifyModel} SymbolGroupUpdateNotify model
   */
  get SymbolGroupUpdateNotifyModel () {
    return this._SymbolGroupUpdateNotifyModel
  }

  /**
   * Get SymbolGetRequest model
   * @this {!Sender}
   * @returns {!SymbolGetRequestModel} SymbolGetRequest model
   */
  get SymbolGetRequestModel () {
    return this._SymbolGetRequestModel
  }

  /**
   * Get SymbolGetResponse model
   * @this {!Sender}
   * @returns {!SymbolGetResponseModel} SymbolGetResponse model
   */
  get SymbolGetResponseModel () {
    return this._SymbolGetResponseModel
  }

  /**
   * Get SymbolsGetAllRequest model
   * @this {!Sender}
   * @returns {!SymbolsGetAllRequestModel} SymbolsGetAllRequest model
   */
  get SymbolsGetAllRequestModel () {
    return this._SymbolsGetAllRequestModel
  }

  /**
   * Get SymbolsGetAllResponse model
   * @this {!Sender}
   * @returns {!SymbolsGetAllResponseModel} SymbolsGetAllResponse model
   */
  get SymbolsGetAllResponseModel () {
    return this._SymbolsGetAllResponseModel
  }

  /**
   * Get SymbolCreateRequest model
   * @this {!Sender}
   * @returns {!SymbolCreateRequestModel} SymbolCreateRequest model
   */
  get SymbolCreateRequestModel () {
    return this._SymbolCreateRequestModel
  }

  /**
   * Get SymbolCreateResponse model
   * @this {!Sender}
   * @returns {!SymbolCreateResponseModel} SymbolCreateResponse model
   */
  get SymbolCreateResponseModel () {
    return this._SymbolCreateResponseModel
  }

  /**
   * Get SymbolModifyRequest model
   * @this {!Sender}
   * @returns {!SymbolModifyRequestModel} SymbolModifyRequest model
   */
  get SymbolModifyRequestModel () {
    return this._SymbolModifyRequestModel
  }

  /**
   * Get SymbolModifyResponse model
   * @this {!Sender}
   * @returns {!SymbolModifyResponseModel} SymbolModifyResponse model
   */
  get SymbolModifyResponseModel () {
    return this._SymbolModifyResponseModel
  }

  /**
   * Get SymbolDeleteRequest model
   * @this {!Sender}
   * @returns {!SymbolDeleteRequestModel} SymbolDeleteRequest model
   */
  get SymbolDeleteRequestModel () {
    return this._SymbolDeleteRequestModel
  }

  /**
   * Get SymbolDeleteResponse model
   * @this {!Sender}
   * @returns {!SymbolDeleteResponseModel} SymbolDeleteResponse model
   */
  get SymbolDeleteResponseModel () {
    return this._SymbolDeleteResponseModel
  }

  /**
   * Get SymbolUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolUpdateNotifyModel} SymbolUpdateNotify model
   */
  get SymbolUpdateNotifyModel () {
    return this._SymbolUpdateNotifyModel
  }

  /**
   * Get SwapsExportRequest model
   * @this {!Sender}
   * @returns {!SwapsExportRequestModel} SwapsExportRequest model
   */
  get SwapsExportRequestModel () {
    return this._SwapsExportRequestModel
  }

  /**
   * Get SwapsExportResponse model
   * @this {!Sender}
   * @returns {!SwapsExportResponseModel} SwapsExportResponse model
   */
  get SwapsExportResponseModel () {
    return this._SwapsExportResponseModel
  }

  /**
   * Get SwapsImportRequest model
   * @this {!Sender}
   * @returns {!SwapsImportRequestModel} SwapsImportRequest model
   */
  get SwapsImportRequestModel () {
    return this._SwapsImportRequestModel
  }

  /**
   * Get SwapsImportResponse model
   * @this {!Sender}
   * @returns {!SwapsImportResponseModel} SwapsImportResponse model
   */
  get SwapsImportResponseModel () {
    return this._SwapsImportResponseModel
  }

  /**
   * Get CommissionProfileGetRequest model
   * @this {!Sender}
   * @returns {!CommissionProfileGetRequestModel} CommissionProfileGetRequest model
   */
  get CommissionProfileGetRequestModel () {
    return this._CommissionProfileGetRequestModel
  }

  /**
   * Get CommissionProfileGetResponse model
   * @this {!Sender}
   * @returns {!CommissionProfileGetResponseModel} CommissionProfileGetResponse model
   */
  get CommissionProfileGetResponseModel () {
    return this._CommissionProfileGetResponseModel
  }

  /**
   * Get CommissionProfilesGetAllRequest model
   * @this {!Sender}
   * @returns {!CommissionProfilesGetAllRequestModel} CommissionProfilesGetAllRequest model
   */
  get CommissionProfilesGetAllRequestModel () {
    return this._CommissionProfilesGetAllRequestModel
  }

  /**
   * Get CommissionProfilesGetAllResponse model
   * @this {!Sender}
   * @returns {!CommissionProfilesGetAllResponseModel} CommissionProfilesGetAllResponse model
   */
  get CommissionProfilesGetAllResponseModel () {
    return this._CommissionProfilesGetAllResponseModel
  }

  /**
   * Get CommissionProfileCreateRequest model
   * @this {!Sender}
   * @returns {!CommissionProfileCreateRequestModel} CommissionProfileCreateRequest model
   */
  get CommissionProfileCreateRequestModel () {
    return this._CommissionProfileCreateRequestModel
  }

  /**
   * Get CommissionProfileCreateResponse model
   * @this {!Sender}
   * @returns {!CommissionProfileCreateResponseModel} CommissionProfileCreateResponse model
   */
  get CommissionProfileCreateResponseModel () {
    return this._CommissionProfileCreateResponseModel
  }

  /**
   * Get CommissionProfileModifyRequest model
   * @this {!Sender}
   * @returns {!CommissionProfileModifyRequestModel} CommissionProfileModifyRequest model
   */
  get CommissionProfileModifyRequestModel () {
    return this._CommissionProfileModifyRequestModel
  }

  /**
   * Get CommissionProfileModifyResponse model
   * @this {!Sender}
   * @returns {!CommissionProfileModifyResponseModel} CommissionProfileModifyResponse model
   */
  get CommissionProfileModifyResponseModel () {
    return this._CommissionProfileModifyResponseModel
  }

  /**
   * Get CommissionProfileDeleteRequest model
   * @this {!Sender}
   * @returns {!CommissionProfileDeleteRequestModel} CommissionProfileDeleteRequest model
   */
  get CommissionProfileDeleteRequestModel () {
    return this._CommissionProfileDeleteRequestModel
  }

  /**
   * Get CommissionProfileDeleteResponse model
   * @this {!Sender}
   * @returns {!CommissionProfileDeleteResponseModel} CommissionProfileDeleteResponse model
   */
  get CommissionProfileDeleteResponseModel () {
    return this._CommissionProfileDeleteResponseModel
  }

  /**
   * Get CommissionProfileUpdateNotify model
   * @this {!Sender}
   * @returns {!CommissionProfileUpdateNotifyModel} CommissionProfileUpdateNotify model
   */
  get CommissionProfileUpdateNotifyModel () {
    return this._CommissionProfileUpdateNotifyModel
  }

  /**
   * Get MarkupProfileGetRequest model
   * @this {!Sender}
   * @returns {!MarkupProfileGetRequestModel} MarkupProfileGetRequest model
   */
  get MarkupProfileGetRequestModel () {
    return this._MarkupProfileGetRequestModel
  }

  /**
   * Get MarkupProfileGetResponse model
   * @this {!Sender}
   * @returns {!MarkupProfileGetResponseModel} MarkupProfileGetResponse model
   */
  get MarkupProfileGetResponseModel () {
    return this._MarkupProfileGetResponseModel
  }

  /**
   * Get MarkupProfilesGetAllRequest model
   * @this {!Sender}
   * @returns {!MarkupProfilesGetAllRequestModel} MarkupProfilesGetAllRequest model
   */
  get MarkupProfilesGetAllRequestModel () {
    return this._MarkupProfilesGetAllRequestModel
  }

  /**
   * Get MarkupProfilesGetAllResponse model
   * @this {!Sender}
   * @returns {!MarkupProfilesGetAllResponseModel} MarkupProfilesGetAllResponse model
   */
  get MarkupProfilesGetAllResponseModel () {
    return this._MarkupProfilesGetAllResponseModel
  }

  /**
   * Get MarkupProfileCreateRequest model
   * @this {!Sender}
   * @returns {!MarkupProfileCreateRequestModel} MarkupProfileCreateRequest model
   */
  get MarkupProfileCreateRequestModel () {
    return this._MarkupProfileCreateRequestModel
  }

  /**
   * Get MarkupProfileCreateResponse model
   * @this {!Sender}
   * @returns {!MarkupProfileCreateResponseModel} MarkupProfileCreateResponse model
   */
  get MarkupProfileCreateResponseModel () {
    return this._MarkupProfileCreateResponseModel
  }

  /**
   * Get MarkupProfileModifyRequest model
   * @this {!Sender}
   * @returns {!MarkupProfileModifyRequestModel} MarkupProfileModifyRequest model
   */
  get MarkupProfileModifyRequestModel () {
    return this._MarkupProfileModifyRequestModel
  }

  /**
   * Get MarkupProfileModifyResponse model
   * @this {!Sender}
   * @returns {!MarkupProfileModifyResponseModel} MarkupProfileModifyResponse model
   */
  get MarkupProfileModifyResponseModel () {
    return this._MarkupProfileModifyResponseModel
  }

  /**
   * Get MarkupProfileDeleteRequest model
   * @this {!Sender}
   * @returns {!MarkupProfileDeleteRequestModel} MarkupProfileDeleteRequest model
   */
  get MarkupProfileDeleteRequestModel () {
    return this._MarkupProfileDeleteRequestModel
  }

  /**
   * Get MarkupProfileDeleteResponse model
   * @this {!Sender}
   * @returns {!MarkupProfileDeleteResponseModel} MarkupProfileDeleteResponse model
   */
  get MarkupProfileDeleteResponseModel () {
    return this._MarkupProfileDeleteResponseModel
  }

  /**
   * Get MarkupProfileUpdateNotify model
   * @this {!Sender}
   * @returns {!MarkupProfileUpdateNotifyModel} MarkupProfileUpdateNotify model
   */
  get MarkupProfileUpdateNotifyModel () {
    return this._MarkupProfileUpdateNotifyModel
  }

  /**
   * Get ManagerGroupGetRequest model
   * @this {!Sender}
   * @returns {!ManagerGroupGetRequestModel} ManagerGroupGetRequest model
   */
  get ManagerGroupGetRequestModel () {
    return this._ManagerGroupGetRequestModel
  }

  /**
   * Get ManagerGroupGetResponse model
   * @this {!Sender}
   * @returns {!ManagerGroupGetResponseModel} ManagerGroupGetResponse model
   */
  get ManagerGroupGetResponseModel () {
    return this._ManagerGroupGetResponseModel
  }

  /**
   * Get ManagerGroupsGetAllRequest model
   * @this {!Sender}
   * @returns {!ManagerGroupsGetAllRequestModel} ManagerGroupsGetAllRequest model
   */
  get ManagerGroupsGetAllRequestModel () {
    return this._ManagerGroupsGetAllRequestModel
  }

  /**
   * Get ManagerGroupsGetAllResponse model
   * @this {!Sender}
   * @returns {!ManagerGroupsGetAllResponseModel} ManagerGroupsGetAllResponse model
   */
  get ManagerGroupsGetAllResponseModel () {
    return this._ManagerGroupsGetAllResponseModel
  }

  /**
   * Get ManagerGroupCreateRequest model
   * @this {!Sender}
   * @returns {!ManagerGroupCreateRequestModel} ManagerGroupCreateRequest model
   */
  get ManagerGroupCreateRequestModel () {
    return this._ManagerGroupCreateRequestModel
  }

  /**
   * Get ManagerGroupCreateResponse model
   * @this {!Sender}
   * @returns {!ManagerGroupCreateResponseModel} ManagerGroupCreateResponse model
   */
  get ManagerGroupCreateResponseModel () {
    return this._ManagerGroupCreateResponseModel
  }

  /**
   * Get ManagerGroupModifyRequest model
   * @this {!Sender}
   * @returns {!ManagerGroupModifyRequestModel} ManagerGroupModifyRequest model
   */
  get ManagerGroupModifyRequestModel () {
    return this._ManagerGroupModifyRequestModel
  }

  /**
   * Get ManagerGroupModifyResponse model
   * @this {!Sender}
   * @returns {!ManagerGroupModifyResponseModel} ManagerGroupModifyResponse model
   */
  get ManagerGroupModifyResponseModel () {
    return this._ManagerGroupModifyResponseModel
  }

  /**
   * Get ManagerGroupDeleteRequest model
   * @this {!Sender}
   * @returns {!ManagerGroupDeleteRequestModel} ManagerGroupDeleteRequest model
   */
  get ManagerGroupDeleteRequestModel () {
    return this._ManagerGroupDeleteRequestModel
  }

  /**
   * Get ManagerGroupDeleteResponse model
   * @this {!Sender}
   * @returns {!ManagerGroupDeleteResponseModel} ManagerGroupDeleteResponse model
   */
  get ManagerGroupDeleteResponseModel () {
    return this._ManagerGroupDeleteResponseModel
  }

  /**
   * Get ManagerGroupUpdateNotify model
   * @this {!Sender}
   * @returns {!ManagerGroupUpdateNotifyModel} ManagerGroupUpdateNotify model
   */
  get ManagerGroupUpdateNotifyModel () {
    return this._ManagerGroupUpdateNotifyModel
  }

  /**
   * Get ManagerGetRequest model
   * @this {!Sender}
   * @returns {!ManagerGetRequestModel} ManagerGetRequest model
   */
  get ManagerGetRequestModel () {
    return this._ManagerGetRequestModel
  }

  /**
   * Get ManagerGetResponse model
   * @this {!Sender}
   * @returns {!ManagerGetResponseModel} ManagerGetResponse model
   */
  get ManagerGetResponseModel () {
    return this._ManagerGetResponseModel
  }

  /**
   * Get ManagersGetAllRequest model
   * @this {!Sender}
   * @returns {!ManagersGetAllRequestModel} ManagersGetAllRequest model
   */
  get ManagersGetAllRequestModel () {
    return this._ManagersGetAllRequestModel
  }

  /**
   * Get ManagersGetAllResponse model
   * @this {!Sender}
   * @returns {!ManagersGetAllResponseModel} ManagersGetAllResponse model
   */
  get ManagersGetAllResponseModel () {
    return this._ManagersGetAllResponseModel
  }

  /**
   * Get ManagerCreateRequest model
   * @this {!Sender}
   * @returns {!ManagerCreateRequestModel} ManagerCreateRequest model
   */
  get ManagerCreateRequestModel () {
    return this._ManagerCreateRequestModel
  }

  /**
   * Get ManagerCreateResponse model
   * @this {!Sender}
   * @returns {!ManagerCreateResponseModel} ManagerCreateResponse model
   */
  get ManagerCreateResponseModel () {
    return this._ManagerCreateResponseModel
  }

  /**
   * Get ManagerModifyRequest model
   * @this {!Sender}
   * @returns {!ManagerModifyRequestModel} ManagerModifyRequest model
   */
  get ManagerModifyRequestModel () {
    return this._ManagerModifyRequestModel
  }

  /**
   * Get ManagerModifyResponse model
   * @this {!Sender}
   * @returns {!ManagerModifyResponseModel} ManagerModifyResponse model
   */
  get ManagerModifyResponseModel () {
    return this._ManagerModifyResponseModel
  }

  /**
   * Get ManagerDeleteRequest model
   * @this {!Sender}
   * @returns {!ManagerDeleteRequestModel} ManagerDeleteRequest model
   */
  get ManagerDeleteRequestModel () {
    return this._ManagerDeleteRequestModel
  }

  /**
   * Get ManagerDeleteResponse model
   * @this {!Sender}
   * @returns {!ManagerDeleteResponseModel} ManagerDeleteResponse model
   */
  get ManagerDeleteResponseModel () {
    return this._ManagerDeleteResponseModel
  }

  /**
   * Get ManagerUpdateNotify model
   * @this {!Sender}
   * @returns {!ManagerUpdateNotifyModel} ManagerUpdateNotify model
   */
  get ManagerUpdateNotifyModel () {
    return this._ManagerUpdateNotifyModel
  }

  /**
   * Get PlatformGetRequest model
   * @this {!Sender}
   * @returns {!PlatformGetRequestModel} PlatformGetRequest model
   */
  get PlatformGetRequestModel () {
    return this._PlatformGetRequestModel
  }

  /**
   * Get PlatformGetResponse model
   * @this {!Sender}
   * @returns {!PlatformGetResponseModel} PlatformGetResponse model
   */
  get PlatformGetResponseModel () {
    return this._PlatformGetResponseModel
  }

  /**
   * Get PlatformsGetAllRequest model
   * @this {!Sender}
   * @returns {!PlatformsGetAllRequestModel} PlatformsGetAllRequest model
   */
  get PlatformsGetAllRequestModel () {
    return this._PlatformsGetAllRequestModel
  }

  /**
   * Get PlatformsGetAllResponse model
   * @this {!Sender}
   * @returns {!PlatformsGetAllResponseModel} PlatformsGetAllResponse model
   */
  get PlatformsGetAllResponseModel () {
    return this._PlatformsGetAllResponseModel
  }

  /**
   * Get PlatformCreateRequest model
   * @this {!Sender}
   * @returns {!PlatformCreateRequestModel} PlatformCreateRequest model
   */
  get PlatformCreateRequestModel () {
    return this._PlatformCreateRequestModel
  }

  /**
   * Get PlatformCreateResponse model
   * @this {!Sender}
   * @returns {!PlatformCreateResponseModel} PlatformCreateResponse model
   */
  get PlatformCreateResponseModel () {
    return this._PlatformCreateResponseModel
  }

  /**
   * Get PlatformModifyRequest model
   * @this {!Sender}
   * @returns {!PlatformModifyRequestModel} PlatformModifyRequest model
   */
  get PlatformModifyRequestModel () {
    return this._PlatformModifyRequestModel
  }

  /**
   * Get PlatformModifyResponse model
   * @this {!Sender}
   * @returns {!PlatformModifyResponseModel} PlatformModifyResponse model
   */
  get PlatformModifyResponseModel () {
    return this._PlatformModifyResponseModel
  }

  /**
   * Get PlatformDeleteRequest model
   * @this {!Sender}
   * @returns {!PlatformDeleteRequestModel} PlatformDeleteRequest model
   */
  get PlatformDeleteRequestModel () {
    return this._PlatformDeleteRequestModel
  }

  /**
   * Get PlatformDeleteResponse model
   * @this {!Sender}
   * @returns {!PlatformDeleteResponseModel} PlatformDeleteResponse model
   */
  get PlatformDeleteResponseModel () {
    return this._PlatformDeleteResponseModel
  }

  /**
   * Get PlatformUpdateNotify model
   * @this {!Sender}
   * @returns {!PlatformUpdateNotifyModel} PlatformUpdateNotify model
   */
  get PlatformUpdateNotifyModel () {
    return this._PlatformUpdateNotifyModel
  }

  /**
   * Get AccountGroupGetRequest model
   * @this {!Sender}
   * @returns {!AccountGroupGetRequestModel} AccountGroupGetRequest model
   */
  get AccountGroupGetRequestModel () {
    return this._AccountGroupGetRequestModel
  }

  /**
   * Get AccountGroupGetResponse model
   * @this {!Sender}
   * @returns {!AccountGroupGetResponseModel} AccountGroupGetResponse model
   */
  get AccountGroupGetResponseModel () {
    return this._AccountGroupGetResponseModel
  }

  /**
   * Get AccountGroupsGetAllRequest model
   * @this {!Sender}
   * @returns {!AccountGroupsGetAllRequestModel} AccountGroupsGetAllRequest model
   */
  get AccountGroupsGetAllRequestModel () {
    return this._AccountGroupsGetAllRequestModel
  }

  /**
   * Get AccountGroupsGetAllResponse model
   * @this {!Sender}
   * @returns {!AccountGroupsGetAllResponseModel} AccountGroupsGetAllResponse model
   */
  get AccountGroupsGetAllResponseModel () {
    return this._AccountGroupsGetAllResponseModel
  }

  /**
   * Get AccountGroupCreateRequest model
   * @this {!Sender}
   * @returns {!AccountGroupCreateRequestModel} AccountGroupCreateRequest model
   */
  get AccountGroupCreateRequestModel () {
    return this._AccountGroupCreateRequestModel
  }

  /**
   * Get AccountGroupCreateResponse model
   * @this {!Sender}
   * @returns {!AccountGroupCreateResponseModel} AccountGroupCreateResponse model
   */
  get AccountGroupCreateResponseModel () {
    return this._AccountGroupCreateResponseModel
  }

  /**
   * Get AccountGroupModifyRequest model
   * @this {!Sender}
   * @returns {!AccountGroupModifyRequestModel} AccountGroupModifyRequest model
   */
  get AccountGroupModifyRequestModel () {
    return this._AccountGroupModifyRequestModel
  }

  /**
   * Get AccountGroupModifyResponse model
   * @this {!Sender}
   * @returns {!AccountGroupModifyResponseModel} AccountGroupModifyResponse model
   */
  get AccountGroupModifyResponseModel () {
    return this._AccountGroupModifyResponseModel
  }

  /**
   * Get AccountGroupDeleteRequest model
   * @this {!Sender}
   * @returns {!AccountGroupDeleteRequestModel} AccountGroupDeleteRequest model
   */
  get AccountGroupDeleteRequestModel () {
    return this._AccountGroupDeleteRequestModel
  }

  /**
   * Get AccountGroupDeleteResponse model
   * @this {!Sender}
   * @returns {!AccountGroupDeleteResponseModel} AccountGroupDeleteResponse model
   */
  get AccountGroupDeleteResponseModel () {
    return this._AccountGroupDeleteResponseModel
  }

  /**
   * Get AccountGroupUpdateNotify model
   * @this {!Sender}
   * @returns {!AccountGroupUpdateNotifyModel} AccountGroupUpdateNotify model
   */
  get AccountGroupUpdateNotifyModel () {
    return this._AccountGroupUpdateNotifyModel
  }

  /**
   * Get AccountGetRequest model
   * @this {!Sender}
   * @returns {!AccountGetRequestModel} AccountGetRequest model
   */
  get AccountGetRequestModel () {
    return this._AccountGetRequestModel
  }

  /**
   * Get AccountGetResponse model
   * @this {!Sender}
   * @returns {!AccountGetResponseModel} AccountGetResponse model
   */
  get AccountGetResponseModel () {
    return this._AccountGetResponseModel
  }

  /**
   * Get AccountsGetAllRequest model
   * @this {!Sender}
   * @returns {!AccountsGetAllRequestModel} AccountsGetAllRequest model
   */
  get AccountsGetAllRequestModel () {
    return this._AccountsGetAllRequestModel
  }

  /**
   * Get AccountsGetAllResponse model
   * @this {!Sender}
   * @returns {!AccountsGetAllResponseModel} AccountsGetAllResponse model
   */
  get AccountsGetAllResponseModel () {
    return this._AccountsGetAllResponseModel
  }

  /**
   * Get AccountCreateRequest model
   * @this {!Sender}
   * @returns {!AccountCreateRequestModel} AccountCreateRequest model
   */
  get AccountCreateRequestModel () {
    return this._AccountCreateRequestModel
  }

  /**
   * Get AccountCreateResponse model
   * @this {!Sender}
   * @returns {!AccountCreateResponseModel} AccountCreateResponse model
   */
  get AccountCreateResponseModel () {
    return this._AccountCreateResponseModel
  }

  /**
   * Get AccountSocialTradingMasterCreateRequest model
   * @this {!Sender}
   * @returns {!AccountSocialTradingMasterCreateRequestModel} AccountSocialTradingMasterCreateRequest model
   */
  get AccountSocialTradingMasterCreateRequestModel () {
    return this._AccountSocialTradingMasterCreateRequestModel
  }

  /**
   * Get AccountSocialTradingMasterCreateResponse model
   * @this {!Sender}
   * @returns {!AccountSocialTradingMasterCreateResponseModel} AccountSocialTradingMasterCreateResponse model
   */
  get AccountSocialTradingMasterCreateResponseModel () {
    return this._AccountSocialTradingMasterCreateResponseModel
  }

  /**
   * Get AccountSocialTradingInvestorCreateRequest model
   * @this {!Sender}
   * @returns {!AccountSocialTradingInvestorCreateRequestModel} AccountSocialTradingInvestorCreateRequest model
   */
  get AccountSocialTradingInvestorCreateRequestModel () {
    return this._AccountSocialTradingInvestorCreateRequestModel
  }

  /**
   * Get AccountSocialTradingInvestorCreateResponse model
   * @this {!Sender}
   * @returns {!AccountSocialTradingInvestorCreateResponseModel} AccountSocialTradingInvestorCreateResponse model
   */
  get AccountSocialTradingInvestorCreateResponseModel () {
    return this._AccountSocialTradingInvestorCreateResponseModel
  }

  /**
   * Get AccountModifyRequest model
   * @this {!Sender}
   * @returns {!AccountModifyRequestModel} AccountModifyRequest model
   */
  get AccountModifyRequestModel () {
    return this._AccountModifyRequestModel
  }

  /**
   * Get AccountModifyResponse model
   * @this {!Sender}
   * @returns {!AccountModifyResponseModel} AccountModifyResponse model
   */
  get AccountModifyResponseModel () {
    return this._AccountModifyResponseModel
  }

  /**
   * Get AccountDeleteRequest model
   * @this {!Sender}
   * @returns {!AccountDeleteRequestModel} AccountDeleteRequest model
   */
  get AccountDeleteRequestModel () {
    return this._AccountDeleteRequestModel
  }

  /**
   * Get AccountDeleteResponse model
   * @this {!Sender}
   * @returns {!AccountDeleteResponseModel} AccountDeleteResponse model
   */
  get AccountDeleteResponseModel () {
    return this._AccountDeleteResponseModel
  }

  /**
   * Get DemoAccountsDeleteRequest model
   * @this {!Sender}
   * @returns {!DemoAccountsDeleteRequestModel} DemoAccountsDeleteRequest model
   */
  get DemoAccountsDeleteRequestModel () {
    return this._DemoAccountsDeleteRequestModel
  }

  /**
   * Get DemoAccountsDeleteResponse model
   * @this {!Sender}
   * @returns {!DemoAccountsDeleteResponseModel} DemoAccountsDeleteResponse model
   */
  get DemoAccountsDeleteResponseModel () {
    return this._DemoAccountsDeleteResponseModel
  }

  /**
   * Get AccountUpgradeToSocialTradingMasterRequest model
   * @this {!Sender}
   * @returns {!AccountUpgradeToSocialTradingMasterRequestModel} AccountUpgradeToSocialTradingMasterRequest model
   */
  get AccountUpgradeToSocialTradingMasterRequestModel () {
    return this._AccountUpgradeToSocialTradingMasterRequestModel
  }

  /**
   * Get AccountUpgradeToSocialTradingMasterResponse model
   * @this {!Sender}
   * @returns {!AccountUpgradeToSocialTradingMasterResponseModel} AccountUpgradeToSocialTradingMasterResponse model
   */
  get AccountUpgradeToSocialTradingMasterResponseModel () {
    return this._AccountUpgradeToSocialTradingMasterResponseModel
  }

  /**
   * Get AccountUpgradeToSocialTradingInvestorRequest model
   * @this {!Sender}
   * @returns {!AccountUpgradeToSocialTradingInvestorRequestModel} AccountUpgradeToSocialTradingInvestorRequest model
   */
  get AccountUpgradeToSocialTradingInvestorRequestModel () {
    return this._AccountUpgradeToSocialTradingInvestorRequestModel
  }

  /**
   * Get AccountUpgradeToSocialTradingInvestorResponse model
   * @this {!Sender}
   * @returns {!AccountUpgradeToSocialTradingInvestorResponseModel} AccountUpgradeToSocialTradingInvestorResponse model
   */
  get AccountUpgradeToSocialTradingInvestorResponseModel () {
    return this._AccountUpgradeToSocialTradingInvestorResponseModel
  }

  /**
   * Get SocialTradingInvestorAccountToRegularRequest model
   * @this {!Sender}
   * @returns {!SocialTradingInvestorAccountToRegularRequestModel} SocialTradingInvestorAccountToRegularRequest model
   */
  get SocialTradingInvestorAccountToRegularRequestModel () {
    return this._SocialTradingInvestorAccountToRegularRequestModel
  }

  /**
   * Get SocialTradingInvestorAccountToRegularResponse model
   * @this {!Sender}
   * @returns {!SocialTradingInvestorAccountToRegularResponseModel} SocialTradingInvestorAccountToRegularResponse model
   */
  get SocialTradingInvestorAccountToRegularResponseModel () {
    return this._SocialTradingInvestorAccountToRegularResponseModel
  }

  /**
   * Get SocialTradingMasterAccountToRegularRequest model
   * @this {!Sender}
   * @returns {!SocialTradingMasterAccountToRegularRequestModel} SocialTradingMasterAccountToRegularRequest model
   */
  get SocialTradingMasterAccountToRegularRequestModel () {
    return this._SocialTradingMasterAccountToRegularRequestModel
  }

  /**
   * Get SocialTradingMasterAccountToRegularResponse model
   * @this {!Sender}
   * @returns {!SocialTradingMasterAccountToRegularResponseModel} SocialTradingMasterAccountToRegularResponse model
   */
  get SocialTradingMasterAccountToRegularResponseModel () {
    return this._SocialTradingMasterAccountToRegularResponseModel
  }

  /**
   * Get AccountUpdateNotify model
   * @this {!Sender}
   * @returns {!AccountUpdateNotifyModel} AccountUpdateNotify model
   */
  get AccountUpdateNotifyModel () {
    return this._AccountUpdateNotifyModel
  }

  /**
   * Get MarginGetRequest model
   * @this {!Sender}
   * @returns {!MarginGetRequestModel} MarginGetRequest model
   */
  get MarginGetRequestModel () {
    return this._MarginGetRequestModel
  }

  /**
   * Get MarginGetResponse model
   * @this {!Sender}
   * @returns {!MarginGetResponseModel} MarginGetResponse model
   */
  get MarginGetResponseModel () {
    return this._MarginGetResponseModel
  }

  /**
   * Get MarginsGetAllRequest model
   * @this {!Sender}
   * @returns {!MarginsGetAllRequestModel} MarginsGetAllRequest model
   */
  get MarginsGetAllRequestModel () {
    return this._MarginsGetAllRequestModel
  }

  /**
   * Get MarginsGetAllResponse model
   * @this {!Sender}
   * @returns {!MarginsGetAllResponseModel} MarginsGetAllResponse model
   */
  get MarginsGetAllResponseModel () {
    return this._MarginsGetAllResponseModel
  }

  /**
   * Get MarginUpdateNotify model
   * @this {!Sender}
   * @returns {!MarginUpdateNotifyModel} MarginUpdateNotify model
   */
  get MarginUpdateNotifyModel () {
    return this._MarginUpdateNotifyModel
  }

  /**
   * Get MarginCallNotify model
   * @this {!Sender}
   * @returns {!MarginCallNotifyModel} MarginCallNotify model
   */
  get MarginCallNotifyModel () {
    return this._MarginCallNotifyModel
  }

  /**
   * Get StopOutNotify model
   * @this {!Sender}
   * @returns {!StopOutNotifyModel} StopOutNotify model
   */
  get StopOutNotifyModel () {
    return this._StopOutNotifyModel
  }

  /**
   * Get AssetGetRequest model
   * @this {!Sender}
   * @returns {!AssetGetRequestModel} AssetGetRequest model
   */
  get AssetGetRequestModel () {
    return this._AssetGetRequestModel
  }

  /**
   * Get AssetGetResponse model
   * @this {!Sender}
   * @returns {!AssetGetResponseModel} AssetGetResponse model
   */
  get AssetGetResponseModel () {
    return this._AssetGetResponseModel
  }

  /**
   * Get AssetsGetRequest model
   * @this {!Sender}
   * @returns {!AssetsGetRequestModel} AssetsGetRequest model
   */
  get AssetsGetRequestModel () {
    return this._AssetsGetRequestModel
  }

  /**
   * Get AssetsGetResponse model
   * @this {!Sender}
   * @returns {!AssetsGetResponseModel} AssetsGetResponse model
   */
  get AssetsGetResponseModel () {
    return this._AssetsGetResponseModel
  }

  /**
   * Get AssetsGetAllRequest model
   * @this {!Sender}
   * @returns {!AssetsGetAllRequestModel} AssetsGetAllRequest model
   */
  get AssetsGetAllRequestModel () {
    return this._AssetsGetAllRequestModel
  }

  /**
   * Get AssetsGetAllResponse model
   * @this {!Sender}
   * @returns {!AssetsGetAllResponseModel} AssetsGetAllResponse model
   */
  get AssetsGetAllResponseModel () {
    return this._AssetsGetAllResponseModel
  }

  /**
   * Get AssetUpdateNotify model
   * @this {!Sender}
   * @returns {!AssetUpdateNotifyModel} AssetUpdateNotify model
   */
  get AssetUpdateNotifyModel () {
    return this._AssetUpdateNotifyModel
  }

  /**
   * Get OrderGetRequest model
   * @this {!Sender}
   * @returns {!OrderGetRequestModel} OrderGetRequest model
   */
  get OrderGetRequestModel () {
    return this._OrderGetRequestModel
  }

  /**
   * Get OrderGetResponse model
   * @this {!Sender}
   * @returns {!OrderGetResponseModel} OrderGetResponse model
   */
  get OrderGetResponseModel () {
    return this._OrderGetResponseModel
  }

  /**
   * Get OrdersGetRequest model
   * @this {!Sender}
   * @returns {!OrdersGetRequestModel} OrdersGetRequest model
   */
  get OrdersGetRequestModel () {
    return this._OrdersGetRequestModel
  }

  /**
   * Get OrdersGetResponse model
   * @this {!Sender}
   * @returns {!OrdersGetResponseModel} OrdersGetResponse model
   */
  get OrdersGetResponseModel () {
    return this._OrdersGetResponseModel
  }

  /**
   * Get OrdersGetAllRequest model
   * @this {!Sender}
   * @returns {!OrdersGetAllRequestModel} OrdersGetAllRequest model
   */
  get OrdersGetAllRequestModel () {
    return this._OrdersGetAllRequestModel
  }

  /**
   * Get OrdersGetAllResponse model
   * @this {!Sender}
   * @returns {!OrdersGetAllResponseModel} OrdersGetAllResponse model
   */
  get OrdersGetAllResponseModel () {
    return this._OrdersGetAllResponseModel
  }

  /**
   * Get OrderCreateRequest model
   * @this {!Sender}
   * @returns {!OrderCreateRequestModel} OrderCreateRequest model
   */
  get OrderCreateRequestModel () {
    return this._OrderCreateRequestModel
  }

  /**
   * Get OrderCreateResponse model
   * @this {!Sender}
   * @returns {!OrderCreateResponseModel} OrderCreateResponse model
   */
  get OrderCreateResponseModel () {
    return this._OrderCreateResponseModel
  }

  /**
   * Get OrderModifyRequest model
   * @this {!Sender}
   * @returns {!OrderModifyRequestModel} OrderModifyRequest model
   */
  get OrderModifyRequestModel () {
    return this._OrderModifyRequestModel
  }

  /**
   * Get OrderModifyResponse model
   * @this {!Sender}
   * @returns {!OrderModifyResponseModel} OrderModifyResponse model
   */
  get OrderModifyResponseModel () {
    return this._OrderModifyResponseModel
  }

  /**
   * Get OrderActivateRequest model
   * @this {!Sender}
   * @returns {!OrderActivateRequestModel} OrderActivateRequest model
   */
  get OrderActivateRequestModel () {
    return this._OrderActivateRequestModel
  }

  /**
   * Get OrderActivateResponse model
   * @this {!Sender}
   * @returns {!OrderActivateResponseModel} OrderActivateResponse model
   */
  get OrderActivateResponseModel () {
    return this._OrderActivateResponseModel
  }

  /**
   * Get OrderCancelRequest model
   * @this {!Sender}
   * @returns {!OrderCancelRequestModel} OrderCancelRequest model
   */
  get OrderCancelRequestModel () {
    return this._OrderCancelRequestModel
  }

  /**
   * Get OrderCancelResponse model
   * @this {!Sender}
   * @returns {!OrderCancelResponseModel} OrderCancelResponse model
   */
  get OrderCancelResponseModel () {
    return this._OrderCancelResponseModel
  }

  /**
   * Get OrderCancelAllRequest model
   * @this {!Sender}
   * @returns {!OrderCancelAllRequestModel} OrderCancelAllRequest model
   */
  get OrderCancelAllRequestModel () {
    return this._OrderCancelAllRequestModel
  }

  /**
   * Get OrderCancelAllResponse model
   * @this {!Sender}
   * @returns {!OrderCancelAllResponseModel} OrderCancelAllResponse model
   */
  get OrderCancelAllResponseModel () {
    return this._OrderCancelAllResponseModel
  }

  /**
   * Get OrderCancelAllByIdRequest model
   * @this {!Sender}
   * @returns {!OrderCancelAllByIdRequestModel} OrderCancelAllByIdRequest model
   */
  get OrderCancelAllByIdRequestModel () {
    return this._OrderCancelAllByIdRequestModel
  }

  /**
   * Get OrderCloseRequest model
   * @this {!Sender}
   * @returns {!OrderCloseRequestModel} OrderCloseRequest model
   */
  get OrderCloseRequestModel () {
    return this._OrderCloseRequestModel
  }

  /**
   * Get OrderCloseResponse model
   * @this {!Sender}
   * @returns {!OrderCloseResponseModel} OrderCloseResponse model
   */
  get OrderCloseResponseModel () {
    return this._OrderCloseResponseModel
  }

  /**
   * Get OrderCloseAllRequest model
   * @this {!Sender}
   * @returns {!OrderCloseAllRequestModel} OrderCloseAllRequest model
   */
  get OrderCloseAllRequestModel () {
    return this._OrderCloseAllRequestModel
  }

  /**
   * Get OrderCloseAllResponse model
   * @this {!Sender}
   * @returns {!OrderCloseAllResponseModel} OrderCloseAllResponse model
   */
  get OrderCloseAllResponseModel () {
    return this._OrderCloseAllResponseModel
  }

  /**
   * Get OrderCloseAllByIdRequest model
   * @this {!Sender}
   * @returns {!OrderCloseAllByIdRequestModel} OrderCloseAllByIdRequest model
   */
  get OrderCloseAllByIdRequestModel () {
    return this._OrderCloseAllByIdRequestModel
  }

  /**
   * Get OrderCloseByRequest model
   * @this {!Sender}
   * @returns {!OrderCloseByRequestModel} OrderCloseByRequest model
   */
  get OrderCloseByRequestModel () {
    return this._OrderCloseByRequestModel
  }

  /**
   * Get OrderCloseByResponse model
   * @this {!Sender}
   * @returns {!OrderCloseByResponseModel} OrderCloseByResponse model
   */
  get OrderCloseByResponseModel () {
    return this._OrderCloseByResponseModel
  }

  /**
   * Get OrderCloseByAllRequest model
   * @this {!Sender}
   * @returns {!OrderCloseByAllRequestModel} OrderCloseByAllRequest model
   */
  get OrderCloseByAllRequestModel () {
    return this._OrderCloseByAllRequestModel
  }

  /**
   * Get OrderCloseByAllResponse model
   * @this {!Sender}
   * @returns {!OrderCloseByAllResponseModel} OrderCloseByAllResponse model
   */
  get OrderCloseByAllResponseModel () {
    return this._OrderCloseByAllResponseModel
  }

  /**
   * Get OrderCancelOrCloseRequest model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseRequestModel} OrderCancelOrCloseRequest model
   */
  get OrderCancelOrCloseRequestModel () {
    return this._OrderCancelOrCloseRequestModel
  }

  /**
   * Get OrderCancelOrCloseResponse model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseResponseModel} OrderCancelOrCloseResponse model
   */
  get OrderCancelOrCloseResponseModel () {
    return this._OrderCancelOrCloseResponseModel
  }

  /**
   * Get OrderCancelOrCloseAllRequest model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseAllRequestModel} OrderCancelOrCloseAllRequest model
   */
  get OrderCancelOrCloseAllRequestModel () {
    return this._OrderCancelOrCloseAllRequestModel
  }

  /**
   * Get OrderCancelOrCloseAllResponse model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseAllResponseModel} OrderCancelOrCloseAllResponse model
   */
  get OrderCancelOrCloseAllResponseModel () {
    return this._OrderCancelOrCloseAllResponseModel
  }

  /**
   * Get OrderUpdateNotify model
   * @this {!Sender}
   * @returns {!OrderUpdateNotifyModel} OrderUpdateNotify model
   */
  get OrderUpdateNotifyModel () {
    return this._OrderUpdateNotifyModel
  }

  /**
   * Get PositionGetRequest model
   * @this {!Sender}
   * @returns {!PositionGetRequestModel} PositionGetRequest model
   */
  get PositionGetRequestModel () {
    return this._PositionGetRequestModel
  }

  /**
   * Get PositionGetResponse model
   * @this {!Sender}
   * @returns {!PositionGetResponseModel} PositionGetResponse model
   */
  get PositionGetResponseModel () {
    return this._PositionGetResponseModel
  }

  /**
   * Get PositionsGetRequest model
   * @this {!Sender}
   * @returns {!PositionsGetRequestModel} PositionsGetRequest model
   */
  get PositionsGetRequestModel () {
    return this._PositionsGetRequestModel
  }

  /**
   * Get PositionsGetResponse model
   * @this {!Sender}
   * @returns {!PositionsGetResponseModel} PositionsGetResponse model
   */
  get PositionsGetResponseModel () {
    return this._PositionsGetResponseModel
  }

  /**
   * Get PositionsGetAllRequest model
   * @this {!Sender}
   * @returns {!PositionsGetAllRequestModel} PositionsGetAllRequest model
   */
  get PositionsGetAllRequestModel () {
    return this._PositionsGetAllRequestModel
  }

  /**
   * Get PositionsGetAllResponse model
   * @this {!Sender}
   * @returns {!PositionsGetAllResponseModel} PositionsGetAllResponse model
   */
  get PositionsGetAllResponseModel () {
    return this._PositionsGetAllResponseModel
  }

  /**
   * Get PositionUpdateNotify model
   * @this {!Sender}
   * @returns {!PositionUpdateNotifyModel} PositionUpdateNotify model
   */
  get PositionUpdateNotifyModel () {
    return this._PositionUpdateNotifyModel
  }

  /**
   * Get TradeHistoryRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryRequestModel} TradeHistoryRequest model
   */
  get TradeHistoryRequestModel () {
    return this._TradeHistoryRequestModel
  }

  /**
   * Get TradeHistoryResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryResponseModel} TradeHistoryResponse model
   */
  get TradeHistoryResponseModel () {
    return this._TradeHistoryResponseModel
  }

  /**
   * Get TradeHistoryFilterRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryFilterRequestModel} TradeHistoryFilterRequest model
   */
  get TradeHistoryFilterRequestModel () {
    return this._TradeHistoryFilterRequestModel
  }

  /**
   * Get TradeHistoryByIdRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryByIdRequestModel} TradeHistoryByIdRequest model
   */
  get TradeHistoryByIdRequestModel () {
    return this._TradeHistoryByIdRequestModel
  }

  /**
   * Get TradeHistoryByIdResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryByIdResponseModel} TradeHistoryByIdResponse model
   */
  get TradeHistoryByIdResponseModel () {
    return this._TradeHistoryByIdResponseModel
  }

  /**
   * Get TradeHistoryUpdateNotify model
   * @this {!Sender}
   * @returns {!TradeHistoryUpdateNotifyModel} TradeHistoryUpdateNotify model
   */
  get TradeHistoryUpdateNotifyModel () {
    return this._TradeHistoryUpdateNotifyModel
  }

  /**
   * Get TestRolloverDumpRequest model
   * @this {!Sender}
   * @returns {!TestRolloverDumpRequestModel} TestRolloverDumpRequest model
   */
  get TestRolloverDumpRequestModel () {
    return this._TestRolloverDumpRequestModel
  }

  /**
   * Get TestRolloverDumpResponse model
   * @this {!Sender}
   * @returns {!TestRolloverDumpResponseModel} TestRolloverDumpResponse model
   */
  get TestRolloverDumpResponseModel () {
    return this._TestRolloverDumpResponseModel
  }

  /**
   * Get Deprecated001 model
   * @this {!Sender}
   * @returns {!Deprecated001Model} Deprecated001 model
   */
  get Deprecated001Model () {
    return this._Deprecated001Model
  }

  /**
   * Get Deprecated002 model
   * @this {!Sender}
   * @returns {!Deprecated002Model} Deprecated002 model
   */
  get Deprecated002Model () {
    return this._Deprecated002Model
  }

  /**
   * Get Deprecated003 model
   * @this {!Sender}
   * @returns {!Deprecated003Model} Deprecated003 model
   */
  get Deprecated003Model () {
    return this._Deprecated003Model
  }

  /**
   * Get Deprecated004 model
   * @this {!Sender}
   * @returns {!Deprecated004Model} Deprecated004 model
   */
  get Deprecated004Model () {
    return this._Deprecated004Model
  }

  /**
   * Get Deprecated005 model
   * @this {!Sender}
   * @returns {!Deprecated005Model} Deprecated005 model
   */
  get Deprecated005Model () {
    return this._Deprecated005Model
  }

  /**
   * Get Deprecated006 model
   * @this {!Sender}
   * @returns {!Deprecated006Model} Deprecated006 model
   */
  get Deprecated006Model () {
    return this._Deprecated006Model
  }

  /**
   * Get Deprecated007 model
   * @this {!Sender}
   * @returns {!Deprecated007Model} Deprecated007 model
   */
  get Deprecated007Model () {
    return this._Deprecated007Model
  }

  /**
   * Get Deprecated008 model
   * @this {!Sender}
   * @returns {!Deprecated008Model} Deprecated008 model
   */
  get Deprecated008Model () {
    return this._Deprecated008Model
  }

  /**
   * Get Deprecated009 model
   * @this {!Sender}
   * @returns {!Deprecated009Model} Deprecated009 model
   */
  get Deprecated009Model () {
    return this._Deprecated009Model
  }

  /**
   * Get Deprecated010 model
   * @this {!Sender}
   * @returns {!Deprecated010Model} Deprecated010 model
   */
  get Deprecated010Model () {
    return this._Deprecated010Model
  }

  /**
   * Get Deprecated011 model
   * @this {!Sender}
   * @returns {!Deprecated011Model} Deprecated011 model
   */
  get Deprecated011Model () {
    return this._Deprecated011Model
  }

  /**
   * Get Deprecated012 model
   * @this {!Sender}
   * @returns {!Deprecated012Model} Deprecated012 model
   */
  get Deprecated012Model () {
    return this._Deprecated012Model
  }

  /**
   * Get Deprecated013 model
   * @this {!Sender}
   * @returns {!Deprecated013Model} Deprecated013 model
   */
  get Deprecated013Model () {
    return this._Deprecated013Model
  }

  /**
   * Get DepositAccountRequest model
   * @this {!Sender}
   * @returns {!DepositAccountRequestModel} DepositAccountRequest model
   */
  get DepositAccountRequestModel () {
    return this._DepositAccountRequestModel
  }

  /**
   * Get DepositAccountResponse model
   * @this {!Sender}
   * @returns {!DepositAccountResponseModel} DepositAccountResponse model
   */
  get DepositAccountResponseModel () {
    return this._DepositAccountResponseModel
  }

  /**
   * Get CreditAccountRequest model
   * @this {!Sender}
   * @returns {!CreditAccountRequestModel} CreditAccountRequest model
   */
  get CreditAccountRequestModel () {
    return this._CreditAccountRequestModel
  }

  /**
   * Get CreditAccountResponse model
   * @this {!Sender}
   * @returns {!CreditAccountResponseModel} CreditAccountResponse model
   */
  get CreditAccountResponseModel () {
    return this._CreditAccountResponseModel
  }

  /**
   * Get TransferMoneyRequest model
   * @this {!Sender}
   * @returns {!TransferMoneyRequestModel} TransferMoneyRequest model
   */
  get TransferMoneyRequestModel () {
    return this._TransferMoneyRequestModel
  }

  /**
   * Get TransferMoneyResponse model
   * @this {!Sender}
   * @returns {!TransferMoneyResponseModel} TransferMoneyResponse model
   */
  get TransferMoneyResponseModel () {
    return this._TransferMoneyResponseModel
  }

  /**
   * Get TradingCalendarRegularRuleGetRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleGetRequestModel} TradingCalendarRegularRuleGetRequest model
   */
  get TradingCalendarRegularRuleGetRequestModel () {
    return this._TradingCalendarRegularRuleGetRequestModel
  }

  /**
   * Get TradingCalendarRegularRuleGetResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleGetResponseModel} TradingCalendarRegularRuleGetResponse model
   */
  get TradingCalendarRegularRuleGetResponseModel () {
    return this._TradingCalendarRegularRuleGetResponseModel
  }

  /**
   * Get TradingCalendarRegularRulesGetAllRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRulesGetAllRequestModel} TradingCalendarRegularRulesGetAllRequest model
   */
  get TradingCalendarRegularRulesGetAllRequestModel () {
    return this._TradingCalendarRegularRulesGetAllRequestModel
  }

  /**
   * Get TradingCalendarRegularRulesGetAllResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRulesGetAllResponseModel} TradingCalendarRegularRulesGetAllResponse model
   */
  get TradingCalendarRegularRulesGetAllResponseModel () {
    return this._TradingCalendarRegularRulesGetAllResponseModel
  }

  /**
   * Get TradingCalendarRegularRuleCreateRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleCreateRequestModel} TradingCalendarRegularRuleCreateRequest model
   */
  get TradingCalendarRegularRuleCreateRequestModel () {
    return this._TradingCalendarRegularRuleCreateRequestModel
  }

  /**
   * Get TradingCalendarRegularRuleCreateResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleCreateResponseModel} TradingCalendarRegularRuleCreateResponse model
   */
  get TradingCalendarRegularRuleCreateResponseModel () {
    return this._TradingCalendarRegularRuleCreateResponseModel
  }

  /**
   * Get TradingCalendarRegularRuleModifyRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleModifyRequestModel} TradingCalendarRegularRuleModifyRequest model
   */
  get TradingCalendarRegularRuleModifyRequestModel () {
    return this._TradingCalendarRegularRuleModifyRequestModel
  }

  /**
   * Get TradingCalendarRegularRuleModifyResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleModifyResponseModel} TradingCalendarRegularRuleModifyResponse model
   */
  get TradingCalendarRegularRuleModifyResponseModel () {
    return this._TradingCalendarRegularRuleModifyResponseModel
  }

  /**
   * Get TradingCalendarRegularRuleDeleteRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleDeleteRequestModel} TradingCalendarRegularRuleDeleteRequest model
   */
  get TradingCalendarRegularRuleDeleteRequestModel () {
    return this._TradingCalendarRegularRuleDeleteRequestModel
  }

  /**
   * Get TradingCalendarRegularRuleDeleteResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleDeleteResponseModel} TradingCalendarRegularRuleDeleteResponse model
   */
  get TradingCalendarRegularRuleDeleteResponseModel () {
    return this._TradingCalendarRegularRuleDeleteResponseModel
  }

  /**
   * Get TradingCalendarRegularRuleUpdateNotify model
   * @this {!Sender}
   * @returns {!TradingCalendarRegularRuleUpdateNotifyModel} TradingCalendarRegularRuleUpdateNotify model
   */
  get TradingCalendarRegularRuleUpdateNotifyModel () {
    return this._TradingCalendarRegularRuleUpdateNotifyModel
  }

  /**
   * Get TradingCalendarOffTimeRuleGetRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleGetRequestModel} TradingCalendarOffTimeRuleGetRequest model
   */
  get TradingCalendarOffTimeRuleGetRequestModel () {
    return this._TradingCalendarOffTimeRuleGetRequestModel
  }

  /**
   * Get TradingCalendarOffTimeRuleGetResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleGetResponseModel} TradingCalendarOffTimeRuleGetResponse model
   */
  get TradingCalendarOffTimeRuleGetResponseModel () {
    return this._TradingCalendarOffTimeRuleGetResponseModel
  }

  /**
   * Get TradingCalendarOffTimeRulesGetAllRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRulesGetAllRequestModel} TradingCalendarOffTimeRulesGetAllRequest model
   */
  get TradingCalendarOffTimeRulesGetAllRequestModel () {
    return this._TradingCalendarOffTimeRulesGetAllRequestModel
  }

  /**
   * Get TradingCalendarOffTimeRulesGetAllResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRulesGetAllResponseModel} TradingCalendarOffTimeRulesGetAllResponse model
   */
  get TradingCalendarOffTimeRulesGetAllResponseModel () {
    return this._TradingCalendarOffTimeRulesGetAllResponseModel
  }

  /**
   * Get TradingCalendarOffTimeRuleCreateRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleCreateRequestModel} TradingCalendarOffTimeRuleCreateRequest model
   */
  get TradingCalendarOffTimeRuleCreateRequestModel () {
    return this._TradingCalendarOffTimeRuleCreateRequestModel
  }

  /**
   * Get TradingCalendarOffTimeRuleCreateResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleCreateResponseModel} TradingCalendarOffTimeRuleCreateResponse model
   */
  get TradingCalendarOffTimeRuleCreateResponseModel () {
    return this._TradingCalendarOffTimeRuleCreateResponseModel
  }

  /**
   * Get TradingCalendarOffTimeRuleModifyRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleModifyRequestModel} TradingCalendarOffTimeRuleModifyRequest model
   */
  get TradingCalendarOffTimeRuleModifyRequestModel () {
    return this._TradingCalendarOffTimeRuleModifyRequestModel
  }

  /**
   * Get TradingCalendarOffTimeRuleModifyResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleModifyResponseModel} TradingCalendarOffTimeRuleModifyResponse model
   */
  get TradingCalendarOffTimeRuleModifyResponseModel () {
    return this._TradingCalendarOffTimeRuleModifyResponseModel
  }

  /**
   * Get TradingCalendarOffTimeRuleDeleteRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleDeleteRequestModel} TradingCalendarOffTimeRuleDeleteRequest model
   */
  get TradingCalendarOffTimeRuleDeleteRequestModel () {
    return this._TradingCalendarOffTimeRuleDeleteRequestModel
  }

  /**
   * Get TradingCalendarOffTimeRuleDeleteResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleDeleteResponseModel} TradingCalendarOffTimeRuleDeleteResponse model
   */
  get TradingCalendarOffTimeRuleDeleteResponseModel () {
    return this._TradingCalendarOffTimeRuleDeleteResponseModel
  }

  /**
   * Get TradingCalendarOffTimeRuleUpdateNotify model
   * @this {!Sender}
   * @returns {!TradingCalendarOffTimeRuleUpdateNotifyModel} TradingCalendarOffTimeRuleUpdateNotify model
   */
  get TradingCalendarOffTimeRuleUpdateNotifyModel () {
    return this._TradingCalendarOffTimeRuleUpdateNotifyModel
  }

  /**
   * Get TradingCalendarRulesGetRequest model
   * @this {!Sender}
   * @returns {!TradingCalendarRulesGetRequestModel} TradingCalendarRulesGetRequest model
   */
  get TradingCalendarRulesGetRequestModel () {
    return this._TradingCalendarRulesGetRequestModel
  }

  /**
   * Get TradingCalendarRulesGetResponse model
   * @this {!Sender}
   * @returns {!TradingCalendarRulesGetResponseModel} TradingCalendarRulesGetResponse model
   */
  get TradingCalendarRulesGetResponseModel () {
    return this._TradingCalendarRulesGetResponseModel
  }

  /**
   * Get TradingRouteRulesGetRequest model
   * @this {!Sender}
   * @returns {!TradingRouteRulesGetRequestModel} TradingRouteRulesGetRequest model
   */
  get TradingRouteRulesGetRequestModel () {
    return this._TradingRouteRulesGetRequestModel
  }

  /**
   * Get TradingRouteRulesGetResponse model
   * @this {!Sender}
   * @returns {!TradingRouteRulesGetResponseModel} TradingRouteRulesGetResponse model
   */
  get TradingRouteRulesGetResponseModel () {
    return this._TradingRouteRulesGetResponseModel
  }

  /**
   * Get TradingRouteRuleCreateRequest model
   * @this {!Sender}
   * @returns {!TradingRouteRuleCreateRequestModel} TradingRouteRuleCreateRequest model
   */
  get TradingRouteRuleCreateRequestModel () {
    return this._TradingRouteRuleCreateRequestModel
  }

  /**
   * Get TradingRouteRuleCreateResponse model
   * @this {!Sender}
   * @returns {!TradingRouteRuleCreateResponseModel} TradingRouteRuleCreateResponse model
   */
  get TradingRouteRuleCreateResponseModel () {
    return this._TradingRouteRuleCreateResponseModel
  }

  /**
   * Get TradingRouteRuleModifyRequest model
   * @this {!Sender}
   * @returns {!TradingRouteRuleModifyRequestModel} TradingRouteRuleModifyRequest model
   */
  get TradingRouteRuleModifyRequestModel () {
    return this._TradingRouteRuleModifyRequestModel
  }

  /**
   * Get TradingRouteRuleModifyResponse model
   * @this {!Sender}
   * @returns {!TradingRouteRuleModifyResponseModel} TradingRouteRuleModifyResponse model
   */
  get TradingRouteRuleModifyResponseModel () {
    return this._TradingRouteRuleModifyResponseModel
  }

  /**
   * Get TradingRouteRuleDeleteRequest model
   * @this {!Sender}
   * @returns {!TradingRouteRuleDeleteRequestModel} TradingRouteRuleDeleteRequest model
   */
  get TradingRouteRuleDeleteRequestModel () {
    return this._TradingRouteRuleDeleteRequestModel
  }

  /**
   * Get TradingRouteRuleDeleteResponse model
   * @this {!Sender}
   * @returns {!TradingRouteRuleDeleteResponseModel} TradingRouteRuleDeleteResponse model
   */
  get TradingRouteRuleDeleteResponseModel () {
    return this._TradingRouteRuleDeleteResponseModel
  }

  /**
   * Get TradingRouteRuleUpdateNotify model
   * @this {!Sender}
   * @returns {!TradingRouteRuleUpdateNotifyModel} TradingRouteRuleUpdateNotify model
   */
  get TradingRouteRuleUpdateNotifyModel () {
    return this._TradingRouteRuleUpdateNotifyModel
  }

  /**
   * Get SymbolMapsGetRequest model
   * @this {!Sender}
   * @returns {!SymbolMapsGetRequestModel} SymbolMapsGetRequest model
   */
  get SymbolMapsGetRequestModel () {
    return this._SymbolMapsGetRequestModel
  }

  /**
   * Get SymbolMapsGetResponse model
   * @this {!Sender}
   * @returns {!SymbolMapsGetResponseModel} SymbolMapsGetResponse model
   */
  get SymbolMapsGetResponseModel () {
    return this._SymbolMapsGetResponseModel
  }

  /**
   * Get SymbolMapCreateRequest model
   * @this {!Sender}
   * @returns {!SymbolMapCreateRequestModel} SymbolMapCreateRequest model
   */
  get SymbolMapCreateRequestModel () {
    return this._SymbolMapCreateRequestModel
  }

  /**
   * Get SymbolMapCreateResponse model
   * @this {!Sender}
   * @returns {!SymbolMapCreateResponseModel} SymbolMapCreateResponse model
   */
  get SymbolMapCreateResponseModel () {
    return this._SymbolMapCreateResponseModel
  }

  /**
   * Get SymbolMapModifyRequest model
   * @this {!Sender}
   * @returns {!SymbolMapModifyRequestModel} SymbolMapModifyRequest model
   */
  get SymbolMapModifyRequestModel () {
    return this._SymbolMapModifyRequestModel
  }

  /**
   * Get SymbolMapModifyResponse model
   * @this {!Sender}
   * @returns {!SymbolMapModifyResponseModel} SymbolMapModifyResponse model
   */
  get SymbolMapModifyResponseModel () {
    return this._SymbolMapModifyResponseModel
  }

  /**
   * Get SymbolMapDeleteRequest model
   * @this {!Sender}
   * @returns {!SymbolMapDeleteRequestModel} SymbolMapDeleteRequest model
   */
  get SymbolMapDeleteRequestModel () {
    return this._SymbolMapDeleteRequestModel
  }

  /**
   * Get SymbolMapDeleteResponse model
   * @this {!Sender}
   * @returns {!SymbolMapDeleteResponseModel} SymbolMapDeleteResponse model
   */
  get SymbolMapDeleteResponseModel () {
    return this._SymbolMapDeleteResponseModel
  }

  /**
   * Get SymbolMapUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolMapUpdateNotifyModel} SymbolMapUpdateNotify model
   */
  get SymbolMapUpdateNotifyModel () {
    return this._SymbolMapUpdateNotifyModel
  }

  /**
   * Get GatewaysGetRequest model
   * @this {!Sender}
   * @returns {!GatewaysGetRequestModel} GatewaysGetRequest model
   */
  get GatewaysGetRequestModel () {
    return this._GatewaysGetRequestModel
  }

  /**
   * Get GatewaysGetResponse model
   * @this {!Sender}
   * @returns {!GatewaysGetResponseModel} GatewaysGetResponse model
   */
  get GatewaysGetResponseModel () {
    return this._GatewaysGetResponseModel
  }

  /**
   * Get GatewayCreateRequest model
   * @this {!Sender}
   * @returns {!GatewayCreateRequestModel} GatewayCreateRequest model
   */
  get GatewayCreateRequestModel () {
    return this._GatewayCreateRequestModel
  }

  /**
   * Get GatewayCreateResponse model
   * @this {!Sender}
   * @returns {!GatewayCreateResponseModel} GatewayCreateResponse model
   */
  get GatewayCreateResponseModel () {
    return this._GatewayCreateResponseModel
  }

  /**
   * Get GatewayModifyRequest model
   * @this {!Sender}
   * @returns {!GatewayModifyRequestModel} GatewayModifyRequest model
   */
  get GatewayModifyRequestModel () {
    return this._GatewayModifyRequestModel
  }

  /**
   * Get GatewayModifyResponse model
   * @this {!Sender}
   * @returns {!GatewayModifyResponseModel} GatewayModifyResponse model
   */
  get GatewayModifyResponseModel () {
    return this._GatewayModifyResponseModel
  }

  /**
   * Get GatewayDeleteRequest model
   * @this {!Sender}
   * @returns {!GatewayDeleteRequestModel} GatewayDeleteRequest model
   */
  get GatewayDeleteRequestModel () {
    return this._GatewayDeleteRequestModel
  }

  /**
   * Get GatewayDeleteResponse model
   * @this {!Sender}
   * @returns {!GatewayDeleteResponseModel} GatewayDeleteResponse model
   */
  get GatewayDeleteResponseModel () {
    return this._GatewayDeleteResponseModel
  }

  /**
   * Get GatewayUpdateNotify model
   * @this {!Sender}
   * @returns {!GatewayUpdateNotifyModel} GatewayUpdateNotify model
   */
  get GatewayUpdateNotifyModel () {
    return this._GatewayUpdateNotifyModel
  }

  /**
   * Get DataFeedsGetRequest model
   * @this {!Sender}
   * @returns {!DataFeedsGetRequestModel} DataFeedsGetRequest model
   */
  get DataFeedsGetRequestModel () {
    return this._DataFeedsGetRequestModel
  }

  /**
   * Get DataFeedsGetResponse model
   * @this {!Sender}
   * @returns {!DataFeedsGetResponseModel} DataFeedsGetResponse model
   */
  get DataFeedsGetResponseModel () {
    return this._DataFeedsGetResponseModel
  }

  /**
   * Get DataFeedCreateRequest model
   * @this {!Sender}
   * @returns {!DataFeedCreateRequestModel} DataFeedCreateRequest model
   */
  get DataFeedCreateRequestModel () {
    return this._DataFeedCreateRequestModel
  }

  /**
   * Get DataFeedCreateResponse model
   * @this {!Sender}
   * @returns {!DataFeedCreateResponseModel} DataFeedCreateResponse model
   */
  get DataFeedCreateResponseModel () {
    return this._DataFeedCreateResponseModel
  }

  /**
   * Get DataFeedModifyRequest model
   * @this {!Sender}
   * @returns {!DataFeedModifyRequestModel} DataFeedModifyRequest model
   */
  get DataFeedModifyRequestModel () {
    return this._DataFeedModifyRequestModel
  }

  /**
   * Get DataFeedModifyResponse model
   * @this {!Sender}
   * @returns {!DataFeedModifyResponseModel} DataFeedModifyResponse model
   */
  get DataFeedModifyResponseModel () {
    return this._DataFeedModifyResponseModel
  }

  /**
   * Get DataFeedDeleteRequest model
   * @this {!Sender}
   * @returns {!DataFeedDeleteRequestModel} DataFeedDeleteRequest model
   */
  get DataFeedDeleteRequestModel () {
    return this._DataFeedDeleteRequestModel
  }

  /**
   * Get DataFeedDeleteResponse model
   * @this {!Sender}
   * @returns {!DataFeedDeleteResponseModel} DataFeedDeleteResponse model
   */
  get DataFeedDeleteResponseModel () {
    return this._DataFeedDeleteResponseModel
  }

  /**
   * Get DataFeedUpdateNotify model
   * @this {!Sender}
   * @returns {!DataFeedUpdateNotifyModel} DataFeedUpdateNotify model
   */
  get DataFeedUpdateNotifyModel () {
    return this._DataFeedUpdateNotifyModel
  }

  /**
   * Get ModuleSystemInfoNotify model
   * @this {!Sender}
   * @returns {!ModuleSystemInfoNotifyModel} ModuleSystemInfoNotify model
   */
  get ModuleSystemInfoNotifyModel () {
    return this._ModuleSystemInfoNotifyModel
  }

  /**
   * Get ModulesSystemInfoGetAllRequest model
   * @this {!Sender}
   * @returns {!ModulesSystemInfoGetAllRequestModel} ModulesSystemInfoGetAllRequest model
   */
  get ModulesSystemInfoGetAllRequestModel () {
    return this._ModulesSystemInfoGetAllRequestModel
  }

  /**
   * Get ModulesSystemInfoGetAllResponse model
   * @this {!Sender}
   * @returns {!ModulesSystemInfoGetAllResponseModel} ModulesSystemInfoGetAllResponse model
   */
  get ModulesSystemInfoGetAllResponseModel () {
    return this._ModulesSystemInfoGetAllResponseModel
  }

  /**
   * Get ModulesSystemInfoNotify model
   * @this {!Sender}
   * @returns {!ModulesSystemInfoNotifyModel} ModulesSystemInfoNotify model
   */
  get ModulesSystemInfoNotifyModel () {
    return this._ModulesSystemInfoNotifyModel
  }

  /**
   * Get ChartBarHistoryModifyRequest model
   * @this {!Sender}
   * @returns {!ChartBarHistoryModifyRequestModel} ChartBarHistoryModifyRequest model
   */
  get ChartBarHistoryModifyRequestModel () {
    return this._ChartBarHistoryModifyRequestModel
  }

  /**
   * Get ChartBarHistoryModifyResponse model
   * @this {!Sender}
   * @returns {!ChartBarHistoryModifyResponseModel} ChartBarHistoryModifyResponse model
   */
  get ChartBarHistoryModifyResponseModel () {
    return this._ChartBarHistoryModifyResponseModel
  }

  /**
   * Get FeedUpdateTickSnapshotRequest model
   * @this {!Sender}
   * @returns {!FeedUpdateTickSnapshotRequestModel} FeedUpdateTickSnapshotRequest model
   */
  get FeedUpdateTickSnapshotRequestModel () {
    return this._FeedUpdateTickSnapshotRequestModel
  }

  /**
   * Get FeedUpdateTickSnapshotResponse model
   * @this {!Sender}
   * @returns {!FeedUpdateTickSnapshotResponseModel} FeedUpdateTickSnapshotResponse model
   */
  get FeedUpdateTickSnapshotResponseModel () {
    return this._FeedUpdateTickSnapshotResponseModel
  }

  /**
   * Get FeedUpdateTickRequest model
   * @this {!Sender}
   * @returns {!FeedUpdateTickRequestModel} FeedUpdateTickRequest model
   */
  get FeedUpdateTickRequestModel () {
    return this._FeedUpdateTickRequestModel
  }

  /**
   * Get FeedUpdateTickResponse model
   * @this {!Sender}
   * @returns {!FeedUpdateTickResponseModel} FeedUpdateTickResponse model
   */
  get FeedUpdateTickResponseModel () {
    return this._FeedUpdateTickResponseModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotRequest model
   * @this {!Sender}
   * @returns {!FeedUpdateLevel2SnapshotRequestModel} FeedUpdateLevel2SnapshotRequest model
   */
  get FeedUpdateLevel2SnapshotRequestModel () {
    return this._FeedUpdateLevel2SnapshotRequestModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotResponse model
   * @this {!Sender}
   * @returns {!FeedUpdateLevel2SnapshotResponseModel} FeedUpdateLevel2SnapshotResponse model
   */
  get FeedUpdateLevel2SnapshotResponseModel () {
    return this._FeedUpdateLevel2SnapshotResponseModel
  }

  /**
   * Get FeedUpdateLevel2Request model
   * @this {!Sender}
   * @returns {!FeedUpdateLevel2RequestModel} FeedUpdateLevel2Request model
   */
  get FeedUpdateLevel2RequestModel () {
    return this._FeedUpdateLevel2RequestModel
  }

  /**
   * Get FeedUpdateLevel2Response model
   * @this {!Sender}
   * @returns {!FeedUpdateLevel2ResponseModel} FeedUpdateLevel2Response model
   */
  get FeedUpdateLevel2ResponseModel () {
    return this._FeedUpdateLevel2ResponseModel
  }

  /**
   * Get MailServerGetRequest model
   * @this {!Sender}
   * @returns {!MailServerGetRequestModel} MailServerGetRequest model
   */
  get MailServerGetRequestModel () {
    return this._MailServerGetRequestModel
  }

  /**
   * Get MailServerGetResponse model
   * @this {!Sender}
   * @returns {!MailServerGetResponseModel} MailServerGetResponse model
   */
  get MailServerGetResponseModel () {
    return this._MailServerGetResponseModel
  }

  /**
   * Get MailServersGetAllRequest model
   * @this {!Sender}
   * @returns {!MailServersGetAllRequestModel} MailServersGetAllRequest model
   */
  get MailServersGetAllRequestModel () {
    return this._MailServersGetAllRequestModel
  }

  /**
   * Get MailServersGetAllResponse model
   * @this {!Sender}
   * @returns {!MailServersGetAllResponseModel} MailServersGetAllResponse model
   */
  get MailServersGetAllResponseModel () {
    return this._MailServersGetAllResponseModel
  }

  /**
   * Get MailServerCreateRequest model
   * @this {!Sender}
   * @returns {!MailServerCreateRequestModel} MailServerCreateRequest model
   */
  get MailServerCreateRequestModel () {
    return this._MailServerCreateRequestModel
  }

  /**
   * Get MailServerCreateResponse model
   * @this {!Sender}
   * @returns {!MailServerCreateResponseModel} MailServerCreateResponse model
   */
  get MailServerCreateResponseModel () {
    return this._MailServerCreateResponseModel
  }

  /**
   * Get MailServerModifyRequest model
   * @this {!Sender}
   * @returns {!MailServerModifyRequestModel} MailServerModifyRequest model
   */
  get MailServerModifyRequestModel () {
    return this._MailServerModifyRequestModel
  }

  /**
   * Get MailServerModifyResponse model
   * @this {!Sender}
   * @returns {!MailServerModifyResponseModel} MailServerModifyResponse model
   */
  get MailServerModifyResponseModel () {
    return this._MailServerModifyResponseModel
  }

  /**
   * Get MailServerDeleteRequest model
   * @this {!Sender}
   * @returns {!MailServerDeleteRequestModel} MailServerDeleteRequest model
   */
  get MailServerDeleteRequestModel () {
    return this._MailServerDeleteRequestModel
  }

  /**
   * Get MailServerDeleteResponse model
   * @this {!Sender}
   * @returns {!MailServerDeleteResponseModel} MailServerDeleteResponse model
   */
  get MailServerDeleteResponseModel () {
    return this._MailServerDeleteResponseModel
  }

  /**
   * Get MailServerUpdateNotify model
   * @this {!Sender}
   * @returns {!MailServerUpdateNotifyModel} MailServerUpdateNotify model
   */
  get MailServerUpdateNotifyModel () {
    return this._MailServerUpdateNotifyModel
  }

  /**
   * Get AccountStatesUpdateNotify model
   * @this {!Sender}
   * @returns {!AccountStatesUpdateNotifyModel} AccountStatesUpdateNotify model
   */
  get AccountStatesUpdateNotifyModel () {
    return this._AccountStatesUpdateNotifyModel
  }

  /**
   * Get AccountStatesGetAllRequest model
   * @this {!Sender}
   * @returns {!AccountStatesGetAllRequestModel} AccountStatesGetAllRequest model
   */
  get AccountStatesGetAllRequestModel () {
    return this._AccountStatesGetAllRequestModel
  }

  /**
   * Get AccountStatesGetAllResponse model
   * @this {!Sender}
   * @returns {!AccountStatesGetAllResponseModel} AccountStatesGetAllResponse model
   */
  get AccountStatesGetAllResponseModel () {
    return this._AccountStatesGetAllResponseModel
  }

  /**
   * Get ModuleAuthRequest model
   * @this {!Sender}
   * @returns {!ModuleAuthRequestModel} ModuleAuthRequest model
   */
  get ModuleAuthRequestModel () {
    return this._ModuleAuthRequestModel
  }

  /**
   * Get ModuleAuthResponse model
   * @this {!Sender}
   * @returns {!ModuleAuthResponseModel} ModuleAuthResponse model
   */
  get ModuleAuthResponseModel () {
    return this._ModuleAuthResponseModel
  }

  /**
   * Get LeverageProfileGetRequest model
   * @this {!Sender}
   * @returns {!LeverageProfileGetRequestModel} LeverageProfileGetRequest model
   */
  get LeverageProfileGetRequestModel () {
    return this._LeverageProfileGetRequestModel
  }

  /**
   * Get LeverageProfileGetResponse model
   * @this {!Sender}
   * @returns {!LeverageProfileGetResponseModel} LeverageProfileGetResponse model
   */
  get LeverageProfileGetResponseModel () {
    return this._LeverageProfileGetResponseModel
  }

  /**
   * Get LeverageProfilesGetAllRequest model
   * @this {!Sender}
   * @returns {!LeverageProfilesGetAllRequestModel} LeverageProfilesGetAllRequest model
   */
  get LeverageProfilesGetAllRequestModel () {
    return this._LeverageProfilesGetAllRequestModel
  }

  /**
   * Get LeverageProfilesGetAllResponse model
   * @this {!Sender}
   * @returns {!LeverageProfilesGetAllResponseModel} LeverageProfilesGetAllResponse model
   */
  get LeverageProfilesGetAllResponseModel () {
    return this._LeverageProfilesGetAllResponseModel
  }

  /**
   * Get LeverageProfileCreateRequest model
   * @this {!Sender}
   * @returns {!LeverageProfileCreateRequestModel} LeverageProfileCreateRequest model
   */
  get LeverageProfileCreateRequestModel () {
    return this._LeverageProfileCreateRequestModel
  }

  /**
   * Get LeverageProfileCreateResponse model
   * @this {!Sender}
   * @returns {!LeverageProfileCreateResponseModel} LeverageProfileCreateResponse model
   */
  get LeverageProfileCreateResponseModel () {
    return this._LeverageProfileCreateResponseModel
  }

  /**
   * Get LeverageProfileModifyRequest model
   * @this {!Sender}
   * @returns {!LeverageProfileModifyRequestModel} LeverageProfileModifyRequest model
   */
  get LeverageProfileModifyRequestModel () {
    return this._LeverageProfileModifyRequestModel
  }

  /**
   * Get LeverageProfileModifyResponse model
   * @this {!Sender}
   * @returns {!LeverageProfileModifyResponseModel} LeverageProfileModifyResponse model
   */
  get LeverageProfileModifyResponseModel () {
    return this._LeverageProfileModifyResponseModel
  }

  /**
   * Get LeverageProfileDeleteRequest model
   * @this {!Sender}
   * @returns {!LeverageProfileDeleteRequestModel} LeverageProfileDeleteRequest model
   */
  get LeverageProfileDeleteRequestModel () {
    return this._LeverageProfileDeleteRequestModel
  }

  /**
   * Get LeverageProfileDeleteResponse model
   * @this {!Sender}
   * @returns {!LeverageProfileDeleteResponseModel} LeverageProfileDeleteResponse model
   */
  get LeverageProfileDeleteResponseModel () {
    return this._LeverageProfileDeleteResponseModel
  }

  /**
   * Get LeverageProfileUpdateNotify model
   * @this {!Sender}
   * @returns {!LeverageProfileUpdateNotifyModel} LeverageProfileUpdateNotify model
   */
  get LeverageProfileUpdateNotifyModel () {
    return this._LeverageProfileUpdateNotifyModel
  }

  /**
   * Get MarginRateProfileGetRequest model
   * @this {!Sender}
   * @returns {!MarginRateProfileGetRequestModel} MarginRateProfileGetRequest model
   */
  get MarginRateProfileGetRequestModel () {
    return this._MarginRateProfileGetRequestModel
  }

  /**
   * Get MarginRateProfileGetResponse model
   * @this {!Sender}
   * @returns {!MarginRateProfileGetResponseModel} MarginRateProfileGetResponse model
   */
  get MarginRateProfileGetResponseModel () {
    return this._MarginRateProfileGetResponseModel
  }

  /**
   * Get MarginRateProfilesGetAllRequest model
   * @this {!Sender}
   * @returns {!MarginRateProfilesGetAllRequestModel} MarginRateProfilesGetAllRequest model
   */
  get MarginRateProfilesGetAllRequestModel () {
    return this._MarginRateProfilesGetAllRequestModel
  }

  /**
   * Get MarginRateProfilesGetAllResponse model
   * @this {!Sender}
   * @returns {!MarginRateProfilesGetAllResponseModel} MarginRateProfilesGetAllResponse model
   */
  get MarginRateProfilesGetAllResponseModel () {
    return this._MarginRateProfilesGetAllResponseModel
  }

  /**
   * Get MarginRateProfileCreateRequest model
   * @this {!Sender}
   * @returns {!MarginRateProfileCreateRequestModel} MarginRateProfileCreateRequest model
   */
  get MarginRateProfileCreateRequestModel () {
    return this._MarginRateProfileCreateRequestModel
  }

  /**
   * Get MarginRateProfileCreateResponse model
   * @this {!Sender}
   * @returns {!MarginRateProfileCreateResponseModel} MarginRateProfileCreateResponse model
   */
  get MarginRateProfileCreateResponseModel () {
    return this._MarginRateProfileCreateResponseModel
  }

  /**
   * Get MarginRateProfileModifyRequest model
   * @this {!Sender}
   * @returns {!MarginRateProfileModifyRequestModel} MarginRateProfileModifyRequest model
   */
  get MarginRateProfileModifyRequestModel () {
    return this._MarginRateProfileModifyRequestModel
  }

  /**
   * Get MarginRateProfileModifyResponse model
   * @this {!Sender}
   * @returns {!MarginRateProfileModifyResponseModel} MarginRateProfileModifyResponse model
   */
  get MarginRateProfileModifyResponseModel () {
    return this._MarginRateProfileModifyResponseModel
  }

  /**
   * Get MarginRateProfileDeleteRequest model
   * @this {!Sender}
   * @returns {!MarginRateProfileDeleteRequestModel} MarginRateProfileDeleteRequest model
   */
  get MarginRateProfileDeleteRequestModel () {
    return this._MarginRateProfileDeleteRequestModel
  }

  /**
   * Get MarginRateProfileDeleteResponse model
   * @this {!Sender}
   * @returns {!MarginRateProfileDeleteResponseModel} MarginRateProfileDeleteResponse model
   */
  get MarginRateProfileDeleteResponseModel () {
    return this._MarginRateProfileDeleteResponseModel
  }

  /**
   * Get MarginRateProfileUpdateNotify model
   * @this {!Sender}
   * @returns {!MarginRateProfileUpdateNotifyModel} MarginRateProfileUpdateNotify model
   */
  get MarginRateProfileUpdateNotifyModel () {
    return this._MarginRateProfileUpdateNotifyModel
  }

  /**
   * Get NewsProfileGetRequest model
   * @this {!Sender}
   * @returns {!NewsProfileGetRequestModel} NewsProfileGetRequest model
   */
  get NewsProfileGetRequestModel () {
    return this._NewsProfileGetRequestModel
  }

  /**
   * Get NewsProfileGetResponse model
   * @this {!Sender}
   * @returns {!NewsProfileGetResponseModel} NewsProfileGetResponse model
   */
  get NewsProfileGetResponseModel () {
    return this._NewsProfileGetResponseModel
  }

  /**
   * Get NewsProfilesGetAllRequest model
   * @this {!Sender}
   * @returns {!NewsProfilesGetAllRequestModel} NewsProfilesGetAllRequest model
   */
  get NewsProfilesGetAllRequestModel () {
    return this._NewsProfilesGetAllRequestModel
  }

  /**
   * Get NewsProfilesGetAllResponse model
   * @this {!Sender}
   * @returns {!NewsProfilesGetAllResponseModel} NewsProfilesGetAllResponse model
   */
  get NewsProfilesGetAllResponseModel () {
    return this._NewsProfilesGetAllResponseModel
  }

  /**
   * Get NewsProfileCreateRequest model
   * @this {!Sender}
   * @returns {!NewsProfileCreateRequestModel} NewsProfileCreateRequest model
   */
  get NewsProfileCreateRequestModel () {
    return this._NewsProfileCreateRequestModel
  }

  /**
   * Get NewsProfileCreateResponse model
   * @this {!Sender}
   * @returns {!NewsProfileCreateResponseModel} NewsProfileCreateResponse model
   */
  get NewsProfileCreateResponseModel () {
    return this._NewsProfileCreateResponseModel
  }

  /**
   * Get NewsProfileModifyRequest model
   * @this {!Sender}
   * @returns {!NewsProfileModifyRequestModel} NewsProfileModifyRequest model
   */
  get NewsProfileModifyRequestModel () {
    return this._NewsProfileModifyRequestModel
  }

  /**
   * Get NewsProfileModifyResponse model
   * @this {!Sender}
   * @returns {!NewsProfileModifyResponseModel} NewsProfileModifyResponse model
   */
  get NewsProfileModifyResponseModel () {
    return this._NewsProfileModifyResponseModel
  }

  /**
   * Get NewsProfileDeleteRequest model
   * @this {!Sender}
   * @returns {!NewsProfileDeleteRequestModel} NewsProfileDeleteRequest model
   */
  get NewsProfileDeleteRequestModel () {
    return this._NewsProfileDeleteRequestModel
  }

  /**
   * Get NewsProfileDeleteResponse model
   * @this {!Sender}
   * @returns {!NewsProfileDeleteResponseModel} NewsProfileDeleteResponse model
   */
  get NewsProfileDeleteResponseModel () {
    return this._NewsProfileDeleteResponseModel
  }

  /**
   * Get NewsProfileUpdateNotify model
   * @this {!Sender}
   * @returns {!NewsProfileUpdateNotifyModel} NewsProfileUpdateNotify model
   */
  get NewsProfileUpdateNotifyModel () {
    return this._NewsProfileUpdateNotifyModel
  }

  /**
   * Get NewsProfileImportRequest model
   * @this {!Sender}
   * @returns {!NewsProfileImportRequestModel} NewsProfileImportRequest model
   */
  get NewsProfileImportRequestModel () {
    return this._NewsProfileImportRequestModel
  }

  /**
   * Get NewsProfileImportResponse model
   * @this {!Sender}
   * @returns {!NewsProfileImportResponseModel} NewsProfileImportResponse model
   */
  get NewsProfileImportResponseModel () {
    return this._NewsProfileImportResponseModel
  }

  /**
   * Get TickHistoryRequest model
   * @this {!Sender}
   * @returns {!TickHistoryRequestModel} TickHistoryRequest model
   */
  get TickHistoryRequestModel () {
    return this._TickHistoryRequestModel
  }

  /**
   * Get TickHistoryResponse model
   * @this {!Sender}
   * @returns {!TickHistoryResponseModel} TickHistoryResponse model
   */
  get TickHistoryResponseModel () {
    return this._TickHistoryResponseModel
  }

  /**
   * Get EmailTemplateGetRequest model
   * @this {!Sender}
   * @returns {!EmailTemplateGetRequestModel} EmailTemplateGetRequest model
   */
  get EmailTemplateGetRequestModel () {
    return this._EmailTemplateGetRequestModel
  }

  /**
   * Get EmailTemplateGetResponse model
   * @this {!Sender}
   * @returns {!EmailTemplateGetResponseModel} EmailTemplateGetResponse model
   */
  get EmailTemplateGetResponseModel () {
    return this._EmailTemplateGetResponseModel
  }

  /**
   * Get EmailTemplatesGetAllRequest model
   * @this {!Sender}
   * @returns {!EmailTemplatesGetAllRequestModel} EmailTemplatesGetAllRequest model
   */
  get EmailTemplatesGetAllRequestModel () {
    return this._EmailTemplatesGetAllRequestModel
  }

  /**
   * Get EmailTemplatesGetAllResponse model
   * @this {!Sender}
   * @returns {!EmailTemplatesGetAllResponseModel} EmailTemplatesGetAllResponse model
   */
  get EmailTemplatesGetAllResponseModel () {
    return this._EmailTemplatesGetAllResponseModel
  }

  /**
   * Get EmailTemplateCreateRequest model
   * @this {!Sender}
   * @returns {!EmailTemplateCreateRequestModel} EmailTemplateCreateRequest model
   */
  get EmailTemplateCreateRequestModel () {
    return this._EmailTemplateCreateRequestModel
  }

  /**
   * Get EmailTemplateCreateResponse model
   * @this {!Sender}
   * @returns {!EmailTemplateCreateResponseModel} EmailTemplateCreateResponse model
   */
  get EmailTemplateCreateResponseModel () {
    return this._EmailTemplateCreateResponseModel
  }

  /**
   * Get EmailTemplateModifyRequest model
   * @this {!Sender}
   * @returns {!EmailTemplateModifyRequestModel} EmailTemplateModifyRequest model
   */
  get EmailTemplateModifyRequestModel () {
    return this._EmailTemplateModifyRequestModel
  }

  /**
   * Get EmailTemplateModifyResponse model
   * @this {!Sender}
   * @returns {!EmailTemplateModifyResponseModel} EmailTemplateModifyResponse model
   */
  get EmailTemplateModifyResponseModel () {
    return this._EmailTemplateModifyResponseModel
  }

  /**
   * Get EmailTemplateDeleteRequest model
   * @this {!Sender}
   * @returns {!EmailTemplateDeleteRequestModel} EmailTemplateDeleteRequest model
   */
  get EmailTemplateDeleteRequestModel () {
    return this._EmailTemplateDeleteRequestModel
  }

  /**
   * Get EmailTemplateDeleteResponse model
   * @this {!Sender}
   * @returns {!EmailTemplateDeleteResponseModel} EmailTemplateDeleteResponse model
   */
  get EmailTemplateDeleteResponseModel () {
    return this._EmailTemplateDeleteResponseModel
  }

  /**
   * Get EmailTemplateUpdateNotify model
   * @this {!Sender}
   * @returns {!EmailTemplateUpdateNotifyModel} EmailTemplateUpdateNotify model
   */
  get EmailTemplateUpdateNotifyModel () {
    return this._EmailTemplateUpdateNotifyModel
  }

  /**
   * Get OrderStateSubscribeRequest model
   * @this {!Sender}
   * @returns {!OrderStateSubscribeRequestModel} OrderStateSubscribeRequest model
   */
  get OrderStateSubscribeRequestModel () {
    return this._OrderStateSubscribeRequestModel
  }

  /**
   * Get OrderStateSubscribeResponse model
   * @this {!Sender}
   * @returns {!OrderStateSubscribeResponseModel} OrderStateSubscribeResponse model
   */
  get OrderStateSubscribeResponseModel () {
    return this._OrderStateSubscribeResponseModel
  }

  /**
   * Get OrderStateUpdateSubscriptionRequest model
   * @this {!Sender}
   * @returns {!OrderStateUpdateSubscriptionRequestModel} OrderStateUpdateSubscriptionRequest model
   */
  get OrderStateUpdateSubscriptionRequestModel () {
    return this._OrderStateUpdateSubscriptionRequestModel
  }

  /**
   * Get OrderStateUpdateSubscriptionResponse model
   * @this {!Sender}
   * @returns {!OrderStateUpdateSubscriptionResponseModel} OrderStateUpdateSubscriptionResponse model
   */
  get OrderStateUpdateSubscriptionResponseModel () {
    return this._OrderStateUpdateSubscriptionResponseModel
  }

  /**
   * Get OrderStateUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!OrderStateUnsubscribeRequestModel} OrderStateUnsubscribeRequest model
   */
  get OrderStateUnsubscribeRequestModel () {
    return this._OrderStateUnsubscribeRequestModel
  }

  /**
   * Get OrderStateUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!OrderStateUnsubscribeResponseModel} OrderStateUnsubscribeResponse model
   */
  get OrderStateUnsubscribeResponseModel () {
    return this._OrderStateUnsubscribeResponseModel
  }

  /**
   * Get OrderStateNotify model
   * @this {!Sender}
   * @returns {!OrderStateNotifyModel} OrderStateNotify model
   */
  get OrderStateNotifyModel () {
    return this._OrderStateNotifyModel
  }

  /**
   * Get RequestMonitoringSubscribeRequest model
   * @this {!Sender}
   * @returns {!RequestMonitoringSubscribeRequestModel} RequestMonitoringSubscribeRequest model
   */
  get RequestMonitoringSubscribeRequestModel () {
    return this._RequestMonitoringSubscribeRequestModel
  }

  /**
   * Get RequestMonitoringSubscribeResponse model
   * @this {!Sender}
   * @returns {!RequestMonitoringSubscribeResponseModel} RequestMonitoringSubscribeResponse model
   */
  get RequestMonitoringSubscribeResponseModel () {
    return this._RequestMonitoringSubscribeResponseModel
  }

  /**
   * Get RequestMonitoringUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!RequestMonitoringUnsubscribeRequestModel} RequestMonitoringUnsubscribeRequest model
   */
  get RequestMonitoringUnsubscribeRequestModel () {
    return this._RequestMonitoringUnsubscribeRequestModel
  }

  /**
   * Get RequestMonitoringUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!RequestMonitoringUnsubscribeResponseModel} RequestMonitoringUnsubscribeResponse model
   */
  get RequestMonitoringUnsubscribeResponseModel () {
    return this._RequestMonitoringUnsubscribeResponseModel
  }

  /**
   * Get RequestMonitoringRequestUpdateNotify model
   * @this {!Sender}
   * @returns {!RequestMonitoringRequestUpdateNotifyModel} RequestMonitoringRequestUpdateNotify model
   */
  get RequestMonitoringRequestUpdateNotifyModel () {
    return this._RequestMonitoringRequestUpdateNotifyModel
  }

  /**
   * Get FilledOrdersSummaryInfoSubscribeRequest model
   * @this {!Sender}
   * @returns {!FilledOrdersSummaryInfoSubscribeRequestModel} FilledOrdersSummaryInfoSubscribeRequest model
   */
  get FilledOrdersSummaryInfoSubscribeRequestModel () {
    return this._FilledOrdersSummaryInfoSubscribeRequestModel
  }

  /**
   * Get FilledOrdersSummaryInfoSubscribeResponse model
   * @this {!Sender}
   * @returns {!FilledOrdersSummaryInfoSubscribeResponseModel} FilledOrdersSummaryInfoSubscribeResponse model
   */
  get FilledOrdersSummaryInfoSubscribeResponseModel () {
    return this._FilledOrdersSummaryInfoSubscribeResponseModel
  }

  /**
   * Get FilledOrdersSummaryInfoNotify model
   * @this {!Sender}
   * @returns {!FilledOrdersSummaryInfoNotifyModel} FilledOrdersSummaryInfoNotify model
   */
  get FilledOrdersSummaryInfoNotifyModel () {
    return this._FilledOrdersSummaryInfoNotifyModel
  }

  /**
   * Get FilledOrdersSummaryInfoUpdateSubscriptionRequest model
   * @this {!Sender}
   * @returns {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel} FilledOrdersSummaryInfoUpdateSubscriptionRequest model
   */
  get FilledOrdersSummaryInfoUpdateSubscriptionRequestModel () {
    return this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel
  }

  /**
   * Get FilledOrdersSummaryInfoUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!FilledOrdersSummaryInfoUnsubscribeRequestModel} FilledOrdersSummaryInfoUnsubscribeRequest model
   */
  get FilledOrdersSummaryInfoUnsubscribeRequestModel () {
    return this._FilledOrdersSummaryInfoUnsubscribeRequestModel
  }

  /**
   * Get FilledOrdersSummaryInfoUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!FilledOrdersSummaryInfoUnsubscribeResponseModel} FilledOrdersSummaryInfoUnsubscribeResponse model
   */
  get FilledOrdersSummaryInfoUnsubscribeResponseModel () {
    return this._FilledOrdersSummaryInfoUnsubscribeResponseModel
  }

  /**
   * Get TradeEventsNotify model
   * @this {!Sender}
   * @returns {!TradeEventsNotifyModel} TradeEventsNotify model
   */
  get TradeEventsNotifyModel () {
    return this._TradeEventsNotifyModel
  }

  /**
   * Get TradeEventsGetRequest model
   * @this {!Sender}
   * @returns {!TradeEventsGetRequestModel} TradeEventsGetRequest model
   */
  get TradeEventsGetRequestModel () {
    return this._TradeEventsGetRequestModel
  }

  /**
   * Get TradeEventsGetResponse model
   * @this {!Sender}
   * @returns {!TradeEventsGetResponseModel} TradeEventsGetResponse model
   */
  get TradeEventsGetResponseModel () {
    return this._TradeEventsGetResponseModel
  }

  /**
   * Get JournalRequest model
   * @this {!Sender}
   * @returns {!JournalRequestModel} JournalRequest model
   */
  get JournalRequestModel () {
    return this._JournalRequestModel
  }

  /**
   * Get JournalResponse model
   * @this {!Sender}
   * @returns {!JournalResponseModel} JournalResponse model
   */
  get JournalResponseModel () {
    return this._JournalResponseModel
  }

  /**
   * Get ClientReportsListGetRequest model
   * @this {!Sender}
   * @returns {!ClientReportsListGetRequestModel} ClientReportsListGetRequest model
   */
  get ClientReportsListGetRequestModel () {
    return this._ClientReportsListGetRequestModel
  }

  /**
   * Get ClientReportsListGetResponse model
   * @this {!Sender}
   * @returns {!ClientReportsListGetResponseModel} ClientReportsListGetResponse model
   */
  get ClientReportsListGetResponseModel () {
    return this._ClientReportsListGetResponseModel
  }

  /**
   * Get ClientReportGetRequest model
   * @this {!Sender}
   * @returns {!ClientReportGetRequestModel} ClientReportGetRequest model
   */
  get ClientReportGetRequestModel () {
    return this._ClientReportGetRequestModel
  }

  /**
   * Get ClientReportGetResponse model
   * @this {!Sender}
   * @returns {!ClientReportGetResponseModel} ClientReportGetResponse model
   */
  get ClientReportGetResponseModel () {
    return this._ClientReportGetResponseModel
  }

  /**
   * Get ClientReportsSendRequest model
   * @this {!Sender}
   * @returns {!ClientReportsSendRequestModel} ClientReportsSendRequest model
   */
  get ClientReportsSendRequestModel () {
    return this._ClientReportsSendRequestModel
  }

  /**
   * Get ClientReportsSendResponse model
   * @this {!Sender}
   * @returns {!ClientReportsSendResponseModel} ClientReportsSendResponse model
   */
  get ClientReportsSendResponseModel () {
    return this._ClientReportsSendResponseModel
  }

  /**
   * Get ProfitClosedGetRequest model
   * @this {!Sender}
   * @returns {!ProfitClosedGetRequestModel} ProfitClosedGetRequest model
   */
  get ProfitClosedGetRequestModel () {
    return this._ProfitClosedGetRequestModel
  }

  /**
   * Get ProfitClosedGetResponse model
   * @this {!Sender}
   * @returns {!ProfitClosedGetResponseModel} ProfitClosedGetResponse model
   */
  get ProfitClosedGetResponseModel () {
    return this._ProfitClosedGetResponseModel
  }

  /**
   * Get ChartBarsImportRequest model
   * @this {!Sender}
   * @returns {!ChartBarsImportRequestModel} ChartBarsImportRequest model
   */
  get ChartBarsImportRequestModel () {
    return this._ChartBarsImportRequestModel
  }

  /**
   * Get ChartBarsImportResponse model
   * @this {!Sender}
   * @returns {!ChartBarsImportResponseModel} ChartBarsImportResponse model
   */
  get ChartBarsImportResponseModel () {
    return this._ChartBarsImportResponseModel
  }

  /**
   * Get ChartBarsImportNotify model
   * @this {!Sender}
   * @returns {!ChartBarsImportNotifyModel} ChartBarsImportNotify model
   */
  get ChartBarsImportNotifyModel () {
    return this._ChartBarsImportNotifyModel
  }

  /**
   * Get JournalBusinessRequest model
   * @this {!Sender}
   * @returns {!JournalBusinessRequestModel} JournalBusinessRequest model
   */
  get JournalBusinessRequestModel () {
    return this._JournalBusinessRequestModel
  }

  /**
   * Get JournalBusinessResponse model
   * @this {!Sender}
   * @returns {!JournalBusinessResponseModel} JournalBusinessResponse model
   */
  get JournalBusinessResponseModel () {
    return this._JournalBusinessResponseModel
  }

  /**
   * Get AccountsGetPageRequest model
   * @this {!Sender}
   * @returns {!AccountsGetPageRequestModel} AccountsGetPageRequest model
   */
  get AccountsGetPageRequestModel () {
    return this._AccountsGetPageRequestModel
  }

  /**
   * Get AccountsGetPageResponse model
   * @this {!Sender}
   * @returns {!AccountsGetPageResponseModel} AccountsGetPageResponse model
   */
  get AccountsGetPageResponseModel () {
    return this._AccountsGetPageResponseModel
  }

  /**
   * Get HeartbeatNotify model
   * @this {!Sender}
   * @returns {!HeartbeatNotifyModel} HeartbeatNotify model
   */
  get HeartbeatNotifyModel () {
    return this._HeartbeatNotifyModel
  }

  /**
   * Get OrdersClosedSummariesGetRequest model
   * @this {!Sender}
   * @returns {!OrdersClosedSummariesGetRequestModel} OrdersClosedSummariesGetRequest model
   */
  get OrdersClosedSummariesGetRequestModel () {
    return this._OrdersClosedSummariesGetRequestModel
  }

  /**
   * Get OrdersClosedSummariesGetResponse model
   * @this {!Sender}
   * @returns {!OrdersClosedSummariesGetResponseModel} OrdersClosedSummariesGetResponse model
   */
  get OrdersClosedSummariesGetResponseModel () {
    return this._OrdersClosedSummariesGetResponseModel
  }

  /**
   * Get OrdersGetPageRequest model
   * @this {!Sender}
   * @returns {!OrdersGetPageRequestModel} OrdersGetPageRequest model
   */
  get OrdersGetPageRequestModel () {
    return this._OrdersGetPageRequestModel
  }

  /**
   * Get OrdersGetPageResponse model
   * @this {!Sender}
   * @returns {!OrdersGetPageResponseModel} OrdersGetPageResponse model
   */
  get OrdersGetPageResponseModel () {
    return this._OrdersGetPageResponseModel
  }

  /**
   * Get StorageUniversalNotify model
   * @this {!Sender}
   * @returns {!StorageUniversalNotifyModel} StorageUniversalNotify model
   */
  get StorageUniversalNotifyModel () {
    return this._StorageUniversalNotifyModel
  }

  /**
   * Get ClientLoginInfoGetRequest model
   * @this {!Sender}
   * @returns {!ClientLoginInfoGetRequestModel} ClientLoginInfoGetRequest model
   */
  get ClientLoginInfoGetRequestModel () {
    return this._ClientLoginInfoGetRequestModel
  }

  /**
   * Get ClientLoginInfoGetResponse model
   * @this {!Sender}
   * @returns {!ClientLoginInfoGetResponseModel} ClientLoginInfoGetResponse model
   */
  get ClientLoginInfoGetResponseModel () {
    return this._ClientLoginInfoGetResponseModel
  }

  /**
   * Get AccountGroupExistsGetRequest model
   * @this {!Sender}
   * @returns {!AccountGroupExistsGetRequestModel} AccountGroupExistsGetRequest model
   */
  get AccountGroupExistsGetRequestModel () {
    return this._AccountGroupExistsGetRequestModel
  }

  /**
   * Get AccountGroupExistsGetResponse model
   * @this {!Sender}
   * @returns {!AccountGroupExistsGetResponseModel} AccountGroupExistsGetResponse model
   */
  get AccountGroupExistsGetResponseModel () {
    return this._AccountGroupExistsGetResponseModel
  }

  /**
   * Get LoginWithPasswordRequest model
   * @this {!Sender}
   * @returns {!LoginWithPasswordRequestModel} LoginWithPasswordRequest model
   */
  get LoginWithPasswordRequestModel () {
    return this._LoginWithPasswordRequestModel
  }

  /**
   * Get LoginWithPasswordResponse model
   * @this {!Sender}
   * @returns {!LoginWithPasswordResponseModel} LoginWithPasswordResponse model
   */
  get LoginWithPasswordResponseModel () {
    return this._LoginWithPasswordResponseModel
  }

  /**
   * Get LoginWithTokenRequest model
   * @this {!Sender}
   * @returns {!LoginWithTokenRequestModel} LoginWithTokenRequest model
   */
  get LoginWithTokenRequestModel () {
    return this._LoginWithTokenRequestModel
  }

  /**
   * Get LoginWithTokenResponse model
   * @this {!Sender}
   * @returns {!LoginWithTokenResponseModel} LoginWithTokenResponse model
   */
  get LoginWithTokenResponseModel () {
    return this._LoginWithTokenResponseModel
  }

  /**
   * Get RefreshTokenRequest model
   * @this {!Sender}
   * @returns {!RefreshTokenRequestModel} RefreshTokenRequest model
   */
  get RefreshTokenRequestModel () {
    return this._RefreshTokenRequestModel
  }

  /**
   * Get RefreshTokenResponse model
   * @this {!Sender}
   * @returns {!RefreshTokenResponseModel} RefreshTokenResponse model
   */
  get RefreshTokenResponseModel () {
    return this._RefreshTokenResponseModel
  }

  /**
   * Get PersonGetRequest model
   * @this {!Sender}
   * @returns {!PersonGetRequestModel} PersonGetRequest model
   */
  get PersonGetRequestModel () {
    return this._PersonGetRequestModel
  }

  /**
   * Get PersonGetResponse model
   * @this {!Sender}
   * @returns {!PersonGetResponseModel} PersonGetResponse model
   */
  get PersonGetResponseModel () {
    return this._PersonGetResponseModel
  }

  /**
   * Get PersonsGetAllRequest model
   * @this {!Sender}
   * @returns {!PersonsGetAllRequestModel} PersonsGetAllRequest model
   */
  get PersonsGetAllRequestModel () {
    return this._PersonsGetAllRequestModel
  }

  /**
   * Get PersonsGetAllResponse model
   * @this {!Sender}
   * @returns {!PersonsGetAllResponseModel} PersonsGetAllResponse model
   */
  get PersonsGetAllResponseModel () {
    return this._PersonsGetAllResponseModel
  }

  /**
   * Get PersonCreateRequest model
   * @this {!Sender}
   * @returns {!PersonCreateRequestModel} PersonCreateRequest model
   */
  get PersonCreateRequestModel () {
    return this._PersonCreateRequestModel
  }

  /**
   * Get PersonCreateResponse model
   * @this {!Sender}
   * @returns {!PersonCreateResponseModel} PersonCreateResponse model
   */
  get PersonCreateResponseModel () {
    return this._PersonCreateResponseModel
  }

  /**
   * Get PersonModifyRequest model
   * @this {!Sender}
   * @returns {!PersonModifyRequestModel} PersonModifyRequest model
   */
  get PersonModifyRequestModel () {
    return this._PersonModifyRequestModel
  }

  /**
   * Get PersonModifyResponse model
   * @this {!Sender}
   * @returns {!PersonModifyResponseModel} PersonModifyResponse model
   */
  get PersonModifyResponseModel () {
    return this._PersonModifyResponseModel
  }

  /**
   * Get PersonUpdateNotify model
   * @this {!Sender}
   * @returns {!PersonUpdateNotifyModel} PersonUpdateNotify model
   */
  get PersonUpdateNotifyModel () {
    return this._PersonUpdateNotifyModel
  }

  /**
   * Get SecuritySettingsGetRequest model
   * @this {!Sender}
   * @returns {!SecuritySettingsGetRequestModel} SecuritySettingsGetRequest model
   */
  get SecuritySettingsGetRequestModel () {
    return this._SecuritySettingsGetRequestModel
  }

  /**
   * Get SecuritySettingsGetResponse model
   * @this {!Sender}
   * @returns {!SecuritySettingsGetResponseModel} SecuritySettingsGetResponse model
   */
  get SecuritySettingsGetResponseModel () {
    return this._SecuritySettingsGetResponseModel
  }

  /**
   * Get SecuritySettingsGetAllRequest model
   * @this {!Sender}
   * @returns {!SecuritySettingsGetAllRequestModel} SecuritySettingsGetAllRequest model
   */
  get SecuritySettingsGetAllRequestModel () {
    return this._SecuritySettingsGetAllRequestModel
  }

  /**
   * Get SecuritySettingsGetAllResponse model
   * @this {!Sender}
   * @returns {!SecuritySettingsGetAllResponseModel} SecuritySettingsGetAllResponse model
   */
  get SecuritySettingsGetAllResponseModel () {
    return this._SecuritySettingsGetAllResponseModel
  }

  /**
   * Get SecuritySettingsCreateRequest model
   * @this {!Sender}
   * @returns {!SecuritySettingsCreateRequestModel} SecuritySettingsCreateRequest model
   */
  get SecuritySettingsCreateRequestModel () {
    return this._SecuritySettingsCreateRequestModel
  }

  /**
   * Get SecuritySettingsCreateResponse model
   * @this {!Sender}
   * @returns {!SecuritySettingsCreateResponseModel} SecuritySettingsCreateResponse model
   */
  get SecuritySettingsCreateResponseModel () {
    return this._SecuritySettingsCreateResponseModel
  }

  /**
   * Get SecuritySettingsModifyRequest model
   * @this {!Sender}
   * @returns {!SecuritySettingsModifyRequestModel} SecuritySettingsModifyRequest model
   */
  get SecuritySettingsModifyRequestModel () {
    return this._SecuritySettingsModifyRequestModel
  }

  /**
   * Get SecuritySettingsModifyResponse model
   * @this {!Sender}
   * @returns {!SecuritySettingsModifyResponseModel} SecuritySettingsModifyResponse model
   */
  get SecuritySettingsModifyResponseModel () {
    return this._SecuritySettingsModifyResponseModel
  }

  /**
   * Get SecuritySettingsDeleteRequest model
   * @this {!Sender}
   * @returns {!SecuritySettingsDeleteRequestModel} SecuritySettingsDeleteRequest model
   */
  get SecuritySettingsDeleteRequestModel () {
    return this._SecuritySettingsDeleteRequestModel
  }

  /**
   * Get SecuritySettingsDeleteResponse model
   * @this {!Sender}
   * @returns {!SecuritySettingsDeleteResponseModel} SecuritySettingsDeleteResponse model
   */
  get SecuritySettingsDeleteResponseModel () {
    return this._SecuritySettingsDeleteResponseModel
  }

  /**
   * Get SecuritySettingsUpdateNotify model
   * @this {!Sender}
   * @returns {!SecuritySettingsUpdateNotifyModel} SecuritySettingsUpdateNotify model
   */
  get SecuritySettingsUpdateNotifyModel () {
    return this._SecuritySettingsUpdateNotifyModel
  }

  /**
   * Get FirewallRuleGetRequest model
   * @this {!Sender}
   * @returns {!FirewallRuleGetRequestModel} FirewallRuleGetRequest model
   */
  get FirewallRuleGetRequestModel () {
    return this._FirewallRuleGetRequestModel
  }

  /**
   * Get FirewallRuleGetResponse model
   * @this {!Sender}
   * @returns {!FirewallRuleGetResponseModel} FirewallRuleGetResponse model
   */
  get FirewallRuleGetResponseModel () {
    return this._FirewallRuleGetResponseModel
  }

  /**
   * Get FirewallRulesGetAllRequest model
   * @this {!Sender}
   * @returns {!FirewallRulesGetAllRequestModel} FirewallRulesGetAllRequest model
   */
  get FirewallRulesGetAllRequestModel () {
    return this._FirewallRulesGetAllRequestModel
  }

  /**
   * Get FirewallRulesGetAllResponse model
   * @this {!Sender}
   * @returns {!FirewallRulesGetAllResponseModel} FirewallRulesGetAllResponse model
   */
  get FirewallRulesGetAllResponseModel () {
    return this._FirewallRulesGetAllResponseModel
  }

  /**
   * Get FirewallRuleCreateRequest model
   * @this {!Sender}
   * @returns {!FirewallRuleCreateRequestModel} FirewallRuleCreateRequest model
   */
  get FirewallRuleCreateRequestModel () {
    return this._FirewallRuleCreateRequestModel
  }

  /**
   * Get FirewallRuleCreateResponse model
   * @this {!Sender}
   * @returns {!FirewallRuleCreateResponseModel} FirewallRuleCreateResponse model
   */
  get FirewallRuleCreateResponseModel () {
    return this._FirewallRuleCreateResponseModel
  }

  /**
   * Get FirewallRuleModifyRequest model
   * @this {!Sender}
   * @returns {!FirewallRuleModifyRequestModel} FirewallRuleModifyRequest model
   */
  get FirewallRuleModifyRequestModel () {
    return this._FirewallRuleModifyRequestModel
  }

  /**
   * Get FirewallRuleModifyResponse model
   * @this {!Sender}
   * @returns {!FirewallRuleModifyResponseModel} FirewallRuleModifyResponse model
   */
  get FirewallRuleModifyResponseModel () {
    return this._FirewallRuleModifyResponseModel
  }

  /**
   * Get FirewallRuleDeleteRequest model
   * @this {!Sender}
   * @returns {!FirewallRuleDeleteRequestModel} FirewallRuleDeleteRequest model
   */
  get FirewallRuleDeleteRequestModel () {
    return this._FirewallRuleDeleteRequestModel
  }

  /**
   * Get FirewallRuleDeleteResponse model
   * @this {!Sender}
   * @returns {!FirewallRuleDeleteResponseModel} FirewallRuleDeleteResponse model
   */
  get FirewallRuleDeleteResponseModel () {
    return this._FirewallRuleDeleteResponseModel
  }

  /**
   * Get FirewallRuleUpdateNotify model
   * @this {!Sender}
   * @returns {!FirewallRuleUpdateNotifyModel} FirewallRuleUpdateNotify model
   */
  get FirewallRuleUpdateNotifyModel () {
    return this._FirewallRuleUpdateNotifyModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof PingModuleRequest) && (value.fbeType === this.PingModuleRequestModel.fbeType)) {
      return this.send_PingModuleRequest(value)
    }
    if ((value instanceof PongModuleResponse) && (value.fbeType === this.PongModuleResponseModel.fbeType)) {
      return this.send_PongModuleResponse(value)
    }
    if ((value instanceof PingModuleReject) && (value.fbeType === this.PingModuleRejectModel.fbeType)) {
      return this.send_PingModuleReject(value)
    }
    if ((value instanceof Deprecated3) && (value.fbeType === this.Deprecated3Model.fbeType)) {
      return this.send_Deprecated3(value)
    }
    if ((value instanceof Deprecated4) && (value.fbeType === this.Deprecated4Model.fbeType)) {
      return this.send_Deprecated4(value)
    }
    if ((value instanceof Deprecated1) && (value.fbeType === this.Deprecated1Model.fbeType)) {
      return this.send_Deprecated1(value)
    }
    if ((value instanceof Deprecated2) && (value.fbeType === this.Deprecated2Model.fbeType)) {
      return this.send_Deprecated2(value)
    }
    if ((value instanceof ManagerSessionInfoRequest) && (value.fbeType === this.ManagerSessionInfoRequestModel.fbeType)) {
      return this.send_ManagerSessionInfoRequest(value)
    }
    if ((value instanceof ManagerSessionInfoResponse) && (value.fbeType === this.ManagerSessionInfoResponseModel.fbeType)) {
      return this.send_ManagerSessionInfoResponse(value)
    }
    if ((value instanceof OnlineSessionsRequest) && (value.fbeType === this.OnlineSessionsRequestModel.fbeType)) {
      return this.send_OnlineSessionsRequest(value)
    }
    if ((value instanceof OnlineSessionsResponse) && (value.fbeType === this.OnlineSessionsResponseModel.fbeType)) {
      return this.send_OnlineSessionsResponse(value)
    }
    if ((value instanceof DropSessionRequest) && (value.fbeType === this.DropSessionRequestModel.fbeType)) {
      return this.send_DropSessionRequest(value)
    }
    if ((value instanceof DropSessionResponse) && (value.fbeType === this.DropSessionResponseModel.fbeType)) {
      return this.send_DropSessionResponse(value)
    }
    if ((value instanceof ConfigRequest) && (value.fbeType === this.ConfigRequestModel.fbeType)) {
      return this.send_ConfigRequest(value)
    }
    if ((value instanceof ConfigResponse) && (value.fbeType === this.ConfigResponseModel.fbeType)) {
      return this.send_ConfigResponse(value)
    }
    if ((value instanceof ConfigReject) && (value.fbeType === this.ConfigRejectModel.fbeType)) {
      return this.send_ConfigReject(value)
    }
    if ((value instanceof ConfigUpdateRequest) && (value.fbeType === this.ConfigUpdateRequestModel.fbeType)) {
      return this.send_ConfigUpdateRequest(value)
    }
    if ((value instanceof ConfigUpdateResponse) && (value.fbeType === this.ConfigUpdateResponseModel.fbeType)) {
      return this.send_ConfigUpdateResponse(value)
    }
    if ((value instanceof ConfigUpdateNotify) && (value.fbeType === this.ConfigUpdateNotifyModel.fbeType)) {
      return this.send_ConfigUpdateNotify(value)
    }
    if ((value instanceof ConfigVersionRequest) && (value.fbeType === this.ConfigVersionRequestModel.fbeType)) {
      return this.send_ConfigVersionRequest(value)
    }
    if ((value instanceof ConfigVersionResponse) && (value.fbeType === this.ConfigVersionResponseModel.fbeType)) {
      return this.send_ConfigVersionResponse(value)
    }
    if ((value instanceof ConfigExportRequest) && (value.fbeType === this.ConfigExportRequestModel.fbeType)) {
      return this.send_ConfigExportRequest(value)
    }
    if ((value instanceof ConfigExportResponse) && (value.fbeType === this.ConfigExportResponseModel.fbeType)) {
      return this.send_ConfigExportResponse(value)
    }
    if ((value instanceof ConfigImportRequest) && (value.fbeType === this.ConfigImportRequestModel.fbeType)) {
      return this.send_ConfigImportRequest(value)
    }
    if ((value instanceof ConfigImportResponse) && (value.fbeType === this.ConfigImportResponseModel.fbeType)) {
      return this.send_ConfigImportResponse(value)
    }
    if ((value instanceof ConfigValidateRequest) && (value.fbeType === this.ConfigValidateRequestModel.fbeType)) {
      return this.send_ConfigValidateRequest(value)
    }
    if ((value instanceof ConfigValidateResponse) && (value.fbeType === this.ConfigValidateResponseModel.fbeType)) {
      return this.send_ConfigValidateResponse(value)
    }
    if ((value instanceof ConfigRefreshRequest) && (value.fbeType === this.ConfigRefreshRequestModel.fbeType)) {
      return this.send_ConfigRefreshRequest(value)
    }
    if ((value instanceof ConfigRefreshResponse) && (value.fbeType === this.ConfigRefreshResponseModel.fbeType)) {
      return this.send_ConfigRefreshResponse(value)
    }
    if ((value instanceof ConfigHistoryRequest) && (value.fbeType === this.ConfigHistoryRequestModel.fbeType)) {
      return this.send_ConfigHistoryRequest(value)
    }
    if ((value instanceof ConfigHistoryResponse) && (value.fbeType === this.ConfigHistoryResponseModel.fbeType)) {
      return this.send_ConfigHistoryResponse(value)
    }
    if ((value instanceof ConfigRollbackRequest) && (value.fbeType === this.ConfigRollbackRequestModel.fbeType)) {
      return this.send_ConfigRollbackRequest(value)
    }
    if ((value instanceof ConfigRollbackResponse) && (value.fbeType === this.ConfigRollbackResponseModel.fbeType)) {
      return this.send_ConfigRollbackResponse(value)
    }
    if ((value instanceof HostGetRequest) && (value.fbeType === this.HostGetRequestModel.fbeType)) {
      return this.send_HostGetRequest(value)
    }
    if ((value instanceof HostGetResponse) && (value.fbeType === this.HostGetResponseModel.fbeType)) {
      return this.send_HostGetResponse(value)
    }
    if ((value instanceof HostsGetAllRequest) && (value.fbeType === this.HostsGetAllRequestModel.fbeType)) {
      return this.send_HostsGetAllRequest(value)
    }
    if ((value instanceof HostsGetAllResponse) && (value.fbeType === this.HostsGetAllResponseModel.fbeType)) {
      return this.send_HostsGetAllResponse(value)
    }
    if ((value instanceof HostCreateRequest) && (value.fbeType === this.HostCreateRequestModel.fbeType)) {
      return this.send_HostCreateRequest(value)
    }
    if ((value instanceof HostCreateResponse) && (value.fbeType === this.HostCreateResponseModel.fbeType)) {
      return this.send_HostCreateResponse(value)
    }
    if ((value instanceof HostModifyRequest) && (value.fbeType === this.HostModifyRequestModel.fbeType)) {
      return this.send_HostModifyRequest(value)
    }
    if ((value instanceof HostModifyResponse) && (value.fbeType === this.HostModifyResponseModel.fbeType)) {
      return this.send_HostModifyResponse(value)
    }
    if ((value instanceof HostDeleteRequest) && (value.fbeType === this.HostDeleteRequestModel.fbeType)) {
      return this.send_HostDeleteRequest(value)
    }
    if ((value instanceof HostDeleteResponse) && (value.fbeType === this.HostDeleteResponseModel.fbeType)) {
      return this.send_HostDeleteResponse(value)
    }
    if ((value instanceof HostUpdateNotify) && (value.fbeType === this.HostUpdateNotifyModel.fbeType)) {
      return this.send_HostUpdateNotify(value)
    }
    if ((value instanceof ModuleGetRequest) && (value.fbeType === this.ModuleGetRequestModel.fbeType)) {
      return this.send_ModuleGetRequest(value)
    }
    if ((value instanceof ModuleGetResponse) && (value.fbeType === this.ModuleGetResponseModel.fbeType)) {
      return this.send_ModuleGetResponse(value)
    }
    if ((value instanceof ModulesGetAllRequest) && (value.fbeType === this.ModulesGetAllRequestModel.fbeType)) {
      return this.send_ModulesGetAllRequest(value)
    }
    if ((value instanceof ModulesGetAllResponse) && (value.fbeType === this.ModulesGetAllResponseModel.fbeType)) {
      return this.send_ModulesGetAllResponse(value)
    }
    if ((value instanceof ModuleCreateRequest) && (value.fbeType === this.ModuleCreateRequestModel.fbeType)) {
      return this.send_ModuleCreateRequest(value)
    }
    if ((value instanceof ModuleCreateResponse) && (value.fbeType === this.ModuleCreateResponseModel.fbeType)) {
      return this.send_ModuleCreateResponse(value)
    }
    if ((value instanceof ModuleModifyRequest) && (value.fbeType === this.ModuleModifyRequestModel.fbeType)) {
      return this.send_ModuleModifyRequest(value)
    }
    if ((value instanceof ModuleModifyResponse) && (value.fbeType === this.ModuleModifyResponseModel.fbeType)) {
      return this.send_ModuleModifyResponse(value)
    }
    if ((value instanceof ModuleDeleteRequest) && (value.fbeType === this.ModuleDeleteRequestModel.fbeType)) {
      return this.send_ModuleDeleteRequest(value)
    }
    if ((value instanceof ModuleDeleteResponse) && (value.fbeType === this.ModuleDeleteResponseModel.fbeType)) {
      return this.send_ModuleDeleteResponse(value)
    }
    if ((value instanceof ModuleUpdateNotify) && (value.fbeType === this.ModuleUpdateNotifyModel.fbeType)) {
      return this.send_ModuleUpdateNotify(value)
    }
    if ((value instanceof ServerTimeGetRequest) && (value.fbeType === this.ServerTimeGetRequestModel.fbeType)) {
      return this.send_ServerTimeGetRequest(value)
    }
    if ((value instanceof ServerTimeGetResponse) && (value.fbeType === this.ServerTimeGetResponseModel.fbeType)) {
      return this.send_ServerTimeGetResponse(value)
    }
    if ((value instanceof ManagerPlatformTimezoneGetRequest) && (value.fbeType === this.ManagerPlatformTimezoneGetRequestModel.fbeType)) {
      return this.send_ManagerPlatformTimezoneGetRequest(value)
    }
    if ((value instanceof ManagerPlatformTimezoneGetResponse) && (value.fbeType === this.ManagerPlatformTimezoneGetResponseModel.fbeType)) {
      return this.send_ManagerPlatformTimezoneGetResponse(value)
    }
    if ((value instanceof TimezoneGetRequest) && (value.fbeType === this.TimezoneGetRequestModel.fbeType)) {
      return this.send_TimezoneGetRequest(value)
    }
    if ((value instanceof TimezoneGetResponse) && (value.fbeType === this.TimezoneGetResponseModel.fbeType)) {
      return this.send_TimezoneGetResponse(value)
    }
    if ((value instanceof TimezonesGetAllRequest) && (value.fbeType === this.TimezonesGetAllRequestModel.fbeType)) {
      return this.send_TimezonesGetAllRequest(value)
    }
    if ((value instanceof TimezonesGetAllResponse) && (value.fbeType === this.TimezonesGetAllResponseModel.fbeType)) {
      return this.send_TimezonesGetAllResponse(value)
    }
    if ((value instanceof TimezoneCreateRequest) && (value.fbeType === this.TimezoneCreateRequestModel.fbeType)) {
      return this.send_TimezoneCreateRequest(value)
    }
    if ((value instanceof TimezoneCreateResponse) && (value.fbeType === this.TimezoneCreateResponseModel.fbeType)) {
      return this.send_TimezoneCreateResponse(value)
    }
    if ((value instanceof TimezoneModifyRequest) && (value.fbeType === this.TimezoneModifyRequestModel.fbeType)) {
      return this.send_TimezoneModifyRequest(value)
    }
    if ((value instanceof TimezoneModifyResponse) && (value.fbeType === this.TimezoneModifyResponseModel.fbeType)) {
      return this.send_TimezoneModifyResponse(value)
    }
    if ((value instanceof TimezoneDeleteRequest) && (value.fbeType === this.TimezoneDeleteRequestModel.fbeType)) {
      return this.send_TimezoneDeleteRequest(value)
    }
    if ((value instanceof TimezoneDeleteResponse) && (value.fbeType === this.TimezoneDeleteResponseModel.fbeType)) {
      return this.send_TimezoneDeleteResponse(value)
    }
    if ((value instanceof TimezoneUpdateNotify) && (value.fbeType === this.TimezoneUpdateNotifyModel.fbeType)) {
      return this.send_TimezoneUpdateNotify(value)
    }
    if ((value instanceof CurrencyGroupGetRequest) && (value.fbeType === this.CurrencyGroupGetRequestModel.fbeType)) {
      return this.send_CurrencyGroupGetRequest(value)
    }
    if ((value instanceof CurrencyGroupGetResponse) && (value.fbeType === this.CurrencyGroupGetResponseModel.fbeType)) {
      return this.send_CurrencyGroupGetResponse(value)
    }
    if ((value instanceof CurrencyGroupsGetAllRequest) && (value.fbeType === this.CurrencyGroupsGetAllRequestModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllRequest(value)
    }
    if ((value instanceof CurrencyGroupsGetAllResponse) && (value.fbeType === this.CurrencyGroupsGetAllResponseModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllResponse(value)
    }
    if ((value instanceof CurrencyGroupCreateRequest) && (value.fbeType === this.CurrencyGroupCreateRequestModel.fbeType)) {
      return this.send_CurrencyGroupCreateRequest(value)
    }
    if ((value instanceof CurrencyGroupCreateResponse) && (value.fbeType === this.CurrencyGroupCreateResponseModel.fbeType)) {
      return this.send_CurrencyGroupCreateResponse(value)
    }
    if ((value instanceof CurrencyGroupModifyRequest) && (value.fbeType === this.CurrencyGroupModifyRequestModel.fbeType)) {
      return this.send_CurrencyGroupModifyRequest(value)
    }
    if ((value instanceof CurrencyGroupModifyResponse) && (value.fbeType === this.CurrencyGroupModifyResponseModel.fbeType)) {
      return this.send_CurrencyGroupModifyResponse(value)
    }
    if ((value instanceof CurrencyGroupDeleteRequest) && (value.fbeType === this.CurrencyGroupDeleteRequestModel.fbeType)) {
      return this.send_CurrencyGroupDeleteRequest(value)
    }
    if ((value instanceof CurrencyGroupDeleteResponse) && (value.fbeType === this.CurrencyGroupDeleteResponseModel.fbeType)) {
      return this.send_CurrencyGroupDeleteResponse(value)
    }
    if ((value instanceof CurrencyGroupUpdateNotify) && (value.fbeType === this.CurrencyGroupUpdateNotifyModel.fbeType)) {
      return this.send_CurrencyGroupUpdateNotify(value)
    }
    if ((value instanceof CurrencyGetRequest) && (value.fbeType === this.CurrencyGetRequestModel.fbeType)) {
      return this.send_CurrencyGetRequest(value)
    }
    if ((value instanceof CurrencyGetResponse) && (value.fbeType === this.CurrencyGetResponseModel.fbeType)) {
      return this.send_CurrencyGetResponse(value)
    }
    if ((value instanceof CurrenciesGetAllRequest) && (value.fbeType === this.CurrenciesGetAllRequestModel.fbeType)) {
      return this.send_CurrenciesGetAllRequest(value)
    }
    if ((value instanceof CurrenciesGetAllResponse) && (value.fbeType === this.CurrenciesGetAllResponseModel.fbeType)) {
      return this.send_CurrenciesGetAllResponse(value)
    }
    if ((value instanceof CurrencyCreateRequest) && (value.fbeType === this.CurrencyCreateRequestModel.fbeType)) {
      return this.send_CurrencyCreateRequest(value)
    }
    if ((value instanceof CurrencyCreateResponse) && (value.fbeType === this.CurrencyCreateResponseModel.fbeType)) {
      return this.send_CurrencyCreateResponse(value)
    }
    if ((value instanceof CurrencyModifyRequest) && (value.fbeType === this.CurrencyModifyRequestModel.fbeType)) {
      return this.send_CurrencyModifyRequest(value)
    }
    if ((value instanceof CurrencyModifyResponse) && (value.fbeType === this.CurrencyModifyResponseModel.fbeType)) {
      return this.send_CurrencyModifyResponse(value)
    }
    if ((value instanceof CurrencyDeleteRequest) && (value.fbeType === this.CurrencyDeleteRequestModel.fbeType)) {
      return this.send_CurrencyDeleteRequest(value)
    }
    if ((value instanceof CurrencyDeleteResponse) && (value.fbeType === this.CurrencyDeleteResponseModel.fbeType)) {
      return this.send_CurrencyDeleteResponse(value)
    }
    if ((value instanceof CurrencyUpdateNotify) && (value.fbeType === this.CurrencyUpdateNotifyModel.fbeType)) {
      return this.send_CurrencyUpdateNotify(value)
    }
    if ((value instanceof SymbolGroupGetRequest) && (value.fbeType === this.SymbolGroupGetRequestModel.fbeType)) {
      return this.send_SymbolGroupGetRequest(value)
    }
    if ((value instanceof SymbolGroupGetResponse) && (value.fbeType === this.SymbolGroupGetResponseModel.fbeType)) {
      return this.send_SymbolGroupGetResponse(value)
    }
    if ((value instanceof SymbolGroupsGetAllRequest) && (value.fbeType === this.SymbolGroupsGetAllRequestModel.fbeType)) {
      return this.send_SymbolGroupsGetAllRequest(value)
    }
    if ((value instanceof SymbolGroupsGetAllResponse) && (value.fbeType === this.SymbolGroupsGetAllResponseModel.fbeType)) {
      return this.send_SymbolGroupsGetAllResponse(value)
    }
    if ((value instanceof SymbolGroupCreateRequest) && (value.fbeType === this.SymbolGroupCreateRequestModel.fbeType)) {
      return this.send_SymbolGroupCreateRequest(value)
    }
    if ((value instanceof SymbolGroupCreateResponse) && (value.fbeType === this.SymbolGroupCreateResponseModel.fbeType)) {
      return this.send_SymbolGroupCreateResponse(value)
    }
    if ((value instanceof SymbolGroupModifyRequest) && (value.fbeType === this.SymbolGroupModifyRequestModel.fbeType)) {
      return this.send_SymbolGroupModifyRequest(value)
    }
    if ((value instanceof SymbolGroupModifyResponse) && (value.fbeType === this.SymbolGroupModifyResponseModel.fbeType)) {
      return this.send_SymbolGroupModifyResponse(value)
    }
    if ((value instanceof SymbolGroupDeleteRequest) && (value.fbeType === this.SymbolGroupDeleteRequestModel.fbeType)) {
      return this.send_SymbolGroupDeleteRequest(value)
    }
    if ((value instanceof SymbolGroupDeleteResponse) && (value.fbeType === this.SymbolGroupDeleteResponseModel.fbeType)) {
      return this.send_SymbolGroupDeleteResponse(value)
    }
    if ((value instanceof SymbolGroupUpdateNotify) && (value.fbeType === this.SymbolGroupUpdateNotifyModel.fbeType)) {
      return this.send_SymbolGroupUpdateNotify(value)
    }
    if ((value instanceof SymbolGetRequest) && (value.fbeType === this.SymbolGetRequestModel.fbeType)) {
      return this.send_SymbolGetRequest(value)
    }
    if ((value instanceof SymbolGetResponse) && (value.fbeType === this.SymbolGetResponseModel.fbeType)) {
      return this.send_SymbolGetResponse(value)
    }
    if ((value instanceof SymbolsGetAllRequest) && (value.fbeType === this.SymbolsGetAllRequestModel.fbeType)) {
      return this.send_SymbolsGetAllRequest(value)
    }
    if ((value instanceof SymbolsGetAllResponse) && (value.fbeType === this.SymbolsGetAllResponseModel.fbeType)) {
      return this.send_SymbolsGetAllResponse(value)
    }
    if ((value instanceof SymbolCreateRequest) && (value.fbeType === this.SymbolCreateRequestModel.fbeType)) {
      return this.send_SymbolCreateRequest(value)
    }
    if ((value instanceof SymbolCreateResponse) && (value.fbeType === this.SymbolCreateResponseModel.fbeType)) {
      return this.send_SymbolCreateResponse(value)
    }
    if ((value instanceof SymbolModifyRequest) && (value.fbeType === this.SymbolModifyRequestModel.fbeType)) {
      return this.send_SymbolModifyRequest(value)
    }
    if ((value instanceof SymbolModifyResponse) && (value.fbeType === this.SymbolModifyResponseModel.fbeType)) {
      return this.send_SymbolModifyResponse(value)
    }
    if ((value instanceof SymbolDeleteRequest) && (value.fbeType === this.SymbolDeleteRequestModel.fbeType)) {
      return this.send_SymbolDeleteRequest(value)
    }
    if ((value instanceof SymbolDeleteResponse) && (value.fbeType === this.SymbolDeleteResponseModel.fbeType)) {
      return this.send_SymbolDeleteResponse(value)
    }
    if ((value instanceof SymbolUpdateNotify) && (value.fbeType === this.SymbolUpdateNotifyModel.fbeType)) {
      return this.send_SymbolUpdateNotify(value)
    }
    if ((value instanceof SwapsExportRequest) && (value.fbeType === this.SwapsExportRequestModel.fbeType)) {
      return this.send_SwapsExportRequest(value)
    }
    if ((value instanceof SwapsExportResponse) && (value.fbeType === this.SwapsExportResponseModel.fbeType)) {
      return this.send_SwapsExportResponse(value)
    }
    if ((value instanceof SwapsImportRequest) && (value.fbeType === this.SwapsImportRequestModel.fbeType)) {
      return this.send_SwapsImportRequest(value)
    }
    if ((value instanceof SwapsImportResponse) && (value.fbeType === this.SwapsImportResponseModel.fbeType)) {
      return this.send_SwapsImportResponse(value)
    }
    if ((value instanceof CommissionProfileGetRequest) && (value.fbeType === this.CommissionProfileGetRequestModel.fbeType)) {
      return this.send_CommissionProfileGetRequest(value)
    }
    if ((value instanceof CommissionProfileGetResponse) && (value.fbeType === this.CommissionProfileGetResponseModel.fbeType)) {
      return this.send_CommissionProfileGetResponse(value)
    }
    if ((value instanceof CommissionProfilesGetAllRequest) && (value.fbeType === this.CommissionProfilesGetAllRequestModel.fbeType)) {
      return this.send_CommissionProfilesGetAllRequest(value)
    }
    if ((value instanceof CommissionProfilesGetAllResponse) && (value.fbeType === this.CommissionProfilesGetAllResponseModel.fbeType)) {
      return this.send_CommissionProfilesGetAllResponse(value)
    }
    if ((value instanceof CommissionProfileCreateRequest) && (value.fbeType === this.CommissionProfileCreateRequestModel.fbeType)) {
      return this.send_CommissionProfileCreateRequest(value)
    }
    if ((value instanceof CommissionProfileCreateResponse) && (value.fbeType === this.CommissionProfileCreateResponseModel.fbeType)) {
      return this.send_CommissionProfileCreateResponse(value)
    }
    if ((value instanceof CommissionProfileModifyRequest) && (value.fbeType === this.CommissionProfileModifyRequestModel.fbeType)) {
      return this.send_CommissionProfileModifyRequest(value)
    }
    if ((value instanceof CommissionProfileModifyResponse) && (value.fbeType === this.CommissionProfileModifyResponseModel.fbeType)) {
      return this.send_CommissionProfileModifyResponse(value)
    }
    if ((value instanceof CommissionProfileDeleteRequest) && (value.fbeType === this.CommissionProfileDeleteRequestModel.fbeType)) {
      return this.send_CommissionProfileDeleteRequest(value)
    }
    if ((value instanceof CommissionProfileDeleteResponse) && (value.fbeType === this.CommissionProfileDeleteResponseModel.fbeType)) {
      return this.send_CommissionProfileDeleteResponse(value)
    }
    if ((value instanceof CommissionProfileUpdateNotify) && (value.fbeType === this.CommissionProfileUpdateNotifyModel.fbeType)) {
      return this.send_CommissionProfileUpdateNotify(value)
    }
    if ((value instanceof MarkupProfileGetRequest) && (value.fbeType === this.MarkupProfileGetRequestModel.fbeType)) {
      return this.send_MarkupProfileGetRequest(value)
    }
    if ((value instanceof MarkupProfileGetResponse) && (value.fbeType === this.MarkupProfileGetResponseModel.fbeType)) {
      return this.send_MarkupProfileGetResponse(value)
    }
    if ((value instanceof MarkupProfilesGetAllRequest) && (value.fbeType === this.MarkupProfilesGetAllRequestModel.fbeType)) {
      return this.send_MarkupProfilesGetAllRequest(value)
    }
    if ((value instanceof MarkupProfilesGetAllResponse) && (value.fbeType === this.MarkupProfilesGetAllResponseModel.fbeType)) {
      return this.send_MarkupProfilesGetAllResponse(value)
    }
    if ((value instanceof MarkupProfileCreateRequest) && (value.fbeType === this.MarkupProfileCreateRequestModel.fbeType)) {
      return this.send_MarkupProfileCreateRequest(value)
    }
    if ((value instanceof MarkupProfileCreateResponse) && (value.fbeType === this.MarkupProfileCreateResponseModel.fbeType)) {
      return this.send_MarkupProfileCreateResponse(value)
    }
    if ((value instanceof MarkupProfileModifyRequest) && (value.fbeType === this.MarkupProfileModifyRequestModel.fbeType)) {
      return this.send_MarkupProfileModifyRequest(value)
    }
    if ((value instanceof MarkupProfileModifyResponse) && (value.fbeType === this.MarkupProfileModifyResponseModel.fbeType)) {
      return this.send_MarkupProfileModifyResponse(value)
    }
    if ((value instanceof MarkupProfileDeleteRequest) && (value.fbeType === this.MarkupProfileDeleteRequestModel.fbeType)) {
      return this.send_MarkupProfileDeleteRequest(value)
    }
    if ((value instanceof MarkupProfileDeleteResponse) && (value.fbeType === this.MarkupProfileDeleteResponseModel.fbeType)) {
      return this.send_MarkupProfileDeleteResponse(value)
    }
    if ((value instanceof MarkupProfileUpdateNotify) && (value.fbeType === this.MarkupProfileUpdateNotifyModel.fbeType)) {
      return this.send_MarkupProfileUpdateNotify(value)
    }
    if ((value instanceof ManagerGroupGetRequest) && (value.fbeType === this.ManagerGroupGetRequestModel.fbeType)) {
      return this.send_ManagerGroupGetRequest(value)
    }
    if ((value instanceof ManagerGroupGetResponse) && (value.fbeType === this.ManagerGroupGetResponseModel.fbeType)) {
      return this.send_ManagerGroupGetResponse(value)
    }
    if ((value instanceof ManagerGroupsGetAllRequest) && (value.fbeType === this.ManagerGroupsGetAllRequestModel.fbeType)) {
      return this.send_ManagerGroupsGetAllRequest(value)
    }
    if ((value instanceof ManagerGroupsGetAllResponse) && (value.fbeType === this.ManagerGroupsGetAllResponseModel.fbeType)) {
      return this.send_ManagerGroupsGetAllResponse(value)
    }
    if ((value instanceof ManagerGroupCreateRequest) && (value.fbeType === this.ManagerGroupCreateRequestModel.fbeType)) {
      return this.send_ManagerGroupCreateRequest(value)
    }
    if ((value instanceof ManagerGroupCreateResponse) && (value.fbeType === this.ManagerGroupCreateResponseModel.fbeType)) {
      return this.send_ManagerGroupCreateResponse(value)
    }
    if ((value instanceof ManagerGroupModifyRequest) && (value.fbeType === this.ManagerGroupModifyRequestModel.fbeType)) {
      return this.send_ManagerGroupModifyRequest(value)
    }
    if ((value instanceof ManagerGroupModifyResponse) && (value.fbeType === this.ManagerGroupModifyResponseModel.fbeType)) {
      return this.send_ManagerGroupModifyResponse(value)
    }
    if ((value instanceof ManagerGroupDeleteRequest) && (value.fbeType === this.ManagerGroupDeleteRequestModel.fbeType)) {
      return this.send_ManagerGroupDeleteRequest(value)
    }
    if ((value instanceof ManagerGroupDeleteResponse) && (value.fbeType === this.ManagerGroupDeleteResponseModel.fbeType)) {
      return this.send_ManagerGroupDeleteResponse(value)
    }
    if ((value instanceof ManagerGroupUpdateNotify) && (value.fbeType === this.ManagerGroupUpdateNotifyModel.fbeType)) {
      return this.send_ManagerGroupUpdateNotify(value)
    }
    if ((value instanceof ManagerGetRequest) && (value.fbeType === this.ManagerGetRequestModel.fbeType)) {
      return this.send_ManagerGetRequest(value)
    }
    if ((value instanceof ManagerGetResponse) && (value.fbeType === this.ManagerGetResponseModel.fbeType)) {
      return this.send_ManagerGetResponse(value)
    }
    if ((value instanceof ManagersGetAllRequest) && (value.fbeType === this.ManagersGetAllRequestModel.fbeType)) {
      return this.send_ManagersGetAllRequest(value)
    }
    if ((value instanceof ManagersGetAllResponse) && (value.fbeType === this.ManagersGetAllResponseModel.fbeType)) {
      return this.send_ManagersGetAllResponse(value)
    }
    if ((value instanceof ManagerCreateRequest) && (value.fbeType === this.ManagerCreateRequestModel.fbeType)) {
      return this.send_ManagerCreateRequest(value)
    }
    if ((value instanceof ManagerCreateResponse) && (value.fbeType === this.ManagerCreateResponseModel.fbeType)) {
      return this.send_ManagerCreateResponse(value)
    }
    if ((value instanceof ManagerModifyRequest) && (value.fbeType === this.ManagerModifyRequestModel.fbeType)) {
      return this.send_ManagerModifyRequest(value)
    }
    if ((value instanceof ManagerModifyResponse) && (value.fbeType === this.ManagerModifyResponseModel.fbeType)) {
      return this.send_ManagerModifyResponse(value)
    }
    if ((value instanceof ManagerDeleteRequest) && (value.fbeType === this.ManagerDeleteRequestModel.fbeType)) {
      return this.send_ManagerDeleteRequest(value)
    }
    if ((value instanceof ManagerDeleteResponse) && (value.fbeType === this.ManagerDeleteResponseModel.fbeType)) {
      return this.send_ManagerDeleteResponse(value)
    }
    if ((value instanceof ManagerUpdateNotify) && (value.fbeType === this.ManagerUpdateNotifyModel.fbeType)) {
      return this.send_ManagerUpdateNotify(value)
    }
    if ((value instanceof PlatformGetRequest) && (value.fbeType === this.PlatformGetRequestModel.fbeType)) {
      return this.send_PlatformGetRequest(value)
    }
    if ((value instanceof PlatformGetResponse) && (value.fbeType === this.PlatformGetResponseModel.fbeType)) {
      return this.send_PlatformGetResponse(value)
    }
    if ((value instanceof PlatformsGetAllRequest) && (value.fbeType === this.PlatformsGetAllRequestModel.fbeType)) {
      return this.send_PlatformsGetAllRequest(value)
    }
    if ((value instanceof PlatformsGetAllResponse) && (value.fbeType === this.PlatformsGetAllResponseModel.fbeType)) {
      return this.send_PlatformsGetAllResponse(value)
    }
    if ((value instanceof PlatformCreateRequest) && (value.fbeType === this.PlatformCreateRequestModel.fbeType)) {
      return this.send_PlatformCreateRequest(value)
    }
    if ((value instanceof PlatformCreateResponse) && (value.fbeType === this.PlatformCreateResponseModel.fbeType)) {
      return this.send_PlatformCreateResponse(value)
    }
    if ((value instanceof PlatformModifyRequest) && (value.fbeType === this.PlatformModifyRequestModel.fbeType)) {
      return this.send_PlatformModifyRequest(value)
    }
    if ((value instanceof PlatformModifyResponse) && (value.fbeType === this.PlatformModifyResponseModel.fbeType)) {
      return this.send_PlatformModifyResponse(value)
    }
    if ((value instanceof PlatformDeleteRequest) && (value.fbeType === this.PlatformDeleteRequestModel.fbeType)) {
      return this.send_PlatformDeleteRequest(value)
    }
    if ((value instanceof PlatformDeleteResponse) && (value.fbeType === this.PlatformDeleteResponseModel.fbeType)) {
      return this.send_PlatformDeleteResponse(value)
    }
    if ((value instanceof PlatformUpdateNotify) && (value.fbeType === this.PlatformUpdateNotifyModel.fbeType)) {
      return this.send_PlatformUpdateNotify(value)
    }
    if ((value instanceof AccountGroupGetRequest) && (value.fbeType === this.AccountGroupGetRequestModel.fbeType)) {
      return this.send_AccountGroupGetRequest(value)
    }
    if ((value instanceof AccountGroupGetResponse) && (value.fbeType === this.AccountGroupGetResponseModel.fbeType)) {
      return this.send_AccountGroupGetResponse(value)
    }
    if ((value instanceof AccountGroupsGetAllRequest) && (value.fbeType === this.AccountGroupsGetAllRequestModel.fbeType)) {
      return this.send_AccountGroupsGetAllRequest(value)
    }
    if ((value instanceof AccountGroupsGetAllResponse) && (value.fbeType === this.AccountGroupsGetAllResponseModel.fbeType)) {
      return this.send_AccountGroupsGetAllResponse(value)
    }
    if ((value instanceof AccountGroupCreateRequest) && (value.fbeType === this.AccountGroupCreateRequestModel.fbeType)) {
      return this.send_AccountGroupCreateRequest(value)
    }
    if ((value instanceof AccountGroupCreateResponse) && (value.fbeType === this.AccountGroupCreateResponseModel.fbeType)) {
      return this.send_AccountGroupCreateResponse(value)
    }
    if ((value instanceof AccountGroupModifyRequest) && (value.fbeType === this.AccountGroupModifyRequestModel.fbeType)) {
      return this.send_AccountGroupModifyRequest(value)
    }
    if ((value instanceof AccountGroupModifyResponse) && (value.fbeType === this.AccountGroupModifyResponseModel.fbeType)) {
      return this.send_AccountGroupModifyResponse(value)
    }
    if ((value instanceof AccountGroupDeleteRequest) && (value.fbeType === this.AccountGroupDeleteRequestModel.fbeType)) {
      return this.send_AccountGroupDeleteRequest(value)
    }
    if ((value instanceof AccountGroupDeleteResponse) && (value.fbeType === this.AccountGroupDeleteResponseModel.fbeType)) {
      return this.send_AccountGroupDeleteResponse(value)
    }
    if ((value instanceof AccountGroupUpdateNotify) && (value.fbeType === this.AccountGroupUpdateNotifyModel.fbeType)) {
      return this.send_AccountGroupUpdateNotify(value)
    }
    if ((value instanceof AccountGetRequest) && (value.fbeType === this.AccountGetRequestModel.fbeType)) {
      return this.send_AccountGetRequest(value)
    }
    if ((value instanceof AccountGetResponse) && (value.fbeType === this.AccountGetResponseModel.fbeType)) {
      return this.send_AccountGetResponse(value)
    }
    if ((value instanceof AccountsGetAllRequest) && (value.fbeType === this.AccountsGetAllRequestModel.fbeType)) {
      return this.send_AccountsGetAllRequest(value)
    }
    if ((value instanceof AccountsGetAllResponse) && (value.fbeType === this.AccountsGetAllResponseModel.fbeType)) {
      return this.send_AccountsGetAllResponse(value)
    }
    if ((value instanceof AccountCreateRequest) && (value.fbeType === this.AccountCreateRequestModel.fbeType)) {
      return this.send_AccountCreateRequest(value)
    }
    if ((value instanceof AccountCreateResponse) && (value.fbeType === this.AccountCreateResponseModel.fbeType)) {
      return this.send_AccountCreateResponse(value)
    }
    if ((value instanceof AccountSocialTradingMasterCreateRequest) && (value.fbeType === this.AccountSocialTradingMasterCreateRequestModel.fbeType)) {
      return this.send_AccountSocialTradingMasterCreateRequest(value)
    }
    if ((value instanceof AccountSocialTradingMasterCreateResponse) && (value.fbeType === this.AccountSocialTradingMasterCreateResponseModel.fbeType)) {
      return this.send_AccountSocialTradingMasterCreateResponse(value)
    }
    if ((value instanceof AccountSocialTradingInvestorCreateRequest) && (value.fbeType === this.AccountSocialTradingInvestorCreateRequestModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorCreateRequest(value)
    }
    if ((value instanceof AccountSocialTradingInvestorCreateResponse) && (value.fbeType === this.AccountSocialTradingInvestorCreateResponseModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorCreateResponse(value)
    }
    if ((value instanceof AccountModifyRequest) && (value.fbeType === this.AccountModifyRequestModel.fbeType)) {
      return this.send_AccountModifyRequest(value)
    }
    if ((value instanceof AccountModifyResponse) && (value.fbeType === this.AccountModifyResponseModel.fbeType)) {
      return this.send_AccountModifyResponse(value)
    }
    if ((value instanceof AccountDeleteRequest) && (value.fbeType === this.AccountDeleteRequestModel.fbeType)) {
      return this.send_AccountDeleteRequest(value)
    }
    if ((value instanceof AccountDeleteResponse) && (value.fbeType === this.AccountDeleteResponseModel.fbeType)) {
      return this.send_AccountDeleteResponse(value)
    }
    if ((value instanceof DemoAccountsDeleteRequest) && (value.fbeType === this.DemoAccountsDeleteRequestModel.fbeType)) {
      return this.send_DemoAccountsDeleteRequest(value)
    }
    if ((value instanceof DemoAccountsDeleteResponse) && (value.fbeType === this.DemoAccountsDeleteResponseModel.fbeType)) {
      return this.send_DemoAccountsDeleteResponse(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingMasterRequest) && (value.fbeType === this.AccountUpgradeToSocialTradingMasterRequestModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingMasterRequest(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingMasterResponse) && (value.fbeType === this.AccountUpgradeToSocialTradingMasterResponseModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingMasterResponse(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingInvestorRequest) && (value.fbeType === this.AccountUpgradeToSocialTradingInvestorRequestModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingInvestorRequest(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingInvestorResponse) && (value.fbeType === this.AccountUpgradeToSocialTradingInvestorResponseModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingInvestorResponse(value)
    }
    if ((value instanceof SocialTradingInvestorAccountToRegularRequest) && (value.fbeType === this.SocialTradingInvestorAccountToRegularRequestModel.fbeType)) {
      return this.send_SocialTradingInvestorAccountToRegularRequest(value)
    }
    if ((value instanceof SocialTradingInvestorAccountToRegularResponse) && (value.fbeType === this.SocialTradingInvestorAccountToRegularResponseModel.fbeType)) {
      return this.send_SocialTradingInvestorAccountToRegularResponse(value)
    }
    if ((value instanceof SocialTradingMasterAccountToRegularRequest) && (value.fbeType === this.SocialTradingMasterAccountToRegularRequestModel.fbeType)) {
      return this.send_SocialTradingMasterAccountToRegularRequest(value)
    }
    if ((value instanceof SocialTradingMasterAccountToRegularResponse) && (value.fbeType === this.SocialTradingMasterAccountToRegularResponseModel.fbeType)) {
      return this.send_SocialTradingMasterAccountToRegularResponse(value)
    }
    if ((value instanceof AccountUpdateNotify) && (value.fbeType === this.AccountUpdateNotifyModel.fbeType)) {
      return this.send_AccountUpdateNotify(value)
    }
    if ((value instanceof MarginGetRequest) && (value.fbeType === this.MarginGetRequestModel.fbeType)) {
      return this.send_MarginGetRequest(value)
    }
    if ((value instanceof MarginGetResponse) && (value.fbeType === this.MarginGetResponseModel.fbeType)) {
      return this.send_MarginGetResponse(value)
    }
    if ((value instanceof MarginsGetAllRequest) && (value.fbeType === this.MarginsGetAllRequestModel.fbeType)) {
      return this.send_MarginsGetAllRequest(value)
    }
    if ((value instanceof MarginsGetAllResponse) && (value.fbeType === this.MarginsGetAllResponseModel.fbeType)) {
      return this.send_MarginsGetAllResponse(value)
    }
    if ((value instanceof MarginUpdateNotify) && (value.fbeType === this.MarginUpdateNotifyModel.fbeType)) {
      return this.send_MarginUpdateNotify(value)
    }
    if ((value instanceof MarginCallNotify) && (value.fbeType === this.MarginCallNotifyModel.fbeType)) {
      return this.send_MarginCallNotify(value)
    }
    if ((value instanceof StopOutNotify) && (value.fbeType === this.StopOutNotifyModel.fbeType)) {
      return this.send_StopOutNotify(value)
    }
    if ((value instanceof AssetGetRequest) && (value.fbeType === this.AssetGetRequestModel.fbeType)) {
      return this.send_AssetGetRequest(value)
    }
    if ((value instanceof AssetGetResponse) && (value.fbeType === this.AssetGetResponseModel.fbeType)) {
      return this.send_AssetGetResponse(value)
    }
    if ((value instanceof AssetsGetRequest) && (value.fbeType === this.AssetsGetRequestModel.fbeType)) {
      return this.send_AssetsGetRequest(value)
    }
    if ((value instanceof AssetsGetResponse) && (value.fbeType === this.AssetsGetResponseModel.fbeType)) {
      return this.send_AssetsGetResponse(value)
    }
    if ((value instanceof AssetsGetAllRequest) && (value.fbeType === this.AssetsGetAllRequestModel.fbeType)) {
      return this.send_AssetsGetAllRequest(value)
    }
    if ((value instanceof AssetsGetAllResponse) && (value.fbeType === this.AssetsGetAllResponseModel.fbeType)) {
      return this.send_AssetsGetAllResponse(value)
    }
    if ((value instanceof AssetUpdateNotify) && (value.fbeType === this.AssetUpdateNotifyModel.fbeType)) {
      return this.send_AssetUpdateNotify(value)
    }
    if ((value instanceof OrderGetRequest) && (value.fbeType === this.OrderGetRequestModel.fbeType)) {
      return this.send_OrderGetRequest(value)
    }
    if ((value instanceof OrderGetResponse) && (value.fbeType === this.OrderGetResponseModel.fbeType)) {
      return this.send_OrderGetResponse(value)
    }
    if ((value instanceof OrdersGetRequest) && (value.fbeType === this.OrdersGetRequestModel.fbeType)) {
      return this.send_OrdersGetRequest(value)
    }
    if ((value instanceof OrdersGetResponse) && (value.fbeType === this.OrdersGetResponseModel.fbeType)) {
      return this.send_OrdersGetResponse(value)
    }
    if ((value instanceof OrdersGetAllRequest) && (value.fbeType === this.OrdersGetAllRequestModel.fbeType)) {
      return this.send_OrdersGetAllRequest(value)
    }
    if ((value instanceof OrdersGetAllResponse) && (value.fbeType === this.OrdersGetAllResponseModel.fbeType)) {
      return this.send_OrdersGetAllResponse(value)
    }
    if ((value instanceof OrderCreateRequest) && (value.fbeType === this.OrderCreateRequestModel.fbeType)) {
      return this.send_OrderCreateRequest(value)
    }
    if ((value instanceof OrderCreateResponse) && (value.fbeType === this.OrderCreateResponseModel.fbeType)) {
      return this.send_OrderCreateResponse(value)
    }
    if ((value instanceof OrderModifyRequest) && (value.fbeType === this.OrderModifyRequestModel.fbeType)) {
      return this.send_OrderModifyRequest(value)
    }
    if ((value instanceof OrderModifyResponse) && (value.fbeType === this.OrderModifyResponseModel.fbeType)) {
      return this.send_OrderModifyResponse(value)
    }
    if ((value instanceof OrderActivateRequest) && (value.fbeType === this.OrderActivateRequestModel.fbeType)) {
      return this.send_OrderActivateRequest(value)
    }
    if ((value instanceof OrderActivateResponse) && (value.fbeType === this.OrderActivateResponseModel.fbeType)) {
      return this.send_OrderActivateResponse(value)
    }
    if ((value instanceof OrderCancelRequest) && (value.fbeType === this.OrderCancelRequestModel.fbeType)) {
      return this.send_OrderCancelRequest(value)
    }
    if ((value instanceof OrderCancelResponse) && (value.fbeType === this.OrderCancelResponseModel.fbeType)) {
      return this.send_OrderCancelResponse(value)
    }
    if ((value instanceof OrderCancelAllRequest) && (value.fbeType === this.OrderCancelAllRequestModel.fbeType)) {
      return this.send_OrderCancelAllRequest(value)
    }
    if ((value instanceof OrderCancelAllResponse) && (value.fbeType === this.OrderCancelAllResponseModel.fbeType)) {
      return this.send_OrderCancelAllResponse(value)
    }
    if ((value instanceof OrderCancelAllByIdRequest) && (value.fbeType === this.OrderCancelAllByIdRequestModel.fbeType)) {
      return this.send_OrderCancelAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseRequest) && (value.fbeType === this.OrderCloseRequestModel.fbeType)) {
      return this.send_OrderCloseRequest(value)
    }
    if ((value instanceof OrderCloseResponse) && (value.fbeType === this.OrderCloseResponseModel.fbeType)) {
      return this.send_OrderCloseResponse(value)
    }
    if ((value instanceof OrderCloseAllRequest) && (value.fbeType === this.OrderCloseAllRequestModel.fbeType)) {
      return this.send_OrderCloseAllRequest(value)
    }
    if ((value instanceof OrderCloseAllResponse) && (value.fbeType === this.OrderCloseAllResponseModel.fbeType)) {
      return this.send_OrderCloseAllResponse(value)
    }
    if ((value instanceof OrderCloseAllByIdRequest) && (value.fbeType === this.OrderCloseAllByIdRequestModel.fbeType)) {
      return this.send_OrderCloseAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseByRequest) && (value.fbeType === this.OrderCloseByRequestModel.fbeType)) {
      return this.send_OrderCloseByRequest(value)
    }
    if ((value instanceof OrderCloseByResponse) && (value.fbeType === this.OrderCloseByResponseModel.fbeType)) {
      return this.send_OrderCloseByResponse(value)
    }
    if ((value instanceof OrderCloseByAllRequest) && (value.fbeType === this.OrderCloseByAllRequestModel.fbeType)) {
      return this.send_OrderCloseByAllRequest(value)
    }
    if ((value instanceof OrderCloseByAllResponse) && (value.fbeType === this.OrderCloseByAllResponseModel.fbeType)) {
      return this.send_OrderCloseByAllResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseRequest) && (value.fbeType === this.OrderCancelOrCloseRequestModel.fbeType)) {
      return this.send_OrderCancelOrCloseRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseResponse) && (value.fbeType === this.OrderCancelOrCloseResponseModel.fbeType)) {
      return this.send_OrderCancelOrCloseResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseAllRequest) && (value.fbeType === this.OrderCancelOrCloseAllRequestModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseAllResponse) && (value.fbeType === this.OrderCancelOrCloseAllResponseModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllResponse(value)
    }
    if ((value instanceof OrderUpdateNotify) && (value.fbeType === this.OrderUpdateNotifyModel.fbeType)) {
      return this.send_OrderUpdateNotify(value)
    }
    if ((value instanceof PositionGetRequest) && (value.fbeType === this.PositionGetRequestModel.fbeType)) {
      return this.send_PositionGetRequest(value)
    }
    if ((value instanceof PositionGetResponse) && (value.fbeType === this.PositionGetResponseModel.fbeType)) {
      return this.send_PositionGetResponse(value)
    }
    if ((value instanceof PositionsGetRequest) && (value.fbeType === this.PositionsGetRequestModel.fbeType)) {
      return this.send_PositionsGetRequest(value)
    }
    if ((value instanceof PositionsGetResponse) && (value.fbeType === this.PositionsGetResponseModel.fbeType)) {
      return this.send_PositionsGetResponse(value)
    }
    if ((value instanceof PositionsGetAllRequest) && (value.fbeType === this.PositionsGetAllRequestModel.fbeType)) {
      return this.send_PositionsGetAllRequest(value)
    }
    if ((value instanceof PositionsGetAllResponse) && (value.fbeType === this.PositionsGetAllResponseModel.fbeType)) {
      return this.send_PositionsGetAllResponse(value)
    }
    if ((value instanceof PositionUpdateNotify) && (value.fbeType === this.PositionUpdateNotifyModel.fbeType)) {
      return this.send_PositionUpdateNotify(value)
    }
    if ((value instanceof TradeHistoryRequest) && (value.fbeType === this.TradeHistoryRequestModel.fbeType)) {
      return this.send_TradeHistoryRequest(value)
    }
    if ((value instanceof TradeHistoryResponse) && (value.fbeType === this.TradeHistoryResponseModel.fbeType)) {
      return this.send_TradeHistoryResponse(value)
    }
    if ((value instanceof TradeHistoryFilterRequest) && (value.fbeType === this.TradeHistoryFilterRequestModel.fbeType)) {
      return this.send_TradeHistoryFilterRequest(value)
    }
    if ((value instanceof TradeHistoryByIdRequest) && (value.fbeType === this.TradeHistoryByIdRequestModel.fbeType)) {
      return this.send_TradeHistoryByIdRequest(value)
    }
    if ((value instanceof TradeHistoryByIdResponse) && (value.fbeType === this.TradeHistoryByIdResponseModel.fbeType)) {
      return this.send_TradeHistoryByIdResponse(value)
    }
    if ((value instanceof TradeHistoryUpdateNotify) && (value.fbeType === this.TradeHistoryUpdateNotifyModel.fbeType)) {
      return this.send_TradeHistoryUpdateNotify(value)
    }
    if ((value instanceof TestRolloverDumpRequest) && (value.fbeType === this.TestRolloverDumpRequestModel.fbeType)) {
      return this.send_TestRolloverDumpRequest(value)
    }
    if ((value instanceof TestRolloverDumpResponse) && (value.fbeType === this.TestRolloverDumpResponseModel.fbeType)) {
      return this.send_TestRolloverDumpResponse(value)
    }
    if ((value instanceof Deprecated001) && (value.fbeType === this.Deprecated001Model.fbeType)) {
      return this.send_Deprecated001(value)
    }
    if ((value instanceof Deprecated002) && (value.fbeType === this.Deprecated002Model.fbeType)) {
      return this.send_Deprecated002(value)
    }
    if ((value instanceof Deprecated003) && (value.fbeType === this.Deprecated003Model.fbeType)) {
      return this.send_Deprecated003(value)
    }
    if ((value instanceof Deprecated004) && (value.fbeType === this.Deprecated004Model.fbeType)) {
      return this.send_Deprecated004(value)
    }
    if ((value instanceof Deprecated005) && (value.fbeType === this.Deprecated005Model.fbeType)) {
      return this.send_Deprecated005(value)
    }
    if ((value instanceof Deprecated006) && (value.fbeType === this.Deprecated006Model.fbeType)) {
      return this.send_Deprecated006(value)
    }
    if ((value instanceof Deprecated007) && (value.fbeType === this.Deprecated007Model.fbeType)) {
      return this.send_Deprecated007(value)
    }
    if ((value instanceof Deprecated008) && (value.fbeType === this.Deprecated008Model.fbeType)) {
      return this.send_Deprecated008(value)
    }
    if ((value instanceof Deprecated009) && (value.fbeType === this.Deprecated009Model.fbeType)) {
      return this.send_Deprecated009(value)
    }
    if ((value instanceof Deprecated010) && (value.fbeType === this.Deprecated010Model.fbeType)) {
      return this.send_Deprecated010(value)
    }
    if ((value instanceof Deprecated011) && (value.fbeType === this.Deprecated011Model.fbeType)) {
      return this.send_Deprecated011(value)
    }
    if ((value instanceof Deprecated012) && (value.fbeType === this.Deprecated012Model.fbeType)) {
      return this.send_Deprecated012(value)
    }
    if ((value instanceof Deprecated013) && (value.fbeType === this.Deprecated013Model.fbeType)) {
      return this.send_Deprecated013(value)
    }
    if ((value instanceof DepositAccountRequest) && (value.fbeType === this.DepositAccountRequestModel.fbeType)) {
      return this.send_DepositAccountRequest(value)
    }
    if ((value instanceof DepositAccountResponse) && (value.fbeType === this.DepositAccountResponseModel.fbeType)) {
      return this.send_DepositAccountResponse(value)
    }
    if ((value instanceof CreditAccountRequest) && (value.fbeType === this.CreditAccountRequestModel.fbeType)) {
      return this.send_CreditAccountRequest(value)
    }
    if ((value instanceof CreditAccountResponse) && (value.fbeType === this.CreditAccountResponseModel.fbeType)) {
      return this.send_CreditAccountResponse(value)
    }
    if ((value instanceof TransferMoneyRequest) && (value.fbeType === this.TransferMoneyRequestModel.fbeType)) {
      return this.send_TransferMoneyRequest(value)
    }
    if ((value instanceof TransferMoneyResponse) && (value.fbeType === this.TransferMoneyResponseModel.fbeType)) {
      return this.send_TransferMoneyResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleGetRequest) && (value.fbeType === this.TradingCalendarRegularRuleGetRequestModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleGetRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleGetResponse) && (value.fbeType === this.TradingCalendarRegularRuleGetResponseModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleGetResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRulesGetAllRequest) && (value.fbeType === this.TradingCalendarRegularRulesGetAllRequestModel.fbeType)) {
      return this.send_TradingCalendarRegularRulesGetAllRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRulesGetAllResponse) && (value.fbeType === this.TradingCalendarRegularRulesGetAllResponseModel.fbeType)) {
      return this.send_TradingCalendarRegularRulesGetAllResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleCreateRequest) && (value.fbeType === this.TradingCalendarRegularRuleCreateRequestModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleCreateRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleCreateResponse) && (value.fbeType === this.TradingCalendarRegularRuleCreateResponseModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleCreateResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleModifyRequest) && (value.fbeType === this.TradingCalendarRegularRuleModifyRequestModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleModifyRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleModifyResponse) && (value.fbeType === this.TradingCalendarRegularRuleModifyResponseModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleModifyResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleDeleteRequest) && (value.fbeType === this.TradingCalendarRegularRuleDeleteRequestModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleDeleteRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleDeleteResponse) && (value.fbeType === this.TradingCalendarRegularRuleDeleteResponseModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleDeleteResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleUpdateNotify) && (value.fbeType === this.TradingCalendarRegularRuleUpdateNotifyModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleUpdateNotify(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleGetRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleGetRequestModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleGetRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleGetResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleGetResponseModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleGetResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRulesGetAllRequest) && (value.fbeType === this.TradingCalendarOffTimeRulesGetAllRequestModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRulesGetAllRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRulesGetAllResponse) && (value.fbeType === this.TradingCalendarOffTimeRulesGetAllResponseModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRulesGetAllResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleCreateRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleCreateRequestModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleCreateRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleCreateResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleCreateResponseModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleCreateResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleModifyRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleModifyRequestModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleModifyRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleModifyResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleModifyResponseModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleModifyResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleDeleteRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleDeleteRequestModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleDeleteRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleDeleteResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleDeleteResponseModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleDeleteResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleUpdateNotify) && (value.fbeType === this.TradingCalendarOffTimeRuleUpdateNotifyModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleUpdateNotify(value)
    }
    if ((value instanceof TradingCalendarRulesGetRequest) && (value.fbeType === this.TradingCalendarRulesGetRequestModel.fbeType)) {
      return this.send_TradingCalendarRulesGetRequest(value)
    }
    if ((value instanceof TradingCalendarRulesGetResponse) && (value.fbeType === this.TradingCalendarRulesGetResponseModel.fbeType)) {
      return this.send_TradingCalendarRulesGetResponse(value)
    }
    if ((value instanceof TradingRouteRulesGetRequest) && (value.fbeType === this.TradingRouteRulesGetRequestModel.fbeType)) {
      return this.send_TradingRouteRulesGetRequest(value)
    }
    if ((value instanceof TradingRouteRulesGetResponse) && (value.fbeType === this.TradingRouteRulesGetResponseModel.fbeType)) {
      return this.send_TradingRouteRulesGetResponse(value)
    }
    if ((value instanceof TradingRouteRuleCreateRequest) && (value.fbeType === this.TradingRouteRuleCreateRequestModel.fbeType)) {
      return this.send_TradingRouteRuleCreateRequest(value)
    }
    if ((value instanceof TradingRouteRuleCreateResponse) && (value.fbeType === this.TradingRouteRuleCreateResponseModel.fbeType)) {
      return this.send_TradingRouteRuleCreateResponse(value)
    }
    if ((value instanceof TradingRouteRuleModifyRequest) && (value.fbeType === this.TradingRouteRuleModifyRequestModel.fbeType)) {
      return this.send_TradingRouteRuleModifyRequest(value)
    }
    if ((value instanceof TradingRouteRuleModifyResponse) && (value.fbeType === this.TradingRouteRuleModifyResponseModel.fbeType)) {
      return this.send_TradingRouteRuleModifyResponse(value)
    }
    if ((value instanceof TradingRouteRuleDeleteRequest) && (value.fbeType === this.TradingRouteRuleDeleteRequestModel.fbeType)) {
      return this.send_TradingRouteRuleDeleteRequest(value)
    }
    if ((value instanceof TradingRouteRuleDeleteResponse) && (value.fbeType === this.TradingRouteRuleDeleteResponseModel.fbeType)) {
      return this.send_TradingRouteRuleDeleteResponse(value)
    }
    if ((value instanceof TradingRouteRuleUpdateNotify) && (value.fbeType === this.TradingRouteRuleUpdateNotifyModel.fbeType)) {
      return this.send_TradingRouteRuleUpdateNotify(value)
    }
    if ((value instanceof SymbolMapsGetRequest) && (value.fbeType === this.SymbolMapsGetRequestModel.fbeType)) {
      return this.send_SymbolMapsGetRequest(value)
    }
    if ((value instanceof SymbolMapsGetResponse) && (value.fbeType === this.SymbolMapsGetResponseModel.fbeType)) {
      return this.send_SymbolMapsGetResponse(value)
    }
    if ((value instanceof SymbolMapCreateRequest) && (value.fbeType === this.SymbolMapCreateRequestModel.fbeType)) {
      return this.send_SymbolMapCreateRequest(value)
    }
    if ((value instanceof SymbolMapCreateResponse) && (value.fbeType === this.SymbolMapCreateResponseModel.fbeType)) {
      return this.send_SymbolMapCreateResponse(value)
    }
    if ((value instanceof SymbolMapModifyRequest) && (value.fbeType === this.SymbolMapModifyRequestModel.fbeType)) {
      return this.send_SymbolMapModifyRequest(value)
    }
    if ((value instanceof SymbolMapModifyResponse) && (value.fbeType === this.SymbolMapModifyResponseModel.fbeType)) {
      return this.send_SymbolMapModifyResponse(value)
    }
    if ((value instanceof SymbolMapDeleteRequest) && (value.fbeType === this.SymbolMapDeleteRequestModel.fbeType)) {
      return this.send_SymbolMapDeleteRequest(value)
    }
    if ((value instanceof SymbolMapDeleteResponse) && (value.fbeType === this.SymbolMapDeleteResponseModel.fbeType)) {
      return this.send_SymbolMapDeleteResponse(value)
    }
    if ((value instanceof SymbolMapUpdateNotify) && (value.fbeType === this.SymbolMapUpdateNotifyModel.fbeType)) {
      return this.send_SymbolMapUpdateNotify(value)
    }
    if ((value instanceof GatewaysGetRequest) && (value.fbeType === this.GatewaysGetRequestModel.fbeType)) {
      return this.send_GatewaysGetRequest(value)
    }
    if ((value instanceof GatewaysGetResponse) && (value.fbeType === this.GatewaysGetResponseModel.fbeType)) {
      return this.send_GatewaysGetResponse(value)
    }
    if ((value instanceof GatewayCreateRequest) && (value.fbeType === this.GatewayCreateRequestModel.fbeType)) {
      return this.send_GatewayCreateRequest(value)
    }
    if ((value instanceof GatewayCreateResponse) && (value.fbeType === this.GatewayCreateResponseModel.fbeType)) {
      return this.send_GatewayCreateResponse(value)
    }
    if ((value instanceof GatewayModifyRequest) && (value.fbeType === this.GatewayModifyRequestModel.fbeType)) {
      return this.send_GatewayModifyRequest(value)
    }
    if ((value instanceof GatewayModifyResponse) && (value.fbeType === this.GatewayModifyResponseModel.fbeType)) {
      return this.send_GatewayModifyResponse(value)
    }
    if ((value instanceof GatewayDeleteRequest) && (value.fbeType === this.GatewayDeleteRequestModel.fbeType)) {
      return this.send_GatewayDeleteRequest(value)
    }
    if ((value instanceof GatewayDeleteResponse) && (value.fbeType === this.GatewayDeleteResponseModel.fbeType)) {
      return this.send_GatewayDeleteResponse(value)
    }
    if ((value instanceof GatewayUpdateNotify) && (value.fbeType === this.GatewayUpdateNotifyModel.fbeType)) {
      return this.send_GatewayUpdateNotify(value)
    }
    if ((value instanceof DataFeedsGetRequest) && (value.fbeType === this.DataFeedsGetRequestModel.fbeType)) {
      return this.send_DataFeedsGetRequest(value)
    }
    if ((value instanceof DataFeedsGetResponse) && (value.fbeType === this.DataFeedsGetResponseModel.fbeType)) {
      return this.send_DataFeedsGetResponse(value)
    }
    if ((value instanceof DataFeedCreateRequest) && (value.fbeType === this.DataFeedCreateRequestModel.fbeType)) {
      return this.send_DataFeedCreateRequest(value)
    }
    if ((value instanceof DataFeedCreateResponse) && (value.fbeType === this.DataFeedCreateResponseModel.fbeType)) {
      return this.send_DataFeedCreateResponse(value)
    }
    if ((value instanceof DataFeedModifyRequest) && (value.fbeType === this.DataFeedModifyRequestModel.fbeType)) {
      return this.send_DataFeedModifyRequest(value)
    }
    if ((value instanceof DataFeedModifyResponse) && (value.fbeType === this.DataFeedModifyResponseModel.fbeType)) {
      return this.send_DataFeedModifyResponse(value)
    }
    if ((value instanceof DataFeedDeleteRequest) && (value.fbeType === this.DataFeedDeleteRequestModel.fbeType)) {
      return this.send_DataFeedDeleteRequest(value)
    }
    if ((value instanceof DataFeedDeleteResponse) && (value.fbeType === this.DataFeedDeleteResponseModel.fbeType)) {
      return this.send_DataFeedDeleteResponse(value)
    }
    if ((value instanceof DataFeedUpdateNotify) && (value.fbeType === this.DataFeedUpdateNotifyModel.fbeType)) {
      return this.send_DataFeedUpdateNotify(value)
    }
    if ((value instanceof ModuleSystemInfoNotify) && (value.fbeType === this.ModuleSystemInfoNotifyModel.fbeType)) {
      return this.send_ModuleSystemInfoNotify(value)
    }
    if ((value instanceof ModulesSystemInfoGetAllRequest) && (value.fbeType === this.ModulesSystemInfoGetAllRequestModel.fbeType)) {
      return this.send_ModulesSystemInfoGetAllRequest(value)
    }
    if ((value instanceof ModulesSystemInfoGetAllResponse) && (value.fbeType === this.ModulesSystemInfoGetAllResponseModel.fbeType)) {
      return this.send_ModulesSystemInfoGetAllResponse(value)
    }
    if ((value instanceof ModulesSystemInfoNotify) && (value.fbeType === this.ModulesSystemInfoNotifyModel.fbeType)) {
      return this.send_ModulesSystemInfoNotify(value)
    }
    if ((value instanceof ChartBarHistoryModifyRequest) && (value.fbeType === this.ChartBarHistoryModifyRequestModel.fbeType)) {
      return this.send_ChartBarHistoryModifyRequest(value)
    }
    if ((value instanceof ChartBarHistoryModifyResponse) && (value.fbeType === this.ChartBarHistoryModifyResponseModel.fbeType)) {
      return this.send_ChartBarHistoryModifyResponse(value)
    }
    if ((value instanceof FeedUpdateTickSnapshotRequest) && (value.fbeType === this.FeedUpdateTickSnapshotRequestModel.fbeType)) {
      return this.send_FeedUpdateTickSnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateTickSnapshotResponse) && (value.fbeType === this.FeedUpdateTickSnapshotResponseModel.fbeType)) {
      return this.send_FeedUpdateTickSnapshotResponse(value)
    }
    if ((value instanceof FeedUpdateTickRequest) && (value.fbeType === this.FeedUpdateTickRequestModel.fbeType)) {
      return this.send_FeedUpdateTickRequest(value)
    }
    if ((value instanceof FeedUpdateTickResponse) && (value.fbeType === this.FeedUpdateTickResponseModel.fbeType)) {
      return this.send_FeedUpdateTickResponse(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotRequest) && (value.fbeType === this.FeedUpdateLevel2SnapshotRequestModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotResponse) && (value.fbeType === this.FeedUpdateLevel2SnapshotResponseModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotResponse(value)
    }
    if ((value instanceof FeedUpdateLevel2Request) && (value.fbeType === this.FeedUpdateLevel2RequestModel.fbeType)) {
      return this.send_FeedUpdateLevel2Request(value)
    }
    if ((value instanceof FeedUpdateLevel2Response) && (value.fbeType === this.FeedUpdateLevel2ResponseModel.fbeType)) {
      return this.send_FeedUpdateLevel2Response(value)
    }
    if ((value instanceof MailServerGetRequest) && (value.fbeType === this.MailServerGetRequestModel.fbeType)) {
      return this.send_MailServerGetRequest(value)
    }
    if ((value instanceof MailServerGetResponse) && (value.fbeType === this.MailServerGetResponseModel.fbeType)) {
      return this.send_MailServerGetResponse(value)
    }
    if ((value instanceof MailServersGetAllRequest) && (value.fbeType === this.MailServersGetAllRequestModel.fbeType)) {
      return this.send_MailServersGetAllRequest(value)
    }
    if ((value instanceof MailServersGetAllResponse) && (value.fbeType === this.MailServersGetAllResponseModel.fbeType)) {
      return this.send_MailServersGetAllResponse(value)
    }
    if ((value instanceof MailServerCreateRequest) && (value.fbeType === this.MailServerCreateRequestModel.fbeType)) {
      return this.send_MailServerCreateRequest(value)
    }
    if ((value instanceof MailServerCreateResponse) && (value.fbeType === this.MailServerCreateResponseModel.fbeType)) {
      return this.send_MailServerCreateResponse(value)
    }
    if ((value instanceof MailServerModifyRequest) && (value.fbeType === this.MailServerModifyRequestModel.fbeType)) {
      return this.send_MailServerModifyRequest(value)
    }
    if ((value instanceof MailServerModifyResponse) && (value.fbeType === this.MailServerModifyResponseModel.fbeType)) {
      return this.send_MailServerModifyResponse(value)
    }
    if ((value instanceof MailServerDeleteRequest) && (value.fbeType === this.MailServerDeleteRequestModel.fbeType)) {
      return this.send_MailServerDeleteRequest(value)
    }
    if ((value instanceof MailServerDeleteResponse) && (value.fbeType === this.MailServerDeleteResponseModel.fbeType)) {
      return this.send_MailServerDeleteResponse(value)
    }
    if ((value instanceof MailServerUpdateNotify) && (value.fbeType === this.MailServerUpdateNotifyModel.fbeType)) {
      return this.send_MailServerUpdateNotify(value)
    }
    if ((value instanceof AccountStatesUpdateNotify) && (value.fbeType === this.AccountStatesUpdateNotifyModel.fbeType)) {
      return this.send_AccountStatesUpdateNotify(value)
    }
    if ((value instanceof AccountStatesGetAllRequest) && (value.fbeType === this.AccountStatesGetAllRequestModel.fbeType)) {
      return this.send_AccountStatesGetAllRequest(value)
    }
    if ((value instanceof AccountStatesGetAllResponse) && (value.fbeType === this.AccountStatesGetAllResponseModel.fbeType)) {
      return this.send_AccountStatesGetAllResponse(value)
    }
    if ((value instanceof ModuleAuthRequest) && (value.fbeType === this.ModuleAuthRequestModel.fbeType)) {
      return this.send_ModuleAuthRequest(value)
    }
    if ((value instanceof ModuleAuthResponse) && (value.fbeType === this.ModuleAuthResponseModel.fbeType)) {
      return this.send_ModuleAuthResponse(value)
    }
    if ((value instanceof LeverageProfileGetRequest) && (value.fbeType === this.LeverageProfileGetRequestModel.fbeType)) {
      return this.send_LeverageProfileGetRequest(value)
    }
    if ((value instanceof LeverageProfileGetResponse) && (value.fbeType === this.LeverageProfileGetResponseModel.fbeType)) {
      return this.send_LeverageProfileGetResponse(value)
    }
    if ((value instanceof LeverageProfilesGetAllRequest) && (value.fbeType === this.LeverageProfilesGetAllRequestModel.fbeType)) {
      return this.send_LeverageProfilesGetAllRequest(value)
    }
    if ((value instanceof LeverageProfilesGetAllResponse) && (value.fbeType === this.LeverageProfilesGetAllResponseModel.fbeType)) {
      return this.send_LeverageProfilesGetAllResponse(value)
    }
    if ((value instanceof LeverageProfileCreateRequest) && (value.fbeType === this.LeverageProfileCreateRequestModel.fbeType)) {
      return this.send_LeverageProfileCreateRequest(value)
    }
    if ((value instanceof LeverageProfileCreateResponse) && (value.fbeType === this.LeverageProfileCreateResponseModel.fbeType)) {
      return this.send_LeverageProfileCreateResponse(value)
    }
    if ((value instanceof LeverageProfileModifyRequest) && (value.fbeType === this.LeverageProfileModifyRequestModel.fbeType)) {
      return this.send_LeverageProfileModifyRequest(value)
    }
    if ((value instanceof LeverageProfileModifyResponse) && (value.fbeType === this.LeverageProfileModifyResponseModel.fbeType)) {
      return this.send_LeverageProfileModifyResponse(value)
    }
    if ((value instanceof LeverageProfileDeleteRequest) && (value.fbeType === this.LeverageProfileDeleteRequestModel.fbeType)) {
      return this.send_LeverageProfileDeleteRequest(value)
    }
    if ((value instanceof LeverageProfileDeleteResponse) && (value.fbeType === this.LeverageProfileDeleteResponseModel.fbeType)) {
      return this.send_LeverageProfileDeleteResponse(value)
    }
    if ((value instanceof LeverageProfileUpdateNotify) && (value.fbeType === this.LeverageProfileUpdateNotifyModel.fbeType)) {
      return this.send_LeverageProfileUpdateNotify(value)
    }
    if ((value instanceof MarginRateProfileGetRequest) && (value.fbeType === this.MarginRateProfileGetRequestModel.fbeType)) {
      return this.send_MarginRateProfileGetRequest(value)
    }
    if ((value instanceof MarginRateProfileGetResponse) && (value.fbeType === this.MarginRateProfileGetResponseModel.fbeType)) {
      return this.send_MarginRateProfileGetResponse(value)
    }
    if ((value instanceof MarginRateProfilesGetAllRequest) && (value.fbeType === this.MarginRateProfilesGetAllRequestModel.fbeType)) {
      return this.send_MarginRateProfilesGetAllRequest(value)
    }
    if ((value instanceof MarginRateProfilesGetAllResponse) && (value.fbeType === this.MarginRateProfilesGetAllResponseModel.fbeType)) {
      return this.send_MarginRateProfilesGetAllResponse(value)
    }
    if ((value instanceof MarginRateProfileCreateRequest) && (value.fbeType === this.MarginRateProfileCreateRequestModel.fbeType)) {
      return this.send_MarginRateProfileCreateRequest(value)
    }
    if ((value instanceof MarginRateProfileCreateResponse) && (value.fbeType === this.MarginRateProfileCreateResponseModel.fbeType)) {
      return this.send_MarginRateProfileCreateResponse(value)
    }
    if ((value instanceof MarginRateProfileModifyRequest) && (value.fbeType === this.MarginRateProfileModifyRequestModel.fbeType)) {
      return this.send_MarginRateProfileModifyRequest(value)
    }
    if ((value instanceof MarginRateProfileModifyResponse) && (value.fbeType === this.MarginRateProfileModifyResponseModel.fbeType)) {
      return this.send_MarginRateProfileModifyResponse(value)
    }
    if ((value instanceof MarginRateProfileDeleteRequest) && (value.fbeType === this.MarginRateProfileDeleteRequestModel.fbeType)) {
      return this.send_MarginRateProfileDeleteRequest(value)
    }
    if ((value instanceof MarginRateProfileDeleteResponse) && (value.fbeType === this.MarginRateProfileDeleteResponseModel.fbeType)) {
      return this.send_MarginRateProfileDeleteResponse(value)
    }
    if ((value instanceof MarginRateProfileUpdateNotify) && (value.fbeType === this.MarginRateProfileUpdateNotifyModel.fbeType)) {
      return this.send_MarginRateProfileUpdateNotify(value)
    }
    if ((value instanceof NewsProfileGetRequest) && (value.fbeType === this.NewsProfileGetRequestModel.fbeType)) {
      return this.send_NewsProfileGetRequest(value)
    }
    if ((value instanceof NewsProfileGetResponse) && (value.fbeType === this.NewsProfileGetResponseModel.fbeType)) {
      return this.send_NewsProfileGetResponse(value)
    }
    if ((value instanceof NewsProfilesGetAllRequest) && (value.fbeType === this.NewsProfilesGetAllRequestModel.fbeType)) {
      return this.send_NewsProfilesGetAllRequest(value)
    }
    if ((value instanceof NewsProfilesGetAllResponse) && (value.fbeType === this.NewsProfilesGetAllResponseModel.fbeType)) {
      return this.send_NewsProfilesGetAllResponse(value)
    }
    if ((value instanceof NewsProfileCreateRequest) && (value.fbeType === this.NewsProfileCreateRequestModel.fbeType)) {
      return this.send_NewsProfileCreateRequest(value)
    }
    if ((value instanceof NewsProfileCreateResponse) && (value.fbeType === this.NewsProfileCreateResponseModel.fbeType)) {
      return this.send_NewsProfileCreateResponse(value)
    }
    if ((value instanceof NewsProfileModifyRequest) && (value.fbeType === this.NewsProfileModifyRequestModel.fbeType)) {
      return this.send_NewsProfileModifyRequest(value)
    }
    if ((value instanceof NewsProfileModifyResponse) && (value.fbeType === this.NewsProfileModifyResponseModel.fbeType)) {
      return this.send_NewsProfileModifyResponse(value)
    }
    if ((value instanceof NewsProfileDeleteRequest) && (value.fbeType === this.NewsProfileDeleteRequestModel.fbeType)) {
      return this.send_NewsProfileDeleteRequest(value)
    }
    if ((value instanceof NewsProfileDeleteResponse) && (value.fbeType === this.NewsProfileDeleteResponseModel.fbeType)) {
      return this.send_NewsProfileDeleteResponse(value)
    }
    if ((value instanceof NewsProfileUpdateNotify) && (value.fbeType === this.NewsProfileUpdateNotifyModel.fbeType)) {
      return this.send_NewsProfileUpdateNotify(value)
    }
    if ((value instanceof NewsProfileImportRequest) && (value.fbeType === this.NewsProfileImportRequestModel.fbeType)) {
      return this.send_NewsProfileImportRequest(value)
    }
    if ((value instanceof NewsProfileImportResponse) && (value.fbeType === this.NewsProfileImportResponseModel.fbeType)) {
      return this.send_NewsProfileImportResponse(value)
    }
    if ((value instanceof TickHistoryRequest) && (value.fbeType === this.TickHistoryRequestModel.fbeType)) {
      return this.send_TickHistoryRequest(value)
    }
    if ((value instanceof TickHistoryResponse) && (value.fbeType === this.TickHistoryResponseModel.fbeType)) {
      return this.send_TickHistoryResponse(value)
    }
    if ((value instanceof EmailTemplateGetRequest) && (value.fbeType === this.EmailTemplateGetRequestModel.fbeType)) {
      return this.send_EmailTemplateGetRequest(value)
    }
    if ((value instanceof EmailTemplateGetResponse) && (value.fbeType === this.EmailTemplateGetResponseModel.fbeType)) {
      return this.send_EmailTemplateGetResponse(value)
    }
    if ((value instanceof EmailTemplatesGetAllRequest) && (value.fbeType === this.EmailTemplatesGetAllRequestModel.fbeType)) {
      return this.send_EmailTemplatesGetAllRequest(value)
    }
    if ((value instanceof EmailTemplatesGetAllResponse) && (value.fbeType === this.EmailTemplatesGetAllResponseModel.fbeType)) {
      return this.send_EmailTemplatesGetAllResponse(value)
    }
    if ((value instanceof EmailTemplateCreateRequest) && (value.fbeType === this.EmailTemplateCreateRequestModel.fbeType)) {
      return this.send_EmailTemplateCreateRequest(value)
    }
    if ((value instanceof EmailTemplateCreateResponse) && (value.fbeType === this.EmailTemplateCreateResponseModel.fbeType)) {
      return this.send_EmailTemplateCreateResponse(value)
    }
    if ((value instanceof EmailTemplateModifyRequest) && (value.fbeType === this.EmailTemplateModifyRequestModel.fbeType)) {
      return this.send_EmailTemplateModifyRequest(value)
    }
    if ((value instanceof EmailTemplateModifyResponse) && (value.fbeType === this.EmailTemplateModifyResponseModel.fbeType)) {
      return this.send_EmailTemplateModifyResponse(value)
    }
    if ((value instanceof EmailTemplateDeleteRequest) && (value.fbeType === this.EmailTemplateDeleteRequestModel.fbeType)) {
      return this.send_EmailTemplateDeleteRequest(value)
    }
    if ((value instanceof EmailTemplateDeleteResponse) && (value.fbeType === this.EmailTemplateDeleteResponseModel.fbeType)) {
      return this.send_EmailTemplateDeleteResponse(value)
    }
    if ((value instanceof EmailTemplateUpdateNotify) && (value.fbeType === this.EmailTemplateUpdateNotifyModel.fbeType)) {
      return this.send_EmailTemplateUpdateNotify(value)
    }
    if ((value instanceof OrderStateSubscribeRequest) && (value.fbeType === this.OrderStateSubscribeRequestModel.fbeType)) {
      return this.send_OrderStateSubscribeRequest(value)
    }
    if ((value instanceof OrderStateSubscribeResponse) && (value.fbeType === this.OrderStateSubscribeResponseModel.fbeType)) {
      return this.send_OrderStateSubscribeResponse(value)
    }
    if ((value instanceof OrderStateUpdateSubscriptionRequest) && (value.fbeType === this.OrderStateUpdateSubscriptionRequestModel.fbeType)) {
      return this.send_OrderStateUpdateSubscriptionRequest(value)
    }
    if ((value instanceof OrderStateUpdateSubscriptionResponse) && (value.fbeType === this.OrderStateUpdateSubscriptionResponseModel.fbeType)) {
      return this.send_OrderStateUpdateSubscriptionResponse(value)
    }
    if ((value instanceof OrderStateUnsubscribeRequest) && (value.fbeType === this.OrderStateUnsubscribeRequestModel.fbeType)) {
      return this.send_OrderStateUnsubscribeRequest(value)
    }
    if ((value instanceof OrderStateUnsubscribeResponse) && (value.fbeType === this.OrderStateUnsubscribeResponseModel.fbeType)) {
      return this.send_OrderStateUnsubscribeResponse(value)
    }
    if ((value instanceof OrderStateNotify) && (value.fbeType === this.OrderStateNotifyModel.fbeType)) {
      return this.send_OrderStateNotify(value)
    }
    if ((value instanceof RequestMonitoringSubscribeRequest) && (value.fbeType === this.RequestMonitoringSubscribeRequestModel.fbeType)) {
      return this.send_RequestMonitoringSubscribeRequest(value)
    }
    if ((value instanceof RequestMonitoringSubscribeResponse) && (value.fbeType === this.RequestMonitoringSubscribeResponseModel.fbeType)) {
      return this.send_RequestMonitoringSubscribeResponse(value)
    }
    if ((value instanceof RequestMonitoringUnsubscribeRequest) && (value.fbeType === this.RequestMonitoringUnsubscribeRequestModel.fbeType)) {
      return this.send_RequestMonitoringUnsubscribeRequest(value)
    }
    if ((value instanceof RequestMonitoringUnsubscribeResponse) && (value.fbeType === this.RequestMonitoringUnsubscribeResponseModel.fbeType)) {
      return this.send_RequestMonitoringUnsubscribeResponse(value)
    }
    if ((value instanceof RequestMonitoringRequestUpdateNotify) && (value.fbeType === this.RequestMonitoringRequestUpdateNotifyModel.fbeType)) {
      return this.send_RequestMonitoringRequestUpdateNotify(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoSubscribeRequest) && (value.fbeType === this.FilledOrdersSummaryInfoSubscribeRequestModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoSubscribeRequest(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoSubscribeResponse) && (value.fbeType === this.FilledOrdersSummaryInfoSubscribeResponseModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoSubscribeResponse(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoNotify) && (value.fbeType === this.FilledOrdersSummaryInfoNotifyModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoNotify(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoUpdateSubscriptionRequest) && (value.fbeType === this.FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoUnsubscribeRequest) && (value.fbeType === this.FilledOrdersSummaryInfoUnsubscribeRequestModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoUnsubscribeRequest(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoUnsubscribeResponse) && (value.fbeType === this.FilledOrdersSummaryInfoUnsubscribeResponseModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoUnsubscribeResponse(value)
    }
    if ((value instanceof TradeEventsNotify) && (value.fbeType === this.TradeEventsNotifyModel.fbeType)) {
      return this.send_TradeEventsNotify(value)
    }
    if ((value instanceof TradeEventsGetRequest) && (value.fbeType === this.TradeEventsGetRequestModel.fbeType)) {
      return this.send_TradeEventsGetRequest(value)
    }
    if ((value instanceof TradeEventsGetResponse) && (value.fbeType === this.TradeEventsGetResponseModel.fbeType)) {
      return this.send_TradeEventsGetResponse(value)
    }
    if ((value instanceof JournalRequest) && (value.fbeType === this.JournalRequestModel.fbeType)) {
      return this.send_JournalRequest(value)
    }
    if ((value instanceof JournalResponse) && (value.fbeType === this.JournalResponseModel.fbeType)) {
      return this.send_JournalResponse(value)
    }
    if ((value instanceof ClientReportsListGetRequest) && (value.fbeType === this.ClientReportsListGetRequestModel.fbeType)) {
      return this.send_ClientReportsListGetRequest(value)
    }
    if ((value instanceof ClientReportsListGetResponse) && (value.fbeType === this.ClientReportsListGetResponseModel.fbeType)) {
      return this.send_ClientReportsListGetResponse(value)
    }
    if ((value instanceof ClientReportGetRequest) && (value.fbeType === this.ClientReportGetRequestModel.fbeType)) {
      return this.send_ClientReportGetRequest(value)
    }
    if ((value instanceof ClientReportGetResponse) && (value.fbeType === this.ClientReportGetResponseModel.fbeType)) {
      return this.send_ClientReportGetResponse(value)
    }
    if ((value instanceof ClientReportsSendRequest) && (value.fbeType === this.ClientReportsSendRequestModel.fbeType)) {
      return this.send_ClientReportsSendRequest(value)
    }
    if ((value instanceof ClientReportsSendResponse) && (value.fbeType === this.ClientReportsSendResponseModel.fbeType)) {
      return this.send_ClientReportsSendResponse(value)
    }
    if ((value instanceof ProfitClosedGetRequest) && (value.fbeType === this.ProfitClosedGetRequestModel.fbeType)) {
      return this.send_ProfitClosedGetRequest(value)
    }
    if ((value instanceof ProfitClosedGetResponse) && (value.fbeType === this.ProfitClosedGetResponseModel.fbeType)) {
      return this.send_ProfitClosedGetResponse(value)
    }
    if ((value instanceof ChartBarsImportRequest) && (value.fbeType === this.ChartBarsImportRequestModel.fbeType)) {
      return this.send_ChartBarsImportRequest(value)
    }
    if ((value instanceof ChartBarsImportResponse) && (value.fbeType === this.ChartBarsImportResponseModel.fbeType)) {
      return this.send_ChartBarsImportResponse(value)
    }
    if ((value instanceof ChartBarsImportNotify) && (value.fbeType === this.ChartBarsImportNotifyModel.fbeType)) {
      return this.send_ChartBarsImportNotify(value)
    }
    if ((value instanceof JournalBusinessRequest) && (value.fbeType === this.JournalBusinessRequestModel.fbeType)) {
      return this.send_JournalBusinessRequest(value)
    }
    if ((value instanceof JournalBusinessResponse) && (value.fbeType === this.JournalBusinessResponseModel.fbeType)) {
      return this.send_JournalBusinessResponse(value)
    }
    if ((value instanceof AccountsGetPageRequest) && (value.fbeType === this.AccountsGetPageRequestModel.fbeType)) {
      return this.send_AccountsGetPageRequest(value)
    }
    if ((value instanceof AccountsGetPageResponse) && (value.fbeType === this.AccountsGetPageResponseModel.fbeType)) {
      return this.send_AccountsGetPageResponse(value)
    }
    if ((value instanceof HeartbeatNotify) && (value.fbeType === this.HeartbeatNotifyModel.fbeType)) {
      return this.send_HeartbeatNotify(value)
    }
    if ((value instanceof OrdersClosedSummariesGetRequest) && (value.fbeType === this.OrdersClosedSummariesGetRequestModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetRequest(value)
    }
    if ((value instanceof OrdersClosedSummariesGetResponse) && (value.fbeType === this.OrdersClosedSummariesGetResponseModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetResponse(value)
    }
    if ((value instanceof OrdersGetPageRequest) && (value.fbeType === this.OrdersGetPageRequestModel.fbeType)) {
      return this.send_OrdersGetPageRequest(value)
    }
    if ((value instanceof OrdersGetPageResponse) && (value.fbeType === this.OrdersGetPageResponseModel.fbeType)) {
      return this.send_OrdersGetPageResponse(value)
    }
    if ((value instanceof StorageUniversalNotify) && (value.fbeType === this.StorageUniversalNotifyModel.fbeType)) {
      return this.send_StorageUniversalNotify(value)
    }
    if ((value instanceof ClientLoginInfoGetRequest) && (value.fbeType === this.ClientLoginInfoGetRequestModel.fbeType)) {
      return this.send_ClientLoginInfoGetRequest(value)
    }
    if ((value instanceof ClientLoginInfoGetResponse) && (value.fbeType === this.ClientLoginInfoGetResponseModel.fbeType)) {
      return this.send_ClientLoginInfoGetResponse(value)
    }
    if ((value instanceof AccountGroupExistsGetRequest) && (value.fbeType === this.AccountGroupExistsGetRequestModel.fbeType)) {
      return this.send_AccountGroupExistsGetRequest(value)
    }
    if ((value instanceof AccountGroupExistsGetResponse) && (value.fbeType === this.AccountGroupExistsGetResponseModel.fbeType)) {
      return this.send_AccountGroupExistsGetResponse(value)
    }
    if ((value instanceof LoginWithPasswordRequest) && (value.fbeType === this.LoginWithPasswordRequestModel.fbeType)) {
      return this.send_LoginWithPasswordRequest(value)
    }
    if ((value instanceof LoginWithPasswordResponse) && (value.fbeType === this.LoginWithPasswordResponseModel.fbeType)) {
      return this.send_LoginWithPasswordResponse(value)
    }
    if ((value instanceof LoginWithTokenRequest) && (value.fbeType === this.LoginWithTokenRequestModel.fbeType)) {
      return this.send_LoginWithTokenRequest(value)
    }
    if ((value instanceof LoginWithTokenResponse) && (value.fbeType === this.LoginWithTokenResponseModel.fbeType)) {
      return this.send_LoginWithTokenResponse(value)
    }
    if ((value instanceof RefreshTokenRequest) && (value.fbeType === this.RefreshTokenRequestModel.fbeType)) {
      return this.send_RefreshTokenRequest(value)
    }
    if ((value instanceof RefreshTokenResponse) && (value.fbeType === this.RefreshTokenResponseModel.fbeType)) {
      return this.send_RefreshTokenResponse(value)
    }
    if ((value instanceof PersonGetRequest) && (value.fbeType === this.PersonGetRequestModel.fbeType)) {
      return this.send_PersonGetRequest(value)
    }
    if ((value instanceof PersonGetResponse) && (value.fbeType === this.PersonGetResponseModel.fbeType)) {
      return this.send_PersonGetResponse(value)
    }
    if ((value instanceof PersonsGetAllRequest) && (value.fbeType === this.PersonsGetAllRequestModel.fbeType)) {
      return this.send_PersonsGetAllRequest(value)
    }
    if ((value instanceof PersonsGetAllResponse) && (value.fbeType === this.PersonsGetAllResponseModel.fbeType)) {
      return this.send_PersonsGetAllResponse(value)
    }
    if ((value instanceof PersonCreateRequest) && (value.fbeType === this.PersonCreateRequestModel.fbeType)) {
      return this.send_PersonCreateRequest(value)
    }
    if ((value instanceof PersonCreateResponse) && (value.fbeType === this.PersonCreateResponseModel.fbeType)) {
      return this.send_PersonCreateResponse(value)
    }
    if ((value instanceof PersonModifyRequest) && (value.fbeType === this.PersonModifyRequestModel.fbeType)) {
      return this.send_PersonModifyRequest(value)
    }
    if ((value instanceof PersonModifyResponse) && (value.fbeType === this.PersonModifyResponseModel.fbeType)) {
      return this.send_PersonModifyResponse(value)
    }
    if ((value instanceof PersonUpdateNotify) && (value.fbeType === this.PersonUpdateNotifyModel.fbeType)) {
      return this.send_PersonUpdateNotify(value)
    }
    if ((value instanceof SecuritySettingsGetRequest) && (value.fbeType === this.SecuritySettingsGetRequestModel.fbeType)) {
      return this.send_SecuritySettingsGetRequest(value)
    }
    if ((value instanceof SecuritySettingsGetResponse) && (value.fbeType === this.SecuritySettingsGetResponseModel.fbeType)) {
      return this.send_SecuritySettingsGetResponse(value)
    }
    if ((value instanceof SecuritySettingsGetAllRequest) && (value.fbeType === this.SecuritySettingsGetAllRequestModel.fbeType)) {
      return this.send_SecuritySettingsGetAllRequest(value)
    }
    if ((value instanceof SecuritySettingsGetAllResponse) && (value.fbeType === this.SecuritySettingsGetAllResponseModel.fbeType)) {
      return this.send_SecuritySettingsGetAllResponse(value)
    }
    if ((value instanceof SecuritySettingsCreateRequest) && (value.fbeType === this.SecuritySettingsCreateRequestModel.fbeType)) {
      return this.send_SecuritySettingsCreateRequest(value)
    }
    if ((value instanceof SecuritySettingsCreateResponse) && (value.fbeType === this.SecuritySettingsCreateResponseModel.fbeType)) {
      return this.send_SecuritySettingsCreateResponse(value)
    }
    if ((value instanceof SecuritySettingsModifyRequest) && (value.fbeType === this.SecuritySettingsModifyRequestModel.fbeType)) {
      return this.send_SecuritySettingsModifyRequest(value)
    }
    if ((value instanceof SecuritySettingsModifyResponse) && (value.fbeType === this.SecuritySettingsModifyResponseModel.fbeType)) {
      return this.send_SecuritySettingsModifyResponse(value)
    }
    if ((value instanceof SecuritySettingsDeleteRequest) && (value.fbeType === this.SecuritySettingsDeleteRequestModel.fbeType)) {
      return this.send_SecuritySettingsDeleteRequest(value)
    }
    if ((value instanceof SecuritySettingsDeleteResponse) && (value.fbeType === this.SecuritySettingsDeleteResponseModel.fbeType)) {
      return this.send_SecuritySettingsDeleteResponse(value)
    }
    if ((value instanceof SecuritySettingsUpdateNotify) && (value.fbeType === this.SecuritySettingsUpdateNotifyModel.fbeType)) {
      return this.send_SecuritySettingsUpdateNotify(value)
    }
    if ((value instanceof FirewallRuleGetRequest) && (value.fbeType === this.FirewallRuleGetRequestModel.fbeType)) {
      return this.send_FirewallRuleGetRequest(value)
    }
    if ((value instanceof FirewallRuleGetResponse) && (value.fbeType === this.FirewallRuleGetResponseModel.fbeType)) {
      return this.send_FirewallRuleGetResponse(value)
    }
    if ((value instanceof FirewallRulesGetAllRequest) && (value.fbeType === this.FirewallRulesGetAllRequestModel.fbeType)) {
      return this.send_FirewallRulesGetAllRequest(value)
    }
    if ((value instanceof FirewallRulesGetAllResponse) && (value.fbeType === this.FirewallRulesGetAllResponseModel.fbeType)) {
      return this.send_FirewallRulesGetAllResponse(value)
    }
    if ((value instanceof FirewallRuleCreateRequest) && (value.fbeType === this.FirewallRuleCreateRequestModel.fbeType)) {
      return this.send_FirewallRuleCreateRequest(value)
    }
    if ((value instanceof FirewallRuleCreateResponse) && (value.fbeType === this.FirewallRuleCreateResponseModel.fbeType)) {
      return this.send_FirewallRuleCreateResponse(value)
    }
    if ((value instanceof FirewallRuleModifyRequest) && (value.fbeType === this.FirewallRuleModifyRequestModel.fbeType)) {
      return this.send_FirewallRuleModifyRequest(value)
    }
    if ((value instanceof FirewallRuleModifyResponse) && (value.fbeType === this.FirewallRuleModifyResponseModel.fbeType)) {
      return this.send_FirewallRuleModifyResponse(value)
    }
    if ((value instanceof FirewallRuleDeleteRequest) && (value.fbeType === this.FirewallRuleDeleteRequestModel.fbeType)) {
      return this.send_FirewallRuleDeleteRequest(value)
    }
    if ((value instanceof FirewallRuleDeleteResponse) && (value.fbeType === this.FirewallRuleDeleteResponseModel.fbeType)) {
      return this.send_FirewallRuleDeleteResponse(value)
    }
    if ((value instanceof FirewallRuleUpdateNotify) && (value.fbeType === this.FirewallRuleUpdateNotifyModel.fbeType)) {
      return this.send_FirewallRuleUpdateNotify(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._configSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._historySender.send(value)
    if (result > 0) {
      return result
    }
    result = this._clientSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._feedSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._storageSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._exposedSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._executionSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._backupSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._innerSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._tradeSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send PingModuleRequest value
   * @this {!Sender}
   * @param {!PingModuleRequest} value PingModuleRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PingModuleRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PingModuleRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PingModuleRequest serialization failed!')
    console.assert(this.PingModuleRequestModel.verify(), 'manager.PingModuleRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PongModuleResponse value
   * @this {!Sender}
   * @param {!PongModuleResponse} value PongModuleResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PongModuleResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PongModuleResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PongModuleResponse serialization failed!')
    console.assert(this.PongModuleResponseModel.verify(), 'manager.PongModuleResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PingModuleReject value
   * @this {!Sender}
   * @param {!PingModuleReject} value PingModuleReject value to send
   * @returns {!number} Sent bytes
   */
  send_PingModuleReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PingModuleRejectModel.serialize(value)
    console.assert((serialized > 0), 'manager.PingModuleReject serialization failed!')
    console.assert(this.PingModuleRejectModel.verify(), 'manager.PingModuleReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated3 value
   * @this {!Sender}
   * @param {!Deprecated3} value Deprecated3 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated3 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated3Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated3 serialization failed!')
    console.assert(this.Deprecated3Model.verify(), 'manager.Deprecated3 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated4 value
   * @this {!Sender}
   * @param {!Deprecated4} value Deprecated4 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated4 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated4Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated4 serialization failed!')
    console.assert(this.Deprecated4Model.verify(), 'manager.Deprecated4 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated1 value
   * @this {!Sender}
   * @param {!Deprecated1} value Deprecated1 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated1 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated1Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated1 serialization failed!')
    console.assert(this.Deprecated1Model.verify(), 'manager.Deprecated1 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated2 value
   * @this {!Sender}
   * @param {!Deprecated2} value Deprecated2 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated2 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated2Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated2 serialization failed!')
    console.assert(this.Deprecated2Model.verify(), 'manager.Deprecated2 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerSessionInfoRequest value
   * @this {!Sender}
   * @param {!ManagerSessionInfoRequest} value ManagerSessionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerSessionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerSessionInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerSessionInfoRequest serialization failed!')
    console.assert(this.ManagerSessionInfoRequestModel.verify(), 'manager.ManagerSessionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerSessionInfoResponse value
   * @this {!Sender}
   * @param {!ManagerSessionInfoResponse} value ManagerSessionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerSessionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerSessionInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerSessionInfoResponse serialization failed!')
    console.assert(this.ManagerSessionInfoResponseModel.verify(), 'manager.ManagerSessionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OnlineSessionsRequest value
   * @this {!Sender}
   * @param {!OnlineSessionsRequest} value OnlineSessionsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OnlineSessionsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OnlineSessionsRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OnlineSessionsRequest serialization failed!')
    console.assert(this.OnlineSessionsRequestModel.verify(), 'manager.OnlineSessionsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OnlineSessionsResponse value
   * @this {!Sender}
   * @param {!OnlineSessionsResponse} value OnlineSessionsResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OnlineSessionsResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OnlineSessionsResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OnlineSessionsResponse serialization failed!')
    console.assert(this.OnlineSessionsResponseModel.verify(), 'manager.OnlineSessionsResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DropSessionRequest value
   * @this {!Sender}
   * @param {!DropSessionRequest} value DropSessionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DropSessionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DropSessionRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.DropSessionRequest serialization failed!')
    console.assert(this.DropSessionRequestModel.verify(), 'manager.DropSessionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DropSessionResponse value
   * @this {!Sender}
   * @param {!DropSessionResponse} value DropSessionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DropSessionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DropSessionResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.DropSessionResponse serialization failed!')
    console.assert(this.DropSessionResponseModel.verify(), 'manager.DropSessionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRequest value
   * @this {!Sender}
   * @param {!ConfigRequest} value ConfigRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRequest serialization failed!')
    console.assert(this.ConfigRequestModel.verify(), 'manager.ConfigRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigResponse value
   * @this {!Sender}
   * @param {!ConfigResponse} value ConfigResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigResponse serialization failed!')
    console.assert(this.ConfigResponseModel.verify(), 'manager.ConfigResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigReject value
   * @this {!Sender}
   * @param {!ConfigReject} value ConfigReject value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRejectModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigReject serialization failed!')
    console.assert(this.ConfigRejectModel.verify(), 'manager.ConfigReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateRequest value
   * @this {!Sender}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigUpdateRequest serialization failed!')
    console.assert(this.ConfigUpdateRequestModel.verify(), 'manager.ConfigUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateResponse value
   * @this {!Sender}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigUpdateResponse serialization failed!')
    console.assert(this.ConfigUpdateResponseModel.verify(), 'manager.ConfigUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateNotify value
   * @this {!Sender}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigUpdateNotify serialization failed!')
    console.assert(this.ConfigUpdateNotifyModel.verify(), 'manager.ConfigUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionRequest value
   * @this {!Sender}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigVersionRequest serialization failed!')
    console.assert(this.ConfigVersionRequestModel.verify(), 'manager.ConfigVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionResponse value
   * @this {!Sender}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigVersionResponse serialization failed!')
    console.assert(this.ConfigVersionResponseModel.verify(), 'manager.ConfigVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigExportRequest value
   * @this {!Sender}
   * @param {!ConfigExportRequest} value ConfigExportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigExportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigExportRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigExportRequest serialization failed!')
    console.assert(this.ConfigExportRequestModel.verify(), 'manager.ConfigExportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigExportResponse value
   * @this {!Sender}
   * @param {!ConfigExportResponse} value ConfigExportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigExportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigExportResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigExportResponse serialization failed!')
    console.assert(this.ConfigExportResponseModel.verify(), 'manager.ConfigExportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigImportRequest value
   * @this {!Sender}
   * @param {!ConfigImportRequest} value ConfigImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigImportRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigImportRequest serialization failed!')
    console.assert(this.ConfigImportRequestModel.verify(), 'manager.ConfigImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigImportResponse value
   * @this {!Sender}
   * @param {!ConfigImportResponse} value ConfigImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigImportResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigImportResponse serialization failed!')
    console.assert(this.ConfigImportResponseModel.verify(), 'manager.ConfigImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigValidateRequest value
   * @this {!Sender}
   * @param {!ConfigValidateRequest} value ConfigValidateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigValidateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigValidateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigValidateRequest serialization failed!')
    console.assert(this.ConfigValidateRequestModel.verify(), 'manager.ConfigValidateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigValidateResponse value
   * @this {!Sender}
   * @param {!ConfigValidateResponse} value ConfigValidateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigValidateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigValidateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigValidateResponse serialization failed!')
    console.assert(this.ConfigValidateResponseModel.verify(), 'manager.ConfigValidateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshRequest value
   * @this {!Sender}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRefreshRequest serialization failed!')
    console.assert(this.ConfigRefreshRequestModel.verify(), 'manager.ConfigRefreshRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshResponse value
   * @this {!Sender}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRefreshResponse serialization failed!')
    console.assert(this.ConfigRefreshResponseModel.verify(), 'manager.ConfigRefreshResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryRequest value
   * @this {!Sender}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigHistoryRequest serialization failed!')
    console.assert(this.ConfigHistoryRequestModel.verify(), 'manager.ConfigHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryResponse value
   * @this {!Sender}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigHistoryResponse serialization failed!')
    console.assert(this.ConfigHistoryResponseModel.verify(), 'manager.ConfigHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackRequest value
   * @this {!Sender}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRollbackRequest serialization failed!')
    console.assert(this.ConfigRollbackRequestModel.verify(), 'manager.ConfigRollbackRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackResponse value
   * @this {!Sender}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRollbackResponse serialization failed!')
    console.assert(this.ConfigRollbackResponseModel.verify(), 'manager.ConfigRollbackResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostGetRequest value
   * @this {!Sender}
   * @param {!HostGetRequest} value HostGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostGetRequest serialization failed!')
    console.assert(this.HostGetRequestModel.verify(), 'manager.HostGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostGetResponse value
   * @this {!Sender}
   * @param {!HostGetResponse} value HostGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostGetResponse serialization failed!')
    console.assert(this.HostGetResponseModel.verify(), 'manager.HostGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostsGetAllRequest value
   * @this {!Sender}
   * @param {!HostsGetAllRequest} value HostsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostsGetAllRequest serialization failed!')
    console.assert(this.HostsGetAllRequestModel.verify(), 'manager.HostsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostsGetAllResponse value
   * @this {!Sender}
   * @param {!HostsGetAllResponse} value HostsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostsGetAllResponse serialization failed!')
    console.assert(this.HostsGetAllResponseModel.verify(), 'manager.HostsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostCreateRequest value
   * @this {!Sender}
   * @param {!HostCreateRequest} value HostCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostCreateRequest serialization failed!')
    console.assert(this.HostCreateRequestModel.verify(), 'manager.HostCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostCreateResponse value
   * @this {!Sender}
   * @param {!HostCreateResponse} value HostCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostCreateResponse serialization failed!')
    console.assert(this.HostCreateResponseModel.verify(), 'manager.HostCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostModifyRequest value
   * @this {!Sender}
   * @param {!HostModifyRequest} value HostModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostModifyRequest serialization failed!')
    console.assert(this.HostModifyRequestModel.verify(), 'manager.HostModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostModifyResponse value
   * @this {!Sender}
   * @param {!HostModifyResponse} value HostModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostModifyResponse serialization failed!')
    console.assert(this.HostModifyResponseModel.verify(), 'manager.HostModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostDeleteRequest value
   * @this {!Sender}
   * @param {!HostDeleteRequest} value HostDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostDeleteRequest serialization failed!')
    console.assert(this.HostDeleteRequestModel.verify(), 'manager.HostDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostDeleteResponse value
   * @this {!Sender}
   * @param {!HostDeleteResponse} value HostDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostDeleteResponse serialization failed!')
    console.assert(this.HostDeleteResponseModel.verify(), 'manager.HostDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostUpdateNotify value
   * @this {!Sender}
   * @param {!HostUpdateNotify} value HostUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_HostUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostUpdateNotify serialization failed!')
    console.assert(this.HostUpdateNotifyModel.verify(), 'manager.HostUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleGetRequest value
   * @this {!Sender}
   * @param {!ModuleGetRequest} value ModuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleGetRequest serialization failed!')
    console.assert(this.ModuleGetRequestModel.verify(), 'manager.ModuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleGetResponse value
   * @this {!Sender}
   * @param {!ModuleGetResponse} value ModuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleGetResponse serialization failed!')
    console.assert(this.ModuleGetResponseModel.verify(), 'manager.ModuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesGetAllRequest value
   * @this {!Sender}
   * @param {!ModulesGetAllRequest} value ModulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesGetAllRequest serialization failed!')
    console.assert(this.ModulesGetAllRequestModel.verify(), 'manager.ModulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesGetAllResponse value
   * @this {!Sender}
   * @param {!ModulesGetAllResponse} value ModulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesGetAllResponse serialization failed!')
    console.assert(this.ModulesGetAllResponseModel.verify(), 'manager.ModulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleCreateRequest value
   * @this {!Sender}
   * @param {!ModuleCreateRequest} value ModuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleCreateRequest serialization failed!')
    console.assert(this.ModuleCreateRequestModel.verify(), 'manager.ModuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleCreateResponse value
   * @this {!Sender}
   * @param {!ModuleCreateResponse} value ModuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleCreateResponse serialization failed!')
    console.assert(this.ModuleCreateResponseModel.verify(), 'manager.ModuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleModifyRequest value
   * @this {!Sender}
   * @param {!ModuleModifyRequest} value ModuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleModifyRequest serialization failed!')
    console.assert(this.ModuleModifyRequestModel.verify(), 'manager.ModuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleModifyResponse value
   * @this {!Sender}
   * @param {!ModuleModifyResponse} value ModuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleModifyResponse serialization failed!')
    console.assert(this.ModuleModifyResponseModel.verify(), 'manager.ModuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleDeleteRequest value
   * @this {!Sender}
   * @param {!ModuleDeleteRequest} value ModuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleDeleteRequest serialization failed!')
    console.assert(this.ModuleDeleteRequestModel.verify(), 'manager.ModuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleDeleteResponse value
   * @this {!Sender}
   * @param {!ModuleDeleteResponse} value ModuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleDeleteResponse serialization failed!')
    console.assert(this.ModuleDeleteResponseModel.verify(), 'manager.ModuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleUpdateNotify value
   * @this {!Sender}
   * @param {!ModuleUpdateNotify} value ModuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleUpdateNotify serialization failed!')
    console.assert(this.ModuleUpdateNotifyModel.verify(), 'manager.ModuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerTimeGetRequest value
   * @this {!Sender}
   * @param {!ServerTimeGetRequest} value ServerTimeGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ServerTimeGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerTimeGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ServerTimeGetRequest serialization failed!')
    console.assert(this.ServerTimeGetRequestModel.verify(), 'manager.ServerTimeGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerTimeGetResponse value
   * @this {!Sender}
   * @param {!ServerTimeGetResponse} value ServerTimeGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ServerTimeGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerTimeGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ServerTimeGetResponse serialization failed!')
    console.assert(this.ServerTimeGetResponseModel.verify(), 'manager.ServerTimeGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerPlatformTimezoneGetRequest value
   * @this {!Sender}
   * @param {!ManagerPlatformTimezoneGetRequest} value ManagerPlatformTimezoneGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerPlatformTimezoneGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerPlatformTimezoneGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerPlatformTimezoneGetRequest serialization failed!')
    console.assert(this.ManagerPlatformTimezoneGetRequestModel.verify(), 'manager.ManagerPlatformTimezoneGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerPlatformTimezoneGetResponse value
   * @this {!Sender}
   * @param {!ManagerPlatformTimezoneGetResponse} value ManagerPlatformTimezoneGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerPlatformTimezoneGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerPlatformTimezoneGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerPlatformTimezoneGetResponse serialization failed!')
    console.assert(this.ManagerPlatformTimezoneGetResponseModel.verify(), 'manager.ManagerPlatformTimezoneGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneGetRequest value
   * @this {!Sender}
   * @param {!TimezoneGetRequest} value TimezoneGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneGetRequest serialization failed!')
    console.assert(this.TimezoneGetRequestModel.verify(), 'manager.TimezoneGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneGetResponse value
   * @this {!Sender}
   * @param {!TimezoneGetResponse} value TimezoneGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneGetResponse serialization failed!')
    console.assert(this.TimezoneGetResponseModel.verify(), 'manager.TimezoneGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezonesGetAllRequest value
   * @this {!Sender}
   * @param {!TimezonesGetAllRequest} value TimezonesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezonesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezonesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezonesGetAllRequest serialization failed!')
    console.assert(this.TimezonesGetAllRequestModel.verify(), 'manager.TimezonesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezonesGetAllResponse value
   * @this {!Sender}
   * @param {!TimezonesGetAllResponse} value TimezonesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezonesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezonesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezonesGetAllResponse serialization failed!')
    console.assert(this.TimezonesGetAllResponseModel.verify(), 'manager.TimezonesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneCreateRequest value
   * @this {!Sender}
   * @param {!TimezoneCreateRequest} value TimezoneCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneCreateRequest serialization failed!')
    console.assert(this.TimezoneCreateRequestModel.verify(), 'manager.TimezoneCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneCreateResponse value
   * @this {!Sender}
   * @param {!TimezoneCreateResponse} value TimezoneCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneCreateResponse serialization failed!')
    console.assert(this.TimezoneCreateResponseModel.verify(), 'manager.TimezoneCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneModifyRequest value
   * @this {!Sender}
   * @param {!TimezoneModifyRequest} value TimezoneModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneModifyRequest serialization failed!')
    console.assert(this.TimezoneModifyRequestModel.verify(), 'manager.TimezoneModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneModifyResponse value
   * @this {!Sender}
   * @param {!TimezoneModifyResponse} value TimezoneModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneModifyResponse serialization failed!')
    console.assert(this.TimezoneModifyResponseModel.verify(), 'manager.TimezoneModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneDeleteRequest value
   * @this {!Sender}
   * @param {!TimezoneDeleteRequest} value TimezoneDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneDeleteRequest serialization failed!')
    console.assert(this.TimezoneDeleteRequestModel.verify(), 'manager.TimezoneDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneDeleteResponse value
   * @this {!Sender}
   * @param {!TimezoneDeleteResponse} value TimezoneDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneDeleteResponse serialization failed!')
    console.assert(this.TimezoneDeleteResponseModel.verify(), 'manager.TimezoneDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneUpdateNotify value
   * @this {!Sender}
   * @param {!TimezoneUpdateNotify} value TimezoneUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneUpdateNotify serialization failed!')
    console.assert(this.TimezoneUpdateNotifyModel.verify(), 'manager.TimezoneUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetRequest value
   * @this {!Sender}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupGetRequest serialization failed!')
    console.assert(this.CurrencyGroupGetRequestModel.verify(), 'manager.CurrencyGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetResponse value
   * @this {!Sender}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupGetResponse serialization failed!')
    console.assert(this.CurrencyGroupGetResponseModel.verify(), 'manager.CurrencyGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllRequest value
   * @this {!Sender}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupsGetAllRequest serialization failed!')
    console.assert(this.CurrencyGroupsGetAllRequestModel.verify(), 'manager.CurrencyGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllResponse value
   * @this {!Sender}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupsGetAllResponse serialization failed!')
    console.assert(this.CurrencyGroupsGetAllResponseModel.verify(), 'manager.CurrencyGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupCreateRequest value
   * @this {!Sender}
   * @param {!CurrencyGroupCreateRequest} value CurrencyGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupCreateRequest serialization failed!')
    console.assert(this.CurrencyGroupCreateRequestModel.verify(), 'manager.CurrencyGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupCreateResponse value
   * @this {!Sender}
   * @param {!CurrencyGroupCreateResponse} value CurrencyGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupCreateResponse serialization failed!')
    console.assert(this.CurrencyGroupCreateResponseModel.verify(), 'manager.CurrencyGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupModifyRequest value
   * @this {!Sender}
   * @param {!CurrencyGroupModifyRequest} value CurrencyGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupModifyRequest serialization failed!')
    console.assert(this.CurrencyGroupModifyRequestModel.verify(), 'manager.CurrencyGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupModifyResponse value
   * @this {!Sender}
   * @param {!CurrencyGroupModifyResponse} value CurrencyGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupModifyResponse serialization failed!')
    console.assert(this.CurrencyGroupModifyResponseModel.verify(), 'manager.CurrencyGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupDeleteRequest value
   * @this {!Sender}
   * @param {!CurrencyGroupDeleteRequest} value CurrencyGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupDeleteRequest serialization failed!')
    console.assert(this.CurrencyGroupDeleteRequestModel.verify(), 'manager.CurrencyGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupDeleteResponse value
   * @this {!Sender}
   * @param {!CurrencyGroupDeleteResponse} value CurrencyGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupDeleteResponse serialization failed!')
    console.assert(this.CurrencyGroupDeleteResponseModel.verify(), 'manager.CurrencyGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupUpdateNotify value
   * @this {!Sender}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupUpdateNotify serialization failed!')
    console.assert(this.CurrencyGroupUpdateNotifyModel.verify(), 'manager.CurrencyGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetRequest value
   * @this {!Sender}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGetRequest serialization failed!')
    console.assert(this.CurrencyGetRequestModel.verify(), 'manager.CurrencyGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetResponse value
   * @this {!Sender}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGetResponse serialization failed!')
    console.assert(this.CurrencyGetResponseModel.verify(), 'manager.CurrencyGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllRequest value
   * @this {!Sender}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrenciesGetAllRequest serialization failed!')
    console.assert(this.CurrenciesGetAllRequestModel.verify(), 'manager.CurrenciesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllResponse value
   * @this {!Sender}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrenciesGetAllResponse serialization failed!')
    console.assert(this.CurrenciesGetAllResponseModel.verify(), 'manager.CurrenciesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyCreateRequest value
   * @this {!Sender}
   * @param {!CurrencyCreateRequest} value CurrencyCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyCreateRequest serialization failed!')
    console.assert(this.CurrencyCreateRequestModel.verify(), 'manager.CurrencyCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyCreateResponse value
   * @this {!Sender}
   * @param {!CurrencyCreateResponse} value CurrencyCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyCreateResponse serialization failed!')
    console.assert(this.CurrencyCreateResponseModel.verify(), 'manager.CurrencyCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyModifyRequest value
   * @this {!Sender}
   * @param {!CurrencyModifyRequest} value CurrencyModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyModifyRequest serialization failed!')
    console.assert(this.CurrencyModifyRequestModel.verify(), 'manager.CurrencyModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyModifyResponse value
   * @this {!Sender}
   * @param {!CurrencyModifyResponse} value CurrencyModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyModifyResponse serialization failed!')
    console.assert(this.CurrencyModifyResponseModel.verify(), 'manager.CurrencyModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyDeleteRequest value
   * @this {!Sender}
   * @param {!CurrencyDeleteRequest} value CurrencyDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyDeleteRequest serialization failed!')
    console.assert(this.CurrencyDeleteRequestModel.verify(), 'manager.CurrencyDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyDeleteResponse value
   * @this {!Sender}
   * @param {!CurrencyDeleteResponse} value CurrencyDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyDeleteResponse serialization failed!')
    console.assert(this.CurrencyDeleteResponseModel.verify(), 'manager.CurrencyDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyUpdateNotify value
   * @this {!Sender}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyUpdateNotify serialization failed!')
    console.assert(this.CurrencyUpdateNotifyModel.verify(), 'manager.CurrencyUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetRequest value
   * @this {!Sender}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupGetRequest serialization failed!')
    console.assert(this.SymbolGroupGetRequestModel.verify(), 'manager.SymbolGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetResponse value
   * @this {!Sender}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupGetResponse serialization failed!')
    console.assert(this.SymbolGroupGetResponseModel.verify(), 'manager.SymbolGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllRequest value
   * @this {!Sender}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupsGetAllRequest serialization failed!')
    console.assert(this.SymbolGroupsGetAllRequestModel.verify(), 'manager.SymbolGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllResponse value
   * @this {!Sender}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupsGetAllResponse serialization failed!')
    console.assert(this.SymbolGroupsGetAllResponseModel.verify(), 'manager.SymbolGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupCreateRequest value
   * @this {!Sender}
   * @param {!SymbolGroupCreateRequest} value SymbolGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupCreateRequest serialization failed!')
    console.assert(this.SymbolGroupCreateRequestModel.verify(), 'manager.SymbolGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupCreateResponse value
   * @this {!Sender}
   * @param {!SymbolGroupCreateResponse} value SymbolGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupCreateResponse serialization failed!')
    console.assert(this.SymbolGroupCreateResponseModel.verify(), 'manager.SymbolGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupModifyRequest value
   * @this {!Sender}
   * @param {!SymbolGroupModifyRequest} value SymbolGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupModifyRequest serialization failed!')
    console.assert(this.SymbolGroupModifyRequestModel.verify(), 'manager.SymbolGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupModifyResponse value
   * @this {!Sender}
   * @param {!SymbolGroupModifyResponse} value SymbolGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupModifyResponse serialization failed!')
    console.assert(this.SymbolGroupModifyResponseModel.verify(), 'manager.SymbolGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupDeleteRequest value
   * @this {!Sender}
   * @param {!SymbolGroupDeleteRequest} value SymbolGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupDeleteRequest serialization failed!')
    console.assert(this.SymbolGroupDeleteRequestModel.verify(), 'manager.SymbolGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupDeleteResponse value
   * @this {!Sender}
   * @param {!SymbolGroupDeleteResponse} value SymbolGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupDeleteResponse serialization failed!')
    console.assert(this.SymbolGroupDeleteResponseModel.verify(), 'manager.SymbolGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupUpdateNotify serialization failed!')
    console.assert(this.SymbolGroupUpdateNotifyModel.verify(), 'manager.SymbolGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetRequest value
   * @this {!Sender}
   * @param {!SymbolGetRequest} value SymbolGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGetRequest serialization failed!')
    console.assert(this.SymbolGetRequestModel.verify(), 'manager.SymbolGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetResponse value
   * @this {!Sender}
   * @param {!SymbolGetResponse} value SymbolGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGetResponse serialization failed!')
    console.assert(this.SymbolGetResponseModel.verify(), 'manager.SymbolGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllRequest value
   * @this {!Sender}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolsGetAllRequest serialization failed!')
    console.assert(this.SymbolsGetAllRequestModel.verify(), 'manager.SymbolsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllResponse value
   * @this {!Sender}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolsGetAllResponse serialization failed!')
    console.assert(this.SymbolsGetAllResponseModel.verify(), 'manager.SymbolsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolCreateRequest value
   * @this {!Sender}
   * @param {!SymbolCreateRequest} value SymbolCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolCreateRequest serialization failed!')
    console.assert(this.SymbolCreateRequestModel.verify(), 'manager.SymbolCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolCreateResponse value
   * @this {!Sender}
   * @param {!SymbolCreateResponse} value SymbolCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolCreateResponse serialization failed!')
    console.assert(this.SymbolCreateResponseModel.verify(), 'manager.SymbolCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolModifyRequest value
   * @this {!Sender}
   * @param {!SymbolModifyRequest} value SymbolModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolModifyRequest serialization failed!')
    console.assert(this.SymbolModifyRequestModel.verify(), 'manager.SymbolModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolModifyResponse value
   * @this {!Sender}
   * @param {!SymbolModifyResponse} value SymbolModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolModifyResponse serialization failed!')
    console.assert(this.SymbolModifyResponseModel.verify(), 'manager.SymbolModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDeleteRequest value
   * @this {!Sender}
   * @param {!SymbolDeleteRequest} value SymbolDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolDeleteRequest serialization failed!')
    console.assert(this.SymbolDeleteRequestModel.verify(), 'manager.SymbolDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDeleteResponse value
   * @this {!Sender}
   * @param {!SymbolDeleteResponse} value SymbolDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolDeleteResponse serialization failed!')
    console.assert(this.SymbolDeleteResponseModel.verify(), 'manager.SymbolDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolUpdateNotify serialization failed!')
    console.assert(this.SymbolUpdateNotifyModel.verify(), 'manager.SymbolUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsExportRequest value
   * @this {!Sender}
   * @param {!SwapsExportRequest} value SwapsExportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsExportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsExportRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsExportRequest serialization failed!')
    console.assert(this.SwapsExportRequestModel.verify(), 'manager.SwapsExportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsExportResponse value
   * @this {!Sender}
   * @param {!SwapsExportResponse} value SwapsExportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsExportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsExportResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsExportResponse serialization failed!')
    console.assert(this.SwapsExportResponseModel.verify(), 'manager.SwapsExportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsImportRequest value
   * @this {!Sender}
   * @param {!SwapsImportRequest} value SwapsImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsImportRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsImportRequest serialization failed!')
    console.assert(this.SwapsImportRequestModel.verify(), 'manager.SwapsImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsImportResponse value
   * @this {!Sender}
   * @param {!SwapsImportResponse} value SwapsImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsImportResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsImportResponse serialization failed!')
    console.assert(this.SwapsImportResponseModel.verify(), 'manager.SwapsImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileGetRequest value
   * @this {!Sender}
   * @param {!CommissionProfileGetRequest} value CommissionProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileGetRequest serialization failed!')
    console.assert(this.CommissionProfileGetRequestModel.verify(), 'manager.CommissionProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileGetResponse value
   * @this {!Sender}
   * @param {!CommissionProfileGetResponse} value CommissionProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileGetResponse serialization failed!')
    console.assert(this.CommissionProfileGetResponseModel.verify(), 'manager.CommissionProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfilesGetAllRequest value
   * @this {!Sender}
   * @param {!CommissionProfilesGetAllRequest} value CommissionProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfilesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfilesGetAllRequest serialization failed!')
    console.assert(this.CommissionProfilesGetAllRequestModel.verify(), 'manager.CommissionProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfilesGetAllResponse value
   * @this {!Sender}
   * @param {!CommissionProfilesGetAllResponse} value CommissionProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfilesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfilesGetAllResponse serialization failed!')
    console.assert(this.CommissionProfilesGetAllResponseModel.verify(), 'manager.CommissionProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileCreateRequest value
   * @this {!Sender}
   * @param {!CommissionProfileCreateRequest} value CommissionProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileCreateRequest serialization failed!')
    console.assert(this.CommissionProfileCreateRequestModel.verify(), 'manager.CommissionProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileCreateResponse value
   * @this {!Sender}
   * @param {!CommissionProfileCreateResponse} value CommissionProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileCreateResponse serialization failed!')
    console.assert(this.CommissionProfileCreateResponseModel.verify(), 'manager.CommissionProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileModifyRequest value
   * @this {!Sender}
   * @param {!CommissionProfileModifyRequest} value CommissionProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileModifyRequest serialization failed!')
    console.assert(this.CommissionProfileModifyRequestModel.verify(), 'manager.CommissionProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileModifyResponse value
   * @this {!Sender}
   * @param {!CommissionProfileModifyResponse} value CommissionProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileModifyResponse serialization failed!')
    console.assert(this.CommissionProfileModifyResponseModel.verify(), 'manager.CommissionProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileDeleteRequest value
   * @this {!Sender}
   * @param {!CommissionProfileDeleteRequest} value CommissionProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileDeleteRequest serialization failed!')
    console.assert(this.CommissionProfileDeleteRequestModel.verify(), 'manager.CommissionProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileDeleteResponse value
   * @this {!Sender}
   * @param {!CommissionProfileDeleteResponse} value CommissionProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileDeleteResponse serialization failed!')
    console.assert(this.CommissionProfileDeleteResponseModel.verify(), 'manager.CommissionProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileUpdateNotify value
   * @this {!Sender}
   * @param {!CommissionProfileUpdateNotify} value CommissionProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileUpdateNotify serialization failed!')
    console.assert(this.CommissionProfileUpdateNotifyModel.verify(), 'manager.CommissionProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileGetRequest value
   * @this {!Sender}
   * @param {!MarkupProfileGetRequest} value MarkupProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileGetRequest serialization failed!')
    console.assert(this.MarkupProfileGetRequestModel.verify(), 'manager.MarkupProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileGetResponse value
   * @this {!Sender}
   * @param {!MarkupProfileGetResponse} value MarkupProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileGetResponse serialization failed!')
    console.assert(this.MarkupProfileGetResponseModel.verify(), 'manager.MarkupProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfilesGetAllRequest value
   * @this {!Sender}
   * @param {!MarkupProfilesGetAllRequest} value MarkupProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfilesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfilesGetAllRequest serialization failed!')
    console.assert(this.MarkupProfilesGetAllRequestModel.verify(), 'manager.MarkupProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfilesGetAllResponse value
   * @this {!Sender}
   * @param {!MarkupProfilesGetAllResponse} value MarkupProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfilesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfilesGetAllResponse serialization failed!')
    console.assert(this.MarkupProfilesGetAllResponseModel.verify(), 'manager.MarkupProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileCreateRequest value
   * @this {!Sender}
   * @param {!MarkupProfileCreateRequest} value MarkupProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileCreateRequest serialization failed!')
    console.assert(this.MarkupProfileCreateRequestModel.verify(), 'manager.MarkupProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileCreateResponse value
   * @this {!Sender}
   * @param {!MarkupProfileCreateResponse} value MarkupProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileCreateResponse serialization failed!')
    console.assert(this.MarkupProfileCreateResponseModel.verify(), 'manager.MarkupProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileModifyRequest value
   * @this {!Sender}
   * @param {!MarkupProfileModifyRequest} value MarkupProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileModifyRequest serialization failed!')
    console.assert(this.MarkupProfileModifyRequestModel.verify(), 'manager.MarkupProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileModifyResponse value
   * @this {!Sender}
   * @param {!MarkupProfileModifyResponse} value MarkupProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileModifyResponse serialization failed!')
    console.assert(this.MarkupProfileModifyResponseModel.verify(), 'manager.MarkupProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileDeleteRequest value
   * @this {!Sender}
   * @param {!MarkupProfileDeleteRequest} value MarkupProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileDeleteRequest serialization failed!')
    console.assert(this.MarkupProfileDeleteRequestModel.verify(), 'manager.MarkupProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileDeleteResponse value
   * @this {!Sender}
   * @param {!MarkupProfileDeleteResponse} value MarkupProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileDeleteResponse serialization failed!')
    console.assert(this.MarkupProfileDeleteResponseModel.verify(), 'manager.MarkupProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileUpdateNotify value
   * @this {!Sender}
   * @param {!MarkupProfileUpdateNotify} value MarkupProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileUpdateNotify serialization failed!')
    console.assert(this.MarkupProfileUpdateNotifyModel.verify(), 'manager.MarkupProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupGetRequest value
   * @this {!Sender}
   * @param {!ManagerGroupGetRequest} value ManagerGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupGetRequest serialization failed!')
    console.assert(this.ManagerGroupGetRequestModel.verify(), 'manager.ManagerGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupGetResponse value
   * @this {!Sender}
   * @param {!ManagerGroupGetResponse} value ManagerGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupGetResponse serialization failed!')
    console.assert(this.ManagerGroupGetResponseModel.verify(), 'manager.ManagerGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupsGetAllRequest value
   * @this {!Sender}
   * @param {!ManagerGroupsGetAllRequest} value ManagerGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupsGetAllRequest serialization failed!')
    console.assert(this.ManagerGroupsGetAllRequestModel.verify(), 'manager.ManagerGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupsGetAllResponse value
   * @this {!Sender}
   * @param {!ManagerGroupsGetAllResponse} value ManagerGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupsGetAllResponse serialization failed!')
    console.assert(this.ManagerGroupsGetAllResponseModel.verify(), 'manager.ManagerGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupCreateRequest value
   * @this {!Sender}
   * @param {!ManagerGroupCreateRequest} value ManagerGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupCreateRequest serialization failed!')
    console.assert(this.ManagerGroupCreateRequestModel.verify(), 'manager.ManagerGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupCreateResponse value
   * @this {!Sender}
   * @param {!ManagerGroupCreateResponse} value ManagerGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupCreateResponse serialization failed!')
    console.assert(this.ManagerGroupCreateResponseModel.verify(), 'manager.ManagerGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupModifyRequest value
   * @this {!Sender}
   * @param {!ManagerGroupModifyRequest} value ManagerGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupModifyRequest serialization failed!')
    console.assert(this.ManagerGroupModifyRequestModel.verify(), 'manager.ManagerGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupModifyResponse value
   * @this {!Sender}
   * @param {!ManagerGroupModifyResponse} value ManagerGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupModifyResponse serialization failed!')
    console.assert(this.ManagerGroupModifyResponseModel.verify(), 'manager.ManagerGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupDeleteRequest value
   * @this {!Sender}
   * @param {!ManagerGroupDeleteRequest} value ManagerGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupDeleteRequest serialization failed!')
    console.assert(this.ManagerGroupDeleteRequestModel.verify(), 'manager.ManagerGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupDeleteResponse value
   * @this {!Sender}
   * @param {!ManagerGroupDeleteResponse} value ManagerGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupDeleteResponse serialization failed!')
    console.assert(this.ManagerGroupDeleteResponseModel.verify(), 'manager.ManagerGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupUpdateNotify value
   * @this {!Sender}
   * @param {!ManagerGroupUpdateNotify} value ManagerGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupUpdateNotify serialization failed!')
    console.assert(this.ManagerGroupUpdateNotifyModel.verify(), 'manager.ManagerGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGetRequest value
   * @this {!Sender}
   * @param {!ManagerGetRequest} value ManagerGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGetRequest serialization failed!')
    console.assert(this.ManagerGetRequestModel.verify(), 'manager.ManagerGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGetResponse value
   * @this {!Sender}
   * @param {!ManagerGetResponse} value ManagerGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGetResponse serialization failed!')
    console.assert(this.ManagerGetResponseModel.verify(), 'manager.ManagerGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagersGetAllRequest value
   * @this {!Sender}
   * @param {!ManagersGetAllRequest} value ManagersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagersGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagersGetAllRequest serialization failed!')
    console.assert(this.ManagersGetAllRequestModel.verify(), 'manager.ManagersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagersGetAllResponse value
   * @this {!Sender}
   * @param {!ManagersGetAllResponse} value ManagersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagersGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagersGetAllResponse serialization failed!')
    console.assert(this.ManagersGetAllResponseModel.verify(), 'manager.ManagersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerCreateRequest value
   * @this {!Sender}
   * @param {!ManagerCreateRequest} value ManagerCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerCreateRequest serialization failed!')
    console.assert(this.ManagerCreateRequestModel.verify(), 'manager.ManagerCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerCreateResponse value
   * @this {!Sender}
   * @param {!ManagerCreateResponse} value ManagerCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerCreateResponse serialization failed!')
    console.assert(this.ManagerCreateResponseModel.verify(), 'manager.ManagerCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerModifyRequest value
   * @this {!Sender}
   * @param {!ManagerModifyRequest} value ManagerModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerModifyRequest serialization failed!')
    console.assert(this.ManagerModifyRequestModel.verify(), 'manager.ManagerModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerModifyResponse value
   * @this {!Sender}
   * @param {!ManagerModifyResponse} value ManagerModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerModifyResponse serialization failed!')
    console.assert(this.ManagerModifyResponseModel.verify(), 'manager.ManagerModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerDeleteRequest value
   * @this {!Sender}
   * @param {!ManagerDeleteRequest} value ManagerDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerDeleteRequest serialization failed!')
    console.assert(this.ManagerDeleteRequestModel.verify(), 'manager.ManagerDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerDeleteResponse value
   * @this {!Sender}
   * @param {!ManagerDeleteResponse} value ManagerDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerDeleteResponse serialization failed!')
    console.assert(this.ManagerDeleteResponseModel.verify(), 'manager.ManagerDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerUpdateNotify value
   * @this {!Sender}
   * @param {!ManagerUpdateNotify} value ManagerUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerUpdateNotify serialization failed!')
    console.assert(this.ManagerUpdateNotifyModel.verify(), 'manager.ManagerUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformGetRequest value
   * @this {!Sender}
   * @param {!PlatformGetRequest} value PlatformGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformGetRequest serialization failed!')
    console.assert(this.PlatformGetRequestModel.verify(), 'manager.PlatformGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformGetResponse value
   * @this {!Sender}
   * @param {!PlatformGetResponse} value PlatformGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformGetResponse serialization failed!')
    console.assert(this.PlatformGetResponseModel.verify(), 'manager.PlatformGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformsGetAllRequest value
   * @this {!Sender}
   * @param {!PlatformsGetAllRequest} value PlatformsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformsGetAllRequest serialization failed!')
    console.assert(this.PlatformsGetAllRequestModel.verify(), 'manager.PlatformsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformsGetAllResponse value
   * @this {!Sender}
   * @param {!PlatformsGetAllResponse} value PlatformsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformsGetAllResponse serialization failed!')
    console.assert(this.PlatformsGetAllResponseModel.verify(), 'manager.PlatformsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformCreateRequest value
   * @this {!Sender}
   * @param {!PlatformCreateRequest} value PlatformCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformCreateRequest serialization failed!')
    console.assert(this.PlatformCreateRequestModel.verify(), 'manager.PlatformCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformCreateResponse value
   * @this {!Sender}
   * @param {!PlatformCreateResponse} value PlatformCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformCreateResponse serialization failed!')
    console.assert(this.PlatformCreateResponseModel.verify(), 'manager.PlatformCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformModifyRequest value
   * @this {!Sender}
   * @param {!PlatformModifyRequest} value PlatformModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformModifyRequest serialization failed!')
    console.assert(this.PlatformModifyRequestModel.verify(), 'manager.PlatformModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformModifyResponse value
   * @this {!Sender}
   * @param {!PlatformModifyResponse} value PlatformModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformModifyResponse serialization failed!')
    console.assert(this.PlatformModifyResponseModel.verify(), 'manager.PlatformModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformDeleteRequest value
   * @this {!Sender}
   * @param {!PlatformDeleteRequest} value PlatformDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformDeleteRequest serialization failed!')
    console.assert(this.PlatformDeleteRequestModel.verify(), 'manager.PlatformDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformDeleteResponse value
   * @this {!Sender}
   * @param {!PlatformDeleteResponse} value PlatformDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformDeleteResponse serialization failed!')
    console.assert(this.PlatformDeleteResponseModel.verify(), 'manager.PlatformDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformUpdateNotify value
   * @this {!Sender}
   * @param {!PlatformUpdateNotify} value PlatformUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformUpdateNotify serialization failed!')
    console.assert(this.PlatformUpdateNotifyModel.verify(), 'manager.PlatformUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupGetRequest value
   * @this {!Sender}
   * @param {!AccountGroupGetRequest} value AccountGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupGetRequest serialization failed!')
    console.assert(this.AccountGroupGetRequestModel.verify(), 'manager.AccountGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupGetResponse value
   * @this {!Sender}
   * @param {!AccountGroupGetResponse} value AccountGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupGetResponse serialization failed!')
    console.assert(this.AccountGroupGetResponseModel.verify(), 'manager.AccountGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupsGetAllRequest value
   * @this {!Sender}
   * @param {!AccountGroupsGetAllRequest} value AccountGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupsGetAllRequest serialization failed!')
    console.assert(this.AccountGroupsGetAllRequestModel.verify(), 'manager.AccountGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupsGetAllResponse value
   * @this {!Sender}
   * @param {!AccountGroupsGetAllResponse} value AccountGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupsGetAllResponse serialization failed!')
    console.assert(this.AccountGroupsGetAllResponseModel.verify(), 'manager.AccountGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupCreateRequest value
   * @this {!Sender}
   * @param {!AccountGroupCreateRequest} value AccountGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupCreateRequest serialization failed!')
    console.assert(this.AccountGroupCreateRequestModel.verify(), 'manager.AccountGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupCreateResponse value
   * @this {!Sender}
   * @param {!AccountGroupCreateResponse} value AccountGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupCreateResponse serialization failed!')
    console.assert(this.AccountGroupCreateResponseModel.verify(), 'manager.AccountGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupModifyRequest value
   * @this {!Sender}
   * @param {!AccountGroupModifyRequest} value AccountGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupModifyRequest serialization failed!')
    console.assert(this.AccountGroupModifyRequestModel.verify(), 'manager.AccountGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupModifyResponse value
   * @this {!Sender}
   * @param {!AccountGroupModifyResponse} value AccountGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupModifyResponse serialization failed!')
    console.assert(this.AccountGroupModifyResponseModel.verify(), 'manager.AccountGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupDeleteRequest value
   * @this {!Sender}
   * @param {!AccountGroupDeleteRequest} value AccountGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupDeleteRequest serialization failed!')
    console.assert(this.AccountGroupDeleteRequestModel.verify(), 'manager.AccountGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupDeleteResponse value
   * @this {!Sender}
   * @param {!AccountGroupDeleteResponse} value AccountGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupDeleteResponse serialization failed!')
    console.assert(this.AccountGroupDeleteResponseModel.verify(), 'manager.AccountGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupUpdateNotify value
   * @this {!Sender}
   * @param {!AccountGroupUpdateNotify} value AccountGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupUpdateNotify serialization failed!')
    console.assert(this.AccountGroupUpdateNotifyModel.verify(), 'manager.AccountGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGetRequest value
   * @this {!Sender}
   * @param {!AccountGetRequest} value AccountGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGetRequest serialization failed!')
    console.assert(this.AccountGetRequestModel.verify(), 'manager.AccountGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGetResponse value
   * @this {!Sender}
   * @param {!AccountGetResponse} value AccountGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGetResponse serialization failed!')
    console.assert(this.AccountGetResponseModel.verify(), 'manager.AccountGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllRequest value
   * @this {!Sender}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetAllRequest serialization failed!')
    console.assert(this.AccountsGetAllRequestModel.verify(), 'manager.AccountsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllResponse value
   * @this {!Sender}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetAllResponse serialization failed!')
    console.assert(this.AccountsGetAllResponseModel.verify(), 'manager.AccountsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountCreateRequest value
   * @this {!Sender}
   * @param {!AccountCreateRequest} value AccountCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountCreateRequest serialization failed!')
    console.assert(this.AccountCreateRequestModel.verify(), 'manager.AccountCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountCreateResponse value
   * @this {!Sender}
   * @param {!AccountCreateResponse} value AccountCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountCreateResponse serialization failed!')
    console.assert(this.AccountCreateResponseModel.verify(), 'manager.AccountCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterCreateRequest value
   * @this {!Sender}
   * @param {!AccountSocialTradingMasterCreateRequest} value AccountSocialTradingMasterCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingMasterCreateRequest serialization failed!')
    console.assert(this.AccountSocialTradingMasterCreateRequestModel.verify(), 'manager.AccountSocialTradingMasterCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterCreateResponse value
   * @this {!Sender}
   * @param {!AccountSocialTradingMasterCreateResponse} value AccountSocialTradingMasterCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingMasterCreateResponse serialization failed!')
    console.assert(this.AccountSocialTradingMasterCreateResponseModel.verify(), 'manager.AccountSocialTradingMasterCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorCreateRequest value
   * @this {!Sender}
   * @param {!AccountSocialTradingInvestorCreateRequest} value AccountSocialTradingInvestorCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingInvestorCreateRequest serialization failed!')
    console.assert(this.AccountSocialTradingInvestorCreateRequestModel.verify(), 'manager.AccountSocialTradingInvestorCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorCreateResponse value
   * @this {!Sender}
   * @param {!AccountSocialTradingInvestorCreateResponse} value AccountSocialTradingInvestorCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingInvestorCreateResponse serialization failed!')
    console.assert(this.AccountSocialTradingInvestorCreateResponseModel.verify(), 'manager.AccountSocialTradingInvestorCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountModifyRequest value
   * @this {!Sender}
   * @param {!AccountModifyRequest} value AccountModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountModifyRequest serialization failed!')
    console.assert(this.AccountModifyRequestModel.verify(), 'manager.AccountModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountModifyResponse value
   * @this {!Sender}
   * @param {!AccountModifyResponse} value AccountModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountModifyResponse serialization failed!')
    console.assert(this.AccountModifyResponseModel.verify(), 'manager.AccountModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteRequest value
   * @this {!Sender}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountDeleteRequest serialization failed!')
    console.assert(this.AccountDeleteRequestModel.verify(), 'manager.AccountDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteResponse value
   * @this {!Sender}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountDeleteResponse serialization failed!')
    console.assert(this.AccountDeleteResponseModel.verify(), 'manager.AccountDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountsDeleteRequest value
   * @this {!Sender}
   * @param {!DemoAccountsDeleteRequest} value DemoAccountsDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountsDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountsDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.DemoAccountsDeleteRequest serialization failed!')
    console.assert(this.DemoAccountsDeleteRequestModel.verify(), 'manager.DemoAccountsDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountsDeleteResponse value
   * @this {!Sender}
   * @param {!DemoAccountsDeleteResponse} value DemoAccountsDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountsDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountsDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.DemoAccountsDeleteResponse serialization failed!')
    console.assert(this.DemoAccountsDeleteResponseModel.verify(), 'manager.DemoAccountsDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingMasterRequest value
   * @this {!Sender}
   * @param {!AccountUpgradeToSocialTradingMasterRequest} value AccountUpgradeToSocialTradingMasterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingMasterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingMasterRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingMasterRequest serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingMasterRequestModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingMasterResponse value
   * @this {!Sender}
   * @param {!AccountUpgradeToSocialTradingMasterResponse} value AccountUpgradeToSocialTradingMasterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingMasterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingMasterResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingMasterResponse serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingMasterResponseModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingInvestorRequest value
   * @this {!Sender}
   * @param {!AccountUpgradeToSocialTradingInvestorRequest} value AccountUpgradeToSocialTradingInvestorRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingInvestorRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingInvestorRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingInvestorRequest serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingInvestorRequestModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingInvestorResponse value
   * @this {!Sender}
   * @param {!AccountUpgradeToSocialTradingInvestorResponse} value AccountUpgradeToSocialTradingInvestorResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingInvestorResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingInvestorResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingInvestorResponse serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingInvestorResponseModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingInvestorAccountToRegularRequest value
   * @this {!Sender}
   * @param {!SocialTradingInvestorAccountToRegularRequest} value SocialTradingInvestorAccountToRegularRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingInvestorAccountToRegularRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingInvestorAccountToRegularRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingInvestorAccountToRegularRequest serialization failed!')
    console.assert(this.SocialTradingInvestorAccountToRegularRequestModel.verify(), 'manager.SocialTradingInvestorAccountToRegularRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingInvestorAccountToRegularResponse value
   * @this {!Sender}
   * @param {!SocialTradingInvestorAccountToRegularResponse} value SocialTradingInvestorAccountToRegularResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingInvestorAccountToRegularResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingInvestorAccountToRegularResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingInvestorAccountToRegularResponse serialization failed!')
    console.assert(this.SocialTradingInvestorAccountToRegularResponseModel.verify(), 'manager.SocialTradingInvestorAccountToRegularResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingMasterAccountToRegularRequest value
   * @this {!Sender}
   * @param {!SocialTradingMasterAccountToRegularRequest} value SocialTradingMasterAccountToRegularRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingMasterAccountToRegularRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingMasterAccountToRegularRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingMasterAccountToRegularRequest serialization failed!')
    console.assert(this.SocialTradingMasterAccountToRegularRequestModel.verify(), 'manager.SocialTradingMasterAccountToRegularRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingMasterAccountToRegularResponse value
   * @this {!Sender}
   * @param {!SocialTradingMasterAccountToRegularResponse} value SocialTradingMasterAccountToRegularResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingMasterAccountToRegularResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingMasterAccountToRegularResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingMasterAccountToRegularResponse serialization failed!')
    console.assert(this.SocialTradingMasterAccountToRegularResponseModel.verify(), 'manager.SocialTradingMasterAccountToRegularResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateNotify value
   * @this {!Sender}
   * @param {!AccountUpdateNotify} value AccountUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpdateNotify serialization failed!')
    console.assert(this.AccountUpdateNotifyModel.verify(), 'manager.AccountUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginGetRequest value
   * @this {!Sender}
   * @param {!MarginGetRequest} value MarginGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginGetRequest serialization failed!')
    console.assert(this.MarginGetRequestModel.verify(), 'manager.MarginGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginGetResponse value
   * @this {!Sender}
   * @param {!MarginGetResponse} value MarginGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginGetResponse serialization failed!')
    console.assert(this.MarginGetResponseModel.verify(), 'manager.MarginGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllRequest value
   * @this {!Sender}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginsGetAllRequest serialization failed!')
    console.assert(this.MarginsGetAllRequestModel.verify(), 'manager.MarginsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllResponse value
   * @this {!Sender}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginsGetAllResponse serialization failed!')
    console.assert(this.MarginsGetAllResponseModel.verify(), 'manager.MarginsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateNotify value
   * @this {!Sender}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginUpdateNotify serialization failed!')
    console.assert(this.MarginUpdateNotifyModel.verify(), 'manager.MarginUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginCallNotify value
   * @this {!Sender}
   * @param {!MarginCallNotify} value MarginCallNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginCallNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginCallNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginCallNotify serialization failed!')
    console.assert(this.MarginCallNotifyModel.verify(), 'manager.MarginCallNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StopOutNotify value
   * @this {!Sender}
   * @param {!StopOutNotify} value StopOutNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StopOutNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StopOutNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.StopOutNotify serialization failed!')
    console.assert(this.StopOutNotifyModel.verify(), 'manager.StopOutNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetGetRequest value
   * @this {!Sender}
   * @param {!AssetGetRequest} value AssetGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetGetRequest serialization failed!')
    console.assert(this.AssetGetRequestModel.verify(), 'manager.AssetGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetGetResponse value
   * @this {!Sender}
   * @param {!AssetGetResponse} value AssetGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetGetResponse serialization failed!')
    console.assert(this.AssetGetResponseModel.verify(), 'manager.AssetGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetRequest value
   * @this {!Sender}
   * @param {!AssetsGetRequest} value AssetsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetRequest serialization failed!')
    console.assert(this.AssetsGetRequestModel.verify(), 'manager.AssetsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetResponse value
   * @this {!Sender}
   * @param {!AssetsGetResponse} value AssetsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetResponse serialization failed!')
    console.assert(this.AssetsGetResponseModel.verify(), 'manager.AssetsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllRequest value
   * @this {!Sender}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetAllRequest serialization failed!')
    console.assert(this.AssetsGetAllRequestModel.verify(), 'manager.AssetsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllResponse value
   * @this {!Sender}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetAllResponse serialization failed!')
    console.assert(this.AssetsGetAllResponseModel.verify(), 'manager.AssetsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdateNotify value
   * @this {!Sender}
   * @param {!AssetUpdateNotify} value AssetUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetUpdateNotify serialization failed!')
    console.assert(this.AssetUpdateNotifyModel.verify(), 'manager.AssetUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderGetRequest value
   * @this {!Sender}
   * @param {!OrderGetRequest} value OrderGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderGetRequest serialization failed!')
    console.assert(this.OrderGetRequestModel.verify(), 'manager.OrderGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderGetResponse value
   * @this {!Sender}
   * @param {!OrderGetResponse} value OrderGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderGetResponse serialization failed!')
    console.assert(this.OrderGetResponseModel.verify(), 'manager.OrderGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetRequest value
   * @this {!Sender}
   * @param {!OrdersGetRequest} value OrdersGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetRequest serialization failed!')
    console.assert(this.OrdersGetRequestModel.verify(), 'manager.OrdersGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetResponse value
   * @this {!Sender}
   * @param {!OrdersGetResponse} value OrdersGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetResponse serialization failed!')
    console.assert(this.OrdersGetResponseModel.verify(), 'manager.OrdersGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllRequest value
   * @this {!Sender}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetAllRequest serialization failed!')
    console.assert(this.OrdersGetAllRequestModel.verify(), 'manager.OrdersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllResponse value
   * @this {!Sender}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetAllResponse serialization failed!')
    console.assert(this.OrdersGetAllResponseModel.verify(), 'manager.OrdersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCreateRequest value
   * @this {!Sender}
   * @param {!OrderCreateRequest} value OrderCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCreateRequest serialization failed!')
    console.assert(this.OrderCreateRequestModel.verify(), 'manager.OrderCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCreateResponse value
   * @this {!Sender}
   * @param {!OrderCreateResponse} value OrderCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCreateResponse serialization failed!')
    console.assert(this.OrderCreateResponseModel.verify(), 'manager.OrderCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderModifyRequest value
   * @this {!Sender}
   * @param {!OrderModifyRequest} value OrderModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderModifyRequest serialization failed!')
    console.assert(this.OrderModifyRequestModel.verify(), 'manager.OrderModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderModifyResponse value
   * @this {!Sender}
   * @param {!OrderModifyResponse} value OrderModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderModifyResponse serialization failed!')
    console.assert(this.OrderModifyResponseModel.verify(), 'manager.OrderModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderActivateRequest value
   * @this {!Sender}
   * @param {!OrderActivateRequest} value OrderActivateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderActivateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderActivateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderActivateRequest serialization failed!')
    console.assert(this.OrderActivateRequestModel.verify(), 'manager.OrderActivateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderActivateResponse value
   * @this {!Sender}
   * @param {!OrderActivateResponse} value OrderActivateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderActivateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderActivateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderActivateResponse serialization failed!')
    console.assert(this.OrderActivateResponseModel.verify(), 'manager.OrderActivateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelRequest value
   * @this {!Sender}
   * @param {!OrderCancelRequest} value OrderCancelRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelRequest serialization failed!')
    console.assert(this.OrderCancelRequestModel.verify(), 'manager.OrderCancelRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelResponse value
   * @this {!Sender}
   * @param {!OrderCancelResponse} value OrderCancelResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelResponse serialization failed!')
    console.assert(this.OrderCancelResponseModel.verify(), 'manager.OrderCancelResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllRequest value
   * @this {!Sender}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelAllRequest serialization failed!')
    console.assert(this.OrderCancelAllRequestModel.verify(), 'manager.OrderCancelAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllResponse value
   * @this {!Sender}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelAllResponse serialization failed!')
    console.assert(this.OrderCancelAllResponseModel.verify(), 'manager.OrderCancelAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllByIdRequest value
   * @this {!Sender}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelAllByIdRequest serialization failed!')
    console.assert(this.OrderCancelAllByIdRequestModel.verify(), 'manager.OrderCancelAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseRequest value
   * @this {!Sender}
   * @param {!OrderCloseRequest} value OrderCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseRequest serialization failed!')
    console.assert(this.OrderCloseRequestModel.verify(), 'manager.OrderCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseResponse value
   * @this {!Sender}
   * @param {!OrderCloseResponse} value OrderCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseResponse serialization failed!')
    console.assert(this.OrderCloseResponseModel.verify(), 'manager.OrderCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllRequest value
   * @this {!Sender}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseAllRequest serialization failed!')
    console.assert(this.OrderCloseAllRequestModel.verify(), 'manager.OrderCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllResponse value
   * @this {!Sender}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseAllResponse serialization failed!')
    console.assert(this.OrderCloseAllResponseModel.verify(), 'manager.OrderCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllByIdRequest value
   * @this {!Sender}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseAllByIdRequest serialization failed!')
    console.assert(this.OrderCloseAllByIdRequestModel.verify(), 'manager.OrderCloseAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByRequest value
   * @this {!Sender}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByRequest serialization failed!')
    console.assert(this.OrderCloseByRequestModel.verify(), 'manager.OrderCloseByRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByResponse value
   * @this {!Sender}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByResponse serialization failed!')
    console.assert(this.OrderCloseByResponseModel.verify(), 'manager.OrderCloseByResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllRequest value
   * @this {!Sender}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByAllRequest serialization failed!')
    console.assert(this.OrderCloseByAllRequestModel.verify(), 'manager.OrderCloseByAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllResponse value
   * @this {!Sender}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByAllResponse serialization failed!')
    console.assert(this.OrderCloseByAllResponseModel.verify(), 'manager.OrderCloseByAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseRequest value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseRequestModel.verify(), 'manager.OrderCancelOrCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseResponse value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseResponseModel.verify(), 'manager.OrderCancelOrCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllRequest value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseAllRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseAllRequestModel.verify(), 'manager.OrderCancelOrCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllResponse value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseAllResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseAllResponseModel.verify(), 'manager.OrderCancelOrCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateNotify value
   * @this {!Sender}
   * @param {!OrderUpdateNotify} value OrderUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderUpdateNotify serialization failed!')
    console.assert(this.OrderUpdateNotifyModel.verify(), 'manager.OrderUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionGetRequest value
   * @this {!Sender}
   * @param {!PositionGetRequest} value PositionGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionGetRequest serialization failed!')
    console.assert(this.PositionGetRequestModel.verify(), 'manager.PositionGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionGetResponse value
   * @this {!Sender}
   * @param {!PositionGetResponse} value PositionGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionGetResponse serialization failed!')
    console.assert(this.PositionGetResponseModel.verify(), 'manager.PositionGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetRequest value
   * @this {!Sender}
   * @param {!PositionsGetRequest} value PositionsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetRequest serialization failed!')
    console.assert(this.PositionsGetRequestModel.verify(), 'manager.PositionsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetResponse value
   * @this {!Sender}
   * @param {!PositionsGetResponse} value PositionsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetResponse serialization failed!')
    console.assert(this.PositionsGetResponseModel.verify(), 'manager.PositionsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllRequest value
   * @this {!Sender}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetAllRequest serialization failed!')
    console.assert(this.PositionsGetAllRequestModel.verify(), 'manager.PositionsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllResponse value
   * @this {!Sender}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetAllResponse serialization failed!')
    console.assert(this.PositionsGetAllResponseModel.verify(), 'manager.PositionsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdateNotify value
   * @this {!Sender}
   * @param {!PositionUpdateNotify} value PositionUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionUpdateNotify serialization failed!')
    console.assert(this.PositionUpdateNotifyModel.verify(), 'manager.PositionUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryRequest value
   * @this {!Sender}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryRequest serialization failed!')
    console.assert(this.TradeHistoryRequestModel.verify(), 'manager.TradeHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryResponse value
   * @this {!Sender}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryResponse serialization failed!')
    console.assert(this.TradeHistoryResponseModel.verify(), 'manager.TradeHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryFilterRequest value
   * @this {!Sender}
   * @param {!TradeHistoryFilterRequest} value TradeHistoryFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryFilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryFilterRequest serialization failed!')
    console.assert(this.TradeHistoryFilterRequestModel.verify(), 'manager.TradeHistoryFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdRequest value
   * @this {!Sender}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryByIdRequest serialization failed!')
    console.assert(this.TradeHistoryByIdRequestModel.verify(), 'manager.TradeHistoryByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdResponse value
   * @this {!Sender}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryByIdResponse serialization failed!')
    console.assert(this.TradeHistoryByIdResponseModel.verify(), 'manager.TradeHistoryByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryUpdateNotify value
   * @this {!Sender}
   * @param {!TradeHistoryUpdateNotify} value TradeHistoryUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryUpdateNotify serialization failed!')
    console.assert(this.TradeHistoryUpdateNotifyModel.verify(), 'manager.TradeHistoryUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TestRolloverDumpRequest value
   * @this {!Sender}
   * @param {!TestRolloverDumpRequest} value TestRolloverDumpRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TestRolloverDumpRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TestRolloverDumpRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TestRolloverDumpRequest serialization failed!')
    console.assert(this.TestRolloverDumpRequestModel.verify(), 'manager.TestRolloverDumpRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TestRolloverDumpResponse value
   * @this {!Sender}
   * @param {!TestRolloverDumpResponse} value TestRolloverDumpResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TestRolloverDumpResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TestRolloverDumpResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TestRolloverDumpResponse serialization failed!')
    console.assert(this.TestRolloverDumpResponseModel.verify(), 'manager.TestRolloverDumpResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated001 value
   * @this {!Sender}
   * @param {!Deprecated001} value Deprecated001 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated001 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated001Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated001 serialization failed!')
    console.assert(this.Deprecated001Model.verify(), 'manager.Deprecated001 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated002 value
   * @this {!Sender}
   * @param {!Deprecated002} value Deprecated002 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated002 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated002Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated002 serialization failed!')
    console.assert(this.Deprecated002Model.verify(), 'manager.Deprecated002 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated003 value
   * @this {!Sender}
   * @param {!Deprecated003} value Deprecated003 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated003 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated003Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated003 serialization failed!')
    console.assert(this.Deprecated003Model.verify(), 'manager.Deprecated003 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated004 value
   * @this {!Sender}
   * @param {!Deprecated004} value Deprecated004 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated004 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated004Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated004 serialization failed!')
    console.assert(this.Deprecated004Model.verify(), 'manager.Deprecated004 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated005 value
   * @this {!Sender}
   * @param {!Deprecated005} value Deprecated005 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated005 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated005Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated005 serialization failed!')
    console.assert(this.Deprecated005Model.verify(), 'manager.Deprecated005 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated006 value
   * @this {!Sender}
   * @param {!Deprecated006} value Deprecated006 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated006 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated006Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated006 serialization failed!')
    console.assert(this.Deprecated006Model.verify(), 'manager.Deprecated006 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated007 value
   * @this {!Sender}
   * @param {!Deprecated007} value Deprecated007 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated007 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated007Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated007 serialization failed!')
    console.assert(this.Deprecated007Model.verify(), 'manager.Deprecated007 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated008 value
   * @this {!Sender}
   * @param {!Deprecated008} value Deprecated008 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated008 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated008Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated008 serialization failed!')
    console.assert(this.Deprecated008Model.verify(), 'manager.Deprecated008 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated009 value
   * @this {!Sender}
   * @param {!Deprecated009} value Deprecated009 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated009 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated009Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated009 serialization failed!')
    console.assert(this.Deprecated009Model.verify(), 'manager.Deprecated009 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated010 value
   * @this {!Sender}
   * @param {!Deprecated010} value Deprecated010 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated010 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated010Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated010 serialization failed!')
    console.assert(this.Deprecated010Model.verify(), 'manager.Deprecated010 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated011 value
   * @this {!Sender}
   * @param {!Deprecated011} value Deprecated011 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated011 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated011Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated011 serialization failed!')
    console.assert(this.Deprecated011Model.verify(), 'manager.Deprecated011 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated012 value
   * @this {!Sender}
   * @param {!Deprecated012} value Deprecated012 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated012 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated012Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated012 serialization failed!')
    console.assert(this.Deprecated012Model.verify(), 'manager.Deprecated012 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated013 value
   * @this {!Sender}
   * @param {!Deprecated013} value Deprecated013 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated013 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated013Model.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated013 serialization failed!')
    console.assert(this.Deprecated013Model.verify(), 'manager.Deprecated013 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositAccountRequest value
   * @this {!Sender}
   * @param {!DepositAccountRequest} value DepositAccountRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DepositAccountRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositAccountRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.DepositAccountRequest serialization failed!')
    console.assert(this.DepositAccountRequestModel.verify(), 'manager.DepositAccountRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositAccountResponse value
   * @this {!Sender}
   * @param {!DepositAccountResponse} value DepositAccountResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DepositAccountResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositAccountResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.DepositAccountResponse serialization failed!')
    console.assert(this.DepositAccountResponseModel.verify(), 'manager.DepositAccountResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CreditAccountRequest value
   * @this {!Sender}
   * @param {!CreditAccountRequest} value CreditAccountRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CreditAccountRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CreditAccountRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.CreditAccountRequest serialization failed!')
    console.assert(this.CreditAccountRequestModel.verify(), 'manager.CreditAccountRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CreditAccountResponse value
   * @this {!Sender}
   * @param {!CreditAccountResponse} value CreditAccountResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CreditAccountResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CreditAccountResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.CreditAccountResponse serialization failed!')
    console.assert(this.CreditAccountResponseModel.verify(), 'manager.CreditAccountResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TransferMoneyRequest value
   * @this {!Sender}
   * @param {!TransferMoneyRequest} value TransferMoneyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TransferMoneyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TransferMoneyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TransferMoneyRequest serialization failed!')
    console.assert(this.TransferMoneyRequestModel.verify(), 'manager.TransferMoneyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TransferMoneyResponse value
   * @this {!Sender}
   * @param {!TransferMoneyResponse} value TransferMoneyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TransferMoneyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TransferMoneyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TransferMoneyResponse serialization failed!')
    console.assert(this.TransferMoneyResponseModel.verify(), 'manager.TransferMoneyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleGetRequest value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleGetRequest} value TradingCalendarRegularRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleGetRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleGetRequestModel.verify(), 'manager.TradingCalendarRegularRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleGetResponse value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleGetResponse} value TradingCalendarRegularRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleGetResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleGetResponseModel.verify(), 'manager.TradingCalendarRegularRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRulesGetAllRequest value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRulesGetAllRequest} value TradingCalendarRegularRulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRulesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRulesGetAllRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRulesGetAllRequestModel.verify(), 'manager.TradingCalendarRegularRulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRulesGetAllResponse value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRulesGetAllResponse} value TradingCalendarRegularRulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRulesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRulesGetAllResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRulesGetAllResponseModel.verify(), 'manager.TradingCalendarRegularRulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleCreateRequest value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleCreateRequest} value TradingCalendarRegularRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleCreateRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleCreateRequestModel.verify(), 'manager.TradingCalendarRegularRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleCreateResponse value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleCreateResponse} value TradingCalendarRegularRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleCreateResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleCreateResponseModel.verify(), 'manager.TradingCalendarRegularRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleModifyRequest value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleModifyRequest} value TradingCalendarRegularRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleModifyRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleModifyRequestModel.verify(), 'manager.TradingCalendarRegularRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleModifyResponse value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleModifyResponse} value TradingCalendarRegularRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleModifyResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleModifyResponseModel.verify(), 'manager.TradingCalendarRegularRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleDeleteRequest value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleDeleteRequest} value TradingCalendarRegularRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleDeleteRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleDeleteRequestModel.verify(), 'manager.TradingCalendarRegularRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleDeleteResponse value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleDeleteResponse} value TradingCalendarRegularRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleDeleteResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleDeleteResponseModel.verify(), 'manager.TradingCalendarRegularRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleUpdateNotify value
   * @this {!Sender}
   * @param {!TradingCalendarRegularRuleUpdateNotify} value TradingCalendarRegularRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleUpdateNotify serialization failed!')
    console.assert(this.TradingCalendarRegularRuleUpdateNotifyModel.verify(), 'manager.TradingCalendarRegularRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleGetRequest value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleGetRequest} value TradingCalendarOffTimeRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleGetRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleGetRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleGetResponse value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleGetResponse} value TradingCalendarOffTimeRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleGetResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleGetResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRulesGetAllRequest value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRulesGetAllRequest} value TradingCalendarOffTimeRulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRulesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRulesGetAllRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRulesGetAllRequestModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRulesGetAllResponse value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRulesGetAllResponse} value TradingCalendarOffTimeRulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRulesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRulesGetAllResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRulesGetAllResponseModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleCreateRequest value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} value TradingCalendarOffTimeRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleCreateRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleCreateRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleCreateResponse value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleCreateResponse} value TradingCalendarOffTimeRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleCreateResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleCreateResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleModifyRequest value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} value TradingCalendarOffTimeRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleModifyRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleModifyRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleModifyResponse value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleModifyResponse} value TradingCalendarOffTimeRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleModifyResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleModifyResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleDeleteRequest value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleDeleteRequest} value TradingCalendarOffTimeRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleDeleteRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleDeleteRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleDeleteResponse value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleDeleteResponse} value TradingCalendarOffTimeRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleDeleteResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleDeleteResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleUpdateNotify value
   * @this {!Sender}
   * @param {!TradingCalendarOffTimeRuleUpdateNotify} value TradingCalendarOffTimeRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleUpdateNotify serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleUpdateNotifyModel.verify(), 'manager.TradingCalendarOffTimeRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRulesGetRequest value
   * @this {!Sender}
   * @param {!TradingCalendarRulesGetRequest} value TradingCalendarRulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRulesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRulesGetRequest serialization failed!')
    console.assert(this.TradingCalendarRulesGetRequestModel.verify(), 'manager.TradingCalendarRulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRulesGetResponse value
   * @this {!Sender}
   * @param {!TradingCalendarRulesGetResponse} value TradingCalendarRulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRulesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRulesGetResponse serialization failed!')
    console.assert(this.TradingCalendarRulesGetResponseModel.verify(), 'manager.TradingCalendarRulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRulesGetRequest value
   * @this {!Sender}
   * @param {!TradingRouteRulesGetRequest} value TradingRouteRulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRulesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRulesGetRequest serialization failed!')
    console.assert(this.TradingRouteRulesGetRequestModel.verify(), 'manager.TradingRouteRulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRulesGetResponse value
   * @this {!Sender}
   * @param {!TradingRouteRulesGetResponse} value TradingRouteRulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRulesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRulesGetResponse serialization failed!')
    console.assert(this.TradingRouteRulesGetResponseModel.verify(), 'manager.TradingRouteRulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleCreateRequest value
   * @this {!Sender}
   * @param {!TradingRouteRuleCreateRequest} value TradingRouteRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleCreateRequest serialization failed!')
    console.assert(this.TradingRouteRuleCreateRequestModel.verify(), 'manager.TradingRouteRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleCreateResponse value
   * @this {!Sender}
   * @param {!TradingRouteRuleCreateResponse} value TradingRouteRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleCreateResponse serialization failed!')
    console.assert(this.TradingRouteRuleCreateResponseModel.verify(), 'manager.TradingRouteRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleModifyRequest value
   * @this {!Sender}
   * @param {!TradingRouteRuleModifyRequest} value TradingRouteRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleModifyRequest serialization failed!')
    console.assert(this.TradingRouteRuleModifyRequestModel.verify(), 'manager.TradingRouteRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleModifyResponse value
   * @this {!Sender}
   * @param {!TradingRouteRuleModifyResponse} value TradingRouteRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleModifyResponse serialization failed!')
    console.assert(this.TradingRouteRuleModifyResponseModel.verify(), 'manager.TradingRouteRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleDeleteRequest value
   * @this {!Sender}
   * @param {!TradingRouteRuleDeleteRequest} value TradingRouteRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleDeleteRequest serialization failed!')
    console.assert(this.TradingRouteRuleDeleteRequestModel.verify(), 'manager.TradingRouteRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleDeleteResponse value
   * @this {!Sender}
   * @param {!TradingRouteRuleDeleteResponse} value TradingRouteRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleDeleteResponse serialization failed!')
    console.assert(this.TradingRouteRuleDeleteResponseModel.verify(), 'manager.TradingRouteRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleUpdateNotify value
   * @this {!Sender}
   * @param {!TradingRouteRuleUpdateNotify} value TradingRouteRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleUpdateNotify serialization failed!')
    console.assert(this.TradingRouteRuleUpdateNotifyModel.verify(), 'manager.TradingRouteRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapsGetRequest value
   * @this {!Sender}
   * @param {!SymbolMapsGetRequest} value SymbolMapsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapsGetRequest serialization failed!')
    console.assert(this.SymbolMapsGetRequestModel.verify(), 'manager.SymbolMapsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapsGetResponse value
   * @this {!Sender}
   * @param {!SymbolMapsGetResponse} value SymbolMapsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapsGetResponse serialization failed!')
    console.assert(this.SymbolMapsGetResponseModel.verify(), 'manager.SymbolMapsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapCreateRequest value
   * @this {!Sender}
   * @param {!SymbolMapCreateRequest} value SymbolMapCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapCreateRequest serialization failed!')
    console.assert(this.SymbolMapCreateRequestModel.verify(), 'manager.SymbolMapCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapCreateResponse value
   * @this {!Sender}
   * @param {!SymbolMapCreateResponse} value SymbolMapCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapCreateResponse serialization failed!')
    console.assert(this.SymbolMapCreateResponseModel.verify(), 'manager.SymbolMapCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapModifyRequest value
   * @this {!Sender}
   * @param {!SymbolMapModifyRequest} value SymbolMapModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapModifyRequest serialization failed!')
    console.assert(this.SymbolMapModifyRequestModel.verify(), 'manager.SymbolMapModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapModifyResponse value
   * @this {!Sender}
   * @param {!SymbolMapModifyResponse} value SymbolMapModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapModifyResponse serialization failed!')
    console.assert(this.SymbolMapModifyResponseModel.verify(), 'manager.SymbolMapModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapDeleteRequest value
   * @this {!Sender}
   * @param {!SymbolMapDeleteRequest} value SymbolMapDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapDeleteRequest serialization failed!')
    console.assert(this.SymbolMapDeleteRequestModel.verify(), 'manager.SymbolMapDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapDeleteResponse value
   * @this {!Sender}
   * @param {!SymbolMapDeleteResponse} value SymbolMapDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapDeleteResponse serialization failed!')
    console.assert(this.SymbolMapDeleteResponseModel.verify(), 'manager.SymbolMapDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolMapUpdateNotify} value SymbolMapUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapUpdateNotify serialization failed!')
    console.assert(this.SymbolMapUpdateNotifyModel.verify(), 'manager.SymbolMapUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewaysGetRequest value
   * @this {!Sender}
   * @param {!GatewaysGetRequest} value GatewaysGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewaysGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewaysGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewaysGetRequest serialization failed!')
    console.assert(this.GatewaysGetRequestModel.verify(), 'manager.GatewaysGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewaysGetResponse value
   * @this {!Sender}
   * @param {!GatewaysGetResponse} value GatewaysGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewaysGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewaysGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewaysGetResponse serialization failed!')
    console.assert(this.GatewaysGetResponseModel.verify(), 'manager.GatewaysGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayCreateRequest value
   * @this {!Sender}
   * @param {!GatewayCreateRequest} value GatewayCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayCreateRequest serialization failed!')
    console.assert(this.GatewayCreateRequestModel.verify(), 'manager.GatewayCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayCreateResponse value
   * @this {!Sender}
   * @param {!GatewayCreateResponse} value GatewayCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayCreateResponse serialization failed!')
    console.assert(this.GatewayCreateResponseModel.verify(), 'manager.GatewayCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModifyRequest value
   * @this {!Sender}
   * @param {!GatewayModifyRequest} value GatewayModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayModifyRequest serialization failed!')
    console.assert(this.GatewayModifyRequestModel.verify(), 'manager.GatewayModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModifyResponse value
   * @this {!Sender}
   * @param {!GatewayModifyResponse} value GatewayModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayModifyResponse serialization failed!')
    console.assert(this.GatewayModifyResponseModel.verify(), 'manager.GatewayModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayDeleteRequest value
   * @this {!Sender}
   * @param {!GatewayDeleteRequest} value GatewayDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayDeleteRequest serialization failed!')
    console.assert(this.GatewayDeleteRequestModel.verify(), 'manager.GatewayDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayDeleteResponse value
   * @this {!Sender}
   * @param {!GatewayDeleteResponse} value GatewayDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayDeleteResponse serialization failed!')
    console.assert(this.GatewayDeleteResponseModel.verify(), 'manager.GatewayDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayUpdateNotify value
   * @this {!Sender}
   * @param {!GatewayUpdateNotify} value GatewayUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayUpdateNotify serialization failed!')
    console.assert(this.GatewayUpdateNotifyModel.verify(), 'manager.GatewayUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedsGetRequest value
   * @this {!Sender}
   * @param {!DataFeedsGetRequest} value DataFeedsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedsGetRequest serialization failed!')
    console.assert(this.DataFeedsGetRequestModel.verify(), 'manager.DataFeedsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedsGetResponse value
   * @this {!Sender}
   * @param {!DataFeedsGetResponse} value DataFeedsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedsGetResponse serialization failed!')
    console.assert(this.DataFeedsGetResponseModel.verify(), 'manager.DataFeedsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedCreateRequest value
   * @this {!Sender}
   * @param {!DataFeedCreateRequest} value DataFeedCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedCreateRequest serialization failed!')
    console.assert(this.DataFeedCreateRequestModel.verify(), 'manager.DataFeedCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedCreateResponse value
   * @this {!Sender}
   * @param {!DataFeedCreateResponse} value DataFeedCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedCreateResponse serialization failed!')
    console.assert(this.DataFeedCreateResponseModel.verify(), 'manager.DataFeedCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModifyRequest value
   * @this {!Sender}
   * @param {!DataFeedModifyRequest} value DataFeedModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedModifyRequest serialization failed!')
    console.assert(this.DataFeedModifyRequestModel.verify(), 'manager.DataFeedModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModifyResponse value
   * @this {!Sender}
   * @param {!DataFeedModifyResponse} value DataFeedModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedModifyResponse serialization failed!')
    console.assert(this.DataFeedModifyResponseModel.verify(), 'manager.DataFeedModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedDeleteRequest value
   * @this {!Sender}
   * @param {!DataFeedDeleteRequest} value DataFeedDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedDeleteRequest serialization failed!')
    console.assert(this.DataFeedDeleteRequestModel.verify(), 'manager.DataFeedDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedDeleteResponse value
   * @this {!Sender}
   * @param {!DataFeedDeleteResponse} value DataFeedDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedDeleteResponse serialization failed!')
    console.assert(this.DataFeedDeleteResponseModel.verify(), 'manager.DataFeedDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedUpdateNotify value
   * @this {!Sender}
   * @param {!DataFeedUpdateNotify} value DataFeedUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedUpdateNotify serialization failed!')
    console.assert(this.DataFeedUpdateNotifyModel.verify(), 'manager.DataFeedUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleSystemInfoNotify value
   * @this {!Sender}
   * @param {!ModuleSystemInfoNotify} value ModuleSystemInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleSystemInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleSystemInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleSystemInfoNotify serialization failed!')
    console.assert(this.ModuleSystemInfoNotifyModel.verify(), 'manager.ModuleSystemInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesSystemInfoGetAllRequest value
   * @this {!Sender}
   * @param {!ModulesSystemInfoGetAllRequest} value ModulesSystemInfoGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesSystemInfoGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesSystemInfoGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesSystemInfoGetAllRequest serialization failed!')
    console.assert(this.ModulesSystemInfoGetAllRequestModel.verify(), 'manager.ModulesSystemInfoGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesSystemInfoGetAllResponse value
   * @this {!Sender}
   * @param {!ModulesSystemInfoGetAllResponse} value ModulesSystemInfoGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesSystemInfoGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesSystemInfoGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesSystemInfoGetAllResponse serialization failed!')
    console.assert(this.ModulesSystemInfoGetAllResponseModel.verify(), 'manager.ModulesSystemInfoGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesSystemInfoNotify value
   * @this {!Sender}
   * @param {!ModulesSystemInfoNotify} value ModulesSystemInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesSystemInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesSystemInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesSystemInfoNotify serialization failed!')
    console.assert(this.ModulesSystemInfoNotifyModel.verify(), 'manager.ModulesSystemInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyRequest value
   * @this {!Sender}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarHistoryModifyRequest serialization failed!')
    console.assert(this.ChartBarHistoryModifyRequestModel.verify(), 'manager.ChartBarHistoryModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyResponse value
   * @this {!Sender}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarHistoryModifyResponse serialization failed!')
    console.assert(this.ChartBarHistoryModifyResponseModel.verify(), 'manager.ChartBarHistoryModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickSnapshotRequest value
   * @this {!Sender}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickSnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickSnapshotRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickSnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateTickSnapshotRequestModel.verify(), 'manager.FeedUpdateTickSnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickSnapshotResponse value
   * @this {!Sender}
   * @param {!FeedUpdateTickSnapshotResponse} value FeedUpdateTickSnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickSnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickSnapshotResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickSnapshotResponse serialization failed!')
    console.assert(this.FeedUpdateTickSnapshotResponseModel.verify(), 'manager.FeedUpdateTickSnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickRequest value
   * @this {!Sender}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickRequest serialization failed!')
    console.assert(this.FeedUpdateTickRequestModel.verify(), 'manager.FeedUpdateTickRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickResponse value
   * @this {!Sender}
   * @param {!FeedUpdateTickResponse} value FeedUpdateTickResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickResponse serialization failed!')
    console.assert(this.FeedUpdateTickResponseModel.verify(), 'manager.FeedUpdateTickResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotRequest value
   * @this {!Sender}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2SnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotRequestModel.verify(), 'manager.FeedUpdateLevel2SnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotResponse value
   * @this {!Sender}
   * @param {!FeedUpdateLevel2SnapshotResponse} value FeedUpdateLevel2SnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2SnapshotResponse serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotResponseModel.verify(), 'manager.FeedUpdateLevel2SnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2Request value
   * @this {!Sender}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2RequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2Request serialization failed!')
    console.assert(this.FeedUpdateLevel2RequestModel.verify(), 'manager.FeedUpdateLevel2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2Response value
   * @this {!Sender}
   * @param {!FeedUpdateLevel2Response} value FeedUpdateLevel2Response value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2Response serialization failed!')
    console.assert(this.FeedUpdateLevel2ResponseModel.verify(), 'manager.FeedUpdateLevel2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerGetRequest value
   * @this {!Sender}
   * @param {!MailServerGetRequest} value MailServerGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerGetRequest serialization failed!')
    console.assert(this.MailServerGetRequestModel.verify(), 'manager.MailServerGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerGetResponse value
   * @this {!Sender}
   * @param {!MailServerGetResponse} value MailServerGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerGetResponse serialization failed!')
    console.assert(this.MailServerGetResponseModel.verify(), 'manager.MailServerGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServersGetAllRequest value
   * @this {!Sender}
   * @param {!MailServersGetAllRequest} value MailServersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServersGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServersGetAllRequest serialization failed!')
    console.assert(this.MailServersGetAllRequestModel.verify(), 'manager.MailServersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServersGetAllResponse value
   * @this {!Sender}
   * @param {!MailServersGetAllResponse} value MailServersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServersGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServersGetAllResponse serialization failed!')
    console.assert(this.MailServersGetAllResponseModel.verify(), 'manager.MailServersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerCreateRequest value
   * @this {!Sender}
   * @param {!MailServerCreateRequest} value MailServerCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerCreateRequest serialization failed!')
    console.assert(this.MailServerCreateRequestModel.verify(), 'manager.MailServerCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerCreateResponse value
   * @this {!Sender}
   * @param {!MailServerCreateResponse} value MailServerCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerCreateResponse serialization failed!')
    console.assert(this.MailServerCreateResponseModel.verify(), 'manager.MailServerCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerModifyRequest value
   * @this {!Sender}
   * @param {!MailServerModifyRequest} value MailServerModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerModifyRequest serialization failed!')
    console.assert(this.MailServerModifyRequestModel.verify(), 'manager.MailServerModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerModifyResponse value
   * @this {!Sender}
   * @param {!MailServerModifyResponse} value MailServerModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerModifyResponse serialization failed!')
    console.assert(this.MailServerModifyResponseModel.verify(), 'manager.MailServerModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerDeleteRequest value
   * @this {!Sender}
   * @param {!MailServerDeleteRequest} value MailServerDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerDeleteRequest serialization failed!')
    console.assert(this.MailServerDeleteRequestModel.verify(), 'manager.MailServerDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerDeleteResponse value
   * @this {!Sender}
   * @param {!MailServerDeleteResponse} value MailServerDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerDeleteResponse serialization failed!')
    console.assert(this.MailServerDeleteResponseModel.verify(), 'manager.MailServerDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerUpdateNotify value
   * @this {!Sender}
   * @param {!MailServerUpdateNotify} value MailServerUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerUpdateNotify serialization failed!')
    console.assert(this.MailServerUpdateNotifyModel.verify(), 'manager.MailServerUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountStatesUpdateNotify value
   * @this {!Sender}
   * @param {!AccountStatesUpdateNotify} value AccountStatesUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountStatesUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountStatesUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountStatesUpdateNotify serialization failed!')
    console.assert(this.AccountStatesUpdateNotifyModel.verify(), 'manager.AccountStatesUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountStatesGetAllRequest value
   * @this {!Sender}
   * @param {!AccountStatesGetAllRequest} value AccountStatesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountStatesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountStatesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountStatesGetAllRequest serialization failed!')
    console.assert(this.AccountStatesGetAllRequestModel.verify(), 'manager.AccountStatesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountStatesGetAllResponse value
   * @this {!Sender}
   * @param {!AccountStatesGetAllResponse} value AccountStatesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountStatesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountStatesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountStatesGetAllResponse serialization failed!')
    console.assert(this.AccountStatesGetAllResponseModel.verify(), 'manager.AccountStatesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleAuthRequest value
   * @this {!Sender}
   * @param {!ModuleAuthRequest} value ModuleAuthRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleAuthRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleAuthRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleAuthRequest serialization failed!')
    console.assert(this.ModuleAuthRequestModel.verify(), 'manager.ModuleAuthRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleAuthResponse value
   * @this {!Sender}
   * @param {!ModuleAuthResponse} value ModuleAuthResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleAuthResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleAuthResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleAuthResponse serialization failed!')
    console.assert(this.ModuleAuthResponseModel.verify(), 'manager.ModuleAuthResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileGetRequest value
   * @this {!Sender}
   * @param {!LeverageProfileGetRequest} value LeverageProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileGetRequest serialization failed!')
    console.assert(this.LeverageProfileGetRequestModel.verify(), 'manager.LeverageProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileGetResponse value
   * @this {!Sender}
   * @param {!LeverageProfileGetResponse} value LeverageProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileGetResponse serialization failed!')
    console.assert(this.LeverageProfileGetResponseModel.verify(), 'manager.LeverageProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfilesGetAllRequest value
   * @this {!Sender}
   * @param {!LeverageProfilesGetAllRequest} value LeverageProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfilesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfilesGetAllRequest serialization failed!')
    console.assert(this.LeverageProfilesGetAllRequestModel.verify(), 'manager.LeverageProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfilesGetAllResponse value
   * @this {!Sender}
   * @param {!LeverageProfilesGetAllResponse} value LeverageProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfilesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfilesGetAllResponse serialization failed!')
    console.assert(this.LeverageProfilesGetAllResponseModel.verify(), 'manager.LeverageProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileCreateRequest value
   * @this {!Sender}
   * @param {!LeverageProfileCreateRequest} value LeverageProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileCreateRequest serialization failed!')
    console.assert(this.LeverageProfileCreateRequestModel.verify(), 'manager.LeverageProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileCreateResponse value
   * @this {!Sender}
   * @param {!LeverageProfileCreateResponse} value LeverageProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileCreateResponse serialization failed!')
    console.assert(this.LeverageProfileCreateResponseModel.verify(), 'manager.LeverageProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileModifyRequest value
   * @this {!Sender}
   * @param {!LeverageProfileModifyRequest} value LeverageProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileModifyRequest serialization failed!')
    console.assert(this.LeverageProfileModifyRequestModel.verify(), 'manager.LeverageProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileModifyResponse value
   * @this {!Sender}
   * @param {!LeverageProfileModifyResponse} value LeverageProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileModifyResponse serialization failed!')
    console.assert(this.LeverageProfileModifyResponseModel.verify(), 'manager.LeverageProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileDeleteRequest value
   * @this {!Sender}
   * @param {!LeverageProfileDeleteRequest} value LeverageProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileDeleteRequest serialization failed!')
    console.assert(this.LeverageProfileDeleteRequestModel.verify(), 'manager.LeverageProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileDeleteResponse value
   * @this {!Sender}
   * @param {!LeverageProfileDeleteResponse} value LeverageProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileDeleteResponse serialization failed!')
    console.assert(this.LeverageProfileDeleteResponseModel.verify(), 'manager.LeverageProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileUpdateNotify value
   * @this {!Sender}
   * @param {!LeverageProfileUpdateNotify} value LeverageProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileUpdateNotify serialization failed!')
    console.assert(this.LeverageProfileUpdateNotifyModel.verify(), 'manager.LeverageProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileGetRequest value
   * @this {!Sender}
   * @param {!MarginRateProfileGetRequest} value MarginRateProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileGetRequest serialization failed!')
    console.assert(this.MarginRateProfileGetRequestModel.verify(), 'manager.MarginRateProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileGetResponse value
   * @this {!Sender}
   * @param {!MarginRateProfileGetResponse} value MarginRateProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileGetResponse serialization failed!')
    console.assert(this.MarginRateProfileGetResponseModel.verify(), 'manager.MarginRateProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfilesGetAllRequest value
   * @this {!Sender}
   * @param {!MarginRateProfilesGetAllRequest} value MarginRateProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfilesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfilesGetAllRequest serialization failed!')
    console.assert(this.MarginRateProfilesGetAllRequestModel.verify(), 'manager.MarginRateProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfilesGetAllResponse value
   * @this {!Sender}
   * @param {!MarginRateProfilesGetAllResponse} value MarginRateProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfilesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfilesGetAllResponse serialization failed!')
    console.assert(this.MarginRateProfilesGetAllResponseModel.verify(), 'manager.MarginRateProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileCreateRequest value
   * @this {!Sender}
   * @param {!MarginRateProfileCreateRequest} value MarginRateProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileCreateRequest serialization failed!')
    console.assert(this.MarginRateProfileCreateRequestModel.verify(), 'manager.MarginRateProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileCreateResponse value
   * @this {!Sender}
   * @param {!MarginRateProfileCreateResponse} value MarginRateProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileCreateResponse serialization failed!')
    console.assert(this.MarginRateProfileCreateResponseModel.verify(), 'manager.MarginRateProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileModifyRequest value
   * @this {!Sender}
   * @param {!MarginRateProfileModifyRequest} value MarginRateProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileModifyRequest serialization failed!')
    console.assert(this.MarginRateProfileModifyRequestModel.verify(), 'manager.MarginRateProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileModifyResponse value
   * @this {!Sender}
   * @param {!MarginRateProfileModifyResponse} value MarginRateProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileModifyResponse serialization failed!')
    console.assert(this.MarginRateProfileModifyResponseModel.verify(), 'manager.MarginRateProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileDeleteRequest value
   * @this {!Sender}
   * @param {!MarginRateProfileDeleteRequest} value MarginRateProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileDeleteRequest serialization failed!')
    console.assert(this.MarginRateProfileDeleteRequestModel.verify(), 'manager.MarginRateProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileDeleteResponse value
   * @this {!Sender}
   * @param {!MarginRateProfileDeleteResponse} value MarginRateProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileDeleteResponse serialization failed!')
    console.assert(this.MarginRateProfileDeleteResponseModel.verify(), 'manager.MarginRateProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileUpdateNotify value
   * @this {!Sender}
   * @param {!MarginRateProfileUpdateNotify} value MarginRateProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileUpdateNotify serialization failed!')
    console.assert(this.MarginRateProfileUpdateNotifyModel.verify(), 'manager.MarginRateProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileGetRequest value
   * @this {!Sender}
   * @param {!NewsProfileGetRequest} value NewsProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileGetRequest serialization failed!')
    console.assert(this.NewsProfileGetRequestModel.verify(), 'manager.NewsProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileGetResponse value
   * @this {!Sender}
   * @param {!NewsProfileGetResponse} value NewsProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileGetResponse serialization failed!')
    console.assert(this.NewsProfileGetResponseModel.verify(), 'manager.NewsProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfilesGetAllRequest value
   * @this {!Sender}
   * @param {!NewsProfilesGetAllRequest} value NewsProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfilesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfilesGetAllRequest serialization failed!')
    console.assert(this.NewsProfilesGetAllRequestModel.verify(), 'manager.NewsProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfilesGetAllResponse value
   * @this {!Sender}
   * @param {!NewsProfilesGetAllResponse} value NewsProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfilesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfilesGetAllResponse serialization failed!')
    console.assert(this.NewsProfilesGetAllResponseModel.verify(), 'manager.NewsProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileCreateRequest value
   * @this {!Sender}
   * @param {!NewsProfileCreateRequest} value NewsProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileCreateRequest serialization failed!')
    console.assert(this.NewsProfileCreateRequestModel.verify(), 'manager.NewsProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileCreateResponse value
   * @this {!Sender}
   * @param {!NewsProfileCreateResponse} value NewsProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileCreateResponse serialization failed!')
    console.assert(this.NewsProfileCreateResponseModel.verify(), 'manager.NewsProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileModifyRequest value
   * @this {!Sender}
   * @param {!NewsProfileModifyRequest} value NewsProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileModifyRequest serialization failed!')
    console.assert(this.NewsProfileModifyRequestModel.verify(), 'manager.NewsProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileModifyResponse value
   * @this {!Sender}
   * @param {!NewsProfileModifyResponse} value NewsProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileModifyResponse serialization failed!')
    console.assert(this.NewsProfileModifyResponseModel.verify(), 'manager.NewsProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileDeleteRequest value
   * @this {!Sender}
   * @param {!NewsProfileDeleteRequest} value NewsProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileDeleteRequest serialization failed!')
    console.assert(this.NewsProfileDeleteRequestModel.verify(), 'manager.NewsProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileDeleteResponse value
   * @this {!Sender}
   * @param {!NewsProfileDeleteResponse} value NewsProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileDeleteResponse serialization failed!')
    console.assert(this.NewsProfileDeleteResponseModel.verify(), 'manager.NewsProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileUpdateNotify value
   * @this {!Sender}
   * @param {!NewsProfileUpdateNotify} value NewsProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileUpdateNotify serialization failed!')
    console.assert(this.NewsProfileUpdateNotifyModel.verify(), 'manager.NewsProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileImportRequest value
   * @this {!Sender}
   * @param {!NewsProfileImportRequest} value NewsProfileImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileImportRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileImportRequest serialization failed!')
    console.assert(this.NewsProfileImportRequestModel.verify(), 'manager.NewsProfileImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileImportResponse value
   * @this {!Sender}
   * @param {!NewsProfileImportResponse} value NewsProfileImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileImportResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileImportResponse serialization failed!')
    console.assert(this.NewsProfileImportResponseModel.verify(), 'manager.NewsProfileImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryRequest value
   * @this {!Sender}
   * @param {!TickHistoryRequest} value TickHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TickHistoryRequest serialization failed!')
    console.assert(this.TickHistoryRequestModel.verify(), 'manager.TickHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryResponse value
   * @this {!Sender}
   * @param {!TickHistoryResponse} value TickHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TickHistoryResponse serialization failed!')
    console.assert(this.TickHistoryResponseModel.verify(), 'manager.TickHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateGetRequest value
   * @this {!Sender}
   * @param {!EmailTemplateGetRequest} value EmailTemplateGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateGetRequest serialization failed!')
    console.assert(this.EmailTemplateGetRequestModel.verify(), 'manager.EmailTemplateGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateGetResponse value
   * @this {!Sender}
   * @param {!EmailTemplateGetResponse} value EmailTemplateGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateGetResponse serialization failed!')
    console.assert(this.EmailTemplateGetResponseModel.verify(), 'manager.EmailTemplateGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplatesGetAllRequest value
   * @this {!Sender}
   * @param {!EmailTemplatesGetAllRequest} value EmailTemplatesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplatesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplatesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplatesGetAllRequest serialization failed!')
    console.assert(this.EmailTemplatesGetAllRequestModel.verify(), 'manager.EmailTemplatesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplatesGetAllResponse value
   * @this {!Sender}
   * @param {!EmailTemplatesGetAllResponse} value EmailTemplatesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplatesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplatesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplatesGetAllResponse serialization failed!')
    console.assert(this.EmailTemplatesGetAllResponseModel.verify(), 'manager.EmailTemplatesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateCreateRequest value
   * @this {!Sender}
   * @param {!EmailTemplateCreateRequest} value EmailTemplateCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateCreateRequest serialization failed!')
    console.assert(this.EmailTemplateCreateRequestModel.verify(), 'manager.EmailTemplateCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateCreateResponse value
   * @this {!Sender}
   * @param {!EmailTemplateCreateResponse} value EmailTemplateCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateCreateResponse serialization failed!')
    console.assert(this.EmailTemplateCreateResponseModel.verify(), 'manager.EmailTemplateCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateModifyRequest value
   * @this {!Sender}
   * @param {!EmailTemplateModifyRequest} value EmailTemplateModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateModifyRequest serialization failed!')
    console.assert(this.EmailTemplateModifyRequestModel.verify(), 'manager.EmailTemplateModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateModifyResponse value
   * @this {!Sender}
   * @param {!EmailTemplateModifyResponse} value EmailTemplateModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateModifyResponse serialization failed!')
    console.assert(this.EmailTemplateModifyResponseModel.verify(), 'manager.EmailTemplateModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateDeleteRequest value
   * @this {!Sender}
   * @param {!EmailTemplateDeleteRequest} value EmailTemplateDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateDeleteRequest serialization failed!')
    console.assert(this.EmailTemplateDeleteRequestModel.verify(), 'manager.EmailTemplateDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateDeleteResponse value
   * @this {!Sender}
   * @param {!EmailTemplateDeleteResponse} value EmailTemplateDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateDeleteResponse serialization failed!')
    console.assert(this.EmailTemplateDeleteResponseModel.verify(), 'manager.EmailTemplateDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateUpdateNotify value
   * @this {!Sender}
   * @param {!EmailTemplateUpdateNotify} value EmailTemplateUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateUpdateNotify serialization failed!')
    console.assert(this.EmailTemplateUpdateNotifyModel.verify(), 'manager.EmailTemplateUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateSubscribeRequest value
   * @this {!Sender}
   * @param {!OrderStateSubscribeRequest} value OrderStateSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateSubscribeRequest serialization failed!')
    console.assert(this.OrderStateSubscribeRequestModel.verify(), 'manager.OrderStateSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateSubscribeResponse value
   * @this {!Sender}
   * @param {!OrderStateSubscribeResponse} value OrderStateSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateSubscribeResponse serialization failed!')
    console.assert(this.OrderStateSubscribeResponseModel.verify(), 'manager.OrderStateSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUpdateSubscriptionRequest value
   * @this {!Sender}
   * @param {!OrderStateUpdateSubscriptionRequest} value OrderStateUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUpdateSubscriptionRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUpdateSubscriptionRequest serialization failed!')
    console.assert(this.OrderStateUpdateSubscriptionRequestModel.verify(), 'manager.OrderStateUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUpdateSubscriptionResponse value
   * @this {!Sender}
   * @param {!OrderStateUpdateSubscriptionResponse} value OrderStateUpdateSubscriptionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUpdateSubscriptionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUpdateSubscriptionResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUpdateSubscriptionResponse serialization failed!')
    console.assert(this.OrderStateUpdateSubscriptionResponseModel.verify(), 'manager.OrderStateUpdateSubscriptionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUnsubscribeRequest value
   * @this {!Sender}
   * @param {!OrderStateUnsubscribeRequest} value OrderStateUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUnsubscribeRequest serialization failed!')
    console.assert(this.OrderStateUnsubscribeRequestModel.verify(), 'manager.OrderStateUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUnsubscribeResponse value
   * @this {!Sender}
   * @param {!OrderStateUnsubscribeResponse} value OrderStateUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUnsubscribeResponse serialization failed!')
    console.assert(this.OrderStateUnsubscribeResponseModel.verify(), 'manager.OrderStateUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateNotify value
   * @this {!Sender}
   * @param {!OrderStateNotify} value OrderStateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateNotify serialization failed!')
    console.assert(this.OrderStateNotifyModel.verify(), 'manager.OrderStateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringSubscribeRequest value
   * @this {!Sender}
   * @param {!RequestMonitoringSubscribeRequest} value RequestMonitoringSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringSubscribeRequest serialization failed!')
    console.assert(this.RequestMonitoringSubscribeRequestModel.verify(), 'manager.RequestMonitoringSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringSubscribeResponse value
   * @this {!Sender}
   * @param {!RequestMonitoringSubscribeResponse} value RequestMonitoringSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringSubscribeResponse serialization failed!')
    console.assert(this.RequestMonitoringSubscribeResponseModel.verify(), 'manager.RequestMonitoringSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringUnsubscribeRequest value
   * @this {!Sender}
   * @param {!RequestMonitoringUnsubscribeRequest} value RequestMonitoringUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringUnsubscribeRequest serialization failed!')
    console.assert(this.RequestMonitoringUnsubscribeRequestModel.verify(), 'manager.RequestMonitoringUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringUnsubscribeResponse value
   * @this {!Sender}
   * @param {!RequestMonitoringUnsubscribeResponse} value RequestMonitoringUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringUnsubscribeResponse serialization failed!')
    console.assert(this.RequestMonitoringUnsubscribeResponseModel.verify(), 'manager.RequestMonitoringUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringRequestUpdateNotify value
   * @this {!Sender}
   * @param {!RequestMonitoringRequestUpdateNotify} value RequestMonitoringRequestUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringRequestUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringRequestUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringRequestUpdateNotify serialization failed!')
    console.assert(this.RequestMonitoringRequestUpdateNotifyModel.verify(), 'manager.RequestMonitoringRequestUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoSubscribeRequest value
   * @this {!Sender}
   * @param {!FilledOrdersSummaryInfoSubscribeRequest} value FilledOrdersSummaryInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoSubscribeRequest serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoSubscribeRequestModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoSubscribeResponse value
   * @this {!Sender}
   * @param {!FilledOrdersSummaryInfoSubscribeResponse} value FilledOrdersSummaryInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoSubscribeResponse serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoSubscribeResponseModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoNotify value
   * @this {!Sender}
   * @param {!FilledOrdersSummaryInfoNotify} value FilledOrdersSummaryInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoNotify serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoNotifyModel.verify(), 'manager.FilledOrdersSummaryInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoUpdateSubscriptionRequest value
   * @this {!Sender}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} value FilledOrdersSummaryInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.verify(), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoUnsubscribeRequest value
   * @this {!Sender}
   * @param {!FilledOrdersSummaryInfoUnsubscribeRequest} value FilledOrdersSummaryInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoUnsubscribeRequestModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoUnsubscribeResponse value
   * @this {!Sender}
   * @param {!FilledOrdersSummaryInfoUnsubscribeResponse} value FilledOrdersSummaryInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoUnsubscribeResponseModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsNotify value
   * @this {!Sender}
   * @param {!TradeEventsNotify} value TradeEventsNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeEventsNotify serialization failed!')
    console.assert(this.TradeEventsNotifyModel.verify(), 'manager.TradeEventsNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetRequest value
   * @this {!Sender}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeEventsGetRequest serialization failed!')
    console.assert(this.TradeEventsGetRequestModel.verify(), 'manager.TradeEventsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetResponse value
   * @this {!Sender}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeEventsGetResponse serialization failed!')
    console.assert(this.TradeEventsGetResponseModel.verify(), 'manager.TradeEventsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalRequest value
   * @this {!Sender}
   * @param {!JournalRequest} value JournalRequest value to send
   * @returns {!number} Sent bytes
   */
  send_JournalRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalRequest serialization failed!')
    console.assert(this.JournalRequestModel.verify(), 'manager.JournalRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalResponse value
   * @this {!Sender}
   * @param {!JournalResponse} value JournalResponse value to send
   * @returns {!number} Sent bytes
   */
  send_JournalResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalResponse serialization failed!')
    console.assert(this.JournalResponseModel.verify(), 'manager.JournalResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetRequest value
   * @this {!Sender}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsListGetRequest serialization failed!')
    console.assert(this.ClientReportsListGetRequestModel.verify(), 'manager.ClientReportsListGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetResponse value
   * @this {!Sender}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsListGetResponse serialization failed!')
    console.assert(this.ClientReportsListGetResponseModel.verify(), 'manager.ClientReportsListGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetRequest value
   * @this {!Sender}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportGetRequest serialization failed!')
    console.assert(this.ClientReportGetRequestModel.verify(), 'manager.ClientReportGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetResponse value
   * @this {!Sender}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportGetResponse serialization failed!')
    console.assert(this.ClientReportGetResponseModel.verify(), 'manager.ClientReportGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendRequest value
   * @this {!Sender}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsSendRequest serialization failed!')
    console.assert(this.ClientReportsSendRequestModel.verify(), 'manager.ClientReportsSendRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendResponse value
   * @this {!Sender}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsSendResponse serialization failed!')
    console.assert(this.ClientReportsSendResponseModel.verify(), 'manager.ClientReportsSendResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetRequest value
   * @this {!Sender}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ProfitClosedGetRequest serialization failed!')
    console.assert(this.ProfitClosedGetRequestModel.verify(), 'manager.ProfitClosedGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetResponse value
   * @this {!Sender}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ProfitClosedGetResponse serialization failed!')
    console.assert(this.ProfitClosedGetResponseModel.verify(), 'manager.ProfitClosedGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportRequest value
   * @this {!Sender}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarsImportRequest serialization failed!')
    console.assert(this.ChartBarsImportRequestModel.verify(), 'manager.ChartBarsImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportResponse value
   * @this {!Sender}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarsImportResponse serialization failed!')
    console.assert(this.ChartBarsImportResponseModel.verify(), 'manager.ChartBarsImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportNotify value
   * @this {!Sender}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarsImportNotify serialization failed!')
    console.assert(this.ChartBarsImportNotifyModel.verify(), 'manager.ChartBarsImportNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalBusinessRequest value
   * @this {!Sender}
   * @param {!JournalBusinessRequest} value JournalBusinessRequest value to send
   * @returns {!number} Sent bytes
   */
  send_JournalBusinessRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalBusinessRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalBusinessRequest serialization failed!')
    console.assert(this.JournalBusinessRequestModel.verify(), 'manager.JournalBusinessRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalBusinessResponse value
   * @this {!Sender}
   * @param {!JournalBusinessResponse} value JournalBusinessResponse value to send
   * @returns {!number} Sent bytes
   */
  send_JournalBusinessResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalBusinessResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalBusinessResponse serialization failed!')
    console.assert(this.JournalBusinessResponseModel.verify(), 'manager.JournalBusinessResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetPageRequest value
   * @this {!Sender}
   * @param {!AccountsGetPageRequest} value AccountsGetPageRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetPageRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetPageRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetPageRequest serialization failed!')
    console.assert(this.AccountsGetPageRequestModel.verify(), 'manager.AccountsGetPageRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetPageResponse value
   * @this {!Sender}
   * @param {!AccountsGetPageResponse} value AccountsGetPageResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetPageResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetPageResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetPageResponse serialization failed!')
    console.assert(this.AccountsGetPageResponseModel.verify(), 'manager.AccountsGetPageResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HeartbeatNotify value
   * @this {!Sender}
   * @param {!HeartbeatNotify} value HeartbeatNotify value to send
   * @returns {!number} Sent bytes
   */
  send_HeartbeatNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HeartbeatNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.HeartbeatNotify serialization failed!')
    console.assert(this.HeartbeatNotifyModel.verify(), 'manager.HeartbeatNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetRequest value
   * @this {!Sender}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersClosedSummariesGetRequest serialization failed!')
    console.assert(this.OrdersClosedSummariesGetRequestModel.verify(), 'manager.OrdersClosedSummariesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetResponse value
   * @this {!Sender}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersClosedSummariesGetResponse serialization failed!')
    console.assert(this.OrdersClosedSummariesGetResponseModel.verify(), 'manager.OrdersClosedSummariesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetPageRequest value
   * @this {!Sender}
   * @param {!OrdersGetPageRequest} value OrdersGetPageRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetPageRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetPageRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetPageRequest serialization failed!')
    console.assert(this.OrdersGetPageRequestModel.verify(), 'manager.OrdersGetPageRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetPageResponse value
   * @this {!Sender}
   * @param {!OrdersGetPageResponse} value OrdersGetPageResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetPageResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetPageResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetPageResponse serialization failed!')
    console.assert(this.OrdersGetPageResponseModel.verify(), 'manager.OrdersGetPageResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StorageUniversalNotify value
   * @this {!Sender}
   * @param {!StorageUniversalNotify} value StorageUniversalNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StorageUniversalNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageUniversalNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.StorageUniversalNotify serialization failed!')
    console.assert(this.StorageUniversalNotifyModel.verify(), 'manager.StorageUniversalNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetRequest value
   * @this {!Sender}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientLoginInfoGetRequest serialization failed!')
    console.assert(this.ClientLoginInfoGetRequestModel.verify(), 'manager.ClientLoginInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetResponse value
   * @this {!Sender}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientLoginInfoGetResponse serialization failed!')
    console.assert(this.ClientLoginInfoGetResponseModel.verify(), 'manager.ClientLoginInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupExistsGetRequest value
   * @this {!Sender}
   * @param {!AccountGroupExistsGetRequest} value AccountGroupExistsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupExistsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupExistsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupExistsGetRequest serialization failed!')
    console.assert(this.AccountGroupExistsGetRequestModel.verify(), 'manager.AccountGroupExistsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupExistsGetResponse value
   * @this {!Sender}
   * @param {!AccountGroupExistsGetResponse} value AccountGroupExistsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupExistsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupExistsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupExistsGetResponse serialization failed!')
    console.assert(this.AccountGroupExistsGetResponseModel.verify(), 'manager.AccountGroupExistsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordRequest value
   * @this {!Sender}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithPasswordRequest serialization failed!')
    console.assert(this.LoginWithPasswordRequestModel.verify(), 'manager.LoginWithPasswordRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordResponse value
   * @this {!Sender}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithPasswordResponse serialization failed!')
    console.assert(this.LoginWithPasswordResponseModel.verify(), 'manager.LoginWithPasswordResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenRequest value
   * @this {!Sender}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithTokenRequest serialization failed!')
    console.assert(this.LoginWithTokenRequestModel.verify(), 'manager.LoginWithTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenResponse value
   * @this {!Sender}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithTokenResponse serialization failed!')
    console.assert(this.LoginWithTokenResponseModel.verify(), 'manager.LoginWithTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenRequest value
   * @this {!Sender}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.RefreshTokenRequest serialization failed!')
    console.assert(this.RefreshTokenRequestModel.verify(), 'manager.RefreshTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenResponse value
   * @this {!Sender}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.RefreshTokenResponse serialization failed!')
    console.assert(this.RefreshTokenResponseModel.verify(), 'manager.RefreshTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonGetRequest value
   * @this {!Sender}
   * @param {!PersonGetRequest} value PersonGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonGetRequest serialization failed!')
    console.assert(this.PersonGetRequestModel.verify(), 'manager.PersonGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonGetResponse value
   * @this {!Sender}
   * @param {!PersonGetResponse} value PersonGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonGetResponse serialization failed!')
    console.assert(this.PersonGetResponseModel.verify(), 'manager.PersonGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllRequest value
   * @this {!Sender}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonsGetAllRequest serialization failed!')
    console.assert(this.PersonsGetAllRequestModel.verify(), 'manager.PersonsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllResponse value
   * @this {!Sender}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonsGetAllResponse serialization failed!')
    console.assert(this.PersonsGetAllResponseModel.verify(), 'manager.PersonsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonCreateRequest value
   * @this {!Sender}
   * @param {!PersonCreateRequest} value PersonCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonCreateRequest serialization failed!')
    console.assert(this.PersonCreateRequestModel.verify(), 'manager.PersonCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonCreateResponse value
   * @this {!Sender}
   * @param {!PersonCreateResponse} value PersonCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonCreateResponse serialization failed!')
    console.assert(this.PersonCreateResponseModel.verify(), 'manager.PersonCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonModifyRequest value
   * @this {!Sender}
   * @param {!PersonModifyRequest} value PersonModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonModifyRequest serialization failed!')
    console.assert(this.PersonModifyRequestModel.verify(), 'manager.PersonModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonModifyResponse value
   * @this {!Sender}
   * @param {!PersonModifyResponse} value PersonModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonModifyResponse serialization failed!')
    console.assert(this.PersonModifyResponseModel.verify(), 'manager.PersonModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateNotify value
   * @this {!Sender}
   * @param {!PersonUpdateNotify} value PersonUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonUpdateNotify serialization failed!')
    console.assert(this.PersonUpdateNotifyModel.verify(), 'manager.PersonUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetRequest value
   * @this {!Sender}
   * @param {!SecuritySettingsGetRequest} value SecuritySettingsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetRequest serialization failed!')
    console.assert(this.SecuritySettingsGetRequestModel.verify(), 'manager.SecuritySettingsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetResponse value
   * @this {!Sender}
   * @param {!SecuritySettingsGetResponse} value SecuritySettingsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetResponse serialization failed!')
    console.assert(this.SecuritySettingsGetResponseModel.verify(), 'manager.SecuritySettingsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetAllRequest value
   * @this {!Sender}
   * @param {!SecuritySettingsGetAllRequest} value SecuritySettingsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetAllRequest serialization failed!')
    console.assert(this.SecuritySettingsGetAllRequestModel.verify(), 'manager.SecuritySettingsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetAllResponse value
   * @this {!Sender}
   * @param {!SecuritySettingsGetAllResponse} value SecuritySettingsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetAllResponse serialization failed!')
    console.assert(this.SecuritySettingsGetAllResponseModel.verify(), 'manager.SecuritySettingsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsCreateRequest value
   * @this {!Sender}
   * @param {!SecuritySettingsCreateRequest} value SecuritySettingsCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsCreateRequest serialization failed!')
    console.assert(this.SecuritySettingsCreateRequestModel.verify(), 'manager.SecuritySettingsCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsCreateResponse value
   * @this {!Sender}
   * @param {!SecuritySettingsCreateResponse} value SecuritySettingsCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsCreateResponse serialization failed!')
    console.assert(this.SecuritySettingsCreateResponseModel.verify(), 'manager.SecuritySettingsCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsModifyRequest value
   * @this {!Sender}
   * @param {!SecuritySettingsModifyRequest} value SecuritySettingsModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsModifyRequest serialization failed!')
    console.assert(this.SecuritySettingsModifyRequestModel.verify(), 'manager.SecuritySettingsModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsModifyResponse value
   * @this {!Sender}
   * @param {!SecuritySettingsModifyResponse} value SecuritySettingsModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsModifyResponse serialization failed!')
    console.assert(this.SecuritySettingsModifyResponseModel.verify(), 'manager.SecuritySettingsModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsDeleteRequest value
   * @this {!Sender}
   * @param {!SecuritySettingsDeleteRequest} value SecuritySettingsDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsDeleteRequest serialization failed!')
    console.assert(this.SecuritySettingsDeleteRequestModel.verify(), 'manager.SecuritySettingsDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsDeleteResponse value
   * @this {!Sender}
   * @param {!SecuritySettingsDeleteResponse} value SecuritySettingsDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsDeleteResponse serialization failed!')
    console.assert(this.SecuritySettingsDeleteResponseModel.verify(), 'manager.SecuritySettingsDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsUpdateNotify value
   * @this {!Sender}
   * @param {!SecuritySettingsUpdateNotify} value SecuritySettingsUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsUpdateNotify serialization failed!')
    console.assert(this.SecuritySettingsUpdateNotifyModel.verify(), 'manager.SecuritySettingsUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleGetRequest value
   * @this {!Sender}
   * @param {!FirewallRuleGetRequest} value FirewallRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleGetRequest serialization failed!')
    console.assert(this.FirewallRuleGetRequestModel.verify(), 'manager.FirewallRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleGetResponse value
   * @this {!Sender}
   * @param {!FirewallRuleGetResponse} value FirewallRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleGetResponse serialization failed!')
    console.assert(this.FirewallRuleGetResponseModel.verify(), 'manager.FirewallRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRulesGetAllRequest value
   * @this {!Sender}
   * @param {!FirewallRulesGetAllRequest} value FirewallRulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRulesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRulesGetAllRequest serialization failed!')
    console.assert(this.FirewallRulesGetAllRequestModel.verify(), 'manager.FirewallRulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRulesGetAllResponse value
   * @this {!Sender}
   * @param {!FirewallRulesGetAllResponse} value FirewallRulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRulesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRulesGetAllResponse serialization failed!')
    console.assert(this.FirewallRulesGetAllResponseModel.verify(), 'manager.FirewallRulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleCreateRequest value
   * @this {!Sender}
   * @param {!FirewallRuleCreateRequest} value FirewallRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleCreateRequest serialization failed!')
    console.assert(this.FirewallRuleCreateRequestModel.verify(), 'manager.FirewallRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleCreateResponse value
   * @this {!Sender}
   * @param {!FirewallRuleCreateResponse} value FirewallRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleCreateResponse serialization failed!')
    console.assert(this.FirewallRuleCreateResponseModel.verify(), 'manager.FirewallRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleModifyRequest value
   * @this {!Sender}
   * @param {!FirewallRuleModifyRequest} value FirewallRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleModifyRequest serialization failed!')
    console.assert(this.FirewallRuleModifyRequestModel.verify(), 'manager.FirewallRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleModifyResponse value
   * @this {!Sender}
   * @param {!FirewallRuleModifyResponse} value FirewallRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleModifyResponse serialization failed!')
    console.assert(this.FirewallRuleModifyResponseModel.verify(), 'manager.FirewallRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleDeleteRequest value
   * @this {!Sender}
   * @param {!FirewallRuleDeleteRequest} value FirewallRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleDeleteRequest serialization failed!')
    console.assert(this.FirewallRuleDeleteRequestModel.verify(), 'manager.FirewallRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleDeleteResponse value
   * @this {!Sender}
   * @param {!FirewallRuleDeleteResponse} value FirewallRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleDeleteResponse serialization failed!')
    console.assert(this.FirewallRuleDeleteResponseModel.verify(), 'manager.FirewallRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleUpdateNotify value
   * @this {!Sender}
   * @param {!FirewallRuleUpdateNotify} value FirewallRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleUpdateNotify serialization failed!')
    console.assert(this.FirewallRuleUpdateNotifyModel.verify(), 'manager.FirewallRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'manager.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
    this._configSender.onSendHandler = handler
    this._historySender.onSendHandler = handler
    this._clientSender.onSendHandler = handler
    this._feedSender.onSendHandler = handler
    this._storageSender.onSendHandler = handler
    this._exposedSender.onSendHandler = handler
    this._executionSender.onSendHandler = handler
    this._backupSender.onSendHandler = handler
    this._innerSender.onSendHandler = handler
    this._tradeSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
    this._configSender.onSendLogHandler = handler
    this._historySender.onSendLogHandler = handler
    this._clientSender.onSendLogHandler = handler
    this._feedSender.onSendLogHandler = handler
    this._storageSender.onSendLogHandler = handler
    this._exposedSender.onSendLogHandler = handler
    this._executionSender.onSendLogHandler = handler
    this._backupSender.onSendLogHandler = handler
    this._innerSender.onSendLogHandler = handler
    this._tradeSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding manager receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize manager receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._configReceiver = new config.Receiver(this.buffer)
    this._historyReceiver = new history.Receiver(this.buffer)
    this._clientReceiver = new client.Receiver(this.buffer)
    this._feedReceiver = new feed.Receiver(this.buffer)
    this._storageReceiver = new storage.Receiver(this.buffer)
    this._exposedReceiver = new exposed.Receiver(this.buffer)
    this._executionReceiver = new execution.Receiver(this.buffer)
    this._backupReceiver = new backup.Receiver(this.buffer)
    this._innerReceiver = new inner.Receiver(this.buffer)
    this._tradeReceiver = new trade.Receiver(this.buffer)
    this._PingModuleRequestValue = new PingModuleRequest()
    this._PingModuleRequestModel = new PingModuleRequestModel()
    this._PongModuleResponseValue = new PongModuleResponse()
    this._PongModuleResponseModel = new PongModuleResponseModel()
    this._PingModuleRejectValue = new PingModuleReject()
    this._PingModuleRejectModel = new PingModuleRejectModel()
    this._Deprecated3Value = new Deprecated3()
    this._Deprecated3Model = new Deprecated3Model()
    this._Deprecated4Value = new Deprecated4()
    this._Deprecated4Model = new Deprecated4Model()
    this._Deprecated1Value = new Deprecated1()
    this._Deprecated1Model = new Deprecated1Model()
    this._Deprecated2Value = new Deprecated2()
    this._Deprecated2Model = new Deprecated2Model()
    this._ManagerSessionInfoRequestValue = new ManagerSessionInfoRequest()
    this._ManagerSessionInfoRequestModel = new ManagerSessionInfoRequestModel()
    this._ManagerSessionInfoResponseValue = new ManagerSessionInfoResponse()
    this._ManagerSessionInfoResponseModel = new ManagerSessionInfoResponseModel()
    this._OnlineSessionsRequestValue = new OnlineSessionsRequest()
    this._OnlineSessionsRequestModel = new OnlineSessionsRequestModel()
    this._OnlineSessionsResponseValue = new OnlineSessionsResponse()
    this._OnlineSessionsResponseModel = new OnlineSessionsResponseModel()
    this._DropSessionRequestValue = new DropSessionRequest()
    this._DropSessionRequestModel = new DropSessionRequestModel()
    this._DropSessionResponseValue = new DropSessionResponse()
    this._DropSessionResponseModel = new DropSessionResponseModel()
    this._ConfigRequestValue = new ConfigRequest()
    this._ConfigRequestModel = new ConfigRequestModel()
    this._ConfigResponseValue = new ConfigResponse()
    this._ConfigResponseModel = new ConfigResponseModel()
    this._ConfigRejectValue = new ConfigReject()
    this._ConfigRejectModel = new ConfigRejectModel()
    this._ConfigUpdateRequestValue = new ConfigUpdateRequest()
    this._ConfigUpdateRequestModel = new ConfigUpdateRequestModel()
    this._ConfigUpdateResponseValue = new ConfigUpdateResponse()
    this._ConfigUpdateResponseModel = new ConfigUpdateResponseModel()
    this._ConfigUpdateNotifyValue = new ConfigUpdateNotify()
    this._ConfigUpdateNotifyModel = new ConfigUpdateNotifyModel()
    this._ConfigVersionRequestValue = new ConfigVersionRequest()
    this._ConfigVersionRequestModel = new ConfigVersionRequestModel()
    this._ConfigVersionResponseValue = new ConfigVersionResponse()
    this._ConfigVersionResponseModel = new ConfigVersionResponseModel()
    this._ConfigExportRequestValue = new ConfigExportRequest()
    this._ConfigExportRequestModel = new ConfigExportRequestModel()
    this._ConfigExportResponseValue = new ConfigExportResponse()
    this._ConfigExportResponseModel = new ConfigExportResponseModel()
    this._ConfigImportRequestValue = new ConfigImportRequest()
    this._ConfigImportRequestModel = new ConfigImportRequestModel()
    this._ConfigImportResponseValue = new ConfigImportResponse()
    this._ConfigImportResponseModel = new ConfigImportResponseModel()
    this._ConfigValidateRequestValue = new ConfigValidateRequest()
    this._ConfigValidateRequestModel = new ConfigValidateRequestModel()
    this._ConfigValidateResponseValue = new ConfigValidateResponse()
    this._ConfigValidateResponseModel = new ConfigValidateResponseModel()
    this._ConfigRefreshRequestValue = new ConfigRefreshRequest()
    this._ConfigRefreshRequestModel = new ConfigRefreshRequestModel()
    this._ConfigRefreshResponseValue = new ConfigRefreshResponse()
    this._ConfigRefreshResponseModel = new ConfigRefreshResponseModel()
    this._ConfigHistoryRequestValue = new ConfigHistoryRequest()
    this._ConfigHistoryRequestModel = new ConfigHistoryRequestModel()
    this._ConfigHistoryResponseValue = new ConfigHistoryResponse()
    this._ConfigHistoryResponseModel = new ConfigHistoryResponseModel()
    this._ConfigRollbackRequestValue = new ConfigRollbackRequest()
    this._ConfigRollbackRequestModel = new ConfigRollbackRequestModel()
    this._ConfigRollbackResponseValue = new ConfigRollbackResponse()
    this._ConfigRollbackResponseModel = new ConfigRollbackResponseModel()
    this._HostGetRequestValue = new HostGetRequest()
    this._HostGetRequestModel = new HostGetRequestModel()
    this._HostGetResponseValue = new HostGetResponse()
    this._HostGetResponseModel = new HostGetResponseModel()
    this._HostsGetAllRequestValue = new HostsGetAllRequest()
    this._HostsGetAllRequestModel = new HostsGetAllRequestModel()
    this._HostsGetAllResponseValue = new HostsGetAllResponse()
    this._HostsGetAllResponseModel = new HostsGetAllResponseModel()
    this._HostCreateRequestValue = new HostCreateRequest()
    this._HostCreateRequestModel = new HostCreateRequestModel()
    this._HostCreateResponseValue = new HostCreateResponse()
    this._HostCreateResponseModel = new HostCreateResponseModel()
    this._HostModifyRequestValue = new HostModifyRequest()
    this._HostModifyRequestModel = new HostModifyRequestModel()
    this._HostModifyResponseValue = new HostModifyResponse()
    this._HostModifyResponseModel = new HostModifyResponseModel()
    this._HostDeleteRequestValue = new HostDeleteRequest()
    this._HostDeleteRequestModel = new HostDeleteRequestModel()
    this._HostDeleteResponseValue = new HostDeleteResponse()
    this._HostDeleteResponseModel = new HostDeleteResponseModel()
    this._HostUpdateNotifyValue = new HostUpdateNotify()
    this._HostUpdateNotifyModel = new HostUpdateNotifyModel()
    this._ModuleGetRequestValue = new ModuleGetRequest()
    this._ModuleGetRequestModel = new ModuleGetRequestModel()
    this._ModuleGetResponseValue = new ModuleGetResponse()
    this._ModuleGetResponseModel = new ModuleGetResponseModel()
    this._ModulesGetAllRequestValue = new ModulesGetAllRequest()
    this._ModulesGetAllRequestModel = new ModulesGetAllRequestModel()
    this._ModulesGetAllResponseValue = new ModulesGetAllResponse()
    this._ModulesGetAllResponseModel = new ModulesGetAllResponseModel()
    this._ModuleCreateRequestValue = new ModuleCreateRequest()
    this._ModuleCreateRequestModel = new ModuleCreateRequestModel()
    this._ModuleCreateResponseValue = new ModuleCreateResponse()
    this._ModuleCreateResponseModel = new ModuleCreateResponseModel()
    this._ModuleModifyRequestValue = new ModuleModifyRequest()
    this._ModuleModifyRequestModel = new ModuleModifyRequestModel()
    this._ModuleModifyResponseValue = new ModuleModifyResponse()
    this._ModuleModifyResponseModel = new ModuleModifyResponseModel()
    this._ModuleDeleteRequestValue = new ModuleDeleteRequest()
    this._ModuleDeleteRequestModel = new ModuleDeleteRequestModel()
    this._ModuleDeleteResponseValue = new ModuleDeleteResponse()
    this._ModuleDeleteResponseModel = new ModuleDeleteResponseModel()
    this._ModuleUpdateNotifyValue = new ModuleUpdateNotify()
    this._ModuleUpdateNotifyModel = new ModuleUpdateNotifyModel()
    this._ServerTimeGetRequestValue = new ServerTimeGetRequest()
    this._ServerTimeGetRequestModel = new ServerTimeGetRequestModel()
    this._ServerTimeGetResponseValue = new ServerTimeGetResponse()
    this._ServerTimeGetResponseModel = new ServerTimeGetResponseModel()
    this._ManagerPlatformTimezoneGetRequestValue = new ManagerPlatformTimezoneGetRequest()
    this._ManagerPlatformTimezoneGetRequestModel = new ManagerPlatformTimezoneGetRequestModel()
    this._ManagerPlatformTimezoneGetResponseValue = new ManagerPlatformTimezoneGetResponse()
    this._ManagerPlatformTimezoneGetResponseModel = new ManagerPlatformTimezoneGetResponseModel()
    this._TimezoneGetRequestValue = new TimezoneGetRequest()
    this._TimezoneGetRequestModel = new TimezoneGetRequestModel()
    this._TimezoneGetResponseValue = new TimezoneGetResponse()
    this._TimezoneGetResponseModel = new TimezoneGetResponseModel()
    this._TimezonesGetAllRequestValue = new TimezonesGetAllRequest()
    this._TimezonesGetAllRequestModel = new TimezonesGetAllRequestModel()
    this._TimezonesGetAllResponseValue = new TimezonesGetAllResponse()
    this._TimezonesGetAllResponseModel = new TimezonesGetAllResponseModel()
    this._TimezoneCreateRequestValue = new TimezoneCreateRequest()
    this._TimezoneCreateRequestModel = new TimezoneCreateRequestModel()
    this._TimezoneCreateResponseValue = new TimezoneCreateResponse()
    this._TimezoneCreateResponseModel = new TimezoneCreateResponseModel()
    this._TimezoneModifyRequestValue = new TimezoneModifyRequest()
    this._TimezoneModifyRequestModel = new TimezoneModifyRequestModel()
    this._TimezoneModifyResponseValue = new TimezoneModifyResponse()
    this._TimezoneModifyResponseModel = new TimezoneModifyResponseModel()
    this._TimezoneDeleteRequestValue = new TimezoneDeleteRequest()
    this._TimezoneDeleteRequestModel = new TimezoneDeleteRequestModel()
    this._TimezoneDeleteResponseValue = new TimezoneDeleteResponse()
    this._TimezoneDeleteResponseModel = new TimezoneDeleteResponseModel()
    this._TimezoneUpdateNotifyValue = new TimezoneUpdateNotify()
    this._TimezoneUpdateNotifyModel = new TimezoneUpdateNotifyModel()
    this._CurrencyGroupGetRequestValue = new CurrencyGroupGetRequest()
    this._CurrencyGroupGetRequestModel = new CurrencyGroupGetRequestModel()
    this._CurrencyGroupGetResponseValue = new CurrencyGroupGetResponse()
    this._CurrencyGroupGetResponseModel = new CurrencyGroupGetResponseModel()
    this._CurrencyGroupsGetAllRequestValue = new CurrencyGroupsGetAllRequest()
    this._CurrencyGroupsGetAllRequestModel = new CurrencyGroupsGetAllRequestModel()
    this._CurrencyGroupsGetAllResponseValue = new CurrencyGroupsGetAllResponse()
    this._CurrencyGroupsGetAllResponseModel = new CurrencyGroupsGetAllResponseModel()
    this._CurrencyGroupCreateRequestValue = new CurrencyGroupCreateRequest()
    this._CurrencyGroupCreateRequestModel = new CurrencyGroupCreateRequestModel()
    this._CurrencyGroupCreateResponseValue = new CurrencyGroupCreateResponse()
    this._CurrencyGroupCreateResponseModel = new CurrencyGroupCreateResponseModel()
    this._CurrencyGroupModifyRequestValue = new CurrencyGroupModifyRequest()
    this._CurrencyGroupModifyRequestModel = new CurrencyGroupModifyRequestModel()
    this._CurrencyGroupModifyResponseValue = new CurrencyGroupModifyResponse()
    this._CurrencyGroupModifyResponseModel = new CurrencyGroupModifyResponseModel()
    this._CurrencyGroupDeleteRequestValue = new CurrencyGroupDeleteRequest()
    this._CurrencyGroupDeleteRequestModel = new CurrencyGroupDeleteRequestModel()
    this._CurrencyGroupDeleteResponseValue = new CurrencyGroupDeleteResponse()
    this._CurrencyGroupDeleteResponseModel = new CurrencyGroupDeleteResponseModel()
    this._CurrencyGroupUpdateNotifyValue = new CurrencyGroupUpdateNotify()
    this._CurrencyGroupUpdateNotifyModel = new CurrencyGroupUpdateNotifyModel()
    this._CurrencyGetRequestValue = new CurrencyGetRequest()
    this._CurrencyGetRequestModel = new CurrencyGetRequestModel()
    this._CurrencyGetResponseValue = new CurrencyGetResponse()
    this._CurrencyGetResponseModel = new CurrencyGetResponseModel()
    this._CurrenciesGetAllRequestValue = new CurrenciesGetAllRequest()
    this._CurrenciesGetAllRequestModel = new CurrenciesGetAllRequestModel()
    this._CurrenciesGetAllResponseValue = new CurrenciesGetAllResponse()
    this._CurrenciesGetAllResponseModel = new CurrenciesGetAllResponseModel()
    this._CurrencyCreateRequestValue = new CurrencyCreateRequest()
    this._CurrencyCreateRequestModel = new CurrencyCreateRequestModel()
    this._CurrencyCreateResponseValue = new CurrencyCreateResponse()
    this._CurrencyCreateResponseModel = new CurrencyCreateResponseModel()
    this._CurrencyModifyRequestValue = new CurrencyModifyRequest()
    this._CurrencyModifyRequestModel = new CurrencyModifyRequestModel()
    this._CurrencyModifyResponseValue = new CurrencyModifyResponse()
    this._CurrencyModifyResponseModel = new CurrencyModifyResponseModel()
    this._CurrencyDeleteRequestValue = new CurrencyDeleteRequest()
    this._CurrencyDeleteRequestModel = new CurrencyDeleteRequestModel()
    this._CurrencyDeleteResponseValue = new CurrencyDeleteResponse()
    this._CurrencyDeleteResponseModel = new CurrencyDeleteResponseModel()
    this._CurrencyUpdateNotifyValue = new CurrencyUpdateNotify()
    this._CurrencyUpdateNotifyModel = new CurrencyUpdateNotifyModel()
    this._SymbolGroupGetRequestValue = new SymbolGroupGetRequest()
    this._SymbolGroupGetRequestModel = new SymbolGroupGetRequestModel()
    this._SymbolGroupGetResponseValue = new SymbolGroupGetResponse()
    this._SymbolGroupGetResponseModel = new SymbolGroupGetResponseModel()
    this._SymbolGroupsGetAllRequestValue = new SymbolGroupsGetAllRequest()
    this._SymbolGroupsGetAllRequestModel = new SymbolGroupsGetAllRequestModel()
    this._SymbolGroupsGetAllResponseValue = new SymbolGroupsGetAllResponse()
    this._SymbolGroupsGetAllResponseModel = new SymbolGroupsGetAllResponseModel()
    this._SymbolGroupCreateRequestValue = new SymbolGroupCreateRequest()
    this._SymbolGroupCreateRequestModel = new SymbolGroupCreateRequestModel()
    this._SymbolGroupCreateResponseValue = new SymbolGroupCreateResponse()
    this._SymbolGroupCreateResponseModel = new SymbolGroupCreateResponseModel()
    this._SymbolGroupModifyRequestValue = new SymbolGroupModifyRequest()
    this._SymbolGroupModifyRequestModel = new SymbolGroupModifyRequestModel()
    this._SymbolGroupModifyResponseValue = new SymbolGroupModifyResponse()
    this._SymbolGroupModifyResponseModel = new SymbolGroupModifyResponseModel()
    this._SymbolGroupDeleteRequestValue = new SymbolGroupDeleteRequest()
    this._SymbolGroupDeleteRequestModel = new SymbolGroupDeleteRequestModel()
    this._SymbolGroupDeleteResponseValue = new SymbolGroupDeleteResponse()
    this._SymbolGroupDeleteResponseModel = new SymbolGroupDeleteResponseModel()
    this._SymbolGroupUpdateNotifyValue = new SymbolGroupUpdateNotify()
    this._SymbolGroupUpdateNotifyModel = new SymbolGroupUpdateNotifyModel()
    this._SymbolGetRequestValue = new SymbolGetRequest()
    this._SymbolGetRequestModel = new SymbolGetRequestModel()
    this._SymbolGetResponseValue = new SymbolGetResponse()
    this._SymbolGetResponseModel = new SymbolGetResponseModel()
    this._SymbolsGetAllRequestValue = new SymbolsGetAllRequest()
    this._SymbolsGetAllRequestModel = new SymbolsGetAllRequestModel()
    this._SymbolsGetAllResponseValue = new SymbolsGetAllResponse()
    this._SymbolsGetAllResponseModel = new SymbolsGetAllResponseModel()
    this._SymbolCreateRequestValue = new SymbolCreateRequest()
    this._SymbolCreateRequestModel = new SymbolCreateRequestModel()
    this._SymbolCreateResponseValue = new SymbolCreateResponse()
    this._SymbolCreateResponseModel = new SymbolCreateResponseModel()
    this._SymbolModifyRequestValue = new SymbolModifyRequest()
    this._SymbolModifyRequestModel = new SymbolModifyRequestModel()
    this._SymbolModifyResponseValue = new SymbolModifyResponse()
    this._SymbolModifyResponseModel = new SymbolModifyResponseModel()
    this._SymbolDeleteRequestValue = new SymbolDeleteRequest()
    this._SymbolDeleteRequestModel = new SymbolDeleteRequestModel()
    this._SymbolDeleteResponseValue = new SymbolDeleteResponse()
    this._SymbolDeleteResponseModel = new SymbolDeleteResponseModel()
    this._SymbolUpdateNotifyValue = new SymbolUpdateNotify()
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel()
    this._SwapsExportRequestValue = new SwapsExportRequest()
    this._SwapsExportRequestModel = new SwapsExportRequestModel()
    this._SwapsExportResponseValue = new SwapsExportResponse()
    this._SwapsExportResponseModel = new SwapsExportResponseModel()
    this._SwapsImportRequestValue = new SwapsImportRequest()
    this._SwapsImportRequestModel = new SwapsImportRequestModel()
    this._SwapsImportResponseValue = new SwapsImportResponse()
    this._SwapsImportResponseModel = new SwapsImportResponseModel()
    this._CommissionProfileGetRequestValue = new CommissionProfileGetRequest()
    this._CommissionProfileGetRequestModel = new CommissionProfileGetRequestModel()
    this._CommissionProfileGetResponseValue = new CommissionProfileGetResponse()
    this._CommissionProfileGetResponseModel = new CommissionProfileGetResponseModel()
    this._CommissionProfilesGetAllRequestValue = new CommissionProfilesGetAllRequest()
    this._CommissionProfilesGetAllRequestModel = new CommissionProfilesGetAllRequestModel()
    this._CommissionProfilesGetAllResponseValue = new CommissionProfilesGetAllResponse()
    this._CommissionProfilesGetAllResponseModel = new CommissionProfilesGetAllResponseModel()
    this._CommissionProfileCreateRequestValue = new CommissionProfileCreateRequest()
    this._CommissionProfileCreateRequestModel = new CommissionProfileCreateRequestModel()
    this._CommissionProfileCreateResponseValue = new CommissionProfileCreateResponse()
    this._CommissionProfileCreateResponseModel = new CommissionProfileCreateResponseModel()
    this._CommissionProfileModifyRequestValue = new CommissionProfileModifyRequest()
    this._CommissionProfileModifyRequestModel = new CommissionProfileModifyRequestModel()
    this._CommissionProfileModifyResponseValue = new CommissionProfileModifyResponse()
    this._CommissionProfileModifyResponseModel = new CommissionProfileModifyResponseModel()
    this._CommissionProfileDeleteRequestValue = new CommissionProfileDeleteRequest()
    this._CommissionProfileDeleteRequestModel = new CommissionProfileDeleteRequestModel()
    this._CommissionProfileDeleteResponseValue = new CommissionProfileDeleteResponse()
    this._CommissionProfileDeleteResponseModel = new CommissionProfileDeleteResponseModel()
    this._CommissionProfileUpdateNotifyValue = new CommissionProfileUpdateNotify()
    this._CommissionProfileUpdateNotifyModel = new CommissionProfileUpdateNotifyModel()
    this._MarkupProfileGetRequestValue = new MarkupProfileGetRequest()
    this._MarkupProfileGetRequestModel = new MarkupProfileGetRequestModel()
    this._MarkupProfileGetResponseValue = new MarkupProfileGetResponse()
    this._MarkupProfileGetResponseModel = new MarkupProfileGetResponseModel()
    this._MarkupProfilesGetAllRequestValue = new MarkupProfilesGetAllRequest()
    this._MarkupProfilesGetAllRequestModel = new MarkupProfilesGetAllRequestModel()
    this._MarkupProfilesGetAllResponseValue = new MarkupProfilesGetAllResponse()
    this._MarkupProfilesGetAllResponseModel = new MarkupProfilesGetAllResponseModel()
    this._MarkupProfileCreateRequestValue = new MarkupProfileCreateRequest()
    this._MarkupProfileCreateRequestModel = new MarkupProfileCreateRequestModel()
    this._MarkupProfileCreateResponseValue = new MarkupProfileCreateResponse()
    this._MarkupProfileCreateResponseModel = new MarkupProfileCreateResponseModel()
    this._MarkupProfileModifyRequestValue = new MarkupProfileModifyRequest()
    this._MarkupProfileModifyRequestModel = new MarkupProfileModifyRequestModel()
    this._MarkupProfileModifyResponseValue = new MarkupProfileModifyResponse()
    this._MarkupProfileModifyResponseModel = new MarkupProfileModifyResponseModel()
    this._MarkupProfileDeleteRequestValue = new MarkupProfileDeleteRequest()
    this._MarkupProfileDeleteRequestModel = new MarkupProfileDeleteRequestModel()
    this._MarkupProfileDeleteResponseValue = new MarkupProfileDeleteResponse()
    this._MarkupProfileDeleteResponseModel = new MarkupProfileDeleteResponseModel()
    this._MarkupProfileUpdateNotifyValue = new MarkupProfileUpdateNotify()
    this._MarkupProfileUpdateNotifyModel = new MarkupProfileUpdateNotifyModel()
    this._ManagerGroupGetRequestValue = new ManagerGroupGetRequest()
    this._ManagerGroupGetRequestModel = new ManagerGroupGetRequestModel()
    this._ManagerGroupGetResponseValue = new ManagerGroupGetResponse()
    this._ManagerGroupGetResponseModel = new ManagerGroupGetResponseModel()
    this._ManagerGroupsGetAllRequestValue = new ManagerGroupsGetAllRequest()
    this._ManagerGroupsGetAllRequestModel = new ManagerGroupsGetAllRequestModel()
    this._ManagerGroupsGetAllResponseValue = new ManagerGroupsGetAllResponse()
    this._ManagerGroupsGetAllResponseModel = new ManagerGroupsGetAllResponseModel()
    this._ManagerGroupCreateRequestValue = new ManagerGroupCreateRequest()
    this._ManagerGroupCreateRequestModel = new ManagerGroupCreateRequestModel()
    this._ManagerGroupCreateResponseValue = new ManagerGroupCreateResponse()
    this._ManagerGroupCreateResponseModel = new ManagerGroupCreateResponseModel()
    this._ManagerGroupModifyRequestValue = new ManagerGroupModifyRequest()
    this._ManagerGroupModifyRequestModel = new ManagerGroupModifyRequestModel()
    this._ManagerGroupModifyResponseValue = new ManagerGroupModifyResponse()
    this._ManagerGroupModifyResponseModel = new ManagerGroupModifyResponseModel()
    this._ManagerGroupDeleteRequestValue = new ManagerGroupDeleteRequest()
    this._ManagerGroupDeleteRequestModel = new ManagerGroupDeleteRequestModel()
    this._ManagerGroupDeleteResponseValue = new ManagerGroupDeleteResponse()
    this._ManagerGroupDeleteResponseModel = new ManagerGroupDeleteResponseModel()
    this._ManagerGroupUpdateNotifyValue = new ManagerGroupUpdateNotify()
    this._ManagerGroupUpdateNotifyModel = new ManagerGroupUpdateNotifyModel()
    this._ManagerGetRequestValue = new ManagerGetRequest()
    this._ManagerGetRequestModel = new ManagerGetRequestModel()
    this._ManagerGetResponseValue = new ManagerGetResponse()
    this._ManagerGetResponseModel = new ManagerGetResponseModel()
    this._ManagersGetAllRequestValue = new ManagersGetAllRequest()
    this._ManagersGetAllRequestModel = new ManagersGetAllRequestModel()
    this._ManagersGetAllResponseValue = new ManagersGetAllResponse()
    this._ManagersGetAllResponseModel = new ManagersGetAllResponseModel()
    this._ManagerCreateRequestValue = new ManagerCreateRequest()
    this._ManagerCreateRequestModel = new ManagerCreateRequestModel()
    this._ManagerCreateResponseValue = new ManagerCreateResponse()
    this._ManagerCreateResponseModel = new ManagerCreateResponseModel()
    this._ManagerModifyRequestValue = new ManagerModifyRequest()
    this._ManagerModifyRequestModel = new ManagerModifyRequestModel()
    this._ManagerModifyResponseValue = new ManagerModifyResponse()
    this._ManagerModifyResponseModel = new ManagerModifyResponseModel()
    this._ManagerDeleteRequestValue = new ManagerDeleteRequest()
    this._ManagerDeleteRequestModel = new ManagerDeleteRequestModel()
    this._ManagerDeleteResponseValue = new ManagerDeleteResponse()
    this._ManagerDeleteResponseModel = new ManagerDeleteResponseModel()
    this._ManagerUpdateNotifyValue = new ManagerUpdateNotify()
    this._ManagerUpdateNotifyModel = new ManagerUpdateNotifyModel()
    this._PlatformGetRequestValue = new PlatformGetRequest()
    this._PlatformGetRequestModel = new PlatformGetRequestModel()
    this._PlatformGetResponseValue = new PlatformGetResponse()
    this._PlatformGetResponseModel = new PlatformGetResponseModel()
    this._PlatformsGetAllRequestValue = new PlatformsGetAllRequest()
    this._PlatformsGetAllRequestModel = new PlatformsGetAllRequestModel()
    this._PlatformsGetAllResponseValue = new PlatformsGetAllResponse()
    this._PlatformsGetAllResponseModel = new PlatformsGetAllResponseModel()
    this._PlatformCreateRequestValue = new PlatformCreateRequest()
    this._PlatformCreateRequestModel = new PlatformCreateRequestModel()
    this._PlatformCreateResponseValue = new PlatformCreateResponse()
    this._PlatformCreateResponseModel = new PlatformCreateResponseModel()
    this._PlatformModifyRequestValue = new PlatformModifyRequest()
    this._PlatformModifyRequestModel = new PlatformModifyRequestModel()
    this._PlatformModifyResponseValue = new PlatformModifyResponse()
    this._PlatformModifyResponseModel = new PlatformModifyResponseModel()
    this._PlatformDeleteRequestValue = new PlatformDeleteRequest()
    this._PlatformDeleteRequestModel = new PlatformDeleteRequestModel()
    this._PlatformDeleteResponseValue = new PlatformDeleteResponse()
    this._PlatformDeleteResponseModel = new PlatformDeleteResponseModel()
    this._PlatformUpdateNotifyValue = new PlatformUpdateNotify()
    this._PlatformUpdateNotifyModel = new PlatformUpdateNotifyModel()
    this._AccountGroupGetRequestValue = new AccountGroupGetRequest()
    this._AccountGroupGetRequestModel = new AccountGroupGetRequestModel()
    this._AccountGroupGetResponseValue = new AccountGroupGetResponse()
    this._AccountGroupGetResponseModel = new AccountGroupGetResponseModel()
    this._AccountGroupsGetAllRequestValue = new AccountGroupsGetAllRequest()
    this._AccountGroupsGetAllRequestModel = new AccountGroupsGetAllRequestModel()
    this._AccountGroupsGetAllResponseValue = new AccountGroupsGetAllResponse()
    this._AccountGroupsGetAllResponseModel = new AccountGroupsGetAllResponseModel()
    this._AccountGroupCreateRequestValue = new AccountGroupCreateRequest()
    this._AccountGroupCreateRequestModel = new AccountGroupCreateRequestModel()
    this._AccountGroupCreateResponseValue = new AccountGroupCreateResponse()
    this._AccountGroupCreateResponseModel = new AccountGroupCreateResponseModel()
    this._AccountGroupModifyRequestValue = new AccountGroupModifyRequest()
    this._AccountGroupModifyRequestModel = new AccountGroupModifyRequestModel()
    this._AccountGroupModifyResponseValue = new AccountGroupModifyResponse()
    this._AccountGroupModifyResponseModel = new AccountGroupModifyResponseModel()
    this._AccountGroupDeleteRequestValue = new AccountGroupDeleteRequest()
    this._AccountGroupDeleteRequestModel = new AccountGroupDeleteRequestModel()
    this._AccountGroupDeleteResponseValue = new AccountGroupDeleteResponse()
    this._AccountGroupDeleteResponseModel = new AccountGroupDeleteResponseModel()
    this._AccountGroupUpdateNotifyValue = new AccountGroupUpdateNotify()
    this._AccountGroupUpdateNotifyModel = new AccountGroupUpdateNotifyModel()
    this._AccountGetRequestValue = new AccountGetRequest()
    this._AccountGetRequestModel = new AccountGetRequestModel()
    this._AccountGetResponseValue = new AccountGetResponse()
    this._AccountGetResponseModel = new AccountGetResponseModel()
    this._AccountsGetAllRequestValue = new AccountsGetAllRequest()
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseValue = new AccountsGetAllResponse()
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel()
    this._AccountCreateRequestValue = new AccountCreateRequest()
    this._AccountCreateRequestModel = new AccountCreateRequestModel()
    this._AccountCreateResponseValue = new AccountCreateResponse()
    this._AccountCreateResponseModel = new AccountCreateResponseModel()
    this._AccountSocialTradingMasterCreateRequestValue = new AccountSocialTradingMasterCreateRequest()
    this._AccountSocialTradingMasterCreateRequestModel = new AccountSocialTradingMasterCreateRequestModel()
    this._AccountSocialTradingMasterCreateResponseValue = new AccountSocialTradingMasterCreateResponse()
    this._AccountSocialTradingMasterCreateResponseModel = new AccountSocialTradingMasterCreateResponseModel()
    this._AccountSocialTradingInvestorCreateRequestValue = new AccountSocialTradingInvestorCreateRequest()
    this._AccountSocialTradingInvestorCreateRequestModel = new AccountSocialTradingInvestorCreateRequestModel()
    this._AccountSocialTradingInvestorCreateResponseValue = new AccountSocialTradingInvestorCreateResponse()
    this._AccountSocialTradingInvestorCreateResponseModel = new AccountSocialTradingInvestorCreateResponseModel()
    this._AccountModifyRequestValue = new AccountModifyRequest()
    this._AccountModifyRequestModel = new AccountModifyRequestModel()
    this._AccountModifyResponseValue = new AccountModifyResponse()
    this._AccountModifyResponseModel = new AccountModifyResponseModel()
    this._AccountDeleteRequestValue = new AccountDeleteRequest()
    this._AccountDeleteRequestModel = new AccountDeleteRequestModel()
    this._AccountDeleteResponseValue = new AccountDeleteResponse()
    this._AccountDeleteResponseModel = new AccountDeleteResponseModel()
    this._DemoAccountsDeleteRequestValue = new DemoAccountsDeleteRequest()
    this._DemoAccountsDeleteRequestModel = new DemoAccountsDeleteRequestModel()
    this._DemoAccountsDeleteResponseValue = new DemoAccountsDeleteResponse()
    this._DemoAccountsDeleteResponseModel = new DemoAccountsDeleteResponseModel()
    this._AccountUpgradeToSocialTradingMasterRequestValue = new AccountUpgradeToSocialTradingMasterRequest()
    this._AccountUpgradeToSocialTradingMasterRequestModel = new AccountUpgradeToSocialTradingMasterRequestModel()
    this._AccountUpgradeToSocialTradingMasterResponseValue = new AccountUpgradeToSocialTradingMasterResponse()
    this._AccountUpgradeToSocialTradingMasterResponseModel = new AccountUpgradeToSocialTradingMasterResponseModel()
    this._AccountUpgradeToSocialTradingInvestorRequestValue = new AccountUpgradeToSocialTradingInvestorRequest()
    this._AccountUpgradeToSocialTradingInvestorRequestModel = new AccountUpgradeToSocialTradingInvestorRequestModel()
    this._AccountUpgradeToSocialTradingInvestorResponseValue = new AccountUpgradeToSocialTradingInvestorResponse()
    this._AccountUpgradeToSocialTradingInvestorResponseModel = new AccountUpgradeToSocialTradingInvestorResponseModel()
    this._SocialTradingInvestorAccountToRegularRequestValue = new SocialTradingInvestorAccountToRegularRequest()
    this._SocialTradingInvestorAccountToRegularRequestModel = new SocialTradingInvestorAccountToRegularRequestModel()
    this._SocialTradingInvestorAccountToRegularResponseValue = new SocialTradingInvestorAccountToRegularResponse()
    this._SocialTradingInvestorAccountToRegularResponseModel = new SocialTradingInvestorAccountToRegularResponseModel()
    this._SocialTradingMasterAccountToRegularRequestValue = new SocialTradingMasterAccountToRegularRequest()
    this._SocialTradingMasterAccountToRegularRequestModel = new SocialTradingMasterAccountToRegularRequestModel()
    this._SocialTradingMasterAccountToRegularResponseValue = new SocialTradingMasterAccountToRegularResponse()
    this._SocialTradingMasterAccountToRegularResponseModel = new SocialTradingMasterAccountToRegularResponseModel()
    this._AccountUpdateNotifyValue = new AccountUpdateNotify()
    this._AccountUpdateNotifyModel = new AccountUpdateNotifyModel()
    this._MarginGetRequestValue = new MarginGetRequest()
    this._MarginGetRequestModel = new MarginGetRequestModel()
    this._MarginGetResponseValue = new MarginGetResponse()
    this._MarginGetResponseModel = new MarginGetResponseModel()
    this._MarginsGetAllRequestValue = new MarginsGetAllRequest()
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseValue = new MarginsGetAllResponse()
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel()
    this._MarginUpdateNotifyValue = new MarginUpdateNotify()
    this._MarginUpdateNotifyModel = new MarginUpdateNotifyModel()
    this._MarginCallNotifyValue = new MarginCallNotify()
    this._MarginCallNotifyModel = new MarginCallNotifyModel()
    this._StopOutNotifyValue = new StopOutNotify()
    this._StopOutNotifyModel = new StopOutNotifyModel()
    this._AssetGetRequestValue = new AssetGetRequest()
    this._AssetGetRequestModel = new AssetGetRequestModel()
    this._AssetGetResponseValue = new AssetGetResponse()
    this._AssetGetResponseModel = new AssetGetResponseModel()
    this._AssetsGetRequestValue = new AssetsGetRequest()
    this._AssetsGetRequestModel = new AssetsGetRequestModel()
    this._AssetsGetResponseValue = new AssetsGetResponse()
    this._AssetsGetResponseModel = new AssetsGetResponseModel()
    this._AssetsGetAllRequestValue = new AssetsGetAllRequest()
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseValue = new AssetsGetAllResponse()
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel()
    this._AssetUpdateNotifyValue = new AssetUpdateNotify()
    this._AssetUpdateNotifyModel = new AssetUpdateNotifyModel()
    this._OrderGetRequestValue = new OrderGetRequest()
    this._OrderGetRequestModel = new OrderGetRequestModel()
    this._OrderGetResponseValue = new OrderGetResponse()
    this._OrderGetResponseModel = new OrderGetResponseModel()
    this._OrdersGetRequestValue = new OrdersGetRequest()
    this._OrdersGetRequestModel = new OrdersGetRequestModel()
    this._OrdersGetResponseValue = new OrdersGetResponse()
    this._OrdersGetResponseModel = new OrdersGetResponseModel()
    this._OrdersGetAllRequestValue = new OrdersGetAllRequest()
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseValue = new OrdersGetAllResponse()
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel()
    this._OrderCreateRequestValue = new OrderCreateRequest()
    this._OrderCreateRequestModel = new OrderCreateRequestModel()
    this._OrderCreateResponseValue = new OrderCreateResponse()
    this._OrderCreateResponseModel = new OrderCreateResponseModel()
    this._OrderModifyRequestValue = new OrderModifyRequest()
    this._OrderModifyRequestModel = new OrderModifyRequestModel()
    this._OrderModifyResponseValue = new OrderModifyResponse()
    this._OrderModifyResponseModel = new OrderModifyResponseModel()
    this._OrderActivateRequestValue = new OrderActivateRequest()
    this._OrderActivateRequestModel = new OrderActivateRequestModel()
    this._OrderActivateResponseValue = new OrderActivateResponse()
    this._OrderActivateResponseModel = new OrderActivateResponseModel()
    this._OrderCancelRequestValue = new OrderCancelRequest()
    this._OrderCancelRequestModel = new OrderCancelRequestModel()
    this._OrderCancelResponseValue = new OrderCancelResponse()
    this._OrderCancelResponseModel = new OrderCancelResponseModel()
    this._OrderCancelAllRequestValue = new OrderCancelAllRequest()
    this._OrderCancelAllRequestModel = new OrderCancelAllRequestModel()
    this._OrderCancelAllResponseValue = new OrderCancelAllResponse()
    this._OrderCancelAllResponseModel = new OrderCancelAllResponseModel()
    this._OrderCancelAllByIdRequestValue = new OrderCancelAllByIdRequest()
    this._OrderCancelAllByIdRequestModel = new OrderCancelAllByIdRequestModel()
    this._OrderCloseRequestValue = new OrderCloseRequest()
    this._OrderCloseRequestModel = new OrderCloseRequestModel()
    this._OrderCloseResponseValue = new OrderCloseResponse()
    this._OrderCloseResponseModel = new OrderCloseResponseModel()
    this._OrderCloseAllRequestValue = new OrderCloseAllRequest()
    this._OrderCloseAllRequestModel = new OrderCloseAllRequestModel()
    this._OrderCloseAllResponseValue = new OrderCloseAllResponse()
    this._OrderCloseAllResponseModel = new OrderCloseAllResponseModel()
    this._OrderCloseAllByIdRequestValue = new OrderCloseAllByIdRequest()
    this._OrderCloseAllByIdRequestModel = new OrderCloseAllByIdRequestModel()
    this._OrderCloseByRequestValue = new OrderCloseByRequest()
    this._OrderCloseByRequestModel = new OrderCloseByRequestModel()
    this._OrderCloseByResponseValue = new OrderCloseByResponse()
    this._OrderCloseByResponseModel = new OrderCloseByResponseModel()
    this._OrderCloseByAllRequestValue = new OrderCloseByAllRequest()
    this._OrderCloseByAllRequestModel = new OrderCloseByAllRequestModel()
    this._OrderCloseByAllResponseValue = new OrderCloseByAllResponse()
    this._OrderCloseByAllResponseModel = new OrderCloseByAllResponseModel()
    this._OrderCancelOrCloseRequestValue = new OrderCancelOrCloseRequest()
    this._OrderCancelOrCloseRequestModel = new OrderCancelOrCloseRequestModel()
    this._OrderCancelOrCloseResponseValue = new OrderCancelOrCloseResponse()
    this._OrderCancelOrCloseResponseModel = new OrderCancelOrCloseResponseModel()
    this._OrderCancelOrCloseAllRequestValue = new OrderCancelOrCloseAllRequest()
    this._OrderCancelOrCloseAllRequestModel = new OrderCancelOrCloseAllRequestModel()
    this._OrderCancelOrCloseAllResponseValue = new OrderCancelOrCloseAllResponse()
    this._OrderCancelOrCloseAllResponseModel = new OrderCancelOrCloseAllResponseModel()
    this._OrderUpdateNotifyValue = new OrderUpdateNotify()
    this._OrderUpdateNotifyModel = new OrderUpdateNotifyModel()
    this._PositionGetRequestValue = new PositionGetRequest()
    this._PositionGetRequestModel = new PositionGetRequestModel()
    this._PositionGetResponseValue = new PositionGetResponse()
    this._PositionGetResponseModel = new PositionGetResponseModel()
    this._PositionsGetRequestValue = new PositionsGetRequest()
    this._PositionsGetRequestModel = new PositionsGetRequestModel()
    this._PositionsGetResponseValue = new PositionsGetResponse()
    this._PositionsGetResponseModel = new PositionsGetResponseModel()
    this._PositionsGetAllRequestValue = new PositionsGetAllRequest()
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseValue = new PositionsGetAllResponse()
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel()
    this._PositionUpdateNotifyValue = new PositionUpdateNotify()
    this._PositionUpdateNotifyModel = new PositionUpdateNotifyModel()
    this._TradeHistoryRequestValue = new TradeHistoryRequest()
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseValue = new TradeHistoryResponse()
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel()
    this._TradeHistoryFilterRequestValue = new TradeHistoryFilterRequest()
    this._TradeHistoryFilterRequestModel = new TradeHistoryFilterRequestModel()
    this._TradeHistoryByIdRequestValue = new TradeHistoryByIdRequest()
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseValue = new TradeHistoryByIdResponse()
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel()
    this._TradeHistoryUpdateNotifyValue = new TradeHistoryUpdateNotify()
    this._TradeHistoryUpdateNotifyModel = new TradeHistoryUpdateNotifyModel()
    this._TestRolloverDumpRequestValue = new TestRolloverDumpRequest()
    this._TestRolloverDumpRequestModel = new TestRolloverDumpRequestModel()
    this._TestRolloverDumpResponseValue = new TestRolloverDumpResponse()
    this._TestRolloverDumpResponseModel = new TestRolloverDumpResponseModel()
    this._Deprecated001Value = new Deprecated001()
    this._Deprecated001Model = new Deprecated001Model()
    this._Deprecated002Value = new Deprecated002()
    this._Deprecated002Model = new Deprecated002Model()
    this._Deprecated003Value = new Deprecated003()
    this._Deprecated003Model = new Deprecated003Model()
    this._Deprecated004Value = new Deprecated004()
    this._Deprecated004Model = new Deprecated004Model()
    this._Deprecated005Value = new Deprecated005()
    this._Deprecated005Model = new Deprecated005Model()
    this._Deprecated006Value = new Deprecated006()
    this._Deprecated006Model = new Deprecated006Model()
    this._Deprecated007Value = new Deprecated007()
    this._Deprecated007Model = new Deprecated007Model()
    this._Deprecated008Value = new Deprecated008()
    this._Deprecated008Model = new Deprecated008Model()
    this._Deprecated009Value = new Deprecated009()
    this._Deprecated009Model = new Deprecated009Model()
    this._Deprecated010Value = new Deprecated010()
    this._Deprecated010Model = new Deprecated010Model()
    this._Deprecated011Value = new Deprecated011()
    this._Deprecated011Model = new Deprecated011Model()
    this._Deprecated012Value = new Deprecated012()
    this._Deprecated012Model = new Deprecated012Model()
    this._Deprecated013Value = new Deprecated013()
    this._Deprecated013Model = new Deprecated013Model()
    this._DepositAccountRequestValue = new DepositAccountRequest()
    this._DepositAccountRequestModel = new DepositAccountRequestModel()
    this._DepositAccountResponseValue = new DepositAccountResponse()
    this._DepositAccountResponseModel = new DepositAccountResponseModel()
    this._CreditAccountRequestValue = new CreditAccountRequest()
    this._CreditAccountRequestModel = new CreditAccountRequestModel()
    this._CreditAccountResponseValue = new CreditAccountResponse()
    this._CreditAccountResponseModel = new CreditAccountResponseModel()
    this._TransferMoneyRequestValue = new TransferMoneyRequest()
    this._TransferMoneyRequestModel = new TransferMoneyRequestModel()
    this._TransferMoneyResponseValue = new TransferMoneyResponse()
    this._TransferMoneyResponseModel = new TransferMoneyResponseModel()
    this._TradingCalendarRegularRuleGetRequestValue = new TradingCalendarRegularRuleGetRequest()
    this._TradingCalendarRegularRuleGetRequestModel = new TradingCalendarRegularRuleGetRequestModel()
    this._TradingCalendarRegularRuleGetResponseValue = new TradingCalendarRegularRuleGetResponse()
    this._TradingCalendarRegularRuleGetResponseModel = new TradingCalendarRegularRuleGetResponseModel()
    this._TradingCalendarRegularRulesGetAllRequestValue = new TradingCalendarRegularRulesGetAllRequest()
    this._TradingCalendarRegularRulesGetAllRequestModel = new TradingCalendarRegularRulesGetAllRequestModel()
    this._TradingCalendarRegularRulesGetAllResponseValue = new TradingCalendarRegularRulesGetAllResponse()
    this._TradingCalendarRegularRulesGetAllResponseModel = new TradingCalendarRegularRulesGetAllResponseModel()
    this._TradingCalendarRegularRuleCreateRequestValue = new TradingCalendarRegularRuleCreateRequest()
    this._TradingCalendarRegularRuleCreateRequestModel = new TradingCalendarRegularRuleCreateRequestModel()
    this._TradingCalendarRegularRuleCreateResponseValue = new TradingCalendarRegularRuleCreateResponse()
    this._TradingCalendarRegularRuleCreateResponseModel = new TradingCalendarRegularRuleCreateResponseModel()
    this._TradingCalendarRegularRuleModifyRequestValue = new TradingCalendarRegularRuleModifyRequest()
    this._TradingCalendarRegularRuleModifyRequestModel = new TradingCalendarRegularRuleModifyRequestModel()
    this._TradingCalendarRegularRuleModifyResponseValue = new TradingCalendarRegularRuleModifyResponse()
    this._TradingCalendarRegularRuleModifyResponseModel = new TradingCalendarRegularRuleModifyResponseModel()
    this._TradingCalendarRegularRuleDeleteRequestValue = new TradingCalendarRegularRuleDeleteRequest()
    this._TradingCalendarRegularRuleDeleteRequestModel = new TradingCalendarRegularRuleDeleteRequestModel()
    this._TradingCalendarRegularRuleDeleteResponseValue = new TradingCalendarRegularRuleDeleteResponse()
    this._TradingCalendarRegularRuleDeleteResponseModel = new TradingCalendarRegularRuleDeleteResponseModel()
    this._TradingCalendarRegularRuleUpdateNotifyValue = new TradingCalendarRegularRuleUpdateNotify()
    this._TradingCalendarRegularRuleUpdateNotifyModel = new TradingCalendarRegularRuleUpdateNotifyModel()
    this._TradingCalendarOffTimeRuleGetRequestValue = new TradingCalendarOffTimeRuleGetRequest()
    this._TradingCalendarOffTimeRuleGetRequestModel = new TradingCalendarOffTimeRuleGetRequestModel()
    this._TradingCalendarOffTimeRuleGetResponseValue = new TradingCalendarOffTimeRuleGetResponse()
    this._TradingCalendarOffTimeRuleGetResponseModel = new TradingCalendarOffTimeRuleGetResponseModel()
    this._TradingCalendarOffTimeRulesGetAllRequestValue = new TradingCalendarOffTimeRulesGetAllRequest()
    this._TradingCalendarOffTimeRulesGetAllRequestModel = new TradingCalendarOffTimeRulesGetAllRequestModel()
    this._TradingCalendarOffTimeRulesGetAllResponseValue = new TradingCalendarOffTimeRulesGetAllResponse()
    this._TradingCalendarOffTimeRulesGetAllResponseModel = new TradingCalendarOffTimeRulesGetAllResponseModel()
    this._TradingCalendarOffTimeRuleCreateRequestValue = new TradingCalendarOffTimeRuleCreateRequest()
    this._TradingCalendarOffTimeRuleCreateRequestModel = new TradingCalendarOffTimeRuleCreateRequestModel()
    this._TradingCalendarOffTimeRuleCreateResponseValue = new TradingCalendarOffTimeRuleCreateResponse()
    this._TradingCalendarOffTimeRuleCreateResponseModel = new TradingCalendarOffTimeRuleCreateResponseModel()
    this._TradingCalendarOffTimeRuleModifyRequestValue = new TradingCalendarOffTimeRuleModifyRequest()
    this._TradingCalendarOffTimeRuleModifyRequestModel = new TradingCalendarOffTimeRuleModifyRequestModel()
    this._TradingCalendarOffTimeRuleModifyResponseValue = new TradingCalendarOffTimeRuleModifyResponse()
    this._TradingCalendarOffTimeRuleModifyResponseModel = new TradingCalendarOffTimeRuleModifyResponseModel()
    this._TradingCalendarOffTimeRuleDeleteRequestValue = new TradingCalendarOffTimeRuleDeleteRequest()
    this._TradingCalendarOffTimeRuleDeleteRequestModel = new TradingCalendarOffTimeRuleDeleteRequestModel()
    this._TradingCalendarOffTimeRuleDeleteResponseValue = new TradingCalendarOffTimeRuleDeleteResponse()
    this._TradingCalendarOffTimeRuleDeleteResponseModel = new TradingCalendarOffTimeRuleDeleteResponseModel()
    this._TradingCalendarOffTimeRuleUpdateNotifyValue = new TradingCalendarOffTimeRuleUpdateNotify()
    this._TradingCalendarOffTimeRuleUpdateNotifyModel = new TradingCalendarOffTimeRuleUpdateNotifyModel()
    this._TradingCalendarRulesGetRequestValue = new TradingCalendarRulesGetRequest()
    this._TradingCalendarRulesGetRequestModel = new TradingCalendarRulesGetRequestModel()
    this._TradingCalendarRulesGetResponseValue = new TradingCalendarRulesGetResponse()
    this._TradingCalendarRulesGetResponseModel = new TradingCalendarRulesGetResponseModel()
    this._TradingRouteRulesGetRequestValue = new TradingRouteRulesGetRequest()
    this._TradingRouteRulesGetRequestModel = new TradingRouteRulesGetRequestModel()
    this._TradingRouteRulesGetResponseValue = new TradingRouteRulesGetResponse()
    this._TradingRouteRulesGetResponseModel = new TradingRouteRulesGetResponseModel()
    this._TradingRouteRuleCreateRequestValue = new TradingRouteRuleCreateRequest()
    this._TradingRouteRuleCreateRequestModel = new TradingRouteRuleCreateRequestModel()
    this._TradingRouteRuleCreateResponseValue = new TradingRouteRuleCreateResponse()
    this._TradingRouteRuleCreateResponseModel = new TradingRouteRuleCreateResponseModel()
    this._TradingRouteRuleModifyRequestValue = new TradingRouteRuleModifyRequest()
    this._TradingRouteRuleModifyRequestModel = new TradingRouteRuleModifyRequestModel()
    this._TradingRouteRuleModifyResponseValue = new TradingRouteRuleModifyResponse()
    this._TradingRouteRuleModifyResponseModel = new TradingRouteRuleModifyResponseModel()
    this._TradingRouteRuleDeleteRequestValue = new TradingRouteRuleDeleteRequest()
    this._TradingRouteRuleDeleteRequestModel = new TradingRouteRuleDeleteRequestModel()
    this._TradingRouteRuleDeleteResponseValue = new TradingRouteRuleDeleteResponse()
    this._TradingRouteRuleDeleteResponseModel = new TradingRouteRuleDeleteResponseModel()
    this._TradingRouteRuleUpdateNotifyValue = new TradingRouteRuleUpdateNotify()
    this._TradingRouteRuleUpdateNotifyModel = new TradingRouteRuleUpdateNotifyModel()
    this._SymbolMapsGetRequestValue = new SymbolMapsGetRequest()
    this._SymbolMapsGetRequestModel = new SymbolMapsGetRequestModel()
    this._SymbolMapsGetResponseValue = new SymbolMapsGetResponse()
    this._SymbolMapsGetResponseModel = new SymbolMapsGetResponseModel()
    this._SymbolMapCreateRequestValue = new SymbolMapCreateRequest()
    this._SymbolMapCreateRequestModel = new SymbolMapCreateRequestModel()
    this._SymbolMapCreateResponseValue = new SymbolMapCreateResponse()
    this._SymbolMapCreateResponseModel = new SymbolMapCreateResponseModel()
    this._SymbolMapModifyRequestValue = new SymbolMapModifyRequest()
    this._SymbolMapModifyRequestModel = new SymbolMapModifyRequestModel()
    this._SymbolMapModifyResponseValue = new SymbolMapModifyResponse()
    this._SymbolMapModifyResponseModel = new SymbolMapModifyResponseModel()
    this._SymbolMapDeleteRequestValue = new SymbolMapDeleteRequest()
    this._SymbolMapDeleteRequestModel = new SymbolMapDeleteRequestModel()
    this._SymbolMapDeleteResponseValue = new SymbolMapDeleteResponse()
    this._SymbolMapDeleteResponseModel = new SymbolMapDeleteResponseModel()
    this._SymbolMapUpdateNotifyValue = new SymbolMapUpdateNotify()
    this._SymbolMapUpdateNotifyModel = new SymbolMapUpdateNotifyModel()
    this._GatewaysGetRequestValue = new GatewaysGetRequest()
    this._GatewaysGetRequestModel = new GatewaysGetRequestModel()
    this._GatewaysGetResponseValue = new GatewaysGetResponse()
    this._GatewaysGetResponseModel = new GatewaysGetResponseModel()
    this._GatewayCreateRequestValue = new GatewayCreateRequest()
    this._GatewayCreateRequestModel = new GatewayCreateRequestModel()
    this._GatewayCreateResponseValue = new GatewayCreateResponse()
    this._GatewayCreateResponseModel = new GatewayCreateResponseModel()
    this._GatewayModifyRequestValue = new GatewayModifyRequest()
    this._GatewayModifyRequestModel = new GatewayModifyRequestModel()
    this._GatewayModifyResponseValue = new GatewayModifyResponse()
    this._GatewayModifyResponseModel = new GatewayModifyResponseModel()
    this._GatewayDeleteRequestValue = new GatewayDeleteRequest()
    this._GatewayDeleteRequestModel = new GatewayDeleteRequestModel()
    this._GatewayDeleteResponseValue = new GatewayDeleteResponse()
    this._GatewayDeleteResponseModel = new GatewayDeleteResponseModel()
    this._GatewayUpdateNotifyValue = new GatewayUpdateNotify()
    this._GatewayUpdateNotifyModel = new GatewayUpdateNotifyModel()
    this._DataFeedsGetRequestValue = new DataFeedsGetRequest()
    this._DataFeedsGetRequestModel = new DataFeedsGetRequestModel()
    this._DataFeedsGetResponseValue = new DataFeedsGetResponse()
    this._DataFeedsGetResponseModel = new DataFeedsGetResponseModel()
    this._DataFeedCreateRequestValue = new DataFeedCreateRequest()
    this._DataFeedCreateRequestModel = new DataFeedCreateRequestModel()
    this._DataFeedCreateResponseValue = new DataFeedCreateResponse()
    this._DataFeedCreateResponseModel = new DataFeedCreateResponseModel()
    this._DataFeedModifyRequestValue = new DataFeedModifyRequest()
    this._DataFeedModifyRequestModel = new DataFeedModifyRequestModel()
    this._DataFeedModifyResponseValue = new DataFeedModifyResponse()
    this._DataFeedModifyResponseModel = new DataFeedModifyResponseModel()
    this._DataFeedDeleteRequestValue = new DataFeedDeleteRequest()
    this._DataFeedDeleteRequestModel = new DataFeedDeleteRequestModel()
    this._DataFeedDeleteResponseValue = new DataFeedDeleteResponse()
    this._DataFeedDeleteResponseModel = new DataFeedDeleteResponseModel()
    this._DataFeedUpdateNotifyValue = new DataFeedUpdateNotify()
    this._DataFeedUpdateNotifyModel = new DataFeedUpdateNotifyModel()
    this._ModuleSystemInfoNotifyValue = new ModuleSystemInfoNotify()
    this._ModuleSystemInfoNotifyModel = new ModuleSystemInfoNotifyModel()
    this._ModulesSystemInfoGetAllRequestValue = new ModulesSystemInfoGetAllRequest()
    this._ModulesSystemInfoGetAllRequestModel = new ModulesSystemInfoGetAllRequestModel()
    this._ModulesSystemInfoGetAllResponseValue = new ModulesSystemInfoGetAllResponse()
    this._ModulesSystemInfoGetAllResponseModel = new ModulesSystemInfoGetAllResponseModel()
    this._ModulesSystemInfoNotifyValue = new ModulesSystemInfoNotify()
    this._ModulesSystemInfoNotifyModel = new ModulesSystemInfoNotifyModel()
    this._ChartBarHistoryModifyRequestValue = new ChartBarHistoryModifyRequest()
    this._ChartBarHistoryModifyRequestModel = new ChartBarHistoryModifyRequestModel()
    this._ChartBarHistoryModifyResponseValue = new ChartBarHistoryModifyResponse()
    this._ChartBarHistoryModifyResponseModel = new ChartBarHistoryModifyResponseModel()
    this._FeedUpdateTickSnapshotRequestValue = new FeedUpdateTickSnapshotRequest()
    this._FeedUpdateTickSnapshotRequestModel = new FeedUpdateTickSnapshotRequestModel()
    this._FeedUpdateTickSnapshotResponseValue = new FeedUpdateTickSnapshotResponse()
    this._FeedUpdateTickSnapshotResponseModel = new FeedUpdateTickSnapshotResponseModel()
    this._FeedUpdateTickRequestValue = new FeedUpdateTickRequest()
    this._FeedUpdateTickRequestModel = new FeedUpdateTickRequestModel()
    this._FeedUpdateTickResponseValue = new FeedUpdateTickResponse()
    this._FeedUpdateTickResponseModel = new FeedUpdateTickResponseModel()
    this._FeedUpdateLevel2SnapshotRequestValue = new FeedUpdateLevel2SnapshotRequest()
    this._FeedUpdateLevel2SnapshotRequestModel = new FeedUpdateLevel2SnapshotRequestModel()
    this._FeedUpdateLevel2SnapshotResponseValue = new FeedUpdateLevel2SnapshotResponse()
    this._FeedUpdateLevel2SnapshotResponseModel = new FeedUpdateLevel2SnapshotResponseModel()
    this._FeedUpdateLevel2RequestValue = new FeedUpdateLevel2Request()
    this._FeedUpdateLevel2RequestModel = new FeedUpdateLevel2RequestModel()
    this._FeedUpdateLevel2ResponseValue = new FeedUpdateLevel2Response()
    this._FeedUpdateLevel2ResponseModel = new FeedUpdateLevel2ResponseModel()
    this._MailServerGetRequestValue = new MailServerGetRequest()
    this._MailServerGetRequestModel = new MailServerGetRequestModel()
    this._MailServerGetResponseValue = new MailServerGetResponse()
    this._MailServerGetResponseModel = new MailServerGetResponseModel()
    this._MailServersGetAllRequestValue = new MailServersGetAllRequest()
    this._MailServersGetAllRequestModel = new MailServersGetAllRequestModel()
    this._MailServersGetAllResponseValue = new MailServersGetAllResponse()
    this._MailServersGetAllResponseModel = new MailServersGetAllResponseModel()
    this._MailServerCreateRequestValue = new MailServerCreateRequest()
    this._MailServerCreateRequestModel = new MailServerCreateRequestModel()
    this._MailServerCreateResponseValue = new MailServerCreateResponse()
    this._MailServerCreateResponseModel = new MailServerCreateResponseModel()
    this._MailServerModifyRequestValue = new MailServerModifyRequest()
    this._MailServerModifyRequestModel = new MailServerModifyRequestModel()
    this._MailServerModifyResponseValue = new MailServerModifyResponse()
    this._MailServerModifyResponseModel = new MailServerModifyResponseModel()
    this._MailServerDeleteRequestValue = new MailServerDeleteRequest()
    this._MailServerDeleteRequestModel = new MailServerDeleteRequestModel()
    this._MailServerDeleteResponseValue = new MailServerDeleteResponse()
    this._MailServerDeleteResponseModel = new MailServerDeleteResponseModel()
    this._MailServerUpdateNotifyValue = new MailServerUpdateNotify()
    this._MailServerUpdateNotifyModel = new MailServerUpdateNotifyModel()
    this._AccountStatesUpdateNotifyValue = new AccountStatesUpdateNotify()
    this._AccountStatesUpdateNotifyModel = new AccountStatesUpdateNotifyModel()
    this._AccountStatesGetAllRequestValue = new AccountStatesGetAllRequest()
    this._AccountStatesGetAllRequestModel = new AccountStatesGetAllRequestModel()
    this._AccountStatesGetAllResponseValue = new AccountStatesGetAllResponse()
    this._AccountStatesGetAllResponseModel = new AccountStatesGetAllResponseModel()
    this._ModuleAuthRequestValue = new ModuleAuthRequest()
    this._ModuleAuthRequestModel = new ModuleAuthRequestModel()
    this._ModuleAuthResponseValue = new ModuleAuthResponse()
    this._ModuleAuthResponseModel = new ModuleAuthResponseModel()
    this._LeverageProfileGetRequestValue = new LeverageProfileGetRequest()
    this._LeverageProfileGetRequestModel = new LeverageProfileGetRequestModel()
    this._LeverageProfileGetResponseValue = new LeverageProfileGetResponse()
    this._LeverageProfileGetResponseModel = new LeverageProfileGetResponseModel()
    this._LeverageProfilesGetAllRequestValue = new LeverageProfilesGetAllRequest()
    this._LeverageProfilesGetAllRequestModel = new LeverageProfilesGetAllRequestModel()
    this._LeverageProfilesGetAllResponseValue = new LeverageProfilesGetAllResponse()
    this._LeverageProfilesGetAllResponseModel = new LeverageProfilesGetAllResponseModel()
    this._LeverageProfileCreateRequestValue = new LeverageProfileCreateRequest()
    this._LeverageProfileCreateRequestModel = new LeverageProfileCreateRequestModel()
    this._LeverageProfileCreateResponseValue = new LeverageProfileCreateResponse()
    this._LeverageProfileCreateResponseModel = new LeverageProfileCreateResponseModel()
    this._LeverageProfileModifyRequestValue = new LeverageProfileModifyRequest()
    this._LeverageProfileModifyRequestModel = new LeverageProfileModifyRequestModel()
    this._LeverageProfileModifyResponseValue = new LeverageProfileModifyResponse()
    this._LeverageProfileModifyResponseModel = new LeverageProfileModifyResponseModel()
    this._LeverageProfileDeleteRequestValue = new LeverageProfileDeleteRequest()
    this._LeverageProfileDeleteRequestModel = new LeverageProfileDeleteRequestModel()
    this._LeverageProfileDeleteResponseValue = new LeverageProfileDeleteResponse()
    this._LeverageProfileDeleteResponseModel = new LeverageProfileDeleteResponseModel()
    this._LeverageProfileUpdateNotifyValue = new LeverageProfileUpdateNotify()
    this._LeverageProfileUpdateNotifyModel = new LeverageProfileUpdateNotifyModel()
    this._MarginRateProfileGetRequestValue = new MarginRateProfileGetRequest()
    this._MarginRateProfileGetRequestModel = new MarginRateProfileGetRequestModel()
    this._MarginRateProfileGetResponseValue = new MarginRateProfileGetResponse()
    this._MarginRateProfileGetResponseModel = new MarginRateProfileGetResponseModel()
    this._MarginRateProfilesGetAllRequestValue = new MarginRateProfilesGetAllRequest()
    this._MarginRateProfilesGetAllRequestModel = new MarginRateProfilesGetAllRequestModel()
    this._MarginRateProfilesGetAllResponseValue = new MarginRateProfilesGetAllResponse()
    this._MarginRateProfilesGetAllResponseModel = new MarginRateProfilesGetAllResponseModel()
    this._MarginRateProfileCreateRequestValue = new MarginRateProfileCreateRequest()
    this._MarginRateProfileCreateRequestModel = new MarginRateProfileCreateRequestModel()
    this._MarginRateProfileCreateResponseValue = new MarginRateProfileCreateResponse()
    this._MarginRateProfileCreateResponseModel = new MarginRateProfileCreateResponseModel()
    this._MarginRateProfileModifyRequestValue = new MarginRateProfileModifyRequest()
    this._MarginRateProfileModifyRequestModel = new MarginRateProfileModifyRequestModel()
    this._MarginRateProfileModifyResponseValue = new MarginRateProfileModifyResponse()
    this._MarginRateProfileModifyResponseModel = new MarginRateProfileModifyResponseModel()
    this._MarginRateProfileDeleteRequestValue = new MarginRateProfileDeleteRequest()
    this._MarginRateProfileDeleteRequestModel = new MarginRateProfileDeleteRequestModel()
    this._MarginRateProfileDeleteResponseValue = new MarginRateProfileDeleteResponse()
    this._MarginRateProfileDeleteResponseModel = new MarginRateProfileDeleteResponseModel()
    this._MarginRateProfileUpdateNotifyValue = new MarginRateProfileUpdateNotify()
    this._MarginRateProfileUpdateNotifyModel = new MarginRateProfileUpdateNotifyModel()
    this._NewsProfileGetRequestValue = new NewsProfileGetRequest()
    this._NewsProfileGetRequestModel = new NewsProfileGetRequestModel()
    this._NewsProfileGetResponseValue = new NewsProfileGetResponse()
    this._NewsProfileGetResponseModel = new NewsProfileGetResponseModel()
    this._NewsProfilesGetAllRequestValue = new NewsProfilesGetAllRequest()
    this._NewsProfilesGetAllRequestModel = new NewsProfilesGetAllRequestModel()
    this._NewsProfilesGetAllResponseValue = new NewsProfilesGetAllResponse()
    this._NewsProfilesGetAllResponseModel = new NewsProfilesGetAllResponseModel()
    this._NewsProfileCreateRequestValue = new NewsProfileCreateRequest()
    this._NewsProfileCreateRequestModel = new NewsProfileCreateRequestModel()
    this._NewsProfileCreateResponseValue = new NewsProfileCreateResponse()
    this._NewsProfileCreateResponseModel = new NewsProfileCreateResponseModel()
    this._NewsProfileModifyRequestValue = new NewsProfileModifyRequest()
    this._NewsProfileModifyRequestModel = new NewsProfileModifyRequestModel()
    this._NewsProfileModifyResponseValue = new NewsProfileModifyResponse()
    this._NewsProfileModifyResponseModel = new NewsProfileModifyResponseModel()
    this._NewsProfileDeleteRequestValue = new NewsProfileDeleteRequest()
    this._NewsProfileDeleteRequestModel = new NewsProfileDeleteRequestModel()
    this._NewsProfileDeleteResponseValue = new NewsProfileDeleteResponse()
    this._NewsProfileDeleteResponseModel = new NewsProfileDeleteResponseModel()
    this._NewsProfileUpdateNotifyValue = new NewsProfileUpdateNotify()
    this._NewsProfileUpdateNotifyModel = new NewsProfileUpdateNotifyModel()
    this._NewsProfileImportRequestValue = new NewsProfileImportRequest()
    this._NewsProfileImportRequestModel = new NewsProfileImportRequestModel()
    this._NewsProfileImportResponseValue = new NewsProfileImportResponse()
    this._NewsProfileImportResponseModel = new NewsProfileImportResponseModel()
    this._TickHistoryRequestValue = new TickHistoryRequest()
    this._TickHistoryRequestModel = new TickHistoryRequestModel()
    this._TickHistoryResponseValue = new TickHistoryResponse()
    this._TickHistoryResponseModel = new TickHistoryResponseModel()
    this._EmailTemplateGetRequestValue = new EmailTemplateGetRequest()
    this._EmailTemplateGetRequestModel = new EmailTemplateGetRequestModel()
    this._EmailTemplateGetResponseValue = new EmailTemplateGetResponse()
    this._EmailTemplateGetResponseModel = new EmailTemplateGetResponseModel()
    this._EmailTemplatesGetAllRequestValue = new EmailTemplatesGetAllRequest()
    this._EmailTemplatesGetAllRequestModel = new EmailTemplatesGetAllRequestModel()
    this._EmailTemplatesGetAllResponseValue = new EmailTemplatesGetAllResponse()
    this._EmailTemplatesGetAllResponseModel = new EmailTemplatesGetAllResponseModel()
    this._EmailTemplateCreateRequestValue = new EmailTemplateCreateRequest()
    this._EmailTemplateCreateRequestModel = new EmailTemplateCreateRequestModel()
    this._EmailTemplateCreateResponseValue = new EmailTemplateCreateResponse()
    this._EmailTemplateCreateResponseModel = new EmailTemplateCreateResponseModel()
    this._EmailTemplateModifyRequestValue = new EmailTemplateModifyRequest()
    this._EmailTemplateModifyRequestModel = new EmailTemplateModifyRequestModel()
    this._EmailTemplateModifyResponseValue = new EmailTemplateModifyResponse()
    this._EmailTemplateModifyResponseModel = new EmailTemplateModifyResponseModel()
    this._EmailTemplateDeleteRequestValue = new EmailTemplateDeleteRequest()
    this._EmailTemplateDeleteRequestModel = new EmailTemplateDeleteRequestModel()
    this._EmailTemplateDeleteResponseValue = new EmailTemplateDeleteResponse()
    this._EmailTemplateDeleteResponseModel = new EmailTemplateDeleteResponseModel()
    this._EmailTemplateUpdateNotifyValue = new EmailTemplateUpdateNotify()
    this._EmailTemplateUpdateNotifyModel = new EmailTemplateUpdateNotifyModel()
    this._OrderStateSubscribeRequestValue = new OrderStateSubscribeRequest()
    this._OrderStateSubscribeRequestModel = new OrderStateSubscribeRequestModel()
    this._OrderStateSubscribeResponseValue = new OrderStateSubscribeResponse()
    this._OrderStateSubscribeResponseModel = new OrderStateSubscribeResponseModel()
    this._OrderStateUpdateSubscriptionRequestValue = new OrderStateUpdateSubscriptionRequest()
    this._OrderStateUpdateSubscriptionRequestModel = new OrderStateUpdateSubscriptionRequestModel()
    this._OrderStateUpdateSubscriptionResponseValue = new OrderStateUpdateSubscriptionResponse()
    this._OrderStateUpdateSubscriptionResponseModel = new OrderStateUpdateSubscriptionResponseModel()
    this._OrderStateUnsubscribeRequestValue = new OrderStateUnsubscribeRequest()
    this._OrderStateUnsubscribeRequestModel = new OrderStateUnsubscribeRequestModel()
    this._OrderStateUnsubscribeResponseValue = new OrderStateUnsubscribeResponse()
    this._OrderStateUnsubscribeResponseModel = new OrderStateUnsubscribeResponseModel()
    this._OrderStateNotifyValue = new OrderStateNotify()
    this._OrderStateNotifyModel = new OrderStateNotifyModel()
    this._RequestMonitoringSubscribeRequestValue = new RequestMonitoringSubscribeRequest()
    this._RequestMonitoringSubscribeRequestModel = new RequestMonitoringSubscribeRequestModel()
    this._RequestMonitoringSubscribeResponseValue = new RequestMonitoringSubscribeResponse()
    this._RequestMonitoringSubscribeResponseModel = new RequestMonitoringSubscribeResponseModel()
    this._RequestMonitoringUnsubscribeRequestValue = new RequestMonitoringUnsubscribeRequest()
    this._RequestMonitoringUnsubscribeRequestModel = new RequestMonitoringUnsubscribeRequestModel()
    this._RequestMonitoringUnsubscribeResponseValue = new RequestMonitoringUnsubscribeResponse()
    this._RequestMonitoringUnsubscribeResponseModel = new RequestMonitoringUnsubscribeResponseModel()
    this._RequestMonitoringRequestUpdateNotifyValue = new RequestMonitoringRequestUpdateNotify()
    this._RequestMonitoringRequestUpdateNotifyModel = new RequestMonitoringRequestUpdateNotifyModel()
    this._FilledOrdersSummaryInfoSubscribeRequestValue = new FilledOrdersSummaryInfoSubscribeRequest()
    this._FilledOrdersSummaryInfoSubscribeRequestModel = new FilledOrdersSummaryInfoSubscribeRequestModel()
    this._FilledOrdersSummaryInfoSubscribeResponseValue = new FilledOrdersSummaryInfoSubscribeResponse()
    this._FilledOrdersSummaryInfoSubscribeResponseModel = new FilledOrdersSummaryInfoSubscribeResponseModel()
    this._FilledOrdersSummaryInfoNotifyValue = new FilledOrdersSummaryInfoNotify()
    this._FilledOrdersSummaryInfoNotifyModel = new FilledOrdersSummaryInfoNotifyModel()
    this._FilledOrdersSummaryInfoUpdateSubscriptionRequestValue = new FilledOrdersSummaryInfoUpdateSubscriptionRequest()
    this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel = new FilledOrdersSummaryInfoUpdateSubscriptionRequestModel()
    this._FilledOrdersSummaryInfoUnsubscribeRequestValue = new FilledOrdersSummaryInfoUnsubscribeRequest()
    this._FilledOrdersSummaryInfoUnsubscribeRequestModel = new FilledOrdersSummaryInfoUnsubscribeRequestModel()
    this._FilledOrdersSummaryInfoUnsubscribeResponseValue = new FilledOrdersSummaryInfoUnsubscribeResponse()
    this._FilledOrdersSummaryInfoUnsubscribeResponseModel = new FilledOrdersSummaryInfoUnsubscribeResponseModel()
    this._TradeEventsNotifyValue = new TradeEventsNotify()
    this._TradeEventsNotifyModel = new TradeEventsNotifyModel()
    this._TradeEventsGetRequestValue = new TradeEventsGetRequest()
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseValue = new TradeEventsGetResponse()
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel()
    this._JournalRequestValue = new JournalRequest()
    this._JournalRequestModel = new JournalRequestModel()
    this._JournalResponseValue = new JournalResponse()
    this._JournalResponseModel = new JournalResponseModel()
    this._ClientReportsListGetRequestValue = new ClientReportsListGetRequest()
    this._ClientReportsListGetRequestModel = new ClientReportsListGetRequestModel()
    this._ClientReportsListGetResponseValue = new ClientReportsListGetResponse()
    this._ClientReportsListGetResponseModel = new ClientReportsListGetResponseModel()
    this._ClientReportGetRequestValue = new ClientReportGetRequest()
    this._ClientReportGetRequestModel = new ClientReportGetRequestModel()
    this._ClientReportGetResponseValue = new ClientReportGetResponse()
    this._ClientReportGetResponseModel = new ClientReportGetResponseModel()
    this._ClientReportsSendRequestValue = new ClientReportsSendRequest()
    this._ClientReportsSendRequestModel = new ClientReportsSendRequestModel()
    this._ClientReportsSendResponseValue = new ClientReportsSendResponse()
    this._ClientReportsSendResponseModel = new ClientReportsSendResponseModel()
    this._ProfitClosedGetRequestValue = new ProfitClosedGetRequest()
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseValue = new ProfitClosedGetResponse()
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel()
    this._ChartBarsImportRequestValue = new ChartBarsImportRequest()
    this._ChartBarsImportRequestModel = new ChartBarsImportRequestModel()
    this._ChartBarsImportResponseValue = new ChartBarsImportResponse()
    this._ChartBarsImportResponseModel = new ChartBarsImportResponseModel()
    this._ChartBarsImportNotifyValue = new ChartBarsImportNotify()
    this._ChartBarsImportNotifyModel = new ChartBarsImportNotifyModel()
    this._JournalBusinessRequestValue = new JournalBusinessRequest()
    this._JournalBusinessRequestModel = new JournalBusinessRequestModel()
    this._JournalBusinessResponseValue = new JournalBusinessResponse()
    this._JournalBusinessResponseModel = new JournalBusinessResponseModel()
    this._AccountsGetPageRequestValue = new AccountsGetPageRequest()
    this._AccountsGetPageRequestModel = new AccountsGetPageRequestModel()
    this._AccountsGetPageResponseValue = new AccountsGetPageResponse()
    this._AccountsGetPageResponseModel = new AccountsGetPageResponseModel()
    this._HeartbeatNotifyValue = new HeartbeatNotify()
    this._HeartbeatNotifyModel = new HeartbeatNotifyModel()
    this._OrdersClosedSummariesGetRequestValue = new OrdersClosedSummariesGetRequest()
    this._OrdersClosedSummariesGetRequestModel = new OrdersClosedSummariesGetRequestModel()
    this._OrdersClosedSummariesGetResponseValue = new OrdersClosedSummariesGetResponse()
    this._OrdersClosedSummariesGetResponseModel = new OrdersClosedSummariesGetResponseModel()
    this._OrdersGetPageRequestValue = new OrdersGetPageRequest()
    this._OrdersGetPageRequestModel = new OrdersGetPageRequestModel()
    this._OrdersGetPageResponseValue = new OrdersGetPageResponse()
    this._OrdersGetPageResponseModel = new OrdersGetPageResponseModel()
    this._StorageUniversalNotifyValue = new StorageUniversalNotify()
    this._StorageUniversalNotifyModel = new StorageUniversalNotifyModel()
    this._ClientLoginInfoGetRequestValue = new ClientLoginInfoGetRequest()
    this._ClientLoginInfoGetRequestModel = new ClientLoginInfoGetRequestModel()
    this._ClientLoginInfoGetResponseValue = new ClientLoginInfoGetResponse()
    this._ClientLoginInfoGetResponseModel = new ClientLoginInfoGetResponseModel()
    this._AccountGroupExistsGetRequestValue = new AccountGroupExistsGetRequest()
    this._AccountGroupExistsGetRequestModel = new AccountGroupExistsGetRequestModel()
    this._AccountGroupExistsGetResponseValue = new AccountGroupExistsGetResponse()
    this._AccountGroupExistsGetResponseModel = new AccountGroupExistsGetResponseModel()
    this._LoginWithPasswordRequestValue = new LoginWithPasswordRequest()
    this._LoginWithPasswordRequestModel = new LoginWithPasswordRequestModel()
    this._LoginWithPasswordResponseValue = new LoginWithPasswordResponse()
    this._LoginWithPasswordResponseModel = new LoginWithPasswordResponseModel()
    this._LoginWithTokenRequestValue = new LoginWithTokenRequest()
    this._LoginWithTokenRequestModel = new LoginWithTokenRequestModel()
    this._LoginWithTokenResponseValue = new LoginWithTokenResponse()
    this._LoginWithTokenResponseModel = new LoginWithTokenResponseModel()
    this._RefreshTokenRequestValue = new RefreshTokenRequest()
    this._RefreshTokenRequestModel = new RefreshTokenRequestModel()
    this._RefreshTokenResponseValue = new RefreshTokenResponse()
    this._RefreshTokenResponseModel = new RefreshTokenResponseModel()
    this._PersonGetRequestValue = new PersonGetRequest()
    this._PersonGetRequestModel = new PersonGetRequestModel()
    this._PersonGetResponseValue = new PersonGetResponse()
    this._PersonGetResponseModel = new PersonGetResponseModel()
    this._PersonsGetAllRequestValue = new PersonsGetAllRequest()
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseValue = new PersonsGetAllResponse()
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel()
    this._PersonCreateRequestValue = new PersonCreateRequest()
    this._PersonCreateRequestModel = new PersonCreateRequestModel()
    this._PersonCreateResponseValue = new PersonCreateResponse()
    this._PersonCreateResponseModel = new PersonCreateResponseModel()
    this._PersonModifyRequestValue = new PersonModifyRequest()
    this._PersonModifyRequestModel = new PersonModifyRequestModel()
    this._PersonModifyResponseValue = new PersonModifyResponse()
    this._PersonModifyResponseModel = new PersonModifyResponseModel()
    this._PersonUpdateNotifyValue = new PersonUpdateNotify()
    this._PersonUpdateNotifyModel = new PersonUpdateNotifyModel()
    this._SecuritySettingsGetRequestValue = new SecuritySettingsGetRequest()
    this._SecuritySettingsGetRequestModel = new SecuritySettingsGetRequestModel()
    this._SecuritySettingsGetResponseValue = new SecuritySettingsGetResponse()
    this._SecuritySettingsGetResponseModel = new SecuritySettingsGetResponseModel()
    this._SecuritySettingsGetAllRequestValue = new SecuritySettingsGetAllRequest()
    this._SecuritySettingsGetAllRequestModel = new SecuritySettingsGetAllRequestModel()
    this._SecuritySettingsGetAllResponseValue = new SecuritySettingsGetAllResponse()
    this._SecuritySettingsGetAllResponseModel = new SecuritySettingsGetAllResponseModel()
    this._SecuritySettingsCreateRequestValue = new SecuritySettingsCreateRequest()
    this._SecuritySettingsCreateRequestModel = new SecuritySettingsCreateRequestModel()
    this._SecuritySettingsCreateResponseValue = new SecuritySettingsCreateResponse()
    this._SecuritySettingsCreateResponseModel = new SecuritySettingsCreateResponseModel()
    this._SecuritySettingsModifyRequestValue = new SecuritySettingsModifyRequest()
    this._SecuritySettingsModifyRequestModel = new SecuritySettingsModifyRequestModel()
    this._SecuritySettingsModifyResponseValue = new SecuritySettingsModifyResponse()
    this._SecuritySettingsModifyResponseModel = new SecuritySettingsModifyResponseModel()
    this._SecuritySettingsDeleteRequestValue = new SecuritySettingsDeleteRequest()
    this._SecuritySettingsDeleteRequestModel = new SecuritySettingsDeleteRequestModel()
    this._SecuritySettingsDeleteResponseValue = new SecuritySettingsDeleteResponse()
    this._SecuritySettingsDeleteResponseModel = new SecuritySettingsDeleteResponseModel()
    this._SecuritySettingsUpdateNotifyValue = new SecuritySettingsUpdateNotify()
    this._SecuritySettingsUpdateNotifyModel = new SecuritySettingsUpdateNotifyModel()
    this._FirewallRuleGetRequestValue = new FirewallRuleGetRequest()
    this._FirewallRuleGetRequestModel = new FirewallRuleGetRequestModel()
    this._FirewallRuleGetResponseValue = new FirewallRuleGetResponse()
    this._FirewallRuleGetResponseModel = new FirewallRuleGetResponseModel()
    this._FirewallRulesGetAllRequestValue = new FirewallRulesGetAllRequest()
    this._FirewallRulesGetAllRequestModel = new FirewallRulesGetAllRequestModel()
    this._FirewallRulesGetAllResponseValue = new FirewallRulesGetAllResponse()
    this._FirewallRulesGetAllResponseModel = new FirewallRulesGetAllResponseModel()
    this._FirewallRuleCreateRequestValue = new FirewallRuleCreateRequest()
    this._FirewallRuleCreateRequestModel = new FirewallRuleCreateRequestModel()
    this._FirewallRuleCreateResponseValue = new FirewallRuleCreateResponse()
    this._FirewallRuleCreateResponseModel = new FirewallRuleCreateResponseModel()
    this._FirewallRuleModifyRequestValue = new FirewallRuleModifyRequest()
    this._FirewallRuleModifyRequestModel = new FirewallRuleModifyRequestModel()
    this._FirewallRuleModifyResponseValue = new FirewallRuleModifyResponse()
    this._FirewallRuleModifyResponseModel = new FirewallRuleModifyResponseModel()
    this._FirewallRuleDeleteRequestValue = new FirewallRuleDeleteRequest()
    this._FirewallRuleDeleteRequestModel = new FirewallRuleDeleteRequestModel()
    this._FirewallRuleDeleteResponseValue = new FirewallRuleDeleteResponse()
    this._FirewallRuleDeleteResponseModel = new FirewallRuleDeleteResponseModel()
    this._FirewallRuleUpdateNotifyValue = new FirewallRuleUpdateNotify()
    this._FirewallRuleUpdateNotifyModel = new FirewallRuleUpdateNotifyModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  /**
   * Get imported config receiver
   * @this {!Receiver}
   * @returns {Receiver} config receiver
   */
  get configReceiver () {
    return this._configReceiver
  }

  /**
   * Set imported config receiver
   * @this {!Receiver}
   * @param {Receiver} receiver config receiver
   */
  set configReceiver (receiver) {
    this._configReceiver = receiver
  }

  /**
   * Get imported history receiver
   * @this {!Receiver}
   * @returns {Receiver} history receiver
   */
  get historyReceiver () {
    return this._historyReceiver
  }

  /**
   * Set imported history receiver
   * @this {!Receiver}
   * @param {Receiver} receiver history receiver
   */
  set historyReceiver (receiver) {
    this._historyReceiver = receiver
  }

  /**
   * Get imported client receiver
   * @this {!Receiver}
   * @returns {Receiver} client receiver
   */
  get clientReceiver () {
    return this._clientReceiver
  }

  /**
   * Set imported client receiver
   * @this {!Receiver}
   * @param {Receiver} receiver client receiver
   */
  set clientReceiver (receiver) {
    this._clientReceiver = receiver
  }

  /**
   * Get imported feed receiver
   * @this {!Receiver}
   * @returns {Receiver} feed receiver
   */
  get feedReceiver () {
    return this._feedReceiver
  }

  /**
   * Set imported feed receiver
   * @this {!Receiver}
   * @param {Receiver} receiver feed receiver
   */
  set feedReceiver (receiver) {
    this._feedReceiver = receiver
  }

  /**
   * Get imported storage receiver
   * @this {!Receiver}
   * @returns {Receiver} storage receiver
   */
  get storageReceiver () {
    return this._storageReceiver
  }

  /**
   * Set imported storage receiver
   * @this {!Receiver}
   * @param {Receiver} receiver storage receiver
   */
  set storageReceiver (receiver) {
    this._storageReceiver = receiver
  }

  /**
   * Get imported exposed receiver
   * @this {!Receiver}
   * @returns {Receiver} exposed receiver
   */
  get exposedReceiver () {
    return this._exposedReceiver
  }

  /**
   * Set imported exposed receiver
   * @this {!Receiver}
   * @param {Receiver} receiver exposed receiver
   */
  set exposedReceiver (receiver) {
    this._exposedReceiver = receiver
  }

  /**
   * Get imported execution receiver
   * @this {!Receiver}
   * @returns {Receiver} execution receiver
   */
  get executionReceiver () {
    return this._executionReceiver
  }

  /**
   * Set imported execution receiver
   * @this {!Receiver}
   * @param {Receiver} receiver execution receiver
   */
  set executionReceiver (receiver) {
    this._executionReceiver = receiver
  }

  /**
   * Get imported backup receiver
   * @this {!Receiver}
   * @returns {Receiver} backup receiver
   */
  get backupReceiver () {
    return this._backupReceiver
  }

  /**
   * Set imported backup receiver
   * @this {!Receiver}
   * @param {Receiver} receiver backup receiver
   */
  set backupReceiver (receiver) {
    this._backupReceiver = receiver
  }

  /**
   * Get imported inner receiver
   * @this {!Receiver}
   * @returns {Receiver} inner receiver
   */
  get innerReceiver () {
    return this._innerReceiver
  }

  /**
   * Set imported inner receiver
   * @this {!Receiver}
   * @param {Receiver} receiver inner receiver
   */
  set innerReceiver (receiver) {
    this._innerReceiver = receiver
  }

  /**
   * Get imported trade receiver
   * @this {!Receiver}
   * @returns {Receiver} trade receiver
   */
  get tradeReceiver () {
    return this._tradeReceiver
  }

  /**
   * Set imported trade receiver
   * @this {!Receiver}
   * @param {Receiver} receiver trade receiver
   */
  set tradeReceiver (receiver) {
    this._tradeReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * PingModuleRequest receive handler
   * @this {!Receiver}
   * @param {!PingModuleRequest} value PingModuleRequest received value
   */
  onReceive_PingModuleRequest (value) {}  // eslint-disable-line

  /**
   * PongModuleResponse receive handler
   * @this {!Receiver}
   * @param {!PongModuleResponse} value PongModuleResponse received value
   */
  onReceive_PongModuleResponse (value) {}  // eslint-disable-line

  /**
   * PingModuleReject receive handler
   * @this {!Receiver}
   * @param {!PingModuleReject} value PingModuleReject received value
   */
  onReceive_PingModuleReject (value) {}  // eslint-disable-line

  /**
   * Deprecated3 receive handler
   * @this {!Receiver}
   * @param {!Deprecated3} value Deprecated3 received value
   */
  onReceive_Deprecated3 (value) {}  // eslint-disable-line

  /**
   * Deprecated4 receive handler
   * @this {!Receiver}
   * @param {!Deprecated4} value Deprecated4 received value
   */
  onReceive_Deprecated4 (value) {}  // eslint-disable-line

  /**
   * Deprecated1 receive handler
   * @this {!Receiver}
   * @param {!Deprecated1} value Deprecated1 received value
   */
  onReceive_Deprecated1 (value) {}  // eslint-disable-line

  /**
   * Deprecated2 receive handler
   * @this {!Receiver}
   * @param {!Deprecated2} value Deprecated2 received value
   */
  onReceive_Deprecated2 (value) {}  // eslint-disable-line

  /**
   * ManagerSessionInfoRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerSessionInfoRequest} value ManagerSessionInfoRequest received value
   */
  onReceive_ManagerSessionInfoRequest (value) {}  // eslint-disable-line

  /**
   * ManagerSessionInfoResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerSessionInfoResponse} value ManagerSessionInfoResponse received value
   */
  onReceive_ManagerSessionInfoResponse (value) {}  // eslint-disable-line

  /**
   * OnlineSessionsRequest receive handler
   * @this {!Receiver}
   * @param {!OnlineSessionsRequest} value OnlineSessionsRequest received value
   */
  onReceive_OnlineSessionsRequest (value) {}  // eslint-disable-line

  /**
   * OnlineSessionsResponse receive handler
   * @this {!Receiver}
   * @param {!OnlineSessionsResponse} value OnlineSessionsResponse received value
   */
  onReceive_OnlineSessionsResponse (value) {}  // eslint-disable-line

  /**
   * DropSessionRequest receive handler
   * @this {!Receiver}
   * @param {!DropSessionRequest} value DropSessionRequest received value
   */
  onReceive_DropSessionRequest (value) {}  // eslint-disable-line

  /**
   * DropSessionResponse receive handler
   * @this {!Receiver}
   * @param {!DropSessionResponse} value DropSessionResponse received value
   */
  onReceive_DropSessionResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigRequest} value ConfigRequest received value
   */
  onReceive_ConfigRequest (value) {}  // eslint-disable-line

  /**
   * ConfigResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigResponse} value ConfigResponse received value
   */
  onReceive_ConfigResponse (value) {}  // eslint-disable-line

  /**
   * ConfigReject receive handler
   * @this {!Receiver}
   * @param {!ConfigReject} value ConfigReject received value
   */
  onReceive_ConfigReject (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest received value
   */
  onReceive_ConfigUpdateRequest (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse received value
   */
  onReceive_ConfigUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify received value
   */
  onReceive_ConfigUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ConfigVersionRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest received value
   */
  onReceive_ConfigVersionRequest (value) {}  // eslint-disable-line

  /**
   * ConfigVersionResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse received value
   */
  onReceive_ConfigVersionResponse (value) {}  // eslint-disable-line

  /**
   * ConfigExportRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigExportRequest} value ConfigExportRequest received value
   */
  onReceive_ConfigExportRequest (value) {}  // eslint-disable-line

  /**
   * ConfigExportResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigExportResponse} value ConfigExportResponse received value
   */
  onReceive_ConfigExportResponse (value) {}  // eslint-disable-line

  /**
   * ConfigImportRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigImportRequest} value ConfigImportRequest received value
   */
  onReceive_ConfigImportRequest (value) {}  // eslint-disable-line

  /**
   * ConfigImportResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigImportResponse} value ConfigImportResponse received value
   */
  onReceive_ConfigImportResponse (value) {}  // eslint-disable-line

  /**
   * ConfigValidateRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigValidateRequest} value ConfigValidateRequest received value
   */
  onReceive_ConfigValidateRequest (value) {}  // eslint-disable-line

  /**
   * ConfigValidateResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigValidateResponse} value ConfigValidateResponse received value
   */
  onReceive_ConfigValidateResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest received value
   */
  onReceive_ConfigRefreshRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse received value
   */
  onReceive_ConfigRefreshResponse (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest received value
   */
  onReceive_ConfigHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse received value
   */
  onReceive_ConfigHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest received value
   */
  onReceive_ConfigRollbackRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse received value
   */
  onReceive_ConfigRollbackResponse (value) {}  // eslint-disable-line

  /**
   * HostGetRequest receive handler
   * @this {!Receiver}
   * @param {!HostGetRequest} value HostGetRequest received value
   */
  onReceive_HostGetRequest (value) {}  // eslint-disable-line

  /**
   * HostGetResponse receive handler
   * @this {!Receiver}
   * @param {!HostGetResponse} value HostGetResponse received value
   */
  onReceive_HostGetResponse (value) {}  // eslint-disable-line

  /**
   * HostsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!HostsGetAllRequest} value HostsGetAllRequest received value
   */
  onReceive_HostsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * HostsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!HostsGetAllResponse} value HostsGetAllResponse received value
   */
  onReceive_HostsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * HostCreateRequest receive handler
   * @this {!Receiver}
   * @param {!HostCreateRequest} value HostCreateRequest received value
   */
  onReceive_HostCreateRequest (value) {}  // eslint-disable-line

  /**
   * HostCreateResponse receive handler
   * @this {!Receiver}
   * @param {!HostCreateResponse} value HostCreateResponse received value
   */
  onReceive_HostCreateResponse (value) {}  // eslint-disable-line

  /**
   * HostModifyRequest receive handler
   * @this {!Receiver}
   * @param {!HostModifyRequest} value HostModifyRequest received value
   */
  onReceive_HostModifyRequest (value) {}  // eslint-disable-line

  /**
   * HostModifyResponse receive handler
   * @this {!Receiver}
   * @param {!HostModifyResponse} value HostModifyResponse received value
   */
  onReceive_HostModifyResponse (value) {}  // eslint-disable-line

  /**
   * HostDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!HostDeleteRequest} value HostDeleteRequest received value
   */
  onReceive_HostDeleteRequest (value) {}  // eslint-disable-line

  /**
   * HostDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!HostDeleteResponse} value HostDeleteResponse received value
   */
  onReceive_HostDeleteResponse (value) {}  // eslint-disable-line

  /**
   * HostUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!HostUpdateNotify} value HostUpdateNotify received value
   */
  onReceive_HostUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ModuleGetRequest receive handler
   * @this {!Receiver}
   * @param {!ModuleGetRequest} value ModuleGetRequest received value
   */
  onReceive_ModuleGetRequest (value) {}  // eslint-disable-line

  /**
   * ModuleGetResponse receive handler
   * @this {!Receiver}
   * @param {!ModuleGetResponse} value ModuleGetResponse received value
   */
  onReceive_ModuleGetResponse (value) {}  // eslint-disable-line

  /**
   * ModulesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!ModulesGetAllRequest} value ModulesGetAllRequest received value
   */
  onReceive_ModulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ModulesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!ModulesGetAllResponse} value ModulesGetAllResponse received value
   */
  onReceive_ModulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ModuleCreateRequest receive handler
   * @this {!Receiver}
   * @param {!ModuleCreateRequest} value ModuleCreateRequest received value
   */
  onReceive_ModuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * ModuleCreateResponse receive handler
   * @this {!Receiver}
   * @param {!ModuleCreateResponse} value ModuleCreateResponse received value
   */
  onReceive_ModuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * ModuleModifyRequest receive handler
   * @this {!Receiver}
   * @param {!ModuleModifyRequest} value ModuleModifyRequest received value
   */
  onReceive_ModuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * ModuleModifyResponse receive handler
   * @this {!Receiver}
   * @param {!ModuleModifyResponse} value ModuleModifyResponse received value
   */
  onReceive_ModuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * ModuleDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!ModuleDeleteRequest} value ModuleDeleteRequest received value
   */
  onReceive_ModuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ModuleDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!ModuleDeleteResponse} value ModuleDeleteResponse received value
   */
  onReceive_ModuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ModuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ModuleUpdateNotify} value ModuleUpdateNotify received value
   */
  onReceive_ModuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ServerTimeGetRequest receive handler
   * @this {!Receiver}
   * @param {!ServerTimeGetRequest} value ServerTimeGetRequest received value
   */
  onReceive_ServerTimeGetRequest (value) {}  // eslint-disable-line

  /**
   * ServerTimeGetResponse receive handler
   * @this {!Receiver}
   * @param {!ServerTimeGetResponse} value ServerTimeGetResponse received value
   */
  onReceive_ServerTimeGetResponse (value) {}  // eslint-disable-line

  /**
   * ManagerPlatformTimezoneGetRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerPlatformTimezoneGetRequest} value ManagerPlatformTimezoneGetRequest received value
   */
  onReceive_ManagerPlatformTimezoneGetRequest (value) {}  // eslint-disable-line

  /**
   * ManagerPlatformTimezoneGetResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerPlatformTimezoneGetResponse} value ManagerPlatformTimezoneGetResponse received value
   */
  onReceive_ManagerPlatformTimezoneGetResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneGetRequest receive handler
   * @this {!Receiver}
   * @param {!TimezoneGetRequest} value TimezoneGetRequest received value
   */
  onReceive_TimezoneGetRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneGetResponse receive handler
   * @this {!Receiver}
   * @param {!TimezoneGetResponse} value TimezoneGetResponse received value
   */
  onReceive_TimezoneGetResponse (value) {}  // eslint-disable-line

  /**
   * TimezonesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!TimezonesGetAllRequest} value TimezonesGetAllRequest received value
   */
  onReceive_TimezonesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TimezonesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!TimezonesGetAllResponse} value TimezonesGetAllResponse received value
   */
  onReceive_TimezonesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneCreateRequest receive handler
   * @this {!Receiver}
   * @param {!TimezoneCreateRequest} value TimezoneCreateRequest received value
   */
  onReceive_TimezoneCreateRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneCreateResponse receive handler
   * @this {!Receiver}
   * @param {!TimezoneCreateResponse} value TimezoneCreateResponse received value
   */
  onReceive_TimezoneCreateResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneModifyRequest receive handler
   * @this {!Receiver}
   * @param {!TimezoneModifyRequest} value TimezoneModifyRequest received value
   */
  onReceive_TimezoneModifyRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneModifyResponse receive handler
   * @this {!Receiver}
   * @param {!TimezoneModifyResponse} value TimezoneModifyResponse received value
   */
  onReceive_TimezoneModifyResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!TimezoneDeleteRequest} value TimezoneDeleteRequest received value
   */
  onReceive_TimezoneDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!TimezoneDeleteResponse} value TimezoneDeleteResponse received value
   */
  onReceive_TimezoneDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TimezoneUpdateNotify} value TimezoneUpdateNotify received value
   */
  onReceive_TimezoneUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest received value
   */
  onReceive_CurrencyGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse received value
   */
  onReceive_CurrencyGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest received value
   */
  onReceive_CurrencyGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse received value
   */
  onReceive_CurrencyGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupCreateRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupCreateRequest} value CurrencyGroupCreateRequest received value
   */
  onReceive_CurrencyGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupCreateResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupCreateResponse} value CurrencyGroupCreateResponse received value
   */
  onReceive_CurrencyGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupModifyRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupModifyRequest} value CurrencyGroupModifyRequest received value
   */
  onReceive_CurrencyGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupModifyResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupModifyResponse} value CurrencyGroupModifyResponse received value
   */
  onReceive_CurrencyGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupDeleteRequest} value CurrencyGroupDeleteRequest received value
   */
  onReceive_CurrencyGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupDeleteResponse} value CurrencyGroupDeleteResponse received value
   */
  onReceive_CurrencyGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify received value
   */
  onReceive_CurrencyGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGetRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest received value
   */
  onReceive_CurrencyGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGetResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse received value
   */
  onReceive_CurrencyGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest received value
   */
  onReceive_CurrenciesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse received value
   */
  onReceive_CurrenciesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyCreateRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyCreateRequest} value CurrencyCreateRequest received value
   */
  onReceive_CurrencyCreateRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyCreateResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyCreateResponse} value CurrencyCreateResponse received value
   */
  onReceive_CurrencyCreateResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyModifyRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyModifyRequest} value CurrencyModifyRequest received value
   */
  onReceive_CurrencyModifyRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyModifyResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyModifyResponse} value CurrencyModifyResponse received value
   */
  onReceive_CurrencyModifyResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyDeleteRequest} value CurrencyDeleteRequest received value
   */
  onReceive_CurrencyDeleteRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyDeleteResponse} value CurrencyDeleteResponse received value
   */
  onReceive_CurrencyDeleteResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify received value
   */
  onReceive_CurrencyUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest received value
   */
  onReceive_SymbolGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse received value
   */
  onReceive_SymbolGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest received value
   */
  onReceive_SymbolGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse received value
   */
  onReceive_SymbolGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupCreateRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupCreateRequest} value SymbolGroupCreateRequest received value
   */
  onReceive_SymbolGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupCreateResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupCreateResponse} value SymbolGroupCreateResponse received value
   */
  onReceive_SymbolGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupModifyRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupModifyRequest} value SymbolGroupModifyRequest received value
   */
  onReceive_SymbolGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupModifyResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupModifyResponse} value SymbolGroupModifyResponse received value
   */
  onReceive_SymbolGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupDeleteRequest} value SymbolGroupDeleteRequest received value
   */
  onReceive_SymbolGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupDeleteResponse} value SymbolGroupDeleteResponse received value
   */
  onReceive_SymbolGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify received value
   */
  onReceive_SymbolGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGetRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGetRequest} value SymbolGetRequest received value
   */
  onReceive_SymbolGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGetResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGetResponse} value SymbolGetResponse received value
   */
  onReceive_SymbolGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest received value
   */
  onReceive_SymbolsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse received value
   */
  onReceive_SymbolsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolCreateRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolCreateRequest} value SymbolCreateRequest received value
   */
  onReceive_SymbolCreateRequest (value) {}  // eslint-disable-line

  /**
   * SymbolCreateResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolCreateResponse} value SymbolCreateResponse received value
   */
  onReceive_SymbolCreateResponse (value) {}  // eslint-disable-line

  /**
   * SymbolModifyRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolModifyRequest} value SymbolModifyRequest received value
   */
  onReceive_SymbolModifyRequest (value) {}  // eslint-disable-line

  /**
   * SymbolModifyResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolModifyResponse} value SymbolModifyResponse received value
   */
  onReceive_SymbolModifyResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolDeleteRequest} value SymbolDeleteRequest received value
   */
  onReceive_SymbolDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolDeleteResponse} value SymbolDeleteResponse received value
   */
  onReceive_SymbolDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify received value
   */
  onReceive_SymbolUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SwapsExportRequest receive handler
   * @this {!Receiver}
   * @param {!SwapsExportRequest} value SwapsExportRequest received value
   */
  onReceive_SwapsExportRequest (value) {}  // eslint-disable-line

  /**
   * SwapsExportResponse receive handler
   * @this {!Receiver}
   * @param {!SwapsExportResponse} value SwapsExportResponse received value
   */
  onReceive_SwapsExportResponse (value) {}  // eslint-disable-line

  /**
   * SwapsImportRequest receive handler
   * @this {!Receiver}
   * @param {!SwapsImportRequest} value SwapsImportRequest received value
   */
  onReceive_SwapsImportRequest (value) {}  // eslint-disable-line

  /**
   * SwapsImportResponse receive handler
   * @this {!Receiver}
   * @param {!SwapsImportResponse} value SwapsImportResponse received value
   */
  onReceive_SwapsImportResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileGetRequest receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileGetRequest} value CommissionProfileGetRequest received value
   */
  onReceive_CommissionProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileGetResponse receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileGetResponse} value CommissionProfileGetResponse received value
   */
  onReceive_CommissionProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfilesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!CommissionProfilesGetAllRequest} value CommissionProfilesGetAllRequest received value
   */
  onReceive_CommissionProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfilesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!CommissionProfilesGetAllResponse} value CommissionProfilesGetAllResponse received value
   */
  onReceive_CommissionProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileCreateRequest receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileCreateRequest} value CommissionProfileCreateRequest received value
   */
  onReceive_CommissionProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileCreateResponse receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileCreateResponse} value CommissionProfileCreateResponse received value
   */
  onReceive_CommissionProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileModifyRequest receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileModifyRequest} value CommissionProfileModifyRequest received value
   */
  onReceive_CommissionProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileModifyResponse receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileModifyResponse} value CommissionProfileModifyResponse received value
   */
  onReceive_CommissionProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileDeleteRequest} value CommissionProfileDeleteRequest received value
   */
  onReceive_CommissionProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileDeleteResponse} value CommissionProfileDeleteResponse received value
   */
  onReceive_CommissionProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CommissionProfileUpdateNotify} value CommissionProfileUpdateNotify received value
   */
  onReceive_CommissionProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarkupProfileGetRequest receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileGetRequest} value MarkupProfileGetRequest received value
   */
  onReceive_MarkupProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileGetResponse receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileGetResponse} value MarkupProfileGetResponse received value
   */
  onReceive_MarkupProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfilesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!MarkupProfilesGetAllRequest} value MarkupProfilesGetAllRequest received value
   */
  onReceive_MarkupProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfilesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!MarkupProfilesGetAllResponse} value MarkupProfilesGetAllResponse received value
   */
  onReceive_MarkupProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileCreateRequest receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileCreateRequest} value MarkupProfileCreateRequest received value
   */
  onReceive_MarkupProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileCreateResponse receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileCreateResponse} value MarkupProfileCreateResponse received value
   */
  onReceive_MarkupProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileModifyRequest receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileModifyRequest} value MarkupProfileModifyRequest received value
   */
  onReceive_MarkupProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileModifyResponse receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileModifyResponse} value MarkupProfileModifyResponse received value
   */
  onReceive_MarkupProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileDeleteRequest} value MarkupProfileDeleteRequest received value
   */
  onReceive_MarkupProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileDeleteResponse} value MarkupProfileDeleteResponse received value
   */
  onReceive_MarkupProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!MarkupProfileUpdateNotify} value MarkupProfileUpdateNotify received value
   */
  onReceive_MarkupProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ManagerGroupGetRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupGetRequest} value ManagerGroupGetRequest received value
   */
  onReceive_ManagerGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupGetResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupGetResponse} value ManagerGroupGetResponse received value
   */
  onReceive_ManagerGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupsGetAllRequest} value ManagerGroupsGetAllRequest received value
   */
  onReceive_ManagerGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupsGetAllResponse} value ManagerGroupsGetAllResponse received value
   */
  onReceive_ManagerGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupCreateRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupCreateRequest} value ManagerGroupCreateRequest received value
   */
  onReceive_ManagerGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupCreateResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupCreateResponse} value ManagerGroupCreateResponse received value
   */
  onReceive_ManagerGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupModifyRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupModifyRequest} value ManagerGroupModifyRequest received value
   */
  onReceive_ManagerGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupModifyResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupModifyResponse} value ManagerGroupModifyResponse received value
   */
  onReceive_ManagerGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupDeleteRequest} value ManagerGroupDeleteRequest received value
   */
  onReceive_ManagerGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupDeleteResponse} value ManagerGroupDeleteResponse received value
   */
  onReceive_ManagerGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ManagerGroupUpdateNotify} value ManagerGroupUpdateNotify received value
   */
  onReceive_ManagerGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ManagerGetRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerGetRequest} value ManagerGetRequest received value
   */
  onReceive_ManagerGetRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGetResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerGetResponse} value ManagerGetResponse received value
   */
  onReceive_ManagerGetResponse (value) {}  // eslint-disable-line

  /**
   * ManagersGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!ManagersGetAllRequest} value ManagersGetAllRequest received value
   */
  onReceive_ManagersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ManagersGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!ManagersGetAllResponse} value ManagersGetAllResponse received value
   */
  onReceive_ManagersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ManagerCreateRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerCreateRequest} value ManagerCreateRequest received value
   */
  onReceive_ManagerCreateRequest (value) {}  // eslint-disable-line

  /**
   * ManagerCreateResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerCreateResponse} value ManagerCreateResponse received value
   */
  onReceive_ManagerCreateResponse (value) {}  // eslint-disable-line

  /**
   * ManagerModifyRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerModifyRequest} value ManagerModifyRequest received value
   */
  onReceive_ManagerModifyRequest (value) {}  // eslint-disable-line

  /**
   * ManagerModifyResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerModifyResponse} value ManagerModifyResponse received value
   */
  onReceive_ManagerModifyResponse (value) {}  // eslint-disable-line

  /**
   * ManagerDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!ManagerDeleteRequest} value ManagerDeleteRequest received value
   */
  onReceive_ManagerDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ManagerDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!ManagerDeleteResponse} value ManagerDeleteResponse received value
   */
  onReceive_ManagerDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ManagerUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ManagerUpdateNotify} value ManagerUpdateNotify received value
   */
  onReceive_ManagerUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PlatformGetRequest receive handler
   * @this {!Receiver}
   * @param {!PlatformGetRequest} value PlatformGetRequest received value
   */
  onReceive_PlatformGetRequest (value) {}  // eslint-disable-line

  /**
   * PlatformGetResponse receive handler
   * @this {!Receiver}
   * @param {!PlatformGetResponse} value PlatformGetResponse received value
   */
  onReceive_PlatformGetResponse (value) {}  // eslint-disable-line

  /**
   * PlatformsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!PlatformsGetAllRequest} value PlatformsGetAllRequest received value
   */
  onReceive_PlatformsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PlatformsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!PlatformsGetAllResponse} value PlatformsGetAllResponse received value
   */
  onReceive_PlatformsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PlatformCreateRequest receive handler
   * @this {!Receiver}
   * @param {!PlatformCreateRequest} value PlatformCreateRequest received value
   */
  onReceive_PlatformCreateRequest (value) {}  // eslint-disable-line

  /**
   * PlatformCreateResponse receive handler
   * @this {!Receiver}
   * @param {!PlatformCreateResponse} value PlatformCreateResponse received value
   */
  onReceive_PlatformCreateResponse (value) {}  // eslint-disable-line

  /**
   * PlatformModifyRequest receive handler
   * @this {!Receiver}
   * @param {!PlatformModifyRequest} value PlatformModifyRequest received value
   */
  onReceive_PlatformModifyRequest (value) {}  // eslint-disable-line

  /**
   * PlatformModifyResponse receive handler
   * @this {!Receiver}
   * @param {!PlatformModifyResponse} value PlatformModifyResponse received value
   */
  onReceive_PlatformModifyResponse (value) {}  // eslint-disable-line

  /**
   * PlatformDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!PlatformDeleteRequest} value PlatformDeleteRequest received value
   */
  onReceive_PlatformDeleteRequest (value) {}  // eslint-disable-line

  /**
   * PlatformDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!PlatformDeleteResponse} value PlatformDeleteResponse received value
   */
  onReceive_PlatformDeleteResponse (value) {}  // eslint-disable-line

  /**
   * PlatformUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!PlatformUpdateNotify} value PlatformUpdateNotify received value
   */
  onReceive_PlatformUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountGroupGetRequest receive handler
   * @this {!Receiver}
   * @param {!AccountGroupGetRequest} value AccountGroupGetRequest received value
   */
  onReceive_AccountGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupGetResponse receive handler
   * @this {!Receiver}
   * @param {!AccountGroupGetResponse} value AccountGroupGetResponse received value
   */
  onReceive_AccountGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AccountGroupsGetAllRequest} value AccountGroupsGetAllRequest received value
   */
  onReceive_AccountGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AccountGroupsGetAllResponse} value AccountGroupsGetAllResponse received value
   */
  onReceive_AccountGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupCreateRequest receive handler
   * @this {!Receiver}
   * @param {!AccountGroupCreateRequest} value AccountGroupCreateRequest received value
   */
  onReceive_AccountGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupCreateResponse receive handler
   * @this {!Receiver}
   * @param {!AccountGroupCreateResponse} value AccountGroupCreateResponse received value
   */
  onReceive_AccountGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupModifyRequest receive handler
   * @this {!Receiver}
   * @param {!AccountGroupModifyRequest} value AccountGroupModifyRequest received value
   */
  onReceive_AccountGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupModifyResponse receive handler
   * @this {!Receiver}
   * @param {!AccountGroupModifyResponse} value AccountGroupModifyResponse received value
   */
  onReceive_AccountGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!AccountGroupDeleteRequest} value AccountGroupDeleteRequest received value
   */
  onReceive_AccountGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!AccountGroupDeleteResponse} value AccountGroupDeleteResponse received value
   */
  onReceive_AccountGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!AccountGroupUpdateNotify} value AccountGroupUpdateNotify received value
   */
  onReceive_AccountGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountGetRequest receive handler
   * @this {!Receiver}
   * @param {!AccountGetRequest} value AccountGetRequest received value
   */
  onReceive_AccountGetRequest (value) {}  // eslint-disable-line

  /**
   * AccountGetResponse receive handler
   * @this {!Receiver}
   * @param {!AccountGetResponse} value AccountGetResponse received value
   */
  onReceive_AccountGetResponse (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest received value
   */
  onReceive_AccountsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse received value
   */
  onReceive_AccountsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AccountCreateRequest receive handler
   * @this {!Receiver}
   * @param {!AccountCreateRequest} value AccountCreateRequest received value
   */
  onReceive_AccountCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountCreateResponse receive handler
   * @this {!Receiver}
   * @param {!AccountCreateResponse} value AccountCreateResponse received value
   */
  onReceive_AccountCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterCreateRequest receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingMasterCreateRequest} value AccountSocialTradingMasterCreateRequest received value
   */
  onReceive_AccountSocialTradingMasterCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterCreateResponse receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingMasterCreateResponse} value AccountSocialTradingMasterCreateResponse received value
   */
  onReceive_AccountSocialTradingMasterCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorCreateRequest receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingInvestorCreateRequest} value AccountSocialTradingInvestorCreateRequest received value
   */
  onReceive_AccountSocialTradingInvestorCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorCreateResponse receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingInvestorCreateResponse} value AccountSocialTradingInvestorCreateResponse received value
   */
  onReceive_AccountSocialTradingInvestorCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountModifyRequest receive handler
   * @this {!Receiver}
   * @param {!AccountModifyRequest} value AccountModifyRequest received value
   */
  onReceive_AccountModifyRequest (value) {}  // eslint-disable-line

  /**
   * AccountModifyResponse receive handler
   * @this {!Receiver}
   * @param {!AccountModifyResponse} value AccountModifyResponse received value
   */
  onReceive_AccountModifyResponse (value) {}  // eslint-disable-line

  /**
   * AccountDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest received value
   */
  onReceive_AccountDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AccountDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse received value
   */
  onReceive_AccountDeleteResponse (value) {}  // eslint-disable-line

  /**
   * DemoAccountsDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!DemoAccountsDeleteRequest} value DemoAccountsDeleteRequest received value
   */
  onReceive_DemoAccountsDeleteRequest (value) {}  // eslint-disable-line

  /**
   * DemoAccountsDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!DemoAccountsDeleteResponse} value DemoAccountsDeleteResponse received value
   */
  onReceive_DemoAccountsDeleteResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingMasterRequest receive handler
   * @this {!Receiver}
   * @param {!AccountUpgradeToSocialTradingMasterRequest} value AccountUpgradeToSocialTradingMasterRequest received value
   */
  onReceive_AccountUpgradeToSocialTradingMasterRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingMasterResponse receive handler
   * @this {!Receiver}
   * @param {!AccountUpgradeToSocialTradingMasterResponse} value AccountUpgradeToSocialTradingMasterResponse received value
   */
  onReceive_AccountUpgradeToSocialTradingMasterResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingInvestorRequest receive handler
   * @this {!Receiver}
   * @param {!AccountUpgradeToSocialTradingInvestorRequest} value AccountUpgradeToSocialTradingInvestorRequest received value
   */
  onReceive_AccountUpgradeToSocialTradingInvestorRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingInvestorResponse receive handler
   * @this {!Receiver}
   * @param {!AccountUpgradeToSocialTradingInvestorResponse} value AccountUpgradeToSocialTradingInvestorResponse received value
   */
  onReceive_AccountUpgradeToSocialTradingInvestorResponse (value) {}  // eslint-disable-line

  /**
   * SocialTradingInvestorAccountToRegularRequest receive handler
   * @this {!Receiver}
   * @param {!SocialTradingInvestorAccountToRegularRequest} value SocialTradingInvestorAccountToRegularRequest received value
   */
  onReceive_SocialTradingInvestorAccountToRegularRequest (value) {}  // eslint-disable-line

  /**
   * SocialTradingInvestorAccountToRegularResponse receive handler
   * @this {!Receiver}
   * @param {!SocialTradingInvestorAccountToRegularResponse} value SocialTradingInvestorAccountToRegularResponse received value
   */
  onReceive_SocialTradingInvestorAccountToRegularResponse (value) {}  // eslint-disable-line

  /**
   * SocialTradingMasterAccountToRegularRequest receive handler
   * @this {!Receiver}
   * @param {!SocialTradingMasterAccountToRegularRequest} value SocialTradingMasterAccountToRegularRequest received value
   */
  onReceive_SocialTradingMasterAccountToRegularRequest (value) {}  // eslint-disable-line

  /**
   * SocialTradingMasterAccountToRegularResponse receive handler
   * @this {!Receiver}
   * @param {!SocialTradingMasterAccountToRegularResponse} value SocialTradingMasterAccountToRegularResponse received value
   */
  onReceive_SocialTradingMasterAccountToRegularResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!AccountUpdateNotify} value AccountUpdateNotify received value
   */
  onReceive_AccountUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginGetRequest receive handler
   * @this {!Receiver}
   * @param {!MarginGetRequest} value MarginGetRequest received value
   */
  onReceive_MarginGetRequest (value) {}  // eslint-disable-line

  /**
   * MarginGetResponse receive handler
   * @this {!Receiver}
   * @param {!MarginGetResponse} value MarginGetResponse received value
   */
  onReceive_MarginGetResponse (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest received value
   */
  onReceive_MarginsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse received value
   */
  onReceive_MarginsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarginUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify received value
   */
  onReceive_MarginUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginCallNotify receive handler
   * @this {!Receiver}
   * @param {!MarginCallNotify} value MarginCallNotify received value
   */
  onReceive_MarginCallNotify (value) {}  // eslint-disable-line

  /**
   * StopOutNotify receive handler
   * @this {!Receiver}
   * @param {!StopOutNotify} value StopOutNotify received value
   */
  onReceive_StopOutNotify (value) {}  // eslint-disable-line

  /**
   * AssetGetRequest receive handler
   * @this {!Receiver}
   * @param {!AssetGetRequest} value AssetGetRequest received value
   */
  onReceive_AssetGetRequest (value) {}  // eslint-disable-line

  /**
   * AssetGetResponse receive handler
   * @this {!Receiver}
   * @param {!AssetGetResponse} value AssetGetResponse received value
   */
  onReceive_AssetGetResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetRequest receive handler
   * @this {!Receiver}
   * @param {!AssetsGetRequest} value AssetsGetRequest received value
   */
  onReceive_AssetsGetRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetResponse receive handler
   * @this {!Receiver}
   * @param {!AssetsGetResponse} value AssetsGetResponse received value
   */
  onReceive_AssetsGetResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest received value
   */
  onReceive_AssetsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse received value
   */
  onReceive_AssetsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AssetUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!AssetUpdateNotify} value AssetUpdateNotify received value
   */
  onReceive_AssetUpdateNotify (value) {}  // eslint-disable-line

  /**
   * OrderGetRequest receive handler
   * @this {!Receiver}
   * @param {!OrderGetRequest} value OrderGetRequest received value
   */
  onReceive_OrderGetRequest (value) {}  // eslint-disable-line

  /**
   * OrderGetResponse receive handler
   * @this {!Receiver}
   * @param {!OrderGetResponse} value OrderGetResponse received value
   */
  onReceive_OrderGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetRequest receive handler
   * @this {!Receiver}
   * @param {!OrdersGetRequest} value OrdersGetRequest received value
   */
  onReceive_OrdersGetRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetResponse receive handler
   * @this {!Receiver}
   * @param {!OrdersGetResponse} value OrdersGetResponse received value
   */
  onReceive_OrdersGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest received value
   */
  onReceive_OrdersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse received value
   */
  onReceive_OrdersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCreateRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCreateRequest} value OrderCreateRequest received value
   */
  onReceive_OrderCreateRequest (value) {}  // eslint-disable-line

  /**
   * OrderCreateResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCreateResponse} value OrderCreateResponse received value
   */
  onReceive_OrderCreateResponse (value) {}  // eslint-disable-line

  /**
   * OrderModifyRequest receive handler
   * @this {!Receiver}
   * @param {!OrderModifyRequest} value OrderModifyRequest received value
   */
  onReceive_OrderModifyRequest (value) {}  // eslint-disable-line

  /**
   * OrderModifyResponse receive handler
   * @this {!Receiver}
   * @param {!OrderModifyResponse} value OrderModifyResponse received value
   */
  onReceive_OrderModifyResponse (value) {}  // eslint-disable-line

  /**
   * OrderActivateRequest receive handler
   * @this {!Receiver}
   * @param {!OrderActivateRequest} value OrderActivateRequest received value
   */
  onReceive_OrderActivateRequest (value) {}  // eslint-disable-line

  /**
   * OrderActivateResponse receive handler
   * @this {!Receiver}
   * @param {!OrderActivateResponse} value OrderActivateResponse received value
   */
  onReceive_OrderActivateResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelRequest} value OrderCancelRequest received value
   */
  onReceive_OrderCancelRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelResponse} value OrderCancelResponse received value
   */
  onReceive_OrderCancelResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest received value
   */
  onReceive_OrderCancelAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse received value
   */
  onReceive_OrderCancelAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllByIdRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest received value
   */
  onReceive_OrderCancelAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseRequest} value OrderCloseRequest received value
   */
  onReceive_OrderCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseResponse} value OrderCloseResponse received value
   */
  onReceive_OrderCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest received value
   */
  onReceive_OrderCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse received value
   */
  onReceive_OrderCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllByIdRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest received value
   */
  onReceive_OrderCloseAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest received value
   */
  onReceive_OrderCloseByRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse received value
   */
  onReceive_OrderCloseByResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest received value
   */
  onReceive_OrderCloseByAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse received value
   */
  onReceive_OrderCloseByAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest received value
   */
  onReceive_OrderCancelOrCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse received value
   */
  onReceive_OrderCancelOrCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest received value
   */
  onReceive_OrderCancelOrCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse received value
   */
  onReceive_OrderCancelOrCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateNotify} value OrderUpdateNotify received value
   */
  onReceive_OrderUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PositionGetRequest receive handler
   * @this {!Receiver}
   * @param {!PositionGetRequest} value PositionGetRequest received value
   */
  onReceive_PositionGetRequest (value) {}  // eslint-disable-line

  /**
   * PositionGetResponse receive handler
   * @this {!Receiver}
   * @param {!PositionGetResponse} value PositionGetResponse received value
   */
  onReceive_PositionGetResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetRequest receive handler
   * @this {!Receiver}
   * @param {!PositionsGetRequest} value PositionsGetRequest received value
   */
  onReceive_PositionsGetRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetResponse receive handler
   * @this {!Receiver}
   * @param {!PositionsGetResponse} value PositionsGetResponse received value
   */
  onReceive_PositionsGetResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest received value
   */
  onReceive_PositionsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse received value
   */
  onReceive_PositionsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!PositionUpdateNotify} value PositionUpdateNotify received value
   */
  onReceive_PositionUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest received value
   */
  onReceive_TradeHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse received value
   */
  onReceive_TradeHistoryResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryFilterRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryFilterRequest} value TradeHistoryFilterRequest received value
   */
  onReceive_TradeHistoryFilterRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest received value
   */
  onReceive_TradeHistoryByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse received value
   */
  onReceive_TradeHistoryByIdResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryUpdateNotify} value TradeHistoryUpdateNotify received value
   */
  onReceive_TradeHistoryUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TestRolloverDumpRequest receive handler
   * @this {!Receiver}
   * @param {!TestRolloverDumpRequest} value TestRolloverDumpRequest received value
   */
  onReceive_TestRolloverDumpRequest (value) {}  // eslint-disable-line

  /**
   * TestRolloverDumpResponse receive handler
   * @this {!Receiver}
   * @param {!TestRolloverDumpResponse} value TestRolloverDumpResponse received value
   */
  onReceive_TestRolloverDumpResponse (value) {}  // eslint-disable-line

  /**
   * Deprecated001 receive handler
   * @this {!Receiver}
   * @param {!Deprecated001} value Deprecated001 received value
   */
  onReceive_Deprecated001 (value) {}  // eslint-disable-line

  /**
   * Deprecated002 receive handler
   * @this {!Receiver}
   * @param {!Deprecated002} value Deprecated002 received value
   */
  onReceive_Deprecated002 (value) {}  // eslint-disable-line

  /**
   * Deprecated003 receive handler
   * @this {!Receiver}
   * @param {!Deprecated003} value Deprecated003 received value
   */
  onReceive_Deprecated003 (value) {}  // eslint-disable-line

  /**
   * Deprecated004 receive handler
   * @this {!Receiver}
   * @param {!Deprecated004} value Deprecated004 received value
   */
  onReceive_Deprecated004 (value) {}  // eslint-disable-line

  /**
   * Deprecated005 receive handler
   * @this {!Receiver}
   * @param {!Deprecated005} value Deprecated005 received value
   */
  onReceive_Deprecated005 (value) {}  // eslint-disable-line

  /**
   * Deprecated006 receive handler
   * @this {!Receiver}
   * @param {!Deprecated006} value Deprecated006 received value
   */
  onReceive_Deprecated006 (value) {}  // eslint-disable-line

  /**
   * Deprecated007 receive handler
   * @this {!Receiver}
   * @param {!Deprecated007} value Deprecated007 received value
   */
  onReceive_Deprecated007 (value) {}  // eslint-disable-line

  /**
   * Deprecated008 receive handler
   * @this {!Receiver}
   * @param {!Deprecated008} value Deprecated008 received value
   */
  onReceive_Deprecated008 (value) {}  // eslint-disable-line

  /**
   * Deprecated009 receive handler
   * @this {!Receiver}
   * @param {!Deprecated009} value Deprecated009 received value
   */
  onReceive_Deprecated009 (value) {}  // eslint-disable-line

  /**
   * Deprecated010 receive handler
   * @this {!Receiver}
   * @param {!Deprecated010} value Deprecated010 received value
   */
  onReceive_Deprecated010 (value) {}  // eslint-disable-line

  /**
   * Deprecated011 receive handler
   * @this {!Receiver}
   * @param {!Deprecated011} value Deprecated011 received value
   */
  onReceive_Deprecated011 (value) {}  // eslint-disable-line

  /**
   * Deprecated012 receive handler
   * @this {!Receiver}
   * @param {!Deprecated012} value Deprecated012 received value
   */
  onReceive_Deprecated012 (value) {}  // eslint-disable-line

  /**
   * Deprecated013 receive handler
   * @this {!Receiver}
   * @param {!Deprecated013} value Deprecated013 received value
   */
  onReceive_Deprecated013 (value) {}  // eslint-disable-line

  /**
   * DepositAccountRequest receive handler
   * @this {!Receiver}
   * @param {!DepositAccountRequest} value DepositAccountRequest received value
   */
  onReceive_DepositAccountRequest (value) {}  // eslint-disable-line

  /**
   * DepositAccountResponse receive handler
   * @this {!Receiver}
   * @param {!DepositAccountResponse} value DepositAccountResponse received value
   */
  onReceive_DepositAccountResponse (value) {}  // eslint-disable-line

  /**
   * CreditAccountRequest receive handler
   * @this {!Receiver}
   * @param {!CreditAccountRequest} value CreditAccountRequest received value
   */
  onReceive_CreditAccountRequest (value) {}  // eslint-disable-line

  /**
   * CreditAccountResponse receive handler
   * @this {!Receiver}
   * @param {!CreditAccountResponse} value CreditAccountResponse received value
   */
  onReceive_CreditAccountResponse (value) {}  // eslint-disable-line

  /**
   * TransferMoneyRequest receive handler
   * @this {!Receiver}
   * @param {!TransferMoneyRequest} value TransferMoneyRequest received value
   */
  onReceive_TransferMoneyRequest (value) {}  // eslint-disable-line

  /**
   * TransferMoneyResponse receive handler
   * @this {!Receiver}
   * @param {!TransferMoneyResponse} value TransferMoneyResponse received value
   */
  onReceive_TransferMoneyResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleGetRequest} value TradingCalendarRegularRuleGetRequest received value
   */
  onReceive_TradingCalendarRegularRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleGetResponse} value TradingCalendarRegularRuleGetResponse received value
   */
  onReceive_TradingCalendarRegularRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRulesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRulesGetAllRequest} value TradingCalendarRegularRulesGetAllRequest received value
   */
  onReceive_TradingCalendarRegularRulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRulesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRulesGetAllResponse} value TradingCalendarRegularRulesGetAllResponse received value
   */
  onReceive_TradingCalendarRegularRulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleCreateRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleCreateRequest} value TradingCalendarRegularRuleCreateRequest received value
   */
  onReceive_TradingCalendarRegularRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleCreateResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleCreateResponse} value TradingCalendarRegularRuleCreateResponse received value
   */
  onReceive_TradingCalendarRegularRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleModifyRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleModifyRequest} value TradingCalendarRegularRuleModifyRequest received value
   */
  onReceive_TradingCalendarRegularRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleModifyResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleModifyResponse} value TradingCalendarRegularRuleModifyResponse received value
   */
  onReceive_TradingCalendarRegularRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleDeleteRequest} value TradingCalendarRegularRuleDeleteRequest received value
   */
  onReceive_TradingCalendarRegularRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleDeleteResponse} value TradingCalendarRegularRuleDeleteResponse received value
   */
  onReceive_TradingCalendarRegularRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRegularRuleUpdateNotify} value TradingCalendarRegularRuleUpdateNotify received value
   */
  onReceive_TradingCalendarRegularRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleGetRequest} value TradingCalendarOffTimeRuleGetRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleGetResponse} value TradingCalendarOffTimeRuleGetResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRulesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRulesGetAllRequest} value TradingCalendarOffTimeRulesGetAllRequest received value
   */
  onReceive_TradingCalendarOffTimeRulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRulesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRulesGetAllResponse} value TradingCalendarOffTimeRulesGetAllResponse received value
   */
  onReceive_TradingCalendarOffTimeRulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleCreateRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} value TradingCalendarOffTimeRuleCreateRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleCreateResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleCreateResponse} value TradingCalendarOffTimeRuleCreateResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleModifyRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} value TradingCalendarOffTimeRuleModifyRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleModifyResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleModifyResponse} value TradingCalendarOffTimeRuleModifyResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleDeleteRequest} value TradingCalendarOffTimeRuleDeleteRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleDeleteResponse} value TradingCalendarOffTimeRuleDeleteResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarOffTimeRuleUpdateNotify} value TradingCalendarOffTimeRuleUpdateNotify received value
   */
  onReceive_TradingCalendarOffTimeRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRulesGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRulesGetRequest} value TradingCalendarRulesGetRequest received value
   */
  onReceive_TradingCalendarRulesGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRulesGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradingCalendarRulesGetResponse} value TradingCalendarRulesGetResponse received value
   */
  onReceive_TradingCalendarRulesGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRulesGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRulesGetRequest} value TradingRouteRulesGetRequest received value
   */
  onReceive_TradingRouteRulesGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRulesGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRulesGetResponse} value TradingRouteRulesGetResponse received value
   */
  onReceive_TradingRouteRulesGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleCreateRequest receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRuleCreateRequest} value TradingRouteRuleCreateRequest received value
   */
  onReceive_TradingRouteRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleCreateResponse receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRuleCreateResponse} value TradingRouteRuleCreateResponse received value
   */
  onReceive_TradingRouteRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleModifyRequest receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRuleModifyRequest} value TradingRouteRuleModifyRequest received value
   */
  onReceive_TradingRouteRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleModifyResponse receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRuleModifyResponse} value TradingRouteRuleModifyResponse received value
   */
  onReceive_TradingRouteRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRuleDeleteRequest} value TradingRouteRuleDeleteRequest received value
   */
  onReceive_TradingRouteRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRuleDeleteResponse} value TradingRouteRuleDeleteResponse received value
   */
  onReceive_TradingRouteRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TradingRouteRuleUpdateNotify} value TradingRouteRuleUpdateNotify received value
   */
  onReceive_TradingRouteRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolMapsGetRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolMapsGetRequest} value SymbolMapsGetRequest received value
   */
  onReceive_SymbolMapsGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapsGetResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolMapsGetResponse} value SymbolMapsGetResponse received value
   */
  onReceive_SymbolMapsGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapCreateRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolMapCreateRequest} value SymbolMapCreateRequest received value
   */
  onReceive_SymbolMapCreateRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapCreateResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolMapCreateResponse} value SymbolMapCreateResponse received value
   */
  onReceive_SymbolMapCreateResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapModifyRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolMapModifyRequest} value SymbolMapModifyRequest received value
   */
  onReceive_SymbolMapModifyRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapModifyResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolMapModifyResponse} value SymbolMapModifyResponse received value
   */
  onReceive_SymbolMapModifyResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolMapDeleteRequest} value SymbolMapDeleteRequest received value
   */
  onReceive_SymbolMapDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolMapDeleteResponse} value SymbolMapDeleteResponse received value
   */
  onReceive_SymbolMapDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolMapUpdateNotify} value SymbolMapUpdateNotify received value
   */
  onReceive_SymbolMapUpdateNotify (value) {}  // eslint-disable-line

  /**
   * GatewaysGetRequest receive handler
   * @this {!Receiver}
   * @param {!GatewaysGetRequest} value GatewaysGetRequest received value
   */
  onReceive_GatewaysGetRequest (value) {}  // eslint-disable-line

  /**
   * GatewaysGetResponse receive handler
   * @this {!Receiver}
   * @param {!GatewaysGetResponse} value GatewaysGetResponse received value
   */
  onReceive_GatewaysGetResponse (value) {}  // eslint-disable-line

  /**
   * GatewayCreateRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayCreateRequest} value GatewayCreateRequest received value
   */
  onReceive_GatewayCreateRequest (value) {}  // eslint-disable-line

  /**
   * GatewayCreateResponse receive handler
   * @this {!Receiver}
   * @param {!GatewayCreateResponse} value GatewayCreateResponse received value
   */
  onReceive_GatewayCreateResponse (value) {}  // eslint-disable-line

  /**
   * GatewayModifyRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayModifyRequest} value GatewayModifyRequest received value
   */
  onReceive_GatewayModifyRequest (value) {}  // eslint-disable-line

  /**
   * GatewayModifyResponse receive handler
   * @this {!Receiver}
   * @param {!GatewayModifyResponse} value GatewayModifyResponse received value
   */
  onReceive_GatewayModifyResponse (value) {}  // eslint-disable-line

  /**
   * GatewayDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayDeleteRequest} value GatewayDeleteRequest received value
   */
  onReceive_GatewayDeleteRequest (value) {}  // eslint-disable-line

  /**
   * GatewayDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!GatewayDeleteResponse} value GatewayDeleteResponse received value
   */
  onReceive_GatewayDeleteResponse (value) {}  // eslint-disable-line

  /**
   * GatewayUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!GatewayUpdateNotify} value GatewayUpdateNotify received value
   */
  onReceive_GatewayUpdateNotify (value) {}  // eslint-disable-line

  /**
   * DataFeedsGetRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedsGetRequest} value DataFeedsGetRequest received value
   */
  onReceive_DataFeedsGetRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedsGetResponse receive handler
   * @this {!Receiver}
   * @param {!DataFeedsGetResponse} value DataFeedsGetResponse received value
   */
  onReceive_DataFeedsGetResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedCreateRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedCreateRequest} value DataFeedCreateRequest received value
   */
  onReceive_DataFeedCreateRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedCreateResponse receive handler
   * @this {!Receiver}
   * @param {!DataFeedCreateResponse} value DataFeedCreateResponse received value
   */
  onReceive_DataFeedCreateResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedModifyRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedModifyRequest} value DataFeedModifyRequest received value
   */
  onReceive_DataFeedModifyRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedModifyResponse receive handler
   * @this {!Receiver}
   * @param {!DataFeedModifyResponse} value DataFeedModifyResponse received value
   */
  onReceive_DataFeedModifyResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedDeleteRequest} value DataFeedDeleteRequest received value
   */
  onReceive_DataFeedDeleteRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!DataFeedDeleteResponse} value DataFeedDeleteResponse received value
   */
  onReceive_DataFeedDeleteResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!DataFeedUpdateNotify} value DataFeedUpdateNotify received value
   */
  onReceive_DataFeedUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoNotify receive handler
   * @this {!Receiver}
   * @param {!ModuleSystemInfoNotify} value ModuleSystemInfoNotify received value
   */
  onReceive_ModuleSystemInfoNotify (value) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!ModulesSystemInfoGetAllRequest} value ModulesSystemInfoGetAllRequest received value
   */
  onReceive_ModulesSystemInfoGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!ModulesSystemInfoGetAllResponse} value ModulesSystemInfoGetAllResponse received value
   */
  onReceive_ModulesSystemInfoGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoNotify receive handler
   * @this {!Receiver}
   * @param {!ModulesSystemInfoNotify} value ModulesSystemInfoNotify received value
   */
  onReceive_ModulesSystemInfoNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest received value
   */
  onReceive_ChartBarHistoryModifyRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse received value
   */
  onReceive_ChartBarHistoryModifyResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest received value
   */
  onReceive_FeedUpdateTickSnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotResponse receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateTickSnapshotResponse} value FeedUpdateTickSnapshotResponse received value
   */
  onReceive_FeedUpdateTickSnapshotResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest received value
   */
  onReceive_FeedUpdateTickRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickResponse receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateTickResponse} value FeedUpdateTickResponse received value
   */
  onReceive_FeedUpdateTickResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest received value
   */
  onReceive_FeedUpdateLevel2SnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotResponse receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateLevel2SnapshotResponse} value FeedUpdateLevel2SnapshotResponse received value
   */
  onReceive_FeedUpdateLevel2SnapshotResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Request receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request received value
   */
  onReceive_FeedUpdateLevel2Request (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Response receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateLevel2Response} value FeedUpdateLevel2Response received value
   */
  onReceive_FeedUpdateLevel2Response (value) {}  // eslint-disable-line

  /**
   * MailServerGetRequest receive handler
   * @this {!Receiver}
   * @param {!MailServerGetRequest} value MailServerGetRequest received value
   */
  onReceive_MailServerGetRequest (value) {}  // eslint-disable-line

  /**
   * MailServerGetResponse receive handler
   * @this {!Receiver}
   * @param {!MailServerGetResponse} value MailServerGetResponse received value
   */
  onReceive_MailServerGetResponse (value) {}  // eslint-disable-line

  /**
   * MailServersGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!MailServersGetAllRequest} value MailServersGetAllRequest received value
   */
  onReceive_MailServersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MailServersGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!MailServersGetAllResponse} value MailServersGetAllResponse received value
   */
  onReceive_MailServersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MailServerCreateRequest receive handler
   * @this {!Receiver}
   * @param {!MailServerCreateRequest} value MailServerCreateRequest received value
   */
  onReceive_MailServerCreateRequest (value) {}  // eslint-disable-line

  /**
   * MailServerCreateResponse receive handler
   * @this {!Receiver}
   * @param {!MailServerCreateResponse} value MailServerCreateResponse received value
   */
  onReceive_MailServerCreateResponse (value) {}  // eslint-disable-line

  /**
   * MailServerModifyRequest receive handler
   * @this {!Receiver}
   * @param {!MailServerModifyRequest} value MailServerModifyRequest received value
   */
  onReceive_MailServerModifyRequest (value) {}  // eslint-disable-line

  /**
   * MailServerModifyResponse receive handler
   * @this {!Receiver}
   * @param {!MailServerModifyResponse} value MailServerModifyResponse received value
   */
  onReceive_MailServerModifyResponse (value) {}  // eslint-disable-line

  /**
   * MailServerDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!MailServerDeleteRequest} value MailServerDeleteRequest received value
   */
  onReceive_MailServerDeleteRequest (value) {}  // eslint-disable-line

  /**
   * MailServerDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!MailServerDeleteResponse} value MailServerDeleteResponse received value
   */
  onReceive_MailServerDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MailServerUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!MailServerUpdateNotify} value MailServerUpdateNotify received value
   */
  onReceive_MailServerUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountStatesUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!AccountStatesUpdateNotify} value AccountStatesUpdateNotify received value
   */
  onReceive_AccountStatesUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountStatesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AccountStatesGetAllRequest} value AccountStatesGetAllRequest received value
   */
  onReceive_AccountStatesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountStatesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AccountStatesGetAllResponse} value AccountStatesGetAllResponse received value
   */
  onReceive_AccountStatesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ModuleAuthRequest receive handler
   * @this {!Receiver}
   * @param {!ModuleAuthRequest} value ModuleAuthRequest received value
   */
  onReceive_ModuleAuthRequest (value) {}  // eslint-disable-line

  /**
   * ModuleAuthResponse receive handler
   * @this {!Receiver}
   * @param {!ModuleAuthResponse} value ModuleAuthResponse received value
   */
  onReceive_ModuleAuthResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileGetRequest receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileGetRequest} value LeverageProfileGetRequest received value
   */
  onReceive_LeverageProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileGetResponse receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileGetResponse} value LeverageProfileGetResponse received value
   */
  onReceive_LeverageProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfilesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!LeverageProfilesGetAllRequest} value LeverageProfilesGetAllRequest received value
   */
  onReceive_LeverageProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfilesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!LeverageProfilesGetAllResponse} value LeverageProfilesGetAllResponse received value
   */
  onReceive_LeverageProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileCreateRequest receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileCreateRequest} value LeverageProfileCreateRequest received value
   */
  onReceive_LeverageProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileCreateResponse receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileCreateResponse} value LeverageProfileCreateResponse received value
   */
  onReceive_LeverageProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileModifyRequest receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileModifyRequest} value LeverageProfileModifyRequest received value
   */
  onReceive_LeverageProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileModifyResponse receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileModifyResponse} value LeverageProfileModifyResponse received value
   */
  onReceive_LeverageProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileDeleteRequest} value LeverageProfileDeleteRequest received value
   */
  onReceive_LeverageProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileDeleteResponse} value LeverageProfileDeleteResponse received value
   */
  onReceive_LeverageProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!LeverageProfileUpdateNotify} value LeverageProfileUpdateNotify received value
   */
  onReceive_LeverageProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileGetRequest receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileGetRequest} value MarginRateProfileGetRequest received value
   */
  onReceive_MarginRateProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileGetResponse receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileGetResponse} value MarginRateProfileGetResponse received value
   */
  onReceive_MarginRateProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfilesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfilesGetAllRequest} value MarginRateProfilesGetAllRequest received value
   */
  onReceive_MarginRateProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfilesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfilesGetAllResponse} value MarginRateProfilesGetAllResponse received value
   */
  onReceive_MarginRateProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileCreateRequest receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileCreateRequest} value MarginRateProfileCreateRequest received value
   */
  onReceive_MarginRateProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileCreateResponse receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileCreateResponse} value MarginRateProfileCreateResponse received value
   */
  onReceive_MarginRateProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileModifyRequest receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileModifyRequest} value MarginRateProfileModifyRequest received value
   */
  onReceive_MarginRateProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileModifyResponse receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileModifyResponse} value MarginRateProfileModifyResponse received value
   */
  onReceive_MarginRateProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileDeleteRequest} value MarginRateProfileDeleteRequest received value
   */
  onReceive_MarginRateProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileDeleteResponse} value MarginRateProfileDeleteResponse received value
   */
  onReceive_MarginRateProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!MarginRateProfileUpdateNotify} value MarginRateProfileUpdateNotify received value
   */
  onReceive_MarginRateProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * NewsProfileGetRequest receive handler
   * @this {!Receiver}
   * @param {!NewsProfileGetRequest} value NewsProfileGetRequest received value
   */
  onReceive_NewsProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileGetResponse receive handler
   * @this {!Receiver}
   * @param {!NewsProfileGetResponse} value NewsProfileGetResponse received value
   */
  onReceive_NewsProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfilesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!NewsProfilesGetAllRequest} value NewsProfilesGetAllRequest received value
   */
  onReceive_NewsProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfilesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!NewsProfilesGetAllResponse} value NewsProfilesGetAllResponse received value
   */
  onReceive_NewsProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileCreateRequest receive handler
   * @this {!Receiver}
   * @param {!NewsProfileCreateRequest} value NewsProfileCreateRequest received value
   */
  onReceive_NewsProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileCreateResponse receive handler
   * @this {!Receiver}
   * @param {!NewsProfileCreateResponse} value NewsProfileCreateResponse received value
   */
  onReceive_NewsProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileModifyRequest receive handler
   * @this {!Receiver}
   * @param {!NewsProfileModifyRequest} value NewsProfileModifyRequest received value
   */
  onReceive_NewsProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileModifyResponse receive handler
   * @this {!Receiver}
   * @param {!NewsProfileModifyResponse} value NewsProfileModifyResponse received value
   */
  onReceive_NewsProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!NewsProfileDeleteRequest} value NewsProfileDeleteRequest received value
   */
  onReceive_NewsProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!NewsProfileDeleteResponse} value NewsProfileDeleteResponse received value
   */
  onReceive_NewsProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!NewsProfileUpdateNotify} value NewsProfileUpdateNotify received value
   */
  onReceive_NewsProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * NewsProfileImportRequest receive handler
   * @this {!Receiver}
   * @param {!NewsProfileImportRequest} value NewsProfileImportRequest received value
   */
  onReceive_NewsProfileImportRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileImportResponse receive handler
   * @this {!Receiver}
   * @param {!NewsProfileImportResponse} value NewsProfileImportResponse received value
   */
  onReceive_NewsProfileImportResponse (value) {}  // eslint-disable-line

  /**
   * TickHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!TickHistoryRequest} value TickHistoryRequest received value
   */
  onReceive_TickHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TickHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!TickHistoryResponse} value TickHistoryResponse received value
   */
  onReceive_TickHistoryResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateGetRequest receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateGetRequest} value EmailTemplateGetRequest received value
   */
  onReceive_EmailTemplateGetRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateGetResponse receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateGetResponse} value EmailTemplateGetResponse received value
   */
  onReceive_EmailTemplateGetResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplatesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!EmailTemplatesGetAllRequest} value EmailTemplatesGetAllRequest received value
   */
  onReceive_EmailTemplatesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplatesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!EmailTemplatesGetAllResponse} value EmailTemplatesGetAllResponse received value
   */
  onReceive_EmailTemplatesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateCreateRequest receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateCreateRequest} value EmailTemplateCreateRequest received value
   */
  onReceive_EmailTemplateCreateRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateCreateResponse receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateCreateResponse} value EmailTemplateCreateResponse received value
   */
  onReceive_EmailTemplateCreateResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateModifyRequest receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateModifyRequest} value EmailTemplateModifyRequest received value
   */
  onReceive_EmailTemplateModifyRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateModifyResponse receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateModifyResponse} value EmailTemplateModifyResponse received value
   */
  onReceive_EmailTemplateModifyResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateDeleteRequest} value EmailTemplateDeleteRequest received value
   */
  onReceive_EmailTemplateDeleteRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateDeleteResponse} value EmailTemplateDeleteResponse received value
   */
  onReceive_EmailTemplateDeleteResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!EmailTemplateUpdateNotify} value EmailTemplateUpdateNotify received value
   */
  onReceive_EmailTemplateUpdateNotify (value) {}  // eslint-disable-line

  /**
   * OrderStateSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderStateSubscribeRequest} value OrderStateSubscribeRequest received value
   */
  onReceive_OrderStateSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderStateSubscribeResponse} value OrderStateSubscribeResponse received value
   */
  onReceive_OrderStateSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateUpdateSubscriptionRequest receive handler
   * @this {!Receiver}
   * @param {!OrderStateUpdateSubscriptionRequest} value OrderStateUpdateSubscriptionRequest received value
   */
  onReceive_OrderStateUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateUpdateSubscriptionResponse receive handler
   * @this {!Receiver}
   * @param {!OrderStateUpdateSubscriptionResponse} value OrderStateUpdateSubscriptionResponse received value
   */
  onReceive_OrderStateUpdateSubscriptionResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderStateUnsubscribeRequest} value OrderStateUnsubscribeRequest received value
   */
  onReceive_OrderStateUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderStateUnsubscribeResponse} value OrderStateUnsubscribeResponse received value
   */
  onReceive_OrderStateUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateNotify receive handler
   * @this {!Receiver}
   * @param {!OrderStateNotify} value OrderStateNotify received value
   */
  onReceive_OrderStateNotify (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!RequestMonitoringSubscribeRequest} value RequestMonitoringSubscribeRequest received value
   */
  onReceive_RequestMonitoringSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!RequestMonitoringSubscribeResponse} value RequestMonitoringSubscribeResponse received value
   */
  onReceive_RequestMonitoringSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!RequestMonitoringUnsubscribeRequest} value RequestMonitoringUnsubscribeRequest received value
   */
  onReceive_RequestMonitoringUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!RequestMonitoringUnsubscribeResponse} value RequestMonitoringUnsubscribeResponse received value
   */
  onReceive_RequestMonitoringUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringRequestUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!RequestMonitoringRequestUpdateNotify} value RequestMonitoringRequestUpdateNotify received value
   */
  onReceive_RequestMonitoringRequestUpdateNotify (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!FilledOrdersSummaryInfoSubscribeRequest} value FilledOrdersSummaryInfoSubscribeRequest received value
   */
  onReceive_FilledOrdersSummaryInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!FilledOrdersSummaryInfoSubscribeResponse} value FilledOrdersSummaryInfoSubscribeResponse received value
   */
  onReceive_FilledOrdersSummaryInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoNotify receive handler
   * @this {!Receiver}
   * @param {!FilledOrdersSummaryInfoNotify} value FilledOrdersSummaryInfoNotify received value
   */
  onReceive_FilledOrdersSummaryInfoNotify (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUpdateSubscriptionRequest receive handler
   * @this {!Receiver}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} value FilledOrdersSummaryInfoUpdateSubscriptionRequest received value
   */
  onReceive_FilledOrdersSummaryInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!FilledOrdersSummaryInfoUnsubscribeRequest} value FilledOrdersSummaryInfoUnsubscribeRequest received value
   */
  onReceive_FilledOrdersSummaryInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!FilledOrdersSummaryInfoUnsubscribeResponse} value FilledOrdersSummaryInfoUnsubscribeResponse received value
   */
  onReceive_FilledOrdersSummaryInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsNotify receive handler
   * @this {!Receiver}
   * @param {!TradeEventsNotify} value TradeEventsNotify received value
   */
  onReceive_TradeEventsNotify (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest received value
   */
  onReceive_TradeEventsGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse received value
   */
  onReceive_TradeEventsGetResponse (value) {}  // eslint-disable-line

  /**
   * JournalRequest receive handler
   * @this {!Receiver}
   * @param {!JournalRequest} value JournalRequest received value
   */
  onReceive_JournalRequest (value) {}  // eslint-disable-line

  /**
   * JournalResponse receive handler
   * @this {!Receiver}
   * @param {!JournalResponse} value JournalResponse received value
   */
  onReceive_JournalResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest received value
   */
  onReceive_ClientReportsListGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse received value
   */
  onReceive_ClientReportsListGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportGetRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest received value
   */
  onReceive_ClientReportGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportGetResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse received value
   */
  onReceive_ClientReportGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest received value
   */
  onReceive_ClientReportsSendRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse received value
   */
  onReceive_ClientReportsSendResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest received value
   */
  onReceive_ProfitClosedGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse received value
   */
  onReceive_ProfitClosedGetResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest received value
   */
  onReceive_ChartBarsImportRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse received value
   */
  onReceive_ChartBarsImportResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify received value
   */
  onReceive_ChartBarsImportNotify (value) {}  // eslint-disable-line

  /**
   * JournalBusinessRequest receive handler
   * @this {!Receiver}
   * @param {!JournalBusinessRequest} value JournalBusinessRequest received value
   */
  onReceive_JournalBusinessRequest (value) {}  // eslint-disable-line

  /**
   * JournalBusinessResponse receive handler
   * @this {!Receiver}
   * @param {!JournalBusinessResponse} value JournalBusinessResponse received value
   */
  onReceive_JournalBusinessResponse (value) {}  // eslint-disable-line

  /**
   * AccountsGetPageRequest receive handler
   * @this {!Receiver}
   * @param {!AccountsGetPageRequest} value AccountsGetPageRequest received value
   */
  onReceive_AccountsGetPageRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetPageResponse receive handler
   * @this {!Receiver}
   * @param {!AccountsGetPageResponse} value AccountsGetPageResponse received value
   */
  onReceive_AccountsGetPageResponse (value) {}  // eslint-disable-line

  /**
   * HeartbeatNotify receive handler
   * @this {!Receiver}
   * @param {!HeartbeatNotify} value HeartbeatNotify received value
   */
  onReceive_HeartbeatNotify (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetRequest receive handler
   * @this {!Receiver}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest received value
   */
  onReceive_OrdersClosedSummariesGetRequest (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetResponse receive handler
   * @this {!Receiver}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse received value
   */
  onReceive_OrdersClosedSummariesGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetPageRequest receive handler
   * @this {!Receiver}
   * @param {!OrdersGetPageRequest} value OrdersGetPageRequest received value
   */
  onReceive_OrdersGetPageRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetPageResponse receive handler
   * @this {!Receiver}
   * @param {!OrdersGetPageResponse} value OrdersGetPageResponse received value
   */
  onReceive_OrdersGetPageResponse (value) {}  // eslint-disable-line

  /**
   * StorageUniversalNotify receive handler
   * @this {!Receiver}
   * @param {!StorageUniversalNotify} value StorageUniversalNotify received value
   */
  onReceive_StorageUniversalNotify (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetRequest receive handler
   * @this {!Receiver}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest received value
   */
  onReceive_ClientLoginInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetResponse receive handler
   * @this {!Receiver}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse received value
   */
  onReceive_ClientLoginInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupExistsGetRequest receive handler
   * @this {!Receiver}
   * @param {!AccountGroupExistsGetRequest} value AccountGroupExistsGetRequest received value
   */
  onReceive_AccountGroupExistsGetRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupExistsGetResponse receive handler
   * @this {!Receiver}
   * @param {!AccountGroupExistsGetResponse} value AccountGroupExistsGetResponse received value
   */
  onReceive_AccountGroupExistsGetResponse (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordRequest receive handler
   * @this {!Receiver}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest received value
   */
  onReceive_LoginWithPasswordRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordResponse receive handler
   * @this {!Receiver}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse received value
   */
  onReceive_LoginWithPasswordResponse (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenRequest receive handler
   * @this {!Receiver}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest received value
   */
  onReceive_LoginWithTokenRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenResponse receive handler
   * @this {!Receiver}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse received value
   */
  onReceive_LoginWithTokenResponse (value) {}  // eslint-disable-line

  /**
   * RefreshTokenRequest receive handler
   * @this {!Receiver}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest received value
   */
  onReceive_RefreshTokenRequest (value) {}  // eslint-disable-line

  /**
   * RefreshTokenResponse receive handler
   * @this {!Receiver}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse received value
   */
  onReceive_RefreshTokenResponse (value) {}  // eslint-disable-line

  /**
   * PersonGetRequest receive handler
   * @this {!Receiver}
   * @param {!PersonGetRequest} value PersonGetRequest received value
   */
  onReceive_PersonGetRequest (value) {}  // eslint-disable-line

  /**
   * PersonGetResponse receive handler
   * @this {!Receiver}
   * @param {!PersonGetResponse} value PersonGetResponse received value
   */
  onReceive_PersonGetResponse (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest received value
   */
  onReceive_PersonsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse received value
   */
  onReceive_PersonsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PersonCreateRequest receive handler
   * @this {!Receiver}
   * @param {!PersonCreateRequest} value PersonCreateRequest received value
   */
  onReceive_PersonCreateRequest (value) {}  // eslint-disable-line

  /**
   * PersonCreateResponse receive handler
   * @this {!Receiver}
   * @param {!PersonCreateResponse} value PersonCreateResponse received value
   */
  onReceive_PersonCreateResponse (value) {}  // eslint-disable-line

  /**
   * PersonModifyRequest receive handler
   * @this {!Receiver}
   * @param {!PersonModifyRequest} value PersonModifyRequest received value
   */
  onReceive_PersonModifyRequest (value) {}  // eslint-disable-line

  /**
   * PersonModifyResponse receive handler
   * @this {!Receiver}
   * @param {!PersonModifyResponse} value PersonModifyResponse received value
   */
  onReceive_PersonModifyResponse (value) {}  // eslint-disable-line

  /**
   * PersonUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!PersonUpdateNotify} value PersonUpdateNotify received value
   */
  onReceive_PersonUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetRequest receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsGetRequest} value SecuritySettingsGetRequest received value
   */
  onReceive_SecuritySettingsGetRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetResponse receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsGetResponse} value SecuritySettingsGetResponse received value
   */
  onReceive_SecuritySettingsGetResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsGetAllRequest} value SecuritySettingsGetAllRequest received value
   */
  onReceive_SecuritySettingsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsGetAllResponse} value SecuritySettingsGetAllResponse received value
   */
  onReceive_SecuritySettingsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsCreateRequest receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsCreateRequest} value SecuritySettingsCreateRequest received value
   */
  onReceive_SecuritySettingsCreateRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsCreateResponse receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsCreateResponse} value SecuritySettingsCreateResponse received value
   */
  onReceive_SecuritySettingsCreateResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsModifyRequest receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsModifyRequest} value SecuritySettingsModifyRequest received value
   */
  onReceive_SecuritySettingsModifyRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsModifyResponse receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsModifyResponse} value SecuritySettingsModifyResponse received value
   */
  onReceive_SecuritySettingsModifyResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsDeleteRequest} value SecuritySettingsDeleteRequest received value
   */
  onReceive_SecuritySettingsDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsDeleteResponse} value SecuritySettingsDeleteResponse received value
   */
  onReceive_SecuritySettingsDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SecuritySettingsUpdateNotify} value SecuritySettingsUpdateNotify received value
   */
  onReceive_SecuritySettingsUpdateNotify (value) {}  // eslint-disable-line

  /**
   * FirewallRuleGetRequest receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleGetRequest} value FirewallRuleGetRequest received value
   */
  onReceive_FirewallRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleGetResponse receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleGetResponse} value FirewallRuleGetResponse received value
   */
  onReceive_FirewallRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRulesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!FirewallRulesGetAllRequest} value FirewallRulesGetAllRequest received value
   */
  onReceive_FirewallRulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRulesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!FirewallRulesGetAllResponse} value FirewallRulesGetAllResponse received value
   */
  onReceive_FirewallRulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleCreateRequest receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleCreateRequest} value FirewallRuleCreateRequest received value
   */
  onReceive_FirewallRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleCreateResponse receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleCreateResponse} value FirewallRuleCreateResponse received value
   */
  onReceive_FirewallRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleModifyRequest receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleModifyRequest} value FirewallRuleModifyRequest received value
   */
  onReceive_FirewallRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleModifyResponse receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleModifyResponse} value FirewallRuleModifyResponse received value
   */
  onReceive_FirewallRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleDeleteRequest} value FirewallRuleDeleteRequest received value
   */
  onReceive_FirewallRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleDeleteResponse} value FirewallRuleDeleteResponse received value
   */
  onReceive_FirewallRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!FirewallRuleUpdateNotify} value FirewallRuleUpdateNotify received value
   */
  onReceive_FirewallRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * manager receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case PingModuleRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PingModuleRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PingModuleRequestModel.verify(), 'manager.PingModuleRequest validation failed!')
        let deserialized = this._PingModuleRequestModel.deserialize(this._PingModuleRequestValue)
        console.assert((deserialized.size > 0), 'manager.PingModuleRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PingModuleRequestValue)
        this.onReceive_PingModuleRequest(this._PingModuleRequestValue)
        return true
      }
      case PongModuleResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PongModuleResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PongModuleResponseModel.verify(), 'manager.PongModuleResponse validation failed!')
        let deserialized = this._PongModuleResponseModel.deserialize(this._PongModuleResponseValue)
        console.assert((deserialized.size > 0), 'manager.PongModuleResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PongModuleResponseValue)
        this.onReceive_PongModuleResponse(this._PongModuleResponseValue)
        return true
      }
      case PingModuleRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PingModuleRejectModel.attachBuffer(buffer, offset)
        console.assert(this._PingModuleRejectModel.verify(), 'manager.PingModuleReject validation failed!')
        let deserialized = this._PingModuleRejectModel.deserialize(this._PingModuleRejectValue)
        console.assert((deserialized.size > 0), 'manager.PingModuleReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PingModuleRejectValue)
        this.onReceive_PingModuleReject(this._PingModuleRejectValue)
        return true
      }
      case Deprecated3Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated3Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated3Model.verify(), 'manager.Deprecated3 validation failed!')
        let deserialized = this._Deprecated3Model.deserialize(this._Deprecated3Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated3 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated3Value)
        this.onReceive_Deprecated3(this._Deprecated3Value)
        return true
      }
      case Deprecated4Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated4Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated4Model.verify(), 'manager.Deprecated4 validation failed!')
        let deserialized = this._Deprecated4Model.deserialize(this._Deprecated4Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated4 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated4Value)
        this.onReceive_Deprecated4(this._Deprecated4Value)
        return true
      }
      case Deprecated1Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated1Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated1Model.verify(), 'manager.Deprecated1 validation failed!')
        let deserialized = this._Deprecated1Model.deserialize(this._Deprecated1Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated1 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated1Value)
        this.onReceive_Deprecated1(this._Deprecated1Value)
        return true
      }
      case Deprecated2Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated2Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated2Model.verify(), 'manager.Deprecated2 validation failed!')
        let deserialized = this._Deprecated2Model.deserialize(this._Deprecated2Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated2 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated2Value)
        this.onReceive_Deprecated2(this._Deprecated2Value)
        return true
      }
      case ManagerSessionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerSessionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerSessionInfoRequestModel.verify(), 'manager.ManagerSessionInfoRequest validation failed!')
        let deserialized = this._ManagerSessionInfoRequestModel.deserialize(this._ManagerSessionInfoRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerSessionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerSessionInfoRequestValue)
        this.onReceive_ManagerSessionInfoRequest(this._ManagerSessionInfoRequestValue)
        return true
      }
      case ManagerSessionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerSessionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerSessionInfoResponseModel.verify(), 'manager.ManagerSessionInfoResponse validation failed!')
        let deserialized = this._ManagerSessionInfoResponseModel.deserialize(this._ManagerSessionInfoResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerSessionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerSessionInfoResponseValue)
        this.onReceive_ManagerSessionInfoResponse(this._ManagerSessionInfoResponseValue)
        return true
      }
      case OnlineSessionsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OnlineSessionsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OnlineSessionsRequestModel.verify(), 'manager.OnlineSessionsRequest validation failed!')
        let deserialized = this._OnlineSessionsRequestModel.deserialize(this._OnlineSessionsRequestValue)
        console.assert((deserialized.size > 0), 'manager.OnlineSessionsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OnlineSessionsRequestValue)
        this.onReceive_OnlineSessionsRequest(this._OnlineSessionsRequestValue)
        return true
      }
      case OnlineSessionsResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OnlineSessionsResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OnlineSessionsResponseModel.verify(), 'manager.OnlineSessionsResponse validation failed!')
        let deserialized = this._OnlineSessionsResponseModel.deserialize(this._OnlineSessionsResponseValue)
        console.assert((deserialized.size > 0), 'manager.OnlineSessionsResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OnlineSessionsResponseValue)
        this.onReceive_OnlineSessionsResponse(this._OnlineSessionsResponseValue)
        return true
      }
      case DropSessionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DropSessionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DropSessionRequestModel.verify(), 'manager.DropSessionRequest validation failed!')
        let deserialized = this._DropSessionRequestModel.deserialize(this._DropSessionRequestValue)
        console.assert((deserialized.size > 0), 'manager.DropSessionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DropSessionRequestValue)
        this.onReceive_DropSessionRequest(this._DropSessionRequestValue)
        return true
      }
      case DropSessionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DropSessionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DropSessionResponseModel.verify(), 'manager.DropSessionResponse validation failed!')
        let deserialized = this._DropSessionResponseModel.deserialize(this._DropSessionResponseValue)
        console.assert((deserialized.size > 0), 'manager.DropSessionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DropSessionResponseValue)
        this.onReceive_DropSessionResponse(this._DropSessionResponseValue)
        return true
      }
      case ConfigRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRequestModel.verify(), 'manager.ConfigRequest validation failed!')
        let deserialized = this._ConfigRequestModel.deserialize(this._ConfigRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRequestValue)
        this.onReceive_ConfigRequest(this._ConfigRequestValue)
        return true
      }
      case ConfigResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigResponseModel.verify(), 'manager.ConfigResponse validation failed!')
        let deserialized = this._ConfigResponseModel.deserialize(this._ConfigResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigResponseValue)
        this.onReceive_ConfigResponse(this._ConfigResponseValue)
        return true
      }
      case ConfigRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRejectModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRejectModel.verify(), 'manager.ConfigReject validation failed!')
        let deserialized = this._ConfigRejectModel.deserialize(this._ConfigRejectValue)
        console.assert((deserialized.size > 0), 'manager.ConfigReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRejectValue)
        this.onReceive_ConfigReject(this._ConfigRejectValue)
        return true
      }
      case ConfigUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRequestModel.verify(), 'manager.ConfigUpdateRequest validation failed!')
        let deserialized = this._ConfigUpdateRequestModel.deserialize(this._ConfigUpdateRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateRequestValue)
        this.onReceive_ConfigUpdateRequest(this._ConfigUpdateRequestValue)
        return true
      }
      case ConfigUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateResponseModel.verify(), 'manager.ConfigUpdateResponse validation failed!')
        let deserialized = this._ConfigUpdateResponseModel.deserialize(this._ConfigUpdateResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateResponseValue)
        this.onReceive_ConfigUpdateResponse(this._ConfigUpdateResponseValue)
        return true
      }
      case ConfigUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateNotifyModel.verify(), 'manager.ConfigUpdateNotify validation failed!')
        let deserialized = this._ConfigUpdateNotifyModel.deserialize(this._ConfigUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.ConfigUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateNotifyValue)
        this.onReceive_ConfigUpdateNotify(this._ConfigUpdateNotifyValue)
        return true
      }
      case ConfigVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionRequestModel.verify(), 'manager.ConfigVersionRequest validation failed!')
        let deserialized = this._ConfigVersionRequestModel.deserialize(this._ConfigVersionRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionRequestValue)
        this.onReceive_ConfigVersionRequest(this._ConfigVersionRequestValue)
        return true
      }
      case ConfigVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionResponseModel.verify(), 'manager.ConfigVersionResponse validation failed!')
        let deserialized = this._ConfigVersionResponseModel.deserialize(this._ConfigVersionResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionResponseValue)
        this.onReceive_ConfigVersionResponse(this._ConfigVersionResponseValue)
        return true
      }
      case ConfigExportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigExportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigExportRequestModel.verify(), 'manager.ConfigExportRequest validation failed!')
        let deserialized = this._ConfigExportRequestModel.deserialize(this._ConfigExportRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigExportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigExportRequestValue)
        this.onReceive_ConfigExportRequest(this._ConfigExportRequestValue)
        return true
      }
      case ConfigExportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigExportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigExportResponseModel.verify(), 'manager.ConfigExportResponse validation failed!')
        let deserialized = this._ConfigExportResponseModel.deserialize(this._ConfigExportResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigExportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigExportResponseValue)
        this.onReceive_ConfigExportResponse(this._ConfigExportResponseValue)
        return true
      }
      case ConfigImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigImportRequestModel.verify(), 'manager.ConfigImportRequest validation failed!')
        let deserialized = this._ConfigImportRequestModel.deserialize(this._ConfigImportRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigImportRequestValue)
        this.onReceive_ConfigImportRequest(this._ConfigImportRequestValue)
        return true
      }
      case ConfigImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigImportResponseModel.verify(), 'manager.ConfigImportResponse validation failed!')
        let deserialized = this._ConfigImportResponseModel.deserialize(this._ConfigImportResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigImportResponseValue)
        this.onReceive_ConfigImportResponse(this._ConfigImportResponseValue)
        return true
      }
      case ConfigValidateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigValidateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigValidateRequestModel.verify(), 'manager.ConfigValidateRequest validation failed!')
        let deserialized = this._ConfigValidateRequestModel.deserialize(this._ConfigValidateRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigValidateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigValidateRequestValue)
        this.onReceive_ConfigValidateRequest(this._ConfigValidateRequestValue)
        return true
      }
      case ConfigValidateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigValidateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigValidateResponseModel.verify(), 'manager.ConfigValidateResponse validation failed!')
        let deserialized = this._ConfigValidateResponseModel.deserialize(this._ConfigValidateResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigValidateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigValidateResponseValue)
        this.onReceive_ConfigValidateResponse(this._ConfigValidateResponseValue)
        return true
      }
      case ConfigRefreshRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshRequestModel.verify(), 'manager.ConfigRefreshRequest validation failed!')
        let deserialized = this._ConfigRefreshRequestModel.deserialize(this._ConfigRefreshRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRefreshRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshRequestValue)
        this.onReceive_ConfigRefreshRequest(this._ConfigRefreshRequestValue)
        return true
      }
      case ConfigRefreshResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshResponseModel.verify(), 'manager.ConfigRefreshResponse validation failed!')
        let deserialized = this._ConfigRefreshResponseModel.deserialize(this._ConfigRefreshResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRefreshResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshResponseValue)
        this.onReceive_ConfigRefreshResponse(this._ConfigRefreshResponseValue)
        return true
      }
      case ConfigHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryRequestModel.verify(), 'manager.ConfigHistoryRequest validation failed!')
        let deserialized = this._ConfigHistoryRequestModel.deserialize(this._ConfigHistoryRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryRequestValue)
        this.onReceive_ConfigHistoryRequest(this._ConfigHistoryRequestValue)
        return true
      }
      case ConfigHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryResponseModel.verify(), 'manager.ConfigHistoryResponse validation failed!')
        let deserialized = this._ConfigHistoryResponseModel.deserialize(this._ConfigHistoryResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryResponseValue)
        this.onReceive_ConfigHistoryResponse(this._ConfigHistoryResponseValue)
        return true
      }
      case ConfigRollbackRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackRequestModel.verify(), 'manager.ConfigRollbackRequest validation failed!')
        let deserialized = this._ConfigRollbackRequestModel.deserialize(this._ConfigRollbackRequestValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRollbackRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackRequestValue)
        this.onReceive_ConfigRollbackRequest(this._ConfigRollbackRequestValue)
        return true
      }
      case ConfigRollbackResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackResponseModel.verify(), 'manager.ConfigRollbackResponse validation failed!')
        let deserialized = this._ConfigRollbackResponseModel.deserialize(this._ConfigRollbackResponseValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRollbackResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackResponseValue)
        this.onReceive_ConfigRollbackResponse(this._ConfigRollbackResponseValue)
        return true
      }
      case HostGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostGetRequestModel.verify(), 'manager.HostGetRequest validation failed!')
        let deserialized = this._HostGetRequestModel.deserialize(this._HostGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.HostGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostGetRequestValue)
        this.onReceive_HostGetRequest(this._HostGetRequestValue)
        return true
      }
      case HostGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostGetResponseModel.verify(), 'manager.HostGetResponse validation failed!')
        let deserialized = this._HostGetResponseModel.deserialize(this._HostGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.HostGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostGetResponseValue)
        this.onReceive_HostGetResponse(this._HostGetResponseValue)
        return true
      }
      case HostsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostsGetAllRequestModel.verify(), 'manager.HostsGetAllRequest validation failed!')
        let deserialized = this._HostsGetAllRequestModel.deserialize(this._HostsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.HostsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostsGetAllRequestValue)
        this.onReceive_HostsGetAllRequest(this._HostsGetAllRequestValue)
        return true
      }
      case HostsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostsGetAllResponseModel.verify(), 'manager.HostsGetAllResponse validation failed!')
        let deserialized = this._HostsGetAllResponseModel.deserialize(this._HostsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.HostsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostsGetAllResponseValue)
        this.onReceive_HostsGetAllResponse(this._HostsGetAllResponseValue)
        return true
      }
      case HostCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostCreateRequestModel.verify(), 'manager.HostCreateRequest validation failed!')
        let deserialized = this._HostCreateRequestModel.deserialize(this._HostCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.HostCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostCreateRequestValue)
        this.onReceive_HostCreateRequest(this._HostCreateRequestValue)
        return true
      }
      case HostCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostCreateResponseModel.verify(), 'manager.HostCreateResponse validation failed!')
        let deserialized = this._HostCreateResponseModel.deserialize(this._HostCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.HostCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostCreateResponseValue)
        this.onReceive_HostCreateResponse(this._HostCreateResponseValue)
        return true
      }
      case HostModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostModifyRequestModel.verify(), 'manager.HostModifyRequest validation failed!')
        let deserialized = this._HostModifyRequestModel.deserialize(this._HostModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.HostModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostModifyRequestValue)
        this.onReceive_HostModifyRequest(this._HostModifyRequestValue)
        return true
      }
      case HostModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostModifyResponseModel.verify(), 'manager.HostModifyResponse validation failed!')
        let deserialized = this._HostModifyResponseModel.deserialize(this._HostModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.HostModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostModifyResponseValue)
        this.onReceive_HostModifyResponse(this._HostModifyResponseValue)
        return true
      }
      case HostDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostDeleteRequestModel.verify(), 'manager.HostDeleteRequest validation failed!')
        let deserialized = this._HostDeleteRequestModel.deserialize(this._HostDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.HostDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostDeleteRequestValue)
        this.onReceive_HostDeleteRequest(this._HostDeleteRequestValue)
        return true
      }
      case HostDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostDeleteResponseModel.verify(), 'manager.HostDeleteResponse validation failed!')
        let deserialized = this._HostDeleteResponseModel.deserialize(this._HostDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.HostDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostDeleteResponseValue)
        this.onReceive_HostDeleteResponse(this._HostDeleteResponseValue)
        return true
      }
      case HostUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._HostUpdateNotifyModel.verify(), 'manager.HostUpdateNotify validation failed!')
        let deserialized = this._HostUpdateNotifyModel.deserialize(this._HostUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.HostUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostUpdateNotifyValue)
        this.onReceive_HostUpdateNotify(this._HostUpdateNotifyValue)
        return true
      }
      case ModuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleGetRequestModel.verify(), 'manager.ModuleGetRequest validation failed!')
        let deserialized = this._ModuleGetRequestModel.deserialize(this._ModuleGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ModuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleGetRequestValue)
        this.onReceive_ModuleGetRequest(this._ModuleGetRequestValue)
        return true
      }
      case ModuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleGetResponseModel.verify(), 'manager.ModuleGetResponse validation failed!')
        let deserialized = this._ModuleGetResponseModel.deserialize(this._ModuleGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ModuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleGetResponseValue)
        this.onReceive_ModuleGetResponse(this._ModuleGetResponseValue)
        return true
      }
      case ModulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesGetAllRequestModel.verify(), 'manager.ModulesGetAllRequest validation failed!')
        let deserialized = this._ModulesGetAllRequestModel.deserialize(this._ModulesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.ModulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesGetAllRequestValue)
        this.onReceive_ModulesGetAllRequest(this._ModulesGetAllRequestValue)
        return true
      }
      case ModulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesGetAllResponseModel.verify(), 'manager.ModulesGetAllResponse validation failed!')
        let deserialized = this._ModulesGetAllResponseModel.deserialize(this._ModulesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.ModulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesGetAllResponseValue)
        this.onReceive_ModulesGetAllResponse(this._ModulesGetAllResponseValue)
        return true
      }
      case ModuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleCreateRequestModel.verify(), 'manager.ModuleCreateRequest validation failed!')
        let deserialized = this._ModuleCreateRequestModel.deserialize(this._ModuleCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.ModuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleCreateRequestValue)
        this.onReceive_ModuleCreateRequest(this._ModuleCreateRequestValue)
        return true
      }
      case ModuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleCreateResponseModel.verify(), 'manager.ModuleCreateResponse validation failed!')
        let deserialized = this._ModuleCreateResponseModel.deserialize(this._ModuleCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.ModuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleCreateResponseValue)
        this.onReceive_ModuleCreateResponse(this._ModuleCreateResponseValue)
        return true
      }
      case ModuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleModifyRequestModel.verify(), 'manager.ModuleModifyRequest validation failed!')
        let deserialized = this._ModuleModifyRequestModel.deserialize(this._ModuleModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.ModuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleModifyRequestValue)
        this.onReceive_ModuleModifyRequest(this._ModuleModifyRequestValue)
        return true
      }
      case ModuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleModifyResponseModel.verify(), 'manager.ModuleModifyResponse validation failed!')
        let deserialized = this._ModuleModifyResponseModel.deserialize(this._ModuleModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.ModuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleModifyResponseValue)
        this.onReceive_ModuleModifyResponse(this._ModuleModifyResponseValue)
        return true
      }
      case ModuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleDeleteRequestModel.verify(), 'manager.ModuleDeleteRequest validation failed!')
        let deserialized = this._ModuleDeleteRequestModel.deserialize(this._ModuleDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.ModuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleDeleteRequestValue)
        this.onReceive_ModuleDeleteRequest(this._ModuleDeleteRequestValue)
        return true
      }
      case ModuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleDeleteResponseModel.verify(), 'manager.ModuleDeleteResponse validation failed!')
        let deserialized = this._ModuleDeleteResponseModel.deserialize(this._ModuleDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.ModuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleDeleteResponseValue)
        this.onReceive_ModuleDeleteResponse(this._ModuleDeleteResponseValue)
        return true
      }
      case ModuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleUpdateNotifyModel.verify(), 'manager.ModuleUpdateNotify validation failed!')
        let deserialized = this._ModuleUpdateNotifyModel.deserialize(this._ModuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.ModuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleUpdateNotifyValue)
        this.onReceive_ModuleUpdateNotify(this._ModuleUpdateNotifyValue)
        return true
      }
      case ServerTimeGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerTimeGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ServerTimeGetRequestModel.verify(), 'manager.ServerTimeGetRequest validation failed!')
        let deserialized = this._ServerTimeGetRequestModel.deserialize(this._ServerTimeGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ServerTimeGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerTimeGetRequestValue)
        this.onReceive_ServerTimeGetRequest(this._ServerTimeGetRequestValue)
        return true
      }
      case ServerTimeGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerTimeGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ServerTimeGetResponseModel.verify(), 'manager.ServerTimeGetResponse validation failed!')
        let deserialized = this._ServerTimeGetResponseModel.deserialize(this._ServerTimeGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ServerTimeGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerTimeGetResponseValue)
        this.onReceive_ServerTimeGetResponse(this._ServerTimeGetResponseValue)
        return true
      }
      case ManagerPlatformTimezoneGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerPlatformTimezoneGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerPlatformTimezoneGetRequestModel.verify(), 'manager.ManagerPlatformTimezoneGetRequest validation failed!')
        let deserialized = this._ManagerPlatformTimezoneGetRequestModel.deserialize(this._ManagerPlatformTimezoneGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerPlatformTimezoneGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerPlatformTimezoneGetRequestValue)
        this.onReceive_ManagerPlatformTimezoneGetRequest(this._ManagerPlatformTimezoneGetRequestValue)
        return true
      }
      case ManagerPlatformTimezoneGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerPlatformTimezoneGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerPlatformTimezoneGetResponseModel.verify(), 'manager.ManagerPlatformTimezoneGetResponse validation failed!')
        let deserialized = this._ManagerPlatformTimezoneGetResponseModel.deserialize(this._ManagerPlatformTimezoneGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerPlatformTimezoneGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerPlatformTimezoneGetResponseValue)
        this.onReceive_ManagerPlatformTimezoneGetResponse(this._ManagerPlatformTimezoneGetResponseValue)
        return true
      }
      case TimezoneGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneGetRequestModel.verify(), 'manager.TimezoneGetRequest validation failed!')
        let deserialized = this._TimezoneGetRequestModel.deserialize(this._TimezoneGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneGetRequestValue)
        this.onReceive_TimezoneGetRequest(this._TimezoneGetRequestValue)
        return true
      }
      case TimezoneGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneGetResponseModel.verify(), 'manager.TimezoneGetResponse validation failed!')
        let deserialized = this._TimezoneGetResponseModel.deserialize(this._TimezoneGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneGetResponseValue)
        this.onReceive_TimezoneGetResponse(this._TimezoneGetResponseValue)
        return true
      }
      case TimezonesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezonesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezonesGetAllRequestModel.verify(), 'manager.TimezonesGetAllRequest validation failed!')
        let deserialized = this._TimezonesGetAllRequestModel.deserialize(this._TimezonesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.TimezonesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezonesGetAllRequestValue)
        this.onReceive_TimezonesGetAllRequest(this._TimezonesGetAllRequestValue)
        return true
      }
      case TimezonesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezonesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezonesGetAllResponseModel.verify(), 'manager.TimezonesGetAllResponse validation failed!')
        let deserialized = this._TimezonesGetAllResponseModel.deserialize(this._TimezonesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.TimezonesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezonesGetAllResponseValue)
        this.onReceive_TimezonesGetAllResponse(this._TimezonesGetAllResponseValue)
        return true
      }
      case TimezoneCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneCreateRequestModel.verify(), 'manager.TimezoneCreateRequest validation failed!')
        let deserialized = this._TimezoneCreateRequestModel.deserialize(this._TimezoneCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneCreateRequestValue)
        this.onReceive_TimezoneCreateRequest(this._TimezoneCreateRequestValue)
        return true
      }
      case TimezoneCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneCreateResponseModel.verify(), 'manager.TimezoneCreateResponse validation failed!')
        let deserialized = this._TimezoneCreateResponseModel.deserialize(this._TimezoneCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneCreateResponseValue)
        this.onReceive_TimezoneCreateResponse(this._TimezoneCreateResponseValue)
        return true
      }
      case TimezoneModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneModifyRequestModel.verify(), 'manager.TimezoneModifyRequest validation failed!')
        let deserialized = this._TimezoneModifyRequestModel.deserialize(this._TimezoneModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneModifyRequestValue)
        this.onReceive_TimezoneModifyRequest(this._TimezoneModifyRequestValue)
        return true
      }
      case TimezoneModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneModifyResponseModel.verify(), 'manager.TimezoneModifyResponse validation failed!')
        let deserialized = this._TimezoneModifyResponseModel.deserialize(this._TimezoneModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneModifyResponseValue)
        this.onReceive_TimezoneModifyResponse(this._TimezoneModifyResponseValue)
        return true
      }
      case TimezoneDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneDeleteRequestModel.verify(), 'manager.TimezoneDeleteRequest validation failed!')
        let deserialized = this._TimezoneDeleteRequestModel.deserialize(this._TimezoneDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneDeleteRequestValue)
        this.onReceive_TimezoneDeleteRequest(this._TimezoneDeleteRequestValue)
        return true
      }
      case TimezoneDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneDeleteResponseModel.verify(), 'manager.TimezoneDeleteResponse validation failed!')
        let deserialized = this._TimezoneDeleteResponseModel.deserialize(this._TimezoneDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneDeleteResponseValue)
        this.onReceive_TimezoneDeleteResponse(this._TimezoneDeleteResponseValue)
        return true
      }
      case TimezoneUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneUpdateNotifyModel.verify(), 'manager.TimezoneUpdateNotify validation failed!')
        let deserialized = this._TimezoneUpdateNotifyModel.deserialize(this._TimezoneUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneUpdateNotifyValue)
        this.onReceive_TimezoneUpdateNotify(this._TimezoneUpdateNotifyValue)
        return true
      }
      case CurrencyGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetRequestModel.verify(), 'manager.CurrencyGroupGetRequest validation failed!')
        let deserialized = this._CurrencyGroupGetRequestModel.deserialize(this._CurrencyGroupGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetRequestValue)
        this.onReceive_CurrencyGroupGetRequest(this._CurrencyGroupGetRequestValue)
        return true
      }
      case CurrencyGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetResponseModel.verify(), 'manager.CurrencyGroupGetResponse validation failed!')
        let deserialized = this._CurrencyGroupGetResponseModel.deserialize(this._CurrencyGroupGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetResponseValue)
        this.onReceive_CurrencyGroupGetResponse(this._CurrencyGroupGetResponseValue)
        return true
      }
      case CurrencyGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllRequestModel.verify(), 'manager.CurrencyGroupsGetAllRequest validation failed!')
        let deserialized = this._CurrencyGroupsGetAllRequestModel.deserialize(this._CurrencyGroupsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllRequestValue)
        this.onReceive_CurrencyGroupsGetAllRequest(this._CurrencyGroupsGetAllRequestValue)
        return true
      }
      case CurrencyGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllResponseModel.verify(), 'manager.CurrencyGroupsGetAllResponse validation failed!')
        let deserialized = this._CurrencyGroupsGetAllResponseModel.deserialize(this._CurrencyGroupsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllResponseValue)
        this.onReceive_CurrencyGroupsGetAllResponse(this._CurrencyGroupsGetAllResponseValue)
        return true
      }
      case CurrencyGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupCreateRequestModel.verify(), 'manager.CurrencyGroupCreateRequest validation failed!')
        let deserialized = this._CurrencyGroupCreateRequestModel.deserialize(this._CurrencyGroupCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupCreateRequestValue)
        this.onReceive_CurrencyGroupCreateRequest(this._CurrencyGroupCreateRequestValue)
        return true
      }
      case CurrencyGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupCreateResponseModel.verify(), 'manager.CurrencyGroupCreateResponse validation failed!')
        let deserialized = this._CurrencyGroupCreateResponseModel.deserialize(this._CurrencyGroupCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupCreateResponseValue)
        this.onReceive_CurrencyGroupCreateResponse(this._CurrencyGroupCreateResponseValue)
        return true
      }
      case CurrencyGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupModifyRequestModel.verify(), 'manager.CurrencyGroupModifyRequest validation failed!')
        let deserialized = this._CurrencyGroupModifyRequestModel.deserialize(this._CurrencyGroupModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupModifyRequestValue)
        this.onReceive_CurrencyGroupModifyRequest(this._CurrencyGroupModifyRequestValue)
        return true
      }
      case CurrencyGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupModifyResponseModel.verify(), 'manager.CurrencyGroupModifyResponse validation failed!')
        let deserialized = this._CurrencyGroupModifyResponseModel.deserialize(this._CurrencyGroupModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupModifyResponseValue)
        this.onReceive_CurrencyGroupModifyResponse(this._CurrencyGroupModifyResponseValue)
        return true
      }
      case CurrencyGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupDeleteRequestModel.verify(), 'manager.CurrencyGroupDeleteRequest validation failed!')
        let deserialized = this._CurrencyGroupDeleteRequestModel.deserialize(this._CurrencyGroupDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupDeleteRequestValue)
        this.onReceive_CurrencyGroupDeleteRequest(this._CurrencyGroupDeleteRequestValue)
        return true
      }
      case CurrencyGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupDeleteResponseModel.verify(), 'manager.CurrencyGroupDeleteResponse validation failed!')
        let deserialized = this._CurrencyGroupDeleteResponseModel.deserialize(this._CurrencyGroupDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupDeleteResponseValue)
        this.onReceive_CurrencyGroupDeleteResponse(this._CurrencyGroupDeleteResponseValue)
        return true
      }
      case CurrencyGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupUpdateNotifyModel.verify(), 'manager.CurrencyGroupUpdateNotify validation failed!')
        let deserialized = this._CurrencyGroupUpdateNotifyModel.deserialize(this._CurrencyGroupUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupUpdateNotifyValue)
        this.onReceive_CurrencyGroupUpdateNotify(this._CurrencyGroupUpdateNotifyValue)
        return true
      }
      case CurrencyGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetRequestModel.verify(), 'manager.CurrencyGetRequest validation failed!')
        let deserialized = this._CurrencyGetRequestModel.deserialize(this._CurrencyGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetRequestValue)
        this.onReceive_CurrencyGetRequest(this._CurrencyGetRequestValue)
        return true
      }
      case CurrencyGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetResponseModel.verify(), 'manager.CurrencyGetResponse validation failed!')
        let deserialized = this._CurrencyGetResponseModel.deserialize(this._CurrencyGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetResponseValue)
        this.onReceive_CurrencyGetResponse(this._CurrencyGetResponseValue)
        return true
      }
      case CurrenciesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllRequestModel.verify(), 'manager.CurrenciesGetAllRequest validation failed!')
        let deserialized = this._CurrenciesGetAllRequestModel.deserialize(this._CurrenciesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrenciesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllRequestValue)
        this.onReceive_CurrenciesGetAllRequest(this._CurrenciesGetAllRequestValue)
        return true
      }
      case CurrenciesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllResponseModel.verify(), 'manager.CurrenciesGetAllResponse validation failed!')
        let deserialized = this._CurrenciesGetAllResponseModel.deserialize(this._CurrenciesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrenciesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllResponseValue)
        this.onReceive_CurrenciesGetAllResponse(this._CurrenciesGetAllResponseValue)
        return true
      }
      case CurrencyCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyCreateRequestModel.verify(), 'manager.CurrencyCreateRequest validation failed!')
        let deserialized = this._CurrencyCreateRequestModel.deserialize(this._CurrencyCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyCreateRequestValue)
        this.onReceive_CurrencyCreateRequest(this._CurrencyCreateRequestValue)
        return true
      }
      case CurrencyCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyCreateResponseModel.verify(), 'manager.CurrencyCreateResponse validation failed!')
        let deserialized = this._CurrencyCreateResponseModel.deserialize(this._CurrencyCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyCreateResponseValue)
        this.onReceive_CurrencyCreateResponse(this._CurrencyCreateResponseValue)
        return true
      }
      case CurrencyModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyModifyRequestModel.verify(), 'manager.CurrencyModifyRequest validation failed!')
        let deserialized = this._CurrencyModifyRequestModel.deserialize(this._CurrencyModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyModifyRequestValue)
        this.onReceive_CurrencyModifyRequest(this._CurrencyModifyRequestValue)
        return true
      }
      case CurrencyModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyModifyResponseModel.verify(), 'manager.CurrencyModifyResponse validation failed!')
        let deserialized = this._CurrencyModifyResponseModel.deserialize(this._CurrencyModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyModifyResponseValue)
        this.onReceive_CurrencyModifyResponse(this._CurrencyModifyResponseValue)
        return true
      }
      case CurrencyDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyDeleteRequestModel.verify(), 'manager.CurrencyDeleteRequest validation failed!')
        let deserialized = this._CurrencyDeleteRequestModel.deserialize(this._CurrencyDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyDeleteRequestValue)
        this.onReceive_CurrencyDeleteRequest(this._CurrencyDeleteRequestValue)
        return true
      }
      case CurrencyDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyDeleteResponseModel.verify(), 'manager.CurrencyDeleteResponse validation failed!')
        let deserialized = this._CurrencyDeleteResponseModel.deserialize(this._CurrencyDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyDeleteResponseValue)
        this.onReceive_CurrencyDeleteResponse(this._CurrencyDeleteResponseValue)
        return true
      }
      case CurrencyUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyUpdateNotifyModel.verify(), 'manager.CurrencyUpdateNotify validation failed!')
        let deserialized = this._CurrencyUpdateNotifyModel.deserialize(this._CurrencyUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyUpdateNotifyValue)
        this.onReceive_CurrencyUpdateNotify(this._CurrencyUpdateNotifyValue)
        return true
      }
      case SymbolGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetRequestModel.verify(), 'manager.SymbolGroupGetRequest validation failed!')
        let deserialized = this._SymbolGroupGetRequestModel.deserialize(this._SymbolGroupGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetRequestValue)
        this.onReceive_SymbolGroupGetRequest(this._SymbolGroupGetRequestValue)
        return true
      }
      case SymbolGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetResponseModel.verify(), 'manager.SymbolGroupGetResponse validation failed!')
        let deserialized = this._SymbolGroupGetResponseModel.deserialize(this._SymbolGroupGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetResponseValue)
        this.onReceive_SymbolGroupGetResponse(this._SymbolGroupGetResponseValue)
        return true
      }
      case SymbolGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllRequestModel.verify(), 'manager.SymbolGroupsGetAllRequest validation failed!')
        let deserialized = this._SymbolGroupsGetAllRequestModel.deserialize(this._SymbolGroupsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllRequestValue)
        this.onReceive_SymbolGroupsGetAllRequest(this._SymbolGroupsGetAllRequestValue)
        return true
      }
      case SymbolGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllResponseModel.verify(), 'manager.SymbolGroupsGetAllResponse validation failed!')
        let deserialized = this._SymbolGroupsGetAllResponseModel.deserialize(this._SymbolGroupsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllResponseValue)
        this.onReceive_SymbolGroupsGetAllResponse(this._SymbolGroupsGetAllResponseValue)
        return true
      }
      case SymbolGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupCreateRequestModel.verify(), 'manager.SymbolGroupCreateRequest validation failed!')
        let deserialized = this._SymbolGroupCreateRequestModel.deserialize(this._SymbolGroupCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupCreateRequestValue)
        this.onReceive_SymbolGroupCreateRequest(this._SymbolGroupCreateRequestValue)
        return true
      }
      case SymbolGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupCreateResponseModel.verify(), 'manager.SymbolGroupCreateResponse validation failed!')
        let deserialized = this._SymbolGroupCreateResponseModel.deserialize(this._SymbolGroupCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupCreateResponseValue)
        this.onReceive_SymbolGroupCreateResponse(this._SymbolGroupCreateResponseValue)
        return true
      }
      case SymbolGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupModifyRequestModel.verify(), 'manager.SymbolGroupModifyRequest validation failed!')
        let deserialized = this._SymbolGroupModifyRequestModel.deserialize(this._SymbolGroupModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupModifyRequestValue)
        this.onReceive_SymbolGroupModifyRequest(this._SymbolGroupModifyRequestValue)
        return true
      }
      case SymbolGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupModifyResponseModel.verify(), 'manager.SymbolGroupModifyResponse validation failed!')
        let deserialized = this._SymbolGroupModifyResponseModel.deserialize(this._SymbolGroupModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupModifyResponseValue)
        this.onReceive_SymbolGroupModifyResponse(this._SymbolGroupModifyResponseValue)
        return true
      }
      case SymbolGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupDeleteRequestModel.verify(), 'manager.SymbolGroupDeleteRequest validation failed!')
        let deserialized = this._SymbolGroupDeleteRequestModel.deserialize(this._SymbolGroupDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupDeleteRequestValue)
        this.onReceive_SymbolGroupDeleteRequest(this._SymbolGroupDeleteRequestValue)
        return true
      }
      case SymbolGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupDeleteResponseModel.verify(), 'manager.SymbolGroupDeleteResponse validation failed!')
        let deserialized = this._SymbolGroupDeleteResponseModel.deserialize(this._SymbolGroupDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupDeleteResponseValue)
        this.onReceive_SymbolGroupDeleteResponse(this._SymbolGroupDeleteResponseValue)
        return true
      }
      case SymbolGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupUpdateNotifyModel.verify(), 'manager.SymbolGroupUpdateNotify validation failed!')
        let deserialized = this._SymbolGroupUpdateNotifyModel.deserialize(this._SymbolGroupUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupUpdateNotifyValue)
        this.onReceive_SymbolGroupUpdateNotify(this._SymbolGroupUpdateNotifyValue)
        return true
      }
      case SymbolGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetRequestModel.verify(), 'manager.SymbolGetRequest validation failed!')
        let deserialized = this._SymbolGetRequestModel.deserialize(this._SymbolGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetRequestValue)
        this.onReceive_SymbolGetRequest(this._SymbolGetRequestValue)
        return true
      }
      case SymbolGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetResponseModel.verify(), 'manager.SymbolGetResponse validation failed!')
        let deserialized = this._SymbolGetResponseModel.deserialize(this._SymbolGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetResponseValue)
        this.onReceive_SymbolGetResponse(this._SymbolGetResponseValue)
        return true
      }
      case SymbolsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllRequestModel.verify(), 'manager.SymbolsGetAllRequest validation failed!')
        let deserialized = this._SymbolsGetAllRequestModel.deserialize(this._SymbolsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllRequestValue)
        this.onReceive_SymbolsGetAllRequest(this._SymbolsGetAllRequestValue)
        return true
      }
      case SymbolsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllResponseModel.verify(), 'manager.SymbolsGetAllResponse validation failed!')
        let deserialized = this._SymbolsGetAllResponseModel.deserialize(this._SymbolsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllResponseValue)
        this.onReceive_SymbolsGetAllResponse(this._SymbolsGetAllResponseValue)
        return true
      }
      case SymbolCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolCreateRequestModel.verify(), 'manager.SymbolCreateRequest validation failed!')
        let deserialized = this._SymbolCreateRequestModel.deserialize(this._SymbolCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolCreateRequestValue)
        this.onReceive_SymbolCreateRequest(this._SymbolCreateRequestValue)
        return true
      }
      case SymbolCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolCreateResponseModel.verify(), 'manager.SymbolCreateResponse validation failed!')
        let deserialized = this._SymbolCreateResponseModel.deserialize(this._SymbolCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolCreateResponseValue)
        this.onReceive_SymbolCreateResponse(this._SymbolCreateResponseValue)
        return true
      }
      case SymbolModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolModifyRequestModel.verify(), 'manager.SymbolModifyRequest validation failed!')
        let deserialized = this._SymbolModifyRequestModel.deserialize(this._SymbolModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolModifyRequestValue)
        this.onReceive_SymbolModifyRequest(this._SymbolModifyRequestValue)
        return true
      }
      case SymbolModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolModifyResponseModel.verify(), 'manager.SymbolModifyResponse validation failed!')
        let deserialized = this._SymbolModifyResponseModel.deserialize(this._SymbolModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolModifyResponseValue)
        this.onReceive_SymbolModifyResponse(this._SymbolModifyResponseValue)
        return true
      }
      case SymbolDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDeleteRequestModel.verify(), 'manager.SymbolDeleteRequest validation failed!')
        let deserialized = this._SymbolDeleteRequestModel.deserialize(this._SymbolDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDeleteRequestValue)
        this.onReceive_SymbolDeleteRequest(this._SymbolDeleteRequestValue)
        return true
      }
      case SymbolDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDeleteResponseModel.verify(), 'manager.SymbolDeleteResponse validation failed!')
        let deserialized = this._SymbolDeleteResponseModel.deserialize(this._SymbolDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDeleteResponseValue)
        this.onReceive_SymbolDeleteResponse(this._SymbolDeleteResponseValue)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyModel.verify(), 'manager.SymbolUpdateNotify validation failed!')
        let deserialized = this._SymbolUpdateNotifyModel.deserialize(this._SymbolUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.SymbolUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolUpdateNotifyValue)
        this.onReceive_SymbolUpdateNotify(this._SymbolUpdateNotifyValue)
        return true
      }
      case SwapsExportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsExportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsExportRequestModel.verify(), 'manager.SwapsExportRequest validation failed!')
        let deserialized = this._SwapsExportRequestModel.deserialize(this._SwapsExportRequestValue)
        console.assert((deserialized.size > 0), 'manager.SwapsExportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsExportRequestValue)
        this.onReceive_SwapsExportRequest(this._SwapsExportRequestValue)
        return true
      }
      case SwapsExportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsExportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsExportResponseModel.verify(), 'manager.SwapsExportResponse validation failed!')
        let deserialized = this._SwapsExportResponseModel.deserialize(this._SwapsExportResponseValue)
        console.assert((deserialized.size > 0), 'manager.SwapsExportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsExportResponseValue)
        this.onReceive_SwapsExportResponse(this._SwapsExportResponseValue)
        return true
      }
      case SwapsImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsImportRequestModel.verify(), 'manager.SwapsImportRequest validation failed!')
        let deserialized = this._SwapsImportRequestModel.deserialize(this._SwapsImportRequestValue)
        console.assert((deserialized.size > 0), 'manager.SwapsImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsImportRequestValue)
        this.onReceive_SwapsImportRequest(this._SwapsImportRequestValue)
        return true
      }
      case SwapsImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsImportResponseModel.verify(), 'manager.SwapsImportResponse validation failed!')
        let deserialized = this._SwapsImportResponseModel.deserialize(this._SwapsImportResponseValue)
        console.assert((deserialized.size > 0), 'manager.SwapsImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsImportResponseValue)
        this.onReceive_SwapsImportResponse(this._SwapsImportResponseValue)
        return true
      }
      case CommissionProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileGetRequestModel.verify(), 'manager.CommissionProfileGetRequest validation failed!')
        let deserialized = this._CommissionProfileGetRequestModel.deserialize(this._CommissionProfileGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileGetRequestValue)
        this.onReceive_CommissionProfileGetRequest(this._CommissionProfileGetRequestValue)
        return true
      }
      case CommissionProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileGetResponseModel.verify(), 'manager.CommissionProfileGetResponse validation failed!')
        let deserialized = this._CommissionProfileGetResponseModel.deserialize(this._CommissionProfileGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileGetResponseValue)
        this.onReceive_CommissionProfileGetResponse(this._CommissionProfileGetResponseValue)
        return true
      }
      case CommissionProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfilesGetAllRequestModel.verify(), 'manager.CommissionProfilesGetAllRequest validation failed!')
        let deserialized = this._CommissionProfilesGetAllRequestModel.deserialize(this._CommissionProfilesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfilesGetAllRequestValue)
        this.onReceive_CommissionProfilesGetAllRequest(this._CommissionProfilesGetAllRequestValue)
        return true
      }
      case CommissionProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfilesGetAllResponseModel.verify(), 'manager.CommissionProfilesGetAllResponse validation failed!')
        let deserialized = this._CommissionProfilesGetAllResponseModel.deserialize(this._CommissionProfilesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfilesGetAllResponseValue)
        this.onReceive_CommissionProfilesGetAllResponse(this._CommissionProfilesGetAllResponseValue)
        return true
      }
      case CommissionProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileCreateRequestModel.verify(), 'manager.CommissionProfileCreateRequest validation failed!')
        let deserialized = this._CommissionProfileCreateRequestModel.deserialize(this._CommissionProfileCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileCreateRequestValue)
        this.onReceive_CommissionProfileCreateRequest(this._CommissionProfileCreateRequestValue)
        return true
      }
      case CommissionProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileCreateResponseModel.verify(), 'manager.CommissionProfileCreateResponse validation failed!')
        let deserialized = this._CommissionProfileCreateResponseModel.deserialize(this._CommissionProfileCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileCreateResponseValue)
        this.onReceive_CommissionProfileCreateResponse(this._CommissionProfileCreateResponseValue)
        return true
      }
      case CommissionProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileModifyRequestModel.verify(), 'manager.CommissionProfileModifyRequest validation failed!')
        let deserialized = this._CommissionProfileModifyRequestModel.deserialize(this._CommissionProfileModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileModifyRequestValue)
        this.onReceive_CommissionProfileModifyRequest(this._CommissionProfileModifyRequestValue)
        return true
      }
      case CommissionProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileModifyResponseModel.verify(), 'manager.CommissionProfileModifyResponse validation failed!')
        let deserialized = this._CommissionProfileModifyResponseModel.deserialize(this._CommissionProfileModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileModifyResponseValue)
        this.onReceive_CommissionProfileModifyResponse(this._CommissionProfileModifyResponseValue)
        return true
      }
      case CommissionProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileDeleteRequestModel.verify(), 'manager.CommissionProfileDeleteRequest validation failed!')
        let deserialized = this._CommissionProfileDeleteRequestModel.deserialize(this._CommissionProfileDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileDeleteRequestValue)
        this.onReceive_CommissionProfileDeleteRequest(this._CommissionProfileDeleteRequestValue)
        return true
      }
      case CommissionProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileDeleteResponseModel.verify(), 'manager.CommissionProfileDeleteResponse validation failed!')
        let deserialized = this._CommissionProfileDeleteResponseModel.deserialize(this._CommissionProfileDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileDeleteResponseValue)
        this.onReceive_CommissionProfileDeleteResponse(this._CommissionProfileDeleteResponseValue)
        return true
      }
      case CommissionProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileUpdateNotifyModel.verify(), 'manager.CommissionProfileUpdateNotify validation failed!')
        let deserialized = this._CommissionProfileUpdateNotifyModel.deserialize(this._CommissionProfileUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileUpdateNotifyValue)
        this.onReceive_CommissionProfileUpdateNotify(this._CommissionProfileUpdateNotifyValue)
        return true
      }
      case MarkupProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileGetRequestModel.verify(), 'manager.MarkupProfileGetRequest validation failed!')
        let deserialized = this._MarkupProfileGetRequestModel.deserialize(this._MarkupProfileGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileGetRequestValue)
        this.onReceive_MarkupProfileGetRequest(this._MarkupProfileGetRequestValue)
        return true
      }
      case MarkupProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileGetResponseModel.verify(), 'manager.MarkupProfileGetResponse validation failed!')
        let deserialized = this._MarkupProfileGetResponseModel.deserialize(this._MarkupProfileGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileGetResponseValue)
        this.onReceive_MarkupProfileGetResponse(this._MarkupProfileGetResponseValue)
        return true
      }
      case MarkupProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfilesGetAllRequestModel.verify(), 'manager.MarkupProfilesGetAllRequest validation failed!')
        let deserialized = this._MarkupProfilesGetAllRequestModel.deserialize(this._MarkupProfilesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfilesGetAllRequestValue)
        this.onReceive_MarkupProfilesGetAllRequest(this._MarkupProfilesGetAllRequestValue)
        return true
      }
      case MarkupProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfilesGetAllResponseModel.verify(), 'manager.MarkupProfilesGetAllResponse validation failed!')
        let deserialized = this._MarkupProfilesGetAllResponseModel.deserialize(this._MarkupProfilesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfilesGetAllResponseValue)
        this.onReceive_MarkupProfilesGetAllResponse(this._MarkupProfilesGetAllResponseValue)
        return true
      }
      case MarkupProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileCreateRequestModel.verify(), 'manager.MarkupProfileCreateRequest validation failed!')
        let deserialized = this._MarkupProfileCreateRequestModel.deserialize(this._MarkupProfileCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileCreateRequestValue)
        this.onReceive_MarkupProfileCreateRequest(this._MarkupProfileCreateRequestValue)
        return true
      }
      case MarkupProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileCreateResponseModel.verify(), 'manager.MarkupProfileCreateResponse validation failed!')
        let deserialized = this._MarkupProfileCreateResponseModel.deserialize(this._MarkupProfileCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileCreateResponseValue)
        this.onReceive_MarkupProfileCreateResponse(this._MarkupProfileCreateResponseValue)
        return true
      }
      case MarkupProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileModifyRequestModel.verify(), 'manager.MarkupProfileModifyRequest validation failed!')
        let deserialized = this._MarkupProfileModifyRequestModel.deserialize(this._MarkupProfileModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileModifyRequestValue)
        this.onReceive_MarkupProfileModifyRequest(this._MarkupProfileModifyRequestValue)
        return true
      }
      case MarkupProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileModifyResponseModel.verify(), 'manager.MarkupProfileModifyResponse validation failed!')
        let deserialized = this._MarkupProfileModifyResponseModel.deserialize(this._MarkupProfileModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileModifyResponseValue)
        this.onReceive_MarkupProfileModifyResponse(this._MarkupProfileModifyResponseValue)
        return true
      }
      case MarkupProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileDeleteRequestModel.verify(), 'manager.MarkupProfileDeleteRequest validation failed!')
        let deserialized = this._MarkupProfileDeleteRequestModel.deserialize(this._MarkupProfileDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileDeleteRequestValue)
        this.onReceive_MarkupProfileDeleteRequest(this._MarkupProfileDeleteRequestValue)
        return true
      }
      case MarkupProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileDeleteResponseModel.verify(), 'manager.MarkupProfileDeleteResponse validation failed!')
        let deserialized = this._MarkupProfileDeleteResponseModel.deserialize(this._MarkupProfileDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileDeleteResponseValue)
        this.onReceive_MarkupProfileDeleteResponse(this._MarkupProfileDeleteResponseValue)
        return true
      }
      case MarkupProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileUpdateNotifyModel.verify(), 'manager.MarkupProfileUpdateNotify validation failed!')
        let deserialized = this._MarkupProfileUpdateNotifyModel.deserialize(this._MarkupProfileUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileUpdateNotifyValue)
        this.onReceive_MarkupProfileUpdateNotify(this._MarkupProfileUpdateNotifyValue)
        return true
      }
      case ManagerGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupGetRequestModel.verify(), 'manager.ManagerGroupGetRequest validation failed!')
        let deserialized = this._ManagerGroupGetRequestModel.deserialize(this._ManagerGroupGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupGetRequestValue)
        this.onReceive_ManagerGroupGetRequest(this._ManagerGroupGetRequestValue)
        return true
      }
      case ManagerGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupGetResponseModel.verify(), 'manager.ManagerGroupGetResponse validation failed!')
        let deserialized = this._ManagerGroupGetResponseModel.deserialize(this._ManagerGroupGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupGetResponseValue)
        this.onReceive_ManagerGroupGetResponse(this._ManagerGroupGetResponseValue)
        return true
      }
      case ManagerGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupsGetAllRequestModel.verify(), 'manager.ManagerGroupsGetAllRequest validation failed!')
        let deserialized = this._ManagerGroupsGetAllRequestModel.deserialize(this._ManagerGroupsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupsGetAllRequestValue)
        this.onReceive_ManagerGroupsGetAllRequest(this._ManagerGroupsGetAllRequestValue)
        return true
      }
      case ManagerGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupsGetAllResponseModel.verify(), 'manager.ManagerGroupsGetAllResponse validation failed!')
        let deserialized = this._ManagerGroupsGetAllResponseModel.deserialize(this._ManagerGroupsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupsGetAllResponseValue)
        this.onReceive_ManagerGroupsGetAllResponse(this._ManagerGroupsGetAllResponseValue)
        return true
      }
      case ManagerGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupCreateRequestModel.verify(), 'manager.ManagerGroupCreateRequest validation failed!')
        let deserialized = this._ManagerGroupCreateRequestModel.deserialize(this._ManagerGroupCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupCreateRequestValue)
        this.onReceive_ManagerGroupCreateRequest(this._ManagerGroupCreateRequestValue)
        return true
      }
      case ManagerGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupCreateResponseModel.verify(), 'manager.ManagerGroupCreateResponse validation failed!')
        let deserialized = this._ManagerGroupCreateResponseModel.deserialize(this._ManagerGroupCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupCreateResponseValue)
        this.onReceive_ManagerGroupCreateResponse(this._ManagerGroupCreateResponseValue)
        return true
      }
      case ManagerGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupModifyRequestModel.verify(), 'manager.ManagerGroupModifyRequest validation failed!')
        let deserialized = this._ManagerGroupModifyRequestModel.deserialize(this._ManagerGroupModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupModifyRequestValue)
        this.onReceive_ManagerGroupModifyRequest(this._ManagerGroupModifyRequestValue)
        return true
      }
      case ManagerGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupModifyResponseModel.verify(), 'manager.ManagerGroupModifyResponse validation failed!')
        let deserialized = this._ManagerGroupModifyResponseModel.deserialize(this._ManagerGroupModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupModifyResponseValue)
        this.onReceive_ManagerGroupModifyResponse(this._ManagerGroupModifyResponseValue)
        return true
      }
      case ManagerGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupDeleteRequestModel.verify(), 'manager.ManagerGroupDeleteRequest validation failed!')
        let deserialized = this._ManagerGroupDeleteRequestModel.deserialize(this._ManagerGroupDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupDeleteRequestValue)
        this.onReceive_ManagerGroupDeleteRequest(this._ManagerGroupDeleteRequestValue)
        return true
      }
      case ManagerGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupDeleteResponseModel.verify(), 'manager.ManagerGroupDeleteResponse validation failed!')
        let deserialized = this._ManagerGroupDeleteResponseModel.deserialize(this._ManagerGroupDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupDeleteResponseValue)
        this.onReceive_ManagerGroupDeleteResponse(this._ManagerGroupDeleteResponseValue)
        return true
      }
      case ManagerGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupUpdateNotifyModel.verify(), 'manager.ManagerGroupUpdateNotify validation failed!')
        let deserialized = this._ManagerGroupUpdateNotifyModel.deserialize(this._ManagerGroupUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupUpdateNotifyValue)
        this.onReceive_ManagerGroupUpdateNotify(this._ManagerGroupUpdateNotifyValue)
        return true
      }
      case ManagerGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGetRequestModel.verify(), 'manager.ManagerGetRequest validation failed!')
        let deserialized = this._ManagerGetRequestModel.deserialize(this._ManagerGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGetRequestValue)
        this.onReceive_ManagerGetRequest(this._ManagerGetRequestValue)
        return true
      }
      case ManagerGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGetResponseModel.verify(), 'manager.ManagerGetResponse validation failed!')
        let deserialized = this._ManagerGetResponseModel.deserialize(this._ManagerGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGetResponseValue)
        this.onReceive_ManagerGetResponse(this._ManagerGetResponseValue)
        return true
      }
      case ManagersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagersGetAllRequestModel.verify(), 'manager.ManagersGetAllRequest validation failed!')
        let deserialized = this._ManagersGetAllRequestModel.deserialize(this._ManagersGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagersGetAllRequestValue)
        this.onReceive_ManagersGetAllRequest(this._ManagersGetAllRequestValue)
        return true
      }
      case ManagersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagersGetAllResponseModel.verify(), 'manager.ManagersGetAllResponse validation failed!')
        let deserialized = this._ManagersGetAllResponseModel.deserialize(this._ManagersGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagersGetAllResponseValue)
        this.onReceive_ManagersGetAllResponse(this._ManagersGetAllResponseValue)
        return true
      }
      case ManagerCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerCreateRequestModel.verify(), 'manager.ManagerCreateRequest validation failed!')
        let deserialized = this._ManagerCreateRequestModel.deserialize(this._ManagerCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerCreateRequestValue)
        this.onReceive_ManagerCreateRequest(this._ManagerCreateRequestValue)
        return true
      }
      case ManagerCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerCreateResponseModel.verify(), 'manager.ManagerCreateResponse validation failed!')
        let deserialized = this._ManagerCreateResponseModel.deserialize(this._ManagerCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerCreateResponseValue)
        this.onReceive_ManagerCreateResponse(this._ManagerCreateResponseValue)
        return true
      }
      case ManagerModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerModifyRequestModel.verify(), 'manager.ManagerModifyRequest validation failed!')
        let deserialized = this._ManagerModifyRequestModel.deserialize(this._ManagerModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerModifyRequestValue)
        this.onReceive_ManagerModifyRequest(this._ManagerModifyRequestValue)
        return true
      }
      case ManagerModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerModifyResponseModel.verify(), 'manager.ManagerModifyResponse validation failed!')
        let deserialized = this._ManagerModifyResponseModel.deserialize(this._ManagerModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerModifyResponseValue)
        this.onReceive_ManagerModifyResponse(this._ManagerModifyResponseValue)
        return true
      }
      case ManagerDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerDeleteRequestModel.verify(), 'manager.ManagerDeleteRequest validation failed!')
        let deserialized = this._ManagerDeleteRequestModel.deserialize(this._ManagerDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.ManagerDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerDeleteRequestValue)
        this.onReceive_ManagerDeleteRequest(this._ManagerDeleteRequestValue)
        return true
      }
      case ManagerDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerDeleteResponseModel.verify(), 'manager.ManagerDeleteResponse validation failed!')
        let deserialized = this._ManagerDeleteResponseModel.deserialize(this._ManagerDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.ManagerDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerDeleteResponseValue)
        this.onReceive_ManagerDeleteResponse(this._ManagerDeleteResponseValue)
        return true
      }
      case ManagerUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerUpdateNotifyModel.verify(), 'manager.ManagerUpdateNotify validation failed!')
        let deserialized = this._ManagerUpdateNotifyModel.deserialize(this._ManagerUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.ManagerUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerUpdateNotifyValue)
        this.onReceive_ManagerUpdateNotify(this._ManagerUpdateNotifyValue)
        return true
      }
      case PlatformGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformGetRequestModel.verify(), 'manager.PlatformGetRequest validation failed!')
        let deserialized = this._PlatformGetRequestModel.deserialize(this._PlatformGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.PlatformGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformGetRequestValue)
        this.onReceive_PlatformGetRequest(this._PlatformGetRequestValue)
        return true
      }
      case PlatformGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformGetResponseModel.verify(), 'manager.PlatformGetResponse validation failed!')
        let deserialized = this._PlatformGetResponseModel.deserialize(this._PlatformGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.PlatformGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformGetResponseValue)
        this.onReceive_PlatformGetResponse(this._PlatformGetResponseValue)
        return true
      }
      case PlatformsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformsGetAllRequestModel.verify(), 'manager.PlatformsGetAllRequest validation failed!')
        let deserialized = this._PlatformsGetAllRequestModel.deserialize(this._PlatformsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.PlatformsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformsGetAllRequestValue)
        this.onReceive_PlatformsGetAllRequest(this._PlatformsGetAllRequestValue)
        return true
      }
      case PlatformsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformsGetAllResponseModel.verify(), 'manager.PlatformsGetAllResponse validation failed!')
        let deserialized = this._PlatformsGetAllResponseModel.deserialize(this._PlatformsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.PlatformsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformsGetAllResponseValue)
        this.onReceive_PlatformsGetAllResponse(this._PlatformsGetAllResponseValue)
        return true
      }
      case PlatformCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformCreateRequestModel.verify(), 'manager.PlatformCreateRequest validation failed!')
        let deserialized = this._PlatformCreateRequestModel.deserialize(this._PlatformCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.PlatformCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformCreateRequestValue)
        this.onReceive_PlatformCreateRequest(this._PlatformCreateRequestValue)
        return true
      }
      case PlatformCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformCreateResponseModel.verify(), 'manager.PlatformCreateResponse validation failed!')
        let deserialized = this._PlatformCreateResponseModel.deserialize(this._PlatformCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.PlatformCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformCreateResponseValue)
        this.onReceive_PlatformCreateResponse(this._PlatformCreateResponseValue)
        return true
      }
      case PlatformModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformModifyRequestModel.verify(), 'manager.PlatformModifyRequest validation failed!')
        let deserialized = this._PlatformModifyRequestModel.deserialize(this._PlatformModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.PlatformModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformModifyRequestValue)
        this.onReceive_PlatformModifyRequest(this._PlatformModifyRequestValue)
        return true
      }
      case PlatformModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformModifyResponseModel.verify(), 'manager.PlatformModifyResponse validation failed!')
        let deserialized = this._PlatformModifyResponseModel.deserialize(this._PlatformModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.PlatformModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformModifyResponseValue)
        this.onReceive_PlatformModifyResponse(this._PlatformModifyResponseValue)
        return true
      }
      case PlatformDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformDeleteRequestModel.verify(), 'manager.PlatformDeleteRequest validation failed!')
        let deserialized = this._PlatformDeleteRequestModel.deserialize(this._PlatformDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.PlatformDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformDeleteRequestValue)
        this.onReceive_PlatformDeleteRequest(this._PlatformDeleteRequestValue)
        return true
      }
      case PlatformDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformDeleteResponseModel.verify(), 'manager.PlatformDeleteResponse validation failed!')
        let deserialized = this._PlatformDeleteResponseModel.deserialize(this._PlatformDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.PlatformDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformDeleteResponseValue)
        this.onReceive_PlatformDeleteResponse(this._PlatformDeleteResponseValue)
        return true
      }
      case PlatformUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformUpdateNotifyModel.verify(), 'manager.PlatformUpdateNotify validation failed!')
        let deserialized = this._PlatformUpdateNotifyModel.deserialize(this._PlatformUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.PlatformUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformUpdateNotifyValue)
        this.onReceive_PlatformUpdateNotify(this._PlatformUpdateNotifyValue)
        return true
      }
      case AccountGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupGetRequestModel.verify(), 'manager.AccountGroupGetRequest validation failed!')
        let deserialized = this._AccountGroupGetRequestModel.deserialize(this._AccountGroupGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupGetRequestValue)
        this.onReceive_AccountGroupGetRequest(this._AccountGroupGetRequestValue)
        return true
      }
      case AccountGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupGetResponseModel.verify(), 'manager.AccountGroupGetResponse validation failed!')
        let deserialized = this._AccountGroupGetResponseModel.deserialize(this._AccountGroupGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupGetResponseValue)
        this.onReceive_AccountGroupGetResponse(this._AccountGroupGetResponseValue)
        return true
      }
      case AccountGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupsGetAllRequestModel.verify(), 'manager.AccountGroupsGetAllRequest validation failed!')
        let deserialized = this._AccountGroupsGetAllRequestModel.deserialize(this._AccountGroupsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupsGetAllRequestValue)
        this.onReceive_AccountGroupsGetAllRequest(this._AccountGroupsGetAllRequestValue)
        return true
      }
      case AccountGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupsGetAllResponseModel.verify(), 'manager.AccountGroupsGetAllResponse validation failed!')
        let deserialized = this._AccountGroupsGetAllResponseModel.deserialize(this._AccountGroupsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupsGetAllResponseValue)
        this.onReceive_AccountGroupsGetAllResponse(this._AccountGroupsGetAllResponseValue)
        return true
      }
      case AccountGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupCreateRequestModel.verify(), 'manager.AccountGroupCreateRequest validation failed!')
        let deserialized = this._AccountGroupCreateRequestModel.deserialize(this._AccountGroupCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupCreateRequestValue)
        this.onReceive_AccountGroupCreateRequest(this._AccountGroupCreateRequestValue)
        return true
      }
      case AccountGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupCreateResponseModel.verify(), 'manager.AccountGroupCreateResponse validation failed!')
        let deserialized = this._AccountGroupCreateResponseModel.deserialize(this._AccountGroupCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupCreateResponseValue)
        this.onReceive_AccountGroupCreateResponse(this._AccountGroupCreateResponseValue)
        return true
      }
      case AccountGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupModifyRequestModel.verify(), 'manager.AccountGroupModifyRequest validation failed!')
        let deserialized = this._AccountGroupModifyRequestModel.deserialize(this._AccountGroupModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupModifyRequestValue)
        this.onReceive_AccountGroupModifyRequest(this._AccountGroupModifyRequestValue)
        return true
      }
      case AccountGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupModifyResponseModel.verify(), 'manager.AccountGroupModifyResponse validation failed!')
        let deserialized = this._AccountGroupModifyResponseModel.deserialize(this._AccountGroupModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupModifyResponseValue)
        this.onReceive_AccountGroupModifyResponse(this._AccountGroupModifyResponseValue)
        return true
      }
      case AccountGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupDeleteRequestModel.verify(), 'manager.AccountGroupDeleteRequest validation failed!')
        let deserialized = this._AccountGroupDeleteRequestModel.deserialize(this._AccountGroupDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupDeleteRequestValue)
        this.onReceive_AccountGroupDeleteRequest(this._AccountGroupDeleteRequestValue)
        return true
      }
      case AccountGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupDeleteResponseModel.verify(), 'manager.AccountGroupDeleteResponse validation failed!')
        let deserialized = this._AccountGroupDeleteResponseModel.deserialize(this._AccountGroupDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupDeleteResponseValue)
        this.onReceive_AccountGroupDeleteResponse(this._AccountGroupDeleteResponseValue)
        return true
      }
      case AccountGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupUpdateNotifyModel.verify(), 'manager.AccountGroupUpdateNotify validation failed!')
        let deserialized = this._AccountGroupUpdateNotifyModel.deserialize(this._AccountGroupUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupUpdateNotifyValue)
        this.onReceive_AccountGroupUpdateNotify(this._AccountGroupUpdateNotifyValue)
        return true
      }
      case AccountGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGetRequestModel.verify(), 'manager.AccountGetRequest validation failed!')
        let deserialized = this._AccountGetRequestModel.deserialize(this._AccountGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGetRequestValue)
        this.onReceive_AccountGetRequest(this._AccountGetRequestValue)
        return true
      }
      case AccountGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGetResponseModel.verify(), 'manager.AccountGetResponse validation failed!')
        let deserialized = this._AccountGetResponseModel.deserialize(this._AccountGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGetResponseValue)
        this.onReceive_AccountGetResponse(this._AccountGetResponseValue)
        return true
      }
      case AccountsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestModel.verify(), 'manager.AccountsGetAllRequest validation failed!')
        let deserialized = this._AccountsGetAllRequestModel.deserialize(this._AccountsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllRequestValue)
        this.onReceive_AccountsGetAllRequest(this._AccountsGetAllRequestValue)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseModel.verify(), 'manager.AccountsGetAllResponse validation failed!')
        let deserialized = this._AccountsGetAllResponseModel.deserialize(this._AccountsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllResponseValue)
        this.onReceive_AccountsGetAllResponse(this._AccountsGetAllResponseValue)
        return true
      }
      case AccountCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountCreateRequestModel.verify(), 'manager.AccountCreateRequest validation failed!')
        let deserialized = this._AccountCreateRequestModel.deserialize(this._AccountCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountCreateRequestValue)
        this.onReceive_AccountCreateRequest(this._AccountCreateRequestValue)
        return true
      }
      case AccountCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountCreateResponseModel.verify(), 'manager.AccountCreateResponse validation failed!')
        let deserialized = this._AccountCreateResponseModel.deserialize(this._AccountCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountCreateResponseValue)
        this.onReceive_AccountCreateResponse(this._AccountCreateResponseValue)
        return true
      }
      case AccountSocialTradingMasterCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterCreateRequestModel.verify(), 'manager.AccountSocialTradingMasterCreateRequest validation failed!')
        let deserialized = this._AccountSocialTradingMasterCreateRequestModel.deserialize(this._AccountSocialTradingMasterCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingMasterCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterCreateRequestValue)
        this.onReceive_AccountSocialTradingMasterCreateRequest(this._AccountSocialTradingMasterCreateRequestValue)
        return true
      }
      case AccountSocialTradingMasterCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterCreateResponseModel.verify(), 'manager.AccountSocialTradingMasterCreateResponse validation failed!')
        let deserialized = this._AccountSocialTradingMasterCreateResponseModel.deserialize(this._AccountSocialTradingMasterCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingMasterCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterCreateResponseValue)
        this.onReceive_AccountSocialTradingMasterCreateResponse(this._AccountSocialTradingMasterCreateResponseValue)
        return true
      }
      case AccountSocialTradingInvestorCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorCreateRequestModel.verify(), 'manager.AccountSocialTradingInvestorCreateRequest validation failed!')
        let deserialized = this._AccountSocialTradingInvestorCreateRequestModel.deserialize(this._AccountSocialTradingInvestorCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingInvestorCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorCreateRequestValue)
        this.onReceive_AccountSocialTradingInvestorCreateRequest(this._AccountSocialTradingInvestorCreateRequestValue)
        return true
      }
      case AccountSocialTradingInvestorCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorCreateResponseModel.verify(), 'manager.AccountSocialTradingInvestorCreateResponse validation failed!')
        let deserialized = this._AccountSocialTradingInvestorCreateResponseModel.deserialize(this._AccountSocialTradingInvestorCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingInvestorCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorCreateResponseValue)
        this.onReceive_AccountSocialTradingInvestorCreateResponse(this._AccountSocialTradingInvestorCreateResponseValue)
        return true
      }
      case AccountModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountModifyRequestModel.verify(), 'manager.AccountModifyRequest validation failed!')
        let deserialized = this._AccountModifyRequestModel.deserialize(this._AccountModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountModifyRequestValue)
        this.onReceive_AccountModifyRequest(this._AccountModifyRequestValue)
        return true
      }
      case AccountModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountModifyResponseModel.verify(), 'manager.AccountModifyResponse validation failed!')
        let deserialized = this._AccountModifyResponseModel.deserialize(this._AccountModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountModifyResponseValue)
        this.onReceive_AccountModifyResponse(this._AccountModifyResponseValue)
        return true
      }
      case AccountDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteRequestModel.verify(), 'manager.AccountDeleteRequest validation failed!')
        let deserialized = this._AccountDeleteRequestModel.deserialize(this._AccountDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteRequestValue)
        this.onReceive_AccountDeleteRequest(this._AccountDeleteRequestValue)
        return true
      }
      case AccountDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteResponseModel.verify(), 'manager.AccountDeleteResponse validation failed!')
        let deserialized = this._AccountDeleteResponseModel.deserialize(this._AccountDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteResponseValue)
        this.onReceive_AccountDeleteResponse(this._AccountDeleteResponseValue)
        return true
      }
      case DemoAccountsDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountsDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountsDeleteRequestModel.verify(), 'manager.DemoAccountsDeleteRequest validation failed!')
        let deserialized = this._DemoAccountsDeleteRequestModel.deserialize(this._DemoAccountsDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.DemoAccountsDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountsDeleteRequestValue)
        this.onReceive_DemoAccountsDeleteRequest(this._DemoAccountsDeleteRequestValue)
        return true
      }
      case DemoAccountsDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountsDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountsDeleteResponseModel.verify(), 'manager.DemoAccountsDeleteResponse validation failed!')
        let deserialized = this._DemoAccountsDeleteResponseModel.deserialize(this._DemoAccountsDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.DemoAccountsDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountsDeleteResponseValue)
        this.onReceive_DemoAccountsDeleteResponse(this._DemoAccountsDeleteResponseValue)
        return true
      }
      case AccountUpgradeToSocialTradingMasterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingMasterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingMasterRequestModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterRequest validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingMasterRequestModel.deserialize(this._AccountUpgradeToSocialTradingMasterRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingMasterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingMasterRequestValue)
        this.onReceive_AccountUpgradeToSocialTradingMasterRequest(this._AccountUpgradeToSocialTradingMasterRequestValue)
        return true
      }
      case AccountUpgradeToSocialTradingMasterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingMasterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingMasterResponseModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterResponse validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingMasterResponseModel.deserialize(this._AccountUpgradeToSocialTradingMasterResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingMasterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingMasterResponseValue)
        this.onReceive_AccountUpgradeToSocialTradingMasterResponse(this._AccountUpgradeToSocialTradingMasterResponseValue)
        return true
      }
      case AccountUpgradeToSocialTradingInvestorRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingInvestorRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingInvestorRequestModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorRequest validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingInvestorRequestModel.deserialize(this._AccountUpgradeToSocialTradingInvestorRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingInvestorRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingInvestorRequestValue)
        this.onReceive_AccountUpgradeToSocialTradingInvestorRequest(this._AccountUpgradeToSocialTradingInvestorRequestValue)
        return true
      }
      case AccountUpgradeToSocialTradingInvestorResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingInvestorResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingInvestorResponseModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorResponse validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingInvestorResponseModel.deserialize(this._AccountUpgradeToSocialTradingInvestorResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingInvestorResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingInvestorResponseValue)
        this.onReceive_AccountUpgradeToSocialTradingInvestorResponse(this._AccountUpgradeToSocialTradingInvestorResponseValue)
        return true
      }
      case SocialTradingInvestorAccountToRegularRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingInvestorAccountToRegularRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingInvestorAccountToRegularRequestModel.verify(), 'manager.SocialTradingInvestorAccountToRegularRequest validation failed!')
        let deserialized = this._SocialTradingInvestorAccountToRegularRequestModel.deserialize(this._SocialTradingInvestorAccountToRegularRequestValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingInvestorAccountToRegularRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingInvestorAccountToRegularRequestValue)
        this.onReceive_SocialTradingInvestorAccountToRegularRequest(this._SocialTradingInvestorAccountToRegularRequestValue)
        return true
      }
      case SocialTradingInvestorAccountToRegularResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingInvestorAccountToRegularResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingInvestorAccountToRegularResponseModel.verify(), 'manager.SocialTradingInvestorAccountToRegularResponse validation failed!')
        let deserialized = this._SocialTradingInvestorAccountToRegularResponseModel.deserialize(this._SocialTradingInvestorAccountToRegularResponseValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingInvestorAccountToRegularResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingInvestorAccountToRegularResponseValue)
        this.onReceive_SocialTradingInvestorAccountToRegularResponse(this._SocialTradingInvestorAccountToRegularResponseValue)
        return true
      }
      case SocialTradingMasterAccountToRegularRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingMasterAccountToRegularRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingMasterAccountToRegularRequestModel.verify(), 'manager.SocialTradingMasterAccountToRegularRequest validation failed!')
        let deserialized = this._SocialTradingMasterAccountToRegularRequestModel.deserialize(this._SocialTradingMasterAccountToRegularRequestValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingMasterAccountToRegularRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingMasterAccountToRegularRequestValue)
        this.onReceive_SocialTradingMasterAccountToRegularRequest(this._SocialTradingMasterAccountToRegularRequestValue)
        return true
      }
      case SocialTradingMasterAccountToRegularResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingMasterAccountToRegularResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingMasterAccountToRegularResponseModel.verify(), 'manager.SocialTradingMasterAccountToRegularResponse validation failed!')
        let deserialized = this._SocialTradingMasterAccountToRegularResponseModel.deserialize(this._SocialTradingMasterAccountToRegularResponseValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingMasterAccountToRegularResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingMasterAccountToRegularResponseValue)
        this.onReceive_SocialTradingMasterAccountToRegularResponse(this._SocialTradingMasterAccountToRegularResponseValue)
        return true
      }
      case AccountUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateNotifyModel.verify(), 'manager.AccountUpdateNotify validation failed!')
        let deserialized = this._AccountUpdateNotifyModel.deserialize(this._AccountUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateNotifyValue)
        this.onReceive_AccountUpdateNotify(this._AccountUpdateNotifyValue)
        return true
      }
      case MarginGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginGetRequestModel.verify(), 'manager.MarginGetRequest validation failed!')
        let deserialized = this._MarginGetRequestModel.deserialize(this._MarginGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarginGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginGetRequestValue)
        this.onReceive_MarginGetRequest(this._MarginGetRequestValue)
        return true
      }
      case MarginGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginGetResponseModel.verify(), 'manager.MarginGetResponse validation failed!')
        let deserialized = this._MarginGetResponseModel.deserialize(this._MarginGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarginGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginGetResponseValue)
        this.onReceive_MarginGetResponse(this._MarginGetResponseValue)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestModel.verify(), 'manager.MarginsGetAllRequest validation failed!')
        let deserialized = this._MarginsGetAllRequestModel.deserialize(this._MarginsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarginsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllRequestValue)
        this.onReceive_MarginsGetAllRequest(this._MarginsGetAllRequestValue)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseModel.verify(), 'manager.MarginsGetAllResponse validation failed!')
        let deserialized = this._MarginsGetAllResponseModel.deserialize(this._MarginsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarginsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllResponseValue)
        this.onReceive_MarginsGetAllResponse(this._MarginsGetAllResponseValue)
        return true
      }
      case MarginUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateNotifyModel.verify(), 'manager.MarginUpdateNotify validation failed!')
        let deserialized = this._MarginUpdateNotifyModel.deserialize(this._MarginUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.MarginUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateNotifyValue)
        this.onReceive_MarginUpdateNotify(this._MarginUpdateNotifyValue)
        return true
      }
      case MarginCallNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginCallNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginCallNotifyModel.verify(), 'manager.MarginCallNotify validation failed!')
        let deserialized = this._MarginCallNotifyModel.deserialize(this._MarginCallNotifyValue)
        console.assert((deserialized.size > 0), 'manager.MarginCallNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginCallNotifyValue)
        this.onReceive_MarginCallNotify(this._MarginCallNotifyValue)
        return true
      }
      case StopOutNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StopOutNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StopOutNotifyModel.verify(), 'manager.StopOutNotify validation failed!')
        let deserialized = this._StopOutNotifyModel.deserialize(this._StopOutNotifyValue)
        console.assert((deserialized.size > 0), 'manager.StopOutNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StopOutNotifyValue)
        this.onReceive_StopOutNotify(this._StopOutNotifyValue)
        return true
      }
      case AssetGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetGetRequestModel.verify(), 'manager.AssetGetRequest validation failed!')
        let deserialized = this._AssetGetRequestModel.deserialize(this._AssetGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.AssetGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetGetRequestValue)
        this.onReceive_AssetGetRequest(this._AssetGetRequestValue)
        return true
      }
      case AssetGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetGetResponseModel.verify(), 'manager.AssetGetResponse validation failed!')
        let deserialized = this._AssetGetResponseModel.deserialize(this._AssetGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.AssetGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetGetResponseValue)
        this.onReceive_AssetGetResponse(this._AssetGetResponseValue)
        return true
      }
      case AssetsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetRequestModel.verify(), 'manager.AssetsGetRequest validation failed!')
        let deserialized = this._AssetsGetRequestModel.deserialize(this._AssetsGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetRequestValue)
        this.onReceive_AssetsGetRequest(this._AssetsGetRequestValue)
        return true
      }
      case AssetsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetResponseModel.verify(), 'manager.AssetsGetResponse validation failed!')
        let deserialized = this._AssetsGetResponseModel.deserialize(this._AssetsGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetResponseValue)
        this.onReceive_AssetsGetResponse(this._AssetsGetResponseValue)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestModel.verify(), 'manager.AssetsGetAllRequest validation failed!')
        let deserialized = this._AssetsGetAllRequestModel.deserialize(this._AssetsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllRequestValue)
        this.onReceive_AssetsGetAllRequest(this._AssetsGetAllRequestValue)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseModel.verify(), 'manager.AssetsGetAllResponse validation failed!')
        let deserialized = this._AssetsGetAllResponseModel.deserialize(this._AssetsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllResponseValue)
        this.onReceive_AssetsGetAllResponse(this._AssetsGetAllResponseValue)
        return true
      }
      case AssetUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateNotifyModel.verify(), 'manager.AssetUpdateNotify validation failed!')
        let deserialized = this._AssetUpdateNotifyModel.deserialize(this._AssetUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.AssetUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdateNotifyValue)
        this.onReceive_AssetUpdateNotify(this._AssetUpdateNotifyValue)
        return true
      }
      case OrderGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderGetRequestModel.verify(), 'manager.OrderGetRequest validation failed!')
        let deserialized = this._OrderGetRequestModel.deserialize(this._OrderGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderGetRequestValue)
        this.onReceive_OrderGetRequest(this._OrderGetRequestValue)
        return true
      }
      case OrderGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderGetResponseModel.verify(), 'manager.OrderGetResponse validation failed!')
        let deserialized = this._OrderGetResponseModel.deserialize(this._OrderGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderGetResponseValue)
        this.onReceive_OrderGetResponse(this._OrderGetResponseValue)
        return true
      }
      case OrdersGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetRequestModel.verify(), 'manager.OrdersGetRequest validation failed!')
        let deserialized = this._OrdersGetRequestModel.deserialize(this._OrdersGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetRequestValue)
        this.onReceive_OrdersGetRequest(this._OrdersGetRequestValue)
        return true
      }
      case OrdersGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetResponseModel.verify(), 'manager.OrdersGetResponse validation failed!')
        let deserialized = this._OrdersGetResponseModel.deserialize(this._OrdersGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetResponseValue)
        this.onReceive_OrdersGetResponse(this._OrdersGetResponseValue)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestModel.verify(), 'manager.OrdersGetAllRequest validation failed!')
        let deserialized = this._OrdersGetAllRequestModel.deserialize(this._OrdersGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllRequestValue)
        this.onReceive_OrdersGetAllRequest(this._OrdersGetAllRequestValue)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseModel.verify(), 'manager.OrdersGetAllResponse validation failed!')
        let deserialized = this._OrdersGetAllResponseModel.deserialize(this._OrdersGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllResponseValue)
        this.onReceive_OrdersGetAllResponse(this._OrdersGetAllResponseValue)
        return true
      }
      case OrderCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCreateRequestModel.verify(), 'manager.OrderCreateRequest validation failed!')
        let deserialized = this._OrderCreateRequestModel.deserialize(this._OrderCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCreateRequestValue)
        this.onReceive_OrderCreateRequest(this._OrderCreateRequestValue)
        return true
      }
      case OrderCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCreateResponseModel.verify(), 'manager.OrderCreateResponse validation failed!')
        let deserialized = this._OrderCreateResponseModel.deserialize(this._OrderCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCreateResponseValue)
        this.onReceive_OrderCreateResponse(this._OrderCreateResponseValue)
        return true
      }
      case OrderModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderModifyRequestModel.verify(), 'manager.OrderModifyRequest validation failed!')
        let deserialized = this._OrderModifyRequestModel.deserialize(this._OrderModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderModifyRequestValue)
        this.onReceive_OrderModifyRequest(this._OrderModifyRequestValue)
        return true
      }
      case OrderModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderModifyResponseModel.verify(), 'manager.OrderModifyResponse validation failed!')
        let deserialized = this._OrderModifyResponseModel.deserialize(this._OrderModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderModifyResponseValue)
        this.onReceive_OrderModifyResponse(this._OrderModifyResponseValue)
        return true
      }
      case OrderActivateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderActivateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderActivateRequestModel.verify(), 'manager.OrderActivateRequest validation failed!')
        let deserialized = this._OrderActivateRequestModel.deserialize(this._OrderActivateRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderActivateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderActivateRequestValue)
        this.onReceive_OrderActivateRequest(this._OrderActivateRequestValue)
        return true
      }
      case OrderActivateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderActivateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderActivateResponseModel.verify(), 'manager.OrderActivateResponse validation failed!')
        let deserialized = this._OrderActivateResponseModel.deserialize(this._OrderActivateResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderActivateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderActivateResponseValue)
        this.onReceive_OrderActivateResponse(this._OrderActivateResponseValue)
        return true
      }
      case OrderCancelRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelRequestModel.verify(), 'manager.OrderCancelRequest validation failed!')
        let deserialized = this._OrderCancelRequestModel.deserialize(this._OrderCancelRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelRequestValue)
        this.onReceive_OrderCancelRequest(this._OrderCancelRequestValue)
        return true
      }
      case OrderCancelResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelResponseModel.verify(), 'manager.OrderCancelResponse validation failed!')
        let deserialized = this._OrderCancelResponseModel.deserialize(this._OrderCancelResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelResponseValue)
        this.onReceive_OrderCancelResponse(this._OrderCancelResponseValue)
        return true
      }
      case OrderCancelAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllRequestModel.verify(), 'manager.OrderCancelAllRequest validation failed!')
        let deserialized = this._OrderCancelAllRequestModel.deserialize(this._OrderCancelAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllRequestValue)
        this.onReceive_OrderCancelAllRequest(this._OrderCancelAllRequestValue)
        return true
      }
      case OrderCancelAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllResponseModel.verify(), 'manager.OrderCancelAllResponse validation failed!')
        let deserialized = this._OrderCancelAllResponseModel.deserialize(this._OrderCancelAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllResponseValue)
        this.onReceive_OrderCancelAllResponse(this._OrderCancelAllResponseValue)
        return true
      }
      case OrderCancelAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllByIdRequestModel.verify(), 'manager.OrderCancelAllByIdRequest validation failed!')
        let deserialized = this._OrderCancelAllByIdRequestModel.deserialize(this._OrderCancelAllByIdRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllByIdRequestValue)
        this.onReceive_OrderCancelAllByIdRequest(this._OrderCancelAllByIdRequestValue)
        return true
      }
      case OrderCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseRequestModel.verify(), 'manager.OrderCloseRequest validation failed!')
        let deserialized = this._OrderCloseRequestModel.deserialize(this._OrderCloseRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseRequestValue)
        this.onReceive_OrderCloseRequest(this._OrderCloseRequestValue)
        return true
      }
      case OrderCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseResponseModel.verify(), 'manager.OrderCloseResponse validation failed!')
        let deserialized = this._OrderCloseResponseModel.deserialize(this._OrderCloseResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseResponseValue)
        this.onReceive_OrderCloseResponse(this._OrderCloseResponseValue)
        return true
      }
      case OrderCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllRequestModel.verify(), 'manager.OrderCloseAllRequest validation failed!')
        let deserialized = this._OrderCloseAllRequestModel.deserialize(this._OrderCloseAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllRequestValue)
        this.onReceive_OrderCloseAllRequest(this._OrderCloseAllRequestValue)
        return true
      }
      case OrderCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllResponseModel.verify(), 'manager.OrderCloseAllResponse validation failed!')
        let deserialized = this._OrderCloseAllResponseModel.deserialize(this._OrderCloseAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllResponseValue)
        this.onReceive_OrderCloseAllResponse(this._OrderCloseAllResponseValue)
        return true
      }
      case OrderCloseAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllByIdRequestModel.verify(), 'manager.OrderCloseAllByIdRequest validation failed!')
        let deserialized = this._OrderCloseAllByIdRequestModel.deserialize(this._OrderCloseAllByIdRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllByIdRequestValue)
        this.onReceive_OrderCloseAllByIdRequest(this._OrderCloseAllByIdRequestValue)
        return true
      }
      case OrderCloseByRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByRequestModel.verify(), 'manager.OrderCloseByRequest validation failed!')
        let deserialized = this._OrderCloseByRequestModel.deserialize(this._OrderCloseByRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByRequestValue)
        this.onReceive_OrderCloseByRequest(this._OrderCloseByRequestValue)
        return true
      }
      case OrderCloseByResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByResponseModel.verify(), 'manager.OrderCloseByResponse validation failed!')
        let deserialized = this._OrderCloseByResponseModel.deserialize(this._OrderCloseByResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByResponseValue)
        this.onReceive_OrderCloseByResponse(this._OrderCloseByResponseValue)
        return true
      }
      case OrderCloseByAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllRequestModel.verify(), 'manager.OrderCloseByAllRequest validation failed!')
        let deserialized = this._OrderCloseByAllRequestModel.deserialize(this._OrderCloseByAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllRequestValue)
        this.onReceive_OrderCloseByAllRequest(this._OrderCloseByAllRequestValue)
        return true
      }
      case OrderCloseByAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllResponseModel.verify(), 'manager.OrderCloseByAllResponse validation failed!')
        let deserialized = this._OrderCloseByAllResponseModel.deserialize(this._OrderCloseByAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllResponseValue)
        this.onReceive_OrderCloseByAllResponse(this._OrderCloseByAllResponseValue)
        return true
      }
      case OrderCancelOrCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseRequestModel.verify(), 'manager.OrderCancelOrCloseRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseRequestModel.deserialize(this._OrderCancelOrCloseRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseRequestValue)
        this.onReceive_OrderCancelOrCloseRequest(this._OrderCancelOrCloseRequestValue)
        return true
      }
      case OrderCancelOrCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseResponseModel.verify(), 'manager.OrderCancelOrCloseResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseResponseModel.deserialize(this._OrderCancelOrCloseResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseResponseValue)
        this.onReceive_OrderCancelOrCloseResponse(this._OrderCancelOrCloseResponseValue)
        return true
      }
      case OrderCancelOrCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllRequestModel.verify(), 'manager.OrderCancelOrCloseAllRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseAllRequestModel.deserialize(this._OrderCancelOrCloseAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllRequestValue)
        this.onReceive_OrderCancelOrCloseAllRequest(this._OrderCancelOrCloseAllRequestValue)
        return true
      }
      case OrderCancelOrCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllResponseModel.verify(), 'manager.OrderCancelOrCloseAllResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseAllResponseModel.deserialize(this._OrderCancelOrCloseAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllResponseValue)
        this.onReceive_OrderCancelOrCloseAllResponse(this._OrderCancelOrCloseAllResponseValue)
        return true
      }
      case OrderUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateNotifyModel.verify(), 'manager.OrderUpdateNotify validation failed!')
        let deserialized = this._OrderUpdateNotifyModel.deserialize(this._OrderUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.OrderUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateNotifyValue)
        this.onReceive_OrderUpdateNotify(this._OrderUpdateNotifyValue)
        return true
      }
      case PositionGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionGetRequestModel.verify(), 'manager.PositionGetRequest validation failed!')
        let deserialized = this._PositionGetRequestModel.deserialize(this._PositionGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.PositionGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionGetRequestValue)
        this.onReceive_PositionGetRequest(this._PositionGetRequestValue)
        return true
      }
      case PositionGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionGetResponseModel.verify(), 'manager.PositionGetResponse validation failed!')
        let deserialized = this._PositionGetResponseModel.deserialize(this._PositionGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.PositionGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionGetResponseValue)
        this.onReceive_PositionGetResponse(this._PositionGetResponseValue)
        return true
      }
      case PositionsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetRequestModel.verify(), 'manager.PositionsGetRequest validation failed!')
        let deserialized = this._PositionsGetRequestModel.deserialize(this._PositionsGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetRequestValue)
        this.onReceive_PositionsGetRequest(this._PositionsGetRequestValue)
        return true
      }
      case PositionsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetResponseModel.verify(), 'manager.PositionsGetResponse validation failed!')
        let deserialized = this._PositionsGetResponseModel.deserialize(this._PositionsGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetResponseValue)
        this.onReceive_PositionsGetResponse(this._PositionsGetResponseValue)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestModel.verify(), 'manager.PositionsGetAllRequest validation failed!')
        let deserialized = this._PositionsGetAllRequestModel.deserialize(this._PositionsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllRequestValue)
        this.onReceive_PositionsGetAllRequest(this._PositionsGetAllRequestValue)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseModel.verify(), 'manager.PositionsGetAllResponse validation failed!')
        let deserialized = this._PositionsGetAllResponseModel.deserialize(this._PositionsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllResponseValue)
        this.onReceive_PositionsGetAllResponse(this._PositionsGetAllResponseValue)
        return true
      }
      case PositionUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateNotifyModel.verify(), 'manager.PositionUpdateNotify validation failed!')
        let deserialized = this._PositionUpdateNotifyModel.deserialize(this._PositionUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.PositionUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdateNotifyValue)
        this.onReceive_PositionUpdateNotify(this._PositionUpdateNotifyValue)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestModel.verify(), 'manager.TradeHistoryRequest validation failed!')
        let deserialized = this._TradeHistoryRequestModel.deserialize(this._TradeHistoryRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryRequestValue)
        this.onReceive_TradeHistoryRequest(this._TradeHistoryRequestValue)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseModel.verify(), 'manager.TradeHistoryResponse validation failed!')
        let deserialized = this._TradeHistoryResponseModel.deserialize(this._TradeHistoryResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryResponseValue)
        this.onReceive_TradeHistoryResponse(this._TradeHistoryResponseValue)
        return true
      }
      case TradeHistoryFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryFilterRequestModel.verify(), 'manager.TradeHistoryFilterRequest validation failed!')
        let deserialized = this._TradeHistoryFilterRequestModel.deserialize(this._TradeHistoryFilterRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryFilterRequestValue)
        this.onReceive_TradeHistoryFilterRequest(this._TradeHistoryFilterRequestValue)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestModel.verify(), 'manager.TradeHistoryByIdRequest validation failed!')
        let deserialized = this._TradeHistoryByIdRequestModel.deserialize(this._TradeHistoryByIdRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdRequestValue)
        this.onReceive_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestValue)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseModel.verify(), 'manager.TradeHistoryByIdResponse validation failed!')
        let deserialized = this._TradeHistoryByIdResponseModel.deserialize(this._TradeHistoryByIdResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdResponseValue)
        this.onReceive_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseValue)
        return true
      }
      case TradeHistoryUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryUpdateNotifyModel.verify(), 'manager.TradeHistoryUpdateNotify validation failed!')
        let deserialized = this._TradeHistoryUpdateNotifyModel.deserialize(this._TradeHistoryUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryUpdateNotifyValue)
        this.onReceive_TradeHistoryUpdateNotify(this._TradeHistoryUpdateNotifyValue)
        return true
      }
      case TestRolloverDumpRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TestRolloverDumpRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TestRolloverDumpRequestModel.verify(), 'manager.TestRolloverDumpRequest validation failed!')
        let deserialized = this._TestRolloverDumpRequestModel.deserialize(this._TestRolloverDumpRequestValue)
        console.assert((deserialized.size > 0), 'manager.TestRolloverDumpRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TestRolloverDumpRequestValue)
        this.onReceive_TestRolloverDumpRequest(this._TestRolloverDumpRequestValue)
        return true
      }
      case TestRolloverDumpResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TestRolloverDumpResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TestRolloverDumpResponseModel.verify(), 'manager.TestRolloverDumpResponse validation failed!')
        let deserialized = this._TestRolloverDumpResponseModel.deserialize(this._TestRolloverDumpResponseValue)
        console.assert((deserialized.size > 0), 'manager.TestRolloverDumpResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TestRolloverDumpResponseValue)
        this.onReceive_TestRolloverDumpResponse(this._TestRolloverDumpResponseValue)
        return true
      }
      case Deprecated001Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated001Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated001Model.verify(), 'manager.Deprecated001 validation failed!')
        let deserialized = this._Deprecated001Model.deserialize(this._Deprecated001Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated001 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated001Value)
        this.onReceive_Deprecated001(this._Deprecated001Value)
        return true
      }
      case Deprecated002Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated002Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated002Model.verify(), 'manager.Deprecated002 validation failed!')
        let deserialized = this._Deprecated002Model.deserialize(this._Deprecated002Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated002 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated002Value)
        this.onReceive_Deprecated002(this._Deprecated002Value)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated003Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003Model.verify(), 'manager.Deprecated003 validation failed!')
        let deserialized = this._Deprecated003Model.deserialize(this._Deprecated003Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated003 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated003Value)
        this.onReceive_Deprecated003(this._Deprecated003Value)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated004Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004Model.verify(), 'manager.Deprecated004 validation failed!')
        let deserialized = this._Deprecated004Model.deserialize(this._Deprecated004Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated004 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated004Value)
        this.onReceive_Deprecated004(this._Deprecated004Value)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated005Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005Model.verify(), 'manager.Deprecated005 validation failed!')
        let deserialized = this._Deprecated005Model.deserialize(this._Deprecated005Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated005 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated005Value)
        this.onReceive_Deprecated005(this._Deprecated005Value)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated006Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006Model.verify(), 'manager.Deprecated006 validation failed!')
        let deserialized = this._Deprecated006Model.deserialize(this._Deprecated006Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated006 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated006Value)
        this.onReceive_Deprecated006(this._Deprecated006Value)
        return true
      }
      case Deprecated007Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated007Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated007Model.verify(), 'manager.Deprecated007 validation failed!')
        let deserialized = this._Deprecated007Model.deserialize(this._Deprecated007Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated007 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated007Value)
        this.onReceive_Deprecated007(this._Deprecated007Value)
        return true
      }
      case Deprecated008Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated008Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated008Model.verify(), 'manager.Deprecated008 validation failed!')
        let deserialized = this._Deprecated008Model.deserialize(this._Deprecated008Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated008 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated008Value)
        this.onReceive_Deprecated008(this._Deprecated008Value)
        return true
      }
      case Deprecated009Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated009Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated009Model.verify(), 'manager.Deprecated009 validation failed!')
        let deserialized = this._Deprecated009Model.deserialize(this._Deprecated009Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated009 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated009Value)
        this.onReceive_Deprecated009(this._Deprecated009Value)
        return true
      }
      case Deprecated010Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated010Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated010Model.verify(), 'manager.Deprecated010 validation failed!')
        let deserialized = this._Deprecated010Model.deserialize(this._Deprecated010Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated010 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated010Value)
        this.onReceive_Deprecated010(this._Deprecated010Value)
        return true
      }
      case Deprecated011Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated011Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated011Model.verify(), 'manager.Deprecated011 validation failed!')
        let deserialized = this._Deprecated011Model.deserialize(this._Deprecated011Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated011 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated011Value)
        this.onReceive_Deprecated011(this._Deprecated011Value)
        return true
      }
      case Deprecated012Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated012Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated012Model.verify(), 'manager.Deprecated012 validation failed!')
        let deserialized = this._Deprecated012Model.deserialize(this._Deprecated012Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated012 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated012Value)
        this.onReceive_Deprecated012(this._Deprecated012Value)
        return true
      }
      case Deprecated013Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated013Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated013Model.verify(), 'manager.Deprecated013 validation failed!')
        let deserialized = this._Deprecated013Model.deserialize(this._Deprecated013Value)
        console.assert((deserialized.size > 0), 'manager.Deprecated013 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated013Value)
        this.onReceive_Deprecated013(this._Deprecated013Value)
        return true
      }
      case DepositAccountRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositAccountRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DepositAccountRequestModel.verify(), 'manager.DepositAccountRequest validation failed!')
        let deserialized = this._DepositAccountRequestModel.deserialize(this._DepositAccountRequestValue)
        console.assert((deserialized.size > 0), 'manager.DepositAccountRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositAccountRequestValue)
        this.onReceive_DepositAccountRequest(this._DepositAccountRequestValue)
        return true
      }
      case DepositAccountResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositAccountResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DepositAccountResponseModel.verify(), 'manager.DepositAccountResponse validation failed!')
        let deserialized = this._DepositAccountResponseModel.deserialize(this._DepositAccountResponseValue)
        console.assert((deserialized.size > 0), 'manager.DepositAccountResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositAccountResponseValue)
        this.onReceive_DepositAccountResponse(this._DepositAccountResponseValue)
        return true
      }
      case CreditAccountRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CreditAccountRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CreditAccountRequestModel.verify(), 'manager.CreditAccountRequest validation failed!')
        let deserialized = this._CreditAccountRequestModel.deserialize(this._CreditAccountRequestValue)
        console.assert((deserialized.size > 0), 'manager.CreditAccountRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CreditAccountRequestValue)
        this.onReceive_CreditAccountRequest(this._CreditAccountRequestValue)
        return true
      }
      case CreditAccountResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CreditAccountResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CreditAccountResponseModel.verify(), 'manager.CreditAccountResponse validation failed!')
        let deserialized = this._CreditAccountResponseModel.deserialize(this._CreditAccountResponseValue)
        console.assert((deserialized.size > 0), 'manager.CreditAccountResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CreditAccountResponseValue)
        this.onReceive_CreditAccountResponse(this._CreditAccountResponseValue)
        return true
      }
      case TransferMoneyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TransferMoneyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TransferMoneyRequestModel.verify(), 'manager.TransferMoneyRequest validation failed!')
        let deserialized = this._TransferMoneyRequestModel.deserialize(this._TransferMoneyRequestValue)
        console.assert((deserialized.size > 0), 'manager.TransferMoneyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TransferMoneyRequestValue)
        this.onReceive_TransferMoneyRequest(this._TransferMoneyRequestValue)
        return true
      }
      case TransferMoneyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TransferMoneyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TransferMoneyResponseModel.verify(), 'manager.TransferMoneyResponse validation failed!')
        let deserialized = this._TransferMoneyResponseModel.deserialize(this._TransferMoneyResponseValue)
        console.assert((deserialized.size > 0), 'manager.TransferMoneyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TransferMoneyResponseValue)
        this.onReceive_TransferMoneyResponse(this._TransferMoneyResponseValue)
        return true
      }
      case TradingCalendarRegularRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleGetRequestModel.verify(), 'manager.TradingCalendarRegularRuleGetRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleGetRequestModel.deserialize(this._TradingCalendarRegularRuleGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleGetRequestValue)
        this.onReceive_TradingCalendarRegularRuleGetRequest(this._TradingCalendarRegularRuleGetRequestValue)
        return true
      }
      case TradingCalendarRegularRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleGetResponseModel.verify(), 'manager.TradingCalendarRegularRuleGetResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleGetResponseModel.deserialize(this._TradingCalendarRegularRuleGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleGetResponseValue)
        this.onReceive_TradingCalendarRegularRuleGetResponse(this._TradingCalendarRegularRuleGetResponseValue)
        return true
      }
      case TradingCalendarRegularRulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRulesGetAllRequestModel.verify(), 'manager.TradingCalendarRegularRulesGetAllRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRulesGetAllRequestModel.deserialize(this._TradingCalendarRegularRulesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRulesGetAllRequestValue)
        this.onReceive_TradingCalendarRegularRulesGetAllRequest(this._TradingCalendarRegularRulesGetAllRequestValue)
        return true
      }
      case TradingCalendarRegularRulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRulesGetAllResponseModel.verify(), 'manager.TradingCalendarRegularRulesGetAllResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRulesGetAllResponseModel.deserialize(this._TradingCalendarRegularRulesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRulesGetAllResponseValue)
        this.onReceive_TradingCalendarRegularRulesGetAllResponse(this._TradingCalendarRegularRulesGetAllResponseValue)
        return true
      }
      case TradingCalendarRegularRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleCreateRequestModel.verify(), 'manager.TradingCalendarRegularRuleCreateRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleCreateRequestModel.deserialize(this._TradingCalendarRegularRuleCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleCreateRequestValue)
        this.onReceive_TradingCalendarRegularRuleCreateRequest(this._TradingCalendarRegularRuleCreateRequestValue)
        return true
      }
      case TradingCalendarRegularRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleCreateResponseModel.verify(), 'manager.TradingCalendarRegularRuleCreateResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleCreateResponseModel.deserialize(this._TradingCalendarRegularRuleCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleCreateResponseValue)
        this.onReceive_TradingCalendarRegularRuleCreateResponse(this._TradingCalendarRegularRuleCreateResponseValue)
        return true
      }
      case TradingCalendarRegularRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleModifyRequestModel.verify(), 'manager.TradingCalendarRegularRuleModifyRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleModifyRequestModel.deserialize(this._TradingCalendarRegularRuleModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleModifyRequestValue)
        this.onReceive_TradingCalendarRegularRuleModifyRequest(this._TradingCalendarRegularRuleModifyRequestValue)
        return true
      }
      case TradingCalendarRegularRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleModifyResponseModel.verify(), 'manager.TradingCalendarRegularRuleModifyResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleModifyResponseModel.deserialize(this._TradingCalendarRegularRuleModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleModifyResponseValue)
        this.onReceive_TradingCalendarRegularRuleModifyResponse(this._TradingCalendarRegularRuleModifyResponseValue)
        return true
      }
      case TradingCalendarRegularRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleDeleteRequestModel.verify(), 'manager.TradingCalendarRegularRuleDeleteRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleDeleteRequestModel.deserialize(this._TradingCalendarRegularRuleDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleDeleteRequestValue)
        this.onReceive_TradingCalendarRegularRuleDeleteRequest(this._TradingCalendarRegularRuleDeleteRequestValue)
        return true
      }
      case TradingCalendarRegularRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleDeleteResponseModel.verify(), 'manager.TradingCalendarRegularRuleDeleteResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleDeleteResponseModel.deserialize(this._TradingCalendarRegularRuleDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleDeleteResponseValue)
        this.onReceive_TradingCalendarRegularRuleDeleteResponse(this._TradingCalendarRegularRuleDeleteResponseValue)
        return true
      }
      case TradingCalendarRegularRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleUpdateNotifyModel.verify(), 'manager.TradingCalendarRegularRuleUpdateNotify validation failed!')
        let deserialized = this._TradingCalendarRegularRuleUpdateNotifyModel.deserialize(this._TradingCalendarRegularRuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleUpdateNotifyValue)
        this.onReceive_TradingCalendarRegularRuleUpdateNotify(this._TradingCalendarRegularRuleUpdateNotifyValue)
        return true
      }
      case TradingCalendarOffTimeRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleGetRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleGetRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleGetRequestModel.deserialize(this._TradingCalendarOffTimeRuleGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleGetRequestValue)
        this.onReceive_TradingCalendarOffTimeRuleGetRequest(this._TradingCalendarOffTimeRuleGetRequestValue)
        return true
      }
      case TradingCalendarOffTimeRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleGetResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleGetResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleGetResponseModel.deserialize(this._TradingCalendarOffTimeRuleGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleGetResponseValue)
        this.onReceive_TradingCalendarOffTimeRuleGetResponse(this._TradingCalendarOffTimeRuleGetResponseValue)
        return true
      }
      case TradingCalendarOffTimeRulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRulesGetAllRequestModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRulesGetAllRequestModel.deserialize(this._TradingCalendarOffTimeRulesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRulesGetAllRequestValue)
        this.onReceive_TradingCalendarOffTimeRulesGetAllRequest(this._TradingCalendarOffTimeRulesGetAllRequestValue)
        return true
      }
      case TradingCalendarOffTimeRulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRulesGetAllResponseModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRulesGetAllResponseModel.deserialize(this._TradingCalendarOffTimeRulesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRulesGetAllResponseValue)
        this.onReceive_TradingCalendarOffTimeRulesGetAllResponse(this._TradingCalendarOffTimeRulesGetAllResponseValue)
        return true
      }
      case TradingCalendarOffTimeRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleCreateRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleCreateRequestModel.deserialize(this._TradingCalendarOffTimeRuleCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleCreateRequestValue)
        this.onReceive_TradingCalendarOffTimeRuleCreateRequest(this._TradingCalendarOffTimeRuleCreateRequestValue)
        return true
      }
      case TradingCalendarOffTimeRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleCreateResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleCreateResponseModel.deserialize(this._TradingCalendarOffTimeRuleCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleCreateResponseValue)
        this.onReceive_TradingCalendarOffTimeRuleCreateResponse(this._TradingCalendarOffTimeRuleCreateResponseValue)
        return true
      }
      case TradingCalendarOffTimeRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleModifyRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleModifyRequestModel.deserialize(this._TradingCalendarOffTimeRuleModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleModifyRequestValue)
        this.onReceive_TradingCalendarOffTimeRuleModifyRequest(this._TradingCalendarOffTimeRuleModifyRequestValue)
        return true
      }
      case TradingCalendarOffTimeRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleModifyResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleModifyResponseModel.deserialize(this._TradingCalendarOffTimeRuleModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleModifyResponseValue)
        this.onReceive_TradingCalendarOffTimeRuleModifyResponse(this._TradingCalendarOffTimeRuleModifyResponseValue)
        return true
      }
      case TradingCalendarOffTimeRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleDeleteRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleDeleteRequestModel.deserialize(this._TradingCalendarOffTimeRuleDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleDeleteRequestValue)
        this.onReceive_TradingCalendarOffTimeRuleDeleteRequest(this._TradingCalendarOffTimeRuleDeleteRequestValue)
        return true
      }
      case TradingCalendarOffTimeRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleDeleteResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleDeleteResponseModel.deserialize(this._TradingCalendarOffTimeRuleDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleDeleteResponseValue)
        this.onReceive_TradingCalendarOffTimeRuleDeleteResponse(this._TradingCalendarOffTimeRuleDeleteResponseValue)
        return true
      }
      case TradingCalendarOffTimeRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleUpdateNotifyModel.verify(), 'manager.TradingCalendarOffTimeRuleUpdateNotify validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleUpdateNotifyModel.deserialize(this._TradingCalendarOffTimeRuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleUpdateNotifyValue)
        this.onReceive_TradingCalendarOffTimeRuleUpdateNotify(this._TradingCalendarOffTimeRuleUpdateNotifyValue)
        return true
      }
      case TradingCalendarRulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRulesGetRequestModel.verify(), 'manager.TradingCalendarRulesGetRequest validation failed!')
        let deserialized = this._TradingCalendarRulesGetRequestModel.deserialize(this._TradingCalendarRulesGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRulesGetRequestValue)
        this.onReceive_TradingCalendarRulesGetRequest(this._TradingCalendarRulesGetRequestValue)
        return true
      }
      case TradingCalendarRulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRulesGetResponseModel.verify(), 'manager.TradingCalendarRulesGetResponse validation failed!')
        let deserialized = this._TradingCalendarRulesGetResponseModel.deserialize(this._TradingCalendarRulesGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRulesGetResponseValue)
        this.onReceive_TradingCalendarRulesGetResponse(this._TradingCalendarRulesGetResponseValue)
        return true
      }
      case TradingRouteRulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRulesGetRequestModel.verify(), 'manager.TradingRouteRulesGetRequest validation failed!')
        let deserialized = this._TradingRouteRulesGetRequestModel.deserialize(this._TradingRouteRulesGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRulesGetRequestValue)
        this.onReceive_TradingRouteRulesGetRequest(this._TradingRouteRulesGetRequestValue)
        return true
      }
      case TradingRouteRulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRulesGetResponseModel.verify(), 'manager.TradingRouteRulesGetResponse validation failed!')
        let deserialized = this._TradingRouteRulesGetResponseModel.deserialize(this._TradingRouteRulesGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRulesGetResponseValue)
        this.onReceive_TradingRouteRulesGetResponse(this._TradingRouteRulesGetResponseValue)
        return true
      }
      case TradingRouteRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleCreateRequestModel.verify(), 'manager.TradingRouteRuleCreateRequest validation failed!')
        let deserialized = this._TradingRouteRuleCreateRequestModel.deserialize(this._TradingRouteRuleCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleCreateRequestValue)
        this.onReceive_TradingRouteRuleCreateRequest(this._TradingRouteRuleCreateRequestValue)
        return true
      }
      case TradingRouteRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleCreateResponseModel.verify(), 'manager.TradingRouteRuleCreateResponse validation failed!')
        let deserialized = this._TradingRouteRuleCreateResponseModel.deserialize(this._TradingRouteRuleCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleCreateResponseValue)
        this.onReceive_TradingRouteRuleCreateResponse(this._TradingRouteRuleCreateResponseValue)
        return true
      }
      case TradingRouteRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleModifyRequestModel.verify(), 'manager.TradingRouteRuleModifyRequest validation failed!')
        let deserialized = this._TradingRouteRuleModifyRequestModel.deserialize(this._TradingRouteRuleModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleModifyRequestValue)
        this.onReceive_TradingRouteRuleModifyRequest(this._TradingRouteRuleModifyRequestValue)
        return true
      }
      case TradingRouteRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleModifyResponseModel.verify(), 'manager.TradingRouteRuleModifyResponse validation failed!')
        let deserialized = this._TradingRouteRuleModifyResponseModel.deserialize(this._TradingRouteRuleModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleModifyResponseValue)
        this.onReceive_TradingRouteRuleModifyResponse(this._TradingRouteRuleModifyResponseValue)
        return true
      }
      case TradingRouteRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleDeleteRequestModel.verify(), 'manager.TradingRouteRuleDeleteRequest validation failed!')
        let deserialized = this._TradingRouteRuleDeleteRequestModel.deserialize(this._TradingRouteRuleDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleDeleteRequestValue)
        this.onReceive_TradingRouteRuleDeleteRequest(this._TradingRouteRuleDeleteRequestValue)
        return true
      }
      case TradingRouteRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleDeleteResponseModel.verify(), 'manager.TradingRouteRuleDeleteResponse validation failed!')
        let deserialized = this._TradingRouteRuleDeleteResponseModel.deserialize(this._TradingRouteRuleDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleDeleteResponseValue)
        this.onReceive_TradingRouteRuleDeleteResponse(this._TradingRouteRuleDeleteResponseValue)
        return true
      }
      case TradingRouteRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleUpdateNotifyModel.verify(), 'manager.TradingRouteRuleUpdateNotify validation failed!')
        let deserialized = this._TradingRouteRuleUpdateNotifyModel.deserialize(this._TradingRouteRuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleUpdateNotifyValue)
        this.onReceive_TradingRouteRuleUpdateNotify(this._TradingRouteRuleUpdateNotifyValue)
        return true
      }
      case SymbolMapsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapsGetRequestModel.verify(), 'manager.SymbolMapsGetRequest validation failed!')
        let deserialized = this._SymbolMapsGetRequestModel.deserialize(this._SymbolMapsGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapsGetRequestValue)
        this.onReceive_SymbolMapsGetRequest(this._SymbolMapsGetRequestValue)
        return true
      }
      case SymbolMapsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapsGetResponseModel.verify(), 'manager.SymbolMapsGetResponse validation failed!')
        let deserialized = this._SymbolMapsGetResponseModel.deserialize(this._SymbolMapsGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapsGetResponseValue)
        this.onReceive_SymbolMapsGetResponse(this._SymbolMapsGetResponseValue)
        return true
      }
      case SymbolMapCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapCreateRequestModel.verify(), 'manager.SymbolMapCreateRequest validation failed!')
        let deserialized = this._SymbolMapCreateRequestModel.deserialize(this._SymbolMapCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapCreateRequestValue)
        this.onReceive_SymbolMapCreateRequest(this._SymbolMapCreateRequestValue)
        return true
      }
      case SymbolMapCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapCreateResponseModel.verify(), 'manager.SymbolMapCreateResponse validation failed!')
        let deserialized = this._SymbolMapCreateResponseModel.deserialize(this._SymbolMapCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapCreateResponseValue)
        this.onReceive_SymbolMapCreateResponse(this._SymbolMapCreateResponseValue)
        return true
      }
      case SymbolMapModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapModifyRequestModel.verify(), 'manager.SymbolMapModifyRequest validation failed!')
        let deserialized = this._SymbolMapModifyRequestModel.deserialize(this._SymbolMapModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapModifyRequestValue)
        this.onReceive_SymbolMapModifyRequest(this._SymbolMapModifyRequestValue)
        return true
      }
      case SymbolMapModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapModifyResponseModel.verify(), 'manager.SymbolMapModifyResponse validation failed!')
        let deserialized = this._SymbolMapModifyResponseModel.deserialize(this._SymbolMapModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapModifyResponseValue)
        this.onReceive_SymbolMapModifyResponse(this._SymbolMapModifyResponseValue)
        return true
      }
      case SymbolMapDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapDeleteRequestModel.verify(), 'manager.SymbolMapDeleteRequest validation failed!')
        let deserialized = this._SymbolMapDeleteRequestModel.deserialize(this._SymbolMapDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapDeleteRequestValue)
        this.onReceive_SymbolMapDeleteRequest(this._SymbolMapDeleteRequestValue)
        return true
      }
      case SymbolMapDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapDeleteResponseModel.verify(), 'manager.SymbolMapDeleteResponse validation failed!')
        let deserialized = this._SymbolMapDeleteResponseModel.deserialize(this._SymbolMapDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapDeleteResponseValue)
        this.onReceive_SymbolMapDeleteResponse(this._SymbolMapDeleteResponseValue)
        return true
      }
      case SymbolMapUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapUpdateNotifyModel.verify(), 'manager.SymbolMapUpdateNotify validation failed!')
        let deserialized = this._SymbolMapUpdateNotifyModel.deserialize(this._SymbolMapUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapUpdateNotifyValue)
        this.onReceive_SymbolMapUpdateNotify(this._SymbolMapUpdateNotifyValue)
        return true
      }
      case GatewaysGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewaysGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewaysGetRequestModel.verify(), 'manager.GatewaysGetRequest validation failed!')
        let deserialized = this._GatewaysGetRequestModel.deserialize(this._GatewaysGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.GatewaysGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewaysGetRequestValue)
        this.onReceive_GatewaysGetRequest(this._GatewaysGetRequestValue)
        return true
      }
      case GatewaysGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewaysGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewaysGetResponseModel.verify(), 'manager.GatewaysGetResponse validation failed!')
        let deserialized = this._GatewaysGetResponseModel.deserialize(this._GatewaysGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.GatewaysGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewaysGetResponseValue)
        this.onReceive_GatewaysGetResponse(this._GatewaysGetResponseValue)
        return true
      }
      case GatewayCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayCreateRequestModel.verify(), 'manager.GatewayCreateRequest validation failed!')
        let deserialized = this._GatewayCreateRequestModel.deserialize(this._GatewayCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.GatewayCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayCreateRequestValue)
        this.onReceive_GatewayCreateRequest(this._GatewayCreateRequestValue)
        return true
      }
      case GatewayCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayCreateResponseModel.verify(), 'manager.GatewayCreateResponse validation failed!')
        let deserialized = this._GatewayCreateResponseModel.deserialize(this._GatewayCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.GatewayCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayCreateResponseValue)
        this.onReceive_GatewayCreateResponse(this._GatewayCreateResponseValue)
        return true
      }
      case GatewayModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModifyRequestModel.verify(), 'manager.GatewayModifyRequest validation failed!')
        let deserialized = this._GatewayModifyRequestModel.deserialize(this._GatewayModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.GatewayModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModifyRequestValue)
        this.onReceive_GatewayModifyRequest(this._GatewayModifyRequestValue)
        return true
      }
      case GatewayModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModifyResponseModel.verify(), 'manager.GatewayModifyResponse validation failed!')
        let deserialized = this._GatewayModifyResponseModel.deserialize(this._GatewayModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.GatewayModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModifyResponseValue)
        this.onReceive_GatewayModifyResponse(this._GatewayModifyResponseValue)
        return true
      }
      case GatewayDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayDeleteRequestModel.verify(), 'manager.GatewayDeleteRequest validation failed!')
        let deserialized = this._GatewayDeleteRequestModel.deserialize(this._GatewayDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.GatewayDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayDeleteRequestValue)
        this.onReceive_GatewayDeleteRequest(this._GatewayDeleteRequestValue)
        return true
      }
      case GatewayDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayDeleteResponseModel.verify(), 'manager.GatewayDeleteResponse validation failed!')
        let deserialized = this._GatewayDeleteResponseModel.deserialize(this._GatewayDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.GatewayDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayDeleteResponseValue)
        this.onReceive_GatewayDeleteResponse(this._GatewayDeleteResponseValue)
        return true
      }
      case GatewayUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayUpdateNotifyModel.verify(), 'manager.GatewayUpdateNotify validation failed!')
        let deserialized = this._GatewayUpdateNotifyModel.deserialize(this._GatewayUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.GatewayUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayUpdateNotifyValue)
        this.onReceive_GatewayUpdateNotify(this._GatewayUpdateNotifyValue)
        return true
      }
      case DataFeedsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedsGetRequestModel.verify(), 'manager.DataFeedsGetRequest validation failed!')
        let deserialized = this._DataFeedsGetRequestModel.deserialize(this._DataFeedsGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedsGetRequestValue)
        this.onReceive_DataFeedsGetRequest(this._DataFeedsGetRequestValue)
        return true
      }
      case DataFeedsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedsGetResponseModel.verify(), 'manager.DataFeedsGetResponse validation failed!')
        let deserialized = this._DataFeedsGetResponseModel.deserialize(this._DataFeedsGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedsGetResponseValue)
        this.onReceive_DataFeedsGetResponse(this._DataFeedsGetResponseValue)
        return true
      }
      case DataFeedCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedCreateRequestModel.verify(), 'manager.DataFeedCreateRequest validation failed!')
        let deserialized = this._DataFeedCreateRequestModel.deserialize(this._DataFeedCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedCreateRequestValue)
        this.onReceive_DataFeedCreateRequest(this._DataFeedCreateRequestValue)
        return true
      }
      case DataFeedCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedCreateResponseModel.verify(), 'manager.DataFeedCreateResponse validation failed!')
        let deserialized = this._DataFeedCreateResponseModel.deserialize(this._DataFeedCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedCreateResponseValue)
        this.onReceive_DataFeedCreateResponse(this._DataFeedCreateResponseValue)
        return true
      }
      case DataFeedModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModifyRequestModel.verify(), 'manager.DataFeedModifyRequest validation failed!')
        let deserialized = this._DataFeedModifyRequestModel.deserialize(this._DataFeedModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModifyRequestValue)
        this.onReceive_DataFeedModifyRequest(this._DataFeedModifyRequestValue)
        return true
      }
      case DataFeedModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModifyResponseModel.verify(), 'manager.DataFeedModifyResponse validation failed!')
        let deserialized = this._DataFeedModifyResponseModel.deserialize(this._DataFeedModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModifyResponseValue)
        this.onReceive_DataFeedModifyResponse(this._DataFeedModifyResponseValue)
        return true
      }
      case DataFeedDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedDeleteRequestModel.verify(), 'manager.DataFeedDeleteRequest validation failed!')
        let deserialized = this._DataFeedDeleteRequestModel.deserialize(this._DataFeedDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedDeleteRequestValue)
        this.onReceive_DataFeedDeleteRequest(this._DataFeedDeleteRequestValue)
        return true
      }
      case DataFeedDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedDeleteResponseModel.verify(), 'manager.DataFeedDeleteResponse validation failed!')
        let deserialized = this._DataFeedDeleteResponseModel.deserialize(this._DataFeedDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedDeleteResponseValue)
        this.onReceive_DataFeedDeleteResponse(this._DataFeedDeleteResponseValue)
        return true
      }
      case DataFeedUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedUpdateNotifyModel.verify(), 'manager.DataFeedUpdateNotify validation failed!')
        let deserialized = this._DataFeedUpdateNotifyModel.deserialize(this._DataFeedUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedUpdateNotifyValue)
        this.onReceive_DataFeedUpdateNotify(this._DataFeedUpdateNotifyValue)
        return true
      }
      case ModuleSystemInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleSystemInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoNotifyModel.verify(), 'manager.ModuleSystemInfoNotify validation failed!')
        let deserialized = this._ModuleSystemInfoNotifyModel.deserialize(this._ModuleSystemInfoNotifyValue)
        console.assert((deserialized.size > 0), 'manager.ModuleSystemInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleSystemInfoNotifyValue)
        this.onReceive_ModuleSystemInfoNotify(this._ModuleSystemInfoNotifyValue)
        return true
      }
      case ModulesSystemInfoGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesSystemInfoGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoGetAllRequestModel.verify(), 'manager.ModulesSystemInfoGetAllRequest validation failed!')
        let deserialized = this._ModulesSystemInfoGetAllRequestModel.deserialize(this._ModulesSystemInfoGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.ModulesSystemInfoGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesSystemInfoGetAllRequestValue)
        this.onReceive_ModulesSystemInfoGetAllRequest(this._ModulesSystemInfoGetAllRequestValue)
        return true
      }
      case ModulesSystemInfoGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesSystemInfoGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoGetAllResponseModel.verify(), 'manager.ModulesSystemInfoGetAllResponse validation failed!')
        let deserialized = this._ModulesSystemInfoGetAllResponseModel.deserialize(this._ModulesSystemInfoGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.ModulesSystemInfoGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesSystemInfoGetAllResponseValue)
        this.onReceive_ModulesSystemInfoGetAllResponse(this._ModulesSystemInfoGetAllResponseValue)
        return true
      }
      case ModulesSystemInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesSystemInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoNotifyModel.verify(), 'manager.ModulesSystemInfoNotify validation failed!')
        let deserialized = this._ModulesSystemInfoNotifyModel.deserialize(this._ModulesSystemInfoNotifyValue)
        console.assert((deserialized.size > 0), 'manager.ModulesSystemInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesSystemInfoNotifyValue)
        this.onReceive_ModulesSystemInfoNotify(this._ModulesSystemInfoNotifyValue)
        return true
      }
      case ChartBarHistoryModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyRequestModel.verify(), 'manager.ChartBarHistoryModifyRequest validation failed!')
        let deserialized = this._ChartBarHistoryModifyRequestModel.deserialize(this._ChartBarHistoryModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarHistoryModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyRequestValue)
        this.onReceive_ChartBarHistoryModifyRequest(this._ChartBarHistoryModifyRequestValue)
        return true
      }
      case ChartBarHistoryModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyResponseModel.verify(), 'manager.ChartBarHistoryModifyResponse validation failed!')
        let deserialized = this._ChartBarHistoryModifyResponseModel.deserialize(this._ChartBarHistoryModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarHistoryModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyResponseValue)
        this.onReceive_ChartBarHistoryModifyResponse(this._ChartBarHistoryModifyResponseValue)
        return true
      }
      case FeedUpdateTickSnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickSnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotRequestModel.verify(), 'manager.FeedUpdateTickSnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateTickSnapshotRequestModel.deserialize(this._FeedUpdateTickSnapshotRequestValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickSnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickSnapshotRequestValue)
        this.onReceive_FeedUpdateTickSnapshotRequest(this._FeedUpdateTickSnapshotRequestValue)
        return true
      }
      case FeedUpdateTickSnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickSnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotResponseModel.verify(), 'manager.FeedUpdateTickSnapshotResponse validation failed!')
        let deserialized = this._FeedUpdateTickSnapshotResponseModel.deserialize(this._FeedUpdateTickSnapshotResponseValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickSnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickSnapshotResponseValue)
        this.onReceive_FeedUpdateTickSnapshotResponse(this._FeedUpdateTickSnapshotResponseValue)
        return true
      }
      case FeedUpdateTickRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickRequestModel.verify(), 'manager.FeedUpdateTickRequest validation failed!')
        let deserialized = this._FeedUpdateTickRequestModel.deserialize(this._FeedUpdateTickRequestValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickRequestValue)
        this.onReceive_FeedUpdateTickRequest(this._FeedUpdateTickRequestValue)
        return true
      }
      case FeedUpdateTickResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickResponseModel.verify(), 'manager.FeedUpdateTickResponse validation failed!')
        let deserialized = this._FeedUpdateTickResponseModel.deserialize(this._FeedUpdateTickResponseValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickResponseValue)
        this.onReceive_FeedUpdateTickResponse(this._FeedUpdateTickResponseValue)
        return true
      }
      case FeedUpdateLevel2SnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotRequestModel.verify(), 'manager.FeedUpdateLevel2SnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotRequestModel.deserialize(this._FeedUpdateLevel2SnapshotRequestValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2SnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotRequestValue)
        this.onReceive_FeedUpdateLevel2SnapshotRequest(this._FeedUpdateLevel2SnapshotRequestValue)
        return true
      }
      case FeedUpdateLevel2SnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotResponseModel.verify(), 'manager.FeedUpdateLevel2SnapshotResponse validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotResponseModel.deserialize(this._FeedUpdateLevel2SnapshotResponseValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2SnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotResponseValue)
        this.onReceive_FeedUpdateLevel2SnapshotResponse(this._FeedUpdateLevel2SnapshotResponseValue)
        return true
      }
      case FeedUpdateLevel2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2RequestModel.verify(), 'manager.FeedUpdateLevel2Request validation failed!')
        let deserialized = this._FeedUpdateLevel2RequestModel.deserialize(this._FeedUpdateLevel2RequestValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2RequestValue)
        this.onReceive_FeedUpdateLevel2Request(this._FeedUpdateLevel2RequestValue)
        return true
      }
      case FeedUpdateLevel2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2ResponseModel.verify(), 'manager.FeedUpdateLevel2Response validation failed!')
        let deserialized = this._FeedUpdateLevel2ResponseModel.deserialize(this._FeedUpdateLevel2ResponseValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2ResponseValue)
        this.onReceive_FeedUpdateLevel2Response(this._FeedUpdateLevel2ResponseValue)
        return true
      }
      case MailServerGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerGetRequestModel.verify(), 'manager.MailServerGetRequest validation failed!')
        let deserialized = this._MailServerGetRequestModel.deserialize(this._MailServerGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.MailServerGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerGetRequestValue)
        this.onReceive_MailServerGetRequest(this._MailServerGetRequestValue)
        return true
      }
      case MailServerGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerGetResponseModel.verify(), 'manager.MailServerGetResponse validation failed!')
        let deserialized = this._MailServerGetResponseModel.deserialize(this._MailServerGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.MailServerGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerGetResponseValue)
        this.onReceive_MailServerGetResponse(this._MailServerGetResponseValue)
        return true
      }
      case MailServersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServersGetAllRequestModel.verify(), 'manager.MailServersGetAllRequest validation failed!')
        let deserialized = this._MailServersGetAllRequestModel.deserialize(this._MailServersGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.MailServersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServersGetAllRequestValue)
        this.onReceive_MailServersGetAllRequest(this._MailServersGetAllRequestValue)
        return true
      }
      case MailServersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServersGetAllResponseModel.verify(), 'manager.MailServersGetAllResponse validation failed!')
        let deserialized = this._MailServersGetAllResponseModel.deserialize(this._MailServersGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.MailServersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServersGetAllResponseValue)
        this.onReceive_MailServersGetAllResponse(this._MailServersGetAllResponseValue)
        return true
      }
      case MailServerCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerCreateRequestModel.verify(), 'manager.MailServerCreateRequest validation failed!')
        let deserialized = this._MailServerCreateRequestModel.deserialize(this._MailServerCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.MailServerCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerCreateRequestValue)
        this.onReceive_MailServerCreateRequest(this._MailServerCreateRequestValue)
        return true
      }
      case MailServerCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerCreateResponseModel.verify(), 'manager.MailServerCreateResponse validation failed!')
        let deserialized = this._MailServerCreateResponseModel.deserialize(this._MailServerCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.MailServerCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerCreateResponseValue)
        this.onReceive_MailServerCreateResponse(this._MailServerCreateResponseValue)
        return true
      }
      case MailServerModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerModifyRequestModel.verify(), 'manager.MailServerModifyRequest validation failed!')
        let deserialized = this._MailServerModifyRequestModel.deserialize(this._MailServerModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.MailServerModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerModifyRequestValue)
        this.onReceive_MailServerModifyRequest(this._MailServerModifyRequestValue)
        return true
      }
      case MailServerModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerModifyResponseModel.verify(), 'manager.MailServerModifyResponse validation failed!')
        let deserialized = this._MailServerModifyResponseModel.deserialize(this._MailServerModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.MailServerModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerModifyResponseValue)
        this.onReceive_MailServerModifyResponse(this._MailServerModifyResponseValue)
        return true
      }
      case MailServerDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerDeleteRequestModel.verify(), 'manager.MailServerDeleteRequest validation failed!')
        let deserialized = this._MailServerDeleteRequestModel.deserialize(this._MailServerDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.MailServerDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerDeleteRequestValue)
        this.onReceive_MailServerDeleteRequest(this._MailServerDeleteRequestValue)
        return true
      }
      case MailServerDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerDeleteResponseModel.verify(), 'manager.MailServerDeleteResponse validation failed!')
        let deserialized = this._MailServerDeleteResponseModel.deserialize(this._MailServerDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.MailServerDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerDeleteResponseValue)
        this.onReceive_MailServerDeleteResponse(this._MailServerDeleteResponseValue)
        return true
      }
      case MailServerUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerUpdateNotifyModel.verify(), 'manager.MailServerUpdateNotify validation failed!')
        let deserialized = this._MailServerUpdateNotifyModel.deserialize(this._MailServerUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.MailServerUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerUpdateNotifyValue)
        this.onReceive_MailServerUpdateNotify(this._MailServerUpdateNotifyValue)
        return true
      }
      case AccountStatesUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountStatesUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesUpdateNotifyModel.verify(), 'manager.AccountStatesUpdateNotify validation failed!')
        let deserialized = this._AccountStatesUpdateNotifyModel.deserialize(this._AccountStatesUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.AccountStatesUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountStatesUpdateNotifyValue)
        this.onReceive_AccountStatesUpdateNotify(this._AccountStatesUpdateNotifyValue)
        return true
      }
      case AccountStatesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountStatesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesGetAllRequestModel.verify(), 'manager.AccountStatesGetAllRequest validation failed!')
        let deserialized = this._AccountStatesGetAllRequestModel.deserialize(this._AccountStatesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountStatesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountStatesGetAllRequestValue)
        this.onReceive_AccountStatesGetAllRequest(this._AccountStatesGetAllRequestValue)
        return true
      }
      case AccountStatesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountStatesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesGetAllResponseModel.verify(), 'manager.AccountStatesGetAllResponse validation failed!')
        let deserialized = this._AccountStatesGetAllResponseModel.deserialize(this._AccountStatesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountStatesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountStatesGetAllResponseValue)
        this.onReceive_AccountStatesGetAllResponse(this._AccountStatesGetAllResponseValue)
        return true
      }
      case ModuleAuthRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleAuthRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleAuthRequestModel.verify(), 'manager.ModuleAuthRequest validation failed!')
        let deserialized = this._ModuleAuthRequestModel.deserialize(this._ModuleAuthRequestValue)
        console.assert((deserialized.size > 0), 'manager.ModuleAuthRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleAuthRequestValue)
        this.onReceive_ModuleAuthRequest(this._ModuleAuthRequestValue)
        return true
      }
      case ModuleAuthResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleAuthResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleAuthResponseModel.verify(), 'manager.ModuleAuthResponse validation failed!')
        let deserialized = this._ModuleAuthResponseModel.deserialize(this._ModuleAuthResponseValue)
        console.assert((deserialized.size > 0), 'manager.ModuleAuthResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleAuthResponseValue)
        this.onReceive_ModuleAuthResponse(this._ModuleAuthResponseValue)
        return true
      }
      case LeverageProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileGetRequestModel.verify(), 'manager.LeverageProfileGetRequest validation failed!')
        let deserialized = this._LeverageProfileGetRequestModel.deserialize(this._LeverageProfileGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileGetRequestValue)
        this.onReceive_LeverageProfileGetRequest(this._LeverageProfileGetRequestValue)
        return true
      }
      case LeverageProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileGetResponseModel.verify(), 'manager.LeverageProfileGetResponse validation failed!')
        let deserialized = this._LeverageProfileGetResponseModel.deserialize(this._LeverageProfileGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileGetResponseValue)
        this.onReceive_LeverageProfileGetResponse(this._LeverageProfileGetResponseValue)
        return true
      }
      case LeverageProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfilesGetAllRequestModel.verify(), 'manager.LeverageProfilesGetAllRequest validation failed!')
        let deserialized = this._LeverageProfilesGetAllRequestModel.deserialize(this._LeverageProfilesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfilesGetAllRequestValue)
        this.onReceive_LeverageProfilesGetAllRequest(this._LeverageProfilesGetAllRequestValue)
        return true
      }
      case LeverageProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfilesGetAllResponseModel.verify(), 'manager.LeverageProfilesGetAllResponse validation failed!')
        let deserialized = this._LeverageProfilesGetAllResponseModel.deserialize(this._LeverageProfilesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfilesGetAllResponseValue)
        this.onReceive_LeverageProfilesGetAllResponse(this._LeverageProfilesGetAllResponseValue)
        return true
      }
      case LeverageProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileCreateRequestModel.verify(), 'manager.LeverageProfileCreateRequest validation failed!')
        let deserialized = this._LeverageProfileCreateRequestModel.deserialize(this._LeverageProfileCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileCreateRequestValue)
        this.onReceive_LeverageProfileCreateRequest(this._LeverageProfileCreateRequestValue)
        return true
      }
      case LeverageProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileCreateResponseModel.verify(), 'manager.LeverageProfileCreateResponse validation failed!')
        let deserialized = this._LeverageProfileCreateResponseModel.deserialize(this._LeverageProfileCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileCreateResponseValue)
        this.onReceive_LeverageProfileCreateResponse(this._LeverageProfileCreateResponseValue)
        return true
      }
      case LeverageProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileModifyRequestModel.verify(), 'manager.LeverageProfileModifyRequest validation failed!')
        let deserialized = this._LeverageProfileModifyRequestModel.deserialize(this._LeverageProfileModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileModifyRequestValue)
        this.onReceive_LeverageProfileModifyRequest(this._LeverageProfileModifyRequestValue)
        return true
      }
      case LeverageProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileModifyResponseModel.verify(), 'manager.LeverageProfileModifyResponse validation failed!')
        let deserialized = this._LeverageProfileModifyResponseModel.deserialize(this._LeverageProfileModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileModifyResponseValue)
        this.onReceive_LeverageProfileModifyResponse(this._LeverageProfileModifyResponseValue)
        return true
      }
      case LeverageProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileDeleteRequestModel.verify(), 'manager.LeverageProfileDeleteRequest validation failed!')
        let deserialized = this._LeverageProfileDeleteRequestModel.deserialize(this._LeverageProfileDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileDeleteRequestValue)
        this.onReceive_LeverageProfileDeleteRequest(this._LeverageProfileDeleteRequestValue)
        return true
      }
      case LeverageProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileDeleteResponseModel.verify(), 'manager.LeverageProfileDeleteResponse validation failed!')
        let deserialized = this._LeverageProfileDeleteResponseModel.deserialize(this._LeverageProfileDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileDeleteResponseValue)
        this.onReceive_LeverageProfileDeleteResponse(this._LeverageProfileDeleteResponseValue)
        return true
      }
      case LeverageProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileUpdateNotifyModel.verify(), 'manager.LeverageProfileUpdateNotify validation failed!')
        let deserialized = this._LeverageProfileUpdateNotifyModel.deserialize(this._LeverageProfileUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileUpdateNotifyValue)
        this.onReceive_LeverageProfileUpdateNotify(this._LeverageProfileUpdateNotifyValue)
        return true
      }
      case MarginRateProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileGetRequestModel.verify(), 'manager.MarginRateProfileGetRequest validation failed!')
        let deserialized = this._MarginRateProfileGetRequestModel.deserialize(this._MarginRateProfileGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileGetRequestValue)
        this.onReceive_MarginRateProfileGetRequest(this._MarginRateProfileGetRequestValue)
        return true
      }
      case MarginRateProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileGetResponseModel.verify(), 'manager.MarginRateProfileGetResponse validation failed!')
        let deserialized = this._MarginRateProfileGetResponseModel.deserialize(this._MarginRateProfileGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileGetResponseValue)
        this.onReceive_MarginRateProfileGetResponse(this._MarginRateProfileGetResponseValue)
        return true
      }
      case MarginRateProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfilesGetAllRequestModel.verify(), 'manager.MarginRateProfilesGetAllRequest validation failed!')
        let deserialized = this._MarginRateProfilesGetAllRequestModel.deserialize(this._MarginRateProfilesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfilesGetAllRequestValue)
        this.onReceive_MarginRateProfilesGetAllRequest(this._MarginRateProfilesGetAllRequestValue)
        return true
      }
      case MarginRateProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfilesGetAllResponseModel.verify(), 'manager.MarginRateProfilesGetAllResponse validation failed!')
        let deserialized = this._MarginRateProfilesGetAllResponseModel.deserialize(this._MarginRateProfilesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfilesGetAllResponseValue)
        this.onReceive_MarginRateProfilesGetAllResponse(this._MarginRateProfilesGetAllResponseValue)
        return true
      }
      case MarginRateProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileCreateRequestModel.verify(), 'manager.MarginRateProfileCreateRequest validation failed!')
        let deserialized = this._MarginRateProfileCreateRequestModel.deserialize(this._MarginRateProfileCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileCreateRequestValue)
        this.onReceive_MarginRateProfileCreateRequest(this._MarginRateProfileCreateRequestValue)
        return true
      }
      case MarginRateProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileCreateResponseModel.verify(), 'manager.MarginRateProfileCreateResponse validation failed!')
        let deserialized = this._MarginRateProfileCreateResponseModel.deserialize(this._MarginRateProfileCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileCreateResponseValue)
        this.onReceive_MarginRateProfileCreateResponse(this._MarginRateProfileCreateResponseValue)
        return true
      }
      case MarginRateProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileModifyRequestModel.verify(), 'manager.MarginRateProfileModifyRequest validation failed!')
        let deserialized = this._MarginRateProfileModifyRequestModel.deserialize(this._MarginRateProfileModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileModifyRequestValue)
        this.onReceive_MarginRateProfileModifyRequest(this._MarginRateProfileModifyRequestValue)
        return true
      }
      case MarginRateProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileModifyResponseModel.verify(), 'manager.MarginRateProfileModifyResponse validation failed!')
        let deserialized = this._MarginRateProfileModifyResponseModel.deserialize(this._MarginRateProfileModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileModifyResponseValue)
        this.onReceive_MarginRateProfileModifyResponse(this._MarginRateProfileModifyResponseValue)
        return true
      }
      case MarginRateProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileDeleteRequestModel.verify(), 'manager.MarginRateProfileDeleteRequest validation failed!')
        let deserialized = this._MarginRateProfileDeleteRequestModel.deserialize(this._MarginRateProfileDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileDeleteRequestValue)
        this.onReceive_MarginRateProfileDeleteRequest(this._MarginRateProfileDeleteRequestValue)
        return true
      }
      case MarginRateProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileDeleteResponseModel.verify(), 'manager.MarginRateProfileDeleteResponse validation failed!')
        let deserialized = this._MarginRateProfileDeleteResponseModel.deserialize(this._MarginRateProfileDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileDeleteResponseValue)
        this.onReceive_MarginRateProfileDeleteResponse(this._MarginRateProfileDeleteResponseValue)
        return true
      }
      case MarginRateProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileUpdateNotifyModel.verify(), 'manager.MarginRateProfileUpdateNotify validation failed!')
        let deserialized = this._MarginRateProfileUpdateNotifyModel.deserialize(this._MarginRateProfileUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileUpdateNotifyValue)
        this.onReceive_MarginRateProfileUpdateNotify(this._MarginRateProfileUpdateNotifyValue)
        return true
      }
      case NewsProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileGetRequestModel.verify(), 'manager.NewsProfileGetRequest validation failed!')
        let deserialized = this._NewsProfileGetRequestModel.deserialize(this._NewsProfileGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileGetRequestValue)
        this.onReceive_NewsProfileGetRequest(this._NewsProfileGetRequestValue)
        return true
      }
      case NewsProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileGetResponseModel.verify(), 'manager.NewsProfileGetResponse validation failed!')
        let deserialized = this._NewsProfileGetResponseModel.deserialize(this._NewsProfileGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileGetResponseValue)
        this.onReceive_NewsProfileGetResponse(this._NewsProfileGetResponseValue)
        return true
      }
      case NewsProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfilesGetAllRequestModel.verify(), 'manager.NewsProfilesGetAllRequest validation failed!')
        let deserialized = this._NewsProfilesGetAllRequestModel.deserialize(this._NewsProfilesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfilesGetAllRequestValue)
        this.onReceive_NewsProfilesGetAllRequest(this._NewsProfilesGetAllRequestValue)
        return true
      }
      case NewsProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfilesGetAllResponseModel.verify(), 'manager.NewsProfilesGetAllResponse validation failed!')
        let deserialized = this._NewsProfilesGetAllResponseModel.deserialize(this._NewsProfilesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfilesGetAllResponseValue)
        this.onReceive_NewsProfilesGetAllResponse(this._NewsProfilesGetAllResponseValue)
        return true
      }
      case NewsProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileCreateRequestModel.verify(), 'manager.NewsProfileCreateRequest validation failed!')
        let deserialized = this._NewsProfileCreateRequestModel.deserialize(this._NewsProfileCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileCreateRequestValue)
        this.onReceive_NewsProfileCreateRequest(this._NewsProfileCreateRequestValue)
        return true
      }
      case NewsProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileCreateResponseModel.verify(), 'manager.NewsProfileCreateResponse validation failed!')
        let deserialized = this._NewsProfileCreateResponseModel.deserialize(this._NewsProfileCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileCreateResponseValue)
        this.onReceive_NewsProfileCreateResponse(this._NewsProfileCreateResponseValue)
        return true
      }
      case NewsProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileModifyRequestModel.verify(), 'manager.NewsProfileModifyRequest validation failed!')
        let deserialized = this._NewsProfileModifyRequestModel.deserialize(this._NewsProfileModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileModifyRequestValue)
        this.onReceive_NewsProfileModifyRequest(this._NewsProfileModifyRequestValue)
        return true
      }
      case NewsProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileModifyResponseModel.verify(), 'manager.NewsProfileModifyResponse validation failed!')
        let deserialized = this._NewsProfileModifyResponseModel.deserialize(this._NewsProfileModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileModifyResponseValue)
        this.onReceive_NewsProfileModifyResponse(this._NewsProfileModifyResponseValue)
        return true
      }
      case NewsProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileDeleteRequestModel.verify(), 'manager.NewsProfileDeleteRequest validation failed!')
        let deserialized = this._NewsProfileDeleteRequestModel.deserialize(this._NewsProfileDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileDeleteRequestValue)
        this.onReceive_NewsProfileDeleteRequest(this._NewsProfileDeleteRequestValue)
        return true
      }
      case NewsProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileDeleteResponseModel.verify(), 'manager.NewsProfileDeleteResponse validation failed!')
        let deserialized = this._NewsProfileDeleteResponseModel.deserialize(this._NewsProfileDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileDeleteResponseValue)
        this.onReceive_NewsProfileDeleteResponse(this._NewsProfileDeleteResponseValue)
        return true
      }
      case NewsProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileUpdateNotifyModel.verify(), 'manager.NewsProfileUpdateNotify validation failed!')
        let deserialized = this._NewsProfileUpdateNotifyModel.deserialize(this._NewsProfileUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileUpdateNotifyValue)
        this.onReceive_NewsProfileUpdateNotify(this._NewsProfileUpdateNotifyValue)
        return true
      }
      case NewsProfileImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileImportRequestModel.verify(), 'manager.NewsProfileImportRequest validation failed!')
        let deserialized = this._NewsProfileImportRequestModel.deserialize(this._NewsProfileImportRequestValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileImportRequestValue)
        this.onReceive_NewsProfileImportRequest(this._NewsProfileImportRequestValue)
        return true
      }
      case NewsProfileImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileImportResponseModel.verify(), 'manager.NewsProfileImportResponse validation failed!')
        let deserialized = this._NewsProfileImportResponseModel.deserialize(this._NewsProfileImportResponseValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileImportResponseValue)
        this.onReceive_NewsProfileImportResponse(this._NewsProfileImportResponseValue)
        return true
      }
      case TickHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryRequestModel.verify(), 'manager.TickHistoryRequest validation failed!')
        let deserialized = this._TickHistoryRequestModel.deserialize(this._TickHistoryRequestValue)
        console.assert((deserialized.size > 0), 'manager.TickHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryRequestValue)
        this.onReceive_TickHistoryRequest(this._TickHistoryRequestValue)
        return true
      }
      case TickHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryResponseModel.verify(), 'manager.TickHistoryResponse validation failed!')
        let deserialized = this._TickHistoryResponseModel.deserialize(this._TickHistoryResponseValue)
        console.assert((deserialized.size > 0), 'manager.TickHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryResponseValue)
        this.onReceive_TickHistoryResponse(this._TickHistoryResponseValue)
        return true
      }
      case EmailTemplateGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateGetRequestModel.verify(), 'manager.EmailTemplateGetRequest validation failed!')
        let deserialized = this._EmailTemplateGetRequestModel.deserialize(this._EmailTemplateGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateGetRequestValue)
        this.onReceive_EmailTemplateGetRequest(this._EmailTemplateGetRequestValue)
        return true
      }
      case EmailTemplateGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateGetResponseModel.verify(), 'manager.EmailTemplateGetResponse validation failed!')
        let deserialized = this._EmailTemplateGetResponseModel.deserialize(this._EmailTemplateGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateGetResponseValue)
        this.onReceive_EmailTemplateGetResponse(this._EmailTemplateGetResponseValue)
        return true
      }
      case EmailTemplatesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplatesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplatesGetAllRequestModel.verify(), 'manager.EmailTemplatesGetAllRequest validation failed!')
        let deserialized = this._EmailTemplatesGetAllRequestModel.deserialize(this._EmailTemplatesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplatesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplatesGetAllRequestValue)
        this.onReceive_EmailTemplatesGetAllRequest(this._EmailTemplatesGetAllRequestValue)
        return true
      }
      case EmailTemplatesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplatesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplatesGetAllResponseModel.verify(), 'manager.EmailTemplatesGetAllResponse validation failed!')
        let deserialized = this._EmailTemplatesGetAllResponseModel.deserialize(this._EmailTemplatesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplatesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplatesGetAllResponseValue)
        this.onReceive_EmailTemplatesGetAllResponse(this._EmailTemplatesGetAllResponseValue)
        return true
      }
      case EmailTemplateCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateCreateRequestModel.verify(), 'manager.EmailTemplateCreateRequest validation failed!')
        let deserialized = this._EmailTemplateCreateRequestModel.deserialize(this._EmailTemplateCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateCreateRequestValue)
        this.onReceive_EmailTemplateCreateRequest(this._EmailTemplateCreateRequestValue)
        return true
      }
      case EmailTemplateCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateCreateResponseModel.verify(), 'manager.EmailTemplateCreateResponse validation failed!')
        let deserialized = this._EmailTemplateCreateResponseModel.deserialize(this._EmailTemplateCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateCreateResponseValue)
        this.onReceive_EmailTemplateCreateResponse(this._EmailTemplateCreateResponseValue)
        return true
      }
      case EmailTemplateModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateModifyRequestModel.verify(), 'manager.EmailTemplateModifyRequest validation failed!')
        let deserialized = this._EmailTemplateModifyRequestModel.deserialize(this._EmailTemplateModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateModifyRequestValue)
        this.onReceive_EmailTemplateModifyRequest(this._EmailTemplateModifyRequestValue)
        return true
      }
      case EmailTemplateModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateModifyResponseModel.verify(), 'manager.EmailTemplateModifyResponse validation failed!')
        let deserialized = this._EmailTemplateModifyResponseModel.deserialize(this._EmailTemplateModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateModifyResponseValue)
        this.onReceive_EmailTemplateModifyResponse(this._EmailTemplateModifyResponseValue)
        return true
      }
      case EmailTemplateDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateDeleteRequestModel.verify(), 'manager.EmailTemplateDeleteRequest validation failed!')
        let deserialized = this._EmailTemplateDeleteRequestModel.deserialize(this._EmailTemplateDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateDeleteRequestValue)
        this.onReceive_EmailTemplateDeleteRequest(this._EmailTemplateDeleteRequestValue)
        return true
      }
      case EmailTemplateDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateDeleteResponseModel.verify(), 'manager.EmailTemplateDeleteResponse validation failed!')
        let deserialized = this._EmailTemplateDeleteResponseModel.deserialize(this._EmailTemplateDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateDeleteResponseValue)
        this.onReceive_EmailTemplateDeleteResponse(this._EmailTemplateDeleteResponseValue)
        return true
      }
      case EmailTemplateUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateUpdateNotifyModel.verify(), 'manager.EmailTemplateUpdateNotify validation failed!')
        let deserialized = this._EmailTemplateUpdateNotifyModel.deserialize(this._EmailTemplateUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateUpdateNotifyValue)
        this.onReceive_EmailTemplateUpdateNotify(this._EmailTemplateUpdateNotifyValue)
        return true
      }
      case OrderStateSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateSubscribeRequestModel.verify(), 'manager.OrderStateSubscribeRequest validation failed!')
        let deserialized = this._OrderStateSubscribeRequestModel.deserialize(this._OrderStateSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateSubscribeRequestValue)
        this.onReceive_OrderStateSubscribeRequest(this._OrderStateSubscribeRequestValue)
        return true
      }
      case OrderStateSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateSubscribeResponseModel.verify(), 'manager.OrderStateSubscribeResponse validation failed!')
        let deserialized = this._OrderStateSubscribeResponseModel.deserialize(this._OrderStateSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateSubscribeResponseValue)
        this.onReceive_OrderStateSubscribeResponse(this._OrderStateSubscribeResponseValue)
        return true
      }
      case OrderStateUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUpdateSubscriptionRequestModel.verify(), 'manager.OrderStateUpdateSubscriptionRequest validation failed!')
        let deserialized = this._OrderStateUpdateSubscriptionRequestModel.deserialize(this._OrderStateUpdateSubscriptionRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUpdateSubscriptionRequestValue)
        this.onReceive_OrderStateUpdateSubscriptionRequest(this._OrderStateUpdateSubscriptionRequestValue)
        return true
      }
      case OrderStateUpdateSubscriptionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUpdateSubscriptionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUpdateSubscriptionResponseModel.verify(), 'manager.OrderStateUpdateSubscriptionResponse validation failed!')
        let deserialized = this._OrderStateUpdateSubscriptionResponseModel.deserialize(this._OrderStateUpdateSubscriptionResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUpdateSubscriptionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUpdateSubscriptionResponseValue)
        this.onReceive_OrderStateUpdateSubscriptionResponse(this._OrderStateUpdateSubscriptionResponseValue)
        return true
      }
      case OrderStateUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUnsubscribeRequestModel.verify(), 'manager.OrderStateUnsubscribeRequest validation failed!')
        let deserialized = this._OrderStateUnsubscribeRequestModel.deserialize(this._OrderStateUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUnsubscribeRequestValue)
        this.onReceive_OrderStateUnsubscribeRequest(this._OrderStateUnsubscribeRequestValue)
        return true
      }
      case OrderStateUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUnsubscribeResponseModel.verify(), 'manager.OrderStateUnsubscribeResponse validation failed!')
        let deserialized = this._OrderStateUnsubscribeResponseModel.deserialize(this._OrderStateUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUnsubscribeResponseValue)
        this.onReceive_OrderStateUnsubscribeResponse(this._OrderStateUnsubscribeResponseValue)
        return true
      }
      case OrderStateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateNotifyModel.verify(), 'manager.OrderStateNotify validation failed!')
        let deserialized = this._OrderStateNotifyModel.deserialize(this._OrderStateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateNotifyValue)
        this.onReceive_OrderStateNotify(this._OrderStateNotifyValue)
        return true
      }
      case RequestMonitoringSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringSubscribeRequestModel.verify(), 'manager.RequestMonitoringSubscribeRequest validation failed!')
        let deserialized = this._RequestMonitoringSubscribeRequestModel.deserialize(this._RequestMonitoringSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringSubscribeRequestValue)
        this.onReceive_RequestMonitoringSubscribeRequest(this._RequestMonitoringSubscribeRequestValue)
        return true
      }
      case RequestMonitoringSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringSubscribeResponseModel.verify(), 'manager.RequestMonitoringSubscribeResponse validation failed!')
        let deserialized = this._RequestMonitoringSubscribeResponseModel.deserialize(this._RequestMonitoringSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringSubscribeResponseValue)
        this.onReceive_RequestMonitoringSubscribeResponse(this._RequestMonitoringSubscribeResponseValue)
        return true
      }
      case RequestMonitoringUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringUnsubscribeRequestModel.verify(), 'manager.RequestMonitoringUnsubscribeRequest validation failed!')
        let deserialized = this._RequestMonitoringUnsubscribeRequestModel.deserialize(this._RequestMonitoringUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringUnsubscribeRequestValue)
        this.onReceive_RequestMonitoringUnsubscribeRequest(this._RequestMonitoringUnsubscribeRequestValue)
        return true
      }
      case RequestMonitoringUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringUnsubscribeResponseModel.verify(), 'manager.RequestMonitoringUnsubscribeResponse validation failed!')
        let deserialized = this._RequestMonitoringUnsubscribeResponseModel.deserialize(this._RequestMonitoringUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringUnsubscribeResponseValue)
        this.onReceive_RequestMonitoringUnsubscribeResponse(this._RequestMonitoringUnsubscribeResponseValue)
        return true
      }
      case RequestMonitoringRequestUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringRequestUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringRequestUpdateNotifyModel.verify(), 'manager.RequestMonitoringRequestUpdateNotify validation failed!')
        let deserialized = this._RequestMonitoringRequestUpdateNotifyModel.deserialize(this._RequestMonitoringRequestUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringRequestUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringRequestUpdateNotifyValue)
        this.onReceive_RequestMonitoringRequestUpdateNotify(this._RequestMonitoringRequestUpdateNotifyValue)
        return true
      }
      case FilledOrdersSummaryInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoSubscribeRequestModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeRequest validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoSubscribeRequestModel.deserialize(this._FilledOrdersSummaryInfoSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoSubscribeRequestValue)
        this.onReceive_FilledOrdersSummaryInfoSubscribeRequest(this._FilledOrdersSummaryInfoSubscribeRequestValue)
        return true
      }
      case FilledOrdersSummaryInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoSubscribeResponseModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeResponse validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoSubscribeResponseModel.deserialize(this._FilledOrdersSummaryInfoSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoSubscribeResponseValue)
        this.onReceive_FilledOrdersSummaryInfoSubscribeResponse(this._FilledOrdersSummaryInfoSubscribeResponseValue)
        return true
      }
      case FilledOrdersSummaryInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoNotifyModel.verify(), 'manager.FilledOrdersSummaryInfoNotify validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoNotifyModel.deserialize(this._FilledOrdersSummaryInfoNotifyValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoNotifyValue)
        this.onReceive_FilledOrdersSummaryInfoNotify(this._FilledOrdersSummaryInfoNotifyValue)
        return true
      }
      case FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.verify(), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.deserialize(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestValue)
        this.onReceive_FilledOrdersSummaryInfoUpdateSubscriptionRequest(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestValue)
        return true
      }
      case FilledOrdersSummaryInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUnsubscribeRequestModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoUnsubscribeRequestModel.deserialize(this._FilledOrdersSummaryInfoUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoUnsubscribeRequestValue)
        this.onReceive_FilledOrdersSummaryInfoUnsubscribeRequest(this._FilledOrdersSummaryInfoUnsubscribeRequestValue)
        return true
      }
      case FilledOrdersSummaryInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUnsubscribeResponseModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoUnsubscribeResponseModel.deserialize(this._FilledOrdersSummaryInfoUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoUnsubscribeResponseValue)
        this.onReceive_FilledOrdersSummaryInfoUnsubscribeResponse(this._FilledOrdersSummaryInfoUnsubscribeResponseValue)
        return true
      }
      case TradeEventsNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsNotifyModel.verify(), 'manager.TradeEventsNotify validation failed!')
        let deserialized = this._TradeEventsNotifyModel.deserialize(this._TradeEventsNotifyValue)
        console.assert((deserialized.size > 0), 'manager.TradeEventsNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsNotifyValue)
        this.onReceive_TradeEventsNotify(this._TradeEventsNotifyValue)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestModel.verify(), 'manager.TradeEventsGetRequest validation failed!')
        let deserialized = this._TradeEventsGetRequestModel.deserialize(this._TradeEventsGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.TradeEventsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetRequestValue)
        this.onReceive_TradeEventsGetRequest(this._TradeEventsGetRequestValue)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseModel.verify(), 'manager.TradeEventsGetResponse validation failed!')
        let deserialized = this._TradeEventsGetResponseModel.deserialize(this._TradeEventsGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.TradeEventsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetResponseValue)
        this.onReceive_TradeEventsGetResponse(this._TradeEventsGetResponseValue)
        return true
      }
      case JournalRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalRequestModel.attachBuffer(buffer, offset)
        console.assert(this._JournalRequestModel.verify(), 'manager.JournalRequest validation failed!')
        let deserialized = this._JournalRequestModel.deserialize(this._JournalRequestValue)
        console.assert((deserialized.size > 0), 'manager.JournalRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalRequestValue)
        this.onReceive_JournalRequest(this._JournalRequestValue)
        return true
      }
      case JournalResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalResponseModel.attachBuffer(buffer, offset)
        console.assert(this._JournalResponseModel.verify(), 'manager.JournalResponse validation failed!')
        let deserialized = this._JournalResponseModel.deserialize(this._JournalResponseValue)
        console.assert((deserialized.size > 0), 'manager.JournalResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalResponseValue)
        this.onReceive_JournalResponse(this._JournalResponseValue)
        return true
      }
      case ClientReportsListGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetRequestModel.verify(), 'manager.ClientReportsListGetRequest validation failed!')
        let deserialized = this._ClientReportsListGetRequestModel.deserialize(this._ClientReportsListGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsListGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetRequestValue)
        this.onReceive_ClientReportsListGetRequest(this._ClientReportsListGetRequestValue)
        return true
      }
      case ClientReportsListGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetResponseModel.verify(), 'manager.ClientReportsListGetResponse validation failed!')
        let deserialized = this._ClientReportsListGetResponseModel.deserialize(this._ClientReportsListGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsListGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetResponseValue)
        this.onReceive_ClientReportsListGetResponse(this._ClientReportsListGetResponseValue)
        return true
      }
      case ClientReportGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetRequestModel.verify(), 'manager.ClientReportGetRequest validation failed!')
        let deserialized = this._ClientReportGetRequestModel.deserialize(this._ClientReportGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetRequestValue)
        this.onReceive_ClientReportGetRequest(this._ClientReportGetRequestValue)
        return true
      }
      case ClientReportGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetResponseModel.verify(), 'manager.ClientReportGetResponse validation failed!')
        let deserialized = this._ClientReportGetResponseModel.deserialize(this._ClientReportGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetResponseValue)
        this.onReceive_ClientReportGetResponse(this._ClientReportGetResponseValue)
        return true
      }
      case ClientReportsSendRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendRequestModel.verify(), 'manager.ClientReportsSendRequest validation failed!')
        let deserialized = this._ClientReportsSendRequestModel.deserialize(this._ClientReportsSendRequestValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsSendRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendRequestValue)
        this.onReceive_ClientReportsSendRequest(this._ClientReportsSendRequestValue)
        return true
      }
      case ClientReportsSendResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendResponseModel.verify(), 'manager.ClientReportsSendResponse validation failed!')
        let deserialized = this._ClientReportsSendResponseModel.deserialize(this._ClientReportsSendResponseValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsSendResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendResponseValue)
        this.onReceive_ClientReportsSendResponse(this._ClientReportsSendResponseValue)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestModel.verify(), 'manager.ProfitClosedGetRequest validation failed!')
        let deserialized = this._ProfitClosedGetRequestModel.deserialize(this._ProfitClosedGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ProfitClosedGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetRequestValue)
        this.onReceive_ProfitClosedGetRequest(this._ProfitClosedGetRequestValue)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseModel.verify(), 'manager.ProfitClosedGetResponse validation failed!')
        let deserialized = this._ProfitClosedGetResponseModel.deserialize(this._ProfitClosedGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ProfitClosedGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetResponseValue)
        this.onReceive_ProfitClosedGetResponse(this._ProfitClosedGetResponseValue)
        return true
      }
      case ChartBarsImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportRequestModel.verify(), 'manager.ChartBarsImportRequest validation failed!')
        let deserialized = this._ChartBarsImportRequestModel.deserialize(this._ChartBarsImportRequestValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarsImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportRequestValue)
        this.onReceive_ChartBarsImportRequest(this._ChartBarsImportRequestValue)
        return true
      }
      case ChartBarsImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportResponseModel.verify(), 'manager.ChartBarsImportResponse validation failed!')
        let deserialized = this._ChartBarsImportResponseModel.deserialize(this._ChartBarsImportResponseValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarsImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportResponseValue)
        this.onReceive_ChartBarsImportResponse(this._ChartBarsImportResponseValue)
        return true
      }
      case ChartBarsImportNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportNotifyModel.verify(), 'manager.ChartBarsImportNotify validation failed!')
        let deserialized = this._ChartBarsImportNotifyModel.deserialize(this._ChartBarsImportNotifyValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarsImportNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportNotifyValue)
        this.onReceive_ChartBarsImportNotify(this._ChartBarsImportNotifyValue)
        return true
      }
      case JournalBusinessRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalBusinessRequestModel.attachBuffer(buffer, offset)
        console.assert(this._JournalBusinessRequestModel.verify(), 'manager.JournalBusinessRequest validation failed!')
        let deserialized = this._JournalBusinessRequestModel.deserialize(this._JournalBusinessRequestValue)
        console.assert((deserialized.size > 0), 'manager.JournalBusinessRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalBusinessRequestValue)
        this.onReceive_JournalBusinessRequest(this._JournalBusinessRequestValue)
        return true
      }
      case JournalBusinessResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalBusinessResponseModel.attachBuffer(buffer, offset)
        console.assert(this._JournalBusinessResponseModel.verify(), 'manager.JournalBusinessResponse validation failed!')
        let deserialized = this._JournalBusinessResponseModel.deserialize(this._JournalBusinessResponseValue)
        console.assert((deserialized.size > 0), 'manager.JournalBusinessResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalBusinessResponseValue)
        this.onReceive_JournalBusinessResponse(this._JournalBusinessResponseValue)
        return true
      }
      case AccountsGetPageRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetPageRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetPageRequestModel.verify(), 'manager.AccountsGetPageRequest validation failed!')
        let deserialized = this._AccountsGetPageRequestModel.deserialize(this._AccountsGetPageRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetPageRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetPageRequestValue)
        this.onReceive_AccountsGetPageRequest(this._AccountsGetPageRequestValue)
        return true
      }
      case AccountsGetPageResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetPageResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetPageResponseModel.verify(), 'manager.AccountsGetPageResponse validation failed!')
        let deserialized = this._AccountsGetPageResponseModel.deserialize(this._AccountsGetPageResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetPageResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetPageResponseValue)
        this.onReceive_AccountsGetPageResponse(this._AccountsGetPageResponseValue)
        return true
      }
      case HeartbeatNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HeartbeatNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._HeartbeatNotifyModel.verify(), 'manager.HeartbeatNotify validation failed!')
        let deserialized = this._HeartbeatNotifyModel.deserialize(this._HeartbeatNotifyValue)
        console.assert((deserialized.size > 0), 'manager.HeartbeatNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HeartbeatNotifyValue)
        this.onReceive_HeartbeatNotify(this._HeartbeatNotifyValue)
        return true
      }
      case OrdersClosedSummariesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetRequestModel.verify(), 'manager.OrdersClosedSummariesGetRequest validation failed!')
        let deserialized = this._OrdersClosedSummariesGetRequestModel.deserialize(this._OrdersClosedSummariesGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrdersClosedSummariesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetRequestValue)
        this.onReceive_OrdersClosedSummariesGetRequest(this._OrdersClosedSummariesGetRequestValue)
        return true
      }
      case OrdersClosedSummariesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetResponseModel.verify(), 'manager.OrdersClosedSummariesGetResponse validation failed!')
        let deserialized = this._OrdersClosedSummariesGetResponseModel.deserialize(this._OrdersClosedSummariesGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrdersClosedSummariesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetResponseValue)
        this.onReceive_OrdersClosedSummariesGetResponse(this._OrdersClosedSummariesGetResponseValue)
        return true
      }
      case OrdersGetPageRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetPageRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetPageRequestModel.verify(), 'manager.OrdersGetPageRequest validation failed!')
        let deserialized = this._OrdersGetPageRequestModel.deserialize(this._OrdersGetPageRequestValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetPageRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetPageRequestValue)
        this.onReceive_OrdersGetPageRequest(this._OrdersGetPageRequestValue)
        return true
      }
      case OrdersGetPageResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetPageResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetPageResponseModel.verify(), 'manager.OrdersGetPageResponse validation failed!')
        let deserialized = this._OrdersGetPageResponseModel.deserialize(this._OrdersGetPageResponseValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetPageResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetPageResponseValue)
        this.onReceive_OrdersGetPageResponse(this._OrdersGetPageResponseValue)
        return true
      }
      case StorageUniversalNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageUniversalNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StorageUniversalNotifyModel.verify(), 'manager.StorageUniversalNotify validation failed!')
        let deserialized = this._StorageUniversalNotifyModel.deserialize(this._StorageUniversalNotifyValue)
        console.assert((deserialized.size > 0), 'manager.StorageUniversalNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageUniversalNotifyValue)
        this.onReceive_StorageUniversalNotify(this._StorageUniversalNotifyValue)
        return true
      }
      case ClientLoginInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetRequestModel.verify(), 'manager.ClientLoginInfoGetRequest validation failed!')
        let deserialized = this._ClientLoginInfoGetRequestModel.deserialize(this._ClientLoginInfoGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.ClientLoginInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetRequestValue)
        this.onReceive_ClientLoginInfoGetRequest(this._ClientLoginInfoGetRequestValue)
        return true
      }
      case ClientLoginInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetResponseModel.verify(), 'manager.ClientLoginInfoGetResponse validation failed!')
        let deserialized = this._ClientLoginInfoGetResponseModel.deserialize(this._ClientLoginInfoGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.ClientLoginInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetResponseValue)
        this.onReceive_ClientLoginInfoGetResponse(this._ClientLoginInfoGetResponseValue)
        return true
      }
      case AccountGroupExistsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupExistsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupExistsGetRequestModel.verify(), 'manager.AccountGroupExistsGetRequest validation failed!')
        let deserialized = this._AccountGroupExistsGetRequestModel.deserialize(this._AccountGroupExistsGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupExistsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupExistsGetRequestValue)
        this.onReceive_AccountGroupExistsGetRequest(this._AccountGroupExistsGetRequestValue)
        return true
      }
      case AccountGroupExistsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupExistsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupExistsGetResponseModel.verify(), 'manager.AccountGroupExistsGetResponse validation failed!')
        let deserialized = this._AccountGroupExistsGetResponseModel.deserialize(this._AccountGroupExistsGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupExistsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupExistsGetResponseValue)
        this.onReceive_AccountGroupExistsGetResponse(this._AccountGroupExistsGetResponseValue)
        return true
      }
      case LoginWithPasswordRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordRequestModel.verify(), 'manager.LoginWithPasswordRequest validation failed!')
        let deserialized = this._LoginWithPasswordRequestModel.deserialize(this._LoginWithPasswordRequestValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithPasswordRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordRequestValue)
        this.onReceive_LoginWithPasswordRequest(this._LoginWithPasswordRequestValue)
        return true
      }
      case LoginWithPasswordResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordResponseModel.verify(), 'manager.LoginWithPasswordResponse validation failed!')
        let deserialized = this._LoginWithPasswordResponseModel.deserialize(this._LoginWithPasswordResponseValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithPasswordResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordResponseValue)
        this.onReceive_LoginWithPasswordResponse(this._LoginWithPasswordResponseValue)
        return true
      }
      case LoginWithTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenRequestModel.verify(), 'manager.LoginWithTokenRequest validation failed!')
        let deserialized = this._LoginWithTokenRequestModel.deserialize(this._LoginWithTokenRequestValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenRequestValue)
        this.onReceive_LoginWithTokenRequest(this._LoginWithTokenRequestValue)
        return true
      }
      case LoginWithTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenResponseModel.verify(), 'manager.LoginWithTokenResponse validation failed!')
        let deserialized = this._LoginWithTokenResponseModel.deserialize(this._LoginWithTokenResponseValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenResponseValue)
        this.onReceive_LoginWithTokenResponse(this._LoginWithTokenResponseValue)
        return true
      }
      case RefreshTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenRequestModel.verify(), 'manager.RefreshTokenRequest validation failed!')
        let deserialized = this._RefreshTokenRequestModel.deserialize(this._RefreshTokenRequestValue)
        console.assert((deserialized.size > 0), 'manager.RefreshTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenRequestValue)
        this.onReceive_RefreshTokenRequest(this._RefreshTokenRequestValue)
        return true
      }
      case RefreshTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenResponseModel.verify(), 'manager.RefreshTokenResponse validation failed!')
        let deserialized = this._RefreshTokenResponseModel.deserialize(this._RefreshTokenResponseValue)
        console.assert((deserialized.size > 0), 'manager.RefreshTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenResponseValue)
        this.onReceive_RefreshTokenResponse(this._RefreshTokenResponseValue)
        return true
      }
      case PersonGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonGetRequestModel.verify(), 'manager.PersonGetRequest validation failed!')
        let deserialized = this._PersonGetRequestModel.deserialize(this._PersonGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.PersonGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonGetRequestValue)
        this.onReceive_PersonGetRequest(this._PersonGetRequestValue)
        return true
      }
      case PersonGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonGetResponseModel.verify(), 'manager.PersonGetResponse validation failed!')
        let deserialized = this._PersonGetResponseModel.deserialize(this._PersonGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.PersonGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonGetResponseValue)
        this.onReceive_PersonGetResponse(this._PersonGetResponseValue)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestModel.verify(), 'manager.PersonsGetAllRequest validation failed!')
        let deserialized = this._PersonsGetAllRequestModel.deserialize(this._PersonsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.PersonsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllRequestValue)
        this.onReceive_PersonsGetAllRequest(this._PersonsGetAllRequestValue)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseModel.verify(), 'manager.PersonsGetAllResponse validation failed!')
        let deserialized = this._PersonsGetAllResponseModel.deserialize(this._PersonsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.PersonsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllResponseValue)
        this.onReceive_PersonsGetAllResponse(this._PersonsGetAllResponseValue)
        return true
      }
      case PersonCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonCreateRequestModel.verify(), 'manager.PersonCreateRequest validation failed!')
        let deserialized = this._PersonCreateRequestModel.deserialize(this._PersonCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.PersonCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonCreateRequestValue)
        this.onReceive_PersonCreateRequest(this._PersonCreateRequestValue)
        return true
      }
      case PersonCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonCreateResponseModel.verify(), 'manager.PersonCreateResponse validation failed!')
        let deserialized = this._PersonCreateResponseModel.deserialize(this._PersonCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.PersonCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonCreateResponseValue)
        this.onReceive_PersonCreateResponse(this._PersonCreateResponseValue)
        return true
      }
      case PersonModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonModifyRequestModel.verify(), 'manager.PersonModifyRequest validation failed!')
        let deserialized = this._PersonModifyRequestModel.deserialize(this._PersonModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.PersonModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonModifyRequestValue)
        this.onReceive_PersonModifyRequest(this._PersonModifyRequestValue)
        return true
      }
      case PersonModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonModifyResponseModel.verify(), 'manager.PersonModifyResponse validation failed!')
        let deserialized = this._PersonModifyResponseModel.deserialize(this._PersonModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.PersonModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonModifyResponseValue)
        this.onReceive_PersonModifyResponse(this._PersonModifyResponseValue)
        return true
      }
      case PersonUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateNotifyModel.verify(), 'manager.PersonUpdateNotify validation failed!')
        let deserialized = this._PersonUpdateNotifyModel.deserialize(this._PersonUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.PersonUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateNotifyValue)
        this.onReceive_PersonUpdateNotify(this._PersonUpdateNotifyValue)
        return true
      }
      case SecuritySettingsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetRequestModel.verify(), 'manager.SecuritySettingsGetRequest validation failed!')
        let deserialized = this._SecuritySettingsGetRequestModel.deserialize(this._SecuritySettingsGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetRequestValue)
        this.onReceive_SecuritySettingsGetRequest(this._SecuritySettingsGetRequestValue)
        return true
      }
      case SecuritySettingsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetResponseModel.verify(), 'manager.SecuritySettingsGetResponse validation failed!')
        let deserialized = this._SecuritySettingsGetResponseModel.deserialize(this._SecuritySettingsGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetResponseValue)
        this.onReceive_SecuritySettingsGetResponse(this._SecuritySettingsGetResponseValue)
        return true
      }
      case SecuritySettingsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetAllRequestModel.verify(), 'manager.SecuritySettingsGetAllRequest validation failed!')
        let deserialized = this._SecuritySettingsGetAllRequestModel.deserialize(this._SecuritySettingsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetAllRequestValue)
        this.onReceive_SecuritySettingsGetAllRequest(this._SecuritySettingsGetAllRequestValue)
        return true
      }
      case SecuritySettingsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetAllResponseModel.verify(), 'manager.SecuritySettingsGetAllResponse validation failed!')
        let deserialized = this._SecuritySettingsGetAllResponseModel.deserialize(this._SecuritySettingsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetAllResponseValue)
        this.onReceive_SecuritySettingsGetAllResponse(this._SecuritySettingsGetAllResponseValue)
        return true
      }
      case SecuritySettingsCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsCreateRequestModel.verify(), 'manager.SecuritySettingsCreateRequest validation failed!')
        let deserialized = this._SecuritySettingsCreateRequestModel.deserialize(this._SecuritySettingsCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsCreateRequestValue)
        this.onReceive_SecuritySettingsCreateRequest(this._SecuritySettingsCreateRequestValue)
        return true
      }
      case SecuritySettingsCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsCreateResponseModel.verify(), 'manager.SecuritySettingsCreateResponse validation failed!')
        let deserialized = this._SecuritySettingsCreateResponseModel.deserialize(this._SecuritySettingsCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsCreateResponseValue)
        this.onReceive_SecuritySettingsCreateResponse(this._SecuritySettingsCreateResponseValue)
        return true
      }
      case SecuritySettingsModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsModifyRequestModel.verify(), 'manager.SecuritySettingsModifyRequest validation failed!')
        let deserialized = this._SecuritySettingsModifyRequestModel.deserialize(this._SecuritySettingsModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsModifyRequestValue)
        this.onReceive_SecuritySettingsModifyRequest(this._SecuritySettingsModifyRequestValue)
        return true
      }
      case SecuritySettingsModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsModifyResponseModel.verify(), 'manager.SecuritySettingsModifyResponse validation failed!')
        let deserialized = this._SecuritySettingsModifyResponseModel.deserialize(this._SecuritySettingsModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsModifyResponseValue)
        this.onReceive_SecuritySettingsModifyResponse(this._SecuritySettingsModifyResponseValue)
        return true
      }
      case SecuritySettingsDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsDeleteRequestModel.verify(), 'manager.SecuritySettingsDeleteRequest validation failed!')
        let deserialized = this._SecuritySettingsDeleteRequestModel.deserialize(this._SecuritySettingsDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsDeleteRequestValue)
        this.onReceive_SecuritySettingsDeleteRequest(this._SecuritySettingsDeleteRequestValue)
        return true
      }
      case SecuritySettingsDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsDeleteResponseModel.verify(), 'manager.SecuritySettingsDeleteResponse validation failed!')
        let deserialized = this._SecuritySettingsDeleteResponseModel.deserialize(this._SecuritySettingsDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsDeleteResponseValue)
        this.onReceive_SecuritySettingsDeleteResponse(this._SecuritySettingsDeleteResponseValue)
        return true
      }
      case SecuritySettingsUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsUpdateNotifyModel.verify(), 'manager.SecuritySettingsUpdateNotify validation failed!')
        let deserialized = this._SecuritySettingsUpdateNotifyModel.deserialize(this._SecuritySettingsUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsUpdateNotifyValue)
        this.onReceive_SecuritySettingsUpdateNotify(this._SecuritySettingsUpdateNotifyValue)
        return true
      }
      case FirewallRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleGetRequestModel.verify(), 'manager.FirewallRuleGetRequest validation failed!')
        let deserialized = this._FirewallRuleGetRequestModel.deserialize(this._FirewallRuleGetRequestValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleGetRequestValue)
        this.onReceive_FirewallRuleGetRequest(this._FirewallRuleGetRequestValue)
        return true
      }
      case FirewallRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleGetResponseModel.verify(), 'manager.FirewallRuleGetResponse validation failed!')
        let deserialized = this._FirewallRuleGetResponseModel.deserialize(this._FirewallRuleGetResponseValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleGetResponseValue)
        this.onReceive_FirewallRuleGetResponse(this._FirewallRuleGetResponseValue)
        return true
      }
      case FirewallRulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRulesGetAllRequestModel.verify(), 'manager.FirewallRulesGetAllRequest validation failed!')
        let deserialized = this._FirewallRulesGetAllRequestModel.deserialize(this._FirewallRulesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRulesGetAllRequestValue)
        this.onReceive_FirewallRulesGetAllRequest(this._FirewallRulesGetAllRequestValue)
        return true
      }
      case FirewallRulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRulesGetAllResponseModel.verify(), 'manager.FirewallRulesGetAllResponse validation failed!')
        let deserialized = this._FirewallRulesGetAllResponseModel.deserialize(this._FirewallRulesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRulesGetAllResponseValue)
        this.onReceive_FirewallRulesGetAllResponse(this._FirewallRulesGetAllResponseValue)
        return true
      }
      case FirewallRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleCreateRequestModel.verify(), 'manager.FirewallRuleCreateRequest validation failed!')
        let deserialized = this._FirewallRuleCreateRequestModel.deserialize(this._FirewallRuleCreateRequestValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleCreateRequestValue)
        this.onReceive_FirewallRuleCreateRequest(this._FirewallRuleCreateRequestValue)
        return true
      }
      case FirewallRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleCreateResponseModel.verify(), 'manager.FirewallRuleCreateResponse validation failed!')
        let deserialized = this._FirewallRuleCreateResponseModel.deserialize(this._FirewallRuleCreateResponseValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleCreateResponseValue)
        this.onReceive_FirewallRuleCreateResponse(this._FirewallRuleCreateResponseValue)
        return true
      }
      case FirewallRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleModifyRequestModel.verify(), 'manager.FirewallRuleModifyRequest validation failed!')
        let deserialized = this._FirewallRuleModifyRequestModel.deserialize(this._FirewallRuleModifyRequestValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleModifyRequestValue)
        this.onReceive_FirewallRuleModifyRequest(this._FirewallRuleModifyRequestValue)
        return true
      }
      case FirewallRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleModifyResponseModel.verify(), 'manager.FirewallRuleModifyResponse validation failed!')
        let deserialized = this._FirewallRuleModifyResponseModel.deserialize(this._FirewallRuleModifyResponseValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleModifyResponseValue)
        this.onReceive_FirewallRuleModifyResponse(this._FirewallRuleModifyResponseValue)
        return true
      }
      case FirewallRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleDeleteRequestModel.verify(), 'manager.FirewallRuleDeleteRequest validation failed!')
        let deserialized = this._FirewallRuleDeleteRequestModel.deserialize(this._FirewallRuleDeleteRequestValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleDeleteRequestValue)
        this.onReceive_FirewallRuleDeleteRequest(this._FirewallRuleDeleteRequestValue)
        return true
      }
      case FirewallRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleDeleteResponseModel.verify(), 'manager.FirewallRuleDeleteResponse validation failed!')
        let deserialized = this._FirewallRuleDeleteResponseModel.deserialize(this._FirewallRuleDeleteResponseValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleDeleteResponseValue)
        this.onReceive_FirewallRuleDeleteResponse(this._FirewallRuleDeleteResponseValue)
        return true
      }
      case FirewallRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleUpdateNotifyModel.verify(), 'manager.FirewallRuleUpdateNotify validation failed!')
        let deserialized = this._FirewallRuleUpdateNotifyModel.deserialize(this._FirewallRuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleUpdateNotifyValue)
        this.onReceive_FirewallRuleUpdateNotify(this._FirewallRuleUpdateNotifyValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.configReceiver != null) && this.configReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.historyReceiver != null) && this.historyReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.clientReceiver != null) && this.clientReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.feedReceiver != null) && this.feedReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.storageReceiver != null) && this.storageReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.exposedReceiver != null) && this.exposedReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.executionReceiver != null) && this.executionReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.backupReceiver != null) && this.backupReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerReceiver != null) && this.innerReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.tradeReceiver != null) && this.tradeReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
    this._configReceiver.onReceiveLogHandler = handler
    this._historyReceiver.onReceiveLogHandler = handler
    this._clientReceiver.onReceiveLogHandler = handler
    this._feedReceiver.onReceiveLogHandler = handler
    this._storageReceiver.onReceiveLogHandler = handler
    this._exposedReceiver.onReceiveLogHandler = handler
    this._executionReceiver.onReceiveLogHandler = handler
    this._backupReceiver.onReceiveLogHandler = handler
    this._innerReceiver.onReceiveLogHandler = handler
    this._tradeReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding manager proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize manager proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._configProxy = new config.Proxy(this.buffer)
    this._historyProxy = new history.Proxy(this.buffer)
    this._clientProxy = new client.Proxy(this.buffer)
    this._feedProxy = new feed.Proxy(this.buffer)
    this._storageProxy = new storage.Proxy(this.buffer)
    this._exposedProxy = new exposed.Proxy(this.buffer)
    this._executionProxy = new execution.Proxy(this.buffer)
    this._backupProxy = new backup.Proxy(this.buffer)
    this._innerProxy = new inner.Proxy(this.buffer)
    this._tradeProxy = new trade.Proxy(this.buffer)
    this._PingModuleRequestModel = new PingModuleRequestModel()
    this._PongModuleResponseModel = new PongModuleResponseModel()
    this._PingModuleRejectModel = new PingModuleRejectModel()
    this._Deprecated3Model = new Deprecated3Model()
    this._Deprecated4Model = new Deprecated4Model()
    this._Deprecated1Model = new Deprecated1Model()
    this._Deprecated2Model = new Deprecated2Model()
    this._ManagerSessionInfoRequestModel = new ManagerSessionInfoRequestModel()
    this._ManagerSessionInfoResponseModel = new ManagerSessionInfoResponseModel()
    this._OnlineSessionsRequestModel = new OnlineSessionsRequestModel()
    this._OnlineSessionsResponseModel = new OnlineSessionsResponseModel()
    this._DropSessionRequestModel = new DropSessionRequestModel()
    this._DropSessionResponseModel = new DropSessionResponseModel()
    this._ConfigRequestModel = new ConfigRequestModel()
    this._ConfigResponseModel = new ConfigResponseModel()
    this._ConfigRejectModel = new ConfigRejectModel()
    this._ConfigUpdateRequestModel = new ConfigUpdateRequestModel()
    this._ConfigUpdateResponseModel = new ConfigUpdateResponseModel()
    this._ConfigUpdateNotifyModel = new ConfigUpdateNotifyModel()
    this._ConfigVersionRequestModel = new ConfigVersionRequestModel()
    this._ConfigVersionResponseModel = new ConfigVersionResponseModel()
    this._ConfigExportRequestModel = new ConfigExportRequestModel()
    this._ConfigExportResponseModel = new ConfigExportResponseModel()
    this._ConfigImportRequestModel = new ConfigImportRequestModel()
    this._ConfigImportResponseModel = new ConfigImportResponseModel()
    this._ConfigValidateRequestModel = new ConfigValidateRequestModel()
    this._ConfigValidateResponseModel = new ConfigValidateResponseModel()
    this._ConfigRefreshRequestModel = new ConfigRefreshRequestModel()
    this._ConfigRefreshResponseModel = new ConfigRefreshResponseModel()
    this._ConfigHistoryRequestModel = new ConfigHistoryRequestModel()
    this._ConfigHistoryResponseModel = new ConfigHistoryResponseModel()
    this._ConfigRollbackRequestModel = new ConfigRollbackRequestModel()
    this._ConfigRollbackResponseModel = new ConfigRollbackResponseModel()
    this._HostGetRequestModel = new HostGetRequestModel()
    this._HostGetResponseModel = new HostGetResponseModel()
    this._HostsGetAllRequestModel = new HostsGetAllRequestModel()
    this._HostsGetAllResponseModel = new HostsGetAllResponseModel()
    this._HostCreateRequestModel = new HostCreateRequestModel()
    this._HostCreateResponseModel = new HostCreateResponseModel()
    this._HostModifyRequestModel = new HostModifyRequestModel()
    this._HostModifyResponseModel = new HostModifyResponseModel()
    this._HostDeleteRequestModel = new HostDeleteRequestModel()
    this._HostDeleteResponseModel = new HostDeleteResponseModel()
    this._HostUpdateNotifyModel = new HostUpdateNotifyModel()
    this._ModuleGetRequestModel = new ModuleGetRequestModel()
    this._ModuleGetResponseModel = new ModuleGetResponseModel()
    this._ModulesGetAllRequestModel = new ModulesGetAllRequestModel()
    this._ModulesGetAllResponseModel = new ModulesGetAllResponseModel()
    this._ModuleCreateRequestModel = new ModuleCreateRequestModel()
    this._ModuleCreateResponseModel = new ModuleCreateResponseModel()
    this._ModuleModifyRequestModel = new ModuleModifyRequestModel()
    this._ModuleModifyResponseModel = new ModuleModifyResponseModel()
    this._ModuleDeleteRequestModel = new ModuleDeleteRequestModel()
    this._ModuleDeleteResponseModel = new ModuleDeleteResponseModel()
    this._ModuleUpdateNotifyModel = new ModuleUpdateNotifyModel()
    this._ServerTimeGetRequestModel = new ServerTimeGetRequestModel()
    this._ServerTimeGetResponseModel = new ServerTimeGetResponseModel()
    this._ManagerPlatformTimezoneGetRequestModel = new ManagerPlatformTimezoneGetRequestModel()
    this._ManagerPlatformTimezoneGetResponseModel = new ManagerPlatformTimezoneGetResponseModel()
    this._TimezoneGetRequestModel = new TimezoneGetRequestModel()
    this._TimezoneGetResponseModel = new TimezoneGetResponseModel()
    this._TimezonesGetAllRequestModel = new TimezonesGetAllRequestModel()
    this._TimezonesGetAllResponseModel = new TimezonesGetAllResponseModel()
    this._TimezoneCreateRequestModel = new TimezoneCreateRequestModel()
    this._TimezoneCreateResponseModel = new TimezoneCreateResponseModel()
    this._TimezoneModifyRequestModel = new TimezoneModifyRequestModel()
    this._TimezoneModifyResponseModel = new TimezoneModifyResponseModel()
    this._TimezoneDeleteRequestModel = new TimezoneDeleteRequestModel()
    this._TimezoneDeleteResponseModel = new TimezoneDeleteResponseModel()
    this._TimezoneUpdateNotifyModel = new TimezoneUpdateNotifyModel()
    this._CurrencyGroupGetRequestModel = new CurrencyGroupGetRequestModel()
    this._CurrencyGroupGetResponseModel = new CurrencyGroupGetResponseModel()
    this._CurrencyGroupsGetAllRequestModel = new CurrencyGroupsGetAllRequestModel()
    this._CurrencyGroupsGetAllResponseModel = new CurrencyGroupsGetAllResponseModel()
    this._CurrencyGroupCreateRequestModel = new CurrencyGroupCreateRequestModel()
    this._CurrencyGroupCreateResponseModel = new CurrencyGroupCreateResponseModel()
    this._CurrencyGroupModifyRequestModel = new CurrencyGroupModifyRequestModel()
    this._CurrencyGroupModifyResponseModel = new CurrencyGroupModifyResponseModel()
    this._CurrencyGroupDeleteRequestModel = new CurrencyGroupDeleteRequestModel()
    this._CurrencyGroupDeleteResponseModel = new CurrencyGroupDeleteResponseModel()
    this._CurrencyGroupUpdateNotifyModel = new CurrencyGroupUpdateNotifyModel()
    this._CurrencyGetRequestModel = new CurrencyGetRequestModel()
    this._CurrencyGetResponseModel = new CurrencyGetResponseModel()
    this._CurrenciesGetAllRequestModel = new CurrenciesGetAllRequestModel()
    this._CurrenciesGetAllResponseModel = new CurrenciesGetAllResponseModel()
    this._CurrencyCreateRequestModel = new CurrencyCreateRequestModel()
    this._CurrencyCreateResponseModel = new CurrencyCreateResponseModel()
    this._CurrencyModifyRequestModel = new CurrencyModifyRequestModel()
    this._CurrencyModifyResponseModel = new CurrencyModifyResponseModel()
    this._CurrencyDeleteRequestModel = new CurrencyDeleteRequestModel()
    this._CurrencyDeleteResponseModel = new CurrencyDeleteResponseModel()
    this._CurrencyUpdateNotifyModel = new CurrencyUpdateNotifyModel()
    this._SymbolGroupGetRequestModel = new SymbolGroupGetRequestModel()
    this._SymbolGroupGetResponseModel = new SymbolGroupGetResponseModel()
    this._SymbolGroupsGetAllRequestModel = new SymbolGroupsGetAllRequestModel()
    this._SymbolGroupsGetAllResponseModel = new SymbolGroupsGetAllResponseModel()
    this._SymbolGroupCreateRequestModel = new SymbolGroupCreateRequestModel()
    this._SymbolGroupCreateResponseModel = new SymbolGroupCreateResponseModel()
    this._SymbolGroupModifyRequestModel = new SymbolGroupModifyRequestModel()
    this._SymbolGroupModifyResponseModel = new SymbolGroupModifyResponseModel()
    this._SymbolGroupDeleteRequestModel = new SymbolGroupDeleteRequestModel()
    this._SymbolGroupDeleteResponseModel = new SymbolGroupDeleteResponseModel()
    this._SymbolGroupUpdateNotifyModel = new SymbolGroupUpdateNotifyModel()
    this._SymbolGetRequestModel = new SymbolGetRequestModel()
    this._SymbolGetResponseModel = new SymbolGetResponseModel()
    this._SymbolsGetAllRequestModel = new SymbolsGetAllRequestModel()
    this._SymbolsGetAllResponseModel = new SymbolsGetAllResponseModel()
    this._SymbolCreateRequestModel = new SymbolCreateRequestModel()
    this._SymbolCreateResponseModel = new SymbolCreateResponseModel()
    this._SymbolModifyRequestModel = new SymbolModifyRequestModel()
    this._SymbolModifyResponseModel = new SymbolModifyResponseModel()
    this._SymbolDeleteRequestModel = new SymbolDeleteRequestModel()
    this._SymbolDeleteResponseModel = new SymbolDeleteResponseModel()
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel()
    this._SwapsExportRequestModel = new SwapsExportRequestModel()
    this._SwapsExportResponseModel = new SwapsExportResponseModel()
    this._SwapsImportRequestModel = new SwapsImportRequestModel()
    this._SwapsImportResponseModel = new SwapsImportResponseModel()
    this._CommissionProfileGetRequestModel = new CommissionProfileGetRequestModel()
    this._CommissionProfileGetResponseModel = new CommissionProfileGetResponseModel()
    this._CommissionProfilesGetAllRequestModel = new CommissionProfilesGetAllRequestModel()
    this._CommissionProfilesGetAllResponseModel = new CommissionProfilesGetAllResponseModel()
    this._CommissionProfileCreateRequestModel = new CommissionProfileCreateRequestModel()
    this._CommissionProfileCreateResponseModel = new CommissionProfileCreateResponseModel()
    this._CommissionProfileModifyRequestModel = new CommissionProfileModifyRequestModel()
    this._CommissionProfileModifyResponseModel = new CommissionProfileModifyResponseModel()
    this._CommissionProfileDeleteRequestModel = new CommissionProfileDeleteRequestModel()
    this._CommissionProfileDeleteResponseModel = new CommissionProfileDeleteResponseModel()
    this._CommissionProfileUpdateNotifyModel = new CommissionProfileUpdateNotifyModel()
    this._MarkupProfileGetRequestModel = new MarkupProfileGetRequestModel()
    this._MarkupProfileGetResponseModel = new MarkupProfileGetResponseModel()
    this._MarkupProfilesGetAllRequestModel = new MarkupProfilesGetAllRequestModel()
    this._MarkupProfilesGetAllResponseModel = new MarkupProfilesGetAllResponseModel()
    this._MarkupProfileCreateRequestModel = new MarkupProfileCreateRequestModel()
    this._MarkupProfileCreateResponseModel = new MarkupProfileCreateResponseModel()
    this._MarkupProfileModifyRequestModel = new MarkupProfileModifyRequestModel()
    this._MarkupProfileModifyResponseModel = new MarkupProfileModifyResponseModel()
    this._MarkupProfileDeleteRequestModel = new MarkupProfileDeleteRequestModel()
    this._MarkupProfileDeleteResponseModel = new MarkupProfileDeleteResponseModel()
    this._MarkupProfileUpdateNotifyModel = new MarkupProfileUpdateNotifyModel()
    this._ManagerGroupGetRequestModel = new ManagerGroupGetRequestModel()
    this._ManagerGroupGetResponseModel = new ManagerGroupGetResponseModel()
    this._ManagerGroupsGetAllRequestModel = new ManagerGroupsGetAllRequestModel()
    this._ManagerGroupsGetAllResponseModel = new ManagerGroupsGetAllResponseModel()
    this._ManagerGroupCreateRequestModel = new ManagerGroupCreateRequestModel()
    this._ManagerGroupCreateResponseModel = new ManagerGroupCreateResponseModel()
    this._ManagerGroupModifyRequestModel = new ManagerGroupModifyRequestModel()
    this._ManagerGroupModifyResponseModel = new ManagerGroupModifyResponseModel()
    this._ManagerGroupDeleteRequestModel = new ManagerGroupDeleteRequestModel()
    this._ManagerGroupDeleteResponseModel = new ManagerGroupDeleteResponseModel()
    this._ManagerGroupUpdateNotifyModel = new ManagerGroupUpdateNotifyModel()
    this._ManagerGetRequestModel = new ManagerGetRequestModel()
    this._ManagerGetResponseModel = new ManagerGetResponseModel()
    this._ManagersGetAllRequestModel = new ManagersGetAllRequestModel()
    this._ManagersGetAllResponseModel = new ManagersGetAllResponseModel()
    this._ManagerCreateRequestModel = new ManagerCreateRequestModel()
    this._ManagerCreateResponseModel = new ManagerCreateResponseModel()
    this._ManagerModifyRequestModel = new ManagerModifyRequestModel()
    this._ManagerModifyResponseModel = new ManagerModifyResponseModel()
    this._ManagerDeleteRequestModel = new ManagerDeleteRequestModel()
    this._ManagerDeleteResponseModel = new ManagerDeleteResponseModel()
    this._ManagerUpdateNotifyModel = new ManagerUpdateNotifyModel()
    this._PlatformGetRequestModel = new PlatformGetRequestModel()
    this._PlatformGetResponseModel = new PlatformGetResponseModel()
    this._PlatformsGetAllRequestModel = new PlatformsGetAllRequestModel()
    this._PlatformsGetAllResponseModel = new PlatformsGetAllResponseModel()
    this._PlatformCreateRequestModel = new PlatformCreateRequestModel()
    this._PlatformCreateResponseModel = new PlatformCreateResponseModel()
    this._PlatformModifyRequestModel = new PlatformModifyRequestModel()
    this._PlatformModifyResponseModel = new PlatformModifyResponseModel()
    this._PlatformDeleteRequestModel = new PlatformDeleteRequestModel()
    this._PlatformDeleteResponseModel = new PlatformDeleteResponseModel()
    this._PlatformUpdateNotifyModel = new PlatformUpdateNotifyModel()
    this._AccountGroupGetRequestModel = new AccountGroupGetRequestModel()
    this._AccountGroupGetResponseModel = new AccountGroupGetResponseModel()
    this._AccountGroupsGetAllRequestModel = new AccountGroupsGetAllRequestModel()
    this._AccountGroupsGetAllResponseModel = new AccountGroupsGetAllResponseModel()
    this._AccountGroupCreateRequestModel = new AccountGroupCreateRequestModel()
    this._AccountGroupCreateResponseModel = new AccountGroupCreateResponseModel()
    this._AccountGroupModifyRequestModel = new AccountGroupModifyRequestModel()
    this._AccountGroupModifyResponseModel = new AccountGroupModifyResponseModel()
    this._AccountGroupDeleteRequestModel = new AccountGroupDeleteRequestModel()
    this._AccountGroupDeleteResponseModel = new AccountGroupDeleteResponseModel()
    this._AccountGroupUpdateNotifyModel = new AccountGroupUpdateNotifyModel()
    this._AccountGetRequestModel = new AccountGetRequestModel()
    this._AccountGetResponseModel = new AccountGetResponseModel()
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel()
    this._AccountCreateRequestModel = new AccountCreateRequestModel()
    this._AccountCreateResponseModel = new AccountCreateResponseModel()
    this._AccountSocialTradingMasterCreateRequestModel = new AccountSocialTradingMasterCreateRequestModel()
    this._AccountSocialTradingMasterCreateResponseModel = new AccountSocialTradingMasterCreateResponseModel()
    this._AccountSocialTradingInvestorCreateRequestModel = new AccountSocialTradingInvestorCreateRequestModel()
    this._AccountSocialTradingInvestorCreateResponseModel = new AccountSocialTradingInvestorCreateResponseModel()
    this._AccountModifyRequestModel = new AccountModifyRequestModel()
    this._AccountModifyResponseModel = new AccountModifyResponseModel()
    this._AccountDeleteRequestModel = new AccountDeleteRequestModel()
    this._AccountDeleteResponseModel = new AccountDeleteResponseModel()
    this._DemoAccountsDeleteRequestModel = new DemoAccountsDeleteRequestModel()
    this._DemoAccountsDeleteResponseModel = new DemoAccountsDeleteResponseModel()
    this._AccountUpgradeToSocialTradingMasterRequestModel = new AccountUpgradeToSocialTradingMasterRequestModel()
    this._AccountUpgradeToSocialTradingMasterResponseModel = new AccountUpgradeToSocialTradingMasterResponseModel()
    this._AccountUpgradeToSocialTradingInvestorRequestModel = new AccountUpgradeToSocialTradingInvestorRequestModel()
    this._AccountUpgradeToSocialTradingInvestorResponseModel = new AccountUpgradeToSocialTradingInvestorResponseModel()
    this._SocialTradingInvestorAccountToRegularRequestModel = new SocialTradingInvestorAccountToRegularRequestModel()
    this._SocialTradingInvestorAccountToRegularResponseModel = new SocialTradingInvestorAccountToRegularResponseModel()
    this._SocialTradingMasterAccountToRegularRequestModel = new SocialTradingMasterAccountToRegularRequestModel()
    this._SocialTradingMasterAccountToRegularResponseModel = new SocialTradingMasterAccountToRegularResponseModel()
    this._AccountUpdateNotifyModel = new AccountUpdateNotifyModel()
    this._MarginGetRequestModel = new MarginGetRequestModel()
    this._MarginGetResponseModel = new MarginGetResponseModel()
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel()
    this._MarginUpdateNotifyModel = new MarginUpdateNotifyModel()
    this._MarginCallNotifyModel = new MarginCallNotifyModel()
    this._StopOutNotifyModel = new StopOutNotifyModel()
    this._AssetGetRequestModel = new AssetGetRequestModel()
    this._AssetGetResponseModel = new AssetGetResponseModel()
    this._AssetsGetRequestModel = new AssetsGetRequestModel()
    this._AssetsGetResponseModel = new AssetsGetResponseModel()
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel()
    this._AssetUpdateNotifyModel = new AssetUpdateNotifyModel()
    this._OrderGetRequestModel = new OrderGetRequestModel()
    this._OrderGetResponseModel = new OrderGetResponseModel()
    this._OrdersGetRequestModel = new OrdersGetRequestModel()
    this._OrdersGetResponseModel = new OrdersGetResponseModel()
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel()
    this._OrderCreateRequestModel = new OrderCreateRequestModel()
    this._OrderCreateResponseModel = new OrderCreateResponseModel()
    this._OrderModifyRequestModel = new OrderModifyRequestModel()
    this._OrderModifyResponseModel = new OrderModifyResponseModel()
    this._OrderActivateRequestModel = new OrderActivateRequestModel()
    this._OrderActivateResponseModel = new OrderActivateResponseModel()
    this._OrderCancelRequestModel = new OrderCancelRequestModel()
    this._OrderCancelResponseModel = new OrderCancelResponseModel()
    this._OrderCancelAllRequestModel = new OrderCancelAllRequestModel()
    this._OrderCancelAllResponseModel = new OrderCancelAllResponseModel()
    this._OrderCancelAllByIdRequestModel = new OrderCancelAllByIdRequestModel()
    this._OrderCloseRequestModel = new OrderCloseRequestModel()
    this._OrderCloseResponseModel = new OrderCloseResponseModel()
    this._OrderCloseAllRequestModel = new OrderCloseAllRequestModel()
    this._OrderCloseAllResponseModel = new OrderCloseAllResponseModel()
    this._OrderCloseAllByIdRequestModel = new OrderCloseAllByIdRequestModel()
    this._OrderCloseByRequestModel = new OrderCloseByRequestModel()
    this._OrderCloseByResponseModel = new OrderCloseByResponseModel()
    this._OrderCloseByAllRequestModel = new OrderCloseByAllRequestModel()
    this._OrderCloseByAllResponseModel = new OrderCloseByAllResponseModel()
    this._OrderCancelOrCloseRequestModel = new OrderCancelOrCloseRequestModel()
    this._OrderCancelOrCloseResponseModel = new OrderCancelOrCloseResponseModel()
    this._OrderCancelOrCloseAllRequestModel = new OrderCancelOrCloseAllRequestModel()
    this._OrderCancelOrCloseAllResponseModel = new OrderCancelOrCloseAllResponseModel()
    this._OrderUpdateNotifyModel = new OrderUpdateNotifyModel()
    this._PositionGetRequestModel = new PositionGetRequestModel()
    this._PositionGetResponseModel = new PositionGetResponseModel()
    this._PositionsGetRequestModel = new PositionsGetRequestModel()
    this._PositionsGetResponseModel = new PositionsGetResponseModel()
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel()
    this._PositionUpdateNotifyModel = new PositionUpdateNotifyModel()
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel()
    this._TradeHistoryFilterRequestModel = new TradeHistoryFilterRequestModel()
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel()
    this._TradeHistoryUpdateNotifyModel = new TradeHistoryUpdateNotifyModel()
    this._TestRolloverDumpRequestModel = new TestRolloverDumpRequestModel()
    this._TestRolloverDumpResponseModel = new TestRolloverDumpResponseModel()
    this._Deprecated001Model = new Deprecated001Model()
    this._Deprecated002Model = new Deprecated002Model()
    this._Deprecated003Model = new Deprecated003Model()
    this._Deprecated004Model = new Deprecated004Model()
    this._Deprecated005Model = new Deprecated005Model()
    this._Deprecated006Model = new Deprecated006Model()
    this._Deprecated007Model = new Deprecated007Model()
    this._Deprecated008Model = new Deprecated008Model()
    this._Deprecated009Model = new Deprecated009Model()
    this._Deprecated010Model = new Deprecated010Model()
    this._Deprecated011Model = new Deprecated011Model()
    this._Deprecated012Model = new Deprecated012Model()
    this._Deprecated013Model = new Deprecated013Model()
    this._DepositAccountRequestModel = new DepositAccountRequestModel()
    this._DepositAccountResponseModel = new DepositAccountResponseModel()
    this._CreditAccountRequestModel = new CreditAccountRequestModel()
    this._CreditAccountResponseModel = new CreditAccountResponseModel()
    this._TransferMoneyRequestModel = new TransferMoneyRequestModel()
    this._TransferMoneyResponseModel = new TransferMoneyResponseModel()
    this._TradingCalendarRegularRuleGetRequestModel = new TradingCalendarRegularRuleGetRequestModel()
    this._TradingCalendarRegularRuleGetResponseModel = new TradingCalendarRegularRuleGetResponseModel()
    this._TradingCalendarRegularRulesGetAllRequestModel = new TradingCalendarRegularRulesGetAllRequestModel()
    this._TradingCalendarRegularRulesGetAllResponseModel = new TradingCalendarRegularRulesGetAllResponseModel()
    this._TradingCalendarRegularRuleCreateRequestModel = new TradingCalendarRegularRuleCreateRequestModel()
    this._TradingCalendarRegularRuleCreateResponseModel = new TradingCalendarRegularRuleCreateResponseModel()
    this._TradingCalendarRegularRuleModifyRequestModel = new TradingCalendarRegularRuleModifyRequestModel()
    this._TradingCalendarRegularRuleModifyResponseModel = new TradingCalendarRegularRuleModifyResponseModel()
    this._TradingCalendarRegularRuleDeleteRequestModel = new TradingCalendarRegularRuleDeleteRequestModel()
    this._TradingCalendarRegularRuleDeleteResponseModel = new TradingCalendarRegularRuleDeleteResponseModel()
    this._TradingCalendarRegularRuleUpdateNotifyModel = new TradingCalendarRegularRuleUpdateNotifyModel()
    this._TradingCalendarOffTimeRuleGetRequestModel = new TradingCalendarOffTimeRuleGetRequestModel()
    this._TradingCalendarOffTimeRuleGetResponseModel = new TradingCalendarOffTimeRuleGetResponseModel()
    this._TradingCalendarOffTimeRulesGetAllRequestModel = new TradingCalendarOffTimeRulesGetAllRequestModel()
    this._TradingCalendarOffTimeRulesGetAllResponseModel = new TradingCalendarOffTimeRulesGetAllResponseModel()
    this._TradingCalendarOffTimeRuleCreateRequestModel = new TradingCalendarOffTimeRuleCreateRequestModel()
    this._TradingCalendarOffTimeRuleCreateResponseModel = new TradingCalendarOffTimeRuleCreateResponseModel()
    this._TradingCalendarOffTimeRuleModifyRequestModel = new TradingCalendarOffTimeRuleModifyRequestModel()
    this._TradingCalendarOffTimeRuleModifyResponseModel = new TradingCalendarOffTimeRuleModifyResponseModel()
    this._TradingCalendarOffTimeRuleDeleteRequestModel = new TradingCalendarOffTimeRuleDeleteRequestModel()
    this._TradingCalendarOffTimeRuleDeleteResponseModel = new TradingCalendarOffTimeRuleDeleteResponseModel()
    this._TradingCalendarOffTimeRuleUpdateNotifyModel = new TradingCalendarOffTimeRuleUpdateNotifyModel()
    this._TradingCalendarRulesGetRequestModel = new TradingCalendarRulesGetRequestModel()
    this._TradingCalendarRulesGetResponseModel = new TradingCalendarRulesGetResponseModel()
    this._TradingRouteRulesGetRequestModel = new TradingRouteRulesGetRequestModel()
    this._TradingRouteRulesGetResponseModel = new TradingRouteRulesGetResponseModel()
    this._TradingRouteRuleCreateRequestModel = new TradingRouteRuleCreateRequestModel()
    this._TradingRouteRuleCreateResponseModel = new TradingRouteRuleCreateResponseModel()
    this._TradingRouteRuleModifyRequestModel = new TradingRouteRuleModifyRequestModel()
    this._TradingRouteRuleModifyResponseModel = new TradingRouteRuleModifyResponseModel()
    this._TradingRouteRuleDeleteRequestModel = new TradingRouteRuleDeleteRequestModel()
    this._TradingRouteRuleDeleteResponseModel = new TradingRouteRuleDeleteResponseModel()
    this._TradingRouteRuleUpdateNotifyModel = new TradingRouteRuleUpdateNotifyModel()
    this._SymbolMapsGetRequestModel = new SymbolMapsGetRequestModel()
    this._SymbolMapsGetResponseModel = new SymbolMapsGetResponseModel()
    this._SymbolMapCreateRequestModel = new SymbolMapCreateRequestModel()
    this._SymbolMapCreateResponseModel = new SymbolMapCreateResponseModel()
    this._SymbolMapModifyRequestModel = new SymbolMapModifyRequestModel()
    this._SymbolMapModifyResponseModel = new SymbolMapModifyResponseModel()
    this._SymbolMapDeleteRequestModel = new SymbolMapDeleteRequestModel()
    this._SymbolMapDeleteResponseModel = new SymbolMapDeleteResponseModel()
    this._SymbolMapUpdateNotifyModel = new SymbolMapUpdateNotifyModel()
    this._GatewaysGetRequestModel = new GatewaysGetRequestModel()
    this._GatewaysGetResponseModel = new GatewaysGetResponseModel()
    this._GatewayCreateRequestModel = new GatewayCreateRequestModel()
    this._GatewayCreateResponseModel = new GatewayCreateResponseModel()
    this._GatewayModifyRequestModel = new GatewayModifyRequestModel()
    this._GatewayModifyResponseModel = new GatewayModifyResponseModel()
    this._GatewayDeleteRequestModel = new GatewayDeleteRequestModel()
    this._GatewayDeleteResponseModel = new GatewayDeleteResponseModel()
    this._GatewayUpdateNotifyModel = new GatewayUpdateNotifyModel()
    this._DataFeedsGetRequestModel = new DataFeedsGetRequestModel()
    this._DataFeedsGetResponseModel = new DataFeedsGetResponseModel()
    this._DataFeedCreateRequestModel = new DataFeedCreateRequestModel()
    this._DataFeedCreateResponseModel = new DataFeedCreateResponseModel()
    this._DataFeedModifyRequestModel = new DataFeedModifyRequestModel()
    this._DataFeedModifyResponseModel = new DataFeedModifyResponseModel()
    this._DataFeedDeleteRequestModel = new DataFeedDeleteRequestModel()
    this._DataFeedDeleteResponseModel = new DataFeedDeleteResponseModel()
    this._DataFeedUpdateNotifyModel = new DataFeedUpdateNotifyModel()
    this._ModuleSystemInfoNotifyModel = new ModuleSystemInfoNotifyModel()
    this._ModulesSystemInfoGetAllRequestModel = new ModulesSystemInfoGetAllRequestModel()
    this._ModulesSystemInfoGetAllResponseModel = new ModulesSystemInfoGetAllResponseModel()
    this._ModulesSystemInfoNotifyModel = new ModulesSystemInfoNotifyModel()
    this._ChartBarHistoryModifyRequestModel = new ChartBarHistoryModifyRequestModel()
    this._ChartBarHistoryModifyResponseModel = new ChartBarHistoryModifyResponseModel()
    this._FeedUpdateTickSnapshotRequestModel = new FeedUpdateTickSnapshotRequestModel()
    this._FeedUpdateTickSnapshotResponseModel = new FeedUpdateTickSnapshotResponseModel()
    this._FeedUpdateTickRequestModel = new FeedUpdateTickRequestModel()
    this._FeedUpdateTickResponseModel = new FeedUpdateTickResponseModel()
    this._FeedUpdateLevel2SnapshotRequestModel = new FeedUpdateLevel2SnapshotRequestModel()
    this._FeedUpdateLevel2SnapshotResponseModel = new FeedUpdateLevel2SnapshotResponseModel()
    this._FeedUpdateLevel2RequestModel = new FeedUpdateLevel2RequestModel()
    this._FeedUpdateLevel2ResponseModel = new FeedUpdateLevel2ResponseModel()
    this._MailServerGetRequestModel = new MailServerGetRequestModel()
    this._MailServerGetResponseModel = new MailServerGetResponseModel()
    this._MailServersGetAllRequestModel = new MailServersGetAllRequestModel()
    this._MailServersGetAllResponseModel = new MailServersGetAllResponseModel()
    this._MailServerCreateRequestModel = new MailServerCreateRequestModel()
    this._MailServerCreateResponseModel = new MailServerCreateResponseModel()
    this._MailServerModifyRequestModel = new MailServerModifyRequestModel()
    this._MailServerModifyResponseModel = new MailServerModifyResponseModel()
    this._MailServerDeleteRequestModel = new MailServerDeleteRequestModel()
    this._MailServerDeleteResponseModel = new MailServerDeleteResponseModel()
    this._MailServerUpdateNotifyModel = new MailServerUpdateNotifyModel()
    this._AccountStatesUpdateNotifyModel = new AccountStatesUpdateNotifyModel()
    this._AccountStatesGetAllRequestModel = new AccountStatesGetAllRequestModel()
    this._AccountStatesGetAllResponseModel = new AccountStatesGetAllResponseModel()
    this._ModuleAuthRequestModel = new ModuleAuthRequestModel()
    this._ModuleAuthResponseModel = new ModuleAuthResponseModel()
    this._LeverageProfileGetRequestModel = new LeverageProfileGetRequestModel()
    this._LeverageProfileGetResponseModel = new LeverageProfileGetResponseModel()
    this._LeverageProfilesGetAllRequestModel = new LeverageProfilesGetAllRequestModel()
    this._LeverageProfilesGetAllResponseModel = new LeverageProfilesGetAllResponseModel()
    this._LeverageProfileCreateRequestModel = new LeverageProfileCreateRequestModel()
    this._LeverageProfileCreateResponseModel = new LeverageProfileCreateResponseModel()
    this._LeverageProfileModifyRequestModel = new LeverageProfileModifyRequestModel()
    this._LeverageProfileModifyResponseModel = new LeverageProfileModifyResponseModel()
    this._LeverageProfileDeleteRequestModel = new LeverageProfileDeleteRequestModel()
    this._LeverageProfileDeleteResponseModel = new LeverageProfileDeleteResponseModel()
    this._LeverageProfileUpdateNotifyModel = new LeverageProfileUpdateNotifyModel()
    this._MarginRateProfileGetRequestModel = new MarginRateProfileGetRequestModel()
    this._MarginRateProfileGetResponseModel = new MarginRateProfileGetResponseModel()
    this._MarginRateProfilesGetAllRequestModel = new MarginRateProfilesGetAllRequestModel()
    this._MarginRateProfilesGetAllResponseModel = new MarginRateProfilesGetAllResponseModel()
    this._MarginRateProfileCreateRequestModel = new MarginRateProfileCreateRequestModel()
    this._MarginRateProfileCreateResponseModel = new MarginRateProfileCreateResponseModel()
    this._MarginRateProfileModifyRequestModel = new MarginRateProfileModifyRequestModel()
    this._MarginRateProfileModifyResponseModel = new MarginRateProfileModifyResponseModel()
    this._MarginRateProfileDeleteRequestModel = new MarginRateProfileDeleteRequestModel()
    this._MarginRateProfileDeleteResponseModel = new MarginRateProfileDeleteResponseModel()
    this._MarginRateProfileUpdateNotifyModel = new MarginRateProfileUpdateNotifyModel()
    this._NewsProfileGetRequestModel = new NewsProfileGetRequestModel()
    this._NewsProfileGetResponseModel = new NewsProfileGetResponseModel()
    this._NewsProfilesGetAllRequestModel = new NewsProfilesGetAllRequestModel()
    this._NewsProfilesGetAllResponseModel = new NewsProfilesGetAllResponseModel()
    this._NewsProfileCreateRequestModel = new NewsProfileCreateRequestModel()
    this._NewsProfileCreateResponseModel = new NewsProfileCreateResponseModel()
    this._NewsProfileModifyRequestModel = new NewsProfileModifyRequestModel()
    this._NewsProfileModifyResponseModel = new NewsProfileModifyResponseModel()
    this._NewsProfileDeleteRequestModel = new NewsProfileDeleteRequestModel()
    this._NewsProfileDeleteResponseModel = new NewsProfileDeleteResponseModel()
    this._NewsProfileUpdateNotifyModel = new NewsProfileUpdateNotifyModel()
    this._NewsProfileImportRequestModel = new NewsProfileImportRequestModel()
    this._NewsProfileImportResponseModel = new NewsProfileImportResponseModel()
    this._TickHistoryRequestModel = new TickHistoryRequestModel()
    this._TickHistoryResponseModel = new TickHistoryResponseModel()
    this._EmailTemplateGetRequestModel = new EmailTemplateGetRequestModel()
    this._EmailTemplateGetResponseModel = new EmailTemplateGetResponseModel()
    this._EmailTemplatesGetAllRequestModel = new EmailTemplatesGetAllRequestModel()
    this._EmailTemplatesGetAllResponseModel = new EmailTemplatesGetAllResponseModel()
    this._EmailTemplateCreateRequestModel = new EmailTemplateCreateRequestModel()
    this._EmailTemplateCreateResponseModel = new EmailTemplateCreateResponseModel()
    this._EmailTemplateModifyRequestModel = new EmailTemplateModifyRequestModel()
    this._EmailTemplateModifyResponseModel = new EmailTemplateModifyResponseModel()
    this._EmailTemplateDeleteRequestModel = new EmailTemplateDeleteRequestModel()
    this._EmailTemplateDeleteResponseModel = new EmailTemplateDeleteResponseModel()
    this._EmailTemplateUpdateNotifyModel = new EmailTemplateUpdateNotifyModel()
    this._OrderStateSubscribeRequestModel = new OrderStateSubscribeRequestModel()
    this._OrderStateSubscribeResponseModel = new OrderStateSubscribeResponseModel()
    this._OrderStateUpdateSubscriptionRequestModel = new OrderStateUpdateSubscriptionRequestModel()
    this._OrderStateUpdateSubscriptionResponseModel = new OrderStateUpdateSubscriptionResponseModel()
    this._OrderStateUnsubscribeRequestModel = new OrderStateUnsubscribeRequestModel()
    this._OrderStateUnsubscribeResponseModel = new OrderStateUnsubscribeResponseModel()
    this._OrderStateNotifyModel = new OrderStateNotifyModel()
    this._RequestMonitoringSubscribeRequestModel = new RequestMonitoringSubscribeRequestModel()
    this._RequestMonitoringSubscribeResponseModel = new RequestMonitoringSubscribeResponseModel()
    this._RequestMonitoringUnsubscribeRequestModel = new RequestMonitoringUnsubscribeRequestModel()
    this._RequestMonitoringUnsubscribeResponseModel = new RequestMonitoringUnsubscribeResponseModel()
    this._RequestMonitoringRequestUpdateNotifyModel = new RequestMonitoringRequestUpdateNotifyModel()
    this._FilledOrdersSummaryInfoSubscribeRequestModel = new FilledOrdersSummaryInfoSubscribeRequestModel()
    this._FilledOrdersSummaryInfoSubscribeResponseModel = new FilledOrdersSummaryInfoSubscribeResponseModel()
    this._FilledOrdersSummaryInfoNotifyModel = new FilledOrdersSummaryInfoNotifyModel()
    this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel = new FilledOrdersSummaryInfoUpdateSubscriptionRequestModel()
    this._FilledOrdersSummaryInfoUnsubscribeRequestModel = new FilledOrdersSummaryInfoUnsubscribeRequestModel()
    this._FilledOrdersSummaryInfoUnsubscribeResponseModel = new FilledOrdersSummaryInfoUnsubscribeResponseModel()
    this._TradeEventsNotifyModel = new TradeEventsNotifyModel()
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel()
    this._JournalRequestModel = new JournalRequestModel()
    this._JournalResponseModel = new JournalResponseModel()
    this._ClientReportsListGetRequestModel = new ClientReportsListGetRequestModel()
    this._ClientReportsListGetResponseModel = new ClientReportsListGetResponseModel()
    this._ClientReportGetRequestModel = new ClientReportGetRequestModel()
    this._ClientReportGetResponseModel = new ClientReportGetResponseModel()
    this._ClientReportsSendRequestModel = new ClientReportsSendRequestModel()
    this._ClientReportsSendResponseModel = new ClientReportsSendResponseModel()
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel()
    this._ChartBarsImportRequestModel = new ChartBarsImportRequestModel()
    this._ChartBarsImportResponseModel = new ChartBarsImportResponseModel()
    this._ChartBarsImportNotifyModel = new ChartBarsImportNotifyModel()
    this._JournalBusinessRequestModel = new JournalBusinessRequestModel()
    this._JournalBusinessResponseModel = new JournalBusinessResponseModel()
    this._AccountsGetPageRequestModel = new AccountsGetPageRequestModel()
    this._AccountsGetPageResponseModel = new AccountsGetPageResponseModel()
    this._HeartbeatNotifyModel = new HeartbeatNotifyModel()
    this._OrdersClosedSummariesGetRequestModel = new OrdersClosedSummariesGetRequestModel()
    this._OrdersClosedSummariesGetResponseModel = new OrdersClosedSummariesGetResponseModel()
    this._OrdersGetPageRequestModel = new OrdersGetPageRequestModel()
    this._OrdersGetPageResponseModel = new OrdersGetPageResponseModel()
    this._StorageUniversalNotifyModel = new StorageUniversalNotifyModel()
    this._ClientLoginInfoGetRequestModel = new ClientLoginInfoGetRequestModel()
    this._ClientLoginInfoGetResponseModel = new ClientLoginInfoGetResponseModel()
    this._AccountGroupExistsGetRequestModel = new AccountGroupExistsGetRequestModel()
    this._AccountGroupExistsGetResponseModel = new AccountGroupExistsGetResponseModel()
    this._LoginWithPasswordRequestModel = new LoginWithPasswordRequestModel()
    this._LoginWithPasswordResponseModel = new LoginWithPasswordResponseModel()
    this._LoginWithTokenRequestModel = new LoginWithTokenRequestModel()
    this._LoginWithTokenResponseModel = new LoginWithTokenResponseModel()
    this._RefreshTokenRequestModel = new RefreshTokenRequestModel()
    this._RefreshTokenResponseModel = new RefreshTokenResponseModel()
    this._PersonGetRequestModel = new PersonGetRequestModel()
    this._PersonGetResponseModel = new PersonGetResponseModel()
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel()
    this._PersonCreateRequestModel = new PersonCreateRequestModel()
    this._PersonCreateResponseModel = new PersonCreateResponseModel()
    this._PersonModifyRequestModel = new PersonModifyRequestModel()
    this._PersonModifyResponseModel = new PersonModifyResponseModel()
    this._PersonUpdateNotifyModel = new PersonUpdateNotifyModel()
    this._SecuritySettingsGetRequestModel = new SecuritySettingsGetRequestModel()
    this._SecuritySettingsGetResponseModel = new SecuritySettingsGetResponseModel()
    this._SecuritySettingsGetAllRequestModel = new SecuritySettingsGetAllRequestModel()
    this._SecuritySettingsGetAllResponseModel = new SecuritySettingsGetAllResponseModel()
    this._SecuritySettingsCreateRequestModel = new SecuritySettingsCreateRequestModel()
    this._SecuritySettingsCreateResponseModel = new SecuritySettingsCreateResponseModel()
    this._SecuritySettingsModifyRequestModel = new SecuritySettingsModifyRequestModel()
    this._SecuritySettingsModifyResponseModel = new SecuritySettingsModifyResponseModel()
    this._SecuritySettingsDeleteRequestModel = new SecuritySettingsDeleteRequestModel()
    this._SecuritySettingsDeleteResponseModel = new SecuritySettingsDeleteResponseModel()
    this._SecuritySettingsUpdateNotifyModel = new SecuritySettingsUpdateNotifyModel()
    this._FirewallRuleGetRequestModel = new FirewallRuleGetRequestModel()
    this._FirewallRuleGetResponseModel = new FirewallRuleGetResponseModel()
    this._FirewallRulesGetAllRequestModel = new FirewallRulesGetAllRequestModel()
    this._FirewallRulesGetAllResponseModel = new FirewallRulesGetAllResponseModel()
    this._FirewallRuleCreateRequestModel = new FirewallRuleCreateRequestModel()
    this._FirewallRuleCreateResponseModel = new FirewallRuleCreateResponseModel()
    this._FirewallRuleModifyRequestModel = new FirewallRuleModifyRequestModel()
    this._FirewallRuleModifyResponseModel = new FirewallRuleModifyResponseModel()
    this._FirewallRuleDeleteRequestModel = new FirewallRuleDeleteRequestModel()
    this._FirewallRuleDeleteResponseModel = new FirewallRuleDeleteResponseModel()
    this._FirewallRuleUpdateNotifyModel = new FirewallRuleUpdateNotifyModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  /**
   * Get imported config proxy
   * @this {!Proxy}
   * @returns {Proxy} config proxy
   */
  get configProxy () {
    return this._configProxy
  }

  /**
   * Set imported config proxy
   * @this {!Proxy}
   * @param {Proxy} proxy config proxy
   */
  set configProxy (proxy) {
    this._configProxy = proxy
  }

  /**
   * Get imported history proxy
   * @this {!Proxy}
   * @returns {Proxy} history proxy
   */
  get historyProxy () {
    return this._historyProxy
  }

  /**
   * Set imported history proxy
   * @this {!Proxy}
   * @param {Proxy} proxy history proxy
   */
  set historyProxy (proxy) {
    this._historyProxy = proxy
  }

  /**
   * Get imported client proxy
   * @this {!Proxy}
   * @returns {Proxy} client proxy
   */
  get clientProxy () {
    return this._clientProxy
  }

  /**
   * Set imported client proxy
   * @this {!Proxy}
   * @param {Proxy} proxy client proxy
   */
  set clientProxy (proxy) {
    this._clientProxy = proxy
  }

  /**
   * Get imported feed proxy
   * @this {!Proxy}
   * @returns {Proxy} feed proxy
   */
  get feedProxy () {
    return this._feedProxy
  }

  /**
   * Set imported feed proxy
   * @this {!Proxy}
   * @param {Proxy} proxy feed proxy
   */
  set feedProxy (proxy) {
    this._feedProxy = proxy
  }

  /**
   * Get imported storage proxy
   * @this {!Proxy}
   * @returns {Proxy} storage proxy
   */
  get storageProxy () {
    return this._storageProxy
  }

  /**
   * Set imported storage proxy
   * @this {!Proxy}
   * @param {Proxy} proxy storage proxy
   */
  set storageProxy (proxy) {
    this._storageProxy = proxy
  }

  /**
   * Get imported exposed proxy
   * @this {!Proxy}
   * @returns {Proxy} exposed proxy
   */
  get exposedProxy () {
    return this._exposedProxy
  }

  /**
   * Set imported exposed proxy
   * @this {!Proxy}
   * @param {Proxy} proxy exposed proxy
   */
  set exposedProxy (proxy) {
    this._exposedProxy = proxy
  }

  /**
   * Get imported execution proxy
   * @this {!Proxy}
   * @returns {Proxy} execution proxy
   */
  get executionProxy () {
    return this._executionProxy
  }

  /**
   * Set imported execution proxy
   * @this {!Proxy}
   * @param {Proxy} proxy execution proxy
   */
  set executionProxy (proxy) {
    this._executionProxy = proxy
  }

  /**
   * Get imported backup proxy
   * @this {!Proxy}
   * @returns {Proxy} backup proxy
   */
  get backupProxy () {
    return this._backupProxy
  }

  /**
   * Set imported backup proxy
   * @this {!Proxy}
   * @param {Proxy} proxy backup proxy
   */
  set backupProxy (proxy) {
    this._backupProxy = proxy
  }

  /**
   * Get imported inner proxy
   * @this {!Proxy}
   * @returns {Proxy} inner proxy
   */
  get innerProxy () {
    return this._innerProxy
  }

  /**
   * Set imported inner proxy
   * @this {!Proxy}
   * @param {Proxy} proxy inner proxy
   */
  set innerProxy (proxy) {
    this._innerProxy = proxy
  }

  /**
   * Get imported trade proxy
   * @this {!Proxy}
   * @returns {Proxy} trade proxy
   */
  get tradeProxy () {
    return this._tradeProxy
  }

  /**
   * Set imported trade proxy
   * @this {!Proxy}
   * @param {Proxy} proxy trade proxy
   */
  set tradeProxy (proxy) {
    this._tradeProxy = proxy
  }

  // Proxy handlers

  /**
   * PingModuleRequest proxy handler
   * @this {!Proxy}
   * @param {!PingModuleRequest} model PingModuleRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PingModuleRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PongModuleResponse proxy handler
   * @this {!Proxy}
   * @param {!PongModuleResponse} model PongModuleResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PongModuleResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PingModuleReject proxy handler
   * @this {!Proxy}
   * @param {!PingModuleReject} model PingModuleReject model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PingModuleReject (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated3 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated3} model Deprecated3 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated3 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated4 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated4} model Deprecated4 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated4 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated1 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated1} model Deprecated1 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated1 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated2 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated2} model Deprecated2 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated2 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerSessionInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerSessionInfoRequest} model ManagerSessionInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerSessionInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerSessionInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerSessionInfoResponse} model ManagerSessionInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerSessionInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OnlineSessionsRequest proxy handler
   * @this {!Proxy}
   * @param {!OnlineSessionsRequest} model OnlineSessionsRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OnlineSessionsRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OnlineSessionsResponse proxy handler
   * @this {!Proxy}
   * @param {!OnlineSessionsResponse} model OnlineSessionsResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OnlineSessionsResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DropSessionRequest proxy handler
   * @this {!Proxy}
   * @param {!DropSessionRequest} model DropSessionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DropSessionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DropSessionResponse proxy handler
   * @this {!Proxy}
   * @param {!DropSessionResponse} model DropSessionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DropSessionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigRequest} model ConfigRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigResponse} model ConfigResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigReject proxy handler
   * @this {!Proxy}
   * @param {!ConfigReject} model ConfigReject model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigReject (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigUpdateRequest} model ConfigUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigUpdateResponse} model ConfigUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ConfigUpdateNotify} model ConfigUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigVersionRequest} model ConfigVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigVersionResponse} model ConfigVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigExportRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigExportRequest} model ConfigExportRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigExportRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigExportResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigExportResponse} model ConfigExportResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigExportResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigImportRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigImportRequest} model ConfigImportRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigImportRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigImportResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigImportResponse} model ConfigImportResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigImportResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigValidateRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigValidateRequest} model ConfigValidateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigValidateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigValidateResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigValidateResponse} model ConfigValidateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigValidateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRefreshRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigRefreshRequest} model ConfigRefreshRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRefreshRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRefreshResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigRefreshResponse} model ConfigRefreshResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRefreshResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigHistoryRequest} model ConfigHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigHistoryResponse} model ConfigHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRollbackRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigRollbackRequest} model ConfigRollbackRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRollbackRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRollbackResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigRollbackResponse} model ConfigRollbackResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRollbackResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostGetRequest proxy handler
   * @this {!Proxy}
   * @param {!HostGetRequest} model HostGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostGetResponse proxy handler
   * @this {!Proxy}
   * @param {!HostGetResponse} model HostGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!HostsGetAllRequest} model HostsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!HostsGetAllResponse} model HostsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!HostCreateRequest} model HostCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!HostCreateResponse} model HostCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!HostModifyRequest} model HostModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!HostModifyResponse} model HostModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!HostDeleteRequest} model HostDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!HostDeleteResponse} model HostDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HostUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!HostUpdateNotify} model HostUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HostUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ModuleGetRequest} model ModuleGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ModuleGetResponse} model ModuleGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModulesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!ModulesGetAllRequest} model ModulesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModulesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModulesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!ModulesGetAllResponse} model ModulesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModulesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!ModuleCreateRequest} model ModuleCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!ModuleCreateResponse} model ModuleCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!ModuleModifyRequest} model ModuleModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!ModuleModifyResponse} model ModuleModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!ModuleDeleteRequest} model ModuleDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!ModuleDeleteResponse} model ModuleDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ModuleUpdateNotify} model ModuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ServerTimeGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ServerTimeGetRequest} model ServerTimeGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ServerTimeGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ServerTimeGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ServerTimeGetResponse} model ServerTimeGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ServerTimeGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerPlatformTimezoneGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerPlatformTimezoneGetRequest} model ManagerPlatformTimezoneGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerPlatformTimezoneGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerPlatformTimezoneGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerPlatformTimezoneGetResponse} model ManagerPlatformTimezoneGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerPlatformTimezoneGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TimezoneGetRequest} model TimezoneGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TimezoneGetResponse} model TimezoneGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezonesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!TimezonesGetAllRequest} model TimezonesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezonesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezonesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!TimezonesGetAllResponse} model TimezonesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezonesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!TimezoneCreateRequest} model TimezoneCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!TimezoneCreateResponse} model TimezoneCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!TimezoneModifyRequest} model TimezoneModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!TimezoneModifyResponse} model TimezoneModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!TimezoneDeleteRequest} model TimezoneDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!TimezoneDeleteResponse} model TimezoneDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TimezoneUpdateNotify} model TimezoneUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupGetRequest} model CurrencyGroupGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupGetResponse} model CurrencyGroupGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupsGetAllRequest} model CurrencyGroupsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupsGetAllResponse} model CurrencyGroupsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupCreateRequest} model CurrencyGroupCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupCreateResponse} model CurrencyGroupCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupModifyRequest} model CurrencyGroupModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupModifyResponse} model CurrencyGroupModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupDeleteRequest} model CurrencyGroupDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupDeleteResponse} model CurrencyGroupDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupUpdateNotify} model CurrencyGroupUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGetRequest} model CurrencyGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGetResponse} model CurrencyGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrenciesGetAllRequest} model CurrenciesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrenciesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrenciesGetAllResponse} model CurrenciesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrenciesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyCreateRequest} model CurrencyCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyCreateResponse} model CurrencyCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyModifyRequest} model CurrencyModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyModifyResponse} model CurrencyModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyDeleteRequest} model CurrencyDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyDeleteResponse} model CurrencyDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CurrencyUpdateNotify} model CurrencyUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupGetRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupGetRequest} model SymbolGroupGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupGetResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupGetResponse} model SymbolGroupGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupsGetAllRequest} model SymbolGroupsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupsGetAllResponse} model SymbolGroupsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupCreateRequest} model SymbolGroupCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupCreateResponse} model SymbolGroupCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupModifyRequest} model SymbolGroupModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupModifyResponse} model SymbolGroupModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupDeleteRequest} model SymbolGroupDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupDeleteResponse} model SymbolGroupDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupUpdateNotify} model SymbolGroupUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGetRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGetRequest} model SymbolGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGetResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGetResponse} model SymbolGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolsGetAllRequest} model SymbolsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolsGetAllResponse} model SymbolsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolCreateRequest} model SymbolCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolCreateResponse} model SymbolCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolModifyRequest} model SymbolModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolModifyResponse} model SymbolModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolDeleteRequest} model SymbolDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolDeleteResponse} model SymbolDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolUpdateNotify} model SymbolUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SwapsExportRequest proxy handler
   * @this {!Proxy}
   * @param {!SwapsExportRequest} model SwapsExportRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SwapsExportRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SwapsExportResponse proxy handler
   * @this {!Proxy}
   * @param {!SwapsExportResponse} model SwapsExportResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SwapsExportResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SwapsImportRequest proxy handler
   * @this {!Proxy}
   * @param {!SwapsImportRequest} model SwapsImportRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SwapsImportRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SwapsImportResponse proxy handler
   * @this {!Proxy}
   * @param {!SwapsImportResponse} model SwapsImportResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SwapsImportResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileGetRequest} model CommissionProfileGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileGetResponse} model CommissionProfileGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfilesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfilesGetAllRequest} model CommissionProfilesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfilesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfilesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfilesGetAllResponse} model CommissionProfilesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfilesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileCreateRequest} model CommissionProfileCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileCreateResponse} model CommissionProfileCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileModifyRequest} model CommissionProfileModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileModifyResponse} model CommissionProfileModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileDeleteRequest} model CommissionProfileDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileDeleteResponse} model CommissionProfileDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CommissionProfileUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CommissionProfileUpdateNotify} model CommissionProfileUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CommissionProfileUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileGetRequest proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileGetRequest} model MarkupProfileGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileGetResponse proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileGetResponse} model MarkupProfileGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfilesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfilesGetAllRequest} model MarkupProfilesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfilesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfilesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfilesGetAllResponse} model MarkupProfilesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfilesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileCreateRequest} model MarkupProfileCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileCreateResponse} model MarkupProfileCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileModifyRequest} model MarkupProfileModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileModifyResponse} model MarkupProfileModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileDeleteRequest} model MarkupProfileDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileDeleteResponse} model MarkupProfileDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarkupProfileUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!MarkupProfileUpdateNotify} model MarkupProfileUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarkupProfileUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupGetRequest} model ManagerGroupGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupGetResponse} model ManagerGroupGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupsGetAllRequest} model ManagerGroupsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupsGetAllResponse} model ManagerGroupsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupCreateRequest} model ManagerGroupCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupCreateResponse} model ManagerGroupCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupModifyRequest} model ManagerGroupModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupModifyResponse} model ManagerGroupModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupDeleteRequest} model ManagerGroupDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupDeleteResponse} model ManagerGroupDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGroupUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ManagerGroupUpdateNotify} model ManagerGroupUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGroupUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerGetRequest} model ManagerGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerGetResponse} model ManagerGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagersGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagersGetAllRequest} model ManagersGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagersGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagersGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagersGetAllResponse} model ManagersGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagersGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerCreateRequest} model ManagerCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerCreateResponse} model ManagerCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerModifyRequest} model ManagerModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerModifyResponse} model ManagerModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!ManagerDeleteRequest} model ManagerDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!ManagerDeleteResponse} model ManagerDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ManagerUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ManagerUpdateNotify} model ManagerUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ManagerUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformGetRequest proxy handler
   * @this {!Proxy}
   * @param {!PlatformGetRequest} model PlatformGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformGetResponse proxy handler
   * @this {!Proxy}
   * @param {!PlatformGetResponse} model PlatformGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!PlatformsGetAllRequest} model PlatformsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!PlatformsGetAllResponse} model PlatformsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!PlatformCreateRequest} model PlatformCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!PlatformCreateResponse} model PlatformCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!PlatformModifyRequest} model PlatformModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!PlatformModifyResponse} model PlatformModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!PlatformDeleteRequest} model PlatformDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!PlatformDeleteResponse} model PlatformDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!PlatformUpdateNotify} model PlatformUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupGetRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupGetRequest} model AccountGroupGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupGetResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupGetResponse} model AccountGroupGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupsGetAllRequest} model AccountGroupsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupsGetAllResponse} model AccountGroupsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupCreateRequest} model AccountGroupCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupCreateResponse} model AccountGroupCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupModifyRequest} model AccountGroupModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupModifyResponse} model AccountGroupModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupDeleteRequest} model AccountGroupDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupDeleteResponse} model AccountGroupDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupUpdateNotify} model AccountGroupUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGetRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountGetRequest} model AccountGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGetResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountGetResponse} model AccountGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetAllRequest} model AccountsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetAllResponse} model AccountsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountCreateRequest} model AccountCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountCreateResponse} model AccountCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingMasterCreateRequest} model AccountSocialTradingMasterCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingMasterCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingMasterCreateResponse} model AccountSocialTradingMasterCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingMasterCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingInvestorCreateRequest} model AccountSocialTradingInvestorCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingInvestorCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingInvestorCreateResponse} model AccountSocialTradingInvestorCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingInvestorCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountModifyRequest} model AccountModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountModifyResponse} model AccountModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountDeleteRequest} model AccountDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountDeleteResponse} model AccountDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DemoAccountsDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!DemoAccountsDeleteRequest} model DemoAccountsDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DemoAccountsDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DemoAccountsDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!DemoAccountsDeleteResponse} model DemoAccountsDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DemoAccountsDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingMasterRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountUpgradeToSocialTradingMasterRequest} model AccountUpgradeToSocialTradingMasterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpgradeToSocialTradingMasterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingMasterResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountUpgradeToSocialTradingMasterResponse} model AccountUpgradeToSocialTradingMasterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpgradeToSocialTradingMasterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingInvestorRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountUpgradeToSocialTradingInvestorRequest} model AccountUpgradeToSocialTradingInvestorRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpgradeToSocialTradingInvestorRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingInvestorResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountUpgradeToSocialTradingInvestorResponse} model AccountUpgradeToSocialTradingInvestorResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpgradeToSocialTradingInvestorResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SocialTradingInvestorAccountToRegularRequest proxy handler
   * @this {!Proxy}
   * @param {!SocialTradingInvestorAccountToRegularRequest} model SocialTradingInvestorAccountToRegularRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SocialTradingInvestorAccountToRegularRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SocialTradingInvestorAccountToRegularResponse proxy handler
   * @this {!Proxy}
   * @param {!SocialTradingInvestorAccountToRegularResponse} model SocialTradingInvestorAccountToRegularResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SocialTradingInvestorAccountToRegularResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SocialTradingMasterAccountToRegularRequest proxy handler
   * @this {!Proxy}
   * @param {!SocialTradingMasterAccountToRegularRequest} model SocialTradingMasterAccountToRegularRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SocialTradingMasterAccountToRegularRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SocialTradingMasterAccountToRegularResponse proxy handler
   * @this {!Proxy}
   * @param {!SocialTradingMasterAccountToRegularResponse} model SocialTradingMasterAccountToRegularResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SocialTradingMasterAccountToRegularResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!AccountUpdateNotify} model AccountUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginGetRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginGetRequest} model MarginGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginGetResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginGetResponse} model MarginGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginsGetAllRequest} model MarginsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginsGetAllResponse} model MarginsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!MarginUpdateNotify} model MarginUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginCallNotify proxy handler
   * @this {!Proxy}
   * @param {!MarginCallNotify} model MarginCallNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginCallNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StopOutNotify proxy handler
   * @this {!Proxy}
   * @param {!StopOutNotify} model StopOutNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StopOutNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetGetRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetGetRequest} model AssetGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetGetResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetGetResponse} model AssetGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetRequest} model AssetsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetResponse} model AssetsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetAllRequest} model AssetsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetAllResponse} model AssetsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!AssetUpdateNotify} model AssetUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderGetRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderGetRequest} model OrderGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderGetResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderGetResponse} model OrderGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetRequest proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetRequest} model OrdersGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetResponse proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetResponse} model OrdersGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetAllRequest} model OrdersGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetAllResponse} model OrdersGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCreateRequest} model OrderCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCreateResponse} model OrderCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderModifyRequest} model OrderModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderModifyResponse} model OrderModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderActivateRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderActivateRequest} model OrderActivateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderActivateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderActivateResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderActivateResponse} model OrderActivateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderActivateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelRequest} model OrderCancelRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelResponse} model OrderCancelResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelAllRequest} model OrderCancelAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelAllResponse} model OrderCancelAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelAllByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelAllByIdRequest} model OrderCancelAllByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelAllByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseRequest} model OrderCloseRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseResponse} model OrderCloseResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseAllRequest} model OrderCloseAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseAllResponse} model OrderCloseAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseAllByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseAllByIdRequest} model OrderCloseAllByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseAllByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByRequest} model OrderCloseByRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByResponse} model OrderCloseByResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByAllRequest} model OrderCloseByAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByAllResponse} model OrderCloseByAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseRequest} model OrderCancelOrCloseRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseResponse} model OrderCancelOrCloseResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseAllRequest} model OrderCancelOrCloseAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseAllResponse} model OrderCancelOrCloseAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateNotify} model OrderUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionGetRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionGetRequest} model PositionGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionGetResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionGetResponse} model PositionGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetRequest} model PositionsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetResponse} model PositionsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetAllRequest} model PositionsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetAllResponse} model PositionsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!PositionUpdateNotify} model PositionUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryRequest} model TradeHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryResponse} model TradeHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryFilterRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryFilterRequest} model TradeHistoryFilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryFilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryByIdRequest} model TradeHistoryByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryByIdResponse} model TradeHistoryByIdResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryByIdResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryUpdateNotify} model TradeHistoryUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TestRolloverDumpRequest proxy handler
   * @this {!Proxy}
   * @param {!TestRolloverDumpRequest} model TestRolloverDumpRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TestRolloverDumpRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TestRolloverDumpResponse proxy handler
   * @this {!Proxy}
   * @param {!TestRolloverDumpResponse} model TestRolloverDumpResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TestRolloverDumpResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated001 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated001} model Deprecated001 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated001 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated002 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated002} model Deprecated002 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated002 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated003 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated003} model Deprecated003 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated003 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated004 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated004} model Deprecated004 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated004 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated005 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated005} model Deprecated005 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated005 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated006 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated006} model Deprecated006 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated006 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated007 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated007} model Deprecated007 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated007 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated008 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated008} model Deprecated008 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated008 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated009 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated009} model Deprecated009 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated009 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated010 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated010} model Deprecated010 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated010 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated011 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated011} model Deprecated011 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated011 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated012 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated012} model Deprecated012 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated012 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated013 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated013} model Deprecated013 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated013 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DepositAccountRequest proxy handler
   * @this {!Proxy}
   * @param {!DepositAccountRequest} model DepositAccountRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DepositAccountRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DepositAccountResponse proxy handler
   * @this {!Proxy}
   * @param {!DepositAccountResponse} model DepositAccountResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DepositAccountResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CreditAccountRequest proxy handler
   * @this {!Proxy}
   * @param {!CreditAccountRequest} model CreditAccountRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CreditAccountRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CreditAccountResponse proxy handler
   * @this {!Proxy}
   * @param {!CreditAccountResponse} model CreditAccountResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CreditAccountResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TransferMoneyRequest proxy handler
   * @this {!Proxy}
   * @param {!TransferMoneyRequest} model TransferMoneyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TransferMoneyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TransferMoneyResponse proxy handler
   * @this {!Proxy}
   * @param {!TransferMoneyResponse} model TransferMoneyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TransferMoneyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleGetRequest} model TradingCalendarRegularRuleGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleGetResponse} model TradingCalendarRegularRuleGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRulesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRulesGetAllRequest} model TradingCalendarRegularRulesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRulesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRulesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRulesGetAllResponse} model TradingCalendarRegularRulesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRulesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleCreateRequest} model TradingCalendarRegularRuleCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleCreateResponse} model TradingCalendarRegularRuleCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleModifyRequest} model TradingCalendarRegularRuleModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleModifyResponse} model TradingCalendarRegularRuleModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleDeleteRequest} model TradingCalendarRegularRuleDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleDeleteResponse} model TradingCalendarRegularRuleDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRegularRuleUpdateNotify} model TradingCalendarRegularRuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRegularRuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleGetRequest} model TradingCalendarOffTimeRuleGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleGetResponse} model TradingCalendarOffTimeRuleGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRulesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRulesGetAllRequest} model TradingCalendarOffTimeRulesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRulesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRulesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRulesGetAllResponse} model TradingCalendarOffTimeRulesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRulesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} model TradingCalendarOffTimeRuleCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleCreateResponse} model TradingCalendarOffTimeRuleCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} model TradingCalendarOffTimeRuleModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleModifyResponse} model TradingCalendarOffTimeRuleModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleDeleteRequest} model TradingCalendarOffTimeRuleDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleDeleteResponse} model TradingCalendarOffTimeRuleDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarOffTimeRuleUpdateNotify} model TradingCalendarOffTimeRuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarOffTimeRuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRulesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRulesGetRequest} model TradingCalendarRulesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRulesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingCalendarRulesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingCalendarRulesGetResponse} model TradingCalendarRulesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingCalendarRulesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRulesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRulesGetRequest} model TradingRouteRulesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRulesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRulesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRulesGetResponse} model TradingRouteRulesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRulesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRuleCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRuleCreateRequest} model TradingRouteRuleCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRuleCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRuleCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRuleCreateResponse} model TradingRouteRuleCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRuleCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRuleModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRuleModifyRequest} model TradingRouteRuleModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRuleModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRuleModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRuleModifyResponse} model TradingRouteRuleModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRuleModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRuleDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRuleDeleteRequest} model TradingRouteRuleDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRuleDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRuleDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRuleDeleteResponse} model TradingRouteRuleDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRuleDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradingRouteRuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TradingRouteRuleUpdateNotify} model TradingRouteRuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradingRouteRuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapsGetRequest} model SymbolMapsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapsGetResponse} model SymbolMapsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapCreateRequest} model SymbolMapCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapCreateResponse} model SymbolMapCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapModifyRequest} model SymbolMapModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapModifyResponse} model SymbolMapModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapDeleteRequest} model SymbolMapDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapDeleteResponse} model SymbolMapDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolMapUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolMapUpdateNotify} model SymbolMapUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolMapUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewaysGetRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewaysGetRequest} model GatewaysGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewaysGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewaysGetResponse proxy handler
   * @this {!Proxy}
   * @param {!GatewaysGetResponse} model GatewaysGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewaysGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayCreateRequest} model GatewayCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!GatewayCreateResponse} model GatewayCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayModifyRequest} model GatewayModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!GatewayModifyResponse} model GatewayModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayDeleteRequest} model GatewayDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!GatewayDeleteResponse} model GatewayDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!GatewayUpdateNotify} model GatewayUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedsGetRequest} model DataFeedsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!DataFeedsGetResponse} model DataFeedsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedCreateRequest} model DataFeedCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!DataFeedCreateResponse} model DataFeedCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedModifyRequest} model DataFeedModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!DataFeedModifyResponse} model DataFeedModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedDeleteRequest} model DataFeedDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!DataFeedDeleteResponse} model DataFeedDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!DataFeedUpdateNotify} model DataFeedUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!ModuleSystemInfoNotify} model ModuleSystemInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleSystemInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!ModulesSystemInfoGetAllRequest} model ModulesSystemInfoGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModulesSystemInfoGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!ModulesSystemInfoGetAllResponse} model ModulesSystemInfoGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModulesSystemInfoGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!ModulesSystemInfoNotify} model ModulesSystemInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModulesSystemInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryModifyRequest} model ChartBarHistoryModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryModifyResponse} model ChartBarHistoryModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateTickSnapshotRequest} model FeedUpdateTickSnapshotRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateTickSnapshotRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateTickSnapshotResponse} model FeedUpdateTickSnapshotResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateTickSnapshotResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateTickRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateTickRequest} model FeedUpdateTickRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateTickRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateTickResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateTickResponse} model FeedUpdateTickResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateTickResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateLevel2SnapshotRequest} model FeedUpdateLevel2SnapshotRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateLevel2SnapshotRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateLevel2SnapshotResponse} model FeedUpdateLevel2SnapshotResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateLevel2SnapshotResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Request proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateLevel2Request} model FeedUpdateLevel2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateLevel2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Response proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateLevel2Response} model FeedUpdateLevel2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateLevel2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerGetRequest proxy handler
   * @this {!Proxy}
   * @param {!MailServerGetRequest} model MailServerGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerGetResponse proxy handler
   * @this {!Proxy}
   * @param {!MailServerGetResponse} model MailServerGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServersGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!MailServersGetAllRequest} model MailServersGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServersGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServersGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!MailServersGetAllResponse} model MailServersGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServersGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!MailServerCreateRequest} model MailServerCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!MailServerCreateResponse} model MailServerCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!MailServerModifyRequest} model MailServerModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!MailServerModifyResponse} model MailServerModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!MailServerDeleteRequest} model MailServerDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!MailServerDeleteResponse} model MailServerDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MailServerUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!MailServerUpdateNotify} model MailServerUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MailServerUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountStatesUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!AccountStatesUpdateNotify} model AccountStatesUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountStatesUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountStatesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountStatesGetAllRequest} model AccountStatesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountStatesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountStatesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountStatesGetAllResponse} model AccountStatesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountStatesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleAuthRequest proxy handler
   * @this {!Proxy}
   * @param {!ModuleAuthRequest} model ModuleAuthRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleAuthRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleAuthResponse proxy handler
   * @this {!Proxy}
   * @param {!ModuleAuthResponse} model ModuleAuthResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleAuthResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileGetRequest proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileGetRequest} model LeverageProfileGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileGetResponse proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileGetResponse} model LeverageProfileGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfilesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfilesGetAllRequest} model LeverageProfilesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfilesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfilesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfilesGetAllResponse} model LeverageProfilesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfilesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileCreateRequest} model LeverageProfileCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileCreateResponse} model LeverageProfileCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileModifyRequest} model LeverageProfileModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileModifyResponse} model LeverageProfileModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileDeleteRequest} model LeverageProfileDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileDeleteResponse} model LeverageProfileDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageProfileUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!LeverageProfileUpdateNotify} model LeverageProfileUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageProfileUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileGetRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileGetRequest} model MarginRateProfileGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileGetResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileGetResponse} model MarginRateProfileGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfilesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfilesGetAllRequest} model MarginRateProfilesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfilesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfilesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfilesGetAllResponse} model MarginRateProfilesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfilesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileCreateRequest} model MarginRateProfileCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileCreateResponse} model MarginRateProfileCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileModifyRequest} model MarginRateProfileModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileModifyResponse} model MarginRateProfileModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileDeleteRequest} model MarginRateProfileDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileDeleteResponse} model MarginRateProfileDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginRateProfileUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!MarginRateProfileUpdateNotify} model MarginRateProfileUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginRateProfileUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileGetRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileGetRequest} model NewsProfileGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileGetResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileGetResponse} model NewsProfileGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfilesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsProfilesGetAllRequest} model NewsProfilesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfilesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfilesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsProfilesGetAllResponse} model NewsProfilesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfilesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileCreateRequest} model NewsProfileCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileCreateResponse} model NewsProfileCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileModifyRequest} model NewsProfileModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileModifyResponse} model NewsProfileModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileDeleteRequest} model NewsProfileDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileDeleteResponse} model NewsProfileDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileUpdateNotify} model NewsProfileUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileImportRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileImportRequest} model NewsProfileImportRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileImportRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsProfileImportResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsProfileImportResponse} model NewsProfileImportResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsProfileImportResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!TickHistoryRequest} model TickHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!TickHistoryResponse} model TickHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateGetRequest proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateGetRequest} model EmailTemplateGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateGetResponse proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateGetResponse} model EmailTemplateGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplatesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplatesGetAllRequest} model EmailTemplatesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplatesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplatesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplatesGetAllResponse} model EmailTemplatesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplatesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateCreateRequest} model EmailTemplateCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateCreateResponse} model EmailTemplateCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateModifyRequest} model EmailTemplateModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateModifyResponse} model EmailTemplateModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateDeleteRequest} model EmailTemplateDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateDeleteResponse} model EmailTemplateDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * EmailTemplateUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!EmailTemplateUpdateNotify} model EmailTemplateUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_EmailTemplateUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderStateSubscribeRequest} model OrderStateSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderStateSubscribeResponse} model OrderStateSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateUpdateSubscriptionRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderStateUpdateSubscriptionRequest} model OrderStateUpdateSubscriptionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateUpdateSubscriptionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateUpdateSubscriptionResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderStateUpdateSubscriptionResponse} model OrderStateUpdateSubscriptionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateUpdateSubscriptionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderStateUnsubscribeRequest} model OrderStateUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderStateUnsubscribeResponse} model OrderStateUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateNotify proxy handler
   * @this {!Proxy}
   * @param {!OrderStateNotify} model OrderStateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RequestMonitoringSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!RequestMonitoringSubscribeRequest} model RequestMonitoringSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RequestMonitoringSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RequestMonitoringSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!RequestMonitoringSubscribeResponse} model RequestMonitoringSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RequestMonitoringSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RequestMonitoringUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!RequestMonitoringUnsubscribeRequest} model RequestMonitoringUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RequestMonitoringUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RequestMonitoringUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!RequestMonitoringUnsubscribeResponse} model RequestMonitoringUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RequestMonitoringUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RequestMonitoringRequestUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!RequestMonitoringRequestUpdateNotify} model RequestMonitoringRequestUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RequestMonitoringRequestUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!FilledOrdersSummaryInfoSubscribeRequest} model FilledOrdersSummaryInfoSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FilledOrdersSummaryInfoSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!FilledOrdersSummaryInfoSubscribeResponse} model FilledOrdersSummaryInfoSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FilledOrdersSummaryInfoSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!FilledOrdersSummaryInfoNotify} model FilledOrdersSummaryInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FilledOrdersSummaryInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUpdateSubscriptionRequest proxy handler
   * @this {!Proxy}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} model FilledOrdersSummaryInfoUpdateSubscriptionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FilledOrdersSummaryInfoUpdateSubscriptionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!FilledOrdersSummaryInfoUnsubscribeRequest} model FilledOrdersSummaryInfoUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FilledOrdersSummaryInfoUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!FilledOrdersSummaryInfoUnsubscribeResponse} model FilledOrdersSummaryInfoUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FilledOrdersSummaryInfoUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsNotify proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsNotify} model TradeEventsNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsGetRequest} model TradeEventsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsGetResponse} model TradeEventsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * JournalRequest proxy handler
   * @this {!Proxy}
   * @param {!JournalRequest} model JournalRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_JournalRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * JournalResponse proxy handler
   * @this {!Proxy}
   * @param {!JournalResponse} model JournalResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_JournalResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsListGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsListGetRequest} model ClientReportsListGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsListGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsListGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsListGetResponse} model ClientReportsListGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsListGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportGetRequest} model ClientReportGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportGetResponse} model ClientReportGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsSendRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsSendRequest} model ClientReportsSendRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsSendRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsSendResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsSendResponse} model ClientReportsSendResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsSendResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedGetRequest} model ProfitClosedGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedGetResponse} model ProfitClosedGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarsImportRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarsImportRequest} model ChartBarsImportRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarsImportRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarsImportResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarsImportResponse} model ChartBarsImportResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarsImportResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarsImportNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarsImportNotify} model ChartBarsImportNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarsImportNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * JournalBusinessRequest proxy handler
   * @this {!Proxy}
   * @param {!JournalBusinessRequest} model JournalBusinessRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_JournalBusinessRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * JournalBusinessResponse proxy handler
   * @this {!Proxy}
   * @param {!JournalBusinessResponse} model JournalBusinessResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_JournalBusinessResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountsGetPageRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetPageRequest} model AccountsGetPageRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetPageRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountsGetPageResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetPageResponse} model AccountsGetPageResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetPageResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HeartbeatNotify proxy handler
   * @this {!Proxy}
   * @param {!HeartbeatNotify} model HeartbeatNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HeartbeatNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!OrdersClosedSummariesGetRequest} model OrdersClosedSummariesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersClosedSummariesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!OrdersClosedSummariesGetResponse} model OrdersClosedSummariesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersClosedSummariesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetPageRequest proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetPageRequest} model OrdersGetPageRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetPageRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetPageResponse proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetPageResponse} model OrdersGetPageResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetPageResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StorageUniversalNotify proxy handler
   * @this {!Proxy}
   * @param {!StorageUniversalNotify} model StorageUniversalNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StorageUniversalNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientLoginInfoGetRequest} model ClientLoginInfoGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientLoginInfoGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientLoginInfoGetResponse} model ClientLoginInfoGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientLoginInfoGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupExistsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupExistsGetRequest} model AccountGroupExistsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupExistsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountGroupExistsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountGroupExistsGetResponse} model AccountGroupExistsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountGroupExistsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithPasswordRequest proxy handler
   * @this {!Proxy}
   * @param {!LoginWithPasswordRequest} model LoginWithPasswordRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithPasswordRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithPasswordResponse proxy handler
   * @this {!Proxy}
   * @param {!LoginWithPasswordResponse} model LoginWithPasswordResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithPasswordResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithTokenRequest proxy handler
   * @this {!Proxy}
   * @param {!LoginWithTokenRequest} model LoginWithTokenRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithTokenRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithTokenResponse proxy handler
   * @this {!Proxy}
   * @param {!LoginWithTokenResponse} model LoginWithTokenResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithTokenResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RefreshTokenRequest proxy handler
   * @this {!Proxy}
   * @param {!RefreshTokenRequest} model RefreshTokenRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RefreshTokenRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RefreshTokenResponse proxy handler
   * @this {!Proxy}
   * @param {!RefreshTokenResponse} model RefreshTokenResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RefreshTokenResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonGetRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonGetRequest} model PersonGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonGetResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonGetResponse} model PersonGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonsGetAllRequest} model PersonsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonsGetAllResponse} model PersonsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonCreateRequest} model PersonCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonCreateResponse} model PersonCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonModifyRequest} model PersonModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonModifyResponse} model PersonModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!PersonUpdateNotify} model PersonUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsGetRequest} model SecuritySettingsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsGetResponse} model SecuritySettingsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsGetAllRequest} model SecuritySettingsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsGetAllResponse} model SecuritySettingsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsCreateRequest} model SecuritySettingsCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsCreateResponse} model SecuritySettingsCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsModifyRequest} model SecuritySettingsModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsModifyResponse} model SecuritySettingsModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsDeleteRequest} model SecuritySettingsDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsDeleteResponse} model SecuritySettingsDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SecuritySettingsUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SecuritySettingsUpdateNotify} model SecuritySettingsUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SecuritySettingsUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleGetRequest proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleGetRequest} model FirewallRuleGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleGetResponse proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleGetResponse} model FirewallRuleGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRulesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!FirewallRulesGetAllRequest} model FirewallRulesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRulesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRulesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!FirewallRulesGetAllResponse} model FirewallRulesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRulesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleCreateRequest} model FirewallRuleCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleCreateResponse} model FirewallRuleCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleModifyRequest} model FirewallRuleModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleModifyResponse} model FirewallRuleModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleDeleteRequest} model FirewallRuleDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleDeleteResponse} model FirewallRuleDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FirewallRuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!FirewallRuleUpdateNotify} model FirewallRuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FirewallRuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * manager receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case PingModuleRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PingModuleRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PingModuleRequestModel.verify(), 'manager.PingModuleRequest validation failed!')

        let fbeBegin = this._PingModuleRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PingModuleRequest(this._PingModuleRequestModel, type, buffer, offset, size)
        this._PingModuleRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PongModuleResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PongModuleResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PongModuleResponseModel.verify(), 'manager.PongModuleResponse validation failed!')

        let fbeBegin = this._PongModuleResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PongModuleResponse(this._PongModuleResponseModel, type, buffer, offset, size)
        this._PongModuleResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PingModuleRejectModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PingModuleRejectModel.attachBuffer(buffer, offset)
        console.assert(this._PingModuleRejectModel.verify(), 'manager.PingModuleReject validation failed!')

        let fbeBegin = this._PingModuleRejectModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PingModuleReject(this._PingModuleRejectModel, type, buffer, offset, size)
        this._PingModuleRejectModel.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated3Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated3Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated3Model.verify(), 'manager.Deprecated3 validation failed!')

        let fbeBegin = this._Deprecated3Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated3(this._Deprecated3Model, type, buffer, offset, size)
        this._Deprecated3Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated4Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated4Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated4Model.verify(), 'manager.Deprecated4 validation failed!')

        let fbeBegin = this._Deprecated4Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated4(this._Deprecated4Model, type, buffer, offset, size)
        this._Deprecated4Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated1Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated1Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated1Model.verify(), 'manager.Deprecated1 validation failed!')

        let fbeBegin = this._Deprecated1Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated1(this._Deprecated1Model, type, buffer, offset, size)
        this._Deprecated1Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated2Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated2Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated2Model.verify(), 'manager.Deprecated2 validation failed!')

        let fbeBegin = this._Deprecated2Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated2(this._Deprecated2Model, type, buffer, offset, size)
        this._Deprecated2Model.model.getEnd(fbeBegin)
        return true
      }
      case ManagerSessionInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerSessionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerSessionInfoRequestModel.verify(), 'manager.ManagerSessionInfoRequest validation failed!')

        let fbeBegin = this._ManagerSessionInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerSessionInfoRequest(this._ManagerSessionInfoRequestModel, type, buffer, offset, size)
        this._ManagerSessionInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerSessionInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerSessionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerSessionInfoResponseModel.verify(), 'manager.ManagerSessionInfoResponse validation failed!')

        let fbeBegin = this._ManagerSessionInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerSessionInfoResponse(this._ManagerSessionInfoResponseModel, type, buffer, offset, size)
        this._ManagerSessionInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OnlineSessionsRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OnlineSessionsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OnlineSessionsRequestModel.verify(), 'manager.OnlineSessionsRequest validation failed!')

        let fbeBegin = this._OnlineSessionsRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OnlineSessionsRequest(this._OnlineSessionsRequestModel, type, buffer, offset, size)
        this._OnlineSessionsRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OnlineSessionsResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OnlineSessionsResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OnlineSessionsResponseModel.verify(), 'manager.OnlineSessionsResponse validation failed!')

        let fbeBegin = this._OnlineSessionsResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OnlineSessionsResponse(this._OnlineSessionsResponseModel, type, buffer, offset, size)
        this._OnlineSessionsResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DropSessionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DropSessionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DropSessionRequestModel.verify(), 'manager.DropSessionRequest validation failed!')

        let fbeBegin = this._DropSessionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DropSessionRequest(this._DropSessionRequestModel, type, buffer, offset, size)
        this._DropSessionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DropSessionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DropSessionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DropSessionResponseModel.verify(), 'manager.DropSessionResponse validation failed!')

        let fbeBegin = this._DropSessionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DropSessionResponse(this._DropSessionResponseModel, type, buffer, offset, size)
        this._DropSessionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRequestModel.verify(), 'manager.ConfigRequest validation failed!')

        let fbeBegin = this._ConfigRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRequest(this._ConfigRequestModel, type, buffer, offset, size)
        this._ConfigRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigResponseModel.verify(), 'manager.ConfigResponse validation failed!')

        let fbeBegin = this._ConfigResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigResponse(this._ConfigResponseModel, type, buffer, offset, size)
        this._ConfigResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRejectModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRejectModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRejectModel.verify(), 'manager.ConfigReject validation failed!')

        let fbeBegin = this._ConfigRejectModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigReject(this._ConfigRejectModel, type, buffer, offset, size)
        this._ConfigRejectModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRequestModel.verify(), 'manager.ConfigUpdateRequest validation failed!')

        let fbeBegin = this._ConfigUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigUpdateRequest(this._ConfigUpdateRequestModel, type, buffer, offset, size)
        this._ConfigUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateResponseModel.verify(), 'manager.ConfigUpdateResponse validation failed!')

        let fbeBegin = this._ConfigUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigUpdateResponse(this._ConfigUpdateResponseModel, type, buffer, offset, size)
        this._ConfigUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateNotifyModel.verify(), 'manager.ConfigUpdateNotify validation failed!')

        let fbeBegin = this._ConfigUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigUpdateNotify(this._ConfigUpdateNotifyModel, type, buffer, offset, size)
        this._ConfigUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionRequestModel.verify(), 'manager.ConfigVersionRequest validation failed!')

        let fbeBegin = this._ConfigVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigVersionRequest(this._ConfigVersionRequestModel, type, buffer, offset, size)
        this._ConfigVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionResponseModel.verify(), 'manager.ConfigVersionResponse validation failed!')

        let fbeBegin = this._ConfigVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigVersionResponse(this._ConfigVersionResponseModel, type, buffer, offset, size)
        this._ConfigVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigExportRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigExportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigExportRequestModel.verify(), 'manager.ConfigExportRequest validation failed!')

        let fbeBegin = this._ConfigExportRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigExportRequest(this._ConfigExportRequestModel, type, buffer, offset, size)
        this._ConfigExportRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigExportResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigExportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigExportResponseModel.verify(), 'manager.ConfigExportResponse validation failed!')

        let fbeBegin = this._ConfigExportResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigExportResponse(this._ConfigExportResponseModel, type, buffer, offset, size)
        this._ConfigExportResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigImportRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigImportRequestModel.verify(), 'manager.ConfigImportRequest validation failed!')

        let fbeBegin = this._ConfigImportRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigImportRequest(this._ConfigImportRequestModel, type, buffer, offset, size)
        this._ConfigImportRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigImportResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigImportResponseModel.verify(), 'manager.ConfigImportResponse validation failed!')

        let fbeBegin = this._ConfigImportResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigImportResponse(this._ConfigImportResponseModel, type, buffer, offset, size)
        this._ConfigImportResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigValidateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigValidateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigValidateRequestModel.verify(), 'manager.ConfigValidateRequest validation failed!')

        let fbeBegin = this._ConfigValidateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigValidateRequest(this._ConfigValidateRequestModel, type, buffer, offset, size)
        this._ConfigValidateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigValidateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigValidateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigValidateResponseModel.verify(), 'manager.ConfigValidateResponse validation failed!')

        let fbeBegin = this._ConfigValidateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigValidateResponse(this._ConfigValidateResponseModel, type, buffer, offset, size)
        this._ConfigValidateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRefreshRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRefreshRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshRequestModel.verify(), 'manager.ConfigRefreshRequest validation failed!')

        let fbeBegin = this._ConfigRefreshRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRefreshRequest(this._ConfigRefreshRequestModel, type, buffer, offset, size)
        this._ConfigRefreshRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRefreshResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRefreshResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshResponseModel.verify(), 'manager.ConfigRefreshResponse validation failed!')

        let fbeBegin = this._ConfigRefreshResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRefreshResponse(this._ConfigRefreshResponseModel, type, buffer, offset, size)
        this._ConfigRefreshResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryRequestModel.verify(), 'manager.ConfigHistoryRequest validation failed!')

        let fbeBegin = this._ConfigHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigHistoryRequest(this._ConfigHistoryRequestModel, type, buffer, offset, size)
        this._ConfigHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryResponseModel.verify(), 'manager.ConfigHistoryResponse validation failed!')

        let fbeBegin = this._ConfigHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigHistoryResponse(this._ConfigHistoryResponseModel, type, buffer, offset, size)
        this._ConfigHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRollbackRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRollbackRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackRequestModel.verify(), 'manager.ConfigRollbackRequest validation failed!')

        let fbeBegin = this._ConfigRollbackRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRollbackRequest(this._ConfigRollbackRequestModel, type, buffer, offset, size)
        this._ConfigRollbackRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRollbackResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRollbackResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackResponseModel.verify(), 'manager.ConfigRollbackResponse validation failed!')

        let fbeBegin = this._ConfigRollbackResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRollbackResponse(this._ConfigRollbackResponseModel, type, buffer, offset, size)
        this._ConfigRollbackResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HostGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostGetRequestModel.verify(), 'manager.HostGetRequest validation failed!')

        let fbeBegin = this._HostGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostGetRequest(this._HostGetRequestModel, type, buffer, offset, size)
        this._HostGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case HostGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostGetResponseModel.verify(), 'manager.HostGetResponse validation failed!')

        let fbeBegin = this._HostGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostGetResponse(this._HostGetResponseModel, type, buffer, offset, size)
        this._HostGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HostsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostsGetAllRequestModel.verify(), 'manager.HostsGetAllRequest validation failed!')

        let fbeBegin = this._HostsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostsGetAllRequest(this._HostsGetAllRequestModel, type, buffer, offset, size)
        this._HostsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case HostsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostsGetAllResponseModel.verify(), 'manager.HostsGetAllResponse validation failed!')

        let fbeBegin = this._HostsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostsGetAllResponse(this._HostsGetAllResponseModel, type, buffer, offset, size)
        this._HostsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HostCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostCreateRequestModel.verify(), 'manager.HostCreateRequest validation failed!')

        let fbeBegin = this._HostCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostCreateRequest(this._HostCreateRequestModel, type, buffer, offset, size)
        this._HostCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case HostCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostCreateResponseModel.verify(), 'manager.HostCreateResponse validation failed!')

        let fbeBegin = this._HostCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostCreateResponse(this._HostCreateResponseModel, type, buffer, offset, size)
        this._HostCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HostModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostModifyRequestModel.verify(), 'manager.HostModifyRequest validation failed!')

        let fbeBegin = this._HostModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostModifyRequest(this._HostModifyRequestModel, type, buffer, offset, size)
        this._HostModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case HostModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostModifyResponseModel.verify(), 'manager.HostModifyResponse validation failed!')

        let fbeBegin = this._HostModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostModifyResponse(this._HostModifyResponseModel, type, buffer, offset, size)
        this._HostModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HostDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HostDeleteRequestModel.verify(), 'manager.HostDeleteRequest validation failed!')

        let fbeBegin = this._HostDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostDeleteRequest(this._HostDeleteRequestModel, type, buffer, offset, size)
        this._HostDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case HostDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HostDeleteResponseModel.verify(), 'manager.HostDeleteResponse validation failed!')

        let fbeBegin = this._HostDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostDeleteResponse(this._HostDeleteResponseModel, type, buffer, offset, size)
        this._HostDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HostUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HostUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._HostUpdateNotifyModel.verify(), 'manager.HostUpdateNotify validation failed!')

        let fbeBegin = this._HostUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HostUpdateNotify(this._HostUpdateNotifyModel, type, buffer, offset, size)
        this._HostUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleGetRequestModel.verify(), 'manager.ModuleGetRequest validation failed!')

        let fbeBegin = this._ModuleGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleGetRequest(this._ModuleGetRequestModel, type, buffer, offset, size)
        this._ModuleGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleGetResponseModel.verify(), 'manager.ModuleGetResponse validation failed!')

        let fbeBegin = this._ModuleGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleGetResponse(this._ModuleGetResponseModel, type, buffer, offset, size)
        this._ModuleGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModulesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesGetAllRequestModel.verify(), 'manager.ModulesGetAllRequest validation failed!')

        let fbeBegin = this._ModulesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModulesGetAllRequest(this._ModulesGetAllRequestModel, type, buffer, offset, size)
        this._ModulesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModulesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesGetAllResponseModel.verify(), 'manager.ModulesGetAllResponse validation failed!')

        let fbeBegin = this._ModulesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModulesGetAllResponse(this._ModulesGetAllResponseModel, type, buffer, offset, size)
        this._ModulesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleCreateRequestModel.verify(), 'manager.ModuleCreateRequest validation failed!')

        let fbeBegin = this._ModuleCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleCreateRequest(this._ModuleCreateRequestModel, type, buffer, offset, size)
        this._ModuleCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleCreateResponseModel.verify(), 'manager.ModuleCreateResponse validation failed!')

        let fbeBegin = this._ModuleCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleCreateResponse(this._ModuleCreateResponseModel, type, buffer, offset, size)
        this._ModuleCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleModifyRequestModel.verify(), 'manager.ModuleModifyRequest validation failed!')

        let fbeBegin = this._ModuleModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleModifyRequest(this._ModuleModifyRequestModel, type, buffer, offset, size)
        this._ModuleModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleModifyResponseModel.verify(), 'manager.ModuleModifyResponse validation failed!')

        let fbeBegin = this._ModuleModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleModifyResponse(this._ModuleModifyResponseModel, type, buffer, offset, size)
        this._ModuleModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleDeleteRequestModel.verify(), 'manager.ModuleDeleteRequest validation failed!')

        let fbeBegin = this._ModuleDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleDeleteRequest(this._ModuleDeleteRequestModel, type, buffer, offset, size)
        this._ModuleDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleDeleteResponseModel.verify(), 'manager.ModuleDeleteResponse validation failed!')

        let fbeBegin = this._ModuleDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleDeleteResponse(this._ModuleDeleteResponseModel, type, buffer, offset, size)
        this._ModuleDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleUpdateNotifyModel.verify(), 'manager.ModuleUpdateNotify validation failed!')

        let fbeBegin = this._ModuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleUpdateNotify(this._ModuleUpdateNotifyModel, type, buffer, offset, size)
        this._ModuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ServerTimeGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ServerTimeGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ServerTimeGetRequestModel.verify(), 'manager.ServerTimeGetRequest validation failed!')

        let fbeBegin = this._ServerTimeGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ServerTimeGetRequest(this._ServerTimeGetRequestModel, type, buffer, offset, size)
        this._ServerTimeGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ServerTimeGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ServerTimeGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ServerTimeGetResponseModel.verify(), 'manager.ServerTimeGetResponse validation failed!')

        let fbeBegin = this._ServerTimeGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ServerTimeGetResponse(this._ServerTimeGetResponseModel, type, buffer, offset, size)
        this._ServerTimeGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerPlatformTimezoneGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerPlatformTimezoneGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerPlatformTimezoneGetRequestModel.verify(), 'manager.ManagerPlatformTimezoneGetRequest validation failed!')

        let fbeBegin = this._ManagerPlatformTimezoneGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerPlatformTimezoneGetRequest(this._ManagerPlatformTimezoneGetRequestModel, type, buffer, offset, size)
        this._ManagerPlatformTimezoneGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerPlatformTimezoneGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerPlatformTimezoneGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerPlatformTimezoneGetResponseModel.verify(), 'manager.ManagerPlatformTimezoneGetResponse validation failed!')

        let fbeBegin = this._ManagerPlatformTimezoneGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerPlatformTimezoneGetResponse(this._ManagerPlatformTimezoneGetResponseModel, type, buffer, offset, size)
        this._ManagerPlatformTimezoneGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneGetRequestModel.verify(), 'manager.TimezoneGetRequest validation failed!')

        let fbeBegin = this._TimezoneGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneGetRequest(this._TimezoneGetRequestModel, type, buffer, offset, size)
        this._TimezoneGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneGetResponseModel.verify(), 'manager.TimezoneGetResponse validation failed!')

        let fbeBegin = this._TimezoneGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneGetResponse(this._TimezoneGetResponseModel, type, buffer, offset, size)
        this._TimezoneGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezonesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezonesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezonesGetAllRequestModel.verify(), 'manager.TimezonesGetAllRequest validation failed!')

        let fbeBegin = this._TimezonesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezonesGetAllRequest(this._TimezonesGetAllRequestModel, type, buffer, offset, size)
        this._TimezonesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezonesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezonesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezonesGetAllResponseModel.verify(), 'manager.TimezonesGetAllResponse validation failed!')

        let fbeBegin = this._TimezonesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezonesGetAllResponse(this._TimezonesGetAllResponseModel, type, buffer, offset, size)
        this._TimezonesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneCreateRequestModel.verify(), 'manager.TimezoneCreateRequest validation failed!')

        let fbeBegin = this._TimezoneCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneCreateRequest(this._TimezoneCreateRequestModel, type, buffer, offset, size)
        this._TimezoneCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneCreateResponseModel.verify(), 'manager.TimezoneCreateResponse validation failed!')

        let fbeBegin = this._TimezoneCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneCreateResponse(this._TimezoneCreateResponseModel, type, buffer, offset, size)
        this._TimezoneCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneModifyRequestModel.verify(), 'manager.TimezoneModifyRequest validation failed!')

        let fbeBegin = this._TimezoneModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneModifyRequest(this._TimezoneModifyRequestModel, type, buffer, offset, size)
        this._TimezoneModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneModifyResponseModel.verify(), 'manager.TimezoneModifyResponse validation failed!')

        let fbeBegin = this._TimezoneModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneModifyResponse(this._TimezoneModifyResponseModel, type, buffer, offset, size)
        this._TimezoneModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneDeleteRequestModel.verify(), 'manager.TimezoneDeleteRequest validation failed!')

        let fbeBegin = this._TimezoneDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneDeleteRequest(this._TimezoneDeleteRequestModel, type, buffer, offset, size)
        this._TimezoneDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneDeleteResponseModel.verify(), 'manager.TimezoneDeleteResponse validation failed!')

        let fbeBegin = this._TimezoneDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneDeleteResponse(this._TimezoneDeleteResponseModel, type, buffer, offset, size)
        this._TimezoneDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneUpdateNotifyModel.verify(), 'manager.TimezoneUpdateNotify validation failed!')

        let fbeBegin = this._TimezoneUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneUpdateNotify(this._TimezoneUpdateNotifyModel, type, buffer, offset, size)
        this._TimezoneUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetRequestModel.verify(), 'manager.CurrencyGroupGetRequest validation failed!')

        let fbeBegin = this._CurrencyGroupGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupGetRequest(this._CurrencyGroupGetRequestModel, type, buffer, offset, size)
        this._CurrencyGroupGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetResponseModel.verify(), 'manager.CurrencyGroupGetResponse validation failed!')

        let fbeBegin = this._CurrencyGroupGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupGetResponse(this._CurrencyGroupGetResponseModel, type, buffer, offset, size)
        this._CurrencyGroupGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllRequestModel.verify(), 'manager.CurrencyGroupsGetAllRequest validation failed!')

        let fbeBegin = this._CurrencyGroupsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupsGetAllRequest(this._CurrencyGroupsGetAllRequestModel, type, buffer, offset, size)
        this._CurrencyGroupsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllResponseModel.verify(), 'manager.CurrencyGroupsGetAllResponse validation failed!')

        let fbeBegin = this._CurrencyGroupsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupsGetAllResponse(this._CurrencyGroupsGetAllResponseModel, type, buffer, offset, size)
        this._CurrencyGroupsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupCreateRequestModel.verify(), 'manager.CurrencyGroupCreateRequest validation failed!')

        let fbeBegin = this._CurrencyGroupCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupCreateRequest(this._CurrencyGroupCreateRequestModel, type, buffer, offset, size)
        this._CurrencyGroupCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupCreateResponseModel.verify(), 'manager.CurrencyGroupCreateResponse validation failed!')

        let fbeBegin = this._CurrencyGroupCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupCreateResponse(this._CurrencyGroupCreateResponseModel, type, buffer, offset, size)
        this._CurrencyGroupCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupModifyRequestModel.verify(), 'manager.CurrencyGroupModifyRequest validation failed!')

        let fbeBegin = this._CurrencyGroupModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupModifyRequest(this._CurrencyGroupModifyRequestModel, type, buffer, offset, size)
        this._CurrencyGroupModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupModifyResponseModel.verify(), 'manager.CurrencyGroupModifyResponse validation failed!')

        let fbeBegin = this._CurrencyGroupModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupModifyResponse(this._CurrencyGroupModifyResponseModel, type, buffer, offset, size)
        this._CurrencyGroupModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupDeleteRequestModel.verify(), 'manager.CurrencyGroupDeleteRequest validation failed!')

        let fbeBegin = this._CurrencyGroupDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupDeleteRequest(this._CurrencyGroupDeleteRequestModel, type, buffer, offset, size)
        this._CurrencyGroupDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupDeleteResponseModel.verify(), 'manager.CurrencyGroupDeleteResponse validation failed!')

        let fbeBegin = this._CurrencyGroupDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupDeleteResponse(this._CurrencyGroupDeleteResponseModel, type, buffer, offset, size)
        this._CurrencyGroupDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupUpdateNotifyModel.verify(), 'manager.CurrencyGroupUpdateNotify validation failed!')

        let fbeBegin = this._CurrencyGroupUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupUpdateNotify(this._CurrencyGroupUpdateNotifyModel, type, buffer, offset, size)
        this._CurrencyGroupUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetRequestModel.verify(), 'manager.CurrencyGetRequest validation failed!')

        let fbeBegin = this._CurrencyGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGetRequest(this._CurrencyGetRequestModel, type, buffer, offset, size)
        this._CurrencyGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetResponseModel.verify(), 'manager.CurrencyGetResponse validation failed!')

        let fbeBegin = this._CurrencyGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGetResponse(this._CurrencyGetResponseModel, type, buffer, offset, size)
        this._CurrencyGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrenciesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrenciesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllRequestModel.verify(), 'manager.CurrenciesGetAllRequest validation failed!')

        let fbeBegin = this._CurrenciesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrenciesGetAllRequest(this._CurrenciesGetAllRequestModel, type, buffer, offset, size)
        this._CurrenciesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrenciesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrenciesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllResponseModel.verify(), 'manager.CurrenciesGetAllResponse validation failed!')

        let fbeBegin = this._CurrenciesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrenciesGetAllResponse(this._CurrenciesGetAllResponseModel, type, buffer, offset, size)
        this._CurrenciesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyCreateRequestModel.verify(), 'manager.CurrencyCreateRequest validation failed!')

        let fbeBegin = this._CurrencyCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyCreateRequest(this._CurrencyCreateRequestModel, type, buffer, offset, size)
        this._CurrencyCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyCreateResponseModel.verify(), 'manager.CurrencyCreateResponse validation failed!')

        let fbeBegin = this._CurrencyCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyCreateResponse(this._CurrencyCreateResponseModel, type, buffer, offset, size)
        this._CurrencyCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyModifyRequestModel.verify(), 'manager.CurrencyModifyRequest validation failed!')

        let fbeBegin = this._CurrencyModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyModifyRequest(this._CurrencyModifyRequestModel, type, buffer, offset, size)
        this._CurrencyModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyModifyResponseModel.verify(), 'manager.CurrencyModifyResponse validation failed!')

        let fbeBegin = this._CurrencyModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyModifyResponse(this._CurrencyModifyResponseModel, type, buffer, offset, size)
        this._CurrencyModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyDeleteRequestModel.verify(), 'manager.CurrencyDeleteRequest validation failed!')

        let fbeBegin = this._CurrencyDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyDeleteRequest(this._CurrencyDeleteRequestModel, type, buffer, offset, size)
        this._CurrencyDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyDeleteResponseModel.verify(), 'manager.CurrencyDeleteResponse validation failed!')

        let fbeBegin = this._CurrencyDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyDeleteResponse(this._CurrencyDeleteResponseModel, type, buffer, offset, size)
        this._CurrencyDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyUpdateNotifyModel.verify(), 'manager.CurrencyUpdateNotify validation failed!')

        let fbeBegin = this._CurrencyUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyUpdateNotify(this._CurrencyUpdateNotifyModel, type, buffer, offset, size)
        this._CurrencyUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetRequestModel.verify(), 'manager.SymbolGroupGetRequest validation failed!')

        let fbeBegin = this._SymbolGroupGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupGetRequest(this._SymbolGroupGetRequestModel, type, buffer, offset, size)
        this._SymbolGroupGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetResponseModel.verify(), 'manager.SymbolGroupGetResponse validation failed!')

        let fbeBegin = this._SymbolGroupGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupGetResponse(this._SymbolGroupGetResponseModel, type, buffer, offset, size)
        this._SymbolGroupGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllRequestModel.verify(), 'manager.SymbolGroupsGetAllRequest validation failed!')

        let fbeBegin = this._SymbolGroupsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupsGetAllRequest(this._SymbolGroupsGetAllRequestModel, type, buffer, offset, size)
        this._SymbolGroupsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllResponseModel.verify(), 'manager.SymbolGroupsGetAllResponse validation failed!')

        let fbeBegin = this._SymbolGroupsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupsGetAllResponse(this._SymbolGroupsGetAllResponseModel, type, buffer, offset, size)
        this._SymbolGroupsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupCreateRequestModel.verify(), 'manager.SymbolGroupCreateRequest validation failed!')

        let fbeBegin = this._SymbolGroupCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupCreateRequest(this._SymbolGroupCreateRequestModel, type, buffer, offset, size)
        this._SymbolGroupCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupCreateResponseModel.verify(), 'manager.SymbolGroupCreateResponse validation failed!')

        let fbeBegin = this._SymbolGroupCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupCreateResponse(this._SymbolGroupCreateResponseModel, type, buffer, offset, size)
        this._SymbolGroupCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupModifyRequestModel.verify(), 'manager.SymbolGroupModifyRequest validation failed!')

        let fbeBegin = this._SymbolGroupModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupModifyRequest(this._SymbolGroupModifyRequestModel, type, buffer, offset, size)
        this._SymbolGroupModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupModifyResponseModel.verify(), 'manager.SymbolGroupModifyResponse validation failed!')

        let fbeBegin = this._SymbolGroupModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupModifyResponse(this._SymbolGroupModifyResponseModel, type, buffer, offset, size)
        this._SymbolGroupModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupDeleteRequestModel.verify(), 'manager.SymbolGroupDeleteRequest validation failed!')

        let fbeBegin = this._SymbolGroupDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupDeleteRequest(this._SymbolGroupDeleteRequestModel, type, buffer, offset, size)
        this._SymbolGroupDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupDeleteResponseModel.verify(), 'manager.SymbolGroupDeleteResponse validation failed!')

        let fbeBegin = this._SymbolGroupDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupDeleteResponse(this._SymbolGroupDeleteResponseModel, type, buffer, offset, size)
        this._SymbolGroupDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupUpdateNotifyModel.verify(), 'manager.SymbolGroupUpdateNotify validation failed!')

        let fbeBegin = this._SymbolGroupUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupUpdateNotify(this._SymbolGroupUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolGroupUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetRequestModel.verify(), 'manager.SymbolGetRequest validation failed!')

        let fbeBegin = this._SymbolGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGetRequest(this._SymbolGetRequestModel, type, buffer, offset, size)
        this._SymbolGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetResponseModel.verify(), 'manager.SymbolGetResponse validation failed!')

        let fbeBegin = this._SymbolGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGetResponse(this._SymbolGetResponseModel, type, buffer, offset, size)
        this._SymbolGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllRequestModel.verify(), 'manager.SymbolsGetAllRequest validation failed!')

        let fbeBegin = this._SymbolsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolsGetAllRequest(this._SymbolsGetAllRequestModel, type, buffer, offset, size)
        this._SymbolsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllResponseModel.verify(), 'manager.SymbolsGetAllResponse validation failed!')

        let fbeBegin = this._SymbolsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolsGetAllResponse(this._SymbolsGetAllResponseModel, type, buffer, offset, size)
        this._SymbolsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolCreateRequestModel.verify(), 'manager.SymbolCreateRequest validation failed!')

        let fbeBegin = this._SymbolCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolCreateRequest(this._SymbolCreateRequestModel, type, buffer, offset, size)
        this._SymbolCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolCreateResponseModel.verify(), 'manager.SymbolCreateResponse validation failed!')

        let fbeBegin = this._SymbolCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolCreateResponse(this._SymbolCreateResponseModel, type, buffer, offset, size)
        this._SymbolCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolModifyRequestModel.verify(), 'manager.SymbolModifyRequest validation failed!')

        let fbeBegin = this._SymbolModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolModifyRequest(this._SymbolModifyRequestModel, type, buffer, offset, size)
        this._SymbolModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolModifyResponseModel.verify(), 'manager.SymbolModifyResponse validation failed!')

        let fbeBegin = this._SymbolModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolModifyResponse(this._SymbolModifyResponseModel, type, buffer, offset, size)
        this._SymbolModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDeleteRequestModel.verify(), 'manager.SymbolDeleteRequest validation failed!')

        let fbeBegin = this._SymbolDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDeleteRequest(this._SymbolDeleteRequestModel, type, buffer, offset, size)
        this._SymbolDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDeleteResponseModel.verify(), 'manager.SymbolDeleteResponse validation failed!')

        let fbeBegin = this._SymbolDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDeleteResponse(this._SymbolDeleteResponseModel, type, buffer, offset, size)
        this._SymbolDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyModel.verify(), 'manager.SymbolUpdateNotify validation failed!')

        let fbeBegin = this._SymbolUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolUpdateNotify(this._SymbolUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SwapsExportRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SwapsExportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsExportRequestModel.verify(), 'manager.SwapsExportRequest validation failed!')

        let fbeBegin = this._SwapsExportRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SwapsExportRequest(this._SwapsExportRequestModel, type, buffer, offset, size)
        this._SwapsExportRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SwapsExportResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SwapsExportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsExportResponseModel.verify(), 'manager.SwapsExportResponse validation failed!')

        let fbeBegin = this._SwapsExportResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SwapsExportResponse(this._SwapsExportResponseModel, type, buffer, offset, size)
        this._SwapsExportResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SwapsImportRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SwapsImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsImportRequestModel.verify(), 'manager.SwapsImportRequest validation failed!')

        let fbeBegin = this._SwapsImportRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SwapsImportRequest(this._SwapsImportRequestModel, type, buffer, offset, size)
        this._SwapsImportRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SwapsImportResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SwapsImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsImportResponseModel.verify(), 'manager.SwapsImportResponse validation failed!')

        let fbeBegin = this._SwapsImportResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SwapsImportResponse(this._SwapsImportResponseModel, type, buffer, offset, size)
        this._SwapsImportResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileGetRequestModel.verify(), 'manager.CommissionProfileGetRequest validation failed!')

        let fbeBegin = this._CommissionProfileGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileGetRequest(this._CommissionProfileGetRequestModel, type, buffer, offset, size)
        this._CommissionProfileGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileGetResponseModel.verify(), 'manager.CommissionProfileGetResponse validation failed!')

        let fbeBegin = this._CommissionProfileGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileGetResponse(this._CommissionProfileGetResponseModel, type, buffer, offset, size)
        this._CommissionProfileGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfilesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfilesGetAllRequestModel.verify(), 'manager.CommissionProfilesGetAllRequest validation failed!')

        let fbeBegin = this._CommissionProfilesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfilesGetAllRequest(this._CommissionProfilesGetAllRequestModel, type, buffer, offset, size)
        this._CommissionProfilesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfilesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfilesGetAllResponseModel.verify(), 'manager.CommissionProfilesGetAllResponse validation failed!')

        let fbeBegin = this._CommissionProfilesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfilesGetAllResponse(this._CommissionProfilesGetAllResponseModel, type, buffer, offset, size)
        this._CommissionProfilesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileCreateRequestModel.verify(), 'manager.CommissionProfileCreateRequest validation failed!')

        let fbeBegin = this._CommissionProfileCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileCreateRequest(this._CommissionProfileCreateRequestModel, type, buffer, offset, size)
        this._CommissionProfileCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileCreateResponseModel.verify(), 'manager.CommissionProfileCreateResponse validation failed!')

        let fbeBegin = this._CommissionProfileCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileCreateResponse(this._CommissionProfileCreateResponseModel, type, buffer, offset, size)
        this._CommissionProfileCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileModifyRequestModel.verify(), 'manager.CommissionProfileModifyRequest validation failed!')

        let fbeBegin = this._CommissionProfileModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileModifyRequest(this._CommissionProfileModifyRequestModel, type, buffer, offset, size)
        this._CommissionProfileModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileModifyResponseModel.verify(), 'manager.CommissionProfileModifyResponse validation failed!')

        let fbeBegin = this._CommissionProfileModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileModifyResponse(this._CommissionProfileModifyResponseModel, type, buffer, offset, size)
        this._CommissionProfileModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileDeleteRequestModel.verify(), 'manager.CommissionProfileDeleteRequest validation failed!')

        let fbeBegin = this._CommissionProfileDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileDeleteRequest(this._CommissionProfileDeleteRequestModel, type, buffer, offset, size)
        this._CommissionProfileDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileDeleteResponseModel.verify(), 'manager.CommissionProfileDeleteResponse validation failed!')

        let fbeBegin = this._CommissionProfileDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileDeleteResponse(this._CommissionProfileDeleteResponseModel, type, buffer, offset, size)
        this._CommissionProfileDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CommissionProfileUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CommissionProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileUpdateNotifyModel.verify(), 'manager.CommissionProfileUpdateNotify validation failed!')

        let fbeBegin = this._CommissionProfileUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CommissionProfileUpdateNotify(this._CommissionProfileUpdateNotifyModel, type, buffer, offset, size)
        this._CommissionProfileUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileGetRequestModel.verify(), 'manager.MarkupProfileGetRequest validation failed!')

        let fbeBegin = this._MarkupProfileGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileGetRequest(this._MarkupProfileGetRequestModel, type, buffer, offset, size)
        this._MarkupProfileGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileGetResponseModel.verify(), 'manager.MarkupProfileGetResponse validation failed!')

        let fbeBegin = this._MarkupProfileGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileGetResponse(this._MarkupProfileGetResponseModel, type, buffer, offset, size)
        this._MarkupProfileGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfilesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfilesGetAllRequestModel.verify(), 'manager.MarkupProfilesGetAllRequest validation failed!')

        let fbeBegin = this._MarkupProfilesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfilesGetAllRequest(this._MarkupProfilesGetAllRequestModel, type, buffer, offset, size)
        this._MarkupProfilesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfilesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfilesGetAllResponseModel.verify(), 'manager.MarkupProfilesGetAllResponse validation failed!')

        let fbeBegin = this._MarkupProfilesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfilesGetAllResponse(this._MarkupProfilesGetAllResponseModel, type, buffer, offset, size)
        this._MarkupProfilesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileCreateRequestModel.verify(), 'manager.MarkupProfileCreateRequest validation failed!')

        let fbeBegin = this._MarkupProfileCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileCreateRequest(this._MarkupProfileCreateRequestModel, type, buffer, offset, size)
        this._MarkupProfileCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileCreateResponseModel.verify(), 'manager.MarkupProfileCreateResponse validation failed!')

        let fbeBegin = this._MarkupProfileCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileCreateResponse(this._MarkupProfileCreateResponseModel, type, buffer, offset, size)
        this._MarkupProfileCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileModifyRequestModel.verify(), 'manager.MarkupProfileModifyRequest validation failed!')

        let fbeBegin = this._MarkupProfileModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileModifyRequest(this._MarkupProfileModifyRequestModel, type, buffer, offset, size)
        this._MarkupProfileModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileModifyResponseModel.verify(), 'manager.MarkupProfileModifyResponse validation failed!')

        let fbeBegin = this._MarkupProfileModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileModifyResponse(this._MarkupProfileModifyResponseModel, type, buffer, offset, size)
        this._MarkupProfileModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileDeleteRequestModel.verify(), 'manager.MarkupProfileDeleteRequest validation failed!')

        let fbeBegin = this._MarkupProfileDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileDeleteRequest(this._MarkupProfileDeleteRequestModel, type, buffer, offset, size)
        this._MarkupProfileDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileDeleteResponseModel.verify(), 'manager.MarkupProfileDeleteResponse validation failed!')

        let fbeBegin = this._MarkupProfileDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileDeleteResponse(this._MarkupProfileDeleteResponseModel, type, buffer, offset, size)
        this._MarkupProfileDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarkupProfileUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarkupProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileUpdateNotifyModel.verify(), 'manager.MarkupProfileUpdateNotify validation failed!')

        let fbeBegin = this._MarkupProfileUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarkupProfileUpdateNotify(this._MarkupProfileUpdateNotifyModel, type, buffer, offset, size)
        this._MarkupProfileUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupGetRequestModel.verify(), 'manager.ManagerGroupGetRequest validation failed!')

        let fbeBegin = this._ManagerGroupGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupGetRequest(this._ManagerGroupGetRequestModel, type, buffer, offset, size)
        this._ManagerGroupGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupGetResponseModel.verify(), 'manager.ManagerGroupGetResponse validation failed!')

        let fbeBegin = this._ManagerGroupGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupGetResponse(this._ManagerGroupGetResponseModel, type, buffer, offset, size)
        this._ManagerGroupGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupsGetAllRequestModel.verify(), 'manager.ManagerGroupsGetAllRequest validation failed!')

        let fbeBegin = this._ManagerGroupsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupsGetAllRequest(this._ManagerGroupsGetAllRequestModel, type, buffer, offset, size)
        this._ManagerGroupsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupsGetAllResponseModel.verify(), 'manager.ManagerGroupsGetAllResponse validation failed!')

        let fbeBegin = this._ManagerGroupsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupsGetAllResponse(this._ManagerGroupsGetAllResponseModel, type, buffer, offset, size)
        this._ManagerGroupsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupCreateRequestModel.verify(), 'manager.ManagerGroupCreateRequest validation failed!')

        let fbeBegin = this._ManagerGroupCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupCreateRequest(this._ManagerGroupCreateRequestModel, type, buffer, offset, size)
        this._ManagerGroupCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupCreateResponseModel.verify(), 'manager.ManagerGroupCreateResponse validation failed!')

        let fbeBegin = this._ManagerGroupCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupCreateResponse(this._ManagerGroupCreateResponseModel, type, buffer, offset, size)
        this._ManagerGroupCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupModifyRequestModel.verify(), 'manager.ManagerGroupModifyRequest validation failed!')

        let fbeBegin = this._ManagerGroupModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupModifyRequest(this._ManagerGroupModifyRequestModel, type, buffer, offset, size)
        this._ManagerGroupModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupModifyResponseModel.verify(), 'manager.ManagerGroupModifyResponse validation failed!')

        let fbeBegin = this._ManagerGroupModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupModifyResponse(this._ManagerGroupModifyResponseModel, type, buffer, offset, size)
        this._ManagerGroupModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupDeleteRequestModel.verify(), 'manager.ManagerGroupDeleteRequest validation failed!')

        let fbeBegin = this._ManagerGroupDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupDeleteRequest(this._ManagerGroupDeleteRequestModel, type, buffer, offset, size)
        this._ManagerGroupDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupDeleteResponseModel.verify(), 'manager.ManagerGroupDeleteResponse validation failed!')

        let fbeBegin = this._ManagerGroupDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupDeleteResponse(this._ManagerGroupDeleteResponseModel, type, buffer, offset, size)
        this._ManagerGroupDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGroupUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupUpdateNotifyModel.verify(), 'manager.ManagerGroupUpdateNotify validation failed!')

        let fbeBegin = this._ManagerGroupUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGroupUpdateNotify(this._ManagerGroupUpdateNotifyModel, type, buffer, offset, size)
        this._ManagerGroupUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGetRequestModel.verify(), 'manager.ManagerGetRequest validation failed!')

        let fbeBegin = this._ManagerGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGetRequest(this._ManagerGetRequestModel, type, buffer, offset, size)
        this._ManagerGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGetResponseModel.verify(), 'manager.ManagerGetResponse validation failed!')

        let fbeBegin = this._ManagerGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerGetResponse(this._ManagerGetResponseModel, type, buffer, offset, size)
        this._ManagerGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagersGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagersGetAllRequestModel.verify(), 'manager.ManagersGetAllRequest validation failed!')

        let fbeBegin = this._ManagersGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagersGetAllRequest(this._ManagersGetAllRequestModel, type, buffer, offset, size)
        this._ManagersGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagersGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagersGetAllResponseModel.verify(), 'manager.ManagersGetAllResponse validation failed!')

        let fbeBegin = this._ManagersGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagersGetAllResponse(this._ManagersGetAllResponseModel, type, buffer, offset, size)
        this._ManagersGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerCreateRequestModel.verify(), 'manager.ManagerCreateRequest validation failed!')

        let fbeBegin = this._ManagerCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerCreateRequest(this._ManagerCreateRequestModel, type, buffer, offset, size)
        this._ManagerCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerCreateResponseModel.verify(), 'manager.ManagerCreateResponse validation failed!')

        let fbeBegin = this._ManagerCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerCreateResponse(this._ManagerCreateResponseModel, type, buffer, offset, size)
        this._ManagerCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerModifyRequestModel.verify(), 'manager.ManagerModifyRequest validation failed!')

        let fbeBegin = this._ManagerModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerModifyRequest(this._ManagerModifyRequestModel, type, buffer, offset, size)
        this._ManagerModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerModifyResponseModel.verify(), 'manager.ManagerModifyResponse validation failed!')

        let fbeBegin = this._ManagerModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerModifyResponse(this._ManagerModifyResponseModel, type, buffer, offset, size)
        this._ManagerModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerDeleteRequestModel.verify(), 'manager.ManagerDeleteRequest validation failed!')

        let fbeBegin = this._ManagerDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerDeleteRequest(this._ManagerDeleteRequestModel, type, buffer, offset, size)
        this._ManagerDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerDeleteResponseModel.verify(), 'manager.ManagerDeleteResponse validation failed!')

        let fbeBegin = this._ManagerDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerDeleteResponse(this._ManagerDeleteResponseModel, type, buffer, offset, size)
        this._ManagerDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ManagerUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ManagerUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerUpdateNotifyModel.verify(), 'manager.ManagerUpdateNotify validation failed!')

        let fbeBegin = this._ManagerUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ManagerUpdateNotify(this._ManagerUpdateNotifyModel, type, buffer, offset, size)
        this._ManagerUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformGetRequestModel.verify(), 'manager.PlatformGetRequest validation failed!')

        let fbeBegin = this._PlatformGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformGetRequest(this._PlatformGetRequestModel, type, buffer, offset, size)
        this._PlatformGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformGetResponseModel.verify(), 'manager.PlatformGetResponse validation failed!')

        let fbeBegin = this._PlatformGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformGetResponse(this._PlatformGetResponseModel, type, buffer, offset, size)
        this._PlatformGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformsGetAllRequestModel.verify(), 'manager.PlatformsGetAllRequest validation failed!')

        let fbeBegin = this._PlatformsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformsGetAllRequest(this._PlatformsGetAllRequestModel, type, buffer, offset, size)
        this._PlatformsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformsGetAllResponseModel.verify(), 'manager.PlatformsGetAllResponse validation failed!')

        let fbeBegin = this._PlatformsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformsGetAllResponse(this._PlatformsGetAllResponseModel, type, buffer, offset, size)
        this._PlatformsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformCreateRequestModel.verify(), 'manager.PlatformCreateRequest validation failed!')

        let fbeBegin = this._PlatformCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformCreateRequest(this._PlatformCreateRequestModel, type, buffer, offset, size)
        this._PlatformCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformCreateResponseModel.verify(), 'manager.PlatformCreateResponse validation failed!')

        let fbeBegin = this._PlatformCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformCreateResponse(this._PlatformCreateResponseModel, type, buffer, offset, size)
        this._PlatformCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformModifyRequestModel.verify(), 'manager.PlatformModifyRequest validation failed!')

        let fbeBegin = this._PlatformModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformModifyRequest(this._PlatformModifyRequestModel, type, buffer, offset, size)
        this._PlatformModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformModifyResponseModel.verify(), 'manager.PlatformModifyResponse validation failed!')

        let fbeBegin = this._PlatformModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformModifyResponse(this._PlatformModifyResponseModel, type, buffer, offset, size)
        this._PlatformModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformDeleteRequestModel.verify(), 'manager.PlatformDeleteRequest validation failed!')

        let fbeBegin = this._PlatformDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformDeleteRequest(this._PlatformDeleteRequestModel, type, buffer, offset, size)
        this._PlatformDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformDeleteResponseModel.verify(), 'manager.PlatformDeleteResponse validation failed!')

        let fbeBegin = this._PlatformDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformDeleteResponse(this._PlatformDeleteResponseModel, type, buffer, offset, size)
        this._PlatformDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformUpdateNotifyModel.verify(), 'manager.PlatformUpdateNotify validation failed!')

        let fbeBegin = this._PlatformUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformUpdateNotify(this._PlatformUpdateNotifyModel, type, buffer, offset, size)
        this._PlatformUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupGetRequestModel.verify(), 'manager.AccountGroupGetRequest validation failed!')

        let fbeBegin = this._AccountGroupGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupGetRequest(this._AccountGroupGetRequestModel, type, buffer, offset, size)
        this._AccountGroupGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupGetResponseModel.verify(), 'manager.AccountGroupGetResponse validation failed!')

        let fbeBegin = this._AccountGroupGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupGetResponse(this._AccountGroupGetResponseModel, type, buffer, offset, size)
        this._AccountGroupGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupsGetAllRequestModel.verify(), 'manager.AccountGroupsGetAllRequest validation failed!')

        let fbeBegin = this._AccountGroupsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupsGetAllRequest(this._AccountGroupsGetAllRequestModel, type, buffer, offset, size)
        this._AccountGroupsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupsGetAllResponseModel.verify(), 'manager.AccountGroupsGetAllResponse validation failed!')

        let fbeBegin = this._AccountGroupsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupsGetAllResponse(this._AccountGroupsGetAllResponseModel, type, buffer, offset, size)
        this._AccountGroupsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupCreateRequestModel.verify(), 'manager.AccountGroupCreateRequest validation failed!')

        let fbeBegin = this._AccountGroupCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupCreateRequest(this._AccountGroupCreateRequestModel, type, buffer, offset, size)
        this._AccountGroupCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupCreateResponseModel.verify(), 'manager.AccountGroupCreateResponse validation failed!')

        let fbeBegin = this._AccountGroupCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupCreateResponse(this._AccountGroupCreateResponseModel, type, buffer, offset, size)
        this._AccountGroupCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupModifyRequestModel.verify(), 'manager.AccountGroupModifyRequest validation failed!')

        let fbeBegin = this._AccountGroupModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupModifyRequest(this._AccountGroupModifyRequestModel, type, buffer, offset, size)
        this._AccountGroupModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupModifyResponseModel.verify(), 'manager.AccountGroupModifyResponse validation failed!')

        let fbeBegin = this._AccountGroupModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupModifyResponse(this._AccountGroupModifyResponseModel, type, buffer, offset, size)
        this._AccountGroupModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupDeleteRequestModel.verify(), 'manager.AccountGroupDeleteRequest validation failed!')

        let fbeBegin = this._AccountGroupDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupDeleteRequest(this._AccountGroupDeleteRequestModel, type, buffer, offset, size)
        this._AccountGroupDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupDeleteResponseModel.verify(), 'manager.AccountGroupDeleteResponse validation failed!')

        let fbeBegin = this._AccountGroupDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupDeleteResponse(this._AccountGroupDeleteResponseModel, type, buffer, offset, size)
        this._AccountGroupDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupUpdateNotifyModel.verify(), 'manager.AccountGroupUpdateNotify validation failed!')

        let fbeBegin = this._AccountGroupUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupUpdateNotify(this._AccountGroupUpdateNotifyModel, type, buffer, offset, size)
        this._AccountGroupUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGetRequestModel.verify(), 'manager.AccountGetRequest validation failed!')

        let fbeBegin = this._AccountGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGetRequest(this._AccountGetRequestModel, type, buffer, offset, size)
        this._AccountGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGetResponseModel.verify(), 'manager.AccountGetResponse validation failed!')

        let fbeBegin = this._AccountGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGetResponse(this._AccountGetResponseModel, type, buffer, offset, size)
        this._AccountGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestModel.verify(), 'manager.AccountsGetAllRequest validation failed!')

        let fbeBegin = this._AccountsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetAllRequest(this._AccountsGetAllRequestModel, type, buffer, offset, size)
        this._AccountsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseModel.verify(), 'manager.AccountsGetAllResponse validation failed!')

        let fbeBegin = this._AccountsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetAllResponse(this._AccountsGetAllResponseModel, type, buffer, offset, size)
        this._AccountsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountCreateRequestModel.verify(), 'manager.AccountCreateRequest validation failed!')

        let fbeBegin = this._AccountCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountCreateRequest(this._AccountCreateRequestModel, type, buffer, offset, size)
        this._AccountCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountCreateResponseModel.verify(), 'manager.AccountCreateResponse validation failed!')

        let fbeBegin = this._AccountCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountCreateResponse(this._AccountCreateResponseModel, type, buffer, offset, size)
        this._AccountCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingMasterCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingMasterCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterCreateRequestModel.verify(), 'manager.AccountSocialTradingMasterCreateRequest validation failed!')

        let fbeBegin = this._AccountSocialTradingMasterCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingMasterCreateRequest(this._AccountSocialTradingMasterCreateRequestModel, type, buffer, offset, size)
        this._AccountSocialTradingMasterCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingMasterCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingMasterCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterCreateResponseModel.verify(), 'manager.AccountSocialTradingMasterCreateResponse validation failed!')

        let fbeBegin = this._AccountSocialTradingMasterCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingMasterCreateResponse(this._AccountSocialTradingMasterCreateResponseModel, type, buffer, offset, size)
        this._AccountSocialTradingMasterCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingInvestorCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingInvestorCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorCreateRequestModel.verify(), 'manager.AccountSocialTradingInvestorCreateRequest validation failed!')

        let fbeBegin = this._AccountSocialTradingInvestorCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingInvestorCreateRequest(this._AccountSocialTradingInvestorCreateRequestModel, type, buffer, offset, size)
        this._AccountSocialTradingInvestorCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingInvestorCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingInvestorCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorCreateResponseModel.verify(), 'manager.AccountSocialTradingInvestorCreateResponse validation failed!')

        let fbeBegin = this._AccountSocialTradingInvestorCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingInvestorCreateResponse(this._AccountSocialTradingInvestorCreateResponseModel, type, buffer, offset, size)
        this._AccountSocialTradingInvestorCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountModifyRequestModel.verify(), 'manager.AccountModifyRequest validation failed!')

        let fbeBegin = this._AccountModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountModifyRequest(this._AccountModifyRequestModel, type, buffer, offset, size)
        this._AccountModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountModifyResponseModel.verify(), 'manager.AccountModifyResponse validation failed!')

        let fbeBegin = this._AccountModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountModifyResponse(this._AccountModifyResponseModel, type, buffer, offset, size)
        this._AccountModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteRequestModel.verify(), 'manager.AccountDeleteRequest validation failed!')

        let fbeBegin = this._AccountDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountDeleteRequest(this._AccountDeleteRequestModel, type, buffer, offset, size)
        this._AccountDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteResponseModel.verify(), 'manager.AccountDeleteResponse validation failed!')

        let fbeBegin = this._AccountDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountDeleteResponse(this._AccountDeleteResponseModel, type, buffer, offset, size)
        this._AccountDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DemoAccountsDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DemoAccountsDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountsDeleteRequestModel.verify(), 'manager.DemoAccountsDeleteRequest validation failed!')

        let fbeBegin = this._DemoAccountsDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DemoAccountsDeleteRequest(this._DemoAccountsDeleteRequestModel, type, buffer, offset, size)
        this._DemoAccountsDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DemoAccountsDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DemoAccountsDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountsDeleteResponseModel.verify(), 'manager.DemoAccountsDeleteResponse validation failed!')

        let fbeBegin = this._DemoAccountsDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DemoAccountsDeleteResponse(this._DemoAccountsDeleteResponseModel, type, buffer, offset, size)
        this._DemoAccountsDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpgradeToSocialTradingMasterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpgradeToSocialTradingMasterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingMasterRequestModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterRequest validation failed!')

        let fbeBegin = this._AccountUpgradeToSocialTradingMasterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpgradeToSocialTradingMasterRequest(this._AccountUpgradeToSocialTradingMasterRequestModel, type, buffer, offset, size)
        this._AccountUpgradeToSocialTradingMasterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpgradeToSocialTradingMasterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpgradeToSocialTradingMasterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingMasterResponseModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterResponse validation failed!')

        let fbeBegin = this._AccountUpgradeToSocialTradingMasterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpgradeToSocialTradingMasterResponse(this._AccountUpgradeToSocialTradingMasterResponseModel, type, buffer, offset, size)
        this._AccountUpgradeToSocialTradingMasterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpgradeToSocialTradingInvestorRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpgradeToSocialTradingInvestorRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingInvestorRequestModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorRequest validation failed!')

        let fbeBegin = this._AccountUpgradeToSocialTradingInvestorRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpgradeToSocialTradingInvestorRequest(this._AccountUpgradeToSocialTradingInvestorRequestModel, type, buffer, offset, size)
        this._AccountUpgradeToSocialTradingInvestorRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpgradeToSocialTradingInvestorResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpgradeToSocialTradingInvestorResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingInvestorResponseModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorResponse validation failed!')

        let fbeBegin = this._AccountUpgradeToSocialTradingInvestorResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpgradeToSocialTradingInvestorResponse(this._AccountUpgradeToSocialTradingInvestorResponseModel, type, buffer, offset, size)
        this._AccountUpgradeToSocialTradingInvestorResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SocialTradingInvestorAccountToRegularRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SocialTradingInvestorAccountToRegularRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingInvestorAccountToRegularRequestModel.verify(), 'manager.SocialTradingInvestorAccountToRegularRequest validation failed!')

        let fbeBegin = this._SocialTradingInvestorAccountToRegularRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SocialTradingInvestorAccountToRegularRequest(this._SocialTradingInvestorAccountToRegularRequestModel, type, buffer, offset, size)
        this._SocialTradingInvestorAccountToRegularRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SocialTradingInvestorAccountToRegularResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SocialTradingInvestorAccountToRegularResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingInvestorAccountToRegularResponseModel.verify(), 'manager.SocialTradingInvestorAccountToRegularResponse validation failed!')

        let fbeBegin = this._SocialTradingInvestorAccountToRegularResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SocialTradingInvestorAccountToRegularResponse(this._SocialTradingInvestorAccountToRegularResponseModel, type, buffer, offset, size)
        this._SocialTradingInvestorAccountToRegularResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SocialTradingMasterAccountToRegularRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SocialTradingMasterAccountToRegularRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingMasterAccountToRegularRequestModel.verify(), 'manager.SocialTradingMasterAccountToRegularRequest validation failed!')

        let fbeBegin = this._SocialTradingMasterAccountToRegularRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SocialTradingMasterAccountToRegularRequest(this._SocialTradingMasterAccountToRegularRequestModel, type, buffer, offset, size)
        this._SocialTradingMasterAccountToRegularRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SocialTradingMasterAccountToRegularResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SocialTradingMasterAccountToRegularResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingMasterAccountToRegularResponseModel.verify(), 'manager.SocialTradingMasterAccountToRegularResponse validation failed!')

        let fbeBegin = this._SocialTradingMasterAccountToRegularResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SocialTradingMasterAccountToRegularResponse(this._SocialTradingMasterAccountToRegularResponseModel, type, buffer, offset, size)
        this._SocialTradingMasterAccountToRegularResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateNotifyModel.verify(), 'manager.AccountUpdateNotify validation failed!')

        let fbeBegin = this._AccountUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpdateNotify(this._AccountUpdateNotifyModel, type, buffer, offset, size)
        this._AccountUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginGetRequestModel.verify(), 'manager.MarginGetRequest validation failed!')

        let fbeBegin = this._MarginGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginGetRequest(this._MarginGetRequestModel, type, buffer, offset, size)
        this._MarginGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginGetResponseModel.verify(), 'manager.MarginGetResponse validation failed!')

        let fbeBegin = this._MarginGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginGetResponse(this._MarginGetResponseModel, type, buffer, offset, size)
        this._MarginGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestModel.verify(), 'manager.MarginsGetAllRequest validation failed!')

        let fbeBegin = this._MarginsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginsGetAllRequest(this._MarginsGetAllRequestModel, type, buffer, offset, size)
        this._MarginsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseModel.verify(), 'manager.MarginsGetAllResponse validation failed!')

        let fbeBegin = this._MarginsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginsGetAllResponse(this._MarginsGetAllResponseModel, type, buffer, offset, size)
        this._MarginsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateNotifyModel.verify(), 'manager.MarginUpdateNotify validation failed!')

        let fbeBegin = this._MarginUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginUpdateNotify(this._MarginUpdateNotifyModel, type, buffer, offset, size)
        this._MarginUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginCallNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginCallNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginCallNotifyModel.verify(), 'manager.MarginCallNotify validation failed!')

        let fbeBegin = this._MarginCallNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginCallNotify(this._MarginCallNotifyModel, type, buffer, offset, size)
        this._MarginCallNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case StopOutNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StopOutNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StopOutNotifyModel.verify(), 'manager.StopOutNotify validation failed!')

        let fbeBegin = this._StopOutNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StopOutNotify(this._StopOutNotifyModel, type, buffer, offset, size)
        this._StopOutNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetGetRequestModel.verify(), 'manager.AssetGetRequest validation failed!')

        let fbeBegin = this._AssetGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetGetRequest(this._AssetGetRequestModel, type, buffer, offset, size)
        this._AssetGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetGetResponseModel.verify(), 'manager.AssetGetResponse validation failed!')

        let fbeBegin = this._AssetGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetGetResponse(this._AssetGetResponseModel, type, buffer, offset, size)
        this._AssetGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetRequestModel.verify(), 'manager.AssetsGetRequest validation failed!')

        let fbeBegin = this._AssetsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetRequest(this._AssetsGetRequestModel, type, buffer, offset, size)
        this._AssetsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetResponseModel.verify(), 'manager.AssetsGetResponse validation failed!')

        let fbeBegin = this._AssetsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetResponse(this._AssetsGetResponseModel, type, buffer, offset, size)
        this._AssetsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestModel.verify(), 'manager.AssetsGetAllRequest validation failed!')

        let fbeBegin = this._AssetsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetAllRequest(this._AssetsGetAllRequestModel, type, buffer, offset, size)
        this._AssetsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseModel.verify(), 'manager.AssetsGetAllResponse validation failed!')

        let fbeBegin = this._AssetsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetAllResponse(this._AssetsGetAllResponseModel, type, buffer, offset, size)
        this._AssetsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateNotifyModel.verify(), 'manager.AssetUpdateNotify validation failed!')

        let fbeBegin = this._AssetUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetUpdateNotify(this._AssetUpdateNotifyModel, type, buffer, offset, size)
        this._AssetUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderGetRequestModel.verify(), 'manager.OrderGetRequest validation failed!')

        let fbeBegin = this._OrderGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderGetRequest(this._OrderGetRequestModel, type, buffer, offset, size)
        this._OrderGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderGetResponseModel.verify(), 'manager.OrderGetResponse validation failed!')

        let fbeBegin = this._OrderGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderGetResponse(this._OrderGetResponseModel, type, buffer, offset, size)
        this._OrderGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetRequestModel.verify(), 'manager.OrdersGetRequest validation failed!')

        let fbeBegin = this._OrdersGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetRequest(this._OrdersGetRequestModel, type, buffer, offset, size)
        this._OrdersGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetResponseModel.verify(), 'manager.OrdersGetResponse validation failed!')

        let fbeBegin = this._OrdersGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetResponse(this._OrdersGetResponseModel, type, buffer, offset, size)
        this._OrdersGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestModel.verify(), 'manager.OrdersGetAllRequest validation failed!')

        let fbeBegin = this._OrdersGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetAllRequest(this._OrdersGetAllRequestModel, type, buffer, offset, size)
        this._OrdersGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseModel.verify(), 'manager.OrdersGetAllResponse validation failed!')

        let fbeBegin = this._OrdersGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetAllResponse(this._OrdersGetAllResponseModel, type, buffer, offset, size)
        this._OrdersGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCreateRequestModel.verify(), 'manager.OrderCreateRequest validation failed!')

        let fbeBegin = this._OrderCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCreateRequest(this._OrderCreateRequestModel, type, buffer, offset, size)
        this._OrderCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCreateResponseModel.verify(), 'manager.OrderCreateResponse validation failed!')

        let fbeBegin = this._OrderCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCreateResponse(this._OrderCreateResponseModel, type, buffer, offset, size)
        this._OrderCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderModifyRequestModel.verify(), 'manager.OrderModifyRequest validation failed!')

        let fbeBegin = this._OrderModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderModifyRequest(this._OrderModifyRequestModel, type, buffer, offset, size)
        this._OrderModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderModifyResponseModel.verify(), 'manager.OrderModifyResponse validation failed!')

        let fbeBegin = this._OrderModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderModifyResponse(this._OrderModifyResponseModel, type, buffer, offset, size)
        this._OrderModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderActivateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderActivateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderActivateRequestModel.verify(), 'manager.OrderActivateRequest validation failed!')

        let fbeBegin = this._OrderActivateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderActivateRequest(this._OrderActivateRequestModel, type, buffer, offset, size)
        this._OrderActivateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderActivateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderActivateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderActivateResponseModel.verify(), 'manager.OrderActivateResponse validation failed!')

        let fbeBegin = this._OrderActivateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderActivateResponse(this._OrderActivateResponseModel, type, buffer, offset, size)
        this._OrderActivateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelRequestModel.verify(), 'manager.OrderCancelRequest validation failed!')

        let fbeBegin = this._OrderCancelRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelRequest(this._OrderCancelRequestModel, type, buffer, offset, size)
        this._OrderCancelRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelResponseModel.verify(), 'manager.OrderCancelResponse validation failed!')

        let fbeBegin = this._OrderCancelResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelResponse(this._OrderCancelResponseModel, type, buffer, offset, size)
        this._OrderCancelResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllRequestModel.verify(), 'manager.OrderCancelAllRequest validation failed!')

        let fbeBegin = this._OrderCancelAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelAllRequest(this._OrderCancelAllRequestModel, type, buffer, offset, size)
        this._OrderCancelAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllResponseModel.verify(), 'manager.OrderCancelAllResponse validation failed!')

        let fbeBegin = this._OrderCancelAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelAllResponse(this._OrderCancelAllResponseModel, type, buffer, offset, size)
        this._OrderCancelAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelAllByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllByIdRequestModel.verify(), 'manager.OrderCancelAllByIdRequest validation failed!')

        let fbeBegin = this._OrderCancelAllByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelAllByIdRequest(this._OrderCancelAllByIdRequestModel, type, buffer, offset, size)
        this._OrderCancelAllByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseRequestModel.verify(), 'manager.OrderCloseRequest validation failed!')

        let fbeBegin = this._OrderCloseRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseRequest(this._OrderCloseRequestModel, type, buffer, offset, size)
        this._OrderCloseRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseResponseModel.verify(), 'manager.OrderCloseResponse validation failed!')

        let fbeBegin = this._OrderCloseResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseResponse(this._OrderCloseResponseModel, type, buffer, offset, size)
        this._OrderCloseResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllRequestModel.verify(), 'manager.OrderCloseAllRequest validation failed!')

        let fbeBegin = this._OrderCloseAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseAllRequest(this._OrderCloseAllRequestModel, type, buffer, offset, size)
        this._OrderCloseAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllResponseModel.verify(), 'manager.OrderCloseAllResponse validation failed!')

        let fbeBegin = this._OrderCloseAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseAllResponse(this._OrderCloseAllResponseModel, type, buffer, offset, size)
        this._OrderCloseAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseAllByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllByIdRequestModel.verify(), 'manager.OrderCloseAllByIdRequest validation failed!')

        let fbeBegin = this._OrderCloseAllByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseAllByIdRequest(this._OrderCloseAllByIdRequestModel, type, buffer, offset, size)
        this._OrderCloseAllByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByRequestModel.verify(), 'manager.OrderCloseByRequest validation failed!')

        let fbeBegin = this._OrderCloseByRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByRequest(this._OrderCloseByRequestModel, type, buffer, offset, size)
        this._OrderCloseByRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByResponseModel.verify(), 'manager.OrderCloseByResponse validation failed!')

        let fbeBegin = this._OrderCloseByResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByResponse(this._OrderCloseByResponseModel, type, buffer, offset, size)
        this._OrderCloseByResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllRequestModel.verify(), 'manager.OrderCloseByAllRequest validation failed!')

        let fbeBegin = this._OrderCloseByAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByAllRequest(this._OrderCloseByAllRequestModel, type, buffer, offset, size)
        this._OrderCloseByAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllResponseModel.verify(), 'manager.OrderCloseByAllResponse validation failed!')

        let fbeBegin = this._OrderCloseByAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByAllResponse(this._OrderCloseByAllResponseModel, type, buffer, offset, size)
        this._OrderCloseByAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseRequestModel.verify(), 'manager.OrderCancelOrCloseRequest validation failed!')

        let fbeBegin = this._OrderCancelOrCloseRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseRequest(this._OrderCancelOrCloseRequestModel, type, buffer, offset, size)
        this._OrderCancelOrCloseRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseResponseModel.verify(), 'manager.OrderCancelOrCloseResponse validation failed!')

        let fbeBegin = this._OrderCancelOrCloseResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseResponse(this._OrderCancelOrCloseResponseModel, type, buffer, offset, size)
        this._OrderCancelOrCloseResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllRequestModel.verify(), 'manager.OrderCancelOrCloseAllRequest validation failed!')

        let fbeBegin = this._OrderCancelOrCloseAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseAllRequest(this._OrderCancelOrCloseAllRequestModel, type, buffer, offset, size)
        this._OrderCancelOrCloseAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllResponseModel.verify(), 'manager.OrderCancelOrCloseAllResponse validation failed!')

        let fbeBegin = this._OrderCancelOrCloseAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseAllResponse(this._OrderCancelOrCloseAllResponseModel, type, buffer, offset, size)
        this._OrderCancelOrCloseAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateNotifyModel.verify(), 'manager.OrderUpdateNotify validation failed!')

        let fbeBegin = this._OrderUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateNotify(this._OrderUpdateNotifyModel, type, buffer, offset, size)
        this._OrderUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionGetRequestModel.verify(), 'manager.PositionGetRequest validation failed!')

        let fbeBegin = this._PositionGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionGetRequest(this._PositionGetRequestModel, type, buffer, offset, size)
        this._PositionGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionGetResponseModel.verify(), 'manager.PositionGetResponse validation failed!')

        let fbeBegin = this._PositionGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionGetResponse(this._PositionGetResponseModel, type, buffer, offset, size)
        this._PositionGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetRequestModel.verify(), 'manager.PositionsGetRequest validation failed!')

        let fbeBegin = this._PositionsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetRequest(this._PositionsGetRequestModel, type, buffer, offset, size)
        this._PositionsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetResponseModel.verify(), 'manager.PositionsGetResponse validation failed!')

        let fbeBegin = this._PositionsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetResponse(this._PositionsGetResponseModel, type, buffer, offset, size)
        this._PositionsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestModel.verify(), 'manager.PositionsGetAllRequest validation failed!')

        let fbeBegin = this._PositionsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetAllRequest(this._PositionsGetAllRequestModel, type, buffer, offset, size)
        this._PositionsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseModel.verify(), 'manager.PositionsGetAllResponse validation failed!')

        let fbeBegin = this._PositionsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetAllResponse(this._PositionsGetAllResponseModel, type, buffer, offset, size)
        this._PositionsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateNotifyModel.verify(), 'manager.PositionUpdateNotify validation failed!')

        let fbeBegin = this._PositionUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionUpdateNotify(this._PositionUpdateNotifyModel, type, buffer, offset, size)
        this._PositionUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestModel.verify(), 'manager.TradeHistoryRequest validation failed!')

        let fbeBegin = this._TradeHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryRequest(this._TradeHistoryRequestModel, type, buffer, offset, size)
        this._TradeHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseModel.verify(), 'manager.TradeHistoryResponse validation failed!')

        let fbeBegin = this._TradeHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryResponse(this._TradeHistoryResponseModel, type, buffer, offset, size)
        this._TradeHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryFilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryFilterRequestModel.verify(), 'manager.TradeHistoryFilterRequest validation failed!')

        let fbeBegin = this._TradeHistoryFilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryFilterRequest(this._TradeHistoryFilterRequestModel, type, buffer, offset, size)
        this._TradeHistoryFilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestModel.verify(), 'manager.TradeHistoryByIdRequest validation failed!')

        let fbeBegin = this._TradeHistoryByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestModel, type, buffer, offset, size)
        this._TradeHistoryByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseModel.verify(), 'manager.TradeHistoryByIdResponse validation failed!')

        let fbeBegin = this._TradeHistoryByIdResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseModel, type, buffer, offset, size)
        this._TradeHistoryByIdResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryUpdateNotifyModel.verify(), 'manager.TradeHistoryUpdateNotify validation failed!')

        let fbeBegin = this._TradeHistoryUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryUpdateNotify(this._TradeHistoryUpdateNotifyModel, type, buffer, offset, size)
        this._TradeHistoryUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TestRolloverDumpRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TestRolloverDumpRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TestRolloverDumpRequestModel.verify(), 'manager.TestRolloverDumpRequest validation failed!')

        let fbeBegin = this._TestRolloverDumpRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TestRolloverDumpRequest(this._TestRolloverDumpRequestModel, type, buffer, offset, size)
        this._TestRolloverDumpRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TestRolloverDumpResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TestRolloverDumpResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TestRolloverDumpResponseModel.verify(), 'manager.TestRolloverDumpResponse validation failed!')

        let fbeBegin = this._TestRolloverDumpResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TestRolloverDumpResponse(this._TestRolloverDumpResponseModel, type, buffer, offset, size)
        this._TestRolloverDumpResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated001Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated001Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated001Model.verify(), 'manager.Deprecated001 validation failed!')

        let fbeBegin = this._Deprecated001Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated001(this._Deprecated001Model, type, buffer, offset, size)
        this._Deprecated001Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated002Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated002Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated002Model.verify(), 'manager.Deprecated002 validation failed!')

        let fbeBegin = this._Deprecated002Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated002(this._Deprecated002Model, type, buffer, offset, size)
        this._Deprecated002Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated003Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003Model.verify(), 'manager.Deprecated003 validation failed!')

        let fbeBegin = this._Deprecated003Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated003(this._Deprecated003Model, type, buffer, offset, size)
        this._Deprecated003Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated004Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004Model.verify(), 'manager.Deprecated004 validation failed!')

        let fbeBegin = this._Deprecated004Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated004(this._Deprecated004Model, type, buffer, offset, size)
        this._Deprecated004Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated005Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005Model.verify(), 'manager.Deprecated005 validation failed!')

        let fbeBegin = this._Deprecated005Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated005(this._Deprecated005Model, type, buffer, offset, size)
        this._Deprecated005Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated006Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006Model.verify(), 'manager.Deprecated006 validation failed!')

        let fbeBegin = this._Deprecated006Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated006(this._Deprecated006Model, type, buffer, offset, size)
        this._Deprecated006Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated007Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated007Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated007Model.verify(), 'manager.Deprecated007 validation failed!')

        let fbeBegin = this._Deprecated007Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated007(this._Deprecated007Model, type, buffer, offset, size)
        this._Deprecated007Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated008Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated008Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated008Model.verify(), 'manager.Deprecated008 validation failed!')

        let fbeBegin = this._Deprecated008Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated008(this._Deprecated008Model, type, buffer, offset, size)
        this._Deprecated008Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated009Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated009Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated009Model.verify(), 'manager.Deprecated009 validation failed!')

        let fbeBegin = this._Deprecated009Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated009(this._Deprecated009Model, type, buffer, offset, size)
        this._Deprecated009Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated010Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated010Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated010Model.verify(), 'manager.Deprecated010 validation failed!')

        let fbeBegin = this._Deprecated010Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated010(this._Deprecated010Model, type, buffer, offset, size)
        this._Deprecated010Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated011Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated011Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated011Model.verify(), 'manager.Deprecated011 validation failed!')

        let fbeBegin = this._Deprecated011Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated011(this._Deprecated011Model, type, buffer, offset, size)
        this._Deprecated011Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated012Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated012Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated012Model.verify(), 'manager.Deprecated012 validation failed!')

        let fbeBegin = this._Deprecated012Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated012(this._Deprecated012Model, type, buffer, offset, size)
        this._Deprecated012Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated013Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated013Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated013Model.verify(), 'manager.Deprecated013 validation failed!')

        let fbeBegin = this._Deprecated013Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated013(this._Deprecated013Model, type, buffer, offset, size)
        this._Deprecated013Model.model.getEnd(fbeBegin)
        return true
      }
      case DepositAccountRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DepositAccountRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DepositAccountRequestModel.verify(), 'manager.DepositAccountRequest validation failed!')

        let fbeBegin = this._DepositAccountRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DepositAccountRequest(this._DepositAccountRequestModel, type, buffer, offset, size)
        this._DepositAccountRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DepositAccountResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DepositAccountResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DepositAccountResponseModel.verify(), 'manager.DepositAccountResponse validation failed!')

        let fbeBegin = this._DepositAccountResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DepositAccountResponse(this._DepositAccountResponseModel, type, buffer, offset, size)
        this._DepositAccountResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CreditAccountRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CreditAccountRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CreditAccountRequestModel.verify(), 'manager.CreditAccountRequest validation failed!')

        let fbeBegin = this._CreditAccountRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CreditAccountRequest(this._CreditAccountRequestModel, type, buffer, offset, size)
        this._CreditAccountRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CreditAccountResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CreditAccountResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CreditAccountResponseModel.verify(), 'manager.CreditAccountResponse validation failed!')

        let fbeBegin = this._CreditAccountResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CreditAccountResponse(this._CreditAccountResponseModel, type, buffer, offset, size)
        this._CreditAccountResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TransferMoneyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TransferMoneyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TransferMoneyRequestModel.verify(), 'manager.TransferMoneyRequest validation failed!')

        let fbeBegin = this._TransferMoneyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TransferMoneyRequest(this._TransferMoneyRequestModel, type, buffer, offset, size)
        this._TransferMoneyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TransferMoneyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TransferMoneyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TransferMoneyResponseModel.verify(), 'manager.TransferMoneyResponse validation failed!')

        let fbeBegin = this._TransferMoneyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TransferMoneyResponse(this._TransferMoneyResponseModel, type, buffer, offset, size)
        this._TransferMoneyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleGetRequestModel.verify(), 'manager.TradingCalendarRegularRuleGetRequest validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleGetRequest(this._TradingCalendarRegularRuleGetRequestModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleGetResponseModel.verify(), 'manager.TradingCalendarRegularRuleGetResponse validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleGetResponse(this._TradingCalendarRegularRuleGetResponseModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRulesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRulesGetAllRequestModel.verify(), 'manager.TradingCalendarRegularRulesGetAllRequest validation failed!')

        let fbeBegin = this._TradingCalendarRegularRulesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRulesGetAllRequest(this._TradingCalendarRegularRulesGetAllRequestModel, type, buffer, offset, size)
        this._TradingCalendarRegularRulesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRulesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRulesGetAllResponseModel.verify(), 'manager.TradingCalendarRegularRulesGetAllResponse validation failed!')

        let fbeBegin = this._TradingCalendarRegularRulesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRulesGetAllResponse(this._TradingCalendarRegularRulesGetAllResponseModel, type, buffer, offset, size)
        this._TradingCalendarRegularRulesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleCreateRequestModel.verify(), 'manager.TradingCalendarRegularRuleCreateRequest validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleCreateRequest(this._TradingCalendarRegularRuleCreateRequestModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleCreateResponseModel.verify(), 'manager.TradingCalendarRegularRuleCreateResponse validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleCreateResponse(this._TradingCalendarRegularRuleCreateResponseModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleModifyRequestModel.verify(), 'manager.TradingCalendarRegularRuleModifyRequest validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleModifyRequest(this._TradingCalendarRegularRuleModifyRequestModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleModifyResponseModel.verify(), 'manager.TradingCalendarRegularRuleModifyResponse validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleModifyResponse(this._TradingCalendarRegularRuleModifyResponseModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleDeleteRequestModel.verify(), 'manager.TradingCalendarRegularRuleDeleteRequest validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleDeleteRequest(this._TradingCalendarRegularRuleDeleteRequestModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleDeleteResponseModel.verify(), 'manager.TradingCalendarRegularRuleDeleteResponse validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleDeleteResponse(this._TradingCalendarRegularRuleDeleteResponseModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRegularRuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRegularRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleUpdateNotifyModel.verify(), 'manager.TradingCalendarRegularRuleUpdateNotify validation failed!')

        let fbeBegin = this._TradingCalendarRegularRuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRegularRuleUpdateNotify(this._TradingCalendarRegularRuleUpdateNotifyModel, type, buffer, offset, size)
        this._TradingCalendarRegularRuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleGetRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleGetRequest validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleGetRequest(this._TradingCalendarOffTimeRuleGetRequestModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleGetResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleGetResponse validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleGetResponse(this._TradingCalendarOffTimeRuleGetResponseModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRulesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRulesGetAllRequestModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllRequest validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRulesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRulesGetAllRequest(this._TradingCalendarOffTimeRulesGetAllRequestModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRulesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRulesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRulesGetAllResponseModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllResponse validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRulesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRulesGetAllResponse(this._TradingCalendarOffTimeRulesGetAllResponseModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRulesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleCreateRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateRequest validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleCreateRequest(this._TradingCalendarOffTimeRuleCreateRequestModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleCreateResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateResponse validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleCreateResponse(this._TradingCalendarOffTimeRuleCreateResponseModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleModifyRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyRequest validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleModifyRequest(this._TradingCalendarOffTimeRuleModifyRequestModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleModifyResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyResponse validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleModifyResponse(this._TradingCalendarOffTimeRuleModifyResponseModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleDeleteRequestModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteRequest validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleDeleteRequest(this._TradingCalendarOffTimeRuleDeleteRequestModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleDeleteResponseModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteResponse validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleDeleteResponse(this._TradingCalendarOffTimeRuleDeleteResponseModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarOffTimeRuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarOffTimeRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleUpdateNotifyModel.verify(), 'manager.TradingCalendarOffTimeRuleUpdateNotify validation failed!')

        let fbeBegin = this._TradingCalendarOffTimeRuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarOffTimeRuleUpdateNotify(this._TradingCalendarOffTimeRuleUpdateNotifyModel, type, buffer, offset, size)
        this._TradingCalendarOffTimeRuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRulesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRulesGetRequestModel.verify(), 'manager.TradingCalendarRulesGetRequest validation failed!')

        let fbeBegin = this._TradingCalendarRulesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRulesGetRequest(this._TradingCalendarRulesGetRequestModel, type, buffer, offset, size)
        this._TradingCalendarRulesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingCalendarRulesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingCalendarRulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRulesGetResponseModel.verify(), 'manager.TradingCalendarRulesGetResponse validation failed!')

        let fbeBegin = this._TradingCalendarRulesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingCalendarRulesGetResponse(this._TradingCalendarRulesGetResponseModel, type, buffer, offset, size)
        this._TradingCalendarRulesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRulesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRulesGetRequestModel.verify(), 'manager.TradingRouteRulesGetRequest validation failed!')

        let fbeBegin = this._TradingRouteRulesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRulesGetRequest(this._TradingRouteRulesGetRequestModel, type, buffer, offset, size)
        this._TradingRouteRulesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRulesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRulesGetResponseModel.verify(), 'manager.TradingRouteRulesGetResponse validation failed!')

        let fbeBegin = this._TradingRouteRulesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRulesGetResponse(this._TradingRouteRulesGetResponseModel, type, buffer, offset, size)
        this._TradingRouteRulesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRuleCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleCreateRequestModel.verify(), 'manager.TradingRouteRuleCreateRequest validation failed!')

        let fbeBegin = this._TradingRouteRuleCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRuleCreateRequest(this._TradingRouteRuleCreateRequestModel, type, buffer, offset, size)
        this._TradingRouteRuleCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRuleCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleCreateResponseModel.verify(), 'manager.TradingRouteRuleCreateResponse validation failed!')

        let fbeBegin = this._TradingRouteRuleCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRuleCreateResponse(this._TradingRouteRuleCreateResponseModel, type, buffer, offset, size)
        this._TradingRouteRuleCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRuleModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleModifyRequestModel.verify(), 'manager.TradingRouteRuleModifyRequest validation failed!')

        let fbeBegin = this._TradingRouteRuleModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRuleModifyRequest(this._TradingRouteRuleModifyRequestModel, type, buffer, offset, size)
        this._TradingRouteRuleModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRuleModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleModifyResponseModel.verify(), 'manager.TradingRouteRuleModifyResponse validation failed!')

        let fbeBegin = this._TradingRouteRuleModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRuleModifyResponse(this._TradingRouteRuleModifyResponseModel, type, buffer, offset, size)
        this._TradingRouteRuleModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRuleDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleDeleteRequestModel.verify(), 'manager.TradingRouteRuleDeleteRequest validation failed!')

        let fbeBegin = this._TradingRouteRuleDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRuleDeleteRequest(this._TradingRouteRuleDeleteRequestModel, type, buffer, offset, size)
        this._TradingRouteRuleDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRuleDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleDeleteResponseModel.verify(), 'manager.TradingRouteRuleDeleteResponse validation failed!')

        let fbeBegin = this._TradingRouteRuleDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRuleDeleteResponse(this._TradingRouteRuleDeleteResponseModel, type, buffer, offset, size)
        this._TradingRouteRuleDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradingRouteRuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradingRouteRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleUpdateNotifyModel.verify(), 'manager.TradingRouteRuleUpdateNotify validation failed!')

        let fbeBegin = this._TradingRouteRuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradingRouteRuleUpdateNotify(this._TradingRouteRuleUpdateNotifyModel, type, buffer, offset, size)
        this._TradingRouteRuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapsGetRequestModel.verify(), 'manager.SymbolMapsGetRequest validation failed!')

        let fbeBegin = this._SymbolMapsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapsGetRequest(this._SymbolMapsGetRequestModel, type, buffer, offset, size)
        this._SymbolMapsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapsGetResponseModel.verify(), 'manager.SymbolMapsGetResponse validation failed!')

        let fbeBegin = this._SymbolMapsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapsGetResponse(this._SymbolMapsGetResponseModel, type, buffer, offset, size)
        this._SymbolMapsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapCreateRequestModel.verify(), 'manager.SymbolMapCreateRequest validation failed!')

        let fbeBegin = this._SymbolMapCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapCreateRequest(this._SymbolMapCreateRequestModel, type, buffer, offset, size)
        this._SymbolMapCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapCreateResponseModel.verify(), 'manager.SymbolMapCreateResponse validation failed!')

        let fbeBegin = this._SymbolMapCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapCreateResponse(this._SymbolMapCreateResponseModel, type, buffer, offset, size)
        this._SymbolMapCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapModifyRequestModel.verify(), 'manager.SymbolMapModifyRequest validation failed!')

        let fbeBegin = this._SymbolMapModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapModifyRequest(this._SymbolMapModifyRequestModel, type, buffer, offset, size)
        this._SymbolMapModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapModifyResponseModel.verify(), 'manager.SymbolMapModifyResponse validation failed!')

        let fbeBegin = this._SymbolMapModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapModifyResponse(this._SymbolMapModifyResponseModel, type, buffer, offset, size)
        this._SymbolMapModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapDeleteRequestModel.verify(), 'manager.SymbolMapDeleteRequest validation failed!')

        let fbeBegin = this._SymbolMapDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapDeleteRequest(this._SymbolMapDeleteRequestModel, type, buffer, offset, size)
        this._SymbolMapDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapDeleteResponseModel.verify(), 'manager.SymbolMapDeleteResponse validation failed!')

        let fbeBegin = this._SymbolMapDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapDeleteResponse(this._SymbolMapDeleteResponseModel, type, buffer, offset, size)
        this._SymbolMapDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolMapUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolMapUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapUpdateNotifyModel.verify(), 'manager.SymbolMapUpdateNotify validation failed!')

        let fbeBegin = this._SymbolMapUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolMapUpdateNotify(this._SymbolMapUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolMapUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewaysGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewaysGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewaysGetRequestModel.verify(), 'manager.GatewaysGetRequest validation failed!')

        let fbeBegin = this._GatewaysGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewaysGetRequest(this._GatewaysGetRequestModel, type, buffer, offset, size)
        this._GatewaysGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewaysGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewaysGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewaysGetResponseModel.verify(), 'manager.GatewaysGetResponse validation failed!')

        let fbeBegin = this._GatewaysGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewaysGetResponse(this._GatewaysGetResponseModel, type, buffer, offset, size)
        this._GatewaysGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayCreateRequestModel.verify(), 'manager.GatewayCreateRequest validation failed!')

        let fbeBegin = this._GatewayCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayCreateRequest(this._GatewayCreateRequestModel, type, buffer, offset, size)
        this._GatewayCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayCreateResponseModel.verify(), 'manager.GatewayCreateResponse validation failed!')

        let fbeBegin = this._GatewayCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayCreateResponse(this._GatewayCreateResponseModel, type, buffer, offset, size)
        this._GatewayCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModifyRequestModel.verify(), 'manager.GatewayModifyRequest validation failed!')

        let fbeBegin = this._GatewayModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayModifyRequest(this._GatewayModifyRequestModel, type, buffer, offset, size)
        this._GatewayModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModifyResponseModel.verify(), 'manager.GatewayModifyResponse validation failed!')

        let fbeBegin = this._GatewayModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayModifyResponse(this._GatewayModifyResponseModel, type, buffer, offset, size)
        this._GatewayModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayDeleteRequestModel.verify(), 'manager.GatewayDeleteRequest validation failed!')

        let fbeBegin = this._GatewayDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayDeleteRequest(this._GatewayDeleteRequestModel, type, buffer, offset, size)
        this._GatewayDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayDeleteResponseModel.verify(), 'manager.GatewayDeleteResponse validation failed!')

        let fbeBegin = this._GatewayDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayDeleteResponse(this._GatewayDeleteResponseModel, type, buffer, offset, size)
        this._GatewayDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayUpdateNotifyModel.verify(), 'manager.GatewayUpdateNotify validation failed!')

        let fbeBegin = this._GatewayUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayUpdateNotify(this._GatewayUpdateNotifyModel, type, buffer, offset, size)
        this._GatewayUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedsGetRequestModel.verify(), 'manager.DataFeedsGetRequest validation failed!')

        let fbeBegin = this._DataFeedsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedsGetRequest(this._DataFeedsGetRequestModel, type, buffer, offset, size)
        this._DataFeedsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedsGetResponseModel.verify(), 'manager.DataFeedsGetResponse validation failed!')

        let fbeBegin = this._DataFeedsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedsGetResponse(this._DataFeedsGetResponseModel, type, buffer, offset, size)
        this._DataFeedsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedCreateRequestModel.verify(), 'manager.DataFeedCreateRequest validation failed!')

        let fbeBegin = this._DataFeedCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedCreateRequest(this._DataFeedCreateRequestModel, type, buffer, offset, size)
        this._DataFeedCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedCreateResponseModel.verify(), 'manager.DataFeedCreateResponse validation failed!')

        let fbeBegin = this._DataFeedCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedCreateResponse(this._DataFeedCreateResponseModel, type, buffer, offset, size)
        this._DataFeedCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModifyRequestModel.verify(), 'manager.DataFeedModifyRequest validation failed!')

        let fbeBegin = this._DataFeedModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedModifyRequest(this._DataFeedModifyRequestModel, type, buffer, offset, size)
        this._DataFeedModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModifyResponseModel.verify(), 'manager.DataFeedModifyResponse validation failed!')

        let fbeBegin = this._DataFeedModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedModifyResponse(this._DataFeedModifyResponseModel, type, buffer, offset, size)
        this._DataFeedModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedDeleteRequestModel.verify(), 'manager.DataFeedDeleteRequest validation failed!')

        let fbeBegin = this._DataFeedDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedDeleteRequest(this._DataFeedDeleteRequestModel, type, buffer, offset, size)
        this._DataFeedDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedDeleteResponseModel.verify(), 'manager.DataFeedDeleteResponse validation failed!')

        let fbeBegin = this._DataFeedDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedDeleteResponse(this._DataFeedDeleteResponseModel, type, buffer, offset, size)
        this._DataFeedDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedUpdateNotifyModel.verify(), 'manager.DataFeedUpdateNotify validation failed!')

        let fbeBegin = this._DataFeedUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedUpdateNotify(this._DataFeedUpdateNotifyModel, type, buffer, offset, size)
        this._DataFeedUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleSystemInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleSystemInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoNotifyModel.verify(), 'manager.ModuleSystemInfoNotify validation failed!')

        let fbeBegin = this._ModuleSystemInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleSystemInfoNotify(this._ModuleSystemInfoNotifyModel, type, buffer, offset, size)
        this._ModuleSystemInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ModulesSystemInfoGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModulesSystemInfoGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoGetAllRequestModel.verify(), 'manager.ModulesSystemInfoGetAllRequest validation failed!')

        let fbeBegin = this._ModulesSystemInfoGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModulesSystemInfoGetAllRequest(this._ModulesSystemInfoGetAllRequestModel, type, buffer, offset, size)
        this._ModulesSystemInfoGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModulesSystemInfoGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModulesSystemInfoGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoGetAllResponseModel.verify(), 'manager.ModulesSystemInfoGetAllResponse validation failed!')

        let fbeBegin = this._ModulesSystemInfoGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModulesSystemInfoGetAllResponse(this._ModulesSystemInfoGetAllResponseModel, type, buffer, offset, size)
        this._ModulesSystemInfoGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModulesSystemInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModulesSystemInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoNotifyModel.verify(), 'manager.ModulesSystemInfoNotify validation failed!')

        let fbeBegin = this._ModulesSystemInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModulesSystemInfoNotify(this._ModulesSystemInfoNotifyModel, type, buffer, offset, size)
        this._ModulesSystemInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyRequestModel.verify(), 'manager.ChartBarHistoryModifyRequest validation failed!')

        let fbeBegin = this._ChartBarHistoryModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryModifyRequest(this._ChartBarHistoryModifyRequestModel, type, buffer, offset, size)
        this._ChartBarHistoryModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyResponseModel.verify(), 'manager.ChartBarHistoryModifyResponse validation failed!')

        let fbeBegin = this._ChartBarHistoryModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryModifyResponse(this._ChartBarHistoryModifyResponseModel, type, buffer, offset, size)
        this._ChartBarHistoryModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateTickSnapshotRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateTickSnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotRequestModel.verify(), 'manager.FeedUpdateTickSnapshotRequest validation failed!')

        let fbeBegin = this._FeedUpdateTickSnapshotRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateTickSnapshotRequest(this._FeedUpdateTickSnapshotRequestModel, type, buffer, offset, size)
        this._FeedUpdateTickSnapshotRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateTickSnapshotResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateTickSnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotResponseModel.verify(), 'manager.FeedUpdateTickSnapshotResponse validation failed!')

        let fbeBegin = this._FeedUpdateTickSnapshotResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateTickSnapshotResponse(this._FeedUpdateTickSnapshotResponseModel, type, buffer, offset, size)
        this._FeedUpdateTickSnapshotResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateTickRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateTickRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickRequestModel.verify(), 'manager.FeedUpdateTickRequest validation failed!')

        let fbeBegin = this._FeedUpdateTickRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateTickRequest(this._FeedUpdateTickRequestModel, type, buffer, offset, size)
        this._FeedUpdateTickRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateTickResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateTickResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickResponseModel.verify(), 'manager.FeedUpdateTickResponse validation failed!')

        let fbeBegin = this._FeedUpdateTickResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateTickResponse(this._FeedUpdateTickResponseModel, type, buffer, offset, size)
        this._FeedUpdateTickResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateLevel2SnapshotRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateLevel2SnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotRequestModel.verify(), 'manager.FeedUpdateLevel2SnapshotRequest validation failed!')

        let fbeBegin = this._FeedUpdateLevel2SnapshotRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateLevel2SnapshotRequest(this._FeedUpdateLevel2SnapshotRequestModel, type, buffer, offset, size)
        this._FeedUpdateLevel2SnapshotRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateLevel2SnapshotResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateLevel2SnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotResponseModel.verify(), 'manager.FeedUpdateLevel2SnapshotResponse validation failed!')

        let fbeBegin = this._FeedUpdateLevel2SnapshotResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateLevel2SnapshotResponse(this._FeedUpdateLevel2SnapshotResponseModel, type, buffer, offset, size)
        this._FeedUpdateLevel2SnapshotResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateLevel2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateLevel2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2RequestModel.verify(), 'manager.FeedUpdateLevel2Request validation failed!')

        let fbeBegin = this._FeedUpdateLevel2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateLevel2Request(this._FeedUpdateLevel2RequestModel, type, buffer, offset, size)
        this._FeedUpdateLevel2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateLevel2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateLevel2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2ResponseModel.verify(), 'manager.FeedUpdateLevel2Response validation failed!')

        let fbeBegin = this._FeedUpdateLevel2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateLevel2Response(this._FeedUpdateLevel2ResponseModel, type, buffer, offset, size)
        this._FeedUpdateLevel2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerGetRequestModel.verify(), 'manager.MailServerGetRequest validation failed!')

        let fbeBegin = this._MailServerGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerGetRequest(this._MailServerGetRequestModel, type, buffer, offset, size)
        this._MailServerGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerGetResponseModel.verify(), 'manager.MailServerGetResponse validation failed!')

        let fbeBegin = this._MailServerGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerGetResponse(this._MailServerGetResponseModel, type, buffer, offset, size)
        this._MailServerGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServersGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServersGetAllRequestModel.verify(), 'manager.MailServersGetAllRequest validation failed!')

        let fbeBegin = this._MailServersGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServersGetAllRequest(this._MailServersGetAllRequestModel, type, buffer, offset, size)
        this._MailServersGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServersGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServersGetAllResponseModel.verify(), 'manager.MailServersGetAllResponse validation failed!')

        let fbeBegin = this._MailServersGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServersGetAllResponse(this._MailServersGetAllResponseModel, type, buffer, offset, size)
        this._MailServersGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerCreateRequestModel.verify(), 'manager.MailServerCreateRequest validation failed!')

        let fbeBegin = this._MailServerCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerCreateRequest(this._MailServerCreateRequestModel, type, buffer, offset, size)
        this._MailServerCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerCreateResponseModel.verify(), 'manager.MailServerCreateResponse validation failed!')

        let fbeBegin = this._MailServerCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerCreateResponse(this._MailServerCreateResponseModel, type, buffer, offset, size)
        this._MailServerCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerModifyRequestModel.verify(), 'manager.MailServerModifyRequest validation failed!')

        let fbeBegin = this._MailServerModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerModifyRequest(this._MailServerModifyRequestModel, type, buffer, offset, size)
        this._MailServerModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerModifyResponseModel.verify(), 'manager.MailServerModifyResponse validation failed!')

        let fbeBegin = this._MailServerModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerModifyResponse(this._MailServerModifyResponseModel, type, buffer, offset, size)
        this._MailServerModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerDeleteRequestModel.verify(), 'manager.MailServerDeleteRequest validation failed!')

        let fbeBegin = this._MailServerDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerDeleteRequest(this._MailServerDeleteRequestModel, type, buffer, offset, size)
        this._MailServerDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerDeleteResponseModel.verify(), 'manager.MailServerDeleteResponse validation failed!')

        let fbeBegin = this._MailServerDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerDeleteResponse(this._MailServerDeleteResponseModel, type, buffer, offset, size)
        this._MailServerDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MailServerUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MailServerUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerUpdateNotifyModel.verify(), 'manager.MailServerUpdateNotify validation failed!')

        let fbeBegin = this._MailServerUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MailServerUpdateNotify(this._MailServerUpdateNotifyModel, type, buffer, offset, size)
        this._MailServerUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountStatesUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountStatesUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesUpdateNotifyModel.verify(), 'manager.AccountStatesUpdateNotify validation failed!')

        let fbeBegin = this._AccountStatesUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountStatesUpdateNotify(this._AccountStatesUpdateNotifyModel, type, buffer, offset, size)
        this._AccountStatesUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountStatesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountStatesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesGetAllRequestModel.verify(), 'manager.AccountStatesGetAllRequest validation failed!')

        let fbeBegin = this._AccountStatesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountStatesGetAllRequest(this._AccountStatesGetAllRequestModel, type, buffer, offset, size)
        this._AccountStatesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountStatesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountStatesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesGetAllResponseModel.verify(), 'manager.AccountStatesGetAllResponse validation failed!')

        let fbeBegin = this._AccountStatesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountStatesGetAllResponse(this._AccountStatesGetAllResponseModel, type, buffer, offset, size)
        this._AccountStatesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleAuthRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleAuthRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleAuthRequestModel.verify(), 'manager.ModuleAuthRequest validation failed!')

        let fbeBegin = this._ModuleAuthRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleAuthRequest(this._ModuleAuthRequestModel, type, buffer, offset, size)
        this._ModuleAuthRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleAuthResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleAuthResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleAuthResponseModel.verify(), 'manager.ModuleAuthResponse validation failed!')

        let fbeBegin = this._ModuleAuthResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleAuthResponse(this._ModuleAuthResponseModel, type, buffer, offset, size)
        this._ModuleAuthResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileGetRequestModel.verify(), 'manager.LeverageProfileGetRequest validation failed!')

        let fbeBegin = this._LeverageProfileGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileGetRequest(this._LeverageProfileGetRequestModel, type, buffer, offset, size)
        this._LeverageProfileGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileGetResponseModel.verify(), 'manager.LeverageProfileGetResponse validation failed!')

        let fbeBegin = this._LeverageProfileGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileGetResponse(this._LeverageProfileGetResponseModel, type, buffer, offset, size)
        this._LeverageProfileGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfilesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfilesGetAllRequestModel.verify(), 'manager.LeverageProfilesGetAllRequest validation failed!')

        let fbeBegin = this._LeverageProfilesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfilesGetAllRequest(this._LeverageProfilesGetAllRequestModel, type, buffer, offset, size)
        this._LeverageProfilesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfilesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfilesGetAllResponseModel.verify(), 'manager.LeverageProfilesGetAllResponse validation failed!')

        let fbeBegin = this._LeverageProfilesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfilesGetAllResponse(this._LeverageProfilesGetAllResponseModel, type, buffer, offset, size)
        this._LeverageProfilesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileCreateRequestModel.verify(), 'manager.LeverageProfileCreateRequest validation failed!')

        let fbeBegin = this._LeverageProfileCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileCreateRequest(this._LeverageProfileCreateRequestModel, type, buffer, offset, size)
        this._LeverageProfileCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileCreateResponseModel.verify(), 'manager.LeverageProfileCreateResponse validation failed!')

        let fbeBegin = this._LeverageProfileCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileCreateResponse(this._LeverageProfileCreateResponseModel, type, buffer, offset, size)
        this._LeverageProfileCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileModifyRequestModel.verify(), 'manager.LeverageProfileModifyRequest validation failed!')

        let fbeBegin = this._LeverageProfileModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileModifyRequest(this._LeverageProfileModifyRequestModel, type, buffer, offset, size)
        this._LeverageProfileModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileModifyResponseModel.verify(), 'manager.LeverageProfileModifyResponse validation failed!')

        let fbeBegin = this._LeverageProfileModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileModifyResponse(this._LeverageProfileModifyResponseModel, type, buffer, offset, size)
        this._LeverageProfileModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileDeleteRequestModel.verify(), 'manager.LeverageProfileDeleteRequest validation failed!')

        let fbeBegin = this._LeverageProfileDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileDeleteRequest(this._LeverageProfileDeleteRequestModel, type, buffer, offset, size)
        this._LeverageProfileDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileDeleteResponseModel.verify(), 'manager.LeverageProfileDeleteResponse validation failed!')

        let fbeBegin = this._LeverageProfileDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileDeleteResponse(this._LeverageProfileDeleteResponseModel, type, buffer, offset, size)
        this._LeverageProfileDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageProfileUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileUpdateNotifyModel.verify(), 'manager.LeverageProfileUpdateNotify validation failed!')

        let fbeBegin = this._LeverageProfileUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageProfileUpdateNotify(this._LeverageProfileUpdateNotifyModel, type, buffer, offset, size)
        this._LeverageProfileUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileGetRequestModel.verify(), 'manager.MarginRateProfileGetRequest validation failed!')

        let fbeBegin = this._MarginRateProfileGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileGetRequest(this._MarginRateProfileGetRequestModel, type, buffer, offset, size)
        this._MarginRateProfileGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileGetResponseModel.verify(), 'manager.MarginRateProfileGetResponse validation failed!')

        let fbeBegin = this._MarginRateProfileGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileGetResponse(this._MarginRateProfileGetResponseModel, type, buffer, offset, size)
        this._MarginRateProfileGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfilesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfilesGetAllRequestModel.verify(), 'manager.MarginRateProfilesGetAllRequest validation failed!')

        let fbeBegin = this._MarginRateProfilesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfilesGetAllRequest(this._MarginRateProfilesGetAllRequestModel, type, buffer, offset, size)
        this._MarginRateProfilesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfilesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfilesGetAllResponseModel.verify(), 'manager.MarginRateProfilesGetAllResponse validation failed!')

        let fbeBegin = this._MarginRateProfilesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfilesGetAllResponse(this._MarginRateProfilesGetAllResponseModel, type, buffer, offset, size)
        this._MarginRateProfilesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileCreateRequestModel.verify(), 'manager.MarginRateProfileCreateRequest validation failed!')

        let fbeBegin = this._MarginRateProfileCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileCreateRequest(this._MarginRateProfileCreateRequestModel, type, buffer, offset, size)
        this._MarginRateProfileCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileCreateResponseModel.verify(), 'manager.MarginRateProfileCreateResponse validation failed!')

        let fbeBegin = this._MarginRateProfileCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileCreateResponse(this._MarginRateProfileCreateResponseModel, type, buffer, offset, size)
        this._MarginRateProfileCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileModifyRequestModel.verify(), 'manager.MarginRateProfileModifyRequest validation failed!')

        let fbeBegin = this._MarginRateProfileModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileModifyRequest(this._MarginRateProfileModifyRequestModel, type, buffer, offset, size)
        this._MarginRateProfileModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileModifyResponseModel.verify(), 'manager.MarginRateProfileModifyResponse validation failed!')

        let fbeBegin = this._MarginRateProfileModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileModifyResponse(this._MarginRateProfileModifyResponseModel, type, buffer, offset, size)
        this._MarginRateProfileModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileDeleteRequestModel.verify(), 'manager.MarginRateProfileDeleteRequest validation failed!')

        let fbeBegin = this._MarginRateProfileDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileDeleteRequest(this._MarginRateProfileDeleteRequestModel, type, buffer, offset, size)
        this._MarginRateProfileDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileDeleteResponseModel.verify(), 'manager.MarginRateProfileDeleteResponse validation failed!')

        let fbeBegin = this._MarginRateProfileDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileDeleteResponse(this._MarginRateProfileDeleteResponseModel, type, buffer, offset, size)
        this._MarginRateProfileDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginRateProfileUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginRateProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileUpdateNotifyModel.verify(), 'manager.MarginRateProfileUpdateNotify validation failed!')

        let fbeBegin = this._MarginRateProfileUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginRateProfileUpdateNotify(this._MarginRateProfileUpdateNotifyModel, type, buffer, offset, size)
        this._MarginRateProfileUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileGetRequestModel.verify(), 'manager.NewsProfileGetRequest validation failed!')

        let fbeBegin = this._NewsProfileGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileGetRequest(this._NewsProfileGetRequestModel, type, buffer, offset, size)
        this._NewsProfileGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileGetResponseModel.verify(), 'manager.NewsProfileGetResponse validation failed!')

        let fbeBegin = this._NewsProfileGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileGetResponse(this._NewsProfileGetResponseModel, type, buffer, offset, size)
        this._NewsProfileGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfilesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfilesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfilesGetAllRequestModel.verify(), 'manager.NewsProfilesGetAllRequest validation failed!')

        let fbeBegin = this._NewsProfilesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfilesGetAllRequest(this._NewsProfilesGetAllRequestModel, type, buffer, offset, size)
        this._NewsProfilesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfilesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfilesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfilesGetAllResponseModel.verify(), 'manager.NewsProfilesGetAllResponse validation failed!')

        let fbeBegin = this._NewsProfilesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfilesGetAllResponse(this._NewsProfilesGetAllResponseModel, type, buffer, offset, size)
        this._NewsProfilesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileCreateRequestModel.verify(), 'manager.NewsProfileCreateRequest validation failed!')

        let fbeBegin = this._NewsProfileCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileCreateRequest(this._NewsProfileCreateRequestModel, type, buffer, offset, size)
        this._NewsProfileCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileCreateResponseModel.verify(), 'manager.NewsProfileCreateResponse validation failed!')

        let fbeBegin = this._NewsProfileCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileCreateResponse(this._NewsProfileCreateResponseModel, type, buffer, offset, size)
        this._NewsProfileCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileModifyRequestModel.verify(), 'manager.NewsProfileModifyRequest validation failed!')

        let fbeBegin = this._NewsProfileModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileModifyRequest(this._NewsProfileModifyRequestModel, type, buffer, offset, size)
        this._NewsProfileModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileModifyResponseModel.verify(), 'manager.NewsProfileModifyResponse validation failed!')

        let fbeBegin = this._NewsProfileModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileModifyResponse(this._NewsProfileModifyResponseModel, type, buffer, offset, size)
        this._NewsProfileModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileDeleteRequestModel.verify(), 'manager.NewsProfileDeleteRequest validation failed!')

        let fbeBegin = this._NewsProfileDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileDeleteRequest(this._NewsProfileDeleteRequestModel, type, buffer, offset, size)
        this._NewsProfileDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileDeleteResponseModel.verify(), 'manager.NewsProfileDeleteResponse validation failed!')

        let fbeBegin = this._NewsProfileDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileDeleteResponse(this._NewsProfileDeleteResponseModel, type, buffer, offset, size)
        this._NewsProfileDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileUpdateNotifyModel.verify(), 'manager.NewsProfileUpdateNotify validation failed!')

        let fbeBegin = this._NewsProfileUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileUpdateNotify(this._NewsProfileUpdateNotifyModel, type, buffer, offset, size)
        this._NewsProfileUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileImportRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileImportRequestModel.verify(), 'manager.NewsProfileImportRequest validation failed!')

        let fbeBegin = this._NewsProfileImportRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileImportRequest(this._NewsProfileImportRequestModel, type, buffer, offset, size)
        this._NewsProfileImportRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsProfileImportResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsProfileImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileImportResponseModel.verify(), 'manager.NewsProfileImportResponse validation failed!')

        let fbeBegin = this._NewsProfileImportResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsProfileImportResponse(this._NewsProfileImportResponseModel, type, buffer, offset, size)
        this._NewsProfileImportResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TickHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryRequestModel.verify(), 'manager.TickHistoryRequest validation failed!')

        let fbeBegin = this._TickHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickHistoryRequest(this._TickHistoryRequestModel, type, buffer, offset, size)
        this._TickHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryResponseModel.verify(), 'manager.TickHistoryResponse validation failed!')

        let fbeBegin = this._TickHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickHistoryResponse(this._TickHistoryResponseModel, type, buffer, offset, size)
        this._TickHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateGetRequestModel.verify(), 'manager.EmailTemplateGetRequest validation failed!')

        let fbeBegin = this._EmailTemplateGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateGetRequest(this._EmailTemplateGetRequestModel, type, buffer, offset, size)
        this._EmailTemplateGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateGetResponseModel.verify(), 'manager.EmailTemplateGetResponse validation failed!')

        let fbeBegin = this._EmailTemplateGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateGetResponse(this._EmailTemplateGetResponseModel, type, buffer, offset, size)
        this._EmailTemplateGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplatesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplatesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplatesGetAllRequestModel.verify(), 'manager.EmailTemplatesGetAllRequest validation failed!')

        let fbeBegin = this._EmailTemplatesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplatesGetAllRequest(this._EmailTemplatesGetAllRequestModel, type, buffer, offset, size)
        this._EmailTemplatesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplatesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplatesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplatesGetAllResponseModel.verify(), 'manager.EmailTemplatesGetAllResponse validation failed!')

        let fbeBegin = this._EmailTemplatesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplatesGetAllResponse(this._EmailTemplatesGetAllResponseModel, type, buffer, offset, size)
        this._EmailTemplatesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateCreateRequestModel.verify(), 'manager.EmailTemplateCreateRequest validation failed!')

        let fbeBegin = this._EmailTemplateCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateCreateRequest(this._EmailTemplateCreateRequestModel, type, buffer, offset, size)
        this._EmailTemplateCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateCreateResponseModel.verify(), 'manager.EmailTemplateCreateResponse validation failed!')

        let fbeBegin = this._EmailTemplateCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateCreateResponse(this._EmailTemplateCreateResponseModel, type, buffer, offset, size)
        this._EmailTemplateCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateModifyRequestModel.verify(), 'manager.EmailTemplateModifyRequest validation failed!')

        let fbeBegin = this._EmailTemplateModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateModifyRequest(this._EmailTemplateModifyRequestModel, type, buffer, offset, size)
        this._EmailTemplateModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateModifyResponseModel.verify(), 'manager.EmailTemplateModifyResponse validation failed!')

        let fbeBegin = this._EmailTemplateModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateModifyResponse(this._EmailTemplateModifyResponseModel, type, buffer, offset, size)
        this._EmailTemplateModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateDeleteRequestModel.verify(), 'manager.EmailTemplateDeleteRequest validation failed!')

        let fbeBegin = this._EmailTemplateDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateDeleteRequest(this._EmailTemplateDeleteRequestModel, type, buffer, offset, size)
        this._EmailTemplateDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateDeleteResponseModel.verify(), 'manager.EmailTemplateDeleteResponse validation failed!')

        let fbeBegin = this._EmailTemplateDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateDeleteResponse(this._EmailTemplateDeleteResponseModel, type, buffer, offset, size)
        this._EmailTemplateDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case EmailTemplateUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._EmailTemplateUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateUpdateNotifyModel.verify(), 'manager.EmailTemplateUpdateNotify validation failed!')

        let fbeBegin = this._EmailTemplateUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_EmailTemplateUpdateNotify(this._EmailTemplateUpdateNotifyModel, type, buffer, offset, size)
        this._EmailTemplateUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateSubscribeRequestModel.verify(), 'manager.OrderStateSubscribeRequest validation failed!')

        let fbeBegin = this._OrderStateSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateSubscribeRequest(this._OrderStateSubscribeRequestModel, type, buffer, offset, size)
        this._OrderStateSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateSubscribeResponseModel.verify(), 'manager.OrderStateSubscribeResponse validation failed!')

        let fbeBegin = this._OrderStateSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateSubscribeResponse(this._OrderStateSubscribeResponseModel, type, buffer, offset, size)
        this._OrderStateSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateUpdateSubscriptionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUpdateSubscriptionRequestModel.verify(), 'manager.OrderStateUpdateSubscriptionRequest validation failed!')

        let fbeBegin = this._OrderStateUpdateSubscriptionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateUpdateSubscriptionRequest(this._OrderStateUpdateSubscriptionRequestModel, type, buffer, offset, size)
        this._OrderStateUpdateSubscriptionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateUpdateSubscriptionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateUpdateSubscriptionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUpdateSubscriptionResponseModel.verify(), 'manager.OrderStateUpdateSubscriptionResponse validation failed!')

        let fbeBegin = this._OrderStateUpdateSubscriptionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateUpdateSubscriptionResponse(this._OrderStateUpdateSubscriptionResponseModel, type, buffer, offset, size)
        this._OrderStateUpdateSubscriptionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUnsubscribeRequestModel.verify(), 'manager.OrderStateUnsubscribeRequest validation failed!')

        let fbeBegin = this._OrderStateUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateUnsubscribeRequest(this._OrderStateUnsubscribeRequestModel, type, buffer, offset, size)
        this._OrderStateUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUnsubscribeResponseModel.verify(), 'manager.OrderStateUnsubscribeResponse validation failed!')

        let fbeBegin = this._OrderStateUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateUnsubscribeResponse(this._OrderStateUnsubscribeResponseModel, type, buffer, offset, size)
        this._OrderStateUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateNotifyModel.verify(), 'manager.OrderStateNotify validation failed!')

        let fbeBegin = this._OrderStateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateNotify(this._OrderStateNotifyModel, type, buffer, offset, size)
        this._OrderStateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case RequestMonitoringSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RequestMonitoringSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringSubscribeRequestModel.verify(), 'manager.RequestMonitoringSubscribeRequest validation failed!')

        let fbeBegin = this._RequestMonitoringSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RequestMonitoringSubscribeRequest(this._RequestMonitoringSubscribeRequestModel, type, buffer, offset, size)
        this._RequestMonitoringSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case RequestMonitoringSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RequestMonitoringSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringSubscribeResponseModel.verify(), 'manager.RequestMonitoringSubscribeResponse validation failed!')

        let fbeBegin = this._RequestMonitoringSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RequestMonitoringSubscribeResponse(this._RequestMonitoringSubscribeResponseModel, type, buffer, offset, size)
        this._RequestMonitoringSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case RequestMonitoringUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RequestMonitoringUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringUnsubscribeRequestModel.verify(), 'manager.RequestMonitoringUnsubscribeRequest validation failed!')

        let fbeBegin = this._RequestMonitoringUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RequestMonitoringUnsubscribeRequest(this._RequestMonitoringUnsubscribeRequestModel, type, buffer, offset, size)
        this._RequestMonitoringUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case RequestMonitoringUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RequestMonitoringUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringUnsubscribeResponseModel.verify(), 'manager.RequestMonitoringUnsubscribeResponse validation failed!')

        let fbeBegin = this._RequestMonitoringUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RequestMonitoringUnsubscribeResponse(this._RequestMonitoringUnsubscribeResponseModel, type, buffer, offset, size)
        this._RequestMonitoringUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case RequestMonitoringRequestUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RequestMonitoringRequestUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringRequestUpdateNotifyModel.verify(), 'manager.RequestMonitoringRequestUpdateNotify validation failed!')

        let fbeBegin = this._RequestMonitoringRequestUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RequestMonitoringRequestUpdateNotify(this._RequestMonitoringRequestUpdateNotifyModel, type, buffer, offset, size)
        this._RequestMonitoringRequestUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case FilledOrdersSummaryInfoSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FilledOrdersSummaryInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoSubscribeRequestModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeRequest validation failed!')

        let fbeBegin = this._FilledOrdersSummaryInfoSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FilledOrdersSummaryInfoSubscribeRequest(this._FilledOrdersSummaryInfoSubscribeRequestModel, type, buffer, offset, size)
        this._FilledOrdersSummaryInfoSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FilledOrdersSummaryInfoSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FilledOrdersSummaryInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoSubscribeResponseModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeResponse validation failed!')

        let fbeBegin = this._FilledOrdersSummaryInfoSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FilledOrdersSummaryInfoSubscribeResponse(this._FilledOrdersSummaryInfoSubscribeResponseModel, type, buffer, offset, size)
        this._FilledOrdersSummaryInfoSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FilledOrdersSummaryInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FilledOrdersSummaryInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoNotifyModel.verify(), 'manager.FilledOrdersSummaryInfoNotify validation failed!')

        let fbeBegin = this._FilledOrdersSummaryInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FilledOrdersSummaryInfoNotify(this._FilledOrdersSummaryInfoNotifyModel, type, buffer, offset, size)
        this._FilledOrdersSummaryInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.verify(), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest validation failed!')

        let fbeBegin = this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FilledOrdersSummaryInfoUpdateSubscriptionRequest(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel, type, buffer, offset, size)
        this._FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FilledOrdersSummaryInfoUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FilledOrdersSummaryInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUnsubscribeRequestModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest validation failed!')

        let fbeBegin = this._FilledOrdersSummaryInfoUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FilledOrdersSummaryInfoUnsubscribeRequest(this._FilledOrdersSummaryInfoUnsubscribeRequestModel, type, buffer, offset, size)
        this._FilledOrdersSummaryInfoUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FilledOrdersSummaryInfoUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FilledOrdersSummaryInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUnsubscribeResponseModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse validation failed!')

        let fbeBegin = this._FilledOrdersSummaryInfoUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FilledOrdersSummaryInfoUnsubscribeResponse(this._FilledOrdersSummaryInfoUnsubscribeResponseModel, type, buffer, offset, size)
        this._FilledOrdersSummaryInfoUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsNotifyModel.verify(), 'manager.TradeEventsNotify validation failed!')

        let fbeBegin = this._TradeEventsNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsNotify(this._TradeEventsNotifyModel, type, buffer, offset, size)
        this._TradeEventsNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestModel.verify(), 'manager.TradeEventsGetRequest validation failed!')

        let fbeBegin = this._TradeEventsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsGetRequest(this._TradeEventsGetRequestModel, type, buffer, offset, size)
        this._TradeEventsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseModel.verify(), 'manager.TradeEventsGetResponse validation failed!')

        let fbeBegin = this._TradeEventsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsGetResponse(this._TradeEventsGetResponseModel, type, buffer, offset, size)
        this._TradeEventsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case JournalRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._JournalRequestModel.attachBuffer(buffer, offset)
        console.assert(this._JournalRequestModel.verify(), 'manager.JournalRequest validation failed!')

        let fbeBegin = this._JournalRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_JournalRequest(this._JournalRequestModel, type, buffer, offset, size)
        this._JournalRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case JournalResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._JournalResponseModel.attachBuffer(buffer, offset)
        console.assert(this._JournalResponseModel.verify(), 'manager.JournalResponse validation failed!')

        let fbeBegin = this._JournalResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_JournalResponse(this._JournalResponseModel, type, buffer, offset, size)
        this._JournalResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsListGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsListGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetRequestModel.verify(), 'manager.ClientReportsListGetRequest validation failed!')

        let fbeBegin = this._ClientReportsListGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsListGetRequest(this._ClientReportsListGetRequestModel, type, buffer, offset, size)
        this._ClientReportsListGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsListGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsListGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetResponseModel.verify(), 'manager.ClientReportsListGetResponse validation failed!')

        let fbeBegin = this._ClientReportsListGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsListGetResponse(this._ClientReportsListGetResponseModel, type, buffer, offset, size)
        this._ClientReportsListGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetRequestModel.verify(), 'manager.ClientReportGetRequest validation failed!')

        let fbeBegin = this._ClientReportGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportGetRequest(this._ClientReportGetRequestModel, type, buffer, offset, size)
        this._ClientReportGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetResponseModel.verify(), 'manager.ClientReportGetResponse validation failed!')

        let fbeBegin = this._ClientReportGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportGetResponse(this._ClientReportGetResponseModel, type, buffer, offset, size)
        this._ClientReportGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsSendRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsSendRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendRequestModel.verify(), 'manager.ClientReportsSendRequest validation failed!')

        let fbeBegin = this._ClientReportsSendRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsSendRequest(this._ClientReportsSendRequestModel, type, buffer, offset, size)
        this._ClientReportsSendRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsSendResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsSendResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendResponseModel.verify(), 'manager.ClientReportsSendResponse validation failed!')

        let fbeBegin = this._ClientReportsSendResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsSendResponse(this._ClientReportsSendResponseModel, type, buffer, offset, size)
        this._ClientReportsSendResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestModel.verify(), 'manager.ProfitClosedGetRequest validation failed!')

        let fbeBegin = this._ProfitClosedGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedGetRequest(this._ProfitClosedGetRequestModel, type, buffer, offset, size)
        this._ProfitClosedGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseModel.verify(), 'manager.ProfitClosedGetResponse validation failed!')

        let fbeBegin = this._ProfitClosedGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedGetResponse(this._ProfitClosedGetResponseModel, type, buffer, offset, size)
        this._ProfitClosedGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarsImportRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarsImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportRequestModel.verify(), 'manager.ChartBarsImportRequest validation failed!')

        let fbeBegin = this._ChartBarsImportRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarsImportRequest(this._ChartBarsImportRequestModel, type, buffer, offset, size)
        this._ChartBarsImportRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarsImportResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarsImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportResponseModel.verify(), 'manager.ChartBarsImportResponse validation failed!')

        let fbeBegin = this._ChartBarsImportResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarsImportResponse(this._ChartBarsImportResponseModel, type, buffer, offset, size)
        this._ChartBarsImportResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarsImportNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarsImportNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportNotifyModel.verify(), 'manager.ChartBarsImportNotify validation failed!')

        let fbeBegin = this._ChartBarsImportNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarsImportNotify(this._ChartBarsImportNotifyModel, type, buffer, offset, size)
        this._ChartBarsImportNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case JournalBusinessRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._JournalBusinessRequestModel.attachBuffer(buffer, offset)
        console.assert(this._JournalBusinessRequestModel.verify(), 'manager.JournalBusinessRequest validation failed!')

        let fbeBegin = this._JournalBusinessRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_JournalBusinessRequest(this._JournalBusinessRequestModel, type, buffer, offset, size)
        this._JournalBusinessRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case JournalBusinessResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._JournalBusinessResponseModel.attachBuffer(buffer, offset)
        console.assert(this._JournalBusinessResponseModel.verify(), 'manager.JournalBusinessResponse validation failed!')

        let fbeBegin = this._JournalBusinessResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_JournalBusinessResponse(this._JournalBusinessResponseModel, type, buffer, offset, size)
        this._JournalBusinessResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountsGetPageRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetPageRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetPageRequestModel.verify(), 'manager.AccountsGetPageRequest validation failed!')

        let fbeBegin = this._AccountsGetPageRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetPageRequest(this._AccountsGetPageRequestModel, type, buffer, offset, size)
        this._AccountsGetPageRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountsGetPageResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetPageResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetPageResponseModel.verify(), 'manager.AccountsGetPageResponse validation failed!')

        let fbeBegin = this._AccountsGetPageResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetPageResponse(this._AccountsGetPageResponseModel, type, buffer, offset, size)
        this._AccountsGetPageResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HeartbeatNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HeartbeatNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._HeartbeatNotifyModel.verify(), 'manager.HeartbeatNotify validation failed!')

        let fbeBegin = this._HeartbeatNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HeartbeatNotify(this._HeartbeatNotifyModel, type, buffer, offset, size)
        this._HeartbeatNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersClosedSummariesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersClosedSummariesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetRequestModel.verify(), 'manager.OrdersClosedSummariesGetRequest validation failed!')

        let fbeBegin = this._OrdersClosedSummariesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersClosedSummariesGetRequest(this._OrdersClosedSummariesGetRequestModel, type, buffer, offset, size)
        this._OrdersClosedSummariesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersClosedSummariesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersClosedSummariesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetResponseModel.verify(), 'manager.OrdersClosedSummariesGetResponse validation failed!')

        let fbeBegin = this._OrdersClosedSummariesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersClosedSummariesGetResponse(this._OrdersClosedSummariesGetResponseModel, type, buffer, offset, size)
        this._OrdersClosedSummariesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetPageRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetPageRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetPageRequestModel.verify(), 'manager.OrdersGetPageRequest validation failed!')

        let fbeBegin = this._OrdersGetPageRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetPageRequest(this._OrdersGetPageRequestModel, type, buffer, offset, size)
        this._OrdersGetPageRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetPageResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetPageResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetPageResponseModel.verify(), 'manager.OrdersGetPageResponse validation failed!')

        let fbeBegin = this._OrdersGetPageResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetPageResponse(this._OrdersGetPageResponseModel, type, buffer, offset, size)
        this._OrdersGetPageResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case StorageUniversalNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StorageUniversalNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StorageUniversalNotifyModel.verify(), 'manager.StorageUniversalNotify validation failed!')

        let fbeBegin = this._StorageUniversalNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StorageUniversalNotify(this._StorageUniversalNotifyModel, type, buffer, offset, size)
        this._StorageUniversalNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientLoginInfoGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientLoginInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetRequestModel.verify(), 'manager.ClientLoginInfoGetRequest validation failed!')

        let fbeBegin = this._ClientLoginInfoGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientLoginInfoGetRequest(this._ClientLoginInfoGetRequestModel, type, buffer, offset, size)
        this._ClientLoginInfoGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientLoginInfoGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientLoginInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetResponseModel.verify(), 'manager.ClientLoginInfoGetResponse validation failed!')

        let fbeBegin = this._ClientLoginInfoGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientLoginInfoGetResponse(this._ClientLoginInfoGetResponseModel, type, buffer, offset, size)
        this._ClientLoginInfoGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupExistsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupExistsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupExistsGetRequestModel.verify(), 'manager.AccountGroupExistsGetRequest validation failed!')

        let fbeBegin = this._AccountGroupExistsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupExistsGetRequest(this._AccountGroupExistsGetRequestModel, type, buffer, offset, size)
        this._AccountGroupExistsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountGroupExistsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountGroupExistsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupExistsGetResponseModel.verify(), 'manager.AccountGroupExistsGetResponse validation failed!')

        let fbeBegin = this._AccountGroupExistsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountGroupExistsGetResponse(this._AccountGroupExistsGetResponseModel, type, buffer, offset, size)
        this._AccountGroupExistsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithPasswordRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithPasswordRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordRequestModel.verify(), 'manager.LoginWithPasswordRequest validation failed!')

        let fbeBegin = this._LoginWithPasswordRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithPasswordRequest(this._LoginWithPasswordRequestModel, type, buffer, offset, size)
        this._LoginWithPasswordRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithPasswordResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithPasswordResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordResponseModel.verify(), 'manager.LoginWithPasswordResponse validation failed!')

        let fbeBegin = this._LoginWithPasswordResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithPasswordResponse(this._LoginWithPasswordResponseModel, type, buffer, offset, size)
        this._LoginWithPasswordResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithTokenRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenRequestModel.verify(), 'manager.LoginWithTokenRequest validation failed!')

        let fbeBegin = this._LoginWithTokenRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithTokenRequest(this._LoginWithTokenRequestModel, type, buffer, offset, size)
        this._LoginWithTokenRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithTokenResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenResponseModel.verify(), 'manager.LoginWithTokenResponse validation failed!')

        let fbeBegin = this._LoginWithTokenResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithTokenResponse(this._LoginWithTokenResponseModel, type, buffer, offset, size)
        this._LoginWithTokenResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case RefreshTokenRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RefreshTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenRequestModel.verify(), 'manager.RefreshTokenRequest validation failed!')

        let fbeBegin = this._RefreshTokenRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RefreshTokenRequest(this._RefreshTokenRequestModel, type, buffer, offset, size)
        this._RefreshTokenRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case RefreshTokenResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RefreshTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenResponseModel.verify(), 'manager.RefreshTokenResponse validation failed!')

        let fbeBegin = this._RefreshTokenResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RefreshTokenResponse(this._RefreshTokenResponseModel, type, buffer, offset, size)
        this._RefreshTokenResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonGetRequestModel.verify(), 'manager.PersonGetRequest validation failed!')

        let fbeBegin = this._PersonGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonGetRequest(this._PersonGetRequestModel, type, buffer, offset, size)
        this._PersonGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonGetResponseModel.verify(), 'manager.PersonGetResponse validation failed!')

        let fbeBegin = this._PersonGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonGetResponse(this._PersonGetResponseModel, type, buffer, offset, size)
        this._PersonGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestModel.verify(), 'manager.PersonsGetAllRequest validation failed!')

        let fbeBegin = this._PersonsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsGetAllRequest(this._PersonsGetAllRequestModel, type, buffer, offset, size)
        this._PersonsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseModel.verify(), 'manager.PersonsGetAllResponse validation failed!')

        let fbeBegin = this._PersonsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsGetAllResponse(this._PersonsGetAllResponseModel, type, buffer, offset, size)
        this._PersonsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonCreateRequestModel.verify(), 'manager.PersonCreateRequest validation failed!')

        let fbeBegin = this._PersonCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonCreateRequest(this._PersonCreateRequestModel, type, buffer, offset, size)
        this._PersonCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonCreateResponseModel.verify(), 'manager.PersonCreateResponse validation failed!')

        let fbeBegin = this._PersonCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonCreateResponse(this._PersonCreateResponseModel, type, buffer, offset, size)
        this._PersonCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonModifyRequestModel.verify(), 'manager.PersonModifyRequest validation failed!')

        let fbeBegin = this._PersonModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonModifyRequest(this._PersonModifyRequestModel, type, buffer, offset, size)
        this._PersonModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonModifyResponseModel.verify(), 'manager.PersonModifyResponse validation failed!')

        let fbeBegin = this._PersonModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonModifyResponse(this._PersonModifyResponseModel, type, buffer, offset, size)
        this._PersonModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateNotifyModel.verify(), 'manager.PersonUpdateNotify validation failed!')

        let fbeBegin = this._PersonUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonUpdateNotify(this._PersonUpdateNotifyModel, type, buffer, offset, size)
        this._PersonUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetRequestModel.verify(), 'manager.SecuritySettingsGetRequest validation failed!')

        let fbeBegin = this._SecuritySettingsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsGetRequest(this._SecuritySettingsGetRequestModel, type, buffer, offset, size)
        this._SecuritySettingsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetResponseModel.verify(), 'manager.SecuritySettingsGetResponse validation failed!')

        let fbeBegin = this._SecuritySettingsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsGetResponse(this._SecuritySettingsGetResponseModel, type, buffer, offset, size)
        this._SecuritySettingsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetAllRequestModel.verify(), 'manager.SecuritySettingsGetAllRequest validation failed!')

        let fbeBegin = this._SecuritySettingsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsGetAllRequest(this._SecuritySettingsGetAllRequestModel, type, buffer, offset, size)
        this._SecuritySettingsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetAllResponseModel.verify(), 'manager.SecuritySettingsGetAllResponse validation failed!')

        let fbeBegin = this._SecuritySettingsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsGetAllResponse(this._SecuritySettingsGetAllResponseModel, type, buffer, offset, size)
        this._SecuritySettingsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsCreateRequestModel.verify(), 'manager.SecuritySettingsCreateRequest validation failed!')

        let fbeBegin = this._SecuritySettingsCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsCreateRequest(this._SecuritySettingsCreateRequestModel, type, buffer, offset, size)
        this._SecuritySettingsCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsCreateResponseModel.verify(), 'manager.SecuritySettingsCreateResponse validation failed!')

        let fbeBegin = this._SecuritySettingsCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsCreateResponse(this._SecuritySettingsCreateResponseModel, type, buffer, offset, size)
        this._SecuritySettingsCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsModifyRequestModel.verify(), 'manager.SecuritySettingsModifyRequest validation failed!')

        let fbeBegin = this._SecuritySettingsModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsModifyRequest(this._SecuritySettingsModifyRequestModel, type, buffer, offset, size)
        this._SecuritySettingsModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsModifyResponseModel.verify(), 'manager.SecuritySettingsModifyResponse validation failed!')

        let fbeBegin = this._SecuritySettingsModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsModifyResponse(this._SecuritySettingsModifyResponseModel, type, buffer, offset, size)
        this._SecuritySettingsModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsDeleteRequestModel.verify(), 'manager.SecuritySettingsDeleteRequest validation failed!')

        let fbeBegin = this._SecuritySettingsDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsDeleteRequest(this._SecuritySettingsDeleteRequestModel, type, buffer, offset, size)
        this._SecuritySettingsDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsDeleteResponseModel.verify(), 'manager.SecuritySettingsDeleteResponse validation failed!')

        let fbeBegin = this._SecuritySettingsDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsDeleteResponse(this._SecuritySettingsDeleteResponseModel, type, buffer, offset, size)
        this._SecuritySettingsDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SecuritySettingsUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SecuritySettingsUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsUpdateNotifyModel.verify(), 'manager.SecuritySettingsUpdateNotify validation failed!')

        let fbeBegin = this._SecuritySettingsUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SecuritySettingsUpdateNotify(this._SecuritySettingsUpdateNotifyModel, type, buffer, offset, size)
        this._SecuritySettingsUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleGetRequestModel.verify(), 'manager.FirewallRuleGetRequest validation failed!')

        let fbeBegin = this._FirewallRuleGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleGetRequest(this._FirewallRuleGetRequestModel, type, buffer, offset, size)
        this._FirewallRuleGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleGetResponseModel.verify(), 'manager.FirewallRuleGetResponse validation failed!')

        let fbeBegin = this._FirewallRuleGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleGetResponse(this._FirewallRuleGetResponseModel, type, buffer, offset, size)
        this._FirewallRuleGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRulesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRulesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRulesGetAllRequestModel.verify(), 'manager.FirewallRulesGetAllRequest validation failed!')

        let fbeBegin = this._FirewallRulesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRulesGetAllRequest(this._FirewallRulesGetAllRequestModel, type, buffer, offset, size)
        this._FirewallRulesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRulesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRulesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRulesGetAllResponseModel.verify(), 'manager.FirewallRulesGetAllResponse validation failed!')

        let fbeBegin = this._FirewallRulesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRulesGetAllResponse(this._FirewallRulesGetAllResponseModel, type, buffer, offset, size)
        this._FirewallRulesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleCreateRequestModel.verify(), 'manager.FirewallRuleCreateRequest validation failed!')

        let fbeBegin = this._FirewallRuleCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleCreateRequest(this._FirewallRuleCreateRequestModel, type, buffer, offset, size)
        this._FirewallRuleCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleCreateResponseModel.verify(), 'manager.FirewallRuleCreateResponse validation failed!')

        let fbeBegin = this._FirewallRuleCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleCreateResponse(this._FirewallRuleCreateResponseModel, type, buffer, offset, size)
        this._FirewallRuleCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleModifyRequestModel.verify(), 'manager.FirewallRuleModifyRequest validation failed!')

        let fbeBegin = this._FirewallRuleModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleModifyRequest(this._FirewallRuleModifyRequestModel, type, buffer, offset, size)
        this._FirewallRuleModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleModifyResponseModel.verify(), 'manager.FirewallRuleModifyResponse validation failed!')

        let fbeBegin = this._FirewallRuleModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleModifyResponse(this._FirewallRuleModifyResponseModel, type, buffer, offset, size)
        this._FirewallRuleModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleDeleteRequestModel.verify(), 'manager.FirewallRuleDeleteRequest validation failed!')

        let fbeBegin = this._FirewallRuleDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleDeleteRequest(this._FirewallRuleDeleteRequestModel, type, buffer, offset, size)
        this._FirewallRuleDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleDeleteResponseModel.verify(), 'manager.FirewallRuleDeleteResponse validation failed!')

        let fbeBegin = this._FirewallRuleDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleDeleteResponse(this._FirewallRuleDeleteResponseModel, type, buffer, offset, size)
        this._FirewallRuleDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FirewallRuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FirewallRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleUpdateNotifyModel.verify(), 'manager.FirewallRuleUpdateNotify validation failed!')

        let fbeBegin = this._FirewallRuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FirewallRuleUpdateNotify(this._FirewallRuleUpdateNotifyModel, type, buffer, offset, size)
        this._FirewallRuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.configProxy != null) && this.configProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.historyProxy != null) && this.historyProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.clientProxy != null) && this.clientProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.feedProxy != null) && this.feedProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.storageProxy != null) && this.storageProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.exposedProxy != null) && this.exposedProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.executionProxy != null) && this.executionProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.backupProxy != null) && this.backupProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerProxy != null) && this.innerProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.tradeProxy != null) && this.tradeProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding manager client
 */
class Client extends fbe.Client {
  /**
   * Initialize manager client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._configClient = new config.Client(this.sendBuffer, this.receiveBuffer)
    this._historyClient = new history.Client(this.sendBuffer, this.receiveBuffer)
    this._clientClient = new client.Client(this.sendBuffer, this.receiveBuffer)
    this._feedClient = new feed.Client(this.sendBuffer, this.receiveBuffer)
    this._storageClient = new storage.Client(this.sendBuffer, this.receiveBuffer)
    this._exposedClient = new exposed.Client(this.sendBuffer, this.receiveBuffer)
    this._executionClient = new execution.Client(this.sendBuffer, this.receiveBuffer)
    this._backupClient = new backup.Client(this.sendBuffer, this.receiveBuffer)
    this._innerClient = new inner.Client(this.sendBuffer, this.receiveBuffer)
    this._tradeClient = new trade.Client(this.sendBuffer, this.receiveBuffer)
    this._PingModuleRequestSenderModel = new PingModuleRequestModel(this.sendBuffer)
    this._PingModuleRequestReceiverValue = new PingModuleRequest()
    this._PingModuleRequestReceiverModel = new PingModuleRequestModel()
    this._PongModuleResponseSenderModel = new PongModuleResponseModel(this.sendBuffer)
    this._PongModuleResponseReceiverValue = new PongModuleResponse()
    this._PongModuleResponseReceiverModel = new PongModuleResponseModel()
    this._PingModuleRejectSenderModel = new PingModuleRejectModel(this.sendBuffer)
    this._PingModuleRejectReceiverValue = new PingModuleReject()
    this._PingModuleRejectReceiverModel = new PingModuleRejectModel()
    this._Deprecated3SenderModel = new Deprecated3Model(this.sendBuffer)
    this._Deprecated3ReceiverValue = new Deprecated3()
    this._Deprecated3ReceiverModel = new Deprecated3Model()
    this._Deprecated4SenderModel = new Deprecated4Model(this.sendBuffer)
    this._Deprecated4ReceiverValue = new Deprecated4()
    this._Deprecated4ReceiverModel = new Deprecated4Model()
    this._Deprecated1SenderModel = new Deprecated1Model(this.sendBuffer)
    this._Deprecated1ReceiverValue = new Deprecated1()
    this._Deprecated1ReceiverModel = new Deprecated1Model()
    this._Deprecated2SenderModel = new Deprecated2Model(this.sendBuffer)
    this._Deprecated2ReceiverValue = new Deprecated2()
    this._Deprecated2ReceiverModel = new Deprecated2Model()
    this._ManagerSessionInfoRequestSenderModel = new ManagerSessionInfoRequestModel(this.sendBuffer)
    this._ManagerSessionInfoRequestReceiverValue = new ManagerSessionInfoRequest()
    this._ManagerSessionInfoRequestReceiverModel = new ManagerSessionInfoRequestModel()
    this._ManagerSessionInfoResponseSenderModel = new ManagerSessionInfoResponseModel(this.sendBuffer)
    this._ManagerSessionInfoResponseReceiverValue = new ManagerSessionInfoResponse()
    this._ManagerSessionInfoResponseReceiverModel = new ManagerSessionInfoResponseModel()
    this._OnlineSessionsRequestSenderModel = new OnlineSessionsRequestModel(this.sendBuffer)
    this._OnlineSessionsRequestReceiverValue = new OnlineSessionsRequest()
    this._OnlineSessionsRequestReceiverModel = new OnlineSessionsRequestModel()
    this._OnlineSessionsResponseSenderModel = new OnlineSessionsResponseModel(this.sendBuffer)
    this._OnlineSessionsResponseReceiverValue = new OnlineSessionsResponse()
    this._OnlineSessionsResponseReceiverModel = new OnlineSessionsResponseModel()
    this._DropSessionRequestSenderModel = new DropSessionRequestModel(this.sendBuffer)
    this._DropSessionRequestReceiverValue = new DropSessionRequest()
    this._DropSessionRequestReceiverModel = new DropSessionRequestModel()
    this._DropSessionResponseSenderModel = new DropSessionResponseModel(this.sendBuffer)
    this._DropSessionResponseReceiverValue = new DropSessionResponse()
    this._DropSessionResponseReceiverModel = new DropSessionResponseModel()
    this._ConfigRequestSenderModel = new ConfigRequestModel(this.sendBuffer)
    this._ConfigRequestReceiverValue = new ConfigRequest()
    this._ConfigRequestReceiverModel = new ConfigRequestModel()
    this._ConfigResponseSenderModel = new ConfigResponseModel(this.sendBuffer)
    this._ConfigResponseReceiverValue = new ConfigResponse()
    this._ConfigResponseReceiverModel = new ConfigResponseModel()
    this._ConfigRejectSenderModel = new ConfigRejectModel(this.sendBuffer)
    this._ConfigRejectReceiverValue = new ConfigReject()
    this._ConfigRejectReceiverModel = new ConfigRejectModel()
    this._ConfigUpdateRequestSenderModel = new ConfigUpdateRequestModel(this.sendBuffer)
    this._ConfigUpdateRequestReceiverValue = new ConfigUpdateRequest()
    this._ConfigUpdateRequestReceiverModel = new ConfigUpdateRequestModel()
    this._ConfigUpdateResponseSenderModel = new ConfigUpdateResponseModel(this.sendBuffer)
    this._ConfigUpdateResponseReceiverValue = new ConfigUpdateResponse()
    this._ConfigUpdateResponseReceiverModel = new ConfigUpdateResponseModel()
    this._ConfigUpdateNotifySenderModel = new ConfigUpdateNotifyModel(this.sendBuffer)
    this._ConfigUpdateNotifyReceiverValue = new ConfigUpdateNotify()
    this._ConfigUpdateNotifyReceiverModel = new ConfigUpdateNotifyModel()
    this._ConfigVersionRequestSenderModel = new ConfigVersionRequestModel(this.sendBuffer)
    this._ConfigVersionRequestReceiverValue = new ConfigVersionRequest()
    this._ConfigVersionRequestReceiverModel = new ConfigVersionRequestModel()
    this._ConfigVersionResponseSenderModel = new ConfigVersionResponseModel(this.sendBuffer)
    this._ConfigVersionResponseReceiverValue = new ConfigVersionResponse()
    this._ConfigVersionResponseReceiverModel = new ConfigVersionResponseModel()
    this._ConfigExportRequestSenderModel = new ConfigExportRequestModel(this.sendBuffer)
    this._ConfigExportRequestReceiverValue = new ConfigExportRequest()
    this._ConfigExportRequestReceiverModel = new ConfigExportRequestModel()
    this._ConfigExportResponseSenderModel = new ConfigExportResponseModel(this.sendBuffer)
    this._ConfigExportResponseReceiverValue = new ConfigExportResponse()
    this._ConfigExportResponseReceiverModel = new ConfigExportResponseModel()
    this._ConfigImportRequestSenderModel = new ConfigImportRequestModel(this.sendBuffer)
    this._ConfigImportRequestReceiverValue = new ConfigImportRequest()
    this._ConfigImportRequestReceiverModel = new ConfigImportRequestModel()
    this._ConfigImportResponseSenderModel = new ConfigImportResponseModel(this.sendBuffer)
    this._ConfigImportResponseReceiverValue = new ConfigImportResponse()
    this._ConfigImportResponseReceiverModel = new ConfigImportResponseModel()
    this._ConfigValidateRequestSenderModel = new ConfigValidateRequestModel(this.sendBuffer)
    this._ConfigValidateRequestReceiverValue = new ConfigValidateRequest()
    this._ConfigValidateRequestReceiverModel = new ConfigValidateRequestModel()
    this._ConfigValidateResponseSenderModel = new ConfigValidateResponseModel(this.sendBuffer)
    this._ConfigValidateResponseReceiverValue = new ConfigValidateResponse()
    this._ConfigValidateResponseReceiverModel = new ConfigValidateResponseModel()
    this._ConfigRefreshRequestSenderModel = new ConfigRefreshRequestModel(this.sendBuffer)
    this._ConfigRefreshRequestReceiverValue = new ConfigRefreshRequest()
    this._ConfigRefreshRequestReceiverModel = new ConfigRefreshRequestModel()
    this._ConfigRefreshResponseSenderModel = new ConfigRefreshResponseModel(this.sendBuffer)
    this._ConfigRefreshResponseReceiverValue = new ConfigRefreshResponse()
    this._ConfigRefreshResponseReceiverModel = new ConfigRefreshResponseModel()
    this._ConfigHistoryRequestSenderModel = new ConfigHistoryRequestModel(this.sendBuffer)
    this._ConfigHistoryRequestReceiverValue = new ConfigHistoryRequest()
    this._ConfigHistoryRequestReceiverModel = new ConfigHistoryRequestModel()
    this._ConfigHistoryResponseSenderModel = new ConfigHistoryResponseModel(this.sendBuffer)
    this._ConfigHistoryResponseReceiverValue = new ConfigHistoryResponse()
    this._ConfigHistoryResponseReceiverModel = new ConfigHistoryResponseModel()
    this._ConfigRollbackRequestSenderModel = new ConfigRollbackRequestModel(this.sendBuffer)
    this._ConfigRollbackRequestReceiverValue = new ConfigRollbackRequest()
    this._ConfigRollbackRequestReceiverModel = new ConfigRollbackRequestModel()
    this._ConfigRollbackResponseSenderModel = new ConfigRollbackResponseModel(this.sendBuffer)
    this._ConfigRollbackResponseReceiverValue = new ConfigRollbackResponse()
    this._ConfigRollbackResponseReceiverModel = new ConfigRollbackResponseModel()
    this._HostGetRequestSenderModel = new HostGetRequestModel(this.sendBuffer)
    this._HostGetRequestReceiverValue = new HostGetRequest()
    this._HostGetRequestReceiverModel = new HostGetRequestModel()
    this._HostGetResponseSenderModel = new HostGetResponseModel(this.sendBuffer)
    this._HostGetResponseReceiverValue = new HostGetResponse()
    this._HostGetResponseReceiverModel = new HostGetResponseModel()
    this._HostsGetAllRequestSenderModel = new HostsGetAllRequestModel(this.sendBuffer)
    this._HostsGetAllRequestReceiverValue = new HostsGetAllRequest()
    this._HostsGetAllRequestReceiverModel = new HostsGetAllRequestModel()
    this._HostsGetAllResponseSenderModel = new HostsGetAllResponseModel(this.sendBuffer)
    this._HostsGetAllResponseReceiverValue = new HostsGetAllResponse()
    this._HostsGetAllResponseReceiverModel = new HostsGetAllResponseModel()
    this._HostCreateRequestSenderModel = new HostCreateRequestModel(this.sendBuffer)
    this._HostCreateRequestReceiverValue = new HostCreateRequest()
    this._HostCreateRequestReceiverModel = new HostCreateRequestModel()
    this._HostCreateResponseSenderModel = new HostCreateResponseModel(this.sendBuffer)
    this._HostCreateResponseReceiverValue = new HostCreateResponse()
    this._HostCreateResponseReceiverModel = new HostCreateResponseModel()
    this._HostModifyRequestSenderModel = new HostModifyRequestModel(this.sendBuffer)
    this._HostModifyRequestReceiverValue = new HostModifyRequest()
    this._HostModifyRequestReceiverModel = new HostModifyRequestModel()
    this._HostModifyResponseSenderModel = new HostModifyResponseModel(this.sendBuffer)
    this._HostModifyResponseReceiverValue = new HostModifyResponse()
    this._HostModifyResponseReceiverModel = new HostModifyResponseModel()
    this._HostDeleteRequestSenderModel = new HostDeleteRequestModel(this.sendBuffer)
    this._HostDeleteRequestReceiverValue = new HostDeleteRequest()
    this._HostDeleteRequestReceiverModel = new HostDeleteRequestModel()
    this._HostDeleteResponseSenderModel = new HostDeleteResponseModel(this.sendBuffer)
    this._HostDeleteResponseReceiverValue = new HostDeleteResponse()
    this._HostDeleteResponseReceiverModel = new HostDeleteResponseModel()
    this._HostUpdateNotifySenderModel = new HostUpdateNotifyModel(this.sendBuffer)
    this._HostUpdateNotifyReceiverValue = new HostUpdateNotify()
    this._HostUpdateNotifyReceiverModel = new HostUpdateNotifyModel()
    this._ModuleGetRequestSenderModel = new ModuleGetRequestModel(this.sendBuffer)
    this._ModuleGetRequestReceiverValue = new ModuleGetRequest()
    this._ModuleGetRequestReceiverModel = new ModuleGetRequestModel()
    this._ModuleGetResponseSenderModel = new ModuleGetResponseModel(this.sendBuffer)
    this._ModuleGetResponseReceiverValue = new ModuleGetResponse()
    this._ModuleGetResponseReceiverModel = new ModuleGetResponseModel()
    this._ModulesGetAllRequestSenderModel = new ModulesGetAllRequestModel(this.sendBuffer)
    this._ModulesGetAllRequestReceiverValue = new ModulesGetAllRequest()
    this._ModulesGetAllRequestReceiverModel = new ModulesGetAllRequestModel()
    this._ModulesGetAllResponseSenderModel = new ModulesGetAllResponseModel(this.sendBuffer)
    this._ModulesGetAllResponseReceiverValue = new ModulesGetAllResponse()
    this._ModulesGetAllResponseReceiverModel = new ModulesGetAllResponseModel()
    this._ModuleCreateRequestSenderModel = new ModuleCreateRequestModel(this.sendBuffer)
    this._ModuleCreateRequestReceiverValue = new ModuleCreateRequest()
    this._ModuleCreateRequestReceiverModel = new ModuleCreateRequestModel()
    this._ModuleCreateResponseSenderModel = new ModuleCreateResponseModel(this.sendBuffer)
    this._ModuleCreateResponseReceiverValue = new ModuleCreateResponse()
    this._ModuleCreateResponseReceiverModel = new ModuleCreateResponseModel()
    this._ModuleModifyRequestSenderModel = new ModuleModifyRequestModel(this.sendBuffer)
    this._ModuleModifyRequestReceiverValue = new ModuleModifyRequest()
    this._ModuleModifyRequestReceiverModel = new ModuleModifyRequestModel()
    this._ModuleModifyResponseSenderModel = new ModuleModifyResponseModel(this.sendBuffer)
    this._ModuleModifyResponseReceiverValue = new ModuleModifyResponse()
    this._ModuleModifyResponseReceiverModel = new ModuleModifyResponseModel()
    this._ModuleDeleteRequestSenderModel = new ModuleDeleteRequestModel(this.sendBuffer)
    this._ModuleDeleteRequestReceiverValue = new ModuleDeleteRequest()
    this._ModuleDeleteRequestReceiverModel = new ModuleDeleteRequestModel()
    this._ModuleDeleteResponseSenderModel = new ModuleDeleteResponseModel(this.sendBuffer)
    this._ModuleDeleteResponseReceiverValue = new ModuleDeleteResponse()
    this._ModuleDeleteResponseReceiverModel = new ModuleDeleteResponseModel()
    this._ModuleUpdateNotifySenderModel = new ModuleUpdateNotifyModel(this.sendBuffer)
    this._ModuleUpdateNotifyReceiverValue = new ModuleUpdateNotify()
    this._ModuleUpdateNotifyReceiverModel = new ModuleUpdateNotifyModel()
    this._ServerTimeGetRequestSenderModel = new ServerTimeGetRequestModel(this.sendBuffer)
    this._ServerTimeGetRequestReceiverValue = new ServerTimeGetRequest()
    this._ServerTimeGetRequestReceiverModel = new ServerTimeGetRequestModel()
    this._ServerTimeGetResponseSenderModel = new ServerTimeGetResponseModel(this.sendBuffer)
    this._ServerTimeGetResponseReceiverValue = new ServerTimeGetResponse()
    this._ServerTimeGetResponseReceiverModel = new ServerTimeGetResponseModel()
    this._ManagerPlatformTimezoneGetRequestSenderModel = new ManagerPlatformTimezoneGetRequestModel(this.sendBuffer)
    this._ManagerPlatformTimezoneGetRequestReceiverValue = new ManagerPlatformTimezoneGetRequest()
    this._ManagerPlatformTimezoneGetRequestReceiverModel = new ManagerPlatformTimezoneGetRequestModel()
    this._ManagerPlatformTimezoneGetResponseSenderModel = new ManagerPlatformTimezoneGetResponseModel(this.sendBuffer)
    this._ManagerPlatformTimezoneGetResponseReceiverValue = new ManagerPlatformTimezoneGetResponse()
    this._ManagerPlatformTimezoneGetResponseReceiverModel = new ManagerPlatformTimezoneGetResponseModel()
    this._TimezoneGetRequestSenderModel = new TimezoneGetRequestModel(this.sendBuffer)
    this._TimezoneGetRequestReceiverValue = new TimezoneGetRequest()
    this._TimezoneGetRequestReceiverModel = new TimezoneGetRequestModel()
    this._TimezoneGetResponseSenderModel = new TimezoneGetResponseModel(this.sendBuffer)
    this._TimezoneGetResponseReceiverValue = new TimezoneGetResponse()
    this._TimezoneGetResponseReceiverModel = new TimezoneGetResponseModel()
    this._TimezonesGetAllRequestSenderModel = new TimezonesGetAllRequestModel(this.sendBuffer)
    this._TimezonesGetAllRequestReceiverValue = new TimezonesGetAllRequest()
    this._TimezonesGetAllRequestReceiverModel = new TimezonesGetAllRequestModel()
    this._TimezonesGetAllResponseSenderModel = new TimezonesGetAllResponseModel(this.sendBuffer)
    this._TimezonesGetAllResponseReceiverValue = new TimezonesGetAllResponse()
    this._TimezonesGetAllResponseReceiverModel = new TimezonesGetAllResponseModel()
    this._TimezoneCreateRequestSenderModel = new TimezoneCreateRequestModel(this.sendBuffer)
    this._TimezoneCreateRequestReceiverValue = new TimezoneCreateRequest()
    this._TimezoneCreateRequestReceiverModel = new TimezoneCreateRequestModel()
    this._TimezoneCreateResponseSenderModel = new TimezoneCreateResponseModel(this.sendBuffer)
    this._TimezoneCreateResponseReceiverValue = new TimezoneCreateResponse()
    this._TimezoneCreateResponseReceiverModel = new TimezoneCreateResponseModel()
    this._TimezoneModifyRequestSenderModel = new TimezoneModifyRequestModel(this.sendBuffer)
    this._TimezoneModifyRequestReceiverValue = new TimezoneModifyRequest()
    this._TimezoneModifyRequestReceiverModel = new TimezoneModifyRequestModel()
    this._TimezoneModifyResponseSenderModel = new TimezoneModifyResponseModel(this.sendBuffer)
    this._TimezoneModifyResponseReceiverValue = new TimezoneModifyResponse()
    this._TimezoneModifyResponseReceiverModel = new TimezoneModifyResponseModel()
    this._TimezoneDeleteRequestSenderModel = new TimezoneDeleteRequestModel(this.sendBuffer)
    this._TimezoneDeleteRequestReceiverValue = new TimezoneDeleteRequest()
    this._TimezoneDeleteRequestReceiverModel = new TimezoneDeleteRequestModel()
    this._TimezoneDeleteResponseSenderModel = new TimezoneDeleteResponseModel(this.sendBuffer)
    this._TimezoneDeleteResponseReceiverValue = new TimezoneDeleteResponse()
    this._TimezoneDeleteResponseReceiverModel = new TimezoneDeleteResponseModel()
    this._TimezoneUpdateNotifySenderModel = new TimezoneUpdateNotifyModel(this.sendBuffer)
    this._TimezoneUpdateNotifyReceiverValue = new TimezoneUpdateNotify()
    this._TimezoneUpdateNotifyReceiverModel = new TimezoneUpdateNotifyModel()
    this._CurrencyGroupGetRequestSenderModel = new CurrencyGroupGetRequestModel(this.sendBuffer)
    this._CurrencyGroupGetRequestReceiverValue = new CurrencyGroupGetRequest()
    this._CurrencyGroupGetRequestReceiverModel = new CurrencyGroupGetRequestModel()
    this._CurrencyGroupGetResponseSenderModel = new CurrencyGroupGetResponseModel(this.sendBuffer)
    this._CurrencyGroupGetResponseReceiverValue = new CurrencyGroupGetResponse()
    this._CurrencyGroupGetResponseReceiverModel = new CurrencyGroupGetResponseModel()
    this._CurrencyGroupsGetAllRequestSenderModel = new CurrencyGroupsGetAllRequestModel(this.sendBuffer)
    this._CurrencyGroupsGetAllRequestReceiverValue = new CurrencyGroupsGetAllRequest()
    this._CurrencyGroupsGetAllRequestReceiverModel = new CurrencyGroupsGetAllRequestModel()
    this._CurrencyGroupsGetAllResponseSenderModel = new CurrencyGroupsGetAllResponseModel(this.sendBuffer)
    this._CurrencyGroupsGetAllResponseReceiverValue = new CurrencyGroupsGetAllResponse()
    this._CurrencyGroupsGetAllResponseReceiverModel = new CurrencyGroupsGetAllResponseModel()
    this._CurrencyGroupCreateRequestSenderModel = new CurrencyGroupCreateRequestModel(this.sendBuffer)
    this._CurrencyGroupCreateRequestReceiverValue = new CurrencyGroupCreateRequest()
    this._CurrencyGroupCreateRequestReceiverModel = new CurrencyGroupCreateRequestModel()
    this._CurrencyGroupCreateResponseSenderModel = new CurrencyGroupCreateResponseModel(this.sendBuffer)
    this._CurrencyGroupCreateResponseReceiverValue = new CurrencyGroupCreateResponse()
    this._CurrencyGroupCreateResponseReceiverModel = new CurrencyGroupCreateResponseModel()
    this._CurrencyGroupModifyRequestSenderModel = new CurrencyGroupModifyRequestModel(this.sendBuffer)
    this._CurrencyGroupModifyRequestReceiverValue = new CurrencyGroupModifyRequest()
    this._CurrencyGroupModifyRequestReceiverModel = new CurrencyGroupModifyRequestModel()
    this._CurrencyGroupModifyResponseSenderModel = new CurrencyGroupModifyResponseModel(this.sendBuffer)
    this._CurrencyGroupModifyResponseReceiverValue = new CurrencyGroupModifyResponse()
    this._CurrencyGroupModifyResponseReceiverModel = new CurrencyGroupModifyResponseModel()
    this._CurrencyGroupDeleteRequestSenderModel = new CurrencyGroupDeleteRequestModel(this.sendBuffer)
    this._CurrencyGroupDeleteRequestReceiverValue = new CurrencyGroupDeleteRequest()
    this._CurrencyGroupDeleteRequestReceiverModel = new CurrencyGroupDeleteRequestModel()
    this._CurrencyGroupDeleteResponseSenderModel = new CurrencyGroupDeleteResponseModel(this.sendBuffer)
    this._CurrencyGroupDeleteResponseReceiverValue = new CurrencyGroupDeleteResponse()
    this._CurrencyGroupDeleteResponseReceiverModel = new CurrencyGroupDeleteResponseModel()
    this._CurrencyGroupUpdateNotifySenderModel = new CurrencyGroupUpdateNotifyModel(this.sendBuffer)
    this._CurrencyGroupUpdateNotifyReceiverValue = new CurrencyGroupUpdateNotify()
    this._CurrencyGroupUpdateNotifyReceiverModel = new CurrencyGroupUpdateNotifyModel()
    this._CurrencyGetRequestSenderModel = new CurrencyGetRequestModel(this.sendBuffer)
    this._CurrencyGetRequestReceiverValue = new CurrencyGetRequest()
    this._CurrencyGetRequestReceiverModel = new CurrencyGetRequestModel()
    this._CurrencyGetResponseSenderModel = new CurrencyGetResponseModel(this.sendBuffer)
    this._CurrencyGetResponseReceiverValue = new CurrencyGetResponse()
    this._CurrencyGetResponseReceiverModel = new CurrencyGetResponseModel()
    this._CurrenciesGetAllRequestSenderModel = new CurrenciesGetAllRequestModel(this.sendBuffer)
    this._CurrenciesGetAllRequestReceiverValue = new CurrenciesGetAllRequest()
    this._CurrenciesGetAllRequestReceiverModel = new CurrenciesGetAllRequestModel()
    this._CurrenciesGetAllResponseSenderModel = new CurrenciesGetAllResponseModel(this.sendBuffer)
    this._CurrenciesGetAllResponseReceiverValue = new CurrenciesGetAllResponse()
    this._CurrenciesGetAllResponseReceiverModel = new CurrenciesGetAllResponseModel()
    this._CurrencyCreateRequestSenderModel = new CurrencyCreateRequestModel(this.sendBuffer)
    this._CurrencyCreateRequestReceiverValue = new CurrencyCreateRequest()
    this._CurrencyCreateRequestReceiverModel = new CurrencyCreateRequestModel()
    this._CurrencyCreateResponseSenderModel = new CurrencyCreateResponseModel(this.sendBuffer)
    this._CurrencyCreateResponseReceiverValue = new CurrencyCreateResponse()
    this._CurrencyCreateResponseReceiverModel = new CurrencyCreateResponseModel()
    this._CurrencyModifyRequestSenderModel = new CurrencyModifyRequestModel(this.sendBuffer)
    this._CurrencyModifyRequestReceiverValue = new CurrencyModifyRequest()
    this._CurrencyModifyRequestReceiverModel = new CurrencyModifyRequestModel()
    this._CurrencyModifyResponseSenderModel = new CurrencyModifyResponseModel(this.sendBuffer)
    this._CurrencyModifyResponseReceiverValue = new CurrencyModifyResponse()
    this._CurrencyModifyResponseReceiverModel = new CurrencyModifyResponseModel()
    this._CurrencyDeleteRequestSenderModel = new CurrencyDeleteRequestModel(this.sendBuffer)
    this._CurrencyDeleteRequestReceiverValue = new CurrencyDeleteRequest()
    this._CurrencyDeleteRequestReceiverModel = new CurrencyDeleteRequestModel()
    this._CurrencyDeleteResponseSenderModel = new CurrencyDeleteResponseModel(this.sendBuffer)
    this._CurrencyDeleteResponseReceiverValue = new CurrencyDeleteResponse()
    this._CurrencyDeleteResponseReceiverModel = new CurrencyDeleteResponseModel()
    this._CurrencyUpdateNotifySenderModel = new CurrencyUpdateNotifyModel(this.sendBuffer)
    this._CurrencyUpdateNotifyReceiverValue = new CurrencyUpdateNotify()
    this._CurrencyUpdateNotifyReceiverModel = new CurrencyUpdateNotifyModel()
    this._SymbolGroupGetRequestSenderModel = new SymbolGroupGetRequestModel(this.sendBuffer)
    this._SymbolGroupGetRequestReceiverValue = new SymbolGroupGetRequest()
    this._SymbolGroupGetRequestReceiverModel = new SymbolGroupGetRequestModel()
    this._SymbolGroupGetResponseSenderModel = new SymbolGroupGetResponseModel(this.sendBuffer)
    this._SymbolGroupGetResponseReceiverValue = new SymbolGroupGetResponse()
    this._SymbolGroupGetResponseReceiverModel = new SymbolGroupGetResponseModel()
    this._SymbolGroupsGetAllRequestSenderModel = new SymbolGroupsGetAllRequestModel(this.sendBuffer)
    this._SymbolGroupsGetAllRequestReceiverValue = new SymbolGroupsGetAllRequest()
    this._SymbolGroupsGetAllRequestReceiverModel = new SymbolGroupsGetAllRequestModel()
    this._SymbolGroupsGetAllResponseSenderModel = new SymbolGroupsGetAllResponseModel(this.sendBuffer)
    this._SymbolGroupsGetAllResponseReceiverValue = new SymbolGroupsGetAllResponse()
    this._SymbolGroupsGetAllResponseReceiverModel = new SymbolGroupsGetAllResponseModel()
    this._SymbolGroupCreateRequestSenderModel = new SymbolGroupCreateRequestModel(this.sendBuffer)
    this._SymbolGroupCreateRequestReceiverValue = new SymbolGroupCreateRequest()
    this._SymbolGroupCreateRequestReceiverModel = new SymbolGroupCreateRequestModel()
    this._SymbolGroupCreateResponseSenderModel = new SymbolGroupCreateResponseModel(this.sendBuffer)
    this._SymbolGroupCreateResponseReceiverValue = new SymbolGroupCreateResponse()
    this._SymbolGroupCreateResponseReceiverModel = new SymbolGroupCreateResponseModel()
    this._SymbolGroupModifyRequestSenderModel = new SymbolGroupModifyRequestModel(this.sendBuffer)
    this._SymbolGroupModifyRequestReceiverValue = new SymbolGroupModifyRequest()
    this._SymbolGroupModifyRequestReceiverModel = new SymbolGroupModifyRequestModel()
    this._SymbolGroupModifyResponseSenderModel = new SymbolGroupModifyResponseModel(this.sendBuffer)
    this._SymbolGroupModifyResponseReceiverValue = new SymbolGroupModifyResponse()
    this._SymbolGroupModifyResponseReceiverModel = new SymbolGroupModifyResponseModel()
    this._SymbolGroupDeleteRequestSenderModel = new SymbolGroupDeleteRequestModel(this.sendBuffer)
    this._SymbolGroupDeleteRequestReceiverValue = new SymbolGroupDeleteRequest()
    this._SymbolGroupDeleteRequestReceiverModel = new SymbolGroupDeleteRequestModel()
    this._SymbolGroupDeleteResponseSenderModel = new SymbolGroupDeleteResponseModel(this.sendBuffer)
    this._SymbolGroupDeleteResponseReceiverValue = new SymbolGroupDeleteResponse()
    this._SymbolGroupDeleteResponseReceiverModel = new SymbolGroupDeleteResponseModel()
    this._SymbolGroupUpdateNotifySenderModel = new SymbolGroupUpdateNotifyModel(this.sendBuffer)
    this._SymbolGroupUpdateNotifyReceiverValue = new SymbolGroupUpdateNotify()
    this._SymbolGroupUpdateNotifyReceiverModel = new SymbolGroupUpdateNotifyModel()
    this._SymbolGetRequestSenderModel = new SymbolGetRequestModel(this.sendBuffer)
    this._SymbolGetRequestReceiverValue = new SymbolGetRequest()
    this._SymbolGetRequestReceiverModel = new SymbolGetRequestModel()
    this._SymbolGetResponseSenderModel = new SymbolGetResponseModel(this.sendBuffer)
    this._SymbolGetResponseReceiverValue = new SymbolGetResponse()
    this._SymbolGetResponseReceiverModel = new SymbolGetResponseModel()
    this._SymbolsGetAllRequestSenderModel = new SymbolsGetAllRequestModel(this.sendBuffer)
    this._SymbolsGetAllRequestReceiverValue = new SymbolsGetAllRequest()
    this._SymbolsGetAllRequestReceiverModel = new SymbolsGetAllRequestModel()
    this._SymbolsGetAllResponseSenderModel = new SymbolsGetAllResponseModel(this.sendBuffer)
    this._SymbolsGetAllResponseReceiverValue = new SymbolsGetAllResponse()
    this._SymbolsGetAllResponseReceiverModel = new SymbolsGetAllResponseModel()
    this._SymbolCreateRequestSenderModel = new SymbolCreateRequestModel(this.sendBuffer)
    this._SymbolCreateRequestReceiverValue = new SymbolCreateRequest()
    this._SymbolCreateRequestReceiverModel = new SymbolCreateRequestModel()
    this._SymbolCreateResponseSenderModel = new SymbolCreateResponseModel(this.sendBuffer)
    this._SymbolCreateResponseReceiverValue = new SymbolCreateResponse()
    this._SymbolCreateResponseReceiverModel = new SymbolCreateResponseModel()
    this._SymbolModifyRequestSenderModel = new SymbolModifyRequestModel(this.sendBuffer)
    this._SymbolModifyRequestReceiverValue = new SymbolModifyRequest()
    this._SymbolModifyRequestReceiverModel = new SymbolModifyRequestModel()
    this._SymbolModifyResponseSenderModel = new SymbolModifyResponseModel(this.sendBuffer)
    this._SymbolModifyResponseReceiverValue = new SymbolModifyResponse()
    this._SymbolModifyResponseReceiverModel = new SymbolModifyResponseModel()
    this._SymbolDeleteRequestSenderModel = new SymbolDeleteRequestModel(this.sendBuffer)
    this._SymbolDeleteRequestReceiverValue = new SymbolDeleteRequest()
    this._SymbolDeleteRequestReceiverModel = new SymbolDeleteRequestModel()
    this._SymbolDeleteResponseSenderModel = new SymbolDeleteResponseModel(this.sendBuffer)
    this._SymbolDeleteResponseReceiverValue = new SymbolDeleteResponse()
    this._SymbolDeleteResponseReceiverModel = new SymbolDeleteResponseModel()
    this._SymbolUpdateNotifySenderModel = new SymbolUpdateNotifyModel(this.sendBuffer)
    this._SymbolUpdateNotifyReceiverValue = new SymbolUpdateNotify()
    this._SymbolUpdateNotifyReceiverModel = new SymbolUpdateNotifyModel()
    this._SwapsExportRequestSenderModel = new SwapsExportRequestModel(this.sendBuffer)
    this._SwapsExportRequestReceiverValue = new SwapsExportRequest()
    this._SwapsExportRequestReceiverModel = new SwapsExportRequestModel()
    this._SwapsExportResponseSenderModel = new SwapsExportResponseModel(this.sendBuffer)
    this._SwapsExportResponseReceiverValue = new SwapsExportResponse()
    this._SwapsExportResponseReceiverModel = new SwapsExportResponseModel()
    this._SwapsImportRequestSenderModel = new SwapsImportRequestModel(this.sendBuffer)
    this._SwapsImportRequestReceiverValue = new SwapsImportRequest()
    this._SwapsImportRequestReceiverModel = new SwapsImportRequestModel()
    this._SwapsImportResponseSenderModel = new SwapsImportResponseModel(this.sendBuffer)
    this._SwapsImportResponseReceiverValue = new SwapsImportResponse()
    this._SwapsImportResponseReceiverModel = new SwapsImportResponseModel()
    this._CommissionProfileGetRequestSenderModel = new CommissionProfileGetRequestModel(this.sendBuffer)
    this._CommissionProfileGetRequestReceiverValue = new CommissionProfileGetRequest()
    this._CommissionProfileGetRequestReceiverModel = new CommissionProfileGetRequestModel()
    this._CommissionProfileGetResponseSenderModel = new CommissionProfileGetResponseModel(this.sendBuffer)
    this._CommissionProfileGetResponseReceiverValue = new CommissionProfileGetResponse()
    this._CommissionProfileGetResponseReceiverModel = new CommissionProfileGetResponseModel()
    this._CommissionProfilesGetAllRequestSenderModel = new CommissionProfilesGetAllRequestModel(this.sendBuffer)
    this._CommissionProfilesGetAllRequestReceiverValue = new CommissionProfilesGetAllRequest()
    this._CommissionProfilesGetAllRequestReceiverModel = new CommissionProfilesGetAllRequestModel()
    this._CommissionProfilesGetAllResponseSenderModel = new CommissionProfilesGetAllResponseModel(this.sendBuffer)
    this._CommissionProfilesGetAllResponseReceiverValue = new CommissionProfilesGetAllResponse()
    this._CommissionProfilesGetAllResponseReceiverModel = new CommissionProfilesGetAllResponseModel()
    this._CommissionProfileCreateRequestSenderModel = new CommissionProfileCreateRequestModel(this.sendBuffer)
    this._CommissionProfileCreateRequestReceiverValue = new CommissionProfileCreateRequest()
    this._CommissionProfileCreateRequestReceiverModel = new CommissionProfileCreateRequestModel()
    this._CommissionProfileCreateResponseSenderModel = new CommissionProfileCreateResponseModel(this.sendBuffer)
    this._CommissionProfileCreateResponseReceiverValue = new CommissionProfileCreateResponse()
    this._CommissionProfileCreateResponseReceiverModel = new CommissionProfileCreateResponseModel()
    this._CommissionProfileModifyRequestSenderModel = new CommissionProfileModifyRequestModel(this.sendBuffer)
    this._CommissionProfileModifyRequestReceiverValue = new CommissionProfileModifyRequest()
    this._CommissionProfileModifyRequestReceiverModel = new CommissionProfileModifyRequestModel()
    this._CommissionProfileModifyResponseSenderModel = new CommissionProfileModifyResponseModel(this.sendBuffer)
    this._CommissionProfileModifyResponseReceiverValue = new CommissionProfileModifyResponse()
    this._CommissionProfileModifyResponseReceiverModel = new CommissionProfileModifyResponseModel()
    this._CommissionProfileDeleteRequestSenderModel = new CommissionProfileDeleteRequestModel(this.sendBuffer)
    this._CommissionProfileDeleteRequestReceiverValue = new CommissionProfileDeleteRequest()
    this._CommissionProfileDeleteRequestReceiverModel = new CommissionProfileDeleteRequestModel()
    this._CommissionProfileDeleteResponseSenderModel = new CommissionProfileDeleteResponseModel(this.sendBuffer)
    this._CommissionProfileDeleteResponseReceiverValue = new CommissionProfileDeleteResponse()
    this._CommissionProfileDeleteResponseReceiverModel = new CommissionProfileDeleteResponseModel()
    this._CommissionProfileUpdateNotifySenderModel = new CommissionProfileUpdateNotifyModel(this.sendBuffer)
    this._CommissionProfileUpdateNotifyReceiverValue = new CommissionProfileUpdateNotify()
    this._CommissionProfileUpdateNotifyReceiverModel = new CommissionProfileUpdateNotifyModel()
    this._MarkupProfileGetRequestSenderModel = new MarkupProfileGetRequestModel(this.sendBuffer)
    this._MarkupProfileGetRequestReceiverValue = new MarkupProfileGetRequest()
    this._MarkupProfileGetRequestReceiverModel = new MarkupProfileGetRequestModel()
    this._MarkupProfileGetResponseSenderModel = new MarkupProfileGetResponseModel(this.sendBuffer)
    this._MarkupProfileGetResponseReceiverValue = new MarkupProfileGetResponse()
    this._MarkupProfileGetResponseReceiverModel = new MarkupProfileGetResponseModel()
    this._MarkupProfilesGetAllRequestSenderModel = new MarkupProfilesGetAllRequestModel(this.sendBuffer)
    this._MarkupProfilesGetAllRequestReceiverValue = new MarkupProfilesGetAllRequest()
    this._MarkupProfilesGetAllRequestReceiverModel = new MarkupProfilesGetAllRequestModel()
    this._MarkupProfilesGetAllResponseSenderModel = new MarkupProfilesGetAllResponseModel(this.sendBuffer)
    this._MarkupProfilesGetAllResponseReceiverValue = new MarkupProfilesGetAllResponse()
    this._MarkupProfilesGetAllResponseReceiverModel = new MarkupProfilesGetAllResponseModel()
    this._MarkupProfileCreateRequestSenderModel = new MarkupProfileCreateRequestModel(this.sendBuffer)
    this._MarkupProfileCreateRequestReceiverValue = new MarkupProfileCreateRequest()
    this._MarkupProfileCreateRequestReceiverModel = new MarkupProfileCreateRequestModel()
    this._MarkupProfileCreateResponseSenderModel = new MarkupProfileCreateResponseModel(this.sendBuffer)
    this._MarkupProfileCreateResponseReceiverValue = new MarkupProfileCreateResponse()
    this._MarkupProfileCreateResponseReceiverModel = new MarkupProfileCreateResponseModel()
    this._MarkupProfileModifyRequestSenderModel = new MarkupProfileModifyRequestModel(this.sendBuffer)
    this._MarkupProfileModifyRequestReceiverValue = new MarkupProfileModifyRequest()
    this._MarkupProfileModifyRequestReceiverModel = new MarkupProfileModifyRequestModel()
    this._MarkupProfileModifyResponseSenderModel = new MarkupProfileModifyResponseModel(this.sendBuffer)
    this._MarkupProfileModifyResponseReceiverValue = new MarkupProfileModifyResponse()
    this._MarkupProfileModifyResponseReceiverModel = new MarkupProfileModifyResponseModel()
    this._MarkupProfileDeleteRequestSenderModel = new MarkupProfileDeleteRequestModel(this.sendBuffer)
    this._MarkupProfileDeleteRequestReceiverValue = new MarkupProfileDeleteRequest()
    this._MarkupProfileDeleteRequestReceiverModel = new MarkupProfileDeleteRequestModel()
    this._MarkupProfileDeleteResponseSenderModel = new MarkupProfileDeleteResponseModel(this.sendBuffer)
    this._MarkupProfileDeleteResponseReceiverValue = new MarkupProfileDeleteResponse()
    this._MarkupProfileDeleteResponseReceiverModel = new MarkupProfileDeleteResponseModel()
    this._MarkupProfileUpdateNotifySenderModel = new MarkupProfileUpdateNotifyModel(this.sendBuffer)
    this._MarkupProfileUpdateNotifyReceiverValue = new MarkupProfileUpdateNotify()
    this._MarkupProfileUpdateNotifyReceiverModel = new MarkupProfileUpdateNotifyModel()
    this._ManagerGroupGetRequestSenderModel = new ManagerGroupGetRequestModel(this.sendBuffer)
    this._ManagerGroupGetRequestReceiverValue = new ManagerGroupGetRequest()
    this._ManagerGroupGetRequestReceiverModel = new ManagerGroupGetRequestModel()
    this._ManagerGroupGetResponseSenderModel = new ManagerGroupGetResponseModel(this.sendBuffer)
    this._ManagerGroupGetResponseReceiverValue = new ManagerGroupGetResponse()
    this._ManagerGroupGetResponseReceiverModel = new ManagerGroupGetResponseModel()
    this._ManagerGroupsGetAllRequestSenderModel = new ManagerGroupsGetAllRequestModel(this.sendBuffer)
    this._ManagerGroupsGetAllRequestReceiverValue = new ManagerGroupsGetAllRequest()
    this._ManagerGroupsGetAllRequestReceiverModel = new ManagerGroupsGetAllRequestModel()
    this._ManagerGroupsGetAllResponseSenderModel = new ManagerGroupsGetAllResponseModel(this.sendBuffer)
    this._ManagerGroupsGetAllResponseReceiverValue = new ManagerGroupsGetAllResponse()
    this._ManagerGroupsGetAllResponseReceiverModel = new ManagerGroupsGetAllResponseModel()
    this._ManagerGroupCreateRequestSenderModel = new ManagerGroupCreateRequestModel(this.sendBuffer)
    this._ManagerGroupCreateRequestReceiverValue = new ManagerGroupCreateRequest()
    this._ManagerGroupCreateRequestReceiverModel = new ManagerGroupCreateRequestModel()
    this._ManagerGroupCreateResponseSenderModel = new ManagerGroupCreateResponseModel(this.sendBuffer)
    this._ManagerGroupCreateResponseReceiverValue = new ManagerGroupCreateResponse()
    this._ManagerGroupCreateResponseReceiverModel = new ManagerGroupCreateResponseModel()
    this._ManagerGroupModifyRequestSenderModel = new ManagerGroupModifyRequestModel(this.sendBuffer)
    this._ManagerGroupModifyRequestReceiverValue = new ManagerGroupModifyRequest()
    this._ManagerGroupModifyRequestReceiverModel = new ManagerGroupModifyRequestModel()
    this._ManagerGroupModifyResponseSenderModel = new ManagerGroupModifyResponseModel(this.sendBuffer)
    this._ManagerGroupModifyResponseReceiverValue = new ManagerGroupModifyResponse()
    this._ManagerGroupModifyResponseReceiverModel = new ManagerGroupModifyResponseModel()
    this._ManagerGroupDeleteRequestSenderModel = new ManagerGroupDeleteRequestModel(this.sendBuffer)
    this._ManagerGroupDeleteRequestReceiverValue = new ManagerGroupDeleteRequest()
    this._ManagerGroupDeleteRequestReceiverModel = new ManagerGroupDeleteRequestModel()
    this._ManagerGroupDeleteResponseSenderModel = new ManagerGroupDeleteResponseModel(this.sendBuffer)
    this._ManagerGroupDeleteResponseReceiverValue = new ManagerGroupDeleteResponse()
    this._ManagerGroupDeleteResponseReceiverModel = new ManagerGroupDeleteResponseModel()
    this._ManagerGroupUpdateNotifySenderModel = new ManagerGroupUpdateNotifyModel(this.sendBuffer)
    this._ManagerGroupUpdateNotifyReceiverValue = new ManagerGroupUpdateNotify()
    this._ManagerGroupUpdateNotifyReceiverModel = new ManagerGroupUpdateNotifyModel()
    this._ManagerGetRequestSenderModel = new ManagerGetRequestModel(this.sendBuffer)
    this._ManagerGetRequestReceiverValue = new ManagerGetRequest()
    this._ManagerGetRequestReceiverModel = new ManagerGetRequestModel()
    this._ManagerGetResponseSenderModel = new ManagerGetResponseModel(this.sendBuffer)
    this._ManagerGetResponseReceiverValue = new ManagerGetResponse()
    this._ManagerGetResponseReceiverModel = new ManagerGetResponseModel()
    this._ManagersGetAllRequestSenderModel = new ManagersGetAllRequestModel(this.sendBuffer)
    this._ManagersGetAllRequestReceiverValue = new ManagersGetAllRequest()
    this._ManagersGetAllRequestReceiverModel = new ManagersGetAllRequestModel()
    this._ManagersGetAllResponseSenderModel = new ManagersGetAllResponseModel(this.sendBuffer)
    this._ManagersGetAllResponseReceiverValue = new ManagersGetAllResponse()
    this._ManagersGetAllResponseReceiverModel = new ManagersGetAllResponseModel()
    this._ManagerCreateRequestSenderModel = new ManagerCreateRequestModel(this.sendBuffer)
    this._ManagerCreateRequestReceiverValue = new ManagerCreateRequest()
    this._ManagerCreateRequestReceiverModel = new ManagerCreateRequestModel()
    this._ManagerCreateResponseSenderModel = new ManagerCreateResponseModel(this.sendBuffer)
    this._ManagerCreateResponseReceiverValue = new ManagerCreateResponse()
    this._ManagerCreateResponseReceiverModel = new ManagerCreateResponseModel()
    this._ManagerModifyRequestSenderModel = new ManagerModifyRequestModel(this.sendBuffer)
    this._ManagerModifyRequestReceiverValue = new ManagerModifyRequest()
    this._ManagerModifyRequestReceiverModel = new ManagerModifyRequestModel()
    this._ManagerModifyResponseSenderModel = new ManagerModifyResponseModel(this.sendBuffer)
    this._ManagerModifyResponseReceiverValue = new ManagerModifyResponse()
    this._ManagerModifyResponseReceiverModel = new ManagerModifyResponseModel()
    this._ManagerDeleteRequestSenderModel = new ManagerDeleteRequestModel(this.sendBuffer)
    this._ManagerDeleteRequestReceiverValue = new ManagerDeleteRequest()
    this._ManagerDeleteRequestReceiverModel = new ManagerDeleteRequestModel()
    this._ManagerDeleteResponseSenderModel = new ManagerDeleteResponseModel(this.sendBuffer)
    this._ManagerDeleteResponseReceiverValue = new ManagerDeleteResponse()
    this._ManagerDeleteResponseReceiverModel = new ManagerDeleteResponseModel()
    this._ManagerUpdateNotifySenderModel = new ManagerUpdateNotifyModel(this.sendBuffer)
    this._ManagerUpdateNotifyReceiverValue = new ManagerUpdateNotify()
    this._ManagerUpdateNotifyReceiverModel = new ManagerUpdateNotifyModel()
    this._PlatformGetRequestSenderModel = new PlatformGetRequestModel(this.sendBuffer)
    this._PlatformGetRequestReceiverValue = new PlatformGetRequest()
    this._PlatformGetRequestReceiverModel = new PlatformGetRequestModel()
    this._PlatformGetResponseSenderModel = new PlatformGetResponseModel(this.sendBuffer)
    this._PlatformGetResponseReceiverValue = new PlatformGetResponse()
    this._PlatformGetResponseReceiverModel = new PlatformGetResponseModel()
    this._PlatformsGetAllRequestSenderModel = new PlatformsGetAllRequestModel(this.sendBuffer)
    this._PlatformsGetAllRequestReceiverValue = new PlatformsGetAllRequest()
    this._PlatformsGetAllRequestReceiverModel = new PlatformsGetAllRequestModel()
    this._PlatformsGetAllResponseSenderModel = new PlatformsGetAllResponseModel(this.sendBuffer)
    this._PlatformsGetAllResponseReceiverValue = new PlatformsGetAllResponse()
    this._PlatformsGetAllResponseReceiverModel = new PlatformsGetAllResponseModel()
    this._PlatformCreateRequestSenderModel = new PlatformCreateRequestModel(this.sendBuffer)
    this._PlatformCreateRequestReceiverValue = new PlatformCreateRequest()
    this._PlatformCreateRequestReceiverModel = new PlatformCreateRequestModel()
    this._PlatformCreateResponseSenderModel = new PlatformCreateResponseModel(this.sendBuffer)
    this._PlatformCreateResponseReceiverValue = new PlatformCreateResponse()
    this._PlatformCreateResponseReceiverModel = new PlatformCreateResponseModel()
    this._PlatformModifyRequestSenderModel = new PlatformModifyRequestModel(this.sendBuffer)
    this._PlatformModifyRequestReceiverValue = new PlatformModifyRequest()
    this._PlatformModifyRequestReceiverModel = new PlatformModifyRequestModel()
    this._PlatformModifyResponseSenderModel = new PlatformModifyResponseModel(this.sendBuffer)
    this._PlatformModifyResponseReceiverValue = new PlatformModifyResponse()
    this._PlatformModifyResponseReceiverModel = new PlatformModifyResponseModel()
    this._PlatformDeleteRequestSenderModel = new PlatformDeleteRequestModel(this.sendBuffer)
    this._PlatformDeleteRequestReceiverValue = new PlatformDeleteRequest()
    this._PlatformDeleteRequestReceiverModel = new PlatformDeleteRequestModel()
    this._PlatformDeleteResponseSenderModel = new PlatformDeleteResponseModel(this.sendBuffer)
    this._PlatformDeleteResponseReceiverValue = new PlatformDeleteResponse()
    this._PlatformDeleteResponseReceiverModel = new PlatformDeleteResponseModel()
    this._PlatformUpdateNotifySenderModel = new PlatformUpdateNotifyModel(this.sendBuffer)
    this._PlatformUpdateNotifyReceiverValue = new PlatformUpdateNotify()
    this._PlatformUpdateNotifyReceiverModel = new PlatformUpdateNotifyModel()
    this._AccountGroupGetRequestSenderModel = new AccountGroupGetRequestModel(this.sendBuffer)
    this._AccountGroupGetRequestReceiverValue = new AccountGroupGetRequest()
    this._AccountGroupGetRequestReceiverModel = new AccountGroupGetRequestModel()
    this._AccountGroupGetResponseSenderModel = new AccountGroupGetResponseModel(this.sendBuffer)
    this._AccountGroupGetResponseReceiverValue = new AccountGroupGetResponse()
    this._AccountGroupGetResponseReceiverModel = new AccountGroupGetResponseModel()
    this._AccountGroupsGetAllRequestSenderModel = new AccountGroupsGetAllRequestModel(this.sendBuffer)
    this._AccountGroupsGetAllRequestReceiverValue = new AccountGroupsGetAllRequest()
    this._AccountGroupsGetAllRequestReceiverModel = new AccountGroupsGetAllRequestModel()
    this._AccountGroupsGetAllResponseSenderModel = new AccountGroupsGetAllResponseModel(this.sendBuffer)
    this._AccountGroupsGetAllResponseReceiverValue = new AccountGroupsGetAllResponse()
    this._AccountGroupsGetAllResponseReceiverModel = new AccountGroupsGetAllResponseModel()
    this._AccountGroupCreateRequestSenderModel = new AccountGroupCreateRequestModel(this.sendBuffer)
    this._AccountGroupCreateRequestReceiverValue = new AccountGroupCreateRequest()
    this._AccountGroupCreateRequestReceiverModel = new AccountGroupCreateRequestModel()
    this._AccountGroupCreateResponseSenderModel = new AccountGroupCreateResponseModel(this.sendBuffer)
    this._AccountGroupCreateResponseReceiverValue = new AccountGroupCreateResponse()
    this._AccountGroupCreateResponseReceiverModel = new AccountGroupCreateResponseModel()
    this._AccountGroupModifyRequestSenderModel = new AccountGroupModifyRequestModel(this.sendBuffer)
    this._AccountGroupModifyRequestReceiverValue = new AccountGroupModifyRequest()
    this._AccountGroupModifyRequestReceiverModel = new AccountGroupModifyRequestModel()
    this._AccountGroupModifyResponseSenderModel = new AccountGroupModifyResponseModel(this.sendBuffer)
    this._AccountGroupModifyResponseReceiverValue = new AccountGroupModifyResponse()
    this._AccountGroupModifyResponseReceiverModel = new AccountGroupModifyResponseModel()
    this._AccountGroupDeleteRequestSenderModel = new AccountGroupDeleteRequestModel(this.sendBuffer)
    this._AccountGroupDeleteRequestReceiverValue = new AccountGroupDeleteRequest()
    this._AccountGroupDeleteRequestReceiverModel = new AccountGroupDeleteRequestModel()
    this._AccountGroupDeleteResponseSenderModel = new AccountGroupDeleteResponseModel(this.sendBuffer)
    this._AccountGroupDeleteResponseReceiverValue = new AccountGroupDeleteResponse()
    this._AccountGroupDeleteResponseReceiverModel = new AccountGroupDeleteResponseModel()
    this._AccountGroupUpdateNotifySenderModel = new AccountGroupUpdateNotifyModel(this.sendBuffer)
    this._AccountGroupUpdateNotifyReceiverValue = new AccountGroupUpdateNotify()
    this._AccountGroupUpdateNotifyReceiverModel = new AccountGroupUpdateNotifyModel()
    this._AccountGetRequestSenderModel = new AccountGetRequestModel(this.sendBuffer)
    this._AccountGetRequestReceiverValue = new AccountGetRequest()
    this._AccountGetRequestReceiverModel = new AccountGetRequestModel()
    this._AccountGetResponseSenderModel = new AccountGetResponseModel(this.sendBuffer)
    this._AccountGetResponseReceiverValue = new AccountGetResponse()
    this._AccountGetResponseReceiverModel = new AccountGetResponseModel()
    this._AccountsGetAllRequestSenderModel = new AccountsGetAllRequestModel(this.sendBuffer)
    this._AccountsGetAllRequestReceiverValue = new AccountsGetAllRequest()
    this._AccountsGetAllRequestReceiverModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseSenderModel = new AccountsGetAllResponseModel(this.sendBuffer)
    this._AccountsGetAllResponseReceiverValue = new AccountsGetAllResponse()
    this._AccountsGetAllResponseReceiverModel = new AccountsGetAllResponseModel()
    this._AccountCreateRequestSenderModel = new AccountCreateRequestModel(this.sendBuffer)
    this._AccountCreateRequestReceiverValue = new AccountCreateRequest()
    this._AccountCreateRequestReceiverModel = new AccountCreateRequestModel()
    this._AccountCreateResponseSenderModel = new AccountCreateResponseModel(this.sendBuffer)
    this._AccountCreateResponseReceiverValue = new AccountCreateResponse()
    this._AccountCreateResponseReceiverModel = new AccountCreateResponseModel()
    this._AccountSocialTradingMasterCreateRequestSenderModel = new AccountSocialTradingMasterCreateRequestModel(this.sendBuffer)
    this._AccountSocialTradingMasterCreateRequestReceiverValue = new AccountSocialTradingMasterCreateRequest()
    this._AccountSocialTradingMasterCreateRequestReceiverModel = new AccountSocialTradingMasterCreateRequestModel()
    this._AccountSocialTradingMasterCreateResponseSenderModel = new AccountSocialTradingMasterCreateResponseModel(this.sendBuffer)
    this._AccountSocialTradingMasterCreateResponseReceiverValue = new AccountSocialTradingMasterCreateResponse()
    this._AccountSocialTradingMasterCreateResponseReceiverModel = new AccountSocialTradingMasterCreateResponseModel()
    this._AccountSocialTradingInvestorCreateRequestSenderModel = new AccountSocialTradingInvestorCreateRequestModel(this.sendBuffer)
    this._AccountSocialTradingInvestorCreateRequestReceiverValue = new AccountSocialTradingInvestorCreateRequest()
    this._AccountSocialTradingInvestorCreateRequestReceiverModel = new AccountSocialTradingInvestorCreateRequestModel()
    this._AccountSocialTradingInvestorCreateResponseSenderModel = new AccountSocialTradingInvestorCreateResponseModel(this.sendBuffer)
    this._AccountSocialTradingInvestorCreateResponseReceiverValue = new AccountSocialTradingInvestorCreateResponse()
    this._AccountSocialTradingInvestorCreateResponseReceiverModel = new AccountSocialTradingInvestorCreateResponseModel()
    this._AccountModifyRequestSenderModel = new AccountModifyRequestModel(this.sendBuffer)
    this._AccountModifyRequestReceiverValue = new AccountModifyRequest()
    this._AccountModifyRequestReceiverModel = new AccountModifyRequestModel()
    this._AccountModifyResponseSenderModel = new AccountModifyResponseModel(this.sendBuffer)
    this._AccountModifyResponseReceiverValue = new AccountModifyResponse()
    this._AccountModifyResponseReceiverModel = new AccountModifyResponseModel()
    this._AccountDeleteRequestSenderModel = new AccountDeleteRequestModel(this.sendBuffer)
    this._AccountDeleteRequestReceiverValue = new AccountDeleteRequest()
    this._AccountDeleteRequestReceiverModel = new AccountDeleteRequestModel()
    this._AccountDeleteResponseSenderModel = new AccountDeleteResponseModel(this.sendBuffer)
    this._AccountDeleteResponseReceiverValue = new AccountDeleteResponse()
    this._AccountDeleteResponseReceiverModel = new AccountDeleteResponseModel()
    this._DemoAccountsDeleteRequestSenderModel = new DemoAccountsDeleteRequestModel(this.sendBuffer)
    this._DemoAccountsDeleteRequestReceiverValue = new DemoAccountsDeleteRequest()
    this._DemoAccountsDeleteRequestReceiverModel = new DemoAccountsDeleteRequestModel()
    this._DemoAccountsDeleteResponseSenderModel = new DemoAccountsDeleteResponseModel(this.sendBuffer)
    this._DemoAccountsDeleteResponseReceiverValue = new DemoAccountsDeleteResponse()
    this._DemoAccountsDeleteResponseReceiverModel = new DemoAccountsDeleteResponseModel()
    this._AccountUpgradeToSocialTradingMasterRequestSenderModel = new AccountUpgradeToSocialTradingMasterRequestModel(this.sendBuffer)
    this._AccountUpgradeToSocialTradingMasterRequestReceiverValue = new AccountUpgradeToSocialTradingMasterRequest()
    this._AccountUpgradeToSocialTradingMasterRequestReceiverModel = new AccountUpgradeToSocialTradingMasterRequestModel()
    this._AccountUpgradeToSocialTradingMasterResponseSenderModel = new AccountUpgradeToSocialTradingMasterResponseModel(this.sendBuffer)
    this._AccountUpgradeToSocialTradingMasterResponseReceiverValue = new AccountUpgradeToSocialTradingMasterResponse()
    this._AccountUpgradeToSocialTradingMasterResponseReceiverModel = new AccountUpgradeToSocialTradingMasterResponseModel()
    this._AccountUpgradeToSocialTradingInvestorRequestSenderModel = new AccountUpgradeToSocialTradingInvestorRequestModel(this.sendBuffer)
    this._AccountUpgradeToSocialTradingInvestorRequestReceiverValue = new AccountUpgradeToSocialTradingInvestorRequest()
    this._AccountUpgradeToSocialTradingInvestorRequestReceiverModel = new AccountUpgradeToSocialTradingInvestorRequestModel()
    this._AccountUpgradeToSocialTradingInvestorResponseSenderModel = new AccountUpgradeToSocialTradingInvestorResponseModel(this.sendBuffer)
    this._AccountUpgradeToSocialTradingInvestorResponseReceiverValue = new AccountUpgradeToSocialTradingInvestorResponse()
    this._AccountUpgradeToSocialTradingInvestorResponseReceiverModel = new AccountUpgradeToSocialTradingInvestorResponseModel()
    this._SocialTradingInvestorAccountToRegularRequestSenderModel = new SocialTradingInvestorAccountToRegularRequestModel(this.sendBuffer)
    this._SocialTradingInvestorAccountToRegularRequestReceiverValue = new SocialTradingInvestorAccountToRegularRequest()
    this._SocialTradingInvestorAccountToRegularRequestReceiverModel = new SocialTradingInvestorAccountToRegularRequestModel()
    this._SocialTradingInvestorAccountToRegularResponseSenderModel = new SocialTradingInvestorAccountToRegularResponseModel(this.sendBuffer)
    this._SocialTradingInvestorAccountToRegularResponseReceiverValue = new SocialTradingInvestorAccountToRegularResponse()
    this._SocialTradingInvestorAccountToRegularResponseReceiverModel = new SocialTradingInvestorAccountToRegularResponseModel()
    this._SocialTradingMasterAccountToRegularRequestSenderModel = new SocialTradingMasterAccountToRegularRequestModel(this.sendBuffer)
    this._SocialTradingMasterAccountToRegularRequestReceiverValue = new SocialTradingMasterAccountToRegularRequest()
    this._SocialTradingMasterAccountToRegularRequestReceiverModel = new SocialTradingMasterAccountToRegularRequestModel()
    this._SocialTradingMasterAccountToRegularResponseSenderModel = new SocialTradingMasterAccountToRegularResponseModel(this.sendBuffer)
    this._SocialTradingMasterAccountToRegularResponseReceiverValue = new SocialTradingMasterAccountToRegularResponse()
    this._SocialTradingMasterAccountToRegularResponseReceiverModel = new SocialTradingMasterAccountToRegularResponseModel()
    this._AccountUpdateNotifySenderModel = new AccountUpdateNotifyModel(this.sendBuffer)
    this._AccountUpdateNotifyReceiverValue = new AccountUpdateNotify()
    this._AccountUpdateNotifyReceiverModel = new AccountUpdateNotifyModel()
    this._MarginGetRequestSenderModel = new MarginGetRequestModel(this.sendBuffer)
    this._MarginGetRequestReceiverValue = new MarginGetRequest()
    this._MarginGetRequestReceiverModel = new MarginGetRequestModel()
    this._MarginGetResponseSenderModel = new MarginGetResponseModel(this.sendBuffer)
    this._MarginGetResponseReceiverValue = new MarginGetResponse()
    this._MarginGetResponseReceiverModel = new MarginGetResponseModel()
    this._MarginsGetAllRequestSenderModel = new MarginsGetAllRequestModel(this.sendBuffer)
    this._MarginsGetAllRequestReceiverValue = new MarginsGetAllRequest()
    this._MarginsGetAllRequestReceiverModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseSenderModel = new MarginsGetAllResponseModel(this.sendBuffer)
    this._MarginsGetAllResponseReceiverValue = new MarginsGetAllResponse()
    this._MarginsGetAllResponseReceiverModel = new MarginsGetAllResponseModel()
    this._MarginUpdateNotifySenderModel = new MarginUpdateNotifyModel(this.sendBuffer)
    this._MarginUpdateNotifyReceiverValue = new MarginUpdateNotify()
    this._MarginUpdateNotifyReceiverModel = new MarginUpdateNotifyModel()
    this._MarginCallNotifySenderModel = new MarginCallNotifyModel(this.sendBuffer)
    this._MarginCallNotifyReceiverValue = new MarginCallNotify()
    this._MarginCallNotifyReceiverModel = new MarginCallNotifyModel()
    this._StopOutNotifySenderModel = new StopOutNotifyModel(this.sendBuffer)
    this._StopOutNotifyReceiverValue = new StopOutNotify()
    this._StopOutNotifyReceiverModel = new StopOutNotifyModel()
    this._AssetGetRequestSenderModel = new AssetGetRequestModel(this.sendBuffer)
    this._AssetGetRequestReceiverValue = new AssetGetRequest()
    this._AssetGetRequestReceiverModel = new AssetGetRequestModel()
    this._AssetGetResponseSenderModel = new AssetGetResponseModel(this.sendBuffer)
    this._AssetGetResponseReceiverValue = new AssetGetResponse()
    this._AssetGetResponseReceiverModel = new AssetGetResponseModel()
    this._AssetsGetRequestSenderModel = new AssetsGetRequestModel(this.sendBuffer)
    this._AssetsGetRequestReceiverValue = new AssetsGetRequest()
    this._AssetsGetRequestReceiverModel = new AssetsGetRequestModel()
    this._AssetsGetResponseSenderModel = new AssetsGetResponseModel(this.sendBuffer)
    this._AssetsGetResponseReceiverValue = new AssetsGetResponse()
    this._AssetsGetResponseReceiverModel = new AssetsGetResponseModel()
    this._AssetsGetAllRequestSenderModel = new AssetsGetAllRequestModel(this.sendBuffer)
    this._AssetsGetAllRequestReceiverValue = new AssetsGetAllRequest()
    this._AssetsGetAllRequestReceiverModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseSenderModel = new AssetsGetAllResponseModel(this.sendBuffer)
    this._AssetsGetAllResponseReceiverValue = new AssetsGetAllResponse()
    this._AssetsGetAllResponseReceiverModel = new AssetsGetAllResponseModel()
    this._AssetUpdateNotifySenderModel = new AssetUpdateNotifyModel(this.sendBuffer)
    this._AssetUpdateNotifyReceiverValue = new AssetUpdateNotify()
    this._AssetUpdateNotifyReceiverModel = new AssetUpdateNotifyModel()
    this._OrderGetRequestSenderModel = new OrderGetRequestModel(this.sendBuffer)
    this._OrderGetRequestReceiverValue = new OrderGetRequest()
    this._OrderGetRequestReceiverModel = new OrderGetRequestModel()
    this._OrderGetResponseSenderModel = new OrderGetResponseModel(this.sendBuffer)
    this._OrderGetResponseReceiverValue = new OrderGetResponse()
    this._OrderGetResponseReceiverModel = new OrderGetResponseModel()
    this._OrdersGetRequestSenderModel = new OrdersGetRequestModel(this.sendBuffer)
    this._OrdersGetRequestReceiverValue = new OrdersGetRequest()
    this._OrdersGetRequestReceiverModel = new OrdersGetRequestModel()
    this._OrdersGetResponseSenderModel = new OrdersGetResponseModel(this.sendBuffer)
    this._OrdersGetResponseReceiverValue = new OrdersGetResponse()
    this._OrdersGetResponseReceiverModel = new OrdersGetResponseModel()
    this._OrdersGetAllRequestSenderModel = new OrdersGetAllRequestModel(this.sendBuffer)
    this._OrdersGetAllRequestReceiverValue = new OrdersGetAllRequest()
    this._OrdersGetAllRequestReceiverModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseSenderModel = new OrdersGetAllResponseModel(this.sendBuffer)
    this._OrdersGetAllResponseReceiverValue = new OrdersGetAllResponse()
    this._OrdersGetAllResponseReceiverModel = new OrdersGetAllResponseModel()
    this._OrderCreateRequestSenderModel = new OrderCreateRequestModel(this.sendBuffer)
    this._OrderCreateRequestReceiverValue = new OrderCreateRequest()
    this._OrderCreateRequestReceiverModel = new OrderCreateRequestModel()
    this._OrderCreateResponseSenderModel = new OrderCreateResponseModel(this.sendBuffer)
    this._OrderCreateResponseReceiverValue = new OrderCreateResponse()
    this._OrderCreateResponseReceiverModel = new OrderCreateResponseModel()
    this._OrderModifyRequestSenderModel = new OrderModifyRequestModel(this.sendBuffer)
    this._OrderModifyRequestReceiverValue = new OrderModifyRequest()
    this._OrderModifyRequestReceiverModel = new OrderModifyRequestModel()
    this._OrderModifyResponseSenderModel = new OrderModifyResponseModel(this.sendBuffer)
    this._OrderModifyResponseReceiverValue = new OrderModifyResponse()
    this._OrderModifyResponseReceiverModel = new OrderModifyResponseModel()
    this._OrderActivateRequestSenderModel = new OrderActivateRequestModel(this.sendBuffer)
    this._OrderActivateRequestReceiverValue = new OrderActivateRequest()
    this._OrderActivateRequestReceiverModel = new OrderActivateRequestModel()
    this._OrderActivateResponseSenderModel = new OrderActivateResponseModel(this.sendBuffer)
    this._OrderActivateResponseReceiverValue = new OrderActivateResponse()
    this._OrderActivateResponseReceiverModel = new OrderActivateResponseModel()
    this._OrderCancelRequestSenderModel = new OrderCancelRequestModel(this.sendBuffer)
    this._OrderCancelRequestReceiverValue = new OrderCancelRequest()
    this._OrderCancelRequestReceiverModel = new OrderCancelRequestModel()
    this._OrderCancelResponseSenderModel = new OrderCancelResponseModel(this.sendBuffer)
    this._OrderCancelResponseReceiverValue = new OrderCancelResponse()
    this._OrderCancelResponseReceiverModel = new OrderCancelResponseModel()
    this._OrderCancelAllRequestSenderModel = new OrderCancelAllRequestModel(this.sendBuffer)
    this._OrderCancelAllRequestReceiverValue = new OrderCancelAllRequest()
    this._OrderCancelAllRequestReceiverModel = new OrderCancelAllRequestModel()
    this._OrderCancelAllResponseSenderModel = new OrderCancelAllResponseModel(this.sendBuffer)
    this._OrderCancelAllResponseReceiverValue = new OrderCancelAllResponse()
    this._OrderCancelAllResponseReceiverModel = new OrderCancelAllResponseModel()
    this._OrderCancelAllByIdRequestSenderModel = new OrderCancelAllByIdRequestModel(this.sendBuffer)
    this._OrderCancelAllByIdRequestReceiverValue = new OrderCancelAllByIdRequest()
    this._OrderCancelAllByIdRequestReceiverModel = new OrderCancelAllByIdRequestModel()
    this._OrderCloseRequestSenderModel = new OrderCloseRequestModel(this.sendBuffer)
    this._OrderCloseRequestReceiverValue = new OrderCloseRequest()
    this._OrderCloseRequestReceiverModel = new OrderCloseRequestModel()
    this._OrderCloseResponseSenderModel = new OrderCloseResponseModel(this.sendBuffer)
    this._OrderCloseResponseReceiverValue = new OrderCloseResponse()
    this._OrderCloseResponseReceiverModel = new OrderCloseResponseModel()
    this._OrderCloseAllRequestSenderModel = new OrderCloseAllRequestModel(this.sendBuffer)
    this._OrderCloseAllRequestReceiverValue = new OrderCloseAllRequest()
    this._OrderCloseAllRequestReceiverModel = new OrderCloseAllRequestModel()
    this._OrderCloseAllResponseSenderModel = new OrderCloseAllResponseModel(this.sendBuffer)
    this._OrderCloseAllResponseReceiverValue = new OrderCloseAllResponse()
    this._OrderCloseAllResponseReceiverModel = new OrderCloseAllResponseModel()
    this._OrderCloseAllByIdRequestSenderModel = new OrderCloseAllByIdRequestModel(this.sendBuffer)
    this._OrderCloseAllByIdRequestReceiverValue = new OrderCloseAllByIdRequest()
    this._OrderCloseAllByIdRequestReceiverModel = new OrderCloseAllByIdRequestModel()
    this._OrderCloseByRequestSenderModel = new OrderCloseByRequestModel(this.sendBuffer)
    this._OrderCloseByRequestReceiverValue = new OrderCloseByRequest()
    this._OrderCloseByRequestReceiverModel = new OrderCloseByRequestModel()
    this._OrderCloseByResponseSenderModel = new OrderCloseByResponseModel(this.sendBuffer)
    this._OrderCloseByResponseReceiverValue = new OrderCloseByResponse()
    this._OrderCloseByResponseReceiverModel = new OrderCloseByResponseModel()
    this._OrderCloseByAllRequestSenderModel = new OrderCloseByAllRequestModel(this.sendBuffer)
    this._OrderCloseByAllRequestReceiverValue = new OrderCloseByAllRequest()
    this._OrderCloseByAllRequestReceiverModel = new OrderCloseByAllRequestModel()
    this._OrderCloseByAllResponseSenderModel = new OrderCloseByAllResponseModel(this.sendBuffer)
    this._OrderCloseByAllResponseReceiverValue = new OrderCloseByAllResponse()
    this._OrderCloseByAllResponseReceiverModel = new OrderCloseByAllResponseModel()
    this._OrderCancelOrCloseRequestSenderModel = new OrderCancelOrCloseRequestModel(this.sendBuffer)
    this._OrderCancelOrCloseRequestReceiverValue = new OrderCancelOrCloseRequest()
    this._OrderCancelOrCloseRequestReceiverModel = new OrderCancelOrCloseRequestModel()
    this._OrderCancelOrCloseResponseSenderModel = new OrderCancelOrCloseResponseModel(this.sendBuffer)
    this._OrderCancelOrCloseResponseReceiverValue = new OrderCancelOrCloseResponse()
    this._OrderCancelOrCloseResponseReceiverModel = new OrderCancelOrCloseResponseModel()
    this._OrderCancelOrCloseAllRequestSenderModel = new OrderCancelOrCloseAllRequestModel(this.sendBuffer)
    this._OrderCancelOrCloseAllRequestReceiverValue = new OrderCancelOrCloseAllRequest()
    this._OrderCancelOrCloseAllRequestReceiverModel = new OrderCancelOrCloseAllRequestModel()
    this._OrderCancelOrCloseAllResponseSenderModel = new OrderCancelOrCloseAllResponseModel(this.sendBuffer)
    this._OrderCancelOrCloseAllResponseReceiverValue = new OrderCancelOrCloseAllResponse()
    this._OrderCancelOrCloseAllResponseReceiverModel = new OrderCancelOrCloseAllResponseModel()
    this._OrderUpdateNotifySenderModel = new OrderUpdateNotifyModel(this.sendBuffer)
    this._OrderUpdateNotifyReceiverValue = new OrderUpdateNotify()
    this._OrderUpdateNotifyReceiverModel = new OrderUpdateNotifyModel()
    this._PositionGetRequestSenderModel = new PositionGetRequestModel(this.sendBuffer)
    this._PositionGetRequestReceiverValue = new PositionGetRequest()
    this._PositionGetRequestReceiverModel = new PositionGetRequestModel()
    this._PositionGetResponseSenderModel = new PositionGetResponseModel(this.sendBuffer)
    this._PositionGetResponseReceiverValue = new PositionGetResponse()
    this._PositionGetResponseReceiverModel = new PositionGetResponseModel()
    this._PositionsGetRequestSenderModel = new PositionsGetRequestModel(this.sendBuffer)
    this._PositionsGetRequestReceiverValue = new PositionsGetRequest()
    this._PositionsGetRequestReceiverModel = new PositionsGetRequestModel()
    this._PositionsGetResponseSenderModel = new PositionsGetResponseModel(this.sendBuffer)
    this._PositionsGetResponseReceiverValue = new PositionsGetResponse()
    this._PositionsGetResponseReceiverModel = new PositionsGetResponseModel()
    this._PositionsGetAllRequestSenderModel = new PositionsGetAllRequestModel(this.sendBuffer)
    this._PositionsGetAllRequestReceiverValue = new PositionsGetAllRequest()
    this._PositionsGetAllRequestReceiverModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseSenderModel = new PositionsGetAllResponseModel(this.sendBuffer)
    this._PositionsGetAllResponseReceiverValue = new PositionsGetAllResponse()
    this._PositionsGetAllResponseReceiverModel = new PositionsGetAllResponseModel()
    this._PositionUpdateNotifySenderModel = new PositionUpdateNotifyModel(this.sendBuffer)
    this._PositionUpdateNotifyReceiverValue = new PositionUpdateNotify()
    this._PositionUpdateNotifyReceiverModel = new PositionUpdateNotifyModel()
    this._TradeHistoryRequestSenderModel = new TradeHistoryRequestModel(this.sendBuffer)
    this._TradeHistoryRequestReceiverValue = new TradeHistoryRequest()
    this._TradeHistoryRequestReceiverModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseSenderModel = new TradeHistoryResponseModel(this.sendBuffer)
    this._TradeHistoryResponseReceiverValue = new TradeHistoryResponse()
    this._TradeHistoryResponseReceiverModel = new TradeHistoryResponseModel()
    this._TradeHistoryFilterRequestSenderModel = new TradeHistoryFilterRequestModel(this.sendBuffer)
    this._TradeHistoryFilterRequestReceiverValue = new TradeHistoryFilterRequest()
    this._TradeHistoryFilterRequestReceiverModel = new TradeHistoryFilterRequestModel()
    this._TradeHistoryByIdRequestSenderModel = new TradeHistoryByIdRequestModel(this.sendBuffer)
    this._TradeHistoryByIdRequestReceiverValue = new TradeHistoryByIdRequest()
    this._TradeHistoryByIdRequestReceiverModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseSenderModel = new TradeHistoryByIdResponseModel(this.sendBuffer)
    this._TradeHistoryByIdResponseReceiverValue = new TradeHistoryByIdResponse()
    this._TradeHistoryByIdResponseReceiverModel = new TradeHistoryByIdResponseModel()
    this._TradeHistoryUpdateNotifySenderModel = new TradeHistoryUpdateNotifyModel(this.sendBuffer)
    this._TradeHistoryUpdateNotifyReceiverValue = new TradeHistoryUpdateNotify()
    this._TradeHistoryUpdateNotifyReceiverModel = new TradeHistoryUpdateNotifyModel()
    this._TestRolloverDumpRequestSenderModel = new TestRolloverDumpRequestModel(this.sendBuffer)
    this._TestRolloverDumpRequestReceiverValue = new TestRolloverDumpRequest()
    this._TestRolloverDumpRequestReceiverModel = new TestRolloverDumpRequestModel()
    this._TestRolloverDumpResponseSenderModel = new TestRolloverDumpResponseModel(this.sendBuffer)
    this._TestRolloverDumpResponseReceiverValue = new TestRolloverDumpResponse()
    this._TestRolloverDumpResponseReceiverModel = new TestRolloverDumpResponseModel()
    this._Deprecated001SenderModel = new Deprecated001Model(this.sendBuffer)
    this._Deprecated001ReceiverValue = new Deprecated001()
    this._Deprecated001ReceiverModel = new Deprecated001Model()
    this._Deprecated002SenderModel = new Deprecated002Model(this.sendBuffer)
    this._Deprecated002ReceiverValue = new Deprecated002()
    this._Deprecated002ReceiverModel = new Deprecated002Model()
    this._Deprecated003SenderModel = new Deprecated003Model(this.sendBuffer)
    this._Deprecated003ReceiverValue = new Deprecated003()
    this._Deprecated003ReceiverModel = new Deprecated003Model()
    this._Deprecated004SenderModel = new Deprecated004Model(this.sendBuffer)
    this._Deprecated004ReceiverValue = new Deprecated004()
    this._Deprecated004ReceiverModel = new Deprecated004Model()
    this._Deprecated005SenderModel = new Deprecated005Model(this.sendBuffer)
    this._Deprecated005ReceiverValue = new Deprecated005()
    this._Deprecated005ReceiverModel = new Deprecated005Model()
    this._Deprecated006SenderModel = new Deprecated006Model(this.sendBuffer)
    this._Deprecated006ReceiverValue = new Deprecated006()
    this._Deprecated006ReceiverModel = new Deprecated006Model()
    this._Deprecated007SenderModel = new Deprecated007Model(this.sendBuffer)
    this._Deprecated007ReceiverValue = new Deprecated007()
    this._Deprecated007ReceiverModel = new Deprecated007Model()
    this._Deprecated008SenderModel = new Deprecated008Model(this.sendBuffer)
    this._Deprecated008ReceiverValue = new Deprecated008()
    this._Deprecated008ReceiverModel = new Deprecated008Model()
    this._Deprecated009SenderModel = new Deprecated009Model(this.sendBuffer)
    this._Deprecated009ReceiverValue = new Deprecated009()
    this._Deprecated009ReceiverModel = new Deprecated009Model()
    this._Deprecated010SenderModel = new Deprecated010Model(this.sendBuffer)
    this._Deprecated010ReceiverValue = new Deprecated010()
    this._Deprecated010ReceiverModel = new Deprecated010Model()
    this._Deprecated011SenderModel = new Deprecated011Model(this.sendBuffer)
    this._Deprecated011ReceiverValue = new Deprecated011()
    this._Deprecated011ReceiverModel = new Deprecated011Model()
    this._Deprecated012SenderModel = new Deprecated012Model(this.sendBuffer)
    this._Deprecated012ReceiverValue = new Deprecated012()
    this._Deprecated012ReceiverModel = new Deprecated012Model()
    this._Deprecated013SenderModel = new Deprecated013Model(this.sendBuffer)
    this._Deprecated013ReceiverValue = new Deprecated013()
    this._Deprecated013ReceiverModel = new Deprecated013Model()
    this._DepositAccountRequestSenderModel = new DepositAccountRequestModel(this.sendBuffer)
    this._DepositAccountRequestReceiverValue = new DepositAccountRequest()
    this._DepositAccountRequestReceiverModel = new DepositAccountRequestModel()
    this._DepositAccountResponseSenderModel = new DepositAccountResponseModel(this.sendBuffer)
    this._DepositAccountResponseReceiverValue = new DepositAccountResponse()
    this._DepositAccountResponseReceiverModel = new DepositAccountResponseModel()
    this._CreditAccountRequestSenderModel = new CreditAccountRequestModel(this.sendBuffer)
    this._CreditAccountRequestReceiverValue = new CreditAccountRequest()
    this._CreditAccountRequestReceiverModel = new CreditAccountRequestModel()
    this._CreditAccountResponseSenderModel = new CreditAccountResponseModel(this.sendBuffer)
    this._CreditAccountResponseReceiverValue = new CreditAccountResponse()
    this._CreditAccountResponseReceiverModel = new CreditAccountResponseModel()
    this._TransferMoneyRequestSenderModel = new TransferMoneyRequestModel(this.sendBuffer)
    this._TransferMoneyRequestReceiverValue = new TransferMoneyRequest()
    this._TransferMoneyRequestReceiverModel = new TransferMoneyRequestModel()
    this._TransferMoneyResponseSenderModel = new TransferMoneyResponseModel(this.sendBuffer)
    this._TransferMoneyResponseReceiverValue = new TransferMoneyResponse()
    this._TransferMoneyResponseReceiverModel = new TransferMoneyResponseModel()
    this._TradingCalendarRegularRuleGetRequestSenderModel = new TradingCalendarRegularRuleGetRequestModel(this.sendBuffer)
    this._TradingCalendarRegularRuleGetRequestReceiverValue = new TradingCalendarRegularRuleGetRequest()
    this._TradingCalendarRegularRuleGetRequestReceiverModel = new TradingCalendarRegularRuleGetRequestModel()
    this._TradingCalendarRegularRuleGetResponseSenderModel = new TradingCalendarRegularRuleGetResponseModel(this.sendBuffer)
    this._TradingCalendarRegularRuleGetResponseReceiverValue = new TradingCalendarRegularRuleGetResponse()
    this._TradingCalendarRegularRuleGetResponseReceiverModel = new TradingCalendarRegularRuleGetResponseModel()
    this._TradingCalendarRegularRulesGetAllRequestSenderModel = new TradingCalendarRegularRulesGetAllRequestModel(this.sendBuffer)
    this._TradingCalendarRegularRulesGetAllRequestReceiverValue = new TradingCalendarRegularRulesGetAllRequest()
    this._TradingCalendarRegularRulesGetAllRequestReceiverModel = new TradingCalendarRegularRulesGetAllRequestModel()
    this._TradingCalendarRegularRulesGetAllResponseSenderModel = new TradingCalendarRegularRulesGetAllResponseModel(this.sendBuffer)
    this._TradingCalendarRegularRulesGetAllResponseReceiverValue = new TradingCalendarRegularRulesGetAllResponse()
    this._TradingCalendarRegularRulesGetAllResponseReceiverModel = new TradingCalendarRegularRulesGetAllResponseModel()
    this._TradingCalendarRegularRuleCreateRequestSenderModel = new TradingCalendarRegularRuleCreateRequestModel(this.sendBuffer)
    this._TradingCalendarRegularRuleCreateRequestReceiverValue = new TradingCalendarRegularRuleCreateRequest()
    this._TradingCalendarRegularRuleCreateRequestReceiverModel = new TradingCalendarRegularRuleCreateRequestModel()
    this._TradingCalendarRegularRuleCreateResponseSenderModel = new TradingCalendarRegularRuleCreateResponseModel(this.sendBuffer)
    this._TradingCalendarRegularRuleCreateResponseReceiverValue = new TradingCalendarRegularRuleCreateResponse()
    this._TradingCalendarRegularRuleCreateResponseReceiverModel = new TradingCalendarRegularRuleCreateResponseModel()
    this._TradingCalendarRegularRuleModifyRequestSenderModel = new TradingCalendarRegularRuleModifyRequestModel(this.sendBuffer)
    this._TradingCalendarRegularRuleModifyRequestReceiverValue = new TradingCalendarRegularRuleModifyRequest()
    this._TradingCalendarRegularRuleModifyRequestReceiverModel = new TradingCalendarRegularRuleModifyRequestModel()
    this._TradingCalendarRegularRuleModifyResponseSenderModel = new TradingCalendarRegularRuleModifyResponseModel(this.sendBuffer)
    this._TradingCalendarRegularRuleModifyResponseReceiverValue = new TradingCalendarRegularRuleModifyResponse()
    this._TradingCalendarRegularRuleModifyResponseReceiverModel = new TradingCalendarRegularRuleModifyResponseModel()
    this._TradingCalendarRegularRuleDeleteRequestSenderModel = new TradingCalendarRegularRuleDeleteRequestModel(this.sendBuffer)
    this._TradingCalendarRegularRuleDeleteRequestReceiverValue = new TradingCalendarRegularRuleDeleteRequest()
    this._TradingCalendarRegularRuleDeleteRequestReceiverModel = new TradingCalendarRegularRuleDeleteRequestModel()
    this._TradingCalendarRegularRuleDeleteResponseSenderModel = new TradingCalendarRegularRuleDeleteResponseModel(this.sendBuffer)
    this._TradingCalendarRegularRuleDeleteResponseReceiverValue = new TradingCalendarRegularRuleDeleteResponse()
    this._TradingCalendarRegularRuleDeleteResponseReceiverModel = new TradingCalendarRegularRuleDeleteResponseModel()
    this._TradingCalendarRegularRuleUpdateNotifySenderModel = new TradingCalendarRegularRuleUpdateNotifyModel(this.sendBuffer)
    this._TradingCalendarRegularRuleUpdateNotifyReceiverValue = new TradingCalendarRegularRuleUpdateNotify()
    this._TradingCalendarRegularRuleUpdateNotifyReceiverModel = new TradingCalendarRegularRuleUpdateNotifyModel()
    this._TradingCalendarOffTimeRuleGetRequestSenderModel = new TradingCalendarOffTimeRuleGetRequestModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleGetRequestReceiverValue = new TradingCalendarOffTimeRuleGetRequest()
    this._TradingCalendarOffTimeRuleGetRequestReceiverModel = new TradingCalendarOffTimeRuleGetRequestModel()
    this._TradingCalendarOffTimeRuleGetResponseSenderModel = new TradingCalendarOffTimeRuleGetResponseModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleGetResponseReceiverValue = new TradingCalendarOffTimeRuleGetResponse()
    this._TradingCalendarOffTimeRuleGetResponseReceiverModel = new TradingCalendarOffTimeRuleGetResponseModel()
    this._TradingCalendarOffTimeRulesGetAllRequestSenderModel = new TradingCalendarOffTimeRulesGetAllRequestModel(this.sendBuffer)
    this._TradingCalendarOffTimeRulesGetAllRequestReceiverValue = new TradingCalendarOffTimeRulesGetAllRequest()
    this._TradingCalendarOffTimeRulesGetAllRequestReceiverModel = new TradingCalendarOffTimeRulesGetAllRequestModel()
    this._TradingCalendarOffTimeRulesGetAllResponseSenderModel = new TradingCalendarOffTimeRulesGetAllResponseModel(this.sendBuffer)
    this._TradingCalendarOffTimeRulesGetAllResponseReceiverValue = new TradingCalendarOffTimeRulesGetAllResponse()
    this._TradingCalendarOffTimeRulesGetAllResponseReceiverModel = new TradingCalendarOffTimeRulesGetAllResponseModel()
    this._TradingCalendarOffTimeRuleCreateRequestSenderModel = new TradingCalendarOffTimeRuleCreateRequestModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleCreateRequestReceiverValue = new TradingCalendarOffTimeRuleCreateRequest()
    this._TradingCalendarOffTimeRuleCreateRequestReceiverModel = new TradingCalendarOffTimeRuleCreateRequestModel()
    this._TradingCalendarOffTimeRuleCreateResponseSenderModel = new TradingCalendarOffTimeRuleCreateResponseModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleCreateResponseReceiverValue = new TradingCalendarOffTimeRuleCreateResponse()
    this._TradingCalendarOffTimeRuleCreateResponseReceiverModel = new TradingCalendarOffTimeRuleCreateResponseModel()
    this._TradingCalendarOffTimeRuleModifyRequestSenderModel = new TradingCalendarOffTimeRuleModifyRequestModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleModifyRequestReceiverValue = new TradingCalendarOffTimeRuleModifyRequest()
    this._TradingCalendarOffTimeRuleModifyRequestReceiverModel = new TradingCalendarOffTimeRuleModifyRequestModel()
    this._TradingCalendarOffTimeRuleModifyResponseSenderModel = new TradingCalendarOffTimeRuleModifyResponseModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleModifyResponseReceiverValue = new TradingCalendarOffTimeRuleModifyResponse()
    this._TradingCalendarOffTimeRuleModifyResponseReceiverModel = new TradingCalendarOffTimeRuleModifyResponseModel()
    this._TradingCalendarOffTimeRuleDeleteRequestSenderModel = new TradingCalendarOffTimeRuleDeleteRequestModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleDeleteRequestReceiverValue = new TradingCalendarOffTimeRuleDeleteRequest()
    this._TradingCalendarOffTimeRuleDeleteRequestReceiverModel = new TradingCalendarOffTimeRuleDeleteRequestModel()
    this._TradingCalendarOffTimeRuleDeleteResponseSenderModel = new TradingCalendarOffTimeRuleDeleteResponseModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleDeleteResponseReceiverValue = new TradingCalendarOffTimeRuleDeleteResponse()
    this._TradingCalendarOffTimeRuleDeleteResponseReceiverModel = new TradingCalendarOffTimeRuleDeleteResponseModel()
    this._TradingCalendarOffTimeRuleUpdateNotifySenderModel = new TradingCalendarOffTimeRuleUpdateNotifyModel(this.sendBuffer)
    this._TradingCalendarOffTimeRuleUpdateNotifyReceiverValue = new TradingCalendarOffTimeRuleUpdateNotify()
    this._TradingCalendarOffTimeRuleUpdateNotifyReceiverModel = new TradingCalendarOffTimeRuleUpdateNotifyModel()
    this._TradingCalendarRulesGetRequestSenderModel = new TradingCalendarRulesGetRequestModel(this.sendBuffer)
    this._TradingCalendarRulesGetRequestReceiverValue = new TradingCalendarRulesGetRequest()
    this._TradingCalendarRulesGetRequestReceiverModel = new TradingCalendarRulesGetRequestModel()
    this._TradingCalendarRulesGetResponseSenderModel = new TradingCalendarRulesGetResponseModel(this.sendBuffer)
    this._TradingCalendarRulesGetResponseReceiverValue = new TradingCalendarRulesGetResponse()
    this._TradingCalendarRulesGetResponseReceiverModel = new TradingCalendarRulesGetResponseModel()
    this._TradingRouteRulesGetRequestSenderModel = new TradingRouteRulesGetRequestModel(this.sendBuffer)
    this._TradingRouteRulesGetRequestReceiverValue = new TradingRouteRulesGetRequest()
    this._TradingRouteRulesGetRequestReceiverModel = new TradingRouteRulesGetRequestModel()
    this._TradingRouteRulesGetResponseSenderModel = new TradingRouteRulesGetResponseModel(this.sendBuffer)
    this._TradingRouteRulesGetResponseReceiverValue = new TradingRouteRulesGetResponse()
    this._TradingRouteRulesGetResponseReceiverModel = new TradingRouteRulesGetResponseModel()
    this._TradingRouteRuleCreateRequestSenderModel = new TradingRouteRuleCreateRequestModel(this.sendBuffer)
    this._TradingRouteRuleCreateRequestReceiverValue = new TradingRouteRuleCreateRequest()
    this._TradingRouteRuleCreateRequestReceiverModel = new TradingRouteRuleCreateRequestModel()
    this._TradingRouteRuleCreateResponseSenderModel = new TradingRouteRuleCreateResponseModel(this.sendBuffer)
    this._TradingRouteRuleCreateResponseReceiverValue = new TradingRouteRuleCreateResponse()
    this._TradingRouteRuleCreateResponseReceiverModel = new TradingRouteRuleCreateResponseModel()
    this._TradingRouteRuleModifyRequestSenderModel = new TradingRouteRuleModifyRequestModel(this.sendBuffer)
    this._TradingRouteRuleModifyRequestReceiverValue = new TradingRouteRuleModifyRequest()
    this._TradingRouteRuleModifyRequestReceiverModel = new TradingRouteRuleModifyRequestModel()
    this._TradingRouteRuleModifyResponseSenderModel = new TradingRouteRuleModifyResponseModel(this.sendBuffer)
    this._TradingRouteRuleModifyResponseReceiverValue = new TradingRouteRuleModifyResponse()
    this._TradingRouteRuleModifyResponseReceiverModel = new TradingRouteRuleModifyResponseModel()
    this._TradingRouteRuleDeleteRequestSenderModel = new TradingRouteRuleDeleteRequestModel(this.sendBuffer)
    this._TradingRouteRuleDeleteRequestReceiverValue = new TradingRouteRuleDeleteRequest()
    this._TradingRouteRuleDeleteRequestReceiverModel = new TradingRouteRuleDeleteRequestModel()
    this._TradingRouteRuleDeleteResponseSenderModel = new TradingRouteRuleDeleteResponseModel(this.sendBuffer)
    this._TradingRouteRuleDeleteResponseReceiverValue = new TradingRouteRuleDeleteResponse()
    this._TradingRouteRuleDeleteResponseReceiverModel = new TradingRouteRuleDeleteResponseModel()
    this._TradingRouteRuleUpdateNotifySenderModel = new TradingRouteRuleUpdateNotifyModel(this.sendBuffer)
    this._TradingRouteRuleUpdateNotifyReceiverValue = new TradingRouteRuleUpdateNotify()
    this._TradingRouteRuleUpdateNotifyReceiverModel = new TradingRouteRuleUpdateNotifyModel()
    this._SymbolMapsGetRequestSenderModel = new SymbolMapsGetRequestModel(this.sendBuffer)
    this._SymbolMapsGetRequestReceiverValue = new SymbolMapsGetRequest()
    this._SymbolMapsGetRequestReceiverModel = new SymbolMapsGetRequestModel()
    this._SymbolMapsGetResponseSenderModel = new SymbolMapsGetResponseModel(this.sendBuffer)
    this._SymbolMapsGetResponseReceiverValue = new SymbolMapsGetResponse()
    this._SymbolMapsGetResponseReceiverModel = new SymbolMapsGetResponseModel()
    this._SymbolMapCreateRequestSenderModel = new SymbolMapCreateRequestModel(this.sendBuffer)
    this._SymbolMapCreateRequestReceiverValue = new SymbolMapCreateRequest()
    this._SymbolMapCreateRequestReceiverModel = new SymbolMapCreateRequestModel()
    this._SymbolMapCreateResponseSenderModel = new SymbolMapCreateResponseModel(this.sendBuffer)
    this._SymbolMapCreateResponseReceiverValue = new SymbolMapCreateResponse()
    this._SymbolMapCreateResponseReceiverModel = new SymbolMapCreateResponseModel()
    this._SymbolMapModifyRequestSenderModel = new SymbolMapModifyRequestModel(this.sendBuffer)
    this._SymbolMapModifyRequestReceiverValue = new SymbolMapModifyRequest()
    this._SymbolMapModifyRequestReceiverModel = new SymbolMapModifyRequestModel()
    this._SymbolMapModifyResponseSenderModel = new SymbolMapModifyResponseModel(this.sendBuffer)
    this._SymbolMapModifyResponseReceiverValue = new SymbolMapModifyResponse()
    this._SymbolMapModifyResponseReceiverModel = new SymbolMapModifyResponseModel()
    this._SymbolMapDeleteRequestSenderModel = new SymbolMapDeleteRequestModel(this.sendBuffer)
    this._SymbolMapDeleteRequestReceiverValue = new SymbolMapDeleteRequest()
    this._SymbolMapDeleteRequestReceiverModel = new SymbolMapDeleteRequestModel()
    this._SymbolMapDeleteResponseSenderModel = new SymbolMapDeleteResponseModel(this.sendBuffer)
    this._SymbolMapDeleteResponseReceiverValue = new SymbolMapDeleteResponse()
    this._SymbolMapDeleteResponseReceiverModel = new SymbolMapDeleteResponseModel()
    this._SymbolMapUpdateNotifySenderModel = new SymbolMapUpdateNotifyModel(this.sendBuffer)
    this._SymbolMapUpdateNotifyReceiverValue = new SymbolMapUpdateNotify()
    this._SymbolMapUpdateNotifyReceiverModel = new SymbolMapUpdateNotifyModel()
    this._GatewaysGetRequestSenderModel = new GatewaysGetRequestModel(this.sendBuffer)
    this._GatewaysGetRequestReceiverValue = new GatewaysGetRequest()
    this._GatewaysGetRequestReceiverModel = new GatewaysGetRequestModel()
    this._GatewaysGetResponseSenderModel = new GatewaysGetResponseModel(this.sendBuffer)
    this._GatewaysGetResponseReceiverValue = new GatewaysGetResponse()
    this._GatewaysGetResponseReceiverModel = new GatewaysGetResponseModel()
    this._GatewayCreateRequestSenderModel = new GatewayCreateRequestModel(this.sendBuffer)
    this._GatewayCreateRequestReceiverValue = new GatewayCreateRequest()
    this._GatewayCreateRequestReceiverModel = new GatewayCreateRequestModel()
    this._GatewayCreateResponseSenderModel = new GatewayCreateResponseModel(this.sendBuffer)
    this._GatewayCreateResponseReceiverValue = new GatewayCreateResponse()
    this._GatewayCreateResponseReceiverModel = new GatewayCreateResponseModel()
    this._GatewayModifyRequestSenderModel = new GatewayModifyRequestModel(this.sendBuffer)
    this._GatewayModifyRequestReceiverValue = new GatewayModifyRequest()
    this._GatewayModifyRequestReceiverModel = new GatewayModifyRequestModel()
    this._GatewayModifyResponseSenderModel = new GatewayModifyResponseModel(this.sendBuffer)
    this._GatewayModifyResponseReceiverValue = new GatewayModifyResponse()
    this._GatewayModifyResponseReceiverModel = new GatewayModifyResponseModel()
    this._GatewayDeleteRequestSenderModel = new GatewayDeleteRequestModel(this.sendBuffer)
    this._GatewayDeleteRequestReceiverValue = new GatewayDeleteRequest()
    this._GatewayDeleteRequestReceiverModel = new GatewayDeleteRequestModel()
    this._GatewayDeleteResponseSenderModel = new GatewayDeleteResponseModel(this.sendBuffer)
    this._GatewayDeleteResponseReceiverValue = new GatewayDeleteResponse()
    this._GatewayDeleteResponseReceiverModel = new GatewayDeleteResponseModel()
    this._GatewayUpdateNotifySenderModel = new GatewayUpdateNotifyModel(this.sendBuffer)
    this._GatewayUpdateNotifyReceiverValue = new GatewayUpdateNotify()
    this._GatewayUpdateNotifyReceiverModel = new GatewayUpdateNotifyModel()
    this._DataFeedsGetRequestSenderModel = new DataFeedsGetRequestModel(this.sendBuffer)
    this._DataFeedsGetRequestReceiverValue = new DataFeedsGetRequest()
    this._DataFeedsGetRequestReceiverModel = new DataFeedsGetRequestModel()
    this._DataFeedsGetResponseSenderModel = new DataFeedsGetResponseModel(this.sendBuffer)
    this._DataFeedsGetResponseReceiverValue = new DataFeedsGetResponse()
    this._DataFeedsGetResponseReceiverModel = new DataFeedsGetResponseModel()
    this._DataFeedCreateRequestSenderModel = new DataFeedCreateRequestModel(this.sendBuffer)
    this._DataFeedCreateRequestReceiverValue = new DataFeedCreateRequest()
    this._DataFeedCreateRequestReceiverModel = new DataFeedCreateRequestModel()
    this._DataFeedCreateResponseSenderModel = new DataFeedCreateResponseModel(this.sendBuffer)
    this._DataFeedCreateResponseReceiverValue = new DataFeedCreateResponse()
    this._DataFeedCreateResponseReceiverModel = new DataFeedCreateResponseModel()
    this._DataFeedModifyRequestSenderModel = new DataFeedModifyRequestModel(this.sendBuffer)
    this._DataFeedModifyRequestReceiverValue = new DataFeedModifyRequest()
    this._DataFeedModifyRequestReceiverModel = new DataFeedModifyRequestModel()
    this._DataFeedModifyResponseSenderModel = new DataFeedModifyResponseModel(this.sendBuffer)
    this._DataFeedModifyResponseReceiverValue = new DataFeedModifyResponse()
    this._DataFeedModifyResponseReceiverModel = new DataFeedModifyResponseModel()
    this._DataFeedDeleteRequestSenderModel = new DataFeedDeleteRequestModel(this.sendBuffer)
    this._DataFeedDeleteRequestReceiverValue = new DataFeedDeleteRequest()
    this._DataFeedDeleteRequestReceiverModel = new DataFeedDeleteRequestModel()
    this._DataFeedDeleteResponseSenderModel = new DataFeedDeleteResponseModel(this.sendBuffer)
    this._DataFeedDeleteResponseReceiverValue = new DataFeedDeleteResponse()
    this._DataFeedDeleteResponseReceiverModel = new DataFeedDeleteResponseModel()
    this._DataFeedUpdateNotifySenderModel = new DataFeedUpdateNotifyModel(this.sendBuffer)
    this._DataFeedUpdateNotifyReceiverValue = new DataFeedUpdateNotify()
    this._DataFeedUpdateNotifyReceiverModel = new DataFeedUpdateNotifyModel()
    this._ModuleSystemInfoNotifySenderModel = new ModuleSystemInfoNotifyModel(this.sendBuffer)
    this._ModuleSystemInfoNotifyReceiverValue = new ModuleSystemInfoNotify()
    this._ModuleSystemInfoNotifyReceiverModel = new ModuleSystemInfoNotifyModel()
    this._ModulesSystemInfoGetAllRequestSenderModel = new ModulesSystemInfoGetAllRequestModel(this.sendBuffer)
    this._ModulesSystemInfoGetAllRequestReceiverValue = new ModulesSystemInfoGetAllRequest()
    this._ModulesSystemInfoGetAllRequestReceiverModel = new ModulesSystemInfoGetAllRequestModel()
    this._ModulesSystemInfoGetAllResponseSenderModel = new ModulesSystemInfoGetAllResponseModel(this.sendBuffer)
    this._ModulesSystemInfoGetAllResponseReceiverValue = new ModulesSystemInfoGetAllResponse()
    this._ModulesSystemInfoGetAllResponseReceiverModel = new ModulesSystemInfoGetAllResponseModel()
    this._ModulesSystemInfoNotifySenderModel = new ModulesSystemInfoNotifyModel(this.sendBuffer)
    this._ModulesSystemInfoNotifyReceiverValue = new ModulesSystemInfoNotify()
    this._ModulesSystemInfoNotifyReceiverModel = new ModulesSystemInfoNotifyModel()
    this._ChartBarHistoryModifyRequestSenderModel = new ChartBarHistoryModifyRequestModel(this.sendBuffer)
    this._ChartBarHistoryModifyRequestReceiverValue = new ChartBarHistoryModifyRequest()
    this._ChartBarHistoryModifyRequestReceiverModel = new ChartBarHistoryModifyRequestModel()
    this._ChartBarHistoryModifyResponseSenderModel = new ChartBarHistoryModifyResponseModel(this.sendBuffer)
    this._ChartBarHistoryModifyResponseReceiverValue = new ChartBarHistoryModifyResponse()
    this._ChartBarHistoryModifyResponseReceiverModel = new ChartBarHistoryModifyResponseModel()
    this._FeedUpdateTickSnapshotRequestSenderModel = new FeedUpdateTickSnapshotRequestModel(this.sendBuffer)
    this._FeedUpdateTickSnapshotRequestReceiverValue = new FeedUpdateTickSnapshotRequest()
    this._FeedUpdateTickSnapshotRequestReceiverModel = new FeedUpdateTickSnapshotRequestModel()
    this._FeedUpdateTickSnapshotResponseSenderModel = new FeedUpdateTickSnapshotResponseModel(this.sendBuffer)
    this._FeedUpdateTickSnapshotResponseReceiverValue = new FeedUpdateTickSnapshotResponse()
    this._FeedUpdateTickSnapshotResponseReceiverModel = new FeedUpdateTickSnapshotResponseModel()
    this._FeedUpdateTickRequestSenderModel = new FeedUpdateTickRequestModel(this.sendBuffer)
    this._FeedUpdateTickRequestReceiverValue = new FeedUpdateTickRequest()
    this._FeedUpdateTickRequestReceiverModel = new FeedUpdateTickRequestModel()
    this._FeedUpdateTickResponseSenderModel = new FeedUpdateTickResponseModel(this.sendBuffer)
    this._FeedUpdateTickResponseReceiverValue = new FeedUpdateTickResponse()
    this._FeedUpdateTickResponseReceiverModel = new FeedUpdateTickResponseModel()
    this._FeedUpdateLevel2SnapshotRequestSenderModel = new FeedUpdateLevel2SnapshotRequestModel(this.sendBuffer)
    this._FeedUpdateLevel2SnapshotRequestReceiverValue = new FeedUpdateLevel2SnapshotRequest()
    this._FeedUpdateLevel2SnapshotRequestReceiverModel = new FeedUpdateLevel2SnapshotRequestModel()
    this._FeedUpdateLevel2SnapshotResponseSenderModel = new FeedUpdateLevel2SnapshotResponseModel(this.sendBuffer)
    this._FeedUpdateLevel2SnapshotResponseReceiverValue = new FeedUpdateLevel2SnapshotResponse()
    this._FeedUpdateLevel2SnapshotResponseReceiverModel = new FeedUpdateLevel2SnapshotResponseModel()
    this._FeedUpdateLevel2RequestSenderModel = new FeedUpdateLevel2RequestModel(this.sendBuffer)
    this._FeedUpdateLevel2RequestReceiverValue = new FeedUpdateLevel2Request()
    this._FeedUpdateLevel2RequestReceiverModel = new FeedUpdateLevel2RequestModel()
    this._FeedUpdateLevel2ResponseSenderModel = new FeedUpdateLevel2ResponseModel(this.sendBuffer)
    this._FeedUpdateLevel2ResponseReceiverValue = new FeedUpdateLevel2Response()
    this._FeedUpdateLevel2ResponseReceiverModel = new FeedUpdateLevel2ResponseModel()
    this._MailServerGetRequestSenderModel = new MailServerGetRequestModel(this.sendBuffer)
    this._MailServerGetRequestReceiverValue = new MailServerGetRequest()
    this._MailServerGetRequestReceiverModel = new MailServerGetRequestModel()
    this._MailServerGetResponseSenderModel = new MailServerGetResponseModel(this.sendBuffer)
    this._MailServerGetResponseReceiverValue = new MailServerGetResponse()
    this._MailServerGetResponseReceiverModel = new MailServerGetResponseModel()
    this._MailServersGetAllRequestSenderModel = new MailServersGetAllRequestModel(this.sendBuffer)
    this._MailServersGetAllRequestReceiverValue = new MailServersGetAllRequest()
    this._MailServersGetAllRequestReceiverModel = new MailServersGetAllRequestModel()
    this._MailServersGetAllResponseSenderModel = new MailServersGetAllResponseModel(this.sendBuffer)
    this._MailServersGetAllResponseReceiverValue = new MailServersGetAllResponse()
    this._MailServersGetAllResponseReceiverModel = new MailServersGetAllResponseModel()
    this._MailServerCreateRequestSenderModel = new MailServerCreateRequestModel(this.sendBuffer)
    this._MailServerCreateRequestReceiverValue = new MailServerCreateRequest()
    this._MailServerCreateRequestReceiverModel = new MailServerCreateRequestModel()
    this._MailServerCreateResponseSenderModel = new MailServerCreateResponseModel(this.sendBuffer)
    this._MailServerCreateResponseReceiverValue = new MailServerCreateResponse()
    this._MailServerCreateResponseReceiverModel = new MailServerCreateResponseModel()
    this._MailServerModifyRequestSenderModel = new MailServerModifyRequestModel(this.sendBuffer)
    this._MailServerModifyRequestReceiverValue = new MailServerModifyRequest()
    this._MailServerModifyRequestReceiverModel = new MailServerModifyRequestModel()
    this._MailServerModifyResponseSenderModel = new MailServerModifyResponseModel(this.sendBuffer)
    this._MailServerModifyResponseReceiverValue = new MailServerModifyResponse()
    this._MailServerModifyResponseReceiverModel = new MailServerModifyResponseModel()
    this._MailServerDeleteRequestSenderModel = new MailServerDeleteRequestModel(this.sendBuffer)
    this._MailServerDeleteRequestReceiverValue = new MailServerDeleteRequest()
    this._MailServerDeleteRequestReceiverModel = new MailServerDeleteRequestModel()
    this._MailServerDeleteResponseSenderModel = new MailServerDeleteResponseModel(this.sendBuffer)
    this._MailServerDeleteResponseReceiverValue = new MailServerDeleteResponse()
    this._MailServerDeleteResponseReceiverModel = new MailServerDeleteResponseModel()
    this._MailServerUpdateNotifySenderModel = new MailServerUpdateNotifyModel(this.sendBuffer)
    this._MailServerUpdateNotifyReceiverValue = new MailServerUpdateNotify()
    this._MailServerUpdateNotifyReceiverModel = new MailServerUpdateNotifyModel()
    this._AccountStatesUpdateNotifySenderModel = new AccountStatesUpdateNotifyModel(this.sendBuffer)
    this._AccountStatesUpdateNotifyReceiverValue = new AccountStatesUpdateNotify()
    this._AccountStatesUpdateNotifyReceiverModel = new AccountStatesUpdateNotifyModel()
    this._AccountStatesGetAllRequestSenderModel = new AccountStatesGetAllRequestModel(this.sendBuffer)
    this._AccountStatesGetAllRequestReceiverValue = new AccountStatesGetAllRequest()
    this._AccountStatesGetAllRequestReceiverModel = new AccountStatesGetAllRequestModel()
    this._AccountStatesGetAllResponseSenderModel = new AccountStatesGetAllResponseModel(this.sendBuffer)
    this._AccountStatesGetAllResponseReceiverValue = new AccountStatesGetAllResponse()
    this._AccountStatesGetAllResponseReceiverModel = new AccountStatesGetAllResponseModel()
    this._ModuleAuthRequestSenderModel = new ModuleAuthRequestModel(this.sendBuffer)
    this._ModuleAuthRequestReceiverValue = new ModuleAuthRequest()
    this._ModuleAuthRequestReceiverModel = new ModuleAuthRequestModel()
    this._ModuleAuthResponseSenderModel = new ModuleAuthResponseModel(this.sendBuffer)
    this._ModuleAuthResponseReceiverValue = new ModuleAuthResponse()
    this._ModuleAuthResponseReceiverModel = new ModuleAuthResponseModel()
    this._LeverageProfileGetRequestSenderModel = new LeverageProfileGetRequestModel(this.sendBuffer)
    this._LeverageProfileGetRequestReceiverValue = new LeverageProfileGetRequest()
    this._LeverageProfileGetRequestReceiverModel = new LeverageProfileGetRequestModel()
    this._LeverageProfileGetResponseSenderModel = new LeverageProfileGetResponseModel(this.sendBuffer)
    this._LeverageProfileGetResponseReceiverValue = new LeverageProfileGetResponse()
    this._LeverageProfileGetResponseReceiverModel = new LeverageProfileGetResponseModel()
    this._LeverageProfilesGetAllRequestSenderModel = new LeverageProfilesGetAllRequestModel(this.sendBuffer)
    this._LeverageProfilesGetAllRequestReceiverValue = new LeverageProfilesGetAllRequest()
    this._LeverageProfilesGetAllRequestReceiverModel = new LeverageProfilesGetAllRequestModel()
    this._LeverageProfilesGetAllResponseSenderModel = new LeverageProfilesGetAllResponseModel(this.sendBuffer)
    this._LeverageProfilesGetAllResponseReceiverValue = new LeverageProfilesGetAllResponse()
    this._LeverageProfilesGetAllResponseReceiverModel = new LeverageProfilesGetAllResponseModel()
    this._LeverageProfileCreateRequestSenderModel = new LeverageProfileCreateRequestModel(this.sendBuffer)
    this._LeverageProfileCreateRequestReceiverValue = new LeverageProfileCreateRequest()
    this._LeverageProfileCreateRequestReceiverModel = new LeverageProfileCreateRequestModel()
    this._LeverageProfileCreateResponseSenderModel = new LeverageProfileCreateResponseModel(this.sendBuffer)
    this._LeverageProfileCreateResponseReceiverValue = new LeverageProfileCreateResponse()
    this._LeverageProfileCreateResponseReceiverModel = new LeverageProfileCreateResponseModel()
    this._LeverageProfileModifyRequestSenderModel = new LeverageProfileModifyRequestModel(this.sendBuffer)
    this._LeverageProfileModifyRequestReceiverValue = new LeverageProfileModifyRequest()
    this._LeverageProfileModifyRequestReceiverModel = new LeverageProfileModifyRequestModel()
    this._LeverageProfileModifyResponseSenderModel = new LeverageProfileModifyResponseModel(this.sendBuffer)
    this._LeverageProfileModifyResponseReceiverValue = new LeverageProfileModifyResponse()
    this._LeverageProfileModifyResponseReceiverModel = new LeverageProfileModifyResponseModel()
    this._LeverageProfileDeleteRequestSenderModel = new LeverageProfileDeleteRequestModel(this.sendBuffer)
    this._LeverageProfileDeleteRequestReceiverValue = new LeverageProfileDeleteRequest()
    this._LeverageProfileDeleteRequestReceiverModel = new LeverageProfileDeleteRequestModel()
    this._LeverageProfileDeleteResponseSenderModel = new LeverageProfileDeleteResponseModel(this.sendBuffer)
    this._LeverageProfileDeleteResponseReceiverValue = new LeverageProfileDeleteResponse()
    this._LeverageProfileDeleteResponseReceiverModel = new LeverageProfileDeleteResponseModel()
    this._LeverageProfileUpdateNotifySenderModel = new LeverageProfileUpdateNotifyModel(this.sendBuffer)
    this._LeverageProfileUpdateNotifyReceiverValue = new LeverageProfileUpdateNotify()
    this._LeverageProfileUpdateNotifyReceiverModel = new LeverageProfileUpdateNotifyModel()
    this._MarginRateProfileGetRequestSenderModel = new MarginRateProfileGetRequestModel(this.sendBuffer)
    this._MarginRateProfileGetRequestReceiverValue = new MarginRateProfileGetRequest()
    this._MarginRateProfileGetRequestReceiverModel = new MarginRateProfileGetRequestModel()
    this._MarginRateProfileGetResponseSenderModel = new MarginRateProfileGetResponseModel(this.sendBuffer)
    this._MarginRateProfileGetResponseReceiverValue = new MarginRateProfileGetResponse()
    this._MarginRateProfileGetResponseReceiverModel = new MarginRateProfileGetResponseModel()
    this._MarginRateProfilesGetAllRequestSenderModel = new MarginRateProfilesGetAllRequestModel(this.sendBuffer)
    this._MarginRateProfilesGetAllRequestReceiverValue = new MarginRateProfilesGetAllRequest()
    this._MarginRateProfilesGetAllRequestReceiverModel = new MarginRateProfilesGetAllRequestModel()
    this._MarginRateProfilesGetAllResponseSenderModel = new MarginRateProfilesGetAllResponseModel(this.sendBuffer)
    this._MarginRateProfilesGetAllResponseReceiverValue = new MarginRateProfilesGetAllResponse()
    this._MarginRateProfilesGetAllResponseReceiverModel = new MarginRateProfilesGetAllResponseModel()
    this._MarginRateProfileCreateRequestSenderModel = new MarginRateProfileCreateRequestModel(this.sendBuffer)
    this._MarginRateProfileCreateRequestReceiverValue = new MarginRateProfileCreateRequest()
    this._MarginRateProfileCreateRequestReceiverModel = new MarginRateProfileCreateRequestModel()
    this._MarginRateProfileCreateResponseSenderModel = new MarginRateProfileCreateResponseModel(this.sendBuffer)
    this._MarginRateProfileCreateResponseReceiverValue = new MarginRateProfileCreateResponse()
    this._MarginRateProfileCreateResponseReceiverModel = new MarginRateProfileCreateResponseModel()
    this._MarginRateProfileModifyRequestSenderModel = new MarginRateProfileModifyRequestModel(this.sendBuffer)
    this._MarginRateProfileModifyRequestReceiverValue = new MarginRateProfileModifyRequest()
    this._MarginRateProfileModifyRequestReceiverModel = new MarginRateProfileModifyRequestModel()
    this._MarginRateProfileModifyResponseSenderModel = new MarginRateProfileModifyResponseModel(this.sendBuffer)
    this._MarginRateProfileModifyResponseReceiverValue = new MarginRateProfileModifyResponse()
    this._MarginRateProfileModifyResponseReceiverModel = new MarginRateProfileModifyResponseModel()
    this._MarginRateProfileDeleteRequestSenderModel = new MarginRateProfileDeleteRequestModel(this.sendBuffer)
    this._MarginRateProfileDeleteRequestReceiverValue = new MarginRateProfileDeleteRequest()
    this._MarginRateProfileDeleteRequestReceiverModel = new MarginRateProfileDeleteRequestModel()
    this._MarginRateProfileDeleteResponseSenderModel = new MarginRateProfileDeleteResponseModel(this.sendBuffer)
    this._MarginRateProfileDeleteResponseReceiverValue = new MarginRateProfileDeleteResponse()
    this._MarginRateProfileDeleteResponseReceiverModel = new MarginRateProfileDeleteResponseModel()
    this._MarginRateProfileUpdateNotifySenderModel = new MarginRateProfileUpdateNotifyModel(this.sendBuffer)
    this._MarginRateProfileUpdateNotifyReceiverValue = new MarginRateProfileUpdateNotify()
    this._MarginRateProfileUpdateNotifyReceiverModel = new MarginRateProfileUpdateNotifyModel()
    this._NewsProfileGetRequestSenderModel = new NewsProfileGetRequestModel(this.sendBuffer)
    this._NewsProfileGetRequestReceiverValue = new NewsProfileGetRequest()
    this._NewsProfileGetRequestReceiverModel = new NewsProfileGetRequestModel()
    this._NewsProfileGetResponseSenderModel = new NewsProfileGetResponseModel(this.sendBuffer)
    this._NewsProfileGetResponseReceiverValue = new NewsProfileGetResponse()
    this._NewsProfileGetResponseReceiverModel = new NewsProfileGetResponseModel()
    this._NewsProfilesGetAllRequestSenderModel = new NewsProfilesGetAllRequestModel(this.sendBuffer)
    this._NewsProfilesGetAllRequestReceiverValue = new NewsProfilesGetAllRequest()
    this._NewsProfilesGetAllRequestReceiverModel = new NewsProfilesGetAllRequestModel()
    this._NewsProfilesGetAllResponseSenderModel = new NewsProfilesGetAllResponseModel(this.sendBuffer)
    this._NewsProfilesGetAllResponseReceiverValue = new NewsProfilesGetAllResponse()
    this._NewsProfilesGetAllResponseReceiverModel = new NewsProfilesGetAllResponseModel()
    this._NewsProfileCreateRequestSenderModel = new NewsProfileCreateRequestModel(this.sendBuffer)
    this._NewsProfileCreateRequestReceiverValue = new NewsProfileCreateRequest()
    this._NewsProfileCreateRequestReceiverModel = new NewsProfileCreateRequestModel()
    this._NewsProfileCreateResponseSenderModel = new NewsProfileCreateResponseModel(this.sendBuffer)
    this._NewsProfileCreateResponseReceiverValue = new NewsProfileCreateResponse()
    this._NewsProfileCreateResponseReceiverModel = new NewsProfileCreateResponseModel()
    this._NewsProfileModifyRequestSenderModel = new NewsProfileModifyRequestModel(this.sendBuffer)
    this._NewsProfileModifyRequestReceiverValue = new NewsProfileModifyRequest()
    this._NewsProfileModifyRequestReceiverModel = new NewsProfileModifyRequestModel()
    this._NewsProfileModifyResponseSenderModel = new NewsProfileModifyResponseModel(this.sendBuffer)
    this._NewsProfileModifyResponseReceiverValue = new NewsProfileModifyResponse()
    this._NewsProfileModifyResponseReceiverModel = new NewsProfileModifyResponseModel()
    this._NewsProfileDeleteRequestSenderModel = new NewsProfileDeleteRequestModel(this.sendBuffer)
    this._NewsProfileDeleteRequestReceiverValue = new NewsProfileDeleteRequest()
    this._NewsProfileDeleteRequestReceiverModel = new NewsProfileDeleteRequestModel()
    this._NewsProfileDeleteResponseSenderModel = new NewsProfileDeleteResponseModel(this.sendBuffer)
    this._NewsProfileDeleteResponseReceiverValue = new NewsProfileDeleteResponse()
    this._NewsProfileDeleteResponseReceiverModel = new NewsProfileDeleteResponseModel()
    this._NewsProfileUpdateNotifySenderModel = new NewsProfileUpdateNotifyModel(this.sendBuffer)
    this._NewsProfileUpdateNotifyReceiverValue = new NewsProfileUpdateNotify()
    this._NewsProfileUpdateNotifyReceiverModel = new NewsProfileUpdateNotifyModel()
    this._NewsProfileImportRequestSenderModel = new NewsProfileImportRequestModel(this.sendBuffer)
    this._NewsProfileImportRequestReceiverValue = new NewsProfileImportRequest()
    this._NewsProfileImportRequestReceiverModel = new NewsProfileImportRequestModel()
    this._NewsProfileImportResponseSenderModel = new NewsProfileImportResponseModel(this.sendBuffer)
    this._NewsProfileImportResponseReceiverValue = new NewsProfileImportResponse()
    this._NewsProfileImportResponseReceiverModel = new NewsProfileImportResponseModel()
    this._TickHistoryRequestSenderModel = new TickHistoryRequestModel(this.sendBuffer)
    this._TickHistoryRequestReceiverValue = new TickHistoryRequest()
    this._TickHistoryRequestReceiverModel = new TickHistoryRequestModel()
    this._TickHistoryResponseSenderModel = new TickHistoryResponseModel(this.sendBuffer)
    this._TickHistoryResponseReceiverValue = new TickHistoryResponse()
    this._TickHistoryResponseReceiverModel = new TickHistoryResponseModel()
    this._EmailTemplateGetRequestSenderModel = new EmailTemplateGetRequestModel(this.sendBuffer)
    this._EmailTemplateGetRequestReceiverValue = new EmailTemplateGetRequest()
    this._EmailTemplateGetRequestReceiverModel = new EmailTemplateGetRequestModel()
    this._EmailTemplateGetResponseSenderModel = new EmailTemplateGetResponseModel(this.sendBuffer)
    this._EmailTemplateGetResponseReceiverValue = new EmailTemplateGetResponse()
    this._EmailTemplateGetResponseReceiverModel = new EmailTemplateGetResponseModel()
    this._EmailTemplatesGetAllRequestSenderModel = new EmailTemplatesGetAllRequestModel(this.sendBuffer)
    this._EmailTemplatesGetAllRequestReceiverValue = new EmailTemplatesGetAllRequest()
    this._EmailTemplatesGetAllRequestReceiverModel = new EmailTemplatesGetAllRequestModel()
    this._EmailTemplatesGetAllResponseSenderModel = new EmailTemplatesGetAllResponseModel(this.sendBuffer)
    this._EmailTemplatesGetAllResponseReceiverValue = new EmailTemplatesGetAllResponse()
    this._EmailTemplatesGetAllResponseReceiverModel = new EmailTemplatesGetAllResponseModel()
    this._EmailTemplateCreateRequestSenderModel = new EmailTemplateCreateRequestModel(this.sendBuffer)
    this._EmailTemplateCreateRequestReceiverValue = new EmailTemplateCreateRequest()
    this._EmailTemplateCreateRequestReceiverModel = new EmailTemplateCreateRequestModel()
    this._EmailTemplateCreateResponseSenderModel = new EmailTemplateCreateResponseModel(this.sendBuffer)
    this._EmailTemplateCreateResponseReceiverValue = new EmailTemplateCreateResponse()
    this._EmailTemplateCreateResponseReceiverModel = new EmailTemplateCreateResponseModel()
    this._EmailTemplateModifyRequestSenderModel = new EmailTemplateModifyRequestModel(this.sendBuffer)
    this._EmailTemplateModifyRequestReceiverValue = new EmailTemplateModifyRequest()
    this._EmailTemplateModifyRequestReceiverModel = new EmailTemplateModifyRequestModel()
    this._EmailTemplateModifyResponseSenderModel = new EmailTemplateModifyResponseModel(this.sendBuffer)
    this._EmailTemplateModifyResponseReceiverValue = new EmailTemplateModifyResponse()
    this._EmailTemplateModifyResponseReceiverModel = new EmailTemplateModifyResponseModel()
    this._EmailTemplateDeleteRequestSenderModel = new EmailTemplateDeleteRequestModel(this.sendBuffer)
    this._EmailTemplateDeleteRequestReceiverValue = new EmailTemplateDeleteRequest()
    this._EmailTemplateDeleteRequestReceiverModel = new EmailTemplateDeleteRequestModel()
    this._EmailTemplateDeleteResponseSenderModel = new EmailTemplateDeleteResponseModel(this.sendBuffer)
    this._EmailTemplateDeleteResponseReceiverValue = new EmailTemplateDeleteResponse()
    this._EmailTemplateDeleteResponseReceiverModel = new EmailTemplateDeleteResponseModel()
    this._EmailTemplateUpdateNotifySenderModel = new EmailTemplateUpdateNotifyModel(this.sendBuffer)
    this._EmailTemplateUpdateNotifyReceiverValue = new EmailTemplateUpdateNotify()
    this._EmailTemplateUpdateNotifyReceiverModel = new EmailTemplateUpdateNotifyModel()
    this._OrderStateSubscribeRequestSenderModel = new OrderStateSubscribeRequestModel(this.sendBuffer)
    this._OrderStateSubscribeRequestReceiverValue = new OrderStateSubscribeRequest()
    this._OrderStateSubscribeRequestReceiverModel = new OrderStateSubscribeRequestModel()
    this._OrderStateSubscribeResponseSenderModel = new OrderStateSubscribeResponseModel(this.sendBuffer)
    this._OrderStateSubscribeResponseReceiverValue = new OrderStateSubscribeResponse()
    this._OrderStateSubscribeResponseReceiverModel = new OrderStateSubscribeResponseModel()
    this._OrderStateUpdateSubscriptionRequestSenderModel = new OrderStateUpdateSubscriptionRequestModel(this.sendBuffer)
    this._OrderStateUpdateSubscriptionRequestReceiverValue = new OrderStateUpdateSubscriptionRequest()
    this._OrderStateUpdateSubscriptionRequestReceiverModel = new OrderStateUpdateSubscriptionRequestModel()
    this._OrderStateUpdateSubscriptionResponseSenderModel = new OrderStateUpdateSubscriptionResponseModel(this.sendBuffer)
    this._OrderStateUpdateSubscriptionResponseReceiverValue = new OrderStateUpdateSubscriptionResponse()
    this._OrderStateUpdateSubscriptionResponseReceiverModel = new OrderStateUpdateSubscriptionResponseModel()
    this._OrderStateUnsubscribeRequestSenderModel = new OrderStateUnsubscribeRequestModel(this.sendBuffer)
    this._OrderStateUnsubscribeRequestReceiverValue = new OrderStateUnsubscribeRequest()
    this._OrderStateUnsubscribeRequestReceiverModel = new OrderStateUnsubscribeRequestModel()
    this._OrderStateUnsubscribeResponseSenderModel = new OrderStateUnsubscribeResponseModel(this.sendBuffer)
    this._OrderStateUnsubscribeResponseReceiverValue = new OrderStateUnsubscribeResponse()
    this._OrderStateUnsubscribeResponseReceiverModel = new OrderStateUnsubscribeResponseModel()
    this._OrderStateNotifySenderModel = new OrderStateNotifyModel(this.sendBuffer)
    this._OrderStateNotifyReceiverValue = new OrderStateNotify()
    this._OrderStateNotifyReceiverModel = new OrderStateNotifyModel()
    this._RequestMonitoringSubscribeRequestSenderModel = new RequestMonitoringSubscribeRequestModel(this.sendBuffer)
    this._RequestMonitoringSubscribeRequestReceiverValue = new RequestMonitoringSubscribeRequest()
    this._RequestMonitoringSubscribeRequestReceiverModel = new RequestMonitoringSubscribeRequestModel()
    this._RequestMonitoringSubscribeResponseSenderModel = new RequestMonitoringSubscribeResponseModel(this.sendBuffer)
    this._RequestMonitoringSubscribeResponseReceiverValue = new RequestMonitoringSubscribeResponse()
    this._RequestMonitoringSubscribeResponseReceiverModel = new RequestMonitoringSubscribeResponseModel()
    this._RequestMonitoringUnsubscribeRequestSenderModel = new RequestMonitoringUnsubscribeRequestModel(this.sendBuffer)
    this._RequestMonitoringUnsubscribeRequestReceiverValue = new RequestMonitoringUnsubscribeRequest()
    this._RequestMonitoringUnsubscribeRequestReceiverModel = new RequestMonitoringUnsubscribeRequestModel()
    this._RequestMonitoringUnsubscribeResponseSenderModel = new RequestMonitoringUnsubscribeResponseModel(this.sendBuffer)
    this._RequestMonitoringUnsubscribeResponseReceiverValue = new RequestMonitoringUnsubscribeResponse()
    this._RequestMonitoringUnsubscribeResponseReceiverModel = new RequestMonitoringUnsubscribeResponseModel()
    this._RequestMonitoringRequestUpdateNotifySenderModel = new RequestMonitoringRequestUpdateNotifyModel(this.sendBuffer)
    this._RequestMonitoringRequestUpdateNotifyReceiverValue = new RequestMonitoringRequestUpdateNotify()
    this._RequestMonitoringRequestUpdateNotifyReceiverModel = new RequestMonitoringRequestUpdateNotifyModel()
    this._FilledOrdersSummaryInfoSubscribeRequestSenderModel = new FilledOrdersSummaryInfoSubscribeRequestModel(this.sendBuffer)
    this._FilledOrdersSummaryInfoSubscribeRequestReceiverValue = new FilledOrdersSummaryInfoSubscribeRequest()
    this._FilledOrdersSummaryInfoSubscribeRequestReceiverModel = new FilledOrdersSummaryInfoSubscribeRequestModel()
    this._FilledOrdersSummaryInfoSubscribeResponseSenderModel = new FilledOrdersSummaryInfoSubscribeResponseModel(this.sendBuffer)
    this._FilledOrdersSummaryInfoSubscribeResponseReceiverValue = new FilledOrdersSummaryInfoSubscribeResponse()
    this._FilledOrdersSummaryInfoSubscribeResponseReceiverModel = new FilledOrdersSummaryInfoSubscribeResponseModel()
    this._FilledOrdersSummaryInfoNotifySenderModel = new FilledOrdersSummaryInfoNotifyModel(this.sendBuffer)
    this._FilledOrdersSummaryInfoNotifyReceiverValue = new FilledOrdersSummaryInfoNotify()
    this._FilledOrdersSummaryInfoNotifyReceiverModel = new FilledOrdersSummaryInfoNotifyModel()
    this._FilledOrdersSummaryInfoUpdateSubscriptionRequestSenderModel = new FilledOrdersSummaryInfoUpdateSubscriptionRequestModel(this.sendBuffer)
    this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverValue = new FilledOrdersSummaryInfoUpdateSubscriptionRequest()
    this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverModel = new FilledOrdersSummaryInfoUpdateSubscriptionRequestModel()
    this._FilledOrdersSummaryInfoUnsubscribeRequestSenderModel = new FilledOrdersSummaryInfoUnsubscribeRequestModel(this.sendBuffer)
    this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverValue = new FilledOrdersSummaryInfoUnsubscribeRequest()
    this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverModel = new FilledOrdersSummaryInfoUnsubscribeRequestModel()
    this._FilledOrdersSummaryInfoUnsubscribeResponseSenderModel = new FilledOrdersSummaryInfoUnsubscribeResponseModel(this.sendBuffer)
    this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverValue = new FilledOrdersSummaryInfoUnsubscribeResponse()
    this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverModel = new FilledOrdersSummaryInfoUnsubscribeResponseModel()
    this._TradeEventsNotifySenderModel = new TradeEventsNotifyModel(this.sendBuffer)
    this._TradeEventsNotifyReceiverValue = new TradeEventsNotify()
    this._TradeEventsNotifyReceiverModel = new TradeEventsNotifyModel()
    this._TradeEventsGetRequestSenderModel = new TradeEventsGetRequestModel(this.sendBuffer)
    this._TradeEventsGetRequestReceiverValue = new TradeEventsGetRequest()
    this._TradeEventsGetRequestReceiverModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseSenderModel = new TradeEventsGetResponseModel(this.sendBuffer)
    this._TradeEventsGetResponseReceiverValue = new TradeEventsGetResponse()
    this._TradeEventsGetResponseReceiverModel = new TradeEventsGetResponseModel()
    this._JournalRequestSenderModel = new JournalRequestModel(this.sendBuffer)
    this._JournalRequestReceiverValue = new JournalRequest()
    this._JournalRequestReceiverModel = new JournalRequestModel()
    this._JournalResponseSenderModel = new JournalResponseModel(this.sendBuffer)
    this._JournalResponseReceiverValue = new JournalResponse()
    this._JournalResponseReceiverModel = new JournalResponseModel()
    this._ClientReportsListGetRequestSenderModel = new ClientReportsListGetRequestModel(this.sendBuffer)
    this._ClientReportsListGetRequestReceiverValue = new ClientReportsListGetRequest()
    this._ClientReportsListGetRequestReceiverModel = new ClientReportsListGetRequestModel()
    this._ClientReportsListGetResponseSenderModel = new ClientReportsListGetResponseModel(this.sendBuffer)
    this._ClientReportsListGetResponseReceiverValue = new ClientReportsListGetResponse()
    this._ClientReportsListGetResponseReceiverModel = new ClientReportsListGetResponseModel()
    this._ClientReportGetRequestSenderModel = new ClientReportGetRequestModel(this.sendBuffer)
    this._ClientReportGetRequestReceiverValue = new ClientReportGetRequest()
    this._ClientReportGetRequestReceiverModel = new ClientReportGetRequestModel()
    this._ClientReportGetResponseSenderModel = new ClientReportGetResponseModel(this.sendBuffer)
    this._ClientReportGetResponseReceiverValue = new ClientReportGetResponse()
    this._ClientReportGetResponseReceiverModel = new ClientReportGetResponseModel()
    this._ClientReportsSendRequestSenderModel = new ClientReportsSendRequestModel(this.sendBuffer)
    this._ClientReportsSendRequestReceiverValue = new ClientReportsSendRequest()
    this._ClientReportsSendRequestReceiverModel = new ClientReportsSendRequestModel()
    this._ClientReportsSendResponseSenderModel = new ClientReportsSendResponseModel(this.sendBuffer)
    this._ClientReportsSendResponseReceiverValue = new ClientReportsSendResponse()
    this._ClientReportsSendResponseReceiverModel = new ClientReportsSendResponseModel()
    this._ProfitClosedGetRequestSenderModel = new ProfitClosedGetRequestModel(this.sendBuffer)
    this._ProfitClosedGetRequestReceiverValue = new ProfitClosedGetRequest()
    this._ProfitClosedGetRequestReceiverModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseSenderModel = new ProfitClosedGetResponseModel(this.sendBuffer)
    this._ProfitClosedGetResponseReceiverValue = new ProfitClosedGetResponse()
    this._ProfitClosedGetResponseReceiverModel = new ProfitClosedGetResponseModel()
    this._ChartBarsImportRequestSenderModel = new ChartBarsImportRequestModel(this.sendBuffer)
    this._ChartBarsImportRequestReceiverValue = new ChartBarsImportRequest()
    this._ChartBarsImportRequestReceiverModel = new ChartBarsImportRequestModel()
    this._ChartBarsImportResponseSenderModel = new ChartBarsImportResponseModel(this.sendBuffer)
    this._ChartBarsImportResponseReceiverValue = new ChartBarsImportResponse()
    this._ChartBarsImportResponseReceiverModel = new ChartBarsImportResponseModel()
    this._ChartBarsImportNotifySenderModel = new ChartBarsImportNotifyModel(this.sendBuffer)
    this._ChartBarsImportNotifyReceiverValue = new ChartBarsImportNotify()
    this._ChartBarsImportNotifyReceiverModel = new ChartBarsImportNotifyModel()
    this._JournalBusinessRequestSenderModel = new JournalBusinessRequestModel(this.sendBuffer)
    this._JournalBusinessRequestReceiverValue = new JournalBusinessRequest()
    this._JournalBusinessRequestReceiverModel = new JournalBusinessRequestModel()
    this._JournalBusinessResponseSenderModel = new JournalBusinessResponseModel(this.sendBuffer)
    this._JournalBusinessResponseReceiverValue = new JournalBusinessResponse()
    this._JournalBusinessResponseReceiverModel = new JournalBusinessResponseModel()
    this._AccountsGetPageRequestSenderModel = new AccountsGetPageRequestModel(this.sendBuffer)
    this._AccountsGetPageRequestReceiverValue = new AccountsGetPageRequest()
    this._AccountsGetPageRequestReceiverModel = new AccountsGetPageRequestModel()
    this._AccountsGetPageResponseSenderModel = new AccountsGetPageResponseModel(this.sendBuffer)
    this._AccountsGetPageResponseReceiverValue = new AccountsGetPageResponse()
    this._AccountsGetPageResponseReceiverModel = new AccountsGetPageResponseModel()
    this._HeartbeatNotifySenderModel = new HeartbeatNotifyModel(this.sendBuffer)
    this._HeartbeatNotifyReceiverValue = new HeartbeatNotify()
    this._HeartbeatNotifyReceiverModel = new HeartbeatNotifyModel()
    this._OrdersClosedSummariesGetRequestSenderModel = new OrdersClosedSummariesGetRequestModel(this.sendBuffer)
    this._OrdersClosedSummariesGetRequestReceiverValue = new OrdersClosedSummariesGetRequest()
    this._OrdersClosedSummariesGetRequestReceiverModel = new OrdersClosedSummariesGetRequestModel()
    this._OrdersClosedSummariesGetResponseSenderModel = new OrdersClosedSummariesGetResponseModel(this.sendBuffer)
    this._OrdersClosedSummariesGetResponseReceiverValue = new OrdersClosedSummariesGetResponse()
    this._OrdersClosedSummariesGetResponseReceiverModel = new OrdersClosedSummariesGetResponseModel()
    this._OrdersGetPageRequestSenderModel = new OrdersGetPageRequestModel(this.sendBuffer)
    this._OrdersGetPageRequestReceiverValue = new OrdersGetPageRequest()
    this._OrdersGetPageRequestReceiverModel = new OrdersGetPageRequestModel()
    this._OrdersGetPageResponseSenderModel = new OrdersGetPageResponseModel(this.sendBuffer)
    this._OrdersGetPageResponseReceiverValue = new OrdersGetPageResponse()
    this._OrdersGetPageResponseReceiverModel = new OrdersGetPageResponseModel()
    this._StorageUniversalNotifySenderModel = new StorageUniversalNotifyModel(this.sendBuffer)
    this._StorageUniversalNotifyReceiverValue = new StorageUniversalNotify()
    this._StorageUniversalNotifyReceiverModel = new StorageUniversalNotifyModel()
    this._ClientLoginInfoGetRequestSenderModel = new ClientLoginInfoGetRequestModel(this.sendBuffer)
    this._ClientLoginInfoGetRequestReceiverValue = new ClientLoginInfoGetRequest()
    this._ClientLoginInfoGetRequestReceiverModel = new ClientLoginInfoGetRequestModel()
    this._ClientLoginInfoGetResponseSenderModel = new ClientLoginInfoGetResponseModel(this.sendBuffer)
    this._ClientLoginInfoGetResponseReceiverValue = new ClientLoginInfoGetResponse()
    this._ClientLoginInfoGetResponseReceiverModel = new ClientLoginInfoGetResponseModel()
    this._AccountGroupExistsGetRequestSenderModel = new AccountGroupExistsGetRequestModel(this.sendBuffer)
    this._AccountGroupExistsGetRequestReceiverValue = new AccountGroupExistsGetRequest()
    this._AccountGroupExistsGetRequestReceiverModel = new AccountGroupExistsGetRequestModel()
    this._AccountGroupExistsGetResponseSenderModel = new AccountGroupExistsGetResponseModel(this.sendBuffer)
    this._AccountGroupExistsGetResponseReceiverValue = new AccountGroupExistsGetResponse()
    this._AccountGroupExistsGetResponseReceiverModel = new AccountGroupExistsGetResponseModel()
    this._LoginWithPasswordRequestSenderModel = new LoginWithPasswordRequestModel(this.sendBuffer)
    this._LoginWithPasswordRequestReceiverValue = new LoginWithPasswordRequest()
    this._LoginWithPasswordRequestReceiverModel = new LoginWithPasswordRequestModel()
    this._LoginWithPasswordResponseSenderModel = new LoginWithPasswordResponseModel(this.sendBuffer)
    this._LoginWithPasswordResponseReceiverValue = new LoginWithPasswordResponse()
    this._LoginWithPasswordResponseReceiverModel = new LoginWithPasswordResponseModel()
    this._LoginWithTokenRequestSenderModel = new LoginWithTokenRequestModel(this.sendBuffer)
    this._LoginWithTokenRequestReceiverValue = new LoginWithTokenRequest()
    this._LoginWithTokenRequestReceiverModel = new LoginWithTokenRequestModel()
    this._LoginWithTokenResponseSenderModel = new LoginWithTokenResponseModel(this.sendBuffer)
    this._LoginWithTokenResponseReceiverValue = new LoginWithTokenResponse()
    this._LoginWithTokenResponseReceiverModel = new LoginWithTokenResponseModel()
    this._RefreshTokenRequestSenderModel = new RefreshTokenRequestModel(this.sendBuffer)
    this._RefreshTokenRequestReceiverValue = new RefreshTokenRequest()
    this._RefreshTokenRequestReceiverModel = new RefreshTokenRequestModel()
    this._RefreshTokenResponseSenderModel = new RefreshTokenResponseModel(this.sendBuffer)
    this._RefreshTokenResponseReceiverValue = new RefreshTokenResponse()
    this._RefreshTokenResponseReceiverModel = new RefreshTokenResponseModel()
    this._PersonGetRequestSenderModel = new PersonGetRequestModel(this.sendBuffer)
    this._PersonGetRequestReceiverValue = new PersonGetRequest()
    this._PersonGetRequestReceiverModel = new PersonGetRequestModel()
    this._PersonGetResponseSenderModel = new PersonGetResponseModel(this.sendBuffer)
    this._PersonGetResponseReceiverValue = new PersonGetResponse()
    this._PersonGetResponseReceiverModel = new PersonGetResponseModel()
    this._PersonsGetAllRequestSenderModel = new PersonsGetAllRequestModel(this.sendBuffer)
    this._PersonsGetAllRequestReceiverValue = new PersonsGetAllRequest()
    this._PersonsGetAllRequestReceiverModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseSenderModel = new PersonsGetAllResponseModel(this.sendBuffer)
    this._PersonsGetAllResponseReceiverValue = new PersonsGetAllResponse()
    this._PersonsGetAllResponseReceiverModel = new PersonsGetAllResponseModel()
    this._PersonCreateRequestSenderModel = new PersonCreateRequestModel(this.sendBuffer)
    this._PersonCreateRequestReceiverValue = new PersonCreateRequest()
    this._PersonCreateRequestReceiverModel = new PersonCreateRequestModel()
    this._PersonCreateResponseSenderModel = new PersonCreateResponseModel(this.sendBuffer)
    this._PersonCreateResponseReceiverValue = new PersonCreateResponse()
    this._PersonCreateResponseReceiverModel = new PersonCreateResponseModel()
    this._PersonModifyRequestSenderModel = new PersonModifyRequestModel(this.sendBuffer)
    this._PersonModifyRequestReceiverValue = new PersonModifyRequest()
    this._PersonModifyRequestReceiverModel = new PersonModifyRequestModel()
    this._PersonModifyResponseSenderModel = new PersonModifyResponseModel(this.sendBuffer)
    this._PersonModifyResponseReceiverValue = new PersonModifyResponse()
    this._PersonModifyResponseReceiverModel = new PersonModifyResponseModel()
    this._PersonUpdateNotifySenderModel = new PersonUpdateNotifyModel(this.sendBuffer)
    this._PersonUpdateNotifyReceiverValue = new PersonUpdateNotify()
    this._PersonUpdateNotifyReceiverModel = new PersonUpdateNotifyModel()
    this._SecuritySettingsGetRequestSenderModel = new SecuritySettingsGetRequestModel(this.sendBuffer)
    this._SecuritySettingsGetRequestReceiverValue = new SecuritySettingsGetRequest()
    this._SecuritySettingsGetRequestReceiverModel = new SecuritySettingsGetRequestModel()
    this._SecuritySettingsGetResponseSenderModel = new SecuritySettingsGetResponseModel(this.sendBuffer)
    this._SecuritySettingsGetResponseReceiverValue = new SecuritySettingsGetResponse()
    this._SecuritySettingsGetResponseReceiverModel = new SecuritySettingsGetResponseModel()
    this._SecuritySettingsGetAllRequestSenderModel = new SecuritySettingsGetAllRequestModel(this.sendBuffer)
    this._SecuritySettingsGetAllRequestReceiverValue = new SecuritySettingsGetAllRequest()
    this._SecuritySettingsGetAllRequestReceiverModel = new SecuritySettingsGetAllRequestModel()
    this._SecuritySettingsGetAllResponseSenderModel = new SecuritySettingsGetAllResponseModel(this.sendBuffer)
    this._SecuritySettingsGetAllResponseReceiverValue = new SecuritySettingsGetAllResponse()
    this._SecuritySettingsGetAllResponseReceiverModel = new SecuritySettingsGetAllResponseModel()
    this._SecuritySettingsCreateRequestSenderModel = new SecuritySettingsCreateRequestModel(this.sendBuffer)
    this._SecuritySettingsCreateRequestReceiverValue = new SecuritySettingsCreateRequest()
    this._SecuritySettingsCreateRequestReceiverModel = new SecuritySettingsCreateRequestModel()
    this._SecuritySettingsCreateResponseSenderModel = new SecuritySettingsCreateResponseModel(this.sendBuffer)
    this._SecuritySettingsCreateResponseReceiverValue = new SecuritySettingsCreateResponse()
    this._SecuritySettingsCreateResponseReceiverModel = new SecuritySettingsCreateResponseModel()
    this._SecuritySettingsModifyRequestSenderModel = new SecuritySettingsModifyRequestModel(this.sendBuffer)
    this._SecuritySettingsModifyRequestReceiverValue = new SecuritySettingsModifyRequest()
    this._SecuritySettingsModifyRequestReceiverModel = new SecuritySettingsModifyRequestModel()
    this._SecuritySettingsModifyResponseSenderModel = new SecuritySettingsModifyResponseModel(this.sendBuffer)
    this._SecuritySettingsModifyResponseReceiverValue = new SecuritySettingsModifyResponse()
    this._SecuritySettingsModifyResponseReceiverModel = new SecuritySettingsModifyResponseModel()
    this._SecuritySettingsDeleteRequestSenderModel = new SecuritySettingsDeleteRequestModel(this.sendBuffer)
    this._SecuritySettingsDeleteRequestReceiverValue = new SecuritySettingsDeleteRequest()
    this._SecuritySettingsDeleteRequestReceiverModel = new SecuritySettingsDeleteRequestModel()
    this._SecuritySettingsDeleteResponseSenderModel = new SecuritySettingsDeleteResponseModel(this.sendBuffer)
    this._SecuritySettingsDeleteResponseReceiverValue = new SecuritySettingsDeleteResponse()
    this._SecuritySettingsDeleteResponseReceiverModel = new SecuritySettingsDeleteResponseModel()
    this._SecuritySettingsUpdateNotifySenderModel = new SecuritySettingsUpdateNotifyModel(this.sendBuffer)
    this._SecuritySettingsUpdateNotifyReceiverValue = new SecuritySettingsUpdateNotify()
    this._SecuritySettingsUpdateNotifyReceiverModel = new SecuritySettingsUpdateNotifyModel()
    this._FirewallRuleGetRequestSenderModel = new FirewallRuleGetRequestModel(this.sendBuffer)
    this._FirewallRuleGetRequestReceiverValue = new FirewallRuleGetRequest()
    this._FirewallRuleGetRequestReceiverModel = new FirewallRuleGetRequestModel()
    this._FirewallRuleGetResponseSenderModel = new FirewallRuleGetResponseModel(this.sendBuffer)
    this._FirewallRuleGetResponseReceiverValue = new FirewallRuleGetResponse()
    this._FirewallRuleGetResponseReceiverModel = new FirewallRuleGetResponseModel()
    this._FirewallRulesGetAllRequestSenderModel = new FirewallRulesGetAllRequestModel(this.sendBuffer)
    this._FirewallRulesGetAllRequestReceiverValue = new FirewallRulesGetAllRequest()
    this._FirewallRulesGetAllRequestReceiverModel = new FirewallRulesGetAllRequestModel()
    this._FirewallRulesGetAllResponseSenderModel = new FirewallRulesGetAllResponseModel(this.sendBuffer)
    this._FirewallRulesGetAllResponseReceiverValue = new FirewallRulesGetAllResponse()
    this._FirewallRulesGetAllResponseReceiverModel = new FirewallRulesGetAllResponseModel()
    this._FirewallRuleCreateRequestSenderModel = new FirewallRuleCreateRequestModel(this.sendBuffer)
    this._FirewallRuleCreateRequestReceiverValue = new FirewallRuleCreateRequest()
    this._FirewallRuleCreateRequestReceiverModel = new FirewallRuleCreateRequestModel()
    this._FirewallRuleCreateResponseSenderModel = new FirewallRuleCreateResponseModel(this.sendBuffer)
    this._FirewallRuleCreateResponseReceiverValue = new FirewallRuleCreateResponse()
    this._FirewallRuleCreateResponseReceiverModel = new FirewallRuleCreateResponseModel()
    this._FirewallRuleModifyRequestSenderModel = new FirewallRuleModifyRequestModel(this.sendBuffer)
    this._FirewallRuleModifyRequestReceiverValue = new FirewallRuleModifyRequest()
    this._FirewallRuleModifyRequestReceiverModel = new FirewallRuleModifyRequestModel()
    this._FirewallRuleModifyResponseSenderModel = new FirewallRuleModifyResponseModel(this.sendBuffer)
    this._FirewallRuleModifyResponseReceiverValue = new FirewallRuleModifyResponse()
    this._FirewallRuleModifyResponseReceiverModel = new FirewallRuleModifyResponseModel()
    this._FirewallRuleDeleteRequestSenderModel = new FirewallRuleDeleteRequestModel(this.sendBuffer)
    this._FirewallRuleDeleteRequestReceiverValue = new FirewallRuleDeleteRequest()
    this._FirewallRuleDeleteRequestReceiverModel = new FirewallRuleDeleteRequestModel()
    this._FirewallRuleDeleteResponseSenderModel = new FirewallRuleDeleteResponseModel(this.sendBuffer)
    this._FirewallRuleDeleteResponseReceiverValue = new FirewallRuleDeleteResponse()
    this._FirewallRuleDeleteResponseReceiverModel = new FirewallRuleDeleteResponseModel()
    this._FirewallRuleUpdateNotifySenderModel = new FirewallRuleUpdateNotifyModel(this.sendBuffer)
    this._FirewallRuleUpdateNotifyReceiverValue = new FirewallRuleUpdateNotify()
    this._FirewallRuleUpdateNotifyReceiverModel = new FirewallRuleUpdateNotifyModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  /**
   * Get imported config client
   * @this {!Client}
   * @returns {!config.Client} config client
   */
  get configClient () {
    return this._configClient
  }

  /**
   * Get imported history client
   * @this {!Client}
   * @returns {!history.Client} history client
   */
  get historyClient () {
    return this._historyClient
  }

  /**
   * Get imported client client
   * @this {!Client}
   * @returns {!client.Client} client client
   */
  get clientClient () {
    return this._clientClient
  }

  /**
   * Get imported feed client
   * @this {!Client}
   * @returns {!feed.Client} feed client
   */
  get feedClient () {
    return this._feedClient
  }

  /**
   * Get imported storage client
   * @this {!Client}
   * @returns {!storage.Client} storage client
   */
  get storageClient () {
    return this._storageClient
  }

  /**
   * Get imported exposed client
   * @this {!Client}
   * @returns {!exposed.Client} exposed client
   */
  get exposedClient () {
    return this._exposedClient
  }

  /**
   * Get imported execution client
   * @this {!Client}
   * @returns {!execution.Client} execution client
   */
  get executionClient () {
    return this._executionClient
  }

  /**
   * Get imported backup client
   * @this {!Client}
   * @returns {!backup.Client} backup client
   */
  get backupClient () {
    return this._backupClient
  }

  /**
   * Get imported inner client
   * @this {!Client}
   * @returns {!inner.Client} inner client
   */
  get innerClient () {
    return this._innerClient
  }

  /**
   * Get imported trade client
   * @this {!Client}
   * @returns {!trade.Client} trade client
   */
  get tradeClient () {
    return this._tradeClient
  }

  // Sender models accessors

  /**
   * Get PingModuleRequest model
   * @this {!Client}
   * @returns {!PingModuleRequestModel} PingModuleRequest sender model
   */
  get PingModuleRequestSenderModel () {
    return this._PingModuleRequestSenderModel
  }

  /**
   * Get PongModuleResponse model
   * @this {!Client}
   * @returns {!PongModuleResponseModel} PongModuleResponse sender model
   */
  get PongModuleResponseSenderModel () {
    return this._PongModuleResponseSenderModel
  }

  /**
   * Get PingModuleReject model
   * @this {!Client}
   * @returns {!PingModuleRejectModel} PingModuleReject sender model
   */
  get PingModuleRejectSenderModel () {
    return this._PingModuleRejectSenderModel
  }

  /**
   * Get Deprecated3 model
   * @this {!Client}
   * @returns {!Deprecated3Model} Deprecated3 sender model
   */
  get Deprecated3SenderModel () {
    return this._Deprecated3SenderModel
  }

  /**
   * Get Deprecated4 model
   * @this {!Client}
   * @returns {!Deprecated4Model} Deprecated4 sender model
   */
  get Deprecated4SenderModel () {
    return this._Deprecated4SenderModel
  }

  /**
   * Get Deprecated1 model
   * @this {!Client}
   * @returns {!Deprecated1Model} Deprecated1 sender model
   */
  get Deprecated1SenderModel () {
    return this._Deprecated1SenderModel
  }

  /**
   * Get Deprecated2 model
   * @this {!Client}
   * @returns {!Deprecated2Model} Deprecated2 sender model
   */
  get Deprecated2SenderModel () {
    return this._Deprecated2SenderModel
  }

  /**
   * Get ManagerSessionInfoRequest model
   * @this {!Client}
   * @returns {!ManagerSessionInfoRequestModel} ManagerSessionInfoRequest sender model
   */
  get ManagerSessionInfoRequestSenderModel () {
    return this._ManagerSessionInfoRequestSenderModel
  }

  /**
   * Get ManagerSessionInfoResponse model
   * @this {!Client}
   * @returns {!ManagerSessionInfoResponseModel} ManagerSessionInfoResponse sender model
   */
  get ManagerSessionInfoResponseSenderModel () {
    return this._ManagerSessionInfoResponseSenderModel
  }

  /**
   * Get OnlineSessionsRequest model
   * @this {!Client}
   * @returns {!OnlineSessionsRequestModel} OnlineSessionsRequest sender model
   */
  get OnlineSessionsRequestSenderModel () {
    return this._OnlineSessionsRequestSenderModel
  }

  /**
   * Get OnlineSessionsResponse model
   * @this {!Client}
   * @returns {!OnlineSessionsResponseModel} OnlineSessionsResponse sender model
   */
  get OnlineSessionsResponseSenderModel () {
    return this._OnlineSessionsResponseSenderModel
  }

  /**
   * Get DropSessionRequest model
   * @this {!Client}
   * @returns {!DropSessionRequestModel} DropSessionRequest sender model
   */
  get DropSessionRequestSenderModel () {
    return this._DropSessionRequestSenderModel
  }

  /**
   * Get DropSessionResponse model
   * @this {!Client}
   * @returns {!DropSessionResponseModel} DropSessionResponse sender model
   */
  get DropSessionResponseSenderModel () {
    return this._DropSessionResponseSenderModel
  }

  /**
   * Get ConfigRequest model
   * @this {!Client}
   * @returns {!ConfigRequestModel} ConfigRequest sender model
   */
  get ConfigRequestSenderModel () {
    return this._ConfigRequestSenderModel
  }

  /**
   * Get ConfigResponse model
   * @this {!Client}
   * @returns {!ConfigResponseModel} ConfigResponse sender model
   */
  get ConfigResponseSenderModel () {
    return this._ConfigResponseSenderModel
  }

  /**
   * Get ConfigReject model
   * @this {!Client}
   * @returns {!ConfigRejectModel} ConfigReject sender model
   */
  get ConfigRejectSenderModel () {
    return this._ConfigRejectSenderModel
  }

  /**
   * Get ConfigUpdateRequest model
   * @this {!Client}
   * @returns {!ConfigUpdateRequestModel} ConfigUpdateRequest sender model
   */
  get ConfigUpdateRequestSenderModel () {
    return this._ConfigUpdateRequestSenderModel
  }

  /**
   * Get ConfigUpdateResponse model
   * @this {!Client}
   * @returns {!ConfigUpdateResponseModel} ConfigUpdateResponse sender model
   */
  get ConfigUpdateResponseSenderModel () {
    return this._ConfigUpdateResponseSenderModel
  }

  /**
   * Get ConfigUpdateNotify model
   * @this {!Client}
   * @returns {!ConfigUpdateNotifyModel} ConfigUpdateNotify sender model
   */
  get ConfigUpdateNotifySenderModel () {
    return this._ConfigUpdateNotifySenderModel
  }

  /**
   * Get ConfigVersionRequest model
   * @this {!Client}
   * @returns {!ConfigVersionRequestModel} ConfigVersionRequest sender model
   */
  get ConfigVersionRequestSenderModel () {
    return this._ConfigVersionRequestSenderModel
  }

  /**
   * Get ConfigVersionResponse model
   * @this {!Client}
   * @returns {!ConfigVersionResponseModel} ConfigVersionResponse sender model
   */
  get ConfigVersionResponseSenderModel () {
    return this._ConfigVersionResponseSenderModel
  }

  /**
   * Get ConfigExportRequest model
   * @this {!Client}
   * @returns {!ConfigExportRequestModel} ConfigExportRequest sender model
   */
  get ConfigExportRequestSenderModel () {
    return this._ConfigExportRequestSenderModel
  }

  /**
   * Get ConfigExportResponse model
   * @this {!Client}
   * @returns {!ConfigExportResponseModel} ConfigExportResponse sender model
   */
  get ConfigExportResponseSenderModel () {
    return this._ConfigExportResponseSenderModel
  }

  /**
   * Get ConfigImportRequest model
   * @this {!Client}
   * @returns {!ConfigImportRequestModel} ConfigImportRequest sender model
   */
  get ConfigImportRequestSenderModel () {
    return this._ConfigImportRequestSenderModel
  }

  /**
   * Get ConfigImportResponse model
   * @this {!Client}
   * @returns {!ConfigImportResponseModel} ConfigImportResponse sender model
   */
  get ConfigImportResponseSenderModel () {
    return this._ConfigImportResponseSenderModel
  }

  /**
   * Get ConfigValidateRequest model
   * @this {!Client}
   * @returns {!ConfigValidateRequestModel} ConfigValidateRequest sender model
   */
  get ConfigValidateRequestSenderModel () {
    return this._ConfigValidateRequestSenderModel
  }

  /**
   * Get ConfigValidateResponse model
   * @this {!Client}
   * @returns {!ConfigValidateResponseModel} ConfigValidateResponse sender model
   */
  get ConfigValidateResponseSenderModel () {
    return this._ConfigValidateResponseSenderModel
  }

  /**
   * Get ConfigRefreshRequest model
   * @this {!Client}
   * @returns {!ConfigRefreshRequestModel} ConfigRefreshRequest sender model
   */
  get ConfigRefreshRequestSenderModel () {
    return this._ConfigRefreshRequestSenderModel
  }

  /**
   * Get ConfigRefreshResponse model
   * @this {!Client}
   * @returns {!ConfigRefreshResponseModel} ConfigRefreshResponse sender model
   */
  get ConfigRefreshResponseSenderModel () {
    return this._ConfigRefreshResponseSenderModel
  }

  /**
   * Get ConfigHistoryRequest model
   * @this {!Client}
   * @returns {!ConfigHistoryRequestModel} ConfigHistoryRequest sender model
   */
  get ConfigHistoryRequestSenderModel () {
    return this._ConfigHistoryRequestSenderModel
  }

  /**
   * Get ConfigHistoryResponse model
   * @this {!Client}
   * @returns {!ConfigHistoryResponseModel} ConfigHistoryResponse sender model
   */
  get ConfigHistoryResponseSenderModel () {
    return this._ConfigHistoryResponseSenderModel
  }

  /**
   * Get ConfigRollbackRequest model
   * @this {!Client}
   * @returns {!ConfigRollbackRequestModel} ConfigRollbackRequest sender model
   */
  get ConfigRollbackRequestSenderModel () {
    return this._ConfigRollbackRequestSenderModel
  }

  /**
   * Get ConfigRollbackResponse model
   * @this {!Client}
   * @returns {!ConfigRollbackResponseModel} ConfigRollbackResponse sender model
   */
  get ConfigRollbackResponseSenderModel () {
    return this._ConfigRollbackResponseSenderModel
  }

  /**
   * Get HostGetRequest model
   * @this {!Client}
   * @returns {!HostGetRequestModel} HostGetRequest sender model
   */
  get HostGetRequestSenderModel () {
    return this._HostGetRequestSenderModel
  }

  /**
   * Get HostGetResponse model
   * @this {!Client}
   * @returns {!HostGetResponseModel} HostGetResponse sender model
   */
  get HostGetResponseSenderModel () {
    return this._HostGetResponseSenderModel
  }

  /**
   * Get HostsGetAllRequest model
   * @this {!Client}
   * @returns {!HostsGetAllRequestModel} HostsGetAllRequest sender model
   */
  get HostsGetAllRequestSenderModel () {
    return this._HostsGetAllRequestSenderModel
  }

  /**
   * Get HostsGetAllResponse model
   * @this {!Client}
   * @returns {!HostsGetAllResponseModel} HostsGetAllResponse sender model
   */
  get HostsGetAllResponseSenderModel () {
    return this._HostsGetAllResponseSenderModel
  }

  /**
   * Get HostCreateRequest model
   * @this {!Client}
   * @returns {!HostCreateRequestModel} HostCreateRequest sender model
   */
  get HostCreateRequestSenderModel () {
    return this._HostCreateRequestSenderModel
  }

  /**
   * Get HostCreateResponse model
   * @this {!Client}
   * @returns {!HostCreateResponseModel} HostCreateResponse sender model
   */
  get HostCreateResponseSenderModel () {
    return this._HostCreateResponseSenderModel
  }

  /**
   * Get HostModifyRequest model
   * @this {!Client}
   * @returns {!HostModifyRequestModel} HostModifyRequest sender model
   */
  get HostModifyRequestSenderModel () {
    return this._HostModifyRequestSenderModel
  }

  /**
   * Get HostModifyResponse model
   * @this {!Client}
   * @returns {!HostModifyResponseModel} HostModifyResponse sender model
   */
  get HostModifyResponseSenderModel () {
    return this._HostModifyResponseSenderModel
  }

  /**
   * Get HostDeleteRequest model
   * @this {!Client}
   * @returns {!HostDeleteRequestModel} HostDeleteRequest sender model
   */
  get HostDeleteRequestSenderModel () {
    return this._HostDeleteRequestSenderModel
  }

  /**
   * Get HostDeleteResponse model
   * @this {!Client}
   * @returns {!HostDeleteResponseModel} HostDeleteResponse sender model
   */
  get HostDeleteResponseSenderModel () {
    return this._HostDeleteResponseSenderModel
  }

  /**
   * Get HostUpdateNotify model
   * @this {!Client}
   * @returns {!HostUpdateNotifyModel} HostUpdateNotify sender model
   */
  get HostUpdateNotifySenderModel () {
    return this._HostUpdateNotifySenderModel
  }

  /**
   * Get ModuleGetRequest model
   * @this {!Client}
   * @returns {!ModuleGetRequestModel} ModuleGetRequest sender model
   */
  get ModuleGetRequestSenderModel () {
    return this._ModuleGetRequestSenderModel
  }

  /**
   * Get ModuleGetResponse model
   * @this {!Client}
   * @returns {!ModuleGetResponseModel} ModuleGetResponse sender model
   */
  get ModuleGetResponseSenderModel () {
    return this._ModuleGetResponseSenderModel
  }

  /**
   * Get ModulesGetAllRequest model
   * @this {!Client}
   * @returns {!ModulesGetAllRequestModel} ModulesGetAllRequest sender model
   */
  get ModulesGetAllRequestSenderModel () {
    return this._ModulesGetAllRequestSenderModel
  }

  /**
   * Get ModulesGetAllResponse model
   * @this {!Client}
   * @returns {!ModulesGetAllResponseModel} ModulesGetAllResponse sender model
   */
  get ModulesGetAllResponseSenderModel () {
    return this._ModulesGetAllResponseSenderModel
  }

  /**
   * Get ModuleCreateRequest model
   * @this {!Client}
   * @returns {!ModuleCreateRequestModel} ModuleCreateRequest sender model
   */
  get ModuleCreateRequestSenderModel () {
    return this._ModuleCreateRequestSenderModel
  }

  /**
   * Get ModuleCreateResponse model
   * @this {!Client}
   * @returns {!ModuleCreateResponseModel} ModuleCreateResponse sender model
   */
  get ModuleCreateResponseSenderModel () {
    return this._ModuleCreateResponseSenderModel
  }

  /**
   * Get ModuleModifyRequest model
   * @this {!Client}
   * @returns {!ModuleModifyRequestModel} ModuleModifyRequest sender model
   */
  get ModuleModifyRequestSenderModel () {
    return this._ModuleModifyRequestSenderModel
  }

  /**
   * Get ModuleModifyResponse model
   * @this {!Client}
   * @returns {!ModuleModifyResponseModel} ModuleModifyResponse sender model
   */
  get ModuleModifyResponseSenderModel () {
    return this._ModuleModifyResponseSenderModel
  }

  /**
   * Get ModuleDeleteRequest model
   * @this {!Client}
   * @returns {!ModuleDeleteRequestModel} ModuleDeleteRequest sender model
   */
  get ModuleDeleteRequestSenderModel () {
    return this._ModuleDeleteRequestSenderModel
  }

  /**
   * Get ModuleDeleteResponse model
   * @this {!Client}
   * @returns {!ModuleDeleteResponseModel} ModuleDeleteResponse sender model
   */
  get ModuleDeleteResponseSenderModel () {
    return this._ModuleDeleteResponseSenderModel
  }

  /**
   * Get ModuleUpdateNotify model
   * @this {!Client}
   * @returns {!ModuleUpdateNotifyModel} ModuleUpdateNotify sender model
   */
  get ModuleUpdateNotifySenderModel () {
    return this._ModuleUpdateNotifySenderModel
  }

  /**
   * Get ServerTimeGetRequest model
   * @this {!Client}
   * @returns {!ServerTimeGetRequestModel} ServerTimeGetRequest sender model
   */
  get ServerTimeGetRequestSenderModel () {
    return this._ServerTimeGetRequestSenderModel
  }

  /**
   * Get ServerTimeGetResponse model
   * @this {!Client}
   * @returns {!ServerTimeGetResponseModel} ServerTimeGetResponse sender model
   */
  get ServerTimeGetResponseSenderModel () {
    return this._ServerTimeGetResponseSenderModel
  }

  /**
   * Get ManagerPlatformTimezoneGetRequest model
   * @this {!Client}
   * @returns {!ManagerPlatformTimezoneGetRequestModel} ManagerPlatformTimezoneGetRequest sender model
   */
  get ManagerPlatformTimezoneGetRequestSenderModel () {
    return this._ManagerPlatformTimezoneGetRequestSenderModel
  }

  /**
   * Get ManagerPlatformTimezoneGetResponse model
   * @this {!Client}
   * @returns {!ManagerPlatformTimezoneGetResponseModel} ManagerPlatformTimezoneGetResponse sender model
   */
  get ManagerPlatformTimezoneGetResponseSenderModel () {
    return this._ManagerPlatformTimezoneGetResponseSenderModel
  }

  /**
   * Get TimezoneGetRequest model
   * @this {!Client}
   * @returns {!TimezoneGetRequestModel} TimezoneGetRequest sender model
   */
  get TimezoneGetRequestSenderModel () {
    return this._TimezoneGetRequestSenderModel
  }

  /**
   * Get TimezoneGetResponse model
   * @this {!Client}
   * @returns {!TimezoneGetResponseModel} TimezoneGetResponse sender model
   */
  get TimezoneGetResponseSenderModel () {
    return this._TimezoneGetResponseSenderModel
  }

  /**
   * Get TimezonesGetAllRequest model
   * @this {!Client}
   * @returns {!TimezonesGetAllRequestModel} TimezonesGetAllRequest sender model
   */
  get TimezonesGetAllRequestSenderModel () {
    return this._TimezonesGetAllRequestSenderModel
  }

  /**
   * Get TimezonesGetAllResponse model
   * @this {!Client}
   * @returns {!TimezonesGetAllResponseModel} TimezonesGetAllResponse sender model
   */
  get TimezonesGetAllResponseSenderModel () {
    return this._TimezonesGetAllResponseSenderModel
  }

  /**
   * Get TimezoneCreateRequest model
   * @this {!Client}
   * @returns {!TimezoneCreateRequestModel} TimezoneCreateRequest sender model
   */
  get TimezoneCreateRequestSenderModel () {
    return this._TimezoneCreateRequestSenderModel
  }

  /**
   * Get TimezoneCreateResponse model
   * @this {!Client}
   * @returns {!TimezoneCreateResponseModel} TimezoneCreateResponse sender model
   */
  get TimezoneCreateResponseSenderModel () {
    return this._TimezoneCreateResponseSenderModel
  }

  /**
   * Get TimezoneModifyRequest model
   * @this {!Client}
   * @returns {!TimezoneModifyRequestModel} TimezoneModifyRequest sender model
   */
  get TimezoneModifyRequestSenderModel () {
    return this._TimezoneModifyRequestSenderModel
  }

  /**
   * Get TimezoneModifyResponse model
   * @this {!Client}
   * @returns {!TimezoneModifyResponseModel} TimezoneModifyResponse sender model
   */
  get TimezoneModifyResponseSenderModel () {
    return this._TimezoneModifyResponseSenderModel
  }

  /**
   * Get TimezoneDeleteRequest model
   * @this {!Client}
   * @returns {!TimezoneDeleteRequestModel} TimezoneDeleteRequest sender model
   */
  get TimezoneDeleteRequestSenderModel () {
    return this._TimezoneDeleteRequestSenderModel
  }

  /**
   * Get TimezoneDeleteResponse model
   * @this {!Client}
   * @returns {!TimezoneDeleteResponseModel} TimezoneDeleteResponse sender model
   */
  get TimezoneDeleteResponseSenderModel () {
    return this._TimezoneDeleteResponseSenderModel
  }

  /**
   * Get TimezoneUpdateNotify model
   * @this {!Client}
   * @returns {!TimezoneUpdateNotifyModel} TimezoneUpdateNotify sender model
   */
  get TimezoneUpdateNotifySenderModel () {
    return this._TimezoneUpdateNotifySenderModel
  }

  /**
   * Get CurrencyGroupGetRequest model
   * @this {!Client}
   * @returns {!CurrencyGroupGetRequestModel} CurrencyGroupGetRequest sender model
   */
  get CurrencyGroupGetRequestSenderModel () {
    return this._CurrencyGroupGetRequestSenderModel
  }

  /**
   * Get CurrencyGroupGetResponse model
   * @this {!Client}
   * @returns {!CurrencyGroupGetResponseModel} CurrencyGroupGetResponse sender model
   */
  get CurrencyGroupGetResponseSenderModel () {
    return this._CurrencyGroupGetResponseSenderModel
  }

  /**
   * Get CurrencyGroupsGetAllRequest model
   * @this {!Client}
   * @returns {!CurrencyGroupsGetAllRequestModel} CurrencyGroupsGetAllRequest sender model
   */
  get CurrencyGroupsGetAllRequestSenderModel () {
    return this._CurrencyGroupsGetAllRequestSenderModel
  }

  /**
   * Get CurrencyGroupsGetAllResponse model
   * @this {!Client}
   * @returns {!CurrencyGroupsGetAllResponseModel} CurrencyGroupsGetAllResponse sender model
   */
  get CurrencyGroupsGetAllResponseSenderModel () {
    return this._CurrencyGroupsGetAllResponseSenderModel
  }

  /**
   * Get CurrencyGroupCreateRequest model
   * @this {!Client}
   * @returns {!CurrencyGroupCreateRequestModel} CurrencyGroupCreateRequest sender model
   */
  get CurrencyGroupCreateRequestSenderModel () {
    return this._CurrencyGroupCreateRequestSenderModel
  }

  /**
   * Get CurrencyGroupCreateResponse model
   * @this {!Client}
   * @returns {!CurrencyGroupCreateResponseModel} CurrencyGroupCreateResponse sender model
   */
  get CurrencyGroupCreateResponseSenderModel () {
    return this._CurrencyGroupCreateResponseSenderModel
  }

  /**
   * Get CurrencyGroupModifyRequest model
   * @this {!Client}
   * @returns {!CurrencyGroupModifyRequestModel} CurrencyGroupModifyRequest sender model
   */
  get CurrencyGroupModifyRequestSenderModel () {
    return this._CurrencyGroupModifyRequestSenderModel
  }

  /**
   * Get CurrencyGroupModifyResponse model
   * @this {!Client}
   * @returns {!CurrencyGroupModifyResponseModel} CurrencyGroupModifyResponse sender model
   */
  get CurrencyGroupModifyResponseSenderModel () {
    return this._CurrencyGroupModifyResponseSenderModel
  }

  /**
   * Get CurrencyGroupDeleteRequest model
   * @this {!Client}
   * @returns {!CurrencyGroupDeleteRequestModel} CurrencyGroupDeleteRequest sender model
   */
  get CurrencyGroupDeleteRequestSenderModel () {
    return this._CurrencyGroupDeleteRequestSenderModel
  }

  /**
   * Get CurrencyGroupDeleteResponse model
   * @this {!Client}
   * @returns {!CurrencyGroupDeleteResponseModel} CurrencyGroupDeleteResponse sender model
   */
  get CurrencyGroupDeleteResponseSenderModel () {
    return this._CurrencyGroupDeleteResponseSenderModel
  }

  /**
   * Get CurrencyGroupUpdateNotify model
   * @this {!Client}
   * @returns {!CurrencyGroupUpdateNotifyModel} CurrencyGroupUpdateNotify sender model
   */
  get CurrencyGroupUpdateNotifySenderModel () {
    return this._CurrencyGroupUpdateNotifySenderModel
  }

  /**
   * Get CurrencyGetRequest model
   * @this {!Client}
   * @returns {!CurrencyGetRequestModel} CurrencyGetRequest sender model
   */
  get CurrencyGetRequestSenderModel () {
    return this._CurrencyGetRequestSenderModel
  }

  /**
   * Get CurrencyGetResponse model
   * @this {!Client}
   * @returns {!CurrencyGetResponseModel} CurrencyGetResponse sender model
   */
  get CurrencyGetResponseSenderModel () {
    return this._CurrencyGetResponseSenderModel
  }

  /**
   * Get CurrenciesGetAllRequest model
   * @this {!Client}
   * @returns {!CurrenciesGetAllRequestModel} CurrenciesGetAllRequest sender model
   */
  get CurrenciesGetAllRequestSenderModel () {
    return this._CurrenciesGetAllRequestSenderModel
  }

  /**
   * Get CurrenciesGetAllResponse model
   * @this {!Client}
   * @returns {!CurrenciesGetAllResponseModel} CurrenciesGetAllResponse sender model
   */
  get CurrenciesGetAllResponseSenderModel () {
    return this._CurrenciesGetAllResponseSenderModel
  }

  /**
   * Get CurrencyCreateRequest model
   * @this {!Client}
   * @returns {!CurrencyCreateRequestModel} CurrencyCreateRequest sender model
   */
  get CurrencyCreateRequestSenderModel () {
    return this._CurrencyCreateRequestSenderModel
  }

  /**
   * Get CurrencyCreateResponse model
   * @this {!Client}
   * @returns {!CurrencyCreateResponseModel} CurrencyCreateResponse sender model
   */
  get CurrencyCreateResponseSenderModel () {
    return this._CurrencyCreateResponseSenderModel
  }

  /**
   * Get CurrencyModifyRequest model
   * @this {!Client}
   * @returns {!CurrencyModifyRequestModel} CurrencyModifyRequest sender model
   */
  get CurrencyModifyRequestSenderModel () {
    return this._CurrencyModifyRequestSenderModel
  }

  /**
   * Get CurrencyModifyResponse model
   * @this {!Client}
   * @returns {!CurrencyModifyResponseModel} CurrencyModifyResponse sender model
   */
  get CurrencyModifyResponseSenderModel () {
    return this._CurrencyModifyResponseSenderModel
  }

  /**
   * Get CurrencyDeleteRequest model
   * @this {!Client}
   * @returns {!CurrencyDeleteRequestModel} CurrencyDeleteRequest sender model
   */
  get CurrencyDeleteRequestSenderModel () {
    return this._CurrencyDeleteRequestSenderModel
  }

  /**
   * Get CurrencyDeleteResponse model
   * @this {!Client}
   * @returns {!CurrencyDeleteResponseModel} CurrencyDeleteResponse sender model
   */
  get CurrencyDeleteResponseSenderModel () {
    return this._CurrencyDeleteResponseSenderModel
  }

  /**
   * Get CurrencyUpdateNotify model
   * @this {!Client}
   * @returns {!CurrencyUpdateNotifyModel} CurrencyUpdateNotify sender model
   */
  get CurrencyUpdateNotifySenderModel () {
    return this._CurrencyUpdateNotifySenderModel
  }

  /**
   * Get SymbolGroupGetRequest model
   * @this {!Client}
   * @returns {!SymbolGroupGetRequestModel} SymbolGroupGetRequest sender model
   */
  get SymbolGroupGetRequestSenderModel () {
    return this._SymbolGroupGetRequestSenderModel
  }

  /**
   * Get SymbolGroupGetResponse model
   * @this {!Client}
   * @returns {!SymbolGroupGetResponseModel} SymbolGroupGetResponse sender model
   */
  get SymbolGroupGetResponseSenderModel () {
    return this._SymbolGroupGetResponseSenderModel
  }

  /**
   * Get SymbolGroupsGetAllRequest model
   * @this {!Client}
   * @returns {!SymbolGroupsGetAllRequestModel} SymbolGroupsGetAllRequest sender model
   */
  get SymbolGroupsGetAllRequestSenderModel () {
    return this._SymbolGroupsGetAllRequestSenderModel
  }

  /**
   * Get SymbolGroupsGetAllResponse model
   * @this {!Client}
   * @returns {!SymbolGroupsGetAllResponseModel} SymbolGroupsGetAllResponse sender model
   */
  get SymbolGroupsGetAllResponseSenderModel () {
    return this._SymbolGroupsGetAllResponseSenderModel
  }

  /**
   * Get SymbolGroupCreateRequest model
   * @this {!Client}
   * @returns {!SymbolGroupCreateRequestModel} SymbolGroupCreateRequest sender model
   */
  get SymbolGroupCreateRequestSenderModel () {
    return this._SymbolGroupCreateRequestSenderModel
  }

  /**
   * Get SymbolGroupCreateResponse model
   * @this {!Client}
   * @returns {!SymbolGroupCreateResponseModel} SymbolGroupCreateResponse sender model
   */
  get SymbolGroupCreateResponseSenderModel () {
    return this._SymbolGroupCreateResponseSenderModel
  }

  /**
   * Get SymbolGroupModifyRequest model
   * @this {!Client}
   * @returns {!SymbolGroupModifyRequestModel} SymbolGroupModifyRequest sender model
   */
  get SymbolGroupModifyRequestSenderModel () {
    return this._SymbolGroupModifyRequestSenderModel
  }

  /**
   * Get SymbolGroupModifyResponse model
   * @this {!Client}
   * @returns {!SymbolGroupModifyResponseModel} SymbolGroupModifyResponse sender model
   */
  get SymbolGroupModifyResponseSenderModel () {
    return this._SymbolGroupModifyResponseSenderModel
  }

  /**
   * Get SymbolGroupDeleteRequest model
   * @this {!Client}
   * @returns {!SymbolGroupDeleteRequestModel} SymbolGroupDeleteRequest sender model
   */
  get SymbolGroupDeleteRequestSenderModel () {
    return this._SymbolGroupDeleteRequestSenderModel
  }

  /**
   * Get SymbolGroupDeleteResponse model
   * @this {!Client}
   * @returns {!SymbolGroupDeleteResponseModel} SymbolGroupDeleteResponse sender model
   */
  get SymbolGroupDeleteResponseSenderModel () {
    return this._SymbolGroupDeleteResponseSenderModel
  }

  /**
   * Get SymbolGroupUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolGroupUpdateNotifyModel} SymbolGroupUpdateNotify sender model
   */
  get SymbolGroupUpdateNotifySenderModel () {
    return this._SymbolGroupUpdateNotifySenderModel
  }

  /**
   * Get SymbolGetRequest model
   * @this {!Client}
   * @returns {!SymbolGetRequestModel} SymbolGetRequest sender model
   */
  get SymbolGetRequestSenderModel () {
    return this._SymbolGetRequestSenderModel
  }

  /**
   * Get SymbolGetResponse model
   * @this {!Client}
   * @returns {!SymbolGetResponseModel} SymbolGetResponse sender model
   */
  get SymbolGetResponseSenderModel () {
    return this._SymbolGetResponseSenderModel
  }

  /**
   * Get SymbolsGetAllRequest model
   * @this {!Client}
   * @returns {!SymbolsGetAllRequestModel} SymbolsGetAllRequest sender model
   */
  get SymbolsGetAllRequestSenderModel () {
    return this._SymbolsGetAllRequestSenderModel
  }

  /**
   * Get SymbolsGetAllResponse model
   * @this {!Client}
   * @returns {!SymbolsGetAllResponseModel} SymbolsGetAllResponse sender model
   */
  get SymbolsGetAllResponseSenderModel () {
    return this._SymbolsGetAllResponseSenderModel
  }

  /**
   * Get SymbolCreateRequest model
   * @this {!Client}
   * @returns {!SymbolCreateRequestModel} SymbolCreateRequest sender model
   */
  get SymbolCreateRequestSenderModel () {
    return this._SymbolCreateRequestSenderModel
  }

  /**
   * Get SymbolCreateResponse model
   * @this {!Client}
   * @returns {!SymbolCreateResponseModel} SymbolCreateResponse sender model
   */
  get SymbolCreateResponseSenderModel () {
    return this._SymbolCreateResponseSenderModel
  }

  /**
   * Get SymbolModifyRequest model
   * @this {!Client}
   * @returns {!SymbolModifyRequestModel} SymbolModifyRequest sender model
   */
  get SymbolModifyRequestSenderModel () {
    return this._SymbolModifyRequestSenderModel
  }

  /**
   * Get SymbolModifyResponse model
   * @this {!Client}
   * @returns {!SymbolModifyResponseModel} SymbolModifyResponse sender model
   */
  get SymbolModifyResponseSenderModel () {
    return this._SymbolModifyResponseSenderModel
  }

  /**
   * Get SymbolDeleteRequest model
   * @this {!Client}
   * @returns {!SymbolDeleteRequestModel} SymbolDeleteRequest sender model
   */
  get SymbolDeleteRequestSenderModel () {
    return this._SymbolDeleteRequestSenderModel
  }

  /**
   * Get SymbolDeleteResponse model
   * @this {!Client}
   * @returns {!SymbolDeleteResponseModel} SymbolDeleteResponse sender model
   */
  get SymbolDeleteResponseSenderModel () {
    return this._SymbolDeleteResponseSenderModel
  }

  /**
   * Get SymbolUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolUpdateNotifyModel} SymbolUpdateNotify sender model
   */
  get SymbolUpdateNotifySenderModel () {
    return this._SymbolUpdateNotifySenderModel
  }

  /**
   * Get SwapsExportRequest model
   * @this {!Client}
   * @returns {!SwapsExportRequestModel} SwapsExportRequest sender model
   */
  get SwapsExportRequestSenderModel () {
    return this._SwapsExportRequestSenderModel
  }

  /**
   * Get SwapsExportResponse model
   * @this {!Client}
   * @returns {!SwapsExportResponseModel} SwapsExportResponse sender model
   */
  get SwapsExportResponseSenderModel () {
    return this._SwapsExportResponseSenderModel
  }

  /**
   * Get SwapsImportRequest model
   * @this {!Client}
   * @returns {!SwapsImportRequestModel} SwapsImportRequest sender model
   */
  get SwapsImportRequestSenderModel () {
    return this._SwapsImportRequestSenderModel
  }

  /**
   * Get SwapsImportResponse model
   * @this {!Client}
   * @returns {!SwapsImportResponseModel} SwapsImportResponse sender model
   */
  get SwapsImportResponseSenderModel () {
    return this._SwapsImportResponseSenderModel
  }

  /**
   * Get CommissionProfileGetRequest model
   * @this {!Client}
   * @returns {!CommissionProfileGetRequestModel} CommissionProfileGetRequest sender model
   */
  get CommissionProfileGetRequestSenderModel () {
    return this._CommissionProfileGetRequestSenderModel
  }

  /**
   * Get CommissionProfileGetResponse model
   * @this {!Client}
   * @returns {!CommissionProfileGetResponseModel} CommissionProfileGetResponse sender model
   */
  get CommissionProfileGetResponseSenderModel () {
    return this._CommissionProfileGetResponseSenderModel
  }

  /**
   * Get CommissionProfilesGetAllRequest model
   * @this {!Client}
   * @returns {!CommissionProfilesGetAllRequestModel} CommissionProfilesGetAllRequest sender model
   */
  get CommissionProfilesGetAllRequestSenderModel () {
    return this._CommissionProfilesGetAllRequestSenderModel
  }

  /**
   * Get CommissionProfilesGetAllResponse model
   * @this {!Client}
   * @returns {!CommissionProfilesGetAllResponseModel} CommissionProfilesGetAllResponse sender model
   */
  get CommissionProfilesGetAllResponseSenderModel () {
    return this._CommissionProfilesGetAllResponseSenderModel
  }

  /**
   * Get CommissionProfileCreateRequest model
   * @this {!Client}
   * @returns {!CommissionProfileCreateRequestModel} CommissionProfileCreateRequest sender model
   */
  get CommissionProfileCreateRequestSenderModel () {
    return this._CommissionProfileCreateRequestSenderModel
  }

  /**
   * Get CommissionProfileCreateResponse model
   * @this {!Client}
   * @returns {!CommissionProfileCreateResponseModel} CommissionProfileCreateResponse sender model
   */
  get CommissionProfileCreateResponseSenderModel () {
    return this._CommissionProfileCreateResponseSenderModel
  }

  /**
   * Get CommissionProfileModifyRequest model
   * @this {!Client}
   * @returns {!CommissionProfileModifyRequestModel} CommissionProfileModifyRequest sender model
   */
  get CommissionProfileModifyRequestSenderModel () {
    return this._CommissionProfileModifyRequestSenderModel
  }

  /**
   * Get CommissionProfileModifyResponse model
   * @this {!Client}
   * @returns {!CommissionProfileModifyResponseModel} CommissionProfileModifyResponse sender model
   */
  get CommissionProfileModifyResponseSenderModel () {
    return this._CommissionProfileModifyResponseSenderModel
  }

  /**
   * Get CommissionProfileDeleteRequest model
   * @this {!Client}
   * @returns {!CommissionProfileDeleteRequestModel} CommissionProfileDeleteRequest sender model
   */
  get CommissionProfileDeleteRequestSenderModel () {
    return this._CommissionProfileDeleteRequestSenderModel
  }

  /**
   * Get CommissionProfileDeleteResponse model
   * @this {!Client}
   * @returns {!CommissionProfileDeleteResponseModel} CommissionProfileDeleteResponse sender model
   */
  get CommissionProfileDeleteResponseSenderModel () {
    return this._CommissionProfileDeleteResponseSenderModel
  }

  /**
   * Get CommissionProfileUpdateNotify model
   * @this {!Client}
   * @returns {!CommissionProfileUpdateNotifyModel} CommissionProfileUpdateNotify sender model
   */
  get CommissionProfileUpdateNotifySenderModel () {
    return this._CommissionProfileUpdateNotifySenderModel
  }

  /**
   * Get MarkupProfileGetRequest model
   * @this {!Client}
   * @returns {!MarkupProfileGetRequestModel} MarkupProfileGetRequest sender model
   */
  get MarkupProfileGetRequestSenderModel () {
    return this._MarkupProfileGetRequestSenderModel
  }

  /**
   * Get MarkupProfileGetResponse model
   * @this {!Client}
   * @returns {!MarkupProfileGetResponseModel} MarkupProfileGetResponse sender model
   */
  get MarkupProfileGetResponseSenderModel () {
    return this._MarkupProfileGetResponseSenderModel
  }

  /**
   * Get MarkupProfilesGetAllRequest model
   * @this {!Client}
   * @returns {!MarkupProfilesGetAllRequestModel} MarkupProfilesGetAllRequest sender model
   */
  get MarkupProfilesGetAllRequestSenderModel () {
    return this._MarkupProfilesGetAllRequestSenderModel
  }

  /**
   * Get MarkupProfilesGetAllResponse model
   * @this {!Client}
   * @returns {!MarkupProfilesGetAllResponseModel} MarkupProfilesGetAllResponse sender model
   */
  get MarkupProfilesGetAllResponseSenderModel () {
    return this._MarkupProfilesGetAllResponseSenderModel
  }

  /**
   * Get MarkupProfileCreateRequest model
   * @this {!Client}
   * @returns {!MarkupProfileCreateRequestModel} MarkupProfileCreateRequest sender model
   */
  get MarkupProfileCreateRequestSenderModel () {
    return this._MarkupProfileCreateRequestSenderModel
  }

  /**
   * Get MarkupProfileCreateResponse model
   * @this {!Client}
   * @returns {!MarkupProfileCreateResponseModel} MarkupProfileCreateResponse sender model
   */
  get MarkupProfileCreateResponseSenderModel () {
    return this._MarkupProfileCreateResponseSenderModel
  }

  /**
   * Get MarkupProfileModifyRequest model
   * @this {!Client}
   * @returns {!MarkupProfileModifyRequestModel} MarkupProfileModifyRequest sender model
   */
  get MarkupProfileModifyRequestSenderModel () {
    return this._MarkupProfileModifyRequestSenderModel
  }

  /**
   * Get MarkupProfileModifyResponse model
   * @this {!Client}
   * @returns {!MarkupProfileModifyResponseModel} MarkupProfileModifyResponse sender model
   */
  get MarkupProfileModifyResponseSenderModel () {
    return this._MarkupProfileModifyResponseSenderModel
  }

  /**
   * Get MarkupProfileDeleteRequest model
   * @this {!Client}
   * @returns {!MarkupProfileDeleteRequestModel} MarkupProfileDeleteRequest sender model
   */
  get MarkupProfileDeleteRequestSenderModel () {
    return this._MarkupProfileDeleteRequestSenderModel
  }

  /**
   * Get MarkupProfileDeleteResponse model
   * @this {!Client}
   * @returns {!MarkupProfileDeleteResponseModel} MarkupProfileDeleteResponse sender model
   */
  get MarkupProfileDeleteResponseSenderModel () {
    return this._MarkupProfileDeleteResponseSenderModel
  }

  /**
   * Get MarkupProfileUpdateNotify model
   * @this {!Client}
   * @returns {!MarkupProfileUpdateNotifyModel} MarkupProfileUpdateNotify sender model
   */
  get MarkupProfileUpdateNotifySenderModel () {
    return this._MarkupProfileUpdateNotifySenderModel
  }

  /**
   * Get ManagerGroupGetRequest model
   * @this {!Client}
   * @returns {!ManagerGroupGetRequestModel} ManagerGroupGetRequest sender model
   */
  get ManagerGroupGetRequestSenderModel () {
    return this._ManagerGroupGetRequestSenderModel
  }

  /**
   * Get ManagerGroupGetResponse model
   * @this {!Client}
   * @returns {!ManagerGroupGetResponseModel} ManagerGroupGetResponse sender model
   */
  get ManagerGroupGetResponseSenderModel () {
    return this._ManagerGroupGetResponseSenderModel
  }

  /**
   * Get ManagerGroupsGetAllRequest model
   * @this {!Client}
   * @returns {!ManagerGroupsGetAllRequestModel} ManagerGroupsGetAllRequest sender model
   */
  get ManagerGroupsGetAllRequestSenderModel () {
    return this._ManagerGroupsGetAllRequestSenderModel
  }

  /**
   * Get ManagerGroupsGetAllResponse model
   * @this {!Client}
   * @returns {!ManagerGroupsGetAllResponseModel} ManagerGroupsGetAllResponse sender model
   */
  get ManagerGroupsGetAllResponseSenderModel () {
    return this._ManagerGroupsGetAllResponseSenderModel
  }

  /**
   * Get ManagerGroupCreateRequest model
   * @this {!Client}
   * @returns {!ManagerGroupCreateRequestModel} ManagerGroupCreateRequest sender model
   */
  get ManagerGroupCreateRequestSenderModel () {
    return this._ManagerGroupCreateRequestSenderModel
  }

  /**
   * Get ManagerGroupCreateResponse model
   * @this {!Client}
   * @returns {!ManagerGroupCreateResponseModel} ManagerGroupCreateResponse sender model
   */
  get ManagerGroupCreateResponseSenderModel () {
    return this._ManagerGroupCreateResponseSenderModel
  }

  /**
   * Get ManagerGroupModifyRequest model
   * @this {!Client}
   * @returns {!ManagerGroupModifyRequestModel} ManagerGroupModifyRequest sender model
   */
  get ManagerGroupModifyRequestSenderModel () {
    return this._ManagerGroupModifyRequestSenderModel
  }

  /**
   * Get ManagerGroupModifyResponse model
   * @this {!Client}
   * @returns {!ManagerGroupModifyResponseModel} ManagerGroupModifyResponse sender model
   */
  get ManagerGroupModifyResponseSenderModel () {
    return this._ManagerGroupModifyResponseSenderModel
  }

  /**
   * Get ManagerGroupDeleteRequest model
   * @this {!Client}
   * @returns {!ManagerGroupDeleteRequestModel} ManagerGroupDeleteRequest sender model
   */
  get ManagerGroupDeleteRequestSenderModel () {
    return this._ManagerGroupDeleteRequestSenderModel
  }

  /**
   * Get ManagerGroupDeleteResponse model
   * @this {!Client}
   * @returns {!ManagerGroupDeleteResponseModel} ManagerGroupDeleteResponse sender model
   */
  get ManagerGroupDeleteResponseSenderModel () {
    return this._ManagerGroupDeleteResponseSenderModel
  }

  /**
   * Get ManagerGroupUpdateNotify model
   * @this {!Client}
   * @returns {!ManagerGroupUpdateNotifyModel} ManagerGroupUpdateNotify sender model
   */
  get ManagerGroupUpdateNotifySenderModel () {
    return this._ManagerGroupUpdateNotifySenderModel
  }

  /**
   * Get ManagerGetRequest model
   * @this {!Client}
   * @returns {!ManagerGetRequestModel} ManagerGetRequest sender model
   */
  get ManagerGetRequestSenderModel () {
    return this._ManagerGetRequestSenderModel
  }

  /**
   * Get ManagerGetResponse model
   * @this {!Client}
   * @returns {!ManagerGetResponseModel} ManagerGetResponse sender model
   */
  get ManagerGetResponseSenderModel () {
    return this._ManagerGetResponseSenderModel
  }

  /**
   * Get ManagersGetAllRequest model
   * @this {!Client}
   * @returns {!ManagersGetAllRequestModel} ManagersGetAllRequest sender model
   */
  get ManagersGetAllRequestSenderModel () {
    return this._ManagersGetAllRequestSenderModel
  }

  /**
   * Get ManagersGetAllResponse model
   * @this {!Client}
   * @returns {!ManagersGetAllResponseModel} ManagersGetAllResponse sender model
   */
  get ManagersGetAllResponseSenderModel () {
    return this._ManagersGetAllResponseSenderModel
  }

  /**
   * Get ManagerCreateRequest model
   * @this {!Client}
   * @returns {!ManagerCreateRequestModel} ManagerCreateRequest sender model
   */
  get ManagerCreateRequestSenderModel () {
    return this._ManagerCreateRequestSenderModel
  }

  /**
   * Get ManagerCreateResponse model
   * @this {!Client}
   * @returns {!ManagerCreateResponseModel} ManagerCreateResponse sender model
   */
  get ManagerCreateResponseSenderModel () {
    return this._ManagerCreateResponseSenderModel
  }

  /**
   * Get ManagerModifyRequest model
   * @this {!Client}
   * @returns {!ManagerModifyRequestModel} ManagerModifyRequest sender model
   */
  get ManagerModifyRequestSenderModel () {
    return this._ManagerModifyRequestSenderModel
  }

  /**
   * Get ManagerModifyResponse model
   * @this {!Client}
   * @returns {!ManagerModifyResponseModel} ManagerModifyResponse sender model
   */
  get ManagerModifyResponseSenderModel () {
    return this._ManagerModifyResponseSenderModel
  }

  /**
   * Get ManagerDeleteRequest model
   * @this {!Client}
   * @returns {!ManagerDeleteRequestModel} ManagerDeleteRequest sender model
   */
  get ManagerDeleteRequestSenderModel () {
    return this._ManagerDeleteRequestSenderModel
  }

  /**
   * Get ManagerDeleteResponse model
   * @this {!Client}
   * @returns {!ManagerDeleteResponseModel} ManagerDeleteResponse sender model
   */
  get ManagerDeleteResponseSenderModel () {
    return this._ManagerDeleteResponseSenderModel
  }

  /**
   * Get ManagerUpdateNotify model
   * @this {!Client}
   * @returns {!ManagerUpdateNotifyModel} ManagerUpdateNotify sender model
   */
  get ManagerUpdateNotifySenderModel () {
    return this._ManagerUpdateNotifySenderModel
  }

  /**
   * Get PlatformGetRequest model
   * @this {!Client}
   * @returns {!PlatformGetRequestModel} PlatformGetRequest sender model
   */
  get PlatformGetRequestSenderModel () {
    return this._PlatformGetRequestSenderModel
  }

  /**
   * Get PlatformGetResponse model
   * @this {!Client}
   * @returns {!PlatformGetResponseModel} PlatformGetResponse sender model
   */
  get PlatformGetResponseSenderModel () {
    return this._PlatformGetResponseSenderModel
  }

  /**
   * Get PlatformsGetAllRequest model
   * @this {!Client}
   * @returns {!PlatformsGetAllRequestModel} PlatformsGetAllRequest sender model
   */
  get PlatformsGetAllRequestSenderModel () {
    return this._PlatformsGetAllRequestSenderModel
  }

  /**
   * Get PlatformsGetAllResponse model
   * @this {!Client}
   * @returns {!PlatformsGetAllResponseModel} PlatformsGetAllResponse sender model
   */
  get PlatformsGetAllResponseSenderModel () {
    return this._PlatformsGetAllResponseSenderModel
  }

  /**
   * Get PlatformCreateRequest model
   * @this {!Client}
   * @returns {!PlatformCreateRequestModel} PlatformCreateRequest sender model
   */
  get PlatformCreateRequestSenderModel () {
    return this._PlatformCreateRequestSenderModel
  }

  /**
   * Get PlatformCreateResponse model
   * @this {!Client}
   * @returns {!PlatformCreateResponseModel} PlatformCreateResponse sender model
   */
  get PlatformCreateResponseSenderModel () {
    return this._PlatformCreateResponseSenderModel
  }

  /**
   * Get PlatformModifyRequest model
   * @this {!Client}
   * @returns {!PlatformModifyRequestModel} PlatformModifyRequest sender model
   */
  get PlatformModifyRequestSenderModel () {
    return this._PlatformModifyRequestSenderModel
  }

  /**
   * Get PlatformModifyResponse model
   * @this {!Client}
   * @returns {!PlatformModifyResponseModel} PlatformModifyResponse sender model
   */
  get PlatformModifyResponseSenderModel () {
    return this._PlatformModifyResponseSenderModel
  }

  /**
   * Get PlatformDeleteRequest model
   * @this {!Client}
   * @returns {!PlatformDeleteRequestModel} PlatformDeleteRequest sender model
   */
  get PlatformDeleteRequestSenderModel () {
    return this._PlatformDeleteRequestSenderModel
  }

  /**
   * Get PlatformDeleteResponse model
   * @this {!Client}
   * @returns {!PlatformDeleteResponseModel} PlatformDeleteResponse sender model
   */
  get PlatformDeleteResponseSenderModel () {
    return this._PlatformDeleteResponseSenderModel
  }

  /**
   * Get PlatformUpdateNotify model
   * @this {!Client}
   * @returns {!PlatformUpdateNotifyModel} PlatformUpdateNotify sender model
   */
  get PlatformUpdateNotifySenderModel () {
    return this._PlatformUpdateNotifySenderModel
  }

  /**
   * Get AccountGroupGetRequest model
   * @this {!Client}
   * @returns {!AccountGroupGetRequestModel} AccountGroupGetRequest sender model
   */
  get AccountGroupGetRequestSenderModel () {
    return this._AccountGroupGetRequestSenderModel
  }

  /**
   * Get AccountGroupGetResponse model
   * @this {!Client}
   * @returns {!AccountGroupGetResponseModel} AccountGroupGetResponse sender model
   */
  get AccountGroupGetResponseSenderModel () {
    return this._AccountGroupGetResponseSenderModel
  }

  /**
   * Get AccountGroupsGetAllRequest model
   * @this {!Client}
   * @returns {!AccountGroupsGetAllRequestModel} AccountGroupsGetAllRequest sender model
   */
  get AccountGroupsGetAllRequestSenderModel () {
    return this._AccountGroupsGetAllRequestSenderModel
  }

  /**
   * Get AccountGroupsGetAllResponse model
   * @this {!Client}
   * @returns {!AccountGroupsGetAllResponseModel} AccountGroupsGetAllResponse sender model
   */
  get AccountGroupsGetAllResponseSenderModel () {
    return this._AccountGroupsGetAllResponseSenderModel
  }

  /**
   * Get AccountGroupCreateRequest model
   * @this {!Client}
   * @returns {!AccountGroupCreateRequestModel} AccountGroupCreateRequest sender model
   */
  get AccountGroupCreateRequestSenderModel () {
    return this._AccountGroupCreateRequestSenderModel
  }

  /**
   * Get AccountGroupCreateResponse model
   * @this {!Client}
   * @returns {!AccountGroupCreateResponseModel} AccountGroupCreateResponse sender model
   */
  get AccountGroupCreateResponseSenderModel () {
    return this._AccountGroupCreateResponseSenderModel
  }

  /**
   * Get AccountGroupModifyRequest model
   * @this {!Client}
   * @returns {!AccountGroupModifyRequestModel} AccountGroupModifyRequest sender model
   */
  get AccountGroupModifyRequestSenderModel () {
    return this._AccountGroupModifyRequestSenderModel
  }

  /**
   * Get AccountGroupModifyResponse model
   * @this {!Client}
   * @returns {!AccountGroupModifyResponseModel} AccountGroupModifyResponse sender model
   */
  get AccountGroupModifyResponseSenderModel () {
    return this._AccountGroupModifyResponseSenderModel
  }

  /**
   * Get AccountGroupDeleteRequest model
   * @this {!Client}
   * @returns {!AccountGroupDeleteRequestModel} AccountGroupDeleteRequest sender model
   */
  get AccountGroupDeleteRequestSenderModel () {
    return this._AccountGroupDeleteRequestSenderModel
  }

  /**
   * Get AccountGroupDeleteResponse model
   * @this {!Client}
   * @returns {!AccountGroupDeleteResponseModel} AccountGroupDeleteResponse sender model
   */
  get AccountGroupDeleteResponseSenderModel () {
    return this._AccountGroupDeleteResponseSenderModel
  }

  /**
   * Get AccountGroupUpdateNotify model
   * @this {!Client}
   * @returns {!AccountGroupUpdateNotifyModel} AccountGroupUpdateNotify sender model
   */
  get AccountGroupUpdateNotifySenderModel () {
    return this._AccountGroupUpdateNotifySenderModel
  }

  /**
   * Get AccountGetRequest model
   * @this {!Client}
   * @returns {!AccountGetRequestModel} AccountGetRequest sender model
   */
  get AccountGetRequestSenderModel () {
    return this._AccountGetRequestSenderModel
  }

  /**
   * Get AccountGetResponse model
   * @this {!Client}
   * @returns {!AccountGetResponseModel} AccountGetResponse sender model
   */
  get AccountGetResponseSenderModel () {
    return this._AccountGetResponseSenderModel
  }

  /**
   * Get AccountsGetAllRequest model
   * @this {!Client}
   * @returns {!AccountsGetAllRequestModel} AccountsGetAllRequest sender model
   */
  get AccountsGetAllRequestSenderModel () {
    return this._AccountsGetAllRequestSenderModel
  }

  /**
   * Get AccountsGetAllResponse model
   * @this {!Client}
   * @returns {!AccountsGetAllResponseModel} AccountsGetAllResponse sender model
   */
  get AccountsGetAllResponseSenderModel () {
    return this._AccountsGetAllResponseSenderModel
  }

  /**
   * Get AccountCreateRequest model
   * @this {!Client}
   * @returns {!AccountCreateRequestModel} AccountCreateRequest sender model
   */
  get AccountCreateRequestSenderModel () {
    return this._AccountCreateRequestSenderModel
  }

  /**
   * Get AccountCreateResponse model
   * @this {!Client}
   * @returns {!AccountCreateResponseModel} AccountCreateResponse sender model
   */
  get AccountCreateResponseSenderModel () {
    return this._AccountCreateResponseSenderModel
  }

  /**
   * Get AccountSocialTradingMasterCreateRequest model
   * @this {!Client}
   * @returns {!AccountSocialTradingMasterCreateRequestModel} AccountSocialTradingMasterCreateRequest sender model
   */
  get AccountSocialTradingMasterCreateRequestSenderModel () {
    return this._AccountSocialTradingMasterCreateRequestSenderModel
  }

  /**
   * Get AccountSocialTradingMasterCreateResponse model
   * @this {!Client}
   * @returns {!AccountSocialTradingMasterCreateResponseModel} AccountSocialTradingMasterCreateResponse sender model
   */
  get AccountSocialTradingMasterCreateResponseSenderModel () {
    return this._AccountSocialTradingMasterCreateResponseSenderModel
  }

  /**
   * Get AccountSocialTradingInvestorCreateRequest model
   * @this {!Client}
   * @returns {!AccountSocialTradingInvestorCreateRequestModel} AccountSocialTradingInvestorCreateRequest sender model
   */
  get AccountSocialTradingInvestorCreateRequestSenderModel () {
    return this._AccountSocialTradingInvestorCreateRequestSenderModel
  }

  /**
   * Get AccountSocialTradingInvestorCreateResponse model
   * @this {!Client}
   * @returns {!AccountSocialTradingInvestorCreateResponseModel} AccountSocialTradingInvestorCreateResponse sender model
   */
  get AccountSocialTradingInvestorCreateResponseSenderModel () {
    return this._AccountSocialTradingInvestorCreateResponseSenderModel
  }

  /**
   * Get AccountModifyRequest model
   * @this {!Client}
   * @returns {!AccountModifyRequestModel} AccountModifyRequest sender model
   */
  get AccountModifyRequestSenderModel () {
    return this._AccountModifyRequestSenderModel
  }

  /**
   * Get AccountModifyResponse model
   * @this {!Client}
   * @returns {!AccountModifyResponseModel} AccountModifyResponse sender model
   */
  get AccountModifyResponseSenderModel () {
    return this._AccountModifyResponseSenderModel
  }

  /**
   * Get AccountDeleteRequest model
   * @this {!Client}
   * @returns {!AccountDeleteRequestModel} AccountDeleteRequest sender model
   */
  get AccountDeleteRequestSenderModel () {
    return this._AccountDeleteRequestSenderModel
  }

  /**
   * Get AccountDeleteResponse model
   * @this {!Client}
   * @returns {!AccountDeleteResponseModel} AccountDeleteResponse sender model
   */
  get AccountDeleteResponseSenderModel () {
    return this._AccountDeleteResponseSenderModel
  }

  /**
   * Get DemoAccountsDeleteRequest model
   * @this {!Client}
   * @returns {!DemoAccountsDeleteRequestModel} DemoAccountsDeleteRequest sender model
   */
  get DemoAccountsDeleteRequestSenderModel () {
    return this._DemoAccountsDeleteRequestSenderModel
  }

  /**
   * Get DemoAccountsDeleteResponse model
   * @this {!Client}
   * @returns {!DemoAccountsDeleteResponseModel} DemoAccountsDeleteResponse sender model
   */
  get DemoAccountsDeleteResponseSenderModel () {
    return this._DemoAccountsDeleteResponseSenderModel
  }

  /**
   * Get AccountUpgradeToSocialTradingMasterRequest model
   * @this {!Client}
   * @returns {!AccountUpgradeToSocialTradingMasterRequestModel} AccountUpgradeToSocialTradingMasterRequest sender model
   */
  get AccountUpgradeToSocialTradingMasterRequestSenderModel () {
    return this._AccountUpgradeToSocialTradingMasterRequestSenderModel
  }

  /**
   * Get AccountUpgradeToSocialTradingMasterResponse model
   * @this {!Client}
   * @returns {!AccountUpgradeToSocialTradingMasterResponseModel} AccountUpgradeToSocialTradingMasterResponse sender model
   */
  get AccountUpgradeToSocialTradingMasterResponseSenderModel () {
    return this._AccountUpgradeToSocialTradingMasterResponseSenderModel
  }

  /**
   * Get AccountUpgradeToSocialTradingInvestorRequest model
   * @this {!Client}
   * @returns {!AccountUpgradeToSocialTradingInvestorRequestModel} AccountUpgradeToSocialTradingInvestorRequest sender model
   */
  get AccountUpgradeToSocialTradingInvestorRequestSenderModel () {
    return this._AccountUpgradeToSocialTradingInvestorRequestSenderModel
  }

  /**
   * Get AccountUpgradeToSocialTradingInvestorResponse model
   * @this {!Client}
   * @returns {!AccountUpgradeToSocialTradingInvestorResponseModel} AccountUpgradeToSocialTradingInvestorResponse sender model
   */
  get AccountUpgradeToSocialTradingInvestorResponseSenderModel () {
    return this._AccountUpgradeToSocialTradingInvestorResponseSenderModel
  }

  /**
   * Get SocialTradingInvestorAccountToRegularRequest model
   * @this {!Client}
   * @returns {!SocialTradingInvestorAccountToRegularRequestModel} SocialTradingInvestorAccountToRegularRequest sender model
   */
  get SocialTradingInvestorAccountToRegularRequestSenderModel () {
    return this._SocialTradingInvestorAccountToRegularRequestSenderModel
  }

  /**
   * Get SocialTradingInvestorAccountToRegularResponse model
   * @this {!Client}
   * @returns {!SocialTradingInvestorAccountToRegularResponseModel} SocialTradingInvestorAccountToRegularResponse sender model
   */
  get SocialTradingInvestorAccountToRegularResponseSenderModel () {
    return this._SocialTradingInvestorAccountToRegularResponseSenderModel
  }

  /**
   * Get SocialTradingMasterAccountToRegularRequest model
   * @this {!Client}
   * @returns {!SocialTradingMasterAccountToRegularRequestModel} SocialTradingMasterAccountToRegularRequest sender model
   */
  get SocialTradingMasterAccountToRegularRequestSenderModel () {
    return this._SocialTradingMasterAccountToRegularRequestSenderModel
  }

  /**
   * Get SocialTradingMasterAccountToRegularResponse model
   * @this {!Client}
   * @returns {!SocialTradingMasterAccountToRegularResponseModel} SocialTradingMasterAccountToRegularResponse sender model
   */
  get SocialTradingMasterAccountToRegularResponseSenderModel () {
    return this._SocialTradingMasterAccountToRegularResponseSenderModel
  }

  /**
   * Get AccountUpdateNotify model
   * @this {!Client}
   * @returns {!AccountUpdateNotifyModel} AccountUpdateNotify sender model
   */
  get AccountUpdateNotifySenderModel () {
    return this._AccountUpdateNotifySenderModel
  }

  /**
   * Get MarginGetRequest model
   * @this {!Client}
   * @returns {!MarginGetRequestModel} MarginGetRequest sender model
   */
  get MarginGetRequestSenderModel () {
    return this._MarginGetRequestSenderModel
  }

  /**
   * Get MarginGetResponse model
   * @this {!Client}
   * @returns {!MarginGetResponseModel} MarginGetResponse sender model
   */
  get MarginGetResponseSenderModel () {
    return this._MarginGetResponseSenderModel
  }

  /**
   * Get MarginsGetAllRequest model
   * @this {!Client}
   * @returns {!MarginsGetAllRequestModel} MarginsGetAllRequest sender model
   */
  get MarginsGetAllRequestSenderModel () {
    return this._MarginsGetAllRequestSenderModel
  }

  /**
   * Get MarginsGetAllResponse model
   * @this {!Client}
   * @returns {!MarginsGetAllResponseModel} MarginsGetAllResponse sender model
   */
  get MarginsGetAllResponseSenderModel () {
    return this._MarginsGetAllResponseSenderModel
  }

  /**
   * Get MarginUpdateNotify model
   * @this {!Client}
   * @returns {!MarginUpdateNotifyModel} MarginUpdateNotify sender model
   */
  get MarginUpdateNotifySenderModel () {
    return this._MarginUpdateNotifySenderModel
  }

  /**
   * Get MarginCallNotify model
   * @this {!Client}
   * @returns {!MarginCallNotifyModel} MarginCallNotify sender model
   */
  get MarginCallNotifySenderModel () {
    return this._MarginCallNotifySenderModel
  }

  /**
   * Get StopOutNotify model
   * @this {!Client}
   * @returns {!StopOutNotifyModel} StopOutNotify sender model
   */
  get StopOutNotifySenderModel () {
    return this._StopOutNotifySenderModel
  }

  /**
   * Get AssetGetRequest model
   * @this {!Client}
   * @returns {!AssetGetRequestModel} AssetGetRequest sender model
   */
  get AssetGetRequestSenderModel () {
    return this._AssetGetRequestSenderModel
  }

  /**
   * Get AssetGetResponse model
   * @this {!Client}
   * @returns {!AssetGetResponseModel} AssetGetResponse sender model
   */
  get AssetGetResponseSenderModel () {
    return this._AssetGetResponseSenderModel
  }

  /**
   * Get AssetsGetRequest model
   * @this {!Client}
   * @returns {!AssetsGetRequestModel} AssetsGetRequest sender model
   */
  get AssetsGetRequestSenderModel () {
    return this._AssetsGetRequestSenderModel
  }

  /**
   * Get AssetsGetResponse model
   * @this {!Client}
   * @returns {!AssetsGetResponseModel} AssetsGetResponse sender model
   */
  get AssetsGetResponseSenderModel () {
    return this._AssetsGetResponseSenderModel
  }

  /**
   * Get AssetsGetAllRequest model
   * @this {!Client}
   * @returns {!AssetsGetAllRequestModel} AssetsGetAllRequest sender model
   */
  get AssetsGetAllRequestSenderModel () {
    return this._AssetsGetAllRequestSenderModel
  }

  /**
   * Get AssetsGetAllResponse model
   * @this {!Client}
   * @returns {!AssetsGetAllResponseModel} AssetsGetAllResponse sender model
   */
  get AssetsGetAllResponseSenderModel () {
    return this._AssetsGetAllResponseSenderModel
  }

  /**
   * Get AssetUpdateNotify model
   * @this {!Client}
   * @returns {!AssetUpdateNotifyModel} AssetUpdateNotify sender model
   */
  get AssetUpdateNotifySenderModel () {
    return this._AssetUpdateNotifySenderModel
  }

  /**
   * Get OrderGetRequest model
   * @this {!Client}
   * @returns {!OrderGetRequestModel} OrderGetRequest sender model
   */
  get OrderGetRequestSenderModel () {
    return this._OrderGetRequestSenderModel
  }

  /**
   * Get OrderGetResponse model
   * @this {!Client}
   * @returns {!OrderGetResponseModel} OrderGetResponse sender model
   */
  get OrderGetResponseSenderModel () {
    return this._OrderGetResponseSenderModel
  }

  /**
   * Get OrdersGetRequest model
   * @this {!Client}
   * @returns {!OrdersGetRequestModel} OrdersGetRequest sender model
   */
  get OrdersGetRequestSenderModel () {
    return this._OrdersGetRequestSenderModel
  }

  /**
   * Get OrdersGetResponse model
   * @this {!Client}
   * @returns {!OrdersGetResponseModel} OrdersGetResponse sender model
   */
  get OrdersGetResponseSenderModel () {
    return this._OrdersGetResponseSenderModel
  }

  /**
   * Get OrdersGetAllRequest model
   * @this {!Client}
   * @returns {!OrdersGetAllRequestModel} OrdersGetAllRequest sender model
   */
  get OrdersGetAllRequestSenderModel () {
    return this._OrdersGetAllRequestSenderModel
  }

  /**
   * Get OrdersGetAllResponse model
   * @this {!Client}
   * @returns {!OrdersGetAllResponseModel} OrdersGetAllResponse sender model
   */
  get OrdersGetAllResponseSenderModel () {
    return this._OrdersGetAllResponseSenderModel
  }

  /**
   * Get OrderCreateRequest model
   * @this {!Client}
   * @returns {!OrderCreateRequestModel} OrderCreateRequest sender model
   */
  get OrderCreateRequestSenderModel () {
    return this._OrderCreateRequestSenderModel
  }

  /**
   * Get OrderCreateResponse model
   * @this {!Client}
   * @returns {!OrderCreateResponseModel} OrderCreateResponse sender model
   */
  get OrderCreateResponseSenderModel () {
    return this._OrderCreateResponseSenderModel
  }

  /**
   * Get OrderModifyRequest model
   * @this {!Client}
   * @returns {!OrderModifyRequestModel} OrderModifyRequest sender model
   */
  get OrderModifyRequestSenderModel () {
    return this._OrderModifyRequestSenderModel
  }

  /**
   * Get OrderModifyResponse model
   * @this {!Client}
   * @returns {!OrderModifyResponseModel} OrderModifyResponse sender model
   */
  get OrderModifyResponseSenderModel () {
    return this._OrderModifyResponseSenderModel
  }

  /**
   * Get OrderActivateRequest model
   * @this {!Client}
   * @returns {!OrderActivateRequestModel} OrderActivateRequest sender model
   */
  get OrderActivateRequestSenderModel () {
    return this._OrderActivateRequestSenderModel
  }

  /**
   * Get OrderActivateResponse model
   * @this {!Client}
   * @returns {!OrderActivateResponseModel} OrderActivateResponse sender model
   */
  get OrderActivateResponseSenderModel () {
    return this._OrderActivateResponseSenderModel
  }

  /**
   * Get OrderCancelRequest model
   * @this {!Client}
   * @returns {!OrderCancelRequestModel} OrderCancelRequest sender model
   */
  get OrderCancelRequestSenderModel () {
    return this._OrderCancelRequestSenderModel
  }

  /**
   * Get OrderCancelResponse model
   * @this {!Client}
   * @returns {!OrderCancelResponseModel} OrderCancelResponse sender model
   */
  get OrderCancelResponseSenderModel () {
    return this._OrderCancelResponseSenderModel
  }

  /**
   * Get OrderCancelAllRequest model
   * @this {!Client}
   * @returns {!OrderCancelAllRequestModel} OrderCancelAllRequest sender model
   */
  get OrderCancelAllRequestSenderModel () {
    return this._OrderCancelAllRequestSenderModel
  }

  /**
   * Get OrderCancelAllResponse model
   * @this {!Client}
   * @returns {!OrderCancelAllResponseModel} OrderCancelAllResponse sender model
   */
  get OrderCancelAllResponseSenderModel () {
    return this._OrderCancelAllResponseSenderModel
  }

  /**
   * Get OrderCancelAllByIdRequest model
   * @this {!Client}
   * @returns {!OrderCancelAllByIdRequestModel} OrderCancelAllByIdRequest sender model
   */
  get OrderCancelAllByIdRequestSenderModel () {
    return this._OrderCancelAllByIdRequestSenderModel
  }

  /**
   * Get OrderCloseRequest model
   * @this {!Client}
   * @returns {!OrderCloseRequestModel} OrderCloseRequest sender model
   */
  get OrderCloseRequestSenderModel () {
    return this._OrderCloseRequestSenderModel
  }

  /**
   * Get OrderCloseResponse model
   * @this {!Client}
   * @returns {!OrderCloseResponseModel} OrderCloseResponse sender model
   */
  get OrderCloseResponseSenderModel () {
    return this._OrderCloseResponseSenderModel
  }

  /**
   * Get OrderCloseAllRequest model
   * @this {!Client}
   * @returns {!OrderCloseAllRequestModel} OrderCloseAllRequest sender model
   */
  get OrderCloseAllRequestSenderModel () {
    return this._OrderCloseAllRequestSenderModel
  }

  /**
   * Get OrderCloseAllResponse model
   * @this {!Client}
   * @returns {!OrderCloseAllResponseModel} OrderCloseAllResponse sender model
   */
  get OrderCloseAllResponseSenderModel () {
    return this._OrderCloseAllResponseSenderModel
  }

  /**
   * Get OrderCloseAllByIdRequest model
   * @this {!Client}
   * @returns {!OrderCloseAllByIdRequestModel} OrderCloseAllByIdRequest sender model
   */
  get OrderCloseAllByIdRequestSenderModel () {
    return this._OrderCloseAllByIdRequestSenderModel
  }

  /**
   * Get OrderCloseByRequest model
   * @this {!Client}
   * @returns {!OrderCloseByRequestModel} OrderCloseByRequest sender model
   */
  get OrderCloseByRequestSenderModel () {
    return this._OrderCloseByRequestSenderModel
  }

  /**
   * Get OrderCloseByResponse model
   * @this {!Client}
   * @returns {!OrderCloseByResponseModel} OrderCloseByResponse sender model
   */
  get OrderCloseByResponseSenderModel () {
    return this._OrderCloseByResponseSenderModel
  }

  /**
   * Get OrderCloseByAllRequest model
   * @this {!Client}
   * @returns {!OrderCloseByAllRequestModel} OrderCloseByAllRequest sender model
   */
  get OrderCloseByAllRequestSenderModel () {
    return this._OrderCloseByAllRequestSenderModel
  }

  /**
   * Get OrderCloseByAllResponse model
   * @this {!Client}
   * @returns {!OrderCloseByAllResponseModel} OrderCloseByAllResponse sender model
   */
  get OrderCloseByAllResponseSenderModel () {
    return this._OrderCloseByAllResponseSenderModel
  }

  /**
   * Get OrderCancelOrCloseRequest model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseRequestModel} OrderCancelOrCloseRequest sender model
   */
  get OrderCancelOrCloseRequestSenderModel () {
    return this._OrderCancelOrCloseRequestSenderModel
  }

  /**
   * Get OrderCancelOrCloseResponse model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseResponseModel} OrderCancelOrCloseResponse sender model
   */
  get OrderCancelOrCloseResponseSenderModel () {
    return this._OrderCancelOrCloseResponseSenderModel
  }

  /**
   * Get OrderCancelOrCloseAllRequest model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseAllRequestModel} OrderCancelOrCloseAllRequest sender model
   */
  get OrderCancelOrCloseAllRequestSenderModel () {
    return this._OrderCancelOrCloseAllRequestSenderModel
  }

  /**
   * Get OrderCancelOrCloseAllResponse model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseAllResponseModel} OrderCancelOrCloseAllResponse sender model
   */
  get OrderCancelOrCloseAllResponseSenderModel () {
    return this._OrderCancelOrCloseAllResponseSenderModel
  }

  /**
   * Get OrderUpdateNotify model
   * @this {!Client}
   * @returns {!OrderUpdateNotifyModel} OrderUpdateNotify sender model
   */
  get OrderUpdateNotifySenderModel () {
    return this._OrderUpdateNotifySenderModel
  }

  /**
   * Get PositionGetRequest model
   * @this {!Client}
   * @returns {!PositionGetRequestModel} PositionGetRequest sender model
   */
  get PositionGetRequestSenderModel () {
    return this._PositionGetRequestSenderModel
  }

  /**
   * Get PositionGetResponse model
   * @this {!Client}
   * @returns {!PositionGetResponseModel} PositionGetResponse sender model
   */
  get PositionGetResponseSenderModel () {
    return this._PositionGetResponseSenderModel
  }

  /**
   * Get PositionsGetRequest model
   * @this {!Client}
   * @returns {!PositionsGetRequestModel} PositionsGetRequest sender model
   */
  get PositionsGetRequestSenderModel () {
    return this._PositionsGetRequestSenderModel
  }

  /**
   * Get PositionsGetResponse model
   * @this {!Client}
   * @returns {!PositionsGetResponseModel} PositionsGetResponse sender model
   */
  get PositionsGetResponseSenderModel () {
    return this._PositionsGetResponseSenderModel
  }

  /**
   * Get PositionsGetAllRequest model
   * @this {!Client}
   * @returns {!PositionsGetAllRequestModel} PositionsGetAllRequest sender model
   */
  get PositionsGetAllRequestSenderModel () {
    return this._PositionsGetAllRequestSenderModel
  }

  /**
   * Get PositionsGetAllResponse model
   * @this {!Client}
   * @returns {!PositionsGetAllResponseModel} PositionsGetAllResponse sender model
   */
  get PositionsGetAllResponseSenderModel () {
    return this._PositionsGetAllResponseSenderModel
  }

  /**
   * Get PositionUpdateNotify model
   * @this {!Client}
   * @returns {!PositionUpdateNotifyModel} PositionUpdateNotify sender model
   */
  get PositionUpdateNotifySenderModel () {
    return this._PositionUpdateNotifySenderModel
  }

  /**
   * Get TradeHistoryRequest model
   * @this {!Client}
   * @returns {!TradeHistoryRequestModel} TradeHistoryRequest sender model
   */
  get TradeHistoryRequestSenderModel () {
    return this._TradeHistoryRequestSenderModel
  }

  /**
   * Get TradeHistoryResponse model
   * @this {!Client}
   * @returns {!TradeHistoryResponseModel} TradeHistoryResponse sender model
   */
  get TradeHistoryResponseSenderModel () {
    return this._TradeHistoryResponseSenderModel
  }

  /**
   * Get TradeHistoryFilterRequest model
   * @this {!Client}
   * @returns {!TradeHistoryFilterRequestModel} TradeHistoryFilterRequest sender model
   */
  get TradeHistoryFilterRequestSenderModel () {
    return this._TradeHistoryFilterRequestSenderModel
  }

  /**
   * Get TradeHistoryByIdRequest model
   * @this {!Client}
   * @returns {!TradeHistoryByIdRequestModel} TradeHistoryByIdRequest sender model
   */
  get TradeHistoryByIdRequestSenderModel () {
    return this._TradeHistoryByIdRequestSenderModel
  }

  /**
   * Get TradeHistoryByIdResponse model
   * @this {!Client}
   * @returns {!TradeHistoryByIdResponseModel} TradeHistoryByIdResponse sender model
   */
  get TradeHistoryByIdResponseSenderModel () {
    return this._TradeHistoryByIdResponseSenderModel
  }

  /**
   * Get TradeHistoryUpdateNotify model
   * @this {!Client}
   * @returns {!TradeHistoryUpdateNotifyModel} TradeHistoryUpdateNotify sender model
   */
  get TradeHistoryUpdateNotifySenderModel () {
    return this._TradeHistoryUpdateNotifySenderModel
  }

  /**
   * Get TestRolloverDumpRequest model
   * @this {!Client}
   * @returns {!TestRolloverDumpRequestModel} TestRolloverDumpRequest sender model
   */
  get TestRolloverDumpRequestSenderModel () {
    return this._TestRolloverDumpRequestSenderModel
  }

  /**
   * Get TestRolloverDumpResponse model
   * @this {!Client}
   * @returns {!TestRolloverDumpResponseModel} TestRolloverDumpResponse sender model
   */
  get TestRolloverDumpResponseSenderModel () {
    return this._TestRolloverDumpResponseSenderModel
  }

  /**
   * Get Deprecated001 model
   * @this {!Client}
   * @returns {!Deprecated001Model} Deprecated001 sender model
   */
  get Deprecated001SenderModel () {
    return this._Deprecated001SenderModel
  }

  /**
   * Get Deprecated002 model
   * @this {!Client}
   * @returns {!Deprecated002Model} Deprecated002 sender model
   */
  get Deprecated002SenderModel () {
    return this._Deprecated002SenderModel
  }

  /**
   * Get Deprecated003 model
   * @this {!Client}
   * @returns {!Deprecated003Model} Deprecated003 sender model
   */
  get Deprecated003SenderModel () {
    return this._Deprecated003SenderModel
  }

  /**
   * Get Deprecated004 model
   * @this {!Client}
   * @returns {!Deprecated004Model} Deprecated004 sender model
   */
  get Deprecated004SenderModel () {
    return this._Deprecated004SenderModel
  }

  /**
   * Get Deprecated005 model
   * @this {!Client}
   * @returns {!Deprecated005Model} Deprecated005 sender model
   */
  get Deprecated005SenderModel () {
    return this._Deprecated005SenderModel
  }

  /**
   * Get Deprecated006 model
   * @this {!Client}
   * @returns {!Deprecated006Model} Deprecated006 sender model
   */
  get Deprecated006SenderModel () {
    return this._Deprecated006SenderModel
  }

  /**
   * Get Deprecated007 model
   * @this {!Client}
   * @returns {!Deprecated007Model} Deprecated007 sender model
   */
  get Deprecated007SenderModel () {
    return this._Deprecated007SenderModel
  }

  /**
   * Get Deprecated008 model
   * @this {!Client}
   * @returns {!Deprecated008Model} Deprecated008 sender model
   */
  get Deprecated008SenderModel () {
    return this._Deprecated008SenderModel
  }

  /**
   * Get Deprecated009 model
   * @this {!Client}
   * @returns {!Deprecated009Model} Deprecated009 sender model
   */
  get Deprecated009SenderModel () {
    return this._Deprecated009SenderModel
  }

  /**
   * Get Deprecated010 model
   * @this {!Client}
   * @returns {!Deprecated010Model} Deprecated010 sender model
   */
  get Deprecated010SenderModel () {
    return this._Deprecated010SenderModel
  }

  /**
   * Get Deprecated011 model
   * @this {!Client}
   * @returns {!Deprecated011Model} Deprecated011 sender model
   */
  get Deprecated011SenderModel () {
    return this._Deprecated011SenderModel
  }

  /**
   * Get Deprecated012 model
   * @this {!Client}
   * @returns {!Deprecated012Model} Deprecated012 sender model
   */
  get Deprecated012SenderModel () {
    return this._Deprecated012SenderModel
  }

  /**
   * Get Deprecated013 model
   * @this {!Client}
   * @returns {!Deprecated013Model} Deprecated013 sender model
   */
  get Deprecated013SenderModel () {
    return this._Deprecated013SenderModel
  }

  /**
   * Get DepositAccountRequest model
   * @this {!Client}
   * @returns {!DepositAccountRequestModel} DepositAccountRequest sender model
   */
  get DepositAccountRequestSenderModel () {
    return this._DepositAccountRequestSenderModel
  }

  /**
   * Get DepositAccountResponse model
   * @this {!Client}
   * @returns {!DepositAccountResponseModel} DepositAccountResponse sender model
   */
  get DepositAccountResponseSenderModel () {
    return this._DepositAccountResponseSenderModel
  }

  /**
   * Get CreditAccountRequest model
   * @this {!Client}
   * @returns {!CreditAccountRequestModel} CreditAccountRequest sender model
   */
  get CreditAccountRequestSenderModel () {
    return this._CreditAccountRequestSenderModel
  }

  /**
   * Get CreditAccountResponse model
   * @this {!Client}
   * @returns {!CreditAccountResponseModel} CreditAccountResponse sender model
   */
  get CreditAccountResponseSenderModel () {
    return this._CreditAccountResponseSenderModel
  }

  /**
   * Get TransferMoneyRequest model
   * @this {!Client}
   * @returns {!TransferMoneyRequestModel} TransferMoneyRequest sender model
   */
  get TransferMoneyRequestSenderModel () {
    return this._TransferMoneyRequestSenderModel
  }

  /**
   * Get TransferMoneyResponse model
   * @this {!Client}
   * @returns {!TransferMoneyResponseModel} TransferMoneyResponse sender model
   */
  get TransferMoneyResponseSenderModel () {
    return this._TransferMoneyResponseSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleGetRequest model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleGetRequestModel} TradingCalendarRegularRuleGetRequest sender model
   */
  get TradingCalendarRegularRuleGetRequestSenderModel () {
    return this._TradingCalendarRegularRuleGetRequestSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleGetResponse model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleGetResponseModel} TradingCalendarRegularRuleGetResponse sender model
   */
  get TradingCalendarRegularRuleGetResponseSenderModel () {
    return this._TradingCalendarRegularRuleGetResponseSenderModel
  }

  /**
   * Get TradingCalendarRegularRulesGetAllRequest model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRulesGetAllRequestModel} TradingCalendarRegularRulesGetAllRequest sender model
   */
  get TradingCalendarRegularRulesGetAllRequestSenderModel () {
    return this._TradingCalendarRegularRulesGetAllRequestSenderModel
  }

  /**
   * Get TradingCalendarRegularRulesGetAllResponse model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRulesGetAllResponseModel} TradingCalendarRegularRulesGetAllResponse sender model
   */
  get TradingCalendarRegularRulesGetAllResponseSenderModel () {
    return this._TradingCalendarRegularRulesGetAllResponseSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleCreateRequest model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleCreateRequestModel} TradingCalendarRegularRuleCreateRequest sender model
   */
  get TradingCalendarRegularRuleCreateRequestSenderModel () {
    return this._TradingCalendarRegularRuleCreateRequestSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleCreateResponse model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleCreateResponseModel} TradingCalendarRegularRuleCreateResponse sender model
   */
  get TradingCalendarRegularRuleCreateResponseSenderModel () {
    return this._TradingCalendarRegularRuleCreateResponseSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleModifyRequest model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleModifyRequestModel} TradingCalendarRegularRuleModifyRequest sender model
   */
  get TradingCalendarRegularRuleModifyRequestSenderModel () {
    return this._TradingCalendarRegularRuleModifyRequestSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleModifyResponse model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleModifyResponseModel} TradingCalendarRegularRuleModifyResponse sender model
   */
  get TradingCalendarRegularRuleModifyResponseSenderModel () {
    return this._TradingCalendarRegularRuleModifyResponseSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleDeleteRequest model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleDeleteRequestModel} TradingCalendarRegularRuleDeleteRequest sender model
   */
  get TradingCalendarRegularRuleDeleteRequestSenderModel () {
    return this._TradingCalendarRegularRuleDeleteRequestSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleDeleteResponse model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleDeleteResponseModel} TradingCalendarRegularRuleDeleteResponse sender model
   */
  get TradingCalendarRegularRuleDeleteResponseSenderModel () {
    return this._TradingCalendarRegularRuleDeleteResponseSenderModel
  }

  /**
   * Get TradingCalendarRegularRuleUpdateNotify model
   * @this {!Client}
   * @returns {!TradingCalendarRegularRuleUpdateNotifyModel} TradingCalendarRegularRuleUpdateNotify sender model
   */
  get TradingCalendarRegularRuleUpdateNotifySenderModel () {
    return this._TradingCalendarRegularRuleUpdateNotifySenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleGetRequest model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleGetRequestModel} TradingCalendarOffTimeRuleGetRequest sender model
   */
  get TradingCalendarOffTimeRuleGetRequestSenderModel () {
    return this._TradingCalendarOffTimeRuleGetRequestSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleGetResponse model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleGetResponseModel} TradingCalendarOffTimeRuleGetResponse sender model
   */
  get TradingCalendarOffTimeRuleGetResponseSenderModel () {
    return this._TradingCalendarOffTimeRuleGetResponseSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRulesGetAllRequest model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRulesGetAllRequestModel} TradingCalendarOffTimeRulesGetAllRequest sender model
   */
  get TradingCalendarOffTimeRulesGetAllRequestSenderModel () {
    return this._TradingCalendarOffTimeRulesGetAllRequestSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRulesGetAllResponse model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRulesGetAllResponseModel} TradingCalendarOffTimeRulesGetAllResponse sender model
   */
  get TradingCalendarOffTimeRulesGetAllResponseSenderModel () {
    return this._TradingCalendarOffTimeRulesGetAllResponseSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleCreateRequest model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleCreateRequestModel} TradingCalendarOffTimeRuleCreateRequest sender model
   */
  get TradingCalendarOffTimeRuleCreateRequestSenderModel () {
    return this._TradingCalendarOffTimeRuleCreateRequestSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleCreateResponse model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleCreateResponseModel} TradingCalendarOffTimeRuleCreateResponse sender model
   */
  get TradingCalendarOffTimeRuleCreateResponseSenderModel () {
    return this._TradingCalendarOffTimeRuleCreateResponseSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleModifyRequest model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleModifyRequestModel} TradingCalendarOffTimeRuleModifyRequest sender model
   */
  get TradingCalendarOffTimeRuleModifyRequestSenderModel () {
    return this._TradingCalendarOffTimeRuleModifyRequestSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleModifyResponse model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleModifyResponseModel} TradingCalendarOffTimeRuleModifyResponse sender model
   */
  get TradingCalendarOffTimeRuleModifyResponseSenderModel () {
    return this._TradingCalendarOffTimeRuleModifyResponseSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleDeleteRequest model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleDeleteRequestModel} TradingCalendarOffTimeRuleDeleteRequest sender model
   */
  get TradingCalendarOffTimeRuleDeleteRequestSenderModel () {
    return this._TradingCalendarOffTimeRuleDeleteRequestSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleDeleteResponse model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleDeleteResponseModel} TradingCalendarOffTimeRuleDeleteResponse sender model
   */
  get TradingCalendarOffTimeRuleDeleteResponseSenderModel () {
    return this._TradingCalendarOffTimeRuleDeleteResponseSenderModel
  }

  /**
   * Get TradingCalendarOffTimeRuleUpdateNotify model
   * @this {!Client}
   * @returns {!TradingCalendarOffTimeRuleUpdateNotifyModel} TradingCalendarOffTimeRuleUpdateNotify sender model
   */
  get TradingCalendarOffTimeRuleUpdateNotifySenderModel () {
    return this._TradingCalendarOffTimeRuleUpdateNotifySenderModel
  }

  /**
   * Get TradingCalendarRulesGetRequest model
   * @this {!Client}
   * @returns {!TradingCalendarRulesGetRequestModel} TradingCalendarRulesGetRequest sender model
   */
  get TradingCalendarRulesGetRequestSenderModel () {
    return this._TradingCalendarRulesGetRequestSenderModel
  }

  /**
   * Get TradingCalendarRulesGetResponse model
   * @this {!Client}
   * @returns {!TradingCalendarRulesGetResponseModel} TradingCalendarRulesGetResponse sender model
   */
  get TradingCalendarRulesGetResponseSenderModel () {
    return this._TradingCalendarRulesGetResponseSenderModel
  }

  /**
   * Get TradingRouteRulesGetRequest model
   * @this {!Client}
   * @returns {!TradingRouteRulesGetRequestModel} TradingRouteRulesGetRequest sender model
   */
  get TradingRouteRulesGetRequestSenderModel () {
    return this._TradingRouteRulesGetRequestSenderModel
  }

  /**
   * Get TradingRouteRulesGetResponse model
   * @this {!Client}
   * @returns {!TradingRouteRulesGetResponseModel} TradingRouteRulesGetResponse sender model
   */
  get TradingRouteRulesGetResponseSenderModel () {
    return this._TradingRouteRulesGetResponseSenderModel
  }

  /**
   * Get TradingRouteRuleCreateRequest model
   * @this {!Client}
   * @returns {!TradingRouteRuleCreateRequestModel} TradingRouteRuleCreateRequest sender model
   */
  get TradingRouteRuleCreateRequestSenderModel () {
    return this._TradingRouteRuleCreateRequestSenderModel
  }

  /**
   * Get TradingRouteRuleCreateResponse model
   * @this {!Client}
   * @returns {!TradingRouteRuleCreateResponseModel} TradingRouteRuleCreateResponse sender model
   */
  get TradingRouteRuleCreateResponseSenderModel () {
    return this._TradingRouteRuleCreateResponseSenderModel
  }

  /**
   * Get TradingRouteRuleModifyRequest model
   * @this {!Client}
   * @returns {!TradingRouteRuleModifyRequestModel} TradingRouteRuleModifyRequest sender model
   */
  get TradingRouteRuleModifyRequestSenderModel () {
    return this._TradingRouteRuleModifyRequestSenderModel
  }

  /**
   * Get TradingRouteRuleModifyResponse model
   * @this {!Client}
   * @returns {!TradingRouteRuleModifyResponseModel} TradingRouteRuleModifyResponse sender model
   */
  get TradingRouteRuleModifyResponseSenderModel () {
    return this._TradingRouteRuleModifyResponseSenderModel
  }

  /**
   * Get TradingRouteRuleDeleteRequest model
   * @this {!Client}
   * @returns {!TradingRouteRuleDeleteRequestModel} TradingRouteRuleDeleteRequest sender model
   */
  get TradingRouteRuleDeleteRequestSenderModel () {
    return this._TradingRouteRuleDeleteRequestSenderModel
  }

  /**
   * Get TradingRouteRuleDeleteResponse model
   * @this {!Client}
   * @returns {!TradingRouteRuleDeleteResponseModel} TradingRouteRuleDeleteResponse sender model
   */
  get TradingRouteRuleDeleteResponseSenderModel () {
    return this._TradingRouteRuleDeleteResponseSenderModel
  }

  /**
   * Get TradingRouteRuleUpdateNotify model
   * @this {!Client}
   * @returns {!TradingRouteRuleUpdateNotifyModel} TradingRouteRuleUpdateNotify sender model
   */
  get TradingRouteRuleUpdateNotifySenderModel () {
    return this._TradingRouteRuleUpdateNotifySenderModel
  }

  /**
   * Get SymbolMapsGetRequest model
   * @this {!Client}
   * @returns {!SymbolMapsGetRequestModel} SymbolMapsGetRequest sender model
   */
  get SymbolMapsGetRequestSenderModel () {
    return this._SymbolMapsGetRequestSenderModel
  }

  /**
   * Get SymbolMapsGetResponse model
   * @this {!Client}
   * @returns {!SymbolMapsGetResponseModel} SymbolMapsGetResponse sender model
   */
  get SymbolMapsGetResponseSenderModel () {
    return this._SymbolMapsGetResponseSenderModel
  }

  /**
   * Get SymbolMapCreateRequest model
   * @this {!Client}
   * @returns {!SymbolMapCreateRequestModel} SymbolMapCreateRequest sender model
   */
  get SymbolMapCreateRequestSenderModel () {
    return this._SymbolMapCreateRequestSenderModel
  }

  /**
   * Get SymbolMapCreateResponse model
   * @this {!Client}
   * @returns {!SymbolMapCreateResponseModel} SymbolMapCreateResponse sender model
   */
  get SymbolMapCreateResponseSenderModel () {
    return this._SymbolMapCreateResponseSenderModel
  }

  /**
   * Get SymbolMapModifyRequest model
   * @this {!Client}
   * @returns {!SymbolMapModifyRequestModel} SymbolMapModifyRequest sender model
   */
  get SymbolMapModifyRequestSenderModel () {
    return this._SymbolMapModifyRequestSenderModel
  }

  /**
   * Get SymbolMapModifyResponse model
   * @this {!Client}
   * @returns {!SymbolMapModifyResponseModel} SymbolMapModifyResponse sender model
   */
  get SymbolMapModifyResponseSenderModel () {
    return this._SymbolMapModifyResponseSenderModel
  }

  /**
   * Get SymbolMapDeleteRequest model
   * @this {!Client}
   * @returns {!SymbolMapDeleteRequestModel} SymbolMapDeleteRequest sender model
   */
  get SymbolMapDeleteRequestSenderModel () {
    return this._SymbolMapDeleteRequestSenderModel
  }

  /**
   * Get SymbolMapDeleteResponse model
   * @this {!Client}
   * @returns {!SymbolMapDeleteResponseModel} SymbolMapDeleteResponse sender model
   */
  get SymbolMapDeleteResponseSenderModel () {
    return this._SymbolMapDeleteResponseSenderModel
  }

  /**
   * Get SymbolMapUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolMapUpdateNotifyModel} SymbolMapUpdateNotify sender model
   */
  get SymbolMapUpdateNotifySenderModel () {
    return this._SymbolMapUpdateNotifySenderModel
  }

  /**
   * Get GatewaysGetRequest model
   * @this {!Client}
   * @returns {!GatewaysGetRequestModel} GatewaysGetRequest sender model
   */
  get GatewaysGetRequestSenderModel () {
    return this._GatewaysGetRequestSenderModel
  }

  /**
   * Get GatewaysGetResponse model
   * @this {!Client}
   * @returns {!GatewaysGetResponseModel} GatewaysGetResponse sender model
   */
  get GatewaysGetResponseSenderModel () {
    return this._GatewaysGetResponseSenderModel
  }

  /**
   * Get GatewayCreateRequest model
   * @this {!Client}
   * @returns {!GatewayCreateRequestModel} GatewayCreateRequest sender model
   */
  get GatewayCreateRequestSenderModel () {
    return this._GatewayCreateRequestSenderModel
  }

  /**
   * Get GatewayCreateResponse model
   * @this {!Client}
   * @returns {!GatewayCreateResponseModel} GatewayCreateResponse sender model
   */
  get GatewayCreateResponseSenderModel () {
    return this._GatewayCreateResponseSenderModel
  }

  /**
   * Get GatewayModifyRequest model
   * @this {!Client}
   * @returns {!GatewayModifyRequestModel} GatewayModifyRequest sender model
   */
  get GatewayModifyRequestSenderModel () {
    return this._GatewayModifyRequestSenderModel
  }

  /**
   * Get GatewayModifyResponse model
   * @this {!Client}
   * @returns {!GatewayModifyResponseModel} GatewayModifyResponse sender model
   */
  get GatewayModifyResponseSenderModel () {
    return this._GatewayModifyResponseSenderModel
  }

  /**
   * Get GatewayDeleteRequest model
   * @this {!Client}
   * @returns {!GatewayDeleteRequestModel} GatewayDeleteRequest sender model
   */
  get GatewayDeleteRequestSenderModel () {
    return this._GatewayDeleteRequestSenderModel
  }

  /**
   * Get GatewayDeleteResponse model
   * @this {!Client}
   * @returns {!GatewayDeleteResponseModel} GatewayDeleteResponse sender model
   */
  get GatewayDeleteResponseSenderModel () {
    return this._GatewayDeleteResponseSenderModel
  }

  /**
   * Get GatewayUpdateNotify model
   * @this {!Client}
   * @returns {!GatewayUpdateNotifyModel} GatewayUpdateNotify sender model
   */
  get GatewayUpdateNotifySenderModel () {
    return this._GatewayUpdateNotifySenderModel
  }

  /**
   * Get DataFeedsGetRequest model
   * @this {!Client}
   * @returns {!DataFeedsGetRequestModel} DataFeedsGetRequest sender model
   */
  get DataFeedsGetRequestSenderModel () {
    return this._DataFeedsGetRequestSenderModel
  }

  /**
   * Get DataFeedsGetResponse model
   * @this {!Client}
   * @returns {!DataFeedsGetResponseModel} DataFeedsGetResponse sender model
   */
  get DataFeedsGetResponseSenderModel () {
    return this._DataFeedsGetResponseSenderModel
  }

  /**
   * Get DataFeedCreateRequest model
   * @this {!Client}
   * @returns {!DataFeedCreateRequestModel} DataFeedCreateRequest sender model
   */
  get DataFeedCreateRequestSenderModel () {
    return this._DataFeedCreateRequestSenderModel
  }

  /**
   * Get DataFeedCreateResponse model
   * @this {!Client}
   * @returns {!DataFeedCreateResponseModel} DataFeedCreateResponse sender model
   */
  get DataFeedCreateResponseSenderModel () {
    return this._DataFeedCreateResponseSenderModel
  }

  /**
   * Get DataFeedModifyRequest model
   * @this {!Client}
   * @returns {!DataFeedModifyRequestModel} DataFeedModifyRequest sender model
   */
  get DataFeedModifyRequestSenderModel () {
    return this._DataFeedModifyRequestSenderModel
  }

  /**
   * Get DataFeedModifyResponse model
   * @this {!Client}
   * @returns {!DataFeedModifyResponseModel} DataFeedModifyResponse sender model
   */
  get DataFeedModifyResponseSenderModel () {
    return this._DataFeedModifyResponseSenderModel
  }

  /**
   * Get DataFeedDeleteRequest model
   * @this {!Client}
   * @returns {!DataFeedDeleteRequestModel} DataFeedDeleteRequest sender model
   */
  get DataFeedDeleteRequestSenderModel () {
    return this._DataFeedDeleteRequestSenderModel
  }

  /**
   * Get DataFeedDeleteResponse model
   * @this {!Client}
   * @returns {!DataFeedDeleteResponseModel} DataFeedDeleteResponse sender model
   */
  get DataFeedDeleteResponseSenderModel () {
    return this._DataFeedDeleteResponseSenderModel
  }

  /**
   * Get DataFeedUpdateNotify model
   * @this {!Client}
   * @returns {!DataFeedUpdateNotifyModel} DataFeedUpdateNotify sender model
   */
  get DataFeedUpdateNotifySenderModel () {
    return this._DataFeedUpdateNotifySenderModel
  }

  /**
   * Get ModuleSystemInfoNotify model
   * @this {!Client}
   * @returns {!ModuleSystemInfoNotifyModel} ModuleSystemInfoNotify sender model
   */
  get ModuleSystemInfoNotifySenderModel () {
    return this._ModuleSystemInfoNotifySenderModel
  }

  /**
   * Get ModulesSystemInfoGetAllRequest model
   * @this {!Client}
   * @returns {!ModulesSystemInfoGetAllRequestModel} ModulesSystemInfoGetAllRequest sender model
   */
  get ModulesSystemInfoGetAllRequestSenderModel () {
    return this._ModulesSystemInfoGetAllRequestSenderModel
  }

  /**
   * Get ModulesSystemInfoGetAllResponse model
   * @this {!Client}
   * @returns {!ModulesSystemInfoGetAllResponseModel} ModulesSystemInfoGetAllResponse sender model
   */
  get ModulesSystemInfoGetAllResponseSenderModel () {
    return this._ModulesSystemInfoGetAllResponseSenderModel
  }

  /**
   * Get ModulesSystemInfoNotify model
   * @this {!Client}
   * @returns {!ModulesSystemInfoNotifyModel} ModulesSystemInfoNotify sender model
   */
  get ModulesSystemInfoNotifySenderModel () {
    return this._ModulesSystemInfoNotifySenderModel
  }

  /**
   * Get ChartBarHistoryModifyRequest model
   * @this {!Client}
   * @returns {!ChartBarHistoryModifyRequestModel} ChartBarHistoryModifyRequest sender model
   */
  get ChartBarHistoryModifyRequestSenderModel () {
    return this._ChartBarHistoryModifyRequestSenderModel
  }

  /**
   * Get ChartBarHistoryModifyResponse model
   * @this {!Client}
   * @returns {!ChartBarHistoryModifyResponseModel} ChartBarHistoryModifyResponse sender model
   */
  get ChartBarHistoryModifyResponseSenderModel () {
    return this._ChartBarHistoryModifyResponseSenderModel
  }

  /**
   * Get FeedUpdateTickSnapshotRequest model
   * @this {!Client}
   * @returns {!FeedUpdateTickSnapshotRequestModel} FeedUpdateTickSnapshotRequest sender model
   */
  get FeedUpdateTickSnapshotRequestSenderModel () {
    return this._FeedUpdateTickSnapshotRequestSenderModel
  }

  /**
   * Get FeedUpdateTickSnapshotResponse model
   * @this {!Client}
   * @returns {!FeedUpdateTickSnapshotResponseModel} FeedUpdateTickSnapshotResponse sender model
   */
  get FeedUpdateTickSnapshotResponseSenderModel () {
    return this._FeedUpdateTickSnapshotResponseSenderModel
  }

  /**
   * Get FeedUpdateTickRequest model
   * @this {!Client}
   * @returns {!FeedUpdateTickRequestModel} FeedUpdateTickRequest sender model
   */
  get FeedUpdateTickRequestSenderModel () {
    return this._FeedUpdateTickRequestSenderModel
  }

  /**
   * Get FeedUpdateTickResponse model
   * @this {!Client}
   * @returns {!FeedUpdateTickResponseModel} FeedUpdateTickResponse sender model
   */
  get FeedUpdateTickResponseSenderModel () {
    return this._FeedUpdateTickResponseSenderModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotRequest model
   * @this {!Client}
   * @returns {!FeedUpdateLevel2SnapshotRequestModel} FeedUpdateLevel2SnapshotRequest sender model
   */
  get FeedUpdateLevel2SnapshotRequestSenderModel () {
    return this._FeedUpdateLevel2SnapshotRequestSenderModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotResponse model
   * @this {!Client}
   * @returns {!FeedUpdateLevel2SnapshotResponseModel} FeedUpdateLevel2SnapshotResponse sender model
   */
  get FeedUpdateLevel2SnapshotResponseSenderModel () {
    return this._FeedUpdateLevel2SnapshotResponseSenderModel
  }

  /**
   * Get FeedUpdateLevel2Request model
   * @this {!Client}
   * @returns {!FeedUpdateLevel2RequestModel} FeedUpdateLevel2Request sender model
   */
  get FeedUpdateLevel2RequestSenderModel () {
    return this._FeedUpdateLevel2RequestSenderModel
  }

  /**
   * Get FeedUpdateLevel2Response model
   * @this {!Client}
   * @returns {!FeedUpdateLevel2ResponseModel} FeedUpdateLevel2Response sender model
   */
  get FeedUpdateLevel2ResponseSenderModel () {
    return this._FeedUpdateLevel2ResponseSenderModel
  }

  /**
   * Get MailServerGetRequest model
   * @this {!Client}
   * @returns {!MailServerGetRequestModel} MailServerGetRequest sender model
   */
  get MailServerGetRequestSenderModel () {
    return this._MailServerGetRequestSenderModel
  }

  /**
   * Get MailServerGetResponse model
   * @this {!Client}
   * @returns {!MailServerGetResponseModel} MailServerGetResponse sender model
   */
  get MailServerGetResponseSenderModel () {
    return this._MailServerGetResponseSenderModel
  }

  /**
   * Get MailServersGetAllRequest model
   * @this {!Client}
   * @returns {!MailServersGetAllRequestModel} MailServersGetAllRequest sender model
   */
  get MailServersGetAllRequestSenderModel () {
    return this._MailServersGetAllRequestSenderModel
  }

  /**
   * Get MailServersGetAllResponse model
   * @this {!Client}
   * @returns {!MailServersGetAllResponseModel} MailServersGetAllResponse sender model
   */
  get MailServersGetAllResponseSenderModel () {
    return this._MailServersGetAllResponseSenderModel
  }

  /**
   * Get MailServerCreateRequest model
   * @this {!Client}
   * @returns {!MailServerCreateRequestModel} MailServerCreateRequest sender model
   */
  get MailServerCreateRequestSenderModel () {
    return this._MailServerCreateRequestSenderModel
  }

  /**
   * Get MailServerCreateResponse model
   * @this {!Client}
   * @returns {!MailServerCreateResponseModel} MailServerCreateResponse sender model
   */
  get MailServerCreateResponseSenderModel () {
    return this._MailServerCreateResponseSenderModel
  }

  /**
   * Get MailServerModifyRequest model
   * @this {!Client}
   * @returns {!MailServerModifyRequestModel} MailServerModifyRequest sender model
   */
  get MailServerModifyRequestSenderModel () {
    return this._MailServerModifyRequestSenderModel
  }

  /**
   * Get MailServerModifyResponse model
   * @this {!Client}
   * @returns {!MailServerModifyResponseModel} MailServerModifyResponse sender model
   */
  get MailServerModifyResponseSenderModel () {
    return this._MailServerModifyResponseSenderModel
  }

  /**
   * Get MailServerDeleteRequest model
   * @this {!Client}
   * @returns {!MailServerDeleteRequestModel} MailServerDeleteRequest sender model
   */
  get MailServerDeleteRequestSenderModel () {
    return this._MailServerDeleteRequestSenderModel
  }

  /**
   * Get MailServerDeleteResponse model
   * @this {!Client}
   * @returns {!MailServerDeleteResponseModel} MailServerDeleteResponse sender model
   */
  get MailServerDeleteResponseSenderModel () {
    return this._MailServerDeleteResponseSenderModel
  }

  /**
   * Get MailServerUpdateNotify model
   * @this {!Client}
   * @returns {!MailServerUpdateNotifyModel} MailServerUpdateNotify sender model
   */
  get MailServerUpdateNotifySenderModel () {
    return this._MailServerUpdateNotifySenderModel
  }

  /**
   * Get AccountStatesUpdateNotify model
   * @this {!Client}
   * @returns {!AccountStatesUpdateNotifyModel} AccountStatesUpdateNotify sender model
   */
  get AccountStatesUpdateNotifySenderModel () {
    return this._AccountStatesUpdateNotifySenderModel
  }

  /**
   * Get AccountStatesGetAllRequest model
   * @this {!Client}
   * @returns {!AccountStatesGetAllRequestModel} AccountStatesGetAllRequest sender model
   */
  get AccountStatesGetAllRequestSenderModel () {
    return this._AccountStatesGetAllRequestSenderModel
  }

  /**
   * Get AccountStatesGetAllResponse model
   * @this {!Client}
   * @returns {!AccountStatesGetAllResponseModel} AccountStatesGetAllResponse sender model
   */
  get AccountStatesGetAllResponseSenderModel () {
    return this._AccountStatesGetAllResponseSenderModel
  }

  /**
   * Get ModuleAuthRequest model
   * @this {!Client}
   * @returns {!ModuleAuthRequestModel} ModuleAuthRequest sender model
   */
  get ModuleAuthRequestSenderModel () {
    return this._ModuleAuthRequestSenderModel
  }

  /**
   * Get ModuleAuthResponse model
   * @this {!Client}
   * @returns {!ModuleAuthResponseModel} ModuleAuthResponse sender model
   */
  get ModuleAuthResponseSenderModel () {
    return this._ModuleAuthResponseSenderModel
  }

  /**
   * Get LeverageProfileGetRequest model
   * @this {!Client}
   * @returns {!LeverageProfileGetRequestModel} LeverageProfileGetRequest sender model
   */
  get LeverageProfileGetRequestSenderModel () {
    return this._LeverageProfileGetRequestSenderModel
  }

  /**
   * Get LeverageProfileGetResponse model
   * @this {!Client}
   * @returns {!LeverageProfileGetResponseModel} LeverageProfileGetResponse sender model
   */
  get LeverageProfileGetResponseSenderModel () {
    return this._LeverageProfileGetResponseSenderModel
  }

  /**
   * Get LeverageProfilesGetAllRequest model
   * @this {!Client}
   * @returns {!LeverageProfilesGetAllRequestModel} LeverageProfilesGetAllRequest sender model
   */
  get LeverageProfilesGetAllRequestSenderModel () {
    return this._LeverageProfilesGetAllRequestSenderModel
  }

  /**
   * Get LeverageProfilesGetAllResponse model
   * @this {!Client}
   * @returns {!LeverageProfilesGetAllResponseModel} LeverageProfilesGetAllResponse sender model
   */
  get LeverageProfilesGetAllResponseSenderModel () {
    return this._LeverageProfilesGetAllResponseSenderModel
  }

  /**
   * Get LeverageProfileCreateRequest model
   * @this {!Client}
   * @returns {!LeverageProfileCreateRequestModel} LeverageProfileCreateRequest sender model
   */
  get LeverageProfileCreateRequestSenderModel () {
    return this._LeverageProfileCreateRequestSenderModel
  }

  /**
   * Get LeverageProfileCreateResponse model
   * @this {!Client}
   * @returns {!LeverageProfileCreateResponseModel} LeverageProfileCreateResponse sender model
   */
  get LeverageProfileCreateResponseSenderModel () {
    return this._LeverageProfileCreateResponseSenderModel
  }

  /**
   * Get LeverageProfileModifyRequest model
   * @this {!Client}
   * @returns {!LeverageProfileModifyRequestModel} LeverageProfileModifyRequest sender model
   */
  get LeverageProfileModifyRequestSenderModel () {
    return this._LeverageProfileModifyRequestSenderModel
  }

  /**
   * Get LeverageProfileModifyResponse model
   * @this {!Client}
   * @returns {!LeverageProfileModifyResponseModel} LeverageProfileModifyResponse sender model
   */
  get LeverageProfileModifyResponseSenderModel () {
    return this._LeverageProfileModifyResponseSenderModel
  }

  /**
   * Get LeverageProfileDeleteRequest model
   * @this {!Client}
   * @returns {!LeverageProfileDeleteRequestModel} LeverageProfileDeleteRequest sender model
   */
  get LeverageProfileDeleteRequestSenderModel () {
    return this._LeverageProfileDeleteRequestSenderModel
  }

  /**
   * Get LeverageProfileDeleteResponse model
   * @this {!Client}
   * @returns {!LeverageProfileDeleteResponseModel} LeverageProfileDeleteResponse sender model
   */
  get LeverageProfileDeleteResponseSenderModel () {
    return this._LeverageProfileDeleteResponseSenderModel
  }

  /**
   * Get LeverageProfileUpdateNotify model
   * @this {!Client}
   * @returns {!LeverageProfileUpdateNotifyModel} LeverageProfileUpdateNotify sender model
   */
  get LeverageProfileUpdateNotifySenderModel () {
    return this._LeverageProfileUpdateNotifySenderModel
  }

  /**
   * Get MarginRateProfileGetRequest model
   * @this {!Client}
   * @returns {!MarginRateProfileGetRequestModel} MarginRateProfileGetRequest sender model
   */
  get MarginRateProfileGetRequestSenderModel () {
    return this._MarginRateProfileGetRequestSenderModel
  }

  /**
   * Get MarginRateProfileGetResponse model
   * @this {!Client}
   * @returns {!MarginRateProfileGetResponseModel} MarginRateProfileGetResponse sender model
   */
  get MarginRateProfileGetResponseSenderModel () {
    return this._MarginRateProfileGetResponseSenderModel
  }

  /**
   * Get MarginRateProfilesGetAllRequest model
   * @this {!Client}
   * @returns {!MarginRateProfilesGetAllRequestModel} MarginRateProfilesGetAllRequest sender model
   */
  get MarginRateProfilesGetAllRequestSenderModel () {
    return this._MarginRateProfilesGetAllRequestSenderModel
  }

  /**
   * Get MarginRateProfilesGetAllResponse model
   * @this {!Client}
   * @returns {!MarginRateProfilesGetAllResponseModel} MarginRateProfilesGetAllResponse sender model
   */
  get MarginRateProfilesGetAllResponseSenderModel () {
    return this._MarginRateProfilesGetAllResponseSenderModel
  }

  /**
   * Get MarginRateProfileCreateRequest model
   * @this {!Client}
   * @returns {!MarginRateProfileCreateRequestModel} MarginRateProfileCreateRequest sender model
   */
  get MarginRateProfileCreateRequestSenderModel () {
    return this._MarginRateProfileCreateRequestSenderModel
  }

  /**
   * Get MarginRateProfileCreateResponse model
   * @this {!Client}
   * @returns {!MarginRateProfileCreateResponseModel} MarginRateProfileCreateResponse sender model
   */
  get MarginRateProfileCreateResponseSenderModel () {
    return this._MarginRateProfileCreateResponseSenderModel
  }

  /**
   * Get MarginRateProfileModifyRequest model
   * @this {!Client}
   * @returns {!MarginRateProfileModifyRequestModel} MarginRateProfileModifyRequest sender model
   */
  get MarginRateProfileModifyRequestSenderModel () {
    return this._MarginRateProfileModifyRequestSenderModel
  }

  /**
   * Get MarginRateProfileModifyResponse model
   * @this {!Client}
   * @returns {!MarginRateProfileModifyResponseModel} MarginRateProfileModifyResponse sender model
   */
  get MarginRateProfileModifyResponseSenderModel () {
    return this._MarginRateProfileModifyResponseSenderModel
  }

  /**
   * Get MarginRateProfileDeleteRequest model
   * @this {!Client}
   * @returns {!MarginRateProfileDeleteRequestModel} MarginRateProfileDeleteRequest sender model
   */
  get MarginRateProfileDeleteRequestSenderModel () {
    return this._MarginRateProfileDeleteRequestSenderModel
  }

  /**
   * Get MarginRateProfileDeleteResponse model
   * @this {!Client}
   * @returns {!MarginRateProfileDeleteResponseModel} MarginRateProfileDeleteResponse sender model
   */
  get MarginRateProfileDeleteResponseSenderModel () {
    return this._MarginRateProfileDeleteResponseSenderModel
  }

  /**
   * Get MarginRateProfileUpdateNotify model
   * @this {!Client}
   * @returns {!MarginRateProfileUpdateNotifyModel} MarginRateProfileUpdateNotify sender model
   */
  get MarginRateProfileUpdateNotifySenderModel () {
    return this._MarginRateProfileUpdateNotifySenderModel
  }

  /**
   * Get NewsProfileGetRequest model
   * @this {!Client}
   * @returns {!NewsProfileGetRequestModel} NewsProfileGetRequest sender model
   */
  get NewsProfileGetRequestSenderModel () {
    return this._NewsProfileGetRequestSenderModel
  }

  /**
   * Get NewsProfileGetResponse model
   * @this {!Client}
   * @returns {!NewsProfileGetResponseModel} NewsProfileGetResponse sender model
   */
  get NewsProfileGetResponseSenderModel () {
    return this._NewsProfileGetResponseSenderModel
  }

  /**
   * Get NewsProfilesGetAllRequest model
   * @this {!Client}
   * @returns {!NewsProfilesGetAllRequestModel} NewsProfilesGetAllRequest sender model
   */
  get NewsProfilesGetAllRequestSenderModel () {
    return this._NewsProfilesGetAllRequestSenderModel
  }

  /**
   * Get NewsProfilesGetAllResponse model
   * @this {!Client}
   * @returns {!NewsProfilesGetAllResponseModel} NewsProfilesGetAllResponse sender model
   */
  get NewsProfilesGetAllResponseSenderModel () {
    return this._NewsProfilesGetAllResponseSenderModel
  }

  /**
   * Get NewsProfileCreateRequest model
   * @this {!Client}
   * @returns {!NewsProfileCreateRequestModel} NewsProfileCreateRequest sender model
   */
  get NewsProfileCreateRequestSenderModel () {
    return this._NewsProfileCreateRequestSenderModel
  }

  /**
   * Get NewsProfileCreateResponse model
   * @this {!Client}
   * @returns {!NewsProfileCreateResponseModel} NewsProfileCreateResponse sender model
   */
  get NewsProfileCreateResponseSenderModel () {
    return this._NewsProfileCreateResponseSenderModel
  }

  /**
   * Get NewsProfileModifyRequest model
   * @this {!Client}
   * @returns {!NewsProfileModifyRequestModel} NewsProfileModifyRequest sender model
   */
  get NewsProfileModifyRequestSenderModel () {
    return this._NewsProfileModifyRequestSenderModel
  }

  /**
   * Get NewsProfileModifyResponse model
   * @this {!Client}
   * @returns {!NewsProfileModifyResponseModel} NewsProfileModifyResponse sender model
   */
  get NewsProfileModifyResponseSenderModel () {
    return this._NewsProfileModifyResponseSenderModel
  }

  /**
   * Get NewsProfileDeleteRequest model
   * @this {!Client}
   * @returns {!NewsProfileDeleteRequestModel} NewsProfileDeleteRequest sender model
   */
  get NewsProfileDeleteRequestSenderModel () {
    return this._NewsProfileDeleteRequestSenderModel
  }

  /**
   * Get NewsProfileDeleteResponse model
   * @this {!Client}
   * @returns {!NewsProfileDeleteResponseModel} NewsProfileDeleteResponse sender model
   */
  get NewsProfileDeleteResponseSenderModel () {
    return this._NewsProfileDeleteResponseSenderModel
  }

  /**
   * Get NewsProfileUpdateNotify model
   * @this {!Client}
   * @returns {!NewsProfileUpdateNotifyModel} NewsProfileUpdateNotify sender model
   */
  get NewsProfileUpdateNotifySenderModel () {
    return this._NewsProfileUpdateNotifySenderModel
  }

  /**
   * Get NewsProfileImportRequest model
   * @this {!Client}
   * @returns {!NewsProfileImportRequestModel} NewsProfileImportRequest sender model
   */
  get NewsProfileImportRequestSenderModel () {
    return this._NewsProfileImportRequestSenderModel
  }

  /**
   * Get NewsProfileImportResponse model
   * @this {!Client}
   * @returns {!NewsProfileImportResponseModel} NewsProfileImportResponse sender model
   */
  get NewsProfileImportResponseSenderModel () {
    return this._NewsProfileImportResponseSenderModel
  }

  /**
   * Get TickHistoryRequest model
   * @this {!Client}
   * @returns {!TickHistoryRequestModel} TickHistoryRequest sender model
   */
  get TickHistoryRequestSenderModel () {
    return this._TickHistoryRequestSenderModel
  }

  /**
   * Get TickHistoryResponse model
   * @this {!Client}
   * @returns {!TickHistoryResponseModel} TickHistoryResponse sender model
   */
  get TickHistoryResponseSenderModel () {
    return this._TickHistoryResponseSenderModel
  }

  /**
   * Get EmailTemplateGetRequest model
   * @this {!Client}
   * @returns {!EmailTemplateGetRequestModel} EmailTemplateGetRequest sender model
   */
  get EmailTemplateGetRequestSenderModel () {
    return this._EmailTemplateGetRequestSenderModel
  }

  /**
   * Get EmailTemplateGetResponse model
   * @this {!Client}
   * @returns {!EmailTemplateGetResponseModel} EmailTemplateGetResponse sender model
   */
  get EmailTemplateGetResponseSenderModel () {
    return this._EmailTemplateGetResponseSenderModel
  }

  /**
   * Get EmailTemplatesGetAllRequest model
   * @this {!Client}
   * @returns {!EmailTemplatesGetAllRequestModel} EmailTemplatesGetAllRequest sender model
   */
  get EmailTemplatesGetAllRequestSenderModel () {
    return this._EmailTemplatesGetAllRequestSenderModel
  }

  /**
   * Get EmailTemplatesGetAllResponse model
   * @this {!Client}
   * @returns {!EmailTemplatesGetAllResponseModel} EmailTemplatesGetAllResponse sender model
   */
  get EmailTemplatesGetAllResponseSenderModel () {
    return this._EmailTemplatesGetAllResponseSenderModel
  }

  /**
   * Get EmailTemplateCreateRequest model
   * @this {!Client}
   * @returns {!EmailTemplateCreateRequestModel} EmailTemplateCreateRequest sender model
   */
  get EmailTemplateCreateRequestSenderModel () {
    return this._EmailTemplateCreateRequestSenderModel
  }

  /**
   * Get EmailTemplateCreateResponse model
   * @this {!Client}
   * @returns {!EmailTemplateCreateResponseModel} EmailTemplateCreateResponse sender model
   */
  get EmailTemplateCreateResponseSenderModel () {
    return this._EmailTemplateCreateResponseSenderModel
  }

  /**
   * Get EmailTemplateModifyRequest model
   * @this {!Client}
   * @returns {!EmailTemplateModifyRequestModel} EmailTemplateModifyRequest sender model
   */
  get EmailTemplateModifyRequestSenderModel () {
    return this._EmailTemplateModifyRequestSenderModel
  }

  /**
   * Get EmailTemplateModifyResponse model
   * @this {!Client}
   * @returns {!EmailTemplateModifyResponseModel} EmailTemplateModifyResponse sender model
   */
  get EmailTemplateModifyResponseSenderModel () {
    return this._EmailTemplateModifyResponseSenderModel
  }

  /**
   * Get EmailTemplateDeleteRequest model
   * @this {!Client}
   * @returns {!EmailTemplateDeleteRequestModel} EmailTemplateDeleteRequest sender model
   */
  get EmailTemplateDeleteRequestSenderModel () {
    return this._EmailTemplateDeleteRequestSenderModel
  }

  /**
   * Get EmailTemplateDeleteResponse model
   * @this {!Client}
   * @returns {!EmailTemplateDeleteResponseModel} EmailTemplateDeleteResponse sender model
   */
  get EmailTemplateDeleteResponseSenderModel () {
    return this._EmailTemplateDeleteResponseSenderModel
  }

  /**
   * Get EmailTemplateUpdateNotify model
   * @this {!Client}
   * @returns {!EmailTemplateUpdateNotifyModel} EmailTemplateUpdateNotify sender model
   */
  get EmailTemplateUpdateNotifySenderModel () {
    return this._EmailTemplateUpdateNotifySenderModel
  }

  /**
   * Get OrderStateSubscribeRequest model
   * @this {!Client}
   * @returns {!OrderStateSubscribeRequestModel} OrderStateSubscribeRequest sender model
   */
  get OrderStateSubscribeRequestSenderModel () {
    return this._OrderStateSubscribeRequestSenderModel
  }

  /**
   * Get OrderStateSubscribeResponse model
   * @this {!Client}
   * @returns {!OrderStateSubscribeResponseModel} OrderStateSubscribeResponse sender model
   */
  get OrderStateSubscribeResponseSenderModel () {
    return this._OrderStateSubscribeResponseSenderModel
  }

  /**
   * Get OrderStateUpdateSubscriptionRequest model
   * @this {!Client}
   * @returns {!OrderStateUpdateSubscriptionRequestModel} OrderStateUpdateSubscriptionRequest sender model
   */
  get OrderStateUpdateSubscriptionRequestSenderModel () {
    return this._OrderStateUpdateSubscriptionRequestSenderModel
  }

  /**
   * Get OrderStateUpdateSubscriptionResponse model
   * @this {!Client}
   * @returns {!OrderStateUpdateSubscriptionResponseModel} OrderStateUpdateSubscriptionResponse sender model
   */
  get OrderStateUpdateSubscriptionResponseSenderModel () {
    return this._OrderStateUpdateSubscriptionResponseSenderModel
  }

  /**
   * Get OrderStateUnsubscribeRequest model
   * @this {!Client}
   * @returns {!OrderStateUnsubscribeRequestModel} OrderStateUnsubscribeRequest sender model
   */
  get OrderStateUnsubscribeRequestSenderModel () {
    return this._OrderStateUnsubscribeRequestSenderModel
  }

  /**
   * Get OrderStateUnsubscribeResponse model
   * @this {!Client}
   * @returns {!OrderStateUnsubscribeResponseModel} OrderStateUnsubscribeResponse sender model
   */
  get OrderStateUnsubscribeResponseSenderModel () {
    return this._OrderStateUnsubscribeResponseSenderModel
  }

  /**
   * Get OrderStateNotify model
   * @this {!Client}
   * @returns {!OrderStateNotifyModel} OrderStateNotify sender model
   */
  get OrderStateNotifySenderModel () {
    return this._OrderStateNotifySenderModel
  }

  /**
   * Get RequestMonitoringSubscribeRequest model
   * @this {!Client}
   * @returns {!RequestMonitoringSubscribeRequestModel} RequestMonitoringSubscribeRequest sender model
   */
  get RequestMonitoringSubscribeRequestSenderModel () {
    return this._RequestMonitoringSubscribeRequestSenderModel
  }

  /**
   * Get RequestMonitoringSubscribeResponse model
   * @this {!Client}
   * @returns {!RequestMonitoringSubscribeResponseModel} RequestMonitoringSubscribeResponse sender model
   */
  get RequestMonitoringSubscribeResponseSenderModel () {
    return this._RequestMonitoringSubscribeResponseSenderModel
  }

  /**
   * Get RequestMonitoringUnsubscribeRequest model
   * @this {!Client}
   * @returns {!RequestMonitoringUnsubscribeRequestModel} RequestMonitoringUnsubscribeRequest sender model
   */
  get RequestMonitoringUnsubscribeRequestSenderModel () {
    return this._RequestMonitoringUnsubscribeRequestSenderModel
  }

  /**
   * Get RequestMonitoringUnsubscribeResponse model
   * @this {!Client}
   * @returns {!RequestMonitoringUnsubscribeResponseModel} RequestMonitoringUnsubscribeResponse sender model
   */
  get RequestMonitoringUnsubscribeResponseSenderModel () {
    return this._RequestMonitoringUnsubscribeResponseSenderModel
  }

  /**
   * Get RequestMonitoringRequestUpdateNotify model
   * @this {!Client}
   * @returns {!RequestMonitoringRequestUpdateNotifyModel} RequestMonitoringRequestUpdateNotify sender model
   */
  get RequestMonitoringRequestUpdateNotifySenderModel () {
    return this._RequestMonitoringRequestUpdateNotifySenderModel
  }

  /**
   * Get FilledOrdersSummaryInfoSubscribeRequest model
   * @this {!Client}
   * @returns {!FilledOrdersSummaryInfoSubscribeRequestModel} FilledOrdersSummaryInfoSubscribeRequest sender model
   */
  get FilledOrdersSummaryInfoSubscribeRequestSenderModel () {
    return this._FilledOrdersSummaryInfoSubscribeRequestSenderModel
  }

  /**
   * Get FilledOrdersSummaryInfoSubscribeResponse model
   * @this {!Client}
   * @returns {!FilledOrdersSummaryInfoSubscribeResponseModel} FilledOrdersSummaryInfoSubscribeResponse sender model
   */
  get FilledOrdersSummaryInfoSubscribeResponseSenderModel () {
    return this._FilledOrdersSummaryInfoSubscribeResponseSenderModel
  }

  /**
   * Get FilledOrdersSummaryInfoNotify model
   * @this {!Client}
   * @returns {!FilledOrdersSummaryInfoNotifyModel} FilledOrdersSummaryInfoNotify sender model
   */
  get FilledOrdersSummaryInfoNotifySenderModel () {
    return this._FilledOrdersSummaryInfoNotifySenderModel
  }

  /**
   * Get FilledOrdersSummaryInfoUpdateSubscriptionRequest model
   * @this {!Client}
   * @returns {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel} FilledOrdersSummaryInfoUpdateSubscriptionRequest sender model
   */
  get FilledOrdersSummaryInfoUpdateSubscriptionRequestSenderModel () {
    return this._FilledOrdersSummaryInfoUpdateSubscriptionRequestSenderModel
  }

  /**
   * Get FilledOrdersSummaryInfoUnsubscribeRequest model
   * @this {!Client}
   * @returns {!FilledOrdersSummaryInfoUnsubscribeRequestModel} FilledOrdersSummaryInfoUnsubscribeRequest sender model
   */
  get FilledOrdersSummaryInfoUnsubscribeRequestSenderModel () {
    return this._FilledOrdersSummaryInfoUnsubscribeRequestSenderModel
  }

  /**
   * Get FilledOrdersSummaryInfoUnsubscribeResponse model
   * @this {!Client}
   * @returns {!FilledOrdersSummaryInfoUnsubscribeResponseModel} FilledOrdersSummaryInfoUnsubscribeResponse sender model
   */
  get FilledOrdersSummaryInfoUnsubscribeResponseSenderModel () {
    return this._FilledOrdersSummaryInfoUnsubscribeResponseSenderModel
  }

  /**
   * Get TradeEventsNotify model
   * @this {!Client}
   * @returns {!TradeEventsNotifyModel} TradeEventsNotify sender model
   */
  get TradeEventsNotifySenderModel () {
    return this._TradeEventsNotifySenderModel
  }

  /**
   * Get TradeEventsGetRequest model
   * @this {!Client}
   * @returns {!TradeEventsGetRequestModel} TradeEventsGetRequest sender model
   */
  get TradeEventsGetRequestSenderModel () {
    return this._TradeEventsGetRequestSenderModel
  }

  /**
   * Get TradeEventsGetResponse model
   * @this {!Client}
   * @returns {!TradeEventsGetResponseModel} TradeEventsGetResponse sender model
   */
  get TradeEventsGetResponseSenderModel () {
    return this._TradeEventsGetResponseSenderModel
  }

  /**
   * Get JournalRequest model
   * @this {!Client}
   * @returns {!JournalRequestModel} JournalRequest sender model
   */
  get JournalRequestSenderModel () {
    return this._JournalRequestSenderModel
  }

  /**
   * Get JournalResponse model
   * @this {!Client}
   * @returns {!JournalResponseModel} JournalResponse sender model
   */
  get JournalResponseSenderModel () {
    return this._JournalResponseSenderModel
  }

  /**
   * Get ClientReportsListGetRequest model
   * @this {!Client}
   * @returns {!ClientReportsListGetRequestModel} ClientReportsListGetRequest sender model
   */
  get ClientReportsListGetRequestSenderModel () {
    return this._ClientReportsListGetRequestSenderModel
  }

  /**
   * Get ClientReportsListGetResponse model
   * @this {!Client}
   * @returns {!ClientReportsListGetResponseModel} ClientReportsListGetResponse sender model
   */
  get ClientReportsListGetResponseSenderModel () {
    return this._ClientReportsListGetResponseSenderModel
  }

  /**
   * Get ClientReportGetRequest model
   * @this {!Client}
   * @returns {!ClientReportGetRequestModel} ClientReportGetRequest sender model
   */
  get ClientReportGetRequestSenderModel () {
    return this._ClientReportGetRequestSenderModel
  }

  /**
   * Get ClientReportGetResponse model
   * @this {!Client}
   * @returns {!ClientReportGetResponseModel} ClientReportGetResponse sender model
   */
  get ClientReportGetResponseSenderModel () {
    return this._ClientReportGetResponseSenderModel
  }

  /**
   * Get ClientReportsSendRequest model
   * @this {!Client}
   * @returns {!ClientReportsSendRequestModel} ClientReportsSendRequest sender model
   */
  get ClientReportsSendRequestSenderModel () {
    return this._ClientReportsSendRequestSenderModel
  }

  /**
   * Get ClientReportsSendResponse model
   * @this {!Client}
   * @returns {!ClientReportsSendResponseModel} ClientReportsSendResponse sender model
   */
  get ClientReportsSendResponseSenderModel () {
    return this._ClientReportsSendResponseSenderModel
  }

  /**
   * Get ProfitClosedGetRequest model
   * @this {!Client}
   * @returns {!ProfitClosedGetRequestModel} ProfitClosedGetRequest sender model
   */
  get ProfitClosedGetRequestSenderModel () {
    return this._ProfitClosedGetRequestSenderModel
  }

  /**
   * Get ProfitClosedGetResponse model
   * @this {!Client}
   * @returns {!ProfitClosedGetResponseModel} ProfitClosedGetResponse sender model
   */
  get ProfitClosedGetResponseSenderModel () {
    return this._ProfitClosedGetResponseSenderModel
  }

  /**
   * Get ChartBarsImportRequest model
   * @this {!Client}
   * @returns {!ChartBarsImportRequestModel} ChartBarsImportRequest sender model
   */
  get ChartBarsImportRequestSenderModel () {
    return this._ChartBarsImportRequestSenderModel
  }

  /**
   * Get ChartBarsImportResponse model
   * @this {!Client}
   * @returns {!ChartBarsImportResponseModel} ChartBarsImportResponse sender model
   */
  get ChartBarsImportResponseSenderModel () {
    return this._ChartBarsImportResponseSenderModel
  }

  /**
   * Get ChartBarsImportNotify model
   * @this {!Client}
   * @returns {!ChartBarsImportNotifyModel} ChartBarsImportNotify sender model
   */
  get ChartBarsImportNotifySenderModel () {
    return this._ChartBarsImportNotifySenderModel
  }

  /**
   * Get JournalBusinessRequest model
   * @this {!Client}
   * @returns {!JournalBusinessRequestModel} JournalBusinessRequest sender model
   */
  get JournalBusinessRequestSenderModel () {
    return this._JournalBusinessRequestSenderModel
  }

  /**
   * Get JournalBusinessResponse model
   * @this {!Client}
   * @returns {!JournalBusinessResponseModel} JournalBusinessResponse sender model
   */
  get JournalBusinessResponseSenderModel () {
    return this._JournalBusinessResponseSenderModel
  }

  /**
   * Get AccountsGetPageRequest model
   * @this {!Client}
   * @returns {!AccountsGetPageRequestModel} AccountsGetPageRequest sender model
   */
  get AccountsGetPageRequestSenderModel () {
    return this._AccountsGetPageRequestSenderModel
  }

  /**
   * Get AccountsGetPageResponse model
   * @this {!Client}
   * @returns {!AccountsGetPageResponseModel} AccountsGetPageResponse sender model
   */
  get AccountsGetPageResponseSenderModel () {
    return this._AccountsGetPageResponseSenderModel
  }

  /**
   * Get HeartbeatNotify model
   * @this {!Client}
   * @returns {!HeartbeatNotifyModel} HeartbeatNotify sender model
   */
  get HeartbeatNotifySenderModel () {
    return this._HeartbeatNotifySenderModel
  }

  /**
   * Get OrdersClosedSummariesGetRequest model
   * @this {!Client}
   * @returns {!OrdersClosedSummariesGetRequestModel} OrdersClosedSummariesGetRequest sender model
   */
  get OrdersClosedSummariesGetRequestSenderModel () {
    return this._OrdersClosedSummariesGetRequestSenderModel
  }

  /**
   * Get OrdersClosedSummariesGetResponse model
   * @this {!Client}
   * @returns {!OrdersClosedSummariesGetResponseModel} OrdersClosedSummariesGetResponse sender model
   */
  get OrdersClosedSummariesGetResponseSenderModel () {
    return this._OrdersClosedSummariesGetResponseSenderModel
  }

  /**
   * Get OrdersGetPageRequest model
   * @this {!Client}
   * @returns {!OrdersGetPageRequestModel} OrdersGetPageRequest sender model
   */
  get OrdersGetPageRequestSenderModel () {
    return this._OrdersGetPageRequestSenderModel
  }

  /**
   * Get OrdersGetPageResponse model
   * @this {!Client}
   * @returns {!OrdersGetPageResponseModel} OrdersGetPageResponse sender model
   */
  get OrdersGetPageResponseSenderModel () {
    return this._OrdersGetPageResponseSenderModel
  }

  /**
   * Get StorageUniversalNotify model
   * @this {!Client}
   * @returns {!StorageUniversalNotifyModel} StorageUniversalNotify sender model
   */
  get StorageUniversalNotifySenderModel () {
    return this._StorageUniversalNotifySenderModel
  }

  /**
   * Get ClientLoginInfoGetRequest model
   * @this {!Client}
   * @returns {!ClientLoginInfoGetRequestModel} ClientLoginInfoGetRequest sender model
   */
  get ClientLoginInfoGetRequestSenderModel () {
    return this._ClientLoginInfoGetRequestSenderModel
  }

  /**
   * Get ClientLoginInfoGetResponse model
   * @this {!Client}
   * @returns {!ClientLoginInfoGetResponseModel} ClientLoginInfoGetResponse sender model
   */
  get ClientLoginInfoGetResponseSenderModel () {
    return this._ClientLoginInfoGetResponseSenderModel
  }

  /**
   * Get AccountGroupExistsGetRequest model
   * @this {!Client}
   * @returns {!AccountGroupExistsGetRequestModel} AccountGroupExistsGetRequest sender model
   */
  get AccountGroupExistsGetRequestSenderModel () {
    return this._AccountGroupExistsGetRequestSenderModel
  }

  /**
   * Get AccountGroupExistsGetResponse model
   * @this {!Client}
   * @returns {!AccountGroupExistsGetResponseModel} AccountGroupExistsGetResponse sender model
   */
  get AccountGroupExistsGetResponseSenderModel () {
    return this._AccountGroupExistsGetResponseSenderModel
  }

  /**
   * Get LoginWithPasswordRequest model
   * @this {!Client}
   * @returns {!LoginWithPasswordRequestModel} LoginWithPasswordRequest sender model
   */
  get LoginWithPasswordRequestSenderModel () {
    return this._LoginWithPasswordRequestSenderModel
  }

  /**
   * Get LoginWithPasswordResponse model
   * @this {!Client}
   * @returns {!LoginWithPasswordResponseModel} LoginWithPasswordResponse sender model
   */
  get LoginWithPasswordResponseSenderModel () {
    return this._LoginWithPasswordResponseSenderModel
  }

  /**
   * Get LoginWithTokenRequest model
   * @this {!Client}
   * @returns {!LoginWithTokenRequestModel} LoginWithTokenRequest sender model
   */
  get LoginWithTokenRequestSenderModel () {
    return this._LoginWithTokenRequestSenderModel
  }

  /**
   * Get LoginWithTokenResponse model
   * @this {!Client}
   * @returns {!LoginWithTokenResponseModel} LoginWithTokenResponse sender model
   */
  get LoginWithTokenResponseSenderModel () {
    return this._LoginWithTokenResponseSenderModel
  }

  /**
   * Get RefreshTokenRequest model
   * @this {!Client}
   * @returns {!RefreshTokenRequestModel} RefreshTokenRequest sender model
   */
  get RefreshTokenRequestSenderModel () {
    return this._RefreshTokenRequestSenderModel
  }

  /**
   * Get RefreshTokenResponse model
   * @this {!Client}
   * @returns {!RefreshTokenResponseModel} RefreshTokenResponse sender model
   */
  get RefreshTokenResponseSenderModel () {
    return this._RefreshTokenResponseSenderModel
  }

  /**
   * Get PersonGetRequest model
   * @this {!Client}
   * @returns {!PersonGetRequestModel} PersonGetRequest sender model
   */
  get PersonGetRequestSenderModel () {
    return this._PersonGetRequestSenderModel
  }

  /**
   * Get PersonGetResponse model
   * @this {!Client}
   * @returns {!PersonGetResponseModel} PersonGetResponse sender model
   */
  get PersonGetResponseSenderModel () {
    return this._PersonGetResponseSenderModel
  }

  /**
   * Get PersonsGetAllRequest model
   * @this {!Client}
   * @returns {!PersonsGetAllRequestModel} PersonsGetAllRequest sender model
   */
  get PersonsGetAllRequestSenderModel () {
    return this._PersonsGetAllRequestSenderModel
  }

  /**
   * Get PersonsGetAllResponse model
   * @this {!Client}
   * @returns {!PersonsGetAllResponseModel} PersonsGetAllResponse sender model
   */
  get PersonsGetAllResponseSenderModel () {
    return this._PersonsGetAllResponseSenderModel
  }

  /**
   * Get PersonCreateRequest model
   * @this {!Client}
   * @returns {!PersonCreateRequestModel} PersonCreateRequest sender model
   */
  get PersonCreateRequestSenderModel () {
    return this._PersonCreateRequestSenderModel
  }

  /**
   * Get PersonCreateResponse model
   * @this {!Client}
   * @returns {!PersonCreateResponseModel} PersonCreateResponse sender model
   */
  get PersonCreateResponseSenderModel () {
    return this._PersonCreateResponseSenderModel
  }

  /**
   * Get PersonModifyRequest model
   * @this {!Client}
   * @returns {!PersonModifyRequestModel} PersonModifyRequest sender model
   */
  get PersonModifyRequestSenderModel () {
    return this._PersonModifyRequestSenderModel
  }

  /**
   * Get PersonModifyResponse model
   * @this {!Client}
   * @returns {!PersonModifyResponseModel} PersonModifyResponse sender model
   */
  get PersonModifyResponseSenderModel () {
    return this._PersonModifyResponseSenderModel
  }

  /**
   * Get PersonUpdateNotify model
   * @this {!Client}
   * @returns {!PersonUpdateNotifyModel} PersonUpdateNotify sender model
   */
  get PersonUpdateNotifySenderModel () {
    return this._PersonUpdateNotifySenderModel
  }

  /**
   * Get SecuritySettingsGetRequest model
   * @this {!Client}
   * @returns {!SecuritySettingsGetRequestModel} SecuritySettingsGetRequest sender model
   */
  get SecuritySettingsGetRequestSenderModel () {
    return this._SecuritySettingsGetRequestSenderModel
  }

  /**
   * Get SecuritySettingsGetResponse model
   * @this {!Client}
   * @returns {!SecuritySettingsGetResponseModel} SecuritySettingsGetResponse sender model
   */
  get SecuritySettingsGetResponseSenderModel () {
    return this._SecuritySettingsGetResponseSenderModel
  }

  /**
   * Get SecuritySettingsGetAllRequest model
   * @this {!Client}
   * @returns {!SecuritySettingsGetAllRequestModel} SecuritySettingsGetAllRequest sender model
   */
  get SecuritySettingsGetAllRequestSenderModel () {
    return this._SecuritySettingsGetAllRequestSenderModel
  }

  /**
   * Get SecuritySettingsGetAllResponse model
   * @this {!Client}
   * @returns {!SecuritySettingsGetAllResponseModel} SecuritySettingsGetAllResponse sender model
   */
  get SecuritySettingsGetAllResponseSenderModel () {
    return this._SecuritySettingsGetAllResponseSenderModel
  }

  /**
   * Get SecuritySettingsCreateRequest model
   * @this {!Client}
   * @returns {!SecuritySettingsCreateRequestModel} SecuritySettingsCreateRequest sender model
   */
  get SecuritySettingsCreateRequestSenderModel () {
    return this._SecuritySettingsCreateRequestSenderModel
  }

  /**
   * Get SecuritySettingsCreateResponse model
   * @this {!Client}
   * @returns {!SecuritySettingsCreateResponseModel} SecuritySettingsCreateResponse sender model
   */
  get SecuritySettingsCreateResponseSenderModel () {
    return this._SecuritySettingsCreateResponseSenderModel
  }

  /**
   * Get SecuritySettingsModifyRequest model
   * @this {!Client}
   * @returns {!SecuritySettingsModifyRequestModel} SecuritySettingsModifyRequest sender model
   */
  get SecuritySettingsModifyRequestSenderModel () {
    return this._SecuritySettingsModifyRequestSenderModel
  }

  /**
   * Get SecuritySettingsModifyResponse model
   * @this {!Client}
   * @returns {!SecuritySettingsModifyResponseModel} SecuritySettingsModifyResponse sender model
   */
  get SecuritySettingsModifyResponseSenderModel () {
    return this._SecuritySettingsModifyResponseSenderModel
  }

  /**
   * Get SecuritySettingsDeleteRequest model
   * @this {!Client}
   * @returns {!SecuritySettingsDeleteRequestModel} SecuritySettingsDeleteRequest sender model
   */
  get SecuritySettingsDeleteRequestSenderModel () {
    return this._SecuritySettingsDeleteRequestSenderModel
  }

  /**
   * Get SecuritySettingsDeleteResponse model
   * @this {!Client}
   * @returns {!SecuritySettingsDeleteResponseModel} SecuritySettingsDeleteResponse sender model
   */
  get SecuritySettingsDeleteResponseSenderModel () {
    return this._SecuritySettingsDeleteResponseSenderModel
  }

  /**
   * Get SecuritySettingsUpdateNotify model
   * @this {!Client}
   * @returns {!SecuritySettingsUpdateNotifyModel} SecuritySettingsUpdateNotify sender model
   */
  get SecuritySettingsUpdateNotifySenderModel () {
    return this._SecuritySettingsUpdateNotifySenderModel
  }

  /**
   * Get FirewallRuleGetRequest model
   * @this {!Client}
   * @returns {!FirewallRuleGetRequestModel} FirewallRuleGetRequest sender model
   */
  get FirewallRuleGetRequestSenderModel () {
    return this._FirewallRuleGetRequestSenderModel
  }

  /**
   * Get FirewallRuleGetResponse model
   * @this {!Client}
   * @returns {!FirewallRuleGetResponseModel} FirewallRuleGetResponse sender model
   */
  get FirewallRuleGetResponseSenderModel () {
    return this._FirewallRuleGetResponseSenderModel
  }

  /**
   * Get FirewallRulesGetAllRequest model
   * @this {!Client}
   * @returns {!FirewallRulesGetAllRequestModel} FirewallRulesGetAllRequest sender model
   */
  get FirewallRulesGetAllRequestSenderModel () {
    return this._FirewallRulesGetAllRequestSenderModel
  }

  /**
   * Get FirewallRulesGetAllResponse model
   * @this {!Client}
   * @returns {!FirewallRulesGetAllResponseModel} FirewallRulesGetAllResponse sender model
   */
  get FirewallRulesGetAllResponseSenderModel () {
    return this._FirewallRulesGetAllResponseSenderModel
  }

  /**
   * Get FirewallRuleCreateRequest model
   * @this {!Client}
   * @returns {!FirewallRuleCreateRequestModel} FirewallRuleCreateRequest sender model
   */
  get FirewallRuleCreateRequestSenderModel () {
    return this._FirewallRuleCreateRequestSenderModel
  }

  /**
   * Get FirewallRuleCreateResponse model
   * @this {!Client}
   * @returns {!FirewallRuleCreateResponseModel} FirewallRuleCreateResponse sender model
   */
  get FirewallRuleCreateResponseSenderModel () {
    return this._FirewallRuleCreateResponseSenderModel
  }

  /**
   * Get FirewallRuleModifyRequest model
   * @this {!Client}
   * @returns {!FirewallRuleModifyRequestModel} FirewallRuleModifyRequest sender model
   */
  get FirewallRuleModifyRequestSenderModel () {
    return this._FirewallRuleModifyRequestSenderModel
  }

  /**
   * Get FirewallRuleModifyResponse model
   * @this {!Client}
   * @returns {!FirewallRuleModifyResponseModel} FirewallRuleModifyResponse sender model
   */
  get FirewallRuleModifyResponseSenderModel () {
    return this._FirewallRuleModifyResponseSenderModel
  }

  /**
   * Get FirewallRuleDeleteRequest model
   * @this {!Client}
   * @returns {!FirewallRuleDeleteRequestModel} FirewallRuleDeleteRequest sender model
   */
  get FirewallRuleDeleteRequestSenderModel () {
    return this._FirewallRuleDeleteRequestSenderModel
  }

  /**
   * Get FirewallRuleDeleteResponse model
   * @this {!Client}
   * @returns {!FirewallRuleDeleteResponseModel} FirewallRuleDeleteResponse sender model
   */
  get FirewallRuleDeleteResponseSenderModel () {
    return this._FirewallRuleDeleteResponseSenderModel
  }

  /**
   * Get FirewallRuleUpdateNotify model
   * @this {!Client}
   * @returns {!FirewallRuleUpdateNotifyModel} FirewallRuleUpdateNotify sender model
   */
  get FirewallRuleUpdateNotifySenderModel () {
    return this._FirewallRuleUpdateNotifySenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof PingModuleRequest) && (value.fbeType === this.PingModuleRequestSenderModel.fbeType)) {
      return this.send_PingModuleRequest(value)
    }
    if ((value instanceof PongModuleResponse) && (value.fbeType === this.PongModuleResponseSenderModel.fbeType)) {
      return this.send_PongModuleResponse(value)
    }
    if ((value instanceof PingModuleReject) && (value.fbeType === this.PingModuleRejectSenderModel.fbeType)) {
      return this.send_PingModuleReject(value)
    }
    if ((value instanceof Deprecated3) && (value.fbeType === this.Deprecated3SenderModel.fbeType)) {
      return this.send_Deprecated3(value)
    }
    if ((value instanceof Deprecated4) && (value.fbeType === this.Deprecated4SenderModel.fbeType)) {
      return this.send_Deprecated4(value)
    }
    if ((value instanceof Deprecated1) && (value.fbeType === this.Deprecated1SenderModel.fbeType)) {
      return this.send_Deprecated1(value)
    }
    if ((value instanceof Deprecated2) && (value.fbeType === this.Deprecated2SenderModel.fbeType)) {
      return this.send_Deprecated2(value)
    }
    if ((value instanceof ManagerSessionInfoRequest) && (value.fbeType === this.ManagerSessionInfoRequestSenderModel.fbeType)) {
      return this.send_ManagerSessionInfoRequest(value)
    }
    if ((value instanceof ManagerSessionInfoResponse) && (value.fbeType === this.ManagerSessionInfoResponseSenderModel.fbeType)) {
      return this.send_ManagerSessionInfoResponse(value)
    }
    if ((value instanceof OnlineSessionsRequest) && (value.fbeType === this.OnlineSessionsRequestSenderModel.fbeType)) {
      return this.send_OnlineSessionsRequest(value)
    }
    if ((value instanceof OnlineSessionsResponse) && (value.fbeType === this.OnlineSessionsResponseSenderModel.fbeType)) {
      return this.send_OnlineSessionsResponse(value)
    }
    if ((value instanceof DropSessionRequest) && (value.fbeType === this.DropSessionRequestSenderModel.fbeType)) {
      return this.send_DropSessionRequest(value)
    }
    if ((value instanceof DropSessionResponse) && (value.fbeType === this.DropSessionResponseSenderModel.fbeType)) {
      return this.send_DropSessionResponse(value)
    }
    if ((value instanceof ConfigRequest) && (value.fbeType === this.ConfigRequestSenderModel.fbeType)) {
      return this.send_ConfigRequest(value)
    }
    if ((value instanceof ConfigResponse) && (value.fbeType === this.ConfigResponseSenderModel.fbeType)) {
      return this.send_ConfigResponse(value)
    }
    if ((value instanceof ConfigReject) && (value.fbeType === this.ConfigRejectSenderModel.fbeType)) {
      return this.send_ConfigReject(value)
    }
    if ((value instanceof ConfigUpdateRequest) && (value.fbeType === this.ConfigUpdateRequestSenderModel.fbeType)) {
      return this.send_ConfigUpdateRequest(value)
    }
    if ((value instanceof ConfigUpdateResponse) && (value.fbeType === this.ConfigUpdateResponseSenderModel.fbeType)) {
      return this.send_ConfigUpdateResponse(value)
    }
    if ((value instanceof ConfigUpdateNotify) && (value.fbeType === this.ConfigUpdateNotifySenderModel.fbeType)) {
      return this.send_ConfigUpdateNotify(value)
    }
    if ((value instanceof ConfigVersionRequest) && (value.fbeType === this.ConfigVersionRequestSenderModel.fbeType)) {
      return this.send_ConfigVersionRequest(value)
    }
    if ((value instanceof ConfigVersionResponse) && (value.fbeType === this.ConfigVersionResponseSenderModel.fbeType)) {
      return this.send_ConfigVersionResponse(value)
    }
    if ((value instanceof ConfigExportRequest) && (value.fbeType === this.ConfigExportRequestSenderModel.fbeType)) {
      return this.send_ConfigExportRequest(value)
    }
    if ((value instanceof ConfigExportResponse) && (value.fbeType === this.ConfigExportResponseSenderModel.fbeType)) {
      return this.send_ConfigExportResponse(value)
    }
    if ((value instanceof ConfigImportRequest) && (value.fbeType === this.ConfigImportRequestSenderModel.fbeType)) {
      return this.send_ConfigImportRequest(value)
    }
    if ((value instanceof ConfigImportResponse) && (value.fbeType === this.ConfigImportResponseSenderModel.fbeType)) {
      return this.send_ConfigImportResponse(value)
    }
    if ((value instanceof ConfigValidateRequest) && (value.fbeType === this.ConfigValidateRequestSenderModel.fbeType)) {
      return this.send_ConfigValidateRequest(value)
    }
    if ((value instanceof ConfigValidateResponse) && (value.fbeType === this.ConfigValidateResponseSenderModel.fbeType)) {
      return this.send_ConfigValidateResponse(value)
    }
    if ((value instanceof ConfigRefreshRequest) && (value.fbeType === this.ConfigRefreshRequestSenderModel.fbeType)) {
      return this.send_ConfigRefreshRequest(value)
    }
    if ((value instanceof ConfigRefreshResponse) && (value.fbeType === this.ConfigRefreshResponseSenderModel.fbeType)) {
      return this.send_ConfigRefreshResponse(value)
    }
    if ((value instanceof ConfigHistoryRequest) && (value.fbeType === this.ConfigHistoryRequestSenderModel.fbeType)) {
      return this.send_ConfigHistoryRequest(value)
    }
    if ((value instanceof ConfigHistoryResponse) && (value.fbeType === this.ConfigHistoryResponseSenderModel.fbeType)) {
      return this.send_ConfigHistoryResponse(value)
    }
    if ((value instanceof ConfigRollbackRequest) && (value.fbeType === this.ConfigRollbackRequestSenderModel.fbeType)) {
      return this.send_ConfigRollbackRequest(value)
    }
    if ((value instanceof ConfigRollbackResponse) && (value.fbeType === this.ConfigRollbackResponseSenderModel.fbeType)) {
      return this.send_ConfigRollbackResponse(value)
    }
    if ((value instanceof HostGetRequest) && (value.fbeType === this.HostGetRequestSenderModel.fbeType)) {
      return this.send_HostGetRequest(value)
    }
    if ((value instanceof HostGetResponse) && (value.fbeType === this.HostGetResponseSenderModel.fbeType)) {
      return this.send_HostGetResponse(value)
    }
    if ((value instanceof HostsGetAllRequest) && (value.fbeType === this.HostsGetAllRequestSenderModel.fbeType)) {
      return this.send_HostsGetAllRequest(value)
    }
    if ((value instanceof HostsGetAllResponse) && (value.fbeType === this.HostsGetAllResponseSenderModel.fbeType)) {
      return this.send_HostsGetAllResponse(value)
    }
    if ((value instanceof HostCreateRequest) && (value.fbeType === this.HostCreateRequestSenderModel.fbeType)) {
      return this.send_HostCreateRequest(value)
    }
    if ((value instanceof HostCreateResponse) && (value.fbeType === this.HostCreateResponseSenderModel.fbeType)) {
      return this.send_HostCreateResponse(value)
    }
    if ((value instanceof HostModifyRequest) && (value.fbeType === this.HostModifyRequestSenderModel.fbeType)) {
      return this.send_HostModifyRequest(value)
    }
    if ((value instanceof HostModifyResponse) && (value.fbeType === this.HostModifyResponseSenderModel.fbeType)) {
      return this.send_HostModifyResponse(value)
    }
    if ((value instanceof HostDeleteRequest) && (value.fbeType === this.HostDeleteRequestSenderModel.fbeType)) {
      return this.send_HostDeleteRequest(value)
    }
    if ((value instanceof HostDeleteResponse) && (value.fbeType === this.HostDeleteResponseSenderModel.fbeType)) {
      return this.send_HostDeleteResponse(value)
    }
    if ((value instanceof HostUpdateNotify) && (value.fbeType === this.HostUpdateNotifySenderModel.fbeType)) {
      return this.send_HostUpdateNotify(value)
    }
    if ((value instanceof ModuleGetRequest) && (value.fbeType === this.ModuleGetRequestSenderModel.fbeType)) {
      return this.send_ModuleGetRequest(value)
    }
    if ((value instanceof ModuleGetResponse) && (value.fbeType === this.ModuleGetResponseSenderModel.fbeType)) {
      return this.send_ModuleGetResponse(value)
    }
    if ((value instanceof ModulesGetAllRequest) && (value.fbeType === this.ModulesGetAllRequestSenderModel.fbeType)) {
      return this.send_ModulesGetAllRequest(value)
    }
    if ((value instanceof ModulesGetAllResponse) && (value.fbeType === this.ModulesGetAllResponseSenderModel.fbeType)) {
      return this.send_ModulesGetAllResponse(value)
    }
    if ((value instanceof ModuleCreateRequest) && (value.fbeType === this.ModuleCreateRequestSenderModel.fbeType)) {
      return this.send_ModuleCreateRequest(value)
    }
    if ((value instanceof ModuleCreateResponse) && (value.fbeType === this.ModuleCreateResponseSenderModel.fbeType)) {
      return this.send_ModuleCreateResponse(value)
    }
    if ((value instanceof ModuleModifyRequest) && (value.fbeType === this.ModuleModifyRequestSenderModel.fbeType)) {
      return this.send_ModuleModifyRequest(value)
    }
    if ((value instanceof ModuleModifyResponse) && (value.fbeType === this.ModuleModifyResponseSenderModel.fbeType)) {
      return this.send_ModuleModifyResponse(value)
    }
    if ((value instanceof ModuleDeleteRequest) && (value.fbeType === this.ModuleDeleteRequestSenderModel.fbeType)) {
      return this.send_ModuleDeleteRequest(value)
    }
    if ((value instanceof ModuleDeleteResponse) && (value.fbeType === this.ModuleDeleteResponseSenderModel.fbeType)) {
      return this.send_ModuleDeleteResponse(value)
    }
    if ((value instanceof ModuleUpdateNotify) && (value.fbeType === this.ModuleUpdateNotifySenderModel.fbeType)) {
      return this.send_ModuleUpdateNotify(value)
    }
    if ((value instanceof ServerTimeGetRequest) && (value.fbeType === this.ServerTimeGetRequestSenderModel.fbeType)) {
      return this.send_ServerTimeGetRequest(value)
    }
    if ((value instanceof ServerTimeGetResponse) && (value.fbeType === this.ServerTimeGetResponseSenderModel.fbeType)) {
      return this.send_ServerTimeGetResponse(value)
    }
    if ((value instanceof ManagerPlatformTimezoneGetRequest) && (value.fbeType === this.ManagerPlatformTimezoneGetRequestSenderModel.fbeType)) {
      return this.send_ManagerPlatformTimezoneGetRequest(value)
    }
    if ((value instanceof ManagerPlatformTimezoneGetResponse) && (value.fbeType === this.ManagerPlatformTimezoneGetResponseSenderModel.fbeType)) {
      return this.send_ManagerPlatformTimezoneGetResponse(value)
    }
    if ((value instanceof TimezoneGetRequest) && (value.fbeType === this.TimezoneGetRequestSenderModel.fbeType)) {
      return this.send_TimezoneGetRequest(value)
    }
    if ((value instanceof TimezoneGetResponse) && (value.fbeType === this.TimezoneGetResponseSenderModel.fbeType)) {
      return this.send_TimezoneGetResponse(value)
    }
    if ((value instanceof TimezonesGetAllRequest) && (value.fbeType === this.TimezonesGetAllRequestSenderModel.fbeType)) {
      return this.send_TimezonesGetAllRequest(value)
    }
    if ((value instanceof TimezonesGetAllResponse) && (value.fbeType === this.TimezonesGetAllResponseSenderModel.fbeType)) {
      return this.send_TimezonesGetAllResponse(value)
    }
    if ((value instanceof TimezoneCreateRequest) && (value.fbeType === this.TimezoneCreateRequestSenderModel.fbeType)) {
      return this.send_TimezoneCreateRequest(value)
    }
    if ((value instanceof TimezoneCreateResponse) && (value.fbeType === this.TimezoneCreateResponseSenderModel.fbeType)) {
      return this.send_TimezoneCreateResponse(value)
    }
    if ((value instanceof TimezoneModifyRequest) && (value.fbeType === this.TimezoneModifyRequestSenderModel.fbeType)) {
      return this.send_TimezoneModifyRequest(value)
    }
    if ((value instanceof TimezoneModifyResponse) && (value.fbeType === this.TimezoneModifyResponseSenderModel.fbeType)) {
      return this.send_TimezoneModifyResponse(value)
    }
    if ((value instanceof TimezoneDeleteRequest) && (value.fbeType === this.TimezoneDeleteRequestSenderModel.fbeType)) {
      return this.send_TimezoneDeleteRequest(value)
    }
    if ((value instanceof TimezoneDeleteResponse) && (value.fbeType === this.TimezoneDeleteResponseSenderModel.fbeType)) {
      return this.send_TimezoneDeleteResponse(value)
    }
    if ((value instanceof TimezoneUpdateNotify) && (value.fbeType === this.TimezoneUpdateNotifySenderModel.fbeType)) {
      return this.send_TimezoneUpdateNotify(value)
    }
    if ((value instanceof CurrencyGroupGetRequest) && (value.fbeType === this.CurrencyGroupGetRequestSenderModel.fbeType)) {
      return this.send_CurrencyGroupGetRequest(value)
    }
    if ((value instanceof CurrencyGroupGetResponse) && (value.fbeType === this.CurrencyGroupGetResponseSenderModel.fbeType)) {
      return this.send_CurrencyGroupGetResponse(value)
    }
    if ((value instanceof CurrencyGroupsGetAllRequest) && (value.fbeType === this.CurrencyGroupsGetAllRequestSenderModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllRequest(value)
    }
    if ((value instanceof CurrencyGroupsGetAllResponse) && (value.fbeType === this.CurrencyGroupsGetAllResponseSenderModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllResponse(value)
    }
    if ((value instanceof CurrencyGroupCreateRequest) && (value.fbeType === this.CurrencyGroupCreateRequestSenderModel.fbeType)) {
      return this.send_CurrencyGroupCreateRequest(value)
    }
    if ((value instanceof CurrencyGroupCreateResponse) && (value.fbeType === this.CurrencyGroupCreateResponseSenderModel.fbeType)) {
      return this.send_CurrencyGroupCreateResponse(value)
    }
    if ((value instanceof CurrencyGroupModifyRequest) && (value.fbeType === this.CurrencyGroupModifyRequestSenderModel.fbeType)) {
      return this.send_CurrencyGroupModifyRequest(value)
    }
    if ((value instanceof CurrencyGroupModifyResponse) && (value.fbeType === this.CurrencyGroupModifyResponseSenderModel.fbeType)) {
      return this.send_CurrencyGroupModifyResponse(value)
    }
    if ((value instanceof CurrencyGroupDeleteRequest) && (value.fbeType === this.CurrencyGroupDeleteRequestSenderModel.fbeType)) {
      return this.send_CurrencyGroupDeleteRequest(value)
    }
    if ((value instanceof CurrencyGroupDeleteResponse) && (value.fbeType === this.CurrencyGroupDeleteResponseSenderModel.fbeType)) {
      return this.send_CurrencyGroupDeleteResponse(value)
    }
    if ((value instanceof CurrencyGroupUpdateNotify) && (value.fbeType === this.CurrencyGroupUpdateNotifySenderModel.fbeType)) {
      return this.send_CurrencyGroupUpdateNotify(value)
    }
    if ((value instanceof CurrencyGetRequest) && (value.fbeType === this.CurrencyGetRequestSenderModel.fbeType)) {
      return this.send_CurrencyGetRequest(value)
    }
    if ((value instanceof CurrencyGetResponse) && (value.fbeType === this.CurrencyGetResponseSenderModel.fbeType)) {
      return this.send_CurrencyGetResponse(value)
    }
    if ((value instanceof CurrenciesGetAllRequest) && (value.fbeType === this.CurrenciesGetAllRequestSenderModel.fbeType)) {
      return this.send_CurrenciesGetAllRequest(value)
    }
    if ((value instanceof CurrenciesGetAllResponse) && (value.fbeType === this.CurrenciesGetAllResponseSenderModel.fbeType)) {
      return this.send_CurrenciesGetAllResponse(value)
    }
    if ((value instanceof CurrencyCreateRequest) && (value.fbeType === this.CurrencyCreateRequestSenderModel.fbeType)) {
      return this.send_CurrencyCreateRequest(value)
    }
    if ((value instanceof CurrencyCreateResponse) && (value.fbeType === this.CurrencyCreateResponseSenderModel.fbeType)) {
      return this.send_CurrencyCreateResponse(value)
    }
    if ((value instanceof CurrencyModifyRequest) && (value.fbeType === this.CurrencyModifyRequestSenderModel.fbeType)) {
      return this.send_CurrencyModifyRequest(value)
    }
    if ((value instanceof CurrencyModifyResponse) && (value.fbeType === this.CurrencyModifyResponseSenderModel.fbeType)) {
      return this.send_CurrencyModifyResponse(value)
    }
    if ((value instanceof CurrencyDeleteRequest) && (value.fbeType === this.CurrencyDeleteRequestSenderModel.fbeType)) {
      return this.send_CurrencyDeleteRequest(value)
    }
    if ((value instanceof CurrencyDeleteResponse) && (value.fbeType === this.CurrencyDeleteResponseSenderModel.fbeType)) {
      return this.send_CurrencyDeleteResponse(value)
    }
    if ((value instanceof CurrencyUpdateNotify) && (value.fbeType === this.CurrencyUpdateNotifySenderModel.fbeType)) {
      return this.send_CurrencyUpdateNotify(value)
    }
    if ((value instanceof SymbolGroupGetRequest) && (value.fbeType === this.SymbolGroupGetRequestSenderModel.fbeType)) {
      return this.send_SymbolGroupGetRequest(value)
    }
    if ((value instanceof SymbolGroupGetResponse) && (value.fbeType === this.SymbolGroupGetResponseSenderModel.fbeType)) {
      return this.send_SymbolGroupGetResponse(value)
    }
    if ((value instanceof SymbolGroupsGetAllRequest) && (value.fbeType === this.SymbolGroupsGetAllRequestSenderModel.fbeType)) {
      return this.send_SymbolGroupsGetAllRequest(value)
    }
    if ((value instanceof SymbolGroupsGetAllResponse) && (value.fbeType === this.SymbolGroupsGetAllResponseSenderModel.fbeType)) {
      return this.send_SymbolGroupsGetAllResponse(value)
    }
    if ((value instanceof SymbolGroupCreateRequest) && (value.fbeType === this.SymbolGroupCreateRequestSenderModel.fbeType)) {
      return this.send_SymbolGroupCreateRequest(value)
    }
    if ((value instanceof SymbolGroupCreateResponse) && (value.fbeType === this.SymbolGroupCreateResponseSenderModel.fbeType)) {
      return this.send_SymbolGroupCreateResponse(value)
    }
    if ((value instanceof SymbolGroupModifyRequest) && (value.fbeType === this.SymbolGroupModifyRequestSenderModel.fbeType)) {
      return this.send_SymbolGroupModifyRequest(value)
    }
    if ((value instanceof SymbolGroupModifyResponse) && (value.fbeType === this.SymbolGroupModifyResponseSenderModel.fbeType)) {
      return this.send_SymbolGroupModifyResponse(value)
    }
    if ((value instanceof SymbolGroupDeleteRequest) && (value.fbeType === this.SymbolGroupDeleteRequestSenderModel.fbeType)) {
      return this.send_SymbolGroupDeleteRequest(value)
    }
    if ((value instanceof SymbolGroupDeleteResponse) && (value.fbeType === this.SymbolGroupDeleteResponseSenderModel.fbeType)) {
      return this.send_SymbolGroupDeleteResponse(value)
    }
    if ((value instanceof SymbolGroupUpdateNotify) && (value.fbeType === this.SymbolGroupUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolGroupUpdateNotify(value)
    }
    if ((value instanceof SymbolGetRequest) && (value.fbeType === this.SymbolGetRequestSenderModel.fbeType)) {
      return this.send_SymbolGetRequest(value)
    }
    if ((value instanceof SymbolGetResponse) && (value.fbeType === this.SymbolGetResponseSenderModel.fbeType)) {
      return this.send_SymbolGetResponse(value)
    }
    if ((value instanceof SymbolsGetAllRequest) && (value.fbeType === this.SymbolsGetAllRequestSenderModel.fbeType)) {
      return this.send_SymbolsGetAllRequest(value)
    }
    if ((value instanceof SymbolsGetAllResponse) && (value.fbeType === this.SymbolsGetAllResponseSenderModel.fbeType)) {
      return this.send_SymbolsGetAllResponse(value)
    }
    if ((value instanceof SymbolCreateRequest) && (value.fbeType === this.SymbolCreateRequestSenderModel.fbeType)) {
      return this.send_SymbolCreateRequest(value)
    }
    if ((value instanceof SymbolCreateResponse) && (value.fbeType === this.SymbolCreateResponseSenderModel.fbeType)) {
      return this.send_SymbolCreateResponse(value)
    }
    if ((value instanceof SymbolModifyRequest) && (value.fbeType === this.SymbolModifyRequestSenderModel.fbeType)) {
      return this.send_SymbolModifyRequest(value)
    }
    if ((value instanceof SymbolModifyResponse) && (value.fbeType === this.SymbolModifyResponseSenderModel.fbeType)) {
      return this.send_SymbolModifyResponse(value)
    }
    if ((value instanceof SymbolDeleteRequest) && (value.fbeType === this.SymbolDeleteRequestSenderModel.fbeType)) {
      return this.send_SymbolDeleteRequest(value)
    }
    if ((value instanceof SymbolDeleteResponse) && (value.fbeType === this.SymbolDeleteResponseSenderModel.fbeType)) {
      return this.send_SymbolDeleteResponse(value)
    }
    if ((value instanceof SymbolUpdateNotify) && (value.fbeType === this.SymbolUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolUpdateNotify(value)
    }
    if ((value instanceof SwapsExportRequest) && (value.fbeType === this.SwapsExportRequestSenderModel.fbeType)) {
      return this.send_SwapsExportRequest(value)
    }
    if ((value instanceof SwapsExportResponse) && (value.fbeType === this.SwapsExportResponseSenderModel.fbeType)) {
      return this.send_SwapsExportResponse(value)
    }
    if ((value instanceof SwapsImportRequest) && (value.fbeType === this.SwapsImportRequestSenderModel.fbeType)) {
      return this.send_SwapsImportRequest(value)
    }
    if ((value instanceof SwapsImportResponse) && (value.fbeType === this.SwapsImportResponseSenderModel.fbeType)) {
      return this.send_SwapsImportResponse(value)
    }
    if ((value instanceof CommissionProfileGetRequest) && (value.fbeType === this.CommissionProfileGetRequestSenderModel.fbeType)) {
      return this.send_CommissionProfileGetRequest(value)
    }
    if ((value instanceof CommissionProfileGetResponse) && (value.fbeType === this.CommissionProfileGetResponseSenderModel.fbeType)) {
      return this.send_CommissionProfileGetResponse(value)
    }
    if ((value instanceof CommissionProfilesGetAllRequest) && (value.fbeType === this.CommissionProfilesGetAllRequestSenderModel.fbeType)) {
      return this.send_CommissionProfilesGetAllRequest(value)
    }
    if ((value instanceof CommissionProfilesGetAllResponse) && (value.fbeType === this.CommissionProfilesGetAllResponseSenderModel.fbeType)) {
      return this.send_CommissionProfilesGetAllResponse(value)
    }
    if ((value instanceof CommissionProfileCreateRequest) && (value.fbeType === this.CommissionProfileCreateRequestSenderModel.fbeType)) {
      return this.send_CommissionProfileCreateRequest(value)
    }
    if ((value instanceof CommissionProfileCreateResponse) && (value.fbeType === this.CommissionProfileCreateResponseSenderModel.fbeType)) {
      return this.send_CommissionProfileCreateResponse(value)
    }
    if ((value instanceof CommissionProfileModifyRequest) && (value.fbeType === this.CommissionProfileModifyRequestSenderModel.fbeType)) {
      return this.send_CommissionProfileModifyRequest(value)
    }
    if ((value instanceof CommissionProfileModifyResponse) && (value.fbeType === this.CommissionProfileModifyResponseSenderModel.fbeType)) {
      return this.send_CommissionProfileModifyResponse(value)
    }
    if ((value instanceof CommissionProfileDeleteRequest) && (value.fbeType === this.CommissionProfileDeleteRequestSenderModel.fbeType)) {
      return this.send_CommissionProfileDeleteRequest(value)
    }
    if ((value instanceof CommissionProfileDeleteResponse) && (value.fbeType === this.CommissionProfileDeleteResponseSenderModel.fbeType)) {
      return this.send_CommissionProfileDeleteResponse(value)
    }
    if ((value instanceof CommissionProfileUpdateNotify) && (value.fbeType === this.CommissionProfileUpdateNotifySenderModel.fbeType)) {
      return this.send_CommissionProfileUpdateNotify(value)
    }
    if ((value instanceof MarkupProfileGetRequest) && (value.fbeType === this.MarkupProfileGetRequestSenderModel.fbeType)) {
      return this.send_MarkupProfileGetRequest(value)
    }
    if ((value instanceof MarkupProfileGetResponse) && (value.fbeType === this.MarkupProfileGetResponseSenderModel.fbeType)) {
      return this.send_MarkupProfileGetResponse(value)
    }
    if ((value instanceof MarkupProfilesGetAllRequest) && (value.fbeType === this.MarkupProfilesGetAllRequestSenderModel.fbeType)) {
      return this.send_MarkupProfilesGetAllRequest(value)
    }
    if ((value instanceof MarkupProfilesGetAllResponse) && (value.fbeType === this.MarkupProfilesGetAllResponseSenderModel.fbeType)) {
      return this.send_MarkupProfilesGetAllResponse(value)
    }
    if ((value instanceof MarkupProfileCreateRequest) && (value.fbeType === this.MarkupProfileCreateRequestSenderModel.fbeType)) {
      return this.send_MarkupProfileCreateRequest(value)
    }
    if ((value instanceof MarkupProfileCreateResponse) && (value.fbeType === this.MarkupProfileCreateResponseSenderModel.fbeType)) {
      return this.send_MarkupProfileCreateResponse(value)
    }
    if ((value instanceof MarkupProfileModifyRequest) && (value.fbeType === this.MarkupProfileModifyRequestSenderModel.fbeType)) {
      return this.send_MarkupProfileModifyRequest(value)
    }
    if ((value instanceof MarkupProfileModifyResponse) && (value.fbeType === this.MarkupProfileModifyResponseSenderModel.fbeType)) {
      return this.send_MarkupProfileModifyResponse(value)
    }
    if ((value instanceof MarkupProfileDeleteRequest) && (value.fbeType === this.MarkupProfileDeleteRequestSenderModel.fbeType)) {
      return this.send_MarkupProfileDeleteRequest(value)
    }
    if ((value instanceof MarkupProfileDeleteResponse) && (value.fbeType === this.MarkupProfileDeleteResponseSenderModel.fbeType)) {
      return this.send_MarkupProfileDeleteResponse(value)
    }
    if ((value instanceof MarkupProfileUpdateNotify) && (value.fbeType === this.MarkupProfileUpdateNotifySenderModel.fbeType)) {
      return this.send_MarkupProfileUpdateNotify(value)
    }
    if ((value instanceof ManagerGroupGetRequest) && (value.fbeType === this.ManagerGroupGetRequestSenderModel.fbeType)) {
      return this.send_ManagerGroupGetRequest(value)
    }
    if ((value instanceof ManagerGroupGetResponse) && (value.fbeType === this.ManagerGroupGetResponseSenderModel.fbeType)) {
      return this.send_ManagerGroupGetResponse(value)
    }
    if ((value instanceof ManagerGroupsGetAllRequest) && (value.fbeType === this.ManagerGroupsGetAllRequestSenderModel.fbeType)) {
      return this.send_ManagerGroupsGetAllRequest(value)
    }
    if ((value instanceof ManagerGroupsGetAllResponse) && (value.fbeType === this.ManagerGroupsGetAllResponseSenderModel.fbeType)) {
      return this.send_ManagerGroupsGetAllResponse(value)
    }
    if ((value instanceof ManagerGroupCreateRequest) && (value.fbeType === this.ManagerGroupCreateRequestSenderModel.fbeType)) {
      return this.send_ManagerGroupCreateRequest(value)
    }
    if ((value instanceof ManagerGroupCreateResponse) && (value.fbeType === this.ManagerGroupCreateResponseSenderModel.fbeType)) {
      return this.send_ManagerGroupCreateResponse(value)
    }
    if ((value instanceof ManagerGroupModifyRequest) && (value.fbeType === this.ManagerGroupModifyRequestSenderModel.fbeType)) {
      return this.send_ManagerGroupModifyRequest(value)
    }
    if ((value instanceof ManagerGroupModifyResponse) && (value.fbeType === this.ManagerGroupModifyResponseSenderModel.fbeType)) {
      return this.send_ManagerGroupModifyResponse(value)
    }
    if ((value instanceof ManagerGroupDeleteRequest) && (value.fbeType === this.ManagerGroupDeleteRequestSenderModel.fbeType)) {
      return this.send_ManagerGroupDeleteRequest(value)
    }
    if ((value instanceof ManagerGroupDeleteResponse) && (value.fbeType === this.ManagerGroupDeleteResponseSenderModel.fbeType)) {
      return this.send_ManagerGroupDeleteResponse(value)
    }
    if ((value instanceof ManagerGroupUpdateNotify) && (value.fbeType === this.ManagerGroupUpdateNotifySenderModel.fbeType)) {
      return this.send_ManagerGroupUpdateNotify(value)
    }
    if ((value instanceof ManagerGetRequest) && (value.fbeType === this.ManagerGetRequestSenderModel.fbeType)) {
      return this.send_ManagerGetRequest(value)
    }
    if ((value instanceof ManagerGetResponse) && (value.fbeType === this.ManagerGetResponseSenderModel.fbeType)) {
      return this.send_ManagerGetResponse(value)
    }
    if ((value instanceof ManagersGetAllRequest) && (value.fbeType === this.ManagersGetAllRequestSenderModel.fbeType)) {
      return this.send_ManagersGetAllRequest(value)
    }
    if ((value instanceof ManagersGetAllResponse) && (value.fbeType === this.ManagersGetAllResponseSenderModel.fbeType)) {
      return this.send_ManagersGetAllResponse(value)
    }
    if ((value instanceof ManagerCreateRequest) && (value.fbeType === this.ManagerCreateRequestSenderModel.fbeType)) {
      return this.send_ManagerCreateRequest(value)
    }
    if ((value instanceof ManagerCreateResponse) && (value.fbeType === this.ManagerCreateResponseSenderModel.fbeType)) {
      return this.send_ManagerCreateResponse(value)
    }
    if ((value instanceof ManagerModifyRequest) && (value.fbeType === this.ManagerModifyRequestSenderModel.fbeType)) {
      return this.send_ManagerModifyRequest(value)
    }
    if ((value instanceof ManagerModifyResponse) && (value.fbeType === this.ManagerModifyResponseSenderModel.fbeType)) {
      return this.send_ManagerModifyResponse(value)
    }
    if ((value instanceof ManagerDeleteRequest) && (value.fbeType === this.ManagerDeleteRequestSenderModel.fbeType)) {
      return this.send_ManagerDeleteRequest(value)
    }
    if ((value instanceof ManagerDeleteResponse) && (value.fbeType === this.ManagerDeleteResponseSenderModel.fbeType)) {
      return this.send_ManagerDeleteResponse(value)
    }
    if ((value instanceof ManagerUpdateNotify) && (value.fbeType === this.ManagerUpdateNotifySenderModel.fbeType)) {
      return this.send_ManagerUpdateNotify(value)
    }
    if ((value instanceof PlatformGetRequest) && (value.fbeType === this.PlatformGetRequestSenderModel.fbeType)) {
      return this.send_PlatformGetRequest(value)
    }
    if ((value instanceof PlatformGetResponse) && (value.fbeType === this.PlatformGetResponseSenderModel.fbeType)) {
      return this.send_PlatformGetResponse(value)
    }
    if ((value instanceof PlatformsGetAllRequest) && (value.fbeType === this.PlatformsGetAllRequestSenderModel.fbeType)) {
      return this.send_PlatformsGetAllRequest(value)
    }
    if ((value instanceof PlatformsGetAllResponse) && (value.fbeType === this.PlatformsGetAllResponseSenderModel.fbeType)) {
      return this.send_PlatformsGetAllResponse(value)
    }
    if ((value instanceof PlatformCreateRequest) && (value.fbeType === this.PlatformCreateRequestSenderModel.fbeType)) {
      return this.send_PlatformCreateRequest(value)
    }
    if ((value instanceof PlatformCreateResponse) && (value.fbeType === this.PlatformCreateResponseSenderModel.fbeType)) {
      return this.send_PlatformCreateResponse(value)
    }
    if ((value instanceof PlatformModifyRequest) && (value.fbeType === this.PlatformModifyRequestSenderModel.fbeType)) {
      return this.send_PlatformModifyRequest(value)
    }
    if ((value instanceof PlatformModifyResponse) && (value.fbeType === this.PlatformModifyResponseSenderModel.fbeType)) {
      return this.send_PlatformModifyResponse(value)
    }
    if ((value instanceof PlatformDeleteRequest) && (value.fbeType === this.PlatformDeleteRequestSenderModel.fbeType)) {
      return this.send_PlatformDeleteRequest(value)
    }
    if ((value instanceof PlatformDeleteResponse) && (value.fbeType === this.PlatformDeleteResponseSenderModel.fbeType)) {
      return this.send_PlatformDeleteResponse(value)
    }
    if ((value instanceof PlatformUpdateNotify) && (value.fbeType === this.PlatformUpdateNotifySenderModel.fbeType)) {
      return this.send_PlatformUpdateNotify(value)
    }
    if ((value instanceof AccountGroupGetRequest) && (value.fbeType === this.AccountGroupGetRequestSenderModel.fbeType)) {
      return this.send_AccountGroupGetRequest(value)
    }
    if ((value instanceof AccountGroupGetResponse) && (value.fbeType === this.AccountGroupGetResponseSenderModel.fbeType)) {
      return this.send_AccountGroupGetResponse(value)
    }
    if ((value instanceof AccountGroupsGetAllRequest) && (value.fbeType === this.AccountGroupsGetAllRequestSenderModel.fbeType)) {
      return this.send_AccountGroupsGetAllRequest(value)
    }
    if ((value instanceof AccountGroupsGetAllResponse) && (value.fbeType === this.AccountGroupsGetAllResponseSenderModel.fbeType)) {
      return this.send_AccountGroupsGetAllResponse(value)
    }
    if ((value instanceof AccountGroupCreateRequest) && (value.fbeType === this.AccountGroupCreateRequestSenderModel.fbeType)) {
      return this.send_AccountGroupCreateRequest(value)
    }
    if ((value instanceof AccountGroupCreateResponse) && (value.fbeType === this.AccountGroupCreateResponseSenderModel.fbeType)) {
      return this.send_AccountGroupCreateResponse(value)
    }
    if ((value instanceof AccountGroupModifyRequest) && (value.fbeType === this.AccountGroupModifyRequestSenderModel.fbeType)) {
      return this.send_AccountGroupModifyRequest(value)
    }
    if ((value instanceof AccountGroupModifyResponse) && (value.fbeType === this.AccountGroupModifyResponseSenderModel.fbeType)) {
      return this.send_AccountGroupModifyResponse(value)
    }
    if ((value instanceof AccountGroupDeleteRequest) && (value.fbeType === this.AccountGroupDeleteRequestSenderModel.fbeType)) {
      return this.send_AccountGroupDeleteRequest(value)
    }
    if ((value instanceof AccountGroupDeleteResponse) && (value.fbeType === this.AccountGroupDeleteResponseSenderModel.fbeType)) {
      return this.send_AccountGroupDeleteResponse(value)
    }
    if ((value instanceof AccountGroupUpdateNotify) && (value.fbeType === this.AccountGroupUpdateNotifySenderModel.fbeType)) {
      return this.send_AccountGroupUpdateNotify(value)
    }
    if ((value instanceof AccountGetRequest) && (value.fbeType === this.AccountGetRequestSenderModel.fbeType)) {
      return this.send_AccountGetRequest(value)
    }
    if ((value instanceof AccountGetResponse) && (value.fbeType === this.AccountGetResponseSenderModel.fbeType)) {
      return this.send_AccountGetResponse(value)
    }
    if ((value instanceof AccountsGetAllRequest) && (value.fbeType === this.AccountsGetAllRequestSenderModel.fbeType)) {
      return this.send_AccountsGetAllRequest(value)
    }
    if ((value instanceof AccountsGetAllResponse) && (value.fbeType === this.AccountsGetAllResponseSenderModel.fbeType)) {
      return this.send_AccountsGetAllResponse(value)
    }
    if ((value instanceof AccountCreateRequest) && (value.fbeType === this.AccountCreateRequestSenderModel.fbeType)) {
      return this.send_AccountCreateRequest(value)
    }
    if ((value instanceof AccountCreateResponse) && (value.fbeType === this.AccountCreateResponseSenderModel.fbeType)) {
      return this.send_AccountCreateResponse(value)
    }
    if ((value instanceof AccountSocialTradingMasterCreateRequest) && (value.fbeType === this.AccountSocialTradingMasterCreateRequestSenderModel.fbeType)) {
      return this.send_AccountSocialTradingMasterCreateRequest(value)
    }
    if ((value instanceof AccountSocialTradingMasterCreateResponse) && (value.fbeType === this.AccountSocialTradingMasterCreateResponseSenderModel.fbeType)) {
      return this.send_AccountSocialTradingMasterCreateResponse(value)
    }
    if ((value instanceof AccountSocialTradingInvestorCreateRequest) && (value.fbeType === this.AccountSocialTradingInvestorCreateRequestSenderModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorCreateRequest(value)
    }
    if ((value instanceof AccountSocialTradingInvestorCreateResponse) && (value.fbeType === this.AccountSocialTradingInvestorCreateResponseSenderModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorCreateResponse(value)
    }
    if ((value instanceof AccountModifyRequest) && (value.fbeType === this.AccountModifyRequestSenderModel.fbeType)) {
      return this.send_AccountModifyRequest(value)
    }
    if ((value instanceof AccountModifyResponse) && (value.fbeType === this.AccountModifyResponseSenderModel.fbeType)) {
      return this.send_AccountModifyResponse(value)
    }
    if ((value instanceof AccountDeleteRequest) && (value.fbeType === this.AccountDeleteRequestSenderModel.fbeType)) {
      return this.send_AccountDeleteRequest(value)
    }
    if ((value instanceof AccountDeleteResponse) && (value.fbeType === this.AccountDeleteResponseSenderModel.fbeType)) {
      return this.send_AccountDeleteResponse(value)
    }
    if ((value instanceof DemoAccountsDeleteRequest) && (value.fbeType === this.DemoAccountsDeleteRequestSenderModel.fbeType)) {
      return this.send_DemoAccountsDeleteRequest(value)
    }
    if ((value instanceof DemoAccountsDeleteResponse) && (value.fbeType === this.DemoAccountsDeleteResponseSenderModel.fbeType)) {
      return this.send_DemoAccountsDeleteResponse(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingMasterRequest) && (value.fbeType === this.AccountUpgradeToSocialTradingMasterRequestSenderModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingMasterRequest(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingMasterResponse) && (value.fbeType === this.AccountUpgradeToSocialTradingMasterResponseSenderModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingMasterResponse(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingInvestorRequest) && (value.fbeType === this.AccountUpgradeToSocialTradingInvestorRequestSenderModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingInvestorRequest(value)
    }
    if ((value instanceof AccountUpgradeToSocialTradingInvestorResponse) && (value.fbeType === this.AccountUpgradeToSocialTradingInvestorResponseSenderModel.fbeType)) {
      return this.send_AccountUpgradeToSocialTradingInvestorResponse(value)
    }
    if ((value instanceof SocialTradingInvestorAccountToRegularRequest) && (value.fbeType === this.SocialTradingInvestorAccountToRegularRequestSenderModel.fbeType)) {
      return this.send_SocialTradingInvestorAccountToRegularRequest(value)
    }
    if ((value instanceof SocialTradingInvestorAccountToRegularResponse) && (value.fbeType === this.SocialTradingInvestorAccountToRegularResponseSenderModel.fbeType)) {
      return this.send_SocialTradingInvestorAccountToRegularResponse(value)
    }
    if ((value instanceof SocialTradingMasterAccountToRegularRequest) && (value.fbeType === this.SocialTradingMasterAccountToRegularRequestSenderModel.fbeType)) {
      return this.send_SocialTradingMasterAccountToRegularRequest(value)
    }
    if ((value instanceof SocialTradingMasterAccountToRegularResponse) && (value.fbeType === this.SocialTradingMasterAccountToRegularResponseSenderModel.fbeType)) {
      return this.send_SocialTradingMasterAccountToRegularResponse(value)
    }
    if ((value instanceof AccountUpdateNotify) && (value.fbeType === this.AccountUpdateNotifySenderModel.fbeType)) {
      return this.send_AccountUpdateNotify(value)
    }
    if ((value instanceof MarginGetRequest) && (value.fbeType === this.MarginGetRequestSenderModel.fbeType)) {
      return this.send_MarginGetRequest(value)
    }
    if ((value instanceof MarginGetResponse) && (value.fbeType === this.MarginGetResponseSenderModel.fbeType)) {
      return this.send_MarginGetResponse(value)
    }
    if ((value instanceof MarginsGetAllRequest) && (value.fbeType === this.MarginsGetAllRequestSenderModel.fbeType)) {
      return this.send_MarginsGetAllRequest(value)
    }
    if ((value instanceof MarginsGetAllResponse) && (value.fbeType === this.MarginsGetAllResponseSenderModel.fbeType)) {
      return this.send_MarginsGetAllResponse(value)
    }
    if ((value instanceof MarginUpdateNotify) && (value.fbeType === this.MarginUpdateNotifySenderModel.fbeType)) {
      return this.send_MarginUpdateNotify(value)
    }
    if ((value instanceof MarginCallNotify) && (value.fbeType === this.MarginCallNotifySenderModel.fbeType)) {
      return this.send_MarginCallNotify(value)
    }
    if ((value instanceof StopOutNotify) && (value.fbeType === this.StopOutNotifySenderModel.fbeType)) {
      return this.send_StopOutNotify(value)
    }
    if ((value instanceof AssetGetRequest) && (value.fbeType === this.AssetGetRequestSenderModel.fbeType)) {
      return this.send_AssetGetRequest(value)
    }
    if ((value instanceof AssetGetResponse) && (value.fbeType === this.AssetGetResponseSenderModel.fbeType)) {
      return this.send_AssetGetResponse(value)
    }
    if ((value instanceof AssetsGetRequest) && (value.fbeType === this.AssetsGetRequestSenderModel.fbeType)) {
      return this.send_AssetsGetRequest(value)
    }
    if ((value instanceof AssetsGetResponse) && (value.fbeType === this.AssetsGetResponseSenderModel.fbeType)) {
      return this.send_AssetsGetResponse(value)
    }
    if ((value instanceof AssetsGetAllRequest) && (value.fbeType === this.AssetsGetAllRequestSenderModel.fbeType)) {
      return this.send_AssetsGetAllRequest(value)
    }
    if ((value instanceof AssetsGetAllResponse) && (value.fbeType === this.AssetsGetAllResponseSenderModel.fbeType)) {
      return this.send_AssetsGetAllResponse(value)
    }
    if ((value instanceof AssetUpdateNotify) && (value.fbeType === this.AssetUpdateNotifySenderModel.fbeType)) {
      return this.send_AssetUpdateNotify(value)
    }
    if ((value instanceof OrderGetRequest) && (value.fbeType === this.OrderGetRequestSenderModel.fbeType)) {
      return this.send_OrderGetRequest(value)
    }
    if ((value instanceof OrderGetResponse) && (value.fbeType === this.OrderGetResponseSenderModel.fbeType)) {
      return this.send_OrderGetResponse(value)
    }
    if ((value instanceof OrdersGetRequest) && (value.fbeType === this.OrdersGetRequestSenderModel.fbeType)) {
      return this.send_OrdersGetRequest(value)
    }
    if ((value instanceof OrdersGetResponse) && (value.fbeType === this.OrdersGetResponseSenderModel.fbeType)) {
      return this.send_OrdersGetResponse(value)
    }
    if ((value instanceof OrdersGetAllRequest) && (value.fbeType === this.OrdersGetAllRequestSenderModel.fbeType)) {
      return this.send_OrdersGetAllRequest(value)
    }
    if ((value instanceof OrdersGetAllResponse) && (value.fbeType === this.OrdersGetAllResponseSenderModel.fbeType)) {
      return this.send_OrdersGetAllResponse(value)
    }
    if ((value instanceof OrderCreateRequest) && (value.fbeType === this.OrderCreateRequestSenderModel.fbeType)) {
      return this.send_OrderCreateRequest(value)
    }
    if ((value instanceof OrderCreateResponse) && (value.fbeType === this.OrderCreateResponseSenderModel.fbeType)) {
      return this.send_OrderCreateResponse(value)
    }
    if ((value instanceof OrderModifyRequest) && (value.fbeType === this.OrderModifyRequestSenderModel.fbeType)) {
      return this.send_OrderModifyRequest(value)
    }
    if ((value instanceof OrderModifyResponse) && (value.fbeType === this.OrderModifyResponseSenderModel.fbeType)) {
      return this.send_OrderModifyResponse(value)
    }
    if ((value instanceof OrderActivateRequest) && (value.fbeType === this.OrderActivateRequestSenderModel.fbeType)) {
      return this.send_OrderActivateRequest(value)
    }
    if ((value instanceof OrderActivateResponse) && (value.fbeType === this.OrderActivateResponseSenderModel.fbeType)) {
      return this.send_OrderActivateResponse(value)
    }
    if ((value instanceof OrderCancelRequest) && (value.fbeType === this.OrderCancelRequestSenderModel.fbeType)) {
      return this.send_OrderCancelRequest(value)
    }
    if ((value instanceof OrderCancelResponse) && (value.fbeType === this.OrderCancelResponseSenderModel.fbeType)) {
      return this.send_OrderCancelResponse(value)
    }
    if ((value instanceof OrderCancelAllRequest) && (value.fbeType === this.OrderCancelAllRequestSenderModel.fbeType)) {
      return this.send_OrderCancelAllRequest(value)
    }
    if ((value instanceof OrderCancelAllResponse) && (value.fbeType === this.OrderCancelAllResponseSenderModel.fbeType)) {
      return this.send_OrderCancelAllResponse(value)
    }
    if ((value instanceof OrderCancelAllByIdRequest) && (value.fbeType === this.OrderCancelAllByIdRequestSenderModel.fbeType)) {
      return this.send_OrderCancelAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseRequest) && (value.fbeType === this.OrderCloseRequestSenderModel.fbeType)) {
      return this.send_OrderCloseRequest(value)
    }
    if ((value instanceof OrderCloseResponse) && (value.fbeType === this.OrderCloseResponseSenderModel.fbeType)) {
      return this.send_OrderCloseResponse(value)
    }
    if ((value instanceof OrderCloseAllRequest) && (value.fbeType === this.OrderCloseAllRequestSenderModel.fbeType)) {
      return this.send_OrderCloseAllRequest(value)
    }
    if ((value instanceof OrderCloseAllResponse) && (value.fbeType === this.OrderCloseAllResponseSenderModel.fbeType)) {
      return this.send_OrderCloseAllResponse(value)
    }
    if ((value instanceof OrderCloseAllByIdRequest) && (value.fbeType === this.OrderCloseAllByIdRequestSenderModel.fbeType)) {
      return this.send_OrderCloseAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseByRequest) && (value.fbeType === this.OrderCloseByRequestSenderModel.fbeType)) {
      return this.send_OrderCloseByRequest(value)
    }
    if ((value instanceof OrderCloseByResponse) && (value.fbeType === this.OrderCloseByResponseSenderModel.fbeType)) {
      return this.send_OrderCloseByResponse(value)
    }
    if ((value instanceof OrderCloseByAllRequest) && (value.fbeType === this.OrderCloseByAllRequestSenderModel.fbeType)) {
      return this.send_OrderCloseByAllRequest(value)
    }
    if ((value instanceof OrderCloseByAllResponse) && (value.fbeType === this.OrderCloseByAllResponseSenderModel.fbeType)) {
      return this.send_OrderCloseByAllResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseRequest) && (value.fbeType === this.OrderCancelOrCloseRequestSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseResponse) && (value.fbeType === this.OrderCancelOrCloseResponseSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseAllRequest) && (value.fbeType === this.OrderCancelOrCloseAllRequestSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseAllResponse) && (value.fbeType === this.OrderCancelOrCloseAllResponseSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllResponse(value)
    }
    if ((value instanceof OrderUpdateNotify) && (value.fbeType === this.OrderUpdateNotifySenderModel.fbeType)) {
      return this.send_OrderUpdateNotify(value)
    }
    if ((value instanceof PositionGetRequest) && (value.fbeType === this.PositionGetRequestSenderModel.fbeType)) {
      return this.send_PositionGetRequest(value)
    }
    if ((value instanceof PositionGetResponse) && (value.fbeType === this.PositionGetResponseSenderModel.fbeType)) {
      return this.send_PositionGetResponse(value)
    }
    if ((value instanceof PositionsGetRequest) && (value.fbeType === this.PositionsGetRequestSenderModel.fbeType)) {
      return this.send_PositionsGetRequest(value)
    }
    if ((value instanceof PositionsGetResponse) && (value.fbeType === this.PositionsGetResponseSenderModel.fbeType)) {
      return this.send_PositionsGetResponse(value)
    }
    if ((value instanceof PositionsGetAllRequest) && (value.fbeType === this.PositionsGetAllRequestSenderModel.fbeType)) {
      return this.send_PositionsGetAllRequest(value)
    }
    if ((value instanceof PositionsGetAllResponse) && (value.fbeType === this.PositionsGetAllResponseSenderModel.fbeType)) {
      return this.send_PositionsGetAllResponse(value)
    }
    if ((value instanceof PositionUpdateNotify) && (value.fbeType === this.PositionUpdateNotifySenderModel.fbeType)) {
      return this.send_PositionUpdateNotify(value)
    }
    if ((value instanceof TradeHistoryRequest) && (value.fbeType === this.TradeHistoryRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryRequest(value)
    }
    if ((value instanceof TradeHistoryResponse) && (value.fbeType === this.TradeHistoryResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryResponse(value)
    }
    if ((value instanceof TradeHistoryFilterRequest) && (value.fbeType === this.TradeHistoryFilterRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryFilterRequest(value)
    }
    if ((value instanceof TradeHistoryByIdRequest) && (value.fbeType === this.TradeHistoryByIdRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryByIdRequest(value)
    }
    if ((value instanceof TradeHistoryByIdResponse) && (value.fbeType === this.TradeHistoryByIdResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryByIdResponse(value)
    }
    if ((value instanceof TradeHistoryUpdateNotify) && (value.fbeType === this.TradeHistoryUpdateNotifySenderModel.fbeType)) {
      return this.send_TradeHistoryUpdateNotify(value)
    }
    if ((value instanceof TestRolloverDumpRequest) && (value.fbeType === this.TestRolloverDumpRequestSenderModel.fbeType)) {
      return this.send_TestRolloverDumpRequest(value)
    }
    if ((value instanceof TestRolloverDumpResponse) && (value.fbeType === this.TestRolloverDumpResponseSenderModel.fbeType)) {
      return this.send_TestRolloverDumpResponse(value)
    }
    if ((value instanceof Deprecated001) && (value.fbeType === this.Deprecated001SenderModel.fbeType)) {
      return this.send_Deprecated001(value)
    }
    if ((value instanceof Deprecated002) && (value.fbeType === this.Deprecated002SenderModel.fbeType)) {
      return this.send_Deprecated002(value)
    }
    if ((value instanceof Deprecated003) && (value.fbeType === this.Deprecated003SenderModel.fbeType)) {
      return this.send_Deprecated003(value)
    }
    if ((value instanceof Deprecated004) && (value.fbeType === this.Deprecated004SenderModel.fbeType)) {
      return this.send_Deprecated004(value)
    }
    if ((value instanceof Deprecated005) && (value.fbeType === this.Deprecated005SenderModel.fbeType)) {
      return this.send_Deprecated005(value)
    }
    if ((value instanceof Deprecated006) && (value.fbeType === this.Deprecated006SenderModel.fbeType)) {
      return this.send_Deprecated006(value)
    }
    if ((value instanceof Deprecated007) && (value.fbeType === this.Deprecated007SenderModel.fbeType)) {
      return this.send_Deprecated007(value)
    }
    if ((value instanceof Deprecated008) && (value.fbeType === this.Deprecated008SenderModel.fbeType)) {
      return this.send_Deprecated008(value)
    }
    if ((value instanceof Deprecated009) && (value.fbeType === this.Deprecated009SenderModel.fbeType)) {
      return this.send_Deprecated009(value)
    }
    if ((value instanceof Deprecated010) && (value.fbeType === this.Deprecated010SenderModel.fbeType)) {
      return this.send_Deprecated010(value)
    }
    if ((value instanceof Deprecated011) && (value.fbeType === this.Deprecated011SenderModel.fbeType)) {
      return this.send_Deprecated011(value)
    }
    if ((value instanceof Deprecated012) && (value.fbeType === this.Deprecated012SenderModel.fbeType)) {
      return this.send_Deprecated012(value)
    }
    if ((value instanceof Deprecated013) && (value.fbeType === this.Deprecated013SenderModel.fbeType)) {
      return this.send_Deprecated013(value)
    }
    if ((value instanceof DepositAccountRequest) && (value.fbeType === this.DepositAccountRequestSenderModel.fbeType)) {
      return this.send_DepositAccountRequest(value)
    }
    if ((value instanceof DepositAccountResponse) && (value.fbeType === this.DepositAccountResponseSenderModel.fbeType)) {
      return this.send_DepositAccountResponse(value)
    }
    if ((value instanceof CreditAccountRequest) && (value.fbeType === this.CreditAccountRequestSenderModel.fbeType)) {
      return this.send_CreditAccountRequest(value)
    }
    if ((value instanceof CreditAccountResponse) && (value.fbeType === this.CreditAccountResponseSenderModel.fbeType)) {
      return this.send_CreditAccountResponse(value)
    }
    if ((value instanceof TransferMoneyRequest) && (value.fbeType === this.TransferMoneyRequestSenderModel.fbeType)) {
      return this.send_TransferMoneyRequest(value)
    }
    if ((value instanceof TransferMoneyResponse) && (value.fbeType === this.TransferMoneyResponseSenderModel.fbeType)) {
      return this.send_TransferMoneyResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleGetRequest) && (value.fbeType === this.TradingCalendarRegularRuleGetRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleGetRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleGetResponse) && (value.fbeType === this.TradingCalendarRegularRuleGetResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleGetResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRulesGetAllRequest) && (value.fbeType === this.TradingCalendarRegularRulesGetAllRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRulesGetAllRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRulesGetAllResponse) && (value.fbeType === this.TradingCalendarRegularRulesGetAllResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRulesGetAllResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleCreateRequest) && (value.fbeType === this.TradingCalendarRegularRuleCreateRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleCreateRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleCreateResponse) && (value.fbeType === this.TradingCalendarRegularRuleCreateResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleCreateResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleModifyRequest) && (value.fbeType === this.TradingCalendarRegularRuleModifyRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleModifyRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleModifyResponse) && (value.fbeType === this.TradingCalendarRegularRuleModifyResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleModifyResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleDeleteRequest) && (value.fbeType === this.TradingCalendarRegularRuleDeleteRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleDeleteRequest(value)
    }
    if ((value instanceof TradingCalendarRegularRuleDeleteResponse) && (value.fbeType === this.TradingCalendarRegularRuleDeleteResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleDeleteResponse(value)
    }
    if ((value instanceof TradingCalendarRegularRuleUpdateNotify) && (value.fbeType === this.TradingCalendarRegularRuleUpdateNotifySenderModel.fbeType)) {
      return this.send_TradingCalendarRegularRuleUpdateNotify(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleGetRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleGetRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleGetRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleGetResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleGetResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleGetResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRulesGetAllRequest) && (value.fbeType === this.TradingCalendarOffTimeRulesGetAllRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRulesGetAllRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRulesGetAllResponse) && (value.fbeType === this.TradingCalendarOffTimeRulesGetAllResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRulesGetAllResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleCreateRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleCreateRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleCreateRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleCreateResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleCreateResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleCreateResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleModifyRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleModifyRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleModifyRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleModifyResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleModifyResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleModifyResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleDeleteRequest) && (value.fbeType === this.TradingCalendarOffTimeRuleDeleteRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleDeleteRequest(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleDeleteResponse) && (value.fbeType === this.TradingCalendarOffTimeRuleDeleteResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleDeleteResponse(value)
    }
    if ((value instanceof TradingCalendarOffTimeRuleUpdateNotify) && (value.fbeType === this.TradingCalendarOffTimeRuleUpdateNotifySenderModel.fbeType)) {
      return this.send_TradingCalendarOffTimeRuleUpdateNotify(value)
    }
    if ((value instanceof TradingCalendarRulesGetRequest) && (value.fbeType === this.TradingCalendarRulesGetRequestSenderModel.fbeType)) {
      return this.send_TradingCalendarRulesGetRequest(value)
    }
    if ((value instanceof TradingCalendarRulesGetResponse) && (value.fbeType === this.TradingCalendarRulesGetResponseSenderModel.fbeType)) {
      return this.send_TradingCalendarRulesGetResponse(value)
    }
    if ((value instanceof TradingRouteRulesGetRequest) && (value.fbeType === this.TradingRouteRulesGetRequestSenderModel.fbeType)) {
      return this.send_TradingRouteRulesGetRequest(value)
    }
    if ((value instanceof TradingRouteRulesGetResponse) && (value.fbeType === this.TradingRouteRulesGetResponseSenderModel.fbeType)) {
      return this.send_TradingRouteRulesGetResponse(value)
    }
    if ((value instanceof TradingRouteRuleCreateRequest) && (value.fbeType === this.TradingRouteRuleCreateRequestSenderModel.fbeType)) {
      return this.send_TradingRouteRuleCreateRequest(value)
    }
    if ((value instanceof TradingRouteRuleCreateResponse) && (value.fbeType === this.TradingRouteRuleCreateResponseSenderModel.fbeType)) {
      return this.send_TradingRouteRuleCreateResponse(value)
    }
    if ((value instanceof TradingRouteRuleModifyRequest) && (value.fbeType === this.TradingRouteRuleModifyRequestSenderModel.fbeType)) {
      return this.send_TradingRouteRuleModifyRequest(value)
    }
    if ((value instanceof TradingRouteRuleModifyResponse) && (value.fbeType === this.TradingRouteRuleModifyResponseSenderModel.fbeType)) {
      return this.send_TradingRouteRuleModifyResponse(value)
    }
    if ((value instanceof TradingRouteRuleDeleteRequest) && (value.fbeType === this.TradingRouteRuleDeleteRequestSenderModel.fbeType)) {
      return this.send_TradingRouteRuleDeleteRequest(value)
    }
    if ((value instanceof TradingRouteRuleDeleteResponse) && (value.fbeType === this.TradingRouteRuleDeleteResponseSenderModel.fbeType)) {
      return this.send_TradingRouteRuleDeleteResponse(value)
    }
    if ((value instanceof TradingRouteRuleUpdateNotify) && (value.fbeType === this.TradingRouteRuleUpdateNotifySenderModel.fbeType)) {
      return this.send_TradingRouteRuleUpdateNotify(value)
    }
    if ((value instanceof SymbolMapsGetRequest) && (value.fbeType === this.SymbolMapsGetRequestSenderModel.fbeType)) {
      return this.send_SymbolMapsGetRequest(value)
    }
    if ((value instanceof SymbolMapsGetResponse) && (value.fbeType === this.SymbolMapsGetResponseSenderModel.fbeType)) {
      return this.send_SymbolMapsGetResponse(value)
    }
    if ((value instanceof SymbolMapCreateRequest) && (value.fbeType === this.SymbolMapCreateRequestSenderModel.fbeType)) {
      return this.send_SymbolMapCreateRequest(value)
    }
    if ((value instanceof SymbolMapCreateResponse) && (value.fbeType === this.SymbolMapCreateResponseSenderModel.fbeType)) {
      return this.send_SymbolMapCreateResponse(value)
    }
    if ((value instanceof SymbolMapModifyRequest) && (value.fbeType === this.SymbolMapModifyRequestSenderModel.fbeType)) {
      return this.send_SymbolMapModifyRequest(value)
    }
    if ((value instanceof SymbolMapModifyResponse) && (value.fbeType === this.SymbolMapModifyResponseSenderModel.fbeType)) {
      return this.send_SymbolMapModifyResponse(value)
    }
    if ((value instanceof SymbolMapDeleteRequest) && (value.fbeType === this.SymbolMapDeleteRequestSenderModel.fbeType)) {
      return this.send_SymbolMapDeleteRequest(value)
    }
    if ((value instanceof SymbolMapDeleteResponse) && (value.fbeType === this.SymbolMapDeleteResponseSenderModel.fbeType)) {
      return this.send_SymbolMapDeleteResponse(value)
    }
    if ((value instanceof SymbolMapUpdateNotify) && (value.fbeType === this.SymbolMapUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolMapUpdateNotify(value)
    }
    if ((value instanceof GatewaysGetRequest) && (value.fbeType === this.GatewaysGetRequestSenderModel.fbeType)) {
      return this.send_GatewaysGetRequest(value)
    }
    if ((value instanceof GatewaysGetResponse) && (value.fbeType === this.GatewaysGetResponseSenderModel.fbeType)) {
      return this.send_GatewaysGetResponse(value)
    }
    if ((value instanceof GatewayCreateRequest) && (value.fbeType === this.GatewayCreateRequestSenderModel.fbeType)) {
      return this.send_GatewayCreateRequest(value)
    }
    if ((value instanceof GatewayCreateResponse) && (value.fbeType === this.GatewayCreateResponseSenderModel.fbeType)) {
      return this.send_GatewayCreateResponse(value)
    }
    if ((value instanceof GatewayModifyRequest) && (value.fbeType === this.GatewayModifyRequestSenderModel.fbeType)) {
      return this.send_GatewayModifyRequest(value)
    }
    if ((value instanceof GatewayModifyResponse) && (value.fbeType === this.GatewayModifyResponseSenderModel.fbeType)) {
      return this.send_GatewayModifyResponse(value)
    }
    if ((value instanceof GatewayDeleteRequest) && (value.fbeType === this.GatewayDeleteRequestSenderModel.fbeType)) {
      return this.send_GatewayDeleteRequest(value)
    }
    if ((value instanceof GatewayDeleteResponse) && (value.fbeType === this.GatewayDeleteResponseSenderModel.fbeType)) {
      return this.send_GatewayDeleteResponse(value)
    }
    if ((value instanceof GatewayUpdateNotify) && (value.fbeType === this.GatewayUpdateNotifySenderModel.fbeType)) {
      return this.send_GatewayUpdateNotify(value)
    }
    if ((value instanceof DataFeedsGetRequest) && (value.fbeType === this.DataFeedsGetRequestSenderModel.fbeType)) {
      return this.send_DataFeedsGetRequest(value)
    }
    if ((value instanceof DataFeedsGetResponse) && (value.fbeType === this.DataFeedsGetResponseSenderModel.fbeType)) {
      return this.send_DataFeedsGetResponse(value)
    }
    if ((value instanceof DataFeedCreateRequest) && (value.fbeType === this.DataFeedCreateRequestSenderModel.fbeType)) {
      return this.send_DataFeedCreateRequest(value)
    }
    if ((value instanceof DataFeedCreateResponse) && (value.fbeType === this.DataFeedCreateResponseSenderModel.fbeType)) {
      return this.send_DataFeedCreateResponse(value)
    }
    if ((value instanceof DataFeedModifyRequest) && (value.fbeType === this.DataFeedModifyRequestSenderModel.fbeType)) {
      return this.send_DataFeedModifyRequest(value)
    }
    if ((value instanceof DataFeedModifyResponse) && (value.fbeType === this.DataFeedModifyResponseSenderModel.fbeType)) {
      return this.send_DataFeedModifyResponse(value)
    }
    if ((value instanceof DataFeedDeleteRequest) && (value.fbeType === this.DataFeedDeleteRequestSenderModel.fbeType)) {
      return this.send_DataFeedDeleteRequest(value)
    }
    if ((value instanceof DataFeedDeleteResponse) && (value.fbeType === this.DataFeedDeleteResponseSenderModel.fbeType)) {
      return this.send_DataFeedDeleteResponse(value)
    }
    if ((value instanceof DataFeedUpdateNotify) && (value.fbeType === this.DataFeedUpdateNotifySenderModel.fbeType)) {
      return this.send_DataFeedUpdateNotify(value)
    }
    if ((value instanceof ModuleSystemInfoNotify) && (value.fbeType === this.ModuleSystemInfoNotifySenderModel.fbeType)) {
      return this.send_ModuleSystemInfoNotify(value)
    }
    if ((value instanceof ModulesSystemInfoGetAllRequest) && (value.fbeType === this.ModulesSystemInfoGetAllRequestSenderModel.fbeType)) {
      return this.send_ModulesSystemInfoGetAllRequest(value)
    }
    if ((value instanceof ModulesSystemInfoGetAllResponse) && (value.fbeType === this.ModulesSystemInfoGetAllResponseSenderModel.fbeType)) {
      return this.send_ModulesSystemInfoGetAllResponse(value)
    }
    if ((value instanceof ModulesSystemInfoNotify) && (value.fbeType === this.ModulesSystemInfoNotifySenderModel.fbeType)) {
      return this.send_ModulesSystemInfoNotify(value)
    }
    if ((value instanceof ChartBarHistoryModifyRequest) && (value.fbeType === this.ChartBarHistoryModifyRequestSenderModel.fbeType)) {
      return this.send_ChartBarHistoryModifyRequest(value)
    }
    if ((value instanceof ChartBarHistoryModifyResponse) && (value.fbeType === this.ChartBarHistoryModifyResponseSenderModel.fbeType)) {
      return this.send_ChartBarHistoryModifyResponse(value)
    }
    if ((value instanceof FeedUpdateTickSnapshotRequest) && (value.fbeType === this.FeedUpdateTickSnapshotRequestSenderModel.fbeType)) {
      return this.send_FeedUpdateTickSnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateTickSnapshotResponse) && (value.fbeType === this.FeedUpdateTickSnapshotResponseSenderModel.fbeType)) {
      return this.send_FeedUpdateTickSnapshotResponse(value)
    }
    if ((value instanceof FeedUpdateTickRequest) && (value.fbeType === this.FeedUpdateTickRequestSenderModel.fbeType)) {
      return this.send_FeedUpdateTickRequest(value)
    }
    if ((value instanceof FeedUpdateTickResponse) && (value.fbeType === this.FeedUpdateTickResponseSenderModel.fbeType)) {
      return this.send_FeedUpdateTickResponse(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotRequest) && (value.fbeType === this.FeedUpdateLevel2SnapshotRequestSenderModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotResponse) && (value.fbeType === this.FeedUpdateLevel2SnapshotResponseSenderModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotResponse(value)
    }
    if ((value instanceof FeedUpdateLevel2Request) && (value.fbeType === this.FeedUpdateLevel2RequestSenderModel.fbeType)) {
      return this.send_FeedUpdateLevel2Request(value)
    }
    if ((value instanceof FeedUpdateLevel2Response) && (value.fbeType === this.FeedUpdateLevel2ResponseSenderModel.fbeType)) {
      return this.send_FeedUpdateLevel2Response(value)
    }
    if ((value instanceof MailServerGetRequest) && (value.fbeType === this.MailServerGetRequestSenderModel.fbeType)) {
      return this.send_MailServerGetRequest(value)
    }
    if ((value instanceof MailServerGetResponse) && (value.fbeType === this.MailServerGetResponseSenderModel.fbeType)) {
      return this.send_MailServerGetResponse(value)
    }
    if ((value instanceof MailServersGetAllRequest) && (value.fbeType === this.MailServersGetAllRequestSenderModel.fbeType)) {
      return this.send_MailServersGetAllRequest(value)
    }
    if ((value instanceof MailServersGetAllResponse) && (value.fbeType === this.MailServersGetAllResponseSenderModel.fbeType)) {
      return this.send_MailServersGetAllResponse(value)
    }
    if ((value instanceof MailServerCreateRequest) && (value.fbeType === this.MailServerCreateRequestSenderModel.fbeType)) {
      return this.send_MailServerCreateRequest(value)
    }
    if ((value instanceof MailServerCreateResponse) && (value.fbeType === this.MailServerCreateResponseSenderModel.fbeType)) {
      return this.send_MailServerCreateResponse(value)
    }
    if ((value instanceof MailServerModifyRequest) && (value.fbeType === this.MailServerModifyRequestSenderModel.fbeType)) {
      return this.send_MailServerModifyRequest(value)
    }
    if ((value instanceof MailServerModifyResponse) && (value.fbeType === this.MailServerModifyResponseSenderModel.fbeType)) {
      return this.send_MailServerModifyResponse(value)
    }
    if ((value instanceof MailServerDeleteRequest) && (value.fbeType === this.MailServerDeleteRequestSenderModel.fbeType)) {
      return this.send_MailServerDeleteRequest(value)
    }
    if ((value instanceof MailServerDeleteResponse) && (value.fbeType === this.MailServerDeleteResponseSenderModel.fbeType)) {
      return this.send_MailServerDeleteResponse(value)
    }
    if ((value instanceof MailServerUpdateNotify) && (value.fbeType === this.MailServerUpdateNotifySenderModel.fbeType)) {
      return this.send_MailServerUpdateNotify(value)
    }
    if ((value instanceof AccountStatesUpdateNotify) && (value.fbeType === this.AccountStatesUpdateNotifySenderModel.fbeType)) {
      return this.send_AccountStatesUpdateNotify(value)
    }
    if ((value instanceof AccountStatesGetAllRequest) && (value.fbeType === this.AccountStatesGetAllRequestSenderModel.fbeType)) {
      return this.send_AccountStatesGetAllRequest(value)
    }
    if ((value instanceof AccountStatesGetAllResponse) && (value.fbeType === this.AccountStatesGetAllResponseSenderModel.fbeType)) {
      return this.send_AccountStatesGetAllResponse(value)
    }
    if ((value instanceof ModuleAuthRequest) && (value.fbeType === this.ModuleAuthRequestSenderModel.fbeType)) {
      return this.send_ModuleAuthRequest(value)
    }
    if ((value instanceof ModuleAuthResponse) && (value.fbeType === this.ModuleAuthResponseSenderModel.fbeType)) {
      return this.send_ModuleAuthResponse(value)
    }
    if ((value instanceof LeverageProfileGetRequest) && (value.fbeType === this.LeverageProfileGetRequestSenderModel.fbeType)) {
      return this.send_LeverageProfileGetRequest(value)
    }
    if ((value instanceof LeverageProfileGetResponse) && (value.fbeType === this.LeverageProfileGetResponseSenderModel.fbeType)) {
      return this.send_LeverageProfileGetResponse(value)
    }
    if ((value instanceof LeverageProfilesGetAllRequest) && (value.fbeType === this.LeverageProfilesGetAllRequestSenderModel.fbeType)) {
      return this.send_LeverageProfilesGetAllRequest(value)
    }
    if ((value instanceof LeverageProfilesGetAllResponse) && (value.fbeType === this.LeverageProfilesGetAllResponseSenderModel.fbeType)) {
      return this.send_LeverageProfilesGetAllResponse(value)
    }
    if ((value instanceof LeverageProfileCreateRequest) && (value.fbeType === this.LeverageProfileCreateRequestSenderModel.fbeType)) {
      return this.send_LeverageProfileCreateRequest(value)
    }
    if ((value instanceof LeverageProfileCreateResponse) && (value.fbeType === this.LeverageProfileCreateResponseSenderModel.fbeType)) {
      return this.send_LeverageProfileCreateResponse(value)
    }
    if ((value instanceof LeverageProfileModifyRequest) && (value.fbeType === this.LeverageProfileModifyRequestSenderModel.fbeType)) {
      return this.send_LeverageProfileModifyRequest(value)
    }
    if ((value instanceof LeverageProfileModifyResponse) && (value.fbeType === this.LeverageProfileModifyResponseSenderModel.fbeType)) {
      return this.send_LeverageProfileModifyResponse(value)
    }
    if ((value instanceof LeverageProfileDeleteRequest) && (value.fbeType === this.LeverageProfileDeleteRequestSenderModel.fbeType)) {
      return this.send_LeverageProfileDeleteRequest(value)
    }
    if ((value instanceof LeverageProfileDeleteResponse) && (value.fbeType === this.LeverageProfileDeleteResponseSenderModel.fbeType)) {
      return this.send_LeverageProfileDeleteResponse(value)
    }
    if ((value instanceof LeverageProfileUpdateNotify) && (value.fbeType === this.LeverageProfileUpdateNotifySenderModel.fbeType)) {
      return this.send_LeverageProfileUpdateNotify(value)
    }
    if ((value instanceof MarginRateProfileGetRequest) && (value.fbeType === this.MarginRateProfileGetRequestSenderModel.fbeType)) {
      return this.send_MarginRateProfileGetRequest(value)
    }
    if ((value instanceof MarginRateProfileGetResponse) && (value.fbeType === this.MarginRateProfileGetResponseSenderModel.fbeType)) {
      return this.send_MarginRateProfileGetResponse(value)
    }
    if ((value instanceof MarginRateProfilesGetAllRequest) && (value.fbeType === this.MarginRateProfilesGetAllRequestSenderModel.fbeType)) {
      return this.send_MarginRateProfilesGetAllRequest(value)
    }
    if ((value instanceof MarginRateProfilesGetAllResponse) && (value.fbeType === this.MarginRateProfilesGetAllResponseSenderModel.fbeType)) {
      return this.send_MarginRateProfilesGetAllResponse(value)
    }
    if ((value instanceof MarginRateProfileCreateRequest) && (value.fbeType === this.MarginRateProfileCreateRequestSenderModel.fbeType)) {
      return this.send_MarginRateProfileCreateRequest(value)
    }
    if ((value instanceof MarginRateProfileCreateResponse) && (value.fbeType === this.MarginRateProfileCreateResponseSenderModel.fbeType)) {
      return this.send_MarginRateProfileCreateResponse(value)
    }
    if ((value instanceof MarginRateProfileModifyRequest) && (value.fbeType === this.MarginRateProfileModifyRequestSenderModel.fbeType)) {
      return this.send_MarginRateProfileModifyRequest(value)
    }
    if ((value instanceof MarginRateProfileModifyResponse) && (value.fbeType === this.MarginRateProfileModifyResponseSenderModel.fbeType)) {
      return this.send_MarginRateProfileModifyResponse(value)
    }
    if ((value instanceof MarginRateProfileDeleteRequest) && (value.fbeType === this.MarginRateProfileDeleteRequestSenderModel.fbeType)) {
      return this.send_MarginRateProfileDeleteRequest(value)
    }
    if ((value instanceof MarginRateProfileDeleteResponse) && (value.fbeType === this.MarginRateProfileDeleteResponseSenderModel.fbeType)) {
      return this.send_MarginRateProfileDeleteResponse(value)
    }
    if ((value instanceof MarginRateProfileUpdateNotify) && (value.fbeType === this.MarginRateProfileUpdateNotifySenderModel.fbeType)) {
      return this.send_MarginRateProfileUpdateNotify(value)
    }
    if ((value instanceof NewsProfileGetRequest) && (value.fbeType === this.NewsProfileGetRequestSenderModel.fbeType)) {
      return this.send_NewsProfileGetRequest(value)
    }
    if ((value instanceof NewsProfileGetResponse) && (value.fbeType === this.NewsProfileGetResponseSenderModel.fbeType)) {
      return this.send_NewsProfileGetResponse(value)
    }
    if ((value instanceof NewsProfilesGetAllRequest) && (value.fbeType === this.NewsProfilesGetAllRequestSenderModel.fbeType)) {
      return this.send_NewsProfilesGetAllRequest(value)
    }
    if ((value instanceof NewsProfilesGetAllResponse) && (value.fbeType === this.NewsProfilesGetAllResponseSenderModel.fbeType)) {
      return this.send_NewsProfilesGetAllResponse(value)
    }
    if ((value instanceof NewsProfileCreateRequest) && (value.fbeType === this.NewsProfileCreateRequestSenderModel.fbeType)) {
      return this.send_NewsProfileCreateRequest(value)
    }
    if ((value instanceof NewsProfileCreateResponse) && (value.fbeType === this.NewsProfileCreateResponseSenderModel.fbeType)) {
      return this.send_NewsProfileCreateResponse(value)
    }
    if ((value instanceof NewsProfileModifyRequest) && (value.fbeType === this.NewsProfileModifyRequestSenderModel.fbeType)) {
      return this.send_NewsProfileModifyRequest(value)
    }
    if ((value instanceof NewsProfileModifyResponse) && (value.fbeType === this.NewsProfileModifyResponseSenderModel.fbeType)) {
      return this.send_NewsProfileModifyResponse(value)
    }
    if ((value instanceof NewsProfileDeleteRequest) && (value.fbeType === this.NewsProfileDeleteRequestSenderModel.fbeType)) {
      return this.send_NewsProfileDeleteRequest(value)
    }
    if ((value instanceof NewsProfileDeleteResponse) && (value.fbeType === this.NewsProfileDeleteResponseSenderModel.fbeType)) {
      return this.send_NewsProfileDeleteResponse(value)
    }
    if ((value instanceof NewsProfileUpdateNotify) && (value.fbeType === this.NewsProfileUpdateNotifySenderModel.fbeType)) {
      return this.send_NewsProfileUpdateNotify(value)
    }
    if ((value instanceof NewsProfileImportRequest) && (value.fbeType === this.NewsProfileImportRequestSenderModel.fbeType)) {
      return this.send_NewsProfileImportRequest(value)
    }
    if ((value instanceof NewsProfileImportResponse) && (value.fbeType === this.NewsProfileImportResponseSenderModel.fbeType)) {
      return this.send_NewsProfileImportResponse(value)
    }
    if ((value instanceof TickHistoryRequest) && (value.fbeType === this.TickHistoryRequestSenderModel.fbeType)) {
      return this.send_TickHistoryRequest(value)
    }
    if ((value instanceof TickHistoryResponse) && (value.fbeType === this.TickHistoryResponseSenderModel.fbeType)) {
      return this.send_TickHistoryResponse(value)
    }
    if ((value instanceof EmailTemplateGetRequest) && (value.fbeType === this.EmailTemplateGetRequestSenderModel.fbeType)) {
      return this.send_EmailTemplateGetRequest(value)
    }
    if ((value instanceof EmailTemplateGetResponse) && (value.fbeType === this.EmailTemplateGetResponseSenderModel.fbeType)) {
      return this.send_EmailTemplateGetResponse(value)
    }
    if ((value instanceof EmailTemplatesGetAllRequest) && (value.fbeType === this.EmailTemplatesGetAllRequestSenderModel.fbeType)) {
      return this.send_EmailTemplatesGetAllRequest(value)
    }
    if ((value instanceof EmailTemplatesGetAllResponse) && (value.fbeType === this.EmailTemplatesGetAllResponseSenderModel.fbeType)) {
      return this.send_EmailTemplatesGetAllResponse(value)
    }
    if ((value instanceof EmailTemplateCreateRequest) && (value.fbeType === this.EmailTemplateCreateRequestSenderModel.fbeType)) {
      return this.send_EmailTemplateCreateRequest(value)
    }
    if ((value instanceof EmailTemplateCreateResponse) && (value.fbeType === this.EmailTemplateCreateResponseSenderModel.fbeType)) {
      return this.send_EmailTemplateCreateResponse(value)
    }
    if ((value instanceof EmailTemplateModifyRequest) && (value.fbeType === this.EmailTemplateModifyRequestSenderModel.fbeType)) {
      return this.send_EmailTemplateModifyRequest(value)
    }
    if ((value instanceof EmailTemplateModifyResponse) && (value.fbeType === this.EmailTemplateModifyResponseSenderModel.fbeType)) {
      return this.send_EmailTemplateModifyResponse(value)
    }
    if ((value instanceof EmailTemplateDeleteRequest) && (value.fbeType === this.EmailTemplateDeleteRequestSenderModel.fbeType)) {
      return this.send_EmailTemplateDeleteRequest(value)
    }
    if ((value instanceof EmailTemplateDeleteResponse) && (value.fbeType === this.EmailTemplateDeleteResponseSenderModel.fbeType)) {
      return this.send_EmailTemplateDeleteResponse(value)
    }
    if ((value instanceof EmailTemplateUpdateNotify) && (value.fbeType === this.EmailTemplateUpdateNotifySenderModel.fbeType)) {
      return this.send_EmailTemplateUpdateNotify(value)
    }
    if ((value instanceof OrderStateSubscribeRequest) && (value.fbeType === this.OrderStateSubscribeRequestSenderModel.fbeType)) {
      return this.send_OrderStateSubscribeRequest(value)
    }
    if ((value instanceof OrderStateSubscribeResponse) && (value.fbeType === this.OrderStateSubscribeResponseSenderModel.fbeType)) {
      return this.send_OrderStateSubscribeResponse(value)
    }
    if ((value instanceof OrderStateUpdateSubscriptionRequest) && (value.fbeType === this.OrderStateUpdateSubscriptionRequestSenderModel.fbeType)) {
      return this.send_OrderStateUpdateSubscriptionRequest(value)
    }
    if ((value instanceof OrderStateUpdateSubscriptionResponse) && (value.fbeType === this.OrderStateUpdateSubscriptionResponseSenderModel.fbeType)) {
      return this.send_OrderStateUpdateSubscriptionResponse(value)
    }
    if ((value instanceof OrderStateUnsubscribeRequest) && (value.fbeType === this.OrderStateUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_OrderStateUnsubscribeRequest(value)
    }
    if ((value instanceof OrderStateUnsubscribeResponse) && (value.fbeType === this.OrderStateUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_OrderStateUnsubscribeResponse(value)
    }
    if ((value instanceof OrderStateNotify) && (value.fbeType === this.OrderStateNotifySenderModel.fbeType)) {
      return this.send_OrderStateNotify(value)
    }
    if ((value instanceof RequestMonitoringSubscribeRequest) && (value.fbeType === this.RequestMonitoringSubscribeRequestSenderModel.fbeType)) {
      return this.send_RequestMonitoringSubscribeRequest(value)
    }
    if ((value instanceof RequestMonitoringSubscribeResponse) && (value.fbeType === this.RequestMonitoringSubscribeResponseSenderModel.fbeType)) {
      return this.send_RequestMonitoringSubscribeResponse(value)
    }
    if ((value instanceof RequestMonitoringUnsubscribeRequest) && (value.fbeType === this.RequestMonitoringUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_RequestMonitoringUnsubscribeRequest(value)
    }
    if ((value instanceof RequestMonitoringUnsubscribeResponse) && (value.fbeType === this.RequestMonitoringUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_RequestMonitoringUnsubscribeResponse(value)
    }
    if ((value instanceof RequestMonitoringRequestUpdateNotify) && (value.fbeType === this.RequestMonitoringRequestUpdateNotifySenderModel.fbeType)) {
      return this.send_RequestMonitoringRequestUpdateNotify(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoSubscribeRequest) && (value.fbeType === this.FilledOrdersSummaryInfoSubscribeRequestSenderModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoSubscribeRequest(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoSubscribeResponse) && (value.fbeType === this.FilledOrdersSummaryInfoSubscribeResponseSenderModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoSubscribeResponse(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoNotify) && (value.fbeType === this.FilledOrdersSummaryInfoNotifySenderModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoNotify(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoUpdateSubscriptionRequest) && (value.fbeType === this.FilledOrdersSummaryInfoUpdateSubscriptionRequestSenderModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoUnsubscribeRequest) && (value.fbeType === this.FilledOrdersSummaryInfoUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoUnsubscribeRequest(value)
    }
    if ((value instanceof FilledOrdersSummaryInfoUnsubscribeResponse) && (value.fbeType === this.FilledOrdersSummaryInfoUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_FilledOrdersSummaryInfoUnsubscribeResponse(value)
    }
    if ((value instanceof TradeEventsNotify) && (value.fbeType === this.TradeEventsNotifySenderModel.fbeType)) {
      return this.send_TradeEventsNotify(value)
    }
    if ((value instanceof TradeEventsGetRequest) && (value.fbeType === this.TradeEventsGetRequestSenderModel.fbeType)) {
      return this.send_TradeEventsGetRequest(value)
    }
    if ((value instanceof TradeEventsGetResponse) && (value.fbeType === this.TradeEventsGetResponseSenderModel.fbeType)) {
      return this.send_TradeEventsGetResponse(value)
    }
    if ((value instanceof JournalRequest) && (value.fbeType === this.JournalRequestSenderModel.fbeType)) {
      return this.send_JournalRequest(value)
    }
    if ((value instanceof JournalResponse) && (value.fbeType === this.JournalResponseSenderModel.fbeType)) {
      return this.send_JournalResponse(value)
    }
    if ((value instanceof ClientReportsListGetRequest) && (value.fbeType === this.ClientReportsListGetRequestSenderModel.fbeType)) {
      return this.send_ClientReportsListGetRequest(value)
    }
    if ((value instanceof ClientReportsListGetResponse) && (value.fbeType === this.ClientReportsListGetResponseSenderModel.fbeType)) {
      return this.send_ClientReportsListGetResponse(value)
    }
    if ((value instanceof ClientReportGetRequest) && (value.fbeType === this.ClientReportGetRequestSenderModel.fbeType)) {
      return this.send_ClientReportGetRequest(value)
    }
    if ((value instanceof ClientReportGetResponse) && (value.fbeType === this.ClientReportGetResponseSenderModel.fbeType)) {
      return this.send_ClientReportGetResponse(value)
    }
    if ((value instanceof ClientReportsSendRequest) && (value.fbeType === this.ClientReportsSendRequestSenderModel.fbeType)) {
      return this.send_ClientReportsSendRequest(value)
    }
    if ((value instanceof ClientReportsSendResponse) && (value.fbeType === this.ClientReportsSendResponseSenderModel.fbeType)) {
      return this.send_ClientReportsSendResponse(value)
    }
    if ((value instanceof ProfitClosedGetRequest) && (value.fbeType === this.ProfitClosedGetRequestSenderModel.fbeType)) {
      return this.send_ProfitClosedGetRequest(value)
    }
    if ((value instanceof ProfitClosedGetResponse) && (value.fbeType === this.ProfitClosedGetResponseSenderModel.fbeType)) {
      return this.send_ProfitClosedGetResponse(value)
    }
    if ((value instanceof ChartBarsImportRequest) && (value.fbeType === this.ChartBarsImportRequestSenderModel.fbeType)) {
      return this.send_ChartBarsImportRequest(value)
    }
    if ((value instanceof ChartBarsImportResponse) && (value.fbeType === this.ChartBarsImportResponseSenderModel.fbeType)) {
      return this.send_ChartBarsImportResponse(value)
    }
    if ((value instanceof ChartBarsImportNotify) && (value.fbeType === this.ChartBarsImportNotifySenderModel.fbeType)) {
      return this.send_ChartBarsImportNotify(value)
    }
    if ((value instanceof JournalBusinessRequest) && (value.fbeType === this.JournalBusinessRequestSenderModel.fbeType)) {
      return this.send_JournalBusinessRequest(value)
    }
    if ((value instanceof JournalBusinessResponse) && (value.fbeType === this.JournalBusinessResponseSenderModel.fbeType)) {
      return this.send_JournalBusinessResponse(value)
    }
    if ((value instanceof AccountsGetPageRequest) && (value.fbeType === this.AccountsGetPageRequestSenderModel.fbeType)) {
      return this.send_AccountsGetPageRequest(value)
    }
    if ((value instanceof AccountsGetPageResponse) && (value.fbeType === this.AccountsGetPageResponseSenderModel.fbeType)) {
      return this.send_AccountsGetPageResponse(value)
    }
    if ((value instanceof HeartbeatNotify) && (value.fbeType === this.HeartbeatNotifySenderModel.fbeType)) {
      return this.send_HeartbeatNotify(value)
    }
    if ((value instanceof OrdersClosedSummariesGetRequest) && (value.fbeType === this.OrdersClosedSummariesGetRequestSenderModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetRequest(value)
    }
    if ((value instanceof OrdersClosedSummariesGetResponse) && (value.fbeType === this.OrdersClosedSummariesGetResponseSenderModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetResponse(value)
    }
    if ((value instanceof OrdersGetPageRequest) && (value.fbeType === this.OrdersGetPageRequestSenderModel.fbeType)) {
      return this.send_OrdersGetPageRequest(value)
    }
    if ((value instanceof OrdersGetPageResponse) && (value.fbeType === this.OrdersGetPageResponseSenderModel.fbeType)) {
      return this.send_OrdersGetPageResponse(value)
    }
    if ((value instanceof StorageUniversalNotify) && (value.fbeType === this.StorageUniversalNotifySenderModel.fbeType)) {
      return this.send_StorageUniversalNotify(value)
    }
    if ((value instanceof ClientLoginInfoGetRequest) && (value.fbeType === this.ClientLoginInfoGetRequestSenderModel.fbeType)) {
      return this.send_ClientLoginInfoGetRequest(value)
    }
    if ((value instanceof ClientLoginInfoGetResponse) && (value.fbeType === this.ClientLoginInfoGetResponseSenderModel.fbeType)) {
      return this.send_ClientLoginInfoGetResponse(value)
    }
    if ((value instanceof AccountGroupExistsGetRequest) && (value.fbeType === this.AccountGroupExistsGetRequestSenderModel.fbeType)) {
      return this.send_AccountGroupExistsGetRequest(value)
    }
    if ((value instanceof AccountGroupExistsGetResponse) && (value.fbeType === this.AccountGroupExistsGetResponseSenderModel.fbeType)) {
      return this.send_AccountGroupExistsGetResponse(value)
    }
    if ((value instanceof LoginWithPasswordRequest) && (value.fbeType === this.LoginWithPasswordRequestSenderModel.fbeType)) {
      return this.send_LoginWithPasswordRequest(value)
    }
    if ((value instanceof LoginWithPasswordResponse) && (value.fbeType === this.LoginWithPasswordResponseSenderModel.fbeType)) {
      return this.send_LoginWithPasswordResponse(value)
    }
    if ((value instanceof LoginWithTokenRequest) && (value.fbeType === this.LoginWithTokenRequestSenderModel.fbeType)) {
      return this.send_LoginWithTokenRequest(value)
    }
    if ((value instanceof LoginWithTokenResponse) && (value.fbeType === this.LoginWithTokenResponseSenderModel.fbeType)) {
      return this.send_LoginWithTokenResponse(value)
    }
    if ((value instanceof RefreshTokenRequest) && (value.fbeType === this.RefreshTokenRequestSenderModel.fbeType)) {
      return this.send_RefreshTokenRequest(value)
    }
    if ((value instanceof RefreshTokenResponse) && (value.fbeType === this.RefreshTokenResponseSenderModel.fbeType)) {
      return this.send_RefreshTokenResponse(value)
    }
    if ((value instanceof PersonGetRequest) && (value.fbeType === this.PersonGetRequestSenderModel.fbeType)) {
      return this.send_PersonGetRequest(value)
    }
    if ((value instanceof PersonGetResponse) && (value.fbeType === this.PersonGetResponseSenderModel.fbeType)) {
      return this.send_PersonGetResponse(value)
    }
    if ((value instanceof PersonsGetAllRequest) && (value.fbeType === this.PersonsGetAllRequestSenderModel.fbeType)) {
      return this.send_PersonsGetAllRequest(value)
    }
    if ((value instanceof PersonsGetAllResponse) && (value.fbeType === this.PersonsGetAllResponseSenderModel.fbeType)) {
      return this.send_PersonsGetAllResponse(value)
    }
    if ((value instanceof PersonCreateRequest) && (value.fbeType === this.PersonCreateRequestSenderModel.fbeType)) {
      return this.send_PersonCreateRequest(value)
    }
    if ((value instanceof PersonCreateResponse) && (value.fbeType === this.PersonCreateResponseSenderModel.fbeType)) {
      return this.send_PersonCreateResponse(value)
    }
    if ((value instanceof PersonModifyRequest) && (value.fbeType === this.PersonModifyRequestSenderModel.fbeType)) {
      return this.send_PersonModifyRequest(value)
    }
    if ((value instanceof PersonModifyResponse) && (value.fbeType === this.PersonModifyResponseSenderModel.fbeType)) {
      return this.send_PersonModifyResponse(value)
    }
    if ((value instanceof PersonUpdateNotify) && (value.fbeType === this.PersonUpdateNotifySenderModel.fbeType)) {
      return this.send_PersonUpdateNotify(value)
    }
    if ((value instanceof SecuritySettingsGetRequest) && (value.fbeType === this.SecuritySettingsGetRequestSenderModel.fbeType)) {
      return this.send_SecuritySettingsGetRequest(value)
    }
    if ((value instanceof SecuritySettingsGetResponse) && (value.fbeType === this.SecuritySettingsGetResponseSenderModel.fbeType)) {
      return this.send_SecuritySettingsGetResponse(value)
    }
    if ((value instanceof SecuritySettingsGetAllRequest) && (value.fbeType === this.SecuritySettingsGetAllRequestSenderModel.fbeType)) {
      return this.send_SecuritySettingsGetAllRequest(value)
    }
    if ((value instanceof SecuritySettingsGetAllResponse) && (value.fbeType === this.SecuritySettingsGetAllResponseSenderModel.fbeType)) {
      return this.send_SecuritySettingsGetAllResponse(value)
    }
    if ((value instanceof SecuritySettingsCreateRequest) && (value.fbeType === this.SecuritySettingsCreateRequestSenderModel.fbeType)) {
      return this.send_SecuritySettingsCreateRequest(value)
    }
    if ((value instanceof SecuritySettingsCreateResponse) && (value.fbeType === this.SecuritySettingsCreateResponseSenderModel.fbeType)) {
      return this.send_SecuritySettingsCreateResponse(value)
    }
    if ((value instanceof SecuritySettingsModifyRequest) && (value.fbeType === this.SecuritySettingsModifyRequestSenderModel.fbeType)) {
      return this.send_SecuritySettingsModifyRequest(value)
    }
    if ((value instanceof SecuritySettingsModifyResponse) && (value.fbeType === this.SecuritySettingsModifyResponseSenderModel.fbeType)) {
      return this.send_SecuritySettingsModifyResponse(value)
    }
    if ((value instanceof SecuritySettingsDeleteRequest) && (value.fbeType === this.SecuritySettingsDeleteRequestSenderModel.fbeType)) {
      return this.send_SecuritySettingsDeleteRequest(value)
    }
    if ((value instanceof SecuritySettingsDeleteResponse) && (value.fbeType === this.SecuritySettingsDeleteResponseSenderModel.fbeType)) {
      return this.send_SecuritySettingsDeleteResponse(value)
    }
    if ((value instanceof SecuritySettingsUpdateNotify) && (value.fbeType === this.SecuritySettingsUpdateNotifySenderModel.fbeType)) {
      return this.send_SecuritySettingsUpdateNotify(value)
    }
    if ((value instanceof FirewallRuleGetRequest) && (value.fbeType === this.FirewallRuleGetRequestSenderModel.fbeType)) {
      return this.send_FirewallRuleGetRequest(value)
    }
    if ((value instanceof FirewallRuleGetResponse) && (value.fbeType === this.FirewallRuleGetResponseSenderModel.fbeType)) {
      return this.send_FirewallRuleGetResponse(value)
    }
    if ((value instanceof FirewallRulesGetAllRequest) && (value.fbeType === this.FirewallRulesGetAllRequestSenderModel.fbeType)) {
      return this.send_FirewallRulesGetAllRequest(value)
    }
    if ((value instanceof FirewallRulesGetAllResponse) && (value.fbeType === this.FirewallRulesGetAllResponseSenderModel.fbeType)) {
      return this.send_FirewallRulesGetAllResponse(value)
    }
    if ((value instanceof FirewallRuleCreateRequest) && (value.fbeType === this.FirewallRuleCreateRequestSenderModel.fbeType)) {
      return this.send_FirewallRuleCreateRequest(value)
    }
    if ((value instanceof FirewallRuleCreateResponse) && (value.fbeType === this.FirewallRuleCreateResponseSenderModel.fbeType)) {
      return this.send_FirewallRuleCreateResponse(value)
    }
    if ((value instanceof FirewallRuleModifyRequest) && (value.fbeType === this.FirewallRuleModifyRequestSenderModel.fbeType)) {
      return this.send_FirewallRuleModifyRequest(value)
    }
    if ((value instanceof FirewallRuleModifyResponse) && (value.fbeType === this.FirewallRuleModifyResponseSenderModel.fbeType)) {
      return this.send_FirewallRuleModifyResponse(value)
    }
    if ((value instanceof FirewallRuleDeleteRequest) && (value.fbeType === this.FirewallRuleDeleteRequestSenderModel.fbeType)) {
      return this.send_FirewallRuleDeleteRequest(value)
    }
    if ((value instanceof FirewallRuleDeleteResponse) && (value.fbeType === this.FirewallRuleDeleteResponseSenderModel.fbeType)) {
      return this.send_FirewallRuleDeleteResponse(value)
    }
    if ((value instanceof FirewallRuleUpdateNotify) && (value.fbeType === this.FirewallRuleUpdateNotifySenderModel.fbeType)) {
      return this.send_FirewallRuleUpdateNotify(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._configClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._historyClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._clientClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._feedClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._storageClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._exposedClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._executionClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._backupClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._innerClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._tradeClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send PingModuleRequest value
   * @this {!Client}
   * @param {!PingModuleRequest} value PingModuleRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PingModuleRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PingModuleRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PingModuleRequest serialization failed!')
    console.assert(this.PingModuleRequestSenderModel.verify(), 'manager.PingModuleRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PongModuleResponse value
   * @this {!Client}
   * @param {!PongModuleResponse} value PongModuleResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PongModuleResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PongModuleResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PongModuleResponse serialization failed!')
    console.assert(this.PongModuleResponseSenderModel.verify(), 'manager.PongModuleResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PingModuleReject value
   * @this {!Client}
   * @param {!PingModuleReject} value PingModuleReject value to send
   * @returns {!number} Sent bytes
   */
  send_PingModuleReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PingModuleRejectSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PingModuleReject serialization failed!')
    console.assert(this.PingModuleRejectSenderModel.verify(), 'manager.PingModuleReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated3 value
   * @this {!Client}
   * @param {!Deprecated3} value Deprecated3 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated3 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated3SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated3 serialization failed!')
    console.assert(this.Deprecated3SenderModel.verify(), 'manager.Deprecated3 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated4 value
   * @this {!Client}
   * @param {!Deprecated4} value Deprecated4 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated4 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated4SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated4 serialization failed!')
    console.assert(this.Deprecated4SenderModel.verify(), 'manager.Deprecated4 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated1 value
   * @this {!Client}
   * @param {!Deprecated1} value Deprecated1 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated1 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated1SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated1 serialization failed!')
    console.assert(this.Deprecated1SenderModel.verify(), 'manager.Deprecated1 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated2 value
   * @this {!Client}
   * @param {!Deprecated2} value Deprecated2 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated2 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated2SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated2 serialization failed!')
    console.assert(this.Deprecated2SenderModel.verify(), 'manager.Deprecated2 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerSessionInfoRequest value
   * @this {!Client}
   * @param {!ManagerSessionInfoRequest} value ManagerSessionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerSessionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerSessionInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerSessionInfoRequest serialization failed!')
    console.assert(this.ManagerSessionInfoRequestSenderModel.verify(), 'manager.ManagerSessionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerSessionInfoResponse value
   * @this {!Client}
   * @param {!ManagerSessionInfoResponse} value ManagerSessionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerSessionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerSessionInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerSessionInfoResponse serialization failed!')
    console.assert(this.ManagerSessionInfoResponseSenderModel.verify(), 'manager.ManagerSessionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OnlineSessionsRequest value
   * @this {!Client}
   * @param {!OnlineSessionsRequest} value OnlineSessionsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OnlineSessionsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OnlineSessionsRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OnlineSessionsRequest serialization failed!')
    console.assert(this.OnlineSessionsRequestSenderModel.verify(), 'manager.OnlineSessionsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OnlineSessionsResponse value
   * @this {!Client}
   * @param {!OnlineSessionsResponse} value OnlineSessionsResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OnlineSessionsResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OnlineSessionsResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OnlineSessionsResponse serialization failed!')
    console.assert(this.OnlineSessionsResponseSenderModel.verify(), 'manager.OnlineSessionsResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DropSessionRequest value
   * @this {!Client}
   * @param {!DropSessionRequest} value DropSessionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DropSessionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DropSessionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DropSessionRequest serialization failed!')
    console.assert(this.DropSessionRequestSenderModel.verify(), 'manager.DropSessionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DropSessionResponse value
   * @this {!Client}
   * @param {!DropSessionResponse} value DropSessionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DropSessionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DropSessionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DropSessionResponse serialization failed!')
    console.assert(this.DropSessionResponseSenderModel.verify(), 'manager.DropSessionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRequest value
   * @this {!Client}
   * @param {!ConfigRequest} value ConfigRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRequest serialization failed!')
    console.assert(this.ConfigRequestSenderModel.verify(), 'manager.ConfigRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigResponse value
   * @this {!Client}
   * @param {!ConfigResponse} value ConfigResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigResponse serialization failed!')
    console.assert(this.ConfigResponseSenderModel.verify(), 'manager.ConfigResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigReject value
   * @this {!Client}
   * @param {!ConfigReject} value ConfigReject value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRejectSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigReject serialization failed!')
    console.assert(this.ConfigRejectSenderModel.verify(), 'manager.ConfigReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateRequest value
   * @this {!Client}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigUpdateRequest serialization failed!')
    console.assert(this.ConfigUpdateRequestSenderModel.verify(), 'manager.ConfigUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateResponse value
   * @this {!Client}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigUpdateResponse serialization failed!')
    console.assert(this.ConfigUpdateResponseSenderModel.verify(), 'manager.ConfigUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateNotify value
   * @this {!Client}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigUpdateNotify serialization failed!')
    console.assert(this.ConfigUpdateNotifySenderModel.verify(), 'manager.ConfigUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionRequest value
   * @this {!Client}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigVersionRequest serialization failed!')
    console.assert(this.ConfigVersionRequestSenderModel.verify(), 'manager.ConfigVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionResponse value
   * @this {!Client}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigVersionResponse serialization failed!')
    console.assert(this.ConfigVersionResponseSenderModel.verify(), 'manager.ConfigVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigExportRequest value
   * @this {!Client}
   * @param {!ConfigExportRequest} value ConfigExportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigExportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigExportRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigExportRequest serialization failed!')
    console.assert(this.ConfigExportRequestSenderModel.verify(), 'manager.ConfigExportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigExportResponse value
   * @this {!Client}
   * @param {!ConfigExportResponse} value ConfigExportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigExportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigExportResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigExportResponse serialization failed!')
    console.assert(this.ConfigExportResponseSenderModel.verify(), 'manager.ConfigExportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigImportRequest value
   * @this {!Client}
   * @param {!ConfigImportRequest} value ConfigImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigImportRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigImportRequest serialization failed!')
    console.assert(this.ConfigImportRequestSenderModel.verify(), 'manager.ConfigImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigImportResponse value
   * @this {!Client}
   * @param {!ConfigImportResponse} value ConfigImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigImportResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigImportResponse serialization failed!')
    console.assert(this.ConfigImportResponseSenderModel.verify(), 'manager.ConfigImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigValidateRequest value
   * @this {!Client}
   * @param {!ConfigValidateRequest} value ConfigValidateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigValidateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigValidateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigValidateRequest serialization failed!')
    console.assert(this.ConfigValidateRequestSenderModel.verify(), 'manager.ConfigValidateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigValidateResponse value
   * @this {!Client}
   * @param {!ConfigValidateResponse} value ConfigValidateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigValidateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigValidateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigValidateResponse serialization failed!')
    console.assert(this.ConfigValidateResponseSenderModel.verify(), 'manager.ConfigValidateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshRequest value
   * @this {!Client}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRefreshRequest serialization failed!')
    console.assert(this.ConfigRefreshRequestSenderModel.verify(), 'manager.ConfigRefreshRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshResponse value
   * @this {!Client}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRefreshResponse serialization failed!')
    console.assert(this.ConfigRefreshResponseSenderModel.verify(), 'manager.ConfigRefreshResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryRequest value
   * @this {!Client}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigHistoryRequest serialization failed!')
    console.assert(this.ConfigHistoryRequestSenderModel.verify(), 'manager.ConfigHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryResponse value
   * @this {!Client}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigHistoryResponse serialization failed!')
    console.assert(this.ConfigHistoryResponseSenderModel.verify(), 'manager.ConfigHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackRequest value
   * @this {!Client}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRollbackRequest serialization failed!')
    console.assert(this.ConfigRollbackRequestSenderModel.verify(), 'manager.ConfigRollbackRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackResponse value
   * @this {!Client}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ConfigRollbackResponse serialization failed!')
    console.assert(this.ConfigRollbackResponseSenderModel.verify(), 'manager.ConfigRollbackResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostGetRequest value
   * @this {!Client}
   * @param {!HostGetRequest} value HostGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostGetRequest serialization failed!')
    console.assert(this.HostGetRequestSenderModel.verify(), 'manager.HostGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostGetResponse value
   * @this {!Client}
   * @param {!HostGetResponse} value HostGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostGetResponse serialization failed!')
    console.assert(this.HostGetResponseSenderModel.verify(), 'manager.HostGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostsGetAllRequest value
   * @this {!Client}
   * @param {!HostsGetAllRequest} value HostsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostsGetAllRequest serialization failed!')
    console.assert(this.HostsGetAllRequestSenderModel.verify(), 'manager.HostsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostsGetAllResponse value
   * @this {!Client}
   * @param {!HostsGetAllResponse} value HostsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostsGetAllResponse serialization failed!')
    console.assert(this.HostsGetAllResponseSenderModel.verify(), 'manager.HostsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostCreateRequest value
   * @this {!Client}
   * @param {!HostCreateRequest} value HostCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostCreateRequest serialization failed!')
    console.assert(this.HostCreateRequestSenderModel.verify(), 'manager.HostCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostCreateResponse value
   * @this {!Client}
   * @param {!HostCreateResponse} value HostCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostCreateResponse serialization failed!')
    console.assert(this.HostCreateResponseSenderModel.verify(), 'manager.HostCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostModifyRequest value
   * @this {!Client}
   * @param {!HostModifyRequest} value HostModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostModifyRequest serialization failed!')
    console.assert(this.HostModifyRequestSenderModel.verify(), 'manager.HostModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostModifyResponse value
   * @this {!Client}
   * @param {!HostModifyResponse} value HostModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostModifyResponse serialization failed!')
    console.assert(this.HostModifyResponseSenderModel.verify(), 'manager.HostModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostDeleteRequest value
   * @this {!Client}
   * @param {!HostDeleteRequest} value HostDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HostDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostDeleteRequest serialization failed!')
    console.assert(this.HostDeleteRequestSenderModel.verify(), 'manager.HostDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostDeleteResponse value
   * @this {!Client}
   * @param {!HostDeleteResponse} value HostDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HostDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostDeleteResponse serialization failed!')
    console.assert(this.HostDeleteResponseSenderModel.verify(), 'manager.HostDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HostUpdateNotify value
   * @this {!Client}
   * @param {!HostUpdateNotify} value HostUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_HostUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HostUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HostUpdateNotify serialization failed!')
    console.assert(this.HostUpdateNotifySenderModel.verify(), 'manager.HostUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleGetRequest value
   * @this {!Client}
   * @param {!ModuleGetRequest} value ModuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleGetRequest serialization failed!')
    console.assert(this.ModuleGetRequestSenderModel.verify(), 'manager.ModuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleGetResponse value
   * @this {!Client}
   * @param {!ModuleGetResponse} value ModuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleGetResponse serialization failed!')
    console.assert(this.ModuleGetResponseSenderModel.verify(), 'manager.ModuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesGetAllRequest value
   * @this {!Client}
   * @param {!ModulesGetAllRequest} value ModulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesGetAllRequest serialization failed!')
    console.assert(this.ModulesGetAllRequestSenderModel.verify(), 'manager.ModulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesGetAllResponse value
   * @this {!Client}
   * @param {!ModulesGetAllResponse} value ModulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesGetAllResponse serialization failed!')
    console.assert(this.ModulesGetAllResponseSenderModel.verify(), 'manager.ModulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleCreateRequest value
   * @this {!Client}
   * @param {!ModuleCreateRequest} value ModuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleCreateRequest serialization failed!')
    console.assert(this.ModuleCreateRequestSenderModel.verify(), 'manager.ModuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleCreateResponse value
   * @this {!Client}
   * @param {!ModuleCreateResponse} value ModuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleCreateResponse serialization failed!')
    console.assert(this.ModuleCreateResponseSenderModel.verify(), 'manager.ModuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleModifyRequest value
   * @this {!Client}
   * @param {!ModuleModifyRequest} value ModuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleModifyRequest serialization failed!')
    console.assert(this.ModuleModifyRequestSenderModel.verify(), 'manager.ModuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleModifyResponse value
   * @this {!Client}
   * @param {!ModuleModifyResponse} value ModuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleModifyResponse serialization failed!')
    console.assert(this.ModuleModifyResponseSenderModel.verify(), 'manager.ModuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleDeleteRequest value
   * @this {!Client}
   * @param {!ModuleDeleteRequest} value ModuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleDeleteRequest serialization failed!')
    console.assert(this.ModuleDeleteRequestSenderModel.verify(), 'manager.ModuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleDeleteResponse value
   * @this {!Client}
   * @param {!ModuleDeleteResponse} value ModuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleDeleteResponse serialization failed!')
    console.assert(this.ModuleDeleteResponseSenderModel.verify(), 'manager.ModuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleUpdateNotify value
   * @this {!Client}
   * @param {!ModuleUpdateNotify} value ModuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleUpdateNotify serialization failed!')
    console.assert(this.ModuleUpdateNotifySenderModel.verify(), 'manager.ModuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerTimeGetRequest value
   * @this {!Client}
   * @param {!ServerTimeGetRequest} value ServerTimeGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ServerTimeGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerTimeGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ServerTimeGetRequest serialization failed!')
    console.assert(this.ServerTimeGetRequestSenderModel.verify(), 'manager.ServerTimeGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerTimeGetResponse value
   * @this {!Client}
   * @param {!ServerTimeGetResponse} value ServerTimeGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ServerTimeGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerTimeGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ServerTimeGetResponse serialization failed!')
    console.assert(this.ServerTimeGetResponseSenderModel.verify(), 'manager.ServerTimeGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerPlatformTimezoneGetRequest value
   * @this {!Client}
   * @param {!ManagerPlatformTimezoneGetRequest} value ManagerPlatformTimezoneGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerPlatformTimezoneGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerPlatformTimezoneGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerPlatformTimezoneGetRequest serialization failed!')
    console.assert(this.ManagerPlatformTimezoneGetRequestSenderModel.verify(), 'manager.ManagerPlatformTimezoneGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerPlatformTimezoneGetResponse value
   * @this {!Client}
   * @param {!ManagerPlatformTimezoneGetResponse} value ManagerPlatformTimezoneGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerPlatformTimezoneGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerPlatformTimezoneGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerPlatformTimezoneGetResponse serialization failed!')
    console.assert(this.ManagerPlatformTimezoneGetResponseSenderModel.verify(), 'manager.ManagerPlatformTimezoneGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneGetRequest value
   * @this {!Client}
   * @param {!TimezoneGetRequest} value TimezoneGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneGetRequest serialization failed!')
    console.assert(this.TimezoneGetRequestSenderModel.verify(), 'manager.TimezoneGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneGetResponse value
   * @this {!Client}
   * @param {!TimezoneGetResponse} value TimezoneGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneGetResponse serialization failed!')
    console.assert(this.TimezoneGetResponseSenderModel.verify(), 'manager.TimezoneGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezonesGetAllRequest value
   * @this {!Client}
   * @param {!TimezonesGetAllRequest} value TimezonesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezonesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezonesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezonesGetAllRequest serialization failed!')
    console.assert(this.TimezonesGetAllRequestSenderModel.verify(), 'manager.TimezonesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezonesGetAllResponse value
   * @this {!Client}
   * @param {!TimezonesGetAllResponse} value TimezonesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezonesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezonesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezonesGetAllResponse serialization failed!')
    console.assert(this.TimezonesGetAllResponseSenderModel.verify(), 'manager.TimezonesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneCreateRequest value
   * @this {!Client}
   * @param {!TimezoneCreateRequest} value TimezoneCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneCreateRequest serialization failed!')
    console.assert(this.TimezoneCreateRequestSenderModel.verify(), 'manager.TimezoneCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneCreateResponse value
   * @this {!Client}
   * @param {!TimezoneCreateResponse} value TimezoneCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneCreateResponse serialization failed!')
    console.assert(this.TimezoneCreateResponseSenderModel.verify(), 'manager.TimezoneCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneModifyRequest value
   * @this {!Client}
   * @param {!TimezoneModifyRequest} value TimezoneModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneModifyRequest serialization failed!')
    console.assert(this.TimezoneModifyRequestSenderModel.verify(), 'manager.TimezoneModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneModifyResponse value
   * @this {!Client}
   * @param {!TimezoneModifyResponse} value TimezoneModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneModifyResponse serialization failed!')
    console.assert(this.TimezoneModifyResponseSenderModel.verify(), 'manager.TimezoneModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneDeleteRequest value
   * @this {!Client}
   * @param {!TimezoneDeleteRequest} value TimezoneDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneDeleteRequest serialization failed!')
    console.assert(this.TimezoneDeleteRequestSenderModel.verify(), 'manager.TimezoneDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneDeleteResponse value
   * @this {!Client}
   * @param {!TimezoneDeleteResponse} value TimezoneDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneDeleteResponse serialization failed!')
    console.assert(this.TimezoneDeleteResponseSenderModel.verify(), 'manager.TimezoneDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneUpdateNotify value
   * @this {!Client}
   * @param {!TimezoneUpdateNotify} value TimezoneUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TimezoneUpdateNotify serialization failed!')
    console.assert(this.TimezoneUpdateNotifySenderModel.verify(), 'manager.TimezoneUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetRequest value
   * @this {!Client}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupGetRequest serialization failed!')
    console.assert(this.CurrencyGroupGetRequestSenderModel.verify(), 'manager.CurrencyGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetResponse value
   * @this {!Client}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupGetResponse serialization failed!')
    console.assert(this.CurrencyGroupGetResponseSenderModel.verify(), 'manager.CurrencyGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllRequest value
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupsGetAllRequest serialization failed!')
    console.assert(this.CurrencyGroupsGetAllRequestSenderModel.verify(), 'manager.CurrencyGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllResponse value
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupsGetAllResponse serialization failed!')
    console.assert(this.CurrencyGroupsGetAllResponseSenderModel.verify(), 'manager.CurrencyGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupCreateRequest value
   * @this {!Client}
   * @param {!CurrencyGroupCreateRequest} value CurrencyGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupCreateRequest serialization failed!')
    console.assert(this.CurrencyGroupCreateRequestSenderModel.verify(), 'manager.CurrencyGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupCreateResponse value
   * @this {!Client}
   * @param {!CurrencyGroupCreateResponse} value CurrencyGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupCreateResponse serialization failed!')
    console.assert(this.CurrencyGroupCreateResponseSenderModel.verify(), 'manager.CurrencyGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupModifyRequest value
   * @this {!Client}
   * @param {!CurrencyGroupModifyRequest} value CurrencyGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupModifyRequest serialization failed!')
    console.assert(this.CurrencyGroupModifyRequestSenderModel.verify(), 'manager.CurrencyGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupModifyResponse value
   * @this {!Client}
   * @param {!CurrencyGroupModifyResponse} value CurrencyGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupModifyResponse serialization failed!')
    console.assert(this.CurrencyGroupModifyResponseSenderModel.verify(), 'manager.CurrencyGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupDeleteRequest value
   * @this {!Client}
   * @param {!CurrencyGroupDeleteRequest} value CurrencyGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupDeleteRequest serialization failed!')
    console.assert(this.CurrencyGroupDeleteRequestSenderModel.verify(), 'manager.CurrencyGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupDeleteResponse value
   * @this {!Client}
   * @param {!CurrencyGroupDeleteResponse} value CurrencyGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupDeleteResponse serialization failed!')
    console.assert(this.CurrencyGroupDeleteResponseSenderModel.verify(), 'manager.CurrencyGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupUpdateNotify value
   * @this {!Client}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGroupUpdateNotify serialization failed!')
    console.assert(this.CurrencyGroupUpdateNotifySenderModel.verify(), 'manager.CurrencyGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetRequest value
   * @this {!Client}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGetRequest serialization failed!')
    console.assert(this.CurrencyGetRequestSenderModel.verify(), 'manager.CurrencyGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetResponse value
   * @this {!Client}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyGetResponse serialization failed!')
    console.assert(this.CurrencyGetResponseSenderModel.verify(), 'manager.CurrencyGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllRequest value
   * @this {!Client}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrenciesGetAllRequest serialization failed!')
    console.assert(this.CurrenciesGetAllRequestSenderModel.verify(), 'manager.CurrenciesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllResponse value
   * @this {!Client}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrenciesGetAllResponse serialization failed!')
    console.assert(this.CurrenciesGetAllResponseSenderModel.verify(), 'manager.CurrenciesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyCreateRequest value
   * @this {!Client}
   * @param {!CurrencyCreateRequest} value CurrencyCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyCreateRequest serialization failed!')
    console.assert(this.CurrencyCreateRequestSenderModel.verify(), 'manager.CurrencyCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyCreateResponse value
   * @this {!Client}
   * @param {!CurrencyCreateResponse} value CurrencyCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyCreateResponse serialization failed!')
    console.assert(this.CurrencyCreateResponseSenderModel.verify(), 'manager.CurrencyCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyModifyRequest value
   * @this {!Client}
   * @param {!CurrencyModifyRequest} value CurrencyModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyModifyRequest serialization failed!')
    console.assert(this.CurrencyModifyRequestSenderModel.verify(), 'manager.CurrencyModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyModifyResponse value
   * @this {!Client}
   * @param {!CurrencyModifyResponse} value CurrencyModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyModifyResponse serialization failed!')
    console.assert(this.CurrencyModifyResponseSenderModel.verify(), 'manager.CurrencyModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyDeleteRequest value
   * @this {!Client}
   * @param {!CurrencyDeleteRequest} value CurrencyDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyDeleteRequest serialization failed!')
    console.assert(this.CurrencyDeleteRequestSenderModel.verify(), 'manager.CurrencyDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyDeleteResponse value
   * @this {!Client}
   * @param {!CurrencyDeleteResponse} value CurrencyDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyDeleteResponse serialization failed!')
    console.assert(this.CurrencyDeleteResponseSenderModel.verify(), 'manager.CurrencyDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyUpdateNotify value
   * @this {!Client}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CurrencyUpdateNotify serialization failed!')
    console.assert(this.CurrencyUpdateNotifySenderModel.verify(), 'manager.CurrencyUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetRequest value
   * @this {!Client}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupGetRequest serialization failed!')
    console.assert(this.SymbolGroupGetRequestSenderModel.verify(), 'manager.SymbolGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetResponse value
   * @this {!Client}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupGetResponse serialization failed!')
    console.assert(this.SymbolGroupGetResponseSenderModel.verify(), 'manager.SymbolGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllRequest value
   * @this {!Client}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupsGetAllRequest serialization failed!')
    console.assert(this.SymbolGroupsGetAllRequestSenderModel.verify(), 'manager.SymbolGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllResponse value
   * @this {!Client}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupsGetAllResponse serialization failed!')
    console.assert(this.SymbolGroupsGetAllResponseSenderModel.verify(), 'manager.SymbolGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupCreateRequest value
   * @this {!Client}
   * @param {!SymbolGroupCreateRequest} value SymbolGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupCreateRequest serialization failed!')
    console.assert(this.SymbolGroupCreateRequestSenderModel.verify(), 'manager.SymbolGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupCreateResponse value
   * @this {!Client}
   * @param {!SymbolGroupCreateResponse} value SymbolGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupCreateResponse serialization failed!')
    console.assert(this.SymbolGroupCreateResponseSenderModel.verify(), 'manager.SymbolGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupModifyRequest value
   * @this {!Client}
   * @param {!SymbolGroupModifyRequest} value SymbolGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupModifyRequest serialization failed!')
    console.assert(this.SymbolGroupModifyRequestSenderModel.verify(), 'manager.SymbolGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupModifyResponse value
   * @this {!Client}
   * @param {!SymbolGroupModifyResponse} value SymbolGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupModifyResponse serialization failed!')
    console.assert(this.SymbolGroupModifyResponseSenderModel.verify(), 'manager.SymbolGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupDeleteRequest value
   * @this {!Client}
   * @param {!SymbolGroupDeleteRequest} value SymbolGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupDeleteRequest serialization failed!')
    console.assert(this.SymbolGroupDeleteRequestSenderModel.verify(), 'manager.SymbolGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupDeleteResponse value
   * @this {!Client}
   * @param {!SymbolGroupDeleteResponse} value SymbolGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupDeleteResponse serialization failed!')
    console.assert(this.SymbolGroupDeleteResponseSenderModel.verify(), 'manager.SymbolGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupUpdateNotify value
   * @this {!Client}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGroupUpdateNotify serialization failed!')
    console.assert(this.SymbolGroupUpdateNotifySenderModel.verify(), 'manager.SymbolGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetRequest value
   * @this {!Client}
   * @param {!SymbolGetRequest} value SymbolGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGetRequest serialization failed!')
    console.assert(this.SymbolGetRequestSenderModel.verify(), 'manager.SymbolGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetResponse value
   * @this {!Client}
   * @param {!SymbolGetResponse} value SymbolGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolGetResponse serialization failed!')
    console.assert(this.SymbolGetResponseSenderModel.verify(), 'manager.SymbolGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllRequest value
   * @this {!Client}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolsGetAllRequest serialization failed!')
    console.assert(this.SymbolsGetAllRequestSenderModel.verify(), 'manager.SymbolsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllResponse value
   * @this {!Client}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolsGetAllResponse serialization failed!')
    console.assert(this.SymbolsGetAllResponseSenderModel.verify(), 'manager.SymbolsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolCreateRequest value
   * @this {!Client}
   * @param {!SymbolCreateRequest} value SymbolCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolCreateRequest serialization failed!')
    console.assert(this.SymbolCreateRequestSenderModel.verify(), 'manager.SymbolCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolCreateResponse value
   * @this {!Client}
   * @param {!SymbolCreateResponse} value SymbolCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolCreateResponse serialization failed!')
    console.assert(this.SymbolCreateResponseSenderModel.verify(), 'manager.SymbolCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolModifyRequest value
   * @this {!Client}
   * @param {!SymbolModifyRequest} value SymbolModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolModifyRequest serialization failed!')
    console.assert(this.SymbolModifyRequestSenderModel.verify(), 'manager.SymbolModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolModifyResponse value
   * @this {!Client}
   * @param {!SymbolModifyResponse} value SymbolModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolModifyResponse serialization failed!')
    console.assert(this.SymbolModifyResponseSenderModel.verify(), 'manager.SymbolModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDeleteRequest value
   * @this {!Client}
   * @param {!SymbolDeleteRequest} value SymbolDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolDeleteRequest serialization failed!')
    console.assert(this.SymbolDeleteRequestSenderModel.verify(), 'manager.SymbolDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDeleteResponse value
   * @this {!Client}
   * @param {!SymbolDeleteResponse} value SymbolDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolDeleteResponse serialization failed!')
    console.assert(this.SymbolDeleteResponseSenderModel.verify(), 'manager.SymbolDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolUpdateNotify value
   * @this {!Client}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolUpdateNotify serialization failed!')
    console.assert(this.SymbolUpdateNotifySenderModel.verify(), 'manager.SymbolUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsExportRequest value
   * @this {!Client}
   * @param {!SwapsExportRequest} value SwapsExportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsExportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsExportRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsExportRequest serialization failed!')
    console.assert(this.SwapsExportRequestSenderModel.verify(), 'manager.SwapsExportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsExportResponse value
   * @this {!Client}
   * @param {!SwapsExportResponse} value SwapsExportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsExportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsExportResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsExportResponse serialization failed!')
    console.assert(this.SwapsExportResponseSenderModel.verify(), 'manager.SwapsExportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsImportRequest value
   * @this {!Client}
   * @param {!SwapsImportRequest} value SwapsImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsImportRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsImportRequest serialization failed!')
    console.assert(this.SwapsImportRequestSenderModel.verify(), 'manager.SwapsImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SwapsImportResponse value
   * @this {!Client}
   * @param {!SwapsImportResponse} value SwapsImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SwapsImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SwapsImportResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SwapsImportResponse serialization failed!')
    console.assert(this.SwapsImportResponseSenderModel.verify(), 'manager.SwapsImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileGetRequest value
   * @this {!Client}
   * @param {!CommissionProfileGetRequest} value CommissionProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileGetRequest serialization failed!')
    console.assert(this.CommissionProfileGetRequestSenderModel.verify(), 'manager.CommissionProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileGetResponse value
   * @this {!Client}
   * @param {!CommissionProfileGetResponse} value CommissionProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileGetResponse serialization failed!')
    console.assert(this.CommissionProfileGetResponseSenderModel.verify(), 'manager.CommissionProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfilesGetAllRequest value
   * @this {!Client}
   * @param {!CommissionProfilesGetAllRequest} value CommissionProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfilesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfilesGetAllRequest serialization failed!')
    console.assert(this.CommissionProfilesGetAllRequestSenderModel.verify(), 'manager.CommissionProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfilesGetAllResponse value
   * @this {!Client}
   * @param {!CommissionProfilesGetAllResponse} value CommissionProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfilesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfilesGetAllResponse serialization failed!')
    console.assert(this.CommissionProfilesGetAllResponseSenderModel.verify(), 'manager.CommissionProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileCreateRequest value
   * @this {!Client}
   * @param {!CommissionProfileCreateRequest} value CommissionProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileCreateRequest serialization failed!')
    console.assert(this.CommissionProfileCreateRequestSenderModel.verify(), 'manager.CommissionProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileCreateResponse value
   * @this {!Client}
   * @param {!CommissionProfileCreateResponse} value CommissionProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileCreateResponse serialization failed!')
    console.assert(this.CommissionProfileCreateResponseSenderModel.verify(), 'manager.CommissionProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileModifyRequest value
   * @this {!Client}
   * @param {!CommissionProfileModifyRequest} value CommissionProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileModifyRequest serialization failed!')
    console.assert(this.CommissionProfileModifyRequestSenderModel.verify(), 'manager.CommissionProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileModifyResponse value
   * @this {!Client}
   * @param {!CommissionProfileModifyResponse} value CommissionProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileModifyResponse serialization failed!')
    console.assert(this.CommissionProfileModifyResponseSenderModel.verify(), 'manager.CommissionProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileDeleteRequest value
   * @this {!Client}
   * @param {!CommissionProfileDeleteRequest} value CommissionProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileDeleteRequest serialization failed!')
    console.assert(this.CommissionProfileDeleteRequestSenderModel.verify(), 'manager.CommissionProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileDeleteResponse value
   * @this {!Client}
   * @param {!CommissionProfileDeleteResponse} value CommissionProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileDeleteResponse serialization failed!')
    console.assert(this.CommissionProfileDeleteResponseSenderModel.verify(), 'manager.CommissionProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CommissionProfileUpdateNotify value
   * @this {!Client}
   * @param {!CommissionProfileUpdateNotify} value CommissionProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CommissionProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CommissionProfileUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CommissionProfileUpdateNotify serialization failed!')
    console.assert(this.CommissionProfileUpdateNotifySenderModel.verify(), 'manager.CommissionProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileGetRequest value
   * @this {!Client}
   * @param {!MarkupProfileGetRequest} value MarkupProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileGetRequest serialization failed!')
    console.assert(this.MarkupProfileGetRequestSenderModel.verify(), 'manager.MarkupProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileGetResponse value
   * @this {!Client}
   * @param {!MarkupProfileGetResponse} value MarkupProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileGetResponse serialization failed!')
    console.assert(this.MarkupProfileGetResponseSenderModel.verify(), 'manager.MarkupProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfilesGetAllRequest value
   * @this {!Client}
   * @param {!MarkupProfilesGetAllRequest} value MarkupProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfilesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfilesGetAllRequest serialization failed!')
    console.assert(this.MarkupProfilesGetAllRequestSenderModel.verify(), 'manager.MarkupProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfilesGetAllResponse value
   * @this {!Client}
   * @param {!MarkupProfilesGetAllResponse} value MarkupProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfilesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfilesGetAllResponse serialization failed!')
    console.assert(this.MarkupProfilesGetAllResponseSenderModel.verify(), 'manager.MarkupProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileCreateRequest value
   * @this {!Client}
   * @param {!MarkupProfileCreateRequest} value MarkupProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileCreateRequest serialization failed!')
    console.assert(this.MarkupProfileCreateRequestSenderModel.verify(), 'manager.MarkupProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileCreateResponse value
   * @this {!Client}
   * @param {!MarkupProfileCreateResponse} value MarkupProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileCreateResponse serialization failed!')
    console.assert(this.MarkupProfileCreateResponseSenderModel.verify(), 'manager.MarkupProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileModifyRequest value
   * @this {!Client}
   * @param {!MarkupProfileModifyRequest} value MarkupProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileModifyRequest serialization failed!')
    console.assert(this.MarkupProfileModifyRequestSenderModel.verify(), 'manager.MarkupProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileModifyResponse value
   * @this {!Client}
   * @param {!MarkupProfileModifyResponse} value MarkupProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileModifyResponse serialization failed!')
    console.assert(this.MarkupProfileModifyResponseSenderModel.verify(), 'manager.MarkupProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileDeleteRequest value
   * @this {!Client}
   * @param {!MarkupProfileDeleteRequest} value MarkupProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileDeleteRequest serialization failed!')
    console.assert(this.MarkupProfileDeleteRequestSenderModel.verify(), 'manager.MarkupProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileDeleteResponse value
   * @this {!Client}
   * @param {!MarkupProfileDeleteResponse} value MarkupProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileDeleteResponse serialization failed!')
    console.assert(this.MarkupProfileDeleteResponseSenderModel.verify(), 'manager.MarkupProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarkupProfileUpdateNotify value
   * @this {!Client}
   * @param {!MarkupProfileUpdateNotify} value MarkupProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarkupProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarkupProfileUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarkupProfileUpdateNotify serialization failed!')
    console.assert(this.MarkupProfileUpdateNotifySenderModel.verify(), 'manager.MarkupProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupGetRequest value
   * @this {!Client}
   * @param {!ManagerGroupGetRequest} value ManagerGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupGetRequest serialization failed!')
    console.assert(this.ManagerGroupGetRequestSenderModel.verify(), 'manager.ManagerGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupGetResponse value
   * @this {!Client}
   * @param {!ManagerGroupGetResponse} value ManagerGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupGetResponse serialization failed!')
    console.assert(this.ManagerGroupGetResponseSenderModel.verify(), 'manager.ManagerGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupsGetAllRequest value
   * @this {!Client}
   * @param {!ManagerGroupsGetAllRequest} value ManagerGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupsGetAllRequest serialization failed!')
    console.assert(this.ManagerGroupsGetAllRequestSenderModel.verify(), 'manager.ManagerGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupsGetAllResponse value
   * @this {!Client}
   * @param {!ManagerGroupsGetAllResponse} value ManagerGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupsGetAllResponse serialization failed!')
    console.assert(this.ManagerGroupsGetAllResponseSenderModel.verify(), 'manager.ManagerGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupCreateRequest value
   * @this {!Client}
   * @param {!ManagerGroupCreateRequest} value ManagerGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupCreateRequest serialization failed!')
    console.assert(this.ManagerGroupCreateRequestSenderModel.verify(), 'manager.ManagerGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupCreateResponse value
   * @this {!Client}
   * @param {!ManagerGroupCreateResponse} value ManagerGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupCreateResponse serialization failed!')
    console.assert(this.ManagerGroupCreateResponseSenderModel.verify(), 'manager.ManagerGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupModifyRequest value
   * @this {!Client}
   * @param {!ManagerGroupModifyRequest} value ManagerGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupModifyRequest serialization failed!')
    console.assert(this.ManagerGroupModifyRequestSenderModel.verify(), 'manager.ManagerGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupModifyResponse value
   * @this {!Client}
   * @param {!ManagerGroupModifyResponse} value ManagerGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupModifyResponse serialization failed!')
    console.assert(this.ManagerGroupModifyResponseSenderModel.verify(), 'manager.ManagerGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupDeleteRequest value
   * @this {!Client}
   * @param {!ManagerGroupDeleteRequest} value ManagerGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupDeleteRequest serialization failed!')
    console.assert(this.ManagerGroupDeleteRequestSenderModel.verify(), 'manager.ManagerGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupDeleteResponse value
   * @this {!Client}
   * @param {!ManagerGroupDeleteResponse} value ManagerGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupDeleteResponse serialization failed!')
    console.assert(this.ManagerGroupDeleteResponseSenderModel.verify(), 'manager.ManagerGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGroupUpdateNotify value
   * @this {!Client}
   * @param {!ManagerGroupUpdateNotify} value ManagerGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGroupUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGroupUpdateNotify serialization failed!')
    console.assert(this.ManagerGroupUpdateNotifySenderModel.verify(), 'manager.ManagerGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGetRequest value
   * @this {!Client}
   * @param {!ManagerGetRequest} value ManagerGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGetRequest serialization failed!')
    console.assert(this.ManagerGetRequestSenderModel.verify(), 'manager.ManagerGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerGetResponse value
   * @this {!Client}
   * @param {!ManagerGetResponse} value ManagerGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerGetResponse serialization failed!')
    console.assert(this.ManagerGetResponseSenderModel.verify(), 'manager.ManagerGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagersGetAllRequest value
   * @this {!Client}
   * @param {!ManagersGetAllRequest} value ManagersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagersGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagersGetAllRequest serialization failed!')
    console.assert(this.ManagersGetAllRequestSenderModel.verify(), 'manager.ManagersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagersGetAllResponse value
   * @this {!Client}
   * @param {!ManagersGetAllResponse} value ManagersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagersGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagersGetAllResponse serialization failed!')
    console.assert(this.ManagersGetAllResponseSenderModel.verify(), 'manager.ManagersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerCreateRequest value
   * @this {!Client}
   * @param {!ManagerCreateRequest} value ManagerCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerCreateRequest serialization failed!')
    console.assert(this.ManagerCreateRequestSenderModel.verify(), 'manager.ManagerCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerCreateResponse value
   * @this {!Client}
   * @param {!ManagerCreateResponse} value ManagerCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerCreateResponse serialization failed!')
    console.assert(this.ManagerCreateResponseSenderModel.verify(), 'manager.ManagerCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerModifyRequest value
   * @this {!Client}
   * @param {!ManagerModifyRequest} value ManagerModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerModifyRequest serialization failed!')
    console.assert(this.ManagerModifyRequestSenderModel.verify(), 'manager.ManagerModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerModifyResponse value
   * @this {!Client}
   * @param {!ManagerModifyResponse} value ManagerModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerModifyResponse serialization failed!')
    console.assert(this.ManagerModifyResponseSenderModel.verify(), 'manager.ManagerModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerDeleteRequest value
   * @this {!Client}
   * @param {!ManagerDeleteRequest} value ManagerDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerDeleteRequest serialization failed!')
    console.assert(this.ManagerDeleteRequestSenderModel.verify(), 'manager.ManagerDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerDeleteResponse value
   * @this {!Client}
   * @param {!ManagerDeleteResponse} value ManagerDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerDeleteResponse serialization failed!')
    console.assert(this.ManagerDeleteResponseSenderModel.verify(), 'manager.ManagerDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ManagerUpdateNotify value
   * @this {!Client}
   * @param {!ManagerUpdateNotify} value ManagerUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ManagerUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ManagerUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ManagerUpdateNotify serialization failed!')
    console.assert(this.ManagerUpdateNotifySenderModel.verify(), 'manager.ManagerUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformGetRequest value
   * @this {!Client}
   * @param {!PlatformGetRequest} value PlatformGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformGetRequest serialization failed!')
    console.assert(this.PlatformGetRequestSenderModel.verify(), 'manager.PlatformGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformGetResponse value
   * @this {!Client}
   * @param {!PlatformGetResponse} value PlatformGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformGetResponse serialization failed!')
    console.assert(this.PlatformGetResponseSenderModel.verify(), 'manager.PlatformGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformsGetAllRequest value
   * @this {!Client}
   * @param {!PlatformsGetAllRequest} value PlatformsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformsGetAllRequest serialization failed!')
    console.assert(this.PlatformsGetAllRequestSenderModel.verify(), 'manager.PlatformsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformsGetAllResponse value
   * @this {!Client}
   * @param {!PlatformsGetAllResponse} value PlatformsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformsGetAllResponse serialization failed!')
    console.assert(this.PlatformsGetAllResponseSenderModel.verify(), 'manager.PlatformsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformCreateRequest value
   * @this {!Client}
   * @param {!PlatformCreateRequest} value PlatformCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformCreateRequest serialization failed!')
    console.assert(this.PlatformCreateRequestSenderModel.verify(), 'manager.PlatformCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformCreateResponse value
   * @this {!Client}
   * @param {!PlatformCreateResponse} value PlatformCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformCreateResponse serialization failed!')
    console.assert(this.PlatformCreateResponseSenderModel.verify(), 'manager.PlatformCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformModifyRequest value
   * @this {!Client}
   * @param {!PlatformModifyRequest} value PlatformModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformModifyRequest serialization failed!')
    console.assert(this.PlatformModifyRequestSenderModel.verify(), 'manager.PlatformModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformModifyResponse value
   * @this {!Client}
   * @param {!PlatformModifyResponse} value PlatformModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformModifyResponse serialization failed!')
    console.assert(this.PlatformModifyResponseSenderModel.verify(), 'manager.PlatformModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformDeleteRequest value
   * @this {!Client}
   * @param {!PlatformDeleteRequest} value PlatformDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformDeleteRequest serialization failed!')
    console.assert(this.PlatformDeleteRequestSenderModel.verify(), 'manager.PlatformDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformDeleteResponse value
   * @this {!Client}
   * @param {!PlatformDeleteResponse} value PlatformDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformDeleteResponse serialization failed!')
    console.assert(this.PlatformDeleteResponseSenderModel.verify(), 'manager.PlatformDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformUpdateNotify value
   * @this {!Client}
   * @param {!PlatformUpdateNotify} value PlatformUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PlatformUpdateNotify serialization failed!')
    console.assert(this.PlatformUpdateNotifySenderModel.verify(), 'manager.PlatformUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupGetRequest value
   * @this {!Client}
   * @param {!AccountGroupGetRequest} value AccountGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupGetRequest serialization failed!')
    console.assert(this.AccountGroupGetRequestSenderModel.verify(), 'manager.AccountGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupGetResponse value
   * @this {!Client}
   * @param {!AccountGroupGetResponse} value AccountGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupGetResponse serialization failed!')
    console.assert(this.AccountGroupGetResponseSenderModel.verify(), 'manager.AccountGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupsGetAllRequest value
   * @this {!Client}
   * @param {!AccountGroupsGetAllRequest} value AccountGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupsGetAllRequest serialization failed!')
    console.assert(this.AccountGroupsGetAllRequestSenderModel.verify(), 'manager.AccountGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupsGetAllResponse value
   * @this {!Client}
   * @param {!AccountGroupsGetAllResponse} value AccountGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupsGetAllResponse serialization failed!')
    console.assert(this.AccountGroupsGetAllResponseSenderModel.verify(), 'manager.AccountGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupCreateRequest value
   * @this {!Client}
   * @param {!AccountGroupCreateRequest} value AccountGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupCreateRequest serialization failed!')
    console.assert(this.AccountGroupCreateRequestSenderModel.verify(), 'manager.AccountGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupCreateResponse value
   * @this {!Client}
   * @param {!AccountGroupCreateResponse} value AccountGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupCreateResponse serialization failed!')
    console.assert(this.AccountGroupCreateResponseSenderModel.verify(), 'manager.AccountGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupModifyRequest value
   * @this {!Client}
   * @param {!AccountGroupModifyRequest} value AccountGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupModifyRequest serialization failed!')
    console.assert(this.AccountGroupModifyRequestSenderModel.verify(), 'manager.AccountGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupModifyResponse value
   * @this {!Client}
   * @param {!AccountGroupModifyResponse} value AccountGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupModifyResponse serialization failed!')
    console.assert(this.AccountGroupModifyResponseSenderModel.verify(), 'manager.AccountGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupDeleteRequest value
   * @this {!Client}
   * @param {!AccountGroupDeleteRequest} value AccountGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupDeleteRequest serialization failed!')
    console.assert(this.AccountGroupDeleteRequestSenderModel.verify(), 'manager.AccountGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupDeleteResponse value
   * @this {!Client}
   * @param {!AccountGroupDeleteResponse} value AccountGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupDeleteResponse serialization failed!')
    console.assert(this.AccountGroupDeleteResponseSenderModel.verify(), 'manager.AccountGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupUpdateNotify value
   * @this {!Client}
   * @param {!AccountGroupUpdateNotify} value AccountGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupUpdateNotify serialization failed!')
    console.assert(this.AccountGroupUpdateNotifySenderModel.verify(), 'manager.AccountGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGetRequest value
   * @this {!Client}
   * @param {!AccountGetRequest} value AccountGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGetRequest serialization failed!')
    console.assert(this.AccountGetRequestSenderModel.verify(), 'manager.AccountGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGetResponse value
   * @this {!Client}
   * @param {!AccountGetResponse} value AccountGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGetResponse serialization failed!')
    console.assert(this.AccountGetResponseSenderModel.verify(), 'manager.AccountGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllRequest value
   * @this {!Client}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetAllRequest serialization failed!')
    console.assert(this.AccountsGetAllRequestSenderModel.verify(), 'manager.AccountsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllResponse value
   * @this {!Client}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetAllResponse serialization failed!')
    console.assert(this.AccountsGetAllResponseSenderModel.verify(), 'manager.AccountsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountCreateRequest value
   * @this {!Client}
   * @param {!AccountCreateRequest} value AccountCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountCreateRequest serialization failed!')
    console.assert(this.AccountCreateRequestSenderModel.verify(), 'manager.AccountCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountCreateResponse value
   * @this {!Client}
   * @param {!AccountCreateResponse} value AccountCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountCreateResponse serialization failed!')
    console.assert(this.AccountCreateResponseSenderModel.verify(), 'manager.AccountCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterCreateRequest value
   * @this {!Client}
   * @param {!AccountSocialTradingMasterCreateRequest} value AccountSocialTradingMasterCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingMasterCreateRequest serialization failed!')
    console.assert(this.AccountSocialTradingMasterCreateRequestSenderModel.verify(), 'manager.AccountSocialTradingMasterCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterCreateResponse value
   * @this {!Client}
   * @param {!AccountSocialTradingMasterCreateResponse} value AccountSocialTradingMasterCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingMasterCreateResponse serialization failed!')
    console.assert(this.AccountSocialTradingMasterCreateResponseSenderModel.verify(), 'manager.AccountSocialTradingMasterCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorCreateRequest value
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorCreateRequest} value AccountSocialTradingInvestorCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingInvestorCreateRequest serialization failed!')
    console.assert(this.AccountSocialTradingInvestorCreateRequestSenderModel.verify(), 'manager.AccountSocialTradingInvestorCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorCreateResponse value
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorCreateResponse} value AccountSocialTradingInvestorCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountSocialTradingInvestorCreateResponse serialization failed!')
    console.assert(this.AccountSocialTradingInvestorCreateResponseSenderModel.verify(), 'manager.AccountSocialTradingInvestorCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountModifyRequest value
   * @this {!Client}
   * @param {!AccountModifyRequest} value AccountModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountModifyRequest serialization failed!')
    console.assert(this.AccountModifyRequestSenderModel.verify(), 'manager.AccountModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountModifyResponse value
   * @this {!Client}
   * @param {!AccountModifyResponse} value AccountModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountModifyResponse serialization failed!')
    console.assert(this.AccountModifyResponseSenderModel.verify(), 'manager.AccountModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteRequest value
   * @this {!Client}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountDeleteRequest serialization failed!')
    console.assert(this.AccountDeleteRequestSenderModel.verify(), 'manager.AccountDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteResponse value
   * @this {!Client}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountDeleteResponse serialization failed!')
    console.assert(this.AccountDeleteResponseSenderModel.verify(), 'manager.AccountDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountsDeleteRequest value
   * @this {!Client}
   * @param {!DemoAccountsDeleteRequest} value DemoAccountsDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountsDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountsDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DemoAccountsDeleteRequest serialization failed!')
    console.assert(this.DemoAccountsDeleteRequestSenderModel.verify(), 'manager.DemoAccountsDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountsDeleteResponse value
   * @this {!Client}
   * @param {!DemoAccountsDeleteResponse} value DemoAccountsDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountsDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountsDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DemoAccountsDeleteResponse serialization failed!')
    console.assert(this.DemoAccountsDeleteResponseSenderModel.verify(), 'manager.DemoAccountsDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingMasterRequest value
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingMasterRequest} value AccountUpgradeToSocialTradingMasterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingMasterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingMasterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingMasterRequest serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingMasterRequestSenderModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingMasterResponse value
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingMasterResponse} value AccountUpgradeToSocialTradingMasterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingMasterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingMasterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingMasterResponse serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingMasterResponseSenderModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingInvestorRequest value
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingInvestorRequest} value AccountUpgradeToSocialTradingInvestorRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingInvestorRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingInvestorRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingInvestorRequest serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingInvestorRequestSenderModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpgradeToSocialTradingInvestorResponse value
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingInvestorResponse} value AccountUpgradeToSocialTradingInvestorResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpgradeToSocialTradingInvestorResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpgradeToSocialTradingInvestorResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpgradeToSocialTradingInvestorResponse serialization failed!')
    console.assert(this.AccountUpgradeToSocialTradingInvestorResponseSenderModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingInvestorAccountToRegularRequest value
   * @this {!Client}
   * @param {!SocialTradingInvestorAccountToRegularRequest} value SocialTradingInvestorAccountToRegularRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingInvestorAccountToRegularRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingInvestorAccountToRegularRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingInvestorAccountToRegularRequest serialization failed!')
    console.assert(this.SocialTradingInvestorAccountToRegularRequestSenderModel.verify(), 'manager.SocialTradingInvestorAccountToRegularRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingInvestorAccountToRegularResponse value
   * @this {!Client}
   * @param {!SocialTradingInvestorAccountToRegularResponse} value SocialTradingInvestorAccountToRegularResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingInvestorAccountToRegularResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingInvestorAccountToRegularResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingInvestorAccountToRegularResponse serialization failed!')
    console.assert(this.SocialTradingInvestorAccountToRegularResponseSenderModel.verify(), 'manager.SocialTradingInvestorAccountToRegularResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingMasterAccountToRegularRequest value
   * @this {!Client}
   * @param {!SocialTradingMasterAccountToRegularRequest} value SocialTradingMasterAccountToRegularRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingMasterAccountToRegularRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingMasterAccountToRegularRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingMasterAccountToRegularRequest serialization failed!')
    console.assert(this.SocialTradingMasterAccountToRegularRequestSenderModel.verify(), 'manager.SocialTradingMasterAccountToRegularRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SocialTradingMasterAccountToRegularResponse value
   * @this {!Client}
   * @param {!SocialTradingMasterAccountToRegularResponse} value SocialTradingMasterAccountToRegularResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SocialTradingMasterAccountToRegularResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SocialTradingMasterAccountToRegularResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SocialTradingMasterAccountToRegularResponse serialization failed!')
    console.assert(this.SocialTradingMasterAccountToRegularResponseSenderModel.verify(), 'manager.SocialTradingMasterAccountToRegularResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateNotify value
   * @this {!Client}
   * @param {!AccountUpdateNotify} value AccountUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountUpdateNotify serialization failed!')
    console.assert(this.AccountUpdateNotifySenderModel.verify(), 'manager.AccountUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginGetRequest value
   * @this {!Client}
   * @param {!MarginGetRequest} value MarginGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginGetRequest serialization failed!')
    console.assert(this.MarginGetRequestSenderModel.verify(), 'manager.MarginGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginGetResponse value
   * @this {!Client}
   * @param {!MarginGetResponse} value MarginGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginGetResponse serialization failed!')
    console.assert(this.MarginGetResponseSenderModel.verify(), 'manager.MarginGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllRequest value
   * @this {!Client}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginsGetAllRequest serialization failed!')
    console.assert(this.MarginsGetAllRequestSenderModel.verify(), 'manager.MarginsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllResponse value
   * @this {!Client}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginsGetAllResponse serialization failed!')
    console.assert(this.MarginsGetAllResponseSenderModel.verify(), 'manager.MarginsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateNotify value
   * @this {!Client}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginUpdateNotify serialization failed!')
    console.assert(this.MarginUpdateNotifySenderModel.verify(), 'manager.MarginUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginCallNotify value
   * @this {!Client}
   * @param {!MarginCallNotify} value MarginCallNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginCallNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginCallNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginCallNotify serialization failed!')
    console.assert(this.MarginCallNotifySenderModel.verify(), 'manager.MarginCallNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StopOutNotify value
   * @this {!Client}
   * @param {!StopOutNotify} value StopOutNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StopOutNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StopOutNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.StopOutNotify serialization failed!')
    console.assert(this.StopOutNotifySenderModel.verify(), 'manager.StopOutNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetGetRequest value
   * @this {!Client}
   * @param {!AssetGetRequest} value AssetGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetGetRequest serialization failed!')
    console.assert(this.AssetGetRequestSenderModel.verify(), 'manager.AssetGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetGetResponse value
   * @this {!Client}
   * @param {!AssetGetResponse} value AssetGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetGetResponse serialization failed!')
    console.assert(this.AssetGetResponseSenderModel.verify(), 'manager.AssetGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetRequest value
   * @this {!Client}
   * @param {!AssetsGetRequest} value AssetsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetRequest serialization failed!')
    console.assert(this.AssetsGetRequestSenderModel.verify(), 'manager.AssetsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetResponse value
   * @this {!Client}
   * @param {!AssetsGetResponse} value AssetsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetResponse serialization failed!')
    console.assert(this.AssetsGetResponseSenderModel.verify(), 'manager.AssetsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllRequest value
   * @this {!Client}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetAllRequest serialization failed!')
    console.assert(this.AssetsGetAllRequestSenderModel.verify(), 'manager.AssetsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllResponse value
   * @this {!Client}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetsGetAllResponse serialization failed!')
    console.assert(this.AssetsGetAllResponseSenderModel.verify(), 'manager.AssetsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdateNotify value
   * @this {!Client}
   * @param {!AssetUpdateNotify} value AssetUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AssetUpdateNotify serialization failed!')
    console.assert(this.AssetUpdateNotifySenderModel.verify(), 'manager.AssetUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderGetRequest value
   * @this {!Client}
   * @param {!OrderGetRequest} value OrderGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderGetRequest serialization failed!')
    console.assert(this.OrderGetRequestSenderModel.verify(), 'manager.OrderGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderGetResponse value
   * @this {!Client}
   * @param {!OrderGetResponse} value OrderGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderGetResponse serialization failed!')
    console.assert(this.OrderGetResponseSenderModel.verify(), 'manager.OrderGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetRequest value
   * @this {!Client}
   * @param {!OrdersGetRequest} value OrdersGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetRequest serialization failed!')
    console.assert(this.OrdersGetRequestSenderModel.verify(), 'manager.OrdersGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetResponse value
   * @this {!Client}
   * @param {!OrdersGetResponse} value OrdersGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetResponse serialization failed!')
    console.assert(this.OrdersGetResponseSenderModel.verify(), 'manager.OrdersGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllRequest value
   * @this {!Client}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetAllRequest serialization failed!')
    console.assert(this.OrdersGetAllRequestSenderModel.verify(), 'manager.OrdersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllResponse value
   * @this {!Client}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetAllResponse serialization failed!')
    console.assert(this.OrdersGetAllResponseSenderModel.verify(), 'manager.OrdersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCreateRequest value
   * @this {!Client}
   * @param {!OrderCreateRequest} value OrderCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCreateRequest serialization failed!')
    console.assert(this.OrderCreateRequestSenderModel.verify(), 'manager.OrderCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCreateResponse value
   * @this {!Client}
   * @param {!OrderCreateResponse} value OrderCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCreateResponse serialization failed!')
    console.assert(this.OrderCreateResponseSenderModel.verify(), 'manager.OrderCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderModifyRequest value
   * @this {!Client}
   * @param {!OrderModifyRequest} value OrderModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderModifyRequest serialization failed!')
    console.assert(this.OrderModifyRequestSenderModel.verify(), 'manager.OrderModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderModifyResponse value
   * @this {!Client}
   * @param {!OrderModifyResponse} value OrderModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderModifyResponse serialization failed!')
    console.assert(this.OrderModifyResponseSenderModel.verify(), 'manager.OrderModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderActivateRequest value
   * @this {!Client}
   * @param {!OrderActivateRequest} value OrderActivateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderActivateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderActivateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderActivateRequest serialization failed!')
    console.assert(this.OrderActivateRequestSenderModel.verify(), 'manager.OrderActivateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderActivateResponse value
   * @this {!Client}
   * @param {!OrderActivateResponse} value OrderActivateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderActivateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderActivateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderActivateResponse serialization failed!')
    console.assert(this.OrderActivateResponseSenderModel.verify(), 'manager.OrderActivateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelRequest value
   * @this {!Client}
   * @param {!OrderCancelRequest} value OrderCancelRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelRequest serialization failed!')
    console.assert(this.OrderCancelRequestSenderModel.verify(), 'manager.OrderCancelRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelResponse value
   * @this {!Client}
   * @param {!OrderCancelResponse} value OrderCancelResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelResponse serialization failed!')
    console.assert(this.OrderCancelResponseSenderModel.verify(), 'manager.OrderCancelResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllRequest value
   * @this {!Client}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelAllRequest serialization failed!')
    console.assert(this.OrderCancelAllRequestSenderModel.verify(), 'manager.OrderCancelAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllResponse value
   * @this {!Client}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelAllResponse serialization failed!')
    console.assert(this.OrderCancelAllResponseSenderModel.verify(), 'manager.OrderCancelAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllByIdRequest value
   * @this {!Client}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelAllByIdRequest serialization failed!')
    console.assert(this.OrderCancelAllByIdRequestSenderModel.verify(), 'manager.OrderCancelAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseRequest value
   * @this {!Client}
   * @param {!OrderCloseRequest} value OrderCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseRequest serialization failed!')
    console.assert(this.OrderCloseRequestSenderModel.verify(), 'manager.OrderCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseResponse value
   * @this {!Client}
   * @param {!OrderCloseResponse} value OrderCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseResponse serialization failed!')
    console.assert(this.OrderCloseResponseSenderModel.verify(), 'manager.OrderCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllRequest value
   * @this {!Client}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseAllRequest serialization failed!')
    console.assert(this.OrderCloseAllRequestSenderModel.verify(), 'manager.OrderCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllResponse value
   * @this {!Client}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseAllResponse serialization failed!')
    console.assert(this.OrderCloseAllResponseSenderModel.verify(), 'manager.OrderCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllByIdRequest value
   * @this {!Client}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseAllByIdRequest serialization failed!')
    console.assert(this.OrderCloseAllByIdRequestSenderModel.verify(), 'manager.OrderCloseAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByRequest value
   * @this {!Client}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByRequest serialization failed!')
    console.assert(this.OrderCloseByRequestSenderModel.verify(), 'manager.OrderCloseByRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByResponse value
   * @this {!Client}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByResponse serialization failed!')
    console.assert(this.OrderCloseByResponseSenderModel.verify(), 'manager.OrderCloseByResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllRequest value
   * @this {!Client}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByAllRequest serialization failed!')
    console.assert(this.OrderCloseByAllRequestSenderModel.verify(), 'manager.OrderCloseByAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllResponse value
   * @this {!Client}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCloseByAllResponse serialization failed!')
    console.assert(this.OrderCloseByAllResponseSenderModel.verify(), 'manager.OrderCloseByAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseRequest value
   * @this {!Client}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseRequestSenderModel.verify(), 'manager.OrderCancelOrCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseResponse value
   * @this {!Client}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseResponseSenderModel.verify(), 'manager.OrderCancelOrCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllRequest value
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseAllRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseAllRequestSenderModel.verify(), 'manager.OrderCancelOrCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllResponse value
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderCancelOrCloseAllResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseAllResponseSenderModel.verify(), 'manager.OrderCancelOrCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateNotify value
   * @this {!Client}
   * @param {!OrderUpdateNotify} value OrderUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderUpdateNotify serialization failed!')
    console.assert(this.OrderUpdateNotifySenderModel.verify(), 'manager.OrderUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionGetRequest value
   * @this {!Client}
   * @param {!PositionGetRequest} value PositionGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionGetRequest serialization failed!')
    console.assert(this.PositionGetRequestSenderModel.verify(), 'manager.PositionGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionGetResponse value
   * @this {!Client}
   * @param {!PositionGetResponse} value PositionGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionGetResponse serialization failed!')
    console.assert(this.PositionGetResponseSenderModel.verify(), 'manager.PositionGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetRequest value
   * @this {!Client}
   * @param {!PositionsGetRequest} value PositionsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetRequest serialization failed!')
    console.assert(this.PositionsGetRequestSenderModel.verify(), 'manager.PositionsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetResponse value
   * @this {!Client}
   * @param {!PositionsGetResponse} value PositionsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetResponse serialization failed!')
    console.assert(this.PositionsGetResponseSenderModel.verify(), 'manager.PositionsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllRequest value
   * @this {!Client}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetAllRequest serialization failed!')
    console.assert(this.PositionsGetAllRequestSenderModel.verify(), 'manager.PositionsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllResponse value
   * @this {!Client}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionsGetAllResponse serialization failed!')
    console.assert(this.PositionsGetAllResponseSenderModel.verify(), 'manager.PositionsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdateNotify value
   * @this {!Client}
   * @param {!PositionUpdateNotify} value PositionUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PositionUpdateNotify serialization failed!')
    console.assert(this.PositionUpdateNotifySenderModel.verify(), 'manager.PositionUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryRequest value
   * @this {!Client}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryRequest serialization failed!')
    console.assert(this.TradeHistoryRequestSenderModel.verify(), 'manager.TradeHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryResponse value
   * @this {!Client}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryResponse serialization failed!')
    console.assert(this.TradeHistoryResponseSenderModel.verify(), 'manager.TradeHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryFilterRequest value
   * @this {!Client}
   * @param {!TradeHistoryFilterRequest} value TradeHistoryFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryFilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryFilterRequest serialization failed!')
    console.assert(this.TradeHistoryFilterRequestSenderModel.verify(), 'manager.TradeHistoryFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdRequest value
   * @this {!Client}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryByIdRequest serialization failed!')
    console.assert(this.TradeHistoryByIdRequestSenderModel.verify(), 'manager.TradeHistoryByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdResponse value
   * @this {!Client}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryByIdResponse serialization failed!')
    console.assert(this.TradeHistoryByIdResponseSenderModel.verify(), 'manager.TradeHistoryByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryUpdateNotify value
   * @this {!Client}
   * @param {!TradeHistoryUpdateNotify} value TradeHistoryUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeHistoryUpdateNotify serialization failed!')
    console.assert(this.TradeHistoryUpdateNotifySenderModel.verify(), 'manager.TradeHistoryUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TestRolloverDumpRequest value
   * @this {!Client}
   * @param {!TestRolloverDumpRequest} value TestRolloverDumpRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TestRolloverDumpRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TestRolloverDumpRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TestRolloverDumpRequest serialization failed!')
    console.assert(this.TestRolloverDumpRequestSenderModel.verify(), 'manager.TestRolloverDumpRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TestRolloverDumpResponse value
   * @this {!Client}
   * @param {!TestRolloverDumpResponse} value TestRolloverDumpResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TestRolloverDumpResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TestRolloverDumpResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TestRolloverDumpResponse serialization failed!')
    console.assert(this.TestRolloverDumpResponseSenderModel.verify(), 'manager.TestRolloverDumpResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated001 value
   * @this {!Client}
   * @param {!Deprecated001} value Deprecated001 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated001 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated001SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated001 serialization failed!')
    console.assert(this.Deprecated001SenderModel.verify(), 'manager.Deprecated001 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated002 value
   * @this {!Client}
   * @param {!Deprecated002} value Deprecated002 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated002 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated002SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated002 serialization failed!')
    console.assert(this.Deprecated002SenderModel.verify(), 'manager.Deprecated002 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated003 value
   * @this {!Client}
   * @param {!Deprecated003} value Deprecated003 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated003 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated003SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated003 serialization failed!')
    console.assert(this.Deprecated003SenderModel.verify(), 'manager.Deprecated003 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated004 value
   * @this {!Client}
   * @param {!Deprecated004} value Deprecated004 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated004 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated004SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated004 serialization failed!')
    console.assert(this.Deprecated004SenderModel.verify(), 'manager.Deprecated004 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated005 value
   * @this {!Client}
   * @param {!Deprecated005} value Deprecated005 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated005 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated005SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated005 serialization failed!')
    console.assert(this.Deprecated005SenderModel.verify(), 'manager.Deprecated005 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated006 value
   * @this {!Client}
   * @param {!Deprecated006} value Deprecated006 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated006 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated006SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated006 serialization failed!')
    console.assert(this.Deprecated006SenderModel.verify(), 'manager.Deprecated006 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated007 value
   * @this {!Client}
   * @param {!Deprecated007} value Deprecated007 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated007 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated007SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated007 serialization failed!')
    console.assert(this.Deprecated007SenderModel.verify(), 'manager.Deprecated007 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated008 value
   * @this {!Client}
   * @param {!Deprecated008} value Deprecated008 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated008 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated008SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated008 serialization failed!')
    console.assert(this.Deprecated008SenderModel.verify(), 'manager.Deprecated008 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated009 value
   * @this {!Client}
   * @param {!Deprecated009} value Deprecated009 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated009 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated009SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated009 serialization failed!')
    console.assert(this.Deprecated009SenderModel.verify(), 'manager.Deprecated009 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated010 value
   * @this {!Client}
   * @param {!Deprecated010} value Deprecated010 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated010 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated010SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated010 serialization failed!')
    console.assert(this.Deprecated010SenderModel.verify(), 'manager.Deprecated010 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated011 value
   * @this {!Client}
   * @param {!Deprecated011} value Deprecated011 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated011 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated011SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated011 serialization failed!')
    console.assert(this.Deprecated011SenderModel.verify(), 'manager.Deprecated011 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated012 value
   * @this {!Client}
   * @param {!Deprecated012} value Deprecated012 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated012 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated012SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated012 serialization failed!')
    console.assert(this.Deprecated012SenderModel.verify(), 'manager.Deprecated012 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated013 value
   * @this {!Client}
   * @param {!Deprecated013} value Deprecated013 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated013 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated013SenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.Deprecated013 serialization failed!')
    console.assert(this.Deprecated013SenderModel.verify(), 'manager.Deprecated013 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositAccountRequest value
   * @this {!Client}
   * @param {!DepositAccountRequest} value DepositAccountRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DepositAccountRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositAccountRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DepositAccountRequest serialization failed!')
    console.assert(this.DepositAccountRequestSenderModel.verify(), 'manager.DepositAccountRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositAccountResponse value
   * @this {!Client}
   * @param {!DepositAccountResponse} value DepositAccountResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DepositAccountResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositAccountResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DepositAccountResponse serialization failed!')
    console.assert(this.DepositAccountResponseSenderModel.verify(), 'manager.DepositAccountResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CreditAccountRequest value
   * @this {!Client}
   * @param {!CreditAccountRequest} value CreditAccountRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CreditAccountRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CreditAccountRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CreditAccountRequest serialization failed!')
    console.assert(this.CreditAccountRequestSenderModel.verify(), 'manager.CreditAccountRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CreditAccountResponse value
   * @this {!Client}
   * @param {!CreditAccountResponse} value CreditAccountResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CreditAccountResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CreditAccountResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.CreditAccountResponse serialization failed!')
    console.assert(this.CreditAccountResponseSenderModel.verify(), 'manager.CreditAccountResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TransferMoneyRequest value
   * @this {!Client}
   * @param {!TransferMoneyRequest} value TransferMoneyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TransferMoneyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TransferMoneyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TransferMoneyRequest serialization failed!')
    console.assert(this.TransferMoneyRequestSenderModel.verify(), 'manager.TransferMoneyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TransferMoneyResponse value
   * @this {!Client}
   * @param {!TransferMoneyResponse} value TransferMoneyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TransferMoneyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TransferMoneyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TransferMoneyResponse serialization failed!')
    console.assert(this.TransferMoneyResponseSenderModel.verify(), 'manager.TransferMoneyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleGetRequest value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleGetRequest} value TradingCalendarRegularRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleGetRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleGetRequestSenderModel.verify(), 'manager.TradingCalendarRegularRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleGetResponse value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleGetResponse} value TradingCalendarRegularRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleGetResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleGetResponseSenderModel.verify(), 'manager.TradingCalendarRegularRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRulesGetAllRequest value
   * @this {!Client}
   * @param {!TradingCalendarRegularRulesGetAllRequest} value TradingCalendarRegularRulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRulesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRulesGetAllRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRulesGetAllRequestSenderModel.verify(), 'manager.TradingCalendarRegularRulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRulesGetAllResponse value
   * @this {!Client}
   * @param {!TradingCalendarRegularRulesGetAllResponse} value TradingCalendarRegularRulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRulesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRulesGetAllResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRulesGetAllResponseSenderModel.verify(), 'manager.TradingCalendarRegularRulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleCreateRequest value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleCreateRequest} value TradingCalendarRegularRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleCreateRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleCreateRequestSenderModel.verify(), 'manager.TradingCalendarRegularRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleCreateResponse value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleCreateResponse} value TradingCalendarRegularRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleCreateResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleCreateResponseSenderModel.verify(), 'manager.TradingCalendarRegularRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleModifyRequest value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleModifyRequest} value TradingCalendarRegularRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleModifyRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleModifyRequestSenderModel.verify(), 'manager.TradingCalendarRegularRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleModifyResponse value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleModifyResponse} value TradingCalendarRegularRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleModifyResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleModifyResponseSenderModel.verify(), 'manager.TradingCalendarRegularRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleDeleteRequest value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleDeleteRequest} value TradingCalendarRegularRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleDeleteRequest serialization failed!')
    console.assert(this.TradingCalendarRegularRuleDeleteRequestSenderModel.verify(), 'manager.TradingCalendarRegularRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleDeleteResponse value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleDeleteResponse} value TradingCalendarRegularRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleDeleteResponse serialization failed!')
    console.assert(this.TradingCalendarRegularRuleDeleteResponseSenderModel.verify(), 'manager.TradingCalendarRegularRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRegularRuleUpdateNotify value
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleUpdateNotify} value TradingCalendarRegularRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRegularRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRegularRuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRegularRuleUpdateNotify serialization failed!')
    console.assert(this.TradingCalendarRegularRuleUpdateNotifySenderModel.verify(), 'manager.TradingCalendarRegularRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleGetRequest value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleGetRequest} value TradingCalendarOffTimeRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleGetRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleGetRequestSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleGetResponse value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleGetResponse} value TradingCalendarOffTimeRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleGetResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleGetResponseSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRulesGetAllRequest value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRulesGetAllRequest} value TradingCalendarOffTimeRulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRulesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRulesGetAllRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRulesGetAllRequestSenderModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRulesGetAllResponse value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRulesGetAllResponse} value TradingCalendarOffTimeRulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRulesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRulesGetAllResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRulesGetAllResponseSenderModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleCreateRequest value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} value TradingCalendarOffTimeRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleCreateRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleCreateRequestSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleCreateResponse value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleCreateResponse} value TradingCalendarOffTimeRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleCreateResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleCreateResponseSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleModifyRequest value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} value TradingCalendarOffTimeRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleModifyRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleModifyRequestSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleModifyResponse value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleModifyResponse} value TradingCalendarOffTimeRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleModifyResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleModifyResponseSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleDeleteRequest value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleDeleteRequest} value TradingCalendarOffTimeRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleDeleteRequest serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleDeleteRequestSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleDeleteResponse value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleDeleteResponse} value TradingCalendarOffTimeRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleDeleteResponse serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleDeleteResponseSenderModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarOffTimeRuleUpdateNotify value
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleUpdateNotify} value TradingCalendarOffTimeRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarOffTimeRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarOffTimeRuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarOffTimeRuleUpdateNotify serialization failed!')
    console.assert(this.TradingCalendarOffTimeRuleUpdateNotifySenderModel.verify(), 'manager.TradingCalendarOffTimeRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRulesGetRequest value
   * @this {!Client}
   * @param {!TradingCalendarRulesGetRequest} value TradingCalendarRulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRulesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRulesGetRequest serialization failed!')
    console.assert(this.TradingCalendarRulesGetRequestSenderModel.verify(), 'manager.TradingCalendarRulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingCalendarRulesGetResponse value
   * @this {!Client}
   * @param {!TradingCalendarRulesGetResponse} value TradingCalendarRulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingCalendarRulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingCalendarRulesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingCalendarRulesGetResponse serialization failed!')
    console.assert(this.TradingCalendarRulesGetResponseSenderModel.verify(), 'manager.TradingCalendarRulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRulesGetRequest value
   * @this {!Client}
   * @param {!TradingRouteRulesGetRequest} value TradingRouteRulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRulesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRulesGetRequest serialization failed!')
    console.assert(this.TradingRouteRulesGetRequestSenderModel.verify(), 'manager.TradingRouteRulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRulesGetResponse value
   * @this {!Client}
   * @param {!TradingRouteRulesGetResponse} value TradingRouteRulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRulesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRulesGetResponse serialization failed!')
    console.assert(this.TradingRouteRulesGetResponseSenderModel.verify(), 'manager.TradingRouteRulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleCreateRequest value
   * @this {!Client}
   * @param {!TradingRouteRuleCreateRequest} value TradingRouteRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleCreateRequest serialization failed!')
    console.assert(this.TradingRouteRuleCreateRequestSenderModel.verify(), 'manager.TradingRouteRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleCreateResponse value
   * @this {!Client}
   * @param {!TradingRouteRuleCreateResponse} value TradingRouteRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleCreateResponse serialization failed!')
    console.assert(this.TradingRouteRuleCreateResponseSenderModel.verify(), 'manager.TradingRouteRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleModifyRequest value
   * @this {!Client}
   * @param {!TradingRouteRuleModifyRequest} value TradingRouteRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleModifyRequest serialization failed!')
    console.assert(this.TradingRouteRuleModifyRequestSenderModel.verify(), 'manager.TradingRouteRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleModifyResponse value
   * @this {!Client}
   * @param {!TradingRouteRuleModifyResponse} value TradingRouteRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleModifyResponse serialization failed!')
    console.assert(this.TradingRouteRuleModifyResponseSenderModel.verify(), 'manager.TradingRouteRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleDeleteRequest value
   * @this {!Client}
   * @param {!TradingRouteRuleDeleteRequest} value TradingRouteRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleDeleteRequest serialization failed!')
    console.assert(this.TradingRouteRuleDeleteRequestSenderModel.verify(), 'manager.TradingRouteRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleDeleteResponse value
   * @this {!Client}
   * @param {!TradingRouteRuleDeleteResponse} value TradingRouteRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleDeleteResponse serialization failed!')
    console.assert(this.TradingRouteRuleDeleteResponseSenderModel.verify(), 'manager.TradingRouteRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradingRouteRuleUpdateNotify value
   * @this {!Client}
   * @param {!TradingRouteRuleUpdateNotify} value TradingRouteRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradingRouteRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradingRouteRuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradingRouteRuleUpdateNotify serialization failed!')
    console.assert(this.TradingRouteRuleUpdateNotifySenderModel.verify(), 'manager.TradingRouteRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapsGetRequest value
   * @this {!Client}
   * @param {!SymbolMapsGetRequest} value SymbolMapsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapsGetRequest serialization failed!')
    console.assert(this.SymbolMapsGetRequestSenderModel.verify(), 'manager.SymbolMapsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapsGetResponse value
   * @this {!Client}
   * @param {!SymbolMapsGetResponse} value SymbolMapsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapsGetResponse serialization failed!')
    console.assert(this.SymbolMapsGetResponseSenderModel.verify(), 'manager.SymbolMapsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapCreateRequest value
   * @this {!Client}
   * @param {!SymbolMapCreateRequest} value SymbolMapCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapCreateRequest serialization failed!')
    console.assert(this.SymbolMapCreateRequestSenderModel.verify(), 'manager.SymbolMapCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapCreateResponse value
   * @this {!Client}
   * @param {!SymbolMapCreateResponse} value SymbolMapCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapCreateResponse serialization failed!')
    console.assert(this.SymbolMapCreateResponseSenderModel.verify(), 'manager.SymbolMapCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapModifyRequest value
   * @this {!Client}
   * @param {!SymbolMapModifyRequest} value SymbolMapModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapModifyRequest serialization failed!')
    console.assert(this.SymbolMapModifyRequestSenderModel.verify(), 'manager.SymbolMapModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapModifyResponse value
   * @this {!Client}
   * @param {!SymbolMapModifyResponse} value SymbolMapModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapModifyResponse serialization failed!')
    console.assert(this.SymbolMapModifyResponseSenderModel.verify(), 'manager.SymbolMapModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapDeleteRequest value
   * @this {!Client}
   * @param {!SymbolMapDeleteRequest} value SymbolMapDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapDeleteRequest serialization failed!')
    console.assert(this.SymbolMapDeleteRequestSenderModel.verify(), 'manager.SymbolMapDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapDeleteResponse value
   * @this {!Client}
   * @param {!SymbolMapDeleteResponse} value SymbolMapDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapDeleteResponse serialization failed!')
    console.assert(this.SymbolMapDeleteResponseSenderModel.verify(), 'manager.SymbolMapDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolMapUpdateNotify value
   * @this {!Client}
   * @param {!SymbolMapUpdateNotify} value SymbolMapUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolMapUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolMapUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SymbolMapUpdateNotify serialization failed!')
    console.assert(this.SymbolMapUpdateNotifySenderModel.verify(), 'manager.SymbolMapUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewaysGetRequest value
   * @this {!Client}
   * @param {!GatewaysGetRequest} value GatewaysGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewaysGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewaysGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewaysGetRequest serialization failed!')
    console.assert(this.GatewaysGetRequestSenderModel.verify(), 'manager.GatewaysGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewaysGetResponse value
   * @this {!Client}
   * @param {!GatewaysGetResponse} value GatewaysGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewaysGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewaysGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewaysGetResponse serialization failed!')
    console.assert(this.GatewaysGetResponseSenderModel.verify(), 'manager.GatewaysGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayCreateRequest value
   * @this {!Client}
   * @param {!GatewayCreateRequest} value GatewayCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayCreateRequest serialization failed!')
    console.assert(this.GatewayCreateRequestSenderModel.verify(), 'manager.GatewayCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayCreateResponse value
   * @this {!Client}
   * @param {!GatewayCreateResponse} value GatewayCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayCreateResponse serialization failed!')
    console.assert(this.GatewayCreateResponseSenderModel.verify(), 'manager.GatewayCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModifyRequest value
   * @this {!Client}
   * @param {!GatewayModifyRequest} value GatewayModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayModifyRequest serialization failed!')
    console.assert(this.GatewayModifyRequestSenderModel.verify(), 'manager.GatewayModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModifyResponse value
   * @this {!Client}
   * @param {!GatewayModifyResponse} value GatewayModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayModifyResponse serialization failed!')
    console.assert(this.GatewayModifyResponseSenderModel.verify(), 'manager.GatewayModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayDeleteRequest value
   * @this {!Client}
   * @param {!GatewayDeleteRequest} value GatewayDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayDeleteRequest serialization failed!')
    console.assert(this.GatewayDeleteRequestSenderModel.verify(), 'manager.GatewayDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayDeleteResponse value
   * @this {!Client}
   * @param {!GatewayDeleteResponse} value GatewayDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayDeleteResponse serialization failed!')
    console.assert(this.GatewayDeleteResponseSenderModel.verify(), 'manager.GatewayDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayUpdateNotify value
   * @this {!Client}
   * @param {!GatewayUpdateNotify} value GatewayUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.GatewayUpdateNotify serialization failed!')
    console.assert(this.GatewayUpdateNotifySenderModel.verify(), 'manager.GatewayUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedsGetRequest value
   * @this {!Client}
   * @param {!DataFeedsGetRequest} value DataFeedsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedsGetRequest serialization failed!')
    console.assert(this.DataFeedsGetRequestSenderModel.verify(), 'manager.DataFeedsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedsGetResponse value
   * @this {!Client}
   * @param {!DataFeedsGetResponse} value DataFeedsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedsGetResponse serialization failed!')
    console.assert(this.DataFeedsGetResponseSenderModel.verify(), 'manager.DataFeedsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedCreateRequest value
   * @this {!Client}
   * @param {!DataFeedCreateRequest} value DataFeedCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedCreateRequest serialization failed!')
    console.assert(this.DataFeedCreateRequestSenderModel.verify(), 'manager.DataFeedCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedCreateResponse value
   * @this {!Client}
   * @param {!DataFeedCreateResponse} value DataFeedCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedCreateResponse serialization failed!')
    console.assert(this.DataFeedCreateResponseSenderModel.verify(), 'manager.DataFeedCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModifyRequest value
   * @this {!Client}
   * @param {!DataFeedModifyRequest} value DataFeedModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedModifyRequest serialization failed!')
    console.assert(this.DataFeedModifyRequestSenderModel.verify(), 'manager.DataFeedModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModifyResponse value
   * @this {!Client}
   * @param {!DataFeedModifyResponse} value DataFeedModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedModifyResponse serialization failed!')
    console.assert(this.DataFeedModifyResponseSenderModel.verify(), 'manager.DataFeedModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedDeleteRequest value
   * @this {!Client}
   * @param {!DataFeedDeleteRequest} value DataFeedDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedDeleteRequest serialization failed!')
    console.assert(this.DataFeedDeleteRequestSenderModel.verify(), 'manager.DataFeedDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedDeleteResponse value
   * @this {!Client}
   * @param {!DataFeedDeleteResponse} value DataFeedDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedDeleteResponse serialization failed!')
    console.assert(this.DataFeedDeleteResponseSenderModel.verify(), 'manager.DataFeedDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedUpdateNotify value
   * @this {!Client}
   * @param {!DataFeedUpdateNotify} value DataFeedUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.DataFeedUpdateNotify serialization failed!')
    console.assert(this.DataFeedUpdateNotifySenderModel.verify(), 'manager.DataFeedUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleSystemInfoNotify value
   * @this {!Client}
   * @param {!ModuleSystemInfoNotify} value ModuleSystemInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleSystemInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleSystemInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleSystemInfoNotify serialization failed!')
    console.assert(this.ModuleSystemInfoNotifySenderModel.verify(), 'manager.ModuleSystemInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesSystemInfoGetAllRequest value
   * @this {!Client}
   * @param {!ModulesSystemInfoGetAllRequest} value ModulesSystemInfoGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesSystemInfoGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesSystemInfoGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesSystemInfoGetAllRequest serialization failed!')
    console.assert(this.ModulesSystemInfoGetAllRequestSenderModel.verify(), 'manager.ModulesSystemInfoGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesSystemInfoGetAllResponse value
   * @this {!Client}
   * @param {!ModulesSystemInfoGetAllResponse} value ModulesSystemInfoGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesSystemInfoGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesSystemInfoGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesSystemInfoGetAllResponse serialization failed!')
    console.assert(this.ModulesSystemInfoGetAllResponseSenderModel.verify(), 'manager.ModulesSystemInfoGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModulesSystemInfoNotify value
   * @this {!Client}
   * @param {!ModulesSystemInfoNotify} value ModulesSystemInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ModulesSystemInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModulesSystemInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModulesSystemInfoNotify serialization failed!')
    console.assert(this.ModulesSystemInfoNotifySenderModel.verify(), 'manager.ModulesSystemInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyRequest value
   * @this {!Client}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarHistoryModifyRequest serialization failed!')
    console.assert(this.ChartBarHistoryModifyRequestSenderModel.verify(), 'manager.ChartBarHistoryModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyResponse value
   * @this {!Client}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarHistoryModifyResponse serialization failed!')
    console.assert(this.ChartBarHistoryModifyResponseSenderModel.verify(), 'manager.ChartBarHistoryModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickSnapshotRequest value
   * @this {!Client}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickSnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickSnapshotRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickSnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateTickSnapshotRequestSenderModel.verify(), 'manager.FeedUpdateTickSnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickSnapshotResponse value
   * @this {!Client}
   * @param {!FeedUpdateTickSnapshotResponse} value FeedUpdateTickSnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickSnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickSnapshotResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickSnapshotResponse serialization failed!')
    console.assert(this.FeedUpdateTickSnapshotResponseSenderModel.verify(), 'manager.FeedUpdateTickSnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickRequest value
   * @this {!Client}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickRequest serialization failed!')
    console.assert(this.FeedUpdateTickRequestSenderModel.verify(), 'manager.FeedUpdateTickRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickResponse value
   * @this {!Client}
   * @param {!FeedUpdateTickResponse} value FeedUpdateTickResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateTickResponse serialization failed!')
    console.assert(this.FeedUpdateTickResponseSenderModel.verify(), 'manager.FeedUpdateTickResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotRequest value
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2SnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotRequestSenderModel.verify(), 'manager.FeedUpdateLevel2SnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotResponse value
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotResponse} value FeedUpdateLevel2SnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2SnapshotResponse serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotResponseSenderModel.verify(), 'manager.FeedUpdateLevel2SnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2Request value
   * @this {!Client}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2Request serialization failed!')
    console.assert(this.FeedUpdateLevel2RequestSenderModel.verify(), 'manager.FeedUpdateLevel2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2Response value
   * @this {!Client}
   * @param {!FeedUpdateLevel2Response} value FeedUpdateLevel2Response value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FeedUpdateLevel2Response serialization failed!')
    console.assert(this.FeedUpdateLevel2ResponseSenderModel.verify(), 'manager.FeedUpdateLevel2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerGetRequest value
   * @this {!Client}
   * @param {!MailServerGetRequest} value MailServerGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerGetRequest serialization failed!')
    console.assert(this.MailServerGetRequestSenderModel.verify(), 'manager.MailServerGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerGetResponse value
   * @this {!Client}
   * @param {!MailServerGetResponse} value MailServerGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerGetResponse serialization failed!')
    console.assert(this.MailServerGetResponseSenderModel.verify(), 'manager.MailServerGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServersGetAllRequest value
   * @this {!Client}
   * @param {!MailServersGetAllRequest} value MailServersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServersGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServersGetAllRequest serialization failed!')
    console.assert(this.MailServersGetAllRequestSenderModel.verify(), 'manager.MailServersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServersGetAllResponse value
   * @this {!Client}
   * @param {!MailServersGetAllResponse} value MailServersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServersGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServersGetAllResponse serialization failed!')
    console.assert(this.MailServersGetAllResponseSenderModel.verify(), 'manager.MailServersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerCreateRequest value
   * @this {!Client}
   * @param {!MailServerCreateRequest} value MailServerCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerCreateRequest serialization failed!')
    console.assert(this.MailServerCreateRequestSenderModel.verify(), 'manager.MailServerCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerCreateResponse value
   * @this {!Client}
   * @param {!MailServerCreateResponse} value MailServerCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerCreateResponse serialization failed!')
    console.assert(this.MailServerCreateResponseSenderModel.verify(), 'manager.MailServerCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerModifyRequest value
   * @this {!Client}
   * @param {!MailServerModifyRequest} value MailServerModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerModifyRequest serialization failed!')
    console.assert(this.MailServerModifyRequestSenderModel.verify(), 'manager.MailServerModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerModifyResponse value
   * @this {!Client}
   * @param {!MailServerModifyResponse} value MailServerModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerModifyResponse serialization failed!')
    console.assert(this.MailServerModifyResponseSenderModel.verify(), 'manager.MailServerModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerDeleteRequest value
   * @this {!Client}
   * @param {!MailServerDeleteRequest} value MailServerDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerDeleteRequest serialization failed!')
    console.assert(this.MailServerDeleteRequestSenderModel.verify(), 'manager.MailServerDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerDeleteResponse value
   * @this {!Client}
   * @param {!MailServerDeleteResponse} value MailServerDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerDeleteResponse serialization failed!')
    console.assert(this.MailServerDeleteResponseSenderModel.verify(), 'manager.MailServerDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MailServerUpdateNotify value
   * @this {!Client}
   * @param {!MailServerUpdateNotify} value MailServerUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MailServerUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MailServerUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MailServerUpdateNotify serialization failed!')
    console.assert(this.MailServerUpdateNotifySenderModel.verify(), 'manager.MailServerUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountStatesUpdateNotify value
   * @this {!Client}
   * @param {!AccountStatesUpdateNotify} value AccountStatesUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountStatesUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountStatesUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountStatesUpdateNotify serialization failed!')
    console.assert(this.AccountStatesUpdateNotifySenderModel.verify(), 'manager.AccountStatesUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountStatesGetAllRequest value
   * @this {!Client}
   * @param {!AccountStatesGetAllRequest} value AccountStatesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountStatesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountStatesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountStatesGetAllRequest serialization failed!')
    console.assert(this.AccountStatesGetAllRequestSenderModel.verify(), 'manager.AccountStatesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountStatesGetAllResponse value
   * @this {!Client}
   * @param {!AccountStatesGetAllResponse} value AccountStatesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountStatesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountStatesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountStatesGetAllResponse serialization failed!')
    console.assert(this.AccountStatesGetAllResponseSenderModel.verify(), 'manager.AccountStatesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleAuthRequest value
   * @this {!Client}
   * @param {!ModuleAuthRequest} value ModuleAuthRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleAuthRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleAuthRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleAuthRequest serialization failed!')
    console.assert(this.ModuleAuthRequestSenderModel.verify(), 'manager.ModuleAuthRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleAuthResponse value
   * @this {!Client}
   * @param {!ModuleAuthResponse} value ModuleAuthResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleAuthResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleAuthResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ModuleAuthResponse serialization failed!')
    console.assert(this.ModuleAuthResponseSenderModel.verify(), 'manager.ModuleAuthResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileGetRequest value
   * @this {!Client}
   * @param {!LeverageProfileGetRequest} value LeverageProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileGetRequest serialization failed!')
    console.assert(this.LeverageProfileGetRequestSenderModel.verify(), 'manager.LeverageProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileGetResponse value
   * @this {!Client}
   * @param {!LeverageProfileGetResponse} value LeverageProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileGetResponse serialization failed!')
    console.assert(this.LeverageProfileGetResponseSenderModel.verify(), 'manager.LeverageProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfilesGetAllRequest value
   * @this {!Client}
   * @param {!LeverageProfilesGetAllRequest} value LeverageProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfilesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfilesGetAllRequest serialization failed!')
    console.assert(this.LeverageProfilesGetAllRequestSenderModel.verify(), 'manager.LeverageProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfilesGetAllResponse value
   * @this {!Client}
   * @param {!LeverageProfilesGetAllResponse} value LeverageProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfilesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfilesGetAllResponse serialization failed!')
    console.assert(this.LeverageProfilesGetAllResponseSenderModel.verify(), 'manager.LeverageProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileCreateRequest value
   * @this {!Client}
   * @param {!LeverageProfileCreateRequest} value LeverageProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileCreateRequest serialization failed!')
    console.assert(this.LeverageProfileCreateRequestSenderModel.verify(), 'manager.LeverageProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileCreateResponse value
   * @this {!Client}
   * @param {!LeverageProfileCreateResponse} value LeverageProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileCreateResponse serialization failed!')
    console.assert(this.LeverageProfileCreateResponseSenderModel.verify(), 'manager.LeverageProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileModifyRequest value
   * @this {!Client}
   * @param {!LeverageProfileModifyRequest} value LeverageProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileModifyRequest serialization failed!')
    console.assert(this.LeverageProfileModifyRequestSenderModel.verify(), 'manager.LeverageProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileModifyResponse value
   * @this {!Client}
   * @param {!LeverageProfileModifyResponse} value LeverageProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileModifyResponse serialization failed!')
    console.assert(this.LeverageProfileModifyResponseSenderModel.verify(), 'manager.LeverageProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileDeleteRequest value
   * @this {!Client}
   * @param {!LeverageProfileDeleteRequest} value LeverageProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileDeleteRequest serialization failed!')
    console.assert(this.LeverageProfileDeleteRequestSenderModel.verify(), 'manager.LeverageProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileDeleteResponse value
   * @this {!Client}
   * @param {!LeverageProfileDeleteResponse} value LeverageProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileDeleteResponse serialization failed!')
    console.assert(this.LeverageProfileDeleteResponseSenderModel.verify(), 'manager.LeverageProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageProfileUpdateNotify value
   * @this {!Client}
   * @param {!LeverageProfileUpdateNotify} value LeverageProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageProfileUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LeverageProfileUpdateNotify serialization failed!')
    console.assert(this.LeverageProfileUpdateNotifySenderModel.verify(), 'manager.LeverageProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileGetRequest value
   * @this {!Client}
   * @param {!MarginRateProfileGetRequest} value MarginRateProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileGetRequest serialization failed!')
    console.assert(this.MarginRateProfileGetRequestSenderModel.verify(), 'manager.MarginRateProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileGetResponse value
   * @this {!Client}
   * @param {!MarginRateProfileGetResponse} value MarginRateProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileGetResponse serialization failed!')
    console.assert(this.MarginRateProfileGetResponseSenderModel.verify(), 'manager.MarginRateProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfilesGetAllRequest value
   * @this {!Client}
   * @param {!MarginRateProfilesGetAllRequest} value MarginRateProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfilesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfilesGetAllRequest serialization failed!')
    console.assert(this.MarginRateProfilesGetAllRequestSenderModel.verify(), 'manager.MarginRateProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfilesGetAllResponse value
   * @this {!Client}
   * @param {!MarginRateProfilesGetAllResponse} value MarginRateProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfilesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfilesGetAllResponse serialization failed!')
    console.assert(this.MarginRateProfilesGetAllResponseSenderModel.verify(), 'manager.MarginRateProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileCreateRequest value
   * @this {!Client}
   * @param {!MarginRateProfileCreateRequest} value MarginRateProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileCreateRequest serialization failed!')
    console.assert(this.MarginRateProfileCreateRequestSenderModel.verify(), 'manager.MarginRateProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileCreateResponse value
   * @this {!Client}
   * @param {!MarginRateProfileCreateResponse} value MarginRateProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileCreateResponse serialization failed!')
    console.assert(this.MarginRateProfileCreateResponseSenderModel.verify(), 'manager.MarginRateProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileModifyRequest value
   * @this {!Client}
   * @param {!MarginRateProfileModifyRequest} value MarginRateProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileModifyRequest serialization failed!')
    console.assert(this.MarginRateProfileModifyRequestSenderModel.verify(), 'manager.MarginRateProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileModifyResponse value
   * @this {!Client}
   * @param {!MarginRateProfileModifyResponse} value MarginRateProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileModifyResponse serialization failed!')
    console.assert(this.MarginRateProfileModifyResponseSenderModel.verify(), 'manager.MarginRateProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileDeleteRequest value
   * @this {!Client}
   * @param {!MarginRateProfileDeleteRequest} value MarginRateProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileDeleteRequest serialization failed!')
    console.assert(this.MarginRateProfileDeleteRequestSenderModel.verify(), 'manager.MarginRateProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileDeleteResponse value
   * @this {!Client}
   * @param {!MarginRateProfileDeleteResponse} value MarginRateProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileDeleteResponse serialization failed!')
    console.assert(this.MarginRateProfileDeleteResponseSenderModel.verify(), 'manager.MarginRateProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginRateProfileUpdateNotify value
   * @this {!Client}
   * @param {!MarginRateProfileUpdateNotify} value MarginRateProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginRateProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginRateProfileUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.MarginRateProfileUpdateNotify serialization failed!')
    console.assert(this.MarginRateProfileUpdateNotifySenderModel.verify(), 'manager.MarginRateProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileGetRequest value
   * @this {!Client}
   * @param {!NewsProfileGetRequest} value NewsProfileGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileGetRequest serialization failed!')
    console.assert(this.NewsProfileGetRequestSenderModel.verify(), 'manager.NewsProfileGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileGetResponse value
   * @this {!Client}
   * @param {!NewsProfileGetResponse} value NewsProfileGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileGetResponse serialization failed!')
    console.assert(this.NewsProfileGetResponseSenderModel.verify(), 'manager.NewsProfileGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfilesGetAllRequest value
   * @this {!Client}
   * @param {!NewsProfilesGetAllRequest} value NewsProfilesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfilesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfilesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfilesGetAllRequest serialization failed!')
    console.assert(this.NewsProfilesGetAllRequestSenderModel.verify(), 'manager.NewsProfilesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfilesGetAllResponse value
   * @this {!Client}
   * @param {!NewsProfilesGetAllResponse} value NewsProfilesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfilesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfilesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfilesGetAllResponse serialization failed!')
    console.assert(this.NewsProfilesGetAllResponseSenderModel.verify(), 'manager.NewsProfilesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileCreateRequest value
   * @this {!Client}
   * @param {!NewsProfileCreateRequest} value NewsProfileCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileCreateRequest serialization failed!')
    console.assert(this.NewsProfileCreateRequestSenderModel.verify(), 'manager.NewsProfileCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileCreateResponse value
   * @this {!Client}
   * @param {!NewsProfileCreateResponse} value NewsProfileCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileCreateResponse serialization failed!')
    console.assert(this.NewsProfileCreateResponseSenderModel.verify(), 'manager.NewsProfileCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileModifyRequest value
   * @this {!Client}
   * @param {!NewsProfileModifyRequest} value NewsProfileModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileModifyRequest serialization failed!')
    console.assert(this.NewsProfileModifyRequestSenderModel.verify(), 'manager.NewsProfileModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileModifyResponse value
   * @this {!Client}
   * @param {!NewsProfileModifyResponse} value NewsProfileModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileModifyResponse serialization failed!')
    console.assert(this.NewsProfileModifyResponseSenderModel.verify(), 'manager.NewsProfileModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileDeleteRequest value
   * @this {!Client}
   * @param {!NewsProfileDeleteRequest} value NewsProfileDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileDeleteRequest serialization failed!')
    console.assert(this.NewsProfileDeleteRequestSenderModel.verify(), 'manager.NewsProfileDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileDeleteResponse value
   * @this {!Client}
   * @param {!NewsProfileDeleteResponse} value NewsProfileDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileDeleteResponse serialization failed!')
    console.assert(this.NewsProfileDeleteResponseSenderModel.verify(), 'manager.NewsProfileDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileUpdateNotify value
   * @this {!Client}
   * @param {!NewsProfileUpdateNotify} value NewsProfileUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileUpdateNotify serialization failed!')
    console.assert(this.NewsProfileUpdateNotifySenderModel.verify(), 'manager.NewsProfileUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileImportRequest value
   * @this {!Client}
   * @param {!NewsProfileImportRequest} value NewsProfileImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileImportRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileImportRequest serialization failed!')
    console.assert(this.NewsProfileImportRequestSenderModel.verify(), 'manager.NewsProfileImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsProfileImportResponse value
   * @this {!Client}
   * @param {!NewsProfileImportResponse} value NewsProfileImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsProfileImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsProfileImportResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.NewsProfileImportResponse serialization failed!')
    console.assert(this.NewsProfileImportResponseSenderModel.verify(), 'manager.NewsProfileImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryRequest value
   * @this {!Client}
   * @param {!TickHistoryRequest} value TickHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TickHistoryRequest serialization failed!')
    console.assert(this.TickHistoryRequestSenderModel.verify(), 'manager.TickHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryResponse value
   * @this {!Client}
   * @param {!TickHistoryResponse} value TickHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TickHistoryResponse serialization failed!')
    console.assert(this.TickHistoryResponseSenderModel.verify(), 'manager.TickHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateGetRequest value
   * @this {!Client}
   * @param {!EmailTemplateGetRequest} value EmailTemplateGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateGetRequest serialization failed!')
    console.assert(this.EmailTemplateGetRequestSenderModel.verify(), 'manager.EmailTemplateGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateGetResponse value
   * @this {!Client}
   * @param {!EmailTemplateGetResponse} value EmailTemplateGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateGetResponse serialization failed!')
    console.assert(this.EmailTemplateGetResponseSenderModel.verify(), 'manager.EmailTemplateGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplatesGetAllRequest value
   * @this {!Client}
   * @param {!EmailTemplatesGetAllRequest} value EmailTemplatesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplatesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplatesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplatesGetAllRequest serialization failed!')
    console.assert(this.EmailTemplatesGetAllRequestSenderModel.verify(), 'manager.EmailTemplatesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplatesGetAllResponse value
   * @this {!Client}
   * @param {!EmailTemplatesGetAllResponse} value EmailTemplatesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplatesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplatesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplatesGetAllResponse serialization failed!')
    console.assert(this.EmailTemplatesGetAllResponseSenderModel.verify(), 'manager.EmailTemplatesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateCreateRequest value
   * @this {!Client}
   * @param {!EmailTemplateCreateRequest} value EmailTemplateCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateCreateRequest serialization failed!')
    console.assert(this.EmailTemplateCreateRequestSenderModel.verify(), 'manager.EmailTemplateCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateCreateResponse value
   * @this {!Client}
   * @param {!EmailTemplateCreateResponse} value EmailTemplateCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateCreateResponse serialization failed!')
    console.assert(this.EmailTemplateCreateResponseSenderModel.verify(), 'manager.EmailTemplateCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateModifyRequest value
   * @this {!Client}
   * @param {!EmailTemplateModifyRequest} value EmailTemplateModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateModifyRequest serialization failed!')
    console.assert(this.EmailTemplateModifyRequestSenderModel.verify(), 'manager.EmailTemplateModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateModifyResponse value
   * @this {!Client}
   * @param {!EmailTemplateModifyResponse} value EmailTemplateModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateModifyResponse serialization failed!')
    console.assert(this.EmailTemplateModifyResponseSenderModel.verify(), 'manager.EmailTemplateModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateDeleteRequest value
   * @this {!Client}
   * @param {!EmailTemplateDeleteRequest} value EmailTemplateDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateDeleteRequest serialization failed!')
    console.assert(this.EmailTemplateDeleteRequestSenderModel.verify(), 'manager.EmailTemplateDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateDeleteResponse value
   * @this {!Client}
   * @param {!EmailTemplateDeleteResponse} value EmailTemplateDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateDeleteResponse serialization failed!')
    console.assert(this.EmailTemplateDeleteResponseSenderModel.verify(), 'manager.EmailTemplateDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send EmailTemplateUpdateNotify value
   * @this {!Client}
   * @param {!EmailTemplateUpdateNotify} value EmailTemplateUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_EmailTemplateUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.EmailTemplateUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.EmailTemplateUpdateNotify serialization failed!')
    console.assert(this.EmailTemplateUpdateNotifySenderModel.verify(), 'manager.EmailTemplateUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateSubscribeRequest value
   * @this {!Client}
   * @param {!OrderStateSubscribeRequest} value OrderStateSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateSubscribeRequest serialization failed!')
    console.assert(this.OrderStateSubscribeRequestSenderModel.verify(), 'manager.OrderStateSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateSubscribeResponse value
   * @this {!Client}
   * @param {!OrderStateSubscribeResponse} value OrderStateSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateSubscribeResponse serialization failed!')
    console.assert(this.OrderStateSubscribeResponseSenderModel.verify(), 'manager.OrderStateSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUpdateSubscriptionRequest value
   * @this {!Client}
   * @param {!OrderStateUpdateSubscriptionRequest} value OrderStateUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUpdateSubscriptionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUpdateSubscriptionRequest serialization failed!')
    console.assert(this.OrderStateUpdateSubscriptionRequestSenderModel.verify(), 'manager.OrderStateUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUpdateSubscriptionResponse value
   * @this {!Client}
   * @param {!OrderStateUpdateSubscriptionResponse} value OrderStateUpdateSubscriptionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUpdateSubscriptionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUpdateSubscriptionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUpdateSubscriptionResponse serialization failed!')
    console.assert(this.OrderStateUpdateSubscriptionResponseSenderModel.verify(), 'manager.OrderStateUpdateSubscriptionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUnsubscribeRequest value
   * @this {!Client}
   * @param {!OrderStateUnsubscribeRequest} value OrderStateUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUnsubscribeRequest serialization failed!')
    console.assert(this.OrderStateUnsubscribeRequestSenderModel.verify(), 'manager.OrderStateUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateUnsubscribeResponse value
   * @this {!Client}
   * @param {!OrderStateUnsubscribeResponse} value OrderStateUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateUnsubscribeResponse serialization failed!')
    console.assert(this.OrderStateUnsubscribeResponseSenderModel.verify(), 'manager.OrderStateUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateNotify value
   * @this {!Client}
   * @param {!OrderStateNotify} value OrderStateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrderStateNotify serialization failed!')
    console.assert(this.OrderStateNotifySenderModel.verify(), 'manager.OrderStateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringSubscribeRequest value
   * @this {!Client}
   * @param {!RequestMonitoringSubscribeRequest} value RequestMonitoringSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringSubscribeRequest serialization failed!')
    console.assert(this.RequestMonitoringSubscribeRequestSenderModel.verify(), 'manager.RequestMonitoringSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringSubscribeResponse value
   * @this {!Client}
   * @param {!RequestMonitoringSubscribeResponse} value RequestMonitoringSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringSubscribeResponse serialization failed!')
    console.assert(this.RequestMonitoringSubscribeResponseSenderModel.verify(), 'manager.RequestMonitoringSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringUnsubscribeRequest value
   * @this {!Client}
   * @param {!RequestMonitoringUnsubscribeRequest} value RequestMonitoringUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringUnsubscribeRequest serialization failed!')
    console.assert(this.RequestMonitoringUnsubscribeRequestSenderModel.verify(), 'manager.RequestMonitoringUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringUnsubscribeResponse value
   * @this {!Client}
   * @param {!RequestMonitoringUnsubscribeResponse} value RequestMonitoringUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringUnsubscribeResponse serialization failed!')
    console.assert(this.RequestMonitoringUnsubscribeResponseSenderModel.verify(), 'manager.RequestMonitoringUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RequestMonitoringRequestUpdateNotify value
   * @this {!Client}
   * @param {!RequestMonitoringRequestUpdateNotify} value RequestMonitoringRequestUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_RequestMonitoringRequestUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestMonitoringRequestUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.RequestMonitoringRequestUpdateNotify serialization failed!')
    console.assert(this.RequestMonitoringRequestUpdateNotifySenderModel.verify(), 'manager.RequestMonitoringRequestUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoSubscribeRequest value
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoSubscribeRequest} value FilledOrdersSummaryInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoSubscribeRequest serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoSubscribeRequestSenderModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoSubscribeResponse value
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoSubscribeResponse} value FilledOrdersSummaryInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoSubscribeResponse serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoSubscribeResponseSenderModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoNotify value
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoNotify} value FilledOrdersSummaryInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoNotify serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoNotifySenderModel.verify(), 'manager.FilledOrdersSummaryInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoUpdateSubscriptionRequest value
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} value FilledOrdersSummaryInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoUpdateSubscriptionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoUpdateSubscriptionRequestSenderModel.verify(), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoUnsubscribeRequest value
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoUnsubscribeRequest} value FilledOrdersSummaryInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoUnsubscribeRequestSenderModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FilledOrdersSummaryInfoUnsubscribeResponse value
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoUnsubscribeResponse} value FilledOrdersSummaryInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FilledOrdersSummaryInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FilledOrdersSummaryInfoUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse serialization failed!')
    console.assert(this.FilledOrdersSummaryInfoUnsubscribeResponseSenderModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsNotify value
   * @this {!Client}
   * @param {!TradeEventsNotify} value TradeEventsNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeEventsNotify serialization failed!')
    console.assert(this.TradeEventsNotifySenderModel.verify(), 'manager.TradeEventsNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetRequest value
   * @this {!Client}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeEventsGetRequest serialization failed!')
    console.assert(this.TradeEventsGetRequestSenderModel.verify(), 'manager.TradeEventsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetResponse value
   * @this {!Client}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.TradeEventsGetResponse serialization failed!')
    console.assert(this.TradeEventsGetResponseSenderModel.verify(), 'manager.TradeEventsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalRequest value
   * @this {!Client}
   * @param {!JournalRequest} value JournalRequest value to send
   * @returns {!number} Sent bytes
   */
  send_JournalRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalRequest serialization failed!')
    console.assert(this.JournalRequestSenderModel.verify(), 'manager.JournalRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalResponse value
   * @this {!Client}
   * @param {!JournalResponse} value JournalResponse value to send
   * @returns {!number} Sent bytes
   */
  send_JournalResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalResponse serialization failed!')
    console.assert(this.JournalResponseSenderModel.verify(), 'manager.JournalResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetRequest value
   * @this {!Client}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsListGetRequest serialization failed!')
    console.assert(this.ClientReportsListGetRequestSenderModel.verify(), 'manager.ClientReportsListGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetResponse value
   * @this {!Client}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsListGetResponse serialization failed!')
    console.assert(this.ClientReportsListGetResponseSenderModel.verify(), 'manager.ClientReportsListGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetRequest value
   * @this {!Client}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportGetRequest serialization failed!')
    console.assert(this.ClientReportGetRequestSenderModel.verify(), 'manager.ClientReportGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetResponse value
   * @this {!Client}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportGetResponse serialization failed!')
    console.assert(this.ClientReportGetResponseSenderModel.verify(), 'manager.ClientReportGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendRequest value
   * @this {!Client}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsSendRequest serialization failed!')
    console.assert(this.ClientReportsSendRequestSenderModel.verify(), 'manager.ClientReportsSendRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendResponse value
   * @this {!Client}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientReportsSendResponse serialization failed!')
    console.assert(this.ClientReportsSendResponseSenderModel.verify(), 'manager.ClientReportsSendResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetRequest value
   * @this {!Client}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ProfitClosedGetRequest serialization failed!')
    console.assert(this.ProfitClosedGetRequestSenderModel.verify(), 'manager.ProfitClosedGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetResponse value
   * @this {!Client}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ProfitClosedGetResponse serialization failed!')
    console.assert(this.ProfitClosedGetResponseSenderModel.verify(), 'manager.ProfitClosedGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportRequest value
   * @this {!Client}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarsImportRequest serialization failed!')
    console.assert(this.ChartBarsImportRequestSenderModel.verify(), 'manager.ChartBarsImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportResponse value
   * @this {!Client}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarsImportResponse serialization failed!')
    console.assert(this.ChartBarsImportResponseSenderModel.verify(), 'manager.ChartBarsImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportNotify value
   * @this {!Client}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ChartBarsImportNotify serialization failed!')
    console.assert(this.ChartBarsImportNotifySenderModel.verify(), 'manager.ChartBarsImportNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalBusinessRequest value
   * @this {!Client}
   * @param {!JournalBusinessRequest} value JournalBusinessRequest value to send
   * @returns {!number} Sent bytes
   */
  send_JournalBusinessRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalBusinessRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalBusinessRequest serialization failed!')
    console.assert(this.JournalBusinessRequestSenderModel.verify(), 'manager.JournalBusinessRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send JournalBusinessResponse value
   * @this {!Client}
   * @param {!JournalBusinessResponse} value JournalBusinessResponse value to send
   * @returns {!number} Sent bytes
   */
  send_JournalBusinessResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.JournalBusinessResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.JournalBusinessResponse serialization failed!')
    console.assert(this.JournalBusinessResponseSenderModel.verify(), 'manager.JournalBusinessResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetPageRequest value
   * @this {!Client}
   * @param {!AccountsGetPageRequest} value AccountsGetPageRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetPageRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetPageRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetPageRequest serialization failed!')
    console.assert(this.AccountsGetPageRequestSenderModel.verify(), 'manager.AccountsGetPageRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetPageResponse value
   * @this {!Client}
   * @param {!AccountsGetPageResponse} value AccountsGetPageResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetPageResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetPageResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountsGetPageResponse serialization failed!')
    console.assert(this.AccountsGetPageResponseSenderModel.verify(), 'manager.AccountsGetPageResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HeartbeatNotify value
   * @this {!Client}
   * @param {!HeartbeatNotify} value HeartbeatNotify value to send
   * @returns {!number} Sent bytes
   */
  send_HeartbeatNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HeartbeatNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.HeartbeatNotify serialization failed!')
    console.assert(this.HeartbeatNotifySenderModel.verify(), 'manager.HeartbeatNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetRequest value
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersClosedSummariesGetRequest serialization failed!')
    console.assert(this.OrdersClosedSummariesGetRequestSenderModel.verify(), 'manager.OrdersClosedSummariesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetResponse value
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersClosedSummariesGetResponse serialization failed!')
    console.assert(this.OrdersClosedSummariesGetResponseSenderModel.verify(), 'manager.OrdersClosedSummariesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetPageRequest value
   * @this {!Client}
   * @param {!OrdersGetPageRequest} value OrdersGetPageRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetPageRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetPageRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetPageRequest serialization failed!')
    console.assert(this.OrdersGetPageRequestSenderModel.verify(), 'manager.OrdersGetPageRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetPageResponse value
   * @this {!Client}
   * @param {!OrdersGetPageResponse} value OrdersGetPageResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetPageResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetPageResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.OrdersGetPageResponse serialization failed!')
    console.assert(this.OrdersGetPageResponseSenderModel.verify(), 'manager.OrdersGetPageResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StorageUniversalNotify value
   * @this {!Client}
   * @param {!StorageUniversalNotify} value StorageUniversalNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StorageUniversalNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageUniversalNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.StorageUniversalNotify serialization failed!')
    console.assert(this.StorageUniversalNotifySenderModel.verify(), 'manager.StorageUniversalNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetRequest value
   * @this {!Client}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientLoginInfoGetRequest serialization failed!')
    console.assert(this.ClientLoginInfoGetRequestSenderModel.verify(), 'manager.ClientLoginInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetResponse value
   * @this {!Client}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.ClientLoginInfoGetResponse serialization failed!')
    console.assert(this.ClientLoginInfoGetResponseSenderModel.verify(), 'manager.ClientLoginInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupExistsGetRequest value
   * @this {!Client}
   * @param {!AccountGroupExistsGetRequest} value AccountGroupExistsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupExistsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupExistsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupExistsGetRequest serialization failed!')
    console.assert(this.AccountGroupExistsGetRequestSenderModel.verify(), 'manager.AccountGroupExistsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountGroupExistsGetResponse value
   * @this {!Client}
   * @param {!AccountGroupExistsGetResponse} value AccountGroupExistsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountGroupExistsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountGroupExistsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.AccountGroupExistsGetResponse serialization failed!')
    console.assert(this.AccountGroupExistsGetResponseSenderModel.verify(), 'manager.AccountGroupExistsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordRequest value
   * @this {!Client}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithPasswordRequest serialization failed!')
    console.assert(this.LoginWithPasswordRequestSenderModel.verify(), 'manager.LoginWithPasswordRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordResponse value
   * @this {!Client}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithPasswordResponse serialization failed!')
    console.assert(this.LoginWithPasswordResponseSenderModel.verify(), 'manager.LoginWithPasswordResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenRequest value
   * @this {!Client}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithTokenRequest serialization failed!')
    console.assert(this.LoginWithTokenRequestSenderModel.verify(), 'manager.LoginWithTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenResponse value
   * @this {!Client}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.LoginWithTokenResponse serialization failed!')
    console.assert(this.LoginWithTokenResponseSenderModel.verify(), 'manager.LoginWithTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenRequest value
   * @this {!Client}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.RefreshTokenRequest serialization failed!')
    console.assert(this.RefreshTokenRequestSenderModel.verify(), 'manager.RefreshTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenResponse value
   * @this {!Client}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.RefreshTokenResponse serialization failed!')
    console.assert(this.RefreshTokenResponseSenderModel.verify(), 'manager.RefreshTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonGetRequest value
   * @this {!Client}
   * @param {!PersonGetRequest} value PersonGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonGetRequest serialization failed!')
    console.assert(this.PersonGetRequestSenderModel.verify(), 'manager.PersonGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonGetResponse value
   * @this {!Client}
   * @param {!PersonGetResponse} value PersonGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonGetResponse serialization failed!')
    console.assert(this.PersonGetResponseSenderModel.verify(), 'manager.PersonGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllRequest value
   * @this {!Client}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonsGetAllRequest serialization failed!')
    console.assert(this.PersonsGetAllRequestSenderModel.verify(), 'manager.PersonsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllResponse value
   * @this {!Client}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonsGetAllResponse serialization failed!')
    console.assert(this.PersonsGetAllResponseSenderModel.verify(), 'manager.PersonsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonCreateRequest value
   * @this {!Client}
   * @param {!PersonCreateRequest} value PersonCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonCreateRequest serialization failed!')
    console.assert(this.PersonCreateRequestSenderModel.verify(), 'manager.PersonCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonCreateResponse value
   * @this {!Client}
   * @param {!PersonCreateResponse} value PersonCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonCreateResponse serialization failed!')
    console.assert(this.PersonCreateResponseSenderModel.verify(), 'manager.PersonCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonModifyRequest value
   * @this {!Client}
   * @param {!PersonModifyRequest} value PersonModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonModifyRequest serialization failed!')
    console.assert(this.PersonModifyRequestSenderModel.verify(), 'manager.PersonModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonModifyResponse value
   * @this {!Client}
   * @param {!PersonModifyResponse} value PersonModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonModifyResponse serialization failed!')
    console.assert(this.PersonModifyResponseSenderModel.verify(), 'manager.PersonModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateNotify value
   * @this {!Client}
   * @param {!PersonUpdateNotify} value PersonUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.PersonUpdateNotify serialization failed!')
    console.assert(this.PersonUpdateNotifySenderModel.verify(), 'manager.PersonUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetRequest value
   * @this {!Client}
   * @param {!SecuritySettingsGetRequest} value SecuritySettingsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetRequest serialization failed!')
    console.assert(this.SecuritySettingsGetRequestSenderModel.verify(), 'manager.SecuritySettingsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetResponse value
   * @this {!Client}
   * @param {!SecuritySettingsGetResponse} value SecuritySettingsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetResponse serialization failed!')
    console.assert(this.SecuritySettingsGetResponseSenderModel.verify(), 'manager.SecuritySettingsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetAllRequest value
   * @this {!Client}
   * @param {!SecuritySettingsGetAllRequest} value SecuritySettingsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetAllRequest serialization failed!')
    console.assert(this.SecuritySettingsGetAllRequestSenderModel.verify(), 'manager.SecuritySettingsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsGetAllResponse value
   * @this {!Client}
   * @param {!SecuritySettingsGetAllResponse} value SecuritySettingsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsGetAllResponse serialization failed!')
    console.assert(this.SecuritySettingsGetAllResponseSenderModel.verify(), 'manager.SecuritySettingsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsCreateRequest value
   * @this {!Client}
   * @param {!SecuritySettingsCreateRequest} value SecuritySettingsCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsCreateRequest serialization failed!')
    console.assert(this.SecuritySettingsCreateRequestSenderModel.verify(), 'manager.SecuritySettingsCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsCreateResponse value
   * @this {!Client}
   * @param {!SecuritySettingsCreateResponse} value SecuritySettingsCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsCreateResponse serialization failed!')
    console.assert(this.SecuritySettingsCreateResponseSenderModel.verify(), 'manager.SecuritySettingsCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsModifyRequest value
   * @this {!Client}
   * @param {!SecuritySettingsModifyRequest} value SecuritySettingsModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsModifyRequest serialization failed!')
    console.assert(this.SecuritySettingsModifyRequestSenderModel.verify(), 'manager.SecuritySettingsModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsModifyResponse value
   * @this {!Client}
   * @param {!SecuritySettingsModifyResponse} value SecuritySettingsModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsModifyResponse serialization failed!')
    console.assert(this.SecuritySettingsModifyResponseSenderModel.verify(), 'manager.SecuritySettingsModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsDeleteRequest value
   * @this {!Client}
   * @param {!SecuritySettingsDeleteRequest} value SecuritySettingsDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsDeleteRequest serialization failed!')
    console.assert(this.SecuritySettingsDeleteRequestSenderModel.verify(), 'manager.SecuritySettingsDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsDeleteResponse value
   * @this {!Client}
   * @param {!SecuritySettingsDeleteResponse} value SecuritySettingsDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsDeleteResponse serialization failed!')
    console.assert(this.SecuritySettingsDeleteResponseSenderModel.verify(), 'manager.SecuritySettingsDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SecuritySettingsUpdateNotify value
   * @this {!Client}
   * @param {!SecuritySettingsUpdateNotify} value SecuritySettingsUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SecuritySettingsUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SecuritySettingsUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.SecuritySettingsUpdateNotify serialization failed!')
    console.assert(this.SecuritySettingsUpdateNotifySenderModel.verify(), 'manager.SecuritySettingsUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleGetRequest value
   * @this {!Client}
   * @param {!FirewallRuleGetRequest} value FirewallRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleGetRequest serialization failed!')
    console.assert(this.FirewallRuleGetRequestSenderModel.verify(), 'manager.FirewallRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleGetResponse value
   * @this {!Client}
   * @param {!FirewallRuleGetResponse} value FirewallRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleGetResponse serialization failed!')
    console.assert(this.FirewallRuleGetResponseSenderModel.verify(), 'manager.FirewallRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRulesGetAllRequest value
   * @this {!Client}
   * @param {!FirewallRulesGetAllRequest} value FirewallRulesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRulesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRulesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRulesGetAllRequest serialization failed!')
    console.assert(this.FirewallRulesGetAllRequestSenderModel.verify(), 'manager.FirewallRulesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRulesGetAllResponse value
   * @this {!Client}
   * @param {!FirewallRulesGetAllResponse} value FirewallRulesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRulesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRulesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRulesGetAllResponse serialization failed!')
    console.assert(this.FirewallRulesGetAllResponseSenderModel.verify(), 'manager.FirewallRulesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleCreateRequest value
   * @this {!Client}
   * @param {!FirewallRuleCreateRequest} value FirewallRuleCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleCreateRequest serialization failed!')
    console.assert(this.FirewallRuleCreateRequestSenderModel.verify(), 'manager.FirewallRuleCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleCreateResponse value
   * @this {!Client}
   * @param {!FirewallRuleCreateResponse} value FirewallRuleCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleCreateResponse serialization failed!')
    console.assert(this.FirewallRuleCreateResponseSenderModel.verify(), 'manager.FirewallRuleCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleModifyRequest value
   * @this {!Client}
   * @param {!FirewallRuleModifyRequest} value FirewallRuleModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleModifyRequest serialization failed!')
    console.assert(this.FirewallRuleModifyRequestSenderModel.verify(), 'manager.FirewallRuleModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleModifyResponse value
   * @this {!Client}
   * @param {!FirewallRuleModifyResponse} value FirewallRuleModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleModifyResponse serialization failed!')
    console.assert(this.FirewallRuleModifyResponseSenderModel.verify(), 'manager.FirewallRuleModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleDeleteRequest value
   * @this {!Client}
   * @param {!FirewallRuleDeleteRequest} value FirewallRuleDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleDeleteRequest serialization failed!')
    console.assert(this.FirewallRuleDeleteRequestSenderModel.verify(), 'manager.FirewallRuleDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleDeleteResponse value
   * @this {!Client}
   * @param {!FirewallRuleDeleteResponse} value FirewallRuleDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleDeleteResponse serialization failed!')
    console.assert(this.FirewallRuleDeleteResponseSenderModel.verify(), 'manager.FirewallRuleDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FirewallRuleUpdateNotify value
   * @this {!Client}
   * @param {!FirewallRuleUpdateNotify} value FirewallRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FirewallRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FirewallRuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'manager.FirewallRuleUpdateNotify serialization failed!')
    console.assert(this.FirewallRuleUpdateNotifySenderModel.verify(), 'manager.FirewallRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'manager.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
    this._configClient.onSendHandler = handler
    this._historyClient.onSendHandler = handler
    this._clientClient.onSendHandler = handler
    this._feedClient.onSendHandler = handler
    this._storageClient.onSendHandler = handler
    this._exposedClient.onSendHandler = handler
    this._executionClient.onSendHandler = handler
    this._backupClient.onSendHandler = handler
    this._innerClient.onSendHandler = handler
    this._tradeClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
    this._configClient.onSendLogHandler = handler
    this._historyClient.onSendLogHandler = handler
    this._clientClient.onSendLogHandler = handler
    this._feedClient.onSendLogHandler = handler
    this._storageClient.onSendLogHandler = handler
    this._exposedClient.onSendLogHandler = handler
    this._executionClient.onSendLogHandler = handler
    this._backupClient.onSendLogHandler = handler
    this._innerClient.onSendLogHandler = handler
    this._tradeClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * PingModuleRequest receive handler
   * @this {!Client}
   * @param {!PingModuleRequest} value PingModuleRequest received value
   */
  onReceive_PingModuleRequest (value) {}  // eslint-disable-line

  /**
   * PongModuleResponse receive handler
   * @this {!Client}
   * @param {!PongModuleResponse} value PongModuleResponse received value
   */
  onReceive_PongModuleResponse (value) {}  // eslint-disable-line

  /**
   * PingModuleReject receive handler
   * @this {!Client}
   * @param {!PingModuleReject} value PingModuleReject received value
   */
  onReceive_PingModuleReject (value) {}  // eslint-disable-line

  /**
   * Deprecated3 receive handler
   * @this {!Client}
   * @param {!Deprecated3} value Deprecated3 received value
   */
  onReceive_Deprecated3 (value) {}  // eslint-disable-line

  /**
   * Deprecated4 receive handler
   * @this {!Client}
   * @param {!Deprecated4} value Deprecated4 received value
   */
  onReceive_Deprecated4 (value) {}  // eslint-disable-line

  /**
   * Deprecated1 receive handler
   * @this {!Client}
   * @param {!Deprecated1} value Deprecated1 received value
   */
  onReceive_Deprecated1 (value) {}  // eslint-disable-line

  /**
   * Deprecated2 receive handler
   * @this {!Client}
   * @param {!Deprecated2} value Deprecated2 received value
   */
  onReceive_Deprecated2 (value) {}  // eslint-disable-line

  /**
   * ManagerSessionInfoRequest receive handler
   * @this {!Client}
   * @param {!ManagerSessionInfoRequest} value ManagerSessionInfoRequest received value
   */
  onReceive_ManagerSessionInfoRequest (value) {}  // eslint-disable-line

  /**
   * ManagerSessionInfoResponse receive handler
   * @this {!Client}
   * @param {!ManagerSessionInfoResponse} value ManagerSessionInfoResponse received value
   */
  onReceive_ManagerSessionInfoResponse (value) {}  // eslint-disable-line

  /**
   * OnlineSessionsRequest receive handler
   * @this {!Client}
   * @param {!OnlineSessionsRequest} value OnlineSessionsRequest received value
   */
  onReceive_OnlineSessionsRequest (value) {}  // eslint-disable-line

  /**
   * OnlineSessionsResponse receive handler
   * @this {!Client}
   * @param {!OnlineSessionsResponse} value OnlineSessionsResponse received value
   */
  onReceive_OnlineSessionsResponse (value) {}  // eslint-disable-line

  /**
   * DropSessionRequest receive handler
   * @this {!Client}
   * @param {!DropSessionRequest} value DropSessionRequest received value
   */
  onReceive_DropSessionRequest (value) {}  // eslint-disable-line

  /**
   * DropSessionResponse receive handler
   * @this {!Client}
   * @param {!DropSessionResponse} value DropSessionResponse received value
   */
  onReceive_DropSessionResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRequest receive handler
   * @this {!Client}
   * @param {!ConfigRequest} value ConfigRequest received value
   */
  onReceive_ConfigRequest (value) {}  // eslint-disable-line

  /**
   * ConfigResponse receive handler
   * @this {!Client}
   * @param {!ConfigResponse} value ConfigResponse received value
   */
  onReceive_ConfigResponse (value) {}  // eslint-disable-line

  /**
   * ConfigReject receive handler
   * @this {!Client}
   * @param {!ConfigReject} value ConfigReject received value
   */
  onReceive_ConfigReject (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateRequest receive handler
   * @this {!Client}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest received value
   */
  onReceive_ConfigUpdateRequest (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateResponse receive handler
   * @this {!Client}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse received value
   */
  onReceive_ConfigUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateNotify receive handler
   * @this {!Client}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify received value
   */
  onReceive_ConfigUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ConfigVersionRequest receive handler
   * @this {!Client}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest received value
   */
  onReceive_ConfigVersionRequest (value) {}  // eslint-disable-line

  /**
   * ConfigVersionResponse receive handler
   * @this {!Client}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse received value
   */
  onReceive_ConfigVersionResponse (value) {}  // eslint-disable-line

  /**
   * ConfigExportRequest receive handler
   * @this {!Client}
   * @param {!ConfigExportRequest} value ConfigExportRequest received value
   */
  onReceive_ConfigExportRequest (value) {}  // eslint-disable-line

  /**
   * ConfigExportResponse receive handler
   * @this {!Client}
   * @param {!ConfigExportResponse} value ConfigExportResponse received value
   */
  onReceive_ConfigExportResponse (value) {}  // eslint-disable-line

  /**
   * ConfigImportRequest receive handler
   * @this {!Client}
   * @param {!ConfigImportRequest} value ConfigImportRequest received value
   */
  onReceive_ConfigImportRequest (value) {}  // eslint-disable-line

  /**
   * ConfigImportResponse receive handler
   * @this {!Client}
   * @param {!ConfigImportResponse} value ConfigImportResponse received value
   */
  onReceive_ConfigImportResponse (value) {}  // eslint-disable-line

  /**
   * ConfigValidateRequest receive handler
   * @this {!Client}
   * @param {!ConfigValidateRequest} value ConfigValidateRequest received value
   */
  onReceive_ConfigValidateRequest (value) {}  // eslint-disable-line

  /**
   * ConfigValidateResponse receive handler
   * @this {!Client}
   * @param {!ConfigValidateResponse} value ConfigValidateResponse received value
   */
  onReceive_ConfigValidateResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshRequest receive handler
   * @this {!Client}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest received value
   */
  onReceive_ConfigRefreshRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshResponse receive handler
   * @this {!Client}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse received value
   */
  onReceive_ConfigRefreshResponse (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryRequest receive handler
   * @this {!Client}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest received value
   */
  onReceive_ConfigHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryResponse receive handler
   * @this {!Client}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse received value
   */
  onReceive_ConfigHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackRequest receive handler
   * @this {!Client}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest received value
   */
  onReceive_ConfigRollbackRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackResponse receive handler
   * @this {!Client}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse received value
   */
  onReceive_ConfigRollbackResponse (value) {}  // eslint-disable-line

  /**
   * HostGetRequest receive handler
   * @this {!Client}
   * @param {!HostGetRequest} value HostGetRequest received value
   */
  onReceive_HostGetRequest (value) {}  // eslint-disable-line

  /**
   * HostGetResponse receive handler
   * @this {!Client}
   * @param {!HostGetResponse} value HostGetResponse received value
   */
  onReceive_HostGetResponse (value) {}  // eslint-disable-line

  /**
   * HostsGetAllRequest receive handler
   * @this {!Client}
   * @param {!HostsGetAllRequest} value HostsGetAllRequest received value
   */
  onReceive_HostsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * HostsGetAllResponse receive handler
   * @this {!Client}
   * @param {!HostsGetAllResponse} value HostsGetAllResponse received value
   */
  onReceive_HostsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * HostCreateRequest receive handler
   * @this {!Client}
   * @param {!HostCreateRequest} value HostCreateRequest received value
   */
  onReceive_HostCreateRequest (value) {}  // eslint-disable-line

  /**
   * HostCreateResponse receive handler
   * @this {!Client}
   * @param {!HostCreateResponse} value HostCreateResponse received value
   */
  onReceive_HostCreateResponse (value) {}  // eslint-disable-line

  /**
   * HostModifyRequest receive handler
   * @this {!Client}
   * @param {!HostModifyRequest} value HostModifyRequest received value
   */
  onReceive_HostModifyRequest (value) {}  // eslint-disable-line

  /**
   * HostModifyResponse receive handler
   * @this {!Client}
   * @param {!HostModifyResponse} value HostModifyResponse received value
   */
  onReceive_HostModifyResponse (value) {}  // eslint-disable-line

  /**
   * HostDeleteRequest receive handler
   * @this {!Client}
   * @param {!HostDeleteRequest} value HostDeleteRequest received value
   */
  onReceive_HostDeleteRequest (value) {}  // eslint-disable-line

  /**
   * HostDeleteResponse receive handler
   * @this {!Client}
   * @param {!HostDeleteResponse} value HostDeleteResponse received value
   */
  onReceive_HostDeleteResponse (value) {}  // eslint-disable-line

  /**
   * HostUpdateNotify receive handler
   * @this {!Client}
   * @param {!HostUpdateNotify} value HostUpdateNotify received value
   */
  onReceive_HostUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ModuleGetRequest receive handler
   * @this {!Client}
   * @param {!ModuleGetRequest} value ModuleGetRequest received value
   */
  onReceive_ModuleGetRequest (value) {}  // eslint-disable-line

  /**
   * ModuleGetResponse receive handler
   * @this {!Client}
   * @param {!ModuleGetResponse} value ModuleGetResponse received value
   */
  onReceive_ModuleGetResponse (value) {}  // eslint-disable-line

  /**
   * ModulesGetAllRequest receive handler
   * @this {!Client}
   * @param {!ModulesGetAllRequest} value ModulesGetAllRequest received value
   */
  onReceive_ModulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ModulesGetAllResponse receive handler
   * @this {!Client}
   * @param {!ModulesGetAllResponse} value ModulesGetAllResponse received value
   */
  onReceive_ModulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ModuleCreateRequest receive handler
   * @this {!Client}
   * @param {!ModuleCreateRequest} value ModuleCreateRequest received value
   */
  onReceive_ModuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * ModuleCreateResponse receive handler
   * @this {!Client}
   * @param {!ModuleCreateResponse} value ModuleCreateResponse received value
   */
  onReceive_ModuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * ModuleModifyRequest receive handler
   * @this {!Client}
   * @param {!ModuleModifyRequest} value ModuleModifyRequest received value
   */
  onReceive_ModuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * ModuleModifyResponse receive handler
   * @this {!Client}
   * @param {!ModuleModifyResponse} value ModuleModifyResponse received value
   */
  onReceive_ModuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * ModuleDeleteRequest receive handler
   * @this {!Client}
   * @param {!ModuleDeleteRequest} value ModuleDeleteRequest received value
   */
  onReceive_ModuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ModuleDeleteResponse receive handler
   * @this {!Client}
   * @param {!ModuleDeleteResponse} value ModuleDeleteResponse received value
   */
  onReceive_ModuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ModuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!ModuleUpdateNotify} value ModuleUpdateNotify received value
   */
  onReceive_ModuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ServerTimeGetRequest receive handler
   * @this {!Client}
   * @param {!ServerTimeGetRequest} value ServerTimeGetRequest received value
   */
  onReceive_ServerTimeGetRequest (value) {}  // eslint-disable-line

  /**
   * ServerTimeGetResponse receive handler
   * @this {!Client}
   * @param {!ServerTimeGetResponse} value ServerTimeGetResponse received value
   */
  onReceive_ServerTimeGetResponse (value) {}  // eslint-disable-line

  /**
   * ManagerPlatformTimezoneGetRequest receive handler
   * @this {!Client}
   * @param {!ManagerPlatformTimezoneGetRequest} value ManagerPlatformTimezoneGetRequest received value
   */
  onReceive_ManagerPlatformTimezoneGetRequest (value) {}  // eslint-disable-line

  /**
   * ManagerPlatformTimezoneGetResponse receive handler
   * @this {!Client}
   * @param {!ManagerPlatformTimezoneGetResponse} value ManagerPlatformTimezoneGetResponse received value
   */
  onReceive_ManagerPlatformTimezoneGetResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneGetRequest receive handler
   * @this {!Client}
   * @param {!TimezoneGetRequest} value TimezoneGetRequest received value
   */
  onReceive_TimezoneGetRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneGetResponse receive handler
   * @this {!Client}
   * @param {!TimezoneGetResponse} value TimezoneGetResponse received value
   */
  onReceive_TimezoneGetResponse (value) {}  // eslint-disable-line

  /**
   * TimezonesGetAllRequest receive handler
   * @this {!Client}
   * @param {!TimezonesGetAllRequest} value TimezonesGetAllRequest received value
   */
  onReceive_TimezonesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TimezonesGetAllResponse receive handler
   * @this {!Client}
   * @param {!TimezonesGetAllResponse} value TimezonesGetAllResponse received value
   */
  onReceive_TimezonesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneCreateRequest receive handler
   * @this {!Client}
   * @param {!TimezoneCreateRequest} value TimezoneCreateRequest received value
   */
  onReceive_TimezoneCreateRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneCreateResponse receive handler
   * @this {!Client}
   * @param {!TimezoneCreateResponse} value TimezoneCreateResponse received value
   */
  onReceive_TimezoneCreateResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneModifyRequest receive handler
   * @this {!Client}
   * @param {!TimezoneModifyRequest} value TimezoneModifyRequest received value
   */
  onReceive_TimezoneModifyRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneModifyResponse receive handler
   * @this {!Client}
   * @param {!TimezoneModifyResponse} value TimezoneModifyResponse received value
   */
  onReceive_TimezoneModifyResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneDeleteRequest receive handler
   * @this {!Client}
   * @param {!TimezoneDeleteRequest} value TimezoneDeleteRequest received value
   */
  onReceive_TimezoneDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneDeleteResponse receive handler
   * @this {!Client}
   * @param {!TimezoneDeleteResponse} value TimezoneDeleteResponse received value
   */
  onReceive_TimezoneDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneUpdateNotify receive handler
   * @this {!Client}
   * @param {!TimezoneUpdateNotify} value TimezoneUpdateNotify received value
   */
  onReceive_TimezoneUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest received value
   */
  onReceive_CurrencyGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse received value
   */
  onReceive_CurrencyGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest received value
   */
  onReceive_CurrencyGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse received value
   */
  onReceive_CurrencyGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupCreateRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGroupCreateRequest} value CurrencyGroupCreateRequest received value
   */
  onReceive_CurrencyGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupCreateResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGroupCreateResponse} value CurrencyGroupCreateResponse received value
   */
  onReceive_CurrencyGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupModifyRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGroupModifyRequest} value CurrencyGroupModifyRequest received value
   */
  onReceive_CurrencyGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupModifyResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGroupModifyResponse} value CurrencyGroupModifyResponse received value
   */
  onReceive_CurrencyGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupDeleteRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGroupDeleteRequest} value CurrencyGroupDeleteRequest received value
   */
  onReceive_CurrencyGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupDeleteResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGroupDeleteResponse} value CurrencyGroupDeleteResponse received value
   */
  onReceive_CurrencyGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupUpdateNotify receive handler
   * @this {!Client}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify received value
   */
  onReceive_CurrencyGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGetRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest received value
   */
  onReceive_CurrencyGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGetResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse received value
   */
  onReceive_CurrencyGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllRequest receive handler
   * @this {!Client}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest received value
   */
  onReceive_CurrenciesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllResponse receive handler
   * @this {!Client}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse received value
   */
  onReceive_CurrenciesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyCreateRequest receive handler
   * @this {!Client}
   * @param {!CurrencyCreateRequest} value CurrencyCreateRequest received value
   */
  onReceive_CurrencyCreateRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyCreateResponse receive handler
   * @this {!Client}
   * @param {!CurrencyCreateResponse} value CurrencyCreateResponse received value
   */
  onReceive_CurrencyCreateResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyModifyRequest receive handler
   * @this {!Client}
   * @param {!CurrencyModifyRequest} value CurrencyModifyRequest received value
   */
  onReceive_CurrencyModifyRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyModifyResponse receive handler
   * @this {!Client}
   * @param {!CurrencyModifyResponse} value CurrencyModifyResponse received value
   */
  onReceive_CurrencyModifyResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyDeleteRequest receive handler
   * @this {!Client}
   * @param {!CurrencyDeleteRequest} value CurrencyDeleteRequest received value
   */
  onReceive_CurrencyDeleteRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyDeleteResponse receive handler
   * @this {!Client}
   * @param {!CurrencyDeleteResponse} value CurrencyDeleteResponse received value
   */
  onReceive_CurrencyDeleteResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyUpdateNotify receive handler
   * @this {!Client}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify received value
   */
  onReceive_CurrencyUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetRequest receive handler
   * @this {!Client}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest received value
   */
  onReceive_SymbolGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetResponse receive handler
   * @this {!Client}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse received value
   */
  onReceive_SymbolGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllRequest receive handler
   * @this {!Client}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest received value
   */
  onReceive_SymbolGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllResponse receive handler
   * @this {!Client}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse received value
   */
  onReceive_SymbolGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupCreateRequest receive handler
   * @this {!Client}
   * @param {!SymbolGroupCreateRequest} value SymbolGroupCreateRequest received value
   */
  onReceive_SymbolGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupCreateResponse receive handler
   * @this {!Client}
   * @param {!SymbolGroupCreateResponse} value SymbolGroupCreateResponse received value
   */
  onReceive_SymbolGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupModifyRequest receive handler
   * @this {!Client}
   * @param {!SymbolGroupModifyRequest} value SymbolGroupModifyRequest received value
   */
  onReceive_SymbolGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupModifyResponse receive handler
   * @this {!Client}
   * @param {!SymbolGroupModifyResponse} value SymbolGroupModifyResponse received value
   */
  onReceive_SymbolGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupDeleteRequest receive handler
   * @this {!Client}
   * @param {!SymbolGroupDeleteRequest} value SymbolGroupDeleteRequest received value
   */
  onReceive_SymbolGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupDeleteResponse receive handler
   * @this {!Client}
   * @param {!SymbolGroupDeleteResponse} value SymbolGroupDeleteResponse received value
   */
  onReceive_SymbolGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify received value
   */
  onReceive_SymbolGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGetRequest receive handler
   * @this {!Client}
   * @param {!SymbolGetRequest} value SymbolGetRequest received value
   */
  onReceive_SymbolGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGetResponse receive handler
   * @this {!Client}
   * @param {!SymbolGetResponse} value SymbolGetResponse received value
   */
  onReceive_SymbolGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllRequest receive handler
   * @this {!Client}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest received value
   */
  onReceive_SymbolsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllResponse receive handler
   * @this {!Client}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse received value
   */
  onReceive_SymbolsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolCreateRequest receive handler
   * @this {!Client}
   * @param {!SymbolCreateRequest} value SymbolCreateRequest received value
   */
  onReceive_SymbolCreateRequest (value) {}  // eslint-disable-line

  /**
   * SymbolCreateResponse receive handler
   * @this {!Client}
   * @param {!SymbolCreateResponse} value SymbolCreateResponse received value
   */
  onReceive_SymbolCreateResponse (value) {}  // eslint-disable-line

  /**
   * SymbolModifyRequest receive handler
   * @this {!Client}
   * @param {!SymbolModifyRequest} value SymbolModifyRequest received value
   */
  onReceive_SymbolModifyRequest (value) {}  // eslint-disable-line

  /**
   * SymbolModifyResponse receive handler
   * @this {!Client}
   * @param {!SymbolModifyResponse} value SymbolModifyResponse received value
   */
  onReceive_SymbolModifyResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDeleteRequest receive handler
   * @this {!Client}
   * @param {!SymbolDeleteRequest} value SymbolDeleteRequest received value
   */
  onReceive_SymbolDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDeleteResponse receive handler
   * @this {!Client}
   * @param {!SymbolDeleteResponse} value SymbolDeleteResponse received value
   */
  onReceive_SymbolDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify received value
   */
  onReceive_SymbolUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SwapsExportRequest receive handler
   * @this {!Client}
   * @param {!SwapsExportRequest} value SwapsExportRequest received value
   */
  onReceive_SwapsExportRequest (value) {}  // eslint-disable-line

  /**
   * SwapsExportResponse receive handler
   * @this {!Client}
   * @param {!SwapsExportResponse} value SwapsExportResponse received value
   */
  onReceive_SwapsExportResponse (value) {}  // eslint-disable-line

  /**
   * SwapsImportRequest receive handler
   * @this {!Client}
   * @param {!SwapsImportRequest} value SwapsImportRequest received value
   */
  onReceive_SwapsImportRequest (value) {}  // eslint-disable-line

  /**
   * SwapsImportResponse receive handler
   * @this {!Client}
   * @param {!SwapsImportResponse} value SwapsImportResponse received value
   */
  onReceive_SwapsImportResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileGetRequest receive handler
   * @this {!Client}
   * @param {!CommissionProfileGetRequest} value CommissionProfileGetRequest received value
   */
  onReceive_CommissionProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileGetResponse receive handler
   * @this {!Client}
   * @param {!CommissionProfileGetResponse} value CommissionProfileGetResponse received value
   */
  onReceive_CommissionProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfilesGetAllRequest receive handler
   * @this {!Client}
   * @param {!CommissionProfilesGetAllRequest} value CommissionProfilesGetAllRequest received value
   */
  onReceive_CommissionProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfilesGetAllResponse receive handler
   * @this {!Client}
   * @param {!CommissionProfilesGetAllResponse} value CommissionProfilesGetAllResponse received value
   */
  onReceive_CommissionProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileCreateRequest receive handler
   * @this {!Client}
   * @param {!CommissionProfileCreateRequest} value CommissionProfileCreateRequest received value
   */
  onReceive_CommissionProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileCreateResponse receive handler
   * @this {!Client}
   * @param {!CommissionProfileCreateResponse} value CommissionProfileCreateResponse received value
   */
  onReceive_CommissionProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileModifyRequest receive handler
   * @this {!Client}
   * @param {!CommissionProfileModifyRequest} value CommissionProfileModifyRequest received value
   */
  onReceive_CommissionProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileModifyResponse receive handler
   * @this {!Client}
   * @param {!CommissionProfileModifyResponse} value CommissionProfileModifyResponse received value
   */
  onReceive_CommissionProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileDeleteRequest receive handler
   * @this {!Client}
   * @param {!CommissionProfileDeleteRequest} value CommissionProfileDeleteRequest received value
   */
  onReceive_CommissionProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * CommissionProfileDeleteResponse receive handler
   * @this {!Client}
   * @param {!CommissionProfileDeleteResponse} value CommissionProfileDeleteResponse received value
   */
  onReceive_CommissionProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * CommissionProfileUpdateNotify receive handler
   * @this {!Client}
   * @param {!CommissionProfileUpdateNotify} value CommissionProfileUpdateNotify received value
   */
  onReceive_CommissionProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarkupProfileGetRequest receive handler
   * @this {!Client}
   * @param {!MarkupProfileGetRequest} value MarkupProfileGetRequest received value
   */
  onReceive_MarkupProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileGetResponse receive handler
   * @this {!Client}
   * @param {!MarkupProfileGetResponse} value MarkupProfileGetResponse received value
   */
  onReceive_MarkupProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfilesGetAllRequest receive handler
   * @this {!Client}
   * @param {!MarkupProfilesGetAllRequest} value MarkupProfilesGetAllRequest received value
   */
  onReceive_MarkupProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfilesGetAllResponse receive handler
   * @this {!Client}
   * @param {!MarkupProfilesGetAllResponse} value MarkupProfilesGetAllResponse received value
   */
  onReceive_MarkupProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileCreateRequest receive handler
   * @this {!Client}
   * @param {!MarkupProfileCreateRequest} value MarkupProfileCreateRequest received value
   */
  onReceive_MarkupProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileCreateResponse receive handler
   * @this {!Client}
   * @param {!MarkupProfileCreateResponse} value MarkupProfileCreateResponse received value
   */
  onReceive_MarkupProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileModifyRequest receive handler
   * @this {!Client}
   * @param {!MarkupProfileModifyRequest} value MarkupProfileModifyRequest received value
   */
  onReceive_MarkupProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileModifyResponse receive handler
   * @this {!Client}
   * @param {!MarkupProfileModifyResponse} value MarkupProfileModifyResponse received value
   */
  onReceive_MarkupProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileDeleteRequest receive handler
   * @this {!Client}
   * @param {!MarkupProfileDeleteRequest} value MarkupProfileDeleteRequest received value
   */
  onReceive_MarkupProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * MarkupProfileDeleteResponse receive handler
   * @this {!Client}
   * @param {!MarkupProfileDeleteResponse} value MarkupProfileDeleteResponse received value
   */
  onReceive_MarkupProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MarkupProfileUpdateNotify receive handler
   * @this {!Client}
   * @param {!MarkupProfileUpdateNotify} value MarkupProfileUpdateNotify received value
   */
  onReceive_MarkupProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ManagerGroupGetRequest receive handler
   * @this {!Client}
   * @param {!ManagerGroupGetRequest} value ManagerGroupGetRequest received value
   */
  onReceive_ManagerGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupGetResponse receive handler
   * @this {!Client}
   * @param {!ManagerGroupGetResponse} value ManagerGroupGetResponse received value
   */
  onReceive_ManagerGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupsGetAllRequest receive handler
   * @this {!Client}
   * @param {!ManagerGroupsGetAllRequest} value ManagerGroupsGetAllRequest received value
   */
  onReceive_ManagerGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupsGetAllResponse receive handler
   * @this {!Client}
   * @param {!ManagerGroupsGetAllResponse} value ManagerGroupsGetAllResponse received value
   */
  onReceive_ManagerGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupCreateRequest receive handler
   * @this {!Client}
   * @param {!ManagerGroupCreateRequest} value ManagerGroupCreateRequest received value
   */
  onReceive_ManagerGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupCreateResponse receive handler
   * @this {!Client}
   * @param {!ManagerGroupCreateResponse} value ManagerGroupCreateResponse received value
   */
  onReceive_ManagerGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupModifyRequest receive handler
   * @this {!Client}
   * @param {!ManagerGroupModifyRequest} value ManagerGroupModifyRequest received value
   */
  onReceive_ManagerGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupModifyResponse receive handler
   * @this {!Client}
   * @param {!ManagerGroupModifyResponse} value ManagerGroupModifyResponse received value
   */
  onReceive_ManagerGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupDeleteRequest receive handler
   * @this {!Client}
   * @param {!ManagerGroupDeleteRequest} value ManagerGroupDeleteRequest received value
   */
  onReceive_ManagerGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGroupDeleteResponse receive handler
   * @this {!Client}
   * @param {!ManagerGroupDeleteResponse} value ManagerGroupDeleteResponse received value
   */
  onReceive_ManagerGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ManagerGroupUpdateNotify receive handler
   * @this {!Client}
   * @param {!ManagerGroupUpdateNotify} value ManagerGroupUpdateNotify received value
   */
  onReceive_ManagerGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ManagerGetRequest receive handler
   * @this {!Client}
   * @param {!ManagerGetRequest} value ManagerGetRequest received value
   */
  onReceive_ManagerGetRequest (value) {}  // eslint-disable-line

  /**
   * ManagerGetResponse receive handler
   * @this {!Client}
   * @param {!ManagerGetResponse} value ManagerGetResponse received value
   */
  onReceive_ManagerGetResponse (value) {}  // eslint-disable-line

  /**
   * ManagersGetAllRequest receive handler
   * @this {!Client}
   * @param {!ManagersGetAllRequest} value ManagersGetAllRequest received value
   */
  onReceive_ManagersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ManagersGetAllResponse receive handler
   * @this {!Client}
   * @param {!ManagersGetAllResponse} value ManagersGetAllResponse received value
   */
  onReceive_ManagersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ManagerCreateRequest receive handler
   * @this {!Client}
   * @param {!ManagerCreateRequest} value ManagerCreateRequest received value
   */
  onReceive_ManagerCreateRequest (value) {}  // eslint-disable-line

  /**
   * ManagerCreateResponse receive handler
   * @this {!Client}
   * @param {!ManagerCreateResponse} value ManagerCreateResponse received value
   */
  onReceive_ManagerCreateResponse (value) {}  // eslint-disable-line

  /**
   * ManagerModifyRequest receive handler
   * @this {!Client}
   * @param {!ManagerModifyRequest} value ManagerModifyRequest received value
   */
  onReceive_ManagerModifyRequest (value) {}  // eslint-disable-line

  /**
   * ManagerModifyResponse receive handler
   * @this {!Client}
   * @param {!ManagerModifyResponse} value ManagerModifyResponse received value
   */
  onReceive_ManagerModifyResponse (value) {}  // eslint-disable-line

  /**
   * ManagerDeleteRequest receive handler
   * @this {!Client}
   * @param {!ManagerDeleteRequest} value ManagerDeleteRequest received value
   */
  onReceive_ManagerDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ManagerDeleteResponse receive handler
   * @this {!Client}
   * @param {!ManagerDeleteResponse} value ManagerDeleteResponse received value
   */
  onReceive_ManagerDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ManagerUpdateNotify receive handler
   * @this {!Client}
   * @param {!ManagerUpdateNotify} value ManagerUpdateNotify received value
   */
  onReceive_ManagerUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PlatformGetRequest receive handler
   * @this {!Client}
   * @param {!PlatformGetRequest} value PlatformGetRequest received value
   */
  onReceive_PlatformGetRequest (value) {}  // eslint-disable-line

  /**
   * PlatformGetResponse receive handler
   * @this {!Client}
   * @param {!PlatformGetResponse} value PlatformGetResponse received value
   */
  onReceive_PlatformGetResponse (value) {}  // eslint-disable-line

  /**
   * PlatformsGetAllRequest receive handler
   * @this {!Client}
   * @param {!PlatformsGetAllRequest} value PlatformsGetAllRequest received value
   */
  onReceive_PlatformsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PlatformsGetAllResponse receive handler
   * @this {!Client}
   * @param {!PlatformsGetAllResponse} value PlatformsGetAllResponse received value
   */
  onReceive_PlatformsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PlatformCreateRequest receive handler
   * @this {!Client}
   * @param {!PlatformCreateRequest} value PlatformCreateRequest received value
   */
  onReceive_PlatformCreateRequest (value) {}  // eslint-disable-line

  /**
   * PlatformCreateResponse receive handler
   * @this {!Client}
   * @param {!PlatformCreateResponse} value PlatformCreateResponse received value
   */
  onReceive_PlatformCreateResponse (value) {}  // eslint-disable-line

  /**
   * PlatformModifyRequest receive handler
   * @this {!Client}
   * @param {!PlatformModifyRequest} value PlatformModifyRequest received value
   */
  onReceive_PlatformModifyRequest (value) {}  // eslint-disable-line

  /**
   * PlatformModifyResponse receive handler
   * @this {!Client}
   * @param {!PlatformModifyResponse} value PlatformModifyResponse received value
   */
  onReceive_PlatformModifyResponse (value) {}  // eslint-disable-line

  /**
   * PlatformDeleteRequest receive handler
   * @this {!Client}
   * @param {!PlatformDeleteRequest} value PlatformDeleteRequest received value
   */
  onReceive_PlatformDeleteRequest (value) {}  // eslint-disable-line

  /**
   * PlatformDeleteResponse receive handler
   * @this {!Client}
   * @param {!PlatformDeleteResponse} value PlatformDeleteResponse received value
   */
  onReceive_PlatformDeleteResponse (value) {}  // eslint-disable-line

  /**
   * PlatformUpdateNotify receive handler
   * @this {!Client}
   * @param {!PlatformUpdateNotify} value PlatformUpdateNotify received value
   */
  onReceive_PlatformUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountGroupGetRequest receive handler
   * @this {!Client}
   * @param {!AccountGroupGetRequest} value AccountGroupGetRequest received value
   */
  onReceive_AccountGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupGetResponse receive handler
   * @this {!Client}
   * @param {!AccountGroupGetResponse} value AccountGroupGetResponse received value
   */
  onReceive_AccountGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupsGetAllRequest receive handler
   * @this {!Client}
   * @param {!AccountGroupsGetAllRequest} value AccountGroupsGetAllRequest received value
   */
  onReceive_AccountGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupsGetAllResponse receive handler
   * @this {!Client}
   * @param {!AccountGroupsGetAllResponse} value AccountGroupsGetAllResponse received value
   */
  onReceive_AccountGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupCreateRequest receive handler
   * @this {!Client}
   * @param {!AccountGroupCreateRequest} value AccountGroupCreateRequest received value
   */
  onReceive_AccountGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupCreateResponse receive handler
   * @this {!Client}
   * @param {!AccountGroupCreateResponse} value AccountGroupCreateResponse received value
   */
  onReceive_AccountGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupModifyRequest receive handler
   * @this {!Client}
   * @param {!AccountGroupModifyRequest} value AccountGroupModifyRequest received value
   */
  onReceive_AccountGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupModifyResponse receive handler
   * @this {!Client}
   * @param {!AccountGroupModifyResponse} value AccountGroupModifyResponse received value
   */
  onReceive_AccountGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupDeleteRequest receive handler
   * @this {!Client}
   * @param {!AccountGroupDeleteRequest} value AccountGroupDeleteRequest received value
   */
  onReceive_AccountGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupDeleteResponse receive handler
   * @this {!Client}
   * @param {!AccountGroupDeleteResponse} value AccountGroupDeleteResponse received value
   */
  onReceive_AccountGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupUpdateNotify receive handler
   * @this {!Client}
   * @param {!AccountGroupUpdateNotify} value AccountGroupUpdateNotify received value
   */
  onReceive_AccountGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountGetRequest receive handler
   * @this {!Client}
   * @param {!AccountGetRequest} value AccountGetRequest received value
   */
  onReceive_AccountGetRequest (value) {}  // eslint-disable-line

  /**
   * AccountGetResponse receive handler
   * @this {!Client}
   * @param {!AccountGetResponse} value AccountGetResponse received value
   */
  onReceive_AccountGetResponse (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest receive handler
   * @this {!Client}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest received value
   */
  onReceive_AccountsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse receive handler
   * @this {!Client}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse received value
   */
  onReceive_AccountsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AccountCreateRequest receive handler
   * @this {!Client}
   * @param {!AccountCreateRequest} value AccountCreateRequest received value
   */
  onReceive_AccountCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountCreateResponse receive handler
   * @this {!Client}
   * @param {!AccountCreateResponse} value AccountCreateResponse received value
   */
  onReceive_AccountCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterCreateRequest receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingMasterCreateRequest} value AccountSocialTradingMasterCreateRequest received value
   */
  onReceive_AccountSocialTradingMasterCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterCreateResponse receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingMasterCreateResponse} value AccountSocialTradingMasterCreateResponse received value
   */
  onReceive_AccountSocialTradingMasterCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorCreateRequest receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorCreateRequest} value AccountSocialTradingInvestorCreateRequest received value
   */
  onReceive_AccountSocialTradingInvestorCreateRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorCreateResponse receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorCreateResponse} value AccountSocialTradingInvestorCreateResponse received value
   */
  onReceive_AccountSocialTradingInvestorCreateResponse (value) {}  // eslint-disable-line

  /**
   * AccountModifyRequest receive handler
   * @this {!Client}
   * @param {!AccountModifyRequest} value AccountModifyRequest received value
   */
  onReceive_AccountModifyRequest (value) {}  // eslint-disable-line

  /**
   * AccountModifyResponse receive handler
   * @this {!Client}
   * @param {!AccountModifyResponse} value AccountModifyResponse received value
   */
  onReceive_AccountModifyResponse (value) {}  // eslint-disable-line

  /**
   * AccountDeleteRequest receive handler
   * @this {!Client}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest received value
   */
  onReceive_AccountDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AccountDeleteResponse receive handler
   * @this {!Client}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse received value
   */
  onReceive_AccountDeleteResponse (value) {}  // eslint-disable-line

  /**
   * DemoAccountsDeleteRequest receive handler
   * @this {!Client}
   * @param {!DemoAccountsDeleteRequest} value DemoAccountsDeleteRequest received value
   */
  onReceive_DemoAccountsDeleteRequest (value) {}  // eslint-disable-line

  /**
   * DemoAccountsDeleteResponse receive handler
   * @this {!Client}
   * @param {!DemoAccountsDeleteResponse} value DemoAccountsDeleteResponse received value
   */
  onReceive_DemoAccountsDeleteResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingMasterRequest receive handler
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingMasterRequest} value AccountUpgradeToSocialTradingMasterRequest received value
   */
  onReceive_AccountUpgradeToSocialTradingMasterRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingMasterResponse receive handler
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingMasterResponse} value AccountUpgradeToSocialTradingMasterResponse received value
   */
  onReceive_AccountUpgradeToSocialTradingMasterResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingInvestorRequest receive handler
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingInvestorRequest} value AccountUpgradeToSocialTradingInvestorRequest received value
   */
  onReceive_AccountUpgradeToSocialTradingInvestorRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpgradeToSocialTradingInvestorResponse receive handler
   * @this {!Client}
   * @param {!AccountUpgradeToSocialTradingInvestorResponse} value AccountUpgradeToSocialTradingInvestorResponse received value
   */
  onReceive_AccountUpgradeToSocialTradingInvestorResponse (value) {}  // eslint-disable-line

  /**
   * SocialTradingInvestorAccountToRegularRequest receive handler
   * @this {!Client}
   * @param {!SocialTradingInvestorAccountToRegularRequest} value SocialTradingInvestorAccountToRegularRequest received value
   */
  onReceive_SocialTradingInvestorAccountToRegularRequest (value) {}  // eslint-disable-line

  /**
   * SocialTradingInvestorAccountToRegularResponse receive handler
   * @this {!Client}
   * @param {!SocialTradingInvestorAccountToRegularResponse} value SocialTradingInvestorAccountToRegularResponse received value
   */
  onReceive_SocialTradingInvestorAccountToRegularResponse (value) {}  // eslint-disable-line

  /**
   * SocialTradingMasterAccountToRegularRequest receive handler
   * @this {!Client}
   * @param {!SocialTradingMasterAccountToRegularRequest} value SocialTradingMasterAccountToRegularRequest received value
   */
  onReceive_SocialTradingMasterAccountToRegularRequest (value) {}  // eslint-disable-line

  /**
   * SocialTradingMasterAccountToRegularResponse receive handler
   * @this {!Client}
   * @param {!SocialTradingMasterAccountToRegularResponse} value SocialTradingMasterAccountToRegularResponse received value
   */
  onReceive_SocialTradingMasterAccountToRegularResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpdateNotify receive handler
   * @this {!Client}
   * @param {!AccountUpdateNotify} value AccountUpdateNotify received value
   */
  onReceive_AccountUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginGetRequest receive handler
   * @this {!Client}
   * @param {!MarginGetRequest} value MarginGetRequest received value
   */
  onReceive_MarginGetRequest (value) {}  // eslint-disable-line

  /**
   * MarginGetResponse receive handler
   * @this {!Client}
   * @param {!MarginGetResponse} value MarginGetResponse received value
   */
  onReceive_MarginGetResponse (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest receive handler
   * @this {!Client}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest received value
   */
  onReceive_MarginsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse receive handler
   * @this {!Client}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse received value
   */
  onReceive_MarginsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarginUpdateNotify receive handler
   * @this {!Client}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify received value
   */
  onReceive_MarginUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginCallNotify receive handler
   * @this {!Client}
   * @param {!MarginCallNotify} value MarginCallNotify received value
   */
  onReceive_MarginCallNotify (value) {}  // eslint-disable-line

  /**
   * StopOutNotify receive handler
   * @this {!Client}
   * @param {!StopOutNotify} value StopOutNotify received value
   */
  onReceive_StopOutNotify (value) {}  // eslint-disable-line

  /**
   * AssetGetRequest receive handler
   * @this {!Client}
   * @param {!AssetGetRequest} value AssetGetRequest received value
   */
  onReceive_AssetGetRequest (value) {}  // eslint-disable-line

  /**
   * AssetGetResponse receive handler
   * @this {!Client}
   * @param {!AssetGetResponse} value AssetGetResponse received value
   */
  onReceive_AssetGetResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetRequest receive handler
   * @this {!Client}
   * @param {!AssetsGetRequest} value AssetsGetRequest received value
   */
  onReceive_AssetsGetRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetResponse receive handler
   * @this {!Client}
   * @param {!AssetsGetResponse} value AssetsGetResponse received value
   */
  onReceive_AssetsGetResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest receive handler
   * @this {!Client}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest received value
   */
  onReceive_AssetsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse receive handler
   * @this {!Client}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse received value
   */
  onReceive_AssetsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AssetUpdateNotify receive handler
   * @this {!Client}
   * @param {!AssetUpdateNotify} value AssetUpdateNotify received value
   */
  onReceive_AssetUpdateNotify (value) {}  // eslint-disable-line

  /**
   * OrderGetRequest receive handler
   * @this {!Client}
   * @param {!OrderGetRequest} value OrderGetRequest received value
   */
  onReceive_OrderGetRequest (value) {}  // eslint-disable-line

  /**
   * OrderGetResponse receive handler
   * @this {!Client}
   * @param {!OrderGetResponse} value OrderGetResponse received value
   */
  onReceive_OrderGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetRequest receive handler
   * @this {!Client}
   * @param {!OrdersGetRequest} value OrdersGetRequest received value
   */
  onReceive_OrdersGetRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetResponse receive handler
   * @this {!Client}
   * @param {!OrdersGetResponse} value OrdersGetResponse received value
   */
  onReceive_OrdersGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest receive handler
   * @this {!Client}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest received value
   */
  onReceive_OrdersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse receive handler
   * @this {!Client}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse received value
   */
  onReceive_OrdersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCreateRequest receive handler
   * @this {!Client}
   * @param {!OrderCreateRequest} value OrderCreateRequest received value
   */
  onReceive_OrderCreateRequest (value) {}  // eslint-disable-line

  /**
   * OrderCreateResponse receive handler
   * @this {!Client}
   * @param {!OrderCreateResponse} value OrderCreateResponse received value
   */
  onReceive_OrderCreateResponse (value) {}  // eslint-disable-line

  /**
   * OrderModifyRequest receive handler
   * @this {!Client}
   * @param {!OrderModifyRequest} value OrderModifyRequest received value
   */
  onReceive_OrderModifyRequest (value) {}  // eslint-disable-line

  /**
   * OrderModifyResponse receive handler
   * @this {!Client}
   * @param {!OrderModifyResponse} value OrderModifyResponse received value
   */
  onReceive_OrderModifyResponse (value) {}  // eslint-disable-line

  /**
   * OrderActivateRequest receive handler
   * @this {!Client}
   * @param {!OrderActivateRequest} value OrderActivateRequest received value
   */
  onReceive_OrderActivateRequest (value) {}  // eslint-disable-line

  /**
   * OrderActivateResponse receive handler
   * @this {!Client}
   * @param {!OrderActivateResponse} value OrderActivateResponse received value
   */
  onReceive_OrderActivateResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelRequest} value OrderCancelRequest received value
   */
  onReceive_OrderCancelRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelResponse} value OrderCancelResponse received value
   */
  onReceive_OrderCancelResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest received value
   */
  onReceive_OrderCancelAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse received value
   */
  onReceive_OrderCancelAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllByIdRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest received value
   */
  onReceive_OrderCancelAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseRequest} value OrderCloseRequest received value
   */
  onReceive_OrderCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseResponse} value OrderCloseResponse received value
   */
  onReceive_OrderCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest received value
   */
  onReceive_OrderCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse received value
   */
  onReceive_OrderCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllByIdRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest received value
   */
  onReceive_OrderCloseAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest received value
   */
  onReceive_OrderCloseByRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse received value
   */
  onReceive_OrderCloseByResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest received value
   */
  onReceive_OrderCloseByAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse received value
   */
  onReceive_OrderCloseByAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest received value
   */
  onReceive_OrderCancelOrCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse received value
   */
  onReceive_OrderCancelOrCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest received value
   */
  onReceive_OrderCancelOrCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse received value
   */
  onReceive_OrderCancelOrCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateNotify receive handler
   * @this {!Client}
   * @param {!OrderUpdateNotify} value OrderUpdateNotify received value
   */
  onReceive_OrderUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PositionGetRequest receive handler
   * @this {!Client}
   * @param {!PositionGetRequest} value PositionGetRequest received value
   */
  onReceive_PositionGetRequest (value) {}  // eslint-disable-line

  /**
   * PositionGetResponse receive handler
   * @this {!Client}
   * @param {!PositionGetResponse} value PositionGetResponse received value
   */
  onReceive_PositionGetResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetRequest receive handler
   * @this {!Client}
   * @param {!PositionsGetRequest} value PositionsGetRequest received value
   */
  onReceive_PositionsGetRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetResponse receive handler
   * @this {!Client}
   * @param {!PositionsGetResponse} value PositionsGetResponse received value
   */
  onReceive_PositionsGetResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest receive handler
   * @this {!Client}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest received value
   */
  onReceive_PositionsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse receive handler
   * @this {!Client}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse received value
   */
  onReceive_PositionsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionUpdateNotify receive handler
   * @this {!Client}
   * @param {!PositionUpdateNotify} value PositionUpdateNotify received value
   */
  onReceive_PositionUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest received value
   */
  onReceive_TradeHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse received value
   */
  onReceive_TradeHistoryResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryFilterRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryFilterRequest} value TradeHistoryFilterRequest received value
   */
  onReceive_TradeHistoryFilterRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest received value
   */
  onReceive_TradeHistoryByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse received value
   */
  onReceive_TradeHistoryByIdResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryUpdateNotify receive handler
   * @this {!Client}
   * @param {!TradeHistoryUpdateNotify} value TradeHistoryUpdateNotify received value
   */
  onReceive_TradeHistoryUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TestRolloverDumpRequest receive handler
   * @this {!Client}
   * @param {!TestRolloverDumpRequest} value TestRolloverDumpRequest received value
   */
  onReceive_TestRolloverDumpRequest (value) {}  // eslint-disable-line

  /**
   * TestRolloverDumpResponse receive handler
   * @this {!Client}
   * @param {!TestRolloverDumpResponse} value TestRolloverDumpResponse received value
   */
  onReceive_TestRolloverDumpResponse (value) {}  // eslint-disable-line

  /**
   * Deprecated001 receive handler
   * @this {!Client}
   * @param {!Deprecated001} value Deprecated001 received value
   */
  onReceive_Deprecated001 (value) {}  // eslint-disable-line

  /**
   * Deprecated002 receive handler
   * @this {!Client}
   * @param {!Deprecated002} value Deprecated002 received value
   */
  onReceive_Deprecated002 (value) {}  // eslint-disable-line

  /**
   * Deprecated003 receive handler
   * @this {!Client}
   * @param {!Deprecated003} value Deprecated003 received value
   */
  onReceive_Deprecated003 (value) {}  // eslint-disable-line

  /**
   * Deprecated004 receive handler
   * @this {!Client}
   * @param {!Deprecated004} value Deprecated004 received value
   */
  onReceive_Deprecated004 (value) {}  // eslint-disable-line

  /**
   * Deprecated005 receive handler
   * @this {!Client}
   * @param {!Deprecated005} value Deprecated005 received value
   */
  onReceive_Deprecated005 (value) {}  // eslint-disable-line

  /**
   * Deprecated006 receive handler
   * @this {!Client}
   * @param {!Deprecated006} value Deprecated006 received value
   */
  onReceive_Deprecated006 (value) {}  // eslint-disable-line

  /**
   * Deprecated007 receive handler
   * @this {!Client}
   * @param {!Deprecated007} value Deprecated007 received value
   */
  onReceive_Deprecated007 (value) {}  // eslint-disable-line

  /**
   * Deprecated008 receive handler
   * @this {!Client}
   * @param {!Deprecated008} value Deprecated008 received value
   */
  onReceive_Deprecated008 (value) {}  // eslint-disable-line

  /**
   * Deprecated009 receive handler
   * @this {!Client}
   * @param {!Deprecated009} value Deprecated009 received value
   */
  onReceive_Deprecated009 (value) {}  // eslint-disable-line

  /**
   * Deprecated010 receive handler
   * @this {!Client}
   * @param {!Deprecated010} value Deprecated010 received value
   */
  onReceive_Deprecated010 (value) {}  // eslint-disable-line

  /**
   * Deprecated011 receive handler
   * @this {!Client}
   * @param {!Deprecated011} value Deprecated011 received value
   */
  onReceive_Deprecated011 (value) {}  // eslint-disable-line

  /**
   * Deprecated012 receive handler
   * @this {!Client}
   * @param {!Deprecated012} value Deprecated012 received value
   */
  onReceive_Deprecated012 (value) {}  // eslint-disable-line

  /**
   * Deprecated013 receive handler
   * @this {!Client}
   * @param {!Deprecated013} value Deprecated013 received value
   */
  onReceive_Deprecated013 (value) {}  // eslint-disable-line

  /**
   * DepositAccountRequest receive handler
   * @this {!Client}
   * @param {!DepositAccountRequest} value DepositAccountRequest received value
   */
  onReceive_DepositAccountRequest (value) {}  // eslint-disable-line

  /**
   * DepositAccountResponse receive handler
   * @this {!Client}
   * @param {!DepositAccountResponse} value DepositAccountResponse received value
   */
  onReceive_DepositAccountResponse (value) {}  // eslint-disable-line

  /**
   * CreditAccountRequest receive handler
   * @this {!Client}
   * @param {!CreditAccountRequest} value CreditAccountRequest received value
   */
  onReceive_CreditAccountRequest (value) {}  // eslint-disable-line

  /**
   * CreditAccountResponse receive handler
   * @this {!Client}
   * @param {!CreditAccountResponse} value CreditAccountResponse received value
   */
  onReceive_CreditAccountResponse (value) {}  // eslint-disable-line

  /**
   * TransferMoneyRequest receive handler
   * @this {!Client}
   * @param {!TransferMoneyRequest} value TransferMoneyRequest received value
   */
  onReceive_TransferMoneyRequest (value) {}  // eslint-disable-line

  /**
   * TransferMoneyResponse receive handler
   * @this {!Client}
   * @param {!TransferMoneyResponse} value TransferMoneyResponse received value
   */
  onReceive_TransferMoneyResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleGetRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleGetRequest} value TradingCalendarRegularRuleGetRequest received value
   */
  onReceive_TradingCalendarRegularRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleGetResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleGetResponse} value TradingCalendarRegularRuleGetResponse received value
   */
  onReceive_TradingCalendarRegularRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRulesGetAllRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRulesGetAllRequest} value TradingCalendarRegularRulesGetAllRequest received value
   */
  onReceive_TradingCalendarRegularRulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRulesGetAllResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRulesGetAllResponse} value TradingCalendarRegularRulesGetAllResponse received value
   */
  onReceive_TradingCalendarRegularRulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleCreateRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleCreateRequest} value TradingCalendarRegularRuleCreateRequest received value
   */
  onReceive_TradingCalendarRegularRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleCreateResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleCreateResponse} value TradingCalendarRegularRuleCreateResponse received value
   */
  onReceive_TradingCalendarRegularRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleModifyRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleModifyRequest} value TradingCalendarRegularRuleModifyRequest received value
   */
  onReceive_TradingCalendarRegularRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleModifyResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleModifyResponse} value TradingCalendarRegularRuleModifyResponse received value
   */
  onReceive_TradingCalendarRegularRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleDeleteRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleDeleteRequest} value TradingCalendarRegularRuleDeleteRequest received value
   */
  onReceive_TradingCalendarRegularRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleDeleteResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleDeleteResponse} value TradingCalendarRegularRuleDeleteResponse received value
   */
  onReceive_TradingCalendarRegularRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRegularRuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!TradingCalendarRegularRuleUpdateNotify} value TradingCalendarRegularRuleUpdateNotify received value
   */
  onReceive_TradingCalendarRegularRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleGetRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleGetRequest} value TradingCalendarOffTimeRuleGetRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleGetResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleGetResponse} value TradingCalendarOffTimeRuleGetResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRulesGetAllRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRulesGetAllRequest} value TradingCalendarOffTimeRulesGetAllRequest received value
   */
  onReceive_TradingCalendarOffTimeRulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRulesGetAllResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRulesGetAllResponse} value TradingCalendarOffTimeRulesGetAllResponse received value
   */
  onReceive_TradingCalendarOffTimeRulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleCreateRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} value TradingCalendarOffTimeRuleCreateRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleCreateResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleCreateResponse} value TradingCalendarOffTimeRuleCreateResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleModifyRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} value TradingCalendarOffTimeRuleModifyRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleModifyResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleModifyResponse} value TradingCalendarOffTimeRuleModifyResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleDeleteRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleDeleteRequest} value TradingCalendarOffTimeRuleDeleteRequest received value
   */
  onReceive_TradingCalendarOffTimeRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleDeleteResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleDeleteResponse} value TradingCalendarOffTimeRuleDeleteResponse received value
   */
  onReceive_TradingCalendarOffTimeRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradingCalendarOffTimeRuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!TradingCalendarOffTimeRuleUpdateNotify} value TradingCalendarOffTimeRuleUpdateNotify received value
   */
  onReceive_TradingCalendarOffTimeRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRulesGetRequest receive handler
   * @this {!Client}
   * @param {!TradingCalendarRulesGetRequest} value TradingCalendarRulesGetRequest received value
   */
  onReceive_TradingCalendarRulesGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingCalendarRulesGetResponse receive handler
   * @this {!Client}
   * @param {!TradingCalendarRulesGetResponse} value TradingCalendarRulesGetResponse received value
   */
  onReceive_TradingCalendarRulesGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRulesGetRequest receive handler
   * @this {!Client}
   * @param {!TradingRouteRulesGetRequest} value TradingRouteRulesGetRequest received value
   */
  onReceive_TradingRouteRulesGetRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRulesGetResponse receive handler
   * @this {!Client}
   * @param {!TradingRouteRulesGetResponse} value TradingRouteRulesGetResponse received value
   */
  onReceive_TradingRouteRulesGetResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleCreateRequest receive handler
   * @this {!Client}
   * @param {!TradingRouteRuleCreateRequest} value TradingRouteRuleCreateRequest received value
   */
  onReceive_TradingRouteRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleCreateResponse receive handler
   * @this {!Client}
   * @param {!TradingRouteRuleCreateResponse} value TradingRouteRuleCreateResponse received value
   */
  onReceive_TradingRouteRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleModifyRequest receive handler
   * @this {!Client}
   * @param {!TradingRouteRuleModifyRequest} value TradingRouteRuleModifyRequest received value
   */
  onReceive_TradingRouteRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleModifyResponse receive handler
   * @this {!Client}
   * @param {!TradingRouteRuleModifyResponse} value TradingRouteRuleModifyResponse received value
   */
  onReceive_TradingRouteRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleDeleteRequest receive handler
   * @this {!Client}
   * @param {!TradingRouteRuleDeleteRequest} value TradingRouteRuleDeleteRequest received value
   */
  onReceive_TradingRouteRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleDeleteResponse receive handler
   * @this {!Client}
   * @param {!TradingRouteRuleDeleteResponse} value TradingRouteRuleDeleteResponse received value
   */
  onReceive_TradingRouteRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradingRouteRuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!TradingRouteRuleUpdateNotify} value TradingRouteRuleUpdateNotify received value
   */
  onReceive_TradingRouteRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolMapsGetRequest receive handler
   * @this {!Client}
   * @param {!SymbolMapsGetRequest} value SymbolMapsGetRequest received value
   */
  onReceive_SymbolMapsGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapsGetResponse receive handler
   * @this {!Client}
   * @param {!SymbolMapsGetResponse} value SymbolMapsGetResponse received value
   */
  onReceive_SymbolMapsGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapCreateRequest receive handler
   * @this {!Client}
   * @param {!SymbolMapCreateRequest} value SymbolMapCreateRequest received value
   */
  onReceive_SymbolMapCreateRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapCreateResponse receive handler
   * @this {!Client}
   * @param {!SymbolMapCreateResponse} value SymbolMapCreateResponse received value
   */
  onReceive_SymbolMapCreateResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapModifyRequest receive handler
   * @this {!Client}
   * @param {!SymbolMapModifyRequest} value SymbolMapModifyRequest received value
   */
  onReceive_SymbolMapModifyRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapModifyResponse receive handler
   * @this {!Client}
   * @param {!SymbolMapModifyResponse} value SymbolMapModifyResponse received value
   */
  onReceive_SymbolMapModifyResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapDeleteRequest receive handler
   * @this {!Client}
   * @param {!SymbolMapDeleteRequest} value SymbolMapDeleteRequest received value
   */
  onReceive_SymbolMapDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SymbolMapDeleteResponse receive handler
   * @this {!Client}
   * @param {!SymbolMapDeleteResponse} value SymbolMapDeleteResponse received value
   */
  onReceive_SymbolMapDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SymbolMapUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolMapUpdateNotify} value SymbolMapUpdateNotify received value
   */
  onReceive_SymbolMapUpdateNotify (value) {}  // eslint-disable-line

  /**
   * GatewaysGetRequest receive handler
   * @this {!Client}
   * @param {!GatewaysGetRequest} value GatewaysGetRequest received value
   */
  onReceive_GatewaysGetRequest (value) {}  // eslint-disable-line

  /**
   * GatewaysGetResponse receive handler
   * @this {!Client}
   * @param {!GatewaysGetResponse} value GatewaysGetResponse received value
   */
  onReceive_GatewaysGetResponse (value) {}  // eslint-disable-line

  /**
   * GatewayCreateRequest receive handler
   * @this {!Client}
   * @param {!GatewayCreateRequest} value GatewayCreateRequest received value
   */
  onReceive_GatewayCreateRequest (value) {}  // eslint-disable-line

  /**
   * GatewayCreateResponse receive handler
   * @this {!Client}
   * @param {!GatewayCreateResponse} value GatewayCreateResponse received value
   */
  onReceive_GatewayCreateResponse (value) {}  // eslint-disable-line

  /**
   * GatewayModifyRequest receive handler
   * @this {!Client}
   * @param {!GatewayModifyRequest} value GatewayModifyRequest received value
   */
  onReceive_GatewayModifyRequest (value) {}  // eslint-disable-line

  /**
   * GatewayModifyResponse receive handler
   * @this {!Client}
   * @param {!GatewayModifyResponse} value GatewayModifyResponse received value
   */
  onReceive_GatewayModifyResponse (value) {}  // eslint-disable-line

  /**
   * GatewayDeleteRequest receive handler
   * @this {!Client}
   * @param {!GatewayDeleteRequest} value GatewayDeleteRequest received value
   */
  onReceive_GatewayDeleteRequest (value) {}  // eslint-disable-line

  /**
   * GatewayDeleteResponse receive handler
   * @this {!Client}
   * @param {!GatewayDeleteResponse} value GatewayDeleteResponse received value
   */
  onReceive_GatewayDeleteResponse (value) {}  // eslint-disable-line

  /**
   * GatewayUpdateNotify receive handler
   * @this {!Client}
   * @param {!GatewayUpdateNotify} value GatewayUpdateNotify received value
   */
  onReceive_GatewayUpdateNotify (value) {}  // eslint-disable-line

  /**
   * DataFeedsGetRequest receive handler
   * @this {!Client}
   * @param {!DataFeedsGetRequest} value DataFeedsGetRequest received value
   */
  onReceive_DataFeedsGetRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedsGetResponse receive handler
   * @this {!Client}
   * @param {!DataFeedsGetResponse} value DataFeedsGetResponse received value
   */
  onReceive_DataFeedsGetResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedCreateRequest receive handler
   * @this {!Client}
   * @param {!DataFeedCreateRequest} value DataFeedCreateRequest received value
   */
  onReceive_DataFeedCreateRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedCreateResponse receive handler
   * @this {!Client}
   * @param {!DataFeedCreateResponse} value DataFeedCreateResponse received value
   */
  onReceive_DataFeedCreateResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedModifyRequest receive handler
   * @this {!Client}
   * @param {!DataFeedModifyRequest} value DataFeedModifyRequest received value
   */
  onReceive_DataFeedModifyRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedModifyResponse receive handler
   * @this {!Client}
   * @param {!DataFeedModifyResponse} value DataFeedModifyResponse received value
   */
  onReceive_DataFeedModifyResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedDeleteRequest receive handler
   * @this {!Client}
   * @param {!DataFeedDeleteRequest} value DataFeedDeleteRequest received value
   */
  onReceive_DataFeedDeleteRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedDeleteResponse receive handler
   * @this {!Client}
   * @param {!DataFeedDeleteResponse} value DataFeedDeleteResponse received value
   */
  onReceive_DataFeedDeleteResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedUpdateNotify receive handler
   * @this {!Client}
   * @param {!DataFeedUpdateNotify} value DataFeedUpdateNotify received value
   */
  onReceive_DataFeedUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoNotify receive handler
   * @this {!Client}
   * @param {!ModuleSystemInfoNotify} value ModuleSystemInfoNotify received value
   */
  onReceive_ModuleSystemInfoNotify (value) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoGetAllRequest receive handler
   * @this {!Client}
   * @param {!ModulesSystemInfoGetAllRequest} value ModulesSystemInfoGetAllRequest received value
   */
  onReceive_ModulesSystemInfoGetAllRequest (value) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoGetAllResponse receive handler
   * @this {!Client}
   * @param {!ModulesSystemInfoGetAllResponse} value ModulesSystemInfoGetAllResponse received value
   */
  onReceive_ModulesSystemInfoGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ModulesSystemInfoNotify receive handler
   * @this {!Client}
   * @param {!ModulesSystemInfoNotify} value ModulesSystemInfoNotify received value
   */
  onReceive_ModulesSystemInfoNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyRequest receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest received value
   */
  onReceive_ChartBarHistoryModifyRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyResponse receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse received value
   */
  onReceive_ChartBarHistoryModifyResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotRequest receive handler
   * @this {!Client}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest received value
   */
  onReceive_FeedUpdateTickSnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotResponse receive handler
   * @this {!Client}
   * @param {!FeedUpdateTickSnapshotResponse} value FeedUpdateTickSnapshotResponse received value
   */
  onReceive_FeedUpdateTickSnapshotResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickRequest receive handler
   * @this {!Client}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest received value
   */
  onReceive_FeedUpdateTickRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickResponse receive handler
   * @this {!Client}
   * @param {!FeedUpdateTickResponse} value FeedUpdateTickResponse received value
   */
  onReceive_FeedUpdateTickResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotRequest receive handler
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest received value
   */
  onReceive_FeedUpdateLevel2SnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotResponse receive handler
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotResponse} value FeedUpdateLevel2SnapshotResponse received value
   */
  onReceive_FeedUpdateLevel2SnapshotResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Request receive handler
   * @this {!Client}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request received value
   */
  onReceive_FeedUpdateLevel2Request (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Response receive handler
   * @this {!Client}
   * @param {!FeedUpdateLevel2Response} value FeedUpdateLevel2Response received value
   */
  onReceive_FeedUpdateLevel2Response (value) {}  // eslint-disable-line

  /**
   * MailServerGetRequest receive handler
   * @this {!Client}
   * @param {!MailServerGetRequest} value MailServerGetRequest received value
   */
  onReceive_MailServerGetRequest (value) {}  // eslint-disable-line

  /**
   * MailServerGetResponse receive handler
   * @this {!Client}
   * @param {!MailServerGetResponse} value MailServerGetResponse received value
   */
  onReceive_MailServerGetResponse (value) {}  // eslint-disable-line

  /**
   * MailServersGetAllRequest receive handler
   * @this {!Client}
   * @param {!MailServersGetAllRequest} value MailServersGetAllRequest received value
   */
  onReceive_MailServersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MailServersGetAllResponse receive handler
   * @this {!Client}
   * @param {!MailServersGetAllResponse} value MailServersGetAllResponse received value
   */
  onReceive_MailServersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MailServerCreateRequest receive handler
   * @this {!Client}
   * @param {!MailServerCreateRequest} value MailServerCreateRequest received value
   */
  onReceive_MailServerCreateRequest (value) {}  // eslint-disable-line

  /**
   * MailServerCreateResponse receive handler
   * @this {!Client}
   * @param {!MailServerCreateResponse} value MailServerCreateResponse received value
   */
  onReceive_MailServerCreateResponse (value) {}  // eslint-disable-line

  /**
   * MailServerModifyRequest receive handler
   * @this {!Client}
   * @param {!MailServerModifyRequest} value MailServerModifyRequest received value
   */
  onReceive_MailServerModifyRequest (value) {}  // eslint-disable-line

  /**
   * MailServerModifyResponse receive handler
   * @this {!Client}
   * @param {!MailServerModifyResponse} value MailServerModifyResponse received value
   */
  onReceive_MailServerModifyResponse (value) {}  // eslint-disable-line

  /**
   * MailServerDeleteRequest receive handler
   * @this {!Client}
   * @param {!MailServerDeleteRequest} value MailServerDeleteRequest received value
   */
  onReceive_MailServerDeleteRequest (value) {}  // eslint-disable-line

  /**
   * MailServerDeleteResponse receive handler
   * @this {!Client}
   * @param {!MailServerDeleteResponse} value MailServerDeleteResponse received value
   */
  onReceive_MailServerDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MailServerUpdateNotify receive handler
   * @this {!Client}
   * @param {!MailServerUpdateNotify} value MailServerUpdateNotify received value
   */
  onReceive_MailServerUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountStatesUpdateNotify receive handler
   * @this {!Client}
   * @param {!AccountStatesUpdateNotify} value AccountStatesUpdateNotify received value
   */
  onReceive_AccountStatesUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountStatesGetAllRequest receive handler
   * @this {!Client}
   * @param {!AccountStatesGetAllRequest} value AccountStatesGetAllRequest received value
   */
  onReceive_AccountStatesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountStatesGetAllResponse receive handler
   * @this {!Client}
   * @param {!AccountStatesGetAllResponse} value AccountStatesGetAllResponse received value
   */
  onReceive_AccountStatesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * ModuleAuthRequest receive handler
   * @this {!Client}
   * @param {!ModuleAuthRequest} value ModuleAuthRequest received value
   */
  onReceive_ModuleAuthRequest (value) {}  // eslint-disable-line

  /**
   * ModuleAuthResponse receive handler
   * @this {!Client}
   * @param {!ModuleAuthResponse} value ModuleAuthResponse received value
   */
  onReceive_ModuleAuthResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileGetRequest receive handler
   * @this {!Client}
   * @param {!LeverageProfileGetRequest} value LeverageProfileGetRequest received value
   */
  onReceive_LeverageProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileGetResponse receive handler
   * @this {!Client}
   * @param {!LeverageProfileGetResponse} value LeverageProfileGetResponse received value
   */
  onReceive_LeverageProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfilesGetAllRequest receive handler
   * @this {!Client}
   * @param {!LeverageProfilesGetAllRequest} value LeverageProfilesGetAllRequest received value
   */
  onReceive_LeverageProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfilesGetAllResponse receive handler
   * @this {!Client}
   * @param {!LeverageProfilesGetAllResponse} value LeverageProfilesGetAllResponse received value
   */
  onReceive_LeverageProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileCreateRequest receive handler
   * @this {!Client}
   * @param {!LeverageProfileCreateRequest} value LeverageProfileCreateRequest received value
   */
  onReceive_LeverageProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileCreateResponse receive handler
   * @this {!Client}
   * @param {!LeverageProfileCreateResponse} value LeverageProfileCreateResponse received value
   */
  onReceive_LeverageProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileModifyRequest receive handler
   * @this {!Client}
   * @param {!LeverageProfileModifyRequest} value LeverageProfileModifyRequest received value
   */
  onReceive_LeverageProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileModifyResponse receive handler
   * @this {!Client}
   * @param {!LeverageProfileModifyResponse} value LeverageProfileModifyResponse received value
   */
  onReceive_LeverageProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileDeleteRequest receive handler
   * @this {!Client}
   * @param {!LeverageProfileDeleteRequest} value LeverageProfileDeleteRequest received value
   */
  onReceive_LeverageProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * LeverageProfileDeleteResponse receive handler
   * @this {!Client}
   * @param {!LeverageProfileDeleteResponse} value LeverageProfileDeleteResponse received value
   */
  onReceive_LeverageProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * LeverageProfileUpdateNotify receive handler
   * @this {!Client}
   * @param {!LeverageProfileUpdateNotify} value LeverageProfileUpdateNotify received value
   */
  onReceive_LeverageProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileGetRequest receive handler
   * @this {!Client}
   * @param {!MarginRateProfileGetRequest} value MarginRateProfileGetRequest received value
   */
  onReceive_MarginRateProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileGetResponse receive handler
   * @this {!Client}
   * @param {!MarginRateProfileGetResponse} value MarginRateProfileGetResponse received value
   */
  onReceive_MarginRateProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfilesGetAllRequest receive handler
   * @this {!Client}
   * @param {!MarginRateProfilesGetAllRequest} value MarginRateProfilesGetAllRequest received value
   */
  onReceive_MarginRateProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfilesGetAllResponse receive handler
   * @this {!Client}
   * @param {!MarginRateProfilesGetAllResponse} value MarginRateProfilesGetAllResponse received value
   */
  onReceive_MarginRateProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileCreateRequest receive handler
   * @this {!Client}
   * @param {!MarginRateProfileCreateRequest} value MarginRateProfileCreateRequest received value
   */
  onReceive_MarginRateProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileCreateResponse receive handler
   * @this {!Client}
   * @param {!MarginRateProfileCreateResponse} value MarginRateProfileCreateResponse received value
   */
  onReceive_MarginRateProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileModifyRequest receive handler
   * @this {!Client}
   * @param {!MarginRateProfileModifyRequest} value MarginRateProfileModifyRequest received value
   */
  onReceive_MarginRateProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileModifyResponse receive handler
   * @this {!Client}
   * @param {!MarginRateProfileModifyResponse} value MarginRateProfileModifyResponse received value
   */
  onReceive_MarginRateProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileDeleteRequest receive handler
   * @this {!Client}
   * @param {!MarginRateProfileDeleteRequest} value MarginRateProfileDeleteRequest received value
   */
  onReceive_MarginRateProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileDeleteResponse receive handler
   * @this {!Client}
   * @param {!MarginRateProfileDeleteResponse} value MarginRateProfileDeleteResponse received value
   */
  onReceive_MarginRateProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MarginRateProfileUpdateNotify receive handler
   * @this {!Client}
   * @param {!MarginRateProfileUpdateNotify} value MarginRateProfileUpdateNotify received value
   */
  onReceive_MarginRateProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * NewsProfileGetRequest receive handler
   * @this {!Client}
   * @param {!NewsProfileGetRequest} value NewsProfileGetRequest received value
   */
  onReceive_NewsProfileGetRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileGetResponse receive handler
   * @this {!Client}
   * @param {!NewsProfileGetResponse} value NewsProfileGetResponse received value
   */
  onReceive_NewsProfileGetResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfilesGetAllRequest receive handler
   * @this {!Client}
   * @param {!NewsProfilesGetAllRequest} value NewsProfilesGetAllRequest received value
   */
  onReceive_NewsProfilesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfilesGetAllResponse receive handler
   * @this {!Client}
   * @param {!NewsProfilesGetAllResponse} value NewsProfilesGetAllResponse received value
   */
  onReceive_NewsProfilesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileCreateRequest receive handler
   * @this {!Client}
   * @param {!NewsProfileCreateRequest} value NewsProfileCreateRequest received value
   */
  onReceive_NewsProfileCreateRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileCreateResponse receive handler
   * @this {!Client}
   * @param {!NewsProfileCreateResponse} value NewsProfileCreateResponse received value
   */
  onReceive_NewsProfileCreateResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileModifyRequest receive handler
   * @this {!Client}
   * @param {!NewsProfileModifyRequest} value NewsProfileModifyRequest received value
   */
  onReceive_NewsProfileModifyRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileModifyResponse receive handler
   * @this {!Client}
   * @param {!NewsProfileModifyResponse} value NewsProfileModifyResponse received value
   */
  onReceive_NewsProfileModifyResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileDeleteRequest receive handler
   * @this {!Client}
   * @param {!NewsProfileDeleteRequest} value NewsProfileDeleteRequest received value
   */
  onReceive_NewsProfileDeleteRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileDeleteResponse receive handler
   * @this {!Client}
   * @param {!NewsProfileDeleteResponse} value NewsProfileDeleteResponse received value
   */
  onReceive_NewsProfileDeleteResponse (value) {}  // eslint-disable-line

  /**
   * NewsProfileUpdateNotify receive handler
   * @this {!Client}
   * @param {!NewsProfileUpdateNotify} value NewsProfileUpdateNotify received value
   */
  onReceive_NewsProfileUpdateNotify (value) {}  // eslint-disable-line

  /**
   * NewsProfileImportRequest receive handler
   * @this {!Client}
   * @param {!NewsProfileImportRequest} value NewsProfileImportRequest received value
   */
  onReceive_NewsProfileImportRequest (value) {}  // eslint-disable-line

  /**
   * NewsProfileImportResponse receive handler
   * @this {!Client}
   * @param {!NewsProfileImportResponse} value NewsProfileImportResponse received value
   */
  onReceive_NewsProfileImportResponse (value) {}  // eslint-disable-line

  /**
   * TickHistoryRequest receive handler
   * @this {!Client}
   * @param {!TickHistoryRequest} value TickHistoryRequest received value
   */
  onReceive_TickHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TickHistoryResponse receive handler
   * @this {!Client}
   * @param {!TickHistoryResponse} value TickHistoryResponse received value
   */
  onReceive_TickHistoryResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateGetRequest receive handler
   * @this {!Client}
   * @param {!EmailTemplateGetRequest} value EmailTemplateGetRequest received value
   */
  onReceive_EmailTemplateGetRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateGetResponse receive handler
   * @this {!Client}
   * @param {!EmailTemplateGetResponse} value EmailTemplateGetResponse received value
   */
  onReceive_EmailTemplateGetResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplatesGetAllRequest receive handler
   * @this {!Client}
   * @param {!EmailTemplatesGetAllRequest} value EmailTemplatesGetAllRequest received value
   */
  onReceive_EmailTemplatesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplatesGetAllResponse receive handler
   * @this {!Client}
   * @param {!EmailTemplatesGetAllResponse} value EmailTemplatesGetAllResponse received value
   */
  onReceive_EmailTemplatesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateCreateRequest receive handler
   * @this {!Client}
   * @param {!EmailTemplateCreateRequest} value EmailTemplateCreateRequest received value
   */
  onReceive_EmailTemplateCreateRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateCreateResponse receive handler
   * @this {!Client}
   * @param {!EmailTemplateCreateResponse} value EmailTemplateCreateResponse received value
   */
  onReceive_EmailTemplateCreateResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateModifyRequest receive handler
   * @this {!Client}
   * @param {!EmailTemplateModifyRequest} value EmailTemplateModifyRequest received value
   */
  onReceive_EmailTemplateModifyRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateModifyResponse receive handler
   * @this {!Client}
   * @param {!EmailTemplateModifyResponse} value EmailTemplateModifyResponse received value
   */
  onReceive_EmailTemplateModifyResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateDeleteRequest receive handler
   * @this {!Client}
   * @param {!EmailTemplateDeleteRequest} value EmailTemplateDeleteRequest received value
   */
  onReceive_EmailTemplateDeleteRequest (value) {}  // eslint-disable-line

  /**
   * EmailTemplateDeleteResponse receive handler
   * @this {!Client}
   * @param {!EmailTemplateDeleteResponse} value EmailTemplateDeleteResponse received value
   */
  onReceive_EmailTemplateDeleteResponse (value) {}  // eslint-disable-line

  /**
   * EmailTemplateUpdateNotify receive handler
   * @this {!Client}
   * @param {!EmailTemplateUpdateNotify} value EmailTemplateUpdateNotify received value
   */
  onReceive_EmailTemplateUpdateNotify (value) {}  // eslint-disable-line

  /**
   * OrderStateSubscribeRequest receive handler
   * @this {!Client}
   * @param {!OrderStateSubscribeRequest} value OrderStateSubscribeRequest received value
   */
  onReceive_OrderStateSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateSubscribeResponse receive handler
   * @this {!Client}
   * @param {!OrderStateSubscribeResponse} value OrderStateSubscribeResponse received value
   */
  onReceive_OrderStateSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateUpdateSubscriptionRequest receive handler
   * @this {!Client}
   * @param {!OrderStateUpdateSubscriptionRequest} value OrderStateUpdateSubscriptionRequest received value
   */
  onReceive_OrderStateUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateUpdateSubscriptionResponse receive handler
   * @this {!Client}
   * @param {!OrderStateUpdateSubscriptionResponse} value OrderStateUpdateSubscriptionResponse received value
   */
  onReceive_OrderStateUpdateSubscriptionResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!OrderStateUnsubscribeRequest} value OrderStateUnsubscribeRequest received value
   */
  onReceive_OrderStateUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!OrderStateUnsubscribeResponse} value OrderStateUnsubscribeResponse received value
   */
  onReceive_OrderStateUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateNotify receive handler
   * @this {!Client}
   * @param {!OrderStateNotify} value OrderStateNotify received value
   */
  onReceive_OrderStateNotify (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringSubscribeRequest receive handler
   * @this {!Client}
   * @param {!RequestMonitoringSubscribeRequest} value RequestMonitoringSubscribeRequest received value
   */
  onReceive_RequestMonitoringSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringSubscribeResponse receive handler
   * @this {!Client}
   * @param {!RequestMonitoringSubscribeResponse} value RequestMonitoringSubscribeResponse received value
   */
  onReceive_RequestMonitoringSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!RequestMonitoringUnsubscribeRequest} value RequestMonitoringUnsubscribeRequest received value
   */
  onReceive_RequestMonitoringUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!RequestMonitoringUnsubscribeResponse} value RequestMonitoringUnsubscribeResponse received value
   */
  onReceive_RequestMonitoringUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * RequestMonitoringRequestUpdateNotify receive handler
   * @this {!Client}
   * @param {!RequestMonitoringRequestUpdateNotify} value RequestMonitoringRequestUpdateNotify received value
   */
  onReceive_RequestMonitoringRequestUpdateNotify (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoSubscribeRequest receive handler
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoSubscribeRequest} value FilledOrdersSummaryInfoSubscribeRequest received value
   */
  onReceive_FilledOrdersSummaryInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoSubscribeResponse receive handler
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoSubscribeResponse} value FilledOrdersSummaryInfoSubscribeResponse received value
   */
  onReceive_FilledOrdersSummaryInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoNotify receive handler
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoNotify} value FilledOrdersSummaryInfoNotify received value
   */
  onReceive_FilledOrdersSummaryInfoNotify (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUpdateSubscriptionRequest receive handler
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} value FilledOrdersSummaryInfoUpdateSubscriptionRequest received value
   */
  onReceive_FilledOrdersSummaryInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoUnsubscribeRequest} value FilledOrdersSummaryInfoUnsubscribeRequest received value
   */
  onReceive_FilledOrdersSummaryInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FilledOrdersSummaryInfoUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!FilledOrdersSummaryInfoUnsubscribeResponse} value FilledOrdersSummaryInfoUnsubscribeResponse received value
   */
  onReceive_FilledOrdersSummaryInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsNotify receive handler
   * @this {!Client}
   * @param {!TradeEventsNotify} value TradeEventsNotify received value
   */
  onReceive_TradeEventsNotify (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest receive handler
   * @this {!Client}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest received value
   */
  onReceive_TradeEventsGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse receive handler
   * @this {!Client}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse received value
   */
  onReceive_TradeEventsGetResponse (value) {}  // eslint-disable-line

  /**
   * JournalRequest receive handler
   * @this {!Client}
   * @param {!JournalRequest} value JournalRequest received value
   */
  onReceive_JournalRequest (value) {}  // eslint-disable-line

  /**
   * JournalResponse receive handler
   * @this {!Client}
   * @param {!JournalResponse} value JournalResponse received value
   */
  onReceive_JournalResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest received value
   */
  onReceive_ClientReportsListGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse received value
   */
  onReceive_ClientReportsListGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportGetRequest receive handler
   * @this {!Client}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest received value
   */
  onReceive_ClientReportGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportGetResponse receive handler
   * @this {!Client}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse received value
   */
  onReceive_ClientReportGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest received value
   */
  onReceive_ClientReportsSendRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse received value
   */
  onReceive_ClientReportsSendResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest receive handler
   * @this {!Client}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest received value
   */
  onReceive_ProfitClosedGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse receive handler
   * @this {!Client}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse received value
   */
  onReceive_ProfitClosedGetResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportRequest receive handler
   * @this {!Client}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest received value
   */
  onReceive_ChartBarsImportRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportResponse receive handler
   * @this {!Client}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse received value
   */
  onReceive_ChartBarsImportResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportNotify receive handler
   * @this {!Client}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify received value
   */
  onReceive_ChartBarsImportNotify (value) {}  // eslint-disable-line

  /**
   * JournalBusinessRequest receive handler
   * @this {!Client}
   * @param {!JournalBusinessRequest} value JournalBusinessRequest received value
   */
  onReceive_JournalBusinessRequest (value) {}  // eslint-disable-line

  /**
   * JournalBusinessResponse receive handler
   * @this {!Client}
   * @param {!JournalBusinessResponse} value JournalBusinessResponse received value
   */
  onReceive_JournalBusinessResponse (value) {}  // eslint-disable-line

  /**
   * AccountsGetPageRequest receive handler
   * @this {!Client}
   * @param {!AccountsGetPageRequest} value AccountsGetPageRequest received value
   */
  onReceive_AccountsGetPageRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetPageResponse receive handler
   * @this {!Client}
   * @param {!AccountsGetPageResponse} value AccountsGetPageResponse received value
   */
  onReceive_AccountsGetPageResponse (value) {}  // eslint-disable-line

  /**
   * HeartbeatNotify receive handler
   * @this {!Client}
   * @param {!HeartbeatNotify} value HeartbeatNotify received value
   */
  onReceive_HeartbeatNotify (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetRequest receive handler
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest received value
   */
  onReceive_OrdersClosedSummariesGetRequest (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetResponse receive handler
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse received value
   */
  onReceive_OrdersClosedSummariesGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetPageRequest receive handler
   * @this {!Client}
   * @param {!OrdersGetPageRequest} value OrdersGetPageRequest received value
   */
  onReceive_OrdersGetPageRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetPageResponse receive handler
   * @this {!Client}
   * @param {!OrdersGetPageResponse} value OrdersGetPageResponse received value
   */
  onReceive_OrdersGetPageResponse (value) {}  // eslint-disable-line

  /**
   * StorageUniversalNotify receive handler
   * @this {!Client}
   * @param {!StorageUniversalNotify} value StorageUniversalNotify received value
   */
  onReceive_StorageUniversalNotify (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetRequest receive handler
   * @this {!Client}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest received value
   */
  onReceive_ClientLoginInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetResponse receive handler
   * @this {!Client}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse received value
   */
  onReceive_ClientLoginInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * AccountGroupExistsGetRequest receive handler
   * @this {!Client}
   * @param {!AccountGroupExistsGetRequest} value AccountGroupExistsGetRequest received value
   */
  onReceive_AccountGroupExistsGetRequest (value) {}  // eslint-disable-line

  /**
   * AccountGroupExistsGetResponse receive handler
   * @this {!Client}
   * @param {!AccountGroupExistsGetResponse} value AccountGroupExistsGetResponse received value
   */
  onReceive_AccountGroupExistsGetResponse (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordRequest receive handler
   * @this {!Client}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest received value
   */
  onReceive_LoginWithPasswordRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordResponse receive handler
   * @this {!Client}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse received value
   */
  onReceive_LoginWithPasswordResponse (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenRequest receive handler
   * @this {!Client}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest received value
   */
  onReceive_LoginWithTokenRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenResponse receive handler
   * @this {!Client}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse received value
   */
  onReceive_LoginWithTokenResponse (value) {}  // eslint-disable-line

  /**
   * RefreshTokenRequest receive handler
   * @this {!Client}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest received value
   */
  onReceive_RefreshTokenRequest (value) {}  // eslint-disable-line

  /**
   * RefreshTokenResponse receive handler
   * @this {!Client}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse received value
   */
  onReceive_RefreshTokenResponse (value) {}  // eslint-disable-line

  /**
   * PersonGetRequest receive handler
   * @this {!Client}
   * @param {!PersonGetRequest} value PersonGetRequest received value
   */
  onReceive_PersonGetRequest (value) {}  // eslint-disable-line

  /**
   * PersonGetResponse receive handler
   * @this {!Client}
   * @param {!PersonGetResponse} value PersonGetResponse received value
   */
  onReceive_PersonGetResponse (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest receive handler
   * @this {!Client}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest received value
   */
  onReceive_PersonsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse receive handler
   * @this {!Client}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse received value
   */
  onReceive_PersonsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PersonCreateRequest receive handler
   * @this {!Client}
   * @param {!PersonCreateRequest} value PersonCreateRequest received value
   */
  onReceive_PersonCreateRequest (value) {}  // eslint-disable-line

  /**
   * PersonCreateResponse receive handler
   * @this {!Client}
   * @param {!PersonCreateResponse} value PersonCreateResponse received value
   */
  onReceive_PersonCreateResponse (value) {}  // eslint-disable-line

  /**
   * PersonModifyRequest receive handler
   * @this {!Client}
   * @param {!PersonModifyRequest} value PersonModifyRequest received value
   */
  onReceive_PersonModifyRequest (value) {}  // eslint-disable-line

  /**
   * PersonModifyResponse receive handler
   * @this {!Client}
   * @param {!PersonModifyResponse} value PersonModifyResponse received value
   */
  onReceive_PersonModifyResponse (value) {}  // eslint-disable-line

  /**
   * PersonUpdateNotify receive handler
   * @this {!Client}
   * @param {!PersonUpdateNotify} value PersonUpdateNotify received value
   */
  onReceive_PersonUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetRequest receive handler
   * @this {!Client}
   * @param {!SecuritySettingsGetRequest} value SecuritySettingsGetRequest received value
   */
  onReceive_SecuritySettingsGetRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetResponse receive handler
   * @this {!Client}
   * @param {!SecuritySettingsGetResponse} value SecuritySettingsGetResponse received value
   */
  onReceive_SecuritySettingsGetResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetAllRequest receive handler
   * @this {!Client}
   * @param {!SecuritySettingsGetAllRequest} value SecuritySettingsGetAllRequest received value
   */
  onReceive_SecuritySettingsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsGetAllResponse receive handler
   * @this {!Client}
   * @param {!SecuritySettingsGetAllResponse} value SecuritySettingsGetAllResponse received value
   */
  onReceive_SecuritySettingsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsCreateRequest receive handler
   * @this {!Client}
   * @param {!SecuritySettingsCreateRequest} value SecuritySettingsCreateRequest received value
   */
  onReceive_SecuritySettingsCreateRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsCreateResponse receive handler
   * @this {!Client}
   * @param {!SecuritySettingsCreateResponse} value SecuritySettingsCreateResponse received value
   */
  onReceive_SecuritySettingsCreateResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsModifyRequest receive handler
   * @this {!Client}
   * @param {!SecuritySettingsModifyRequest} value SecuritySettingsModifyRequest received value
   */
  onReceive_SecuritySettingsModifyRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsModifyResponse receive handler
   * @this {!Client}
   * @param {!SecuritySettingsModifyResponse} value SecuritySettingsModifyResponse received value
   */
  onReceive_SecuritySettingsModifyResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsDeleteRequest receive handler
   * @this {!Client}
   * @param {!SecuritySettingsDeleteRequest} value SecuritySettingsDeleteRequest received value
   */
  onReceive_SecuritySettingsDeleteRequest (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsDeleteResponse receive handler
   * @this {!Client}
   * @param {!SecuritySettingsDeleteResponse} value SecuritySettingsDeleteResponse received value
   */
  onReceive_SecuritySettingsDeleteResponse (value) {}  // eslint-disable-line

  /**
   * SecuritySettingsUpdateNotify receive handler
   * @this {!Client}
   * @param {!SecuritySettingsUpdateNotify} value SecuritySettingsUpdateNotify received value
   */
  onReceive_SecuritySettingsUpdateNotify (value) {}  // eslint-disable-line

  /**
   * FirewallRuleGetRequest receive handler
   * @this {!Client}
   * @param {!FirewallRuleGetRequest} value FirewallRuleGetRequest received value
   */
  onReceive_FirewallRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleGetResponse receive handler
   * @this {!Client}
   * @param {!FirewallRuleGetResponse} value FirewallRuleGetResponse received value
   */
  onReceive_FirewallRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRulesGetAllRequest receive handler
   * @this {!Client}
   * @param {!FirewallRulesGetAllRequest} value FirewallRulesGetAllRequest received value
   */
  onReceive_FirewallRulesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRulesGetAllResponse receive handler
   * @this {!Client}
   * @param {!FirewallRulesGetAllResponse} value FirewallRulesGetAllResponse received value
   */
  onReceive_FirewallRulesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleCreateRequest receive handler
   * @this {!Client}
   * @param {!FirewallRuleCreateRequest} value FirewallRuleCreateRequest received value
   */
  onReceive_FirewallRuleCreateRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleCreateResponse receive handler
   * @this {!Client}
   * @param {!FirewallRuleCreateResponse} value FirewallRuleCreateResponse received value
   */
  onReceive_FirewallRuleCreateResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleModifyRequest receive handler
   * @this {!Client}
   * @param {!FirewallRuleModifyRequest} value FirewallRuleModifyRequest received value
   */
  onReceive_FirewallRuleModifyRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleModifyResponse receive handler
   * @this {!Client}
   * @param {!FirewallRuleModifyResponse} value FirewallRuleModifyResponse received value
   */
  onReceive_FirewallRuleModifyResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleDeleteRequest receive handler
   * @this {!Client}
   * @param {!FirewallRuleDeleteRequest} value FirewallRuleDeleteRequest received value
   */
  onReceive_FirewallRuleDeleteRequest (value) {}  // eslint-disable-line

  /**
   * FirewallRuleDeleteResponse receive handler
   * @this {!Client}
   * @param {!FirewallRuleDeleteResponse} value FirewallRuleDeleteResponse received value
   */
  onReceive_FirewallRuleDeleteResponse (value) {}  // eslint-disable-line

  /**
   * FirewallRuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!FirewallRuleUpdateNotify} value FirewallRuleUpdateNotify received value
   */
  onReceive_FirewallRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * manager receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case PingModuleRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PingModuleRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PingModuleRequestReceiverModel.verify(), 'manager.PingModuleRequest validation failed!')
        let deserialized = this._PingModuleRequestReceiverModel.deserialize(this._PingModuleRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PingModuleRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PingModuleRequestReceiverValue)
        this.onReceive_PingModuleRequest(this._PingModuleRequestReceiverValue)
        return true
      }
      case PongModuleResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PongModuleResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PongModuleResponseReceiverModel.verify(), 'manager.PongModuleResponse validation failed!')
        let deserialized = this._PongModuleResponseReceiverModel.deserialize(this._PongModuleResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PongModuleResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PongModuleResponseReceiverValue)
        this.onReceive_PongModuleResponse(this._PongModuleResponseReceiverValue)
        return true
      }
      case PingModuleRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PingModuleRejectReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PingModuleRejectReceiverModel.verify(), 'manager.PingModuleReject validation failed!')
        let deserialized = this._PingModuleRejectReceiverModel.deserialize(this._PingModuleRejectReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PingModuleReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PingModuleRejectReceiverValue)
        this.onReceive_PingModuleReject(this._PingModuleRejectReceiverValue)
        return true
      }
      case Deprecated3Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated3ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated3ReceiverModel.verify(), 'manager.Deprecated3 validation failed!')
        let deserialized = this._Deprecated3ReceiverModel.deserialize(this._Deprecated3ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated3 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated3ReceiverValue)
        this.onReceive_Deprecated3(this._Deprecated3ReceiverValue)
        return true
      }
      case Deprecated4Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated4ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated4ReceiverModel.verify(), 'manager.Deprecated4 validation failed!')
        let deserialized = this._Deprecated4ReceiverModel.deserialize(this._Deprecated4ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated4 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated4ReceiverValue)
        this.onReceive_Deprecated4(this._Deprecated4ReceiverValue)
        return true
      }
      case Deprecated1Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated1ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated1ReceiverModel.verify(), 'manager.Deprecated1 validation failed!')
        let deserialized = this._Deprecated1ReceiverModel.deserialize(this._Deprecated1ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated1 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated1ReceiverValue)
        this.onReceive_Deprecated1(this._Deprecated1ReceiverValue)
        return true
      }
      case Deprecated2Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated2ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated2ReceiverModel.verify(), 'manager.Deprecated2 validation failed!')
        let deserialized = this._Deprecated2ReceiverModel.deserialize(this._Deprecated2ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated2 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated2ReceiverValue)
        this.onReceive_Deprecated2(this._Deprecated2ReceiverValue)
        return true
      }
      case ManagerSessionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerSessionInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerSessionInfoRequestReceiverModel.verify(), 'manager.ManagerSessionInfoRequest validation failed!')
        let deserialized = this._ManagerSessionInfoRequestReceiverModel.deserialize(this._ManagerSessionInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerSessionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerSessionInfoRequestReceiverValue)
        this.onReceive_ManagerSessionInfoRequest(this._ManagerSessionInfoRequestReceiverValue)
        return true
      }
      case ManagerSessionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerSessionInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerSessionInfoResponseReceiverModel.verify(), 'manager.ManagerSessionInfoResponse validation failed!')
        let deserialized = this._ManagerSessionInfoResponseReceiverModel.deserialize(this._ManagerSessionInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerSessionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerSessionInfoResponseReceiverValue)
        this.onReceive_ManagerSessionInfoResponse(this._ManagerSessionInfoResponseReceiverValue)
        return true
      }
      case OnlineSessionsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OnlineSessionsRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OnlineSessionsRequestReceiverModel.verify(), 'manager.OnlineSessionsRequest validation failed!')
        let deserialized = this._OnlineSessionsRequestReceiverModel.deserialize(this._OnlineSessionsRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OnlineSessionsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OnlineSessionsRequestReceiverValue)
        this.onReceive_OnlineSessionsRequest(this._OnlineSessionsRequestReceiverValue)
        return true
      }
      case OnlineSessionsResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OnlineSessionsResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OnlineSessionsResponseReceiverModel.verify(), 'manager.OnlineSessionsResponse validation failed!')
        let deserialized = this._OnlineSessionsResponseReceiverModel.deserialize(this._OnlineSessionsResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OnlineSessionsResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OnlineSessionsResponseReceiverValue)
        this.onReceive_OnlineSessionsResponse(this._OnlineSessionsResponseReceiverValue)
        return true
      }
      case DropSessionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DropSessionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DropSessionRequestReceiverModel.verify(), 'manager.DropSessionRequest validation failed!')
        let deserialized = this._DropSessionRequestReceiverModel.deserialize(this._DropSessionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DropSessionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DropSessionRequestReceiverValue)
        this.onReceive_DropSessionRequest(this._DropSessionRequestReceiverValue)
        return true
      }
      case DropSessionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DropSessionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DropSessionResponseReceiverModel.verify(), 'manager.DropSessionResponse validation failed!')
        let deserialized = this._DropSessionResponseReceiverModel.deserialize(this._DropSessionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DropSessionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DropSessionResponseReceiverValue)
        this.onReceive_DropSessionResponse(this._DropSessionResponseReceiverValue)
        return true
      }
      case ConfigRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRequestReceiverModel.verify(), 'manager.ConfigRequest validation failed!')
        let deserialized = this._ConfigRequestReceiverModel.deserialize(this._ConfigRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRequestReceiverValue)
        this.onReceive_ConfigRequest(this._ConfigRequestReceiverValue)
        return true
      }
      case ConfigResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigResponseReceiverModel.verify(), 'manager.ConfigResponse validation failed!')
        let deserialized = this._ConfigResponseReceiverModel.deserialize(this._ConfigResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigResponseReceiverValue)
        this.onReceive_ConfigResponse(this._ConfigResponseReceiverValue)
        return true
      }
      case ConfigRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRejectReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRejectReceiverModel.verify(), 'manager.ConfigReject validation failed!')
        let deserialized = this._ConfigRejectReceiverModel.deserialize(this._ConfigRejectReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRejectReceiverValue)
        this.onReceive_ConfigReject(this._ConfigRejectReceiverValue)
        return true
      }
      case ConfigUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRequestReceiverModel.verify(), 'manager.ConfigUpdateRequest validation failed!')
        let deserialized = this._ConfigUpdateRequestReceiverModel.deserialize(this._ConfigUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateRequestReceiverValue)
        this.onReceive_ConfigUpdateRequest(this._ConfigUpdateRequestReceiverValue)
        return true
      }
      case ConfigUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateResponseReceiverModel.verify(), 'manager.ConfigUpdateResponse validation failed!')
        let deserialized = this._ConfigUpdateResponseReceiverModel.deserialize(this._ConfigUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateResponseReceiverValue)
        this.onReceive_ConfigUpdateResponse(this._ConfigUpdateResponseReceiverValue)
        return true
      }
      case ConfigUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateNotifyReceiverModel.verify(), 'manager.ConfigUpdateNotify validation failed!')
        let deserialized = this._ConfigUpdateNotifyReceiverModel.deserialize(this._ConfigUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateNotifyReceiverValue)
        this.onReceive_ConfigUpdateNotify(this._ConfigUpdateNotifyReceiverValue)
        return true
      }
      case ConfigVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionRequestReceiverModel.verify(), 'manager.ConfigVersionRequest validation failed!')
        let deserialized = this._ConfigVersionRequestReceiverModel.deserialize(this._ConfigVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionRequestReceiverValue)
        this.onReceive_ConfigVersionRequest(this._ConfigVersionRequestReceiverValue)
        return true
      }
      case ConfigVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionResponseReceiverModel.verify(), 'manager.ConfigVersionResponse validation failed!')
        let deserialized = this._ConfigVersionResponseReceiverModel.deserialize(this._ConfigVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionResponseReceiverValue)
        this.onReceive_ConfigVersionResponse(this._ConfigVersionResponseReceiverValue)
        return true
      }
      case ConfigExportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigExportRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigExportRequestReceiverModel.verify(), 'manager.ConfigExportRequest validation failed!')
        let deserialized = this._ConfigExportRequestReceiverModel.deserialize(this._ConfigExportRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigExportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigExportRequestReceiverValue)
        this.onReceive_ConfigExportRequest(this._ConfigExportRequestReceiverValue)
        return true
      }
      case ConfigExportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigExportResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigExportResponseReceiverModel.verify(), 'manager.ConfigExportResponse validation failed!')
        let deserialized = this._ConfigExportResponseReceiverModel.deserialize(this._ConfigExportResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigExportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigExportResponseReceiverValue)
        this.onReceive_ConfigExportResponse(this._ConfigExportResponseReceiverValue)
        return true
      }
      case ConfigImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigImportRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigImportRequestReceiverModel.verify(), 'manager.ConfigImportRequest validation failed!')
        let deserialized = this._ConfigImportRequestReceiverModel.deserialize(this._ConfigImportRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigImportRequestReceiverValue)
        this.onReceive_ConfigImportRequest(this._ConfigImportRequestReceiverValue)
        return true
      }
      case ConfigImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigImportResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigImportResponseReceiverModel.verify(), 'manager.ConfigImportResponse validation failed!')
        let deserialized = this._ConfigImportResponseReceiverModel.deserialize(this._ConfigImportResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigImportResponseReceiverValue)
        this.onReceive_ConfigImportResponse(this._ConfigImportResponseReceiverValue)
        return true
      }
      case ConfigValidateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigValidateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigValidateRequestReceiverModel.verify(), 'manager.ConfigValidateRequest validation failed!')
        let deserialized = this._ConfigValidateRequestReceiverModel.deserialize(this._ConfigValidateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigValidateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigValidateRequestReceiverValue)
        this.onReceive_ConfigValidateRequest(this._ConfigValidateRequestReceiverValue)
        return true
      }
      case ConfigValidateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigValidateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigValidateResponseReceiverModel.verify(), 'manager.ConfigValidateResponse validation failed!')
        let deserialized = this._ConfigValidateResponseReceiverModel.deserialize(this._ConfigValidateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigValidateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigValidateResponseReceiverValue)
        this.onReceive_ConfigValidateResponse(this._ConfigValidateResponseReceiverValue)
        return true
      }
      case ConfigRefreshRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshRequestReceiverModel.verify(), 'manager.ConfigRefreshRequest validation failed!')
        let deserialized = this._ConfigRefreshRequestReceiverModel.deserialize(this._ConfigRefreshRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRefreshRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshRequestReceiverValue)
        this.onReceive_ConfigRefreshRequest(this._ConfigRefreshRequestReceiverValue)
        return true
      }
      case ConfigRefreshResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshResponseReceiverModel.verify(), 'manager.ConfigRefreshResponse validation failed!')
        let deserialized = this._ConfigRefreshResponseReceiverModel.deserialize(this._ConfigRefreshResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRefreshResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshResponseReceiverValue)
        this.onReceive_ConfigRefreshResponse(this._ConfigRefreshResponseReceiverValue)
        return true
      }
      case ConfigHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryRequestReceiverModel.verify(), 'manager.ConfigHistoryRequest validation failed!')
        let deserialized = this._ConfigHistoryRequestReceiverModel.deserialize(this._ConfigHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryRequestReceiverValue)
        this.onReceive_ConfigHistoryRequest(this._ConfigHistoryRequestReceiverValue)
        return true
      }
      case ConfigHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryResponseReceiverModel.verify(), 'manager.ConfigHistoryResponse validation failed!')
        let deserialized = this._ConfigHistoryResponseReceiverModel.deserialize(this._ConfigHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryResponseReceiverValue)
        this.onReceive_ConfigHistoryResponse(this._ConfigHistoryResponseReceiverValue)
        return true
      }
      case ConfigRollbackRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackRequestReceiverModel.verify(), 'manager.ConfigRollbackRequest validation failed!')
        let deserialized = this._ConfigRollbackRequestReceiverModel.deserialize(this._ConfigRollbackRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRollbackRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackRequestReceiverValue)
        this.onReceive_ConfigRollbackRequest(this._ConfigRollbackRequestReceiverValue)
        return true
      }
      case ConfigRollbackResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackResponseReceiverModel.verify(), 'manager.ConfigRollbackResponse validation failed!')
        let deserialized = this._ConfigRollbackResponseReceiverModel.deserialize(this._ConfigRollbackResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ConfigRollbackResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackResponseReceiverValue)
        this.onReceive_ConfigRollbackResponse(this._ConfigRollbackResponseReceiverValue)
        return true
      }
      case HostGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostGetRequestReceiverModel.verify(), 'manager.HostGetRequest validation failed!')
        let deserialized = this._HostGetRequestReceiverModel.deserialize(this._HostGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostGetRequestReceiverValue)
        this.onReceive_HostGetRequest(this._HostGetRequestReceiverValue)
        return true
      }
      case HostGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostGetResponseReceiverModel.verify(), 'manager.HostGetResponse validation failed!')
        let deserialized = this._HostGetResponseReceiverModel.deserialize(this._HostGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostGetResponseReceiverValue)
        this.onReceive_HostGetResponse(this._HostGetResponseReceiverValue)
        return true
      }
      case HostsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostsGetAllRequestReceiverModel.verify(), 'manager.HostsGetAllRequest validation failed!')
        let deserialized = this._HostsGetAllRequestReceiverModel.deserialize(this._HostsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostsGetAllRequestReceiverValue)
        this.onReceive_HostsGetAllRequest(this._HostsGetAllRequestReceiverValue)
        return true
      }
      case HostsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostsGetAllResponseReceiverModel.verify(), 'manager.HostsGetAllResponse validation failed!')
        let deserialized = this._HostsGetAllResponseReceiverModel.deserialize(this._HostsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostsGetAllResponseReceiverValue)
        this.onReceive_HostsGetAllResponse(this._HostsGetAllResponseReceiverValue)
        return true
      }
      case HostCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostCreateRequestReceiverModel.verify(), 'manager.HostCreateRequest validation failed!')
        let deserialized = this._HostCreateRequestReceiverModel.deserialize(this._HostCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostCreateRequestReceiverValue)
        this.onReceive_HostCreateRequest(this._HostCreateRequestReceiverValue)
        return true
      }
      case HostCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostCreateResponseReceiverModel.verify(), 'manager.HostCreateResponse validation failed!')
        let deserialized = this._HostCreateResponseReceiverModel.deserialize(this._HostCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostCreateResponseReceiverValue)
        this.onReceive_HostCreateResponse(this._HostCreateResponseReceiverValue)
        return true
      }
      case HostModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostModifyRequestReceiverModel.verify(), 'manager.HostModifyRequest validation failed!')
        let deserialized = this._HostModifyRequestReceiverModel.deserialize(this._HostModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostModifyRequestReceiverValue)
        this.onReceive_HostModifyRequest(this._HostModifyRequestReceiverValue)
        return true
      }
      case HostModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostModifyResponseReceiverModel.verify(), 'manager.HostModifyResponse validation failed!')
        let deserialized = this._HostModifyResponseReceiverModel.deserialize(this._HostModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostModifyResponseReceiverValue)
        this.onReceive_HostModifyResponse(this._HostModifyResponseReceiverValue)
        return true
      }
      case HostDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostDeleteRequestReceiverModel.verify(), 'manager.HostDeleteRequest validation failed!')
        let deserialized = this._HostDeleteRequestReceiverModel.deserialize(this._HostDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostDeleteRequestReceiverValue)
        this.onReceive_HostDeleteRequest(this._HostDeleteRequestReceiverValue)
        return true
      }
      case HostDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostDeleteResponseReceiverModel.verify(), 'manager.HostDeleteResponse validation failed!')
        let deserialized = this._HostDeleteResponseReceiverModel.deserialize(this._HostDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostDeleteResponseReceiverValue)
        this.onReceive_HostDeleteResponse(this._HostDeleteResponseReceiverValue)
        return true
      }
      case HostUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HostUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HostUpdateNotifyReceiverModel.verify(), 'manager.HostUpdateNotify validation failed!')
        let deserialized = this._HostUpdateNotifyReceiverModel.deserialize(this._HostUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HostUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HostUpdateNotifyReceiverValue)
        this.onReceive_HostUpdateNotify(this._HostUpdateNotifyReceiverValue)
        return true
      }
      case ModuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleGetRequestReceiverModel.verify(), 'manager.ModuleGetRequest validation failed!')
        let deserialized = this._ModuleGetRequestReceiverModel.deserialize(this._ModuleGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleGetRequestReceiverValue)
        this.onReceive_ModuleGetRequest(this._ModuleGetRequestReceiverValue)
        return true
      }
      case ModuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleGetResponseReceiverModel.verify(), 'manager.ModuleGetResponse validation failed!')
        let deserialized = this._ModuleGetResponseReceiverModel.deserialize(this._ModuleGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleGetResponseReceiverValue)
        this.onReceive_ModuleGetResponse(this._ModuleGetResponseReceiverValue)
        return true
      }
      case ModulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesGetAllRequestReceiverModel.verify(), 'manager.ModulesGetAllRequest validation failed!')
        let deserialized = this._ModulesGetAllRequestReceiverModel.deserialize(this._ModulesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesGetAllRequestReceiverValue)
        this.onReceive_ModulesGetAllRequest(this._ModulesGetAllRequestReceiverValue)
        return true
      }
      case ModulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesGetAllResponseReceiverModel.verify(), 'manager.ModulesGetAllResponse validation failed!')
        let deserialized = this._ModulesGetAllResponseReceiverModel.deserialize(this._ModulesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesGetAllResponseReceiverValue)
        this.onReceive_ModulesGetAllResponse(this._ModulesGetAllResponseReceiverValue)
        return true
      }
      case ModuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleCreateRequestReceiverModel.verify(), 'manager.ModuleCreateRequest validation failed!')
        let deserialized = this._ModuleCreateRequestReceiverModel.deserialize(this._ModuleCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleCreateRequestReceiverValue)
        this.onReceive_ModuleCreateRequest(this._ModuleCreateRequestReceiverValue)
        return true
      }
      case ModuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleCreateResponseReceiverModel.verify(), 'manager.ModuleCreateResponse validation failed!')
        let deserialized = this._ModuleCreateResponseReceiverModel.deserialize(this._ModuleCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleCreateResponseReceiverValue)
        this.onReceive_ModuleCreateResponse(this._ModuleCreateResponseReceiverValue)
        return true
      }
      case ModuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleModifyRequestReceiverModel.verify(), 'manager.ModuleModifyRequest validation failed!')
        let deserialized = this._ModuleModifyRequestReceiverModel.deserialize(this._ModuleModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleModifyRequestReceiverValue)
        this.onReceive_ModuleModifyRequest(this._ModuleModifyRequestReceiverValue)
        return true
      }
      case ModuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleModifyResponseReceiverModel.verify(), 'manager.ModuleModifyResponse validation failed!')
        let deserialized = this._ModuleModifyResponseReceiverModel.deserialize(this._ModuleModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleModifyResponseReceiverValue)
        this.onReceive_ModuleModifyResponse(this._ModuleModifyResponseReceiverValue)
        return true
      }
      case ModuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleDeleteRequestReceiverModel.verify(), 'manager.ModuleDeleteRequest validation failed!')
        let deserialized = this._ModuleDeleteRequestReceiverModel.deserialize(this._ModuleDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleDeleteRequestReceiverValue)
        this.onReceive_ModuleDeleteRequest(this._ModuleDeleteRequestReceiverValue)
        return true
      }
      case ModuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleDeleteResponseReceiverModel.verify(), 'manager.ModuleDeleteResponse validation failed!')
        let deserialized = this._ModuleDeleteResponseReceiverModel.deserialize(this._ModuleDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleDeleteResponseReceiverValue)
        this.onReceive_ModuleDeleteResponse(this._ModuleDeleteResponseReceiverValue)
        return true
      }
      case ModuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleUpdateNotifyReceiverModel.verify(), 'manager.ModuleUpdateNotify validation failed!')
        let deserialized = this._ModuleUpdateNotifyReceiverModel.deserialize(this._ModuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleUpdateNotifyReceiverValue)
        this.onReceive_ModuleUpdateNotify(this._ModuleUpdateNotifyReceiverValue)
        return true
      }
      case ServerTimeGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerTimeGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ServerTimeGetRequestReceiverModel.verify(), 'manager.ServerTimeGetRequest validation failed!')
        let deserialized = this._ServerTimeGetRequestReceiverModel.deserialize(this._ServerTimeGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ServerTimeGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerTimeGetRequestReceiverValue)
        this.onReceive_ServerTimeGetRequest(this._ServerTimeGetRequestReceiverValue)
        return true
      }
      case ServerTimeGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerTimeGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ServerTimeGetResponseReceiverModel.verify(), 'manager.ServerTimeGetResponse validation failed!')
        let deserialized = this._ServerTimeGetResponseReceiverModel.deserialize(this._ServerTimeGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ServerTimeGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerTimeGetResponseReceiverValue)
        this.onReceive_ServerTimeGetResponse(this._ServerTimeGetResponseReceiverValue)
        return true
      }
      case ManagerPlatformTimezoneGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerPlatformTimezoneGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerPlatformTimezoneGetRequestReceiverModel.verify(), 'manager.ManagerPlatformTimezoneGetRequest validation failed!')
        let deserialized = this._ManagerPlatformTimezoneGetRequestReceiverModel.deserialize(this._ManagerPlatformTimezoneGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerPlatformTimezoneGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerPlatformTimezoneGetRequestReceiverValue)
        this.onReceive_ManagerPlatformTimezoneGetRequest(this._ManagerPlatformTimezoneGetRequestReceiverValue)
        return true
      }
      case ManagerPlatformTimezoneGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerPlatformTimezoneGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerPlatformTimezoneGetResponseReceiverModel.verify(), 'manager.ManagerPlatformTimezoneGetResponse validation failed!')
        let deserialized = this._ManagerPlatformTimezoneGetResponseReceiverModel.deserialize(this._ManagerPlatformTimezoneGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerPlatformTimezoneGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerPlatformTimezoneGetResponseReceiverValue)
        this.onReceive_ManagerPlatformTimezoneGetResponse(this._ManagerPlatformTimezoneGetResponseReceiverValue)
        return true
      }
      case TimezoneGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneGetRequestReceiverModel.verify(), 'manager.TimezoneGetRequest validation failed!')
        let deserialized = this._TimezoneGetRequestReceiverModel.deserialize(this._TimezoneGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneGetRequestReceiverValue)
        this.onReceive_TimezoneGetRequest(this._TimezoneGetRequestReceiverValue)
        return true
      }
      case TimezoneGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneGetResponseReceiverModel.verify(), 'manager.TimezoneGetResponse validation failed!')
        let deserialized = this._TimezoneGetResponseReceiverModel.deserialize(this._TimezoneGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneGetResponseReceiverValue)
        this.onReceive_TimezoneGetResponse(this._TimezoneGetResponseReceiverValue)
        return true
      }
      case TimezonesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezonesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezonesGetAllRequestReceiverModel.verify(), 'manager.TimezonesGetAllRequest validation failed!')
        let deserialized = this._TimezonesGetAllRequestReceiverModel.deserialize(this._TimezonesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezonesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezonesGetAllRequestReceiverValue)
        this.onReceive_TimezonesGetAllRequest(this._TimezonesGetAllRequestReceiverValue)
        return true
      }
      case TimezonesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezonesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezonesGetAllResponseReceiverModel.verify(), 'manager.TimezonesGetAllResponse validation failed!')
        let deserialized = this._TimezonesGetAllResponseReceiverModel.deserialize(this._TimezonesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezonesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezonesGetAllResponseReceiverValue)
        this.onReceive_TimezonesGetAllResponse(this._TimezonesGetAllResponseReceiverValue)
        return true
      }
      case TimezoneCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneCreateRequestReceiverModel.verify(), 'manager.TimezoneCreateRequest validation failed!')
        let deserialized = this._TimezoneCreateRequestReceiverModel.deserialize(this._TimezoneCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneCreateRequestReceiverValue)
        this.onReceive_TimezoneCreateRequest(this._TimezoneCreateRequestReceiverValue)
        return true
      }
      case TimezoneCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneCreateResponseReceiverModel.verify(), 'manager.TimezoneCreateResponse validation failed!')
        let deserialized = this._TimezoneCreateResponseReceiverModel.deserialize(this._TimezoneCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneCreateResponseReceiverValue)
        this.onReceive_TimezoneCreateResponse(this._TimezoneCreateResponseReceiverValue)
        return true
      }
      case TimezoneModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneModifyRequestReceiverModel.verify(), 'manager.TimezoneModifyRequest validation failed!')
        let deserialized = this._TimezoneModifyRequestReceiverModel.deserialize(this._TimezoneModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneModifyRequestReceiverValue)
        this.onReceive_TimezoneModifyRequest(this._TimezoneModifyRequestReceiverValue)
        return true
      }
      case TimezoneModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneModifyResponseReceiverModel.verify(), 'manager.TimezoneModifyResponse validation failed!')
        let deserialized = this._TimezoneModifyResponseReceiverModel.deserialize(this._TimezoneModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneModifyResponseReceiverValue)
        this.onReceive_TimezoneModifyResponse(this._TimezoneModifyResponseReceiverValue)
        return true
      }
      case TimezoneDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneDeleteRequestReceiverModel.verify(), 'manager.TimezoneDeleteRequest validation failed!')
        let deserialized = this._TimezoneDeleteRequestReceiverModel.deserialize(this._TimezoneDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneDeleteRequestReceiverValue)
        this.onReceive_TimezoneDeleteRequest(this._TimezoneDeleteRequestReceiverValue)
        return true
      }
      case TimezoneDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneDeleteResponseReceiverModel.verify(), 'manager.TimezoneDeleteResponse validation failed!')
        let deserialized = this._TimezoneDeleteResponseReceiverModel.deserialize(this._TimezoneDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneDeleteResponseReceiverValue)
        this.onReceive_TimezoneDeleteResponse(this._TimezoneDeleteResponseReceiverValue)
        return true
      }
      case TimezoneUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneUpdateNotifyReceiverModel.verify(), 'manager.TimezoneUpdateNotify validation failed!')
        let deserialized = this._TimezoneUpdateNotifyReceiverModel.deserialize(this._TimezoneUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TimezoneUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneUpdateNotifyReceiverValue)
        this.onReceive_TimezoneUpdateNotify(this._TimezoneUpdateNotifyReceiverValue)
        return true
      }
      case CurrencyGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetRequestReceiverModel.verify(), 'manager.CurrencyGroupGetRequest validation failed!')
        let deserialized = this._CurrencyGroupGetRequestReceiverModel.deserialize(this._CurrencyGroupGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetRequestReceiverValue)
        this.onReceive_CurrencyGroupGetRequest(this._CurrencyGroupGetRequestReceiverValue)
        return true
      }
      case CurrencyGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetResponseReceiverModel.verify(), 'manager.CurrencyGroupGetResponse validation failed!')
        let deserialized = this._CurrencyGroupGetResponseReceiverModel.deserialize(this._CurrencyGroupGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetResponseReceiverValue)
        this.onReceive_CurrencyGroupGetResponse(this._CurrencyGroupGetResponseReceiverValue)
        return true
      }
      case CurrencyGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllRequestReceiverModel.verify(), 'manager.CurrencyGroupsGetAllRequest validation failed!')
        let deserialized = this._CurrencyGroupsGetAllRequestReceiverModel.deserialize(this._CurrencyGroupsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllRequestReceiverValue)
        this.onReceive_CurrencyGroupsGetAllRequest(this._CurrencyGroupsGetAllRequestReceiverValue)
        return true
      }
      case CurrencyGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllResponseReceiverModel.verify(), 'manager.CurrencyGroupsGetAllResponse validation failed!')
        let deserialized = this._CurrencyGroupsGetAllResponseReceiverModel.deserialize(this._CurrencyGroupsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllResponseReceiverValue)
        this.onReceive_CurrencyGroupsGetAllResponse(this._CurrencyGroupsGetAllResponseReceiverValue)
        return true
      }
      case CurrencyGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupCreateRequestReceiverModel.verify(), 'manager.CurrencyGroupCreateRequest validation failed!')
        let deserialized = this._CurrencyGroupCreateRequestReceiverModel.deserialize(this._CurrencyGroupCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupCreateRequestReceiverValue)
        this.onReceive_CurrencyGroupCreateRequest(this._CurrencyGroupCreateRequestReceiverValue)
        return true
      }
      case CurrencyGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupCreateResponseReceiverModel.verify(), 'manager.CurrencyGroupCreateResponse validation failed!')
        let deserialized = this._CurrencyGroupCreateResponseReceiverModel.deserialize(this._CurrencyGroupCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupCreateResponseReceiverValue)
        this.onReceive_CurrencyGroupCreateResponse(this._CurrencyGroupCreateResponseReceiverValue)
        return true
      }
      case CurrencyGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupModifyRequestReceiverModel.verify(), 'manager.CurrencyGroupModifyRequest validation failed!')
        let deserialized = this._CurrencyGroupModifyRequestReceiverModel.deserialize(this._CurrencyGroupModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupModifyRequestReceiverValue)
        this.onReceive_CurrencyGroupModifyRequest(this._CurrencyGroupModifyRequestReceiverValue)
        return true
      }
      case CurrencyGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupModifyResponseReceiverModel.verify(), 'manager.CurrencyGroupModifyResponse validation failed!')
        let deserialized = this._CurrencyGroupModifyResponseReceiverModel.deserialize(this._CurrencyGroupModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupModifyResponseReceiverValue)
        this.onReceive_CurrencyGroupModifyResponse(this._CurrencyGroupModifyResponseReceiverValue)
        return true
      }
      case CurrencyGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupDeleteRequestReceiverModel.verify(), 'manager.CurrencyGroupDeleteRequest validation failed!')
        let deserialized = this._CurrencyGroupDeleteRequestReceiverModel.deserialize(this._CurrencyGroupDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupDeleteRequestReceiverValue)
        this.onReceive_CurrencyGroupDeleteRequest(this._CurrencyGroupDeleteRequestReceiverValue)
        return true
      }
      case CurrencyGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupDeleteResponseReceiverModel.verify(), 'manager.CurrencyGroupDeleteResponse validation failed!')
        let deserialized = this._CurrencyGroupDeleteResponseReceiverModel.deserialize(this._CurrencyGroupDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupDeleteResponseReceiverValue)
        this.onReceive_CurrencyGroupDeleteResponse(this._CurrencyGroupDeleteResponseReceiverValue)
        return true
      }
      case CurrencyGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupUpdateNotifyReceiverModel.verify(), 'manager.CurrencyGroupUpdateNotify validation failed!')
        let deserialized = this._CurrencyGroupUpdateNotifyReceiverModel.deserialize(this._CurrencyGroupUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupUpdateNotifyReceiverValue)
        this.onReceive_CurrencyGroupUpdateNotify(this._CurrencyGroupUpdateNotifyReceiverValue)
        return true
      }
      case CurrencyGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetRequestReceiverModel.verify(), 'manager.CurrencyGetRequest validation failed!')
        let deserialized = this._CurrencyGetRequestReceiverModel.deserialize(this._CurrencyGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetRequestReceiverValue)
        this.onReceive_CurrencyGetRequest(this._CurrencyGetRequestReceiverValue)
        return true
      }
      case CurrencyGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetResponseReceiverModel.verify(), 'manager.CurrencyGetResponse validation failed!')
        let deserialized = this._CurrencyGetResponseReceiverModel.deserialize(this._CurrencyGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetResponseReceiverValue)
        this.onReceive_CurrencyGetResponse(this._CurrencyGetResponseReceiverValue)
        return true
      }
      case CurrenciesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllRequestReceiverModel.verify(), 'manager.CurrenciesGetAllRequest validation failed!')
        let deserialized = this._CurrenciesGetAllRequestReceiverModel.deserialize(this._CurrenciesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrenciesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllRequestReceiverValue)
        this.onReceive_CurrenciesGetAllRequest(this._CurrenciesGetAllRequestReceiverValue)
        return true
      }
      case CurrenciesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllResponseReceiverModel.verify(), 'manager.CurrenciesGetAllResponse validation failed!')
        let deserialized = this._CurrenciesGetAllResponseReceiverModel.deserialize(this._CurrenciesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrenciesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllResponseReceiverValue)
        this.onReceive_CurrenciesGetAllResponse(this._CurrenciesGetAllResponseReceiverValue)
        return true
      }
      case CurrencyCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyCreateRequestReceiverModel.verify(), 'manager.CurrencyCreateRequest validation failed!')
        let deserialized = this._CurrencyCreateRequestReceiverModel.deserialize(this._CurrencyCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyCreateRequestReceiverValue)
        this.onReceive_CurrencyCreateRequest(this._CurrencyCreateRequestReceiverValue)
        return true
      }
      case CurrencyCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyCreateResponseReceiverModel.verify(), 'manager.CurrencyCreateResponse validation failed!')
        let deserialized = this._CurrencyCreateResponseReceiverModel.deserialize(this._CurrencyCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyCreateResponseReceiverValue)
        this.onReceive_CurrencyCreateResponse(this._CurrencyCreateResponseReceiverValue)
        return true
      }
      case CurrencyModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyModifyRequestReceiverModel.verify(), 'manager.CurrencyModifyRequest validation failed!')
        let deserialized = this._CurrencyModifyRequestReceiverModel.deserialize(this._CurrencyModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyModifyRequestReceiverValue)
        this.onReceive_CurrencyModifyRequest(this._CurrencyModifyRequestReceiverValue)
        return true
      }
      case CurrencyModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyModifyResponseReceiverModel.verify(), 'manager.CurrencyModifyResponse validation failed!')
        let deserialized = this._CurrencyModifyResponseReceiverModel.deserialize(this._CurrencyModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyModifyResponseReceiverValue)
        this.onReceive_CurrencyModifyResponse(this._CurrencyModifyResponseReceiverValue)
        return true
      }
      case CurrencyDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyDeleteRequestReceiverModel.verify(), 'manager.CurrencyDeleteRequest validation failed!')
        let deserialized = this._CurrencyDeleteRequestReceiverModel.deserialize(this._CurrencyDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyDeleteRequestReceiverValue)
        this.onReceive_CurrencyDeleteRequest(this._CurrencyDeleteRequestReceiverValue)
        return true
      }
      case CurrencyDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyDeleteResponseReceiverModel.verify(), 'manager.CurrencyDeleteResponse validation failed!')
        let deserialized = this._CurrencyDeleteResponseReceiverModel.deserialize(this._CurrencyDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyDeleteResponseReceiverValue)
        this.onReceive_CurrencyDeleteResponse(this._CurrencyDeleteResponseReceiverValue)
        return true
      }
      case CurrencyUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyUpdateNotifyReceiverModel.verify(), 'manager.CurrencyUpdateNotify validation failed!')
        let deserialized = this._CurrencyUpdateNotifyReceiverModel.deserialize(this._CurrencyUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CurrencyUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyUpdateNotifyReceiverValue)
        this.onReceive_CurrencyUpdateNotify(this._CurrencyUpdateNotifyReceiverValue)
        return true
      }
      case SymbolGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetRequestReceiverModel.verify(), 'manager.SymbolGroupGetRequest validation failed!')
        let deserialized = this._SymbolGroupGetRequestReceiverModel.deserialize(this._SymbolGroupGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetRequestReceiverValue)
        this.onReceive_SymbolGroupGetRequest(this._SymbolGroupGetRequestReceiverValue)
        return true
      }
      case SymbolGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetResponseReceiverModel.verify(), 'manager.SymbolGroupGetResponse validation failed!')
        let deserialized = this._SymbolGroupGetResponseReceiverModel.deserialize(this._SymbolGroupGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetResponseReceiverValue)
        this.onReceive_SymbolGroupGetResponse(this._SymbolGroupGetResponseReceiverValue)
        return true
      }
      case SymbolGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllRequestReceiverModel.verify(), 'manager.SymbolGroupsGetAllRequest validation failed!')
        let deserialized = this._SymbolGroupsGetAllRequestReceiverModel.deserialize(this._SymbolGroupsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllRequestReceiverValue)
        this.onReceive_SymbolGroupsGetAllRequest(this._SymbolGroupsGetAllRequestReceiverValue)
        return true
      }
      case SymbolGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllResponseReceiverModel.verify(), 'manager.SymbolGroupsGetAllResponse validation failed!')
        let deserialized = this._SymbolGroupsGetAllResponseReceiverModel.deserialize(this._SymbolGroupsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllResponseReceiverValue)
        this.onReceive_SymbolGroupsGetAllResponse(this._SymbolGroupsGetAllResponseReceiverValue)
        return true
      }
      case SymbolGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupCreateRequestReceiverModel.verify(), 'manager.SymbolGroupCreateRequest validation failed!')
        let deserialized = this._SymbolGroupCreateRequestReceiverModel.deserialize(this._SymbolGroupCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupCreateRequestReceiverValue)
        this.onReceive_SymbolGroupCreateRequest(this._SymbolGroupCreateRequestReceiverValue)
        return true
      }
      case SymbolGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupCreateResponseReceiverModel.verify(), 'manager.SymbolGroupCreateResponse validation failed!')
        let deserialized = this._SymbolGroupCreateResponseReceiverModel.deserialize(this._SymbolGroupCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupCreateResponseReceiverValue)
        this.onReceive_SymbolGroupCreateResponse(this._SymbolGroupCreateResponseReceiverValue)
        return true
      }
      case SymbolGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupModifyRequestReceiverModel.verify(), 'manager.SymbolGroupModifyRequest validation failed!')
        let deserialized = this._SymbolGroupModifyRequestReceiverModel.deserialize(this._SymbolGroupModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupModifyRequestReceiverValue)
        this.onReceive_SymbolGroupModifyRequest(this._SymbolGroupModifyRequestReceiverValue)
        return true
      }
      case SymbolGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupModifyResponseReceiverModel.verify(), 'manager.SymbolGroupModifyResponse validation failed!')
        let deserialized = this._SymbolGroupModifyResponseReceiverModel.deserialize(this._SymbolGroupModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupModifyResponseReceiverValue)
        this.onReceive_SymbolGroupModifyResponse(this._SymbolGroupModifyResponseReceiverValue)
        return true
      }
      case SymbolGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupDeleteRequestReceiverModel.verify(), 'manager.SymbolGroupDeleteRequest validation failed!')
        let deserialized = this._SymbolGroupDeleteRequestReceiverModel.deserialize(this._SymbolGroupDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupDeleteRequestReceiverValue)
        this.onReceive_SymbolGroupDeleteRequest(this._SymbolGroupDeleteRequestReceiverValue)
        return true
      }
      case SymbolGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupDeleteResponseReceiverModel.verify(), 'manager.SymbolGroupDeleteResponse validation failed!')
        let deserialized = this._SymbolGroupDeleteResponseReceiverModel.deserialize(this._SymbolGroupDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupDeleteResponseReceiverValue)
        this.onReceive_SymbolGroupDeleteResponse(this._SymbolGroupDeleteResponseReceiverValue)
        return true
      }
      case SymbolGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupUpdateNotifyReceiverModel.verify(), 'manager.SymbolGroupUpdateNotify validation failed!')
        let deserialized = this._SymbolGroupUpdateNotifyReceiverModel.deserialize(this._SymbolGroupUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupUpdateNotifyReceiverValue)
        this.onReceive_SymbolGroupUpdateNotify(this._SymbolGroupUpdateNotifyReceiverValue)
        return true
      }
      case SymbolGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetRequestReceiverModel.verify(), 'manager.SymbolGetRequest validation failed!')
        let deserialized = this._SymbolGetRequestReceiverModel.deserialize(this._SymbolGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetRequestReceiverValue)
        this.onReceive_SymbolGetRequest(this._SymbolGetRequestReceiverValue)
        return true
      }
      case SymbolGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetResponseReceiverModel.verify(), 'manager.SymbolGetResponse validation failed!')
        let deserialized = this._SymbolGetResponseReceiverModel.deserialize(this._SymbolGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetResponseReceiverValue)
        this.onReceive_SymbolGetResponse(this._SymbolGetResponseReceiverValue)
        return true
      }
      case SymbolsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllRequestReceiverModel.verify(), 'manager.SymbolsGetAllRequest validation failed!')
        let deserialized = this._SymbolsGetAllRequestReceiverModel.deserialize(this._SymbolsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllRequestReceiverValue)
        this.onReceive_SymbolsGetAllRequest(this._SymbolsGetAllRequestReceiverValue)
        return true
      }
      case SymbolsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllResponseReceiverModel.verify(), 'manager.SymbolsGetAllResponse validation failed!')
        let deserialized = this._SymbolsGetAllResponseReceiverModel.deserialize(this._SymbolsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllResponseReceiverValue)
        this.onReceive_SymbolsGetAllResponse(this._SymbolsGetAllResponseReceiverValue)
        return true
      }
      case SymbolCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolCreateRequestReceiverModel.verify(), 'manager.SymbolCreateRequest validation failed!')
        let deserialized = this._SymbolCreateRequestReceiverModel.deserialize(this._SymbolCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolCreateRequestReceiverValue)
        this.onReceive_SymbolCreateRequest(this._SymbolCreateRequestReceiverValue)
        return true
      }
      case SymbolCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolCreateResponseReceiverModel.verify(), 'manager.SymbolCreateResponse validation failed!')
        let deserialized = this._SymbolCreateResponseReceiverModel.deserialize(this._SymbolCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolCreateResponseReceiverValue)
        this.onReceive_SymbolCreateResponse(this._SymbolCreateResponseReceiverValue)
        return true
      }
      case SymbolModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolModifyRequestReceiverModel.verify(), 'manager.SymbolModifyRequest validation failed!')
        let deserialized = this._SymbolModifyRequestReceiverModel.deserialize(this._SymbolModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolModifyRequestReceiverValue)
        this.onReceive_SymbolModifyRequest(this._SymbolModifyRequestReceiverValue)
        return true
      }
      case SymbolModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolModifyResponseReceiverModel.verify(), 'manager.SymbolModifyResponse validation failed!')
        let deserialized = this._SymbolModifyResponseReceiverModel.deserialize(this._SymbolModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolModifyResponseReceiverValue)
        this.onReceive_SymbolModifyResponse(this._SymbolModifyResponseReceiverValue)
        return true
      }
      case SymbolDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDeleteRequestReceiverModel.verify(), 'manager.SymbolDeleteRequest validation failed!')
        let deserialized = this._SymbolDeleteRequestReceiverModel.deserialize(this._SymbolDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDeleteRequestReceiverValue)
        this.onReceive_SymbolDeleteRequest(this._SymbolDeleteRequestReceiverValue)
        return true
      }
      case SymbolDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDeleteResponseReceiverModel.verify(), 'manager.SymbolDeleteResponse validation failed!')
        let deserialized = this._SymbolDeleteResponseReceiverModel.deserialize(this._SymbolDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDeleteResponseReceiverValue)
        this.onReceive_SymbolDeleteResponse(this._SymbolDeleteResponseReceiverValue)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyReceiverModel.verify(), 'manager.SymbolUpdateNotify validation failed!')
        let deserialized = this._SymbolUpdateNotifyReceiverModel.deserialize(this._SymbolUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolUpdateNotifyReceiverValue)
        this.onReceive_SymbolUpdateNotify(this._SymbolUpdateNotifyReceiverValue)
        return true
      }
      case SwapsExportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsExportRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsExportRequestReceiverModel.verify(), 'manager.SwapsExportRequest validation failed!')
        let deserialized = this._SwapsExportRequestReceiverModel.deserialize(this._SwapsExportRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SwapsExportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsExportRequestReceiverValue)
        this.onReceive_SwapsExportRequest(this._SwapsExportRequestReceiverValue)
        return true
      }
      case SwapsExportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsExportResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsExportResponseReceiverModel.verify(), 'manager.SwapsExportResponse validation failed!')
        let deserialized = this._SwapsExportResponseReceiverModel.deserialize(this._SwapsExportResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SwapsExportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsExportResponseReceiverValue)
        this.onReceive_SwapsExportResponse(this._SwapsExportResponseReceiverValue)
        return true
      }
      case SwapsImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsImportRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsImportRequestReceiverModel.verify(), 'manager.SwapsImportRequest validation failed!')
        let deserialized = this._SwapsImportRequestReceiverModel.deserialize(this._SwapsImportRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SwapsImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsImportRequestReceiverValue)
        this.onReceive_SwapsImportRequest(this._SwapsImportRequestReceiverValue)
        return true
      }
      case SwapsImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SwapsImportResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SwapsImportResponseReceiverModel.verify(), 'manager.SwapsImportResponse validation failed!')
        let deserialized = this._SwapsImportResponseReceiverModel.deserialize(this._SwapsImportResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SwapsImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SwapsImportResponseReceiverValue)
        this.onReceive_SwapsImportResponse(this._SwapsImportResponseReceiverValue)
        return true
      }
      case CommissionProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileGetRequestReceiverModel.verify(), 'manager.CommissionProfileGetRequest validation failed!')
        let deserialized = this._CommissionProfileGetRequestReceiverModel.deserialize(this._CommissionProfileGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileGetRequestReceiverValue)
        this.onReceive_CommissionProfileGetRequest(this._CommissionProfileGetRequestReceiverValue)
        return true
      }
      case CommissionProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileGetResponseReceiverModel.verify(), 'manager.CommissionProfileGetResponse validation failed!')
        let deserialized = this._CommissionProfileGetResponseReceiverModel.deserialize(this._CommissionProfileGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileGetResponseReceiverValue)
        this.onReceive_CommissionProfileGetResponse(this._CommissionProfileGetResponseReceiverValue)
        return true
      }
      case CommissionProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfilesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfilesGetAllRequestReceiverModel.verify(), 'manager.CommissionProfilesGetAllRequest validation failed!')
        let deserialized = this._CommissionProfilesGetAllRequestReceiverModel.deserialize(this._CommissionProfilesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfilesGetAllRequestReceiverValue)
        this.onReceive_CommissionProfilesGetAllRequest(this._CommissionProfilesGetAllRequestReceiverValue)
        return true
      }
      case CommissionProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfilesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfilesGetAllResponseReceiverModel.verify(), 'manager.CommissionProfilesGetAllResponse validation failed!')
        let deserialized = this._CommissionProfilesGetAllResponseReceiverModel.deserialize(this._CommissionProfilesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfilesGetAllResponseReceiverValue)
        this.onReceive_CommissionProfilesGetAllResponse(this._CommissionProfilesGetAllResponseReceiverValue)
        return true
      }
      case CommissionProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileCreateRequestReceiverModel.verify(), 'manager.CommissionProfileCreateRequest validation failed!')
        let deserialized = this._CommissionProfileCreateRequestReceiverModel.deserialize(this._CommissionProfileCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileCreateRequestReceiverValue)
        this.onReceive_CommissionProfileCreateRequest(this._CommissionProfileCreateRequestReceiverValue)
        return true
      }
      case CommissionProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileCreateResponseReceiverModel.verify(), 'manager.CommissionProfileCreateResponse validation failed!')
        let deserialized = this._CommissionProfileCreateResponseReceiverModel.deserialize(this._CommissionProfileCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileCreateResponseReceiverValue)
        this.onReceive_CommissionProfileCreateResponse(this._CommissionProfileCreateResponseReceiverValue)
        return true
      }
      case CommissionProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileModifyRequestReceiverModel.verify(), 'manager.CommissionProfileModifyRequest validation failed!')
        let deserialized = this._CommissionProfileModifyRequestReceiverModel.deserialize(this._CommissionProfileModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileModifyRequestReceiverValue)
        this.onReceive_CommissionProfileModifyRequest(this._CommissionProfileModifyRequestReceiverValue)
        return true
      }
      case CommissionProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileModifyResponseReceiverModel.verify(), 'manager.CommissionProfileModifyResponse validation failed!')
        let deserialized = this._CommissionProfileModifyResponseReceiverModel.deserialize(this._CommissionProfileModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileModifyResponseReceiverValue)
        this.onReceive_CommissionProfileModifyResponse(this._CommissionProfileModifyResponseReceiverValue)
        return true
      }
      case CommissionProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileDeleteRequestReceiverModel.verify(), 'manager.CommissionProfileDeleteRequest validation failed!')
        let deserialized = this._CommissionProfileDeleteRequestReceiverModel.deserialize(this._CommissionProfileDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileDeleteRequestReceiverValue)
        this.onReceive_CommissionProfileDeleteRequest(this._CommissionProfileDeleteRequestReceiverValue)
        return true
      }
      case CommissionProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileDeleteResponseReceiverModel.verify(), 'manager.CommissionProfileDeleteResponse validation failed!')
        let deserialized = this._CommissionProfileDeleteResponseReceiverModel.deserialize(this._CommissionProfileDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileDeleteResponseReceiverValue)
        this.onReceive_CommissionProfileDeleteResponse(this._CommissionProfileDeleteResponseReceiverValue)
        return true
      }
      case CommissionProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CommissionProfileUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CommissionProfileUpdateNotifyReceiverModel.verify(), 'manager.CommissionProfileUpdateNotify validation failed!')
        let deserialized = this._CommissionProfileUpdateNotifyReceiverModel.deserialize(this._CommissionProfileUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CommissionProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CommissionProfileUpdateNotifyReceiverValue)
        this.onReceive_CommissionProfileUpdateNotify(this._CommissionProfileUpdateNotifyReceiverValue)
        return true
      }
      case MarkupProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileGetRequestReceiverModel.verify(), 'manager.MarkupProfileGetRequest validation failed!')
        let deserialized = this._MarkupProfileGetRequestReceiverModel.deserialize(this._MarkupProfileGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileGetRequestReceiverValue)
        this.onReceive_MarkupProfileGetRequest(this._MarkupProfileGetRequestReceiverValue)
        return true
      }
      case MarkupProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileGetResponseReceiverModel.verify(), 'manager.MarkupProfileGetResponse validation failed!')
        let deserialized = this._MarkupProfileGetResponseReceiverModel.deserialize(this._MarkupProfileGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileGetResponseReceiverValue)
        this.onReceive_MarkupProfileGetResponse(this._MarkupProfileGetResponseReceiverValue)
        return true
      }
      case MarkupProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfilesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfilesGetAllRequestReceiverModel.verify(), 'manager.MarkupProfilesGetAllRequest validation failed!')
        let deserialized = this._MarkupProfilesGetAllRequestReceiverModel.deserialize(this._MarkupProfilesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfilesGetAllRequestReceiverValue)
        this.onReceive_MarkupProfilesGetAllRequest(this._MarkupProfilesGetAllRequestReceiverValue)
        return true
      }
      case MarkupProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfilesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfilesGetAllResponseReceiverModel.verify(), 'manager.MarkupProfilesGetAllResponse validation failed!')
        let deserialized = this._MarkupProfilesGetAllResponseReceiverModel.deserialize(this._MarkupProfilesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfilesGetAllResponseReceiverValue)
        this.onReceive_MarkupProfilesGetAllResponse(this._MarkupProfilesGetAllResponseReceiverValue)
        return true
      }
      case MarkupProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileCreateRequestReceiverModel.verify(), 'manager.MarkupProfileCreateRequest validation failed!')
        let deserialized = this._MarkupProfileCreateRequestReceiverModel.deserialize(this._MarkupProfileCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileCreateRequestReceiverValue)
        this.onReceive_MarkupProfileCreateRequest(this._MarkupProfileCreateRequestReceiverValue)
        return true
      }
      case MarkupProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileCreateResponseReceiverModel.verify(), 'manager.MarkupProfileCreateResponse validation failed!')
        let deserialized = this._MarkupProfileCreateResponseReceiverModel.deserialize(this._MarkupProfileCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileCreateResponseReceiverValue)
        this.onReceive_MarkupProfileCreateResponse(this._MarkupProfileCreateResponseReceiverValue)
        return true
      }
      case MarkupProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileModifyRequestReceiverModel.verify(), 'manager.MarkupProfileModifyRequest validation failed!')
        let deserialized = this._MarkupProfileModifyRequestReceiverModel.deserialize(this._MarkupProfileModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileModifyRequestReceiverValue)
        this.onReceive_MarkupProfileModifyRequest(this._MarkupProfileModifyRequestReceiverValue)
        return true
      }
      case MarkupProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileModifyResponseReceiverModel.verify(), 'manager.MarkupProfileModifyResponse validation failed!')
        let deserialized = this._MarkupProfileModifyResponseReceiverModel.deserialize(this._MarkupProfileModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileModifyResponseReceiverValue)
        this.onReceive_MarkupProfileModifyResponse(this._MarkupProfileModifyResponseReceiverValue)
        return true
      }
      case MarkupProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileDeleteRequestReceiverModel.verify(), 'manager.MarkupProfileDeleteRequest validation failed!')
        let deserialized = this._MarkupProfileDeleteRequestReceiverModel.deserialize(this._MarkupProfileDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileDeleteRequestReceiverValue)
        this.onReceive_MarkupProfileDeleteRequest(this._MarkupProfileDeleteRequestReceiverValue)
        return true
      }
      case MarkupProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileDeleteResponseReceiverModel.verify(), 'manager.MarkupProfileDeleteResponse validation failed!')
        let deserialized = this._MarkupProfileDeleteResponseReceiverModel.deserialize(this._MarkupProfileDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileDeleteResponseReceiverValue)
        this.onReceive_MarkupProfileDeleteResponse(this._MarkupProfileDeleteResponseReceiverValue)
        return true
      }
      case MarkupProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarkupProfileUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarkupProfileUpdateNotifyReceiverModel.verify(), 'manager.MarkupProfileUpdateNotify validation failed!')
        let deserialized = this._MarkupProfileUpdateNotifyReceiverModel.deserialize(this._MarkupProfileUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarkupProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarkupProfileUpdateNotifyReceiverValue)
        this.onReceive_MarkupProfileUpdateNotify(this._MarkupProfileUpdateNotifyReceiverValue)
        return true
      }
      case ManagerGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupGetRequestReceiverModel.verify(), 'manager.ManagerGroupGetRequest validation failed!')
        let deserialized = this._ManagerGroupGetRequestReceiverModel.deserialize(this._ManagerGroupGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupGetRequestReceiverValue)
        this.onReceive_ManagerGroupGetRequest(this._ManagerGroupGetRequestReceiverValue)
        return true
      }
      case ManagerGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupGetResponseReceiverModel.verify(), 'manager.ManagerGroupGetResponse validation failed!')
        let deserialized = this._ManagerGroupGetResponseReceiverModel.deserialize(this._ManagerGroupGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupGetResponseReceiverValue)
        this.onReceive_ManagerGroupGetResponse(this._ManagerGroupGetResponseReceiverValue)
        return true
      }
      case ManagerGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupsGetAllRequestReceiverModel.verify(), 'manager.ManagerGroupsGetAllRequest validation failed!')
        let deserialized = this._ManagerGroupsGetAllRequestReceiverModel.deserialize(this._ManagerGroupsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupsGetAllRequestReceiverValue)
        this.onReceive_ManagerGroupsGetAllRequest(this._ManagerGroupsGetAllRequestReceiverValue)
        return true
      }
      case ManagerGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupsGetAllResponseReceiverModel.verify(), 'manager.ManagerGroupsGetAllResponse validation failed!')
        let deserialized = this._ManagerGroupsGetAllResponseReceiverModel.deserialize(this._ManagerGroupsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupsGetAllResponseReceiverValue)
        this.onReceive_ManagerGroupsGetAllResponse(this._ManagerGroupsGetAllResponseReceiverValue)
        return true
      }
      case ManagerGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupCreateRequestReceiverModel.verify(), 'manager.ManagerGroupCreateRequest validation failed!')
        let deserialized = this._ManagerGroupCreateRequestReceiverModel.deserialize(this._ManagerGroupCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupCreateRequestReceiverValue)
        this.onReceive_ManagerGroupCreateRequest(this._ManagerGroupCreateRequestReceiverValue)
        return true
      }
      case ManagerGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupCreateResponseReceiverModel.verify(), 'manager.ManagerGroupCreateResponse validation failed!')
        let deserialized = this._ManagerGroupCreateResponseReceiverModel.deserialize(this._ManagerGroupCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupCreateResponseReceiverValue)
        this.onReceive_ManagerGroupCreateResponse(this._ManagerGroupCreateResponseReceiverValue)
        return true
      }
      case ManagerGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupModifyRequestReceiverModel.verify(), 'manager.ManagerGroupModifyRequest validation failed!')
        let deserialized = this._ManagerGroupModifyRequestReceiverModel.deserialize(this._ManagerGroupModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupModifyRequestReceiverValue)
        this.onReceive_ManagerGroupModifyRequest(this._ManagerGroupModifyRequestReceiverValue)
        return true
      }
      case ManagerGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupModifyResponseReceiverModel.verify(), 'manager.ManagerGroupModifyResponse validation failed!')
        let deserialized = this._ManagerGroupModifyResponseReceiverModel.deserialize(this._ManagerGroupModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupModifyResponseReceiverValue)
        this.onReceive_ManagerGroupModifyResponse(this._ManagerGroupModifyResponseReceiverValue)
        return true
      }
      case ManagerGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupDeleteRequestReceiverModel.verify(), 'manager.ManagerGroupDeleteRequest validation failed!')
        let deserialized = this._ManagerGroupDeleteRequestReceiverModel.deserialize(this._ManagerGroupDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupDeleteRequestReceiverValue)
        this.onReceive_ManagerGroupDeleteRequest(this._ManagerGroupDeleteRequestReceiverValue)
        return true
      }
      case ManagerGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupDeleteResponseReceiverModel.verify(), 'manager.ManagerGroupDeleteResponse validation failed!')
        let deserialized = this._ManagerGroupDeleteResponseReceiverModel.deserialize(this._ManagerGroupDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupDeleteResponseReceiverValue)
        this.onReceive_ManagerGroupDeleteResponse(this._ManagerGroupDeleteResponseReceiverValue)
        return true
      }
      case ManagerGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGroupUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGroupUpdateNotifyReceiverModel.verify(), 'manager.ManagerGroupUpdateNotify validation failed!')
        let deserialized = this._ManagerGroupUpdateNotifyReceiverModel.deserialize(this._ManagerGroupUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGroupUpdateNotifyReceiverValue)
        this.onReceive_ManagerGroupUpdateNotify(this._ManagerGroupUpdateNotifyReceiverValue)
        return true
      }
      case ManagerGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGetRequestReceiverModel.verify(), 'manager.ManagerGetRequest validation failed!')
        let deserialized = this._ManagerGetRequestReceiverModel.deserialize(this._ManagerGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGetRequestReceiverValue)
        this.onReceive_ManagerGetRequest(this._ManagerGetRequestReceiverValue)
        return true
      }
      case ManagerGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerGetResponseReceiverModel.verify(), 'manager.ManagerGetResponse validation failed!')
        let deserialized = this._ManagerGetResponseReceiverModel.deserialize(this._ManagerGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerGetResponseReceiverValue)
        this.onReceive_ManagerGetResponse(this._ManagerGetResponseReceiverValue)
        return true
      }
      case ManagersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagersGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagersGetAllRequestReceiverModel.verify(), 'manager.ManagersGetAllRequest validation failed!')
        let deserialized = this._ManagersGetAllRequestReceiverModel.deserialize(this._ManagersGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagersGetAllRequestReceiverValue)
        this.onReceive_ManagersGetAllRequest(this._ManagersGetAllRequestReceiverValue)
        return true
      }
      case ManagersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagersGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagersGetAllResponseReceiverModel.verify(), 'manager.ManagersGetAllResponse validation failed!')
        let deserialized = this._ManagersGetAllResponseReceiverModel.deserialize(this._ManagersGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagersGetAllResponseReceiverValue)
        this.onReceive_ManagersGetAllResponse(this._ManagersGetAllResponseReceiverValue)
        return true
      }
      case ManagerCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerCreateRequestReceiverModel.verify(), 'manager.ManagerCreateRequest validation failed!')
        let deserialized = this._ManagerCreateRequestReceiverModel.deserialize(this._ManagerCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerCreateRequestReceiverValue)
        this.onReceive_ManagerCreateRequest(this._ManagerCreateRequestReceiverValue)
        return true
      }
      case ManagerCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerCreateResponseReceiverModel.verify(), 'manager.ManagerCreateResponse validation failed!')
        let deserialized = this._ManagerCreateResponseReceiverModel.deserialize(this._ManagerCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerCreateResponseReceiverValue)
        this.onReceive_ManagerCreateResponse(this._ManagerCreateResponseReceiverValue)
        return true
      }
      case ManagerModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerModifyRequestReceiverModel.verify(), 'manager.ManagerModifyRequest validation failed!')
        let deserialized = this._ManagerModifyRequestReceiverModel.deserialize(this._ManagerModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerModifyRequestReceiverValue)
        this.onReceive_ManagerModifyRequest(this._ManagerModifyRequestReceiverValue)
        return true
      }
      case ManagerModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerModifyResponseReceiverModel.verify(), 'manager.ManagerModifyResponse validation failed!')
        let deserialized = this._ManagerModifyResponseReceiverModel.deserialize(this._ManagerModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerModifyResponseReceiverValue)
        this.onReceive_ManagerModifyResponse(this._ManagerModifyResponseReceiverValue)
        return true
      }
      case ManagerDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerDeleteRequestReceiverModel.verify(), 'manager.ManagerDeleteRequest validation failed!')
        let deserialized = this._ManagerDeleteRequestReceiverModel.deserialize(this._ManagerDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerDeleteRequestReceiverValue)
        this.onReceive_ManagerDeleteRequest(this._ManagerDeleteRequestReceiverValue)
        return true
      }
      case ManagerDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerDeleteResponseReceiverModel.verify(), 'manager.ManagerDeleteResponse validation failed!')
        let deserialized = this._ManagerDeleteResponseReceiverModel.deserialize(this._ManagerDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerDeleteResponseReceiverValue)
        this.onReceive_ManagerDeleteResponse(this._ManagerDeleteResponseReceiverValue)
        return true
      }
      case ManagerUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ManagerUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ManagerUpdateNotifyReceiverModel.verify(), 'manager.ManagerUpdateNotify validation failed!')
        let deserialized = this._ManagerUpdateNotifyReceiverModel.deserialize(this._ManagerUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ManagerUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ManagerUpdateNotifyReceiverValue)
        this.onReceive_ManagerUpdateNotify(this._ManagerUpdateNotifyReceiverValue)
        return true
      }
      case PlatformGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformGetRequestReceiverModel.verify(), 'manager.PlatformGetRequest validation failed!')
        let deserialized = this._PlatformGetRequestReceiverModel.deserialize(this._PlatformGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformGetRequestReceiverValue)
        this.onReceive_PlatformGetRequest(this._PlatformGetRequestReceiverValue)
        return true
      }
      case PlatformGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformGetResponseReceiverModel.verify(), 'manager.PlatformGetResponse validation failed!')
        let deserialized = this._PlatformGetResponseReceiverModel.deserialize(this._PlatformGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformGetResponseReceiverValue)
        this.onReceive_PlatformGetResponse(this._PlatformGetResponseReceiverValue)
        return true
      }
      case PlatformsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformsGetAllRequestReceiverModel.verify(), 'manager.PlatformsGetAllRequest validation failed!')
        let deserialized = this._PlatformsGetAllRequestReceiverModel.deserialize(this._PlatformsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformsGetAllRequestReceiverValue)
        this.onReceive_PlatformsGetAllRequest(this._PlatformsGetAllRequestReceiverValue)
        return true
      }
      case PlatformsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformsGetAllResponseReceiverModel.verify(), 'manager.PlatformsGetAllResponse validation failed!')
        let deserialized = this._PlatformsGetAllResponseReceiverModel.deserialize(this._PlatformsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformsGetAllResponseReceiverValue)
        this.onReceive_PlatformsGetAllResponse(this._PlatformsGetAllResponseReceiverValue)
        return true
      }
      case PlatformCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformCreateRequestReceiverModel.verify(), 'manager.PlatformCreateRequest validation failed!')
        let deserialized = this._PlatformCreateRequestReceiverModel.deserialize(this._PlatformCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformCreateRequestReceiverValue)
        this.onReceive_PlatformCreateRequest(this._PlatformCreateRequestReceiverValue)
        return true
      }
      case PlatformCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformCreateResponseReceiverModel.verify(), 'manager.PlatformCreateResponse validation failed!')
        let deserialized = this._PlatformCreateResponseReceiverModel.deserialize(this._PlatformCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformCreateResponseReceiverValue)
        this.onReceive_PlatformCreateResponse(this._PlatformCreateResponseReceiverValue)
        return true
      }
      case PlatformModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformModifyRequestReceiverModel.verify(), 'manager.PlatformModifyRequest validation failed!')
        let deserialized = this._PlatformModifyRequestReceiverModel.deserialize(this._PlatformModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformModifyRequestReceiverValue)
        this.onReceive_PlatformModifyRequest(this._PlatformModifyRequestReceiverValue)
        return true
      }
      case PlatformModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformModifyResponseReceiverModel.verify(), 'manager.PlatformModifyResponse validation failed!')
        let deserialized = this._PlatformModifyResponseReceiverModel.deserialize(this._PlatformModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformModifyResponseReceiverValue)
        this.onReceive_PlatformModifyResponse(this._PlatformModifyResponseReceiverValue)
        return true
      }
      case PlatformDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformDeleteRequestReceiverModel.verify(), 'manager.PlatformDeleteRequest validation failed!')
        let deserialized = this._PlatformDeleteRequestReceiverModel.deserialize(this._PlatformDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformDeleteRequestReceiverValue)
        this.onReceive_PlatformDeleteRequest(this._PlatformDeleteRequestReceiverValue)
        return true
      }
      case PlatformDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformDeleteResponseReceiverModel.verify(), 'manager.PlatformDeleteResponse validation failed!')
        let deserialized = this._PlatformDeleteResponseReceiverModel.deserialize(this._PlatformDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformDeleteResponseReceiverValue)
        this.onReceive_PlatformDeleteResponse(this._PlatformDeleteResponseReceiverValue)
        return true
      }
      case PlatformUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformUpdateNotifyReceiverModel.verify(), 'manager.PlatformUpdateNotify validation failed!')
        let deserialized = this._PlatformUpdateNotifyReceiverModel.deserialize(this._PlatformUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PlatformUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformUpdateNotifyReceiverValue)
        this.onReceive_PlatformUpdateNotify(this._PlatformUpdateNotifyReceiverValue)
        return true
      }
      case AccountGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupGetRequestReceiverModel.verify(), 'manager.AccountGroupGetRequest validation failed!')
        let deserialized = this._AccountGroupGetRequestReceiverModel.deserialize(this._AccountGroupGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupGetRequestReceiverValue)
        this.onReceive_AccountGroupGetRequest(this._AccountGroupGetRequestReceiverValue)
        return true
      }
      case AccountGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupGetResponseReceiverModel.verify(), 'manager.AccountGroupGetResponse validation failed!')
        let deserialized = this._AccountGroupGetResponseReceiverModel.deserialize(this._AccountGroupGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupGetResponseReceiverValue)
        this.onReceive_AccountGroupGetResponse(this._AccountGroupGetResponseReceiverValue)
        return true
      }
      case AccountGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupsGetAllRequestReceiverModel.verify(), 'manager.AccountGroupsGetAllRequest validation failed!')
        let deserialized = this._AccountGroupsGetAllRequestReceiverModel.deserialize(this._AccountGroupsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupsGetAllRequestReceiverValue)
        this.onReceive_AccountGroupsGetAllRequest(this._AccountGroupsGetAllRequestReceiverValue)
        return true
      }
      case AccountGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupsGetAllResponseReceiverModel.verify(), 'manager.AccountGroupsGetAllResponse validation failed!')
        let deserialized = this._AccountGroupsGetAllResponseReceiverModel.deserialize(this._AccountGroupsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupsGetAllResponseReceiverValue)
        this.onReceive_AccountGroupsGetAllResponse(this._AccountGroupsGetAllResponseReceiverValue)
        return true
      }
      case AccountGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupCreateRequestReceiverModel.verify(), 'manager.AccountGroupCreateRequest validation failed!')
        let deserialized = this._AccountGroupCreateRequestReceiverModel.deserialize(this._AccountGroupCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupCreateRequestReceiverValue)
        this.onReceive_AccountGroupCreateRequest(this._AccountGroupCreateRequestReceiverValue)
        return true
      }
      case AccountGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupCreateResponseReceiverModel.verify(), 'manager.AccountGroupCreateResponse validation failed!')
        let deserialized = this._AccountGroupCreateResponseReceiverModel.deserialize(this._AccountGroupCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupCreateResponseReceiverValue)
        this.onReceive_AccountGroupCreateResponse(this._AccountGroupCreateResponseReceiverValue)
        return true
      }
      case AccountGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupModifyRequestReceiverModel.verify(), 'manager.AccountGroupModifyRequest validation failed!')
        let deserialized = this._AccountGroupModifyRequestReceiverModel.deserialize(this._AccountGroupModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupModifyRequestReceiverValue)
        this.onReceive_AccountGroupModifyRequest(this._AccountGroupModifyRequestReceiverValue)
        return true
      }
      case AccountGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupModifyResponseReceiverModel.verify(), 'manager.AccountGroupModifyResponse validation failed!')
        let deserialized = this._AccountGroupModifyResponseReceiverModel.deserialize(this._AccountGroupModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupModifyResponseReceiverValue)
        this.onReceive_AccountGroupModifyResponse(this._AccountGroupModifyResponseReceiverValue)
        return true
      }
      case AccountGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupDeleteRequestReceiverModel.verify(), 'manager.AccountGroupDeleteRequest validation failed!')
        let deserialized = this._AccountGroupDeleteRequestReceiverModel.deserialize(this._AccountGroupDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupDeleteRequestReceiverValue)
        this.onReceive_AccountGroupDeleteRequest(this._AccountGroupDeleteRequestReceiverValue)
        return true
      }
      case AccountGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupDeleteResponseReceiverModel.verify(), 'manager.AccountGroupDeleteResponse validation failed!')
        let deserialized = this._AccountGroupDeleteResponseReceiverModel.deserialize(this._AccountGroupDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupDeleteResponseReceiverValue)
        this.onReceive_AccountGroupDeleteResponse(this._AccountGroupDeleteResponseReceiverValue)
        return true
      }
      case AccountGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupUpdateNotifyReceiverModel.verify(), 'manager.AccountGroupUpdateNotify validation failed!')
        let deserialized = this._AccountGroupUpdateNotifyReceiverModel.deserialize(this._AccountGroupUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupUpdateNotifyReceiverValue)
        this.onReceive_AccountGroupUpdateNotify(this._AccountGroupUpdateNotifyReceiverValue)
        return true
      }
      case AccountGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGetRequestReceiverModel.verify(), 'manager.AccountGetRequest validation failed!')
        let deserialized = this._AccountGetRequestReceiverModel.deserialize(this._AccountGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGetRequestReceiverValue)
        this.onReceive_AccountGetRequest(this._AccountGetRequestReceiverValue)
        return true
      }
      case AccountGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGetResponseReceiverModel.verify(), 'manager.AccountGetResponse validation failed!')
        let deserialized = this._AccountGetResponseReceiverModel.deserialize(this._AccountGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGetResponseReceiverValue)
        this.onReceive_AccountGetResponse(this._AccountGetResponseReceiverValue)
        return true
      }
      case AccountsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestReceiverModel.verify(), 'manager.AccountsGetAllRequest validation failed!')
        let deserialized = this._AccountsGetAllRequestReceiverModel.deserialize(this._AccountsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllRequestReceiverValue)
        this.onReceive_AccountsGetAllRequest(this._AccountsGetAllRequestReceiverValue)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseReceiverModel.verify(), 'manager.AccountsGetAllResponse validation failed!')
        let deserialized = this._AccountsGetAllResponseReceiverModel.deserialize(this._AccountsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllResponseReceiverValue)
        this.onReceive_AccountsGetAllResponse(this._AccountsGetAllResponseReceiverValue)
        return true
      }
      case AccountCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountCreateRequestReceiverModel.verify(), 'manager.AccountCreateRequest validation failed!')
        let deserialized = this._AccountCreateRequestReceiverModel.deserialize(this._AccountCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountCreateRequestReceiverValue)
        this.onReceive_AccountCreateRequest(this._AccountCreateRequestReceiverValue)
        return true
      }
      case AccountCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountCreateResponseReceiverModel.verify(), 'manager.AccountCreateResponse validation failed!')
        let deserialized = this._AccountCreateResponseReceiverModel.deserialize(this._AccountCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountCreateResponseReceiverValue)
        this.onReceive_AccountCreateResponse(this._AccountCreateResponseReceiverValue)
        return true
      }
      case AccountSocialTradingMasterCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterCreateRequestReceiverModel.verify(), 'manager.AccountSocialTradingMasterCreateRequest validation failed!')
        let deserialized = this._AccountSocialTradingMasterCreateRequestReceiverModel.deserialize(this._AccountSocialTradingMasterCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingMasterCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterCreateRequestReceiverValue)
        this.onReceive_AccountSocialTradingMasterCreateRequest(this._AccountSocialTradingMasterCreateRequestReceiverValue)
        return true
      }
      case AccountSocialTradingMasterCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterCreateResponseReceiverModel.verify(), 'manager.AccountSocialTradingMasterCreateResponse validation failed!')
        let deserialized = this._AccountSocialTradingMasterCreateResponseReceiverModel.deserialize(this._AccountSocialTradingMasterCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingMasterCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterCreateResponseReceiverValue)
        this.onReceive_AccountSocialTradingMasterCreateResponse(this._AccountSocialTradingMasterCreateResponseReceiverValue)
        return true
      }
      case AccountSocialTradingInvestorCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorCreateRequestReceiverModel.verify(), 'manager.AccountSocialTradingInvestorCreateRequest validation failed!')
        let deserialized = this._AccountSocialTradingInvestorCreateRequestReceiverModel.deserialize(this._AccountSocialTradingInvestorCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingInvestorCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorCreateRequestReceiverValue)
        this.onReceive_AccountSocialTradingInvestorCreateRequest(this._AccountSocialTradingInvestorCreateRequestReceiverValue)
        return true
      }
      case AccountSocialTradingInvestorCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorCreateResponseReceiverModel.verify(), 'manager.AccountSocialTradingInvestorCreateResponse validation failed!')
        let deserialized = this._AccountSocialTradingInvestorCreateResponseReceiverModel.deserialize(this._AccountSocialTradingInvestorCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountSocialTradingInvestorCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorCreateResponseReceiverValue)
        this.onReceive_AccountSocialTradingInvestorCreateResponse(this._AccountSocialTradingInvestorCreateResponseReceiverValue)
        return true
      }
      case AccountModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountModifyRequestReceiverModel.verify(), 'manager.AccountModifyRequest validation failed!')
        let deserialized = this._AccountModifyRequestReceiverModel.deserialize(this._AccountModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountModifyRequestReceiverValue)
        this.onReceive_AccountModifyRequest(this._AccountModifyRequestReceiverValue)
        return true
      }
      case AccountModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountModifyResponseReceiverModel.verify(), 'manager.AccountModifyResponse validation failed!')
        let deserialized = this._AccountModifyResponseReceiverModel.deserialize(this._AccountModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountModifyResponseReceiverValue)
        this.onReceive_AccountModifyResponse(this._AccountModifyResponseReceiverValue)
        return true
      }
      case AccountDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteRequestReceiverModel.verify(), 'manager.AccountDeleteRequest validation failed!')
        let deserialized = this._AccountDeleteRequestReceiverModel.deserialize(this._AccountDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteRequestReceiverValue)
        this.onReceive_AccountDeleteRequest(this._AccountDeleteRequestReceiverValue)
        return true
      }
      case AccountDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteResponseReceiverModel.verify(), 'manager.AccountDeleteResponse validation failed!')
        let deserialized = this._AccountDeleteResponseReceiverModel.deserialize(this._AccountDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteResponseReceiverValue)
        this.onReceive_AccountDeleteResponse(this._AccountDeleteResponseReceiverValue)
        return true
      }
      case DemoAccountsDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountsDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountsDeleteRequestReceiverModel.verify(), 'manager.DemoAccountsDeleteRequest validation failed!')
        let deserialized = this._DemoAccountsDeleteRequestReceiverModel.deserialize(this._DemoAccountsDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DemoAccountsDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountsDeleteRequestReceiverValue)
        this.onReceive_DemoAccountsDeleteRequest(this._DemoAccountsDeleteRequestReceiverValue)
        return true
      }
      case DemoAccountsDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountsDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountsDeleteResponseReceiverModel.verify(), 'manager.DemoAccountsDeleteResponse validation failed!')
        let deserialized = this._DemoAccountsDeleteResponseReceiverModel.deserialize(this._DemoAccountsDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DemoAccountsDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountsDeleteResponseReceiverValue)
        this.onReceive_DemoAccountsDeleteResponse(this._DemoAccountsDeleteResponseReceiverValue)
        return true
      }
      case AccountUpgradeToSocialTradingMasterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingMasterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingMasterRequestReceiverModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterRequest validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingMasterRequestReceiverModel.deserialize(this._AccountUpgradeToSocialTradingMasterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingMasterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingMasterRequestReceiverValue)
        this.onReceive_AccountUpgradeToSocialTradingMasterRequest(this._AccountUpgradeToSocialTradingMasterRequestReceiverValue)
        return true
      }
      case AccountUpgradeToSocialTradingMasterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingMasterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingMasterResponseReceiverModel.verify(), 'manager.AccountUpgradeToSocialTradingMasterResponse validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingMasterResponseReceiverModel.deserialize(this._AccountUpgradeToSocialTradingMasterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingMasterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingMasterResponseReceiverValue)
        this.onReceive_AccountUpgradeToSocialTradingMasterResponse(this._AccountUpgradeToSocialTradingMasterResponseReceiverValue)
        return true
      }
      case AccountUpgradeToSocialTradingInvestorRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingInvestorRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingInvestorRequestReceiverModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorRequest validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingInvestorRequestReceiverModel.deserialize(this._AccountUpgradeToSocialTradingInvestorRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingInvestorRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingInvestorRequestReceiverValue)
        this.onReceive_AccountUpgradeToSocialTradingInvestorRequest(this._AccountUpgradeToSocialTradingInvestorRequestReceiverValue)
        return true
      }
      case AccountUpgradeToSocialTradingInvestorResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpgradeToSocialTradingInvestorResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpgradeToSocialTradingInvestorResponseReceiverModel.verify(), 'manager.AccountUpgradeToSocialTradingInvestorResponse validation failed!')
        let deserialized = this._AccountUpgradeToSocialTradingInvestorResponseReceiverModel.deserialize(this._AccountUpgradeToSocialTradingInvestorResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpgradeToSocialTradingInvestorResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpgradeToSocialTradingInvestorResponseReceiverValue)
        this.onReceive_AccountUpgradeToSocialTradingInvestorResponse(this._AccountUpgradeToSocialTradingInvestorResponseReceiverValue)
        return true
      }
      case SocialTradingInvestorAccountToRegularRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingInvestorAccountToRegularRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingInvestorAccountToRegularRequestReceiverModel.verify(), 'manager.SocialTradingInvestorAccountToRegularRequest validation failed!')
        let deserialized = this._SocialTradingInvestorAccountToRegularRequestReceiverModel.deserialize(this._SocialTradingInvestorAccountToRegularRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingInvestorAccountToRegularRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingInvestorAccountToRegularRequestReceiverValue)
        this.onReceive_SocialTradingInvestorAccountToRegularRequest(this._SocialTradingInvestorAccountToRegularRequestReceiverValue)
        return true
      }
      case SocialTradingInvestorAccountToRegularResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingInvestorAccountToRegularResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingInvestorAccountToRegularResponseReceiverModel.verify(), 'manager.SocialTradingInvestorAccountToRegularResponse validation failed!')
        let deserialized = this._SocialTradingInvestorAccountToRegularResponseReceiverModel.deserialize(this._SocialTradingInvestorAccountToRegularResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingInvestorAccountToRegularResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingInvestorAccountToRegularResponseReceiverValue)
        this.onReceive_SocialTradingInvestorAccountToRegularResponse(this._SocialTradingInvestorAccountToRegularResponseReceiverValue)
        return true
      }
      case SocialTradingMasterAccountToRegularRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingMasterAccountToRegularRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingMasterAccountToRegularRequestReceiverModel.verify(), 'manager.SocialTradingMasterAccountToRegularRequest validation failed!')
        let deserialized = this._SocialTradingMasterAccountToRegularRequestReceiverModel.deserialize(this._SocialTradingMasterAccountToRegularRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingMasterAccountToRegularRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingMasterAccountToRegularRequestReceiverValue)
        this.onReceive_SocialTradingMasterAccountToRegularRequest(this._SocialTradingMasterAccountToRegularRequestReceiverValue)
        return true
      }
      case SocialTradingMasterAccountToRegularResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SocialTradingMasterAccountToRegularResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SocialTradingMasterAccountToRegularResponseReceiverModel.verify(), 'manager.SocialTradingMasterAccountToRegularResponse validation failed!')
        let deserialized = this._SocialTradingMasterAccountToRegularResponseReceiverModel.deserialize(this._SocialTradingMasterAccountToRegularResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SocialTradingMasterAccountToRegularResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SocialTradingMasterAccountToRegularResponseReceiverValue)
        this.onReceive_SocialTradingMasterAccountToRegularResponse(this._SocialTradingMasterAccountToRegularResponseReceiverValue)
        return true
      }
      case AccountUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateNotifyReceiverModel.verify(), 'manager.AccountUpdateNotify validation failed!')
        let deserialized = this._AccountUpdateNotifyReceiverModel.deserialize(this._AccountUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateNotifyReceiverValue)
        this.onReceive_AccountUpdateNotify(this._AccountUpdateNotifyReceiverValue)
        return true
      }
      case MarginGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginGetRequestReceiverModel.verify(), 'manager.MarginGetRequest validation failed!')
        let deserialized = this._MarginGetRequestReceiverModel.deserialize(this._MarginGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginGetRequestReceiverValue)
        this.onReceive_MarginGetRequest(this._MarginGetRequestReceiverValue)
        return true
      }
      case MarginGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginGetResponseReceiverModel.verify(), 'manager.MarginGetResponse validation failed!')
        let deserialized = this._MarginGetResponseReceiverModel.deserialize(this._MarginGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginGetResponseReceiverValue)
        this.onReceive_MarginGetResponse(this._MarginGetResponseReceiverValue)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestReceiverModel.verify(), 'manager.MarginsGetAllRequest validation failed!')
        let deserialized = this._MarginsGetAllRequestReceiverModel.deserialize(this._MarginsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllRequestReceiverValue)
        this.onReceive_MarginsGetAllRequest(this._MarginsGetAllRequestReceiverValue)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseReceiverModel.verify(), 'manager.MarginsGetAllResponse validation failed!')
        let deserialized = this._MarginsGetAllResponseReceiverModel.deserialize(this._MarginsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllResponseReceiverValue)
        this.onReceive_MarginsGetAllResponse(this._MarginsGetAllResponseReceiverValue)
        return true
      }
      case MarginUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateNotifyReceiverModel.verify(), 'manager.MarginUpdateNotify validation failed!')
        let deserialized = this._MarginUpdateNotifyReceiverModel.deserialize(this._MarginUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateNotifyReceiverValue)
        this.onReceive_MarginUpdateNotify(this._MarginUpdateNotifyReceiverValue)
        return true
      }
      case MarginCallNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginCallNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginCallNotifyReceiverModel.verify(), 'manager.MarginCallNotify validation failed!')
        let deserialized = this._MarginCallNotifyReceiverModel.deserialize(this._MarginCallNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginCallNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginCallNotifyReceiverValue)
        this.onReceive_MarginCallNotify(this._MarginCallNotifyReceiverValue)
        return true
      }
      case StopOutNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StopOutNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StopOutNotifyReceiverModel.verify(), 'manager.StopOutNotify validation failed!')
        let deserialized = this._StopOutNotifyReceiverModel.deserialize(this._StopOutNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.StopOutNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StopOutNotifyReceiverValue)
        this.onReceive_StopOutNotify(this._StopOutNotifyReceiverValue)
        return true
      }
      case AssetGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetGetRequestReceiverModel.verify(), 'manager.AssetGetRequest validation failed!')
        let deserialized = this._AssetGetRequestReceiverModel.deserialize(this._AssetGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AssetGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetGetRequestReceiverValue)
        this.onReceive_AssetGetRequest(this._AssetGetRequestReceiverValue)
        return true
      }
      case AssetGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetGetResponseReceiverModel.verify(), 'manager.AssetGetResponse validation failed!')
        let deserialized = this._AssetGetResponseReceiverModel.deserialize(this._AssetGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AssetGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetGetResponseReceiverValue)
        this.onReceive_AssetGetResponse(this._AssetGetResponseReceiverValue)
        return true
      }
      case AssetsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetRequestReceiverModel.verify(), 'manager.AssetsGetRequest validation failed!')
        let deserialized = this._AssetsGetRequestReceiverModel.deserialize(this._AssetsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetRequestReceiverValue)
        this.onReceive_AssetsGetRequest(this._AssetsGetRequestReceiverValue)
        return true
      }
      case AssetsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetResponseReceiverModel.verify(), 'manager.AssetsGetResponse validation failed!')
        let deserialized = this._AssetsGetResponseReceiverModel.deserialize(this._AssetsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetResponseReceiverValue)
        this.onReceive_AssetsGetResponse(this._AssetsGetResponseReceiverValue)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestReceiverModel.verify(), 'manager.AssetsGetAllRequest validation failed!')
        let deserialized = this._AssetsGetAllRequestReceiverModel.deserialize(this._AssetsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllRequestReceiverValue)
        this.onReceive_AssetsGetAllRequest(this._AssetsGetAllRequestReceiverValue)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseReceiverModel.verify(), 'manager.AssetsGetAllResponse validation failed!')
        let deserialized = this._AssetsGetAllResponseReceiverModel.deserialize(this._AssetsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AssetsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllResponseReceiverValue)
        this.onReceive_AssetsGetAllResponse(this._AssetsGetAllResponseReceiverValue)
        return true
      }
      case AssetUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateNotifyReceiverModel.verify(), 'manager.AssetUpdateNotify validation failed!')
        let deserialized = this._AssetUpdateNotifyReceiverModel.deserialize(this._AssetUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AssetUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdateNotifyReceiverValue)
        this.onReceive_AssetUpdateNotify(this._AssetUpdateNotifyReceiverValue)
        return true
      }
      case OrderGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderGetRequestReceiverModel.verify(), 'manager.OrderGetRequest validation failed!')
        let deserialized = this._OrderGetRequestReceiverModel.deserialize(this._OrderGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderGetRequestReceiverValue)
        this.onReceive_OrderGetRequest(this._OrderGetRequestReceiverValue)
        return true
      }
      case OrderGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderGetResponseReceiverModel.verify(), 'manager.OrderGetResponse validation failed!')
        let deserialized = this._OrderGetResponseReceiverModel.deserialize(this._OrderGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderGetResponseReceiverValue)
        this.onReceive_OrderGetResponse(this._OrderGetResponseReceiverValue)
        return true
      }
      case OrdersGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetRequestReceiverModel.verify(), 'manager.OrdersGetRequest validation failed!')
        let deserialized = this._OrdersGetRequestReceiverModel.deserialize(this._OrdersGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetRequestReceiverValue)
        this.onReceive_OrdersGetRequest(this._OrdersGetRequestReceiverValue)
        return true
      }
      case OrdersGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetResponseReceiverModel.verify(), 'manager.OrdersGetResponse validation failed!')
        let deserialized = this._OrdersGetResponseReceiverModel.deserialize(this._OrdersGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetResponseReceiverValue)
        this.onReceive_OrdersGetResponse(this._OrdersGetResponseReceiverValue)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestReceiverModel.verify(), 'manager.OrdersGetAllRequest validation failed!')
        let deserialized = this._OrdersGetAllRequestReceiverModel.deserialize(this._OrdersGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllRequestReceiverValue)
        this.onReceive_OrdersGetAllRequest(this._OrdersGetAllRequestReceiverValue)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseReceiverModel.verify(), 'manager.OrdersGetAllResponse validation failed!')
        let deserialized = this._OrdersGetAllResponseReceiverModel.deserialize(this._OrdersGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllResponseReceiverValue)
        this.onReceive_OrdersGetAllResponse(this._OrdersGetAllResponseReceiverValue)
        return true
      }
      case OrderCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCreateRequestReceiverModel.verify(), 'manager.OrderCreateRequest validation failed!')
        let deserialized = this._OrderCreateRequestReceiverModel.deserialize(this._OrderCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCreateRequestReceiverValue)
        this.onReceive_OrderCreateRequest(this._OrderCreateRequestReceiverValue)
        return true
      }
      case OrderCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCreateResponseReceiverModel.verify(), 'manager.OrderCreateResponse validation failed!')
        let deserialized = this._OrderCreateResponseReceiverModel.deserialize(this._OrderCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCreateResponseReceiverValue)
        this.onReceive_OrderCreateResponse(this._OrderCreateResponseReceiverValue)
        return true
      }
      case OrderModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderModifyRequestReceiverModel.verify(), 'manager.OrderModifyRequest validation failed!')
        let deserialized = this._OrderModifyRequestReceiverModel.deserialize(this._OrderModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderModifyRequestReceiverValue)
        this.onReceive_OrderModifyRequest(this._OrderModifyRequestReceiverValue)
        return true
      }
      case OrderModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderModifyResponseReceiverModel.verify(), 'manager.OrderModifyResponse validation failed!')
        let deserialized = this._OrderModifyResponseReceiverModel.deserialize(this._OrderModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderModifyResponseReceiverValue)
        this.onReceive_OrderModifyResponse(this._OrderModifyResponseReceiverValue)
        return true
      }
      case OrderActivateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderActivateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderActivateRequestReceiverModel.verify(), 'manager.OrderActivateRequest validation failed!')
        let deserialized = this._OrderActivateRequestReceiverModel.deserialize(this._OrderActivateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderActivateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderActivateRequestReceiverValue)
        this.onReceive_OrderActivateRequest(this._OrderActivateRequestReceiverValue)
        return true
      }
      case OrderActivateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderActivateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderActivateResponseReceiverModel.verify(), 'manager.OrderActivateResponse validation failed!')
        let deserialized = this._OrderActivateResponseReceiverModel.deserialize(this._OrderActivateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderActivateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderActivateResponseReceiverValue)
        this.onReceive_OrderActivateResponse(this._OrderActivateResponseReceiverValue)
        return true
      }
      case OrderCancelRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelRequestReceiverModel.verify(), 'manager.OrderCancelRequest validation failed!')
        let deserialized = this._OrderCancelRequestReceiverModel.deserialize(this._OrderCancelRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelRequestReceiverValue)
        this.onReceive_OrderCancelRequest(this._OrderCancelRequestReceiverValue)
        return true
      }
      case OrderCancelResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelResponseReceiverModel.verify(), 'manager.OrderCancelResponse validation failed!')
        let deserialized = this._OrderCancelResponseReceiverModel.deserialize(this._OrderCancelResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelResponseReceiverValue)
        this.onReceive_OrderCancelResponse(this._OrderCancelResponseReceiverValue)
        return true
      }
      case OrderCancelAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllRequestReceiverModel.verify(), 'manager.OrderCancelAllRequest validation failed!')
        let deserialized = this._OrderCancelAllRequestReceiverModel.deserialize(this._OrderCancelAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllRequestReceiverValue)
        this.onReceive_OrderCancelAllRequest(this._OrderCancelAllRequestReceiverValue)
        return true
      }
      case OrderCancelAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllResponseReceiverModel.verify(), 'manager.OrderCancelAllResponse validation failed!')
        let deserialized = this._OrderCancelAllResponseReceiverModel.deserialize(this._OrderCancelAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllResponseReceiverValue)
        this.onReceive_OrderCancelAllResponse(this._OrderCancelAllResponseReceiverValue)
        return true
      }
      case OrderCancelAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllByIdRequestReceiverModel.verify(), 'manager.OrderCancelAllByIdRequest validation failed!')
        let deserialized = this._OrderCancelAllByIdRequestReceiverModel.deserialize(this._OrderCancelAllByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllByIdRequestReceiverValue)
        this.onReceive_OrderCancelAllByIdRequest(this._OrderCancelAllByIdRequestReceiverValue)
        return true
      }
      case OrderCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseRequestReceiverModel.verify(), 'manager.OrderCloseRequest validation failed!')
        let deserialized = this._OrderCloseRequestReceiverModel.deserialize(this._OrderCloseRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseRequestReceiverValue)
        this.onReceive_OrderCloseRequest(this._OrderCloseRequestReceiverValue)
        return true
      }
      case OrderCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseResponseReceiverModel.verify(), 'manager.OrderCloseResponse validation failed!')
        let deserialized = this._OrderCloseResponseReceiverModel.deserialize(this._OrderCloseResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseResponseReceiverValue)
        this.onReceive_OrderCloseResponse(this._OrderCloseResponseReceiverValue)
        return true
      }
      case OrderCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllRequestReceiverModel.verify(), 'manager.OrderCloseAllRequest validation failed!')
        let deserialized = this._OrderCloseAllRequestReceiverModel.deserialize(this._OrderCloseAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllRequestReceiverValue)
        this.onReceive_OrderCloseAllRequest(this._OrderCloseAllRequestReceiverValue)
        return true
      }
      case OrderCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllResponseReceiverModel.verify(), 'manager.OrderCloseAllResponse validation failed!')
        let deserialized = this._OrderCloseAllResponseReceiverModel.deserialize(this._OrderCloseAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllResponseReceiverValue)
        this.onReceive_OrderCloseAllResponse(this._OrderCloseAllResponseReceiverValue)
        return true
      }
      case OrderCloseAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllByIdRequestReceiverModel.verify(), 'manager.OrderCloseAllByIdRequest validation failed!')
        let deserialized = this._OrderCloseAllByIdRequestReceiverModel.deserialize(this._OrderCloseAllByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllByIdRequestReceiverValue)
        this.onReceive_OrderCloseAllByIdRequest(this._OrderCloseAllByIdRequestReceiverValue)
        return true
      }
      case OrderCloseByRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByRequestReceiverModel.verify(), 'manager.OrderCloseByRequest validation failed!')
        let deserialized = this._OrderCloseByRequestReceiverModel.deserialize(this._OrderCloseByRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByRequestReceiverValue)
        this.onReceive_OrderCloseByRequest(this._OrderCloseByRequestReceiverValue)
        return true
      }
      case OrderCloseByResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByResponseReceiverModel.verify(), 'manager.OrderCloseByResponse validation failed!')
        let deserialized = this._OrderCloseByResponseReceiverModel.deserialize(this._OrderCloseByResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByResponseReceiverValue)
        this.onReceive_OrderCloseByResponse(this._OrderCloseByResponseReceiverValue)
        return true
      }
      case OrderCloseByAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllRequestReceiverModel.verify(), 'manager.OrderCloseByAllRequest validation failed!')
        let deserialized = this._OrderCloseByAllRequestReceiverModel.deserialize(this._OrderCloseByAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllRequestReceiverValue)
        this.onReceive_OrderCloseByAllRequest(this._OrderCloseByAllRequestReceiverValue)
        return true
      }
      case OrderCloseByAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllResponseReceiverModel.verify(), 'manager.OrderCloseByAllResponse validation failed!')
        let deserialized = this._OrderCloseByAllResponseReceiverModel.deserialize(this._OrderCloseByAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCloseByAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllResponseReceiverValue)
        this.onReceive_OrderCloseByAllResponse(this._OrderCloseByAllResponseReceiverValue)
        return true
      }
      case OrderCancelOrCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseRequestReceiverModel.verify(), 'manager.OrderCancelOrCloseRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseRequestReceiverModel.deserialize(this._OrderCancelOrCloseRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseRequestReceiverValue)
        this.onReceive_OrderCancelOrCloseRequest(this._OrderCancelOrCloseRequestReceiverValue)
        return true
      }
      case OrderCancelOrCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseResponseReceiverModel.verify(), 'manager.OrderCancelOrCloseResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseResponseReceiverModel.deserialize(this._OrderCancelOrCloseResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseResponseReceiverValue)
        this.onReceive_OrderCancelOrCloseResponse(this._OrderCancelOrCloseResponseReceiverValue)
        return true
      }
      case OrderCancelOrCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllRequestReceiverModel.verify(), 'manager.OrderCancelOrCloseAllRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseAllRequestReceiverModel.deserialize(this._OrderCancelOrCloseAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllRequestReceiverValue)
        this.onReceive_OrderCancelOrCloseAllRequest(this._OrderCancelOrCloseAllRequestReceiverValue)
        return true
      }
      case OrderCancelOrCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllResponseReceiverModel.verify(), 'manager.OrderCancelOrCloseAllResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseAllResponseReceiverModel.deserialize(this._OrderCancelOrCloseAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderCancelOrCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllResponseReceiverValue)
        this.onReceive_OrderCancelOrCloseAllResponse(this._OrderCancelOrCloseAllResponseReceiverValue)
        return true
      }
      case OrderUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateNotifyReceiverModel.verify(), 'manager.OrderUpdateNotify validation failed!')
        let deserialized = this._OrderUpdateNotifyReceiverModel.deserialize(this._OrderUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateNotifyReceiverValue)
        this.onReceive_OrderUpdateNotify(this._OrderUpdateNotifyReceiverValue)
        return true
      }
      case PositionGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionGetRequestReceiverModel.verify(), 'manager.PositionGetRequest validation failed!')
        let deserialized = this._PositionGetRequestReceiverModel.deserialize(this._PositionGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PositionGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionGetRequestReceiverValue)
        this.onReceive_PositionGetRequest(this._PositionGetRequestReceiverValue)
        return true
      }
      case PositionGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionGetResponseReceiverModel.verify(), 'manager.PositionGetResponse validation failed!')
        let deserialized = this._PositionGetResponseReceiverModel.deserialize(this._PositionGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PositionGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionGetResponseReceiverValue)
        this.onReceive_PositionGetResponse(this._PositionGetResponseReceiverValue)
        return true
      }
      case PositionsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetRequestReceiverModel.verify(), 'manager.PositionsGetRequest validation failed!')
        let deserialized = this._PositionsGetRequestReceiverModel.deserialize(this._PositionsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetRequestReceiverValue)
        this.onReceive_PositionsGetRequest(this._PositionsGetRequestReceiverValue)
        return true
      }
      case PositionsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetResponseReceiverModel.verify(), 'manager.PositionsGetResponse validation failed!')
        let deserialized = this._PositionsGetResponseReceiverModel.deserialize(this._PositionsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetResponseReceiverValue)
        this.onReceive_PositionsGetResponse(this._PositionsGetResponseReceiverValue)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestReceiverModel.verify(), 'manager.PositionsGetAllRequest validation failed!')
        let deserialized = this._PositionsGetAllRequestReceiverModel.deserialize(this._PositionsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllRequestReceiverValue)
        this.onReceive_PositionsGetAllRequest(this._PositionsGetAllRequestReceiverValue)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseReceiverModel.verify(), 'manager.PositionsGetAllResponse validation failed!')
        let deserialized = this._PositionsGetAllResponseReceiverModel.deserialize(this._PositionsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PositionsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllResponseReceiverValue)
        this.onReceive_PositionsGetAllResponse(this._PositionsGetAllResponseReceiverValue)
        return true
      }
      case PositionUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateNotifyReceiverModel.verify(), 'manager.PositionUpdateNotify validation failed!')
        let deserialized = this._PositionUpdateNotifyReceiverModel.deserialize(this._PositionUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PositionUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdateNotifyReceiverValue)
        this.onReceive_PositionUpdateNotify(this._PositionUpdateNotifyReceiverValue)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestReceiverModel.verify(), 'manager.TradeHistoryRequest validation failed!')
        let deserialized = this._TradeHistoryRequestReceiverModel.deserialize(this._TradeHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryRequestReceiverValue)
        this.onReceive_TradeHistoryRequest(this._TradeHistoryRequestReceiverValue)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseReceiverModel.verify(), 'manager.TradeHistoryResponse validation failed!')
        let deserialized = this._TradeHistoryResponseReceiverModel.deserialize(this._TradeHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryResponseReceiverValue)
        this.onReceive_TradeHistoryResponse(this._TradeHistoryResponseReceiverValue)
        return true
      }
      case TradeHistoryFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryFilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryFilterRequestReceiverModel.verify(), 'manager.TradeHistoryFilterRequest validation failed!')
        let deserialized = this._TradeHistoryFilterRequestReceiverModel.deserialize(this._TradeHistoryFilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryFilterRequestReceiverValue)
        this.onReceive_TradeHistoryFilterRequest(this._TradeHistoryFilterRequestReceiverValue)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestReceiverModel.verify(), 'manager.TradeHistoryByIdRequest validation failed!')
        let deserialized = this._TradeHistoryByIdRequestReceiverModel.deserialize(this._TradeHistoryByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdRequestReceiverValue)
        this.onReceive_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestReceiverValue)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseReceiverModel.verify(), 'manager.TradeHistoryByIdResponse validation failed!')
        let deserialized = this._TradeHistoryByIdResponseReceiverModel.deserialize(this._TradeHistoryByIdResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdResponseReceiverValue)
        this.onReceive_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseReceiverValue)
        return true
      }
      case TradeHistoryUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryUpdateNotifyReceiverModel.verify(), 'manager.TradeHistoryUpdateNotify validation failed!')
        let deserialized = this._TradeHistoryUpdateNotifyReceiverModel.deserialize(this._TradeHistoryUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeHistoryUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryUpdateNotifyReceiverValue)
        this.onReceive_TradeHistoryUpdateNotify(this._TradeHistoryUpdateNotifyReceiverValue)
        return true
      }
      case TestRolloverDumpRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TestRolloverDumpRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TestRolloverDumpRequestReceiverModel.verify(), 'manager.TestRolloverDumpRequest validation failed!')
        let deserialized = this._TestRolloverDumpRequestReceiverModel.deserialize(this._TestRolloverDumpRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TestRolloverDumpRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TestRolloverDumpRequestReceiverValue)
        this.onReceive_TestRolloverDumpRequest(this._TestRolloverDumpRequestReceiverValue)
        return true
      }
      case TestRolloverDumpResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TestRolloverDumpResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TestRolloverDumpResponseReceiverModel.verify(), 'manager.TestRolloverDumpResponse validation failed!')
        let deserialized = this._TestRolloverDumpResponseReceiverModel.deserialize(this._TestRolloverDumpResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TestRolloverDumpResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TestRolloverDumpResponseReceiverValue)
        this.onReceive_TestRolloverDumpResponse(this._TestRolloverDumpResponseReceiverValue)
        return true
      }
      case Deprecated001Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated001ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated001ReceiverModel.verify(), 'manager.Deprecated001 validation failed!')
        let deserialized = this._Deprecated001ReceiverModel.deserialize(this._Deprecated001ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated001 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated001ReceiverValue)
        this.onReceive_Deprecated001(this._Deprecated001ReceiverValue)
        return true
      }
      case Deprecated002Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated002ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated002ReceiverModel.verify(), 'manager.Deprecated002 validation failed!')
        let deserialized = this._Deprecated002ReceiverModel.deserialize(this._Deprecated002ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated002 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated002ReceiverValue)
        this.onReceive_Deprecated002(this._Deprecated002ReceiverValue)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated003ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003ReceiverModel.verify(), 'manager.Deprecated003 validation failed!')
        let deserialized = this._Deprecated003ReceiverModel.deserialize(this._Deprecated003ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated003 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated003ReceiverValue)
        this.onReceive_Deprecated003(this._Deprecated003ReceiverValue)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated004ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004ReceiverModel.verify(), 'manager.Deprecated004 validation failed!')
        let deserialized = this._Deprecated004ReceiverModel.deserialize(this._Deprecated004ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated004 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated004ReceiverValue)
        this.onReceive_Deprecated004(this._Deprecated004ReceiverValue)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated005ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005ReceiverModel.verify(), 'manager.Deprecated005 validation failed!')
        let deserialized = this._Deprecated005ReceiverModel.deserialize(this._Deprecated005ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated005 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated005ReceiverValue)
        this.onReceive_Deprecated005(this._Deprecated005ReceiverValue)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated006ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006ReceiverModel.verify(), 'manager.Deprecated006 validation failed!')
        let deserialized = this._Deprecated006ReceiverModel.deserialize(this._Deprecated006ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated006 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated006ReceiverValue)
        this.onReceive_Deprecated006(this._Deprecated006ReceiverValue)
        return true
      }
      case Deprecated007Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated007ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated007ReceiverModel.verify(), 'manager.Deprecated007 validation failed!')
        let deserialized = this._Deprecated007ReceiverModel.deserialize(this._Deprecated007ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated007 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated007ReceiverValue)
        this.onReceive_Deprecated007(this._Deprecated007ReceiverValue)
        return true
      }
      case Deprecated008Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated008ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated008ReceiverModel.verify(), 'manager.Deprecated008 validation failed!')
        let deserialized = this._Deprecated008ReceiverModel.deserialize(this._Deprecated008ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated008 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated008ReceiverValue)
        this.onReceive_Deprecated008(this._Deprecated008ReceiverValue)
        return true
      }
      case Deprecated009Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated009ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated009ReceiverModel.verify(), 'manager.Deprecated009 validation failed!')
        let deserialized = this._Deprecated009ReceiverModel.deserialize(this._Deprecated009ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated009 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated009ReceiverValue)
        this.onReceive_Deprecated009(this._Deprecated009ReceiverValue)
        return true
      }
      case Deprecated010Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated010ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated010ReceiverModel.verify(), 'manager.Deprecated010 validation failed!')
        let deserialized = this._Deprecated010ReceiverModel.deserialize(this._Deprecated010ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated010 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated010ReceiverValue)
        this.onReceive_Deprecated010(this._Deprecated010ReceiverValue)
        return true
      }
      case Deprecated011Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated011ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated011ReceiverModel.verify(), 'manager.Deprecated011 validation failed!')
        let deserialized = this._Deprecated011ReceiverModel.deserialize(this._Deprecated011ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated011 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated011ReceiverValue)
        this.onReceive_Deprecated011(this._Deprecated011ReceiverValue)
        return true
      }
      case Deprecated012Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated012ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated012ReceiverModel.verify(), 'manager.Deprecated012 validation failed!')
        let deserialized = this._Deprecated012ReceiverModel.deserialize(this._Deprecated012ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated012 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated012ReceiverValue)
        this.onReceive_Deprecated012(this._Deprecated012ReceiverValue)
        return true
      }
      case Deprecated013Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated013ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated013ReceiverModel.verify(), 'manager.Deprecated013 validation failed!')
        let deserialized = this._Deprecated013ReceiverModel.deserialize(this._Deprecated013ReceiverValue)
        console.assert((deserialized.size > 0), 'manager.Deprecated013 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated013ReceiverValue)
        this.onReceive_Deprecated013(this._Deprecated013ReceiverValue)
        return true
      }
      case DepositAccountRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositAccountRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DepositAccountRequestReceiverModel.verify(), 'manager.DepositAccountRequest validation failed!')
        let deserialized = this._DepositAccountRequestReceiverModel.deserialize(this._DepositAccountRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DepositAccountRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositAccountRequestReceiverValue)
        this.onReceive_DepositAccountRequest(this._DepositAccountRequestReceiverValue)
        return true
      }
      case DepositAccountResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositAccountResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DepositAccountResponseReceiverModel.verify(), 'manager.DepositAccountResponse validation failed!')
        let deserialized = this._DepositAccountResponseReceiverModel.deserialize(this._DepositAccountResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DepositAccountResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositAccountResponseReceiverValue)
        this.onReceive_DepositAccountResponse(this._DepositAccountResponseReceiverValue)
        return true
      }
      case CreditAccountRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CreditAccountRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CreditAccountRequestReceiverModel.verify(), 'manager.CreditAccountRequest validation failed!')
        let deserialized = this._CreditAccountRequestReceiverModel.deserialize(this._CreditAccountRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CreditAccountRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CreditAccountRequestReceiverValue)
        this.onReceive_CreditAccountRequest(this._CreditAccountRequestReceiverValue)
        return true
      }
      case CreditAccountResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CreditAccountResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CreditAccountResponseReceiverModel.verify(), 'manager.CreditAccountResponse validation failed!')
        let deserialized = this._CreditAccountResponseReceiverModel.deserialize(this._CreditAccountResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.CreditAccountResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CreditAccountResponseReceiverValue)
        this.onReceive_CreditAccountResponse(this._CreditAccountResponseReceiverValue)
        return true
      }
      case TransferMoneyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TransferMoneyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TransferMoneyRequestReceiverModel.verify(), 'manager.TransferMoneyRequest validation failed!')
        let deserialized = this._TransferMoneyRequestReceiverModel.deserialize(this._TransferMoneyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TransferMoneyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TransferMoneyRequestReceiverValue)
        this.onReceive_TransferMoneyRequest(this._TransferMoneyRequestReceiverValue)
        return true
      }
      case TransferMoneyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TransferMoneyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TransferMoneyResponseReceiverModel.verify(), 'manager.TransferMoneyResponse validation failed!')
        let deserialized = this._TransferMoneyResponseReceiverModel.deserialize(this._TransferMoneyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TransferMoneyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TransferMoneyResponseReceiverValue)
        this.onReceive_TransferMoneyResponse(this._TransferMoneyResponseReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleGetRequestReceiverModel.verify(), 'manager.TradingCalendarRegularRuleGetRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleGetRequestReceiverModel.deserialize(this._TradingCalendarRegularRuleGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleGetRequestReceiverValue)
        this.onReceive_TradingCalendarRegularRuleGetRequest(this._TradingCalendarRegularRuleGetRequestReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleGetResponseReceiverModel.verify(), 'manager.TradingCalendarRegularRuleGetResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleGetResponseReceiverModel.deserialize(this._TradingCalendarRegularRuleGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleGetResponseReceiverValue)
        this.onReceive_TradingCalendarRegularRuleGetResponse(this._TradingCalendarRegularRuleGetResponseReceiverValue)
        return true
      }
      case TradingCalendarRegularRulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRulesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRulesGetAllRequestReceiverModel.verify(), 'manager.TradingCalendarRegularRulesGetAllRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRulesGetAllRequestReceiverModel.deserialize(this._TradingCalendarRegularRulesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRulesGetAllRequestReceiverValue)
        this.onReceive_TradingCalendarRegularRulesGetAllRequest(this._TradingCalendarRegularRulesGetAllRequestReceiverValue)
        return true
      }
      case TradingCalendarRegularRulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRulesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRulesGetAllResponseReceiverModel.verify(), 'manager.TradingCalendarRegularRulesGetAllResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRulesGetAllResponseReceiverModel.deserialize(this._TradingCalendarRegularRulesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRulesGetAllResponseReceiverValue)
        this.onReceive_TradingCalendarRegularRulesGetAllResponse(this._TradingCalendarRegularRulesGetAllResponseReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleCreateRequestReceiverModel.verify(), 'manager.TradingCalendarRegularRuleCreateRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleCreateRequestReceiverModel.deserialize(this._TradingCalendarRegularRuleCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleCreateRequestReceiverValue)
        this.onReceive_TradingCalendarRegularRuleCreateRequest(this._TradingCalendarRegularRuleCreateRequestReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleCreateResponseReceiverModel.verify(), 'manager.TradingCalendarRegularRuleCreateResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleCreateResponseReceiverModel.deserialize(this._TradingCalendarRegularRuleCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleCreateResponseReceiverValue)
        this.onReceive_TradingCalendarRegularRuleCreateResponse(this._TradingCalendarRegularRuleCreateResponseReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleModifyRequestReceiverModel.verify(), 'manager.TradingCalendarRegularRuleModifyRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleModifyRequestReceiverModel.deserialize(this._TradingCalendarRegularRuleModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleModifyRequestReceiverValue)
        this.onReceive_TradingCalendarRegularRuleModifyRequest(this._TradingCalendarRegularRuleModifyRequestReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleModifyResponseReceiverModel.verify(), 'manager.TradingCalendarRegularRuleModifyResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleModifyResponseReceiverModel.deserialize(this._TradingCalendarRegularRuleModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleModifyResponseReceiverValue)
        this.onReceive_TradingCalendarRegularRuleModifyResponse(this._TradingCalendarRegularRuleModifyResponseReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleDeleteRequestReceiverModel.verify(), 'manager.TradingCalendarRegularRuleDeleteRequest validation failed!')
        let deserialized = this._TradingCalendarRegularRuleDeleteRequestReceiverModel.deserialize(this._TradingCalendarRegularRuleDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleDeleteRequestReceiverValue)
        this.onReceive_TradingCalendarRegularRuleDeleteRequest(this._TradingCalendarRegularRuleDeleteRequestReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleDeleteResponseReceiverModel.verify(), 'manager.TradingCalendarRegularRuleDeleteResponse validation failed!')
        let deserialized = this._TradingCalendarRegularRuleDeleteResponseReceiverModel.deserialize(this._TradingCalendarRegularRuleDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleDeleteResponseReceiverValue)
        this.onReceive_TradingCalendarRegularRuleDeleteResponse(this._TradingCalendarRegularRuleDeleteResponseReceiverValue)
        return true
      }
      case TradingCalendarRegularRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRegularRuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRegularRuleUpdateNotifyReceiverModel.verify(), 'manager.TradingCalendarRegularRuleUpdateNotify validation failed!')
        let deserialized = this._TradingCalendarRegularRuleUpdateNotifyReceiverModel.deserialize(this._TradingCalendarRegularRuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRegularRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRegularRuleUpdateNotifyReceiverValue)
        this.onReceive_TradingCalendarRegularRuleUpdateNotify(this._TradingCalendarRegularRuleUpdateNotifyReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleGetRequestReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleGetRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleGetRequestReceiverModel.deserialize(this._TradingCalendarOffTimeRuleGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleGetRequestReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleGetRequest(this._TradingCalendarOffTimeRuleGetRequestReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleGetResponseReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleGetResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleGetResponseReceiverModel.deserialize(this._TradingCalendarOffTimeRuleGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleGetResponseReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleGetResponse(this._TradingCalendarOffTimeRuleGetResponseReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRulesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRulesGetAllRequestReceiverModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRulesGetAllRequestReceiverModel.deserialize(this._TradingCalendarOffTimeRulesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRulesGetAllRequestReceiverValue)
        this.onReceive_TradingCalendarOffTimeRulesGetAllRequest(this._TradingCalendarOffTimeRulesGetAllRequestReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRulesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRulesGetAllResponseReceiverModel.verify(), 'manager.TradingCalendarOffTimeRulesGetAllResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRulesGetAllResponseReceiverModel.deserialize(this._TradingCalendarOffTimeRulesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRulesGetAllResponseReceiverValue)
        this.onReceive_TradingCalendarOffTimeRulesGetAllResponse(this._TradingCalendarOffTimeRulesGetAllResponseReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleCreateRequestReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleCreateRequestReceiverModel.deserialize(this._TradingCalendarOffTimeRuleCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleCreateRequestReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleCreateRequest(this._TradingCalendarOffTimeRuleCreateRequestReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleCreateResponseReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleCreateResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleCreateResponseReceiverModel.deserialize(this._TradingCalendarOffTimeRuleCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleCreateResponseReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleCreateResponse(this._TradingCalendarOffTimeRuleCreateResponseReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleModifyRequestReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleModifyRequestReceiverModel.deserialize(this._TradingCalendarOffTimeRuleModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleModifyRequestReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleModifyRequest(this._TradingCalendarOffTimeRuleModifyRequestReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleModifyResponseReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleModifyResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleModifyResponseReceiverModel.deserialize(this._TradingCalendarOffTimeRuleModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleModifyResponseReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleModifyResponse(this._TradingCalendarOffTimeRuleModifyResponseReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleDeleteRequestReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteRequest validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleDeleteRequestReceiverModel.deserialize(this._TradingCalendarOffTimeRuleDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleDeleteRequestReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleDeleteRequest(this._TradingCalendarOffTimeRuleDeleteRequestReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleDeleteResponseReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleDeleteResponse validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleDeleteResponseReceiverModel.deserialize(this._TradingCalendarOffTimeRuleDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleDeleteResponseReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleDeleteResponse(this._TradingCalendarOffTimeRuleDeleteResponseReceiverValue)
        return true
      }
      case TradingCalendarOffTimeRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarOffTimeRuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarOffTimeRuleUpdateNotifyReceiverModel.verify(), 'manager.TradingCalendarOffTimeRuleUpdateNotify validation failed!')
        let deserialized = this._TradingCalendarOffTimeRuleUpdateNotifyReceiverModel.deserialize(this._TradingCalendarOffTimeRuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarOffTimeRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarOffTimeRuleUpdateNotifyReceiverValue)
        this.onReceive_TradingCalendarOffTimeRuleUpdateNotify(this._TradingCalendarOffTimeRuleUpdateNotifyReceiverValue)
        return true
      }
      case TradingCalendarRulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRulesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRulesGetRequestReceiverModel.verify(), 'manager.TradingCalendarRulesGetRequest validation failed!')
        let deserialized = this._TradingCalendarRulesGetRequestReceiverModel.deserialize(this._TradingCalendarRulesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRulesGetRequestReceiverValue)
        this.onReceive_TradingCalendarRulesGetRequest(this._TradingCalendarRulesGetRequestReceiverValue)
        return true
      }
      case TradingCalendarRulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingCalendarRulesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingCalendarRulesGetResponseReceiverModel.verify(), 'manager.TradingCalendarRulesGetResponse validation failed!')
        let deserialized = this._TradingCalendarRulesGetResponseReceiverModel.deserialize(this._TradingCalendarRulesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingCalendarRulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingCalendarRulesGetResponseReceiverValue)
        this.onReceive_TradingCalendarRulesGetResponse(this._TradingCalendarRulesGetResponseReceiverValue)
        return true
      }
      case TradingRouteRulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRulesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRulesGetRequestReceiverModel.verify(), 'manager.TradingRouteRulesGetRequest validation failed!')
        let deserialized = this._TradingRouteRulesGetRequestReceiverModel.deserialize(this._TradingRouteRulesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRulesGetRequestReceiverValue)
        this.onReceive_TradingRouteRulesGetRequest(this._TradingRouteRulesGetRequestReceiverValue)
        return true
      }
      case TradingRouteRulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRulesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRulesGetResponseReceiverModel.verify(), 'manager.TradingRouteRulesGetResponse validation failed!')
        let deserialized = this._TradingRouteRulesGetResponseReceiverModel.deserialize(this._TradingRouteRulesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRulesGetResponseReceiverValue)
        this.onReceive_TradingRouteRulesGetResponse(this._TradingRouteRulesGetResponseReceiverValue)
        return true
      }
      case TradingRouteRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleCreateRequestReceiverModel.verify(), 'manager.TradingRouteRuleCreateRequest validation failed!')
        let deserialized = this._TradingRouteRuleCreateRequestReceiverModel.deserialize(this._TradingRouteRuleCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleCreateRequestReceiverValue)
        this.onReceive_TradingRouteRuleCreateRequest(this._TradingRouteRuleCreateRequestReceiverValue)
        return true
      }
      case TradingRouteRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleCreateResponseReceiverModel.verify(), 'manager.TradingRouteRuleCreateResponse validation failed!')
        let deserialized = this._TradingRouteRuleCreateResponseReceiverModel.deserialize(this._TradingRouteRuleCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleCreateResponseReceiverValue)
        this.onReceive_TradingRouteRuleCreateResponse(this._TradingRouteRuleCreateResponseReceiverValue)
        return true
      }
      case TradingRouteRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleModifyRequestReceiverModel.verify(), 'manager.TradingRouteRuleModifyRequest validation failed!')
        let deserialized = this._TradingRouteRuleModifyRequestReceiverModel.deserialize(this._TradingRouteRuleModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleModifyRequestReceiverValue)
        this.onReceive_TradingRouteRuleModifyRequest(this._TradingRouteRuleModifyRequestReceiverValue)
        return true
      }
      case TradingRouteRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleModifyResponseReceiverModel.verify(), 'manager.TradingRouteRuleModifyResponse validation failed!')
        let deserialized = this._TradingRouteRuleModifyResponseReceiverModel.deserialize(this._TradingRouteRuleModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleModifyResponseReceiverValue)
        this.onReceive_TradingRouteRuleModifyResponse(this._TradingRouteRuleModifyResponseReceiverValue)
        return true
      }
      case TradingRouteRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleDeleteRequestReceiverModel.verify(), 'manager.TradingRouteRuleDeleteRequest validation failed!')
        let deserialized = this._TradingRouteRuleDeleteRequestReceiverModel.deserialize(this._TradingRouteRuleDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleDeleteRequestReceiverValue)
        this.onReceive_TradingRouteRuleDeleteRequest(this._TradingRouteRuleDeleteRequestReceiverValue)
        return true
      }
      case TradingRouteRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleDeleteResponseReceiverModel.verify(), 'manager.TradingRouteRuleDeleteResponse validation failed!')
        let deserialized = this._TradingRouteRuleDeleteResponseReceiverModel.deserialize(this._TradingRouteRuleDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleDeleteResponseReceiverValue)
        this.onReceive_TradingRouteRuleDeleteResponse(this._TradingRouteRuleDeleteResponseReceiverValue)
        return true
      }
      case TradingRouteRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradingRouteRuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradingRouteRuleUpdateNotifyReceiverModel.verify(), 'manager.TradingRouteRuleUpdateNotify validation failed!')
        let deserialized = this._TradingRouteRuleUpdateNotifyReceiverModel.deserialize(this._TradingRouteRuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradingRouteRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradingRouteRuleUpdateNotifyReceiverValue)
        this.onReceive_TradingRouteRuleUpdateNotify(this._TradingRouteRuleUpdateNotifyReceiverValue)
        return true
      }
      case SymbolMapsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapsGetRequestReceiverModel.verify(), 'manager.SymbolMapsGetRequest validation failed!')
        let deserialized = this._SymbolMapsGetRequestReceiverModel.deserialize(this._SymbolMapsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapsGetRequestReceiverValue)
        this.onReceive_SymbolMapsGetRequest(this._SymbolMapsGetRequestReceiverValue)
        return true
      }
      case SymbolMapsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapsGetResponseReceiverModel.verify(), 'manager.SymbolMapsGetResponse validation failed!')
        let deserialized = this._SymbolMapsGetResponseReceiverModel.deserialize(this._SymbolMapsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapsGetResponseReceiverValue)
        this.onReceive_SymbolMapsGetResponse(this._SymbolMapsGetResponseReceiverValue)
        return true
      }
      case SymbolMapCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapCreateRequestReceiverModel.verify(), 'manager.SymbolMapCreateRequest validation failed!')
        let deserialized = this._SymbolMapCreateRequestReceiverModel.deserialize(this._SymbolMapCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapCreateRequestReceiverValue)
        this.onReceive_SymbolMapCreateRequest(this._SymbolMapCreateRequestReceiverValue)
        return true
      }
      case SymbolMapCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapCreateResponseReceiverModel.verify(), 'manager.SymbolMapCreateResponse validation failed!')
        let deserialized = this._SymbolMapCreateResponseReceiverModel.deserialize(this._SymbolMapCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapCreateResponseReceiverValue)
        this.onReceive_SymbolMapCreateResponse(this._SymbolMapCreateResponseReceiverValue)
        return true
      }
      case SymbolMapModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapModifyRequestReceiverModel.verify(), 'manager.SymbolMapModifyRequest validation failed!')
        let deserialized = this._SymbolMapModifyRequestReceiverModel.deserialize(this._SymbolMapModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapModifyRequestReceiverValue)
        this.onReceive_SymbolMapModifyRequest(this._SymbolMapModifyRequestReceiverValue)
        return true
      }
      case SymbolMapModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapModifyResponseReceiverModel.verify(), 'manager.SymbolMapModifyResponse validation failed!')
        let deserialized = this._SymbolMapModifyResponseReceiverModel.deserialize(this._SymbolMapModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapModifyResponseReceiverValue)
        this.onReceive_SymbolMapModifyResponse(this._SymbolMapModifyResponseReceiverValue)
        return true
      }
      case SymbolMapDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapDeleteRequestReceiverModel.verify(), 'manager.SymbolMapDeleteRequest validation failed!')
        let deserialized = this._SymbolMapDeleteRequestReceiverModel.deserialize(this._SymbolMapDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapDeleteRequestReceiverValue)
        this.onReceive_SymbolMapDeleteRequest(this._SymbolMapDeleteRequestReceiverValue)
        return true
      }
      case SymbolMapDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapDeleteResponseReceiverModel.verify(), 'manager.SymbolMapDeleteResponse validation failed!')
        let deserialized = this._SymbolMapDeleteResponseReceiverModel.deserialize(this._SymbolMapDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapDeleteResponseReceiverValue)
        this.onReceive_SymbolMapDeleteResponse(this._SymbolMapDeleteResponseReceiverValue)
        return true
      }
      case SymbolMapUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolMapUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolMapUpdateNotifyReceiverModel.verify(), 'manager.SymbolMapUpdateNotify validation failed!')
        let deserialized = this._SymbolMapUpdateNotifyReceiverModel.deserialize(this._SymbolMapUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SymbolMapUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolMapUpdateNotifyReceiverValue)
        this.onReceive_SymbolMapUpdateNotify(this._SymbolMapUpdateNotifyReceiverValue)
        return true
      }
      case GatewaysGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewaysGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewaysGetRequestReceiverModel.verify(), 'manager.GatewaysGetRequest validation failed!')
        let deserialized = this._GatewaysGetRequestReceiverModel.deserialize(this._GatewaysGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewaysGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewaysGetRequestReceiverValue)
        this.onReceive_GatewaysGetRequest(this._GatewaysGetRequestReceiverValue)
        return true
      }
      case GatewaysGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewaysGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewaysGetResponseReceiverModel.verify(), 'manager.GatewaysGetResponse validation failed!')
        let deserialized = this._GatewaysGetResponseReceiverModel.deserialize(this._GatewaysGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewaysGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewaysGetResponseReceiverValue)
        this.onReceive_GatewaysGetResponse(this._GatewaysGetResponseReceiverValue)
        return true
      }
      case GatewayCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayCreateRequestReceiverModel.verify(), 'manager.GatewayCreateRequest validation failed!')
        let deserialized = this._GatewayCreateRequestReceiverModel.deserialize(this._GatewayCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewayCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayCreateRequestReceiverValue)
        this.onReceive_GatewayCreateRequest(this._GatewayCreateRequestReceiverValue)
        return true
      }
      case GatewayCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayCreateResponseReceiverModel.verify(), 'manager.GatewayCreateResponse validation failed!')
        let deserialized = this._GatewayCreateResponseReceiverModel.deserialize(this._GatewayCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewayCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayCreateResponseReceiverValue)
        this.onReceive_GatewayCreateResponse(this._GatewayCreateResponseReceiverValue)
        return true
      }
      case GatewayModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModifyRequestReceiverModel.verify(), 'manager.GatewayModifyRequest validation failed!')
        let deserialized = this._GatewayModifyRequestReceiverModel.deserialize(this._GatewayModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewayModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModifyRequestReceiverValue)
        this.onReceive_GatewayModifyRequest(this._GatewayModifyRequestReceiverValue)
        return true
      }
      case GatewayModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModifyResponseReceiverModel.verify(), 'manager.GatewayModifyResponse validation failed!')
        let deserialized = this._GatewayModifyResponseReceiverModel.deserialize(this._GatewayModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewayModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModifyResponseReceiverValue)
        this.onReceive_GatewayModifyResponse(this._GatewayModifyResponseReceiverValue)
        return true
      }
      case GatewayDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayDeleteRequestReceiverModel.verify(), 'manager.GatewayDeleteRequest validation failed!')
        let deserialized = this._GatewayDeleteRequestReceiverModel.deserialize(this._GatewayDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewayDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayDeleteRequestReceiverValue)
        this.onReceive_GatewayDeleteRequest(this._GatewayDeleteRequestReceiverValue)
        return true
      }
      case GatewayDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayDeleteResponseReceiverModel.verify(), 'manager.GatewayDeleteResponse validation failed!')
        let deserialized = this._GatewayDeleteResponseReceiverModel.deserialize(this._GatewayDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewayDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayDeleteResponseReceiverValue)
        this.onReceive_GatewayDeleteResponse(this._GatewayDeleteResponseReceiverValue)
        return true
      }
      case GatewayUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayUpdateNotifyReceiverModel.verify(), 'manager.GatewayUpdateNotify validation failed!')
        let deserialized = this._GatewayUpdateNotifyReceiverModel.deserialize(this._GatewayUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.GatewayUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayUpdateNotifyReceiverValue)
        this.onReceive_GatewayUpdateNotify(this._GatewayUpdateNotifyReceiverValue)
        return true
      }
      case DataFeedsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedsGetRequestReceiverModel.verify(), 'manager.DataFeedsGetRequest validation failed!')
        let deserialized = this._DataFeedsGetRequestReceiverModel.deserialize(this._DataFeedsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedsGetRequestReceiverValue)
        this.onReceive_DataFeedsGetRequest(this._DataFeedsGetRequestReceiverValue)
        return true
      }
      case DataFeedsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedsGetResponseReceiverModel.verify(), 'manager.DataFeedsGetResponse validation failed!')
        let deserialized = this._DataFeedsGetResponseReceiverModel.deserialize(this._DataFeedsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedsGetResponseReceiverValue)
        this.onReceive_DataFeedsGetResponse(this._DataFeedsGetResponseReceiverValue)
        return true
      }
      case DataFeedCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedCreateRequestReceiverModel.verify(), 'manager.DataFeedCreateRequest validation failed!')
        let deserialized = this._DataFeedCreateRequestReceiverModel.deserialize(this._DataFeedCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedCreateRequestReceiverValue)
        this.onReceive_DataFeedCreateRequest(this._DataFeedCreateRequestReceiverValue)
        return true
      }
      case DataFeedCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedCreateResponseReceiverModel.verify(), 'manager.DataFeedCreateResponse validation failed!')
        let deserialized = this._DataFeedCreateResponseReceiverModel.deserialize(this._DataFeedCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedCreateResponseReceiverValue)
        this.onReceive_DataFeedCreateResponse(this._DataFeedCreateResponseReceiverValue)
        return true
      }
      case DataFeedModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModifyRequestReceiverModel.verify(), 'manager.DataFeedModifyRequest validation failed!')
        let deserialized = this._DataFeedModifyRequestReceiverModel.deserialize(this._DataFeedModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModifyRequestReceiverValue)
        this.onReceive_DataFeedModifyRequest(this._DataFeedModifyRequestReceiverValue)
        return true
      }
      case DataFeedModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModifyResponseReceiverModel.verify(), 'manager.DataFeedModifyResponse validation failed!')
        let deserialized = this._DataFeedModifyResponseReceiverModel.deserialize(this._DataFeedModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModifyResponseReceiverValue)
        this.onReceive_DataFeedModifyResponse(this._DataFeedModifyResponseReceiverValue)
        return true
      }
      case DataFeedDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedDeleteRequestReceiverModel.verify(), 'manager.DataFeedDeleteRequest validation failed!')
        let deserialized = this._DataFeedDeleteRequestReceiverModel.deserialize(this._DataFeedDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedDeleteRequestReceiverValue)
        this.onReceive_DataFeedDeleteRequest(this._DataFeedDeleteRequestReceiverValue)
        return true
      }
      case DataFeedDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedDeleteResponseReceiverModel.verify(), 'manager.DataFeedDeleteResponse validation failed!')
        let deserialized = this._DataFeedDeleteResponseReceiverModel.deserialize(this._DataFeedDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedDeleteResponseReceiverValue)
        this.onReceive_DataFeedDeleteResponse(this._DataFeedDeleteResponseReceiverValue)
        return true
      }
      case DataFeedUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedUpdateNotifyReceiverModel.verify(), 'manager.DataFeedUpdateNotify validation failed!')
        let deserialized = this._DataFeedUpdateNotifyReceiverModel.deserialize(this._DataFeedUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.DataFeedUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedUpdateNotifyReceiverValue)
        this.onReceive_DataFeedUpdateNotify(this._DataFeedUpdateNotifyReceiverValue)
        return true
      }
      case ModuleSystemInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleSystemInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoNotifyReceiverModel.verify(), 'manager.ModuleSystemInfoNotify validation failed!')
        let deserialized = this._ModuleSystemInfoNotifyReceiverModel.deserialize(this._ModuleSystemInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleSystemInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleSystemInfoNotifyReceiverValue)
        this.onReceive_ModuleSystemInfoNotify(this._ModuleSystemInfoNotifyReceiverValue)
        return true
      }
      case ModulesSystemInfoGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesSystemInfoGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoGetAllRequestReceiverModel.verify(), 'manager.ModulesSystemInfoGetAllRequest validation failed!')
        let deserialized = this._ModulesSystemInfoGetAllRequestReceiverModel.deserialize(this._ModulesSystemInfoGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModulesSystemInfoGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesSystemInfoGetAllRequestReceiverValue)
        this.onReceive_ModulesSystemInfoGetAllRequest(this._ModulesSystemInfoGetAllRequestReceiverValue)
        return true
      }
      case ModulesSystemInfoGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesSystemInfoGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoGetAllResponseReceiverModel.verify(), 'manager.ModulesSystemInfoGetAllResponse validation failed!')
        let deserialized = this._ModulesSystemInfoGetAllResponseReceiverModel.deserialize(this._ModulesSystemInfoGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModulesSystemInfoGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesSystemInfoGetAllResponseReceiverValue)
        this.onReceive_ModulesSystemInfoGetAllResponse(this._ModulesSystemInfoGetAllResponseReceiverValue)
        return true
      }
      case ModulesSystemInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModulesSystemInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModulesSystemInfoNotifyReceiverModel.verify(), 'manager.ModulesSystemInfoNotify validation failed!')
        let deserialized = this._ModulesSystemInfoNotifyReceiverModel.deserialize(this._ModulesSystemInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModulesSystemInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModulesSystemInfoNotifyReceiverValue)
        this.onReceive_ModulesSystemInfoNotify(this._ModulesSystemInfoNotifyReceiverValue)
        return true
      }
      case ChartBarHistoryModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyRequestReceiverModel.verify(), 'manager.ChartBarHistoryModifyRequest validation failed!')
        let deserialized = this._ChartBarHistoryModifyRequestReceiverModel.deserialize(this._ChartBarHistoryModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarHistoryModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyRequestReceiverValue)
        this.onReceive_ChartBarHistoryModifyRequest(this._ChartBarHistoryModifyRequestReceiverValue)
        return true
      }
      case ChartBarHistoryModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyResponseReceiverModel.verify(), 'manager.ChartBarHistoryModifyResponse validation failed!')
        let deserialized = this._ChartBarHistoryModifyResponseReceiverModel.deserialize(this._ChartBarHistoryModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarHistoryModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyResponseReceiverValue)
        this.onReceive_ChartBarHistoryModifyResponse(this._ChartBarHistoryModifyResponseReceiverValue)
        return true
      }
      case FeedUpdateTickSnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickSnapshotRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotRequestReceiverModel.verify(), 'manager.FeedUpdateTickSnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateTickSnapshotRequestReceiverModel.deserialize(this._FeedUpdateTickSnapshotRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickSnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickSnapshotRequestReceiverValue)
        this.onReceive_FeedUpdateTickSnapshotRequest(this._FeedUpdateTickSnapshotRequestReceiverValue)
        return true
      }
      case FeedUpdateTickSnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickSnapshotResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotResponseReceiverModel.verify(), 'manager.FeedUpdateTickSnapshotResponse validation failed!')
        let deserialized = this._FeedUpdateTickSnapshotResponseReceiverModel.deserialize(this._FeedUpdateTickSnapshotResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickSnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickSnapshotResponseReceiverValue)
        this.onReceive_FeedUpdateTickSnapshotResponse(this._FeedUpdateTickSnapshotResponseReceiverValue)
        return true
      }
      case FeedUpdateTickRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickRequestReceiverModel.verify(), 'manager.FeedUpdateTickRequest validation failed!')
        let deserialized = this._FeedUpdateTickRequestReceiverModel.deserialize(this._FeedUpdateTickRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickRequestReceiverValue)
        this.onReceive_FeedUpdateTickRequest(this._FeedUpdateTickRequestReceiverValue)
        return true
      }
      case FeedUpdateTickResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickResponseReceiverModel.verify(), 'manager.FeedUpdateTickResponse validation failed!')
        let deserialized = this._FeedUpdateTickResponseReceiverModel.deserialize(this._FeedUpdateTickResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateTickResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickResponseReceiverValue)
        this.onReceive_FeedUpdateTickResponse(this._FeedUpdateTickResponseReceiverValue)
        return true
      }
      case FeedUpdateLevel2SnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotRequestReceiverModel.verify(), 'manager.FeedUpdateLevel2SnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotRequestReceiverModel.deserialize(this._FeedUpdateLevel2SnapshotRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2SnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotRequestReceiverValue)
        this.onReceive_FeedUpdateLevel2SnapshotRequest(this._FeedUpdateLevel2SnapshotRequestReceiverValue)
        return true
      }
      case FeedUpdateLevel2SnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotResponseReceiverModel.verify(), 'manager.FeedUpdateLevel2SnapshotResponse validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotResponseReceiverModel.deserialize(this._FeedUpdateLevel2SnapshotResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2SnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotResponseReceiverValue)
        this.onReceive_FeedUpdateLevel2SnapshotResponse(this._FeedUpdateLevel2SnapshotResponseReceiverValue)
        return true
      }
      case FeedUpdateLevel2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2RequestReceiverModel.verify(), 'manager.FeedUpdateLevel2Request validation failed!')
        let deserialized = this._FeedUpdateLevel2RequestReceiverModel.deserialize(this._FeedUpdateLevel2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2RequestReceiverValue)
        this.onReceive_FeedUpdateLevel2Request(this._FeedUpdateLevel2RequestReceiverValue)
        return true
      }
      case FeedUpdateLevel2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2ResponseReceiverModel.verify(), 'manager.FeedUpdateLevel2Response validation failed!')
        let deserialized = this._FeedUpdateLevel2ResponseReceiverModel.deserialize(this._FeedUpdateLevel2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FeedUpdateLevel2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2ResponseReceiverValue)
        this.onReceive_FeedUpdateLevel2Response(this._FeedUpdateLevel2ResponseReceiverValue)
        return true
      }
      case MailServerGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerGetRequestReceiverModel.verify(), 'manager.MailServerGetRequest validation failed!')
        let deserialized = this._MailServerGetRequestReceiverModel.deserialize(this._MailServerGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerGetRequestReceiverValue)
        this.onReceive_MailServerGetRequest(this._MailServerGetRequestReceiverValue)
        return true
      }
      case MailServerGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerGetResponseReceiverModel.verify(), 'manager.MailServerGetResponse validation failed!')
        let deserialized = this._MailServerGetResponseReceiverModel.deserialize(this._MailServerGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerGetResponseReceiverValue)
        this.onReceive_MailServerGetResponse(this._MailServerGetResponseReceiverValue)
        return true
      }
      case MailServersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServersGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServersGetAllRequestReceiverModel.verify(), 'manager.MailServersGetAllRequest validation failed!')
        let deserialized = this._MailServersGetAllRequestReceiverModel.deserialize(this._MailServersGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServersGetAllRequestReceiverValue)
        this.onReceive_MailServersGetAllRequest(this._MailServersGetAllRequestReceiverValue)
        return true
      }
      case MailServersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServersGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServersGetAllResponseReceiverModel.verify(), 'manager.MailServersGetAllResponse validation failed!')
        let deserialized = this._MailServersGetAllResponseReceiverModel.deserialize(this._MailServersGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServersGetAllResponseReceiverValue)
        this.onReceive_MailServersGetAllResponse(this._MailServersGetAllResponseReceiverValue)
        return true
      }
      case MailServerCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerCreateRequestReceiverModel.verify(), 'manager.MailServerCreateRequest validation failed!')
        let deserialized = this._MailServerCreateRequestReceiverModel.deserialize(this._MailServerCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerCreateRequestReceiverValue)
        this.onReceive_MailServerCreateRequest(this._MailServerCreateRequestReceiverValue)
        return true
      }
      case MailServerCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerCreateResponseReceiverModel.verify(), 'manager.MailServerCreateResponse validation failed!')
        let deserialized = this._MailServerCreateResponseReceiverModel.deserialize(this._MailServerCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerCreateResponseReceiverValue)
        this.onReceive_MailServerCreateResponse(this._MailServerCreateResponseReceiverValue)
        return true
      }
      case MailServerModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerModifyRequestReceiverModel.verify(), 'manager.MailServerModifyRequest validation failed!')
        let deserialized = this._MailServerModifyRequestReceiverModel.deserialize(this._MailServerModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerModifyRequestReceiverValue)
        this.onReceive_MailServerModifyRequest(this._MailServerModifyRequestReceiverValue)
        return true
      }
      case MailServerModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerModifyResponseReceiverModel.verify(), 'manager.MailServerModifyResponse validation failed!')
        let deserialized = this._MailServerModifyResponseReceiverModel.deserialize(this._MailServerModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerModifyResponseReceiverValue)
        this.onReceive_MailServerModifyResponse(this._MailServerModifyResponseReceiverValue)
        return true
      }
      case MailServerDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerDeleteRequestReceiverModel.verify(), 'manager.MailServerDeleteRequest validation failed!')
        let deserialized = this._MailServerDeleteRequestReceiverModel.deserialize(this._MailServerDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerDeleteRequestReceiverValue)
        this.onReceive_MailServerDeleteRequest(this._MailServerDeleteRequestReceiverValue)
        return true
      }
      case MailServerDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerDeleteResponseReceiverModel.verify(), 'manager.MailServerDeleteResponse validation failed!')
        let deserialized = this._MailServerDeleteResponseReceiverModel.deserialize(this._MailServerDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerDeleteResponseReceiverValue)
        this.onReceive_MailServerDeleteResponse(this._MailServerDeleteResponseReceiverValue)
        return true
      }
      case MailServerUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MailServerUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MailServerUpdateNotifyReceiverModel.verify(), 'manager.MailServerUpdateNotify validation failed!')
        let deserialized = this._MailServerUpdateNotifyReceiverModel.deserialize(this._MailServerUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MailServerUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MailServerUpdateNotifyReceiverValue)
        this.onReceive_MailServerUpdateNotify(this._MailServerUpdateNotifyReceiverValue)
        return true
      }
      case AccountStatesUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountStatesUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesUpdateNotifyReceiverModel.verify(), 'manager.AccountStatesUpdateNotify validation failed!')
        let deserialized = this._AccountStatesUpdateNotifyReceiverModel.deserialize(this._AccountStatesUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountStatesUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountStatesUpdateNotifyReceiverValue)
        this.onReceive_AccountStatesUpdateNotify(this._AccountStatesUpdateNotifyReceiverValue)
        return true
      }
      case AccountStatesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountStatesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesGetAllRequestReceiverModel.verify(), 'manager.AccountStatesGetAllRequest validation failed!')
        let deserialized = this._AccountStatesGetAllRequestReceiverModel.deserialize(this._AccountStatesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountStatesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountStatesGetAllRequestReceiverValue)
        this.onReceive_AccountStatesGetAllRequest(this._AccountStatesGetAllRequestReceiverValue)
        return true
      }
      case AccountStatesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountStatesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountStatesGetAllResponseReceiverModel.verify(), 'manager.AccountStatesGetAllResponse validation failed!')
        let deserialized = this._AccountStatesGetAllResponseReceiverModel.deserialize(this._AccountStatesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountStatesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountStatesGetAllResponseReceiverValue)
        this.onReceive_AccountStatesGetAllResponse(this._AccountStatesGetAllResponseReceiverValue)
        return true
      }
      case ModuleAuthRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleAuthRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleAuthRequestReceiverModel.verify(), 'manager.ModuleAuthRequest validation failed!')
        let deserialized = this._ModuleAuthRequestReceiverModel.deserialize(this._ModuleAuthRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleAuthRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleAuthRequestReceiverValue)
        this.onReceive_ModuleAuthRequest(this._ModuleAuthRequestReceiverValue)
        return true
      }
      case ModuleAuthResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleAuthResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleAuthResponseReceiverModel.verify(), 'manager.ModuleAuthResponse validation failed!')
        let deserialized = this._ModuleAuthResponseReceiverModel.deserialize(this._ModuleAuthResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ModuleAuthResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleAuthResponseReceiverValue)
        this.onReceive_ModuleAuthResponse(this._ModuleAuthResponseReceiverValue)
        return true
      }
      case LeverageProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileGetRequestReceiverModel.verify(), 'manager.LeverageProfileGetRequest validation failed!')
        let deserialized = this._LeverageProfileGetRequestReceiverModel.deserialize(this._LeverageProfileGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileGetRequestReceiverValue)
        this.onReceive_LeverageProfileGetRequest(this._LeverageProfileGetRequestReceiverValue)
        return true
      }
      case LeverageProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileGetResponseReceiverModel.verify(), 'manager.LeverageProfileGetResponse validation failed!')
        let deserialized = this._LeverageProfileGetResponseReceiverModel.deserialize(this._LeverageProfileGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileGetResponseReceiverValue)
        this.onReceive_LeverageProfileGetResponse(this._LeverageProfileGetResponseReceiverValue)
        return true
      }
      case LeverageProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfilesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfilesGetAllRequestReceiverModel.verify(), 'manager.LeverageProfilesGetAllRequest validation failed!')
        let deserialized = this._LeverageProfilesGetAllRequestReceiverModel.deserialize(this._LeverageProfilesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfilesGetAllRequestReceiverValue)
        this.onReceive_LeverageProfilesGetAllRequest(this._LeverageProfilesGetAllRequestReceiverValue)
        return true
      }
      case LeverageProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfilesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfilesGetAllResponseReceiverModel.verify(), 'manager.LeverageProfilesGetAllResponse validation failed!')
        let deserialized = this._LeverageProfilesGetAllResponseReceiverModel.deserialize(this._LeverageProfilesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfilesGetAllResponseReceiverValue)
        this.onReceive_LeverageProfilesGetAllResponse(this._LeverageProfilesGetAllResponseReceiverValue)
        return true
      }
      case LeverageProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileCreateRequestReceiverModel.verify(), 'manager.LeverageProfileCreateRequest validation failed!')
        let deserialized = this._LeverageProfileCreateRequestReceiverModel.deserialize(this._LeverageProfileCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileCreateRequestReceiverValue)
        this.onReceive_LeverageProfileCreateRequest(this._LeverageProfileCreateRequestReceiverValue)
        return true
      }
      case LeverageProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileCreateResponseReceiverModel.verify(), 'manager.LeverageProfileCreateResponse validation failed!')
        let deserialized = this._LeverageProfileCreateResponseReceiverModel.deserialize(this._LeverageProfileCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileCreateResponseReceiverValue)
        this.onReceive_LeverageProfileCreateResponse(this._LeverageProfileCreateResponseReceiverValue)
        return true
      }
      case LeverageProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileModifyRequestReceiverModel.verify(), 'manager.LeverageProfileModifyRequest validation failed!')
        let deserialized = this._LeverageProfileModifyRequestReceiverModel.deserialize(this._LeverageProfileModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileModifyRequestReceiverValue)
        this.onReceive_LeverageProfileModifyRequest(this._LeverageProfileModifyRequestReceiverValue)
        return true
      }
      case LeverageProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileModifyResponseReceiverModel.verify(), 'manager.LeverageProfileModifyResponse validation failed!')
        let deserialized = this._LeverageProfileModifyResponseReceiverModel.deserialize(this._LeverageProfileModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileModifyResponseReceiverValue)
        this.onReceive_LeverageProfileModifyResponse(this._LeverageProfileModifyResponseReceiverValue)
        return true
      }
      case LeverageProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileDeleteRequestReceiverModel.verify(), 'manager.LeverageProfileDeleteRequest validation failed!')
        let deserialized = this._LeverageProfileDeleteRequestReceiverModel.deserialize(this._LeverageProfileDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileDeleteRequestReceiverValue)
        this.onReceive_LeverageProfileDeleteRequest(this._LeverageProfileDeleteRequestReceiverValue)
        return true
      }
      case LeverageProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileDeleteResponseReceiverModel.verify(), 'manager.LeverageProfileDeleteResponse validation failed!')
        let deserialized = this._LeverageProfileDeleteResponseReceiverModel.deserialize(this._LeverageProfileDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileDeleteResponseReceiverValue)
        this.onReceive_LeverageProfileDeleteResponse(this._LeverageProfileDeleteResponseReceiverValue)
        return true
      }
      case LeverageProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageProfileUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageProfileUpdateNotifyReceiverModel.verify(), 'manager.LeverageProfileUpdateNotify validation failed!')
        let deserialized = this._LeverageProfileUpdateNotifyReceiverModel.deserialize(this._LeverageProfileUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LeverageProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageProfileUpdateNotifyReceiverValue)
        this.onReceive_LeverageProfileUpdateNotify(this._LeverageProfileUpdateNotifyReceiverValue)
        return true
      }
      case MarginRateProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileGetRequestReceiverModel.verify(), 'manager.MarginRateProfileGetRequest validation failed!')
        let deserialized = this._MarginRateProfileGetRequestReceiverModel.deserialize(this._MarginRateProfileGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileGetRequestReceiverValue)
        this.onReceive_MarginRateProfileGetRequest(this._MarginRateProfileGetRequestReceiverValue)
        return true
      }
      case MarginRateProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileGetResponseReceiverModel.verify(), 'manager.MarginRateProfileGetResponse validation failed!')
        let deserialized = this._MarginRateProfileGetResponseReceiverModel.deserialize(this._MarginRateProfileGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileGetResponseReceiverValue)
        this.onReceive_MarginRateProfileGetResponse(this._MarginRateProfileGetResponseReceiverValue)
        return true
      }
      case MarginRateProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfilesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfilesGetAllRequestReceiverModel.verify(), 'manager.MarginRateProfilesGetAllRequest validation failed!')
        let deserialized = this._MarginRateProfilesGetAllRequestReceiverModel.deserialize(this._MarginRateProfilesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfilesGetAllRequestReceiverValue)
        this.onReceive_MarginRateProfilesGetAllRequest(this._MarginRateProfilesGetAllRequestReceiverValue)
        return true
      }
      case MarginRateProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfilesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfilesGetAllResponseReceiverModel.verify(), 'manager.MarginRateProfilesGetAllResponse validation failed!')
        let deserialized = this._MarginRateProfilesGetAllResponseReceiverModel.deserialize(this._MarginRateProfilesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfilesGetAllResponseReceiverValue)
        this.onReceive_MarginRateProfilesGetAllResponse(this._MarginRateProfilesGetAllResponseReceiverValue)
        return true
      }
      case MarginRateProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileCreateRequestReceiverModel.verify(), 'manager.MarginRateProfileCreateRequest validation failed!')
        let deserialized = this._MarginRateProfileCreateRequestReceiverModel.deserialize(this._MarginRateProfileCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileCreateRequestReceiverValue)
        this.onReceive_MarginRateProfileCreateRequest(this._MarginRateProfileCreateRequestReceiverValue)
        return true
      }
      case MarginRateProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileCreateResponseReceiverModel.verify(), 'manager.MarginRateProfileCreateResponse validation failed!')
        let deserialized = this._MarginRateProfileCreateResponseReceiverModel.deserialize(this._MarginRateProfileCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileCreateResponseReceiverValue)
        this.onReceive_MarginRateProfileCreateResponse(this._MarginRateProfileCreateResponseReceiverValue)
        return true
      }
      case MarginRateProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileModifyRequestReceiverModel.verify(), 'manager.MarginRateProfileModifyRequest validation failed!')
        let deserialized = this._MarginRateProfileModifyRequestReceiverModel.deserialize(this._MarginRateProfileModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileModifyRequestReceiverValue)
        this.onReceive_MarginRateProfileModifyRequest(this._MarginRateProfileModifyRequestReceiverValue)
        return true
      }
      case MarginRateProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileModifyResponseReceiverModel.verify(), 'manager.MarginRateProfileModifyResponse validation failed!')
        let deserialized = this._MarginRateProfileModifyResponseReceiverModel.deserialize(this._MarginRateProfileModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileModifyResponseReceiverValue)
        this.onReceive_MarginRateProfileModifyResponse(this._MarginRateProfileModifyResponseReceiverValue)
        return true
      }
      case MarginRateProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileDeleteRequestReceiverModel.verify(), 'manager.MarginRateProfileDeleteRequest validation failed!')
        let deserialized = this._MarginRateProfileDeleteRequestReceiverModel.deserialize(this._MarginRateProfileDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileDeleteRequestReceiverValue)
        this.onReceive_MarginRateProfileDeleteRequest(this._MarginRateProfileDeleteRequestReceiverValue)
        return true
      }
      case MarginRateProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileDeleteResponseReceiverModel.verify(), 'manager.MarginRateProfileDeleteResponse validation failed!')
        let deserialized = this._MarginRateProfileDeleteResponseReceiverModel.deserialize(this._MarginRateProfileDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileDeleteResponseReceiverValue)
        this.onReceive_MarginRateProfileDeleteResponse(this._MarginRateProfileDeleteResponseReceiverValue)
        return true
      }
      case MarginRateProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginRateProfileUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginRateProfileUpdateNotifyReceiverModel.verify(), 'manager.MarginRateProfileUpdateNotify validation failed!')
        let deserialized = this._MarginRateProfileUpdateNotifyReceiverModel.deserialize(this._MarginRateProfileUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.MarginRateProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginRateProfileUpdateNotifyReceiverValue)
        this.onReceive_MarginRateProfileUpdateNotify(this._MarginRateProfileUpdateNotifyReceiverValue)
        return true
      }
      case NewsProfileGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileGetRequestReceiverModel.verify(), 'manager.NewsProfileGetRequest validation failed!')
        let deserialized = this._NewsProfileGetRequestReceiverModel.deserialize(this._NewsProfileGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileGetRequestReceiverValue)
        this.onReceive_NewsProfileGetRequest(this._NewsProfileGetRequestReceiverValue)
        return true
      }
      case NewsProfileGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileGetResponseReceiverModel.verify(), 'manager.NewsProfileGetResponse validation failed!')
        let deserialized = this._NewsProfileGetResponseReceiverModel.deserialize(this._NewsProfileGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileGetResponseReceiverValue)
        this.onReceive_NewsProfileGetResponse(this._NewsProfileGetResponseReceiverValue)
        return true
      }
      case NewsProfilesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfilesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfilesGetAllRequestReceiverModel.verify(), 'manager.NewsProfilesGetAllRequest validation failed!')
        let deserialized = this._NewsProfilesGetAllRequestReceiverModel.deserialize(this._NewsProfilesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfilesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfilesGetAllRequestReceiverValue)
        this.onReceive_NewsProfilesGetAllRequest(this._NewsProfilesGetAllRequestReceiverValue)
        return true
      }
      case NewsProfilesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfilesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfilesGetAllResponseReceiverModel.verify(), 'manager.NewsProfilesGetAllResponse validation failed!')
        let deserialized = this._NewsProfilesGetAllResponseReceiverModel.deserialize(this._NewsProfilesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfilesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfilesGetAllResponseReceiverValue)
        this.onReceive_NewsProfilesGetAllResponse(this._NewsProfilesGetAllResponseReceiverValue)
        return true
      }
      case NewsProfileCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileCreateRequestReceiverModel.verify(), 'manager.NewsProfileCreateRequest validation failed!')
        let deserialized = this._NewsProfileCreateRequestReceiverModel.deserialize(this._NewsProfileCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileCreateRequestReceiverValue)
        this.onReceive_NewsProfileCreateRequest(this._NewsProfileCreateRequestReceiverValue)
        return true
      }
      case NewsProfileCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileCreateResponseReceiverModel.verify(), 'manager.NewsProfileCreateResponse validation failed!')
        let deserialized = this._NewsProfileCreateResponseReceiverModel.deserialize(this._NewsProfileCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileCreateResponseReceiverValue)
        this.onReceive_NewsProfileCreateResponse(this._NewsProfileCreateResponseReceiverValue)
        return true
      }
      case NewsProfileModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileModifyRequestReceiverModel.verify(), 'manager.NewsProfileModifyRequest validation failed!')
        let deserialized = this._NewsProfileModifyRequestReceiverModel.deserialize(this._NewsProfileModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileModifyRequestReceiverValue)
        this.onReceive_NewsProfileModifyRequest(this._NewsProfileModifyRequestReceiverValue)
        return true
      }
      case NewsProfileModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileModifyResponseReceiverModel.verify(), 'manager.NewsProfileModifyResponse validation failed!')
        let deserialized = this._NewsProfileModifyResponseReceiverModel.deserialize(this._NewsProfileModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileModifyResponseReceiverValue)
        this.onReceive_NewsProfileModifyResponse(this._NewsProfileModifyResponseReceiverValue)
        return true
      }
      case NewsProfileDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileDeleteRequestReceiverModel.verify(), 'manager.NewsProfileDeleteRequest validation failed!')
        let deserialized = this._NewsProfileDeleteRequestReceiverModel.deserialize(this._NewsProfileDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileDeleteRequestReceiverValue)
        this.onReceive_NewsProfileDeleteRequest(this._NewsProfileDeleteRequestReceiverValue)
        return true
      }
      case NewsProfileDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileDeleteResponseReceiverModel.verify(), 'manager.NewsProfileDeleteResponse validation failed!')
        let deserialized = this._NewsProfileDeleteResponseReceiverModel.deserialize(this._NewsProfileDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileDeleteResponseReceiverValue)
        this.onReceive_NewsProfileDeleteResponse(this._NewsProfileDeleteResponseReceiverValue)
        return true
      }
      case NewsProfileUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileUpdateNotifyReceiverModel.verify(), 'manager.NewsProfileUpdateNotify validation failed!')
        let deserialized = this._NewsProfileUpdateNotifyReceiverModel.deserialize(this._NewsProfileUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileUpdateNotifyReceiverValue)
        this.onReceive_NewsProfileUpdateNotify(this._NewsProfileUpdateNotifyReceiverValue)
        return true
      }
      case NewsProfileImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileImportRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileImportRequestReceiverModel.verify(), 'manager.NewsProfileImportRequest validation failed!')
        let deserialized = this._NewsProfileImportRequestReceiverModel.deserialize(this._NewsProfileImportRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileImportRequestReceiverValue)
        this.onReceive_NewsProfileImportRequest(this._NewsProfileImportRequestReceiverValue)
        return true
      }
      case NewsProfileImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsProfileImportResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsProfileImportResponseReceiverModel.verify(), 'manager.NewsProfileImportResponse validation failed!')
        let deserialized = this._NewsProfileImportResponseReceiverModel.deserialize(this._NewsProfileImportResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.NewsProfileImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsProfileImportResponseReceiverValue)
        this.onReceive_NewsProfileImportResponse(this._NewsProfileImportResponseReceiverValue)
        return true
      }
      case TickHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryRequestReceiverModel.verify(), 'manager.TickHistoryRequest validation failed!')
        let deserialized = this._TickHistoryRequestReceiverModel.deserialize(this._TickHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TickHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryRequestReceiverValue)
        this.onReceive_TickHistoryRequest(this._TickHistoryRequestReceiverValue)
        return true
      }
      case TickHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryResponseReceiverModel.verify(), 'manager.TickHistoryResponse validation failed!')
        let deserialized = this._TickHistoryResponseReceiverModel.deserialize(this._TickHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TickHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryResponseReceiverValue)
        this.onReceive_TickHistoryResponse(this._TickHistoryResponseReceiverValue)
        return true
      }
      case EmailTemplateGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateGetRequestReceiverModel.verify(), 'manager.EmailTemplateGetRequest validation failed!')
        let deserialized = this._EmailTemplateGetRequestReceiverModel.deserialize(this._EmailTemplateGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateGetRequestReceiverValue)
        this.onReceive_EmailTemplateGetRequest(this._EmailTemplateGetRequestReceiverValue)
        return true
      }
      case EmailTemplateGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateGetResponseReceiverModel.verify(), 'manager.EmailTemplateGetResponse validation failed!')
        let deserialized = this._EmailTemplateGetResponseReceiverModel.deserialize(this._EmailTemplateGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateGetResponseReceiverValue)
        this.onReceive_EmailTemplateGetResponse(this._EmailTemplateGetResponseReceiverValue)
        return true
      }
      case EmailTemplatesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplatesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplatesGetAllRequestReceiverModel.verify(), 'manager.EmailTemplatesGetAllRequest validation failed!')
        let deserialized = this._EmailTemplatesGetAllRequestReceiverModel.deserialize(this._EmailTemplatesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplatesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplatesGetAllRequestReceiverValue)
        this.onReceive_EmailTemplatesGetAllRequest(this._EmailTemplatesGetAllRequestReceiverValue)
        return true
      }
      case EmailTemplatesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplatesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplatesGetAllResponseReceiverModel.verify(), 'manager.EmailTemplatesGetAllResponse validation failed!')
        let deserialized = this._EmailTemplatesGetAllResponseReceiverModel.deserialize(this._EmailTemplatesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplatesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplatesGetAllResponseReceiverValue)
        this.onReceive_EmailTemplatesGetAllResponse(this._EmailTemplatesGetAllResponseReceiverValue)
        return true
      }
      case EmailTemplateCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateCreateRequestReceiverModel.verify(), 'manager.EmailTemplateCreateRequest validation failed!')
        let deserialized = this._EmailTemplateCreateRequestReceiverModel.deserialize(this._EmailTemplateCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateCreateRequestReceiverValue)
        this.onReceive_EmailTemplateCreateRequest(this._EmailTemplateCreateRequestReceiverValue)
        return true
      }
      case EmailTemplateCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateCreateResponseReceiverModel.verify(), 'manager.EmailTemplateCreateResponse validation failed!')
        let deserialized = this._EmailTemplateCreateResponseReceiverModel.deserialize(this._EmailTemplateCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateCreateResponseReceiverValue)
        this.onReceive_EmailTemplateCreateResponse(this._EmailTemplateCreateResponseReceiverValue)
        return true
      }
      case EmailTemplateModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateModifyRequestReceiverModel.verify(), 'manager.EmailTemplateModifyRequest validation failed!')
        let deserialized = this._EmailTemplateModifyRequestReceiverModel.deserialize(this._EmailTemplateModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateModifyRequestReceiverValue)
        this.onReceive_EmailTemplateModifyRequest(this._EmailTemplateModifyRequestReceiverValue)
        return true
      }
      case EmailTemplateModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateModifyResponseReceiverModel.verify(), 'manager.EmailTemplateModifyResponse validation failed!')
        let deserialized = this._EmailTemplateModifyResponseReceiverModel.deserialize(this._EmailTemplateModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateModifyResponseReceiverValue)
        this.onReceive_EmailTemplateModifyResponse(this._EmailTemplateModifyResponseReceiverValue)
        return true
      }
      case EmailTemplateDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateDeleteRequestReceiverModel.verify(), 'manager.EmailTemplateDeleteRequest validation failed!')
        let deserialized = this._EmailTemplateDeleteRequestReceiverModel.deserialize(this._EmailTemplateDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateDeleteRequestReceiverValue)
        this.onReceive_EmailTemplateDeleteRequest(this._EmailTemplateDeleteRequestReceiverValue)
        return true
      }
      case EmailTemplateDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateDeleteResponseReceiverModel.verify(), 'manager.EmailTemplateDeleteResponse validation failed!')
        let deserialized = this._EmailTemplateDeleteResponseReceiverModel.deserialize(this._EmailTemplateDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateDeleteResponseReceiverValue)
        this.onReceive_EmailTemplateDeleteResponse(this._EmailTemplateDeleteResponseReceiverValue)
        return true
      }
      case EmailTemplateUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._EmailTemplateUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._EmailTemplateUpdateNotifyReceiverModel.verify(), 'manager.EmailTemplateUpdateNotify validation failed!')
        let deserialized = this._EmailTemplateUpdateNotifyReceiverModel.deserialize(this._EmailTemplateUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.EmailTemplateUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._EmailTemplateUpdateNotifyReceiverValue)
        this.onReceive_EmailTemplateUpdateNotify(this._EmailTemplateUpdateNotifyReceiverValue)
        return true
      }
      case OrderStateSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateSubscribeRequestReceiverModel.verify(), 'manager.OrderStateSubscribeRequest validation failed!')
        let deserialized = this._OrderStateSubscribeRequestReceiverModel.deserialize(this._OrderStateSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateSubscribeRequestReceiverValue)
        this.onReceive_OrderStateSubscribeRequest(this._OrderStateSubscribeRequestReceiverValue)
        return true
      }
      case OrderStateSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateSubscribeResponseReceiverModel.verify(), 'manager.OrderStateSubscribeResponse validation failed!')
        let deserialized = this._OrderStateSubscribeResponseReceiverModel.deserialize(this._OrderStateSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateSubscribeResponseReceiverValue)
        this.onReceive_OrderStateSubscribeResponse(this._OrderStateSubscribeResponseReceiverValue)
        return true
      }
      case OrderStateUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUpdateSubscriptionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUpdateSubscriptionRequestReceiverModel.verify(), 'manager.OrderStateUpdateSubscriptionRequest validation failed!')
        let deserialized = this._OrderStateUpdateSubscriptionRequestReceiverModel.deserialize(this._OrderStateUpdateSubscriptionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUpdateSubscriptionRequestReceiverValue)
        this.onReceive_OrderStateUpdateSubscriptionRequest(this._OrderStateUpdateSubscriptionRequestReceiverValue)
        return true
      }
      case OrderStateUpdateSubscriptionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUpdateSubscriptionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUpdateSubscriptionResponseReceiverModel.verify(), 'manager.OrderStateUpdateSubscriptionResponse validation failed!')
        let deserialized = this._OrderStateUpdateSubscriptionResponseReceiverModel.deserialize(this._OrderStateUpdateSubscriptionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUpdateSubscriptionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUpdateSubscriptionResponseReceiverValue)
        this.onReceive_OrderStateUpdateSubscriptionResponse(this._OrderStateUpdateSubscriptionResponseReceiverValue)
        return true
      }
      case OrderStateUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUnsubscribeRequestReceiverModel.verify(), 'manager.OrderStateUnsubscribeRequest validation failed!')
        let deserialized = this._OrderStateUnsubscribeRequestReceiverModel.deserialize(this._OrderStateUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUnsubscribeRequestReceiverValue)
        this.onReceive_OrderStateUnsubscribeRequest(this._OrderStateUnsubscribeRequestReceiverValue)
        return true
      }
      case OrderStateUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateUnsubscribeResponseReceiverModel.verify(), 'manager.OrderStateUnsubscribeResponse validation failed!')
        let deserialized = this._OrderStateUnsubscribeResponseReceiverModel.deserialize(this._OrderStateUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateUnsubscribeResponseReceiverValue)
        this.onReceive_OrderStateUnsubscribeResponse(this._OrderStateUnsubscribeResponseReceiverValue)
        return true
      }
      case OrderStateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateNotifyReceiverModel.verify(), 'manager.OrderStateNotify validation failed!')
        let deserialized = this._OrderStateNotifyReceiverModel.deserialize(this._OrderStateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrderStateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateNotifyReceiverValue)
        this.onReceive_OrderStateNotify(this._OrderStateNotifyReceiverValue)
        return true
      }
      case RequestMonitoringSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringSubscribeRequestReceiverModel.verify(), 'manager.RequestMonitoringSubscribeRequest validation failed!')
        let deserialized = this._RequestMonitoringSubscribeRequestReceiverModel.deserialize(this._RequestMonitoringSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringSubscribeRequestReceiverValue)
        this.onReceive_RequestMonitoringSubscribeRequest(this._RequestMonitoringSubscribeRequestReceiverValue)
        return true
      }
      case RequestMonitoringSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringSubscribeResponseReceiverModel.verify(), 'manager.RequestMonitoringSubscribeResponse validation failed!')
        let deserialized = this._RequestMonitoringSubscribeResponseReceiverModel.deserialize(this._RequestMonitoringSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringSubscribeResponseReceiverValue)
        this.onReceive_RequestMonitoringSubscribeResponse(this._RequestMonitoringSubscribeResponseReceiverValue)
        return true
      }
      case RequestMonitoringUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringUnsubscribeRequestReceiverModel.verify(), 'manager.RequestMonitoringUnsubscribeRequest validation failed!')
        let deserialized = this._RequestMonitoringUnsubscribeRequestReceiverModel.deserialize(this._RequestMonitoringUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringUnsubscribeRequestReceiverValue)
        this.onReceive_RequestMonitoringUnsubscribeRequest(this._RequestMonitoringUnsubscribeRequestReceiverValue)
        return true
      }
      case RequestMonitoringUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringUnsubscribeResponseReceiverModel.verify(), 'manager.RequestMonitoringUnsubscribeResponse validation failed!')
        let deserialized = this._RequestMonitoringUnsubscribeResponseReceiverModel.deserialize(this._RequestMonitoringUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringUnsubscribeResponseReceiverValue)
        this.onReceive_RequestMonitoringUnsubscribeResponse(this._RequestMonitoringUnsubscribeResponseReceiverValue)
        return true
      }
      case RequestMonitoringRequestUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestMonitoringRequestUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RequestMonitoringRequestUpdateNotifyReceiverModel.verify(), 'manager.RequestMonitoringRequestUpdateNotify validation failed!')
        let deserialized = this._RequestMonitoringRequestUpdateNotifyReceiverModel.deserialize(this._RequestMonitoringRequestUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.RequestMonitoringRequestUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestMonitoringRequestUpdateNotifyReceiverValue)
        this.onReceive_RequestMonitoringRequestUpdateNotify(this._RequestMonitoringRequestUpdateNotifyReceiverValue)
        return true
      }
      case FilledOrdersSummaryInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoSubscribeRequestReceiverModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeRequest validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoSubscribeRequestReceiverModel.deserialize(this._FilledOrdersSummaryInfoSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoSubscribeRequestReceiverValue)
        this.onReceive_FilledOrdersSummaryInfoSubscribeRequest(this._FilledOrdersSummaryInfoSubscribeRequestReceiverValue)
        return true
      }
      case FilledOrdersSummaryInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoSubscribeResponseReceiverModel.verify(), 'manager.FilledOrdersSummaryInfoSubscribeResponse validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoSubscribeResponseReceiverModel.deserialize(this._FilledOrdersSummaryInfoSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoSubscribeResponseReceiverValue)
        this.onReceive_FilledOrdersSummaryInfoSubscribeResponse(this._FilledOrdersSummaryInfoSubscribeResponseReceiverValue)
        return true
      }
      case FilledOrdersSummaryInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoNotifyReceiverModel.verify(), 'manager.FilledOrdersSummaryInfoNotify validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoNotifyReceiverModel.deserialize(this._FilledOrdersSummaryInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoNotifyReceiverValue)
        this.onReceive_FilledOrdersSummaryInfoNotify(this._FilledOrdersSummaryInfoNotifyReceiverValue)
        return true
      }
      case FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverModel.verify(), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverModel.deserialize(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverValue)
        this.onReceive_FilledOrdersSummaryInfoUpdateSubscriptionRequest(this._FilledOrdersSummaryInfoUpdateSubscriptionRequestReceiverValue)
        return true
      }
      case FilledOrdersSummaryInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverModel.deserialize(this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverValue)
        this.onReceive_FilledOrdersSummaryInfoUnsubscribeRequest(this._FilledOrdersSummaryInfoUnsubscribeRequestReceiverValue)
        return true
      }
      case FilledOrdersSummaryInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverModel.verify(), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse validation failed!')
        let deserialized = this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverModel.deserialize(this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FilledOrdersSummaryInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverValue)
        this.onReceive_FilledOrdersSummaryInfoUnsubscribeResponse(this._FilledOrdersSummaryInfoUnsubscribeResponseReceiverValue)
        return true
      }
      case TradeEventsNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsNotifyReceiverModel.verify(), 'manager.TradeEventsNotify validation failed!')
        let deserialized = this._TradeEventsNotifyReceiverModel.deserialize(this._TradeEventsNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeEventsNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsNotifyReceiverValue)
        this.onReceive_TradeEventsNotify(this._TradeEventsNotifyReceiverValue)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestReceiverModel.verify(), 'manager.TradeEventsGetRequest validation failed!')
        let deserialized = this._TradeEventsGetRequestReceiverModel.deserialize(this._TradeEventsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeEventsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetRequestReceiverValue)
        this.onReceive_TradeEventsGetRequest(this._TradeEventsGetRequestReceiverValue)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseReceiverModel.verify(), 'manager.TradeEventsGetResponse validation failed!')
        let deserialized = this._TradeEventsGetResponseReceiverModel.deserialize(this._TradeEventsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.TradeEventsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetResponseReceiverValue)
        this.onReceive_TradeEventsGetResponse(this._TradeEventsGetResponseReceiverValue)
        return true
      }
      case JournalRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._JournalRequestReceiverModel.verify(), 'manager.JournalRequest validation failed!')
        let deserialized = this._JournalRequestReceiverModel.deserialize(this._JournalRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.JournalRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalRequestReceiverValue)
        this.onReceive_JournalRequest(this._JournalRequestReceiverValue)
        return true
      }
      case JournalResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._JournalResponseReceiverModel.verify(), 'manager.JournalResponse validation failed!')
        let deserialized = this._JournalResponseReceiverModel.deserialize(this._JournalResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.JournalResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalResponseReceiverValue)
        this.onReceive_JournalResponse(this._JournalResponseReceiverValue)
        return true
      }
      case ClientReportsListGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetRequestReceiverModel.verify(), 'manager.ClientReportsListGetRequest validation failed!')
        let deserialized = this._ClientReportsListGetRequestReceiverModel.deserialize(this._ClientReportsListGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsListGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetRequestReceiverValue)
        this.onReceive_ClientReportsListGetRequest(this._ClientReportsListGetRequestReceiverValue)
        return true
      }
      case ClientReportsListGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetResponseReceiverModel.verify(), 'manager.ClientReportsListGetResponse validation failed!')
        let deserialized = this._ClientReportsListGetResponseReceiverModel.deserialize(this._ClientReportsListGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsListGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetResponseReceiverValue)
        this.onReceive_ClientReportsListGetResponse(this._ClientReportsListGetResponseReceiverValue)
        return true
      }
      case ClientReportGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetRequestReceiverModel.verify(), 'manager.ClientReportGetRequest validation failed!')
        let deserialized = this._ClientReportGetRequestReceiverModel.deserialize(this._ClientReportGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetRequestReceiverValue)
        this.onReceive_ClientReportGetRequest(this._ClientReportGetRequestReceiverValue)
        return true
      }
      case ClientReportGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetResponseReceiverModel.verify(), 'manager.ClientReportGetResponse validation failed!')
        let deserialized = this._ClientReportGetResponseReceiverModel.deserialize(this._ClientReportGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetResponseReceiverValue)
        this.onReceive_ClientReportGetResponse(this._ClientReportGetResponseReceiverValue)
        return true
      }
      case ClientReportsSendRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendRequestReceiverModel.verify(), 'manager.ClientReportsSendRequest validation failed!')
        let deserialized = this._ClientReportsSendRequestReceiverModel.deserialize(this._ClientReportsSendRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsSendRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendRequestReceiverValue)
        this.onReceive_ClientReportsSendRequest(this._ClientReportsSendRequestReceiverValue)
        return true
      }
      case ClientReportsSendResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendResponseReceiverModel.verify(), 'manager.ClientReportsSendResponse validation failed!')
        let deserialized = this._ClientReportsSendResponseReceiverModel.deserialize(this._ClientReportsSendResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientReportsSendResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendResponseReceiverValue)
        this.onReceive_ClientReportsSendResponse(this._ClientReportsSendResponseReceiverValue)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestReceiverModel.verify(), 'manager.ProfitClosedGetRequest validation failed!')
        let deserialized = this._ProfitClosedGetRequestReceiverModel.deserialize(this._ProfitClosedGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ProfitClosedGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetRequestReceiverValue)
        this.onReceive_ProfitClosedGetRequest(this._ProfitClosedGetRequestReceiverValue)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseReceiverModel.verify(), 'manager.ProfitClosedGetResponse validation failed!')
        let deserialized = this._ProfitClosedGetResponseReceiverModel.deserialize(this._ProfitClosedGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ProfitClosedGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetResponseReceiverValue)
        this.onReceive_ProfitClosedGetResponse(this._ProfitClosedGetResponseReceiverValue)
        return true
      }
      case ChartBarsImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportRequestReceiverModel.verify(), 'manager.ChartBarsImportRequest validation failed!')
        let deserialized = this._ChartBarsImportRequestReceiverModel.deserialize(this._ChartBarsImportRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarsImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportRequestReceiverValue)
        this.onReceive_ChartBarsImportRequest(this._ChartBarsImportRequestReceiverValue)
        return true
      }
      case ChartBarsImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportResponseReceiverModel.verify(), 'manager.ChartBarsImportResponse validation failed!')
        let deserialized = this._ChartBarsImportResponseReceiverModel.deserialize(this._ChartBarsImportResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarsImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportResponseReceiverValue)
        this.onReceive_ChartBarsImportResponse(this._ChartBarsImportResponseReceiverValue)
        return true
      }
      case ChartBarsImportNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportNotifyReceiverModel.verify(), 'manager.ChartBarsImportNotify validation failed!')
        let deserialized = this._ChartBarsImportNotifyReceiverModel.deserialize(this._ChartBarsImportNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ChartBarsImportNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportNotifyReceiverValue)
        this.onReceive_ChartBarsImportNotify(this._ChartBarsImportNotifyReceiverValue)
        return true
      }
      case JournalBusinessRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalBusinessRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._JournalBusinessRequestReceiverModel.verify(), 'manager.JournalBusinessRequest validation failed!')
        let deserialized = this._JournalBusinessRequestReceiverModel.deserialize(this._JournalBusinessRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.JournalBusinessRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalBusinessRequestReceiverValue)
        this.onReceive_JournalBusinessRequest(this._JournalBusinessRequestReceiverValue)
        return true
      }
      case JournalBusinessResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._JournalBusinessResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._JournalBusinessResponseReceiverModel.verify(), 'manager.JournalBusinessResponse validation failed!')
        let deserialized = this._JournalBusinessResponseReceiverModel.deserialize(this._JournalBusinessResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.JournalBusinessResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._JournalBusinessResponseReceiverValue)
        this.onReceive_JournalBusinessResponse(this._JournalBusinessResponseReceiverValue)
        return true
      }
      case AccountsGetPageRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetPageRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetPageRequestReceiverModel.verify(), 'manager.AccountsGetPageRequest validation failed!')
        let deserialized = this._AccountsGetPageRequestReceiverModel.deserialize(this._AccountsGetPageRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetPageRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetPageRequestReceiverValue)
        this.onReceive_AccountsGetPageRequest(this._AccountsGetPageRequestReceiverValue)
        return true
      }
      case AccountsGetPageResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetPageResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetPageResponseReceiverModel.verify(), 'manager.AccountsGetPageResponse validation failed!')
        let deserialized = this._AccountsGetPageResponseReceiverModel.deserialize(this._AccountsGetPageResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountsGetPageResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetPageResponseReceiverValue)
        this.onReceive_AccountsGetPageResponse(this._AccountsGetPageResponseReceiverValue)
        return true
      }
      case HeartbeatNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HeartbeatNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HeartbeatNotifyReceiverModel.verify(), 'manager.HeartbeatNotify validation failed!')
        let deserialized = this._HeartbeatNotifyReceiverModel.deserialize(this._HeartbeatNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.HeartbeatNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HeartbeatNotifyReceiverValue)
        this.onReceive_HeartbeatNotify(this._HeartbeatNotifyReceiverValue)
        return true
      }
      case OrdersClosedSummariesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetRequestReceiverModel.verify(), 'manager.OrdersClosedSummariesGetRequest validation failed!')
        let deserialized = this._OrdersClosedSummariesGetRequestReceiverModel.deserialize(this._OrdersClosedSummariesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersClosedSummariesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetRequestReceiverValue)
        this.onReceive_OrdersClosedSummariesGetRequest(this._OrdersClosedSummariesGetRequestReceiverValue)
        return true
      }
      case OrdersClosedSummariesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetResponseReceiverModel.verify(), 'manager.OrdersClosedSummariesGetResponse validation failed!')
        let deserialized = this._OrdersClosedSummariesGetResponseReceiverModel.deserialize(this._OrdersClosedSummariesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersClosedSummariesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetResponseReceiverValue)
        this.onReceive_OrdersClosedSummariesGetResponse(this._OrdersClosedSummariesGetResponseReceiverValue)
        return true
      }
      case OrdersGetPageRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetPageRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetPageRequestReceiverModel.verify(), 'manager.OrdersGetPageRequest validation failed!')
        let deserialized = this._OrdersGetPageRequestReceiverModel.deserialize(this._OrdersGetPageRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetPageRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetPageRequestReceiverValue)
        this.onReceive_OrdersGetPageRequest(this._OrdersGetPageRequestReceiverValue)
        return true
      }
      case OrdersGetPageResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetPageResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetPageResponseReceiverModel.verify(), 'manager.OrdersGetPageResponse validation failed!')
        let deserialized = this._OrdersGetPageResponseReceiverModel.deserialize(this._OrdersGetPageResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.OrdersGetPageResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetPageResponseReceiverValue)
        this.onReceive_OrdersGetPageResponse(this._OrdersGetPageResponseReceiverValue)
        return true
      }
      case StorageUniversalNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageUniversalNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StorageUniversalNotifyReceiverModel.verify(), 'manager.StorageUniversalNotify validation failed!')
        let deserialized = this._StorageUniversalNotifyReceiverModel.deserialize(this._StorageUniversalNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.StorageUniversalNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageUniversalNotifyReceiverValue)
        this.onReceive_StorageUniversalNotify(this._StorageUniversalNotifyReceiverValue)
        return true
      }
      case ClientLoginInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetRequestReceiverModel.verify(), 'manager.ClientLoginInfoGetRequest validation failed!')
        let deserialized = this._ClientLoginInfoGetRequestReceiverModel.deserialize(this._ClientLoginInfoGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientLoginInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetRequestReceiverValue)
        this.onReceive_ClientLoginInfoGetRequest(this._ClientLoginInfoGetRequestReceiverValue)
        return true
      }
      case ClientLoginInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetResponseReceiverModel.verify(), 'manager.ClientLoginInfoGetResponse validation failed!')
        let deserialized = this._ClientLoginInfoGetResponseReceiverModel.deserialize(this._ClientLoginInfoGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.ClientLoginInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetResponseReceiverValue)
        this.onReceive_ClientLoginInfoGetResponse(this._ClientLoginInfoGetResponseReceiverValue)
        return true
      }
      case AccountGroupExistsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupExistsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupExistsGetRequestReceiverModel.verify(), 'manager.AccountGroupExistsGetRequest validation failed!')
        let deserialized = this._AccountGroupExistsGetRequestReceiverModel.deserialize(this._AccountGroupExistsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupExistsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupExistsGetRequestReceiverValue)
        this.onReceive_AccountGroupExistsGetRequest(this._AccountGroupExistsGetRequestReceiverValue)
        return true
      }
      case AccountGroupExistsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountGroupExistsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountGroupExistsGetResponseReceiverModel.verify(), 'manager.AccountGroupExistsGetResponse validation failed!')
        let deserialized = this._AccountGroupExistsGetResponseReceiverModel.deserialize(this._AccountGroupExistsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.AccountGroupExistsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountGroupExistsGetResponseReceiverValue)
        this.onReceive_AccountGroupExistsGetResponse(this._AccountGroupExistsGetResponseReceiverValue)
        return true
      }
      case LoginWithPasswordRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordRequestReceiverModel.verify(), 'manager.LoginWithPasswordRequest validation failed!')
        let deserialized = this._LoginWithPasswordRequestReceiverModel.deserialize(this._LoginWithPasswordRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithPasswordRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordRequestReceiverValue)
        this.onReceive_LoginWithPasswordRequest(this._LoginWithPasswordRequestReceiverValue)
        return true
      }
      case LoginWithPasswordResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordResponseReceiverModel.verify(), 'manager.LoginWithPasswordResponse validation failed!')
        let deserialized = this._LoginWithPasswordResponseReceiverModel.deserialize(this._LoginWithPasswordResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithPasswordResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordResponseReceiverValue)
        this.onReceive_LoginWithPasswordResponse(this._LoginWithPasswordResponseReceiverValue)
        return true
      }
      case LoginWithTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenRequestReceiverModel.verify(), 'manager.LoginWithTokenRequest validation failed!')
        let deserialized = this._LoginWithTokenRequestReceiverModel.deserialize(this._LoginWithTokenRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenRequestReceiverValue)
        this.onReceive_LoginWithTokenRequest(this._LoginWithTokenRequestReceiverValue)
        return true
      }
      case LoginWithTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenResponseReceiverModel.verify(), 'manager.LoginWithTokenResponse validation failed!')
        let deserialized = this._LoginWithTokenResponseReceiverModel.deserialize(this._LoginWithTokenResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.LoginWithTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenResponseReceiverValue)
        this.onReceive_LoginWithTokenResponse(this._LoginWithTokenResponseReceiverValue)
        return true
      }
      case RefreshTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenRequestReceiverModel.verify(), 'manager.RefreshTokenRequest validation failed!')
        let deserialized = this._RefreshTokenRequestReceiverModel.deserialize(this._RefreshTokenRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.RefreshTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenRequestReceiverValue)
        this.onReceive_RefreshTokenRequest(this._RefreshTokenRequestReceiverValue)
        return true
      }
      case RefreshTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenResponseReceiverModel.verify(), 'manager.RefreshTokenResponse validation failed!')
        let deserialized = this._RefreshTokenResponseReceiverModel.deserialize(this._RefreshTokenResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.RefreshTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenResponseReceiverValue)
        this.onReceive_RefreshTokenResponse(this._RefreshTokenResponseReceiverValue)
        return true
      }
      case PersonGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonGetRequestReceiverModel.verify(), 'manager.PersonGetRequest validation failed!')
        let deserialized = this._PersonGetRequestReceiverModel.deserialize(this._PersonGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonGetRequestReceiverValue)
        this.onReceive_PersonGetRequest(this._PersonGetRequestReceiverValue)
        return true
      }
      case PersonGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonGetResponseReceiverModel.verify(), 'manager.PersonGetResponse validation failed!')
        let deserialized = this._PersonGetResponseReceiverModel.deserialize(this._PersonGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonGetResponseReceiverValue)
        this.onReceive_PersonGetResponse(this._PersonGetResponseReceiverValue)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestReceiverModel.verify(), 'manager.PersonsGetAllRequest validation failed!')
        let deserialized = this._PersonsGetAllRequestReceiverModel.deserialize(this._PersonsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllRequestReceiverValue)
        this.onReceive_PersonsGetAllRequest(this._PersonsGetAllRequestReceiverValue)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseReceiverModel.verify(), 'manager.PersonsGetAllResponse validation failed!')
        let deserialized = this._PersonsGetAllResponseReceiverModel.deserialize(this._PersonsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllResponseReceiverValue)
        this.onReceive_PersonsGetAllResponse(this._PersonsGetAllResponseReceiverValue)
        return true
      }
      case PersonCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonCreateRequestReceiverModel.verify(), 'manager.PersonCreateRequest validation failed!')
        let deserialized = this._PersonCreateRequestReceiverModel.deserialize(this._PersonCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonCreateRequestReceiverValue)
        this.onReceive_PersonCreateRequest(this._PersonCreateRequestReceiverValue)
        return true
      }
      case PersonCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonCreateResponseReceiverModel.verify(), 'manager.PersonCreateResponse validation failed!')
        let deserialized = this._PersonCreateResponseReceiverModel.deserialize(this._PersonCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonCreateResponseReceiverValue)
        this.onReceive_PersonCreateResponse(this._PersonCreateResponseReceiverValue)
        return true
      }
      case PersonModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonModifyRequestReceiverModel.verify(), 'manager.PersonModifyRequest validation failed!')
        let deserialized = this._PersonModifyRequestReceiverModel.deserialize(this._PersonModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonModifyRequestReceiverValue)
        this.onReceive_PersonModifyRequest(this._PersonModifyRequestReceiverValue)
        return true
      }
      case PersonModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonModifyResponseReceiverModel.verify(), 'manager.PersonModifyResponse validation failed!')
        let deserialized = this._PersonModifyResponseReceiverModel.deserialize(this._PersonModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonModifyResponseReceiverValue)
        this.onReceive_PersonModifyResponse(this._PersonModifyResponseReceiverValue)
        return true
      }
      case PersonUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateNotifyReceiverModel.verify(), 'manager.PersonUpdateNotify validation failed!')
        let deserialized = this._PersonUpdateNotifyReceiverModel.deserialize(this._PersonUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.PersonUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateNotifyReceiverValue)
        this.onReceive_PersonUpdateNotify(this._PersonUpdateNotifyReceiverValue)
        return true
      }
      case SecuritySettingsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetRequestReceiverModel.verify(), 'manager.SecuritySettingsGetRequest validation failed!')
        let deserialized = this._SecuritySettingsGetRequestReceiverModel.deserialize(this._SecuritySettingsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetRequestReceiverValue)
        this.onReceive_SecuritySettingsGetRequest(this._SecuritySettingsGetRequestReceiverValue)
        return true
      }
      case SecuritySettingsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetResponseReceiverModel.verify(), 'manager.SecuritySettingsGetResponse validation failed!')
        let deserialized = this._SecuritySettingsGetResponseReceiverModel.deserialize(this._SecuritySettingsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetResponseReceiverValue)
        this.onReceive_SecuritySettingsGetResponse(this._SecuritySettingsGetResponseReceiverValue)
        return true
      }
      case SecuritySettingsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetAllRequestReceiverModel.verify(), 'manager.SecuritySettingsGetAllRequest validation failed!')
        let deserialized = this._SecuritySettingsGetAllRequestReceiverModel.deserialize(this._SecuritySettingsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetAllRequestReceiverValue)
        this.onReceive_SecuritySettingsGetAllRequest(this._SecuritySettingsGetAllRequestReceiverValue)
        return true
      }
      case SecuritySettingsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsGetAllResponseReceiverModel.verify(), 'manager.SecuritySettingsGetAllResponse validation failed!')
        let deserialized = this._SecuritySettingsGetAllResponseReceiverModel.deserialize(this._SecuritySettingsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsGetAllResponseReceiverValue)
        this.onReceive_SecuritySettingsGetAllResponse(this._SecuritySettingsGetAllResponseReceiverValue)
        return true
      }
      case SecuritySettingsCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsCreateRequestReceiverModel.verify(), 'manager.SecuritySettingsCreateRequest validation failed!')
        let deserialized = this._SecuritySettingsCreateRequestReceiverModel.deserialize(this._SecuritySettingsCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsCreateRequestReceiverValue)
        this.onReceive_SecuritySettingsCreateRequest(this._SecuritySettingsCreateRequestReceiverValue)
        return true
      }
      case SecuritySettingsCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsCreateResponseReceiverModel.verify(), 'manager.SecuritySettingsCreateResponse validation failed!')
        let deserialized = this._SecuritySettingsCreateResponseReceiverModel.deserialize(this._SecuritySettingsCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsCreateResponseReceiverValue)
        this.onReceive_SecuritySettingsCreateResponse(this._SecuritySettingsCreateResponseReceiverValue)
        return true
      }
      case SecuritySettingsModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsModifyRequestReceiverModel.verify(), 'manager.SecuritySettingsModifyRequest validation failed!')
        let deserialized = this._SecuritySettingsModifyRequestReceiverModel.deserialize(this._SecuritySettingsModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsModifyRequestReceiverValue)
        this.onReceive_SecuritySettingsModifyRequest(this._SecuritySettingsModifyRequestReceiverValue)
        return true
      }
      case SecuritySettingsModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsModifyResponseReceiverModel.verify(), 'manager.SecuritySettingsModifyResponse validation failed!')
        let deserialized = this._SecuritySettingsModifyResponseReceiverModel.deserialize(this._SecuritySettingsModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsModifyResponseReceiverValue)
        this.onReceive_SecuritySettingsModifyResponse(this._SecuritySettingsModifyResponseReceiverValue)
        return true
      }
      case SecuritySettingsDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsDeleteRequestReceiverModel.verify(), 'manager.SecuritySettingsDeleteRequest validation failed!')
        let deserialized = this._SecuritySettingsDeleteRequestReceiverModel.deserialize(this._SecuritySettingsDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsDeleteRequestReceiverValue)
        this.onReceive_SecuritySettingsDeleteRequest(this._SecuritySettingsDeleteRequestReceiverValue)
        return true
      }
      case SecuritySettingsDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsDeleteResponseReceiverModel.verify(), 'manager.SecuritySettingsDeleteResponse validation failed!')
        let deserialized = this._SecuritySettingsDeleteResponseReceiverModel.deserialize(this._SecuritySettingsDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsDeleteResponseReceiverValue)
        this.onReceive_SecuritySettingsDeleteResponse(this._SecuritySettingsDeleteResponseReceiverValue)
        return true
      }
      case SecuritySettingsUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SecuritySettingsUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SecuritySettingsUpdateNotifyReceiverModel.verify(), 'manager.SecuritySettingsUpdateNotify validation failed!')
        let deserialized = this._SecuritySettingsUpdateNotifyReceiverModel.deserialize(this._SecuritySettingsUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.SecuritySettingsUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SecuritySettingsUpdateNotifyReceiverValue)
        this.onReceive_SecuritySettingsUpdateNotify(this._SecuritySettingsUpdateNotifyReceiverValue)
        return true
      }
      case FirewallRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleGetRequestReceiverModel.verify(), 'manager.FirewallRuleGetRequest validation failed!')
        let deserialized = this._FirewallRuleGetRequestReceiverModel.deserialize(this._FirewallRuleGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleGetRequestReceiverValue)
        this.onReceive_FirewallRuleGetRequest(this._FirewallRuleGetRequestReceiverValue)
        return true
      }
      case FirewallRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleGetResponseReceiverModel.verify(), 'manager.FirewallRuleGetResponse validation failed!')
        let deserialized = this._FirewallRuleGetResponseReceiverModel.deserialize(this._FirewallRuleGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleGetResponseReceiverValue)
        this.onReceive_FirewallRuleGetResponse(this._FirewallRuleGetResponseReceiverValue)
        return true
      }
      case FirewallRulesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRulesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRulesGetAllRequestReceiverModel.verify(), 'manager.FirewallRulesGetAllRequest validation failed!')
        let deserialized = this._FirewallRulesGetAllRequestReceiverModel.deserialize(this._FirewallRulesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRulesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRulesGetAllRequestReceiverValue)
        this.onReceive_FirewallRulesGetAllRequest(this._FirewallRulesGetAllRequestReceiverValue)
        return true
      }
      case FirewallRulesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRulesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRulesGetAllResponseReceiverModel.verify(), 'manager.FirewallRulesGetAllResponse validation failed!')
        let deserialized = this._FirewallRulesGetAllResponseReceiverModel.deserialize(this._FirewallRulesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRulesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRulesGetAllResponseReceiverValue)
        this.onReceive_FirewallRulesGetAllResponse(this._FirewallRulesGetAllResponseReceiverValue)
        return true
      }
      case FirewallRuleCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleCreateRequestReceiverModel.verify(), 'manager.FirewallRuleCreateRequest validation failed!')
        let deserialized = this._FirewallRuleCreateRequestReceiverModel.deserialize(this._FirewallRuleCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleCreateRequestReceiverValue)
        this.onReceive_FirewallRuleCreateRequest(this._FirewallRuleCreateRequestReceiverValue)
        return true
      }
      case FirewallRuleCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleCreateResponseReceiverModel.verify(), 'manager.FirewallRuleCreateResponse validation failed!')
        let deserialized = this._FirewallRuleCreateResponseReceiverModel.deserialize(this._FirewallRuleCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleCreateResponseReceiverValue)
        this.onReceive_FirewallRuleCreateResponse(this._FirewallRuleCreateResponseReceiverValue)
        return true
      }
      case FirewallRuleModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleModifyRequestReceiverModel.verify(), 'manager.FirewallRuleModifyRequest validation failed!')
        let deserialized = this._FirewallRuleModifyRequestReceiverModel.deserialize(this._FirewallRuleModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleModifyRequestReceiverValue)
        this.onReceive_FirewallRuleModifyRequest(this._FirewallRuleModifyRequestReceiverValue)
        return true
      }
      case FirewallRuleModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleModifyResponseReceiverModel.verify(), 'manager.FirewallRuleModifyResponse validation failed!')
        let deserialized = this._FirewallRuleModifyResponseReceiverModel.deserialize(this._FirewallRuleModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleModifyResponseReceiverValue)
        this.onReceive_FirewallRuleModifyResponse(this._FirewallRuleModifyResponseReceiverValue)
        return true
      }
      case FirewallRuleDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleDeleteRequestReceiverModel.verify(), 'manager.FirewallRuleDeleteRequest validation failed!')
        let deserialized = this._FirewallRuleDeleteRequestReceiverModel.deserialize(this._FirewallRuleDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleDeleteRequestReceiverValue)
        this.onReceive_FirewallRuleDeleteRequest(this._FirewallRuleDeleteRequestReceiverValue)
        return true
      }
      case FirewallRuleDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleDeleteResponseReceiverModel.verify(), 'manager.FirewallRuleDeleteResponse validation failed!')
        let deserialized = this._FirewallRuleDeleteResponseReceiverModel.deserialize(this._FirewallRuleDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleDeleteResponseReceiverValue)
        this.onReceive_FirewallRuleDeleteResponse(this._FirewallRuleDeleteResponseReceiverValue)
        return true
      }
      case FirewallRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FirewallRuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FirewallRuleUpdateNotifyReceiverModel.verify(), 'manager.FirewallRuleUpdateNotify validation failed!')
        let deserialized = this._FirewallRuleUpdateNotifyReceiverModel.deserialize(this._FirewallRuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'manager.FirewallRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FirewallRuleUpdateNotifyReceiverValue)
        this.onReceive_FirewallRuleUpdateNotify(this._FirewallRuleUpdateNotifyReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.configClient != null) && this.configClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.historyClient != null) && this.historyClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.clientClient != null) && this.clientClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.feedClient != null) && this.feedClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.storageClient != null) && this.storageClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.exposedClient != null) && this.exposedClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.executionClient != null) && this.executionClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.backupClient != null) && this.backupClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerClient != null) && this.innerClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.tradeClient != null) && this.tradeClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
    this._configClient.onReceiveLogHandler = handler
    this._historyClient.onReceiveLogHandler = handler
    this._clientClient.onReceiveLogHandler = handler
    this._feedClient.onReceiveLogHandler = handler
    this._storageClient.onReceiveLogHandler = handler
    this._exposedClient.onReceiveLogHandler = handler
    this._executionClient.onReceiveLogHandler = handler
    this._backupClient.onReceiveLogHandler = handler
    this._innerClient.onReceiveLogHandler = handler
    this._tradeClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._configClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._historyClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._clientClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._feedClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._storageClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._exposedClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._executionClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._backupClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._innerClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._tradeClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._configClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._historyClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._clientClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._feedClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._storageClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._exposedClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._executionClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._backupClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._innerClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._tradeClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()
    this._configClient.resetRequests()
    this._historyClient.resetRequests()
    this._clientClient.resetRequests()
    this._feedClient.resetRequests()
    this._storageClient.resetRequests()
    this._exposedClient.resetRequests()
    this._executionClient.resetRequests()
    this._backupClient.resetRequests()
    this._innerClient.resetRequests()
    this._tradeClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)
    this._configClient.watchdogRequests(utc)
    this._historyClient.watchdogRequests(utc)
    this._clientClient.watchdogRequests(utc)
    this._feedClient.watchdogRequests(utc)
    this._storageClient.watchdogRequests(utc)
    this._exposedClient.watchdogRequests(utc)
    this._executionClient.watchdogRequests(utc)
    this._backupClient.watchdogRequests(utc)
    this._innerClient.watchdogRequests(utc)
    this._tradeClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
