
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {TickHistoryResponse} from './TickHistoryResponse';

/**
 * TickHistoryRequest struct
 */
class TickHistoryRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!UUID=} symbolId
   * @param {!Date=} timeFrom
   * @param {!Date=} timeTo
   * @param {!number=} offset
   * @param {!number=} limit
   * @constructor
   */
  constructor (parent = new Request(), symbolId = new UUID(), timeFrom = new Date(0), timeTo = new Date(0), offset = new UInt64(0, 0), limit = new UInt64(100, 0)) {
    super()
    super.copy(parent)
    this.SymbolId = symbolId
    this.TimeFrom = timeFrom
    this.TimeTo = timeTo
    this.Offset = offset
    this.Limit = limit
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TickHistoryRequest}
   * @param {!TickHistoryRequest} other Other struct
   * @returns {!TickHistoryRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.TimeFrom != null) {
      if (other.TimeFrom instanceof Date) {
        this.TimeFrom = new Date(other.TimeFrom.getTime())
      } else {
        this.TimeFrom = new Date(Math.round(other.TimeFrom / 1000000))
      }
    } else {
      this.TimeFrom = null
    }
    if (other.TimeTo != null) {
      if (other.TimeTo instanceof Date) {
        this.TimeTo = new Date(other.TimeTo.getTime())
      } else {
        this.TimeTo = new Date(Math.round(other.TimeTo / 1000000))
      }
    } else {
      this.TimeTo = null
    }
    if (other.Offset != null) {
      this.Offset = UInt64.fromNumber(other.Offset)
    } else {
      this.Offset = null
    }
    if (other.Limit != null) {
      this.Limit = UInt64.fromNumber(other.Limit)
    } else {
      this.Limit = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TickHistoryRequest}
   * @returns {!TickHistoryRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TickHistoryRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TickHistoryRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TickHistoryRequest}
   * @param {!TickHistoryRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TickHistoryRequest)) {
      throw new TypeError('Instance of TickHistoryRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TickHistoryRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      TimeFrom: ((this.TimeFrom != null) ? (this.TimeFrom.getTime() * 1000000) : null),
      TimeTo: ((this.TimeTo != null) ? (this.TimeTo.getTime() * 1000000) : null),
      Offset: ((this.Offset != null) ? this.Offset.toNumber() : null),
      Limit: ((this.Limit != null) ? this.Limit.toNumber() : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TickHistoryRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TickHistoryRequest} other Object value
   * @returns {!TickHistoryRequest} Created struct
   */
  static fromObject (other) {
    return new TickHistoryRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TickHistoryRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TickHistoryRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TickHistoryRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 3008
  }
}

export { TickHistoryRequest };

/**
 * Fast Binary Encoding TickHistoryRequest field model
 */
class FieldModelTickHistoryRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.SymbolId = new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.TimeFrom = new fbe.FieldModelTimestamp(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.TimeTo = new fbe.FieldModelTimestamp(buffer, this.TimeFrom.fbeOffset + this.TimeFrom.fbeSize)
    this.Offset = new fbe.FieldModelUInt64(buffer, this.TimeTo.fbeOffset + this.TimeTo.fbeSize)
    this.Limit = new fbe.FieldModelUInt64(buffer, this.Offset.fbeOffset + this.Offset.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SymbolId.fbeSize + this.TimeFrom.fbeSize + this.TimeTo.fbeSize + this.Offset.fbeSize + this.Limit.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SymbolId.fbeExtra + this.TimeFrom.fbeExtra + this.TimeTo.fbeExtra + this.Offset.fbeExtra + this.Limit.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTickHistoryRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 3008
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTickHistoryRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTickHistoryRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.TimeFrom.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimeFrom.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeFrom.fbeSize

    if ((fbeCurrentSize + this.TimeTo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimeTo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeTo.fbeSize

    if ((fbeCurrentSize + this.Offset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Offset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Offset.fbeSize

    if ((fbeCurrentSize + this.Limit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Limit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Limit.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTickHistoryRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTickHistoryRequest}
   * @param {!TickHistoryRequest} fbeValue Default value, defaults is new TickHistoryRequest()
   * @returns {!TickHistoryRequest} TickHistoryRequest value
   */
  get (fbeValue = new TickHistoryRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTickHistoryRequest}
   * @param {!TickHistoryRequest} fbeValue TickHistoryRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.TimeFrom.fbeSize) <= fbeStructSize) {
      fbeValue.TimeFrom = this.TimeFrom.get()
    } else {
      fbeValue.TimeFrom = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeFrom.fbeSize

    if ((fbeCurrentSize + this.TimeTo.fbeSize) <= fbeStructSize) {
      fbeValue.TimeTo = this.TimeTo.get()
    } else {
      fbeValue.TimeTo = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeTo.fbeSize

    if ((fbeCurrentSize + this.Offset.fbeSize) <= fbeStructSize) {
      fbeValue.Offset = this.Offset.get()
    } else {
      fbeValue.Offset = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Offset.fbeSize

    if ((fbeCurrentSize + this.Limit.fbeSize) <= fbeStructSize) {
      fbeValue.Limit = this.Limit.get(new UInt64(100, 0))
    } else {
      fbeValue.Limit = new UInt64(100, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Limit.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTickHistoryRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTickHistoryRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTickHistoryRequest}
   * @param {!TickHistoryRequest} fbeValue TickHistoryRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTickHistoryRequest}
   * @param {TickHistoryRequest} fbeValue TickHistoryRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SymbolId.set(fbeValue.SymbolId)
    this.TimeFrom.set(fbeValue.TimeFrom)
    this.TimeTo.set(fbeValue.TimeTo)
    this.Offset.set(fbeValue.Offset)
    this.Limit.set(fbeValue.Limit)
  }
}

export { FieldModelTickHistoryRequest };

/**
 * Fast Binary Encoding TickHistoryRequest model
 */
class TickHistoryRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTickHistoryRequest(this.buffer, 4)
  }

  /**
   * Get the TickHistoryRequest model
   * @this {!TickHistoryRequestModel}
   * @returns {!FieldModelTickHistoryRequest} model TickHistoryRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TickHistoryRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TickHistoryRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TickHistoryRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TickHistoryRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTickHistoryRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TickHistoryRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TickHistoryRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TickHistoryRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TickHistoryRequestModel}
   * @param {!TickHistoryRequest} value TickHistoryRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TickHistoryRequestModel}
   * @param {!TickHistoryRequest} value TickHistoryRequest value, defaults is new TickHistoryRequest()
   * @return {!object} Deserialized TickHistoryRequest value and its size
   */
  deserialize (value = new TickHistoryRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TickHistoryRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TickHistoryRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TickHistoryRequestModel}
   * @param {!number} prev Previous TickHistoryRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TickHistoryRequestModel };
TickHistoryRequest.__has_response = true;
TickHistoryRequest.__response_class = TickHistoryResponse;
