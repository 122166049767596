
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {OrderCreateResponse} from './OrderCreateResponse';
import {OrderSide} from '../core/OrderSide';
import {FieldModelOrderSide} from '../core/OrderSide';
import {OrderType} from '../core/OrderType';
import {FieldModelOrderType} from '../core/OrderType';
import {OrderTimeInForce} from '../core/OrderTimeInForce';
import {FieldModelOrderTimeInForce} from '../core/OrderTimeInForce';

/**
 * OrderCreateRequest struct
 */
class OrderCreateRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!number=} login
   * @param {string=} clientOrderId
   * @param {UUID=} symbolId
   * @param {string=} symbolName
   * @param {!OrderSide=} orderSide
   * @param {!OrderType=} orderType
   * @param {OrderTimeInForce=} orderTimeInForce
   * @param {number=} price
   * @param {number=} stopPrice
   * @param {number=} takeProfit
   * @param {number=} stopLoss
   * @param {!number=} amount
   * @param {Date=} expiration
   * @param {!string=} accountComment
   * @param {!string=} managerComment
   * @param {number=} magic
   * @constructor
   */
  constructor (parent = new Request(), login = new UInt64(0, 0), clientOrderId = null, symbolId = null, symbolName = null, orderSide = new OrderSide(), orderType = new OrderType(), orderTimeInForce = null, price = null, stopPrice = null, takeProfit = null, stopLoss = null, amount = 0.0, expiration = null, accountComment = '', managerComment = '', magic = null) {
    super()
    super.copy(parent)
    this.Login = login
    this.ClientOrderId = clientOrderId
    this.SymbolId = symbolId
    this.SymbolName = symbolName
    this.OrderSide = orderSide
    this.OrderType = orderType
    this.OrderTimeInForce = orderTimeInForce
    this.Price = price
    this.StopPrice = stopPrice
    this.TakeProfit = takeProfit
    this.StopLoss = stopLoss
    this.Amount = amount
    this.Expiration = expiration
    this.AccountComment = accountComment
    this.ManagerComment = managerComment
    this.Magic = magic
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderCreateRequest}
   * @param {!OrderCreateRequest} other Other struct
   * @returns {!OrderCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ClientOrderId != null) {
      this.ClientOrderId = other.ClientOrderId
    } else {
      this.ClientOrderId = null
    }
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.SymbolName != null) {
      this.SymbolName = other.SymbolName
    } else {
      this.SymbolName = null
    }
    if (other.OrderSide != null) {
      this.OrderSide = OrderSide.fromObject(other.OrderSide)
    } else {
      this.OrderSide = null
    }
    if (other.OrderType != null) {
      this.OrderType = OrderType.fromObject(other.OrderType)
    } else {
      this.OrderType = null
    }
    if (other.OrderTimeInForce != null) {
      this.OrderTimeInForce = OrderTimeInForce.fromObject(other.OrderTimeInForce)
    } else {
      this.OrderTimeInForce = null
    }
    if (other.Price != null) {
      this.Price = other.Price
    } else {
      this.Price = null
    }
    if (other.StopPrice != null) {
      this.StopPrice = other.StopPrice
    } else {
      this.StopPrice = null
    }
    if (other.TakeProfit != null) {
      this.TakeProfit = other.TakeProfit
    } else {
      this.TakeProfit = null
    }
    if (other.StopLoss != null) {
      this.StopLoss = other.StopLoss
    } else {
      this.StopLoss = null
    }
    if (other.Amount != null) {
      this.Amount = other.Amount
    } else {
      this.Amount = null
    }
    if (other.Expiration != null) {
      if (other.Expiration instanceof Date) {
        this.Expiration = new Date(other.Expiration.getTime())
      } else {
        this.Expiration = new Date(Math.round(other.Expiration / 1000000))
      }
    } else {
      this.Expiration = null
    }
    if (other.AccountComment != null) {
      this.AccountComment = other.AccountComment
    } else {
      this.AccountComment = null
    }
    if (other.ManagerComment != null) {
      this.ManagerComment = other.ManagerComment
    } else {
      this.ManagerComment = null
    }
    if (other.Magic != null) {
      this.Magic = other.Magic
    } else {
      this.Magic = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderCreateRequest}
   * @returns {!OrderCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderCreateRequest}
   * @param {!OrderCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderCreateRequest)) {
      throw new TypeError('Instance of OrderCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ClientOrderId: ((this.ClientOrderId != null) ? this.ClientOrderId : null),
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      SymbolName: ((this.SymbolName != null) ? this.SymbolName : null),
      OrderSide: ((this.OrderSide != null) ? this.OrderSide : null),
      OrderType: ((this.OrderType != null) ? this.OrderType : null),
      OrderTimeInForce: ((this.OrderTimeInForce != null) ? this.OrderTimeInForce : null),
      Price: ((this.Price != null) ? this.Price : null),
      StopPrice: ((this.StopPrice != null) ? this.StopPrice : null),
      TakeProfit: ((this.TakeProfit != null) ? this.TakeProfit : null),
      StopLoss: ((this.StopLoss != null) ? this.StopLoss : null),
      Amount: ((this.Amount != null) ? this.Amount : null),
      Expiration: ((this.Expiration != null) ? (this.Expiration.getTime() * 1000000) : null),
      AccountComment: ((this.AccountComment != null) ? this.AccountComment : null),
      ManagerComment: ((this.ManagerComment != null) ? this.ManagerComment : null),
      Magic: ((this.Magic != null) ? this.Magic : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderCreateRequest} other Object value
   * @returns {!OrderCreateRequest} Created struct
   */
  static fromObject (other) {
    return new OrderCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6221
  }
}

export { OrderCreateRequest };

/**
 * Fast Binary Encoding OrderCreateRequest field model
 */
class FieldModelOrderCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.Login = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.ClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.SymbolId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize), buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize)
    this.SymbolName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize), buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.OrderSide = new FieldModelOrderSide(buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize)
    this.OrderType = new FieldModelOrderType(buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize)
    this.OrderTimeInForce = new fbe.FieldModelOptional(new FieldModelOrderTimeInForce(buffer, this.OrderType.fbeOffset + this.OrderType.fbeSize), buffer, this.OrderType.fbeOffset + this.OrderType.fbeSize)
    this.Price = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OrderTimeInForce.fbeOffset + this.OrderTimeInForce.fbeSize), buffer, this.OrderTimeInForce.fbeOffset + this.OrderTimeInForce.fbeSize)
    this.StopPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Price.fbeOffset + this.Price.fbeSize), buffer, this.Price.fbeOffset + this.Price.fbeSize)
    this.TakeProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize), buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize)
    this.StopLoss = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize), buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize)
    this.Amount = new fbe.FieldModelDouble(buffer, this.StopLoss.fbeOffset + this.StopLoss.fbeSize)
    this.Expiration = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Amount.fbeOffset + this.Amount.fbeSize), buffer, this.Amount.fbeOffset + this.Amount.fbeSize)
    this.AccountComment = new fbe.FieldModelString(buffer, this.Expiration.fbeOffset + this.Expiration.fbeSize)
    this.ManagerComment = new fbe.FieldModelString(buffer, this.AccountComment.fbeOffset + this.AccountComment.fbeSize)
    this.Magic = new fbe.FieldModelOptional(new fbe.FieldModelInt32(buffer, this.ManagerComment.fbeOffset + this.ManagerComment.fbeSize), buffer, this.ManagerComment.fbeOffset + this.ManagerComment.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Login.fbeSize + this.ClientOrderId.fbeSize + this.SymbolId.fbeSize + this.SymbolName.fbeSize + this.OrderSide.fbeSize + this.OrderType.fbeSize + this.OrderTimeInForce.fbeSize + this.Price.fbeSize + this.StopPrice.fbeSize + this.TakeProfit.fbeSize + this.StopLoss.fbeSize + this.Amount.fbeSize + this.Expiration.fbeSize + this.AccountComment.fbeSize + this.ManagerComment.fbeSize + this.Magic.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Login.fbeExtra + this.ClientOrderId.fbeExtra + this.SymbolId.fbeExtra + this.SymbolName.fbeExtra + this.OrderSide.fbeExtra + this.OrderType.fbeExtra + this.OrderTimeInForce.fbeExtra + this.Price.fbeExtra + this.StopPrice.fbeExtra + this.TakeProfit.fbeExtra + this.StopLoss.fbeExtra + this.Amount.fbeExtra + this.Expiration.fbeExtra + this.AccountComment.fbeExtra + this.ManagerComment.fbeExtra + this.Magic.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6221
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderTimeInForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Price.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TakeProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopLoss.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Amount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Expiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.AccountComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountComment.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Magic.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderCreateRequest}
   * @param {!OrderCreateRequest} fbeValue Default value, defaults is new OrderCreateRequest()
   * @returns {!OrderCreateRequest} OrderCreateRequest value
   */
  get (fbeValue = new OrderCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderCreateRequest}
   * @param {!OrderCreateRequest} fbeValue OrderCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientOrderId = this.ClientOrderId.get()
    } else {
      fbeValue.ClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolName = this.SymbolName.get()
    } else {
      fbeValue.SymbolName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) <= fbeStructSize) {
      fbeValue.OrderSide = this.OrderSide.get()
    } else {
      fbeValue.OrderSide = new OrderSide()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderType = this.OrderType.get()
    } else {
      fbeValue.OrderType = new OrderType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) <= fbeStructSize) {
      fbeValue.OrderTimeInForce = this.OrderTimeInForce.get()
    } else {
      fbeValue.OrderTimeInForce = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) <= fbeStructSize) {
      fbeValue.Price = this.Price.get()
    } else {
      fbeValue.Price = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) <= fbeStructSize) {
      fbeValue.StopPrice = this.StopPrice.get()
    } else {
      fbeValue.StopPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TakeProfit = this.TakeProfit.get()
    } else {
      fbeValue.TakeProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) <= fbeStructSize) {
      fbeValue.StopLoss = this.StopLoss.get()
    } else {
      fbeValue.StopLoss = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) <= fbeStructSize) {
      fbeValue.Amount = this.Amount.get()
    } else {
      fbeValue.Amount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) <= fbeStructSize) {
      fbeValue.Expiration = this.Expiration.get()
    } else {
      fbeValue.Expiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.AccountComment.fbeSize) <= fbeStructSize) {
      fbeValue.AccountComment = this.AccountComment.get()
    } else {
      fbeValue.AccountComment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountComment.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerComment = this.ManagerComment.get()
    } else {
      fbeValue.ManagerComment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) <= fbeStructSize) {
      fbeValue.Magic = this.Magic.get()
    } else {
      fbeValue.Magic = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderCreateRequest}
   * @param {!OrderCreateRequest} fbeValue OrderCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderCreateRequest}
   * @param {OrderCreateRequest} fbeValue OrderCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Login.set(fbeValue.Login)
    this.ClientOrderId.set(fbeValue.ClientOrderId)
    this.SymbolId.set(fbeValue.SymbolId)
    this.SymbolName.set(fbeValue.SymbolName)
    this.OrderSide.set(fbeValue.OrderSide)
    this.OrderType.set(fbeValue.OrderType)
    this.OrderTimeInForce.set(fbeValue.OrderTimeInForce)
    this.Price.set(fbeValue.Price)
    this.StopPrice.set(fbeValue.StopPrice)
    this.TakeProfit.set(fbeValue.TakeProfit)
    this.StopLoss.set(fbeValue.StopLoss)
    this.Amount.set(fbeValue.Amount)
    this.Expiration.set(fbeValue.Expiration)
    this.AccountComment.set(fbeValue.AccountComment)
    this.ManagerComment.set(fbeValue.ManagerComment)
    this.Magic.set(fbeValue.Magic)
  }
}

export { FieldModelOrderCreateRequest };

/**
 * Fast Binary Encoding OrderCreateRequest model
 */
class OrderCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderCreateRequest(this.buffer, 4)
  }

  /**
   * Get the OrderCreateRequest model
   * @this {!OrderCreateRequestModel}
   * @returns {!FieldModelOrderCreateRequest} model OrderCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderCreateRequestModel}
   * @param {!OrderCreateRequest} value OrderCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderCreateRequestModel}
   * @param {!OrderCreateRequest} value OrderCreateRequest value, defaults is new OrderCreateRequest()
   * @return {!object} Deserialized OrderCreateRequest value and its size
   */
  deserialize (value = new OrderCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderCreateRequestModel}
   * @param {!number} prev Previous OrderCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderCreateRequestModel };
OrderCreateRequest.__has_response = true;
OrderCreateRequest.__response_class = OrderCreateResponse;
