import { Order } from '@api/fbe/core/Order';
import { OrderState } from '@api/fbe/core/OrderState';
import { OrderType } from '@api/fbe/core/OrderType';
import { OrderWithAccount } from '@api/fbe/core/OrderWithAccount';
import { Account, convertAccountRecordToAccountModel } from '@models/accounts/Account';
import { AccountMargin, convertMarginRecordToMarginModel } from '@models/accounts/AccountMargin';

export type OrderStateModel = {
    OrderId: number;
    MarginRate: number;
    Profit: number;
    CurrentPrice: number;
    DayProfit: number;
};

export type OrdersRequestParams = {
    SymbolName: string | null;
    TimeFrom: Date | null;
    TimeTo: Date | null;
    ExternalClientIds: string[];
    OrderIds: string[];
    Logins: string[];
    AccountGroupNames: string[];
};

export type OrderModel = {
    key?: number;
    Login: number;
    OrderId: number;
    ParentOrderId: number | null;
    ClientOrderId: string | null;
    ActualVersion: number;
    SymbolId: string;
    OrderSide: number;
    OrderType: number;
    OrderInitialType: number;
    OrderTimeInForce: number;
    OrderStatus: number;
    Price: number | null;
    StopPrice: number | null;
    TakeProfit: number | null;
    StopLoss: number | null;
    Amount: number;
    ExecutedAmount: number;
    RemainingAmount: number;
    Expiration: Date | null;
    AccountComment: string;
    ManagerComment: string;
    Created: Date;
    Modified: Date;
    Archived: Date;
    Deleted: Date;
    OpenPrice: number | null;
    ClosePrice: number | null;
    CloseAmount: number | null;
    OpenMarginRate: number | null;
    Commission: number | null;
    Swap: number | null;
    OpenMarkup: number | null;
    GatewayId: string | null;
    CloseProfit: number | null;
    Opened: Date;
    Closed: Date;
    OpenAccountMargin: number | null;
    OpenAccountEquity: number | null;
    OpenAccountCredit: number | null;
    OpenAmount: number | null;

    OrderRequestSourceType: number | null;
    IsCreatedViaQuickTrading: boolean | null;
    IsCreatedViaFastTrader: boolean | null;

    SocialTradingOrderType: number;
    SocialTradingOrderInvestor: {
        MasterOrderId: number;
        MasterLogin: number;
    } | null;
};

export type OrderWithAccountModel = {
    key?: number;
    Order: OrderModel;
    OrderState: OrderStateModel | null;
    Account: Account | null;
    AccountMargin: AccountMargin | null;
};

export const convertOrderStateToOrderStateModel = (record: OrderState): OrderStateModel => ({
    OrderId: record.OrderId.toInt32(),
    MarginRate: record.MarginRate,
    Profit: record.Profit,
    CurrentPrice: record.CurrentPrice,
    DayProfit: record.DayProfit,
});

export const convertOrderToOrderModel = (record: Order): OrderModel => ({
    Login: record.Login.toInt32(),
    OrderId: record.OrderId.toInt32(),
    ParentOrderId: record.ParentOrderId?.toInt32() ?? null,
    ClientOrderId: record.ClientOrderId ?? null,
    ActualVersion: record.ActualVersion.toInt32(),
    SymbolId: record.SymbolId.toString(),
    OrderSide: record.OrderSide.valueOf(),
    OrderType: record.OrderType.valueOf(),
    OrderInitialType: record.OrderInitialType.valueOf(),
    OrderTimeInForce: record.OrderTimeInForce.valueOf(),
    OrderStatus: record.OrderStatus.valueOf(),
    Price: record.Price ?? null,
    StopPrice: record.StopPrice ?? null,
    TakeProfit: record.TakeProfit ?? null,
    StopLoss: record.StopLoss ?? null,
    Amount: record.Amount,
    ExecutedAmount: record.ExecutedAmount,
    RemainingAmount: record.RemainingAmount,
    Expiration: record.Expiration ?? null,
    AccountComment: record.AccountComment,
    ManagerComment: record.ManagerComment,
    Created: record.Created,
    Modified: record.Modified,
    Archived: record.Archived,
    Deleted: record.Deleted,
    OpenPrice: record.OpenPrice ?? null,
    ClosePrice: record.ClosePrice ?? null,
    CloseAmount: record.CloseAmount ?? null,
    OpenMarginRate: record.OpenMarginRate ?? null,
    Commission: record.Commission ?? null,
    Swap: record.Swap ?? null,
    OpenMarkup: record.OpenMarkup?.toInt32() ?? null,
    GatewayId: record.GatewayId?.toString() ?? null,
    CloseProfit: record.CloseProfit ?? null,
    Opened: record.Opened,
    Closed: record.Closed,

    OpenAccountMargin: record.OpenAccountMargin ?? null,
    OpenAccountEquity: record.OpenAccountEquity ?? null,
    OpenAccountCredit: record.OpenAccountCredit ?? null,
    OpenAmount: record.OpenAmount ?? null,

    OrderRequestSourceType: record.OrderRequestSourceType.valueOf() ?? null,
    IsCreatedViaQuickTrading: record.IsCreatedViaQuickTrading ?? null,
    IsCreatedViaFastTrader: record.IsCreatedViaFastTrader ?? null,

    SocialTradingOrderType: record.SocialTradingOrderType.valueOf(),
    SocialTradingOrderInvestor: record.SocialTradingOrderInvestor
        ? {
              MasterOrderId: record.SocialTradingOrderInvestor?.MasterOrderId.toInt32(),
              MasterLogin: record.SocialTradingOrderInvestor?.MasterLogin.toInt32(),
          }
        : null,
});

export const convertOrderWithAccountsToModel = (record: OrderWithAccount): OrderWithAccountModel => {
    const orderValue = convertOrderToOrderModel(record.Order);
    const accountMarginValue = record.AccountMargin ? convertMarginRecordToMarginModel(record.AccountMargin) : null;
    const accountValueWithMargin = record.Account
        ? convertAccountRecordToAccountModel(record.Account, accountMarginValue)
        : null;
    const orderStateValue = record.OrderState ? convertOrderStateToOrderStateModel(record.OrderState) : null;

    return {
        Order: orderValue,
        Account: accountValueWithMargin,
        AccountMargin: accountMarginValue,
        OrderState: orderStateValue,
    };
};

export const positionsOrderTypes = [OrderType.MARKET.valueOf()]; // TODO: RID OF

export const pendingOrdersOrderTypes = [OrderType.LIMIT.valueOf(), OrderType.STOP.valueOf()]; // TODO: RID OF

export type CreateOrderModel = {
    Login: number;
    SymbolId: string;
    OrderSide: number;
    OrderType: number;
    Price: number | null;
    StopPrice: number | null;
    TakeProfit: number | null;
    StopLoss: number | null;
    Amount: number;
    ManagerComment: string;
};

export type ModifyOrderModelPartial = {
    Login: number;
    OrderId: number | null;
    SymbolId: string;
    OrderSide: number;
    OrderType: number;
    Price: number | null;
    StopPrice: number | null;
    TakeProfit: number | null;
    StopLoss: number | null;
    Amount: number;
    ManagerComment: string;
};

export type GetOrderRequestParams = {
    Login: number;
    OrderId: number | null;
};