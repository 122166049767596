
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ClientReportTemplatePlaceholder enum
 */
class ClientReportTemplatePlaceholder {
  /**
   * Initialize enum with a given value
   * @param {ClientReportTemplatePlaceholder|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof ClientReportTemplatePlaceholder) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!ClientReportTemplatePlaceholder}
   * @param {!ClientReportTemplatePlaceholder} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ClientReportTemplatePlaceholder)) {
      throw new TypeError('Instance of ClientReportTemplatePlaceholder is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!ClientReportTemplatePlaceholder}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!ClientReportTemplatePlaceholder}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === ClientReportTemplatePlaceholder.ACCOUNT.value) {
      return 'ACCOUNT'
    }
    if (this.value === ClientReportTemplatePlaceholder.NAME.value) {
      return 'NAME'
    }
    if (this.value === ClientReportTemplatePlaceholder.FULLTIME.value) {
      return 'FULLTIME'
    }
    if (this.value === ClientReportTemplatePlaceholder.CURRENCY.value) {
      return 'CURRENCY'
    }
    if (this.value === ClientReportTemplatePlaceholder.PREVDAYBALANCE.value) {
      return 'PREVDAYBALANCE'
    }
    if (this.value === ClientReportTemplatePlaceholder.CLOSED_CREDIT.value) {
      return 'CLOSED_CREDIT'
    }
    if (this.value === ClientReportTemplatePlaceholder.CLOSED_PL.value) {
      return 'CLOSED_PL'
    }
    if (this.value === ClientReportTemplatePlaceholder.CREDIT.value) {
      return 'CREDIT'
    }
    if (this.value === ClientReportTemplatePlaceholder.DEPOSIT.value) {
      return 'DEPOSIT'
    }
    if (this.value === ClientReportTemplatePlaceholder.EQUITY.value) {
      return 'EQUITY'
    }
    if (this.value === ClientReportTemplatePlaceholder.BALANCE.value) {
      return 'BALANCE'
    }
    if (this.value === ClientReportTemplatePlaceholder.MARGIN.value) {
      return 'MARGIN'
    }
    if (this.value === ClientReportTemplatePlaceholder.FLOAT_PL.value) {
      return 'FLOAT_PL'
    }
    if (this.value === ClientReportTemplatePlaceholder.FREEMARGIN.value) {
      return 'FREEMARGIN'
    }
    if (this.value === ClientReportTemplatePlaceholder.OPENPOSITIONS.value) {
      return 'OPENPOSITIONS'
    }
    if (this.value === ClientReportTemplatePlaceholder.OPENPOSITIONS_SUM_VIEW_TYPE.value) {
      return 'OPENPOSITIONS_SUM_VIEW_TYPE'
    }
    if (this.value === ClientReportTemplatePlaceholder.COMMISSION.value) {
      return 'COMMISSION'
    }
    if (this.value === ClientReportTemplatePlaceholder.STORAGE.value) {
      return 'STORAGE'
    }
    if (this.value === ClientReportTemplatePlaceholder.PROFIT.value) {
      return 'PROFIT'
    }
    if (this.value === ClientReportTemplatePlaceholder.OPENORDERS.value) {
      return 'OPENORDERS'
    }
    if (this.value === ClientReportTemplatePlaceholder.TODAYORDERS.value) {
      return 'TODAYORDERS'
    }
    if (this.value === ClientReportTemplatePlaceholder.TODAYORDERS_SUM_VIEW_TYPE.value) {
      return 'TODAYORDERS_SUM_VIEW_TYPE'
    }
    if (this.value === ClientReportTemplatePlaceholder.CLOSED_COMMISSION.value) {
      return 'CLOSED_COMMISSION'
    }
    if (this.value === ClientReportTemplatePlaceholder.CLOSED_STORAGE.value) {
      return 'CLOSED_STORAGE'
    }
    if (this.value === ClientReportTemplatePlaceholder.CLOSED_PROFIT.value) {
      return 'CLOSED_PROFIT'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!ClientReportTemplatePlaceholder}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!ClientReportTemplatePlaceholder}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!ClientReportTemplatePlaceholder} Created enum
   */
  static fromObject (other) {
    return new ClientReportTemplatePlaceholder(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.ACCOUNT = new ClientReportTemplatePlaceholder(0 + 0)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.NAME = new ClientReportTemplatePlaceholder(0 + 1)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.FULLTIME = new ClientReportTemplatePlaceholder(0 + 2)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.CURRENCY = new ClientReportTemplatePlaceholder(0 + 3)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.PREVDAYBALANCE = new ClientReportTemplatePlaceholder(0 + 4)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.CLOSED_CREDIT = new ClientReportTemplatePlaceholder(0 + 5)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.CLOSED_PL = new ClientReportTemplatePlaceholder(0 + 6)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.CREDIT = new ClientReportTemplatePlaceholder(0 + 7)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.DEPOSIT = new ClientReportTemplatePlaceholder(0 + 8)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.EQUITY = new ClientReportTemplatePlaceholder(0 + 9)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.BALANCE = new ClientReportTemplatePlaceholder(0 + 10)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.MARGIN = new ClientReportTemplatePlaceholder(0 + 11)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.FLOAT_PL = new ClientReportTemplatePlaceholder(0 + 12)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.FREEMARGIN = new ClientReportTemplatePlaceholder(0 + 13)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.OPENPOSITIONS = new ClientReportTemplatePlaceholder(0 + 14)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.OPENPOSITIONS_SUM_VIEW_TYPE = new ClientReportTemplatePlaceholder(0 + 15)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.COMMISSION = new ClientReportTemplatePlaceholder(0 + 16)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.STORAGE = new ClientReportTemplatePlaceholder(0 + 17)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.PROFIT = new ClientReportTemplatePlaceholder(0 + 18)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.OPENORDERS = new ClientReportTemplatePlaceholder(0 + 19)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.TODAYORDERS = new ClientReportTemplatePlaceholder(0 + 20)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.TODAYORDERS_SUM_VIEW_TYPE = new ClientReportTemplatePlaceholder(0 + 21)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.CLOSED_COMMISSION = new ClientReportTemplatePlaceholder(0 + 22)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.CLOSED_STORAGE = new ClientReportTemplatePlaceholder(0 + 23)
// noinspection PointlessArithmeticExpressionJS
ClientReportTemplatePlaceholder.CLOSED_PROFIT = new ClientReportTemplatePlaceholder(0 + 24)

export { ClientReportTemplatePlaceholder };

/**
 * Fast Binary Encoding ClientReportTemplatePlaceholder field model
 */
class FieldModelClientReportTemplatePlaceholder extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelClientReportTemplatePlaceholder}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelClientReportTemplatePlaceholder}
   * @param {ClientReportTemplatePlaceholder=} defaults Default value, defaults is new ClientReportTemplatePlaceholder()
   * @returns {!ClientReportTemplatePlaceholder} Result value
   */
  get (defaults = new ClientReportTemplatePlaceholder()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new ClientReportTemplatePlaceholder(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelClientReportTemplatePlaceholder}
   * @param {!ClientReportTemplatePlaceholder} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelClientReportTemplatePlaceholder };
