import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { ErrorsTracker } from '@api/errorsTracker';
import { ConvertedSymbol, convertSymbolToSymbolModel } from '@models/Symbols';
import { UInt64 } from '@api/fbe/int64';
import { SymbolsGetAllRequest } from '@api/fbe/manager/SymbolsGetAllRequest';
import { OrderCreateRequest } from '@api/fbe/manager/OrderCreateRequest';
import { convertOrderToOrderModel, CreateOrderModel, GetOrderRequestParams, ModifyOrderModelPartial } from '@models/riskManagement/Order';
import { OrderSide } from '@api/fbe/core/OrderSide';
import { OrderType } from '@api/fbe/core/OrderType';
import { OrderModifyRequest } from '@api/fbe/manager/OrderModifyRequest';
import { Message, MessageType } from '@components/Message/Message';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { OrderGetRequest } from '@api/fbe/manager/OrderGetRequest';

export class OrderStore {
    errorTracker = new ErrorsTracker({ title: 'Order' });

    constructor() {
        makeAutoObservable(this);
    }

    symbols: ConvertedSymbol[] = [];

    order: ModifyOrderModelPartial | null = null;

    isLoadingSymbols: boolean = false;
    isLoadingOrder: boolean = false;

    setSymbols(value: ConvertedSymbol[]) {
        this.symbols = value;
    }

    @ErrorsTracker.wrapApi()
    async getSymbols() {
        const request = new SymbolsGetAllRequest();
        this.isLoadingSymbols = true;
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setSymbols(data.Symbols.map(convertSymbolToSymbolModel));
        this.isLoadingSymbols = false;
    }

    @ErrorsTracker.wrapApi()
    async createOrder(data: CreateOrderModel) {
        const request = new OrderCreateRequest();
        request.Login = UInt64.fromNumber(data.Login);
        request.SymbolId = UUID.fromString(data.SymbolId);
        request.OrderSide = new OrderSide(data.OrderSide);
        request.OrderType = new OrderType(data.OrderType);
        request.Price = data.Price;
        request.StopPrice = data.StopPrice;
        request.TakeProfit = data.TakeProfit;
        request.StopLoss = data.StopLoss;
        request.Amount = data.Amount;
        request.ManagerComment = data.ManagerComment;
        return await SocketClient.instance.request(request, this.errorTracker);
    }

    @ErrorsTracker.wrapApi()
    async modifyOrderPartial(record: ModifyOrderModelPartial, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new OrderModifyRequest();
        if (record.OrderId !== null) {
            request.Login = UInt64.fromNumber(record.Login);
            request.OrderId = UInt64.fromNumber(record.OrderId);
            request.NewManagerComment = record.ManagerComment;
            if (dirtyFields?.TakeProfit) {
                request.NewTakeProfit = record.TakeProfit;
                if (record.TakeProfit === null) {
                    request.NewTakeProfitReset = true;
                }
            }
            if (dirtyFields?.StopLoss) {
                request.NewStopLoss = record.StopLoss;
                if (record.StopLoss === null) {
                    request.NewStopLossReset = true;
                }
            }
            if (record.OrderType === OrderType.LIMIT.valueOf()) {
                // Position
                if (dirtyFields?.Price) {
                    request.NewPrice = record.Price;
                    if (record.Price === null) {
                        request.NewPriceReset = true;
                    }
                }
            }
            if (record.OrderType === OrderType.STOP.valueOf()) {
                // PendingOrder
                if (dirtyFields?.StopPrice) {
                    request.NewStopPrice = record.StopPrice;
                    if (record.StopPrice === null) {
                        request.NewStopPriceReset = true;
                    }
                }
            }
            return await SocketClient.instance.request(request, this.errorTracker);
        } else {
            Message(MessageType.error, 'OrderId are required');
        }
    }

    @ErrorsTracker.wrapApi()
    async getOrderById({ Login, OrderId }: GetOrderRequestParams) {
        this.isLoadingOrder = true;
        if (OrderId === null) {
            Message(MessageType.error, "Order ID are required");
            return;
        };
        try {
            const request = new OrderGetRequest();
            request.Login = UInt64.fromNumber(Login);
            request.OrderId = UInt64.fromNumber(OrderId);
            const result = await SocketClient.instance.request(request, this.errorTracker);
            const order = convertOrderToOrderModel(result.Order);
            this.order = {
                Login: order.Login,
                OrderId: order.OrderId,
                SymbolId: order.SymbolId,
                OrderSide: order.OrderSide,
                OrderType: order.OrderType,
                Price: order.Price,
                StopPrice: order.StopPrice,
                TakeProfit: order.TakeProfit,
                StopLoss: order.StopLoss,
                Amount: order.Amount,
                ManagerComment: order.ManagerComment,
            };
        } catch (error) {
            Message(MessageType.error, "Can't load Order");
            this.order = null;
        }
        this.isLoadingOrder = false;
    }

    reset() {
        this.symbols = [];
        this.order = null;
        this.isLoadingSymbols = false;
        this.isLoadingOrder = false;
    }
}

const orderStore = new OrderStore();
export { orderStore };
