
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ChartBarsImportFinishedNotify struct
 */
class ChartBarsImportFinishedNotify {
  /**
   * Initialize struct
   * @param {!Date=} timeFrom
   * @param {!Date=} timeTo
   * @param {!Set=} importedSymbols
   * @constructor
   */
  constructor (timeFrom = new Date(0), timeTo = new Date(0), importedSymbols = new Set()) {
    this.TimeFrom = timeFrom
    this.TimeTo = timeTo
    this.ImportedSymbols = importedSymbols
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ChartBarsImportFinishedNotify}
   * @param {!ChartBarsImportFinishedNotify} other Other struct
   * @returns {!ChartBarsImportFinishedNotify} This struct
   */
  copy (other) {
    if (other.TimeFrom != null) {
      if (other.TimeFrom instanceof Date) {
        this.TimeFrom = new Date(other.TimeFrom.getTime())
      } else {
        this.TimeFrom = new Date(Math.round(other.TimeFrom / 1000000))
      }
    } else {
      this.TimeFrom = null
    }
    if (other.TimeTo != null) {
      if (other.TimeTo instanceof Date) {
        this.TimeTo = new Date(other.TimeTo.getTime())
      } else {
        this.TimeTo = new Date(Math.round(other.TimeTo / 1000000))
      }
    } else {
      this.TimeTo = null
    }
    if (other.ImportedSymbols != null) {
      this.ImportedSymbols = new Set()
      for (let item of other.ImportedSymbols) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.ImportedSymbols.add(tempItem)
        } else {
          this.ImportedSymbols.add(null)
        }
      }
    } else {
      this.ImportedSymbols = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ChartBarsImportFinishedNotify}
   * @returns {!ChartBarsImportFinishedNotify} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ChartBarsImportFinishedNotifyModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ChartBarsImportFinishedNotifyModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ChartBarsImportFinishedNotify}
   * @param {!ChartBarsImportFinishedNotify} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ChartBarsImportFinishedNotify)) {
      throw new TypeError('Instance of ChartBarsImportFinishedNotify is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ChartBarsImportFinishedNotify}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      TimeFrom: ((this.TimeFrom != null) ? (this.TimeFrom.getTime() * 1000000) : null),
      TimeTo: ((this.TimeTo != null) ? (this.TimeTo.getTime() * 1000000) : null),
      ImportedSymbols: ((this.ImportedSymbols != null) ? Array.from(this.ImportedSymbols, item => ((item != null) ? item.toString() : null)) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ChartBarsImportFinishedNotify.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ChartBarsImportFinishedNotify} other Object value
   * @returns {!ChartBarsImportFinishedNotify} Created struct
   */
  static fromObject (other) {
    return new ChartBarsImportFinishedNotify().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ChartBarsImportFinishedNotify}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ChartBarsImportFinishedNotify.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ChartBarsImportFinishedNotify}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 3026
  }
}

export { ChartBarsImportFinishedNotify };

/**
 * Fast Binary Encoding ChartBarsImportFinishedNotify field model
 */
class FieldModelChartBarsImportFinishedNotify extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.TimeFrom = new fbe.FieldModelTimestamp(buffer, 4 + 4)
    this.TimeTo = new fbe.FieldModelTimestamp(buffer, this.TimeFrom.fbeOffset + this.TimeFrom.fbeSize)
    this.ImportedSymbols = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.TimeTo.fbeOffset + this.TimeTo.fbeSize), buffer, this.TimeTo.fbeOffset + this.TimeTo.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.TimeFrom.fbeSize + this.TimeTo.fbeSize + this.ImportedSymbols.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.TimeFrom.fbeExtra + this.TimeTo.fbeExtra + this.ImportedSymbols.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelChartBarsImportFinishedNotify.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 3026
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.TimeFrom.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimeFrom.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeFrom.fbeSize

    if ((fbeCurrentSize + this.TimeTo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimeTo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeTo.fbeSize

    if ((fbeCurrentSize + this.ImportedSymbols.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ImportedSymbols.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ImportedSymbols.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {!ChartBarsImportFinishedNotify} fbeValue Default value, defaults is new ChartBarsImportFinishedNotify()
   * @returns {!ChartBarsImportFinishedNotify} ChartBarsImportFinishedNotify value
   */
  get (fbeValue = new ChartBarsImportFinishedNotify()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {!ChartBarsImportFinishedNotify} fbeValue ChartBarsImportFinishedNotify value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.TimeFrom.fbeSize) <= fbeStructSize) {
      fbeValue.TimeFrom = this.TimeFrom.get()
    } else {
      fbeValue.TimeFrom = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeFrom.fbeSize

    if ((fbeCurrentSize + this.TimeTo.fbeSize) <= fbeStructSize) {
      fbeValue.TimeTo = this.TimeTo.get()
    } else {
      fbeValue.TimeTo = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeTo.fbeSize

    if ((fbeCurrentSize + this.ImportedSymbols.fbeSize) <= fbeStructSize) {
      this.ImportedSymbols.get(fbeValue.ImportedSymbols)
    } else {
      fbeValue.ImportedSymbols.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ImportedSymbols.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {!ChartBarsImportFinishedNotify} fbeValue ChartBarsImportFinishedNotify value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelChartBarsImportFinishedNotify}
   * @param {ChartBarsImportFinishedNotify} fbeValue ChartBarsImportFinishedNotify value
   */
  setFields (fbeValue) {
    this.TimeFrom.set(fbeValue.TimeFrom)
    this.TimeTo.set(fbeValue.TimeTo)
    this.ImportedSymbols.set(fbeValue.ImportedSymbols)
  }
}

export { FieldModelChartBarsImportFinishedNotify };

/**
 * Fast Binary Encoding ChartBarsImportFinishedNotify model
 */
class ChartBarsImportFinishedNotifyModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelChartBarsImportFinishedNotify(this.buffer, 4)
  }

  /**
   * Get the ChartBarsImportFinishedNotify model
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @returns {!FieldModelChartBarsImportFinishedNotify} model ChartBarsImportFinishedNotify model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ChartBarsImportFinishedNotifyModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelChartBarsImportFinishedNotify.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @param {!ChartBarsImportFinishedNotify} value ChartBarsImportFinishedNotify value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @param {!ChartBarsImportFinishedNotify} value ChartBarsImportFinishedNotify value, defaults is new ChartBarsImportFinishedNotify()
   * @return {!object} Deserialized ChartBarsImportFinishedNotify value and its size
   */
  deserialize (value = new ChartBarsImportFinishedNotify()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ChartBarsImportFinishedNotify(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ChartBarsImportFinishedNotify(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ChartBarsImportFinishedNotifyModel}
   * @param {!number} prev Previous ChartBarsImportFinishedNotify model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ChartBarsImportFinishedNotifyModel };
